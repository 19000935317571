import instance from '../../../../../../apis/local/systemAPI';

// START OF COURSE OUTLINE DETAIL
export const getCourseOutlineDetail = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/course-outline/details/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "SUB.Description": keyword,
            "SUB.SubjectCode": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/course-outline/details/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineDetailQuery = async (con) => {
  const result = await instance.post(`api/course-outline/details/s`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineDetailFiltered = async (con) => {
  const result = await instance.post(`api/course-outline/details/s`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineF = async (con) => {
  const result = await instance.post(`api/course-outlines/s`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createCourseOutlineDetail = async (formValues) => {
  const result = await instance.post("api/course-outline/details/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCourseOutlineDetail = async (id, formValues) => {
  const result = await instance.patch(`api/course-outline/details/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getYearLevel = async () => {
  const result = await instance.get(`api/references/yearlevels/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubject = async () => {
  const result = await instance.get(`api/references/subjects/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectQuery = async (q) => {
  const result = await instance.post(`api/references/subjects/q`, q);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getSemester = async () => {
  const result = await instance.get(`api/references/semesters/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutline = async () => {
  const result = await instance.get(`api/references/semesters/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineQuery = async (q) => {
  const result = await instance.post(`api/course-outlines/s`, q);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addUpdateCourseOutlineDetail = async (val) => {
  const result = await instance.post(`api/course-outline/details/addUpdate`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteCourseOutlineDetail = async (val) => {
  const result = await instance.post(`api/course-outline/details/delete`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineReport = async (values) => {
  const result = await instance.post("api/course-outlines/report", values);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

// END OF COURSE OUTLINE DETAIL