import React, { useState, useEffect } from "react";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import {
  Modal, Box
} from '@material-ui/core';
import {
  getSem, getSchoolYear, getEnrollment, getStudentEnrollmentSubjects,
  getEnrollmentStatus, updateEnrollment, getStudentFromCampus, createStudentInfo,
  createStudents, createStudentFamily, createEnrollment, createEnrollmentMigration
} from "../../Admissions/ApplicantDataEntry/__hooks__";
import { admissionsTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import NoDataFound from "../../../../../_metronic/layout/components/custom/forms/NoDataFound";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import MigratingLoading from "../../../../../_metronic/layout/components/custom/forms/MigratingLoading";
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect'
import { useForm } from "react-hook-form";
import { NoAccess } from "../../../../../_metronic/layout/components/custom/forms/NoAccess";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import moment from "moment";

export default function ApplicantDataEntry(props) {
  const { register, handleSubmit } = useForm()
  const { state: { applicantData, auth }, dispatch } = useAppContext();
  const [data, setData] = useState(undefined)
  const { setValue } = useForm();
  const [keepLoading, setKeepLoading] = useState(true)
  const [results, setResults] = useState([]);
  const [onSearch, setOnSearch] = useState(false);
  const [keyword, setKeyword] = useState()
  const [page, setPage] = useState(0);
  const [openMigrateModal, setOpenMigrateModal] = useState(false)
  const [campusData, setCampusData] = useState()
  const [isImporting, setIsImporting] = useState(null)

  const [activeSem, setActiveSem] = useState()
  const [semesters, setSemesters] = useState()
  const [schoolYears, setSchoolYears] = useState()
  const [activeSchoolYear, setActiveSchoolYear] = useState()
  const [enrollment, setEnrollment] = useState([])
  const [student, setStudent] = useState()
  const [studentSubjects, setStudentSubjects] = useState()
  const [dataPerPage, setDataPerPage] = useState([])
  const [enrollmentStatus, setEnrollmentStatus] = useState()
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [totalRecords, setTotalRecords] = useState()

  const [references, setReferences] = useState()
  const [eStatus, setEStatus] = useState()
  const [semestersSelect, setSemestersSelect] = useState()
  const [schoolYearSelect, setSchoolYearSelect] = useState()
  const [isChangedStatus, setIsChangedStatus] = useState(false)
  const [filteredSem, setFilteredSem] = useState('1S')
  const [filteredSy, setFilteredSy] = useState(undefined)
  const [filteredEStatus, setFilteredEStatus] = useState('DR')
  const [recordPage, setRecordPage] = useState(0)
  const updateId = applicantData.id;
  const isLastUpdate = true;


  const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };


  const Query = (operation, condition, setData, identifier) => {

    const execute = async () => {
      if (identifier == 'migrate') {
        console.log(condition)
        await operation(condition)
          .then(response => {

            setData(response)

            return
          })
          .catch(error => {
            if (error) {
              return error
              // alert("something went wrong")
            } else {
              return
            }
          })
      } else if (identifier == 'table') {
        let obj = eval("(" + condition + ")");
        await operation(obj)
          .then(response => {
            if (response.success) {
              setTotalRecords(response.totalRecords)
              setData(response.data)
            }
            return
          })
          .catch(error => {
            if (error) {
              return error
              // alert("something went wrong")
            } else {
              return
            }
          })
      } else {
        let obj = eval("(" + condition + ")");
        await operation(obj)
          .then(response => {
            if (response.success) {
              setData(response.data)
            }

            return
          })
          .catch(error => {
            if (error) {
              return error
              // alert("something went wrong")
            } else {
              return
            }
          })
      }
    }
    execute()
  }

  const onUpdateEnrollment = async (id, values) => {
    await updateEnrollment(id, values)
      .then(response => {
        if (response.success) {
          setIsChangedStatus(true)

        } else {

        }
      })
      .catch(error => {
        alert('enrollment did not update')
      })
  }

  function makeid(length) {
    var result = '';
    var characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for (var i = 0; i < length; i++) {
      result += characters.charAt(Math.floor(Math.random() *
        charactersLength));
    }
    return result;
  }

  const useEffectOperation = (operation, condition, setData, trigger, trigger2, trigger3) => {
    let dataStorer = []
    useEffect(() => {
      const execute = async () => {
        await operation()
          .then(response => {
            if (response.success) {
              for (let i = 0; i < response.data.length; i++) {
                if (eval(condition)) {
                  dataStorer.push(response.data[i])
                }
              }
              setData(dataStorer)
            } else {
            }
          })
          .catch(error => {
            if (error) {
              // alert("something went wrong")
            } else {
            }
          })
      }
      execute()

    }, [trigger, trigger2, trigger3])
  }


  const getActiveQuery = "{ where: 'WHERE IsActive = 1' }"
  const getAll = "{where: ''}"
  //const getEnrollmentQuery = "{ where: ` WHERE e.SchoolYear = ${filteredSy} AND e.SemesterId = '${filteredSem}' AND e.EnrollmentStatusId = '${filteredEStatus}' LIMIT ${rowsPerPage} OFFSET ${page}` }"
  const getEnrollmentQuery = "{ where: ` WHERE e.SchoolYear = ${filteredSy} AND e.SemesterId = '${filteredSem}' AND e.EnrollmentStatusId = '${filteredEStatus}'`, pagination: `LIMIT ${rowsPerPage} OFFSET ${recordPage}`}"

  const getStudentQuery = "{where: `WHERE s.StudentId IN (${enrollment.map(d => { return d.StudentId })})`}"
  const search = "{where: `WHERE LastName LIKE '%${keyword}%' OR FirstName LIKE '%${keyword}%' OR e.StudentNo LIKE '%${keyword}%' LIMIT ${rowsPerPage} OFFSET ${page} `}"


  let conGetStudentEnrollmentSub = 'response.data[i].StudentId === student[0].StudentId && response.data[i].SemesterId === activeSem[0].SemesterId && response.data[i].SchoolYear === activeSchoolYear[0].SchoolYear'
  useEffectOperation(getStudentEnrollmentSubjects, conGetStudentEnrollmentSub, setStudentSubjects, student, activeSem, activeSchoolYear)


  useEffect(() => {
    // sem
    Query(getSem, getActiveQuery, setActiveSem)
    // sem all
    Query(getSem, getAll, setSemesters)
    // sy
    Query(getSchoolYear, getActiveQuery, setActiveSchoolYear)
    // sy all
    Query(getSchoolYear, getAll, setSchoolYears)

    // enrollmentStatys
    Query(getEnrollmentStatus, getAll, setEnrollmentStatus)

  }, [])


  useEffect(() => {

    if (filteredSem !== undefined && filteredSy !== undefined && filteredEStatus !== undefined && onSearch === false) {
      // enrollment
      Query(getEnrollment, getEnrollmentQuery, setEnrollment, 'table')

    } else if (onSearch === true) {

    }

  }, [filteredSem, filteredSy, filteredEStatus, rowsPerPage, recordPage, onSearch, openMigrateModal])


  useEffect(() => {
    if (isImporting === false) {
      // enrollment
      Query(getEnrollment, getEnrollmentQuery, setEnrollment, 'table')
    }
  }, [isImporting])




  // useEffect(() => {
  //   if (isChangedStatus === false && enrollment !== undefined) {

  //     //student
  //     Query(getStudent, getStudentQuery, setStudent)
  //   }
  // }, [enrollment, isChangedStatus])




  // responsible for enrollment status changed when school year && semester changed  

  // useEffect(() => {
  //   if (studentEnrollment !== undefined && studentEnrollment.length > 0 && activeSem !== undefined && activeSchoolYear !== undefined && runAgain === false) {

  //     const enrollmentStatusChangedOldStudent = {
  //       enrollmentStatusId: 'OS'
  //     }
  //     const enrollmentStatusChangedActive = {
  //       enrollmentStatusId: 'E'
  //     }

  //     let enStat = eStatus
  //     console.log(studentEnrollment)
  //     for (let i = 0; i < studentEnrollment.length; i++) {
  //       if (studentEnrollment[i].EnrollmentStatusId === 'E' && (studentEnrollment[i].SemesterId !== activeSem[0].SemesterId || studentEnrollment[i].SchoolYear !== activeSchoolYear[0].SchoolYear)) {

  //         onUpdateEnrollment(studentEnrollment[i].EnrollmentId, enrollmentStatusChangedOldStudent)
  //         enStat = 'E'
  //       }
  //       if (studentEnrollment[i].EnrollmentStatusId === 'OS' && studentEnrollment[i].SemesterId === activeSem[0].SemesterId && studentEnrollment[i].SchoolYear === activeSchoolYear[0].SchoolYear) {

  //         onUpdateEnrollment(studentEnrollment[i].EnrollmentId, enrollmentStatusChangedActive)
  //         enStat = 'OS'

  //       }
  //     }
  //     setEStatus(enStat)

  //     if (isChangedStatus === false) {
  //       setRunAgain(true)
  //     }
  //   }

  // }, [studentEnrollment])

  useEffect(() => {
    let arr = []
    if (enrollment !== undefined && enrollmentStatus !== undefined) {

      for (let r = 0; r < enrollment.length; r++) {
        for (let i = 0; i < enrollmentStatus.length; i++) {
          if (enrollment[r].EnrollmentStatusId === enrollmentStatus[i].EnrollmentStatusId) {
            arr.push({
              enrollmentId: enrollment[r].EnrollmentId,
              studentId: enrollment[r].StudentId,
              studentNo: enrollment[r].StudentNo,
              studentName: `${enrollment[r].LastName}, ${enrollment[r].FirstName}`,
              firstName: `${enrollment[r].FirstName}`,
              lastName: `${enrollment[r].LastName}`,
              middleName: `${enrollment[r].MiddleName}`,
              studentInfoId: enrollment[r].StudentInfoId,
              sy: `${enrollment[r].SchoolYear} / ${enrollment[r].SemesterId}`,
              semester: enrollment[r].SemesterId,
              schoolYear: enrollment[r].SchoolYear,
              enrollmentStatus: enrollmentStatus[i].EnrollmentStatus,
              civilStatusId: enrollment[r].CivilStatusId,
              religionId: enrollment[r].ReligionId,
              birthDate: enrollment[r].BirthDate,
              birthPlace: enrollment[r].BirthPlace,
              telNo: enrollment[r].TelNo,
              celNo: enrollment[r].CelNo,
              email: enrollment[r].EmailAddress,
              address: enrollment[r].Address1,
              lastSchool: enrollment[r].LastSchoolId,
              course: enrollment[r].Course,
              courseId: enrollment[r].CourseId,
              courseDesc: enrollment[r].CourseDesc,
              yearLevel: enrollment[r].YearLevel,
              yearLevelId: enrollment[r].YearLevelId,
              status: enrollment[r].EnrollmentStatusId,
              gender: enrollment[r].Gender,
              fatherName: enrollment[r].FatherName,
              fatherOccupation: enrollment[r].FatherBusinessName,
              fatherOccupationAddress: enrollment[r].FatherBusinessAddress,
              fatherContactNo: enrollment[r].FatherCellNo,
              fatherAddress: enrollment[r].FatherHomeAddress,
              fatherMaritalStatus: enrollment[r].FatherMaritalStatus,
              fatherEducation: enrollment[r].FatherHighestEducationalAttainment,
              fatherCitizen: enrollment[r].FatherCitizen,
              motherName: enrollment[r].MotherName,
              motherOccupation: enrollment[r].MotherBusinessName,
              motherOccupationAddress: enrollment[r].MotherBusinessAddress,
              motherContactNo: enrollment[r].MotherCellNo,
              motherAddress: enrollment[r].MotherHomeAddress,
              motherMaritalStatus: enrollment[r].MotherMaritalStatus,
              motherCitizen: enrollment[r].MotherCitizen,
              motherEducation: enrollment[r].MotherHighestEducationalAttainment,
              familyBackgroundId: enrollment[r].FamilyBackgroundId,
              municipalityId: enrollment[r].MunicipalityId,
              provinceId: enrollment[r].ProvinceId,
              countryId: enrollment[r].CountryId,
              barangay: enrollment[r].Barangay,
              isOpenModal: false

            })
          }
        }

      }

    }
    console.log(arr)
    const keys = ['studentId'],
      filtered = arr.reverse().filter(
        (s => o =>
          (k => !s.has(k) && s.add(k))
            (keys.map(k => o[k]).join('|'))
        )
          (new Set)
      );
    setResults(filtered)

  }, [enrollment, onSearch, page])




  // useEffect(() => {
  //   if (enrollment !== undefined) {
  //     if (enrollment.length > 0) {

  //       setEStatus(enrollment[0].EnrollmentStatusId)

  //     }
  //   }
  // }, [enrollment])

  // useEffect(() => {
  //   if (eStatus !== undefined && activeSem !== undefined) {

  //     setData({ 'EnrollmentStatus': eStatus, 'Semester': activeSem[0].SemesterId })
  //   }
  // }, [eStatus, activeSem])

  useEffect(() => {
    let referencessArr = []
    let semestersArr = []
    let schoolYearArr = []

    if (enrollment !== undefined && enrollmentStatus !== undefined && semesters !== undefined && schoolYears !== undefined) {

      for (let v = 0; v < enrollmentStatus.length; v++) {
        referencessArr.push({ tableId: enrollmentStatus[v].EnrollmentStatusId, code: enrollmentStatus[v].EnrollmentStatus, })
      }

      for (let s = 0; s < semesters.length; s++) {
        semestersArr.push({ tableId: semesters[s].SemesterId, code: semesters[s].Description })
      }

      for (let g = 0; g < schoolYears.length; g++) {
        schoolYearArr.push({ tableId: schoolYears[g].SchoolYear, code: schoolYears[g].Description })
      }
      setSemestersSelect(semestersArr)
      setSchoolYearSelect(schoolYearArr)


      const keys = ['tableId'],
        filtered = referencessArr.filter(
          (s => o =>
            (k => !s.has(k) && s.add(k))
              (keys.map(k => o[k]).join('|'))
          )
            (new Set)
        );

      setReferences(filtered)
    }


  }, [enrollment, enrollmentStatus, semesters, schoolYears, data])

  useEffect(() => {
    if (campusData !== undefined && campusData.length > 0) {
      console.log(campusData)
      console.log(moment(campusData[0].EnrollDate).format("YYYY-MM-DD hh:mm:ss A Z").slice(0, 19))
      let campusArr = []
      for (let i = 0; i < campusData.length; i++) {
        campusArr.push({
          lastName: campusData[i].LastName,
          firstName: campusData[i].FirstName,
          middleName: campusData[i].MidName,
          gender: campusData[i].Gender,
          birthDate: campusData[i].BirthDate,
          birthPlace: campusData[i].BirthPlace,
          studentNo: campusData[i].StudentNo,
          telNo: campusData[i].HomePhone,
          celNo: campusData[i].CellPhone,
          schoolYear: campusData[i].UserSchoolYear,
          semesterId: campusData[i].SemesterCode,
          courseId: campusData[i].Coursekey,
          yearLevelId: campusData[i].YearLevelkey,
          enrollmentDate: moment(campusData[0].EnrollDate).format("YYYY-MM-DD hh:mm:ss A Z").slice(0, 19),
          instituteId: campusData[i].Institutekey
        })
      }

      // ------------------------------------

      const executeMigration = async (values) => {
        setIsImporting(true)
        await createEnrollmentMigration(values)
          .then(res => {
            if (res.success) {
              setIsImporting(false)

            }
          })
          .catch(e => {
            setIsImporting(false)

          })

      }
      console.log(campusArr)
      executeMigration(campusArr)
      setOpenMigrateModal(false)

    }
  }, [campusData])

  useEffect(() => {
    if (activeSchoolYear !== undefined) {
      setFilteredSy(activeSchoolYear[0].SchoolYear)
    }
  }, [activeSchoolYear])

  const handleChangeKeyword = (event) => {
    setKeyword(event);

  };

  useEffect(() => {
    if (keyword !== undefined && onSearch === true) {
      setPage(0)
      console.log(search)
      Query(getEnrollment, search, setEnrollment, 'table')
    }
  }, [keyword, page])
  const selectHandleChange = name => event => { setData({ ...data, [name]: event }); }
  const selectSemChange = name => event => { setFilteredSem(event) }
  const selectSyChange = name => event => { setFilteredSy(event) }
  const selectStatusChange = name => event => { setFilteredEStatus(event) }


  const handleChangeOnSearch = (event) => {
    console.log(event)
    setOnSearch(event)

  };

  const handleChangePage = (event, newPage) => {
    console.log(event)
    console.log(newPage)
    let pageInserted = `${newPage}0`
    console.log(pageInserted)
    let pageConv = parseInt(pageInserted)
    setRecordPage(pageInserted)
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };


  const handleClick = (data) => {
    let items = results
    let foundIndex = items.findIndex(x => x.studentNo == data.studentNo);
    items[foundIndex] = data;
    setResults(items)
    setDataPerPage(items)
  }

  const onMigrateData = () => {
    setOpenMigrateModal(true)
  }


  const imSelectDropdownReference = () => {

    return (

      <>
        {references &&
          <IMSelect
            data={references}
            onHandleChange={selectStatusChange()}
            refClassContainer=""
            name="SubjectScheduleId"
            isRequired={false}
            withLabel={false}
            label="Enrollment Status"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={onSearch === false ? filteredEStatus === undefined ? enrollment[0].EnrollmentStatusId : filteredEStatus : ''}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
              tableId: 'tableId',
              display: 'code'
            }}
          />
        }

      </>
    )

  }

  const imSelectDropdownSemesters = () => {
    return (

      <>
        {semestersSelect &&

          <IMSelect
            data={semestersSelect}
            onHandleChange={selectSemChange()}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={onSearch === false ? filteredSem === undefined ? activeSem[0].SemesterId : filteredSem : ''}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
              tableId: 'tableId',
              display: 'code'
            }}

          />
        }

      </>
    )

  }
  const imSelectDropdownSchoolYear = () => {
    return (

      <>
        {schoolYearSelect &&
          <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectSyChange()}
            refClassContainer=""
            name="SchoolYear"
            isRequired={false}
            withLabel={false}
            label="SchoolYear"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={onSearch === false ? filteredSy === undefined ? activeSchoolYear[0].SchoolYear : filteredSy : ''}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
              tableId: 'tableId',
              display: 'code'
            }}

          />
        }


      </>
    )

  }

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'studentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'studentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'sy', label: 'SY / Semester', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    // { id: 'enrollmentDate', label: 'Application Date', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'enrollmentStatus', label: 'Enrollment Status', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'course', label: 'Course', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'yearLevel', label: 'Year', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false },

  ]
  let tableProps = undefined
  if (activeSem && activeSchoolYear && enrollment) {

    tableProps = {
      recordId: 'studentInfoId',
      sortField: '',
      columns: columns,
      rows: results,
      totalRecords: totalRecords === undefined ? enrollment.length : totalRecords,
      withMoreButton: true,
      childWithMoreButton: true,
      withFooter: false,
      tableType: 'studentMasterFile',
      parentId: null,
      tableTitle: '',
      onSearch: onSearch,
      keyword: keyword,
      lastUpdateId: updateId,
      onIsLastUpdate: isLastUpdate,
      onPage: page,
      onRowsPerPage: rowsPerPage,
      onRowsPerPageOptions: [10, 25, 50, 100],
      onTypes: admissionsTypes,
      onHandleChangePage: handleChangePage,
      onHandleChangeRowsPerPage: handleChangeRowsPerPage,
      onHandleChangeKeyword: handleChangeKeyword,
      onHandleChangeOnSearch: handleChangeOnSearch,
      activeSem: activeSem[0].SemesterId,
      activeSchoolYear: activeSchoolYear[0].SchoolYear,
      studentSubjects: studentSubjects,
      selectedSem: filteredSem,
      selectedSy: filteredSy
    }

  }


  setTimeout(function () {
    setKeepLoading(false)
  }, 1000);

  const handleClose = () => {
    setOpenMigrateModal(false)
  }


  const MigrateModal = (props) => {

    const onSubmit = (formValues) => {
      let migrationData = {
        ...formValues,
        semesterId: filteredSem,
        schoolYear: filteredSy
      }

      console.log(migrationData)

      Query(getStudentFromCampus, migrationData, setCampusData, 'migrate')

    }
    if (enrollment !== undefined) {
      console.log(results)
    }

    console.log(results)
    return (
      <Modal
        open={openMigrateModal}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="card p -5" style={style}>
          <div className="card-header"> <h3>Migrate from campus ++</h3> </div>
          <div className="card-body">

            <div className="col bg-white mr-3 my-0 p-0">{imSelectDropdownSchoolYear()}</div>
            <div className="my-4 col bg-white my-0 p-0">{imSelectDropdownSemesters()}</div>
            <form onSubmit={handleSubmit(onSubmit)} method="post">
              <input
                className="form-control"
                name="studentNo"
                placeholder="Student No."
                ref={register({
                  required: false
                })}
              />
              <button type="submit" className="mt-5 mr-5 btn btn-primary ">Migrate</button>
              <button type="button" onClick={() => { handleClose() }} className="mt-5 btn btn-secondary ">Exit</button>
            </form>


          </div>
        </Box>


      </Modal>
    )
  }


  console.log(campusData)
  console.log(enrollment)
  console.log(rowsPerPage)
  console.log(keyword)
  console.log(totalRecords)
  console.log(results)
  return (
    <>
      {activeSem !== undefined && activeSchoolYear !== undefined && results !== undefined && tableProps !== undefined

        ?
        <>
          <MigrateModal isOpen={openMigrateModal} />
          <div className="card">
            <div className="card-header font-weight-bold  ">

              <h3 className="mb-0 float-left">  &nbsp; Student Master Lists</h3>
              <p className="float-right">&nbsp; Current School Year / Semester : <span className="text-success">{`${activeSchoolYear[0].SchoolYear} ${activeSem[0].Semester}`}</span> </p>


              <br />
            </div>



            <ListFilters
              selectDropdownStatus={imSelectDropdownReference}
              selectDropdownSemester={imSelectDropdownSemesters}
              selectDropdownSchoolYear={imSelectDropdownSchoolYear}
              onSearch={onSearch}
              keyword={keyword}
              onHandleChangeOnSearch={handleChangeOnSearch}
              onHandleChangeKeyword={handleChangeKeyword}
              tableTitle='Filter: '
            />

            {auth.data.UserType === 'CHR'
              || auth.data.UserType === 'RGR'
              || auth.data.UserType === 'ADM' ?

              <>
                {results.length > 0 ?
                  <>
                    <IMTableCustom
                      handleClick={handleClick}
                      enrollmentFilter={imSelectDropdownReference}
                      semesterFilter={imSelectDropdownSemesters}
                      tableProps={tableProps} />
                  </>
                  :
                  <>
                    {keepLoading === true ?

                      <Loading />
                      :
                      <>
                        {isImporting === true ?
                          <MigratingLoading />
                          :
                          <NoDataFound />

                        }
                      </>


                    }
                  </>
                }
              </>
              :
              <NoAccess />
            }
          </div>
        </>
        :
        <>
          <Loading />
        </>
      }
    </>
  )
}