import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControlLabel, Switch } from '@material-ui/core';
import { CourseSelect, SchoolYearSelect, SemesterSelect } from '../';
import moment from 'moment'

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [effectiveDate, setEffectiveDate] = useState(props.doc.EffectiveDate === undefined ? moment().format('YYYY-MM-DD') : moment(props.doc.EffectiveDate).format('YYYY-MM-DD'));
  const [data, setData] = useState({
    courseId: null,
    schoolYear: null,
    semesterId: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'courseId' }, { required: true });
    register({ name: 'schoolYear' }, { required: true });
    register({ name: 'semesterId' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { courseOutline, description, courseId, noOfYears, schoolYear, semesterId, effectiveDate, isActive } = formValues;

    const convertedFormValues = {
      courseOutline: courseOutline,
      description: description,
      courseId: courseId,
      noOfYears: noOfYears,
      effectiveSchoolYear: schoolYear,
      effectiveSemesterId: semesterId,
      effectiveDate: effectiveDate,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Course Outline <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="courseOutline"
                  defaultValue={props.doc.CourseOutline === undefined ? "" : props.doc.CourseOutline}
                  className={`form-control${errors.courseOutline === undefined ? "" : errors.courseOutline && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your course outline.</span>
                {errors.courseOutline && errors.courseOutline.type === "required" && <div className="invalid-feedback">Course outline is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <CourseSelect
                refOnHandleChange={selectHandleChange('courseId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.CourseId === undefined ? "" : props.doc.CourseId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.courseId}
                refErrorMessage={errors.courseId && errors.courseId.type === "required" && 'Course is required'}
              />
              <div className="form-group">
                <label className="col-form-label">No. of Years <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="noOfYears"
                  defaultValue={props.doc.NoOfYears === undefined ? "" : props.doc.NoOfYears}
                  className={`form-control${errors.noOfYears === undefined ? "" : errors.noOfYears && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your no. of years.</span>
                {errors.noOfYears && errors.noOfYears.type === "required" && <div className="invalid-feedback">No. of years is required</div>}
              </div>
              <SchoolYearSelect
                refOnHandleChange={selectHandleChange('schoolYear')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.EffectiveSchoolYear === undefined ? "" : props.doc.EffectiveSchoolYear}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.courseId}
                refErrorMessage={errors.courseId && errors.courseId.type === "required" && 'School year is required'}
              />
              <SemesterSelect
                refOnHandleChange={selectHandleChange('semesterId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.EffectiveSemesterId === undefined ? "" : props.doc.EffectiveSemesterId}
                refWithFilter={true}
                refClassName={`form-control${errors.semesterId === undefined ? "" : " is-invalid"}`}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.courseId}
                refErrorMessage={errors.courseId && errors.courseId.type === "required" && 'Semester is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Effective Date <span className="text-danger">*</span> : </label>
                <input
                  type="date"
                  name="effectiveDate"
                  value={effectiveDate}
                  onChange={event => setEffectiveDate(event.target.value)}
                  className={`form-control${errors.effectiveDate === undefined ? "" : errors.effectiveDate && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your effective date.</span>
                {errors.effectiveDate && errors.effectiveDate.type === "required" && <div className="invalid-feedback">Effective date is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControlLabel
                  name="isActive"
                  inputRef={register()}
                  control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                  label="Active"
                />
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}