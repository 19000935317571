import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../../contexts/useAppContext";
import { useForm } from "react-hook-form";
import { accountTypes } from "./__hooks__/types";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import IMCollapsibleTableStudent from "../../../../../_metronic/layout/components/custom/table/IMCollapsibleTableStudent";
import IMCollapsibleTableStudentAAddDropSubjects from "../../../../../_metronic/layout/components/custom/table/IMCollapsibleTalbeStudentAddDropSubjects";
import IMTableStudentAssessment from "../../../../../_metronic/layout/components/custom/table/IMTableStudentAssessment";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import {
    updateEnrollment, addUpdateEnrollmentDiscount, createAssessmentAddOns,
    addUpdateStudentEnrollmentSubject, sendMail
} from "./__hooks__/index.js"
import history from "../../../../history";
import IMDialogEnrollOrValidate from "../../../../../_metronic/layout/components/custom/dialog/IMDialogEnrollOrValidate";
import { useParams } from "react-router-dom";
import LoadingOnFetching from "../../../../../_metronic/layout/components/custom/loader/LoadingOnFetching";
import IMDialogSubmition from "../../../../../_metronic/layout/components/custom/dialog/IMDialogSubmition";
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, } from '@material-ui/core';
import moment from "moment";
import { makeStyles } from '@material-ui/core/styles';
import BackAccountNotification from "../../../../../_metronic/layout/components/custom/modal/BackAccountNotification.js";
import { getStudentYearLevel } from "../../Student/ApplicantDataEntryDetail/__hooks__/proc";
import GrantDisclosureAgreement from "./GrantDisclosureAgreement.js";
import _ from "lodash";
import { Alert } from "bootstrap";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});




export default function ReAssessment(props) {
    const {
        studentAccount, studentSubjects, paymentModeSelect,
        studentFees, studentOtherFees, totalFee, totalInterest,
        studentDiscount, subjectId, subjectCode, onChangePaymentMode,
        onChangeStudentYearLevel, assessment,
        totalOtherFee, studentAddOns, totalAddOns, totalDiscount,
        studentDiscountParticulars, studentAddDropSubjects,
        studentAddDropSelectedSub, isAddDropValidated, isValidated,
        isPaid, paymentSchedule, totalAssessmentFee, yearLevelSelect,
        studentYearLevel, totalPayment, isWithBalance, studentTotalBackBalance,
        totalScholarshipDiscount
    } = props
    const { state: { auth }, dispatch } = useAppContext();
    const { id } = useParams()
    const [showDialog, setShowDialog] = useState({
        id: null,
        record: '',
        status: false
    });
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const [data, setData] = useState({
        SubjectScheduleId: null,
        PaymentModeId: studentAccount.PaymentModeId || null,
        AssessmentTemplateId: studentAccount.AssessmentTemplateId,
        CourseId: null,
        SubSchedDetailId: null,
        YearLevelId: null
    })
    const enrollmentDate = studentAccount.EnrollmentDate
    const { setValue } = useForm();
    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
    const classes = useStyles();
    const [isSubmitting, setIsSubmitting] = useState(false)
    const [isOpenWithBalance, setIsOpenWithBalance] = useState(false)


    const subjectCodeHandler = (data) => { }
    const scheduleCodeHandler = (data) => { }
    const selectHandleChange = name => event => {
        if (name == 'PaymentModeId') {
            onChangePaymentMode(event)

        } else if (name == 'YearLevelId') {
            onChangeStudentYearLevel(event)
        }
        setData({ ...data, [name]: event });

    }

    const onUpdateEnrollment = async (id, values) => {
        await updateEnrollment(id, values)
            .then(async response => {
                if (studentDiscount !== 0 && studentDiscount.length > 0) {
                    let tuitionFee = studentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
                    let registrationFee = studentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
                    let miscFee = studentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
                    let labFee = studentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
                    let otherFee = studentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
                    const updatedStudentDiscount = studentDiscount.map((obj, index) => ({
                        ...obj,
                        enrollmentId: studentAccount.EnrollmentId,
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: labFee.TotalGAmount,
                        enrollmentDiscountId: index === 0 ? 1 : 0,
                        createdBy: auth.data.Username
                    }));
                    await addUpdateEnrollmentDiscount(updatedStudentDiscount)
                }

            })
            .catch(error => {
                alert('enrollment did not update')
            })
    }


    const onAddUpdateStudentEnrollmentSubject = async (val) => {
        await addUpdateStudentEnrollmentSubject(val)
            .then(res => {

            })
            .catch(e => {

            })
    }

    const onSubmit = () => {
        setIsSubmitting(true)
        let enrollmentValues = {
            assessmentTemplateId: data.AssessmentTemplateId,
            studentId: studentAccount.StudentId,
            // assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            enrollmentStatusId: 'PV',
            paymentModeId: data.PaymentModeId !== null ? data.PaymentModeId : studentAccount.PaymentModeId,
            schoolYear: studentAccount.SchoolYear,
            semesterId: studentAccount.SemesterId,
            courseId: studentAccount.CourseId,
            yearLevelId: studentYearLevel

        }

        if (studentAccount.isIrregular === true) {
            enrollmentValues = { ...enrollmentValues, isIrregular: 1, classSectionId: null }
        } else {
            enrollmentValues = { ...enrollmentValues, isIrregular: 0 }

        }

        //save enrollment
        onUpdateEnrollment(studentAccount.EnrollmentId, enrollmentValues)
            .then(res => {
                sendMail({
                    isPaymentValidated: 1,
                    emailAddress: studentAccount.EmailAddress,
                    course: studentAccount.CourseDesc,
                    schoolYear: studentAccount.StudentSY
                }).then(email => {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'success',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Validated'
                    });

                    history.push('/admin/accounting')
                    setIsSubmitting(false)
                    setTimeout(() => {
                        history.push(`${accountTypes.MORE_LINK}${id}`)
                    }, 200);
                })

            })




    }

    const onRecompute = async (message) => {

        let enrollmentValues = {
            assessmentTemplateId: studentFees[0].AssessmentTemplateId,
            studentId: studentAccount.StudentId,
            assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            // enrollmentStatusId: 'PV',
            paymentModeId: studentAccount.PaymentModeId,
            schoolYear: studentAccount.SchoolYear,
            semesterId: studentAccount.SemesterId,
            courseId: studentAccount.CourseId,

        }

        //save enrollment

        await updateEnrollment(studentAccount.EnrollmentId, enrollmentValues)
            .then(async response => {
                if (studentDiscount !== 0 && studentDiscount.length > 0) {
                    let tuitionFee = studentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
                    let registrationFee = studentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
                    let miscFee = studentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
                    let labFee = studentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
                    let otherFee = studentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
                    const updatedStudentDiscount = studentDiscount.map((obj, index) => ({
                        ...obj,
                        enrollmentId: studentAccount.EnrollmentId,
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: labFee.TotalGAmount,
                        enrollmentDiscountId: index === 0 ? 1 : 0,
                        createdBy: auth.data.Username
                    }));
                    await addUpdateEnrollmentDiscount(updatedStudentDiscount)
                }

            })
            .catch(error => {
                //  alert('enrollment did not update')
            })


        dispatch({
            type: componentTypes.SHOW_SNACKBAR,
            snackBarStatus: 'success',
            snackBarDuration: 5000,
            snackBarMessage: message
        });

    }

    const onValidateAddDrop = async () => {

        if (isPaid == false) {
            if (isAddDropValidated == true) {
                for (let i = 0; i < studentAddDropSubjects.length; i++) {
                    onAddUpdateStudentEnrollmentSubject({
                        studentEnrollmentSubjectId: studentAddDropSubjects[i].StudentEnrollmentSubjectsId,
                        studentId: studentAddDropSubjects[i].StudentId,
                        subjectId: studentAddDropSubjects[i].SubjectId,
                        semesterId: studentAddDropSubjects[i].SemesterId,
                        schoolYear: studentAddDropSubjects[i].SchoolYear,
                        subjectScheduleDetailId: studentAddDropSubjects[i].SubjectScheduleDetailId,
                        createdBy: auth.data.Username,
                        subjectStatusId: studentAddDropSubjects[i].SubjectStatusId,
                        enrollmentStatus: studentAddDropSubjects[i].EnrollmentStatus,
                        isPending: 1,
                        isValidated: 1

                    })
                }
            } else {
                for (let i = 0; i < studentAddDropSubjects.length; i++) {
                    onAddUpdateStudentEnrollmentSubject({
                        studentEnrollmentSubjectId: studentAddDropSubjects[i].StudentEnrollmentSubjectsId,
                        studentId: studentAddDropSubjects[i].StudentId,
                        subjectId: studentAddDropSubjects[i].SubjectId,
                        semesterId: studentAddDropSubjects[i].SemesterId,
                        schoolYear: studentAddDropSubjects[i].SchoolYear,
                        subjectScheduleDetailId: studentAddDropSubjects[i].SubjectScheduleDetailId,
                        createdBy: auth.data.Username,
                        subjectStatusId: studentAddDropSubjects[i].SubjectStatusId,
                        enrollmentStatus: studentAddDropSubjects[i].SubjectStatusId == 1 ? 1 : studentAddDropSubjects[i].SubjectStatusId == 2 ? 0 : studentAddDropSubjects[i].SubjectStatusId == 3 ? 1 : 0,
                        isPending: 0,
                        isValidated: 1

                    })
                }
            }
        } else {
            for (let i = 0; i < studentAddDropSubjects.length; i++) {
                onAddUpdateStudentEnrollmentSubject({
                    studentEnrollmentSubjectId: studentAddDropSubjects[i].StudentEnrollmentSubjectsId,
                    studentId: studentAddDropSubjects[i].StudentId,
                    subjectId: studentAddDropSubjects[i].SubjectId,
                    semesterId: studentAddDropSubjects[i].SemesterId,
                    schoolYear: studentAddDropSubjects[i].SchoolYear,
                    subjectScheduleDetailId: studentAddDropSubjects[i].SubjectScheduleDetailId,
                    createdBy: auth.data.Username,
                    subjectStatusId: studentAddDropSubjects[i].SubjectStatusId,
                    enrollmentStatus: 1,
                    isPending: 0,
                    isValidated: 1

                })
            }

        }




        dispatch({
            type: componentTypes.SHOW_SNACKBAR,
            snackBarStatus: 'success',
            snackBarDuration: 5000,
            snackBarMessage: 'Student Add Subjects Validated'
        });
        onRecompute('Student Assessment Recomputed')
        history.push('/admin/accounting')
        setTimeout(() => {
            history.push(`${accountTypes.MORE_LINK}${id}`)
        }, 200);


    }

    const onEnrollStudentDialog = () => {
        setShowDialog({ id: null, record: '', status: true })

    }

    const onEnrollStudent = () => {
        let enrollmentValues = {
            assessmentTemplateId: data.AssessmentTemplateId,
            studentId: studentAccount.StudentId,
            // assessmentDate: dateTime,
            enrollmentDate: enrollmentDate == null ? dateTime : enrollmentDate,
            enrollmentStatusId: 'E',
            paymentModeId: data.PaymentModeId !== null ? data.PaymentModeId : studentAccount.PaymentModeId,
            schoolYear: studentAccount.SchoolYear,
            semesterId: studentAccount.SemesterId,
            courseId: studentAccount.CourseId,
            yearLevelId: studentYearLevel

        }

        if (studentAccount.isIrregular === true) {
            enrollmentValues = { ...enrollmentValues, isIrregular: 1, classSectionId: null }
        } else {
            enrollmentValues = { ...enrollmentValues, isIrregular: 0 }

        }


        //save enrollment
        onUpdateEnrollment(studentAccount.EnrollmentId, enrollmentValues)
            .then(res => {

                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'success',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Enrolled'
                });

                history.push('/admin/accounting')
                setTimeout(() => {
                    history.push(`${accountTypes.MORE_LINK}${id}`)
                }, 200);
            })

    }


    const onRevalidate = () => {
        let enrollmentValues = {
            assessmentTemplateId: data.AssessmentTemplateId,
            studentId: studentAccount.StudentId,
            // assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            enrollmentStatusId: 'E',
            paymentModeId: data.PaymentModeId,
            schoolYear: studentAccount.SchoolYear,
            semesterId: studentAccount.SemesterId,
            courseId: studentAccount.CourseId,
            yearLevelId: studentYearLevel

        }

        if (studentAccount.isIrregular === true) {
            enrollmentValues = { ...enrollmentValues, isIrregular: 1, classSectionId: null }
        } else {
            enrollmentValues = { ...enrollmentValues, isIrregular: 0 }

        }


        //save enrollment
        onUpdateEnrollment(studentAccount.EnrollmentId, enrollmentValues)
            .then(res => {



                history.push('/admin/accounting')
                setTimeout(() => {
                    history.push(`${accountTypes.MORE_LINK}${id}`)
                }, 200);
            })

    }

    const onSavePaymentMode = () => {
        let enrollmentValues = {
            assessmentTemplateId: data.AssessmentTemplateId,
            studentId: studentAccount.StudentId,
            // assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            enrollmentStatusId: 'E',
            paymentModeId: data.PaymentModeId,
            schoolYear: studentAccount.SchoolYear,
            semesterId: studentAccount.SemesterId,
            courseId: studentAccount.CourseId,
            yearLevelId: studentYearLevel

        }

        if (studentAccount.isIrregular === true) {
            enrollmentValues = { ...enrollmentValues, isIrregular: 1, classSectionId: null }
        } else {
            enrollmentValues = { ...enrollmentValues, isIrregular: 0 }

        }


        //save enrollment
        onUpdateEnrollment(studentAccount.EnrollmentId, enrollmentValues)
            .then(res => {



                history.push('/admin/accounting')
                setTimeout(() => {
                    history.push(`${accountTypes.MORE_LINK}${id}`)
                }, 200);
            })

    }


    const onValidate = () => {
        if (parseFloat(totalFee) + parseFloat(totalInterest) - parseFloat(studentDiscount.length > 0 ? totalDiscount : 0.00) <= 0) {
            setShowDialog({ id: null, record: '', status: true })

        } else {
            console.log(parseFloat(totalFee) + parseFloat(totalInterest) - parseFloat(studentDiscount.length > 0 ? totalDiscount : 0.00))
            // alert('test2')
            if (studentTotalBackBalance > 0) {
                setIsOpenWithBalance(true)
            } else {
                onSubmit()

            }
        }
    }

    useEffect(() => {
        if (!assessment || assessment.length == 0)
            return
        setData({ ...data, AssessmentTemplateId: assessment[0].AssessmentTemplateId });

    }, [assessment])


    const imSelectDropdownPaymentMode = () => {
        return <IMSelect
            data={paymentModeSelect}
            onHandleChange={selectHandleChange('PaymentModeId')}
            refClassContainer=""
            name="PaymentModeId"
            isRequired={true}
            withLabel={true}
            label="Choose Payment Mode"
            placeHolder="Payment Mode"
            forwardRef={setValue}
            selectedId={data.PaymentModeId === null ? '' : data.PaymentModeId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />

    }

    const imSelectDropdownYearLevel = () => {
        return <IMSelect
            data={yearLevelSelect}
            onHandleChange={selectHandleChange('YearLevelId')}
            refClassContainer=""
            name="YearLevelId"
            isRequired={true}
            withLabel={true}
            label="Choose Year Level"
            placeHolder="Year Level"
            forwardRef={setValue}
            selectedId={data.YearLevelId === null ? studentYearLevel !== null ? studentYearLevel : '' : data.YearLevelId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />

    }

    const columns = [
        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'UnitHour', label: 'Units', align: 'center', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SubjectType', label: 'Type', align: 'center', withSorting: false, hidden: false, disablePadding: false },
    ]


    const columnsAddDrop = [

        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TotalUnit', label: 'Units', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectStatusId', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'Action', type: 'action', label: 'Select Subject', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }


    ]

    const childColumns = [
        { id: 'RowCnt', type: 'index', label: '', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'TimeStartDesc', type: 'time', label: 'Time Start', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TimeEndDesc', type: 'time', label: 'Time End', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', type: 'string', label: 'Day Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Room', type: 'string', label: 'Room', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    ]

    const columnsPayment = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeCode', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FinalAmount', type: 'Amount', label: 'Amount', align: 'left', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const columnsOtherFee = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeCode', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Fee', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    ]

    const columnsAddOns = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeDesc', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Amount', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    ]

    const columnsDiscount = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Scholarship', label: 'Scholarship', align: 'right', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DiscountAmount', label: 'Discount Fee', align: 'right', withSorting: true, hidden: false, disablePadding: false, format: true },
    ]

    const tableProps = {
        recordId: 'SubjectScheduleDetailId',
        recordIdMore: 'SubjectScheduleDetailListId',
        sortField: '',
        columns: columns,
        childColumns: childColumns,
        rows: studentSubjects,
        totalRecords: studentSubjects.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: true,
        tableType: 'child',
        // parentId: id,
        childArray: 'schedules',
        tableTitle: 'List of Subjects',

    }

    const tablePropsAddDrop = {
        recordId: 'SubjectScheduleDetailId',
        recordIdMore: 'SubjectScheduleDetailListId',
        sortField: '',
        columns: columnsAddDrop,
        childColumns: childColumns,
        rows: studentAddDropSubjects !== null ? studentAddDropSubjects : [],
        totalRecords: studentSubjects.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: true,
        tableType: 'child',
        // parentId: id,
        childArray: 'schedules',
        tableTitle: 'List of Subjects',

    }

    const tablePropsFeeGroup = {
        recordId: 'FeeId',
        sortField: '',
        columns: columnsPayment,
        rows: studentFees,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Fees',

    }


    const tablePropsOtherFeeGroup = {
        recordId: 'FeeId',
        sortField: '',
        columns: columnsOtherFee,
        rows: studentOtherFees,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Other Fee Details',

    }

    const tablePropsAddOnsGroup = {
        recordId: 'PaymentAddOnsId',
        sortField: '',
        columns: columnsAddOns,
        rows: studentAddOns,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Add Ons',

    }


    const tablePropsScholarships = {
        recordId: 'DiscountId',
        sortField: '',
        columns: columnsDiscount,
        rows: studentDiscount,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Scholarships',

    }

    const viewAssessment = () => {
        history.push(`/admin/student/create-assessment/${studentAccount.EnrollmentId}`)
    }

    console.log(data.AssessmentTemplateId)
    console.log(paymentSchedule)
    console.log(studentDiscount)
    console.log(data)
    return (
        <>



            {showDialog.status && <IMDialogSubmition
                title={`Question`}
                content={<span>Do you want to Enroll this Student? (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
                handleSubmit={onEnrollStudent}
                onShowDialog={setShowDialog}
                isOpen={true}
            />}

            <BackAccountNotification
                isOpen={isOpenWithBalance}
                handleClose={() => { setIsOpenWithBalance(false); }}
                handleProceed={onSubmit}
                handleNotifyStudent={() => { }}
                title={'Previous Balance Found'}
                amount={studentTotalBackBalance}
                sy={`SY ${studentAccount.LastEnrolledSchoolYear}/${studentAccount.LastEnrolledSemesterId}`}
            />

            < div className="row" >

                <div className="col-12 col-xl-6 mt-5">

                    <div className="card p-5">
                        <div className="card-header">
                            <h3 className="display-4 ">{studentAccount.StudentName}</h3>
                            <p className="my-0 lead ">{studentAccount.CourseDesc}</p>
                            <p className="my-0 lead ">{studentAccount.SY}</p>
                            <p className="my-0 lead">{studentAccount.SemesterDesc}</p>
                            <p className="my-0 lead ">{studentAccount.StudentNo}</p>

                            <LoadingOnFetching />
                            {studentAccount.IsAcceptGrant == 1 &&
                                <p style={{ borderRadius: '5px' }} className="p-2 bg-secondary"> <i className="fa fa-info text-primary"></i> &nbsp; This Student Accepted Grant Disclosure Agreement</p>
                            }
                            {studentAccount.EnrollmentStatusId === 'E' ?
                                <>
                                    <div
                                        style={{ 'backgroundColor': 'rgba(34, 255, 145, 0.2)' }}
                                        className="alert mt-5" role="alert">
                                        This Student is Enrolled
                                    </div>
                                </>

                                :
                                <>
                                    {studentAccount.EnrollmentStatusId === 'PV' &&
                                        <>
                                            <div
                                                style={{ 'backgroundColor': 'rgba(34, 255, 145, 0.2)' }}
                                                className="alert mt-5"
                                                role="alert">
                                                {studentAccount.EnrollmentStatusId === 'PV' && "Payment Validated"}

                                            </div>

                                        </>

                                    }
                                </>
                            }


                            {studentAccount.IsIrregular == 1 ?
                                <>
                                    <div className="input-group mt-5">
                                        <div className="input-group-prepend ">
                                            <span className="input-group-text" id="basic-addon1">Status: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;</span>
                                        </div>
                                        <input readOnly value="Irregular Student" type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                    <div className="mt-5">{imSelectDropdownYearLevel()}</div>

                                </>

                                :
                                <>
                                    <div className="input-group mt-5">
                                        <div className="input-group-prepend ">
                                            <span className="input-group-text" id="basic-addon1">Class Section</span>
                                        </div>
                                        <input readOnly value={studentSubjects[0].ClassSection} type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />
                                    </div>
                                </>


                            }

                        </div>

                        <div className="card  border-0 container mb-5">
                            <h4 className="mt-5">Student Subjects</h4>

                            < IMCollapsibleTableStudent
                                tableProps={tableProps}
                                subjectCodeHandler={subjectCodeHandler}
                                scheduleCodeHandler={scheduleCodeHandler}
                                selectedSub={subjectId}
                                selectedSubCode={subjectCode}
                                isProcessed={true}

                            />



                            {paymentSchedule !== null && paymentSchedule.length > 0 &&
                                <>
                                    <h4 className="mt-5">Payment Schedule</h4>

                                    <TableContainer className="mt-5">
                                        <Table className={classes.table} size="small">

                                            <TableHead>
                                                <TableRow>
                                                    <TableCell>Period</TableCell>
                                                    <TableCell align="center">Date</TableCell>
                                                    <TableCell align="center">Amount to Pay</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {paymentSchedule.map((result) => {
                                                    const { PaymentScheduleId, Period, Date, Amount } = result;
                                                    // rowCnt += 1;

                                                    // let isChecked = studentExistingPaymentSchedule.find(data => data.PaymentScheduleId === PaymentScheduleId);
                                                    // console.log(isChecked);
                                                    // console.log(PaymentScheduleId);
                                                    // console.log(isChecked?.PaymentScheduleId === PaymentScheduleId);
                                                    // console.log(isChecked?.PaymentScheduleId + '__' + PaymentScheduleId);

                                                    return (
                                                        <TableRow key={PaymentScheduleId}>

                                                            <TableCell component="th" scope="row">{Period}</TableCell>
                                                            <TableCell align="center">{moment(Date).format('YYYY-MM-DD')}</TableCell>
                                                            <TableCell align="center" component="th" scope="row">{parseFloat(Amount).toFixed(2) <= 0.00 ? 0.00 : parseFloat(Amount).toFixed(2)}</TableCell>

                                                        </TableRow>
                                                    );
                                                })}
                                                <TableRow>

                                                    <TableCell component="th" scope="row">Other Fee</TableCell>
                                                    <TableCell align="center">{moment(paymentSchedule[paymentSchedule.length - 1].Date).format('YYYY-MM-DD')}</TableCell>
                                                    <TableCell align="center" component="th" scope="row">{numberWithCommas(totalOtherFee)}</TableCell>

                                                </TableRow>
                                            </TableBody>
                                        </Table>
                                        <div className="mt-0 alert alert-secondary p-3 row justify-content-between">
                                            <h6 className="col">Total Payment: ₱{numberWithCommas(parseFloat(totalPayment).toFixed(2))}</h6>
                                        </div>


                                    </TableContainer>
                                </>

                            }




                        </div>


                    </div>

                    {/* {enrollment[0].EnrollmentStatusId !== 'DR' &&
                                <div className="card p-5 mt-5">
                                    <section className="card-header row p-1">
                                        <h4 className=" text-dark p-5  col-6">Payment Schedule</h4>

                                    </section>


                                    {studentPaymentSchedule !== undefined && studentPaymentSchedule.length > 0 &&
                                        < IMTablePaymentSchedule
                                            rows={paymentSchedule}
                                            isFullPayment={assessmentTemplate[0].PaymentModeId === 2}
                                            initialFee={assessmentTemplate[0].InitialFee}
                                            studentPaymentSchedule={studentPaymentSchedule}
                                            totalFee={parseFloat(totalFee) + parseFloat(interestValues) - parseFloat(totalDiscount)}
                                        />
                                    }

                                </div>

                            } */}
                    {(studentAccount.EnrollmentStatusId === "A" || studentAccount.EnrollmentStatusId === "PV" || studentAccount.EnrollmentStatusId === "E") && studentAddDropSubjects !== null && studentAddDropSubjects.length > 0 &&
                        <div className="card mt-5 border-0 container mb-5 p-5">
                            <section className="container">
                                <h4>Add and Drop Subjects</h4>

                                <IMCollapsibleTableStudentAAddDropSubjects
                                    tableProps={tablePropsAddDrop}
                                    subjectCodeHandler={() => { }}
                                    scheduleCodeHandler={() => { }}
                                    selectedSub={studentAddDropSelectedSub}
                                    selectedSubCode={[]}
                                    isProcessed={true}
                                    type="assessment"

                                />



                            </section>

                        </div>

                    }



                </div>

                <div className="col-12 col-xl-6 mt-5">
                    <div className="card p-5  ">
                        <h4 className="card-header  text-dark p-5 ">Payment Selection</h4>

                        {/* {studentAccount.EnrollmentStatusId === "E" || studentAccount.EnrollmentStatusId === "PV" ?
                            <div className="input-group container mt-5 ">
                                <div className="input-group-prepend ">
                                    <span className="input-group-text" id="basic-addon1">Payment Mode</span>
                                </div>
                                {studentAccount.PaymentModeId !== null ?
                                    <input readOnly value={studentAccount.PaymentModeDesc} type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />
                                    :
                                    <input readOnly value={studentAccount.AssPaymentModeDesc} type="text" className="form-control" aria-label="Username" aria-describedby="basic-addon1" />


                                }
                            </div>
                            :  */}
                        <div className='my-5 container'>{imSelectDropdownPaymentMode()}</div>
                        {/* } */}

                        {assessment == null ?
                            <div className="input-group container mt-5 ">
                                <div className="input-group-prepend ">
                                    <span className="input-group-text" id="basic-addon1">Assessment Template</span>
                                </div>
                                <input readOnly value={studentAccount.AssessmentName} type="text" className="form-control" placeholder="No Assessment of Fee Found" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>

                            :

                            <div className="input-group container mt-5 ">
                                <div className="input-group-prepend ">
                                    <span className="input-group-text" id="basic-addon1">Assessment Template</span>
                                </div>
                                <input readOnly value={assessment.length > 0 ? assessment[0].ReferenceName : ''} type="text" className="form-control" placeholder="No Assessment of Fee Found" aria-label="Username" aria-describedby="basic-addon1" />
                            </div>


                        }




                        <div className="card  border-0 container mb-5">

                            <IMTableStudentAssessment
                                tableProps={tablePropsFeeGroup}
                            />
                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5">₱{numberWithCommas(totalAssessmentFee)}</h6> </div>


                        </div>
                        <div className="card  border-0 container mb-5">
                            <IMTableStudentAssessment
                                tableProps={tablePropsOtherFeeGroup}
                            />
                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5">₱{numberWithCommas(totalOtherFee)}</h6> </div>

                            {/* <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right mr-3">Total: 1000</h6> </div> */}
                        </div>
                        {studentAddOns !== null && studentAddOns !== undefined && studentAddOns.length > 0
                            &&

                            <div className="card  border-0 container mb-5">
                                <IMTableStudentAssessment
                                    tableProps={tablePropsAddOnsGroup}
                                />
                                <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5">₱{numberWithCommas(totalAddOns)}</h6> </div>

                                {/* <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right mr-3">Total: 1000</h6> </div> */}
                            </div>
                        }


                        {studentDiscount.length > 0 !== undefined ? <h4 className="card-header  text-dark p-5 ">Student Discount</h4> : ''}
                        <div className="card  border-0 container mb-5">
                            {studentDiscount.length > 0 ? <IMTableStudentAssessment tableProps={tablePropsScholarships} />
                                : ''}
                        </div>
                        <h4 className="card-header  text-dark p-5 ">Payment Summary</h4>

                        <div className="container mt-5">

                            <div className="row">
                                <div className="col">
                                    <p className="lead">Total Fee: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5"> ₱{numberWithCommas(parseFloat(totalFee))}</h6>
                                </div>
                            </div>


                            <div className="row">
                                <div className="col">
                                    <p className="lead">Interest: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5">₱ {numberWithCommas(parseFloat(totalInterest))}</h6>
                                </div>
                            </div>




                            <div className="row">
                                <div className="col">
                                    <p className="lead">Tuition Fee Discounts: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5"> - ₱{studentDiscount.length > 0 ? numberWithCommas(parseFloat(totalDiscount)) : 0}</h6>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col">
                                    <p className="lead">Total Amount: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5">
                                        ₱{parseFloat(totalFee) + parseFloat(totalInterest) - parseFloat(studentDiscount.length > 0 ? totalDiscount : 0.00) < 0.00 ? 0.00 :
                                            numberWithCommas((parseFloat(totalFee) + parseFloat(totalInterest) - parseFloat(studentDiscount.length > 0 ? totalDiscount : 0.00)).toFixed(2))
                                        }

                                    </h6>
                                </div>
                            </div>



                            {studentAccount.EnrollmentStatusId == 'A' &&
                                <>
                                    {data.PaymentModeId != null ?
                                        <>
                                            {isSubmitting == false ?

                                                <button onClick={() => {
                                                    onValidate()
                                                }} className="btn btn-success">Verify Fees</button>
                                                :
                                                <button class="btn btn-secondary" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span> &nbsp;
                                                    Loading, Please wait...
                                                </button>
                                            }
                                            <button className="btn btn-link text-left" onClick={() => viewAssessment()}>Print Assessment</button>
                                            {studentAccount.IsAcceptGrant == 1 &&
                                                <GrantDisclosureAgreement
                                                    student={studentAccount}
                                                    totalScholarshipDiscount={numberWithCommas(totalScholarshipDiscount)}
                                                />
                                            }
                                        </>

                                        :
                                        <>
                                            <button className="btn btn-link text-left" onClick={() => viewAssessment()}>Print Assessment</button>

                                            <button disabled className="btn btn-secondary">Verify Fees</button>

                                        </>



                                    }

                                </>

                            }

                            {(studentAccount.EnrollmentStatusId == 'PV') &&
                                <>


                                    <button onClick={() => { onEnrollStudentDialog() }}
                                        className="btn btn-success mr-5 mt-5">Enroll Student</button>



                                    {/* <button onClick={() => { onRecompute('Student Assessment Recomputed') }}
                                        className="btn btn-secondary mt-5">Recompute</button> */}
                                </>

                            }
                            {(studentAccount.EnrollmentStatusId == 'PV' || studentAccount.EnrollmentStatusId == 'E') &&
                                <>
                                    {/* <button onClick={() => { onRevalidate() }}
                                        className="btn btn-secondary mr-5 mt-5">Revalidate</button> */}
                                    {data.PaymentModeId != studentAccount.PaymentModeId &&
                                        <button onClick={() => { onSavePaymentMode() }}
                                            className="btn btn-success mr-5 mt-5">Save Payment Mode</button>
                                    }

                                    {studentAccount.IsAcceptGrant == 1 &&
                                        <GrantDisclosureAgreement
                                            student={studentAccount}
                                            totalScholarshipDiscount={numberWithCommas(totalScholarshipDiscount)}
                                        />
                                    }

                                </>

                            }

                            {studentAccount.EnrollmentStatusId == 'E' && studentAddDropSubjects !== null &&
                                studentAddDropSubjects.length > 0 && isValidated == false
                                &&

                                <button
                                    onClick={() => { onValidateAddDrop() }}
                                    className="btn btn-success">Validate Add / Drop Subject</button>


                            }


                        </div>

                    </div>
                </div>



            </div>

        </>



    )



}