export const referenceTypes = {
    SELECTED_COMPUTATION_TYPE: 'SELECTED-COMPUTATION-TYPE',
    GET_COMPUTATION_TYPES: 'GET-COMPUTATION-TYPES',
    GET_COMPUTATION_TYPE: 'GET-COMPUTATION-TYPE-BY-ID',
    CREATE: 'CREATE-COMPUTATION-TYPE',
    UPDATE: 'UPDATE-COMPUTATION-TYPE',
    DELETE: 'DELETE-COMPUTATION-TYPE',
    LIST_LINK: '/admin/references/computation-types',
    CREATE_LINK: '/admin/references/create-computation-types/',
    UPDATE_LINK: '/admin/references/update-computation-types/',
    DELETE_LINK: '/admin/references/delete-computation-types/'
}