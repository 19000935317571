import React, { useState, useEffect } from 'react';
import {
  getGradeSubmissionStatus, getGradeSubmissionStatusByGradingPeriod
} from './__hooks__';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import './__hooks__/styles.css'
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import moment from "moment";

export default function PrintGradeSubmissionMonitoring(props){
  const { state: { fees, auth, semesters }, dispatch } = useAppContext();
  const [results, setResults] = useState([]);
  const [onAlert, setOnAlert] = useState(false);
  const [data, setData] = useState({ 
          schoolYear: localStorage.getItem("gsm-school-year"),
          semesterId: localStorage.getItem("gsm-semester-id"),
          instituteId: localStorage.getItem("gsm-institute-id"),
          gradingPeriodId: localStorage.getItem("gsm-gradingPeriod-id"),
          schoolYearDisplay: localStorage.getItem("gsm-schoolYearDisplay"),
          semesterDisplay: localStorage.getItem("gsm-semesterDisplay"),
          gradingPeriodDisplay: localStorage.getItem("gsm-gradingPeriodDisplay"),
          hasIG: localStorage.getItem("gsm-has-ig")
  });

  //console.log("PROPS: ", props.location.state.data);
  const currDate = moment().format('YYYY-MM-DD');
  let newData = [];
  let currUser = auth.data.Username.split('@')[0];
  let ctr = 0;

  useEffect(() => {
    if(props.location.state.data !== undefined) {
      newData = props.location.state.data;
      setData((prevData) => {
        return {...prevData, 
          schoolYear: newData.schoolYear,
          semesterId: newData.semesterId,
          instituteId: newData.instituteId,
          gradingPeriodId: newData.gradingPeriodId,
          schoolYearDisplay: newData.schoolYearDisplay,
          semesterDisplay: newData.semesterDisplay,
          gradingPeriodDisplay: newData.gradingPeriodDisplay,
          hasIG: newData.hasIG
        }
      });

    } else {
      setData((prevData) => {
        return {...prevData,
          schoolYear: localStorage.getItem("gsm-school-year"),
          semesterId: localStorage.getItem("gsm-semester-id"),
          instituteId: localStorage.getItem("gsm-institute-id"),
          gradingPeriodId: localStorage.getItem("gsm-gradingPeriod-id"),
          schoolYearDisplay: localStorage.getItem("gsm-schoolYearDisplay"),
          semesterDisplay: localStorage.getItem("gsm-semesterDisplay"),
          gradingPeriodDisplay: localStorage.getItem("gsm-gradingPeriodDisplay"),
          hasIG: localStorage.getItem("gsm-has-ig")
        }
      });
    }

    if((data.schoolYear === null || data.schoolYear == '') || (data.semesterId === null || data.semesterId == '')){
      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "No data available." });
    } else {
      searchForGrades();
    }
  }, []);

  const searchForGrades = async () => {

      let values = {
        schoolYear: data.schoolYear,
        semesterId: data.semesterId,
        gradingPeriodId: data.gradingPeriodId,
        instituteId: data.instituteId,
        hasIG: data.hasIG
      };

      if(data.gradingPeriodId == 0) {
        await getGradeSubmissionStatus(values)
        .then(result => {
            if (result.success) {
                if(result.success){
                    if(result.data.length > 0){
                      setResults(result.data);
                    } else {
                      setOnAlert(true); 
                    }
                } else {
                    setOnAlert(true);  
                }          
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
            }
        })
        .catch(error => {
          if (error.response.status === 401) {
            //history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        });
      } else {
        await getGradeSubmissionStatusByGradingPeriod(values)
        .then(result => {
            if (result.success) {
                if(result.success){
                    if(result.data.length > 0){
                      setResults(result.data);
                    } else {
                      setOnAlert(true); 
                    }
                } else {
                    setOnAlert(true);  
                }          
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
            }
        })
        .catch(error => {
          if (error.response.status === 401) {
            //history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        });
      }
    }

  return (
    <>
      {
        // This will be the table view when search is for all grading periods
        results.length > 0 && data.gradingPeriodId == 0 &&
          <>
            <br></br>
            <div id="" className="faith-header-print-only">
                <h2 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h2>
                {/* <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p> */}
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>GRADE SUBMISSION MONITORING</p>
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.schoolYearDisplay} - {data.semesterDisplay}</p>
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.gradingPeriodDisplay}</p>
            </div>
            
            <br></br>
            <br></br>

            <div id="" className="sog-print-detail">
                <p className="" style={{lineHeight:"1px"}}>{`Printed By: ${currUser}`}</p>
                <p className="" style={{lineHeight:"1px"}}>Print Date: {currDate}</p>
            </div>
            
            <br></br>

            <table className="class-record-style class-record-table-centered">
                <thead>  
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header" width="1%">#</th>
                        <th className="class-record-style-header" width="15%">FACULTY NAME</th>
                        <th className="class-record-style-header" width="5%">CLASS CODE </th>
                        <th className="class-record-style-header" width="5%">INSTITUTE </th>
                        <th className="class-record-style-header" width="5%">SUBJECT CODE</th>
                        <th className="class-record-style-header" width="10%">SECTION</th>
                        <th className="class-record-style-header" width="15%">DESCRIPTION</th>
                        <th className="class-record-style-header" width="3%">UNITS</th>
                        <th className="class-record-style-header" width="13%">PRELIM STATUS</th>
                        <th className="class-record-style-header" width="13%">MIDTERM STATUS</th>
                        <th className="class-record-style-header" width="13%">FINALS STATUS</th>
                    </tr>
                    
                </thead>
                  {results.map((row, i) => (
                    <tbody>
                      <tr key={i} >
                        <td className={'class-record-style gsm-td-padding-left'} style={{paddingLeft:"5px"}}>{`${i + 1}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.EmployeeName}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.ClassCode}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.Institute}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.SubjectCode}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.ClassSections}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.Description}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.Units}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.PostingStatusPrelim}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.PostingStatusMidterm}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.PostingStatusFinals}`}</td>
                      </tr>    
                    </tbody>
                   ))}   
            </table>
            
            <br></br>
            <br></br>
          </>  
      }

      {
        // This will be the table view when search is for a specific grading period
        results.length > 0 && data.gradingPeriodId != 0 &&
          <>
            <br></br>
            <div id="" className="faith-header-print-only">
                <h2 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h2>
                {/* <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p> */}
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>GRADE SUBMISSION MONITORING</p>
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.schoolYearDisplay} - {data.semesterDisplay}</p>
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.gradingPeriodDisplay}</p>
            </div>
            
            <br></br>
            <br></br>

            <div id="" className="sog-print-detail">
                <p className="" style={{lineHeight:"1px"}}>{`Printed By: ${currUser}`}</p>
                <p className="" style={{lineHeight:"1px"}}>Print Date: {currDate}</p>
            </div>
            
            <br></br>

            <table className="class-record-style class-record-table-centered">
                <thead>  
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header" width="1%">#</th>
                        <th className="class-record-style-header" width="11%">FACULTY NAME</th>
                        <th className="class-record-style-header" width="4%">CLASS CODE </th>
                        <th className="class-record-style-header" width="4%">INSTITUTE </th>
                        <th className="class-record-style-header" width="4%">SUBJECT CODE</th>
                        <th className="class-record-style-header" width="9%">SECTION</th>
                        <th className="class-record-style-header" width="11%">DESCRIPTION</th>
                        <th className="class-record-style-header" width="3%">UNITS</th>
                        <th className="class-record-style-header" width="10%">Date Submitted for Validation</th>
                        <th className="class-record-style-header" width="10%">Date Validated</th>
                        <th className="class-record-style-header" width="10%">Date Authenticated</th>
                        <th className="class-record-style-header" width="10%">Date Posted</th>
                        <th className="class-record-style-header" width="11%">Grade Posting Status</th>
                    </tr>
                    
                </thead>
                  {results.map((row, i) => (
                    <tbody>
                      <tr key={i} >
                        <td className={'class-record-style gsm-td-padding-left'} style={{paddingLeft:"5px"}}>{`${i + 1}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.EmployeeName}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.ClassCode}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.Institute}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.SubjectCode}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.ClassSections}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.Description}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.Units}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.DateSubmittedForValidation}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.DateValidated}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.DateAuthenticated}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.DatePosted}`}</td>
                        <td className={'class-record-style gsm-td-padding-left'}>{`${row.GradePostingStatus}`}</td>
                      </tr>    
                    </tbody>
                   ))}   
            </table>
            
            <br></br>
            <br></br>
          </>  
      }

      {results.length == 0 && !onAlert &&
        <Loading /> // "No Data Available"
      }

      { onAlert && 
        <>
          <div id="" className="faith-header-print-only">
              <h2 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h2>
              {/* <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p> */}
              <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>SUMMARY OF GRADES REPORT</p>
              <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.schoolYearDisplay} - {data.semesterDisplay}</p>
              {/* <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p> */}
          </div>

          <br></br>
          <br></br>

          <div id="" className="sog-print-detail">
              <p className="" style={{lineHeight:"1px"}}>{`Printed By: ${currUser}`}</p>
              <p className="" style={{lineHeight:"1px"}}>Print Date: {currDate}</p>
          </div>

          <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>No Data Available</p>
        </>
      }
    </>
  )
}