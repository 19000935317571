import instance from '../../../../../apis/local/systemAPI';

export const autoposting = async (values) => {
  const result = await instance.post(`api/settings/system-configuration/autoposting`, values);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getRegistrarConfigs = async () => {
  const result = await instance.post(`api/settings/system-configuration/getRegistrarConfigs`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


// END OF COURSE OUTLINE



