import React from 'react';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import Forms from './Forms';
import { updateSchoolEntry } from './__hooks__';

export default function Update(props) {
    const { state: { schools }, dispatch } = useAppContext();
    const { id } = props.match.params;
    console.log(props.match.params)
    if (schools.data.length === 0) {
        history.push(referenceTypes.LIST_LINK);
        return null;
    }


    const applicant = schools.data[id];
    console.log(applicant)
    console.log("this is applicant")
    const onSubmit = formValues => {
        const execute = async () => {
            await updateSchoolEntry(id, formValues)
                .then(response => {
                    if (response.success) {
                        dispatch({ type: referenceTypes.UPDATE, data: response.data, id: response.data.StudentInfoId });
                        history.push(referenceTypes.LIST_LINK);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();
    }

    return (
        <>
            <div className="kt-container  kt-grid__item kt-grid__item--fluid">
                <div className="row ac-minimum-height-container-350">
                    <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
                        <Forms onSubmit={onSubmit} doc={applicant} />
                    </div>
                </div>
            </div>
        </>
    );
}