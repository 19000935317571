export const referenceTypes = {
    SELECTED_STUDENT_CATEGORY: 'SELECTED-STUDENT-CATEGORY',
    GET_STUDENT_CATEGORIES: 'GET-STUDENT-CATEGORIES',
    GET_STUDENT_CATEGORIES_BY_FORM_TYPE: 'GET-STUDENT-CATEGORIES-BY-FORM-TYPE',
    GET_STUDENT_CATEGORY: 'GET-STUDENT-CATEGORY-BY-ID',
    CREATE: 'CREATE-STUDENT-CATEGORY',
    UPDATE: 'UPDATE-STUDENT-CATEGORY',
    DELETE: 'DELETE-STUDENT-CATEGORY',
    LIST_LINK: '/admin/references/student-categories',
    CREATE_LINK: '/admin/references/create-student-categories/',
    UPDATE_LINK: '/admin/references/update-student-categories/',
    DELETE_LINK: '/admin/references/delete-student-categories/'
}