import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    instituteDropdown, instructorDropdown, attendanceSheet, 
    attendanceDropdownChecker, attendanceSearchSubjects,
    attendanceSubjectSchedId, getSchoolYear, getSemester,
    getInstitute, getCustomCourseList, studentAutoComplete, 
    getSummaryOfGradesReport, getGradingPeriod, getGradeSubmissionStatusIGSelect
} from './__hooks__';
//import PrintAttendanceSheet from "./PrintAttendanceSheet";
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import moment from "moment";
import { Link } from 'react-router-dom';
import GradeSubmissionMonitoringExcelFormat from "./GradeSubmissionMonitoringExcelFormat";
import history from '../../../../history';
import SummaryOfGradesConfirmation from "../../../../../_metronic/layout/components/custom/modal/SummaryOfGradesConfirmation";

export default function GradeSubmissionMonitoring() {

    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const { register, handleSubmit, errors } = useForm();
    const [onAlert, setOnAlert] = useState(false);  
    const [instituteSelect, setInstituteSelect] = useState([]);
    const [instituteArray, setInstituteArray] = useState([])
    //const [courseSelect, setCourseSelect] = useState([]);
    const [schoolYearSelect, setSchoolYearSelect] = useState(null);
    const [semesterSelect, setSemesterSelect] = useState(null);
    const [gradingPeriodSelect, setGradingPeriodSelect] = useState([]);
    const [hasIGSelect, setHasIGSelect] = useState(null);
    const [schoolYearDisplay, setSchoolYearDisplay] = useState(null);
    const [gradingPeriodDisplay, setGradingPeriodDisplay] = useState(null);
    const [semesterDisplay, setSemesterDisplay] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [results, setResults] = useState([]);
    const [studentId, setStudentId] = useState(0);
    const [isForConfirmation, setIsForConfirmation] = useState(false);
    const [timeGenerated, setTimeGenerated] = useState(null);
    const [defaultValues, setDefaultValues] = useState({
        studentId: 0,
        studentName: '',
    });
    //const history = useHistory();
    const currDate = moment().format('YYYY-MM-DD');

    const onReset = async () => {
        //setAttendance([]);
        //setSubject('');
        //setSubjectSelect([]);
        setResults([]);
        setIsLoading(false);
        setOnAlert(false);
        //handleSubjectInputChange('');

        setDefaultValues((prevData) => {
            return {...prevData, 
                studentId: studentSelected.studentId, 
                studentName: studentSelected.studentName
            }
        });
    }

    const [studentSelected, setStudentSelected] = useState({
        studentId: 0,
        studentName: '',
    });

    const [filter, setFilter] = useState({ 
        schoolYear: auth.data.schoolyears.grading.SchoolYear, 
        semesterId: auth.data.schoolyears.grading.SemesterId,
        gradingPeriodId: 1,
        instituteId: null,
        hasIG: 1
    });

    const [args, setArgs] = useState({ 
        schoolYear: filter.schoolYear,
        semester: filter.semesterId,
        instituteId: (filter.instituteId === null || filter.instituteId === '') ? 90 : filter.instituteId,
        //courseId: null,
        gradingPeriodId: (filter.gradingPeriodId === null || filter.gradingPeriodId === "") ? 0 : filter.gradingPeriodId,
        //studentId: 0,
        //instituteArray: instituteArray,
        schoolYearDisplay: schoolYearDisplay,
        semesterDisplay: semesterDisplay,
        gradingPeriodDisplay: gradingPeriodDisplay,
        hasIG: (filter.hasIG === null || filter.hasIG === "") ? 1 : filter.hasIG
    });

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description");
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description");
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code');
        SelectCreator(getGradingPeriod, setGradingPeriodSelect, "GradingPeriodId", "Description");
        SelectCreator(getGradeSubmissionStatusIGSelect, setHasIGSelect, "SelectInstructionalGuideId", "Description");
    }, []);

    useEffect(() => {
        if(instituteSelect.length > 0){
            let arrIG = [];
            for(let i = 1; i < instituteSelect.length; i++){
                arrIG.push(instituteSelect[i].tableId);
            }
            setInstituteArray(arrIG);
        }
    }, [instituteSelect]);

    useEffect(() => {
        setArgs((prevData) => {
            return {...prevData, 
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: (filter.instituteId === null || filter.instituteId === '') ? 90 : filter.instituteId,
                courseId: (filter.courseId === null || filter.courseId == '') ? 0 : filter.courseId,
                gradingPeriodId: (filter.gradingPeriodId === null || filter.gradingPeriodId === "") ? 0 : filter.gradingPeriodId,
                studentId: studentId,
                instituteArray: instituteArray,
                schoolYearDisplay: schoolYearDisplay,
                semesterDisplay: semesterDisplay,
                gradingPeriodDisplay: gradingPeriodDisplay,
                hasIG: filter.hasIG
            }
        });
    }, [filter.schoolYear, filter.semesterId, filter.instituteId, filter.courseId, filter.gradingPeriodId, filter.hasIG,
        studentId, instituteArray, semesterDisplay, schoolYearDisplay, gradingPeriodDisplay]);

    useEffect(() => {
        localStorage.setItem("gsm-school-year",filter.schoolYear);
        localStorage.setItem("gsm-semester-id",filter.semesterId);
        localStorage.setItem("gsm-institute-id",(filter.instituteId === null || filter.instituteId === "") ? 90 : filter.instituteId);
        localStorage.setItem("gsm-course-id",(filter.courseId === null || filter.courseId === "") ? 0 : filter.courseId);
        localStorage.setItem("gsm-gradingPeriod-id",(filter.gradingPeriodId === null || filter.gradingPeriodId === "") ? 0 : filter.gradingPeriodId);
        localStorage.setItem("gsm-student-id",studentId);
        localStorage.setItem("gsm-institute-select",instituteArray);
        localStorage.setItem("gsm-schoolYearDisplay",schoolYearDisplay);
        localStorage.setItem("gsm-gradingPeriodDisplay",gradingPeriodDisplay);
        localStorage.setItem("gsm-semesterDisplay",semesterDisplay);
        localStorage.setItem("gsm-has-ig",(filter.hasIG === null || filter.hasIG === "") ? 1 : filter.hasIG);
    }, [filter.schoolYear, filter.semesterId, filter.instituteId, filter.courseId, filter.hasIG,
        filter.gradingPeriodId, studentId, instituteArray, semesterDisplay, schoolYearDisplay, gradingPeriodDisplay]);

    useEffect(() => {
        if(schoolYearSelect !== null && typeof(schoolYearSelect) !== "undefined" ) {
            for(let i = 0; i < schoolYearSelect.length; i++){
                if(filter.schoolYear == schoolYearSelect[i].tableId){
                    setSchoolYearDisplay(schoolYearSelect[i].code);
                    break;
                }
            }
        }

        if(semesterSelect !== null && typeof(semesterSelect) !== "undefined" ) {
            for(let i = 0; i < semesterSelect.length; i++){
                if(filter.semesterId == semesterSelect[i].tableId){
                    setSemesterDisplay(semesterSelect[i].code);
                    break;
                }
            }
        }

        if(gradingPeriodSelect !== null && typeof(gradingPeriodSelect) !== "undefined" ) {
            if(filter.gradingPeriodId === '' || filter.gradingPeriodId == 0){
                setGradingPeriodDisplay("Summary");
            } else {
                for(let i = 0; i < gradingPeriodSelect.length; i++){
                    if(filter.gradingPeriodId == gradingPeriodSelect[i].tableId){
                        setGradingPeriodDisplay(gradingPeriodSelect[i].code);
                        break;
                    }
                }
            }
        }

    }, [filter.schoolYear, filter.semesterId, filter.gradingPeriodId, schoolYearSelect, semesterSelect, gradingPeriodSelect]);

    // const onSearch = () => {
    //     searchForGrades();
    // }

    const handleStudentInputChange = async (input) => {
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    const handleGetStudent = async (data) => {
        
        //setStudent(null);
        setResults([]);
        setOnAlert(false);
        setStudentId(data.StudentId);

        if(studentList !== null){
            for(let i = 0; i < studentList.length; i++){
                if(studentList[i].StudentId == data.StudentId){
                    setStudentSelected((prevData) => {
                        return {...prevData, 
                            studentId: studentList[i].StudentId, 
                            studentName: studentList[i].StudentName
                        }
                    });
                    break;
                }
            }
        }
    }

    // const searchForGrades = async () => {

    //     if(filter.schoolYear == '' || filter.semesterId == ''){
    //         setOnAlert(true);
    //     } else {
    //         setIsLoading(true);
    //         let date = new Date();
    //         setTimeGenerated(date.toLocaleTimeString('en-US', { hour12: true }));
    //         setResults([]);
    //         setOnAlert(false);
    
    //         let values = {
    //             studentId: studentId === null ? 0 : studentId,
    //             schoolYear: filter.schoolYear,
    //             semesterId: filter.semesterId,
    //             instituteId: filter.instituteId === null ? 90 : filter.instituteId,
    //             courseId: filter.courseId === null ? 0 : filter.courseId,
    //             instituteSelect: instituteSelect
    //         };

    //         let displayNoRecordFound = true;
    
    //         // await getSummaryOfGradesReport(values)
    //         //     .then(result => {
    //         //         if (result.success) {
    //         //             if(result.data.length > 0)
    //         //                 if((filter.instituteId === null || filter.instituteId == 90) && studentId == 0){
    //         //                     for(let i = 0; i < result.data.length; i++){
    //         //                         console.log(result.data[i].length);
    //         //                         if(result.data[i].length > 0){
    //         //                             displayNoRecordFound = false;
    //         //                             break;
    //         //                         }
    //         //                     }
    //         //                 } else {
    //         //                     displayNoRecordFound = false;
    //         //                 }

    //         //                 if(displayNoRecordFound){
    //         //                     setOnAlert(true); 
    //         //                 } else {
    //         //                     setOnAlert(false);  
    //         //                     setResults(result.data);   
    //         //                 }
                            
    //         //                 setIsLoading(false);
    //         //             } else {
    //         //                 setOnAlert(true);  
    //         //                 setIsLoading(false);
    //         //             }          
    //         //         } else {
    //         //           dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
    //         //         }
    //         //     })
    //         //     .catch(error => {
    //         //       if (error.response.status === 401) {
    //         //         //history.push('/auth/login');
    //         //       } else {
    //         //         dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
    //         //       }
    //         //     });
    //     }
    // }

    const handleSearchClick = () => {
        if((filter.instituteId === null || filter.instituteId == "" || filter.instituteId == 90)){
                setIsForConfirmation(true);
        } else {
            history.push('/report/grade-submission-monitoring', { data: args });
        }
    };

    const afterConfirmation = () => {
        history.push('/report/grade-submission-monitoring', { data: args });
    };

    return (
        <>
            {
                results.length == 0 && !isLoading && schoolYearSelect && semesterSelect && instituteSelect && gradingPeriodSelect && hasIGSelect &&
                <>
                    <SummaryOfGradesConfirmation
                        isOpen={isForConfirmation}
                        handleConfirm={() => afterConfirmation()}
                        handleClose={() => setIsForConfirmation(false)}
                        title={'Grade Submission Monitoring'}
                        content={'The selected filters will list all available data. Would you like to proceed?'}
                    />

                    <div className='d-flex align-items-center'>
                        <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                            <header className='card-header p-5 bg-secondary'>
                                <h6 class="float-left m-0 mt-4 ml-5">Grade Submission Monitoring</h6>
                            </header>

                            <section className="card-body">
                                {onAlert == true && results.length == 0 &&
                                    <div className="alert alert-danger" role="alert">
                                        No Record Found
                                    </div>
                                }

                                {/* School Year Dropdown */}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <IMSelect
                                            data={schoolYearSelect}
                                            onHandleChange={filterChange('schoolYear')}
                                            refClassContainer=""
                                            name="schoolYear"
                                            isRequired={false}
                                            withLabel={true}
                                            label="School Year"
                                            placeHolder="Select School Year"
                                            forwardRef={() => { }}
                                            selectedId={filter.schoolYear}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            description={`School Year`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Semester Dropdown */}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <IMSelect
                                            data={semesterSelect}
                                            onHandleChange={filterChange('semesterId')}
                                            refClassContainer=""
                                            name="semesterId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Semester"
                                            placeHolder="Select Semester"
                                            forwardRef={() => { }}
                                            selectedId={filter.semesterId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            description={`Semester`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Grading Period */}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <IMSelect
                                            data={gradingPeriodSelect}
                                            onHandleChange={filterChange('gradingPeriodId')}
                                            refClassContainer=""
                                            name="gradingPeriod"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Grading Period"
                                            placeHolder="Select Grading Period"
                                            forwardRef={() => { }}
                                            selectedId={filter.gradingPeriodId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            description={`Grading Period`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[]}
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>
                                </div>

                                {/* Institutes */}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <IMSelect
                                            data={instituteSelect}
                                            onHandleChange={filterChange('instituteId')}
                                            refClassContainer=""
                                            name="instituteId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Search by Institute"
                                            placeHolder="Select Institute"
                                            forwardRef={() => { }}
                                            selectedId={filter.instituteId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            description={`Institutes`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                            isWithToolTip={true}
                                            toolTipText={'Optional; Leave blank to search "All" by default'}
                                        />
                                    </div>
                                </div>

                                {/* With or Without IG */}
                                <div className="form-row">
                                    <div className="form-group col">
                                        <IMSelect
                                            data={hasIGSelect}
                                            onHandleChange={filterChange('hasIG')}
                                            refClassContainer=""
                                            name="hasIG"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Instructional Guide"
                                            placeHolder="Select With or Without Instructional Guide"
                                            forwardRef={() => { }}
                                            selectedId={filter.hasIG}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            description={`Instructional Guide`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[]}
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                            isWithToolTip={true}
                                            toolTipText={'Optional; Leave blank to search "With Instructional Guide Only" by default'}
                                        />
                                    </div>
                                </div>

                                {/* Courses */}
                                {/* <div className="form-row">
                                    <div className="form-group col">
                                        <IMSelect
                                            data={courseSelect}
                                            onHandleChange={filterChange('courseId')}
                                            refClassContainer=""
                                            name="courseId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Search by Course"
                                            placeHolder="Select Course"
                                            forwardRef={() => { }}
                                            selectedId={filter.courseId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            description={`Courses`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                            isWithToolTip={true}
                                            toolTipText={'Optional; Leave blank to search "All" by default'}
                                        />
                                    </div>
                                </div> */}

                                {/* <div className="form-row">
                                    <div className="form-group col"> 
                                        <label htmlFor="studentSubjects" style={{marginRight:"1%"}}>Search by Student</label>
                                        <i class="fa fa-info-circle" title="Optional; Leave blank to search for all/available students"></i>
                                        <Autocomplete
                                            id="studentList"
                                            options={studentList}
                                            defaultValue={{StudentId: defaultValues.studentId, StudentName: defaultValues.studentName}}
                                            getOptionLabel={(option) => option.StudentName}
                                            getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    handleGetStudent(value)
                                                } else {                                         
                                                    setStudentId(0);
                                                    setResults([]);
                                                    setOnAlert(false);
                                                    setStudentSelected((prevData) => {
                                                         return {...prevData, 
                                                             studentId: null, 
                                                             studentName: '',
                                                         }
                                                     });
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Student Number/Name"
                                                    variant="outlined"
                                                    inputRef={register}
                                                    name="studentList"
                                                    onChange={
                                                        (e) => { handleStudentInputChange(e.target.value) }
                                                    }
                                                />
                                            )}
                                        /> 
                                    </div>
                                </div> */}
                                           
                                {/* <div className="form-row">
                                    <section className="col"> 
                                        <button onClick={() => { onSearch() }} className='btn btn-success btn-block'>Search</button>
                                    </section>
                                </div> */}

                                <div className='row'>
                                    <div className="form-group col">
                                        {
                                           (filter.semesterId != '' && filter.schoolYear != '') ?
                                               <>
                                                   <button className='btn btn-success btn-block' onClick={() => { handleSearchClick() }} > 
                                                       Search
                                                   </button> 
                                               </>
                                               
                                               :
                                           
                                               <>
                                                   <button className='btn btn-success btn-block' onClick={() => { setOnAlert(true) }} > 
                                                       Search
                                                   </button> 
                                               </>
                                           }
                                    </div>

                                    <div className="form-group col">
                                        {
                                            // totalRecords > 0 && 
                                            <GradeSubmissionMonitoringExcelFormat 
                                                fileName={'GradeSubmissionMonitoring'} 
                                                btnName={'Extract Report'} 
                                                schoolYear={filter.schoolYear}
                                                semesterId={filter.semesterId}
                                                instituteId={(filter.instituteId === null || filter.instituteId === '') ? 90 : filter.instituteId}
                                                gradingPeriodId={(filter.gradingPeriodId === null || filter.gradingPeriodId === '') ? 0 : filter.gradingPeriodId}
                                                //courseId={(filter.courseId === null || filter.courseId == '') ? 0 : filter.courseId}
                                                //studentId={studentId}
                                                //instituteArray={instituteArray}
                                                schoolYearDisplay={schoolYearDisplay}
                                                semesterDisplay={semesterDisplay}
                                                gradingPeriodDisplay={gradingPeriodDisplay}
                                                hasIG={(filter.hasIG === null || filter.hasIG === "") ? 1 : filter.hasIG}
                                            />
                                        }
                                    </div>
                                </div>
                            </section>
                        </main>
                    </div>
                </>
            }

            {/* {
                results.length > 0 && !isLoading &&
                
                    <div>
                        <div className="container" >
                            <main className="card border-0 p-5">
                                <header className="card-header"> 
                                    <div className='row'>
                                        <button id="btn-reset" style={{width:"11.65%"}} className="mt-3 btn btn-primary mr-3" onClick={() => { onReset() }} > 
                                            <i className="fa flaticon-reply"></i>&nbsp;Back
                                        </button>   
                                    </div>
                                </header>
                            </main>
                        </div>
                    </div>
            }

            {
                results.length == 0 && isLoading && 
                
                    <Loading />
            } */}
        </>
    )
}