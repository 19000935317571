import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControlLabel, Switch } from '@material-ui/core';
import { InstituteSelect, CourseSelect, YearLevelSelect, StudentCategorySelect } from '../';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    studentCategoryId: null,
    instituteId: null,
    courseId: null,
    yearLevelId: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'studentCategoryId' }, { required: true });
    register({ name: 'instituteId' }, { required: true });
    register({ name: 'courseId' }, { required: true });
    register({ name: 'yearLevelId' }, { required: true });
    console.log(data);
  }, [register, data])

  const switchHandleChange = name => event => {
    setSwitchState({ [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { classSection, description, studentCategoryId, instituteId, courseId, yearLevelId, classCapacity, isActive } = formValues;

    const convertedFormValues = {
      classSection: classSection,
      description: description,
      studentCategoryId: studentCategoryId,
      instituteId: instituteId,
      courseId: courseId,
      yearLevelId: yearLevelId,
      classCapacity: classCapacity,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Class Section <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="classSection"
                  defaultValue={props.doc.ClassSection === undefined ? "" : props.doc.ClassSection}
                  className={`form-control${errors.classSection === undefined ? "" : errors.classSection && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your class section.</span>
                {errors.classSection && errors.classSection.type === "required" && <div className="invalid-feedback">Class section is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <StudentCategorySelect
                refOnHandleChange={selectHandleChange('studentCategoryId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.StudentCategoryId === undefined ? "" : props.doc.StudentCategoryId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.studentCategoryId}
                refErrorMessage={errors.studentCategoryId && errors.studentCategoryId.type === "required" && 'Student category is required'}
              />
              <InstituteSelect
                refOnHandleChange={selectHandleChange('instituteId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.InstituteId === undefined ? "" : props.doc.InstituteId}
                studentCategory={data.studentCategoryId === null ? props.doc.StudentCategoryId : data.studentCategoryId}
                refWithFilter={true}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.instituteId}
                refErrorMessage={errors.instituteId && errors.instituteId.type === "required" && 'Institute is required'}
              />
              <CourseSelect
                refOnHandleChange={selectHandleChange('courseId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.CourseId === undefined ? "" : props.doc.CourseId}
                institute={data.instituteId === null ? props.doc.InstituteId : data.instituteId}
                refWithFilter={true}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.courseId}
                refErrorMessage={errors.courseId && errors.courseId.type === "required" && 'Course is required'}
              />
              <YearLevelSelect
                refOnHandleChange={selectHandleChange('yearLevelId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.YearLevelId === undefined ? "" : props.doc.YearLevelId}
                institute={data.instituteId === null ? props.doc.InstituteId : data.instituteId}
                refWithFilter={true}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.yearLevelId}
                refErrorMessage={errors.yearLevelId && errors.yearLevelId.type === "required" && 'Year level is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Class Capacity <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="classCapacity"
                  defaultValue={props.doc.ClassCapacity === undefined ? "" : props.doc.ClassCapacity}
                  className={`form-control${errors.classCapacity === undefined ? "" : errors.classCapacity && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your class capacity.</span>
                {errors.classCapacity && errors.classCapacity.type === "required" && <div className="invalid-feedback">Class capacity is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControlLabel
                  name="isActive"
                  inputRef={register()}
                  control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                  label="Active"
                />
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}