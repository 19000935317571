import instance from "../../../../../apis/local/systemAPI";



export const getStudentCredentials = async (formValues) => {
    const result = await instance.post("api/registrar/student/studentCredentials", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAccount = async (formValues) => {
    const result = await instance.post("api/registrar/student/applicantAccount", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentToRecieveDocs = async (formValues) => {
    const result = await instance.post("api/registrar/student/studentToRecieveDocs", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const sendMail = async (values) => {
    const result = await instance.post("api/nodemailer/send-mail", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createCredentialStudent = async (formValues) => {
    const result = await instance.post("api/registrar/student/studentCreateCredentials", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createStudentAccount = async (formValues) => {
    const result = await instance.post("api/registrar/student/studentAccountCreation", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const searchStudent = async (data) => {
    const result = await instance.post("api/enrollments/search", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentDocumentReport = async (data) => {
    const result = await instance.post("api/registrar/student/documentReport", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createStudentNo = async (data) => {
    const result = await instance.post("api/students/insertStudentNo", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}