import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getCourseOutline, getCourseOutlineFilter, getCourseOutlineReport, getCourse,
  deleteCourseOutline, getSchoolYear, getSemester, getCourses, getCourseOutlineAll,
  getCourseOutlineQuery, getInstitute, addUpdateCourseOutline, getInstituteQuery
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import PrintedForm from './PrintedForm';

export default function List() {
  const { state: { fees, auth }, dispatch } = useAppContext();
  const activeSy = auth.data.schoolyears.assessment.SchoolYear
  const activeSem = auth.data.schoolyears.assessment.SemesterId
  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
  const [page, setPage] = useState(fees.page);
  const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
  const [keyword, setKeyword] = useState(fees.keyword);
  const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
  const [onAlert, setOnAlert] = useState(false)

  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)
  const [filter, setFilter] = useState({ curriculumCode: null, instituteId: null, courseId: null })

  const updateId = fees.id;


  const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }
  const [addCouseSelect, setAddCourseSelect] = useState(null)
  const [courseSelect, setCourseSelect] = useState([{ tableId: '', code: '' }])
  const [curriculumSelect, setCurriculumSelect] = useState([{ tableId: '', code: '' }])
  const [instituteSelect, setInstituteSelect] = useState(null)
  const [schoolYearSelect, setSchoolYearSelect] = useState(null)
  const [semesterSelect, setSemesterSelect] = useState(null)


  const [report, setReport] = useState([])


  const [formData, setFormData] = useState({
    description: '',
    courseId: null,
    noOfYears: '',
    effectiveSchoolYear: null,
    effectiveSemesterId: null,
    effectiveDate: '',
    isActive: 1,
    createdBy: auth.data.UserName
  })

  const formSelectChange = name => event => { setFormData({ ...formData, [name]: event }) }
  const formInputChange = (name, data) => { setFormData({ ...formData, [name]: data }) }

  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // on save grade element  -----------------------------------------------------------
  const onSaveGradeElement = async (val) => {
    console.log(val)
    const values = {
      courseOutlineId: 0,
      description: val.description,
      courseId: val.courseId,
      noOfYears: val.noOfYears,
      schoolYear: val.effectiveSchoolYear,
      semesterId: val.effectiveSemesterId,
      effectiveDate: val.effectiveDate,
      isActive: val.isActive,
      createdBy: auth.data.Username
    }
    await addUpdateCourseOutline(values)
      .then(res => {
        console.log(res)
        setIsOpenAdd(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Course Outline has been saved.' });
        if (results.length == 0) {
          history.push(`/admin/references/course-outline-details/${res.data.CourseOutlineId}`)
        } else {
          onSearchFilters()
          setFormData({
            description: '',
            courseId: null,
            noOfYears: '',
            effectiveSchoolYear: null,
            effectiveSemesterId: null,
            effectiveDate: '',
            isActive: 1,
            createdBy: auth.data.UserName
          })
        }


      })
      .catch(e => {
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: e.message });

      })
  }

  // on update grade element
  const onUpdateElement = async (id, val) => {
    const values = {
      courseOutlineId: val.courseOutlineId,
      description: val.description,
      courseId: val.courseId,
      noOfYears: val.noOfYears,
      schoolYear: val.effectiveSchoolYear,
      semesterId: val.effectiveSemesterId,
      effectiveDate: val.effectiveDate,
      isActive: val.isActive,
      createdBy: auth.data.Username
    }
    await addUpdateCourseOutline(id, val)
      .then(res => {
        onSearchFilters()
        setIsOpenUpdate(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Course Outline has been updated.' });
      })
  }

  // on delete grade element
  const onDeleteElement = async (id) => {
    await deleteCourseOutline({ courseOutlineId: id })
      .then(res => {
        onSearchFilters()
        setIsOpenDelete(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Course Outline has been Deleted.' });
      })
      .catch(e => {
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: e.message });

      })
  }



  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    setIsOpenAdd(true)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {
    setRowValue(data)
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
    setRowDeleteValue(null)
  }


  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  const onSearchFilters = async () => {
    await getCourseOutlineFilter(page, rowsPerPage, filter.curriculumCode, filter.instituteId, filter.courseId)
      .then(res => {
        if (res.data.length > 1) {
          setResults(res.data)
          setOnAlert(true)
          setTotalRecord(res.data.length > 0 ? res.data.length : 0);
        } else if (res.data.length == 1) {
          history.push(`/admin/references/course-outline-details/${res.data[0].CourseOutlineId}`)
        } else {
          setOnAlert(true)
          setTotalRecord(res.data.length > 0 ? res.data.length : 0);
        }

      })

    await getCourseOutlineReport({
      instituteId: filter.instituteId,
      curriculumCode: filter.curriculumCode,
      withInstituteId: filter.instituteId !== null ? true : false,
      withCurrCode: filter.curriculumCode !== null ? true : false
    })
      .then(res => {
        // let arr = []
        // let arr2 = []
        // res.data.map(items => {
        //   console.log(items.Details)

        //   arr.push({ ...items, Details: JSON.parse(items.Details) })
        // })


        // arr.map(items => {
        //   console.log('fetching record')
        //   const updatedDetails = items.Details.map(detail => {
        //     let totalLecHours = 0;
        //     let totalLabHours = 0;

        //     detail.Subjects.forEach(subject => {
        //       totalLecHours += parseInt(subject.LecHours);
        //       totalLabHours += parseInt(subject.LabHours);
        //     });

        //     return {
        //       ...detail,
        //       TotalLecHours: totalLecHours,
        //       TotalLabHours: totalLabHours
        //     };
        //   });

        //   arr2.push({ ...items, Details: updatedDetails });
        // });

        console.log(res)
        setReport(res)

      })
  }

  const onSeachAgain = () => {
    setResults([])
    setOnAlert(false)

  }

  useEffect(() => {
    SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
    SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
    SelectCreator(getInstituteQuery, setInstituteSelect, 'InstituteId', 'Description', { where: `WHERE I.IsActive = 1 ORDER BY I.Description` })
    SelectCreator(getCourse, setAddCourseSelect, 'CourseId', 'Description')

  }, [])


  useEffect(() => {
    const getCourseSelect = async () => {
      await getCourses({ where: `WHERE InstituteId = ${filter.instituteId}` })
        .then(resp => {
          let arr = []
          for (let i = 0; i < resp.data.length; i++) {
            arr.push({ tableId: resp.data[i].CourseId, code: resp.data[i].Description })
          }

          setCourseSelect(arr)
        })
        .catch(e => {

        })
    }
    if (filter.instituteId !== null) {
      getCourseSelect()
    }


  }, [filter.instituteId])


  useEffect(() => {
    const getCurrSelect = async () => {
      await getCourseOutlineQuery({ where: `WHERE CO.CourseId = ${filter.courseId} AND I.InstituteId = ${filter.instituteId}` })
        .then(resp => {
          let arr = []
          for (let i = 0; i < resp.data.length; i++) {
            arr.push({ tableId: resp.data[i].Description, code: resp.data[i].Description })
          }

          setCurriculumSelect(arr)
        })
        .catch(e => {

        })
    }
    if (filter.courseId !== null) {
      getCurrSelect()
    }
  }, [filter.courseId])

  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      description: props.value !== undefined && props.value !== null ? props.value.description : null,
      courseId: props.value !== undefined && props.value !== null ? props.value.courseId : null,
      noOfYears: props.value !== undefined && props.value !== null ? props.value.noOfYears : null,
      effectiveSchoolYear: props.value !== undefined && props.value !== null ? props.value.effectiveSchoolYear : null,
      effectiveSemesterId: props.value !== undefined && props.value !== null ? props.value.effectiveSemesterId : null,
      effectiveDate: props.value !== undefined && props.value !== null ? props.value.effectiveDate : null,
      createdBy: auth.data.UserName

    })


    const [switchState, setSwitchState] = useState({
      isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false

    });

    const switchHandleChange = name => event => {
      console.log(event.target.checked)
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects

    const selectCourseChange = name => event => { setData({ ...data, courseId: event }) }
    const selectSyChange = name => event => { setData({ ...data, effectiveSchoolYear: event }) }
    const selectSemChange = name => event => { setData({ ...data, effectiveSemesterId: event }) }





    useEffect(() => {
      if (data && switchState) {

        props.onSave({
          ...data,
          isActive: switchState.isActive == true ? 1 : 0
        })
      }

    }, [data, switchState])


    console.log(props.value)
    console.log(data)
    return (
      <>

        <main className='container'>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Description</label>
              <input
                required type="text"
                className="form-control"
                id="inputEmail4"
                placeholder="Description"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.description : ''}
                onBlur={(e) => { setData({ ...data, description: e.target.value }) }}
              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Number of Years</label>
              <input
                required type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="No of Years"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.noOfYears : ''}
                onBlur={(e) => { setData({ ...data, noOfYears: e.target.value }) }}
              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.courseSelect}
                onHandleChange={selectCourseChange('courseId')}
                refClassContainer=""
                name="courseId"
                isRequired={false}
                withLabel={true}
                label="Course"
                placeHolder="Select Course"
                forwardRef={() => { }}
                selectedId={data.courseId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.schoolYearSelect}
                onHandleChange={selectSyChange('schoolYear')}
                refClassContainer=""
                name="schoolYear"
                isRequired={false}
                withLabel={true}
                label="School Year"
                placeHolder="Select School Year"
                forwardRef={() => { }}
                selectedId={data.effectiveSchoolYear}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.semesterSelect}
                onHandleChange={selectSemChange('semesterId')}
                refClassContainer=""
                name="semesterId"
                isRequired={false}
                withLabel={true}
                label="Semester"
                placeHolder="Select Semester"
                forwardRef={() => { }}
                selectedId={data.effectiveSemesterId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>


          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Effective Date</label>
              <input
                required type="date"
                className="form-control"
                id="inputEmail4"
                placeholder="Short Name"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.noOfYears : ''}
                onBlur={(e) => { setData({ ...data, effectiveDate: e.target.value }) }}
              />
            </div>

          </div>

          <div className="form-row">

            <div className="form-group col">
              <FormControl component="fieldset">
                <FormControlLabel
                  name="isActive"
                  inputRef={() => { }}
                  control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                  label="Active"
                />
              </FormControl>
            </div>
          </div>
        </main>
      </>
    )
  }

  const DeleteContent = (props) => {


    console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.description : ''} `}</span> </p>
        </main>



      </>
    )
  }



  // useEffect(() => {
  //   const execute = async () => {
  //     await getCourseOutline(page, rowsPerPage, keyword)
  //       .then(response => {
  //         if (response.success) {
  //           setResults(response.data);
  //           setTotalRecord(response.data.length > 0 ? response.records : 0);
  //         } else {
  //           dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
  //         }
  //       })
  //       .catch(error => {

  //         dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

  //       })
  //   }
  //   execute();

  // }, [page, rowsPerPage, keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'NoOfYears', label: 'No. of Years', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'SchoolYearDescription', label: 'Effective School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'SemesterDescription', label: 'Effective Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'CourseOutlineId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdateCourseOutline',
    parentId: null,
    tableTitle: 'List of Course Outline',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: referenceTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)
  console.log(keysToCamelCase(rowValue))


  console.log(semesterSelect)
  console.log(schoolYearSelect)
  console.log(courseSelect)
  console.log(results)
  console.log(report)
  console.log(rowDeleteValue)

  return (
    <>
      {semesterSelect && schoolYearSelect && instituteSelect && curriculumSelect && courseSelect && addCouseSelect &&
        <>
          <AddUpdateModal
            title="Add Course Outline"
            isOpen={isOpenAdd}
            Content={Content}
            handleClose={onAddGradeElementClose}
            isAdd={true}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            instituteSelect={instituteSelect}
            courseSelect={addCouseSelect}
            onSubmit={onSaveGradeElement}
            id="courseOutlineId"

          />

          <AddUpdateModal
            title="Update Course Outline"
            isOpen={isOpenUpdate}
            Content={Content}
            value={keysToCamelCase(rowValue)}
            handleClose={onUpdateGradeElementClose}
            isUpdate={true}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            courseSelect={addCouseSelect}
            instituteSelect={instituteSelect}
            onSubmit={onUpdateElement}
            id="courseOutlineId"

          />

          <AddUpdateModal
            title="Delete Course Outline"
            isOpen={isOpenDelete}
            Content={DeleteContent}
            handleClose={onDeleteGradeElementClose}
            isDelete={true}
            onSubmit={onDeleteElement}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            courseSelect={addCouseSelect}
            instituteSelect={instituteSelect}
            value={keysToCamelCase(rowDeleteValue)}
            id="courseOutlineId"

          />
          {results.length > 0 ?
            <>
              <div className="card card-custom gutter-b example example-compact">
                <div className="card-header">
                  <div className="card-title">
                    <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                  </div>
                  <div className="card-toolbar">
                    <div className="example-tools">

                      <button onClick={() => { onSeachAgain() }} className="btn btn-sm btn-icon-sm btn-secondary mr-3">
                        Search Again
                      </button>
                      {report.length > 0 ?
                        <PrintedForm
                          report={report}
                        />

                        :
                        <>
                          <section className='text-center'>
                            <div className="spinner-border" role="status">
                              <span className="sr-only">Loading...</span>
                            </div>

                          </section>

                        </>

                      }

                      {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                        <i className="fa fa-plus"></i> Add New
                      </button> */}
                    </div>
                  </div>
                </div>
                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                  <IMTable tableProps={tableProps} />
                  <section className='card p-2'>

                    <div className="d-flex align-items-center" style={{ width: '100%' }}>
                      <div className="form-group col">
                        <label htmlFor="inputEmail4">Code</label>
                        <input
                          required type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="Code"
                          value={formData.description}
                          onChange={(e) => { formInputChange('description', e.target.value) }}
                        />
                      </div>
                      <div className="form-group col">
                        <label htmlFor="inputEmail4">No of Years</label>
                        <input
                          required type="text"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="No of Years"
                          value={formData.noOfYears}
                          onChange={(e) => { formInputChange('noOfYears', e.target.value) }}
                        />
                      </div>
                      <div className="form-group mb-0 col">
                        <IMSelect
                          data={courseSelect}
                          onHandleChange={formSelectChange('courseId')}
                          refClassContainer=""
                          name="courseId"
                          isRequired={false}
                          withLabel={false}
                          label="Course"
                          placeHolder="Select Course"
                          forwardRef={() => { }}
                          selectedId={formData.courseId}
                          refClassName={`text-center mr-3`}
                          withDescription={false}
                          //description={`Enrollment Status`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[]}
                          field={{
                            tableId: 'tableId',
                            display: 'code',
                          }}
                        />
                      </div>
                      <div className="form-group mb-0 col">
                        <IMSelect
                          data={schoolYearSelect}
                          onHandleChange={formSelectChange('effectiveSchoolYear')}
                          refClassContainer=""
                          name="effectiveSchoolYear"
                          isRequired={false}
                          withLabel={false}
                          label="School Year"
                          placeHolder="Select School Year"
                          forwardRef={() => { }}
                          selectedId={formData.effectiveSchoolYear}
                          refClassName={`text-center mr-3`}
                          withDescription={false}
                          //description={`Enrollment Status`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[]}
                          field={{
                            tableId: 'tableId',
                            display: 'code',
                          }}
                        />
                      </div>
                      <div className="form-group mb-0 col">
                        <IMSelect
                          data={semesterSelect}
                          onHandleChange={formSelectChange('effectiveSemesterId')}
                          refClassContainer=""
                          name="effectiveSemesterId"
                          isRequired={false}
                          withLabel={false}
                          label="Semester"
                          placeHolder="Select Semester"
                          forwardRef={() => { }}
                          selectedId={formData.effectiveSemesterId}
                          refClassName={`text-center mr-3`}
                          withDescription={false}
                          //description={`Enrollment Status`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[]}
                          field={{
                            tableId: 'tableId',
                            display: 'code',
                          }}
                        />
                      </div>
                      <div className="form-group col">
                        <label htmlFor="inputEmail4">Effective Date</label>
                        <input
                          required
                          type="date"
                          className="form-control"
                          id="inputEmail4"
                          placeholder="No of Years"
                          value={formData.effectiveDate}
                          onChange={(e) => { formInputChange('effectiveDate', e.target.value) }}
                        />
                      </div>
                      {formData.yearLevelId !== null && formData.yearLevelId !== ''
                        && formData.semesterId !== null && formData.semesterId !== ''
                        && formData.subjectId !== null && formData.subjectId !== '' ?
                        <button
                          onClick={() => { onSaveGradeElement(formData) }}
                          className="btn btn-sm btn-primary col-1">
                          Add
                        </button>
                        :
                        <button
                          disabled
                          className="btn btn-sm btn-secondary col-1">
                          Add
                        </button>

                      }
                    </div>
                  </section>
                </div>
              </div>
            </>
            :
            <>
              <div className='d-flex align-items-center'>
                <main class="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                  <header className='card-header p-5 bg-secondary'>
                    <h6 class="float-left m-0 mt-4 ml-5">Course Outline</h6>
                    <section className='float-right'>
                      <button onClick={() => { onAddGradeElement() }} className='btn btn-sm btn-primary'>Add</button>

                    </section>

                  </header>
                  <section class="card-body">
                    {onAlert == true && results.length == 0 &&
                      <div className="alert alert-danger" role="alert">
                        No Record Found
                      </div>
                    }
                    <p className="card-text">Search for Course Outline</p>

                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={instituteSelect}
                          onHandleChange={filterChange('instituteId')}
                          refClassContainer=""
                          name="instituteId"
                          isRequired={false}
                          withLabel={false}
                          label="Department / Institute"
                          placeHolder="Department / Institute"
                          forwardRef={() => { }}
                          selectedId={filter.instituteId}
                          refClassName={`text-center`}
                          withDescription={true}
                          description={`Institute:`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[
                          ]
                          }
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                    </div>


                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={courseSelect}
                          onHandleChange={filterChange('courseId')}
                          refClassContainer=""
                          name="courseId"
                          isRequired={false}
                          withLabel={false}
                          label="Course"
                          placeHolder="Program"
                          forwardRef={() => { }}
                          selectedId={filter.courseId}
                          refClassName={`text-center`}
                          withDescription={true}
                          description={`Program:`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[
                          ]
                          }
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                    </div>


                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={curriculumSelect}
                          onHandleChange={filterChange('curriculumCode')}
                          refClassContainer=""
                          name="curriculumCode"
                          isRequired={false}
                          withLabel={false}
                          label="Curriculum Code"
                          placeHolder="Curriculum Code"
                          forwardRef={() => { }}
                          selectedId={filter.curriculumCode}
                          refClassName={`text-center`}
                          withDescription={true}
                          description={`Curriculum Code:`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[
                          ]
                          }
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                    </div>

                    <button onClick={() => { onSearchFilters() }} className='btn btn-success btn-block'>Search</button>


                  </section>
                </main>

              </div>
            </>

          }


        </>
      }

    </>
  );
}