export const referenceTypes = {
    SELECTED_SCHOLARSHIP_TAG: 'SELECTED-SCHOLARSHIP-TAG',
    GET_SCHOLARSHIP_TAGS: 'GET-SCHOLARSHIP-TAGS',    
    GET_SCHOLARSHIP_TAG: 'GET-SCHOLARSHIP-TAG',
    CREATE: 'CREATE-SCHOLARSHIP-TAG',
    UPDATE: 'UPDATE-SCHOLARSHIP-TAG',
    DELETE: 'DELETE-SCHOLARSHIP-TAG',
    LIST_LINK: '/admin/references/scholarship-tags',
    CREATE_LINK: '/admin/references/create-scholarship-tags/',
    UPDATE_LINK: '/admin/references/update-scholarship-tags/',
    DELETE_LINK: '/admin/references/delete-scholarship-tags/'
}