import instance from "../../../../../apis/local/systemAPI";

export const getStudentClearance = async (data) => {
    const result = await instance.post("api/students/studentClearance", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentClearanceAddUpdate = async (data) => {
    const result = await instance.post("api/students/clearanceAddUpdate", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const searchStudent = async (data) => {
    const result = await instance.post("api/enrollments/search", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getAccountability = async () => {
    const result = await instance.get("api/references/accountability/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
