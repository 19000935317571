import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import IMTable from '../table/IMTable';
import Loading from '../forms/Loading';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal, Box } from '@material-ui/core';
import { useEffect } from 'react';
import { create } from 'lodash';
import { useAppContext } from '../../../../../app/contexts/useAppContext';
import { componentTypes } from '../../snackbar/__hooks__/types';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function GradeBookDetailFinalPeriodModal(props) {
    const { results, handleClose, isOpen, isInstructor, isDeanChair, onPostGrades,
        period, createdBy, date, onLoad, gradingPeriodId, isPrelimPosted, isMidtermPosted, isFinalPosted,
        isAuthenticated, data, unPostingGrades, scheduleTypeId } = props;
    const [formValues, setFormValues] = useState({})
    const classes = useRowStyles();
    const [rawGrades, setRawGrades] = useState()
    const [isValidScore, setIsValidScore] = useState(false)
    const [encodedRemarks, setEncodedRemarks] = useState(null)
    const [isPosted, setIsPosted] = useState(true)
    const [toBeSubmitted, setToBeSubmitted] = useState([])
    const [toBeAuthenticate, setToBeAuthenticate] = useState([])
    const [loadingState, setLoadingState] = useState(new Array(results.length).fill(false));
    const [loadingStateAll, setLoadingStateAll] = useState(false)
    const [isLoadingSubmition, setIsLoadingSubmition] = useState(false)
    const { state: { auth }, dispatch } = useAppContext();
    let cnt = 0


    const onSave = () => {
        let updatedRawGrades = []; // create a copy of the rawGrades state
        console.log(rawGrades)
        setLoadingState(new Array(results.length).fill(true))
        setLoadingStateAll(true)
        rawGrades.map(row => {
            updatedRawGrades.push({
                finalGrade: row.finalGrade,
                gBookFinalGradeId: row.gBookFinalGradeId,
                studentId: row.studentId,
                semesterId: row.semesterId,
                schoolYear: row.schoolYear,
                createdBy: createdBy,
                dateUpdated: date,
                gBookFinalGradeDetailId: row.gBookFinalGradeDetailId,
                gradingPeriodId: gradingPeriodId,
                subjectId: row.subjectId,
                grade: row.grade,
                isPosted: 1,
                deanIsPosted: 0,
                chairIsPosted: 0,
                postingDate: date,
                chairValidationDate: null,
                deanValidationDate: null,
                createdBy: createdBy,
                dateUpdated: date,
                remarks: row.remarks,
                isForDeanValidation: false,
                isForChairValidation: false,
                finalRemarks: '',
                instructionalGuideId: row.instructionalGuideId,
                scheduleTypeId: scheduleTypeId
            })
        })
        console.log(updatedRawGrades)
        // // alert('posted')
        onPostGrades(updatedRawGrades)
            .then(async res => {
                await onLoad(0, results[0].InstructionalGuideId, 0)
                    .then(d => {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grades have been posted' });
                        setLoadingState(new Array(results.length).fill(false))
                        setLoadingStateAll(false)
                        setIsPosted(true)
                    })


            })
        setIsValidScore(false)
    }


    const onSubmitSelectedItems = () => {
        setIsLoadingSubmition(true)
        onPostGrades(toBeSubmitted)
            .then(res => {
                onLoad(toBeSubmitted[0].InstructionalGuideDetailId, toBeSubmitted[0].InstructionalGuideId, toBeSubmitted[0].gradingPeriodId)
                    .then(resp => {
                        setToBeSubmitted([])
                        setToBeAuthenticate([])
                        setIsLoadingSubmition(false)
                    })
                // setLoadingState(prevData => {
                //     const updatedLoadingState = [...prevData];
                //     updatedLoadingState[index] = false;
                //     return updatedLoadingState;
                // });
            })
    }



    const onCheckItems = (e, toSubmit, toLoad) => {
        console.log(e.target.checked);
        let isChecked = e.target.checked;

        if (isChecked) {
            // If checkbox is checked, add the 'toSubmit' parameter to 'toBeSubmitted'
            setToBeSubmitted((prevData) => [...prevData, toSubmit]);
        } else {
            // If checkbox is unchecked, remove the 'toSubmit' parameter from 'toBeSubmitted'
            setToBeSubmitted((prevData) => prevData.filter(item =>
                item.studentId !== toSubmit.studentId
            ));
        }
    }




    const onUnpostAll = () => {
        let updatedRawGrades = []; // create a copy of the rawGrades state
        console.log(rawGrades)
        setLoadingState(new Array(results.length).fill(true))
        setLoadingStateAll(true)
        rawGrades.map(row => {
            console.log(row)
            updatedRawGrades.push({
                finalGrade: row.finalGrade,
                gBookFinalGradeId: row.gBookFinalGradeId,
                studentId: row.studentId,
                semesterId: row.semesterId,
                schoolYear: row.schoolYear,
                createdBy: createdBy,
                dateUpdated: date,
                gBookFinalGradeDetailId: row.gBookFinalGradeDetailId,
                gradingPeriodId: gradingPeriodId,
                subjectId: row.subjectId,
                grade: row.grade,
                isPosted: row.deanIsPosted == 1 && row.chairIsPosted == 1 ? 1 : 0,
                deanIsPosted: row.deanIsPosted,
                chairIsPosted: row.chairIsPosted,
                postingDate: date,
                chairValidationDate: null,
                deanValidationDate: null,
                createdBy: createdBy,
                dateUpdated: date,
                remarks: row.remarks,
                isForDeanValidation: false,
                isForChairValidation: false,
                finalRemarks: '',
                instructionalGuideId: row.instructionalGuideId,
                scheduleTypeId: scheduleTypeId
            })
        })
        console.log(updatedRawGrades)
        // // alert('posted')
        onPostGrades(updatedRawGrades)
            .then(async res => {
                await onLoad(0, results[0].InstructionalGuideId, 0)
                    .then(d => {
                        setLoadingState(new Array(results.length).fill(false))
                        setLoadingStateAll(false)
                        setIsPosted(false)
                    })


            })
        setIsValidScore(false)
    }

    useEffect(() => {
        let updatedRawGrades = []; // create a copy of the rawGrades state

        results.map(row => {
            if ((row.IsPosted == 0 || row.IsPosted == null) && row.GradingPeriodId == gradingPeriodId) {
                setIsPosted(false)
            }
            updatedRawGrades.push({
                finalGrade: row.ComputedGrades,
                gBookFinalGradeId: row.GBookFinalGradeId,
                studentId: row.StudentId,
                semesterId: row.SemesterId,
                schoolYear: row.SchoolYear,
                createdBy: createdBy,
                dateUpdated: date,
                gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                gradingPeriodId: gradingPeriodId,
                subjectId: row.SubjectId,
                grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                isPosted: 1,
                deanIsPosted: row.DeanIsPosted,
                chairIsPosted: row.ChairIsPosted,
                postingDate: date,
                chairValidationDate: null,
                deanValidationDate: null,
                createdBy: createdBy,
                dateUpdated: date,
                remarks: '',
                isForDeanValidation: false,
                isForChairValidation: false,
                finalRemarks: '',
                instructionalGuideId: row.InstructionalGuideId

            })
        })
        console.log(updatedRawGrades)
        setRawGrades(updatedRawGrades); // update the rawGrades state with the new array
    }, [])

    useEffect(() => {
        let updatedRawGrades = []; // create a copy of the rawGrades state




        results.map(row => {
            if ((row.IsPosted == 0 || row.IsPosted == null) && row.GradingPeriodId == gradingPeriodId) {
                setIsPosted(false)
            }
            updatedRawGrades.push({
                finalGrade: row.ComputedGrades,
                gBookFinalGradeId: row.GBookFinalGradeId,
                studentId: row.StudentId,
                semesterId: row.SemesterId,
                schoolYear: row.SchoolYear,
                createdBy: createdBy,
                dateUpdated: date,
                gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                gradingPeriodId: gradingPeriodId,
                subjectId: row.SubjectId,
                grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                isPosted: 1,
                deanIsPosted: row.DeanIsPosted,
                chairIsPosted: row.ChairIsPosted,
                postingDate: date,
                chairValidationDate: null,
                deanValidationDate: null,
                createdBy: createdBy,
                dateUpdated: date,
                remarks: '',
                isForDeanValidation: false,
                isForChairValidation: false,
                finalRemarks: '',
                instructionalGuideId: row.InstructionalGuideId

            })
        })



        console.log(updatedRawGrades)
        setRawGrades(updatedRawGrades); // update the rawGrades state with the new array
    }, [isPosted])

    useEffect(() => {
        if (rawGrades !== undefined && rawGrades !== null) {
            console.log(rawGrades)
            console.log(results)
            let totalRawGradesData = rawGrades.length
            let checked = 0
            for (let i = 0; i < rawGrades.length; i++) {
                console.log(parseInt(rawGrades[i].rawScore))
                console.log(parseInt(results[0].HighScore))
                if (parseInt(rawGrades[i].rawScore) <= parseInt(results[0].HighScore)) {
                    checked++
                }
            }
            console.log(checked + '- -' + totalRawGradesData)
            if (checked == totalRawGradesData) {
                setIsValidScore(true)
            } else {
                setIsValidScore(false)
            }
        }
    }, [rawGrades])





    console.log(props.value)
    console.log(formValues)
    console.log({ ...props.value, ...formValues })
    console.log(rawGrades)
    console.log(results)
    console.log(gradingPeriodId)

    console.log(isPrelimPosted)
    console.log(isMidtermPosted)
    console.log(isFinalPosted)

    console.log(encodedRemarks)


    console.log(loadingState)
    console.log(toBeSubmitted)
    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle className='pt-0 text-primary mt-5' id="alert-dialog-slide-title ">{results.length > 0 && results[0].GradingPeriod}</DialogTitle>


                <hr />

                {results.length > 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center">#</TableCell>
                                        <TableCell align="center">Student No</TableCell>
                                        <TableCell align="left">Student Name</TableCell>
                                        <TableCell align="left">Remarks</TableCell>
                                        <TableCell align="center">{`${period} Grade`}</TableCell>
                                        <TableCell align="center">Action</TableCell>




                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((row, index) => {
                                        cnt++
                                        return (
                                            <>
                                                <TableRow className={classes.root}>
                                                    <TableCell align="center">{cnt}</TableCell>
                                                    <TableCell align="center">{row.StudentNo}</TableCell>
                                                    <TableCell align="left">{row.StudentName}</TableCell>

                                                    {row.IsPosted == 0 || row.IsPosted == null ?
                                                        <input
                                                            name="remark"
                                                            className='form-control'
                                                            defaultValue={row.Remarks}
                                                            onChange={(event) => {
                                                                setEncodedRemarks({ ...encodedRemarks, [index]: event.target.value })
                                                                const updatedRawGrades = rawGrades ? [...rawGrades] : []; // create a copy of the rawGrades state
                                                                const existingGradeIndex = updatedRawGrades.findIndex((grade) =>
                                                                    grade.studentId === row.StudentId &&
                                                                    grade.semesterId === row.SemesterId &&
                                                                    grade.schoolYear === row.SchoolYear &&
                                                                    grade.subjectId === row.SubjectId
                                                                )
                                                                console.log(updatedRawGrades)
                                                                console.log(existingGradeIndex)

                                                                if (existingGradeIndex >= 0) {
                                                                    // if an existing grade is found, update its rawScore value
                                                                    updatedRawGrades[existingGradeIndex].remarks = event.target.value;
                                                                } else {
                                                                    // if no existing grade is found, add a new grade object to the array
                                                                    updatedRawGrades.push({
                                                                        finalGrade: row.ComputedGrades,
                                                                        gBookFinalGradeId: row.GBookFinalGradeId,
                                                                        studentId: row.StudentId,
                                                                        semesterId: row.SemesterId,
                                                                        schoolYear: row.SchoolYear,
                                                                        createdBy: createdBy,
                                                                        dateUpdated: date,
                                                                        gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                                                                        gradingPeriodId: gradingPeriodId,
                                                                        subjectId: row.SubjectId,
                                                                        grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                                                                        isPosted: 1,
                                                                        deanIsPosted: 0,
                                                                        chairIsPosted: 0,
                                                                        postingDate: date,
                                                                        chairValidationDate: null,
                                                                        deanValidationDate: null,
                                                                        createdBy: createdBy,
                                                                        dateUpdated: date,
                                                                        remarks: event.target.value,
                                                                        isForDeanValidation: false,
                                                                        isForChairValidation: false,
                                                                        finalRemarks: ''


                                                                    });


                                                                }
                                                                setRawGrades(updatedRawGrades); // update the rawGrades state with the new array
                                                            }}
                                                            type='text'
                                                        />
                                                        :
                                                        <TableCell align="left">{row.Remarks}</TableCell>


                                                    }

                                                    {gradingPeriodId == 1
                                                        && <TableCell className={parseFloat(row.PrelimGrade) < 75 ? 'text-danger' : ''} align="center">{parseFloat(row.PrelimGrade).toFixed(2)}</TableCell>
                                                    }
                                                    {gradingPeriodId == 2
                                                        && <TableCell className={parseFloat(row.MidtermGrade) < 75 ? 'text-danger' : ''} align="center">{parseFloat(row.MidtermGrade).toFixed(2)}</TableCell>
                                                    }
                                                    {gradingPeriodId == 4
                                                        && <TableCell className={parseFloat(row.FinalGrade) < 75 ? 'text-danger' : ''} align="center">{parseFloat(row.FinalGrade).toFixed(2)}</TableCell>
                                                    }



                                                    {
                                                        row.IsPosted == 1 && gradingPeriodId == row.GradingPeriodId
                                                            ?
                                                            <>
                                                                {(row.ChairIsPosted == 0 || row.ChairIsPosted == null) && (row.DeanIsPosted == 0 || row.DeanIsPosted == null) ?
                                                                    <TableCell align="center" >
                                                                        {/* For Validation */}
                                                                        <button onClick={() => {

                                                                            setLoadingState(prevData => {
                                                                                const updatedLoadingState = [...prevData];
                                                                                updatedLoadingState[index] = true;
                                                                                return updatedLoadingState;
                                                                            });


                                                                            unPostingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId }])
                                                                                .then(async res => {

                                                                                    await onLoad(row.InstructionalGuideDetailId, row.InstructionalGuideId, gradingPeriodId)
                                                                                        .then(d => {
                                                                                            const updatedRawGrades = rawGrades ? [...rawGrades] : []; // create a copy of the rawGrades state
                                                                                            const existingGradeIndex = updatedRawGrades.findIndex((grade) =>
                                                                                                grade.studentId === row.StudentId &&
                                                                                                grade.semesterId === row.SemesterId &&
                                                                                                grade.schoolYear === row.SchoolYear &&
                                                                                                grade.subjectId === row.SubjectId
                                                                                            )
                                                                                            console.log(updatedRawGrades)
                                                                                            console.log(existingGradeIndex)

                                                                                            if (existingGradeIndex >= 0) {
                                                                                                // if an existing grade is found, update its rawScore value
                                                                                                updatedRawGrades[existingGradeIndex].remarks = row.Remarks;
                                                                                            } else {
                                                                                                // if no existing grade is found, add a new grade object to the array
                                                                                                updatedRawGrades.push({
                                                                                                    finalGrade: row.ComputedGrades,
                                                                                                    gBookFinalGradeId: row.GBookFinalGradeId,
                                                                                                    studentId: row.StudentId,
                                                                                                    semesterId: row.SemesterId,
                                                                                                    schoolYear: row.SchoolYear,
                                                                                                    createdBy: createdBy,
                                                                                                    dateUpdated: date,
                                                                                                    gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                                                                                                    gradingPeriodId: gradingPeriodId,
                                                                                                    subjectId: row.SubjectId,
                                                                                                    grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                                                                                                    isPosted: 1,
                                                                                                    deanIsPosted: 0,
                                                                                                    chairIsPosted: 0,
                                                                                                    postingDate: date,
                                                                                                    chairValidationDate: null,
                                                                                                    deanValidationDate: null,
                                                                                                    createdBy: createdBy,
                                                                                                    dateUpdated: date,
                                                                                                    remarks: row.Remarks,
                                                                                                    isForDeanValidation: false,
                                                                                                    isForChairValidation: false,
                                                                                                    finalRemarks: '',
                                                                                                    instructionalGuideId: row.InstructionalGuideId



                                                                                                });


                                                                                            }
                                                                                            setRawGrades(updatedRawGrades); // update the rawGrades state with the new array
                                                                                            setLoadingState(prevData => {
                                                                                                const updatedLoadingState = [...prevData];
                                                                                                updatedLoadingState[index] = false;
                                                                                                return updatedLoadingState;
                                                                                            });


                                                                                        })
                                                                                    setIsValidScore(false)
                                                                                })
                                                                        }} className={`btn btn-sm px-3 py-1 ${loadingState[index] ? 'btn-secondary' : 'btn-warning'}`}  >{loadingState[index] ? <div className="spinner-border  spinner-border-sm" role="status">
                                                                            <span class="sr-only">Loading...</span>
                                                                        </div> : 'Cancel'}</button>
                                                                    </TableCell>
                                                                    :
                                                                    <>
                                                                        {row.ChairIsPosted == 1 && row.DeanIsPosted != 1
                                                                            ?
                                                                            <TableCell align="center" >
                                                                                <button disabled className='btn btn-sm btn-secondary px-3 py-1' >For Authentication</button>
                                                                            </TableCell>
                                                                            :
                                                                            <>
                                                                                {row.ChairIsPosted == 1 && row.DeanIsPosted == 1
                                                                                    ?
                                                                                    <TableCell align="center" >
                                                                                        <button disabled className='btn btn-sm btn-secondary px-3 py-1' >Posted</button>
                                                                                    </TableCell>
                                                                                    :
                                                                                    <TableCell align="center" >
                                                                                        <button disabled className='btn btn-sm btn-secondary px-3 py-1' >For Validation</button>
                                                                                    </TableCell>
                                                                                }

                                                                            </>




                                                                        }


                                                                    </>


                                                                }


                                                            </>


                                                            :

                                                            <TableCell align="center" >
                                                                <input
                                                                    type='checkbox'
                                                                    onChange={(e) => {
                                                                        onCheckItems(
                                                                            e,
                                                                            {
                                                                                finalGrade: row.ComputedGrades,
                                                                                gBookFinalGradeId: row.GBookFinalGradeId,
                                                                                studentId: row.StudentId,
                                                                                semesterId: row.SemesterId,
                                                                                schoolYear: row.SchoolYear,
                                                                                createdBy: createdBy,
                                                                                dateUpdated: date,
                                                                                gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                                                                                gradingPeriodId: gradingPeriodId,
                                                                                subjectId: row.SubjectId,
                                                                                grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                                                                                isPosted: 1,
                                                                                deanIsPosted: 0,
                                                                                chairIsPosted: 0,
                                                                                postingDate: date,
                                                                                chairValidationDate: null,
                                                                                deanValidationDate: null,
                                                                                createdBy: createdBy,
                                                                                dateUpdated: date,
                                                                                remarks: encodedRemarks == null || encodedRemarks[index] == undefined ? '' : encodedRemarks[index],
                                                                                isForDeanValidation: false,
                                                                                isForChairValidation: false,
                                                                                instructionalGuideId: row.InstructionalGuideId,
                                                                                scheduleTypeId: scheduleTypeId
                                                                            },
                                                                            {
                                                                                instructionalGuideDetailId: row.InstructionalGuideDetailId,
                                                                                InstructionalGuideId: row.InstructionalGuideId,
                                                                                gradingPeriodId: gradingPeriodId
                                                                            }
                                                                        )
                                                                    }}
                                                                />
                                                                {/* <button onClick={async () => {
                                                                    setLoadingState(prevData => {
                                                                        const updatedLoadingState = [...prevData];
                                                                        updatedLoadingState[index] = true;
                                                                        return updatedLoadingState;
                                                                    });

                                                                    // ... your asynchronous code ...


                                                                    await onPostGrades([{
                                                                        finalGrade: row.ComputedGrades,
                                                                        gBookFinalGradeId: row.GBookFinalGradeId,
                                                                        studentId: row.StudentId,
                                                                        semesterId: row.SemesterId,
                                                                        schoolYear: row.SchoolYear,
                                                                        createdBy: createdBy,
                                                                        dateUpdated: date,
                                                                        gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                                                                        gradingPeriodId: gradingPeriodId,
                                                                        subjectId: row.SubjectId,
                                                                        grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                                                                        isPosted: 1,
                                                                        deanIsPosted: 0,
                                                                        chairIsPosted: 0,
                                                                        postingDate: date,
                                                                        chairValidationDate: null,
                                                                        deanValidationDate: null,
                                                                        createdBy: createdBy,
                                                                        dateUpdated: date,
                                                                        remarks: encodedRemarks == null || encodedRemarks[index] == undefined ? '' : encodedRemarks[index],
                                                                        isForDeanValidation: false,
                                                                        isForChairValidation: false,
                                                                        instructionalGuideId: row.InstructionalGuideId,
                                                                        scheduleTypeId: scheduleTypeId
                                                                    }]).then(async r => {
                                                                        await onLoad(row.InstructionalGuideDetailId, row.InstructionalGuideId, gradingPeriodId)
                                                                            .then(d => {
                                                                                const updatedRawGrades = rawGrades ? [...rawGrades] : []; // create a copy of the rawGrades state
                                                                                const existingGradeIndex = updatedRawGrades.findIndex((grade) =>
                                                                                    grade.studentId === row.StudentId &&
                                                                                    grade.semesterId === row.SemesterId &&
                                                                                    grade.schoolYear === row.SchoolYear &&
                                                                                    grade.subjectId === row.SubjectId
                                                                                )
                                                                                console.log(updatedRawGrades)
                                                                                console.log(existingGradeIndex)

                                                                                if (existingGradeIndex >= 0) {
                                                                                    // if an existing grade is found, update its rawScore value
                                                                                    updatedRawGrades[existingGradeIndex].remarks = row.Remarks;
                                                                                } else {
                                                                                    // if no existing grade is found, add a new grade object to the array
                                                                                    updatedRawGrades.push({
                                                                                        finalGrade: row.ComputedGrades,
                                                                                        gBookFinalGradeId: row.GBookFinalGradeId,
                                                                                        studentId: row.StudentId,
                                                                                        semesterId: row.SemesterId,
                                                                                        schoolYear: row.SchoolYear,
                                                                                        createdBy: createdBy,
                                                                                        dateUpdated: date,
                                                                                        gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                                                                                        gradingPeriodId: gradingPeriodId,
                                                                                        subjectId: row.SubjectId,
                                                                                        grade: gradingPeriodId == 1 ? parseFloat(row.PrelimGrade) : gradingPeriodId == 2 ? parseFloat(row.MidtermGrade) : gradingPeriodId == 4 ? parseFloat(row.FinalGrade) : 0.00,
                                                                                        isPosted: 1,
                                                                                        deanIsPosted: 0,
                                                                                        chairIsPosted: 0,
                                                                                        postingDate: date,
                                                                                        chairValidationDate: null,
                                                                                        deanValidationDate: null,
                                                                                        createdBy: createdBy,
                                                                                        dateUpdated: date,
                                                                                        remarks: row.Remarks,
                                                                                        isForDeanValidation: false,
                                                                                        isForChairValidation: false,
                                                                                        finalRemarks: '',
                                                                                        instructionalGuideId: row.InstructionalGuideId



                                                                                    });


                                                                                }
                                                                                setRawGrades(updatedRawGrades); // update the rawGrades state with the new array
                                                                                setLoadingState(prevData => {
                                                                                    const updatedLoadingState = [...prevData];
                                                                                    updatedLoadingState[index] = false;
                                                                                    return updatedLoadingState;
                                                                                });

                                                                            })
                                                                        setIsValidScore(false)
                                                                    })

                                                                    // handleClose()
                                                                }}
                                                                    className={`btn btn-sm px-3 py-1 ${loadingState[index] ? 'btn-primary' : 'btn-primary'}`}
                                                                    disabled={loadingState[index]}
                                                                >  {loadingState[index] ? <div className="spinner-border  spinner-border-sm" role="status">
                                                                    <span class="sr-only">Loading...</span>
                                                                </div> : 'Submit for Validation'}
                                                                </button> */}
                                                            </TableCell>
                                                    }


                                                </TableRow>

                                            </>
                                        )

                                    })}

                                </TableBody>



                            </Table>


                        </TableContainer>
                    </>
                    :
                    <>
                        <Loading />
                    </>
                }
                {/* table where details will go */}


                <DialogActions>
                    {isInstructor &&
                        <>

                            {isPosted == false ?
                                <>

                                    {
                                        toBeSubmitted.length == 0 ?
                                            <>
                                                {rawGrades !== null && rawGrades.length > 0 ?
                                                    <button onClick={() => onSave()} className={`btn btn-success`} disabled={loadingStateAll}>
                                                        Submit All for Validation
                                                    </button>
                                                    :

                                                    <button className="btn btn-secondary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp; Loading...
                                                    </button>
                                                }

                                            </>

                                            :
                                            <>
                                                {isLoadingSubmition == false ?
                                                    <button onClick={() => { onSubmitSelectedItems() }} className='btn btn-primary'>Submit All Checked</button>
                                                    :
                                                    <button className="btn btn-secondary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp; Loading...
                                                    </button>
                                                }

                                            </>

                                    }

                                </>

                                :
                                <>
                                    {isAuthenticated == true ?
                                        <button onClick={() => onUnpostAll()} className={`btn btn-warning`} disabled={loadingStateAll}>
                                            Cancel All
                                        </button> :
                                        ''
                                    }

                                </>

                            }
                        </>



                    }

                    {isDeanChair &&
                        <button
                            disabled
                            className="btn btn-secondary">
                            Validate Grade
                        </button>
                    }

                    <button onClick={handleClose} className="btn btn-secondary">
                        Back
                    </button>
                </DialogActions>
            </Dialog>
        </>




    );
}