import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getAssessmentTemplateDetailsByAssessmentTemplateId
  , createAssessmentTemplateDetail
  , updateAssessmentTemplateDetail
  , deleteAssessmentTemplateDetail,
  addUpdateAssessmentTemplateDetail,
  addDuplicateAssessmentTemplateDetail,
  getAssessmentTemplate,
  getAssessmentTemplateQuery,
  getPaymentMode
} from './__hooks__';
import Form from './Form';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function List(props) {
  const classes = useStyles();
  const { AssessmentTemplateId } = props.assessmentTemplate;
  const { dispatch } = useAppContext();
  const [selectedId, setSelectedId] = useState(0);
  const [results, setResults] = useState([]);
  const [retrieve, setRetrieve] = useState(true);
  const [assessmetnTemplate, setAssessmentTemplate] = useState()
  const [paymentMode, setPaymentMode] = useState()

  const insId = props.assessmentTemplate.InstituteId
  const courId = props.assessmentTemplate.CourseId
  const yearLevId = props.assessmentTemplate.YearLevelId
  const syId = props.assessmentTemplate.SchoolYear
  const semId = props.assessmentTemplate.SemesterId


  console.log(insId)
  console.log(courId)
  console.log(yearLevId)
  console.log(syId)
  console.log(semId)

  const [showDialog, setShowDialog] = useState({
    id: null,
    record: '',
    status: false
  });
  var rowCnt = 0;

  console.log(props)
  const Query = (operation, condition, setData) => {
    let obj = eval("(" + condition + ")");
    const execute = async () => {
      await operation(obj)
        .then(response => {
          if (response.success) {

            setData(response.data)
          }
          return
        })
        .catch(error => {
          if (error) {
            return error
            // alert("something went wrong")
          } else {
            return
          }
        })
    }
    execute()
  }


  useEffect(() => {
    const getAssessment = async (con) => {
      await getAssessmentTemplateQuery(con)
        .then(res => {
          if (res.data.length > 0)
            setAssessmentTemplate(res.data[0])
        })
    }
    getAssessment({ where: `WHERE AssessmentTemplateId = ${parseInt(AssessmentTemplateId)}` })
    Query(getPaymentMode, null, setPaymentMode)
  }, [])

  useEffect(() => {
    if (retrieve) {
      const execute = async () => {
        await getAssessmentTemplateDetailsByAssessmentTemplateId(AssessmentTemplateId)
          .then(response => {
            if (response.success) {
              dispatch({ type: enrollmentTypes.GET_ASSESSMENT_DETAILS, data: response.data });
              setResults(response.data);
              props.handleGetDatails(response.data)
              setRetrieve(false);
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
            }
          })
      }
      execute();
    }
  }, [dispatch, AssessmentTemplateId, retrieve])

  const handleAdd = (data, isDuplicate) => {
    // if (isDuplicate !== 'd') {
    const execute = async () => {
      await addUpdateAssessmentTemplateDetail({ ...data, assessmentTemplateDetailId: 0 })
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();

  }

  const handleDuplicate = (data) => {
    // if (isDuplicate !== 'd') {
    const execute = async () => {
      await addDuplicateAssessmentTemplateDetail({ ...data, schoolYear: assessmetnTemplate.SchoolYear, semesterId: assessmetnTemplate.SemesterId })
        .then(response => {
          setRetrieve(true);
          setSelectedId(0);
        })
        .catch(error => {

        })
    }
    execute();

  }

  const handleEdit = (id) => {
    setSelectedId(id);
  }

  const handleCancel = () => {
    setSelectedId(0);
  }

  const handleSave = (id, data) => {
    const execute = async () => {
      await addUpdateAssessmentTemplateDetail({ ...data, assessmentTemplateDetailId: id })
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleDelete = () => {
    const execute = async () => {
      await deleteAssessmentTemplateDetail({ assessmentTemplateDetailId: showDialog.id })
        .then(response => {

          setRetrieve(true);
          setShowDialog({ id: null, record: '', status: false })

        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  console.log(results)
  console.log(assessmetnTemplate)
  console.log(AssessmentTemplateId)
  console.log(paymentMode)
  return (
    <>




      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell>Fee Group</TableCell>
              <TableCell align="center">Computation</TableCell>
              <TableCell align="center">Amount</TableCell>
              <TableCell>Interest Rate</TableCell>
              <TableCell>Interest Type</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.length > 0 ? results.map((result) => {
              const { AssessmentTemplateDetailId, FeeCode, Computation, Amount, InterestRate, InterestType, FeeOrder, IsActive, FeeName, FeeGroup } = result;
              rowCnt += 1;
              console.log(FeeCode)
              if (selectedId !== 0 && selectedId === result.AssessmentTemplateDetailId) {
                return (
                  <Form key={AssessmentTemplateDetailId} doc={result} assessmentTemplateId={AssessmentTemplateId} onHandleCancel={handleCancel} onHandleSave={handleSave} index={rowCnt} />
                );
              } else {
                return (

                  <TableRow key={AssessmentTemplateDetailId}>
                    <TableCell align="center">{rowCnt}</TableCell>
                    <TableCell component="th" scope="row">{FeeName}</TableCell>
                    <TableCell>{FeeGroup}</TableCell>
                    <TableCell align="center">{Computation}</TableCell>

                    <TableCell align="center">{Amount}</TableCell>
                    <TableCell>{InterestRate}</TableCell>
                    <TableCell>{InterestType}</TableCell>
                    <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                    <TableCell align="center">
                      <button
                        className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                        onClick={() => handleEdit(AssessmentTemplateDetailId)}
                      >
                        <i className="fa fa-pencil-alt text-white"></i>
                      </button>
                      <Link
                        to={`${enrollmentTypes.MORE_LINK}${AssessmentTemplateId}/${AssessmentTemplateDetailId}`}
                        className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                        <i className="fa fa-list-alt text-white"></i>
                      </Link>
                      <button
                        className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                        onClick={() => setShowDialog({ id: AssessmentTemplateDetailId, record: `${FeeCode}  - ${Amount}  - ${FeeOrder}`, status: !showDialog.status })}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </TableCell>
                  </TableRow>
                );
              }
            }) : ''}
          </TableBody>
          <TableFooter>
            <Form doc={[]} assessmentTemplateId={AssessmentTemplateId} onHandleAdd={handleAdd} onHandleDuplicate={handleDuplicate} />
          </TableFooter>
        </Table>
      </TableContainer>
      {showDialog.status && <IMDialog
        title={`Question`}
        content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
        onHandleDelete={handleDelete}
        onShowDialog={setShowDialog}
        isOpen={true}
      />}
    </>
  );
}