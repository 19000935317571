export const referenceTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_COURSE_OUTLINE: 'SELECTED-COURSE-OUTLINE',
    GET_COURSE_OUTLINES: 'GET-COURSE-OUTLINES',
    GET_COURSE_OUTLINE: 'GET-COURSE-OUTLINE-BY-ID',
    CREATE: 'CREATE-COURSE-OUTLINE',
    UPDATE: 'UPDATE-COURSE-OUTLINE',
    DELETE: 'DELETE-COURSE-OUTLINE',
    LIST_LINK: '/admin/references/course-outlines',
    MORE_LINK: '/admin/references/course-outline-details/',
    REQ_LINK: '/admin/references/course-outline-detail-reqs/',
    CREATE_LINK: '/admin/references/create-course-outline/',
    UPDATE_LINK: '/admin/references/update-course-outline/',
    DELETE_LINK: '/admin/references/delete-course-outline/'
}