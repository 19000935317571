import React, { useState, useEffect } from "react";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";

import { useAppContext } from "../../../../contexts/useAppContext";
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import { studentPayment, updateORNo, studentAutoComplete } from "./__hooks__/proc";
import PrintReceipt from "./PrintReciept";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});
export default function DirectCashierForm(props) {
    const { register, setValue } = useForm()

    const { ORNo, SemesterId, SchoolYear, feeSelect, isOverride } = props
    const { state: { auth }, dispatch } = useAppContext()
    const [paymentDescription, setPaymentDescription] = useState(null)
    const today = moment().format("YYYY-MM-DD");
    const [isPaid, setIsPaid] = useState(false)
    const [paymentDetails, setPaymentDetails] = useState(null)
    const [paymentAmount, setPaymentAmount] = useState(0);
    const [receivedFrom, setReceivedFrom] = useState(null)
    const [feeDescription, setFeeDescription] = useState(null)
    const [inputOrNo, setInputOrNo] = useState(ORNo)
    const [currentStatusName, setCurrentStatusName] = useState(null)
    const [isLoading, setIsLoading] = useState(false)
    const [studentList, setStudentList] = useState([])
    const [studentNo, setStudentNo] = useState(null)
    const [filteredFee, setFilteredFee] = useState(null)
    const [studentId, setStudentId] = useState(null)


    const [isReadyToPay, setIsReadyToPay] = useState(false)
    const [switchState, setSwitchState] = useState({
        isManualPayment: false
    });

    let rowCnt = 0

    // functions

    const onChangeFee = name => event => {
        console.log(event)
        setFilteredFee(event)
    }


    const handleStudentInputChange = async (input) => {
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }

    const handleGetStudent = async (data) => {
        setStudentNo(data.StudentNo)
        setStudentId(data.StudentId)
        console.log(data)
    }

    const onStartNewTransaction = () => {
        props.onStartNewTransaction()
    }


    const handlePaymentChange = (event) => {
        alert('test')
        console.log(event.target.value)
        setPaymentAmount(parseFloat(event.target.value));
    };



    const onSubmit = async () => {
        setIsLoading(true)
        const orNo = ORNo;
        const orDate = today;





        const pureObjects = {

            orNo: inputOrNo,
            orDate: orDate,
            payorType: 'O',
            description: receivedFrom,
            totalAmount: paymentAmount,
            schoolYear: SchoolYear,
            semesterId: SemesterId,
            studentId: studentId,
            studentNo: studentNo,
            feeId: 262,
            otherFeeDescription: feeDescription,
            createdBy: auth.data.Username,
            amountReceived: paymentAmount,
            receivedFrom: receivedFrom

        };

        console.log(pureObjects)

        await studentPayment([pureObjects])
            .then(res => {
                setIsPaid(true)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Payment has been processed successfully' });
                setIsLoading(false)

            })

        await updateORNo({ userId: auth.data.UserId, orNo: inputOrNo })
        setPaymentDetails(pureObjects)

    }


    useEffect(() => {
        if (paymentAmount != 0
            && feeDescription != null && feeDescription != ''
            && paymentDescription != null && paymentDescription != ''
            && receivedFrom != null && receivedFrom != '') {
            setIsReadyToPay(true)
        } else {
            setIsReadyToPay(false)

        }
    }, [paymentAmount, feeDescription, paymentDescription, receivedFrom])

    console.log(filteredFee)
    console.log(ORNo)
    console.log(paymentAmount)
    console.log(paymentDescription)
    console.log(currentStatusName)
    console.log(inputOrNo)


    console.log(feeDescription)
    console.log(paymentDescription)
    console.log(receivedFrom)


    console.log(isReadyToPay)
    return (


        <>
            <main className="row justify-content-center align-items-center mt-5">
                <div className="col-12 col-md-8 col-lg-6">
                    <main className="card p-5 rounded">
                        <header className="p-5">
                            <h4><i className="fa fa-cash-register"></i>  Cashiering <span className="text-primary text-sm">(Direct Payment)</span> </h4>

                        </header>
                        <article className="card-body">
                            <section className="row">
                                <section className="col-12 pr-5">
                                    {isOverride == false ?
                                        <h3 className="">Official Receipt #: <span className="text-success">{ORNo}</span> </h3>
                                        :
                                        <h3 className="d-inline">Official Receipt #: <span className="text-success">
                                            <input onChange={(e) => { setInputOrNo(e.target.value) }} defaultValue={ORNo} name="orNumber" className="form-control lead d-inline mt-3" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" type="number" />
                                        </span> </h3>

                                        // <div className="input-group mt-5 lead">
                                        //     <div className="input-group-prepend">
                                        //         <span className="lead" id="inputGroupPrepend2">O.R #</span>
                                        //     </div>
                                        //     <input onChange={(e) => { setInputOrNo(e.target.value) }} defaultValue={ORNo} name="orNumber" className="form-control lead" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" type="number" />
                                        // </div>
                                    }
                                    <hr />
                                    <div className="input-group pl-0 mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Payment</span>
                                        </div>
                                        {/* this is where i put the amount to be distributted */}
                                        <input
                                            disabled={switchState.isManualPayment}
                                            type="number"
                                            className={switchState.isManualPayment == false ? 'form-control' : 'form-control bg-secondary'}
                                            placeholder="Payment"
                                            aria-label="Payment"
                                            aria-describedby="basic-addon1"
                                            value={paymentAmount}
                                            onChange={e => setPaymentAmount(e.target.value)}
                                        />
                                    </div>
                                    <div className="input-group pl-0 mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Payment for</span>
                                        </div>
                                        <input
                                            disabled={switchState.isManualPayment}
                                            type="text"
                                            className={switchState.isManualPayment == false ? 'form-control' : 'form-control bg-secondary'}
                                            placeholder="Fee Description"
                                            aria-label="Fee Description"
                                            aria-describedby="basic-addon1"
                                            onChange={e => setFeeDescription(e.target.value)}
                                        />
                                    </div>
                                    <div className="input-group pl-0 mb-3 mt-5">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="basic-addon1">Received From</span>
                                        </div>
                                        <input
                                            disabled={switchState.isManualPayment}
                                            type="text"
                                            className={switchState.isManualPayment == false ? 'form-control' : 'form-control bg-secondary'}
                                            placeholder="Received From"
                                            aria-label="Received From"
                                            aria-describedby="basic-addon1"
                                            onChange={e => setReceivedFrom(e.target.value)}
                                        />
                                    </div>
                                    <Autocomplete
                                        id="studentNo"
                                        options={studentList}
                                        getOptionLabel={(option) => option.StudentName}
                                        onChange={(event, value) => {
                                            if (value) {
                                                handleGetStudent(value)
                                                // Handle the selected option
                                            } else {
                                                // Handle the case when no option is selected
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Student Number"
                                                variant="outlined"
                                                inputRef={register}
                                                name="studentNo"
                                                value={studentNo}
                                                onChange={
                                                    (e) => { handleStudentInputChange(e.target.value) }

                                                }
                                            />
                                        )}
                                    />


                                    <div className="form-group">
                                        <label htmlFor="exampleTextarea">Payment Description</label>
                                        <textarea onChange={(e) => { setPaymentDescription(e.target.value) }} className="form-control" id="exampleTextarea" rows="6">{paymentDescription}</textarea>
                                    </div>



                                    {ORNo !== null ?
                                        <>

                                            {isPaid == false ?
                                                <>
                                                    {isLoading == false ?
                                                        <>
                                                            {isReadyToPay == true ?
                                                                <button onClick={() => { onSubmit() }} type="submit" className="mt-5 p-3 btn btn-primary float-right btn-block">Proceed Payment</button>
                                                                :
                                                                <button disabled className="mt-5 p-3 btn btn-secondary float-right btn-block">Proceed Payment</button>

                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            <button className="mt-5 p-3 btn btn-secondary float-right btn-block" type="button" disabled>
                                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"> </span>
                                                                Processing Payment...
                                                            </button>
                                                        </>
                                                    }


                                                </>

                                                :
                                                <>
                                                    {paymentDetails !== null &&
                                                        <PrintReceipt
                                                            orNo={ORNo}
                                                            date={today}
                                                            name={paymentDescription}
                                                            studentNo={''}
                                                            paymentDetails={paymentDetails}
                                                        />
                                                    }

                                                    {/* <button className="mt-5 p-3 btn btn-success float-right btn-block">Print Receipt</button> */}

                                                    <button onClick={() => { onStartNewTransaction() }} className="mt-5 p-3 btn btn-secondary float-right btn-block">Start new transaction</button>
                                                </>

                                            }

                                        </>

                                        :

                                        <button disabled className="btn btn-secondary">Proceed Payment</button>
                                    }


                                    {/* </main> */}
                                </section>

                            </section>

                        </article>
                    </main>
                </div>
            </main>

        </>



    )
}