import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Button } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullCapacityModal(props) {
    const { title, isOpen, handleClose, classCodes } = props;

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
        >
            <DialogTitle>{title}</DialogTitle>

            <hr />
            <DialogContent>
                <p>Selected Class Codes that are full in capacity</p>
                <ul className='container'>
                    {classCodes.map(d => {
                        return (
                            <>
                                <li>{d}</li>
                            </>
                        )
                    })}
                </ul>
            </DialogContent>
            <DialogActions>

                <button onClick={handleClose} className='btn btn-secondary btn-sm'>
                    Confirm
                </button>
            </DialogActions>
        </Dialog>
    );
}