import instance from "../../../../../apis/local/systemAPI";


export const getOnlineReservations = async (formValues) => {
    const result = await instance.post("api/accounting/student/paymentRequestList", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}