import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getApplicantReport, getActiveSemester,
    getActiveSchoolYear, getApplicantDataForUpdate, getCourses, getYearLevel
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import { FormControlLabel, Switch } from "@material-ui/core";
import ExportExcel from "./ExportExcel";

export default function ApplicantReport(props) {
    const { setValue } = useForm();
    const [results, setResults] = useState([])
    const [studentData, setStudentData] = ([])
    const [filteredSem, setFilteredSem] = useState()
    const [filteredSy, setFilteredSy] = useState()
    const [filteredDateFrom, setFilteredDateFrom] = useState(moment().format("YYYY-MM-DD"))
    const [filteredDateTo, setFilteredDateTo] = useState(moment().add({ days: 1 }).format("YYYY-MM-DD"))
    const [filteredCourse, setFilteredCourse] = useState(null)
    const [filteredYearLevel, setFilteredYearLevel] = useState(21)
    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [activeSem, setActiveSem] = useState('1S')
    const [activeSy, setActiveSy] = useState('')
    const [isGeneratingReport, setIsGeneratingReport] = useState(false)
    const [excelFormat, setExcelFormat] = useState([])
    const [excelFormatReservation, setExcelFormatReservation] = useState([])
    const [courseSelect, setCourseSelect] = useState([])
    const [yearLevelSelect, setYearLevelSelect] = useState([])
    const [isFetchingData, setIsFetchingData] = useState(false)
    const [switchState, setSwitchState] = useState({
        IsForReservation: false,

    });

    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectCourseChange = name => event => { setFilteredCourse(event) }
    const selectYearLevelChange = name => event => { setFilteredYearLevel(event) }



    const switchHandleChange = name => event => {
        console.log({ [name]: event.target.checked })
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };


    const onGetApplicantReportData = async (v) => {
        setIsGeneratingReport(true)
        await getApplicantDataForUpdate(v)
            .then(async res => {
                await getApplicantReport(v)
                    .then(resp => {
                        console.log(resp)
                        setResults(resp.data)
                        setStudentData(resp.data)
                        setIsGeneratingReport(false)

                    })
                    .catch(e => {
                        setIsGeneratingReport(false)
                    })


            })
            .catch(e => {
                setIsGeneratingReport(false)
            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }

    const onSubmit = () => {
        setResults([])
        let dataToBeSubmit = {
            dateFrom: filteredDateFrom,
            dateTo: filteredDateTo,
            courseId: filteredCourse,
            yearLevelId: filteredYearLevel,
            isForReservation: switchState.IsForReservation == true ? 1 : 0

        }
        console.log(dataToBeSubmit)
        onGetApplicantReportData(dataToBeSubmit)

    }


    useEffect(() => {
        onSubmit()
    }, [filteredDateFrom, filteredDateTo, filteredCourse, filteredYearLevel, switchState.IsForReservation])
    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')
        SelectCreator(getCourses, setCourseSelect, 'CourseId', 'Code')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')


        const exeGetActiveSy = async () => {
            await getActiveSchoolYear()
                .then(res => {
                    console.log(res)

                    setActiveSy(res.data[0].SchoolYear)
                })
        }

        const exeGetActiveSem = async () => {
            await getActiveSemester()
                .then(res => {
                    console.log(res)
                    setActiveSem(res.data[0].SemesterId)
                })
        }

        exeGetActiveSy()
        exeGetActiveSem()

    }, [])

    useEffect(() => {
        if (results !== undefined) {
            let report1 = []
            let report2 = []
            for (let i = 0; i < results.length; i++) {
                report1.push({
                    'StudentNo': `${results[i].StudentNo}`,
                    'Student Name': `${results[i].StudentName}`,
                    'Course': `${results[i].CourseCode}`,
                    'Year Level': `${results[i].YearLevel}`,
                    'School Year': `${results[i].SchoolYear}`,
                    'Semester': `${results[i].SemesterId}`,
                    'Birthday': `${results[i].BirthDate}`,
                    'Contact No': `${results[i].CelNo}`,
                    'Email': `${results[i].EmailAddress}`,
                    'Last School Attended': `${results[i].LastSchool}`,
                    'School Type': `${results[i].SchoolType}`,
                    'Assessment Date': `${results[i].AssessmentDate}`,
                    'Enrollment Date': `${results[i].EnrollmentDate}`,
                    'Verified By': `${results[i].VerifiedBy}`,
                    'Verification Date': `${results[i].VerificationDate}`,
                    'Reserved By': `${results[i].ReservedBy}`,
                    'Reserved Date': `${results[i].ReservedDate}`,
                    'Is Verified': `${results[i].IsVerified}`,
                    'For Reservation': `${results[i].ForReservation}`,
                    'Application Date': `${moment(results[i].dateCreated).format('YYYY-MM-DD')}`
                })


                report2.push({
                    'StudentNo': `${results[i].StudentNo}`,
                    'Student Name': `${results[i].StudentName}`,
                    'Track': `${results[i].Track}`,
                    'JHS Completed': `${results[i].JrHigh}`,
                    'School Type': `${results[i].JRSchoolType}`,
                    'First College Preferrence': `${results[i].FirstChoiceCourse}`,
                    'Second College Preferrence': `${results[i].SecondChoiceCourse}`,
                    'Reserved Course': `${results[i].CourseCode}`,
                    'First School Preferrence': `${results[i].FirstChoiceSchool}`,
                    'Second College Preferrence': `${results[i].SecondChoiceCourse}`,
                    'Senior High School': `${results[i].SrHigh}`,
                    'School Type': `${results[i].SRSchoolType}`,
                    'Reserved Date': `${results[i].ReservedDate}`,
                    'Reserved Scholarship Grant': `${results[i].ScholarshipGrant}`,
                    'Reservation Status': `${results[i].ReservationStatus}`,
                    'Application Date': `${moment(results[i].dateCreated).format('YYYY-MM-DD')}`
                })
            }

            setExcelFormat(report1)
            setExcelFormatReservation(report2)
        }
    }, [results])

    const columns = [
        { id: 'StudentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'CourseCode', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SchoolYear', label: 'School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'BirthDate', label: 'Birthday', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
        { id: 'CelNo', label: 'ContactNo', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'EmailAddress', label: 'EmailAddress', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LastSchool', label: 'Last School', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FirstChoiceCourse', label: 'Preferred Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SecondChoiceCourse', label: 'Preferred Course 2', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SchoolType', label: 'School Type', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'dateCreated', label: 'Application Date', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
        { id: 'EnrollmentDate', label: 'Enrollment Date ', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
        { id: 'VerifiedBy', label: 'Verified By', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'VerificationDate', label: 'Verification Date', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
        { id: 'ReservedBy', label: 'Reserved By', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ReservedDate', label: 'Reserved Date', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
        { id: 'IsVerified', label: 'Verified', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ForReservation', label: 'For Reservation', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ReservationStatus', label: 'Reservation Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },


    ]

    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        studentData: studentData,
        totalRecords: results.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'applicantReport',
        parentId: null,
        tableTitle: '',

    }

    const imSelectDropdownSchoolYear = () => {
        return <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectSyChange()}
            refClassContainer=""
            name="SchoolYear"
            isRequired={false}
            withLabel={false}
            label="SchoolYear"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={filteredSy === undefined ? activeSy : filteredSy}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }


    const imSelectDropdownSemesters = () => {
        return <IMSelect
            data={semestersSelect}
            onHandleChange={selectSemChange()}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Semester"
            forwardRef={setValue}
            selectedId={filteredSem === undefined ? activeSem : filteredSem}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />
    }


    const imSelectDropdownCourses = () => {
        return <IMSelect
            data={courseSelect}
            onHandleChange={selectCourseChange()}
            refClassContainer=""
            name="courseId"
            isRequired={false}
            withLabel={false}
            label="Course"
            placeHolder="Select Course"
            forwardRef={setValue}
            selectedId={filteredCourse}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />
    }

    const imSelectDropdownYearLevel = () => {
        return <IMSelect
            data={yearLevelSelect}
            onHandleChange={selectYearLevelChange()}
            refClassContainer=""
            name="yearLevelId"
            isRequired={false}
            withLabel={false}
            label="Year Level"
            placeHolder="Select Year"
            forwardRef={setValue}
            selectedId={filteredYearLevel}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />
    }


    console.log(activeSem)
    console.log(activeSy)
    console.log(filteredSem)
    console.log(filteredSy)
    console.log(results)

    console.log(filteredDateFrom)
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"))

    if (excelFormat.length > 0) {
        console.log(excelFormat[0])
        console.log(excelFormat[0][['Prototype']])

    }


    console.log(excelFormatReservation)
    console.log(excelFormat)


    return (
        <>

            <div className="card">
                <div className="card-header font-weight-bold">
                    <h3 className="mb-0 d-block">  &nbsp;Applicant Report</h3>
                    <hr />
                    {isGeneratingReport == false ?
                        <section className="row">
                            <div className="col bg-white my-0 p-0 mr-3">
                                <FormControlLabel
                                    name="IsForReservation"
                                    inputRef={() => { }}
                                    control={<Switch checked={switchState.IsForReservation} onChange={switchHandleChange('IsForReservation')} value={switchState.IsForReservation} />}
                                    label="For Reservation"
                                />

                            </div>
                            <div className="col bg-white my-0 p-0 ">{imSelectDropdownCourses()}</div>
                            <div className="col bg-white my-0 p-0 ml-3">{imSelectDropdownYearLevel()}</div>
                            <div className="col bg-white my-0 p-0 ml-3">
                                <input
                                    type='date'
                                    onChange={(e) => { onDateFromHandler(e.target.value) }}
                                    defaultValue={filteredDateFrom}
                                    style={{
                                        flex: 1,
                                        padding: '0.375rem 0.75rem',
                                        fontSize: '1rem',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%'
                                    }}
                                />
                            </div>
                            <div className="col bg-white my-0 p-0 ml-3">
                                <input
                                    type='date'
                                    onChange={(e) => { onDateToHandler(e.target.value) }}
                                    defaultValue={filteredDateTo}
                                    style={{
                                        flex: 1,
                                        padding: '0.375rem 0.75rem',
                                        fontSize: '1rem',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%'
                                    }}

                                />
                            </div>
                            {results.length > 0 &&
                                <div className="col bg-white my-0 p-0 ml-3">
                                    {switchState.IsForReservation == true ?
                                        <ExportExcel excelData={excelFormatReservation} fileName={'ApplicantReservationReport'} btnName="Reservation Report" />
                                        :
                                        <ExportExcel excelData={excelFormat} fileName={'ApplicantReport'} btnName="Export Report" />

                                    }

                                </div>

                            }

                            {/* <button className="btn btn-sm ">Generate Report</button> */}
                            {/* <button onClick={() => { onSubmit() }} className="py-1 px-1 btn btn-sm btn-primary ml-5">
                         Generate Report
                     </button> */}
                        </section>

                        :

                        <>
                            <div className="d-flex align-items-center">
                                <strong>Loading...</strong>
                                <div className="spinner-border ml-auto" role="status" aria-hidden="true"></div>
                            </div>
                        </>
                    }

                </div>
                {/* <ListFilters
                    selectDropdownSemester={imSelectDropdownSemesters}
                    selectDropdownSchoolYear={imSelectDropdownSchoolYear}
                    selectDropdownCourses={imSelectDropdownCourses}
                    selectDropdownYearLevel={imSelectDropdownYearLevel}
                    onDateFromHandler={onDateFromHandler}
                    onDateToHandler={onDateToHandler}
                    dateFrom={filteredDateFrom}
                    dateTo={filteredDateTo}
                    tableTitle='Filter: '
                    type={'applicantReport'}
                    onSubmit={onSubmit}
                    results={results}
                    excelFormat={excelFormat}
                    applicantReport={1}
                    isGeneratingReport={isGeneratingReport}
                    switchHandleChange={switchHandleChange}
                    switchState={switchState}
                    excelFormatReservation={excelFormatReservation}

                /> */}

                <IMTableCustom tableProps={tableProps} />


            </div>


        </>
    )
}