import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuide, createInstructionalGuide, updateInstructionalGuide,
    deleteInstructionalGuide, getSubjects, getEmployees,
    getGradeTemplate, getSchoolYear, getInstructionalGuideQuery, getEmployeeSelect,
    getGradeAuthenticationList, getGradeAuthenticationListStatus
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { getSemester } from '../ChangeCourse/__hooks__';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';

export default function GradeAuthentication() {
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const activeSem = auth.data.schoolyears.grading.SemesterId
    const activeSy = auth.data.schoolyears.grading.SchoolYear
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(0);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(fees.keyword);
    const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)
    const [onAlert, setOnAlert] = useState(false)
    const [isLoading, setIsLoading] = useState(false)
    const updateId = fees.id;


    const [subjectCodeSelect, setSubjectCodeSelect] = useState(null)
    const [employeeSelect, setEmployeeSelect] = useState(null)
    const [gradeTemplateSelect, setGradeTemplateSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [facultySelect, setFacultySelect] = useState(null)
    const [gradingPeriodSelect, setGradingPeriodSelect] = useState([
        { tableId: 1, code: 'Prelim' },
        { tableId: 2, code: 'Midterm' },
        { tableId: 4, code: 'Final' },
    ])
    const [statusSelect, setStatusSelect] = useState([])

    const [filter, setFilter] = useState({ schoolYear: activeSy, semesterId: activeSem, employeeId: null, gradingPeriodId: 1 })
    const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }



    // table pagination and search -----------------------------------------------------

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        await createInstructionalGuide(val)
            .then(res => {
                setIsOpenAdd(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been saved.' });

            })
    }

    // on update grade element
    const onUpdateElement = async (id, val) => {
        await updateInstructionalGuide(id, val)
            .then(res => {
                setIsOpenUpdate(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been updated.' });
            })
    }

    // on delete grade element
    const onDeleteElement = async (id) => {
        await deleteInstructionalGuide(id)
            .then(res => {
                setIsOpenDelete(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been Deleted.' });
            })
            .catch(e => {

            })
    }

    const onGetGradeBook = async () => {
        setIsLoading(true)
        getFacultySelect({
            instituteId: auth.data.InstituteId,
            courseId: auth.data.CourseId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isDean: auth.data.roles.includes(7) ? true : false,
            isChair: auth.data.roles.includes(6) ? true : false,
        })
        if (auth.data.UserType == 'ADM') {
            await getGradeAuthenticationList({
                userId: auth.data.UserId,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: auth.data.InstituteId,
                courseId: auth.data.CourseId,
                isChair: false,
                isDean: false,
                gradingPeriodId: filter.gradingPeriodId,
                rowsPerPage: rowsPerPage,
                page: page
            })
                .then(async response => {
                    if (response.success) {
                        setResults(response.data);
                        setTotalRecord(response.data.length > 0 ? response.data[0].TotalRecord : 0);
                        setOnAlert(true)

                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        } else if (auth.data.UserType == 'DCI' || auth.data.UserType == 'DEN' || auth.data.UserType == 'DCG' || auth.data.UserType == 'RGD') {
            // if dean

            // if dean and chair
            if (auth.data.UserType == 'DCI' || auth.data.UserType == 'DCG' || auth.data.UserType == 'RGD') {
                await getGradeAuthenticationList({
                    userId: auth.data.UserId,
                    schoolYear: filter.schoolYear,
                    semesterId: filter.semesterId,
                    instituteId: auth.data.InstituteId,
                    courseId: auth.data.CourseId,
                    isChair: true,
                    isDean: true,
                    status: filter.status,
                    employeeId: filter.employeeId,
                    gradingPeriodId: filter.gradingPeriodId,
                    rowsPerPage: rowsPerPage,
                    page: page
                })
                    .then(res => {

                        setResults(res.data);
                        setTotalRecord(res.data.length > 0 ? res.data[0].TotalRecord : 0);
                        setOnAlert(true)
                        setIsLoading(false)


                    })
                //if dean
            } else if (auth.data.UserType == 'DEN') {
                await getGradeAuthenticationList({
                    userId: auth.data.UserId,
                    schoolYear: filter.schoolYear,
                    semesterId: filter.semesterId,
                    instituteId: auth.data.InstituteId,
                    courseId: auth.data.CourseId,
                    isChair: false,
                    isDean: true,
                    status: filter.status,
                    employeeId: filter.employeeId,
                    gradingPeriodId: filter.gradingPeriodId,
                    rowsPerPage: rowsPerPage,
                    page: page
                })
                    .then(res => {

                        setResults(res.data);
                        setTotalRecord(res.data.length > 0 ? res.data[0].TotalRecord : 0);
                        setOnAlert(true)
                        setIsLoading(false)


                    })
                // if chair
            }

        } else if (auth.data.UserType == 'CHR') {
            await getGradeAuthenticationList({
                userId: auth.data.UserId,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: auth.data.InstituteId,
                courseId: auth.data.CourseId,
                isChair: true,
                isDean: false,
                status: filter.status,
                employeeId: filter.employeeId,
                gradingPeriodId: filter.gradingPeriodId,
                rowsPerPage: rowsPerPage,
                page: page
            })
                .then(res => {

                    setResults(res.data);
                    setTotalRecord(res.data.length > 0 ? res.data[0].TotalRecord : 0);
                    setOnAlert(true)
                    setIsLoading(false)


                })
        }

    }

    const onSeachAgain = () => {
        setResults([])
        setOnAlert(false)

    }

    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
    }

    //Update
    const onUpdateGradeElement = (data) => {
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = () => {
        setIsOpenDelete(false)
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }


    const getFacultySelect = async (val) => {
        await getEmployeeSelect(val)
            .then(res => {
                console.log(res)
                setFacultySelect(res.data)
            })
            .catch(e => {
                console.log(e)
            })
    }



    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            classPlanDescription: props.value !== undefined && props.value !== null ? props.value.classPlanDescription : null,
            subjectCode: props.value !== undefined && props.value !== null ? props.value.subjectCode : null,
            employeeId: props.value !== undefined && props.value !== null ? props.value.employeeId : null,
            gradeTemplateId: props.value !== undefined && props.value !== null ? props.value.gradeTemplateId : null,
            schoolYear: props.value !== undefined && props.value !== null ? props.value.schoolYear : null,
            semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : null

        })


        // Selects
        const selectSubjectChange = name => event => { setData({ ...data, subjectCode: event }) }
        const selectEmployeeChange = name => event => { setData({ ...data, employeeId: event }) }
        const selectGradeTemplateChange = name => event => { setData({ ...data, gradeTemplateId: event }) }
        const selectSchoolYearChange = name => event => { setData({ ...data, schoolYear: event }) }
        const selectSemesterChange = name => event => { setData({ ...data, semesterId: event }) }


        console.log(semesters)
        return (
            <>

                <main className='container'>
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="inputEmail4">Class Plan Description</label>
                            <input
                                required type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Short Name"
                                defaultValue={props.value !== undefined && props.value !== null ? props.value.classPlanDescription : ''}
                                onBlur={(e) => { setData({ ...data, classPlanDescription: e.target.value }) }}
                            />
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.subjectCodeSelect}
                                onHandleChange={selectSubjectChange('subjectCode')}
                                refClassContainer=""
                                name="subjectCode"
                                isRequired={false}
                                withLabel={false}
                                label="Subject Code"
                                placeHolder="Select Subject"
                                forwardRef={() => { }}
                                selectedId={data.subjectCode}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.employeeSelect}
                                onHandleChange={selectEmployeeChange('employeeId')}
                                refClassContainer=""
                                name="employeeId"
                                isRequired={false}
                                withLabel={false}
                                label="Instructor"
                                placeHolder="Select Instructor"
                                forwardRef={() => { }}
                                selectedId={data.employeeId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.gradeTemplateSelect}
                                onHandleChange={selectGradeTemplateChange('gradeTemplateId')}
                                refClassContainer=""
                                name="gradeTemplateId"
                                isRequired={false}
                                withLabel={false}
                                label="Grade Template"
                                placeHolder="Select Grade Template"
                                forwardRef={() => { }}
                                selectedId={data.gradeTemplateId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.schoolYearSelect}
                                onHandleChange={selectSchoolYearChange('schoolYear')}
                                refClassContainer=""
                                name="schoolYear"
                                isRequired={false}
                                withLabel={false}
                                label="School Year"
                                placeHolder="Select School Year"
                                forwardRef={() => { }}
                                selectedId={data.schoolYear}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.semesterSelect}
                                onHandleChange={selectSemesterChange('semesterId')}
                                refClassContainer=""
                                name="semesterId"
                                isRequired={false}
                                withLabel={false}
                                label="Semester"
                                placeHolder="Select Semester"
                                forwardRef={() => { }}
                                selectedId={data.semesterId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>



                </main>
            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.subjectCode : ''} `}</span> </p>
                </main>



            </>
        )
    }
    useEffect(() => {
        SelectCreator(getSubjects, setSubjectCodeSelect, 'SubjectCode', 'SubjectCode')
        SelectCreator(getEmployees, setEmployeeSelect, "EmployeeId", "EmployeeName")
        SelectCreator(getGradeTemplate, setGradeTemplateSelect, "GradeTemplateId", "GradeTemplateCode")
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description")
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description")

        getGradeAuthenticationListStatus({
            status: auth.data.UserType
        }).then(res => {
            if (res.data.length > 0)
                setFilter((prevData) => { return { ...prevData, status: res.data[0].tableId } })
            setStatusSelect(res.data)
        })
        getFacultySelect({
            instituteId: auth.data.InstituteId,
            courseId: auth.data.CourseId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isDean: auth.data.roles.includes(7) ? true : false,
            isChair: auth.data.roles.includes(6) ? true : false,
        })
        // SelectCreator(getSubjectSchedDetail, setInstituteSelect, 'InstituteId', 'Description')


    }, [])


    useEffect(() => {

        getFacultySelect({
            instituteId: auth.data.InstituteId,
            courseId: auth.data.CourseId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isDean: auth.data.roles.includes(7) ? true : false,
            isChair: auth.data.roles.includes(6) ? true : false,
        })

    }, [filter])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])


    useEffect(() => {
        setResults([])
        // setOnAlert(false)
        if (auth.data.UserType == 'ADM') {
            getGradeAuthenticationList({
                userId: auth.data.UserId,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: auth.data.InstituteId,
                courseId: auth.data.CourseId,
                isChair: false,
                isDean: false,
                gradingPeriodId: filter.gradingPeriodId,
                rowsPerPage: rowsPerPage,
                page: page
            })
                .then(async response => {
                    if (response.success) {
                        setResults(response.data);
                        setTotalRecord(response.data.length > 0 ? response.data[0].TotalRecord : 0);
                        // setOnAlert(true)

                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        } else if (auth.data.UserType == 'DCI' || auth.data.UserType == 'DEN' || auth.data.UserType == 'DCG') {
            // if dean

            // if dean and chair
            if (auth.data.UserType == 'DCI' || auth.data.UserType == 'DCG') {
                getGradeAuthenticationList({
                    userId: auth.data.UserId,
                    schoolYear: filter.schoolYear,
                    semesterId: filter.semesterId,
                    instituteId: auth.data.InstituteId,
                    courseId: auth.data.CourseId,
                    isChair: true,
                    isDean: true,
                    status: filter.status,
                    employeeId: filter.employeeId,
                    gradingPeriodId: filter.gradingPeriodId,
                    rowsPerPage: rowsPerPage,
                    page: page
                })
                    .then(res => {

                        setResults(res.data);
                        setTotalRecord(res.data.length > 0 ? res.data[0].TotalRecord : 0);
                        setIsLoading(false)


                    })
                //if dean
            } else if (auth.data.UserType == 'DEN') {
                getGradeAuthenticationList({
                    userId: auth.data.UserId,
                    schoolYear: filter.schoolYear,
                    semesterId: filter.semesterId,
                    instituteId: auth.data.InstituteId,
                    courseId: auth.data.CourseId,
                    isChair: false,
                    isDean: true,
                    status: filter.status,
                    employeeId: filter.employeeId,
                    gradingPeriodId: filter.gradingPeriodId,
                    rowsPerPage: rowsPerPage,
                    page: page
                })
                    .then(res => {

                        setResults(res.data);
                        setTotalRecord(res.data.length > 0 ? res.data[0].TotalRecord : 0);
                        setIsLoading(false)


                    })
                // if chair
            }

        } else if (auth.data.UserType == 'CHR') {
            getGradeAuthenticationList({
                userId: auth.data.UserId,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: auth.data.InstituteId,
                courseId: auth.data.CourseId,
                isChair: true,
                isDean: false,
                status: filter.status,
                employeeId: filter.employeeId,
                gradingPeriodId: filter.gradingPeriodId,
                rowsPerPage: rowsPerPage,
                page: page
            })
                .then(res => {

                    setResults(res.data);
                    setTotalRecord(res.data.length > 0 ? res.data[0].TotalRecord : 0);
                    setIsLoading(false)


                })
        }
    }, [page, rowsPerPage])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Title', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FacultyName', label: 'Faculty Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'BlockSection', label: 'Section', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectType', label: 'Type', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'AuthenticationStatus', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'UniqueId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'addUpdateGradeBook',
        parentId: null,
        tableTitle: 'List of Grade Book',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: updateId,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch,
        onUpdate: onUpdateGradeElement,
        onDelete: onDeleteGradeElement
    }
    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))

    console.log(schoolYearSelect)
    console.log(semesterSelect)
    console.log(gradeTemplateSelect)
    console.log(employeeSelect)
    console.log(subjectCodeSelect)
    console.log(facultySelect)

    console.log(auth.data)
    console.log(results)
    console.log(filter)
    console.log(statusSelect)
    return (
        <>
            {schoolYearSelect && semesterSelect && gradeTemplateSelect && employeeSelect && subjectCodeSelect ?


                <>
                    <AddUpdateModal
                        title="Add Instructional Guide"
                        isOpen={isOpenAdd}
                        Content={Content}
                        handleClose={onAddGradeElementClose}
                        isAdd={true}
                        onSubmit={onSaveGradeElement}
                        id="instructionalGuideId"
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        gradeTemplateSelect={gradeTemplateSelect}
                        employeeSelect={employeeSelect}
                        subjectCodeSelect={subjectCodeSelect}


                    />

                    <AddUpdateModal
                        title="Update Instructional Guide"
                        isOpen={isOpenUpdate}
                        Content={Content}
                        value={keysToCamelCase(rowValue)}
                        handleClose={onUpdateGradeElementClose}
                        isUpdate={true}
                        onSubmit={onUpdateElement}
                        id="instructionalGuideId"
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        gradeTemplateSelect={gradeTemplateSelect}
                        employeeSelect={employeeSelect}
                        subjectCodeSelect={subjectCodeSelect}

                    />

                    <AddUpdateModal
                        title="Delete Instructional Guide"
                        isOpen={isOpenDelete}
                        Content={DeleteContent}
                        handleClose={onDeleteGradeElementClose}
                        isDelete={true}
                        onSubmit={onDeleteElement}
                        value={keysToCamelCase(rowDeleteValue)}
                        id="instructionalGuideId"
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        gradeTemplateSelect={gradeTemplateSelect}
                        employeeSelect={employeeSelect}
                        subjectCodeSelect={subjectCodeSelect}

                    />

                    {results.length == 0 && onAlert == false ?
                        <>
                            <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                                <header className='card-header p-5 bg-secondary'>
                                    <h6 className="float-left m-0 mt-4 ml-5">{
                                        auth.data.UserType == 'CHR' ? 'Grade Validation'
                                            : auth.data.UserType == 'DEN' ? 'Grade Authentication'
                                                : auth.data.UserType == 'DCI' || auth.data.UserType == 'DCH' || auth.data.UserType == 'RGR' ? 'Grade Validation / Authentication'
                                                    : ''
                                    } </h6>
                                </header>
                                <div className="card-body">
                                    {onAlert == true && results.length == 0 &&
                                        <div className="alert alert-danger" role="alert">
                                            No Record Found
                                        </div>
                                    }
                                    <p className="card-text">Search</p>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <IMSelect
                                                data={schoolYearSelect}
                                                onHandleChange={filterChange('schoolYear')}
                                                refClassContainer=""
                                                name="schoolYear"
                                                isRequired={false}
                                                withLabel={true}
                                                label="School Year"
                                                placeHolder="Select School Year"
                                                forwardRef={() => { }}
                                                selectedId={filter.schoolYear}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                description={`School Year`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                        </div>
                                    </div>



                                    <div className="form-row">
                                        <div className="form-group col">
                                            <IMSelect
                                                data={semesterSelect}
                                                onHandleChange={filterChange('semesterId')}
                                                refClassContainer=""
                                                name="semesterId"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Semester"
                                                placeHolder="Select Semester"
                                                forwardRef={() => { }}
                                                selectedId={filter.semesterId}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                description={`Semester`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                        </div>
                                    </div>

                                    {facultySelect != null && facultySelect.length > 0 &&
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={facultySelect}
                                                    onHandleChange={filterChange('employeeId')}
                                                    refClassContainer=""
                                                    name="employeeId"
                                                    isRequired={false}
                                                    withLabel={true}
                                                    label="Faculty"
                                                    placeHolder="Select Faculty"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.employeeId}
                                                    refClassName={`text-center`}
                                                    withDescription={false}
                                                    description={`Faculty`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                    }

                                    <div className="form-row">
                                        <div className="form-group col">
                                            <IMSelect
                                                data={gradingPeriodSelect}
                                                onHandleChange={filterChange('gradingPeriodId')}
                                                refClassContainer=""
                                                name="gradingPeriodId"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Grading Period"
                                                placeHolder="Select Period"
                                                forwardRef={() => { }}
                                                selectedId={filter.gradingPeriodId}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                description={`Grading period`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <IMSelect
                                                data={statusSelect}
                                                onHandleChange={filterChange('status')}
                                                refClassContainer=""
                                                name="status"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Status"
                                                placeHolder="Select Status"
                                                forwardRef={() => { }}
                                                selectedId={filter.status}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                description={`Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                        </div>
                                    </div>
                                    <div className="form-row bg-secondary">
                                        {isLoading == false && onAlert == false ?
                                            <button onClick={() => { onGetGradeBook() }} className='btn btn-success btn-block'>Search</button>

                                            :
                                            <button className="btn btn-secondary btn-block" type="button" disabled>
                                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                &nbsp;  Loading...
                                            </button>
                                        }

                                    </div>
                                </div>

                            </main>







                        </>

                        :

                        <>
                            <div className="card card-custom gutter-b example example-compact mx-auto" >
                                <div className="mt-2" style={{ padding: '0px', margin: '0px' }}>
                                    <button onClick={() => { setResults([]); setOnAlert(false); setPage(0) }} className='btn btn-secondary m-5'>Search Again</button>
                                    <IMTable tableProps={tableProps} />
                                </div>
                            </div>

                        </>

                    }












                </>


                :

                <Loading />
            }

        </>

    );
}