import React from "react";
import { useForm } from "react-hook-form";
import { Modal, Box } from "@material-ui/core";

export default function MigrateModal(props) {
    const onSubmit = () => {

    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="card p -5" style={style}>
                <div className="card-header"> <h3>{props.title}</h3> </div>
                <div className="card-body">

                    <p className="card-text">{props.question}</p>


                    <button type="submit" onClick={() => { props.handleSubmit() }} className="mt-5 mr-5 btn btn-primary ">Migrate</button>
                    <button type="button" onClick={() => { props.handleClose() }} className="mt-5 btn btn-secondary ">Cancel</button>


                </div>
            </Box>


        </Modal>
    )
}