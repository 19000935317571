import { number } from "prop-types";
import React, { useRef, forwardRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';



export default function PrintSpecialPermit(props) {
    const { results, permitNo, gradingPeriod, studentSpecialPermitaddUpdate, encoder } = props
    let componentRef = useRef();


    const pageStyle = " @page { size: 7in 4.25in; }"



    const Component = forwardRef((props, ref) => {


        return (
            <>



                <div className="d-none" style={{ backgroundColor: 'white' }}>
                    {/* <style>{pageStyle}</style> */}
                    <style type="text/css" media="print">{"\
                          @page {\ size: portrait;\ margin: 5mm 2mm 5mm 2mm;}\
                        "}</style>

                    <div ref={ref}>
                        <div className="card container m-0" >

                            <main className="border-0 p-5">
                                <>
                                    <section className="row" style={{ pageBreakAfter: "always" }}>
                                        <div className="border-right border-secondary" style={{ display: 'flex', flexDirection: 'column', width: "49%" }}>
                                            <main className="col-12" style={{ flex: '1' }}>
                                                {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                <h5 className="text-center mb-0 ">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h5>
                                                <p className="text-center mt-0 ">#2 President Laurel Highway, Darasa, City of Tanauan, Batangas</p>
                                                <br />
                                                <h4 className="text-center mb-3">Special Exam Permit</h4>
                                                <h4 className="text-center mb-3">
                                                    {gradingPeriod == 1 ? 'PRELIM'
                                                        : gradingPeriod == 2 ? 'MIDTERM'
                                                            : gradingPeriod == 4 ? 'FINALS' :
                                                                ''}

                                                </h4>
                                                <br />
                                                <table className="mx-5" style={{ width: '100%' }}>

                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold">Name</td>
                                                            <td colSpan={4}>&nbsp; {results[0].StudentName}</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Course</td>
                                                            <td >&nbsp; {results[0].CourseCode}</td>
                                                            <td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                            <td className="font-weight-bold">Yr. Level</td>
                                                            <td >&nbsp; {results[0].YearLevel}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Permit No</td>
                                                            <td >&nbsp; {permitNo}</td>
                                                            <td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                            <td className="font-weight-bold">AY/Sem:</td>
                                                            <td >&nbsp;{results[0].SY}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>
                                                {/* <div className="col-8">
                                                        <p className="mb-0"><span className="font-weight-bold">Name:</span>&nbsp;  {results[0].StudentName}</p>
                                                        <p className="mb-0"><span className="font-weight-bold">Course:</span>&nbsp;  {results[0].CourseCode}</p>
                                                        <p className="mb-0"><span className="font-weight-bold">Permit No:</span>&nbsp;  {permitNo}</p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="float-right"><span className="font-weight-bold">Yr. Level:</span>&nbsp;  {results[0].YearLevel}</p>
                                                        <p className="float-right"><span className="font-weight-bold">AY/Sem:</span>&nbsp; {results[0].SY}</p>
                                                    </div> */}
                                                <br />
                                                <table className="table table-sm ">
                                                    <thead>
                                                        <tr className="p-0">
                                                            <th className="text-center" colspan="2" scope="col pb-0">Subject</th>
                                                            <th className="text-center" scope="col pb-0">Proctor Signature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <>
                                                            {results !== null && results.length > 0 &&
                                                                results.map((row, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-center">{row.ClassCode}</td>
                                                                                <td className="text-center">{row.SubjectCode}</td>
                                                                                <td></td>

                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}

                                                        </>
                                                        <tr>
                                                            <td className="text-center">*********</td>
                                                            <td className="text-center">*********</td>
                                                            <td className="text-center">*********</td>

                                                        </tr>
                                                    </tbody>
                                                </table>
                                            </main>

                                            <section className="row  float-right" style={{ marginLeft: '5%', marginBottom: '20px', fontSize: "smaller" }}>
                                                <ol className="p-1">
                                                    <li>Present this permit when taking your examination, NO PERMIT NO EXAM.</li>
                                                    <li>This Permit is subject to audit of the Business Office during Examination Days</li>
                                                    <li>Proctor should sign in each subject on the space provided</li>
                                                </ol>

                                            </section>

                                        </div>
                                        <div className="" style={{ display: 'flex', flexDirection: 'column', width: "49%" }}>
                                            <main className="col-12" style={{ flex: '1' }}>
                                                {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                <h5 className="text-center mb-0 ">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h5>
                                                <p className="text-center mt-0 ">#2 President Laurel Highway, Darasa, City of Tanauan, Batangas</p>
                                                <br />
                                                <h4 className="text-center mb-3">Special Exam Permit</h4>
                                                <h4 className="text-center mb-3">
                                                    {gradingPeriod == 1 ? 'PRELIM'
                                                        : gradingPeriod == 2 ? 'MIDTERM'
                                                            : gradingPeriod == 4 ? 'FINALS' :
                                                                ''}

                                                </h4>
                                                <br />
                                                <table className="mx-5" style={{ width: '100%' }}>

                                                    <tbody>
                                                        <tr>
                                                            <td className="font-weight-bold">Name</td>
                                                            <td colSpan={4}>&nbsp; {results[0].StudentName}</td>

                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Course</td>
                                                            <td >&nbsp; {results[0].CourseCode}</td>
                                                            <td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                            <td className="font-weight-bold">Yr. Level</td>
                                                            <td >&nbsp; {results[0].YearLevel}</td>
                                                        </tr>
                                                        <tr>
                                                            <td className="font-weight-bold">Permit No</td>
                                                            <td >&nbsp; {permitNo}</td>
                                                            <td >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</td>
                                                            <td className="font-weight-bold">AY/Sem:</td>
                                                            <td >&nbsp;{results[0].SY}</td>
                                                        </tr>
                                                    </tbody>
                                                </table>

                                                <br />
                                                <table className="table table-sm ">
                                                    <thead>
                                                        <tr className="p-0">
                                                            <th className="text-center" colspan="2" scope="col pb-0">Subject</th>
                                                            <th className="text-center" scope="col pb-0">Proctor Signature</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        <>
                                                            {results !== null && results.length > 0 &&
                                                                results.map((row, index) => {
                                                                    return (
                                                                        <>
                                                                            <tr>
                                                                                <td className="text-center">{row.ClassCode}</td>
                                                                                <td className="text-center">{row.SubjectCode}</td>
                                                                                <td></td>

                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}

                                                        </>
                                                        <tr>
                                                            <td className="text-center">*********</td>
                                                            <td className="text-center">*********</td>
                                                            <td className="text-center">*********</td>

                                                        </tr>
                                                    </tbody>
                                                </table>

                                            </main>

                                            <section className="row  float-right" style={{ marginLeft: '5%', marginBottom: '20px', fontSize: "smaller" }}>
                                                <ol className="p-1">
                                                    <li>Present this permit when taking your examination, NO PERMIT NO EXAM.</li>
                                                    <li>This Permit is subject to audit of the Business Office during Examination Days</li>
                                                    <li>Proctor should sign in each subject on the space provided</li>
                                                </ol>

                                            </section>
                                        </div>

                                    </section>

                                </>

                            </main>
                        </div>
                    </div>
                </div>
            </>
        )

    })

    const handleBeforePrint = async () => {
        await studentSpecialPermitaddUpdate({
            specialExamPermitId: 0,
            enrollmentId: results[0].EnrollmentId,
            gradingPeriodId: gradingPeriod,
            permitNo: permitNo,
            printedBy: encoder
        })
    }

    const handleAfterPrint = async () => {
        await studentSpecialPermitaddUpdate({
            specialExamPermitId: 0,
            enrollmentId: results[0].EnrollmentId,
            gradingPeriodId: gradingPeriod,
            permitNo: permitNo,
            printedBy: encoder
        })
    }
    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    // pageStyle="@page { size: 2.5in 4in }"

                    trigger={() => <button className="btn btn-sm btn-success mt-5 ">Print Form</button>}
                    content={() => componentRef.current}
                    // onBeforePrint={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}

                />
            </div>



        </>
    )


}