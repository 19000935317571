export const admissionsTypes = {
    SELECTED_APPLICANT_DATA_DETAIL: 'SELECTED-APPLICANT-DATA-DETAIL',
    GET_APPLICANT_DATA_DETAIL: 'GET-APPLICANT-DATA-DETAIL',
    GET_APPLICANT_DATA_DETAIL_BY_ID: 'GET-APPLICANT-DATA-DETAIL-BY-ID',
    CREATE: 'CREATE-APPLICANT-DATA-DETAIL',
    UPDATE: 'UPDATE-APPLICANT-DATA-DETAIL',
    DELETE: 'DELETE-APPLICANT-DATA-DETAIL',
    LIST_LINK_TO_PARENT: '/admin/admissions/applicant-data-entry',
    LIST_LINK: '/admin/admissions/applicant-data-entry-detail/',
    CREATE_ASSESSMENT: '/admin/admissions/create-assessment',
    PROCESS_ENROLLMENT: '/admin/admissions/process-enrollment/',
    CREATE_LINK: '/admin/admissions/create-applicant-data-entry',
    UPDATE_LINK: '/admin/admissions/update-applicant-data-entry-detail',
    DELETE_LINK: '/admin/admissions/delete-applicant-data-entry-detail',
    ASSESSMENT_LINK: '/admin/admissions/student-pre-assessment',
    ASSESSMENT: '/admin/admissions/create-assessment/'

}