import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { deleteCourseOutline } from './__hooks__';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import { NoAccess } from '../../../../../_metronic/layout/components/custom/forms/NoAccess';


export default function Delete(props) {
  const { state: { courseOutlines, auth }, dispatch } = useAppContext();
  const { id } = props.match.params;
  const [keepLoading, setKeepLoading] = useState(true)
  if (courseOutlines.data.length === 0) {
    history.push(referenceTypes.LIST_LINK);
    return null;
  }
  const courseOutline = courseOutlines.data[id];

  const onClickDelete = () => {
    const execute = async () => {
      await deleteCourseOutline(id)
        .then(response => {
          if (response.success) {
            dispatch({ type: referenceTypes.DELETE, data: id });
            history.push(referenceTypes.LIST_LINK);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  setTimeout(function () {
    setKeepLoading(false)
  }, 5000);
  return (
    <>

      {auth.data.UserType === 'DEN'
        || auth.data.UserType === 'RGR'
        || auth.data.UserType === 'ADM'

        ?
        <div className="kt-container  kt-grid__item kt-grid__item--fluid">
          <div className="row ac-minimum-height-container-350">
            <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
              <div className="card card-custom gutter-b example example-compact">
                <div className="card-body">
                  <h3>Question</h3>
                  <p>Are you sure you want to delete this record ( <b className="text-primary">{courseOutline === undefined ? null : `${courseOutline.CourseOutline} - ${courseOutline.Description}`}</b> ) ?</p>
                  <Link to={referenceTypes.LIST_LINK} className="btn btn-lg btn-bold btn-upper btn-font-sm btn-secondary">No</Link>&nbsp;
                  <button className="btn btn-lg btn-bold btn-upper btn-font-sm btn-success" onClick={onClickDelete} >Yes</button>
                </div>
              </div>
            </div>
          </div>
        </div>

        :

        <>
          <div className="card p-5">

            {keepLoading === true
              ?
              <Loading />
              :

              <>
                {auth.data.UserType === 'DEN'
                  || auth.data.UserType === 'RGR'
                  || auth.data.UserType === 'ADM'

                  ? ''

                  : <NoAccess />
                }
              </>
            }
          </div>

        </>


      }

    </>
  );
}