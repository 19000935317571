import React, { useEffect, useMemo, useState } from "react";
import checkLogo from "./assets/check.png"
import history from "../../../history";
import FaithLogo from './assets/lb-logo.png'
import { applicantTypes } from "./__hooks__/types";
import { useAppContext } from '../../../../app/contexts/useAppContext';
import { Switch, Route } from 'react-router-dom';
// import { useHtmlClassService } from "../../../../_core/MetronicLayout";
// import { Header } from "../../../../components/header/Header";
// import { HeaderMobile } from "../../../../components/header-mobile/HeaderMobile";
// import { Aside } from "../../../../components/aside/Aside";
// import { Footer } from "../../../../components/footer/Footer";
// import { LayoutInit } from "../../../LayoutInit";
// import { SubHeader } from "../../../../components/subheader/SubHeader";
// import { QuickPanel } from "../../../../components/extras/offcanvas/QuickPanel";
// import { QuickUser } from "../../../../components/extras/offcanvas/QuickUser";
// import { ScrollTop } from "../../../../components/extras/ScrollTop";
// import { StickyToolbar } from "./extras/StickyToolbar";
import routes from '../../../routes';
import firebase from '../../../apis/firebase/firebase.config';
import { loginWithGoogle } from '../../Auth/__hooks__';
import { authTypes } from '../../Auth/__hooks__/types';
import { login, userHasAccess, isRequiredPWChange } from '../../Auth/__hooks__/';
import { componentTypes } from '../../../../_metronic/layout/components/snackbar/__hooks__/types';
// import CustomSnackbar from '../../../../components/snackbar/snackbar';
// import PageNotFound from "../../../redirects/PageNotFound/PageNotFound";
import { NoAccess } from "../../../../_metronic/layout/components/custom/forms/NoAccess";
import CryptoJS from "crypto-js";

export default function PlainReportLayout(props) {

    // const onReturnHandler = () => {
    //     history.push(`/student-registration`);
    // }

    const { state: { auth, snackbar }, dispatch } = useAppContext();
    const [routePaths, setRoutePaths] = useState([]);

    useEffect(() => {
        if (auth.loginType === 'L') {
          // LOCAL LOGIN
          if (!localStorage.getItem('Username') && !localStorage.getItem('Password')) {
            history.push('/auth/login');
          } else {
            if (!auth.isUserVerified) {
              const formValues = {
                emailAddress: localStorage.getItem('Username'),
                password: CryptoJS.AES.decrypt(localStorage.getItem('Password'), localStorage.getItem('AccessToken')).toString(CryptoJS.enc.Utf8)
              }
              console.log(CryptoJS.AES.decrypt(localStorage.getItem('Password'), localStorage.getItem('AccessToken')))
              const execute = async () => {
                await login(formValues)
                  .then(result => {
                    if (result.success) {
                      dispatch({ type: authTypes.LOGIN, data: result.data, loginType: 'L' });
                    } else {
                      history.push('/auth/login');
    
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                  })
              }
              execute();
            }
          }
        } else if (auth.loginType === 'G') {
          // FIREBASE GOOGLE AUTH
          firebase.auth().onAuthStateChanged(function (user) {
            if (user) {
              const execute = async () => {
                const response = await loginWithGoogle(user.refreshToken, user.email);
                if (response.success) {
                  dispatch({ type: authTypes.LOGIN_WITH_GOOGLE, accessToken: user.refreshToken, data: user.providerData[0], isAdmin: response.data.IsAdmin, roleId: response.data.RoleId, designation: response.data.Designation, defaults: response.defaults.data });
                }
              }
              execute();
            } else {
              // USER IS SIGNED OUT
              history.push('../auth/login');
            }
          });
        } else if (auth.loginType === 'F') {
          // FIREBASE FACEBOOK AUTH
        }
      }, [dispatch, auth])
    
    
      useEffect(() => {
        const url = history.location.pathname.split('/');
        const mainPath = url
          .slice(1)
          .map((part) => (isNaN(part) ? part : ':id'))
          .join('/');
    
    
        console.log(auth.data)
        console.log(url)
        console.log(mainPath)

        if (auth.data.UserId != undefined) {
          isRequiredPWChange({ userId: auth.data.UserId })
            .then(res => {
              console.log("isRequiredPWChange: ", );
              if (res.data.IsDefaultPassword == 1) {
                // setChangePassword(1);
                history.push({
                  pathname: "/admin/user/profile",
                  data: 1
                });
              }
            })
        }

        if (auth.data.UserId != undefined) {
          userHasAccess({ userId: auth.data.UserId, routePath: '/' + mainPath })
            .then(res => {
              console.log(res.data.HasAccess)
              if (res.data.HasAccess == 1) {
                setRoutePaths(() => {
                  return routes
                })
    
              } else {
                history.push('/admin/NoAccess')
                setRoutePaths(() => {
                  return (
                    [
                      {
                        path: "/NoAccess",
                        name: "Page Not Found",
                        component: NoAccess,
                        layout: "/admin",
                        subLayout: "",
                        breadcrumbs: [{
                          title: "Page Not Found",
                          pathname: "/admin/NoAccess"
                        }]
                      }
                    ]
                  );
                })
    
              }
            })
          setRoutePaths(() => {
            return routes
          })
        }
    
      }, [auth, history.location.pathname])

    const getRoutes = routes => {
        console.log(auth)
        console.log(routePaths)
        // alert('TestROute')
    
    
        return (
          <>
            {routePaths &&
              <Switch>
                {routePaths.map((props, key) => {
                  // console.log(props)
                  if (props.layout === "/report") {
                    //console.log(props);
                    //console.log(key);
                    return (
                      <Route
                        path={props.layout + props.path}
                        component={props.component}
                        key={key}
                      />
                    );
                  } else {
                    return null;
                  }
                })}
              </Switch>
            }
    
          </>
        )
      };
    

    return (
       <>
        <div className="container-fluid">
            {auth.isUserVerified && getRoutes(routePaths)}
        </div>
       </>
    )
}