import React, { useState, useEffect } from "react";
import { searchStudent, getSchoolYear, getSemester, getActiveSchoolYear, getActiveSemester } from "./__hooks__/index"
import { useAppContext } from "../../../../contexts/useAppContext";
import { useForm } from "react-hook-form";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { TextField } from "@material-ui/core";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import history from "../../../../history";

export default function AssessmentPrinting() {
    const { dispatch, state: { auth } } = useAppContext();
    const { setValue, handleSubmit, register } = useForm();

    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState()
    const [semSelect, setSemSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const [filteredSy, setFilteredSy] = useState(null)
    const [filteredSem, setFilteredSem] = useState(null)

    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear


    const executeSearchStudent = async (val) => {
        await searchStudent(val)
            .then(res => {
                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found, Try Migrating Student In Campus'
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }


    const onSubmit = (formValues) => {
        let data = {
            ...formValues,
            semesterId: filteredSem == null ? activeSem : filteredSem,
            schoolYear: filteredSy == null ? activeSy : filteredSy
        }

        executeSearchStudent(data)

    }

    const onPrint = () => {
        history.push(`/admin/student/create-assessment/${student.EnrollmentId}`)
    }

    const onBack = () => {
        setStudent(null)
    }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }


    useEffect(() => {
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')



    }, [])


    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === null ? activeSy : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === null ? activeSem : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    console.log(student)
    console.log(activeSem)
    console.log(activeSy)

    return (
        <>
            <div className="container">
                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "Old Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <hr />


                            </div>


                        </div>
                        <div className="col-12 col-xl-8">


                            <div className="p-5 mb-5 ">
                                {student === null ?

                                    <form onSubmit={handleSubmit(onSubmit)} method="post">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Search</h3>
                                        <div className="bg-white mb-3 p-0">{imSelectDropdownSchoolYear()}</div>
                                        <div className="bg-white mb-3 p-0">{imSelectDropdownSemester()}</div>
                                        <TextField
                                            id="outlined-basic"
                                            label="Student No"
                                            variant="outlined"
                                            name="studentNo"
                                            value={studentNo}
                                            inputRef={register({
                                                required: true
                                            })}
                                            className="bg-white mt-4"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setStudentNo(e.target.value)
                                            }}
                                        />

                                        <button type="submit" className="mt-3 btn btn-primary">Search</button>
                                    </form>

                                    :
                                    ""
                                }


                                {student !== null &&
                                    <>
                                        <button onClick={() => { onPrint() }} className="btn btn-primary">Print Assessment</button>
                                        <button onClick={() => { onBack() }} className="btn btn-secondary ml-5 ">Back</button>

                                    </>

                                }




                            </div>


                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}