import { setYear } from "date-fns";
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import Form from "./Form"
import {
    getStudent, getSchoolHistory, getSem, getSchoolYear, getEnrollment,
    getScholarship, getStudentDiscount, getStudyTypes, getSchool,
    getStudentInfo, getCourses, getYearLevel, getFamily, getPreferredCourses,
    getCoursesSelect
} from "./__hooks__/index"
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import { useAppContext } from "../../../../contexts/useAppContext";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
export default function ApplicantVerification() {
    const { state: { auth } } = useAppContext()
    const { id } = useParams()
    const [student, setStudent] = useState()
    const [schoolHistory, setSchoolHistory] = useState()
    const activeSem = auth.data.schoolyears.admissions.SemesterId
    const activeSy = auth.data.schoolyears.admissions.SchoolYear
    const [enrollment, setEnrollment] = useState()
    const [scholarship, setScholarship] = useState()
    const [studentDiscount, setStudentDiscount] = useState()
    const [studyTypes, setStudyTypes] = useState()
    const [school, setSchool] = useState()
    const [studentInfo, setStudentInfo] = useState()
    const [course, setCourse] = useState()
    const [studentPreferred, setStudentPreferred] = useState()
    const [yearLevel, setYearLevel] = useState()
    const [family, setFamily] = useState()
    const [courseSelect, setCourseSelect] = useState()
    const Query = (operation, condition, setData) => {
        console.log(condition)
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }
    let getPreferredCoursesQuery = "{ where: `WHERE SFC.StudentInfoId = ${student[0].StudentInfoId}` }"
    let getActiveQuery = "{ where: 'WHERE IsActive = 1' }"
    let studentQuery = " { where: `WHERE s.StudentId = ${parseInt(id)}` }"
    let schoolHistoryQuery = " { where: `WHERE student_school_history.StudentInfoId = ${student[0].StudentInfoId}` }"
    let getEnrollmentQuery = "{ where: `WHERE e.StudentId = ${parseInt(id)} AND e.SemesterId = '${activeSem.SemesterId}' AND e.SchoolYear = ${activeSy.SchoolYear}` }"
    let getStudentDiscountQuery = "{ where: `WHERE StudentId = ${parseInt(id)} AND IsActive = 1` }"
    let studentInfoQuery = "{ where: `WHERE student_infos.StudentInfoId = ${student[0].StudentInfoId}` }"
    let getCourseQuery = "{ where: `WHERE CourseId = ${student[0].CourseId}` }"
    let getYearLevelQuery = "{ where: `WHERE YearLevelId = ${student[0].YearLevelId}` }"
    let getFamilyQuery = "{ where: `WHERE student_family_background.StudentInfoId = ${studentInfo[0].StudentInfoId}` }"
    let getSchoolQuery = "{ where: `WHERE SchoolId IN (${schoolHistory.map(d => { return d.SchoolId })})`}"
    useEffect(() => {
        //student
        // Query(getStudent, studentQuery, setStudent)
        getStudent({ studentId: parseInt(id) })
            .then(res => {
                setStudent(res.data)
            })

        // scholarship
        Query(getScholarship, getActiveQuery, setScholarship)
        // study types
        Query(getStudyTypes, null, setStudyTypes)



    }, [])

    useEffect(() => {
        if (student !== undefined) {
            // school history
            Query(getSchoolHistory, schoolHistoryQuery, setSchoolHistory)
            // student discount
            Query(getStudentDiscount, getStudentDiscountQuery, setStudentDiscount)
            // studentInfo
            Query(getStudentInfo, studentInfoQuery, setStudentInfo)
            // course
            Query(getCourses, getCourseQuery, setCourse)
            // student preferred course
            Query(getPreferredCourses, getPreferredCoursesQuery, setStudentPreferred)

            SelectCreator(getCoursesSelect, setCourseSelect, 'CourseId', 'Description')

            // year level
            Query(getYearLevel, getYearLevelQuery, setYearLevel)


        }
    }, [student])

    useEffect(() => {
        if (activeSem !== undefined && activeSy !== undefined) {
            // enrollment
            Query(getEnrollment, getEnrollmentQuery, setEnrollment)

        }
    }, [activeSem, activeSy])

    useEffect(() => {
        if (studentInfo !== undefined) {
            // family
            Query(getFamily, getFamilyQuery, setFamily)

        }
    }, [studentInfo])

    useEffect(() => {
        if (schoolHistory !== undefined) {
            // school
            Query(getSchool, getSchoolQuery, setSchool)

        }
    }, [schoolHistory])

    console.log(parseInt(id))
    console.log(auth)
    console.log(student)
    console.log(schoolHistory)
    console.log(activeSem)
    console.log(activeSy)
    console.log(enrollment)
    console.log(scholarship)
    console.log(studyTypes)
    console.log(studentInfo)
    console.log(course)
    console.log(yearLevel)
    console.log(family)
    console.log(studentPreferred)
    return (
        <>
            {student !== undefined && schoolHistory !== undefined && activeSem !== undefined && activeSy !== undefined
                && scholarship !== undefined && studyTypes !== undefined && studentInfo !== undefined && course !== undefined && yearLevel !== undefined && family !== undefined

                ?

                <Form
                    student={student}
                    schoolHistory={schoolHistory}
                    activeSem={activeSem}
                    activeSy={activeSy}
                    enrollment={enrollment}
                    scholarship={scholarship}
                    studentDiscount={studentDiscount}
                    studentPreferred={studentPreferred}
                    studyTypes={studyTypes}
                    studentInfo={studentInfo}
                    course={course}
                    yearLevel={yearLevel}
                    family={family}
                    school={school}
                    courseSelect={courseSelect}


                />

                :

                ""


            }

        </>
    )
}