import instance from "../../../../../apis/local/systemAPI";


export const getSubjectList = async (formValues) => {
    const result = await instance.post("api/instructor/account/listOfSubjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentList = async (formValues) => {
    const result = await instance.post("api/instructor/account/listOfStudents", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchoolYear = async () => {
    const result = await instance.get(`api/references/schoolyears/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get(`api/references/semesters/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


