import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IMDialogDuplicateAssessmentFee(props) {
    const { isOpen, onShowDialog,
        isAssessment, onDeletingClose, executeRejectOutsideCurriculum,
        isCurriculum, onHandleDuplicate, onHandleAdd } = props;
    const [open, setOpen] = useState(isOpen);

    const handleClose = () => {
        onShowDialog(false);
        setOpen(false);
        {
            isCurriculum === true &&
                executeRejectOutsideCurriculum()
        }
    }

    const handleCloseAssessment = () => {
        // onShowDialog({ status: false });
        onDeletingClose()
        setOpen(false);
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={isAssessment !== true ? handleClose : handleCloseAssessment}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Duplicate Entry</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        Do you want to duplicate the entry?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>


                    <button onClick={() => { onHandleDuplicate(); handleClose() }} className="btn btn-primary">
                        Duplicate
                    </button>
                    <button onClick={() => { onHandleAdd(); handleClose() }} className="btn btn-primary">
                        Add
                    </button>
                    <button onClick={handleClose} className="btn btn-secondary">
                        Cancel
                    </button>


                </DialogActions>
            </Dialog>
        </>
    );
}