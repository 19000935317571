import instance from "../../../../../apis/local/systemAPI";

// get payment add on
export const getAssessmentAddOns = async () => {
    const result = await instance.get("api/assessment/add-ons");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentAddOnsQuery = async (con) => {
    const result = await instance.post("api/assessment/add-ons/q", con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
// create payment add on
export const createAssessmentAddOns = async (formValues) => {
    const result = await instance.post("api/assessment/add-ons", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// update payment add ons
export const updateAssessmentAddOns = async (id, formValues) => {
    const result = await instance.patch(`api/assessment/add-ons/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// delete payment add ons
export const deleteAssessmentAddOns = async (id) => {
    const result = await instance.delete(`api/assessment/add-ons/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSem = async (condtion) => {
    const result = await instance.post("api/references/semesters/q", condtion);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAllSem = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAllInstitute = async () => {
    const result = await instance.get("api/references/institutes");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourse = async (condtion) => {
    const result = await instance.post("api/references/courses/q", condtion);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAllCourse = async () => {
    const result = await instance.get("api/references/courses");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevel = async (condtion) => {
    const result = await instance.post("api/references/yearlevels/q", condtion);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAllYearLevel = async () => {
    const result = await instance.get("api/references/yearlevels");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSchoolYear = async (condition) => {
    const result = await instance.post("api/references/schoolyears/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAllSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFees = async () => {
    const result = await instance.get("api/references/fees");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}