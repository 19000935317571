import React from "react";
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';

export default function ChedExcelFormat({ excelData, fileName, btnName }) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const exportToExcel = () => {
        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        // Add header with two rows

        worksheet.mergeCells('A1:A2'); // Merge cells for the first row
        worksheet.getCell('A1').value = 'Student No';
        worksheet.mergeCells('B1:B2'); // Merge cells for the first row
        worksheet.getCell('B1').value = 'Surname';
        worksheet.mergeCells('C1:C2'); // Merge cells for the first row
        worksheet.getCell('C1').value = 'First Name';
        worksheet.mergeCells('D1:D2'); // Merge cells for the first row
        worksheet.getCell('D1').value = 'Middle Name';
        worksheet.mergeCells('E1:E2'); // Merge cells for the first row
        worksheet.getCell('E1').value = 'Suffix';
        worksheet.mergeCells('F1:F2'); // Merge cells for the first row
        worksheet.getCell('F1').value = 'Gender';
        worksheet.mergeCells('G1:G2'); // Merge cells for the first row
        worksheet.getCell('G1').value = 'Nationality';
        worksheet.mergeCells('H1:H2'); // Merge cells for the first row
        worksheet.getCell('H1').value = 'Year Level';
        worksheet.mergeCells('I1:I2'); // Merge cells for the first row
        worksheet.getCell('I1').value = 'Program';
        worksheet.mergeCells('J1:J2'); // Merge cells for the first row
        worksheet.getCell('J1').value = 'Major';
        worksheet.mergeCells('K1:M1'); // Merge cells for the first row
        worksheet.getCell('K1').value = 'Code / Subject Units';
        worksheet.getCell('K1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('K2').value = 'Subject Code';
        worksheet.getCell('L2').value = 'Subject Description';
        worksheet.getCell('M2').value = 'Units';
        worksheet.mergeCells('N1:P1'); // Merge cells for the first row
        worksheet.getCell('N1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('N1').value = 'Code / Subject Units';
        worksheet.getCell('N2').value = 'Subject Code';
        worksheet.getCell('O2').value = 'Subject Description';
        worksheet.getCell('P2').value = 'Units';
        worksheet.mergeCells('Q1:S1'); // Merge cells for the first row
        worksheet.getCell('Q1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('Q1').value = 'Code / Subject Units';
        worksheet.getCell('Q2').value = 'Subject Code';
        worksheet.getCell('R2').value = 'Subject Description';
        worksheet.getCell('S2').value = 'Units';
        worksheet.mergeCells('T1:V1'); // Merge cells for the first row
        worksheet.getCell('T1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('T1').value = 'Code / Subject Units';
        worksheet.getCell('T2').value = 'Subject Code';
        worksheet.getCell('U2').value = 'Subject Description';
        worksheet.getCell('V2').value = 'Units';
        worksheet.mergeCells('W1:Y1'); // Merge cells for the first row
        worksheet.getCell('W1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('W1').value = 'Code / Subject Units';
        worksheet.getCell('W2').value = 'Subject Code';
        worksheet.getCell('X2').value = 'Subject Description';
        worksheet.getCell('Y2').value = 'Units';
        worksheet.mergeCells('Z1:AB1'); // Merge cells for the first row
        worksheet.getCell('Z1:AB1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('Z1').value = 'Code / Subject Units';
        worksheet.getCell('Z2').value = 'Subject Code';
        worksheet.getCell('AA2').value = 'Subject Description';
        worksheet.getCell('AB2').value = 'Units';
        worksheet.mergeCells('AC1:AE1'); // Merge cells for the first row
        worksheet.getCell('AC1:AE1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('AC1').value = 'Code / Subject Units';
        worksheet.getCell('AC2').value = 'Subject Code';
        worksheet.getCell('AD2').value = 'Subject Description';
        worksheet.getCell('AE2').value = 'Units';
        worksheet.mergeCells('AF1:AH1'); // Merge cells for the first row
        worksheet.getCell('AF1:AH1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('AF1').value = 'Code / Subject Units';
        worksheet.getCell('AF2').value = 'Subject Code';
        worksheet.getCell('AG2').value = 'Subject Description';
        worksheet.getCell('AH2').value = 'Units';
        worksheet.mergeCells('AI1:AK1'); // Merge cells for the first row
        worksheet.getCell('AI1:AK1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('AI1').value = 'Code / Subject Units';
        worksheet.getCell('AI2').value = 'Subject Code';
        worksheet.getCell('AJ2').value = 'Subject Description';
        worksheet.getCell('AK2').value = 'Units';
        worksheet.mergeCells('AL1:AN1'); // Merge cells for the first row
        worksheet.getCell('AL1:AN1').alignment = { horizontal: 'center', vertical: 'middle' };
        worksheet.getCell('AL1').value = 'Code / Subject Units';
        worksheet.getCell('AL2').value = 'Subject Code';
        worksheet.getCell('AM2').value = 'Subject Description';
        worksheet.getCell('AN2').value = 'Units';
        worksheet.mergeCells('AO1:AO2'); // Merge cells for the first row
        worksheet.getCell('AO1').value = 'Total Units';
        // Add headers
        const headers = Object.keys(excelData[0]);
        // worksheet.addRow(headers);

        // Add data rows
        excelData.forEach((data) => {
            const row = [];
            headers.forEach((header) => {
                row.push(data[header]);
            });
            worksheet.addRow(row);
        });


        // Adjust column widths based on content
        worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? cell.value.toString().length : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        // Generate a blob from the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        });
    };

    return (
        <>
            <button onClick={exportToExcel} className="col btn btn-sm btn-success">{btnName}</button>
        </>
    );
}
