export const referenceTypes = {

    MORE_LINK: '/admin/student/receive-documents-form/',
    SELECTED_STUDENT_INFO: 'SELECTED-STUDENT-INFO',
    GET_STUDENT_INFO: 'GET-STUDENT-INFO',
    GET_STUDENT_INFO_BY_ID: 'GET-STUDENT-INFO-BY-ID',
    CREATE: 'CREATE-APPLICANT',
    UPDATE: 'UPDATE-APPLICANT',
    DELETE: 'DELETE-APPLICANT',
    LIST_LINK: '/admin/student/applicant-data-entry/',
    CREATE_LINK: '/admin/student/create-applicant-data-entry/new-applicant',
    UPDATE_LINK: '/admin/student/create-applicant-data-entry/',
    DELETE_LINK: '/admin/student/delete-applicant-data-entry/',
    ASSESSMENT_LINK: '/admin/student/create-assessment/',
    CREATE_LINK_DETAIL: '/admin/student/create-applicant-data-entry-detail'


}