export const referenceTypes = {
    SELECTED_MODE_OF_PAYMENT: 'SELECTED-MODE-OF-PAYMENT',
    GET_MODE_OF_PAYMENTS: 'GET-MODE-OF-PAYMENTS',
    GET_MODE_OF_PAYMENT: 'GET-MODE-OF-PAYMENT-BY-ID',
    CREATE: 'CREATE-MODE-OF-PAYMENT',
    UPDATE: 'UPDATE-MODE-OF-PAYMENT',
    DELETE: 'DELETE-MODE-OF-PAYMENT',
    LIST_LINK: '/admin/references/mode-of-payments',
    CREATE_LINK: '/admin/references/create-mode-of-payments/',
    UPDATE_LINK: '/admin/references/update-mode-of-payments/',
    DELETE_LINK: '/admin/references/delete-mode-of-payments/'
}