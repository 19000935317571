import { number } from "prop-types";
import React, { useRef, forwardRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckLogo from '../../../../../_metronic/_assets/logo/check.png'
import moment from "moment";


const useStyles = makeStyles({
    table: {
        minWidth: 650
    },

});


function calculateFontSize(stringLength, maxLength, minFontSize = 8, identifier) {
    // Handle edge cases where string_length is 0 or exceeds max_length
    if (identifier == 'yeah')
        console.log(stringLength)
    if (stringLength === 0) {
        return maxLength; // No text, so use full space
    } else if (stringLength > maxLength) {
        return minFontSize; // String too long, use minimum size
    }

    // Calculate ratio considering minimum font size
    const ratio = Math.min(1, stringLength / maxLength);
    const fontSize = 1 - ratio * (1 - minFontSize / 18);

    // Apply a minimum font size
    return Math.max(minFontSize, fontSize * 18);
}

function checkOverflow(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}


export default function PrintStudent201(props) {
    const { results } = props
    let componentRef = useRef();
    const classes = useStyles();


    const Component = forwardRef((props, ref) => {
        const pageStyle = " @page { size:  8.5in 13in; }"
        console.log(results)
        return (
            <>




                <div style={{ display: 'none', backgroundColor: 'white' }}>
                    <style>{pageStyle}</style>
                    <div ref={ref}>

                        <main className="container" style={{ fontFamily: 'Open Sans', fontWeight: 'bold', color: 'black', backgroundColor: 'white' }}>
                            {/* header 1*/}
                            <section className="d-flex justify-content-between">
                                <img style={{ width: "400px", marginLeft: '2%', height: '100px' }} src={FaithLogo} />
                                <div className="d-flex align-items-center">
                                    <h5 className=" mb-5" style={{ fontWeight: 'bold' }}>APPLICATION FOR COLLEGE ADMISSION</h5>
                                </div>
                            </section>
                            {/* header 1*/}
                            <section className="row mt-1">
                                <h5 className="col mt-2" style={{ fontWeight: 'bold', fontSize: '15px' }}>A. STUDENT INFORMATION</h5>
                                <h5 className="col mt-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>STUDENT NO.   {results.StudentNo ? results.StudentNo.split('').map((letter, index) => (
                                    <span key={index} style={{ border: '1px solid black', padding: '2px', margin: '2px', display: 'inline-block', width: '20px', textAlign: 'center', fontWeight: 'normal' }}>{letter}</span>
                                )) : '###########'.split('').map((letter, index) => (
                                    <span key={index} style={{ border: '1px solid black', padding: '2px', margin: '2px', display: 'inline-block', width: '20px', textAlign: 'center', fontWeight: 'normal' }}>{letter}</span>
                                ))}</h5>
                            </section>
                            {/* header 2*/}
                            <section className="row mt-1">
                                <h5 className="col-8 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>NAME OF STUDENT (As stated in Birth Certificate. Please PRINT)</h5>

                            </section>
                            {/* name section*/}
                            <section className="mb-1">
                                <section className="row">
                                    <div className="col mr-3 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: `${calculateFontSize(results.LastName && results.LastName.length, 50)}px`, fontWeight: 'normal' }}>{results.LastName}</span>
                                    </div>
                                    <div className="col mr-3 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: `${calculateFontSize(results.FirstName && results.FirstName.length, 50)}px`, fontWeight: 'normal' }}>{results.FirstName}</span>
                                    </div>
                                    <div className="col  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: `${calculateFontSize(results.MiddleName && results.MiddleName.length, 50)}px`, fontWeight: 'normal' }}>{results.MiddleName}</span>
                                    </div>
                                </section>
                                <section className="row mt-1">
                                    <div className="col text-center">
                                        <label style={{ fontWeight: 'normal' }}>(Last Name)</label>
                                    </div>
                                    <div className="col text-center">
                                        <label style={{ fontWeight: 'normal' }}>(First Name)</label>
                                    </div>
                                    <div className="col text-center">
                                        <label style={{ fontWeight: 'normal' }}>(Middle Name)</label>
                                    </div>
                                </section>
                            </section>

                            {/* Section 2 gender, civil*/}
                            <section className="row d-flex justify-content-between  mr-5">
                                <div className="col-6 row align-items-end " >
                                    <h5 className="d-inline col-3 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>GENDER</h5>
                                    <h5 className="d-inline col-1 mr-5 p-0 mb-0" style={{ fontWeight: 'normal', fontSize: '15px' }}>Male</h5>
                                    <span className="d-inline col-1 mr-2 " style={{ border: '1px solid black', padding: '1px', margin: '1px', display: 'inline-block', width: '25px', fontWeight: 'normal', height: '25px' }}>  {results.Gender == 'M' && <img style={{ width: "20px", marginLeft: '20%' }} src={CheckLogo} />}
                                    </span>
                                    <h5 className="d-inline col-2  p-0 mb-0" style={{ fontWeight: 'normal', fontSize: '15px' }}>Female</h5>
                                    <span className="d-inline col-1 " style={{ border: '1px solid black', padding: '1px', margin: '1px', display: 'inline-block', width: '25px', fontWeight: 'normal', height: '25px' }}> {results.Gender == 'F' && <img style={{ width: "20px", marginLeft: '20%' }} src={CheckLogo} />}</span>

                                </div>
                                <div className="col-6 row d-flex justify-content-between align-items-end pr-0">
                                    <h5 className="col-4 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>CIVIL STATUS</h5>
                                    <div className="col-8 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.CivilStatus}</span>
                                    </div>
                                </div>

                            </section>
                            {/* Section 3 Citizenship*/}
                            <section className="row d-flex justify-content-between mt-2 mr-5">
                                <div className="col-6 row align-items-end " >
                                    <h5 className="d-inline col-4 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>CITIZENSHIP</h5>
                                    <div className="col-8 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.Nationality}</span>
                                    </div>
                                </div>
                                <div className="col-6 row align-items-end pr-0" >
                                    <h5 className="d-inline col-4 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>RELIGION</h5>
                                    <div className="col-8 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.Religion}</span>
                                    </div>
                                </div>


                            </section>
                            {/* Section 4 Places*/}
                            <section className="row d-flex justify-content-between mt-2 mr-5">
                                <div className="col-6 row align-items-end " >
                                    <h5 className="d-inline col-5 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>DATE OF BIRTH</h5>
                                    <div className="col-7 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{moment(results.BirthDate).format('YYYY-MM-DD')}</span>
                                    </div>
                                </div>
                                <div className="col-6 row align-items-end pr-0" >
                                    <h5 className="d-inline col-5 mb-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>PLACE OF BIRTH</h5>
                                    <div className="col-7 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.BirthPlace}</span>
                                    </div>
                                </div>
                            </section>
                            {/* Section 5 Contact Details*/}
                            <section className="row justify-content-between align-items-end mt-2" >
                                <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                    CONTACT DETAILS
                                </h5>
                                <h5 className="d-inline col-1 p-0 mb-0 d-flex justify-content-end" style={{ fontWeight: 'normal', fontSize: '15px' }}>
                                    Landline
                                </h5>
                                <div className="col-2 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.TelNo}</span>
                                </div>
                                <h5 className="d-inline col-1 p-0 mb-0 d-flex justify-content-end" style={{ fontWeight: 'normal', fontSize: '15px' }}>
                                    Mobile
                                </h5>
                                <div className="col-2 mr-3 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.CelNo}</span>
                                </div>
                                <h5 className="d-inline col-0.5 p-0 mb-0 d-flex justify-content-end" style={{ fontWeight: 'normal', fontSize: '15px' }}>
                                    Email
                                </h5>
                                <div className="col-2.5 mr-3 text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: `${calculateFontSize(results.EmailAddress && results.EmailAddress.length, 30)}px`, fontWeight: 'normal' }}>{results.EmailAddress}</span>
                                </div>
                            </section>
                            {/* Section 5 Address*/}
                            <section className="row justify-content-between align-items-end mt-2">
                                <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                    ADDRESS
                                </h5>

                                <div className="col  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal', whiteSpace: 'nowrap' }}>{results.Barangay}</span>
                                </div>
                                <div className="col  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.Municipality}</span>
                                </div>
                                <div className="col  text-center mr-5" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.Province}</span>
                                </div>
                            </section>
                            <section className="row mt-1">
                                <div className="col-2 text-center">
                                    <label style={{ fontWeight: 'normal' }}></label>
                                </div>

                                <div className="col text-center">
                                    <label style={{ fontWeight: 'normal' }}>No./Street &nbsp;&nbsp; Village/Subd &nbsp;&nbsp; Barangay</label>
                                </div>
                                <div className="col text-center">
                                    <label style={{ fontWeight: 'normal' }}>Municipality</label>
                                </div>
                                <div className="col text-center">
                                    <label style={{ fontWeight: 'normal' }}>Province</label>
                                </div>
                            </section>
                            {/* Section 5 School*/}
                            <section className="row justify-content-start align-items-end">
                                <h5 className="col-1 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '15px' }}>
                                    SCHOOL
                                </h5>

                                <div className="col-8  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.School}</span>
                                </div>
                                <div className="col-3 row align-items-end " >
                                    <h5 className="d-inline col-4 p-0 mb-0" style={{ fontWeight: 'normal', fontSize: '90%', }}>Private</h5>
                                    <span className="d-inline col-2 mr-2 " style={{ border: '1px solid black', padding: '1px', margin: '1px', display: 'inline-block', width: '25px', fontWeight: 'normal', height: '25px' }}>  {results.SchoolType == 'PR' && <img style={{ width: "20px", marginLeft: '20%' }} src={CheckLogo} />}
                                    </span>
                                    <h5 className="d-inline col-3  p-0 mb-0" style={{ fontWeight: 'normal', fontSize: '90%', }}>Public</h5>
                                    <span className="d-inline col-2 " style={{ border: '1px solid black', padding: '1px', margin: '1px', display: 'inline-block', width: '25px', fontWeight: 'normal', height: '25px' }}> {results.SchoolType == 'PU' && <img style={{ width: "20px", marginLeft: '20%' }} src={CheckLogo} />}</span>

                                </div>


                            </section>
                            <section className="row mt-1">
                                <div className="col-9 ">
                                    <h5 style={{ fontWeight: 'normal', fontSize: '15px' }}>(High School / If Transferee - last school attended)</h5>
                                </div>

                            </section>
                            {/* Section 5 School Address*/}
                            <section className="row justify-content-between align-items-end mt-2">
                                <div className="col-2  text-center" >
                                </div>
                                <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '15px' }}>
                                    Address of School
                                </h5>

                                <div className="col  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.SchoolAddress}</span>
                                </div>
                                <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '15px' }}>
                                    Year Level/Section
                                </h5>
                                <div className="col  text-center mr-5" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                    <span style={{ fontSize: '90%', fontWeight: 'normal' }}>{results.Province}</span>
                                </div>
                            </section>
                            {/* header 2*/}
                            <section className="row mt-1">
                                <h5 className="col-6 mt-2" style={{ fontWeight: 'bold' }}>B. FAMILY BACKGROUND</h5>

                            </section>
                            {/* Family background table*/}
                            <main className="mx-2">
                                <section className="row" style={{ border: '3px solid black' }}>
                                    <div className="col-4" style={{ borderRight: '2px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Father's Name)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherName != null ? results.FatherName : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-2" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Citizenship)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherNationality != null ? results.FatherNationality : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-4" style={{ borderRight: '2px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Mother's Name)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherName != null ? results.MotherName : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-2">
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Citizenship)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherNationality != null ? results.MotherNationality : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-6" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Home Address)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherHomeAddress != null ? results.MotherHomeAddress : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-6" >
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Home Address)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherHomeAddress != null ? results.MotherHomeAddress : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3" style={{ borderRight: '2px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Marital Status)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherCivilStatus != null ? results.FatherCivilStatus : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-3" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Highest Educational Attainment)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherEducation != null ? results.FatherEducation : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-3" style={{ borderRight: '2px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Marital Status)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherCivilStatus != null ? results.MotherCivilStatus : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-3" >
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Highest Educational Attainment)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherEducation != null ? results.MotherEducation : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-6" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Occupation/Business Name)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherBusinessName != null ? results.FatherBusinessName : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Occupation/Business Name)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherBusinessName != null ? results.MotherBusinessName : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-6" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Office/Business Address)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherBusinessAddress != null ? results.FatherBusinessAddress : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Office/Business Address)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.MotherBusinessAddress != null ? results.MotherBusinessAddress : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-6" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Contact Nos)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.FatherContactNo != null ? results.FatherContactNo : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-6">
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>(Contact Nos)</p>
                                        <div style={{ fontSize: '15px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal', maxWidth: '50%' }}>{results.MotherContactNo != null ? results.MotherContactNo : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                            </main>


                            <p className="mb-0" style={{ fontWeight: 'normal' }}>(Please check appropriate box)</p>
                            {/* Income, educ plan, scholarship, position family */}
                            <main className="mx-2" >
                                <section className="row" style={{ border: '3px solid black' }}>
                                    {/* Monthly Income box */}
                                    <div className="col-3 p-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0">Monthly Family Net Income</p>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Less than 5,000</p>
                                            <div style={{ border: '1px solid black', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyMonthlyIncome) < 5000 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>5,000-9,000</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyMonthlyIncome) >= 5000 && parseFloat(results.FamilyMonthlyIncome) <= 9000 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>9,000-15,000</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyMonthlyIncome) >= 9000 && parseFloat(results.FamilyMonthlyIncome) <= 15000 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>15,000-49,000</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyMonthlyIncome) >= 15000 && parseFloat(results.FamilyMonthlyIncome) <= 49000 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>49,000-up</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderBottom: '0', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyMonthlyIncome) > 49000 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                    </div>
                                    {/* Educational Plan Box */}
                                    <div className="col-3 p-0" style={{ borderRight: '3px solid black' }}>
                                        <p className="text-center  mb-0">Educational Plan</p>
                                        <div className="d-flex justify-content-between " >
                                            <p className="my-2 text-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>CAP</p>
                                            <div style={{ border: '1px solid black', borderRight: '0', width: '30%' }}>

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between " >
                                            <p className="my-2 text-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>P.E.T</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between " >
                                            <p className="my-2 text-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Philam</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between " >
                                            <p className="my-2 text-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Prudential</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between " >
                                            <p className="my-2 text-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Others (pls specify)</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderBottom: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                    </div>
                                    {/* Scholarship Box */}
                                    <div className="col-3 p-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center  mb-0">Scholarship</p>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Corporate</p>
                                            <div style={{ border: '1px solid black', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Institutuional</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Valedictorian</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Salutatorian</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}></div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Others (pls. specify)</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderBottom: '0', borderRight: '0', width: '30%', fontSize: '15px' }}>
                                                <p className="m-0" style={{ fontSize: '80%' }}>{results.StudentScholarship != null ? results.StudentScholarship : ''}</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* Position in the Family box */}
                                    <div className="col-3 p-0">
                                        <p className="text-center  mb-0">Position in the Family</p>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Eldest</p>
                                            <div style={{ border: '1px solid black', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyPositionId) == 4 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Middle</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyPositionId) == 5 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                        <div className="d-flex justify-content-between ">
                                            <p className="my-2 align-items-center ml-2" style={{ fontWeight: 'normal', fontSize: '10px' }}>Youngest</p>
                                            <div style={{ border: '1px solid black', borderTop: '0', borderRight: '0', width: '30%' }}>
                                                {parseFloat(results.FamilyPositionId) == 6 && <img style={{ width: "23px", marginLeft: '35%' }} src={CheckLogo} />}

                                            </div>
                                        </div>
                                    </div>
                                </section>

                            </main>
                            {/* Siblings Table */}
                            <main className="mx-2 mt-5" style={{ fontWeight: 'normal' }}>
                                <section className="row my-auto" style={{ border: '3px solid black' }}>
                                    <div className="col-3 px-0 py-2" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0">Names of Brothers/Sisters</p>
                                    </div>
                                    <div className="col px-0 py-2" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0">Marital Status</p>
                                    </div>
                                    <div className="col-1 px-0 py-2" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0">Age</p>
                                    </div>
                                    <div className="col-3 px-0 py-2" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0">School/Company</p>
                                    </div>
                                    <div className="col-1 px-0 py-2" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0">Year/Level</p>

                                    </div>
                                    <div className="col px-0 py-2" >
                                        <p className="text-center mb-0">Contact No</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 0 ? results.StudentSiblings[0].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 0 ? results.StudentSiblings[0].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 0 ? results.StudentSiblings[0].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 0 ? results.StudentSiblings[0].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 0 ? results.StudentSiblings[0].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 0 ? results.StudentSiblings[0].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 1 ? results.StudentSiblings[1].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 1 ? results.StudentSiblings[1].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 1 ? results.StudentSiblings[1].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 1 ? results.StudentSiblings[1].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 1 ? results.StudentSiblings[1].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 1 ? results.StudentSiblings[1].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 2 ? results.StudentSiblings[2].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 2 ? results.StudentSiblings[2].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 2 ? results.StudentSiblings[2].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 2 ? results.StudentSiblings[2].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 2 ? results.StudentSiblings[2].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 2 ? results.StudentSiblings[2].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 3 ? results.StudentSiblings[3].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 3 ? results.StudentSiblings[3].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 3 ? results.StudentSiblings[3].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 3 ? results.StudentSiblings[3].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 3 ? results.StudentSiblings[3].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 3 ? results.StudentSiblings[3].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 4 ? results.StudentSiblings[4].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 4 ? results.StudentSiblings[4].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 4 ? results.StudentSiblings[4].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 4 ? results.StudentSiblings[4].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 4 ? results.StudentSiblings[4].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 4 ? results.StudentSiblings[4].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 5 ? results.StudentSiblings[5].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 5 ? results.StudentSiblings[5].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 5 ? results.StudentSiblings[5].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 5 ? results.StudentSiblings[5].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 5 ? results.StudentSiblings[5].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 5 ? results.StudentSiblings[5].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 6 ? results.StudentSiblings[6].name : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 6 ? results.StudentSiblings[6].civilStatus : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 6 ? results.StudentSiblings[6].age : 'N/A'}</p>
                                    </div>
                                    <div className="col-3 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 6 ? results.StudentSiblings[6].affiliation : 'N/A'}</p>
                                    </div>
                                    <div className="col-1 px-0 py-1" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 6 ? results.StudentSiblings[6].yearLevel : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0 py-1" >
                                        <p className="text-center mb-0" style={{ fontSize: '80%' }}>{results.StudentSiblings.length > 6 ? results.StudentSiblings[6].contactNo : 'N/A'}</p>
                                    </div>
                                </section>
                                <section className="row" style={{ border: '3px solid black', borderTop: '0' }}>
                                    <div className="col-6" style={{ borderRight: '2px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>Guardian Name(If not living with parents)</p>
                                        <div style={{ fontSize: '12px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.ContactPerson != null ? results.ContactPerson : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-2" style={{ borderRight: '3px solid black' }}>
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>Contact No.</p>
                                        <div style={{ fontSize: '12px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.ContactPersonCelNo != null ? results.ContactPersonCelNo : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col-4" >
                                        <p className="mb-0" style={{ fontWeight: 'normal', fontSize: '12px' }}>Address</p>
                                        <div style={{ fontSize: '12px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.ContactPersonAddress != null ? results.ContactPersonAddress : 'N/A'}</p>
                                        </div>
                                    </div>

                                </section>
                            </main>
                            {/* header 3*/}
                            <section className="row mt-1">
                                <h5 className="col-6 mt-2" style={{ fontWeight: 'bold' }}>C. EDUCATIONAL BACKGROUND</h5>

                            </section>
                            {/* Educational Background */}
                            <main className="mx-2" style={{ fontWeight: 'normal' }}>
                                <section className="row my-auto" style={{ border: '3px solid black' }}>
                                    <div className="col-2 px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3">Level</p>
                                    </div>
                                    <div className="col-2 p-0 align-items-center" style={{ borderRight: '2px solid black' }}>
                                        <div className="row mx-0" style={{ borderBottom: '2px solid black' }}>
                                            <p className="col-12 text-center p-0 mb-0">Year</p>

                                        </div>
                                        <div className="row mx-0">
                                            <div className="col-6 p-0" style={{ borderRight: '2px solid black' }}>
                                                <p className="text-center mb-0">From</p>
                                            </div>
                                            <div className="col-6 p-0">
                                                <p className="text-center mb-0">To</p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3">Name of School</p>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3">Address</p>

                                    </div>
                                    <div className="col px-0" >
                                        <p className="text-center my-3">Awards Received</p>
                                    </div>
                                </section>
                                <section className="row my-auto" style={{ border: '3px solid black', borderTop: '0', fontSize: '80%' }}>
                                    <div className="col-2 px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mt-auto" >Pre-Elementary</p>
                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" >{results.PreElemFrom != null ? results.PreElemFrom : 'N/A'}</p>

                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black', }}>
                                        <p className="text-center my-3">{results.PreElemTo != null ? results.PreElemTo : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.PreElemSchool ? results.PreElemSchool.length : 25, 55, 5)}px` }}>{results.PreElemSchool != null ? results.PreElemSchool : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.PreElemAddress ? results.PreElemAddress.length : 25, 55, 5)}px` }}>{results.PreElemAddress != null ? results.PreElemAddress : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" >
                                        <p className="text-center my-3" style={{ fontSize: '80%' }}></p>
                                    </div>
                                </section>
                                <section className="row my-auto" style={{ border: '3px solid black', borderTop: '0', fontSize: '80%' }}>
                                    <div className="col-2 px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mt-auto" >Elementary</p>
                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3">{results.ElemFrom != null ? results.ElemFrom : 'N/A'}</p>

                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black', }}>
                                        <p className="text-center my-3">{results.ElemTo != null ? results.ElemTo : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.ElemSchool ? results.ElemSchool.length : 25, 55, 5)}px` }}>{results.ElemSchool != null ? results.ElemSchool : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.ElemAddress ? results.ElemAddress.length : 25, 55, 5)}px` }}>{results.ElemAddress != null ? results.ElemAddress : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" >
                                        <p className="text-center my-3" style={{ fontSize: '80%' }}></p>
                                    </div>
                                </section>
                                <section className="row my-auto" style={{ border: '3px solid black', borderTop: '0', fontSize: '80%' }}>
                                    <div className="col-2 px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mt-auto" >Junior High School</p>
                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3">{results.JrFrom != null ? results.JrFrom : 'N/A'}</p>

                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black', }}>
                                        <p className="text-center my-3">{results.JrTo != null ? results.JrTo : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.JrSchool ? results.JrSchool.length : 25, 55, 5)}px` }}>{results.JrSchool != null ? results.JrSchool : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.JrAddress ? results.JrAddress.length : 25, 55, 5)}px` }}>{results.JrAddress != null ? results.JrAddress : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" >
                                        <p className="text-center my-3" style={{ fontSize: '80%' }}></p>
                                    </div>
                                </section>
                                <section className="row my-auto" style={{ border: '3px solid black', borderTop: '0', fontSize: '80%' }}>
                                    <div className="col-2 px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mt-auto" >Senior High School</p>
                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3">{results.SrFrom != null ? results.SrFrom : 'N/A'}</p>

                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black', }}>
                                        <p className="text-center my-3">{results.SrTo != null ? results.SrTo : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.SrSchool ? results.SrSchool.length : 25, 55, 5)}px` }}>{results.SrSchool != null ? results.SrSchool : 'N/A'}</p>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3" style={{ fontSize: `${calculateFontSize(results.SrAddress ? results.SrAddress.length : 25, 55, 5, 'yeah')}px` }}>{results.SrAddress != null ? results.SrAddress : 'N/A'}</p>

                                    </div>
                                    <div className="col px-0" >
                                        <p className="text-center my-3" style={{ fontSize: '80%' }}></p>
                                    </div>
                                </section>
                                <section className="row my-auto" style={{ border: '3px solid black', borderTop: '0', fontSize: '80%' }}>
                                    <div className="col-2 px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center mt-auto">College (for transferee only)</p>
                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3"></p>

                                    </div>
                                    <div className="col-1 p-0 align-items-center" style={{ borderRight: '2px solid black', }}>
                                        <p className="text-center my-3"></p>

                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3"></p>
                                    </div>
                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="text-center my-3"></p>

                                    </div>
                                    <div className="col px-0" >
                                        <p className="text-center my-3"></p>
                                    </div>
                                </section>
                                <br />
                                <section className="row mt-5" style={{ border: '3px solid black', fontSize: '80%' }}>

                                    <div className="col px-0" style={{ borderRight: '2px solid black' }}>
                                        <p className="ml-1 mb-0">Person Responsible for your study</p>
                                        <div style={{ fontSize: '12px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.ContactPerson != null ? results.ContactPerson : 'N/A'}</p>
                                        </div>
                                    </div>
                                    <div className="col px-0" >
                                        <p className="ml-1 mb-0">Address</p>
                                        <div style={{ fontSize: '12px' }}>
                                            <p className="mb-0 ml-2" style={{ fontSize: '80%', fontWeight: 'normal' }}>{results.ContactPersonAddress != null ? results.ContactPersonAddress : 'N/A'}</p>
                                        </div>
                                    </div>
                                </section>
                                <br />
                                {/* Drawing Section */}
                                <section className="row mt-1">
                                    <h5 className="col mt-2 text-center" style={{ fontWeight: 'normal', fontSize: '17px' }}>D. PLEASE SKETCH YOUR HOUSE LOCATION AT THE SPACE PROVIDED BELOW STARTING FROM A NEAREST KNOWN LANDMARK(i.e Municipal Hall, Jollibee, St. Thomas Church, etc.)</h5>
                                </section>
                                <br />
                                <section className="mt-1" style={{ border: '2px solid black', height: '900px' }}>
                                </section>
                                {/* In Case of Emergency */}
                                <section className="row mt-1">
                                    <h5 className="col mt-2" style={{ fontWeight: 'normal', fontSize: '17px' }}>E. IN CASE OF EMERGENCY</h5>
                                </section>
                                <section className="row justify-content-start align-items-end mt-4">
                                    <h5 className="col-3 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        Name of Person to notify
                                    </h5>

                                    <div className="col-9  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>



                                </section>
                                <section className="row justify-content-start align-items-end mt-4">
                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        Relationship
                                    </h5>

                                    <div className="col-10  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>



                                </section>
                                <section className="row justify-content-start align-items-end mt-4">
                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        Contact No/s
                                    </h5>

                                    <div className="col-10  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>



                                </section>
                                <section className="row justify-content-start align-items-end mt-4">
                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        Address
                                    </h5>

                                    <div className="col-10  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>



                                </section>
                                <br />
                                <section className="row justify-content-start align-items-end mt-5 text-center">
                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        I am allowing
                                    </h5>

                                    <div className="col-8  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>
                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        to transact with
                                    </h5>


                                </section>
                                <section className="row mt-1">
                                    <div className="col-2 text-center">
                                        <label style={{ fontWeight: 'normal' }}></label>
                                    </div>
                                    <div className="col-8 text-center">
                                        <label style={{ fontWeight: 'normal' }}>(Name of Parent/Sibling/Guardian)</label>
                                    </div>
                                    <div className="col text-center">
                                        <label style={{ fontWeight: 'normal' }}></label>
                                    </div>
                                </section>


                                <section className="row justify-content-start align-items-end mt-5">
                                    <h5 className="col mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>
                                        FAITH on my behalf and receive confidential information/records as my authorized representative
                                    </h5>
                                </section>
                                <br /><br />
                                <section className="row justify-content-start align-items-end mt-5">
                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>

                                    </h5>

                                    <div className="col-8  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>

                                    <h5 className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>

                                    </h5>
                                </section>
                                <section className="row mt-1">
                                    <div className="col-2 text-center">
                                        <label style={{ fontWeight: 'normal' }}></label>
                                    </div>
                                    <div className="col-8 text-center">
                                        <h5 style={{ fontWeight: 'normal' }}>Student's Signature Over Printed Name</h5>
                                    </div>
                                    <div className="col text-center">
                                        <label style={{ fontWeight: 'normal' }}></label>
                                    </div>
                                </section>
                                <br /><br />
                                <section className="row justify-content-start align-items-end mt-5">
                                    <h5 className="col-3 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>

                                    </h5>

                                    <div className="col-6  text-center" style={{ borderBottom: '1px solid black', fontSize: '15px' }}>
                                        <span style={{ fontSize: '90%', fontWeight: 'normal' }}></span>
                                    </div>

                                    <h5 className="col-3 mb-0 d-inline pr-0" style={{ fontWeight: 'normal', fontSize: '16px' }}>

                                    </h5>
                                </section>

                                <section className="row mt-1">
                                    <div className="col-2 text-center">
                                        <label style={{ fontWeight: 'normal' }}></label>
                                    </div>
                                    <div className="col-8 text-center">
                                        <h5 style={{ fontWeight: 'normal' }}>Date Signed</h5>
                                    </div>
                                    <div className="col text-center">
                                        <label style={{ fontWeight: 'normal' }}></label>
                                    </div>
                                </section>
                            </main>
                        </main>

                    </div>
                </div>

            </>
        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>


            <Component ref={componentRef} />
            {/* button to trigger printing of target component */}
            <ReactToPrint
                trigger={() => <button className="btn btn-sm btn-primary mr-3 d-inline">Print 201</button>}
                content={() => componentRef.current}
                onBeforePrint={handleBeforePrint}
                pageStyle={`
                        @page {
                        size:  8.5in 13in;
                        margin: 5%;
                        background-color: #ffffff;
                        }
                  `}

            />



        </>
    )


}