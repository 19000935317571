import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import history from "../../../../history";
import CashierForm from "./CashierForm"
import DirectCashierForm from "./DirectCashierForm";
import { getFee } from "./__hooks__";
import {
    studentPayment, getLatestORNo
} from "./__hooks__/proc"
import { useAppContext } from "../../../../contexts/useAppContext";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
export default function DirectCashierPayment(props) {
    const { state: { auth } } = useAppContext()
    const [ORNo, setORNo] = useState(null)
    const [isOverride, setIsOverride] = useState(false)
    const [feeSelect, setFeeSelect] = useState([])
    // select
    const data = props.location.data



    if (data == null) {
        history.push("/admin/accounting/cashier")
    }
    const payment = async (val) => {
        await studentPayment(val)
            .then(res => {

            })
            .catch(e => {

            })
    }


    const onStartNewTransaction = () => {
        history.push(`/admin/accounting`)
        setTimeout(() => {
            history.replace({
                pathname: `/admin/accounting/direct-payment`,
                data: { SchoolYear: data.SchoolYear, SemesterId: data.SemesterId },
            });
        }, 500)

    }


    useEffect(() => {
        SelectCreator(getFee, setFeeSelect, 'FeeId', 'ShortName')
        getLatestORNo({ userId: auth.data.UserId })
            .then(res => {
                setORNo(res.data.LatestORNo)
                if (res.data.IsOverride == 1) {
                    setIsOverride(true)
                } else {
                    setIsOverride(false)

                }
            })
    }, [])


    console.log(auth.data.schoolyears.assessment.SemesterId)
    console.log(feeSelect)

    console.log(data)
    console.log(ORNo)
    return (
        <>
            {data !== null && data !== undefined
                && ORNo !== null
                ?
                <DirectCashierForm
                    SemesterId={data !== null ? data.SemesterId : auth.data.schoolyears.assessment.SemesterId}
                    SchoolYear={data !== null ? data.SchoolYear : auth.data.schoolyears.assessment.SchoolYear}
                    onStartNewTransaction={onStartNewTransaction}
                    ORNo={ORNo}
                    feeSelect={feeSelect}
                    isOverride={isOverride}
                />
                :
                <div className="alert alert-warning text-center" role="alert">
                    Please assign your official receipt number in the Assign Official Receipt module.
                </div>

            }


        </>
    )
}   