import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { FeeSelect, PaymentScheduleSelect } from '../../../References';
import IMDialogDuplicateAssessmentFee from '../../../../../../_metronic/layout/components/custom/dialog/IMDialogDuplicateAssessmentFee';

export default function Form(props) {
  const { state: { fees, paymentSchedules } } = useAppContext();
  const { AssessmentTemplateOtherId, FeeId, MFee, FFee, PaymentScheduleId, IsOptional, IsActive } = props.doc;
  const { register, errors, setValue } = useForm();
  const [duplicateDialogue, setDuplicateDialogue] = useState(false)

  const recordCnt = props.doc.length;
  const initialState = recordCnt === 0 ? {
    assessmentTemplateId: props.assessmentTemplateId,
    feeId: null,
    mFee: null,
    fFee: null,
    paymentScheduleId: null,
    isOptional: true,
    isActive: true
  } : {
    assessmentTemplateId: props.assessmentTemplateId,
    feeId: FeeId,
    mFee: MFee,
    fFee: FFee,
    paymentScheduleId: PaymentScheduleId,
    isOptional: IsOptional === 1 ? true : false,
    isActive: IsActive === 1 ? true : false
  }
  const [data, setData] = useState(initialState);

  useEffect(() => {
    register({ name: 'feeId' }, { required: true });
    register({ name: 'paymentScheduleId' }, { required: true });
  }, [register])

  const handleChange = name => event => {
    if (name === 'isOptional' || name === 'isActive') {
      setData({ ...data, [name]: event.target.checked });
    } else if (name === 'feeId' || name === 'paymentScheduleId') {
      setData({ ...data, [name]: event });
    } else {
      setData({ ...data, [name]: event.target.value });
    }
  }

  return (
    <>
      {duplicateDialogue && <IMDialogDuplicateAssessmentFee
        title={`Question`}
        onHandleAdd={() => props.onHandleAdd(data, 'd')}
        onHandleDuplicate={() => props.onHandleDuplicate(data)}
        onShowDialog={setDuplicateDialogue}
        isOpen={true}
      />}
      <TableRow key={AssessmentTemplateOtherId}>
        <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
        <TableCell>
          <FeeSelect
            refOnHandleChange={handleChange('feeId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={FeeId === undefined ? "" : FeeId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? fees.data : []}
            refError={!!errors.feeId}
            refErrorMessage={errors.feeId && errors.feeId.type === "required" && 'Fee is required'}
          />
        </TableCell>
        <TableCell>
          <input
            onChange={handleChange('mFee')}
            type="number"
            name="mFee"
            placeholder="Male Fee"
            defaultValue={MFee === undefined ? "" : MFee}
            className={`form-control${errors.mFee === undefined ? "" : errors.mFee && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
          />
        </TableCell>
        <TableCell>
          <input
            onChange={handleChange('fFee')}
            type="number"
            name="fFee"
            placeholder="Female Fee"
            defaultValue={FFee === undefined ? "" : FFee}
            className={`form-control${errors.fFee === undefined ? "" : errors.fFee && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
          />
        </TableCell>
        {/* <TableCell>
          <PaymentScheduleSelect
            refOnHandleChange={handleChange('paymentScheduleId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={PaymentScheduleId === undefined ? "" : PaymentScheduleId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? paymentSchedules.data : []}
            refError={!!errors.paymentScheduleId}
            refErrorMessage={errors.paymentScheduleId && errors.paymentScheduleId.type === "required" && 'Payment schedule is required'}
          />
        </TableCell> */}
        <TableCell align="center">
          <FormControl>
            <FormControlLabel
              name="isOptional"
              inputRef={register()}
              control={<Switch checked={data.isOptional} onChange={handleChange('isOptional')} value={data.isOptional} />}
              label="Optional"
            />
          </FormControl>
        </TableCell>
        <TableCell align="center">
          <FormControl>
            <FormControlLabel
              name="isActive"
              inputRef={register()}
              control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
              label="Active"
            />
          </FormControl>
        </TableCell>
        <TableCell align="center">
          {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => props.onHandleAdd(data)}>
            <i className="fa fa-plus"></i>
          </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
            <i className="fa fa-ban"></i>
          </button>&nbsp;
            <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(AssessmentTemplateOtherId, data)}>
              <i className="fa fa-save text-white"></i>
            </button></>}
        </TableCell>
      </TableRow>
    </>
  );
}