import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getRegistrarConfigs, autoposting
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelectAddEmployee from '../../../../../_metronic/layout/components/custom/select/IMSelectAddEmployee';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import moment from "moment";
import './__hooks__/styles.css'

export default function SystemConfigurations() {
  const { register, handleSubmit, errors } = useForm();
  const { state: { fees, auth }, dispatch } = useAppContext();
  // const activeSy = auth.data.schoolyears.assessment.SchoolYear
  // const activeSem = auth.data.schoolyears.assessment.SemesterId
  // const [isOpenAdd, setIsOpenAdd] = useState(false);
  // const [switchHistory, setSwitchHistory] = useState({switchStateIsActive: false, dataIsActive: 0}); //Set IsActive history to avoid multiple/infinite trigger
  // const [switchState, setSwitchState] = useState({isActive: false}); //Set default value of switch state

  const [roleId,setRoleId] = useState(null);
  const [autoPostingObj,setAutoPostingObj] = useState([]);
  const [gradeViewingObj,setGradeViewingObj] = useState([]);

  useEffect(() => {
    if(auth !== null || typeof(auth) !== "undefined") {
      if(auth.data.RoleId == 9 || auth.data.RoleId == 19 || auth.data.RoleId == 20){
        setRoleId(2); //Registrar
      } else if (auth.data.RoleId == 1) {
        setRoleId(1); //Admin
      } else {
        setRoleId(0); //None
      }
    }
  },[auth]);

  useEffect(() => {
    if(roleId !== null || typeof(roleId) !== "undefined") {
      if(roleId == 2){
        onGetRegistrarConfigs();
      }

      if(roleId == 1){
        onGetRegistrarConfigs();
      }
    }
  },[roleId]);

  const onDateChange = async(configId, configValue, configTypeId, gradingPeriod) => {
    console.log("configDetail: ",configId);
    console.log("dateVal: ",configValue);

    let values = {
      configId: configId,
      configValue: configValue,
      updatedBy: auth.data.Username
    }

    await autoposting(values)
      .then(res => {
        console.log("autoposting: ", res);
        if(res !== null || res != '' ||typeof(res) !== "undefined"){
          if(res.success){
            dispatch({ 
                type: componentTypes.SHOW_SNACKBAR, 
                snackBarStatus: 'success', 
                snackBarDuration: 5000, 
                snackBarMessage: configTypeId == 1 ? `Auto Posting date (${gradingPeriod}) has been updated!` : `Grade Viewing date (${gradingPeriod}) has been updated!`
            });
          } else {
            dispatch({ 
              type: componentTypes.SHOW_SNACKBAR, 
              snackBarStatus: 'error', 
              snackBarDuration: 5000, 
              snackBarMessage: 'An error has occurred. Please contact your administrator.' 
            });
          }
        }
      })
      .catch(error => {
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Kindly contact your administrator." });
        if (error.response.status === 401) {
        } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
        }
      })
  }

  const onGetRegistrarConfigs = async() => {
    await getRegistrarConfigs()
      .then(res => {
        console.log("Res: ",res);
        let autoPostingArr = [];
        let gradeViewingArr = [];
        for(let i = 0; i < res.data.length; i++){
          //console.log("DATA: ", data);
          switch(res.data[i].ConfigTypeId){
            case 1:
              autoPostingArr.push(res.data[i]);
              break;
            case 2:
              gradeViewingArr.push(res.data[i]);
              break;
            default:
              break;
          }
        }
        setAutoPostingObj(autoPostingArr);
        setGradeViewingObj(gradeViewingArr);
      })
      .catch(

      )
  };
  
  console.log("autoPostingObj: ",autoPostingObj);
  console.log("gradeViewingArr: ",gradeViewingObj);

  return (
    <>
      { (roleId == 2 || roleId == 1) &&
        <div className='d-flex align-items-center'>
          <main className="card mx-auto my-auto shadow-lg" style={{ width: "100%" }}>
              <header className='card-header p-5 bg-secondary'>
                  <h6 className="float-left m-0 mt-4 ml-5">Auto Posting & Grade Viewing</h6>
              </header>

              <section className="card-body">

                <table width="100%" className='sys-config-registrars sys-config-registrars-striped'>
                  <thead style={{height:"25px"}}>
                    <tr className='row'>
                      <th className='col-3'></th>
                      {autoPostingObj.map((res,i) => {
                          return ( 
                            <th className='col-3'>
                              {res.GradingPeriod}
                            </th>
                          )
                        })
                      }
                    </tr>
                  </thead>

                  <tbody>
                    <tr className='row'>
                      <td className='col-3'>
                        <div style={{fontWeight:"bold", margin:"8%"}}>
                          <span>Auto Posting</span>
                        </div>
                      </td>
                      {autoPostingObj.map((res,i) => {
                          let d = new Date(res.ConfigValue),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                          if (month.length < 2) 
                              month = '0' + month;
                          if (day.length < 2) 
                              day = '0' + day;

                          let newDate = [year, month, day].join('-');
                          console.log("newDate: ", newDate);
                          return ( 
                            // <td className='col-3'>{res.ConfigValue}</td>
                            <td className='col-3'>
                              <div className="form-row">
                                <div className="form-group col">
                                  <label htmlFor={res.ConfigDetail}></label>
                                  <input
                                    ref={register({
                                      required: false
                                    })}
                                    name={res.ConfigDetail}
                                    type="date"
                                    className="form-control"
                                    id={res.ConfigDetail}
                                    // placeholder="Birth Date"
                                    defaultValue={newDate}
                                    // defaultValue={props.value !== undefined && props.value !== null ? props.value.birthDate : ''}
                                    onChange={(e) => { onDateChange(res.ConfigId, e.target.value, res.ConfigTypeId, res.GradingPeriod) }}
                                  />
                                </div>
                              </div>
                            </td>
                          )
                        })
                      }
                    </tr>

                    <tr className='row'>
                    <td className='col-3'>
                        <div style={{fontWeight:"bold", margin:"8%"}}>
                          <span>Grade Viewing</span>
                        </div>
                      </td>
                      {gradeViewingObj.map((res,i) => {
                          let d = new Date(res.ConfigValue),
                              month = '' + (d.getMonth() + 1),
                              day = '' + d.getDate(),
                              year = d.getFullYear();

                          if (month.length < 2) 
                              month = '0' + month;
                          if (day.length < 2) 
                              day = '0' + day;

                          let newDate = [year, month, day].join('-');
                          console.log("newDate: ", newDate);
                          return ( 
                            // <td className='col-3'>{res.ConfigValue}</td>
                            <td className='col-3'>
                              <div className="form-row">
                                <div className="form-group col">
                                  <label htmlFor={res.ConfigDetail}></label>
                                  <input
                                    ref={register({
                                      required: false
                                    })}
                                    name={res.ConfigDetail}
                                    type="date"
                                    className="form-control"
                                    id={res.ConfigDetail}
                                    // placeholder="Birth Date"
                                    defaultValue={newDate}
                                    // defaultValue={props.value !== undefined && props.value !== null ? props.value.birthDate : ''}
                                    // onBlur={(e) => { setData({ ...data, birthDate: e.target.value }) }}
                                    onChange={(e) => { onDateChange(res.ConfigId, e.target.value, res.ConfigTypeId, res.GradingPeriod) }}
                                  />
                                </div>
                              </div>
                            </td>
                          )
                        })
                      }
                    </tr>
                  </tbody>
                </table>                  
              </section>
          </main>
      </div>
      }
    </>
  );
}