import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getActiveSemester,
    getActiveSchoolYear, getInstitute, getCourse, getYearLevel, getEnrollmentListChedReport,
    getEnrollmentChedReport, getStudentNo
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import ExportExcel from "../../Enrollment/ApplicantReport/ExportExcel";
import ExportJsExcel from "../ExceljsReport";
import ChedExcelFormat from "./ChedExcelFormat";
import { useAppContext } from "../../../../contexts/useAppContext";
import ExcelJsReport from "../ExceljsReport";




export default function ChedEnrollmentReports(props) {
    const { setValue } = useForm();
    const { state: { auth } } = useAppContext()
    const [results, setResults] = useState([])
    const [chedFormatResult, setChedFormatResult] = useState([])
    const [chedResult, setChedResult] = useState([])
    const [summaryResult, setSummaryResult] = useState([])
    const [progress, setProgress] = useState(0);
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);




    const [studentNo, setStudentNo] = useState()
    const [filteredIns, setFilteredIns] = useState(90)
    const [filteredCourse, setFilteredCourse] = useState(null)
    const [filteredYearLevel, setFilteredYearLevel] = useState(null)
    const [filteredDateFrom, setFilteredDateFrom] = useState(moment().format("YYYY-MM-DD"))
    const [filteredDateTo, setFilteredDateTo] = useState(moment().add({ days: 1 }).format("YYYY-MM-DD"))

    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [instituteSelect, setInstituteSelect] = useState([])
    const [courseSelect, setCourseSelect] = useState([])
    const [yearLevelSelect, setYearLevelSelect] = useState([])


    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear

    const [filteredSem, setFilteredSem] = useState(activeSem)
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [excelChedFormat, setExcelChedFormat] = useState([])
    const [excelFormat, setExcelFormat] = useState([])

    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectCourseChange = name => event => { setFilteredCourse(event) }
    const selectInsChange = name => event => { setFilteredIns(event) }
    const selectYearLevelChange = name => event => { setFilteredYearLevel(event) }




    const onGetEnrollmentReport = async (v) => {
        await getEnrollmentChedReport(v)
            .then(res => {
                console.log(res)
                setIsGeneratingReport(false)
                setResults(res.data)
            })
            .catch(e => {
                setIsGeneratingReport(false)
            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }



    const onSubmit = async () => {
        setIsGeneratingReport(true)
        let dataToBeSubmit = {
            semesterId: filteredSem,
            schoolYear: filteredSy

        }
        let result = []
        await getEnrollmentListChedReport({
            schoolYear: filteredSy,
            semesterId: filteredSem
        }).then(res => {
            setSummaryResult(res.data)
        })
        for (let i = 0; i < studentNo.length; i++) {
            await getEnrollmentChedReport({ schoolYear: filteredSy, semesterId: filteredSem, studentNo: studentNo[i].StudentNo })
                .then(async res => {
                    console.log(res)
                    if (res.data !== undefined && res.data !== null) {
                        result.push(res.data)
                    } else {
                        await getEnrollmentChedReport({ schoolYear: filteredSy, semesterId: filteredSem, studentNo: studentNo[i].StudentNo })
                            .then(res2 => {
                                result.push(res2.data)

                            })
                    }

                    // Update progress
                    const currentProgress = ((i + 1) / studentNo.length) * 100;
                    setProgress(currentProgress.toFixed(2));

                })
        }
        console.log(result)
        setChedResult(result)
        setIsGeneratingReport(false)
        // onGetEnrollmentReport(dataToBeSubmit)
    }



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')
        SelectCreator(getCourse, setCourseSelect, 'CourseId', 'Code')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')




    }, [])


    useEffect(() => {
        const exeGetStudentNo = async (d) => {
            await getStudentNo(d)
                .then(res => {
                    setStudentNo(res.data)
                })
        }
        exeGetStudentNo({ schoolYear: filteredSy, semesterId: filteredSem, instituteId: filteredIns, yearLevelId: filteredYearLevel })
    }, [filteredSem, filteredSy, filteredIns, filteredYearLevel])


    useEffect(() => {
        if (results !== undefined) {
            let report = []
            for (let i = 0; i < results.length; i++) {
                report.push({
                    'Course': `${results[i].Course}`,
                    'Major': `${results[i].Major}`,
                    'Male 1st Year': `${results[i].FirstYearMale}`,
                    'Female 1st Year': `${results[i].FirstYearFemale}`,
                    'First Year Total': `${results[i].FirstYearFemale}`,
                    'Male 2nd Year': `${results[i].SecondYearMale}`,
                    'Female 2nd Year': `${results[i].SecondYearFemale}`,
                    'Second Year Total': `${results[i].SecondYearFemale}`,
                    'Male 3rd Year': `${results[i].ThirdYearMale}`,
                    'Female 3rd Year': `${results[i].ThirdYearFemale}`,
                    'Third Year Total': `${results[i].ThirdYearFemale}`,
                    'Male 4th Year': `${results[i].FourthYearMale}`,
                    'Female 4th Year': `${results[i].FourthYearFemale}`,
                    'Fourth Year Total': `${results[i].FourthYearFemale}`,
                    'Grand Total': `${results[i].GrandTotal}`,




                })




            }


            console.log(report)
            setExcelFormat(report)
        }
    }, [results])


    const columns = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: true, hidden: false, disablePadding: false },

        { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Major', label: 'Major', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        //  { id: 'FirstYearMale', label: '1st Year/Male', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        //  { id: 'FirstYearFemale', label: '1st Year/Female', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FirstYearTotal', label: '1st Year/Total', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        //  { id: 'SecondYearMale', label: '2nd Year/Male', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'SecondYearFemale', label: '2nd Year/Female', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SecondYearTotal', label: '2nd Year/Total', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'ThirdYearMale', label: '3rd Year/Male', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'ThirdYearFemale', label: '3rd Year/Female', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ThirdYearTotal', label: '3rd Year/Total', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'FourthYearMale', label: '4th Year/Male', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        //  { id: 'FourthYearFemale', label: '4th Year/Female', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FourthYearTotal', label: '4th Year/Total', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'GrandTotal', label: 'Grand Total', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]


    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: results.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'applicantReport',
        parentId: null,
        tableTitle: '',
        onPage: 0,
        onRowsPerPage: 10

    }




    console.log(excelFormat)
    console.log(results)
    console.log(chedResult)
    console.log(studentNo)
    return (
        <>

            <div className="card mx-auto" style={{ width: '500px' }}>
                <div className="card-header font-weight-bold p-5">
                    <h6 className="mb-0 d-block">Ched Enrollment Report</h6>
                </div>

                <header className="row p-5">
                    <div className="col-12 mb-5">
                        <IMSelect
                            data={schoolYearSelect}
                            onHandleChange={selectSyChange()}
                            refClassContainer=""
                            name="SchoolYear"
                            isRequired={false}
                            withLabel={false}
                            label="SchoolYear"
                            placeHolder="Select School Year"
                            forwardRef={setValue}
                            selectedId={filteredSy === undefined ? activeSy : filteredSy}
                            refClassName={`text-center`}
                            withDescription={false}
                            //description={`Enrollment Status`}
                            refDisabled={true}
                            refIsStatic={false}
                            refStaticData={[

                            ]
                            }
                            field={{
                                tableId: 'tableId',
                                display: 'code'
                            }}

                        />
                    </div>
                    <div className="col-12 mb-5">
                        <IMSelect
                            data={semestersSelect}
                            onHandleChange={selectSemChange()}
                            refClassContainer=""
                            name="Semester"
                            isRequired={false}
                            withLabel={false}
                            label="Semester"
                            placeHolder="Select Semester"
                            forwardRef={setValue}
                            selectedId={filteredSem === undefined ? activeSem : filteredSem}
                            refClassName={`text-center`}
                            withDescription={false}
                            //description={`Enrollment Status`}
                            refDisabled={true}
                            refIsStatic={false}
                            refStaticData={[

                            ]
                            }
                            field={{
                                tableId: 'tableId',
                                display: 'code'
                            }}

                        />
                    </div>
                    <div className="col-12 mb-5">
                        <IMSelect
                            data={instituteSelect}
                            onHandleChange={selectInsChange()}
                            refClassContainer=""
                            name="Department"
                            isRequired={false}
                            withLabel={false}
                            label="Department"
                            placeHolder="Select Department"
                            forwardRef={setValue}
                            selectedId={filteredIns}
                            refClassName={`text-center`}
                            withDescription={false}
                            //description={`Enrollment Status`}
                            refDisabled={true}
                            refIsStatic={false}
                            refStaticData={[

                            ]
                            }
                            field={{
                                tableId: 'tableId',
                                display: 'code'
                            }}

                        />
                    </div>
                    <div className="col-12 mb-5">
                        <IMSelect
                            data={yearLevelSelect}
                            onHandleChange={selectYearLevelChange()}
                            refClassContainer=""
                            name="Year Level"
                            isRequired={false}
                            withLabel={false}
                            label="Year Level"
                            placeHolder="Select Year Level"
                            forwardRef={setValue}
                            selectedId={filteredYearLevel}
                            refClassName={`text-center`}
                            withDescription={false}
                            //description={`Enrollment Status`}
                            refDisabled={true}
                            refIsStatic={false}
                            refStaticData={[

                            ]
                            }
                            field={{
                                tableId: 'tableId',
                                display: 'code'
                            }}

                        />
                    </div>
                    {isGeneratingReport == false && chedResult.length == 0 && <button onClick={() => { onSubmit() }} className="col btn btn-sm btn-primary">Generate Report </button>

                    }
                    {chedResult.length > 0 && <ChedExcelFormat excelData={chedResult} fileName={'ChedReport'} btnName={'Extract Report'} />}
                    {summaryResult.length > 0 && chedResult.length > 0 && <ExcelJsReport excelData={summaryResult} fileName={'ChedReportSummary'} btnName={'Extract Summary'} />}
                    {chedResult.length > 0 && <button onClick={() => { setChedResult([]) }} className="col btn btn-sm btn-secondary">Search Again</button>}

                </header>
                {isGeneratingReport && (
                    <div>
                        <div className="text-center">{`Building Report Please wait ...: ${progress}%`}</div>
                        <div style={{ width: '100%', backgroundColor: '#ddd', borderRadius: 4, marginBottom: '1%' }}>
                            <div style={{ width: `${progress}%`, height: 20, backgroundColor: '#4CAF50', borderRadius: 4 }}></div>
                        </div>
                    </div>
                )}
                {/* <IMTableCustom tableProps={tableProps} /> */}


            </div>


        </>
    )
}