import instance from '../../../../../../apis/local/systemAPI';

// START OF SUBJECT SCHEDULE DETAIL
export const getActiveSubjectScheduleDetails = async () => {
  const result = await instance.get("api/schedule/details/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

// export const getSubjectScheduleDetails = async (page, rowsPerPage, keyword) => {
//   var result;
//   if (keyword === '') {
//     result = await instance.get(`api/schedule/details/page/${page + 1}/${rowsPerPage}`);
//   } else {
//     const params = {
//       where: [
//         {
//           "o": "", "p": ["LIKE", "||", {
//             "subjectSchedule": keyword,
//             "schoolYear": keyword,
//             "semesterId": keyword,
//             "classSection": keyword,
//             "subject": keyword,
//             "classCode": keyword,
//             "capacity": keyword
//           }
//           ]
//         }
//       ]
//       , start: page + 1
//       , limit: rowsPerPage
//     }
//     result = await instance.post(`api/schedule/details/q`, params);
//   }

//   if (result.status === 200 || result.status === 201) {
//     return result.data;
//   }
// }

export const getSubjectScheduleDetails = async () => {
  const result = await instance.get("api/schedule/details");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectScheduleDetailsQuery = async (con) => {
  const result = await instance.post("api/schedule/details/q", con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjSchedDetails = async (condition) => {
  const result = await instance.post("api/schedule/details/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectType = async (formValues) => {
  const result = await instance.get("api/references/subject-types", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getRooms = async (formValues) => {
  const result = await instance.get("api/references/rooms", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjects = async (formValues) => {
  const result = await instance.get("api/references/subjects", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getClassSections = async (formValues) => {
  const result = await instance.post("api/references/class-sections/f", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data.data;
  }
}

export const getStudentCategory = async (formValues) => {
  const result = await instance.get("api/references/student-categories", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getStudent = async (formValues) => {
  const result = await instance.get("api/students", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectSchedule = async (formValues) => {
  const result = await instance.get("/api/schedules/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectScheduleQuery = async (q) => {
  const result = await instance.post("/api/schedules/q", q);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getEnrollmentData = async (formValues) => {
  const result = await instance.get("api/enrollments/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createSubjectScheduleDetail = async (formValues) => {
  const result = await instance.post("api/schedule/details", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  } else {
    return result
  }
}

export const updateSubjectScheduleDetail = async (id, formValues) => {
  const result = await instance.patch(`api/schedule/details/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  } else {
    return result.message;
  }
}

export const deleteSubjectScheduleDetail = async (id) => {
  const result = await instance.delete(`api/schedule/details/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectScheduleDetail = async (id) => {
  const result = await instance.get(`api/schedule/details/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectScheduleDetailList = async () => {
  const result = await instance.get(`api/schedule/detail-lists`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createSubjectScheduleDetailList = async (formValues) => {
  const result = await instance.post("api/schedule/detail-lists", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateSubjectScheduleDetailList = async (id, formValues) => {
  const result = await instance.patch(`api/schedule/detail-lists/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteSubjectScheduleDetailList = async (id) => {
  const result = await instance.delete(`api/schedule/detail-lists/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getCurriculumSubjectFilter = async (con) => {
  const result = await instance.post(`api/course-outlines/subjectFilter`, con);

  if (result.status === 200 || result.status === 201) {
    return result.data.data;
  }


}

export const SubjectScheduleDetailAddUpdate = async (formValues) => {
  const result = await instance.post("api/schedule/details/addUpdate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  } else {
    return result
  }
}

export const SubjectScheduleDetailDelete = async (formValues) => {
  const result = await instance.post("api/schedule/details/delete", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  } else {
    return result
  }
}

export const subjectScheduleAddFaculty = async (formValues) => {
  const result = await instance.post("api/schedule/details/addFaculty", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const subjectScheduleAddFacultyLab = async (formValues) => {
  const result = await instance.post("api/schedule/details/addFacultyLab", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const subjectScheduledeleteFaculty = async (formValues) => {
  const result = await instance.post("api/schedule/details/deleteFaculty", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getEmployeeSelect = async () => {
  const result = await instance.get("api/schedule/details/employeeSelect");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFacultyMergerSelect = async (val) => {
  const result = await instance.post("api/schedule/details/facultyMergerSelect", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const mergeFaculty = async (val) => {
  const result = await instance.post("api/schedule/details/mergeFaculty", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getSubjectScheduleFacultyList = async (val) => {
  const result = await instance.post(`api/schedule/details/facultyList`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addUpdateFacultyList = async (val) => {
  const result = await instance.post(`api/schedule/details/addUpdateFacultyList`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteFacultyList = async (val) => {
  const result = await instance.post(`api/schedule/details/deleteFacultyList`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

// END OF SUBJECT SCHEDULE DETAIL