import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getClassSections, getSubjects, getRooms, getSubjectType, getSubjSchedDetails,
  getSubjectScheduleDetailsQuery, getSubjectScheduleQuery, getCurriculumSubjectFilter, SubjectScheduleDetailAddUpdate,
  SubjectScheduleDetailDelete, subjectScheduleAddFaculty, getEmployeeSelect, subjectScheduleAddFacultyLab,
  subjectScheduledeleteFaculty, mergeFaculty, getFacultyMergerSelect
} from './__hooks__';
import IMTableCollapsible from '../../../../../../_metronic/layout/components/custom/table/IMTableCollapsibleFacultyLoading'
import { useParams } from 'react-router-dom';
import IMDialog from '../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import AddUpdateModal from '../../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import { merge } from 'jquery';
import MergeFacultyModal from '../../../../../../_metronic/layout/components/custom/modal/MergeFacultyModal';
import Loading from '../../../../../../_metronic/layout/components/custom/forms/Loading';


export default function List(props) {
  const { state: { subjectSchedules, subjectScheduleDetails, auth }, dispatch } = useAppContext();
  const [results, setResults] = useState(null);
  const [selectedId, setSelectedId] = useState()
  const { id } = useParams();

  const activeSy = auth.data.schoolyears.enrollment.SchoolYear
  const activeSem = auth.data.schoolyears.enrollment.SemesterId


  const [classSection, setClassSection] = useState()
  const [classSectionSelect, setClassSectionSelect] = useState()
  const [subject, setSubject] = useState()
  const [subSelect, setSubSelect] = useState([{ tableId: '', code: 'Set subjects in Course Outline' }])
  const [room, setRooms] = useState()
  const [roomSelect, setRoomSelect] = useState()
  const [subjectType, setSubjectType] = useState()
  const [subjectTypeSelect, setSubjectTypeSelect] = useState()
  const [employeeSelect, setEmployeeSelect] = useState(null)
  const [classCodeSelect, setClassCodeSelect] = useState(null)

  const [subSchedSemester, setSubSchedSemester] = useState(props.location.data == undefined ? undefined : props.location.data.SemesterId)
  const [subSchedSchoolYear, setSubSchedSchoolYear] = useState(props.location.data == undefined ? undefined : props.location.data.SchoolYear)
  const [startingCode, setStartingCode] = useState(props.location.data == undefined ? undefined : props.location.data.StartingClassCode)
  const [subSchedInstitute, setSubSchedInstitute] = useState()
  const [subSchedSySem, setSubSchedSySem] = useState()
  const [classCode, setClassCode] = useState()
  const [merging, setMerging] = useState({ currentId: null, subjectScheduleDetailIdMerger: null })

  const [onOpenSubSchedDetail, setOnOpenSubSchedDetail] = useState(false)
  const [subSchedDetailData, setSubSchedDetailData] = useState()


  const [isOpenFacultyMerger, setIsOpenFacultyMerger] = useState(false)


  let resultQuery = { where: `WHERE SubjectScheduleId = ${parseInt(id)} ORDER BY ClassCode ASC` }

  const executeGetSubjectScheduleDetl = async (q) => {
    await getSubjectScheduleDetailsQuery(q)
      .then(response => {
        if (response.success) {

          let resLength = response.data.length
          let nextIndex = resLength - 1
          setResults(response.data);

        } else {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
        }
      })
      .catch(error => {
        console.log(error)
      })
  }


  const handleCloseSubSchedDetail = () => {
    setOnOpenSubSchedDetail(false)
    setSubSchedDetailData()
  }


  const handleOpenSubSchedDetail = (subSchedDetailId) => {
    let query = { where: `WHERE SubjectScheduleDetailId = ${subSchedDetailId}` }
    const exe = async (q) => {
      await getSubjSchedDetails(q)
        .then(res => {
          console.log(res)
          setSubSchedDetailData(res.data)
        })
        .catch(e => {

        })
    }

    exe(query)
    setOnOpenSubSchedDetail(true)

  }


  const onUpdateSubSchedDetail = async (values) => {
    console.log(values)

    await subjectScheduleAddFaculty({ subjectScheduleDetailId: values.id, employeeId: values.employeeId })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.log(e)
      })



    await subjectScheduleAddFacultyLab({ subjectScheduleDetailId: values.id, employeeId: values.employeeLabId })
      .then(res => {
        console.log(res)
      })
      .catch(e => {
        console.log(e)
      })



    Promise.all([subjectScheduleAddFaculty, subjectScheduleAddFacultyLab])
      .then(resp => {
        executeGetSubjectScheduleDetl(resultQuery)

      })
  }

  const onSaveSubSchedDetail = (values, cc) => {
    let nextClsCode
    let resLength = results.length
    let nextIndex = resLength - 1
    if (results.length == 0) {
      nextClsCode = startingCode
    } else {
      nextClsCode = parseInt(results[nextIndex].ClassCode) + 1
    }

    const subValues = {
      subjectScheduleDetailId: 0,
      subjectScheduleDetailListId: 0,
      subjectScheduleId: parseInt(id),
      classCode: nextClsCode,
      subjectId: values.subjectId,
      classSectionId: values.classSectionId,
      capacity: values.capacity,
      schoolYear: values.schoolYear,
      semesterId: values.semesterId,
      timeStart: values.timeStart,
      timeEnd: values.timeEnd,
      labTimeStart: null,
      labTimeEnd: null,
      dayCode: values.dayCode,
      roomId: values.roomId,
      createdBy: auth.data.Username
    }


    const exeAddSubSchedDetl = async (subSchedVal) => {
      alert('test1')
      await SubjectScheduleDetailAddUpdate(subSchedVal)
        .then(res => {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Subject schedule added successfully' });
          executeGetSubjectScheduleDetl(resultQuery)
          console.log(res)
        })
        .catch(e => {
          console.log(e)
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: e.message });
        })

    }
    exeAddSubSchedDetl(subValues)

  }


  const onDeleteFaculty = async (id) => {
    await subjectScheduledeleteFaculty({ subjectScheduleDetailId: id })
      .then(res => {
        executeGetSubjectScheduleDetl(resultQuery)

      })
      .catch(e => {
        executeGetSubjectScheduleDetl(resultQuery)

      })
  }


  const handleDelete = (subSchedDetlId, subSchedDetlListId) => {
    const exeDeleteSubSchedDetl = async (id) => {
      await SubjectScheduleDetailDelete(id)
        .then(res => {
          executeGetSubjectScheduleDetl(resultQuery)
        })
        .catch(e => {

        })
    }
    exeDeleteSubSchedDetl({ subjectScheduleDetailId: subSchedDetlId })

  }



  const Query = (operation, condition, setData) => {
    let obj = eval("(" + condition + ")");
    const execute = async () => {
      await operation(obj)
        .then(response => {
          if (response.success) {

            setData(response.data)
          }
          return
        })
        .catch(error => {
          if (error) {
            return error
          } else {
            return
          }
        })
    }
    execute()
  }

  const MergeContent = (props) => {

    const selectChange = name => event => { setMerging({ ...merging, subjectScheduleDetailIdMerger: event }) }



    return (
      <>
        <main className='p-5'>
          <IMSelect
            data={props.classCodeSelect}
            onHandleChange={selectChange()}
            refClassContainer=""
            name=""
            isRequired={false}
            withLabel={true}
            label="Select Class Code to Merge"
            placeHolder="Class Code"
            forwardRef={() => { }}
            selectedId={merging.subjectScheduleDetailIdMerger !== null ? merging.subjectScheduleDetailIdMerger : ''}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[
            ]
            }
            field={{
              tableId: 'tableId',
              display: 'code'
            }}
          />
        </main>

      </>

    )
  }


  const mergeSubSchedFaculty = async (id) => {
    setIsOpenFacultyMerger(true)
    setMerging({ ...merging, currentId: id })
  }

  const onMerge = async () => {
    await mergeFaculty(merging)
      .then(res => {
        setIsOpenFacultyMerger(false)
        executeGetSubjectScheduleDetl(resultQuery)

      })
      .catch(e => {

      })
  }


  const onCloseMerging = () => {
    setIsOpenFacultyMerger(false)

  }


  useEffect(() => {


    Query(getSubjects, null, setSubject)
    Query(getRooms, null, setRooms)
    Query(getSubjectType, null, setSubjectType)

    const getStartingClassCode = async (q) => {
      await getSubjectScheduleQuery(q)
        .then(res => {
          setStartingCode(res.data[0].StartingClassCode)
          setSubSchedSemester(res.data[0].SemesterId)
          setSubSchedSchoolYear(res.data[0].SchoolYear)
          setSubSchedInstitute(res.data[0].InstituteDesc)
          setSubSchedSySem(`${res.data[0].SYDesc} - ${res.data[0].Semester}`)

          getClassSections({ where: `WHERE InstituteId = ${res.data[0].InstituteId}` })
            .then(data => {
              setClassSection(data)
            })

          getCurriculumSubjectFilter({ instituteId: res.data[0].InstituteId, schoolYear: res.data[0].SchoolYear, semesterId: res.data[0].SemesterId })
            .then(data => {
              if (data.length > 0)
                setSubSelect(data)
            })
        })
    }

    const getEmployeeSelects = async () => {
      await getEmployeeSelect()
        .then(res => {
          console.log(res)
          setEmployeeSelect(res.data)
        })
        .catch(e => {
          console.log(e)
        })
    }


    getEmployeeSelects()
    getStartingClassCode({ where: `WHERE sched.SubjectScheduleId = ${parseInt(id)}` })

  }, [])

  useEffect(() => {
    if (subSchedSchoolYear !== undefined && subSchedSemester !== undefined) {
      const getFacultyMergeSelect = async () => {
        await getFacultyMergerSelect({ schoolYear: subSchedSchoolYear, semesterId: subSchedSemester })
          .then(res => {
            setClassCodeSelect(res.data)
          })
          .catch(e => {
            console.log(e)
          })
      }
      getFacultyMergeSelect()

    }
  }, [subSchedSchoolYear, subSchedSchoolYear])





  const handleEdit = (id) => {
    console.log(id)
    setSelectedId(id);
  }



  useEffect(() => {
    executeGetSubjectScheduleDetl(resultQuery);
  }, [])


  useEffect(() => {
    if (results !== null && results.length > 0) {
      setClassCode(results[0].ClassCodeLimit)
    }
  }, [results])


  console.log(results)

  return (
    <>
      {results && employeeSelect && classCodeSelect ?



        <>
          {/* <AddUpdateModal
            title="Merge Faculty"
            isOpen={isOpenFacultyMerger}
            content={MergeContent}
            handleClose={onCloseMerging}
            onSubmit={onMerge}
            isMerger={true}
            id=""

          /> */}

          <MergeFacultyModal
            title="Merge Faculty"
            isOpen={isOpenFacultyMerger}
            Content={MergeContent}
            handleClose={onCloseMerging}
            classCodeSelect={classCodeSelect}
            onSubmit={onMerge}
          />

          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label text-primary">{subSchedInstitute} <span className='lead text-dark'>({subSchedSySem})</span> </h3>
                <br />
              </div>

            </div>
            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>


              <IMTableCollapsible
                results={results}
                handleEdit={handleEdit}
                selectedId={selectedId}
                classSectionSelect={classSectionSelect}
                subSelect={subSelect}
                roomSelect={roomSelect}
                employeeSelect={employeeSelect}
                subjectTypeSelect={subjectTypeSelect}
                handleDelete={handleDelete}
                subSchedId={parseInt(id)}
                handleCloseSubSchedDetail={handleCloseSubSchedDetail}
                handleOpenSubSchedDetail={handleOpenSubSchedDetail}
                onOpenSubSchedDetail={onOpenSubSchedDetail}
                subSchedDetailData={subSchedDetailData}
                onUpdateSubSchedDetail={onUpdateSubSchedDetail}
                onSaveSubSchedDetail={onSaveSubSchedDetail}
                semesterId={subSchedSemester}
                schoolYear={subSchedSchoolYear}
                classCode={classCode}
                executeGetSubjectScheduleDetl={executeGetSubjectScheduleDetl}
                onDeleteFaculty={onDeleteFaculty}
                mergeSubSchedFaculty={mergeSubSchedFaculty}
              />

            </div>
          </div>

        </>
        :
        <Loading />

      }

    </>
  );
}