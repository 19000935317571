import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { getActiveInstitutes, getActiveInstitutesByStudentCategories } from './__hooks__';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export function InstituteSelect(props) {
    const { refOnHandleChange, refClassContainer,
        refWithLabel, refIsRequired,
        forwardRef, selectedId,

        refClassName, refWithDescription,
        refDisabled, refError,
        refErrorMessage, refIsStatic,
        refStaticData } = props;
    const { state: { studentCategories }, dispatch } = useAppContext();
    const [disabled, setDisabled] = useState(refDisabled);
    const [results, setResults] = useState(refIsStatic ? Object.values(refStaticData) : []);
    useEffect(() => {
        if (!refIsStatic) {
            localStorage.removeItem('withSelectedInstitute');
            const execute = async () => {
                if (props.refWithFilter) {

                    if (studentCategories.length !== 0) {
                        if (Object.values(studentCategories.data).length > 0) {




                            await getActiveInstitutes()
                                .then(response => {

                                    if (response.success) {
                                        dispatch({ type: referenceTypes.GET_INSTITUTES, data: response.data });
                                        setResults(response.data);
                                        setDisabled(false);
                                    } else {
                                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                                    }
                                })
                                .catch(error => {
                                    if (error.response.status === 401) {
                                        localStorage.clear();
                                        history.push('/auth/login');
                                    } else {
                                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                    }
                                })

                        } else {

                            if (Object.values(studentCategories.data).length > 0) {

                                if (localStorage.getItem('withSelectedStudentCategory')) {
                                    await getActiveInstitutesByStudentCategories(Object.values(studentCategories.data)[0])
                                        .then(response => {
                                            if (response.success) {
                                                dispatch({ type: referenceTypes.GET_INSTITUTES, data: response.data });
                                                setResults(response.data);
                                                setDisabled(false);
                                            } else {
                                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                                            }
                                        })
                                        .catch(error => {
                                            if (error.response.status === 401) {
                                                localStorage.clear();
                                                history.push('/auth/login');
                                            } else {
                                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                            }
                                        })
                                } else {
                                    if (Object.values(studentCategories.data).filter(row => row.StudentCategoryId === props.studentCategory)[0] !== undefined) {
                                        await getActiveInstitutesByStudentCategories(Object.values(studentCategories.data).filter(row => row.StudentCategoryId === props.studentCategory)[0])
                                            .then(response => {
                                                if (response.success) {
                                                    dispatch({ type: referenceTypes.GET_INSTITUTES, data: response.data });
                                                    setResults(response.data);
                                                    setDisabled(false);
                                                } else {
                                                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                                                }
                                            })
                                            .catch(error => {
                                                if (error.response.status === 401) {
                                                    localStorage.clear();
                                                    history.push('/auth/login');
                                                } else {
                                                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                                }
                                            })
                                    }
                                }
                            }

                        }
                    }
                } else {

                    await getActiveInstitutes()
                        .then(response => {

                            if (response.success) {
                                dispatch({ type: referenceTypes.GET_INSTITUTES, data: response.data });
                                setResults(response.data);
                                setDisabled(false);
                            } else {
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                localStorage.clear();
                                history.push('/auth/login');
                            } else {
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                            }
                        })
                }

            }
            execute();

            return () => {
                dispatch({ type: referenceTypes.GET_INSTITUTE, data: [] });
            }
        }
    }, [dispatch, props.refWithFilter, studentCategories, props.studentCategory, refIsStatic])

    // const handleChange = val => {
    //     dispatch({ type: referenceTypes.SELECTED_INSTITUTE, data: results.filter(row => row.InstituteId === Number(val)) });
    //     localStorage.setItem('withSelectedInstitute', true);
    // }
    return (
        <IMSelect
            name="instituteId"
            isRequired={refIsRequired}
            withLabel={refWithLabel}
            label="Institute"
            placeHolder="Institute"
            forwardRef={forwardRef}
            selectedId={selectedId}
            data={results}
            field={{
                tableId: 'InstituteId',
                code: 'Code',
                display: 'Description'
            }}
            onHandleChange={refOnHandleChange}
            withClassName={refClassName}
            withDescription={refWithDescription}
            withClassContainer={refClassContainer}
            description="Please enter your institute."
            disabled={disabled}
            error={refError}
            errorMessage={refErrorMessage}
        />
    );
}