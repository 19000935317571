import instance from '../../../../../apis/local/systemAPI';

// START OF COURSE
export const getActiveCourses = async () => {
  const result = await instance.get("api/references/courses/active")
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getActiveCoursesByInstituteId = async (institute) => {
  const { InstituteId } = institute;
  const params = {
    IsActive: 1,
    InstituteId: InstituteId
  }

  const result = await instance.post("api/references/courses/f", params)
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getCourses = async () => {
  const result = await instance.get("api/references/courses");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getInstitutes = async () => {
  const result = await instance.get("api/references/institutes");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createCourseProgram = async (formValues) => {
  const result = await instance.post("api/references/courses", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseProgram = async (formValues) => {
  const result = await instance.get("api/references/courses", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCourseProgram = async (id, formValues) => {
  const result = await instance.patch(`api/references/courses/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteCourseProgram = async (id) => {
  const result = await instance.delete(`api/references/courses/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getRoleCourse = async () => {
  const result = await instance.get("api/role/courses");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourse = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/courses/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Description": keyword,
            "Code": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/courses/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createCourse = async (formValues) => {
  const result = await instance.post("api/references/courses/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCourse = async (id, formValues) => {
  const result = await instance.patch(`api/references/courses/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteCourse = async (id) => {
  const result = await instance.delete(`api/references/courses/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitute = async () => {
  const result = await instance.get(`api/references/institutes/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}





// END OF COURSE