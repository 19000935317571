import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { enrollmentTypes } from './__hooks__/types';
import { FormControlLabel, Switch } from '@material-ui/core';
import {
  SchoolYearSelect, SemesterSelect, StudentCategorySelect
  , InstituteSelect, CourseSelect, YearLevelSelect, ModeOfPaymentSelect
} from '../../References/';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();

  useEffect(() => {
    register({ name: 'schoolYear' }, { required: true });
    register({ name: 'semesterId' }, { required: true });
    register({ name: 'studentCategoryId' }, { required: true });
    register({ name: 'instituteId' });
    register({ name: 'courseId' });
    register({ name: 'yearLevelId' });
    // register({ name: 'paymentModeId' }, { required: true });
  }, [register])

  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const onSubmit = formValues => {
    const { schoolYear, semesterId, referenceName, studentCategoryId, instituteId, courseId, yearLevelId, paymentModeId, isActive } = formValues;

    const convertedFormValues = {
      schoolYear: schoolYear,
      semesterId: semesterId,
      referenceName: referenceName,
      studentCategoryId: studentCategoryId,
      instituteId: instituteId === "" ? null : instituteId,
      courseId: courseId === "" ? null : courseId,
      yearLevelId: yearLevelId === "" ? null : yearLevelId,
      // paymentModeId: paymentModeId,
      isActive: isActive ? 1 : 0,
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <SchoolYearSelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.SchoolYear === undefined ? "" : props.doc.SchoolYear}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}
                refError={!!errors.schoolYear}
                refErrorMessage={errors.schoolYear && errors.schoolYear.type === "required" && 'School year is required'}
              />
              <SemesterSelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.SemesterId === undefined ? "" : props.doc.SemesterId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}

                refError={!!errors.semesterId}
                refErrorMessage={errors.semesterId && errors.semesterId.type === "required" && 'Semester is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Reference Name <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="referenceName"
                  defaultValue={props.doc.ReferenceName === undefined ? "" : props.doc.ReferenceName}
                  className={`form-control${errors.referenceName === undefined ? "" : errors.referenceName && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your reference name.</span>
                {errors.referenceName && errors.referenceName.type === "required" && <div className="invalid-feedback">Reference name is required</div>}
              </div>
              <StudentCategorySelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.StudentCategoryId === undefined ? "" : props.doc.StudentCategoryId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}

                refError={!!errors.studentCategoryId}
                refErrorMessage={errors.studentCategoryId && errors.studentCategoryId.type === "required" && 'Student category is required'}
              />
              <InstituteSelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={false}
                forwardRef={setValue}
                selectedId={props.doc.InstituteId === undefined ? "" : props.doc.InstituteId}
                studentCategory={props.doc.StudentCategoryId === undefined ? "" : props.doc.StudentCategoryId}
                refWithFilter={true}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}

                refError={!!errors.instituteId}
                refErrorMessage={errors.instituteId && errors.instituteId.type === "required" && 'Institute is required'}
              />
              <CourseSelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={false}
                forwardRef={setValue}
                selectedId={props.doc.CourseId === undefined ? "" : props.doc.CourseId}
                institute={props.doc.InstituteId === undefined ? "" : props.doc.InstituteId}
                refWithFilter={true}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}

                refError={!!errors.courseId}
                refErrorMessage={errors.courseId && errors.courseId.type === "required" && 'Course is required'}
              />
              <YearLevelSelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={false}
                forwardRef={setValue}
                selectedId={props.doc.YearLevelId === undefined ? "" : props.doc.YearLevelId}
                institute={props.doc.InstituteId === undefined ? "" : props.doc.InstituteId}
                refWithFilter={true}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}

                refError={!!errors.yearLevelId}
                refErrorMessage={errors.yearLevelId && errors.yearLevelId.type === "required" && 'Year level is required'}
              />
              {/* <ModeOfPaymentSelect
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.PaymentModeId === undefined ? "" : props.doc.PaymentModeId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refOnHandleChange={switchHandleChange}

                refError={!!errors.paymentModeId}
                refErrorMessage={errors.paymentModeId && errors.paymentModeId.type === "required" && 'Payment mode is required'}
              /> */}
              <div className="form-group mt-5">
                <FormControlLabel
                  name="isActive"
                  inputRef={register()}
                  control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                  label="Active"
                />
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={enrollmentTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}