import React from "react";


export default function SelectCreator(operation, setData, id, code, con, isMasterlist) {

    const execute = async () => {
        await operation(con)
            .then(res => {
                if (res.success) {
                    console.log(res)
                    let arr = []
                    if (id == 'SchoolId' || id == 'CourseId') {
                        arr.push({ tableId: null, code: 'Not Listed' })
                    }
                    if (id == 'DroppingReasonId') {
                        arr.push({ tableId: -1, code: 'Not Listed' })
                    }
                    if (id == 'SubjectCategoryId') {
                        arr.push({ tableId: null, code: 'Unassigned' })
                    }
                    if (id == 'YearLevelId' && isMasterlist == true) {
                        arr.push({ tableId: null, code: 'NA' })
                    }
                    if (id == 'YearLevelId' && isMasterlist != true) {
                        arr.push({ tableId: null, code: 'All' })
                    }
                    if (id == 'CourseIdx') {
                        for (let i = 0; i < res.data.length; i++) {
                            arr.push({ tableId: res.data[i][id], code: res.data[i][code].toString() })
                        }
                    } else {
                        for (let i = 0; i < res.data.length; i++) {
                            if (res.data[i].IsActive == 1)
                                arr.push({ tableId: res.data[i][id], code: res.data[i][code].toString() })
                        }
                    }

                    console.log(arr)
                    setData(arr)
                }
            })
            .catch(e => {
                console.log(e)
                return []
            })
    }

    execute()





}