import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getEnrollmentReport, getEnrollmentChedReport, getActiveSemester,
    getActiveSchoolYear, getInstitute, getCourse, getYearLevel, getEnrollmentStatus
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import { useAppContext } from "../../../../contexts/useAppContext";

export default function EnrollmentReports(props) {
    const { state: { auth } } = useAppContext()
    const { setValue } = useForm();
    const [results, setResults] = useState([])
    const [chedResult, setChedResult] = useState(null)
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const activeSem = auth.data.schoolyears.enrollment.SemesterId

    const [filteredColumn, setFilteredColumn] = useState({
        isInstitute: true,
        isCourse: true,
        isYearLevel: true,
        isStudentType: false,
        isPlaceOfBirth: false,
        isStudentStatus: false,
        isGender: false,
        isCivilStatus: false,
        isAddress: false,
        isAddress2: false,
        isAddress3: false,
        isAddress4: false,
        isBirthDate: false,
        isHomePhone: false,
        isCellphone: false,
        isNationality: false,
        isProvince: false,
        isEmail: false,
        isContactPerson: false,
        isLastSchool: false,
        isSchoolType: false,
        isJuniorHighSchoolAttended: false,
        isJuniorHighSchoolType: false,
        isSeniorHighSchoolAttended: false,
        isSeniorHighSchoolType: false,
        isSeniorHighSchoolStrand: false,
        isSchoolType: false,
        isExamDate: false,
        isEnrollDate: true,
        isReserveDate: false,
        isAssessedDate: false,
        isExamCourse: false,
        isDiscount: false,
        isScholarship: false,
        isStatus: false,
        isProperName: false,
        isLastName: false,
        isFirstName: false,
        isMiddleName: false,
        isWithdrawnDate: false,
        isWithPostingDate: true
    })

    const [filteredStatus, setFilteredStatus] = useState('E')
    const [filteredSem, setFilteredSem] = useState(activeSem)
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredIns, setFilteredIns] = useState(null)
    const [filteredGender, setFilteredGender] = useState(null)
    const [filteredCourse, setFilteredCourse] = useState(null)
    const [filteredYearLevel, setFilteredYearLevel] = useState(null)
    const [filteredDateFrom, setFilteredDateFrom] = useState(moment().format("YYYY-MM-DD"))
    const [filteredDateTo, setFilteredDateTo] = useState(moment().add({ days: 1 }).format("YYYY-MM-DD"))

    const [statusSelect, setStatusSelect] = useState([])
    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [instituteSelect, setInstituteSelect] = useState([])
    const [courseSelect, setCourseSelect] = useState([])
    const [yearLevelSelect, setYearLevelSelect] = useState([])


    const [excelFormat, setExcelFormat] = useState([])
    const [excelChedFormat, setExcelChedFormat] = useState([])
    const [isGenerationgReport, setIsGeneratingReport] = useState(false)
    const [columns, setColumns] = useState([])

    const [isReportGenerated, setIsReportGenerated] = useState(false);

    const selectStatusChange = name => event => { setFilteredStatus(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectGenderChange = name => event => { setFilteredGender(event) }
    const selectYearLevelChange = name => event => { setFilteredYearLevel(event) }
    const selectInsChange = name => event => {
        if (event == 90) {
            setFilteredIns(null)
            setFilteredCourse(null)
        } else {
            setFilteredIns(event)
        }
    }
    const selectCourseChange = name => event => {
        if (event == 1956) {
            setFilteredIns(null)
            setFilteredCourse(null)
        } else {
            setFilteredCourse(event)

        }
    }


    const onGetEnrollmentReport = async (v) => {
        setIsReportGenerated(false);
        let newArray = []
        for (let i = 0; i < v.length; i++) {
            await getEnrollmentReport(v[i])
                .then(res => {
                    for (let items in res.data) {
                        newArray.push(res.data[items]);
                    }
                })
                .catch(e => {
                    setIsGeneratingReport(false);
                })
        }


        setResults(newArray)
        setIsGeneratingReport(false)
        setIsReportGenerated(true);

    }


    const onGetEnrollmentChedReport = async (v) => {
        await getEnrollmentChedReport(v)
            .then(res => {
                setIsGeneratingReport(false)
                setChedResult(res.data)
            })
            .catch(e => {
                setIsGeneratingReport(false)
            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }


    const checkBoxHandleChange = name => event => {
        //console.log(name)
        //console.log(event.target.checked)
        setFilteredColumn({ ...filteredColumn, [name]: event.target.checked });
    };

    const onSubmit = (isChed) => {
        let startDateValue;
        let endDateValue;

        if (document.querySelector("#startDate") !== null) {
            startDateValue = document.querySelector("#startDate").value;
            console.log("val (Start): ", startDateValue);
        }

        if (document.querySelector("#endDate") !== null) {
            endDateValue = document.querySelector("#endDate").value;
            console.log("val (End): ", endDateValue);
        }

        // if(document.getElementById("endDate") !== null){
        //     document.getElementById("endDate").addEventListener("change", function() {
        //         let input = this.value;
        //         let dateEntered = new Date(input);
        //         console.log("input: ",input); //e.g. 2015-11-13
        //         console.log("dateEntered: ",dateEntered); //e.g. Fri Nov 13 2015 00:00:00 GMT+0000 (GMT Standard Time)
        //     });
        // }

        setIsGeneratingReport(true)
        let dataToBeSubmit = []

        // if (filteredCourse !== null && filteredYearLevel !== null) {
        //     dataToBeSubmit.push({
        //         semesterId: filteredSem,
        //         schoolYear: filteredSy,
        //         instituteId: filteredIns,
        //         genderId: filteredGender,
        //         yearLevelId: filteredYearLevel,
        //         courseId: filteredCourse,
        //         enrollmentStatusId: filteredStatus,
        //         startDateValue: startDateValue == '' ? null : startDateValue,
        //         endDateValue: endDateValue == '' ? null : endDateValue,
        //         withInstitute: filteredIns == null ? false : true,
        //         withCourse: filteredCourse == null ? false : true,
        //         withGender: filteredGender == null ? false : true,
        //         withYearLevel: filteredYearLevel == null ? false : true,
        //         withStatus: filteredStatus == null ? false : true

        //     })
        // }

        // else if (filteredCourse !== null && filteredYearLevel == null) {
        //     for (let u = 0; u < yearLevelSelect.length; u++) {
        //         dataToBeSubmit.push({
        //             semesterId: filteredSem,
        //             schoolYear: filteredSy,
        //             instituteId: filteredIns,
        //             genderId: filteredGender,
        //             yearLevelId: yearLevelSelect[u].tableId,
        //             courseId: filteredCourse,
        //             enrollmentStatusId: filteredStatus,
        //             startDateValue: startDateValue == '' ? null : startDateValue,
        //             endDateValue: endDateValue == '' ? null : endDateValue,
        //             withInstitute: filteredIns == null ? false : true,
        //             withCourse: filteredCourse == null ? false : true,
        //             withGender: filteredGender == null ? false : true,
        //             withYearLevel: true,
        //             withStatus: filteredStatus == null ? false : true
        //         })
        //     }
        // }

        // else if (filteredCourse == null && filteredYearLevel !== null) {
        //     for (let i = 0; i < courseSelect.length; i++) {
        //         dataToBeSubmit.push({
        //             semesterId: filteredSem,
        //             schoolYear: filteredSy,
        //             instituteId: filteredIns,
        //             genderId: filteredGender,
        //             yearLevelId: filteredYearLevel,
        //             courseId: courseSelect[i].tableId,
        //             enrollmentStatusId: filteredStatus,
        //             startDateValue: startDateValue == '' ? null : startDateValue,
        //             endDateValue: endDateValue == '' ? null : endDateValue,
        //             withInstitute: filteredIns == null ? false : true,
        //             withCourse: true,
        //             withGender: filteredGender == null ? false : true,
        //             withYearLevel: filteredYearLevel == null ? false : true,
        //             withStatus: filteredStatus == null ? false : true

        //         })
        //     }
        // }

        // else {
        //     for (let i = 0; i < courseSelect.length; i++) {
        //         if (courseSelect[i].tableId != null)
        //             for (let u = 0; u < yearLevelSelect.length; u++) {
        //                 dataToBeSubmit.push({
        //                     semesterId: filteredSem,
        //                     schoolYear: filteredSy,
        //                     instituteId: filteredIns,
        //                     genderId: filteredGender,
        //                     yearLevelId: yearLevelSelect[u].tableId,
        //                     courseId: courseSelect[i].tableId,
        //                     enrollmentStatusId: filteredStatus,
        //                     startDateValue: startDateValue == '' ? null : startDateValue,
        //                     endDateValue: endDateValue == '' ? null : endDateValue,
        //                     withInstitute: filteredIns == null ? false : true,
        //                     withCourse: true,
        //                     withGender: filteredGender == null ? false : true,
        //                     withYearLevel: true,
        //                     withStatus: filteredStatus == null ? false : true

        //                 })
        //             }

        //     }
        // }

        dataToBeSubmit.push({
            semesterId: filteredSem,
            schoolYear: filteredSy,
            instituteId: filteredIns === '' ? null : filteredIns,
            genderId: filteredGender === '' ? null : filteredGender,
            yearLevelId: filteredYearLevel === '' ? null : filteredYearLevel,
            courseId: filteredCourse === '' ? null : filteredCourse,
            enrollmentStatusId: filteredStatus,
            startDateValue: startDateValue === '' ? null : startDateValue,
            endDateValue: endDateValue === '' ? null : endDateValue,
            withInstitute: (filteredIns === null || filteredIns === '') ? false : true,
            withCourse: (filteredCourse === null || filteredCourse === '') ? false : true,
            withGender: (filteredGender === null || filteredGender === '') ? false : true,
            withYearLevel: (filteredYearLevel === null || filteredYearLevel === '') ? false : true,
            withStatus: (filteredStatus === null || filteredStatus === '') ? false : true

        })

        onGetEnrollmentReport(dataToBeSubmit)
    }

    useEffect(() => {
        if (isReportGenerated) {
            setIsReportGenerated(false);
            for (let i = 0; i < results.length; i++) {
                results[i].ProperName = results[i].ProperName.replace(/\b\w/g, match => match.toUpperCase());
                //results[i].LastName = results[i].LastName.replace(/\b\w/g, match =>match.toUpperCase());
                //results[i].FirstName = results[i].FirstName.replace(/\b\w/g, match =>match.toUpperCase());
                //results[i].MiddleName = results[i].MiddleName.replace(/\b\w/g, match =>match.toUpperCase());
            }
        }

    }, [isReportGenerated]);



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')
        SelectCreator(getCourse, setCourseSelect, 'CourseId', 'Code')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')


        const exeGetEnrollmentStatus = async (con) => {

            let data = []

            data.push({ tableId: 'E', code: 'Enrolled Students' })
            data.push({ tableId: 'A', code: 'Assessed Students' })
            //data.push({ tableId: 'AD', code: 'Add and Drop Students' })
            data.push({ tableId: 'W', code: 'Withdrawn Students' })
            setStatusSelect(data)

        }




        exeGetEnrollmentStatus()

    }, [])

    useEffect(() => {
        let columnArr = [
            { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
            { id: 'StudentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
            { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        ]

        if (filteredColumn.isProperName === true) columnArr.push({ id: 'ProperName', label: 'Student Proper Name', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isLastName === true) columnArr.push({ id: 'LastName', label: 'Last Name', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isFirstName === true) columnArr.push({ id: 'FirstName', label: 'First Name', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isMiddleName === true) columnArr.push({ id: 'MiddleName', label: 'Middle Name', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isInstitute === true) columnArr.push({ id: 'College', label: 'College', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isCourse === true) columnArr.push({ id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isYearLevel === true) columnArr.push({ id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isStudentType === true) columnArr.push({ id: 'StudentType', label: 'Student Type', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isPlaceOfBirth === true) columnArr.push({ id: 'BirthPlace', label: 'Place of Birth', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isStudentStatus === true) columnArr.push({ id: 'StudentStatus', label: 'Student Status', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isGender === true) columnArr.push({ id: 'Gender', label: 'Gender', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isCivilStatus === true) columnArr.push({ id: 'CivilStatus', label: 'Civil Status', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isAddress === true) columnArr.push({ id: 'Address', label: 'Address 1', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isAddress2 === true) columnArr.push({ id: 'Address2', label: 'Address 2 (Barangay)', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isAddress3 === true) columnArr.push({ id: 'Address3', label: 'Address 3 (City/Municipality)', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isAddress4 === true) columnArr.push({ id: 'Address4', label: 'Address 4 (Province)', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isBirthDate === true) columnArr.push({ id: 'BirthDate', label: 'Birth Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isHomePhone === true) columnArr.push({ id: 'HomePhone', label: 'Home Phone', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isCellphone === true) columnArr.push({ id: 'CellPhone', label: 'Cellphone', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isNationality === true) columnArr.push({ id: 'Nationality', label: 'Nationality', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isProvince === true) columnArr.push({ id: 'Province', label: 'Province', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isEmail === true) columnArr.push({ id: 'Email', label: 'Email Address', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isContactPerson === true) columnArr.push({ id: 'ContactPerson', label: 'Contact Person', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isLastSchool === true) columnArr.push({ id: 'LastSchool', label: 'Last School Attended', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isSchoolType === true) columnArr.push({ id: 'SchoolType', label: 'School Type', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isJuniorHighSchoolAttended === true) columnArr.push({ id: 'JuniorHighSchoolAttended', label: 'Junior High School Attended', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isJuniorHighSchoolType === true) columnArr.push({ id: 'JuniorHighSchoolType', label: 'Junior High School Type', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isSeniorHighSchoolAttended === true) columnArr.push({ id: 'SeniorHighSchoolAttended', label: 'Senior High School Attended', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isSeniorHighSchoolType === true) columnArr.push({ id: 'SeniorHighSchoolType', label: 'Senior High School Type', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isSeniorHighSchoolStrand === true) columnArr.push({ id: 'Strand', label: 'Track/Strand', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isExamDate === true) columnArr.push({ id: 'ExamDate', label: 'Exam Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isEnrollDate === true) columnArr.push({ id: 'DateEnrolled', label: 'Enrollment Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isReserveDate === true) columnArr.push({ id: 'ReserveDate', label: 'Reserve Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isAssessedDate === true) columnArr.push({ id: 'AssessedDate', label: 'Assessed Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isExamCourse === true) columnArr.push({ id: 'ExamCourse', label: 'Exam Course', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isDiscount === true) columnArr.push({ id: 'Discount', label: 'Discount', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isScholarship === true) columnArr.push({ id: 'Scholarship', label: 'Scholarship', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isStatus === true) columnArr.push({ id: 'EnrollmentStatus', label: 'Enrollment Status', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isWithdrawnDate === true) columnArr.push({ id: 'WithdrawnDate', label: 'Withdrawn Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })
        if (filteredColumn.isWithPostingDate === true) columnArr.push({ id: 'PostingDate', label: 'Posting Date', align: 'left', withSorting: true, hidden: false, disablePadding: false })

        setColumns(columnArr)


    }, [filteredColumn, results])

    useEffect(() => {
        if (results !== undefined) {
            let report = [{}]
            let report2 = []
            for (let i = 0; i < results.length; i++) {
                let obj = {}
                obj['StudentNo'] = `${results[i].StudentNo}`
                obj['Student Name'] = `${results[i].StudentName}`

                //console.log("Test: ",results[i].ProperName.replace(/\b\w/g, match =>match.toUpperCase()))

                if (filteredColumn.isProperName === true) obj['Student Proper Name'] = `${results[i].ProperName.replace(/\b\w/g, match => match.toUpperCase())}`
                if (filteredColumn.isLastName === true) obj['Last Name'] = `${results[i].LastName.replace(/\b\w/g, match => match.toUpperCase())}`
                if (filteredColumn.isFirstName === true) obj['First Name'] = `${results[i].FirstName.replace(/\b\w/g, match => match.toUpperCase())}`
                if (filteredColumn.isMiddleName === true) obj['Middle Name'] = `${results[i].MiddleName.replace(/\b\w/g, match => match.toUpperCase())}`
                if (filteredColumn.isInstitute === true) obj['College'] = `${results[i].College}`
                if (filteredColumn.isCourse === true) obj['Course'] = `${results[i].Course}`
                if (filteredColumn.isYearLevel === true) obj['Year Level'] = `${results[i].YearLevel}`
                if (filteredColumn.isStudentType === true) obj['Student Type'] = `${results[i].StudentType}`
                if (filteredColumn.isPlaceOfBirth === true) obj['Place of Birth'] = `${results[i].BirthPlace}`
                if (filteredColumn.isStudentStatus === true) obj['Student Status'] = `${results[i].StudentStatus}`
                if (filteredColumn.isGender === true) obj['Gender'] = `${results[i].Gender}`
                if (filteredColumn.isCivilStatus === true) obj['Civil Status'] = `${results[i].CivilStatus}`
                if (filteredColumn.isAddress === true) obj['Address 1'] = `${results[i].Address}`
                if (filteredColumn.isAddress2 === true) obj['Address 2 (Barangay)'] = `${results[i].Address2}`
                if (filteredColumn.isAddress3 === true) obj['Address 3 (City/Municipality)'] = `${results[i].Address3}`
                if (filteredColumn.isAddress4 === true) obj['Address 4 (Province)'] = `${results[i].Address4}`
                if (filteredColumn.isBirthDate === true) obj['Birth Date'] = `${results[i].BirthDate}`
                if (filteredColumn.isHomePhone === true) obj['Home Phone'] = `${results[i].HomePhone}`
                if (filteredColumn.isCellphone === true) obj['Cellular Phone'] = `${results[i].CellPhone}`
                if (filteredColumn.isNationality === true) obj['Nationality'] = `${results[i].Nationality}`
                if (filteredColumn.isProvince === true) obj['Province'] = `${results[i].Address}`
                if (filteredColumn.isEmail === true) obj['Email'] = `${results[i].Email}`
                if (filteredColumn.isLastSchool === true) obj['Last School Attended'] = `${results[i].LastSchool}`
                if (filteredColumn.isSchoolType === true) obj['School Type'] = `${results[i].SchoolType}`
                if (filteredColumn.isJuniorHighSchoolAttended === true) obj['Junior High School Attended'] = `${results[i].JuniorHighSchoolAttended}`
                if (filteredColumn.isJuniorHighSchoolType === true) obj['Junior High School Type'] = `${results[i].JuniorHighSchoolType}`
                if (filteredColumn.isSeniorHighSchoolAttended === true) obj['Senior High School Attended'] = `${results[i].SeniorHighSchoolAttended}`
                if (filteredColumn.isSeniorHighSchoolType === true) obj['Senior High School Type'] = `${results[i].SeniorHighSchoolType}`
                if (filteredColumn.isSeniorHighSchoolStrand === true) obj['Track/Strand'] = `${results[i].Strand}`
                if (filteredColumn.isExamDate === true) obj['ExamDate'] = `${results[i].ExamDate}`
                if (filteredColumn.isEnrollDate === true) obj['Enrollment Date'] = `${results[i].DateEnrolled}`
                if (filteredColumn.isReserveDate === true) obj['Reserved Date'] = `${results[i].ReservedDate}`
                if (filteredColumn.isAssessedDate === true) obj['Assessed Date'] = `${results[i].AssessedDate}`
                if (filteredColumn.isWithdrawnDate === true) obj['Withdrawn Date'] = `${results[i].WithdrawnDate}`
                if (filteredColumn.isExamCourse === true) obj['Exam Course'] = `${results[i].ExamCourse}`
                if (filteredColumn.isDiscount === true) obj['Discount'] = `${results[i].Discount}`
                if (filteredColumn.isScholarship === true) obj['Scholarship'] = `${results[i].Scholarship}`
                if (filteredColumn.isStatus === true) obj['Enrollment Status'] = `${results[i].EnrollmentStatus}`
                if (filteredColumn.isWithPostingDate === true) obj['Posting Date'] = `${results[i].PostingDate}`
                report.push(obj)

            }

            setExcelFormat(report)
            setExcelChedFormat(report2)
        }
    }, [results, filteredColumn])


    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: results.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'applicantReport',
        parentId: null,
        tableTitle: '',
        onPage: 0,
        onRowsPerPage: 10

    }

    const imSelectDropdownStatus = () => {
        return (
            <>
                {/* <label>School Year</label> */}
                <IMSelect
                    data={statusSelect}
                    onHandleChange={selectStatusChange()}
                    refClassContainer=""
                    name="EnrollmentStatusId"
                    isRequired={false}
                    withLabel={false}
                    label="Status"
                    placeHolder="Select Status"
                    forwardRef={setValue}
                    selectedId={filteredStatus === undefined ? '' : filteredStatus}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={false}
                    refStaticData={[

                    ]
                    }
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />
            </>
        )
    }

    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {/* <label>School Year</label> */}
                <IMSelect
                    data={schoolYearSelect}
                    onHandleChange={selectSyChange()}
                    refClassContainer=""
                    name="SchoolYear"
                    isRequired={false}
                    withLabel={false}
                    label="SchoolYear"
                    placeHolder="Select Status"
                    forwardRef={setValue}
                    selectedId={filteredSy === undefined ? activeSy : filteredSy}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={false}
                    refStaticData={[

                    ]
                    }
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />
            </>
        )
    }


    const imSelectDropdownSemesters = () => {
        return (
            <>
                {/* <label>Semester</label> */}
                <IMSelect
                    data={semestersSelect}
                    onHandleChange={selectSemChange()}
                    refClassContainer=""
                    name="Semester"
                    isRequired={false}
                    withLabel={false}
                    label="Semester"
                    placeHolder="Select Semester"
                    forwardRef={setValue}
                    selectedId={filteredSem === undefined ? activeSem : filteredSem}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={false}
                    refStaticData={[

                    ]
                    }
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />
            </>
        )



    }

    const imSelectDropdownInstitute = () => {

        return (
            <>
                {/* <label>Institute</label> */}
                <IMSelect
                    data={instituteSelect}
                    onHandleChange={selectInsChange()}
                    refClassContainer=""
                    name="Institute"
                    isRequired={false}
                    withLabel={false}
                    label="Institute"
                    placeHolder="Select Institute"
                    forwardRef={setValue}
                    selectedId={filteredIns === undefined ? "" : filteredIns}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={false}
                    refStaticData={[

                    ]
                    }
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />
            </>
        )


    }

    const imSelectDropdownCourse = () => {
        return (

            <>
                {/* <label>Course</label> */}
                <IMSelect
                    data={courseSelect}
                    onHandleChange={selectCourseChange()}
                    refClassContainer=""
                    name="Course"
                    isRequired={false}
                    withLabel={false}
                    label="Course"
                    placeHolder="Select Course"
                    forwardRef={setValue}
                    selectedId={filteredCourse === undefined ? "" : filteredCourse}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={false}
                    refStaticData={[

                    ]
                    }
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />


            </>
        )




    }

    const imSelectDropdownGender = () => {
        return (
            <>
                {/* <label>Gender</label> */}
                <IMSelect
                    onHandleChange={selectGenderChange()}
                    refClassContainer=""
                    name="Gender"
                    isRequired={false}
                    withLabel={false}
                    label="Gender"
                    placeHolder="Select Gender"
                    forwardRef={setValue}
                    selectedId={filteredGender === null ? "" : filteredGender}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={true}
                    refStaticData={[
                        { id: 'A', label: 'All' },
                        { id: 'M', label: 'Male' },
                        { id: 'F', label: 'Female' }
                    ]}
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />
            </>
        )


    }


    const imSelectDropdownYearLevel = () => {
        return (
            <>
                {/* <label>Year Level</label> */}
                <IMSelect
                    data={yearLevelSelect}
                    onHandleChange={selectYearLevelChange()}
                    refClassContainer=""
                    name="YearLevel"
                    isRequired={false}
                    withLabel={false}
                    label="Year Level"
                    placeHolder="Select Year Level"
                    forwardRef={setValue}
                    selectedId={filteredYearLevel === null ? "" : filteredYearLevel}
                    refClassName={`text-center`}
                    withDescription={false}
                    //description={`Enrollment Status`}
                    refDisabled={true}
                    refIsStatic={false}
                    refStaticData={[

                    ]
                    }
                    field={{
                        tableId: 'tableId',
                        display: 'code'
                    }}

                />

            </>
        )

    }

    const selectDateRangeStart = () => {
        return (
            <>
                <div className="form-group col">
                    <label htmlFor="startDate">Start Date<span className="text-danger">&nbsp;*</span></label>
                    <input
                        name="startDate"
                        type="date"
                        className="form-control"
                        id="startDate"
                        placeholder="Start Date"
                    />
                </div>
            </>
        )

    }

    //console.log(excelFormat)
    //console.log(excelChedFormat)
    //console.log(chedResult)
    //console.log("statusSelect: ", statusSelect);
    //console.log(courseSelect)
    //console.log(filteredCourse)
    //console.log(yearLevelSelect)

    return (
        <>

            <div className="card">
                <div className="card-header font-weight-bold">
                    <h3 className="mb-0 d-block">  &nbsp;Enrollment Report</h3>
                    <div className="ml-2 mt-2" >
                        <p className=" d-block mb-0">{`SY ${activeSy} - ${parseInt(activeSy) + 1} / ${activeSem}`}</p>

                    </div>
                </div>

                <br></br>

                <div className="row">
                    <div className="col-2">
                        <p className="col-2 d-flex align-items-center">Date Range: </p>
                    </div>

                    <div className="col-3">
                        <label htmlFor="startDate">Start Date</label>
                        <input
                            style={{ width: "70%" }}
                            name="startDate"
                            type="date"
                            className="form-control"
                            id="startDate"
                            placeholder="Start Date"
                        />
                    </div>

                    <div className="col-3">
                        <label htmlFor="endDate">End Date</label>
                        <input
                            style={{ width: "70%" }}
                            name="endDate"
                            type="date"
                            className="form-control"
                            id="endDate"
                            placeholder="End Date"
                        />
                    </div>
                </div>


                <ListFilters
                    selectDropdownSemester={imSelectDropdownSemesters}
                    selectDropdownSchoolYear={imSelectDropdownSchoolYear}
                    selectDropdownInstitute={imSelectDropdownInstitute}
                    selectDropdownCourse={imSelectDropdownCourse}
                    selectDropdownGender={imSelectDropdownGender}
                    selectDropdownYearLevel={imSelectDropdownYearLevel}
                    selectDropdownStatus={imSelectDropdownStatus}
                    onDateFromHandler={onDateFromHandler}
                    onDateToHandler={onDateToHandler}
                    dateFrom={filteredDateFrom}
                    dateTo={filteredDateTo}
                    tableTitle='Filter: '
                    type={'studentEnrollmentReport'}
                    onSubmit={onSubmit}
                    results={results}
                    excelFormat={excelFormat}
                    excelChedFormat={excelChedFormat}
                    enrollmentReport={1}
                    isGeneratingReport={isGenerationgReport}

                />
                <section className="my-5 mx-5 card p-5 bg-secondary">
                    <h6 className=" text-center mb-5">Select Column Data</h6>
                    <div className="row mx-5">
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck1"
                                defaultChecked={filteredColumn.isInstitute}
                                onChange={checkBoxHandleChange("isInstitute")}
                                name="isInstitute"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck1">Institute</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck2"
                                defaultChecked={filteredColumn.isCourse}
                                onChange={checkBoxHandleChange("isCourse")}
                                name="isCourse"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck2">Course</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck3"
                                defaultChecked={filteredColumn.isYearLevel}
                                onChange={checkBoxHandleChange("isYearLevel")}
                                name="isYearLevel"

                            />
                            <label className="form-check-label" htmlFor="exampleCheck3">Year Level</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isProperName"
                                defaultChecked={filteredColumn.isProperName}
                                onChange={checkBoxHandleChange("isProperName")}
                                name="isProperName"
                            />
                            <label className="form-check-label" htmlFor="isProperName">Student Proper Name</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isLastName"
                                defaultChecked={filteredColumn.isLastName}
                                onChange={checkBoxHandleChange("isLastName")}
                                name="isLastName"
                            />
                            <label className="form-check-label" htmlFor="isLastName">Last Name</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isFirstName"
                                defaultChecked={filteredColumn.isFirstName}
                                onChange={checkBoxHandleChange("isFirstName")}
                                name="isFirstName"
                            />
                            <label className="form-check-label" htmlFor="isFirstName">First Name</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isMiddleName"
                                defaultChecked={filteredColumn.isMiddleName}
                                onChange={checkBoxHandleChange("isMiddleName")}
                                name="isMiddleName"
                            />
                            <label className="form-check-label" htmlFor="isMiddleName">Middle Name</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck4"
                                defaultChecked={filteredColumn.isStudentType}
                                onChange={checkBoxHandleChange("isStudentType")}
                                name="isStudentType"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck4">Student Type</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isPlaceOfBirth"
                                defaultChecked={filteredColumn.isPlaceOfBirth}
                                onChange={checkBoxHandleChange("isPlaceOfBirth")}
                                name="isPlaceOfBirth"
                            />
                            <label className="form-check-label" htmlFor="isPlaceOfBirth">Place of Birth</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isStudentStatus"
                                defaultChecked={filteredColumn.isStudentStatus}
                                onChange={checkBoxHandleChange("isStudentStatus")}
                                name="isStudentStatus"
                            />
                            <label className="form-check-label" htmlFor="isStudentStatus">Student Status</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck55"
                                defaultChecked={filteredColumn.isGender}
                                onChange={checkBoxHandleChange("isGender")}
                                name="isGender"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck55">Gender</label>
                        </div>

                    </div>

                    <div className="row mx-5 mt-5">
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck5"
                                defaultChecked={filteredColumn.isCivilStatus}
                                onChange={checkBoxHandleChange("isCivilStatus")}
                                name="isCivilStatus"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck5">Civil Status</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck6"
                                defaultChecked={filteredColumn.isAddress}
                                onChange={checkBoxHandleChange("isAddress")}
                                name="isAddress"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck6">Address 1</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isAddress2"
                                defaultChecked={filteredColumn.isAddress2}
                                onChange={checkBoxHandleChange("isAddress2")}
                                name="isAddress2"
                            />
                            <label className="form-check-label" htmlFor="isAddress2">Barangay (Address 2)</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isAddress3"
                                defaultChecked={filteredColumn.isAddress3}
                                onChange={checkBoxHandleChange("isAddress3")}
                                name="isAddress3"
                            />
                            <label className="form-check-label" htmlFor="isAddress3">City (Address 3)</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isAddress4"
                                defaultChecked={filteredColumn.isAddress4}
                                onChange={checkBoxHandleChange("isAddress4")}
                                name="isAddress4"
                            />
                            <label className="form-check-label" htmlFor="isAddress4">Province (Address 4)</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck7"
                                defaultChecked={filteredColumn.isBirthDate}
                                onChange={checkBoxHandleChange("isBirthDate")}
                                name="isBirthDate"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck7">Birth Date</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck8"
                                defaultChecked={filteredColumn.isHomePhone}
                                onChange={checkBoxHandleChange("isHomePhone")}
                                name="isHomePhone"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck8">Home Phone</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck9"
                                defaultChecked={filteredColumn.isCellphone}
                                onChange={checkBoxHandleChange("isCellphone")}
                                name="isCellphone"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck9">Cellular Phone</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck10"
                                defaultChecked={filteredColumn.isNationality}
                                onChange={checkBoxHandleChange("isNationality")}
                                name="isNationality"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck10">Nationality</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck11"
                                defaultChecked={filteredColumn.isProvince}
                                onChange={checkBoxHandleChange("isProvince")}
                                name="isProvince"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck11">Province</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck12"
                                defaultChecked={filteredColumn.isEmail}
                                onChange={checkBoxHandleChange("isEmail")}
                                name="isEmail"

                            />
                            <label className="form-check-label" htmlFor="exampleCheck12">Email</label>
                        </div>
                    </div>


                    <div className="row mx-5 mt-5">
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck13"
                                defaultChecked={filteredColumn.isContactPerson}
                                onChange={checkBoxHandleChange("isContactPerson")}
                                name="isContactPerson"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck13">Contact Person</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck14"
                                defaultChecked={filteredColumn.isLastSchool}
                                onChange={checkBoxHandleChange("isLastSchool")}
                                name="isLastSchool"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck14">Last School</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck15"
                                defaultChecked={filteredColumn.isSchoolType}
                                onChange={checkBoxHandleChange("isSchoolType")}
                                name="isSchoolType"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck15">School Type</label>
                        </div>

                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck16"
                                defaultChecked={filteredColumn.isExamDate}
                                onChange={checkBoxHandleChange("isExamDate")}
                                name="isExamDate"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck16">Exam Date</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck17"
                                defaultChecked={filteredColumn.isEnrollDate}
                                onChange={checkBoxHandleChange("isEnrollDate")}
                                name="isEnrollDate"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck17">Enroll Date</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck18"
                                defaultChecked={filteredColumn.isReserveDate}
                                onChange={checkBoxHandleChange("isReserveDate")}
                                name="isReserveDate"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck18">Reserve Date</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck19"
                                defaultChecked={filteredColumn.isAssessedDate}
                                onChange={checkBoxHandleChange("isAssessedDate")}
                                name="isAssessedDate"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck19">Assessed Date</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="withdrawnDate"
                                defaultChecked={filteredColumn.isWithdrawnDate}
                                onChange={checkBoxHandleChange("isWithdrawnDate")}
                                name="isWithdrawnDate"
                            />
                            <label className="form-check-label" htmlFor="withdrawnDate">Withdrawn Date</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck20"
                                defaultChecked={filteredColumn.isExamCourse}
                                onChange={checkBoxHandleChange("isExamCourse")}
                                name="isExamCourse"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck20">Exam Course</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck22"
                                defaultChecked={filteredColumn.isDiscount}
                                onChange={checkBoxHandleChange("isDiscount")}
                                name="isDiscount"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck22">Discount</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck23"
                                defaultChecked={filteredColumn.isScholarship}
                                onChange={checkBoxHandleChange("isScholarship")}
                                name="isScholarship"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck23">Scholarship</label>
                        </div>
                        {/* <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck24"
                                defaultChecked={filteredColumn.isStatus}
                                onChange={checkBoxHandleChange("isStatus")}
                                name="isStatus"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck24">Enrollment Status</label>
                        </div> */}
                    </div>

                    <div className="row mx-5 mt-5">
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isJuniorHighSchoolAttended"
                                defaultChecked={filteredColumn.isJuniorHighSchoolAttended}
                                onChange={checkBoxHandleChange("isJuniorHighSchoolAttended")}
                                name="isJuniorHighSchoolAttended"
                            />
                            <label className="form-check-label" htmlFor="isJuniorHighSchoolAttended">Junior High School Attended</label>
                        </div>

                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isJuniorHighSchoolType"
                                defaultChecked={filteredColumn.isJuniorHighSchoolType}
                                onChange={checkBoxHandleChange("isJuniorHighSchoolType")}
                                name="isJuniorHighSchoolType"
                            />
                            <label className="form-check-label" htmlFor="isJuniorHighSchoolType">Junior High School Type</label>
                        </div>

                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSeniorHighSchoolAttended"
                                defaultChecked={filteredColumn.isSeniorHighSchoolAttended}
                                onChange={checkBoxHandleChange("isSeniorHighSchoolAttended")}
                                name="isSeniorHighSchoolAttended"
                            />
                            <label className="form-check-label" htmlFor="isSeniorHighSchoolAttended">Senior High School Attended</label>
                        </div>

                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSeniorHighSchoolType"
                                defaultChecked={filteredColumn.isSeniorHighSchoolType}
                                onChange={checkBoxHandleChange("isSeniorHighSchoolType")}
                                name="isSeniorHighSchoolType"
                            />
                            <label className="form-check-label" htmlFor="isSeniorHighSchoolType">Senior High School Type</label>
                        </div>

                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="isSeniorHighSchoolStrand"
                                defaultChecked={filteredColumn.isSeniorHighSchoolStrand}
                                onChange={checkBoxHandleChange("isSeniorHighSchoolStrand")}
                                name="isSeniorHighSchoolStrand"
                            />
                            <label className="form-check-label" htmlFor="isSeniorHighSchoolStrand">Track/Strand</label>
                        </div>

                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck24"
                                defaultChecked={filteredColumn.isStatus}
                                onChange={checkBoxHandleChange("isStatus")}
                                name="isStatus"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck24">Enrollment Status</label>
                        </div>
                        <div className="form-check col-1">
                            <input
                                type="checkbox"
                                className="form-check-input"
                                id="exampleCheck24"
                                defaultChecked={filteredColumn.isWithPostingDate}
                                onChange={checkBoxHandleChange("isWithPostingDate")}
                                name="isStatus"
                            />
                            <label className="form-check-label" htmlFor="exampleCheck24">Posting Date</label>
                        </div>
                    </div>



                </section>


                <IMTableCustom tableProps={tableProps} />


            </div>


        </>
    )
}