export const referenceTypes = {
    SELECTED_YEAR_LEVEL: 'SELECTED-YEAR-LEVEL',
    GET_YEAR_LEVELS: 'GET-YEAR-LEVELS',
    GET_YEAR_LEVELS_BY_STUDENT_CATEGORY_ID: 'GET-YEAR-LEVELS-BY-STUDENT-CATEGORY-ID',
    GET_YEAR_LEVEL: 'GET-YEAR-LEVEL-BY-ID',
    CREATE: 'CREATE-YEAR-LEVEL',
    UPDATE: 'UPDATE-YEAR-LEVEL',
    DELETE: 'DELETE-YEAR-LEVEL',
    LIST_LINK: '/admin/references/yearlevels',
    CREATE_LINK: '/admin/references/create-yearlevels/',
    UPDATE_LINK: '/admin/references/update-yearlevels/',
    DELETE_LINK: '/admin/references/delete-yearlevels/'
}