import React, { useState, useEffect } from 'react';
import AddUpdateModal from '../../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getCourseOutlineQuery, getCourseOutlineDetailQuery,
  deleteCourseOutlineDetail, getSemester, getYearLevel, addUpdateCourseOutlineDetail,
  getSubjectQuery, getCourseOutlineReport
} from './__hooks__';
import IMTable from '../../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import history from '../../../../../history';
import PrintedForm from '../PrintedForm';
import Loading from '../../../../../../_metronic/layout/components/custom/forms/Loading';

export default function List(props) {
  const { state: { fees, auth }, dispatch } = useAppContext();
  const [results, setResults] = useState([]);
  const [courseOutline, setCourseOutline] = useState(null)
  const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
  const [page, setPage] = useState(fees.page);
  const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
  const [keyword, setKeyword] = useState(fees.keyword);
  const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)
  const [report, setReport] = useState([])
  const updateId = fees.id;
  const { id } = useParams()
  const status = props.location.status || 'addUpdateGradeTemplate'


  const [yearLevelSelect, setYearLevelSelect] = useState(null)
  const [semesterSelect, setSemesterSelect] = useState(null)
  const [subjectSelect, setSubjectSelect] = useState(null)


  const [formData, setFormData] = useState({
    courseOutlineId: parseInt(id),
    yearLevelId: null,
    semesterId: null,
    subjectId: null,
    createdBy: auth.data.UserName
  })

  const formSelectChange = name => event => { setFormData({ ...formData, [name]: event }) }



  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // on save grade element  -----------------------------------------------------------
  const onSaveGradeElement = async (val) => {
    const formVal = {
      courseOutlineDetailId: 0,
      courseOutlineId: parseInt(id),
      yearLevelId: val.yearLevelId,
      semesterId: val.semesterId,
      subjectId: val.subjectId,
      isActive: 1,
      createdBy: auth.data.Username
    }
    await addUpdateCourseOutlineDetail(formVal)
      .then(res => {
        setIsOpenAdd(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Course Outline Detail has been saved.' });
        getCourseOutlineDetail()
        setFormData({
          courseOutlineId: parseInt(id),
          yearLevelId: null,
          semesterId: null,
          subjectId: null,
          createdBy: auth.data.UserName
        })

      })
      .catch(e => {
        console.log(e)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: e.message });

      })
  }

  // on update grade element
  const onUpdateElement = async (id, val) => {
    const values = {
      courseOutlineDetailId: val.courseOutlineDetailId,
      courseOutlineId: val.courseOutlineId,
      yearLevelId: val.yearLevelId,
      semesterId: val.semesterId,
      subjectId: val.subjectId,
      isActive: val.isActive,
      createdBy: auth.data.Username
    }
    await addUpdateCourseOutlineDetail(values)
      .then(res => {
        setIsOpenUpdate(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Course Outline Detail has been updated.' });
      })
      .catch(e => {
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: e.message });

      })
  }

  // on delete grade element
  const onDeleteElement = async (id) => {
    await deleteCourseOutlineDetail({ courseOutlineDetailId: id })
      .then(res => {
        setIsOpenDelete(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Course Outline Detail has been Deleted.' });
      })
      .catch(e => {
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: e.message });

      })
  }



  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    setIsOpenAdd(true)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {
    setRowValue(data)
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
  }


  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  const getParentCourseOutline = async (q) => {
    await getCourseOutlineQuery(q)
      .then(res => {
        setCourseOutline(res.data)
      })
  }

  const getCourseOutlineDetail = async () => {
    let condition
    if (onSearch == false) {
      condition = { where: `WHERE COD.CourseOutlineId = ${parseInt(id)}` }
    } else {
      condition = { where: `WHERE COD.CourseOutlineId = ${parseInt(id)} AND (SUB.Description LIKE  '%${keyword}%'  || SUB.SubjectCode LIKE '%${keyword}%' || YL.Description LIKE '%${keyword}%' || SEM.Description LIKE '%${keyword}%' )` }

    }
    await getCourseOutlineDetailQuery(condition)
      .then(async response => {
        if (response.success) {
          if (response.data.length > 0) {
            await getCourseOutlineReport({
              instituteId: response.data[0].InstituteId,
              curriculumCode: response.data[0].CurrCode,
              withInstituteId: true,
              withCurrCode: true
            })
              .then(res => {

                console.log(res)
                setReport(res)

              })
          }
          setResults(response.data);
          setTotalRecord(response.data.length > 0 ? response.records : 0);
        } else {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
        }
      })
      .catch(error => {

        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

      })
  }

  const onBack = () => {
    history.push("/admin/references/course-outlines")
  }

  useEffect(() => {
    SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description")
    SelectCreator(getYearLevel, setYearLevelSelect, "YearLevelId", "Description")
    SelectCreator(getSubjectQuery, setSubjectSelect, "SubjectId", "SubjectCode", { where: `ORDER BY SubjectCode` })



    getParentCourseOutline({ where: `WHERE CO.CourseOutlineId = ${parseInt(id)}` })
  }, [])

  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      courseOutlineId: parseInt(id),
      yearLevelId: props.value !== undefined && props.value !== null ? props.value.yearLevelId : null,
      semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : null,
      subjectId: props.value !== undefined && props.value !== null ? props.value.subjectId : null,
      createdBy: auth.data.UserName

    })


    const [switchState, setSwitchState] = useState({
      isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false
    });

    const switchHandleChange = name => event => {
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects

    const selectYearLevelChange = name => event => { setData({ ...data, yearLevelId: event }) }
    const selectSemesterChange = name => event => { setData({ ...data, semesterId: event }) }
    const selectSubjectChange = name => event => { setData({ ...data, subjectId: event }) }





    useEffect(() => {
      if (data && switchState) {
        console.log({
          ...data,
          isActive: switchState.isActive == true ? 1 : 0,
          isPeriodicOverride: switchState.isPeriodicOverride == true ? 1 : 0,
          isFinalsOverride: switchState.isFinalsOverride == true ? 1 : 0,
        })
        props.onSave({
          ...data,
          isActive: switchState.isActive == true ? 1 : 0,
          isPeriodicOverride: switchState.isPeriodicOverride == true ? 1 : 0,
          isFinalsOverride: switchState.isFinalsOverride == true ? 1 : 0,
        })
      }

    }, [data, switchState])



    console.log(props.value)
    return (
      <>

        <main className='container'>


          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.yearLevelSelect}
                onHandleChange={selectYearLevelChange('yearLevelId')}
                refClassContainer=""
                name="yearLevelId"
                isRequired={false}
                withLabel={false}
                label="Year Level"
                placeHolder="Select Year Level"
                forwardRef={() => { }}
                selectedId={data.yearLevelId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>
          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.semesterSelect}
                onHandleChange={selectSemesterChange('semesterId')}
                refClassContainer=""
                name="semesterId"
                isRequired={false}
                withLabel={false}
                label="Semester"
                placeHolder="Select Semester"
                forwardRef={() => { }}
                selectedId={data.semesterId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.subjectSelect}
                onHandleChange={selectSubjectChange('subjectId')}
                refClassContainer=""
                name="subjectId"
                isRequired={false}
                withLabel={false}
                label="Subject"
                placeHolder="Select Subject"
                forwardRef={() => { }}
                selectedId={data.subjectId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>
          <div className="form-row">

            <div className="form-group col">
              <FormControl component="fieldset">
                <FormControlLabel
                  name="isActive"
                  inputRef={() => { }}
                  control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                  label="Active"
                />
              </FormControl>
            </div>
          </div>
        </main>
      </>
    )
  }

  const DeleteContent = (props) => {


    console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.subject : ''} `}</span> </p>
        </main>



      </>
    )
  }

  useEffect(() => {
    getCourseOutlineDetail();
  }, [page, rowsPerPage, keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Semester', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Subject', label: 'Subject Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Units', label: 'Units', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'LecHours', label: 'Lecture Hours', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'LabHours', label: 'Laboratory Hours', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    status == 'addUpdateGradeTemplate' ? { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false } : ''
  ]

  const tableProps = {
    recordId: 'CourseOutlineDetailId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdateGradeTemplate',
    parentId: null,
    tableTitle: 'List of Course Outline Details',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: referenceTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)
  console.log(keysToCamelCase(rowValue))

  console.log(results)
  console.log(formData)
  console.log(onSearch)
  console.log(status)


  console.log(props.location.status)
  return (
    <>
      {semesterSelect && subjectSelect && yearLevelSelect && courseOutline ?
        <>
          <AddUpdateModal
            title="Add Course Outline Detail"
            isOpen={isOpenAdd}
            Content={Content}
            handleClose={onAddGradeElementClose}
            isAdd={true}
            semesterSelect={semesterSelect}
            subjectSelect={subjectSelect}
            yearLevelSelect={yearLevelSelect}
            onSubmit={onSaveGradeElement}
            id="courseOutlineDetailId"

          />

          <AddUpdateModal
            title="Update Course Outline Detail"
            isOpen={isOpenUpdate}
            Content={Content}
            value={keysToCamelCase(rowValue)}
            handleClose={onUpdateGradeElementClose}
            isUpdate={true}
            semesterSelect={semesterSelect}
            subjectSelect={subjectSelect}
            yearLevelSelect={yearLevelSelect} onSubmit={onUpdateElement}
            id="courseOutlineDetailId"

          />

          <AddUpdateModal
            title="Delete Course Outline Detail"
            isOpen={isOpenDelete}
            Content={DeleteContent}
            handleClose={onDeleteGradeElementClose}
            isDelete={true}
            onSubmit={onDeleteElement}
            semesterSelect={semesterSelect}
            subjectSelect={subjectSelect}
            yearLevelSelect={yearLevelSelect}
            value={keysToCamelCase(rowDeleteValue)}
            id="courseOutlineDetailId"

          />
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label text-primary">{courseOutline[0].Institute} <span className='lead text-dark'>({courseOutline[0].Description}) </span> </h3>
              </div>
              <div className="card-toolbar">
                <div className="example-tools">
                  {report == 'addUpdateGradeTemplate' ?
                    <button onClick={() => { onAddGradeElement() }} className="btn btn btn-icon-sm btn-primary mr-5">
                      <i className="fa fa-plus"></i> Add New
                    </button>

                    :

                    ''

                  }

                  {report.length > 0 ?
                    <PrintedForm
                      report={report}
                    />

                    :
                    <>
                      {/* <section className='text-center'>
                        <div className="spinner-border" role="status">
                          <span className="sr-only">Loading...</span>
                        </div>

                      </section> */}

                    </>

                  }


                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
              <IMTable tableProps={tableProps} />

              <section className='card p-2'>
                <div className="d-flex" style={{ width: '100%' }}>
                  <div className="form-group mb-0 col">
                    <IMSelect
                      data={yearLevelSelect}
                      onHandleChange={formSelectChange('yearLevelId')}
                      refClassContainer=""
                      name="yearLevelId"
                      isRequired={false}
                      withLabel={false}
                      label="Year Level"
                      placeHolder="Select Year Level"
                      forwardRef={() => { }}
                      selectedId={formData.yearLevelId}
                      refClassName={`text-center mr-3`}
                      withDescription={false}
                      //description={`Enrollment Status`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[]}
                      field={{
                        tableId: 'tableId',
                        display: 'code',
                      }}
                    />
                  </div>
                  <div className="form-group mb-0 col">
                    <IMSelect
                      data={semesterSelect}
                      onHandleChange={formSelectChange('semesterId')}
                      refClassContainer=""
                      name="semesterId"
                      isRequired={false}
                      withLabel={false}
                      label="Semester"
                      placeHolder="Select Semester"
                      forwardRef={() => { }}
                      selectedId={formData.semesterId}
                      refClassName={`text-center mr-3`}
                      withDescription={false}
                      //description={`Enrollment Status`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[]}
                      field={{
                        tableId: 'tableId',
                        display: 'code',
                      }}
                    />
                  </div>
                  <div className="form-group mb-0 col">
                    <IMSelect
                      data={subjectSelect}
                      onHandleChange={formSelectChange('subjectId')}
                      refClassContainer=""
                      name="subjectId"
                      isRequired={false}
                      withLabel={false}
                      label="Subject"
                      placeHolder="Select Subject"
                      forwardRef={() => { }}
                      selectedId={formData.subjectId}
                      refClassName={`text-center mr-3`}
                      withDescription={false}
                      //description={`Enrollment Status`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[]}
                      field={{
                        tableId: 'tableId',
                        display: 'code',
                      }}
                    />
                  </div>
                  {formData.yearLevelId !== null && formData.yearLevelId !== ''
                    && formData.semesterId !== null && formData.semesterId !== ''
                    && formData.subjectId !== null && formData.subjectId !== '' ?
                    <button
                      onClick={() => { onSaveGradeElement(formData) }}
                      className="btn btn-sm btn-primary col-1 p-0">
                      Add
                    </button>

                    :

                    <button
                      disabled
                      className="btn btn-sm btn-secondary col-1 p-0">
                      Add
                    </button>

                  }
                </div>
              </section>



              <button onClick={() => { onBack() }} className='btn btn-secondary mt-5 ml-5 mb-5'>Back</button>
            </div>
          </div>

        </>

        :

        <Loading />
      }

    </>
  );
}