import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { TableContainer, Table, TableRow, TableCell, TableHead, Paper, TableBody } from "@material-ui/core";
import {
    getORDetails, getFee, updateORDetails, getSchoolYear, getSemester, cancelOR, deleteFee
} from "./__hooks__"
import { TextField } from "@material-ui/core";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../../../contexts/useAppContext";
import { makeStyles } from '@material-ui/core/styles';
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import DeleteModal from "../../../../../_metronic/layout/components/custom/modal/DeleteModal";



const useStyles = makeStyles({
    root: {
        width: '100%',
        marginTop: '5%'
    },
    container: {
        maxHeight: 440,
    },
});
export default function OfficialRecieptDetails() {
    const { state: { auth }, dispatch } = useAppContext();
    const classes = useStyles();

    const [ORDetails, setORDetails] = useState(null)
    const [orNo, setORNo] = useState(null)
    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [selectedId, setSelectedId] = useState(null)
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const [feeSelect, setFeeSelect] = useState([])
    const [data, setData] = useState({ feeId: null, amount: null, schoolyear: null, semesterId: null })
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [isOpenIndividualFee, setIsOpenIndividualFee] = useState(false)
    const [paymentId, setPaymentId] = useState(null)
    const selectHandleChange = name => event => { setData((prevData) => { return { ...prevData, [name]: event } }); }
    const searchOR = async () => {
        await getORDetails({ ORNo: orNo.trim() })
            .then(res => {
                setORDetails(res.data)
            })
    }


    const updateOR = async (val) => {
        await updateORDetails({
            paymentDetailId: val.PaymentDetailId,
            paymentId: val.PaymentId,
            feeId: data.feeId == null ? val.FeeId : data.feeId,
            schoolYear: data.schoolyear == null ? val.SchoolYear : data.schoolyear,
            semesterId: data.semesterId == null ? val.Semester : data.semesterId,
            amount: data.amount == null ? val.Amount : data.amount,
            studentId: val.StudentId,
            createdBy: auth.data.Username
        }).then(async res => {
            await getORDetails({ ORNo: orNo })
                .then(resp => {
                    setData({ feeId: null, amount: null, schoolyear: null, semesterId: null })
                    setSelectedId(null)
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'OR Details Updated' })
                    setORDetails(resp.data)
                })

        })
    }


    const executeCancelOR = async () => {
        await cancelOR({ paymentId: paymentId, cancelledBy: auth.data.Username })
            .then(res => {
                searchOR().then(resp => {
                    setIsOpenDelete(false)
                })
            })
    }

    const executeDeleteFee = async () => {
        await deleteFee({ paymentDetailId: selectedToDelete })
            .then(res => {
                searchOR().then(resp => {
                    setSelectedToDelete(null)
                    setIsOpenIndividualFee(false)
                })
            })
    }


    const onCancelSearch = () => {
        setORDetails(null)
        setORNo('')
    }

    const cancelEdit = () => {
        setSelectedId(null)
        setData({ feeId: null, amount: null, schoolyear: null, semesterId: null })
    }

    useEffect(() => {
        SelectCreator(getFee, setFeeSelect, 'FeeId', 'ShortName')
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Semester')
    }, [])

    console.log(data.amount)
    console.log(ORDetails)
    console.log(auth)
    return (
        <>
            <DeleteModal
                isOpen={isOpenDelete}
                handleDelete={() => executeCancelOR()}
                handleClose={() => setIsOpenDelete(false)}
                title={`${ORDetails !== null && ORDetails.length > 0 && ORDetails[0].IsCancelled == 0 ? 'Cancel Official Receipt' : 'Activate Official Receipt'} `}
                content={`Are you sure you want to ${ORDetails !== null && ORDetails.length > 0 && ORDetails[0].IsCancelled == 0 ? 'cancel' : 'activate'} this OR?`}
            />

            <DeleteModal
                isOpen={isOpenIndividualFee}
                handleDelete={() => executeDeleteFee()}
                handleClose={() => setIsOpenIndividualFee(false)}
                title={`Delete OR Fee`}
                content={`Are you sure you want to delete this Fee?`}
            />
            <div className="container">
                <div className="card">


                    <div className="row container my-5">

                        <div className={ORDetails !== null && ORDetails.length > 0 ? "col-12 col-xl-4  border-right border-primary" : 'd-none'}>
                            <div className=" p-5">
                                {ORDetails !== null && ORDetails.length > 0 ?
                                    <>
                                        <h3 className="">{`${ORDetails[0].StudentName}`}</h3>
                                        <p className="my-0  ">{ORDetails[0].StudentNo}</p>
                                        {auth.data.UserType == 'CSM' &&
                                            <button onClick={() => {
                                                setIsOpenDelete(true);
                                                setPaymentId(ORDetails[0].PaymentId)

                                            }} className={`mt-5 btn btn-sm ${ORDetails[0].IsCancelled == 0 ? 'btn-danger' : 'btn-success'}  p-1`}>
                                                {ORDetails[0].IsCancelled == 0 ? 'Cancel OR' : 'Activate OR'}
                                            </button>

                                        }


                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Official Receipt Information</h3>

                                    </>

                                }


                                <hr />
                                {ORDetails !== null && ORDetails.length > 0 &&
                                    <>
                                        <div className="row">
                                            <p className="lead col mb-0">OR #: {ORDetails[0].ORNo}</p>

                                        </div>
                                        <div className="row">
                                            <p className="lead col">Status: {ORDetails[0].IsCancelled == 0 ? 'Active' : 'Cancelled'}</p>

                                        </div>

                                    </>

                                }


                            </div>


                        </div>
                        <div className={ORDetails !== null && ORDetails.length > 0 ? "col-12 col-xl-8" : "col-12"}>

                            <div className="p-5 mb-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">{ORDetails == null ? 'Search Official Reciept' : 'Official Reciept Details'}</h3>
                                {(ORDetails == null || ORDetails.length == 0) &&
                                    <section className="p-5">
                                        <input
                                            className="form-control"
                                            onChange={(e) => { setORNo(e.target.value) }}
                                            value={orNo}
                                        />
                                        {ORDetails !== null && ORDetails.length == 0 &&
                                            <div className="alert alert-warning" role="alert">
                                                <i className="fa fa-exclamation-triangle"></i> &nbsp;&nbsp;OR not found
                                            </div>
                                        }
                                    </section>

                                }


                                {ORDetails !== null && ORDetails.length > 0 &&
                                    <Paper className={classes.root}>
                                        <TableContainer className={classes.container}>
                                            <Table stickyHeader size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow >
                                                        <TableCell >Fee</TableCell>
                                                        <TableCell >School Year</TableCell>
                                                        <TableCell >Semester</TableCell>
                                                        <TableCell >Amount</TableCell>
                                                        {auth.data.UserType == 'CSM' &&
                                                            <TableCell align="center" style={{ width: '20%' }}>Action</TableCell>
                                                        }
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {ORDetails.length > 0 && ORDetails[0].ORDetails.map((row, index) => {
                                                        console.log(row)
                                                        return (
                                                            <>
                                                                {selectedId !== row.PaymentDetailId ?
                                                                    <>
                                                                        <TableRow hover tabIndex={-1} key={index}>
                                                                            <TableCell >{row.FeeDesc}</TableCell>
                                                                            <TableCell >{row.SchoolYear}</TableCell>
                                                                            <TableCell >{row.Semester}</TableCell>
                                                                            <TableCell >{row.Amount}</TableCell>
                                                                            {auth.data.UserType == 'CSM' &&
                                                                                <TableCell align="center" >
                                                                                    <button className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                                                                                        onClick={() => { setSelectedId(row.PaymentDetailId); setData({ feeId: null, amount: null, schoolyear: null, semesterId: null }) }}>
                                                                                        <i className="fa fa-pencil-alt text-white"></i>
                                                                                    </button>
                                                                                    <button className="btn btn-xs btn-icon btn-icon-xs btn-danger mr-1"
                                                                                        onClick={() => { setSelectedToDelete(row.PaymentDetailId); setIsOpenIndividualFee(true) }}
                                                                                    >
                                                                                        <i className="fa fa-trash text-white"></i>
                                                                                    </button>

                                                                                </TableCell>
                                                                            }

                                                                        </TableRow>
                                                                    </>
                                                                    :
                                                                    <>
                                                                        <TableRow hover tabIndex={-1} >
                                                                            <TableCell >
                                                                                <IMSelect
                                                                                    data={feeSelect}
                                                                                    onHandleChange={selectHandleChange('feeId')}
                                                                                    refClassContainer=""
                                                                                    name="Fee"
                                                                                    isRequired={true}
                                                                                    withLabel={false}
                                                                                    placeHolder="Fee"
                                                                                    forwardRef={() => { }}
                                                                                    selectedId={data.feeId == null ? row.FeeId : data.feeId}
                                                                                    refClassName={``}
                                                                                    withDescription={false}
                                                                                    // description={`Please select your gender.`}
                                                                                    refDisabled={true}
                                                                                    refIsStatic={false}
                                                                                    refStaticData={[

                                                                                    ]
                                                                                    }
                                                                                    field={{
                                                                                        tableId: 'tableId',
                                                                                        display: 'code'
                                                                                    }}

                                                                                />
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                <IMSelect
                                                                                    data={schoolYearSelect}
                                                                                    onHandleChange={selectHandleChange('schoolYear')}
                                                                                    refClassContainer=""
                                                                                    name="Fee"
                                                                                    isRequired={true}
                                                                                    withLabel={false}
                                                                                    placeHolder="Fee"
                                                                                    forwardRef={() => { }}
                                                                                    selectedId={data.schoolyear == null ? row.SchoolYear : data.schoolyear}
                                                                                    refClassName={``}
                                                                                    withDescription={false}
                                                                                    // description={`Please select your gender.`}
                                                                                    refDisabled={true}
                                                                                    refIsStatic={false}
                                                                                    refStaticData={[

                                                                                    ]
                                                                                    }
                                                                                    field={{
                                                                                        tableId: 'tableId',
                                                                                        display: 'code'
                                                                                    }}

                                                                                />
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                <IMSelect
                                                                                    data={semestersSelect}
                                                                                    onHandleChange={selectHandleChange('semesterId')}
                                                                                    refClassContainer=""
                                                                                    name="Fee"
                                                                                    isRequired={true}
                                                                                    withLabel={false}
                                                                                    placeHolder="Fee"
                                                                                    forwardRef={() => { }}
                                                                                    selectedId={data.semesterId == null ? row.Semester : data.semesterId}
                                                                                    refClassName={``}
                                                                                    withDescription={false}
                                                                                    // description={`Please select your gender.`}
                                                                                    refDisabled={true}
                                                                                    refIsStatic={false}
                                                                                    refStaticData={[

                                                                                    ]
                                                                                    }
                                                                                    field={{
                                                                                        tableId: 'tableId',
                                                                                        display: 'code'
                                                                                    }}

                                                                                />
                                                                            </TableCell>
                                                                            <TableCell >
                                                                                <input
                                                                                    className="form-control"
                                                                                    type='number'
                                                                                    name="amount"
                                                                                    defaultValue={row.Amount}
                                                                                    onChange={(e) => { setData((prevData) => { return { ...prevData, amount: parseFloat(e.target.value) } }) }}
                                                                                />
                                                                            </TableCell>


                                                                            <TableCell align="center">
                                                                                <button
                                                                                    className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1"
                                                                                    onClick={() => { updateOR(row) }}
                                                                                >
                                                                                    <i className="fa fa-save"></i>
                                                                                </button>
                                                                                <button className="btn btn-xs btn-icon btn-icon-xs btn-secondary"
                                                                                    onClick={() => { cancelEdit() }}>
                                                                                    <i className="fa fa-window-close"></i>
                                                                                </button>
                                                                            </TableCell>
                                                                        </TableRow>
                                                                    </>
                                                                }


                                                            </>
                                                        )

                                                    })}

                                                </TableBody>
                                            </Table>
                                        </TableContainer>

                                    </Paper>


                                }

                                <section className="px-5">

                                    {ORDetails !== null && ORDetails.length > 0 ? <> <button onClick={() => { onCancelSearch() }} type="button" className="mt-5 btn btn-secondary ">Cancel</button></> : <button onClick={() => { searchOR() }} className="btn btn-primary">Search</button>}
                                </section>






                            </div>


                        </div>
                    </div>
                </div>
            </div >


        </>
    )

}