import instance from "../../../../../apis/local/systemAPI";

export const getStudentAccount = async (data) => {
    const result = await instance.post("api/registrar/addDropSubject/account", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentSubjects = async (data) => {
    const result = await instance.post("api/registrar/addDropSubject/details", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async (condition) => {
    const result = await instance.post("api/references/semesters/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async (condition) => {
    const result = await instance.post("api/references/schoolyears/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createStudentSubject = async (formValues) => {
    const result = await instance.post("api/student/student-enrollment-subjects/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentSubjectSelect = async (val) => {
    const result = await instance.post(`api/registrar/student/subjectSelect`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateStudentEnrollmentSubjects = async (id, formValues) => {
    const result = await instance.patch(`api/student/student-enrollment-subjects/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



