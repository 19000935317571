import React, { useState, forwardRef, useRef } from 'react';
import { Dialog, DialogActions, DialogTitle, Slide } from '@material-ui/core';
import Loading from '../forms/Loading';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useEffect } from 'react';
import Notification from '../notification/Notification';



const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function AddUpdateModal(props) {
    const { results, handleClose, isOpen, onSubmit, data, onSaveRawGrades, onSaveIsVisible, isStudent,
        isViewingOfSummary, onLoad, classSectionId, isDean, hps, isPosted, onPostGrades, date, createdBy,
        isPercentageClear, scheduleTypeId } = props;
    const [formValues, setFormValues] = useState({})
    const classes = useRowStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [rawGrades, setRawGrades] = useState()
    const [isComputing, setIsComputing] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showNotification, setShowNotification] = useState(false);

    const [isValidScore, setIsValidScore] = useState(false)
    const onSave = () => {
        onPostGrades(rawGrades)
        setIsValidScore(false)
        handleClose()
    }
    let cnt = 0
    const inputRefs = useRef([]); // Array of refs to hold the input elements

    const handleShowNotification = () => {
        setShowNotification(true);

        // Set a timeout to hide the notification after a delay
        setTimeout(() => {
            setShowNotification(false);
        }, 3000); // Change 3000 to your desired delay (in milliseconds)
    };

    const handleKeyPress = (event, currentIndex) => {
        if (event.key === 'Enter') {
            // alert(event.key)
            event.preventDefault(); // Prevent the default Enter behavior (e.g., form submission)

            // Determine the index of the next input field
            const nextIndex = currentIndex + 1;
            const previosIndex = currentIndex - 1;

            // Check if there is a next input field with that index

            // Focus on the next input field
            console.log(currentIndex)
            console.log(inputRefs)
            if (inputRefs.current[nextIndex] != undefined) {
                inputRefs.current[nextIndex].focus();
            } else {
                inputRefs.current[previosIndex].focus();

            }


        }
    };

    console.log(props.value)
    console.log(formValues)
    console.log({ ...props.value, ...formValues })


    console.log(rawGrades)
    console.log(results)
    console.log(isPosted)
    // useEffect(() => {
    //     if (rawGrades !== undefined && rawGrades !== null) {
    //         console.log(rawGrades)
    //         console.log(results)
    //         let totalRawGradesData = rawGrades.length
    //         let checked = 0
    //         for (let i = 0; i < rawGrades.length; i++) {
    //             console.log(parseInt(rawGrades[i].rawScore))
    //             console.log(parseInt(results[0].HighScore))
    //             if (parseInt(rawGrades[i].rawScore) <= parseInt(results[0].HighScore)) {
    //                 checked++
    //             }
    //         }
    //         console.log(checked + '- -' + totalRawGradesData)
    //         if (checked == totalRawGradesData) {
    //             setIsValidScore(true)
    //         } else {
    //             setIsValidScore(false)
    //         }
    //         onSaveRawGrades(rawGrades)
    //             .then(res => {
    //                 console.log(res)
    //                 onLoad(results[0].GivenInstructionalGuideDetailId, results[0].GivenInstructionalGuideId, results[0].GradingPeriodId)

    //             })


    //     }
    // }, [rawGrades])


    // useEffect(() => {
    //     if (!isComputing && currentIndex !== null) {
    //         // Ensure currentIndex is within the range of inputRefs array
    //         if (currentIndex >= 0 && currentIndex < inputRefs.current.length) {
    //             const inputRef = inputRefs.current[currentIndex];
    //             inputRef.focus();
    //         }
    //     }
    // }, [isComputing, currentIndex]);



    console.log(onLoad)
    console.log(results)
    console.log(data)
    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
                fullWidth
            >
                <section className='row'>
                    <div className='col-9'>

                        <DialogTitle className='pt-0 text-primary mt-5' id="alert-dialog-slide-title ">{results.length > 0 && results[0].ComponentDesc}</DialogTitle>
                    </div>
                    <div className='col-3'>
                        <section className='mt-5 card p-5 bg-secondary mr-3'>
                            <table style={{ borderCollapse: 'collapse' }}>

                                <tbody>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Grading Period: </td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].GradingPeriod}</td>
                                    </tr>
                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>High Score</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].HighScore}</td>
                                    </tr>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Computational Base</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].ComputeBase}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </section>
                    </div>
                </section>


                {showNotification && <Notification className='m-5 col-4' message={`The highest score is ${hps}`} />}

                <hr />

                {results.length > 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center">#</TableCell>
                                        <TableCell align="center">Student No</TableCell>
                                        <TableCell align="left">Student Name</TableCell>
                                        {isViewingOfSummary !== true &&
                                            <>
                                                <TableCell align="center">Score</TableCell>
                                                <TableCell align="center">Equivalent Grade</TableCell>
                                            </>}

                                        <TableCell align="center">{results[0].GradingPeriod} Grade</TableCell>
                                        {/* <TableCell align="center">Visible to Student</TableCell> */}

                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((row, index) => {
                                        cnt++
                                        const handleIsVisibleChange = (isChecked, gBookRawGradeId) => {

                                            let values = {
                                                isVisible: isChecked ? 1 : 0,
                                                gBookRawGrade: gBookRawGradeId
                                            }
                                            console.log("values: ", values);

                                            onSaveIsVisible(values);
                                        }


                                        const handleInputChange = (event, value) => {
                                            console.log(event, + ' ' + value)
                                            const inputValue = event.target.value;
                                            if (parseFloat(inputValue) != parseFloat(value)) {

                                                if (parseFloat(inputValue) > parseFloat(hps)) {
                                                    handleShowNotification();
                                                    // Optionally, you can reset the input value to an empty string
                                                    // setInputValue('');
                                                } else {
                                                    setIsLoading(true)
                                                    setIsComputing(true);
                                                    const updatedRawGrades = rawGrades ? [...rawGrades] : [];
                                                    const existingGradeIndex = updatedRawGrades.findIndex((grade) => grade.studentId === row.StudentId);

                                                    if (existingGradeIndex >= 0) {

                                                        updatedRawGrades[existingGradeIndex].rawScore = inputValue;
                                                    } else {

                                                        updatedRawGrades.push({
                                                            gBookRawGradeId: row.GBookRawGradeId !== null ? row.GBookRawGradeId : 0,
                                                            rawScore: parseFloat(inputValue),
                                                            instructionalGuideId: row.GivenInstructionalGuideId,
                                                            instructionalGuideDetailId: row.GivenInstructionalGuideDetailId,
                                                            subjectScheduleDetailId: row.SubjectScheduleDetailId,
                                                            subjectId: row.SubjectId,
                                                            userId: data.UserId,
                                                            schoolYear: data.SchoolYear,
                                                            semesterId: data.SemesterId,
                                                            studentId: row.StudentId,
                                                            classSectionId: classSectionId,
                                                            scheduleTypeId: scheduleTypeId
                                                        });
                                                    }

                                                    onSaveRawGrades(updatedRawGrades)
                                                        .then((res) => {
                                                            // setCurrentIndex(index);
                                                            console.log(res);

                                                            onLoad(results[0].GivenInstructionalGuideDetailId, results[0].GivenInstructionalGuideId, results[0].GradingPeriodId)
                                                                .then(res => {
                                                                    console.log('test')
                                                                    setIsComputing(false);
                                                                    setIsLoading(false)

                                                                })


                                                        });
                                                }
                                            } else {
                                                setIsLoading(false)
                                            }


                                        };
                                        return (
                                            <>
                                                <TableRow className={classes.root}>
                                                    <TableCell align="center">{cnt}</TableCell>
                                                    <TableCell align="center">{row.StudentNo}</TableCell>
                                                    <TableCell align="left">{row.StudentName}</TableCell>


                                                    {isViewingOfSummary !== true &&
                                                        <>
                                                            {isStudent != true ?
                                                                <>
                                                                    {isPercentageClear == true ?
                                                                        <>
                                                                            {row.IsPosted == 0 || row.IsPosted == null ?
                                                                                <input
                                                                                    ref={(input) => (inputRefs.current[index] = input)}

                                                                                    // disabled={isComputing}
                                                                                    className='form-control '
                                                                                    defaultValue={row.Score}
                                                                                    onBlur={(e) => { handleInputChange(e, row.Score) }}
                                                                                    onKeyPress={(e) => handleKeyPress(e, index)}
                                                                                    type='number'
                                                                                />

                                                                                :
                                                                                <input
                                                                                    ref={(input) => (inputRefs.current[index] = input)}

                                                                                    disabled
                                                                                    className='form-control '
                                                                                    defaultValue={row.Score}
                                                                                    // onChange={handleInputChange}

                                                                                    type='number'
                                                                                />

                                                                            }
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <input
                                                                                ref={(input) => (inputRefs.current[index] = input)}

                                                                                disabled
                                                                                className='form-control '
                                                                                placeholder='Make sure IG Percentage is 100%'
                                                                                type='number'
                                                                            />
                                                                        </>
                                                                    }


                                                                </>

                                                                :

                                                                <TableCell align="center">
                                                                    {row.RawScore}
                                                                </TableCell>



                                                            }
                                                            <TableCell align="center">{parseFloat(row.EquivalentGrade).toFixed(2)}</TableCell>

                                                        </>
                                                    }

                                                    <TableCell align="center">{parseFloat(row.Grade).toFixed(2)}</TableCell>
                                                    {/* <TableCell align="center">{
                                                        <input
                                                            className=' '
                                                            type='checkbox'
                                                            defaultChecked={row.IsVisible == 1 ? "checked" : ""}
                                                            onChange={(e) => { handleIsVisibleChange(e.target.checked, row.GBookRawGradeId) }}
                                                        />
                                                    }</TableCell> */}



                                                </TableRow>
                                            </>
                                        )

                                    })}

                                </TableBody>



                            </Table>


                        </TableContainer>
                    </>
                    :
                    <>
                        <Loading />
                    </>
                }
                {/* table where details will go */}


                <DialogActions>


                    {isLoading == false ?
                        <button onClick={handleClose} className="btn btn-secondary">
                            Back
                        </button>
                        :
                        <button className="btn btn-secondary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp; Saving Please wait...
                        </button>

                    }


                </DialogActions>
            </Dialog>
        </>




    );
}