import React from "react";
import checkLogo from "./assets/check.png"
import history from "../../../history";
import FaithLogo from './assets/lb-logo.png'
import { applicantTypes } from "./__hooks__/types";
export default function FormSubmitted(props) {

    const onReturnHandler = () => {
        history.push(`/student-registration`);

    }




    return (
        <div className="container  my-auto " >
            <div className="card  mx-auto my-auto text-center " style={{ width: '500px' }}>

                <div className="card-body">
                    <img className="mb-5" style={{ width: '80px' }} src={FaithLogo} />

                    <h5 className="card-title mb-0">Your Application has been recieved.</h5>
                    <p className="card-text mb-4">Thank you.</p>
                    <a href="#" className="btn btn-primary" onClick={onReturnHandler}>Return in Application</a>

                </div>

            </div>
        </div>


    )
}