import instance from "../../../../../apis/local/systemAPI";



export const getAssessmentStudentSubjectScheduleDetail = async (val) => {
    const result = await instance.post(`api/schedule/details/studentSubjectScheduleDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getAssessmentStudentIregSubjectScheduleDetail = async (val) => {
    const result = await instance.post(`api/schedule/details/studentIregSubjectScheduleDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getStudentEnrollmentSubjects = async (val) => {
    const result = await instance.post(`api/registrar/student/studentEnrollmentSubject`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAssessmentTemplateDetail = async (val) => {
    const result = await instance.post(`/api/assessment/template-details/studentAssessmentTemplateDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentAssessmentOtherFees = async (val) => {
    const result = await instance.post(`api/assessment/student-other-fees/studentOtherFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentAssessment = async (val) => {
    const result = await instance.post(`api/assessment/templates/studentAssessment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentLabFee = async (val) => {
    const result = await instance.post(`api/assessment/student-lab-fees/studentLabFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentEnrollmentAccount = async (val) => {
    const result = await instance.post(`api/enrollment/account/studentEnrollmentAccount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentCampusEnrollmentAccount = async (val) => {
    const result = await instance.post(`api/enrollment/account/studentCampusEnrollmentAccount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAssessmentStudentTotalUnits = async (val) => {
    const result = await instance.post(`api/schedule/details/studentTotalUnits`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFeeConvertedFromCampus = async (val) => {
    const result = await instance.post(`api/references/fees/campusToAstraConverter`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const checkCurriculum = async (val) => {
    const result = await instance.post(`api/course-outlines/checkCurriculum`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentRegistrarAccount = async (val) => {
    const result = await instance.post(`api/registrar/student/account`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentRegistrarNewAccount = async (val) => {
    const result = await instance.post(`api/registrar/student/newAccount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentYearLevel = async (val) => {
    const result = await instance.post(`api/registrar/student/studentYearLevel`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentClassSection = async (val) => {
    const result = await instance.post(`api/registrar/student/classSection`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentSubjectSelect = async (val) => {
    const result = await instance.post(`api/registrar/student/subjectSelect`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentAstraSubjects = async (val) => {
    const result = await instance.post(`api/schedule/details/studentSubjects`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAccountingStudentListOfAssessment = async (val) => {
    const result = await instance.post(`api/accounting/student/assessmentList`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDiscount = async (val) => {
    const result = await instance.post(`api/cashiering/student/discount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentLatestEnrollmentAccount = async (val) => {
    const result = await instance.post(`api/registrar/student/studentLatestEnrollment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createUpdateEnrollment = async (val) => {
    const result = await instance.post(`api/enrollments/createUpdate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAssessmentTemplateId = async (val) => {
    const result = await instance.post(`/api/assessment/template-details/studentAssessmentTemplateIds`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const addUpdateStudentEnrollmentSubject = async (val) => {
    const result = await instance.post(`/api/student/student-enrollment-subjects/addUpdate`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentDiscountParticulars = async (val) => {
    const result = await instance.post(`api/cashiering/student/discountParticulars`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentSchedule = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierPaymentSchedule`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentPayment = async (val) => {
    const result = await instance.post(`api/students/studentPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const assignStudentCurriculum = async (val) => {
    const result = await instance.post(`api/student/course-outlines/assignStudentCurriculum`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getPaymentModeSelect = async (val) => {
    const result = await instance.post(`api/accounting/student/paymentModeSelect`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const sendMail = async (values) => {
    const result = await instance.post("api/nodemailer/send-mail", values);
    if (result.status === 200 || result.status === 201) {
        // alert('email send')
    }
}