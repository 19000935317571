import _ from 'lodash';
import { referenceTypes } from './types';

const reducer = (state, action) => {
    // console.log('state',state,'action',action);
    switch (action.type) {
        case referenceTypes.SELECTED_SCHOOL_YEAR:
            return { ...state, data: action.data };
        case referenceTypes.CREATE:
            return { ...state, data: { ...state.data, [action.data.SchoolYear]: action.data } };
        case referenceTypes.GET_SCHOOL_YEARS:
            return {
                ...state
                , data: { ..._.mapKeys(action.data, 'SchoolYear') }
                , keyword: action.keyword === undefined ? state.keyword : action.keyword
                , page: action.page === undefined ? state.page : action.page
                , rowsPerPage: action.rowsPerPage === undefined ? state.rowsPerPage : action.rowsPerPage
            };
        case referenceTypes.UPDATE:
            return { ...state, data: { ...state.data, [action.data.SchoolYear]: action.data }, id: action.id }
        case referenceTypes.DELETE:
            return { ...state, data: { ..._.omit(state.data, action.data) } };
        case referenceTypes.CLEAR_LAST_UPDATE_ID:
            return { ...state, ...state.id, id: null }
        default:
            return state;
    }
}

export default reducer;