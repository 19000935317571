import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getSchoolYear, getSemester, employeeAutoComplete, getRolesAndUserType,
  getDepartments, getNameSuffixes, addNewEmployee, getEmployeeDetails, 
  updateEmployeeDetails, getInstituteAndCourses, getLookUpIdDean, getLookUpIdChair,
  updatePassword, employeeAndStudentAutoComplete, revertDefaultPassword
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelectAddEmployee from '../../../../../_metronic/layout/components/custom/select/IMSelectAddEmployee';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import './__hooks__/styles.css'
// import PrintedForm from './PrintedForm';
import SummaryOfGradesConfirmation from "../../../../../_metronic/layout/components/custom/modal/SummaryOfGradesConfirmation";

var courseId = null;

export default function UserMaintenance() {
  const { register, handleSubmit, errors } = useForm();
  const { state: { fees, auth }, dispatch } = useAppContext();
  const activeSy = auth.data.schoolyears.assessment.SchoolYear
  const activeSem = auth.data.schoolyears.assessment.SemesterId
  const [isOpenAdd, setIsOpenAdd] = useState(false);
  const [isSecondAuth, setIsSecondAuth] = useState(false);
  const [schoolYearSelect, setSchoolYearSelect] = useState(null);
  const [semesterSelect, setSemesterSelect] = useState(null);
  const [isAddEmployee, setIsAddEmployee] = useState(false);
  const [employee, setEmployee] = useState(null);
  const [employeeNo, setEmployeeNo] = useState(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [employeeId, setEmployeeId] = useState(null);
  const [roleSelect, setRoleSelect] = useState([{ tableId: '', code: '' }]);
  const [departmentSelect, setDepartmentSelect] = useState([{ tableId: '', code: '' }]);
  const [nameSuffixSelect, setNameSuffixSelect] = useState([{ tableId: '', code: '' }]);
  const [instituteSelect, setInstituteSelect] = useState([{ tableId: '', code: '' }]);
  const [coursesSelect, setCoursesSelect] = useState([]);
  const [rolesAndUserTypes, setRolesAndUserTypes] = useState(null);
  const [switchHistory, setSwitchHistory] = useState({switchStateIsActive: false, dataIsActive: 0}); //Set IsActive history to avoid multiple/infinite trigger
  const [switchState, setSwitchState] = useState({isActive: false}); //Set default value of switch state
  const [courseArray, setCourseArray] = useState([]);
  const [lookupId, setLookupId] = useState(null);
  const [isChair, setIsChair] = useState(false);
  const [isDean, setIsDean] = useState(false);
  const [chairRoleIds, setChairRoleIds] = useState([]);
  const [deanRoleIds, setDeanRoleIds] = useState([]);
  const [onAlert, setOnAlert] = useState(false);
  const [onSuccess, setOnSuccess] = useState(false);
  const [userId, setUserId] = useState(null);

  const isAdmin = auth.data.RoleId == 26 || auth.data.RoleId == 1 ? true : false;
  const [isEmployee, setIsEmployee] = useState(1);
  const [userIdChangePassword, setUserIdChangePassword] = useState(0);
  
  const [isForConfirmation, setIsForConfirmation] = useState(false);
  const [employeeStudentNo, setEmployeeStudentNo] = useState(false);

  const execRolesAndUserTypes = async () => {
    await getRolesAndUserType({
    })
        .then(res => {
          setRolesAndUserTypes(res);
          if (res.success) {
              let arr = [];
              for (let i = 0; i < res.data.length; i++) {
                  if (res.data[i].IsActive == 1)
                      arr.push({ tableId: res.data[i]["RoleId"], code: res.data[i]["Role"] })
              }
              setRoleSelect(arr)
          }
        })
        .catch(e => {
            return []
        })
  }

  useEffect(() => {
    let value = {
      isInstitute:1,
      instituteId:null
    }

    SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
    SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
    SelectCreator(getDepartments, setDepartmentSelect, 'DepartmentId', 'DepartmentCode')
    SelectCreator(getNameSuffixes, setNameSuffixSelect, 'NameSuffixId', 'NameSuffix')
    SelectCreator(getInstituteAndCourses, setInstituteSelect, 'InstituteId', 'Description',value)
    SelectCreator(getLookUpIdChair, setChairRoleIds, 'RoleId', 'Description')
    SelectCreator(getLookUpIdDean, setDeanRoleIds, 'RoleId', 'Description')
    execRolesAndUserTypes();

  }, []);

  // Set Student ID based on the selected value from the Auto-Complete field
  const handleGetEmployee = async (data) => {
      setEmployeeId(data.EmployeeId);
      setEmployee(data);
      setUserIdChangePassword(data.UserId);
      setEmployeeStudentNo(data.EmployeeNo); //either EmployeeNo or StudentNo
      setIsEmployee(data.IsEmployee == 1 ? 1 : 0);
      //console.log("Employee: ", data);
  }

  const handleEmployeeInputChange = async (input) => {

    if(isAdmin){
      await employeeAndStudentAutoComplete({ input: input })
      .then(res => {
          setEmployeeList(res.data);
          //console.log("List: ",res.data);
      })
      .catch(e => {

      })
    } else {
      await employeeAutoComplete({ input: input })
      .then(res => {
          setEmployeeList(res.data);
          //console.log("List: ",res.data);
      })
      .catch(e => {

      })
    }
  }

  /** Set default values for form fields */
  const [data, setData] = useState({ 
    employeeNo: "",
    departmentId: null, 
    roleId: null, 
    nameSuffixId: "", 
    userType: "", 
    emailAddress: "",
    lastName: "",
    firstName: "",
    middleName: "",
    birthDate: "",
    cellphone: "",
    telephone: "",
    isActive: 0,
    instituteId: null,
    courseId: null
  });

  /** Set selected role, department, and name suffix */
  const selectRoleChange = name => event => { setData({ ...data, roleId: event }, {}) }
  const selectDepartmentChange = name => event => { setData({ ...data, departmentId: event }) }
  const selectNameSuffixChange = name => event => { setData({ ...data, nameSuffixId: event }) }
  const selectInstituteChange = name => event => { setData({ ...data, instituteId: event }) }
  const selectCourseChange = name => event => { setData({ ...data, courseId: event }) }

  /** Set Switch State when IsActive switch is clicked */
  const switchHandleChange = name => event => {
  //console.log(event.target.checked);
  setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  /** Get Employee Details for Edit */
  const onSearchEmployee = async () => {
    if(employeeNo == null){
      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Enter employee number or name to search.' });
    } else {
      let values = {
        employeeNo: employeeNo
      }
  
      await getEmployeeDetails(values)
        .then(res => {
          if(res.success){
            if(res.data.length == 1){
              let date = new Date(res.data[0].BirthDate);
              let month = date.getMonth() + 1;
              let dt = date.getDate();
  
              if (month < 10) {
                month = '0' + month;
              }
  
              if (dt < 10) {
                dt = '0' + dt;
              }
  
              let newDate = date.getFullYear() + '-' + month + '-' + dt;
              let courseList = JSON.parse(res.data[0].CourseId);
              courseId = null;
              if(res.data[0].CourseId === null){
                // setData({ ...data, 
                //   courseId: null
                // });
              } else {
                if(courseList.length == 1){
                  setData({...data, courseId: courseList[0].tableId});
                } else {
                    courseList.sort(function(a,b) {
                    return a.tableId - b.tableId;
                });
        
                for(let i = 0; i < courseList.length; i++){
                  if(i == 0){
                    courseId = courseList[0].tableId;
                  } else {
                    courseId += "," + courseList[i].tableId;
                  }
                }
                // setData({...data, courseId: courseId});
                }
              }
  
              setData({ ...data, 
                roleId: res.data[0].RoleId, 
                departmentId: res.data[0].DepartmentId, 
                nameSuffixId: res.data[0].NameSuffixId, 
                userType: res.data[0].UserType, 
                emailAddress: res.data[0].EmailAddress,
                employeeNo: res.data[0].EmployeeNo,
                lastName: res.data[0].LastName,
                firstName: res.data[0].FirstName,
                middleName: res.data[0].MiddleName,
                birthDate: newDate,
                cellphone: res.data[0].Cellphone,
                telephone: res.data[0].Telephone,
                isActive: res.data[0].IsActive,
                instituteId: res.data[0].InstituteId,
                courseId: courseId
              });

              
              

              setLookupId(res.data[0].LookupId);
              setCourseArray(res.data[0].CourseId === null ? [] : JSON.parse(res.data[0].CourseId));
              setIsOpenAdd(true);

              if(res.data[0].IsSecondAuth == 1){
                setIsSecondAuth(true);
                setUserId(res.data[0].UserId);
              }
            }
          }
        })
    }
  }

  const onChangeToDefaultPassword = async () => {
    if(employeeNo === null){
      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Enter employee/student name or number to search.' });
    } else {
      setIsForConfirmation(true);
    }
  }

  const onPasswordChange = formValues => {

    const execChangePassword = async () => {
      if((formValues.password != formValues.confirm) || (formValues.password == '' || formValues.confirm == '')){
        setOnAlert(true);
        setOnSuccess(false);
        
      } else {
        const userValues = {
          userId: userId,
          newPassword: formValues.password
        }

        await updatePassword(userValues)
          .then(response => {
              if(response.success){
                setOnSuccess(true);
                setOnAlert(false);
  
                document.getElementById('password').value = '';
                document.getElementById('confirm').value = '';
              }
          })
          .catch(error => {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: 'An error has occurred. Please contact your administrator.' });
              // alert("something went wrong")
              console.log(error)
          })
      }
    }
    execChangePassword();
  }

  /** Save New Employee */
  const onSubmit = formValues => {
    const { v4: randomKey } = require('uuid');

    const onAddNewEmployee = async () => {
      if((isDean || isChair) && (data.instituteId == "" || data.instituteId === null)){
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Please fill required fields.' });
      } else {
        let values = {
          userName: formValues.email,
          employeeNo: formValues.employeeNumber.toUpperCase(),
          departmentId: data.departmentId,
          lastName: formValues.lastName.toUpperCase(),
          firstName: formValues.firstName.toUpperCase(),
          middleName: formValues.middleName.toUpperCase(),
          nameSuffix: data.nameSuffixId == "" ? null : data.nameSuffixId,
          birthDate: formValues.birthDate,
          emailAddress: formValues.email,
          cellphone: formValues.cellphone,
          telephone: formValues.telephone,
          roleId: data.roleId,
          userType: data.userType,
          isActive: data.isActive,
          createdBy: auth.data.Username,
          instituteId: data.instituteId,
          courseId: data.courseId,
          accessTokenKey: randomKey()
        };
      
        if(values.roleId === null || values.userType === null || values.departmentId === null 
          || values.employeeNumber == "" || values.emailAddress == ""
          || values.lastName == "" || values.firstName == "" || values.birthDate == ""){
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Please fill required fields.' });
        } else {
          await addNewEmployee(values)
          .then(res => {
            //console.log("Res: ", res);
            if(res.success){
              if(res.data[0].Result == 0){
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Email Address/Username already exists in the system!' });
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Employee has been added to ASTRA College!' });
                setIsOpenAdd(false);
              }
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'Adding new employee unsuccessful. Please contact your administrator.' });
            }
          })
        }
      }
    }

    const onEditEmployeeDetail = async () => {
      //console.log("EMP ID: ", employee.EmployeeId);

      if((isDean || isChair) && (data.instituteId == "" || data.instituteId === null)){
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Please fill required fields.' });
      } else {
        let values = {
          userName: formValues.email,
          employeeNo: formValues.employeeNumber.toUpperCase(),
          departmentId: data.departmentId,
          lastName: formValues.lastName.toUpperCase(),
          firstName: formValues.firstName.toUpperCase(),
          middleName: formValues.middleName.toUpperCase(),
          nameSuffix: data.nameSuffixId == "" ? null : data.nameSuffixId,
          birthDate: formValues.birthDate,
          emailAddress: formValues.email,
          cellphone: formValues.cellphone,
          telephone: formValues.telephone,
          roleId: data.roleId,
          userType: data.userType,
          isActive: data.isActive,
          updatedBy: auth.data.Username,
          employeeId: employeeId,
          instituteId: data.instituteId,
          courseId: data.courseId
        };
  
        if(values.employeeId !== "undefined" || values.employeeId !== null){
          if(values.roleId === null || values.userType === null || values.departmentId === null 
            || values.employeeNumber == "" || values.emailAddress == ""
            || values.lastName == "" || values.firstName == "" || values.birthDate == ""){
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Please fill required fields.' });
          } else {
            await updateEmployeeDetails(values)
            .then(res => {
              if(res.success){
                if(res.data[0].Result == 0){
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Email Address/Username already exists in the system!' });
                } else {
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Employee\'s details has been updated!' });
                  setIsOpenAdd(false);
                }
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'Updating employee details unsuccessful. Please contact your administrator.' });
              }
            })
          }
        } else {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'An error has occurred. Please contact your administrator.' });
        }
      }      
    }

    isAddEmployee ? onAddNewEmployee() : onEditEmployeeDetail();
  }

  // set IsOpenAdd - show form field when true; show search user when false
  const onAddNewEmployee = () => {
    setIsOpenAdd(true);
    setIsAddEmployee(true);
  }

  const onCancel = () => {
    setIsOpenAdd(false); 
    setIsSecondAuth(false);
    setOnAlert(false); 
    setOnSuccess(false);
  }

  
  const afterConfirmation = async () => {
    //alert("IsEmployee: " + isEmployee + ": UserId: " + userIdChangePassword);=
    let values = {
      userId:userIdChangePassword,
      isEmployee:isEmployee,
      employeeStudentNo:employeeStudentNo,
      updatedBy:auth.data.Username
    };

    console.log("values: ", values);

    await revertDefaultPassword(values)
      .then(res => {
        console.log("res: ", res);
        if(res.success){
          if(res.data[0].Result == 0){
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Email Address/Username already exists in the system!' });
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Password has been updated!' });
          }
        } else {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'Updating password unsuccessful. Please contact your administrator.' });
        }
      })

    setIsForConfirmation(false);
  };

  const handleGetCourses = async (courseList) => {
    setCourseArray(courseList);

    if(courseList.length > 0){
      courseId = null;
      if(courseList.length == 1){
        setData({...data, courseId: courseList[0].tableId});
      } else {
        courseList.sort(function(a,b) {
          return a.tableId - b.tableId;
        });

        for(let i = 0; i < courseList.length; i++){
          if(i == 0){
            courseId = courseList[0].tableId;
          } else {
            courseId += "," + courseList[i].tableId;
          }
        }
        setData({...data, courseId: courseId});
      }
    }
  }

  useEffect(() => {
    setData((prevData) => {
      return {...prevData, 
          courseId: null
      }
    });

    setCoursesSelect([]);
    setCourseArray([]);
    
    let value = {
      isInstitute:0,
      instituteId:data.instituteId
    }

    SelectCreator(getInstituteAndCourses, setCoursesSelect, 'CourseId', 'Description',value);

  }, [data.instituteId]);

  // Set IsActive (1/0) that will be saved on database based on IsActive switch
  useEffect(() => {
    if(switchState.isActive != switchHistory.switchStateIsActive){
      setData({ ...data, isActive: switchState.isActive ? 1 : 0 });
      setSwitchHistory({ ...switchHistory, dataIsActive: switchState.isActive ? 1 : 0, switchStateIsActive: switchState.isActive });
    }
  }, [switchState.isActive]);

  // Set IsActive switch - mainly used to populate form when Edit
  useEffect(() => {
    if(data.isActive != switchHistory.dataIsActive) {
      setSwitchState({ ...switchState, isActive: data.isActive == 1 ? true : false });
      setSwitchHistory({ ...switchHistory, dataIsActive: data.isActive,switchStateIsActive: data.isActive == 1 ? true : false });
    }    
  }, [data.isActive]);

  // Reset data (field/forms)
  useEffect(() => {
    if (!isOpenAdd) {
      setSwitchHistory({ ...switchHistory, 
        dataIsActive: 0, 
        switchStateIsActive: false 
      });
      setSwitchState({ ...switchState, isActive: false });
      setData({ ...data, 
        employeeNo: "",
        departmentId: null, 
        roleId: null, 
        nameSuffixId: "", 
        userType: "", 
        emailAddress: "",
        lastName: "",
        firstName: "",
        middleName: "",
        birthDate: "",
        cellphone: "",
        telephone: "",
        isActive: 0,
        instituteId: null
      });
      setIsAddEmployee(false);
      setEmployee(null);
      setEmployeeId(null);
      setEmployeeNo(null);
      setEmployeeList([]);
      setCourseArray([]);
      setCoursesSelect([]);
    }
  }, [isOpenAdd]);

  //Set User Type based on Role selected
  useEffect(() => {
    if(data.roleId == 0 || data.roleId == '' || data.roleId === null){
      setData((prevData) => {
        return {...prevData, 
            courseId: null,
            instituteId: null
        }
      });
    }

    if((data.roleId !== null || data.roleId == "") && rolesAndUserTypes !== null){
      for(let i = 0; i < rolesAndUserTypes.data.length; i++){
        if(rolesAndUserTypes.data[i].RoleId == data.roleId){
          setData({ ...data, userType: rolesAndUserTypes.data[i].UserType })
          break;
        }
      }

      if(chairRoleIds !== null){
        //console.log(chairRoleIds.length);
        for(let i = 0; i < chairRoleIds.length; i++){
          if(data.roleId == chairRoleIds[i].tableId){
            setIsChair(true);
            break;
          } else {
            setIsChair(false);
          }
        }
      }

      if(deanRoleIds !== null){
        //console.log(deanRoleIds.length);
        for(let i = 0; i < deanRoleIds.length; i++){
          if(data.roleId == deanRoleIds[i].tableId){
            setIsDean(true);
            break;
          } else {
            setIsDean(false);
          }
        }
      }

    } else {
      setData({ ...data, userType: "" })
    }

  }, [data.roleId]);

  useEffect(() => {
    if(employee !== null){
      setEmployeeNo(employee.EmployeeNo);
    }
  }, [employee]);


  return (
    <>
      <SummaryOfGradesConfirmation
          isOpen={isForConfirmation}
          handleConfirm={() => afterConfirmation()}
          handleClose={() => setIsForConfirmation(false)}
          title={'Revert Employee\'s/Student\'s Password'}
          content={'Continue reverting the employee\'s/student\'s password to default?'}
      />

      {roleSelect && departmentSelect && nameSuffixSelect && rolesAndUserTypes &&
        <>
          {isOpenAdd ? 
            !isSecondAuth ?
            <>
              <div >
                <main className="card mx-auto my-auto shadow-lg" style={{ width: "50%" }}>
                  <header className='card-header p-5 bg-secondary'>
                    {isAddEmployee ? 
                    <h6 className="float-left m-0 mt-4 ml-5">Add New Employee</h6>
                    :
                    <h6 className="float-left m-0 mt-4 ml-5">Edit Employee Details</h6>
                    }
                  </header>

                  <section className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} className="form fv-plugins-bootstrap fv-plugins-framework mt-5">
                  
                      {/* Employee Number */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="employeeNumber">Employee Number<span className="text-danger">&nbsp;*</span></label>
                          <input
                            ref={register({
                              required: false
                            })}
                            type="text"
                            id="employeeNumber"
                            name="employeeNumber"
                            className={`form-control ${errors.employeeNumber === undefined ? "" : " is-invalid"}`}
                            placeholder="Employee Number"
                            defaultValue={data.employeeNo}
                            maxLength="11"
                            style={{textTransform:"uppercase"}}
                          />
                        </div>
                      </div>
                          
                      {/* Department */}
                      <div className="form-row">
                        <div className="form-group col">
                          <IMSelectAddEmployee
                            data={departmentSelect}
                            onHandleChange={selectDepartmentChange('departmentId')}
                            refClassContainer=""
                            name="departmentId"
                            isRequired={true}
                            withLabel={true}
                            label="Department"
                            placeHolder="Select Department"
                            forwardRef={() => { }}
                            selectedId={data.departmentId}
                            refClassName={`text-center`}
                            withDescription={false}
                            refDisabled={true}
                            refIsStatic={false}
                            defaultValue={data.departmentId}
                            refStaticData={[
                            ]
                            }
                            field={{
                              tableId: 'tableId',
                              display: 'code'
                            }}
                          />
                        </div>
                      </div>
                          
                      {/* Last Name */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="lastName">Last Name<span className="text-danger">&nbsp;*</span></label>
                          <input
                            ref={register({
                              required: false
                            })}
                            type="text"
                            name="lastName"
                            className={`form-control ${errors.lastName === undefined ? "" : " is-invalid"}`}
                            id="lastName"
                            placeholder="Last Name"
                            defaultValue={data.lastName}
                            maxLength="40"
                            style={{textTransform:"uppercase"}}
                          />
                        </div>
                      </div>
                          
                      {/* First Name */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="firstName">First Name<span className="text-danger">&nbsp;*</span></label>
                          <input
                            ref={register({
                              required: false
                            })}
                            type="text"
                            name="firstName"
                            className={`form-control ${errors.firstName === undefined ? "" : " is-invalid"}`}
                            id="firstName"
                            placeholder="First Name"
                            defaultValue={data.firstName}
                            maxLength="40"
                            style={{textTransform:"uppercase"}}
                          />
                        </div>
                      </div>
                          
                      {/* Middle Name*/}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="middleName">Middle Name</label>
                          <input
                            ref={register({
                              required: false
                            })}
                            type="text"
                            className="form-control"
                            id="middleName"
                            name="middleName"
                            placeholder="Middle Name"
                            defaultValue={data.middleName}
                            maxLength="40"
                            style={{textTransform:"uppercase"}}
                          />
                        </div>
                      </div>

                      {/* Name Suffix */}
                      <div className="form-row">
                        <div className="form-group col">
                          <IMSelectAddEmployee
                            data={nameSuffixSelect}
                            onHandleChange={selectNameSuffixChange('nameSuffixId')}
                            refClassContainer=""
                            name="nameSuffixId"
                            isRequired={false}
                            withLabel={true}
                            label="Name Suffix"
                            placeHolder="Select Name Suffix"
                            forwardRef={() => { }}
                            selectedId={data.nameSuffixId}
                            refClassName={`text-center`}
                            withDescription={false}
                            refDisabled={true}
                            refIsStatic={false}
                            defaultValue={data.nameSuffixId}
                            refStaticData={[
                            ]
                            }
                            field={{
                              tableId: 'tableId',
                              display: 'code'
                            }}
                          />
                        </div>
                      </div>
                          
                      {/* Birth Date */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="birthDate">Birth Date<span className="text-danger">&nbsp;*</span></label>
                          <input
                            ref={register({
                              required: false
                            })}
                            name="birthDate"
                            type="date"
                            className="form-control"
                            id="birthDate"
                            placeholder="Birth Date"
                            defaultValue={data.birthDate}
                            // defaultValue={props.value !== undefined && props.value !== null ? props.value.birthDate : ''}
                            // onBlur={(e) => { setData({ ...data, birthDate: e.target.value }) }}
                          />
                        </div>
                      </div>
                          
                      {/* Email Address */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="emailAddress">Email Address<span className="text-danger">&nbsp;*</span></label>
                          <input
                            ref={register({
                              required: false
                            })}
                            name="email"
                            type="email"
                            className="form-control"
                            id="emailAddress"
                            placeholder="Email Address"
                            maxLength="90"
                            defaultValue={data.emailAddress}
                            // defaultValue={props.value !== undefined && props.value !== null ? props.value.emailAddress : ''}
                            onBlur={(e) => { setData({ ...data, emailAddress: e.target.value }) }}
                          />
                        </div>
                      </div>

                      {/* Username */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="username">Username</label>
                          <input
                            type="text"
                            id="username"
                            name="username"
                            // className={`form-control ${errors.username === undefined ? "" : " is-invalid"}`}
                            className="form-control"
                            placeholder="Username"
                            value={ data.emailAddress }
                            disabled="disabled"
                          />
                        </div>
                      </div>
                          
                      {/* Cellphone */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="cellphone">Cellphone Number</label>
                          <input
                            ref={register({
                              required: false
                            })}
                            name="cellphone"
                            type="tel"
                            className="form-control"
                            id="cellphone"
                            placeholder="Cellphone Number"
                            // pattern="[0-9]{11,14}"
                            defaultValue={data.cellphone}
                            // defaultValue={props.value !== undefined && props.value !== null ? props.value.cellphone : ''}
                            // onBlur={(e) => { setData({ ...data, cellphone: e.target.value }) }}
                            maxLength="30"
                          />
                          {/* <small>Format: 09XXXXXXXXX</small><br></br> */}
                        </div>
                      </div>
                          
                      {/* Telephone */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="telephone">Telephone Number</label>
                          <input
                            ref={register({
                              required: false
                            })}
                            name="telephone"
                            type="tel"
                            className="form-control"
                            id="telephone"
                            placeholder="Telephone Number"
                            // pattern="[0-9]{8,30}"
                            defaultValue={data.telephone}
                            // defaultValue={props.value !== undefined && props.value !== null ? props.value.telephone : ''}
                            // onBlur={(e) => { setData({ ...data, telephone: e.target.value }) }}
                            maxLength="70"
                          />
                        </div>
                      </div>
                          
                      {/* Role */}
                      <div className="form-row">
                        <div className="form-group col">
                          <IMSelectAddEmployee
                            data={roleSelect}
                            onHandleChange={selectRoleChange('roleId')}
                            refClassContainer=""
                            name="roleId"
                            isRequired={true}
                            withLabel={true}
                            label="Role"
                            placeHolder="Select Role"
                            forwardRef={() => { }}
                            selectedId={data.roleId}
                            refClassName={`text-center`}
                            withDescription={false}
                            refDisabled={true}
                            refIsStatic={false}
                            defaultValue={data.roleId}
                            withClassContainer={`withClassContainer`}
                            refStaticData={[
                            ]
                            }
                            field={{
                              tableId: 'tableId',
                              display: 'code'
                            }}
                          />
                        </div>
                      </div>

                      {/* Institute */}
                      { (isDean || isChair) &&
                        <div className="form-row">
                          <div className="form-group col">
                            <IMSelectAddEmployee
                              data={instituteSelect}
                              onHandleChange={selectInstituteChange('instituteId')}
                              refClassContainer=""
                              name="instituteId"
                              isRequired={true}
                              withLabel={true}
                              label="Institute"
                              placeHolder="Select institute"
                              forwardRef={() => { }}
                              selectedId={data.instituteId}
                              refClassName={`text-center`}
                              withDescription={false}
                              refDisabled={true}
                              refIsStatic={false}
                              defaultValue={data.instituteId}
                              withClassContainer={`withClassContainer`}
                              refStaticData={[
                              ]
                              }
                              field={{
                                tableId: 'tableId',
                                display: 'code'
                              }}
                            />
                          </div>
                        </div>
                      }

                      {/* Course */} 
                      { isChair &&
                        <div className="form-row">
                          <div className="form-group col">
                            <Autocomplete
                              size="small"
                              id="courses"
                              multiple
                              disableCloseOnSelect
                              options={coursesSelect}
                              getOptionLabel={(option) => option.code}
                              value={courseArray}
                              getOptionSelected={(option,value) => option.tableId === value.tableId}
                              onChange={(event, value) => {
                                  if (value) {
                                    //console.log("value: ",value);
                                    handleGetCourses(value);
                                      // Handle the selected option
                                  } else {
                                      // Handle the case when no option is selected
                                  }
                              }}
                              renderInput={(params) => (
                                  <>
                                    <label>Course/Program</label>
                                    <TextField
                                        {...params}
                                        //label="Select Course/s"
                                        placeholder='Select Course/s'
                                        variant="outlined"
                                        inputRef={register}
                                        name="courses"
                                        // value={employeeId}
                                        // onChange={
                                        //     (e) => { handleEmployeeInputChange(e.target.value) }
                                        // }
                                    />
                                  </>
                              )}
                            /> 
                          </div>
                        </div>
                      }

                      {/* User Type */}
                      <div className="form-row">
                        <div className="form-group col">
                          <label htmlFor="userType">User Type</label>
                          <input
                            name="userType"
                            type="text"
                            className="form-control"
                            id="userType"
                            placeholder="User Type"
                            value={ data.userType }
                            disabled="disabled"
                          />
                        </div>
                      </div>

                      {/* IsActive */}
                      <div className="form-row">
                        <div className="form-group col">
                          <FormControl component="fieldset">
                            <FormControlLabel
                              name="isActive"
                              inputRef={() => { }}
                              control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                              label="Active"
                            />
                          </FormControl>
                        </div>
                      </div>

                      <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button type="submit" className={`btn btn-success`}>Save</button>
                        <button onClick={ () => { onCancel() }} className={`btn btn-secondary`}>Cancel</button>
                      </div>   
                    </form>
                  </section>
                </main> 
              </div>
            </>

            :

            <>
              <div >
                <main className="card mx-auto my-auto shadow-lg" style={{ width: "50%" }}>
                  <header className='card-header p-5 bg-secondary'>
                    <h6 className="float-left m-0 mt-4 ml-5">Change Password</h6>
                  </header>
                  
                  <section className="card-body">
                    {onAlert &&
                      <div className="alert alert-danger" role="alert">
                        Invalid New Password/Confirm Password!
                      </div>
                    }

                    {onSuccess &&
                      <div className="alert alert-success" role="alert">
                        Password has been changed!
                      </div>
                    }

                    <form onSubmit={handleSubmit(onPasswordChange)} className="form fv-plugins-bootstrap fv-plugins-framework mt-5">
                      <div className="form-group col">
                          <label htmlFor="username">Username: </label>
                          <input
                            type="username"
                            className={`form-control`}
                            id="username"
                            name="username"
                            placeholder="Username"
                            ref={register({
                              required: false
                            })}
                            value={data.emailAddress}
                            disabled
                          />
                      </div>
                          
                        <div className="form-group col">
                          <label htmlFor="password">New Password: </label>
                          <input
                            type="password"
                            className={`form-control`}
                            id="password"
                            name="password"
                            placeholder="New Password"
                            ref={register({
                              required: false
                            })}
                          />
                        </div>
                          
                        <div className="form-group col">
                          <label htmlFor="confirm">Confirm New Password: </label>
                          <input
                            type="password"
                            className={`form-control`}
                            id="confirm"
                            name="confirm"
                            placeholder="Confirm Password"
                            ref={register({
                              required: false
                            })}
                          />
                        </div>

                      <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        <button type="submit" className={`btn btn-success`}>Save</button>
                        <button onClick={ () => { onCancel()  }} className={`btn btn-secondary`}>Cancel</button>
                      </div>  
                    </form>
                  </section>
                </main>
              </div>
            </>
            
            :

            // If OpenAdd and SecondAuth are both false
            <>
                <div className='d-flex align-items-center'>
                  <main className="card mx-auto my-auto shadow-lg" style={{ width: "50%" }}>
                    <header className='card-header p-5 bg-secondary'>
                      <h6 className="float-left m-0 mt-4 ml-5">User Maintenance</h6>
                      <section className='float-right'>
                        <button onClick={() => { onAddNewEmployee() }} className='btn btn-sm btn-primary'>Add</button>
                      </section>
                    </header>
                    {/* <section className="card-body"> */}
                      {/* {onAlert == true &&
                        <div className="alert alert-danger" role="alert">
                          No Record Found
                        </div>
                      } */}
                      <div className='row my-5 padding-side-25'>
                        <div className='col-12 col-xl-6 border-right border-primary'>
                          {isAdmin ? <p className="card-text">Employee/Student Info: </p> : <p className="card-text">Employee Info: </p>}

                          {/* Username */}
                          <div className="form-group col">
                              <span className="form-text text-muted">Username: </span>
                              {employee !== null ?
                                  <>
                                      <input value={employee.Username} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                  </>
                                  :
                                      <input placeholder="Username" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                              }
                          </div>

                          {/* Lastname */}
                          <div className="form-group col">
                              <span className="form-text text-muted">Last Name: </span>
                              {employee !== null ?
                                  <>
                                      <input value={employee.LastName} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                  </>
                                  :
                                      <input placeholder="Last Name" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                              }
                          </div>

                          {/* Firstname */}
                          <div className="form-group col">
                              <span className="form-text text-muted">First Name: </span>
                              {employee !== null ?
                                  <>
                                      <input value={employee.FirstName} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                  </>
                                  :
                                      <input placeholder="First Name" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                              }
                          </div>

                          {/* Department */}
                          <div className="form-group col">
                              <span className="form-text text-muted">Department: &nbsp;&nbsp;</span>
                              {employee !== null ?
                                  <>
                                      <input value={employee.DepartmentCode} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                  </>
                                  :
                                      <input placeholder="Department" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled" />
                              }
                          </div>
                        </div>
                                        
                        <div className='col-12 col-xl-6' >
                          {isAdmin ? <p className="card-text">Search Employee/Student: </p> : <p className="card-text">Search Employee: </p>}
                          
                      
                          {/* Employee Number/Name Autocomplete Dropdown */}
                          <div className="form-row">
                            <div className="form-group col">
                              <Autocomplete
                                  id="employeeNo"
                                  options={employeeList}
                                  getOptionLabel={(option) => option.EmployeeName}
                                  onChange={(event, value) => {
                                      if (value) {
                                          handleGetEmployee(value)
                                          // Handle the selected option
                                      } else {
                                        setEmployee(null);
                                        setEmployeeId(null);
                                        setEmployeeNo(null);
                                        setEmployeeList([]);
                                        setIsEmployee(1);
                                          // Handle the case when no option is selected
                                      }
                                  }}
                                  renderInput={(params) => (
                                      <TextField
                                          {...params}
                                          label={isAdmin ? "Employee/Student No./Name" : "Employee No./Name"}
                                          variant="outlined"
                                          inputRef={register}
                                          name="employeeNo"
                                          value={employeeId}
                                          onChange={
                                              (e) => { handleEmployeeInputChange(e.target.value) }
                                          }
                                      />
                                  )}
                              /> 
                            </div>
                          </div>
      
                          {isEmployee == 1 ? 
                            <div>
                              <button onClick={() => { onSearchEmployee() }} className='btn btn-success btn-block'>Search</button>
                              <button onClick={() => { onChangeToDefaultPassword() }} className='btn btn-success btn-block'>Default Password</button>
                            </div>
                            : 
                            <button onClick={() => { onChangeToDefaultPassword() }} className='btn btn-success btn-block'>Default Password</button>}
                        </div>
                      </div>
                      
                    {/* </section> */}
                  </main>
                </div>
            </>
          }
        </>
      }
    </>
  );
}