export const referenceTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_COURSE_OUTLINE_REQ: 'SELECTED-COURSE-OUTLINE-REQ',
    GET_COURSE_OUTLINE_REQS: 'GET-COURSE-OUTLINE-REQS',
    GET_COURSE_OUTLINE_REQ: 'GET-COURSE-OUTLINE-REQ-BY-ID',
    CREATE: 'CREATE-COURSE-OUTLINE-REQ',
    UPDATE: 'UPDATE-COURSE-OUTLINE-REQ',
    DELETE: 'DELETE-COURSE-OUTLINE-REQ',
    LIST_LINK_TO_PARENT: '/admin/references/course-outlines',
    LIST_LINK_TO_PREVIOUS_PARENT: '/admin/references/course-outline-details',
    LIST_LINK: '/admin/references/course-outline-detail-reqs',
    CREATE_LINK: '/admin/references/create-course-outline-detail-reqs/',
    UPDATE_LINK: '/admin/references/update-course-outline-detail-reqs/',
    DELETE_LINK: '/admin/references/delete-course-outline-detail-reqs/'
}