import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';

export default function Form(props) {
  const { register, handleSubmit, errors } = useForm();
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true,
    IsDefault: props.doc.IsDefault === undefined ? false : props.doc.IsDefault === 0 ? false : true,
    AdmissionSY: props.doc.AdmissionSY === undefined ? false : props.doc.AdmissionSY === 0 ? false : true,
    AssessmentSY: props.doc.AssessmentSY === undefined ? false : props.doc.AssessmentSY === 0 ? false : true,
    EnrollmentSY: props.doc.EnrollmentSY === undefined ? false : props.doc.EnrollmentSY === 0 ? false : true,
    GradingSY: props.doc.GradingSY === undefined ? false : props.doc.GradingSY === 0 ? false : true,
    LibrarySY: props.doc.LibrarySY === undefined ? false : props.doc.LibrarySY === 0 ? false : true,
    MobileIsActive: props.doc.MobileIsActive === undefined ? false : props.doc.MobileIsActive === 0 ? false : true
  });
  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const onSubmit = formValues => {
    const { schoolYear, description, isActive, isDefault, admissionSY, assessmentSY, enrollmentSY, gradingSY, librarySY, mobileIsActive } = formValues;

    const convertedFormValues = {
      schoolYear: schoolYear,
      description: description,
      isActive: isActive ? 1 : 0,
      isDefault: isDefault ? 1 : 0,
      admissionSY: admissionSY ? 1 : 0,
      assessmentSY: assessmentSY ? 1 : 0,
      enrollmentSY: enrollmentSY ? 1 : 0,
      gradingSY: gradingSY ? 1 : 0,
      librarySY: librarySY ? 1 : 0,
      mobileIsActive: mobileIsActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">School Year <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="schoolYear"
                  defaultValue={props.doc.SchoolYear === undefined ? "" : props.doc.SchoolYear}
                  className={`form-control${errors.schoolYear === undefined ? "" : errors.schoolYear && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your school year.</span>
                {errors.schoolYear && errors.schoolYear.type === "required" && <div className="invalid-feedback">School year is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  /><br />
                  <FormControlLabel
                    name="isDefault"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsDefault} onChange={switchHandleChange('IsDefault')} value={switchState.IsDefault} />}
                    label="Default"
                  /><br />
                  <FormControlLabel
                    name="admissionSY"
                    inputRef={register()}
                    control={<Switch checked={switchState.AdmissionSY} onChange={switchHandleChange('AdmissionSY')} value={switchState.AdmissionSY} />}
                    label="Admission SY"
                  /><br />
                  <FormControlLabel
                    name="assessmentSY"
                    inputRef={register()}
                    control={<Switch checked={switchState.AssessmentSY} onChange={switchHandleChange('AssessmentSY')} value={switchState.AssessmentSY} />}
                    label="Assessment SY"
                  /><br />
                  <FormControlLabel
                    name="enrollmentSY"
                    inputRef={register()}
                    control={<Switch checked={switchState.EnrollmentSY} onChange={switchHandleChange('EnrollmentSY')} value={switchState.EnrollmentSY} />}
                    label="Enrollment SY"
                  /><br />
                  <FormControlLabel
                    name="gradingSY"
                    inputRef={register()}
                    control={<Switch checked={switchState.GradingSY} onChange={switchHandleChange('GradingSY')} value={switchState.GradingSY} />}
                    label="Grading SY"
                  /><br />
                  <FormControlLabel
                    name="librarySY"
                    inputRef={register()}
                    control={<Switch checked={switchState.LibrarySY} onChange={switchHandleChange('LibrarySY')} value={switchState.LibrarySY} />}
                    label="Library SY"
                  /><br />
                  <FormControlLabel
                    name="mobileIsActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.MobileIsActive} onChange={switchHandleChange('MobileIsActive')} value={switchState.MobileIsActive} />}
                    label="Mobile is Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}