import instance from "../../../../../../apis/local/systemAPI";
import { applicantTypes } from "./types";

// walk in Applicant Form

export const getEnrollment = async (formValues) => {
  const result = await instance.get("api/enrollments", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}




export const createEnrollment = async (formValues) => {
  const result = await instance.post("api/enrollments", formValues);
  console.log(result)
  if (result.status === 200 || result.status === 201) {
    console.log(JSON.stringify(result.data) + 'here in index ')
    return result.data;
  } else {
    console.log(result)
  }
}

export const createEnrollmentBatches = async (formValues) => {
  const result = await instance.post("api/enrollment/batches", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createStudentInfo = async (formValues) => {
  const result = await instance.post("api/student/infos", formValues);
  console.log(result)
  if (result.status === 200 || result.status === 201) {
    console.log(JSON.stringify(result.data) + 'here in index ')
    return result.data;
  }
}

export const createStudent = async (formValues) => {
  const result = await instance.post("api/students", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createStudentFamilyBackground = async (formValues) => {
  const result = await instance.post("api/student/familybackground", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createStudentSchoolHistory = async (formValues) => {
  const result = await instance.post("api/student/schoolhistory", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createStudentDiscount = async (formValues) => {
  const result = await instance.post("api/student/discounts", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getSchoolInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/schools`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "School": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/schools`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getCourses = async (formValues) => {
  const result = await instance.get("api/references/courses", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchoolYear = async (formValues) => {
  const result = await instance.get("api/references/schoolyears", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSemester = async (formValues) => {
  const result = await instance.get("api/references/semesters", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createRegistration = async (formValues) => {
  const result = await instance.post("api/students/register", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const checkStudentNo = async (formValues) => {
  const result = await instance.post("api/students/checkStudentNo", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const checkStudentName = async (formValues) => {
  const result = await instance.post("api/students/checkStudentName", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getStudent = async (formValues) => {
  const result = await instance.get("api/students/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getInstitute = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/institutes`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Description": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/institutes`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getClassSections = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/class-sections`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Description": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/class-sections`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getReligionInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/religions`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Religion": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/religions`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getCivilStatusInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/civilstatus`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "CivilStatus": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/civilstatus`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getYearLevelInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/yearlevels`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "YearLevel": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/yearlevels`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getNationalityInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/nationalities`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Nationality": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/nationalities`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFamilyNetIncome = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/family-net-income`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "NetIncome": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/family-net-income`, params);
    console.log(JSON.stringify(result) + 'this is it')
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getEducationalPlan = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/educational-plan`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "NetIncome": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/educational-plan`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getScholarship = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/scholarships`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "NetIncome": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/scholarships`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFamilyPosition = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/family-position`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "NetIncome": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/family-position`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const login = async (formValues) => {
  const result = await instance.post("api/user/login", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const authTypes = {
  VERIFY_USER_BY_EMAIL_ADDRESS: 'VERIFY-USER-BY-EMAIL-ADDRESS',
  LOGIN: 'LOGIN',
  LOGOUT: 'LOGOUT',
  SEARCH_KEYWORD: 'SEARCH-KEYWORD'
}

export const verifyUserByEmailAddress = async emailAddress => {
  let params = {
    key: authTypes.VERIFY_USER_BY_EMAIL_ADDRESS,
    emailAddress: emailAddress
  };

  const result = await instance.post("/im-user-request", params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


// ----------------------------------------



export const getApplicantList = async (id) => {
  const result = await instance.post(`/api/admission/applicant-list`, id);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCivilStatus = async (condition) => {
  const result = await instance.post(`api/references/civilstatus/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getReligion = async (condition) => {
  const result = await instance.post(`api/references/religions/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getStudentStatus = async (condition) => {
  const result = await instance.post(`api/student/student-status/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourse = async (condition) => {
  const result = await instance.post(`api/references/courses/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getYearLevel = async (condition) => {
  const result = await instance.post("api/references/yearlevels/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getSchool = async (condition) => {
  const result = await instance.post("api/references/schools/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getNationality = async (condition) => {
  const result = await instance.post("api/references/nationalities/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getProvinces = async (condition) => {
  const result = await instance.post(`api/references/provinces/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getMunicipalities = async (condition) => {
  const result = await instance.post(`api/references/municipalities/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCountries = async (condition) => {
  const result = await instance.post(`api/references/countries/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getFamilyNetIncomes = async (condition) => {
  const result = await instance.post("api/references/family-net-income/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getEducationalPlans = async (condition) => {
  const result = await instance.post("api/references/educational-plan/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getScholarships = async (condition) => {
  const result = await instance.post("api/references/scholarships/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getFamilyPositions = async (condition) => {
  const result = await instance.post("api/references/family-position/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getStudentInfo = async (condition) => {
  const result = await instance.post("api/student/infos/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getStudentBESInfo = async (val) => {
  const result = await instance.post("api/bes/studentInfo-migrate/", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const sendMail = async (values) => {
  const result = await instance.post("api/nodemailer/send-mail", values);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const addUpdateStudentSiblings = async (val) => {
  const result = await instance.post("api/students/addUpdateStudentSibling", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
