import instance from "../../../../../apis/local/systemAPI";

export const getCourse = async () => {
    const result = await instance.get("api/campus/course-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getFees = async () => {
    const result = await instance.get("api/campus/fees-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getAssessmentTemplateDetail = async (data) => {
    const result = await instance.post("api/campus/assessmentTemplateDetail-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const getComputationType = async () => {
    const result = await instance.get("api/campus/computationType-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const getFeeGroups = async () => {
    const result = await instance.get("api/campus/feeGroup-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getScholarship = async () => {
    const result = await instance.get("api/campus/scholarship-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const getCourseOutline = async (values) => {
    const result = await instance.post("api/campus/course-outline-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCourseOutlineDetail = async (values) => {
    const result = await instance.post("api/campus/course-outline-detail-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCourseOutlineReq = async (values) => {
    const result = await instance.post("api/campus/course-outline-req-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const getSubjects = async () => {
    const result = await instance.get("api/campus/subject-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getFinalGrade = async (data) => {
    const result = await instance.post("api/campus/finalGrade-migrate", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const getSubjectSchedule = async (data) => {
    const result = await instance.post("api/campus/subjectSchedule-migrate", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getSubjectScheduleDetail = async (data) => {
    const result = await instance.post("api/campus/subjectScheduleDetail-migrate", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getAssessmentTemplate = async (data) => {
    const result = await instance.post("api/campus/assessmentTemplate-migrate", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const getClassSection = async (data) => {
    const result = await instance.post("api/campus/classSection-migrate", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getSubjectScheduleBatch = async (data) => {
    const result = await instance.post("api/campus/subjectScheduleBatch-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveCourse = async (formValues) => {
    const result = await instance.post("api/references/courses/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveScholarship = async (formValues) => {
    const result = await instance.post("api/references/scholarships/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveScholarshipApplied = async (formValues) => {
    const result = await instance.post("api/references/scholarship-applied/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveCourseOutline = async (formValues) => {
    const result = await instance.post("api/course-outlines/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveCourseOutlineDetail = async (formValues) => {
    const result = await instance.post("api/course-outline/details/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveCourseOutlineReq = async (formValues) => {
    const result = await instance.post("api/course-outline/reqs/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveSubjectScheduleDetailList = async (formValues) => {
    const result = await instance.post("api/schedule/detail-lists/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveFees = async (formValues) => {
    const result = await instance.post("api/references/fees/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveClassSection = async (formValues) => {
    const result = await instance.post("api/references/class-sections/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveSubjectScheduleBatch = async (formValues) => {
    const result = await instance.post("api/schedule/batch/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveSubjectScheduleDetail = async (formValues) => {
    const result = await instance.post("api/schedule/details/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveSubjectCategory = async (formValues) => {
    const result = await instance.post("api/references/subject-categories/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const saveAssessmentTamplateRate = async (formValues) => {
    const result = await instance.post("api/assessment/rate/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveAssessmentLabCat = async (formValues) => {
    const result = await instance.post("api/assessment/lab-categories/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveAssessmentTemplateOther = async (formValues) => {
    const result = await instance.post("api/assessment/template-others/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveAssessmentTemplateOtherRate = async (formValues) => {
    const result = await instance.post("api/assessment/template-other-rates/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveFeeGroups = async (formValues) => {
    const result = await instance.post("api/references/feegroups/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveAssessmentTemplate = async (formValues) => {
    const result = await instance.post("api/assessment/templates/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveAssessmentTemplateDetail = async (formValues) => {
    const result = await instance.post("api/assessment/template-details/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveSubjects = async (formValues) => {
    const result = await instance.post("api/references/subjects/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveUsers = async (formValues) => {
    const result = await instance.post("api/user/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const saveComputationType = async (formValues) => {
    const result = await instance.post("api/assessment/computation-types/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const saveStudent201 = async (data) => {
    const result = await instance.post("api/enrollments/migrate201", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const saveFinalGrade = async (data) => {
    const result = await instance.post("api/grades/final-grades/migrateCampus", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveSubjectSchedule = async (data) => {
    const result = await instance.post("api/schedules/migrateCampus", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const getStudentFromCampus = async (data) => {
    const result = await instance.post("api/campus/migrate", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getAssessmentUnits = async (data) => {
    const result = await instance.post("api/campus/units", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getSem = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const getSubjectCategory = async () => {
    const result = await instance.get("api/campus/subjectCategory-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getCampusUsers = async () => {
    const result = await instance.get("api/campus/user-migrate/");
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}





export const getAssessmentTemplateRate = async (values) => {
    const result = await instance.post("api/campus/assessmentTemplateRate-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getAssessmentLabCategory = async (values) => {
    const result = await instance.post("api/campus/assessmentLabCategory-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getAssessmentTemplateOther = async (values) => {
    const result = await instance.post("api/campus/assessmentTemplateOther-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getAssessmentTemplateOtherRate = async (values) => {
    const result = await instance.post("api/campus/assessmentTemplateOtherRate-migrate/", values);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}




export const getStudentCategory = async () => {
    const result = await instance.get("api/references/student-categories/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async () => {
    const result = await instance.get("api/references/courses/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevels = async () => {
    const result = await instance.get("api/references/yearlevels/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const computeStudentCampusEnrollmentAccount = async (formValues) => {
    const result = await instance.post("api/enrollment/account/computeStudentCampusEnrollmentAccount", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const createEnrollmentAccountAddsOn = async (formValues) => {
    const result = await instance.post("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentCampusDiscount = async (data) => {
    const result = await instance.post("api/campus/discount-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getCampusGradeElement = async (data) => {
    const result = await instance.post("api/campus/grade-element-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveGradeElement = async (formValues) => {
    const result = await instance.post("api/references/grade-element/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCampusGradeTemplates = async (data) => {
    const result = await instance.post("api/campus/grade-template-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveGradeTemplate = async (formValues) => {
    const result = await instance.post("api/grades/grade-templates/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getCampusGradeTemplateDetailPeriod = async (data) => {
    const result = await instance.post("api/campus/grade-template-detail-period-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveGradeTemplateDetailPeriod = async (formValues) => {
    const result = await instance.post("api/grades/grade-template-detail-periods/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getCampusGradeTemplateDetailFinal = async (data) => {
    const result = await instance.post("api/campus/grade-template-detail-final-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveGradeTemplateDetailFinal = async (formValues) => {
    const result = await instance.post("api/grades/grade-template-detail-finals/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getCampusInstructionalGuide = async (data) => {
    const result = await instance.post("api/campus/instructionalGuide-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveInstructionalGuide = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCampusInstructionalGuideDetail = async (data) => {
    const result = await instance.post("api/campus/instructionalGuideDetail-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveInstructionalGuideDetail = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide-detail/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getCampusExamType = async (data) => {
    const result = await instance.post("api/campus/examType-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveExamType = async (formValues) => {
    const result = await instance.post("api/student/exam-types/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const getCampusGradeBook = async (data) => {
    const result = await instance.post("api/campus/gBookRawGrade-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveGradeBook = async (formValues) => {
    const result = await instance.post("api/grades/gbook-raw-grade/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

