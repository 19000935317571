export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_SUBJECT_SCHEDULE_DETAIL: 'SELECTED-SUBJECT-SCHEDULE-DETAIL',
    GET_SUBJECT_SCHEDULE_DETAILS: 'GET-SUBJECT-SCHEDULE-DETAILS',
    GET_SUBJECT_SCHEDULE_DETAIL: 'GET-SUBJECT-SCHEDULE-DETAIL-BY-ID',
    CREATE: 'CREATE-SUBJECT-SCHEDULE-DETAIL',
    UPDATE: 'UPDATE-SUBJECT-SCHEDULE-DETAIL',
    DELETE: 'DELETE-SUBJECT-SCHEDULE-DETAIL',
    LIST_LINK_TO_PARENT: '/admin/enrollment/subject-schedules',
    LIST_LINK: '/admin/enrollment/subject-schedule-details/',
    CREATE_LINK: '/admin/enrollment/create-subject-schedule-details/',
    UPDATE_LINK: '/admin/enrollment/update-subject-schedule-details/',
    DELETE_LINK: '/admin/enrollment/delete-subject-schedule-details/',
    // MORE
    CLEAR_LAST_UPDATE_ID_MORE: 'CLEAR-LAST-UPDATE-ID-MORE',
    SELECTED_SUBJECT_SCHEDULE_DETAIL_LIST: 'SELECTED-SUBJECT-SCHEDULE-DETAIL-LIST',
    GET_SUBJECT_SCHEDULE_DETAIL_LISTS: 'GET-SUBJECT-SCHEDULE-DETAIL-LISTS',
    GET_SUBJECT_SCHEDULE_DETAIL_LIST: 'GET-SUBJECT-SCHEDULE-DETAIL-LIST-BY-ID',
    CREATE_MORE: 'CREATE-SUBJECT-SCHEDULE-DETAIL-LIST',
    UPDATE_MORE: 'UPDATE-SUBJECT-SCHEDULE-DETAIL-LIST',
    DELETE_MORE: 'DELETE-SUBJECT-SCHEDULE-DETAIL-LIST',
    CREATE_MORE_LINK: '/admin/enrollment/create-subject-schedule-detail-lists/',
    UPDATE_MORE_LINK: '/admin/enrollment/update-subject-schedule-detail-lists/',
    DELETE_MORE_LINK: '/admin/enrollment/delete-subject-schedule-detail-lists/'
}