import instance from '../../../../../apis/local/systemAPI';

// START OF SEMESTER
export const getActiveSemesters = async () => {
  const result = await instance.get("api/references/semesters");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSemesters = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/semesters/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "semester": keyword,
            "description": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/semesters/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createSemester = async (formValues) => {
  const result = await instance.post("api/references/semesters", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateSemester = async (id, formValues) => {
  const result = await instance.patch(`api/references/semesters/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteSemester = async (id) => {
  const result = await instance.delete(`api/references/semesters/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF SEMESTER