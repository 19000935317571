
import React, { useState, useEffect } from "react";
import LineChart from '../../../../../_metronic/layout/components/custom/chart/line-chart'
import GraphChart from "../../../../../_metronic/layout/components/custom/chart/graph-chart";
import DoughnutChart from "../../../../../_metronic/layout/components/custom/chart/dougnut-chart";
import {
    getInstitute, getEnrollment, getSemester, getSchoolYear, getStudent,
    getEnrollmentQuery, getEnrollmentCurrentWeekReport, getEnrollmentLastWeekReport,
    getEnrollmentYearLevelStatistic, getEnrollmentInstituteStatistic
} from './__hooks__'
import STable from '../../../../../_metronic/layout/components/custom/table/STable'
import '../../../../../_metronic/css/custom/cards/cardsCustom.scss'
import moment from "moment";
import Form from './Form'
import { setIn } from "formik";


export default function Cashier_Accountant_Dashboard(props) {

    const [activeSy, setActiveSy] = useState([])
    const [activeSem, setActiveSem] = useState([])

    const [institute, setInstitute] = useState(undefined)
    const [totalEnrolled, setTotalEnrolled] = useState(0)
    const [totalAssessed, setTotalAssessed] = useState(0)
    const [totalInquires, setTotalInquiries] = useState(0)
    const [totalProcessed, setTotalProcessed] = useState(0)
    const [instituteStatistics, setInstituteStatistics] = useState(null)


    const [weeklyThisWeek, setWeeklyThisWeek] = useState(null)
    const [weeklyLastWeek, setWeeklyLastWeek] = useState(null)
    const [yearlyStats, setYearlyStats] = useState(null)
    const [instituteStats, setInstituteStats] = useState(null)

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const date2 = new Date().toISOString().substr(0, 19).replace('T', ' ').slice(0, -9);
    const lastYear = today.getFullYear() - 1;
    const thisMonth = moment(today).add(0, 'M')


    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }

    const QueryCount = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        if (response.data.length > 0) {
                            setData(response.data.length - 1)

                        } else {
                            setData(0)

                        }
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }


    const getCurrentWeek = async () => {
        await getEnrollmentCurrentWeekReport()
            .then(res => {
                console.log(res.data[0])
                console.log(JSON.parse(res.data[0].Results))
                let data = JSON.parse(res.data[0].Results)
                setWeeklyThisWeek([data.sunday, data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday])
            })
            .catch(e => {

            })



    }

    const getLastWeek = async () => {
        await getEnrollmentLastWeekReport()
            .then(res => {
                console.log(res.data[0])
                console.log(JSON.parse(res.data[0].Results))
                let data = JSON.parse(res.data[0].Results)
                setWeeklyLastWeek([data.sunday, data.monday, data.tuesday, data.wednesday, data.thursday, data.friday, data.saturday])
            })
            .catch(e => {

            })



    }

    const getYearlyStatistic = async (q) => {
        await getEnrollmentYearLevelStatistic(q)
            .then(res => {
                console.log(res.data[0])
                console.log(JSON.parse(res.data[0].Results))
                let data = JSON.parse(res.data[0].Results)
                setYearlyStats([data.firstYear, data.secondYear, data.thirdYear, data.fourthYear, data.fifthYear])

            })
            .catch(e => {

            })
    }


    const getInstituteStatistic = async (q) => {
        await getEnrollmentInstituteStatistic(q)
            .then(res => {
                console.log(res.data[0])
                console.log(JSON.parse(res.data[0].Results))
                let data = JSON.parse(res.data[0].Results)
                setInstituteStats([data.con, data.cba, data.coe, data.coh, data.cas, data.coed, data.chm, data.ccit, data.cops, data.sgs])
            })
            .catch(e => {

            })
    }

    let getActive = "{where: `WHERE IsActive = 1`}"
    let getEnrolledStudent = "{where: `WHERE e.SchoolYear = ${activeSy[0].SchoolYear} AND e.SemesterId = '${activeSem[0].SemesterId}' AND e.EnrollmentStatusId = 'E'`}"
    let getAssessedStudent = "{where: `WHERE e.SchoolYear = ${activeSy[0].SchoolYear} AND e.SemesterId = '${activeSem[0].SemesterId}' AND e.EnrollmentStatusId = 'A'`}"
    let getTotalInquiries = "{where: `WHERE s.SchoolYear = ${activeSy[0].SchoolYear} AND s.SemesterId = '${activeSem[0].SemesterId}'`}"
    let getProcessedStudents = "{where: `WHERE e.SchoolYear = ${activeSy[0].SchoolYear} AND e.SemesterId = '${activeSem[0].SemesterId}' AND e.EnrollmentStatusId = 'DR'`}"
    let getCurrentWeekReports = "{where: `WHERE WEEK(e.DateCreated)=WEEK(NOW()) AND e.SchoolYear = ${activeSy[0].SchoolYear} AND e.SemesterId = '${activeSem[0].SemesterId}'`}"


    useEffect(() => {
        Query(getSemester, getActive, setActiveSem)
        Query(getSchoolYear, getActive, setActiveSy)
    }, [])


    useEffect(() => {

        if (activeSem.length > 0 && activeSy.length > 0) {
            QueryCount(getEnrollmentQuery, getEnrolledStudent, setTotalEnrolled)
            QueryCount(getEnrollmentQuery, getAssessedStudent, setTotalAssessed)
            // QueryCount(getStudent, getTotalInquiries, setTotalInquiries)
            QueryCount(getEnrollmentQuery, getProcessedStudents, setTotalProcessed)
            getCurrentWeek()
            getLastWeek()
            getYearlyStatistic({ semesterId: activeSem[0].SemesterId, schoolYear: activeSy[0].SchoolYear })
            getInstituteStatistic({ semesterId: activeSem[0].SemesterId, schoolYear: activeSy[0].SchoolYear })
        }
    }, [activeSem, activeSy])

    useEffect(() => {
        setTotalInquiries(totalEnrolled + totalAssessed + totalProcessed)
    }, [totalEnrolled, totalAssessed, totalProcessed])



    const weeklyEnrollmentChartLabel = {
        isDataset2: true,

        label: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        labelset1: 'This Week',
        dataset1: weeklyThisWeek,
        labelset2: 'Last Week',
        dataset2: weeklyLastWeek,
    }


    const dailyEnrollmentChartLabel = {
        isDataset2: false,
        label: ['1st Year', '2nd Year', '3rd Year', '4th Year', '5th Year'],
        labelset1: 'Enrolled',
        dataset1: yearlyStats,
        // labelset2: 'Last Week',
        // dataset2: [75, 80, 50, 10],
    }


    const institutesPerCourseLabel = {
        isDataset2: false,
        label: ['CON', 'CBA', 'COE', 'COH', 'CAS', 'COED', 'CHM', 'CCIT', 'COPS', 'SGS'],
        labelset1: 'Enrolled',
        dataset1: instituteStats,
        // labelset2: 'Last Week',
        // dataset2: [75, 80, 50, 10],
    }


    console.log(activeSem)
    console.log(activeSy)
    console.log(weeklyThisWeek)
    console.log(weeklyLastWeek)
    console.log(yearlyStats)
    console.log(instituteStats)
    console.log(totalEnrolled)
    return (
        <>

            {activeSem && activeSy && weeklyThisWeek !== null && weeklyLastWeek !== null && instituteStats !== null
                && yearlyStats !== null ?
                <>
                    <Form
                        totalEnrolled={totalEnrolled}
                        totalAssessed={totalAssessed}
                        totalInquires={totalInquires}
                        totalProcessed={totalProcessed}
                        weeklyEnrollmentChartLabel={weeklyEnrollmentChartLabel}
                        dailyEnrollmentChartLabel={dailyEnrollmentChartLabel}
                        institutesPerCourseLabel={institutesPerCourseLabel}
                        institute={institute}



                    />
                </>

                : ''
            }

        </>
    )
}