import instance from '../../../../../apis/local/systemAPI';

// START OF SCHOLARSHIP TAG
export const getActiveScholarshipTags = async () => {
  const result = await instance.get("api/references/scholarship-tags/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getScholarshipTags = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/scholarship-tags/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "scholarshipTag": keyword,
            "shortName": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/scholarship-tags/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createScholarshipTag = async (formValues) => {
  const result = await instance.post("api/references/scholarship-tags", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateScholarshipTag = async (id, formValues) => {
  const result = await instance.patch(`api/references/scholarship-tags/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteScholarshipTag = async (id) => {
  const result = await instance.delete(`api/references/scholarship-tags/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF SCHOLARSHIP TAG