import React, { useEffect, useState } from 'react';
import { useAppContext } from '../../contexts/useAppContext';
import { getUserInRole } from "./_Dashboards/CashierAccountantDashboard/__hooks__"
import Cashier_Accountant_Dashboard from './_Dashboards/CashierAccountantDashboard/Create';
import Registrar_Dashboard from './_Dashboards/RegistrarDashboard/Create';
import Student_Dashboard from './_Dashboards/StudentDashboard/StudentDashboard'
import moment from 'moment';
import history from '../../history';

export default function Dashboard() {
    const { state: { auth }, dispatch } = useAppContext()
    const [isUserRole, setIsUserRole] = useState(false)
    const [userRoleData, setUserRoleData] = useState(null)
    useEffect(() => {
        if (auth.data.UserType === "ONL") {
            localStorage.clear()
            history.push(`/auth/login`)
        }
    }, [])
    // const executeGetUserRole = async () => {
    //     let userRole = []
    //     await getUserInRole()
    //         .then(response => {
    //             if (response.success) {
    //                 for (let i = 0; i < response.data.length; i++) {
    //                     if (response.data[i].UserId === auth.data.UserId) {
    //                         userRole.push(response.data[i])
    //                     }
    //                 }
    //                 setUserRoleData(userRole[0])
    //             }
    //         })
    //         .catch(error => {
    //             localStorage.clear();
    //         })
    // }


    // if (isUserRole === false) {
    //     executeGetUserRole()
    //     setIsUserRole(true)


    // }


    // USER DASHBOARD CONDITION ______________________________
    // ROLE ID #11 = Accountant
    // ROLE ID #10 = Admission
    // ROLE ID #9 = Registrar
    // ROLE ID #8 = Student
    // ROLE ID #7 = Dean
    // ROLE ID #6 = Chairman
    // ROLE ID #5 = CASHIER ROLE
    // ROLE ID #4 = Course Outline
    // ROLE ID #3 = Faculty
    // ROLE ID #2 = User
    // ROLE ID #1 = Admin

    let Dashboard
    if (auth.data !== null) {
        if (auth.data.RoleId === 5 || auth.data.RoleId === 11) {
            // Dashboard = <Cashier_Accountant_Dashboard />
        } else if (auth.data.RoleId === 9) {
            // Dashboard = <Registrar_Dashboard />

        } else if (auth.data.RoleId === 8) {
            Dashboard = <Student_Dashboard />

        }
    }

    console.log(auth)
    console.log(userRoleData)
    return (
        <>
            <main className='bg-white container-fluid '>
                <div className='card bg-transparent p-5 border-0'>
                    {userRoleData !== null && userRoleData.RoleId === 8 ? '' :
                        <>
                            <h1>Dashboard</h1>
                            <p className='lead'>{moment().format('LLLL')}</p>
                        </>
                    }


                    <section className='card-body p-0'>
                        {Dashboard}

                    </section>



                </div>
            </main>


        </>

    );
}