import React, { forwardRef, useRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from "../../../../../_metronic/_assets/logo/FaithCollegesPNG.png"
import './__hooks__/styles.css'
//import FaithLogo from './assets/FaithCollegesPNG.png'

export default function PrintedForm(props) {
    const { report } = props
    let componentRef = useRef();
    console.log("Test")
    console.log(report)

    const Component = forwardRef((props, ref) => {


        return (
            <div className="d-none">
                <div ref={ref}>

                    <div className="container">
                        <main className="card border-0 table-panel">

                            <div className="table-header">

                                <section className="card-header table-head text-center border-0">

                                    <img style={{ width: '250px' }} src={FaithLogo} />
                                    <h1 className="font-weight-bold mt-5">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h1>
                                    <h4 className="">Course Outline Printing</h4>



                                </section>
                                <section className="card-body">
                                    {report.length > 0 &&

                                        report.map(items => {

                                            return (
                                                <>
                                                    <div className="">

                                                        <h3 className="text-center mt-5 table-heads">{`${items.CourseCode} - ${items.CourseDesc}`}({items.CurrCode})</h3>

                                                        <hr />
                                                        {items.Details.map(det => {
                                                            return (
                                                                <>

                                                                    <div className=" table-report">
                                                                        <h6 className="table-title">{det.Period}</h6>
                                                                        <table className="table table-bordered ">

                                                                            <thead>
                                                                                <tr>
                                                                                    <th scope="col">Subject Code</th>
                                                                                    <th scope="col">Subject Description</th>
                                                                                    <th scope="col">Units</th>
                                                                                    <th scope="col">Lec / Lab Hours</th>
                                                                                    <th scope="col">Co-Requisite</th>
                                                                                    <th scope="col">Pre-Requisite</th>


                                                                                </tr>
                                                                            </thead>
                                                                            <tbody>
                                                                                {det.Subjects.map(sub => {

                                                                                    return (

                                                                                        <>
                                                                                            <tr>
                                                                                                <th>{sub.SubjectCode}</th>
                                                                                                <td>{sub.SubjectDescription}</td>
                                                                                                <td>{sub.Units}</td>
                                                                                                <td>{sub.LecLabHours}</td>
                                                                                                <td>{sub.CoRequisite}</td>
                                                                                                <td>{sub.PreRequisite}</td>

                                                                                            </tr>
                                                                                        </>
                                                                                    )
                                                                                })}
                                                                            </tbody>
                                                                        </table>
                                                                        <p className="lead mb-5"> The total number of lecture and laboratory units per hour: <span><b>{det.TotalLabHours + det.TotalLecHours}</b> </span> </p>

                                                                    </div>




                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                </>
                                            )


                                        })



                                    }
                                </section>
                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    })


    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }

    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn btn-lg btn-success mr-3">Print Course Curriculum</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}

                />
            </div>



        </>
    )
}