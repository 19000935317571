import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getSubject, createSubject, updateSubject, deleteSubject, getInstitute, getTest, getSubjectCategory } from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { getIn, setIn } from 'formik';

export default function List() {
  const { state: { fees }, dispatch } = useAppContext();
  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
  const [page, setPage] = useState(fees.page);
  const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
  const [keyword, setKeyword] = useState(fees.keyword);
  const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)
  const updateId = fees.id;



  const [instituteSelect, setInstituteSelect] = useState(null)
  const [subjectCategorySelect, setSubjectCategorySelect] = useState(null)
  const [isProcessing, setIsProcessing] = useState(false)

  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // on save grade element  -----------------------------------------------------------
  const onSaveGradeElement = async (val) => {
    setIsProcessing(true)
    await createSubject(val)
      .then(async res => {
        await getSubject(page, rowsPerPage, keyword)
          .then(response => {
            if (response.success) {
              setResults(response.data);
              setTotalRecord(response.data.length > 0 ? response.records : 0);
              setIsOpenAdd(false)
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Subject has been saved.' });
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
            setIsProcessing(false)
          })
          .catch(error => {

            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

          })




      })
  }

  // on update grade element
  const onUpdateElement = async (id, val) => {
    setIsProcessing(true)
    await updateSubject(id, val)
      .then(async res => {
        await getSubject(page, rowsPerPage, keyword)
          .then(response => {
            if (response.success) {
              setResults(response.data);
              setTotalRecord(response.data.length > 0 ? response.records : 0);
              setIsOpenUpdate(false)
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Subject has been updated.' });
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
            setIsProcessing(false)

          })
          .catch(error => {

            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

          })

      })
  }

  // on delete grade element
  const onDeleteElement = async (id) => {
    await deleteSubject(id)
      .then(async res => {
        await getSubject(page, rowsPerPage, keyword)
          .then(response => {
            if (response.success) {
              setResults(response.data);
              setTotalRecord(response.data.length > 0 ? response.records : 0);
              setIsOpenDelete(false)
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Subject has been Deleted.' });
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {

            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

          })

      })
      .catch(e => {

      })
  }



  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    setIsOpenAdd(true)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {
    setRowValue(data)
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
  }


  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  useEffect(() => {
    SelectCreator(getSubjectCategory, setSubjectCategorySelect, 'SubjectCategoryId', 'SubjectCategory')
  }, [])

  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      subjectCode: props.value !== undefined && props.value !== null ? props.value.subjectCode : null,
      description: props.value !== undefined && props.value !== null ? props.value.gradeTemplateDesc : null,
      units: props.value !== undefined && props.value !== null ? props.value.units : null,
      labUnits: props.value !== undefined && props.value !== null ? props.value.labUnits : null,
      labHours: props.value !== undefined && props.value !== null ? props.value.labHours : null,
      lecUnits: props.value !== undefined && props.value !== null ? props.value.lecUnits : null,
      lecHours: props.value !== undefined && props.value !== null ? props.value.lecHours : null,
      subjectCategoryId: props.value !== undefined && props.value !== null ? props.value.subjectCategoryId : null

    })


    const [switchState, setSwitchState] = useState({
      isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false

    });

    const switchHandleChange = name => event => {
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects

    const selectInstituteChange = name => event => { setData({ ...data, subjectCategoryId: event }) }





    useEffect(() => {


      props.onSave({
        ...data,
        isActive: switchState.isActive == true ? 1 : 0
      })


    }, [data, switchState])



    console.log(props.value)
    return (
      <>

        <main className='container'>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Subject Code</label>
              <input
                required type="text"
                className="form-control"
                id="inputEmail4"
                placeholder="Subject Code"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.subjectCode : ''}
                onBlur={(e) => { setData({ ...data, subjectCode: e.target.value }) }}
              />
            </div>

          </div>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Description</label>
              <input
                type="text"
                className="form-control"
                id="inputEmail4"
                placeholder="Description"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.gradeTemplateDesc : ''}
                onBlur={(e) => { setData({ ...data, description: e.target.value }) }}

              />
            </div>

          </div>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Unit</label>
              <input
                type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="Unit"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.units : ''}
                onBlur={(e) => { setData({ ...data, units: e.target.value }) }}

              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Lab Units</label>
              <input
                type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="Lab Unit"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.labUnits : ''}
                onBlur={(e) => { setData({ ...data, labUnits: e.target.value }) }}

              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Lab Hours</label>
              <input
                type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="Lab Hour"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.labHours : ''}
                onBlur={(e) => { setData({ ...data, labHours: e.target.value }) }}

              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Lec Units</label>
              <input
                type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="Lab Hour"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.lecUnits : ''}
                onBlur={(e) => { setData({ ...data, lecUnits: e.target.value }) }}

              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Lec Hours</label>
              <input
                type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="Lab Hour"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.lecHours : ''}
                onBlur={(e) => { setData({ ...data, lecHours: e.target.value }) }}

              />
            </div>

          </div>
          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.subjectCategorySelect}
                onHandleChange={selectInstituteChange('subjectCategoryId')}
                refClassContainer=""
                name="subjectCategoryId"
                isRequired={false}
                withLabel={false}
                label="Subject Category"
                placeHolder="Select Category"
                forwardRef={() => { }}
                selectedId={data.subjectCategoryId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>
          <div className="form-row">

            <div className="form-group col">
              <FormControl component="fieldset">
                <FormControlLabel
                  name="isActive"
                  inputRef={() => { }}
                  control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                  label="Active"
                />
              </FormControl>
            </div>
          </div>
        </main>
      </>
    )
  }

  const DeleteContent = (props) => {


    console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.subjectCode : ''} `}</span> </p>
        </main>



      </>
    )
  }



  useEffect(() => {
    const execute = async () => {
      await getSubject(page, rowsPerPage, keyword)
        .then(response => {
          if (response.success) {
            setResults(response.data);
            setTotalRecord(response.data.length > 0 ? response.records : 0);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {

          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

        })
    }
    execute();

  }, [page, rowsPerPage, keyword])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'SubjectCode', label: 'Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Units', label: 'Unit', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'LabUnits', label: 'Lab Unit', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'LabHours', label: 'Lab Hour', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'LecUnits', label: 'Lec Unit', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'LecHours', label: 'Lec Hour', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'SubjectCategory', label: 'Subject Category', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'SubjectId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdateSubject',
    parentId: null,
    tableTitle: 'List of Subjects',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: referenceTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)
  console.log(keysToCamelCase(rowValue))
  console.log(instituteSelect)

  return (
    <>
      {subjectCategorySelect &&
        <>
          <AddUpdateModal
            title="Add Subject"
            isOpen={isOpenAdd}
            Content={Content}
            handleClose={onAddGradeElementClose}
            isAdd={true}
            subjectCategorySelect={subjectCategorySelect}
            onSubmit={onSaveGradeElement}
            id="subjectId"
            isProcessing={isProcessing}
          />

          <AddUpdateModal
            title="Update Subject"
            isOpen={isOpenUpdate}
            Content={Content}
            value={keysToCamelCase(rowValue)}
            handleClose={onUpdateGradeElementClose}
            isUpdate={true}
            subjectCategorySelect={subjectCategorySelect}
            onSubmit={onUpdateElement}
            id="subjectId"
            isProcessing={isProcessing}

          />

          <AddUpdateModal
            title="Delete Subject"
            isOpen={isOpenDelete}
            Content={DeleteContent}
            handleClose={onDeleteGradeElementClose}
            isDelete={true}
            onSubmit={onDeleteElement}
            subjectCategorySelect={subjectCategorySelect}
            value={keysToCamelCase(rowDeleteValue)}
            id="subjectId"
            isProcessing={isProcessing}
          />
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
              </div>
              <div className="card-toolbar">
                <div className="example-tools">
                  <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                    <i className="fa fa-plus"></i> Add New
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
              <IMTable tableProps={tableProps} />
            </div>
          </div>

        </>
      }

    </>
  );
}