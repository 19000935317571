import instance from '../../../../../apis/local/systemAPI';

// START OF MUNICIPALITY
export const getActiveMunicipalities = async () => {
  const result = await instance.get("api/references/municipalities");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getMunicipalities = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/municipalities/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "municipality": keyword,
            "zipCode": keyword,
            "province": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/municipalities/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createMunicipality = async (formValues) => {
  const result = await instance.post("api/references/municipalities", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateMunicipality = async (id, formValues) => {
  const result = await instance.patch(`api/references/municipalities/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteMunicipality = async (id) => {
  const result = await instance.delete(`api/references/municipalities/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF MUNICIPALITY