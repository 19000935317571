import React, { useState, useEffect } from "react";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { getStudentList, getSchoolYear, getSemester, studentAutoComplete, checkIfSOAExist } from "./__hooks__/index"
import { useAppContext } from "../../../../contexts/useAppContext";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { accountTypes } from "./__hooks__/types";
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from "lodash";
import history from "../../../../history";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import './__hooks__/styles.css';


export default function AccountSummarySearch(props) {
    const { state: { auth } } = useAppContext()
    const { setValue, handleSubmit, register } = useForm()
    const [student, setStudent] = useState(null)
    const [studentId, setStudentId] = useState(auth.data.StudentId);
    const [studentNo, setStudentNo] = useState(null)
    const [error, setError] = useState(false)
    const [hasSOARecord, setHasSOARecord] = useState(false);

    // filter
    const [studentList, setStudentList] = useState([]);
    const [defaultValues, setDefaultValues] = useState({
        studentId: null,
        studentName: '',
    });
    
    const [schoolYearSelect, setSchoolYearSelect] = useState(null);
    const [semesterSelect, setSemesterSelect] = useState(null);

    const [filter, setFilter] = useState({ 
        schoolYear: auth.data.schoolyears.enrollment.SchoolYear, 
        semesterId: auth.data.schoolyears.enrollment.SemesterId
    });

    const filterChange = name => event => { 
        //setFilter({ ...filter, [name]: event });
        
        setError(false);

        setFilter((prevData) => {
            return {...prevData, [name]: event}
        });

        let selectedSY = filter.schoolYear;
        let selectedSem = filter.semesterId;

        if(name == "schoolYear"){
            selectedSY = event;
        } else {
            selectedSem = event;
        }

        execCheckIfSOAExist({ schoolYear: selectedSY, semesterId: selectedSem, studentId: studentId});
        getStudentListData({ schoolYear: selectedSY, semesterId: selectedSem, studentId: auth.data.UserType == 'S' ? auth.data.StudentId : studentId});
    }

    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description");
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description");

        if(auth.data.UserType == 'S'){
            
            execCheckIfSOAExist({ 
                schoolYear: auth.data.schoolyears.enrollment.SchoolYear, 
                semesterId: auth.data.schoolyears.enrollment.SemesterId, 
                studentId: studentId});

            getStudentListData({ 
                schoolYear: auth.data.schoolyears.enrollment.SchoolYear, 
                semesterId: auth.data.schoolyears.enrollment.SemesterId, 
                studentId: studentId});
        }
    }, [])


    // functions

    const getStudentListData = async (val) => {
        await getStudentList(val)
            .then(res => {
                if (res.data.length > 0) {
                    setStudent(res.data[0]);

                    // if(auth.data.UserType != 'S'){
                    //     setStudent(res.data[0]);
                    // } else {
                    //     history.push({
                    //         pathname: "/admin/student/student-account-summary", 
                    //         data: res.data[0]
                    //     });
                    // }

                } else {
                    //setError(true)
                }
            })
    }

    const execCheckIfSOAExist = async (val) => {
        await checkIfSOAExist(val)
            .then(res => {
                if (res.data.length > 0) {
                    setHasSOARecord(res.data[0].Result == 1 ? true : false);
                } else {
                    setError(true)
                }
            })
    }

    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);

    }

    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
            })
            .catch(e => {
            })
    }, 300);

    const handleGetStudent = async (data) => {
        setStudentId(data.StudentId);
        //setStudentNo(data.StudentNo);

        execCheckIfSOAExist({ schoolYear: filter.schoolYear, semesterId: filter.semesterId, studentId: data.StudentId});
        getStudentListData({ schoolYear: filter.schoolYear, semesterId: filter.semesterId, studentId: data.StudentId});
    }

    // const onCancel = () => {
    //     setStudent(null)
    //     setStudentNo('')
    //     setStudentId(0)
    //     setError(false)
    // }

    return (
        <>
            {
                schoolYearSelect && semesterSelect ? 

                    // auth.data.UserType == 'S' ?
                
                    // <>
                    //     <Loading />
                    // </>
    
                    // :
                
                    <>
                        <div className="container">
                            <section className="p-5">
                                <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                                    <section className="card-body">
                                        {error == true &&
                                            <div className="alert alert-danger" role="alert">
                                                No Record Found
                                            </div>
                                        }

                                        <p className="card-text">Search for Statement of Account</p>
                                    
                                        {/* School Year Dropdown */}
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={schoolYearSelect}
                                                    onHandleChange={filterChange('schoolYear')}
                                                    refClassContainer=""
                                                    name="schoolYear"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="School Year"
                                                    placeHolder="Select School Year"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.schoolYear}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`School Year`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>
                                                
                                        {/* Semester Dropdown */}
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={semesterSelect}
                                                    onHandleChange={filterChange('semesterId')}
                                                    refClassContainer=""
                                                    name="semesterId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Semester"
                                                    placeHolder="Select Semester"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.semesterId}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Semester`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {/* Student Detail (Number, ID, Name) */}
                                        {auth.data.UserType != "S" ? 
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <Autocomplete
                                                        id="studentList"
                                                        options={studentList}
                                                        defaultValue={{StudentId: defaultValues.studentId, StudentName: defaultValues.studentName}}
                                                        getOptionLabel={(option) => option.StudentName}
                                                        getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                                        onChange={(event, value) => {
                                                            if (value) {
                                                                // Handle the selected option
                                                                handleGetStudent(value)
                                                            } else {                                                                            
                                                                // Handle the case when no option is selected
                                                                // setStudentId(null);
                                                                // setStudentList([]);
                                                                // setStudent(null);
                                                                // setResults([]);
                                                                // setOnAlert(false);
                                                                // setStudentSelected((prevData) => {
                                                                //     return {...prevData, 
                                                                //         studentId: null, 
                                                                //         studentName: '',
                                                                //         fullName: '',
                                                                //         course: ''}
                                                                // });
                                                            }
                                                        }}
                                                        renderInput={(params) => (
                                                            <TextField
                                                                {...params}
                                                                label="Student Number/Name"
                                                                variant="outlined"
                                                                inputRef={register}
                                                                name="studentList"
                                                                onChange={
                                                                    (e) => { handleStudentInputChange(e.target.value) }
                                                                }
                                                            />
                                                        )}
                                                    /> 
                                                </div>
                                            </div> 
                                            
                                            : 
                                            
                                            <></>
                                        }
                                        <div className="form-row">
                                            {student !== null && hasSOARecord ? 
                                                <>
                                                    {student.IsForReservation == 0 &&
                                                        <section className="col"> 
                                                            <Link to={{ 
                                                                pathname: `${accountTypes.ACCOUNT_SUMMARY}`, data: student }} 
                                                                className="btn btn-success soa-search-button">
                                                                Search
                                                            </Link>
                                                        </section>   
                                                    } 
                                                </> 
                                                
                                                : 
                                                
                                                <>
                                                    {
                                                        <section className="col"> 
                                                            <button onClick={() => { setError(true) }} className="btn btn-success soa-search-button">Search</button>
                                                        </section>  
                                                    }
                                                </>
                                            }
                                        </div>
                                    </section>
                                </main>
                            </section>
                        </div>
                    </>
                
                :
                <Loading />
            }
            
        </>
    )
}