import React, { useState } from 'react';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import Form from './Form';
import { updateInstitute } from './__hooks__';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import { NoAccess } from '../../../../../_metronic/layout/components/custom/forms/NoAccess';

export default function Update(props) {
  const { state: { institutes, auth }, dispatch } = useAppContext();
  const { id } = props.match.params;
  const [keepLoading, setKeepLoading] = useState(true)
  if (institutes.data.length === 0) {
    history.push(referenceTypes.LIST_LINK);
    return null;
  }
  const institute = institutes.data[id];

  const onSubmit = formValues => {
    const execute = async () => {
      await updateInstitute(id, formValues)
        .then(response => {
          if (response.success) {
            dispatch({ type: referenceTypes.UPDATE, data: response.data, id: response.data.InstituteId });
            history.push(referenceTypes.LIST_LINK);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  setTimeout(function () {
    setKeepLoading(false)
  }, 5000);

  return (
    <>
      {

        auth.data.UserType === 'DEN'
          || auth.data.UserType === 'CHR'
          || auth.data.UserType === 'RGR'
          || auth.data.UserType === 'ADM'

          ?

          <div className="kt-container  kt-grid__item kt-grid__item--fluid">
            <div className="row ac-minimum-height-container-350">
              <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
                <Form onSubmit={onSubmit} doc={institute} />
              </div>
            </div>
          </div>

          :

          <>
            <div className="card p-5">

              {keepLoading === true
                ?
                <Loading />
                :
                <>
                  {auth.data.UserType === 'DEN'
                    || auth.data.UserType === 'CHR'
                    || auth.data.UserType === 'RGR'
                    || auth.data.UserType === 'ADM'

                    ? ''

                    : <NoAccess />
                  }
                </>
              }
            </div>
          </>

      }

    </>
  );
}