import React, { useState, useEffect } from "react";
import IMTableClearance from "../../../../../_metronic/layout/components/custom/table/IMTableClearance";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import { useAppContext } from "../../../../contexts/useAppContext";
import {
    getStudentClearance, studentClearanceAddUpdate,
    searchStudent, studentAutoComplete, getSchoolYear,
    getSemester, getAccountability
} from "./__hooks__/index.js"
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import IMDialogClearanceNotification from "../../../../../_metronic/layout/components/custom/dialog/IMDialogClearanceNotification";




export default function StudentClearance(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const { setValue, handleSubmit, register } = useForm()
    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState()
    const [studentId, setStudentId] = useState(null)
    const [studentClearance, setStudentClearance] = useState(null)
    const [selectedClearance, setSelectedClearance] = useState([])
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear

    const [semSelect, setSemSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const [accountabilitySelect, setAccountabilitySelect] = useState()
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)

    const [studentList, setStudentList] = useState([])
    const [clearanceDialogue, setClearanceDialogue] = useState(false)


    const [studentClearanceInfo, setStudentClearanceInfo] = useState({})


    const today = new Date();
    const month = today.getMonth() + 1
    const date = today.getFullYear() + '-' + month + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }

    const exeGetStudentClearance = async (val) => {
        await getStudentClearance(val)
            .then(res => {
                setSelectedClearance(res.selectedClearance)
                setStudentClearance(res.data)
            })
            .catch(e => {

            })
    }

    const onHandleRemarks = (remark, accountabilityId) => {
        console.log(remark)
        console.log(studentClearanceInfo)
        onCreateClearanceStudent(studentClearanceInfo, remark, accountabilityId)

    }

    const onCreateClearanceStudent = (data, remark, accountabilityId) => {
        const execute = async (d) => {
            let clearance = studentClearance.find(clearance => clearance.DepartmentId == d)
            console.log(clearance)
            console.log(d)

            await studentClearanceAddUpdate({
                ClearanceId: clearance.ClearanceId,
                AccountabilityId: accountabilityId,
                StudentId: student.StudentId,
                DepartmentId: data,
                HoldBy: (clearance.Status == null || clearance.Status == 'P') ? null : auth.data.Username,
                HoldDate: null,
                ClearBy: (clearance.Status == null || clearance.Status == 'C') ? null : auth.data.Username,
                Status: (clearance.Status == null || clearance.Status == 'C') ? 'P' : 'C',
                Remarks: remark,
                CreatedBy: auth.data.Username,
                SchoolYear: filteredSy,
                SemesterId: filteredSem
            })
                .then(response => {
                    exeGetStudentClearance({
                        studentId: student.StudentId,
                        schoolYear: filteredSy,
                        semesterId: filteredSem,
                        userType: auth.data.UserType
                    })
                })

        }

        execute(data)
    }



    const onSubmit = (formValues) => {
        console.log(formValues)

        let data = {
            ...formValues,
            semesterId: filteredSem,
            schoolYear: filteredSy
        }
        executeSearchStudent(data)

    }


    const handleStudentInputChange = async (input) => {
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }


    const executeSearchStudent = async (val) => {
        await searchStudent(val)
            .then(res => {
                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found, Try Migrating Student In Campus'
                    });

                    setStudent(null)
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }

    const onCancelSearch = () => {
        setStudent(null)
        setStudentClearance(null)
        setStudentId(null)
    }

    const clickHandler = (data, isCleared) => {
        setStudentClearanceInfo(data)
        console.log(isCleared)
        if (isCleared == true)
            setClearanceDialogue(true)
        else {
            onCreateClearanceStudent(data, '')

        }

    }

    const onCloseHandler = () => {
        setClearanceDialogue(false)

    }

    const handleGetStudent = async (data) => {
        setStudentId(data.StudentId)

        console.log(data)
    }

    const searchStudentDetail = (formValues) => {
        console.log(formValues)

        let data = {
            ...formValues,
            semesterId: filteredSem,
            schoolYear: filteredSy
        }
        executeSearchStudent(data)

    }

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'DepartmentCode', label: 'Department', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Accountability', label: 'Accountability', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Status', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },


    ]


    let tableProps = {
        recordId: 'DepartmentId',
        sortField: '',
        columns: columns,
        rows: studentClearance,
        totalRecords: studentClearance !== null && studentClearance.length > 0 ? studentClearance.length : 0,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'clearance',
        parentId: null,
        tableTitle: 'List of Clearance',
        onSearch: '',
        keyword: '',
        lastUpdateId: '',
        onIsLastUpdate: false,
        onPage: true,
        onRowsPerPage: 10,
        onRowsPerPageOptions: [10, 25, 50, 100]

    }

    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? activeSy : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? activeSem : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    useEffect(() => {
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
        SelectCreator(getAccountability, setAccountabilitySelect, 'AccountabilityId', 'Description')

    }, [])


    useEffect(() => {
        if (studentId !== undefined && studentId !== null) {
            setTimeout(() => {
                searchStudentDetail({ studentId: studentId, semesterId: filteredSem, schoolYear: filteredSy })

            }, 500)

        }
    }, [studentId])

    // useEffect(() => {
    //     exeGetStudentClearance({ studentId: auth.data.StudentId })
    // }, [])

    console.log(studentClearance)
    console.log(auth)


    console.log(filteredSy)
    console.log(filteredSem)
    console.log(accountabilitySelect)
    return (

        <>
            {studentClearance !== null ?

                <>

                    {clearanceDialogue &&
                        <IMDialogClearanceNotification
                            title={`Remarks`}
                            description='Provide a comprehensive explanation of the Student liability.'
                            onShowDialog={setClearanceDialogue}
                            onHandleRemarks={onHandleRemarks}
                            accountabilitySelect={accountabilitySelect}
                            isOpen={true}
                        />

                    }

                    <main className="card p-5">
                        <section className="card-header">
                            <h6 className="float-left">Student Clearance</h6>
                            <button onClick={onCancelSearch} className="btn btn-secondary float-right">Search Again</button>

                        </section>
                        <section className="card-body">
                            <IMTableClearance
                                tableProps={tableProps}
                                dateTime={dateTime}
                                user={auth.data.Username}
                                clickHandler={clickHandler}
                                selectedCredentials={selectedClearance}
                            />
                        </section>


                    </main>

                </>

                :
                <main className="container">
                    <div className="card">


                        <div className="row container my-5">
                            <div className="col-12 col-xl-4  border-right border-primary">
                                <div className=" p-5">
                                    {student !== null ?
                                        <>
                                            <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                            <p className="my-0  ">{student.CourseDesc}</p>
                                        </>

                                        :

                                        <>
                                            <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                            {/* //<p className="my-0  ">Student Course</p> */}
                                        </>

                                    }


                                    <hr />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>



                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "Old Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>
                                    <hr />


                                </div>


                            </div>
                            <div className="col-12 col-xl-8">

                                <div className="p-5 mb-5 ">
                                    <h3 className="bg-secondary lead p-3 font-weight-bold">Search for a Student</h3>

                                    <section className="p-5">
                                        <div className="mb-2">{imSelectDropdownSchoolYear()}</div>
                                        <div className="mb-2">{imSelectDropdownSemester()}</div>
                                        <form onSubmit={handleSubmit(onSubmit)} method="post">



                                            <Autocomplete
                                                id="studentNo"
                                                options={studentList}
                                                getOptionLabel={(option) => option.StudentName}
                                                onChange={(event, value) => {
                                                    if (value) {
                                                        handleGetStudent(value)
                                                        // Handle the selected option
                                                    } else {
                                                        // Handle the case when no option is selected
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Student Number"
                                                        variant="outlined"
                                                        inputRef={register}
                                                        name="studentNo"
                                                        value={studentNo}
                                                        onChange={
                                                            (e) => { handleStudentInputChange(e.target.value) }

                                                        }
                                                    />
                                                )}
                                            />


                                        </form>
                                        {student !== null ?
                                            <>
                                                <div className="mt-5">

                                                    <button onClick={() => {
                                                        exeGetStudentClearance({ studentId: student.StudentId, schoolYear: filteredSy, semesterId: filteredSem, userType: auth.data.UserType })
                                                    }} type="button" className="btn btn-primary mr-5 ">Clearance</button>


                                                    <button onClick={() => { onCancelSearch() }} type="button" className="btn btn-secondary ">Cancel</button>
                                                </div>


                                            </>
                                            :
                                            ''

                                        }
                                    </section>



                                </div>


                            </div>
                        </div>
                    </div>

                </main>

            }
        </>
    )
}