export const admissionsTypes = {

    SELECTED_STUDENT_INFO: 'SELECTED-STUDENT-INFO',
    GET_STUDENT_INFO: 'GET-STUDENT-INFO',
    GET_STUDENT_INFO_BY_ID: 'GET-STUDENT-INFO-BY-ID',
    CREATE: 'CREATE-APPLICANT',
    UPDATE: 'UPDATE-APPLICANT',
    DELETE: 'DELETE-APPLICANT',
    LIST_LINK: '/admin/student/create-assessment/',
    LIST_LINK_MORE: '/admin/student/student-master-lists',
    CREATE_LINK: '/admin/student/create-applicant-data-entry/new-applicant',
    UPDATE_LINK: '/admin/student/update-student/',
    DELETE_LINK: '/admin/student/delete-applicant-data-entry/',
    MORE_LINK: '/admin/student/create-assessment/',
    CREATE_LINK_DETAIL: '/admin/student/create-applicant-data-entry-detail',
    STUDENT_MASTERLIST_LINK: '/admin/student/student-master-lists'



}


