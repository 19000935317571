export const referenceTypes = {
    SELECTED_EDUCATIONAL_PLAN: 'SELECTED-EDUCATIONAL-PLAN',
    GET_EDUCATIONAL_PLAN: 'GET-EDUCATIONAL-PLAN',
    GET_EDUCATIONAL_PLAN_BY_ID: 'GET-EDUCATIONAL-PLAN-BY-ID',
    CREATE: 'CREATE-EDUCATIONAL-PLAN',
    UPDATE: 'UPDATE-EDUCATIONAL-PLAN',
    DELETE: 'DELETE-EDUCATIONAL-PLAN',
    LIST_LINK: '/admin/references/educational-plan',
    CREATE_LINK: '/admin/references/create-educational-plan/',
    UPDATE_LINK: '/admin/references/update-educational-plan/',
    DELETE_LINK: '/admin/references/delete-educational-plan/'
}