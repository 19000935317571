export const referenceTypes = {
    SELECTED_FAMILY_NET_INCOME: 'SELECTED-FAMILY-NET-INCOME',
    GET_FAMILY_NET_INCOME: 'GET-FAMILY-NET-INCOME',
    GET_FAMILY_NET_INCOME_BY_ID: 'GET-FAMILY-NET-INCOME-BY-ID',
    CREATE: 'CREATE-FAMILY-NET-INCOME',
    UPDATE: 'UPDATE-FAMILY-NET-INCOME',
    DELETE: 'DELETE-FAMILY-NET-INCOME',
    LIST_LINK: '/admin/references/family-net-income',
    CREATE_LINK: '/admin/references/create-family-net-income/',
    UPDATE_LINK: '/admin/references/update-family-net-income/',
    DELETE_LINK: '/admin/references/delete-family-net-income/'
}