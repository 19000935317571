import instance from '../../../../../../apis/local/systemAPI';

// START OF ASSESSMENT TEMPLATE OTHER
export const getActiveAssessmentTemplateOthers = async () => {
  const result = await instance.get("api/assessment/template-others/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateOthersByAssessmentTemplateId = async (id) => {
  const params = {
    "assessmentTemplateId": id === undefined ? null : id
  }
  var result = await instance.post('api/assessment/template-others/q', params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createAssessmentTemplateOther = async (formValues) => {
  const result = await instance.post("api/assessment/template-others", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplateOther = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/template-others/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteAssessmentTemplateOther = async (val) => {
  const result = await instance.post(`api/assessment/template-others/delete`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addUpdateAssessmentTemplateOther = async (val) => {
  const result = await instance.post(`api/assessment/template-others/addUpdate`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const addDuplicateAssessmentTemplateOther = async (val) => {
  const result = await instance.post(`api/assessment/template-others/addDuplicate`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateQuery = async (con) => {
  const result = await instance.post("api/assessment/templates/q", con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF ASSESSMENT TEMPLATE OTHER