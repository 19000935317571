import React, { useState, useEffect } from 'react';
import GradeBookDetailsModal from "../../../../../../_metronic/layout/components/custom/modal/GradeBookDetailsModal"
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuideDetail, getRawGradeDetails, addUpdateGBookRawGrades, isClearPercentage,
    isHaveSchedule, updateIsVisibleRawGrades
} from './__hooks__';
import { useParams } from 'react-router-dom';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../../../../history';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { data, classSectionId, subjectScheduleDetailId, isPosted, instructionalScheduleTypeId } = props
    const { state: { }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [rawDetails, setRawDetails] = useState([])
    const [hps, setHps] = useState(0)
    const classes = useStyles();
    const { id } = useParams()
    const [isOpenGbookDetails, setIsOpenGbookDetails] = useState(false)
    const [isPercentageClear, setIsPercentageIsClear] = useState(true)

    const [isLoadingStudent, setIsLoadingStudent] = useState(false)
    let rowCnt = 0;


    if (data == null) {
        history.push(`/admin/student/gradebook`)
    } else {
        console.log(data)
    }



    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;

        } else {
            setIsOpenGbookDetails(false)
            setRawDetails(() => { return [] })
        }

    }

    const handleOpen = (detailId, guideId, periodId) => {
        setIsOpenGbookDetails(true)
        setIsLoadingStudent(true)
        getGradeBookDetails(guideId, detailId, periodId)
    }


    const onLoad = async (detailId, guideId, periodId) => {
        await getGradeBookDetails(guideId, detailId, periodId)

    }


    useEffect(() => {

        console.log(rawDetails)
        if (rawDetails.length > 0) {
            console.log(rawDetails)
            setIsLoadingStudent(false)

        } else {
            if (isLoadingStudent == true) {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'There is no student associated with this section.'
                });
                setIsLoadingStudent(false)
            }

        }
    }, [rawDetails])


    const getGradeBookDetails = async (guideId, guideDetailId, gradingPeriodId) => {
        await isClearPercentage({ instructionalGuideId: guideId, gradingPeriodId: gradingPeriodId })
            .then(res => {
                if (res.data.length > 0) {
                    if (res.data[0].IsClearedInPercentage == 0) {
                        setIsPercentageIsClear(false)
                    } else {
                        setIsPercentageIsClear(true)

                    }
                }
            })
        await isHaveSchedule({ instructionalGuideId: guideId })
            .then(async resp => {
                if (resp.data.length > 0 && resp.data[0].IsHaveSchedule == 1) {
                    await getRawGradeDetails({
                        instructionalGuideId: guideId,
                        instructionalGuideDetailId: guideDetailId,
                        schoolYear: props.data.SchoolYear,
                        semesterId: props.data.SemesterId,
                        gradingPeriodId: gradingPeriodId,
                        subjectScheduleDetailId: subjectScheduleDetailId,
                        classSectionId: classSectionId,
                        isLec: data.IsLec,
                        scheduleTypeId: instructionalScheduleTypeId
                    })
                        .then(res => {
                            // alert('test')

                            console.log(res)
                            if (res.data.length > 0)
                                setHps(res.data[0].HighScore)
                            setRawDetails(() => { return res.data })
                        })
                        .catch(e => {

                        })
                } else {
                    setIsLoadingStudent(false)
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'No designated schedule is currently available for the instructional guide.' });

                }
            })

    }


    const onSaveRawGrades = async (grades) => {
        await addUpdateGBookRawGrades(grades)
            .then(res => {
                return res
                // getGradeBookDetails(grades.instructionalGuieId, grades.instructionalGuideDetailId)

            })
    }

    const onSaveIsVisible = async (values) => {
        await updateIsVisibleRawGrades(values)
            .then(res => {
                return res;
                // getGradeBookDetails(grades.instructionalGuieId, grades.instructionalGuideDetailId)
            })
    }

    useEffect(() => {
        const execute = async () => {
            await getInstructionalGuideDetail({ where: `WHERE IGD.InstructionalGuideId = ${parseInt(id)}` })
                .then(response => {
                    if (response.success) {
                        setResults(response.data);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        }
        execute();

    }, [])



    console.log(results)
    console.log(isOpenGbookDetails)
    console.log(rawDetails)
    console.log(data)
    console.log(isPosted)
    console.log(isPercentageClear)
    return (
        <>
            {results.length > 0 &&

                <>
                    {/* {rawDetails.length > 0 && */}
                    <GradeBookDetailsModal
                        isOpen={isOpenGbookDetails}
                        results={rawDetails}
                        onLoad={onLoad}
                        handleClose={handleClose}
                        data={data}
                        onSaveRawGrades={onSaveRawGrades}
                        onSaveIsVisible={onSaveIsVisible}
                        classSectionId={classSectionId}
                        hps={hps}
                        isPosted={isPosted}
                        isPercentageClear={isPercentageClear}
                        scheduleTypeId={instructionalScheduleTypeId}

                    />

                    {/* } */}

                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header d-block bg-white" style={{ minHeight: 0 }}>
                            <div className="card-title">
                                {/* <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> */}
                            </div>
                            <div className="card-toolbar float-right">
                                <div className="example-tools">
                                    {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary p-1">
                                        <i className="fa fa-plus p-0"></i>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                            {/* <IMTable tableProps={tableProps} /> */}
                            <TableContainer >
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">#</TableCell>
                                            <TableCell>Period</TableCell>
                                            <TableCell align="center">Component</TableCell>
                                            {/* <TableCell align="center">Topic No</TableCell> */}
                                            <TableCell align="center">Topic Description</TableCell>
                                            <TableCell align="center">High Score</TableCell>
                                            <TableCell align="center">Percentage</TableCell>
                                            <TableCell align="center">Computational Base</TableCell>
                                            <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results.length > 0 ? results.map((result) => {
                                            const { InstructionalGuideDetailId, InstructionalGuideId, GradingPeriod, Component, GradingPeriodId, HighScore, Percentage, ComputeBase, TopicDescription } = result;
                                            let isLoading
                                            rowCnt += 1;

                                            return (

                                                <TableRow key={InstructionalGuideDetailId}>
                                                    <TableCell align="center">{rowCnt}</TableCell>
                                                    <TableCell component="th" scope="row">{GradingPeriod}</TableCell>
                                                    <TableCell
                                                        className='text-primary'
                                                        align="center"
                                                        onClick={() => { handleOpen(InstructionalGuideDetailId, InstructionalGuideId, GradingPeriodId) }}
                                                        style={{ cursor: 'pointer' }}
                                                    >
                                                        {Component}
                                                    </TableCell>
                                                    {/* <TableCell align="center">{TopicNo}</TableCell> */}
                                                    <TableCell align="center">{TopicDescription}</TableCell>
                                                    <TableCell align="center">{HighScore}</TableCell>
                                                    <TableCell align="center">{Percentage}</TableCell>
                                                    <TableCell align="center">{ComputeBase}</TableCell>

                                                    <TableCell align="center">

                                                        {isLoadingStudent == false ?
                                                            <button onClick={() => { handleOpen(InstructionalGuideDetailId, InstructionalGuideId, GradingPeriodId) }} className='btn btn-xs btn-icon btn-icon-xs btn-primary mr-1'>
                                                                <i className="fa fa-users"></i>
                                                            </button>
                                                            :

                                                            <button className="btn btn-sm btn-primary" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm p-0" role="status" aria-hidden="true"></span>
                                                                <span className="sr-only">Loading...</span>
                                                            </button>
                                                        }



                                                    </TableCell>
                                                </TableRow>
                                            );

                                        }) : <p className='p-1 text-center'>No data available in the table</p>}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </>

            }



        </>
    );
}