import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import StudentComponentModal from "../../../../../_metronic/layout/components/custom/modal/StudentComponentsModal"
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getSchoolYear, getGradingPeriod, getStudentGrades, studentAutoComplete, getStudentDetails, getClearanceStatus,
    getReprintStatus, getComponents, getGradingPeriods, getWeightedAverage, getNumberOfUnits, getNumberOfUnitsFailed,
    getNumberOfUnitsPassed, getNumberOfUnitsDropped, hasPrintAccess
} from './__hooks__';
import PrintStudentGradeViewing from "./PrintStudentGradeViewing";
import IMTableGradeViewing from '../../../../../_metronic/layout/components/custom/table/IMTableGradeViewing';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { getSemester } from '../ChangeCourse/__hooks__';
import { getStudentAccount } from "../../_Dashboards/__hooks__"
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import ProfileCover from "../../../../../_metronic/_assets/logo/profileCover.jpg"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";


export default function StudentGradeViewing() {
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const activeSem = auth.data.schoolyears.grading.SemesterId
    const activeSy = auth.data.schoolyears.grading.SchoolYear
    const [results, setResults] = useState([]);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(fees.keyword);
    //const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
    const [onAlert, setOnAlert] = useState(false)
    const updateId = fees.id;
    const gradingPeriodId = 1;
    const [timeGenerated, setTimeGenerated] = useState(null);
    const [schoolYearSelect, setSchoolYearSelect] = useState(null);
    const [semesterSelect, setSemesterSelect] = useState(null);
    const [gradingPeriodSelect, setGradingPeriodSelect] = useState(null);
    const [clearance, setClearance] = useState([]);
    const [reprintStatus, setReprintStatus] = useState(0);
    const { register, handleSubmit, errors } = useForm();

    const [student, setStudent] = useState(null);
    const [studentList, setStudentList] = useState([]);
    const [studentId, setStudentId] = useState()

    const [rawDetails, setRawDetails] = useState([]);
    const [isOpenComponents, setIsOpenComponents] = useState(false);

    const [semesterString, setSemesterString] = useState(null);
    const [academicYearSelect, setAcademicYearSelect] = useState(null);
    const [gradingPeriodName, setGradingPeriodName] = useState('');
    const [totalUnits, setTotalUnits] = useState(0.00);
    const [weightedAverage, setWeightedAverage] = useState(0.00);
    const [totalUnitsPassed, setTotalUnitsPassed] = useState(0);
    const [totalUnitsPassedCampus, setTotalUnitsPassedCampus] = useState(0);
    const [totalUnitsPassedAstra, setTotalUnitsPassedAstra] = useState(0);
    const [totalUnitsFailed, setTotalUnitsFailed] = useState(0.00);
    const [totalUnitsDropped, setTotalUnitsDropped] = useState(0.00);
    const [hasPrintingAccess, setHasPrintingAccess] = useState(false);
    const [isFetchingGrade, setIsFetchingGrade] = useState(false)

    const [filter, setFilter] = useState({
        schoolYear: activeSy,
        semesterId: activeSem,
        gradingPeriodId: gradingPeriodId
    });

    const filterChange = name => event => {
        setFilter({ ...filter, [name]: event });
        setData((prevData) => {
            return { ...prevData, [name]: event }
        });
        setResults([]);
        //setGradingPeriodName('');
        setTotalUnits(0.00);
        setWeightedAverage(0.00);
        setTimeGenerated(null);
        setOnAlert(false);
        setClearance([]);
        executeGetStudentClearance();
        //setStudentId(null);
    }

    const [data, setData] = useState({
        studentId: null,
        studentNo: null,
        schoolYear: filter.schoolYear,
        semesterId: filter.semesterId,
        gradingPeriodId: filter.gradingPeriodId,
        createdBy: auth.data.Username,
        numOfGrades: 0
    });

    const [studentSelected, setStudentSelected] = useState({
        studentId: null,
        studentName: '',
        fullName: '',
        course: '',
        studentNo: ''
    });

    const [defaultValues, setDefaultValues] = useState({
        studentId: null,
        studentName: '',
    });

    const handleClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;

        } else {
            setIsOpenComponents(false)
            setRawDetails(() => { return [] })
        }
    }

    // table pagination and search -----------------------------------------------------

    const onSearch = () => {
        setIsFetchingGrade(true)
        onGetNumberOfUnits()
            .then(() => onGetWeightedAverage())
            .then(() => onGetNumberOfUnits())
            .then(() => onGetNumberOfUnitsPassed())
            .then(() => onGetNumberOfUnitsFailed())
            .then(() => onGetNumberOfUnitsDropped())
            .then(() => searchForGrades())
    }

    const onReset = async () => {
        //setStudentList([]);
        setResults([]);
        setTotalUnits(0.00);
        setTotalUnitsPassed(0);
        setTotalUnitsFailed(0);
        setWeightedAverage(0);
        setTimeGenerated(null);
        setOnAlert(false);

        setDefaultValues((prevData) => {
            return {
                ...prevData,
                studentId: studentSelected.studentId,
                studentName: studentSelected.studentName
            }
        });
    }

    // Search for student's grade
    const searchForGrades = async () => {

        if (filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semesterId == '') {
            setOnAlert(true);
        } else {
            let date = new Date();
            setTimeGenerated(date.toLocaleTimeString('en-US', { hour12: true }));
            setResults([]);
            setOnAlert(false);

            let values = {
                studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId, //Set value of studentId based on user type
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                gradingPeriod: filter.gradingPeriodId,
                userId: auth.data.UserId,
                studentNo: studentId
            };

            await getStudentGrades(values)
                .then(result => {
                    if (result.success) {
                        setIsFetchingGrade(false)

                        if (result.results > 0) {
                            setResults(result.data);
                            setSemesterString(result.data[0].Semester);
                            setAcademicYearSelect(result.data[0].SchoolYear);
                            setGradingPeriodName(result.data[0].GradingPeriod);

                            // let totalUnits = 0.00;
                            // for(let i = 0; i < result.data.length; i++){
                            //     totalUnits += parseFloat(result.data[i].Units);                         
                            // }
                            // setTotalUnits(totalUnits.toFixed(2));

                            // let totalGrade = 0.00;
                            // let ctr = 0;
                            // for(let i = 0; i < result.data.length; i++){
                            //     if(result.data[i].Grade != 'NG'){
                            //         totalGrade += parseFloat(result.data[i].Grade);
                            //         ctr++;
                            //     }                        
                            // }

                            // if(ctr != 0){
                            //     totalGrade = totalGrade / ctr;
                            // }

                            //console.log("Weighted Average: ", totalGrade.toFixed(2));
                            //setWeightedAverage(totalGrade.toFixed(2));
                        }
                        setOnAlert(true);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                });
        }
    }

    const onGetWeightedAverage = async () => {

        if (filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semesterId == '') {
            setOnAlert(true);
        } else {
            let values = {
                studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId, //Set value of studentId based on user type
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                gradingPeriod: filter.gradingPeriodId,
                userId: auth.data.UserId
            };

            await getWeightedAverage(values)
                .then(result => {
                    console.log(result)
                    if (result.success) {
                        setWeightedAverage(result.data.WeightedAverage === null ? 0.00 : parseFloat(result.data.WeightedAverage).toFixed(2));
                        //setOnAlert(true);            
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                    console.log(error)
                    if (error.response.status === 401) {
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                });
        }
    }

    const onGetNumberOfUnits = async () => {

        if (filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semesterId == '') {
            setOnAlert(true);
        } else {

            let values = {
                studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId, //Set value of studentId based on user type
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                gradingPeriod: filter.gradingPeriodId
            };

            await getNumberOfUnits(values)
                .then(result => {
                    if (result.success) {
                        setTotalUnits(result.data.NoOfUnits === null ? 0.00 : result.data.NoOfUnits);
                        //setOnAlert(true);            
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                });
        }
    }

    const onGetNumberOfUnitsPassed = async () => {

        if (filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semesterId == '') {
            setOnAlert(true);
        } else {

            let values = {
                studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId, //Set value of studentId based on user type
                userId: auth.data.UserId
                // schoolYear: filter.schoolYear,
                // semesterId: filter.semesterId,
                // gradingPeriod: filter.gradingPeriodId
            };

            await getNumberOfUnitsPassed(values)
                .then(result => {
                    if (result.success) {
                        setTotalUnitsPassed(result.data.TotalUnits === null ? 0 : result.data.TotalUnits);
                        setTotalUnitsPassedCampus(result.data.CampusUnits === null ? 0 : result.data.CampusUnits);
                        setTotalUnitsPassedAstra(result.data.ACUnits === null ? 0 : result.data.ACUnits);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                });
        }
    }

    const onGetNumberOfUnitsFailed = async () => {

        if (filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semesterId == '') {
            setOnAlert(true);
        } else {

            let values = {
                studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId, //Set value of studentId based on user type
                userId: auth.data.UserId
                // schoolYear: filter.schoolYear,
                // semesterId: filter.semesterId,
                // gradingPeriod: filter.gradingPeriodId
            };

            await getNumberOfUnitsFailed(values)
                .then(result => {
                    if (result.success) {
                        setTotalUnitsFailed(result.data.TotalUnits === null ? 0.00 : result.data.TotalUnits);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                });
        }
    }

    const onGetNumberOfUnitsDropped = async () => {

        if (filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semesterId == '') {
            setOnAlert(true);
        } else {

            let values = {
                studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId, //Set value of studentId based on user type
                userId: auth.data.UserId// schoolYear: filter.schoolYear,
                // semesterId: filter.semesterId,
                // gradingPeriod: filter.gradingPeriodId
            };

            await getNumberOfUnitsDropped(values)
                .then(result => {
                    if (result.success) {
                        setTotalUnitsDropped(result.data.DroppedUnits === null ? 0.00 : result.data.DroppedUnits);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                });
        }
    }

    const handleStudentInputChange = async (input) => {
        await studentAutoComplete({ input: input, schoolYear: filter.schoolYear, semesterId: filter.semesterId })
            .then(res => {
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    // Set Student ID based on the selected value from the Auto-Complete field
    const handleGetStudent = async (data) => {
        setClearance([]);
        setStudent(null);
        setResults([]);
        setTotalUnits(0);
        setWeightedAverage(0.00);
        setTimeGenerated(null);
        setOnAlert(false);
        setStudentId(data.StudentId);
        //executeGetStudentClearance();
        //setClearance([]);
        //setStudentList([]);

        if (studentList !== null) {
            for (let i = 0; i < studentList.length; i++) {
                if (studentList[i].StudentNo == data.StudentNo) {
                    console.log(studentList[i])
                    setStudentSelected((prevData) => {
                        return {
                            ...prevData,
                            studentId: studentList[i].StudentId,
                            studentName: studentList[i].StudentName,
                            fullName: studentList[i].FullName,
                            course: studentList[i].CourseDesc,
                            studentNo: studentList[i].StudentNo
                        }
                    });
                    break;
                }
            }
        }

        if (auth.data.UserType != "S") {
            setStudent(null);
        }
    }

    // Get Student Account if auth.data.UserType == S (for Student)
    const executeGetStudentAccount = async (val) => {
        let values = {
            studentId: auth.data.UserType != "S" ? val : auth.data.StudentId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            gradingPeriodId: filter.gradingPeriodId
        }

        await getStudentDetails(values)
            .then(res => {
                if (res.success) {
                    if (res.data.length > 0) {
                        setStudent(res.data[0]);
                        setData({ ...data, studentNo: res.data[0].StudentNo, studentId: res.data[0].StudentId });

                        if (auth.data.UserType == "S") {
                            setStudentSelected((prevData) => {
                                return {
                                    ...prevData,
                                    studentId: 0,
                                    fullName: res.data[0].StudentName,
                                    course: res.data[0].CourseDesc,
                                    studentNo: res.data[0].StudentNo
                                }
                            });
                        }
                    }
                }
            })
            .catch(error => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                if (error.response.status === 401) {
                    //history.push('/auth/login');
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                }
            })
    }

    const onGetComponents = async (row) => {
        let values = {
            subjectId: row.SubjectId,
            studentId: auth.data.UserType != "S" ? studentId : auth.data.StudentId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            gradingPeriodId: filter.gradingPeriodId,
            subjectScheduleDetailId: row.SubjectScheduleDetailId
        }

        await getComponents(values)
            .then(res => {
                if (res.success) {
                    setRawDetails(res.data);
                    setIsOpenComponents(true);
                }
            })
            .catch(error => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                if (error.response.status === 401) {
                    //history.push('/auth/login');
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                }
            })
    }

    const executeGetStudentClearance = async () => {
        let values = {
            studentId: studentId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId
            //gradingPeriodId: filter.gradingPeriodId
        }

        await getClearanceStatus(values)
            .then(res => {
                if (res.success) {
                    if (res.data.length > 0) {
                        setClearance(res.data);
                    }
                }
            })
            .catch(error => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                if (error.response.status === 401) {
                    //history.push('/auth/login');
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                }
            })
    }

    // const executeGetReprintStatus = async () => {
    //     let values = {
    //         studentNo: data.studentNo,
    //         schoolYear: filter.schoolYear,
    //         semesterId: filter.semesterId,
    //         gradingPeriodId: filter.gradingPeriodId,
    //         createdBy: auth.data.Username
    //     }

    //     await getReprintStatus(values)
    //         .then(res => {
    //             if(res.success){
    //                 if (res.data.length > 0) {
    //                     console.log("REPRINT STATUS: ", res.data);
    //                     setReprintStatus(res.data[0].ReprintStatus);
    //                 }
    //             }
    //         })
    //         .catch(error => {
    //             dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
    //           if (error.response.status === 401) {
    //             //history.push('/auth/login');
    //           } else {
    //             dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
    //           }
    //         })
    // }

    const executeHasPrintAccess = async () => {
        let values = {
            userId: auth.data.UserId
        }

        await hasPrintAccess(values)
            .then(res => {
                if (res.success) {
                    setHasPrintingAccess(res.data.Result == 1 ? true : false);
                }
            })
            .catch(error => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
            })
    }

    useEffect(() => {
        if (auth.data.UserType == 'S') {
            executeGetStudentAccount(auth.data.StudentId);
        }
    }, [])

    useEffect(() => {
        if (studentId !== undefined && studentId !== "" && auth.data.UserType != "S" && studentId != null) {
            console.log(studentId)
            executeGetStudentAccount(studentId);
            executeGetStudentClearance();
        }
    }, [studentId])

    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description");
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description");
        SelectCreator(getGradingPeriods, setGradingPeriodSelect, "GradingPeriodId", "Description");
        executeHasPrintAccess();
    }, [])

    useEffect(() => {
        if (filter.semesterId !== null && semesterSelect !== null) {
            for (let i = 0; i < semesterSelect.length; i++) {
                if (semesterSelect[i].tableId == filter.semesterId) {
                    setSemesterString(semesterSelect[i].code);
                    executeGetStudentClearance();
                    break;
                }
            }
        }
    }, [filter.semesterId])

    useEffect(() => {
        if (filter.schoolYear !== null && schoolYearSelect !== null) {
            for (let i = 0; i < schoolYearSelect.length; i++) {
                if (schoolYearSelect[i].tableId == filter.schoolYear) {
                    setAcademicYearSelect(schoolYearSelect[i].code.slice(3));
                    executeGetStudentClearance();
                    break;
                }
            }
        }
    }, [filter.schoolYear])

    useEffect(() => {
        if (filter.gradingPeriodId !== null && gradingPeriodSelect !== null) {
            for (let i = 0; i < gradingPeriodSelect.length; i++) {
                if (gradingPeriodSelect[i].tableId == filter.gradingPeriodId) {
                    setGradingPeriodName(gradingPeriodSelect[i].code);
                    executeGetStudentClearance();
                    // setData((prevData) => {
                    //     return {...prevData, gradingPeriodId: filter.gradingPeriodId}
                    // });
                    break;
                }
            }
        }
    }, [filter.gradingPeriodId])

    useEffect(() => {
        if (results !== null) {
            setData((prevData) => {
                return { ...prevData, numOfGrades: results.length }
            });
        }
    }, [results]);


    useEffect(() => {
        if (filter.schoolYear < 2023) {

            setGradingPeriodSelect([{ tableId: 5, code: '<Final Rating>' }])
        } else if (filter.schoolYear == 2023 && filter.semesterId == '1S') {
            setGradingPeriodSelect([{ tableId: 5, code: '<Final Rating>' }])
        } else {
            SelectCreator(getGradingPeriods, setGradingPeriodSelect, "GradingPeriodId", "Description");

        }
    }, [filter.schoolYear, filter.semesterId])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Instructor', label: 'Instructor', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Grade', label: 'Grade', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Units', label: 'Units', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'GradingPeriod', label: 'Grading Period', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        // { id: 'Components', label: 'Components', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'SubjectCode',
        sortField: '',
        columns: columns,
        rows: results,
        //totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'individualReportCard',
        parentId: null,
        tableTitle: '',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: updateId,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        // onGetComponents: onGetComponents
        // onHandleChangePage: handleChangePage,
        // onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        // onHandleChangeKeyword: handleChangeKeyword,
        // onHandleChangeOnSearch: handleChangeOnSearch,
        // onUpdate: onUpdateGradeElement,
        // onDelete: onDeleteGradeElement
    }

    return (
        <>
            {/* {
                <StudentComponentModal
                    isOpen={isOpenComponents}
                    results={rawDetails}
                    //onLoad={onLoad}
                    handleClose={handleClose}
                    data={data}
                    //onSaveRawGrades={onSaveRawGrades}
                    //onSaveIsVisible={onSaveIsVisible}
                    //classSectionId={classSectionId}
                    //hps={hps}
                    //isPosted={isPosted}
                    //isPercentageClear={isPercentageClear}
                    //scheduleTypeId={instructionalScheduleTypeId}
                />
            } */}

            {schoolYearSelect && semesterSelect && gradingPeriodSelect ?
                <>
                    <div className="d-flex flex-column-fluid">
                        <div className="container-fluid p-0 p-xl">
                            <main className="bg-white container-fluid p-0 p-xl">
                                <div className="card bg-transparent p-5 border-0">
                                    <section className="card-body p-0 p-xl">
                                        <div>
                                            <div className="row">
                                                <div className="col-12 col-xl-3 min-height">
                                                    <div className="card" style={{ height: '100%' }}>
                                                        <img className="card-img-top" src={ProfileCover} alt="Bologna" style={{ height: '200px', overflow: 'hidden' }} />
                                                        <div className="card-body bg-light text-center">
                                                            <img className="avatar rounded-circle" src={ProfileLogo} alt="Bologna" />
                                                            {studentSelected.studentId !== null ?
                                                                <>
                                                                    {/* <h3 className="card-title mb-0" >{student.StudentName}</h3> 
                                                                    <p className="card-text mt-0" placeholder="Course">{student !== null ? student.CourseDesc : "------"}</p> */}
                                                                    <h3 className="card-title mb-0" >{studentSelected.fullName}</h3>
                                                                    <div>
                                                                        <br></br>
                                                                        <p className="card-text mt-0" placeholder="Course">{studentSelected.course}</p>
                                                                        <p className="card-text mt-0" placeholder="Course">{studentSelected.studentNo}</p>
                                                                    </div>

                                                                    {clearance.length > 0 ?
                                                                        <>
                                                                            <div className="card-body text-left">
                                                                                <p style={{ fontWeight: "700", textAlign: "center" }}>LIST OF CLEARANCES TO OBTAIN:</p>
                                                                                <ol>
                                                                                    {clearance.map((d, i) => {
                                                                                        return (
                                                                                            <div key={i}>
                                                                                                <li className="text-left">{d.DepartmentCode}</li>
                                                                                                {d.Remarks !== null && d.Remarks.length > 0 ?
                                                                                                    <ul style={{ listStylePosition: "inside" }}>
                                                                                                        <li className="text-left">{d.Remarks}</li>
                                                                                                    </ul> :
                                                                                                    <>
                                                                                                        {/* <p>LOL</p> */}
                                                                                                    </>
                                                                                                }
                                                                                            </div>
                                                                                        )
                                                                                    })}
                                                                                </ol>
                                                                            </div>
                                                                        </>

                                                                        : <> </>
                                                                    }
                                                                </>
                                                                :
                                                                <>
                                                                    <h3 className="card-title mb-0" style={{ color: "#B5B5C3" }}>Student Name</h3>

                                                                    <div>
                                                                        <br></br>
                                                                        <p className="card-text mt-0" style={{ color: "#B5B5C3" }}>Course</p>
                                                                        <p className="card-text mt-0" style={{ color: "#B5B5C3" }}>Student No.</p>
                                                                    </div>

                                                                    <div>
                                                                    </div>
                                                                </>
                                                            }
                                                        </div>
                                                    </div>
                                                </div>

                                                {results.length > 0 ?
                                                    <>
                                                        <div className="col-12 col-xl-9">
                                                            {hasPrintingAccess ?
                                                                <>
                                                                    <section className="col-12 col-xl-3"> <button onClick={() => { onReset() }} className='btn btn-success btn-block'>Reset</button></section>
                                                                    <section className="col-12 col-xl-3">
                                                                        <PrintStudentGradeViewing results={[academicYearSelect, semesterString, timeGenerated,
                                                                            student, results, gradingPeriodName, totalUnits, weightedAverage, data, clearance.length,
                                                                            totalUnitsPassed, totalUnitsFailed, totalUnitsDropped]} />
                                                                    </section>
                                                                </>


                                                                :

                                                                // <div className="form-row" style={{ width: "25%", float: "right" }}>
                                                                <section className="col-12 col-xl-3 mt-5"> <button onClick={() => { onReset() }} className='btn btn-success btn-block'>Reset</button></section>
                                                                // </div>
                                                            }
                                                            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                                                <IMTableGradeViewing tableProps={tableProps} />
                                                            </div>

                                                            <br></br>
                                                            <div>
                                                                <table className='col-8 col-xl-4'>
                                                                    {/* computation should be done inside SP or server-side, not client */}
                                                                    <tr>
                                                                        <td>Total Units:</td>
                                                                        <td>{totalUnits}</td>
                                                                    </tr>
                                                                    {
                                                                        filter.gradingPeriodId == 5 &&
                                                                        <>
                                                                            {/* <tr>
                                                                        <td>No. of Units Passed (Campus):</td>
                                                                        <td>{totalUnitsPassedCampus}</td>
                                                                    </tr>
                                                                    <tr>
                                                                        <td>No. of Units Passed (Astra):</td>
                                                                        <td>{totalUnitsPassedAstra}</td>
                                                                    </tr> */}
                                                                            <tr>
                                                                                <td>No. of Units Passed:</td>
                                                                                <td>{totalUnitsPassed}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>No. of Units Failed:</td>
                                                                                <td>{totalUnitsFailed}</td>
                                                                            </tr>
                                                                            <tr>
                                                                                <td>No. of Units Dropped:</td>
                                                                                <td>{totalUnitsDropped}</td>
                                                                            </tr>
                                                                        </>
                                                                    }
                                                                    <tr>
                                                                        <td>Weighted Average:</td>
                                                                        <td>{weightedAverage}</td>
                                                                    </tr>
                                                                </table>
                                                            </div>
                                                        </div>
                                                    </>

                                                    :
                                                    <>

                                                        <div className="col-12 col-xl-6 mt-5">
                                                            <main className="card mx-auto my-auto shadow-lg" style={{ width: "100%" }}>
                                                                <section className="card-body">
                                                                    {onAlert == true && results.length == 0 &&
                                                                        <div className="alert alert-danger" role="alert">
                                                                            No Record Found
                                                                        </div>
                                                                    }
                                                                    <p className="card-text">Search for Grades</p>

                                                                    {/* School Year Dropdown */}
                                                                    <div className="form-row">
                                                                        <div className="form-group col">
                                                                            <IMSelect
                                                                                data={schoolYearSelect}
                                                                                onHandleChange={filterChange('schoolYear')}
                                                                                refClassContainer=""
                                                                                name="schoolYear"
                                                                                isRequired={false}
                                                                                withLabel={false}
                                                                                label="School Year"
                                                                                placeHolder="Select School Year"
                                                                                forwardRef={() => { }}
                                                                                selectedId={filter.schoolYear}
                                                                                refClassName={`text-center`}
                                                                                withDescription={true}
                                                                                description={`School Year`}
                                                                                refDisabled={true}
                                                                                refIsStatic={false}
                                                                                refStaticData={[
                                                                                ]
                                                                                }
                                                                                field={{
                                                                                    tableId: 'tableId',
                                                                                    display: 'code'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Semester Dropdown */}
                                                                    <div className="form-row">
                                                                        <div className="form-group col">
                                                                            <IMSelect
                                                                                data={semesterSelect}
                                                                                onHandleChange={filterChange('semesterId')}
                                                                                refClassContainer=""
                                                                                name="semesterId"
                                                                                isRequired={false}
                                                                                withLabel={false}
                                                                                label="Semester"
                                                                                placeHolder="Select Semester"
                                                                                forwardRef={() => { }}
                                                                                selectedId={filter.semesterId}
                                                                                refClassName={`text-center`}
                                                                                withDescription={true}
                                                                                description={`Semester`}
                                                                                refDisabled={true}
                                                                                refIsStatic={false}
                                                                                refStaticData={[
                                                                                ]
                                                                                }
                                                                                field={{
                                                                                    tableId: 'tableId',
                                                                                    display: 'code'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Grading Period */}
                                                                    <div className="form-row">
                                                                        <div className="form-group col">
                                                                            <IMSelect
                                                                                data={gradingPeriodSelect}
                                                                                onHandleChange={filterChange('gradingPeriodId')}
                                                                                refClassContainer=""
                                                                                name="gradingPeriod"
                                                                                isRequired={false}
                                                                                withLabel={false}
                                                                                label="Grading Period"
                                                                                placeHolder="Select Grading Period"
                                                                                forwardRef={() => { }}
                                                                                selectedId={filter.gradingPeriodId}
                                                                                refClassName={`text-center`}
                                                                                withDescription={true}
                                                                                description={`Grading Period`}
                                                                                refDisabled={true}
                                                                                refIsStatic={false}
                                                                                refStaticData={[]}
                                                                                field={{
                                                                                    tableId: 'tableId',
                                                                                    display: 'code'
                                                                                }}
                                                                            />
                                                                        </div>
                                                                    </div>

                                                                    {/* Student Detail (Number, ID, Name) */}
                                                                    {auth.data.UserType != "S" ?
                                                                        <div className="form-row">
                                                                            <div className="form-group col">
                                                                                <Autocomplete
                                                                                    id="studentList"
                                                                                    options={studentList}
                                                                                    defaultValue={{ StudentId: defaultValues.studentId, StudentName: defaultValues.studentName }}
                                                                                    getOptionLabel={(option) => option.StudentName}
                                                                                    getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                                                                    onChange={(event, value) => {
                                                                                        if (value) {
                                                                                            // Handle the selected option
                                                                                            handleGetStudent(value)
                                                                                        } else {
                                                                                            // Handle the case when no option is selected
                                                                                            setStudentId(null);
                                                                                            setStudentList([]);
                                                                                            setStudent(null);
                                                                                            setResults([]);
                                                                                            setTotalUnits(0.00);
                                                                                            setTotalUnitsPassed(0);
                                                                                            setTotalUnitsFailed(0);
                                                                                            setWeightedAverage(0);
                                                                                            setTimeGenerated(null);
                                                                                            setClearance([]);
                                                                                            setOnAlert(false);
                                                                                            setStudentSelected((prevData) => {
                                                                                                return {
                                                                                                    ...prevData,
                                                                                                    studentId: null,
                                                                                                    studentName: '',
                                                                                                    fullName: '',
                                                                                                    course: ''
                                                                                                }
                                                                                            });
                                                                                        }
                                                                                    }}
                                                                                    renderInput={(params) => (
                                                                                        <TextField
                                                                                            {...params}
                                                                                            label="Student Number/Name"
                                                                                            variant="outlined"
                                                                                            inputRef={register}
                                                                                            name="studentList"
                                                                                            //value={{StudentId: studentSelected.studentId,  StudentName: studentSelected.studentName}}
                                                                                            onChange={
                                                                                                (e) => { handleStudentInputChange(e.target.value) }
                                                                                            }
                                                                                        />
                                                                                    )}
                                                                                />
                                                                            </div>
                                                                        </div> : <></>
                                                                    }

                                                                    {/* <div className="form-row bg-secondary"> */}
                                                                    <div className="form-row">

                                                                        <section className="col">
                                                                            {isFetchingGrade == false ?
                                                                                <button onClick={() => { onSearch() }} className='btn btn-success btn-block'>Search</button>
                                                                                :
                                                                                <button className='btn btn-secondary btn-block' type="button" disabled>
                                                                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                                                    &nbsp; Loading...
                                                                                </button>

                                                                            }

                                                                        </section>
                                                                        {/* {auth.data.UserType != "S" ? 
                                                                <section className="col"> 
                                                                    <PrintStudentGradeViewing results={[academicYearSelect, semesterString, timeGenerated, 
                                                                        student, results, gradingPeriodName, totalUnits, weightedAverage, data]}/>
                                                                </section> 
                                                            : <></> } */}
                                                                    </div>
                                                                </section>
                                                            </main>
                                                        </div>
                                                    </>
                                                }

                                                <div className="col-3">
                                                </div>
                                            </div>
                                            {/* <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                            <IMTableGradeViewing tableProps={tableProps} />
                                        </div> */}
                                        </div>
                                    </section>
                                </div>
                            </main>
                        </div>
                    </div>
                </>
                :
                <Loading />
            }
        </>
    );
}