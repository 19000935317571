import instance from "../../../../../apis/local/systemAPI";

export const getSemester = async () => {
    const result = await instance.get(`api/references/semesters/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get(`api/references/schoolyears/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getRoomFacilityReport = async (schoolYear, semester, roomId) => {
    const result = await instance.get(`api/registrar/roomFacility/getRoomFacilityReport/${schoolYear}/${semester}/${roomId}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAvailableRooms = async () => {
    const result = await instance.get(`api/registrar/roomFacility/getAvailableRooms`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}