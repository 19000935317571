import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import IMTable from '../table/IMTable';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ListOfStudentModal(props) {
    const { isOpen, handleClose, students } = props;


    const columns = [
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Student Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]

    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: students,
        totalRecords: students.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'instructorStudentList',
        parentId: null,
        tableTitle: 'List of Students',
    }

    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="sm"
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">List of Students</DialogTitle>
                <hr />



                <IMTable tableProps={tableProps} />


                <DialogActions>

                    <button onClick={handleClose} className="btn btn-secondary">
                        Back
                    </button>

                </DialogActions>
            </Dialog>
        </>
    );

}