import instance from '../../../../../apis/local/systemAPI';

// START OF CIVIL STATUS
export const getActiveMonthlyFamilyNetIncome = async () => {
  const result = await instance.get("api/references/family-net-income/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFamilyNetIncome = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/family-net-income/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "netIncome": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/family-net-income/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createFamilyNetIncome = async (formValues) => {
  const result = await instance.post("api/references/family-net-income", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateFamilyNetIncome = async (id, formValues) => {
  const result = await instance.patch(`api/references/family-net-income/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteFamilyNetIncome = async (id) => {
  const result = await instance.delete(`api/references/family-net-income/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF FAMILY NET INCOME