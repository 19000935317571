import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControlLabel, Switch } from '@material-ui/core';
import { SubjectSelect } from '../../../';
import IMSelect from '../../../../../../../_metronic/layout/components/custom/select/IMSelect';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    subjectId: null,
    courseOutlineType: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'subjectId' }, { required: true });
    register({ name: 'courseOutlineType' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { subjectId, courseOutlineType, isActive } = formValues;

    const convertedFormValues = {
      courseOutlineDetailId: props.courseOutlineDetailId,
      subjectId: subjectId,
      courseOutlineType: courseOutlineType,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <SubjectSelect
                refOnHandleChange={selectHandleChange('subjectId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.SubjectId === undefined ? "" : props.doc.SubjectId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.subjectId}
                refErrorMessage={errors.subjectId && errors.subjectId.type === "required" && 'Subject is required'}
              />
              <IMSelect
                onHandleChange={selectHandleChange('courseOutlineType')}
                refClassContainer="form-group"
                name="courseOutlineType"
                isRequired={true}
                withLabel={true}
                label="Course Outline Type"
                placeHolder="Course Outline Type"
                forwardRef={setValue}
                selectedId={data.courseOutlineType === null ? props.doc.CourseOutlineType : data.courseOutlineType}
                refClassName={``}
                withDescription={true}
                description={`Please enter your course outline type.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: '', label: '' },
                  { id: 'P', label: 'Prereq' },
                  { id: 'C', label: 'Coreq' },
                  { id: 'A', label: 'Alias' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
                error={!!errors.courseOutlineType}
                errorMessage={errors.courseOutlineType && errors.courseOutlineType.type === "required" && 'Course outline is required'}
              />
              <div className="form-group mt-5">
                <FormControlLabel
                  name="isActive"
                  inputRef={register()}
                  control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                  label="Active"
                />
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={`${referenceTypes.LIST_LINK}/${props.courseOutlineId}/${props.courseOutlineDetailId}`} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}