import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import {
    getSchoolYear, getSemester, searchStudent, studentAutoComplete
} from "./__hooks__"
import { TextField } from "@material-ui/core";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import history from '../../../../../app/history';
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from "lodash";



export default function EnrollmentDropping() {
    const { state: { auth }, dispatch } = useAppContext();
    const { setValue, handleSubmit, register } = useForm()


    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState()
    const [studentId, setStudentId] = useState(0)

    const [semSelect, setSemSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const [isLoading, setIsLoading] = useState(false)


    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)


    const [studentList, setStudentList] = useState([])



    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }


    const executeSearchStudent = async (val) => {
        setIsLoading(true)
        await searchStudent(val)
            .then(res => {
                setIsLoading(false)

                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found, Try Migrating Student In Campus'
                    });

                    setStudent(null)
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }

    const viewAssessment = () => {
        history.push(`/admin/student/create-assessment/${student.EnrollmentId}`)
    }



    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);
    }

    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }, 300);

    const handleGetStudent = async (data) => {
        setStudentNo(data.StudentNo)
        setStudentId(data.StudentId)
        console.log(data)
    }



    useEffect(() => {
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
    }, [])




    useEffect(() => {
        if (studentId !== undefined && studentId !== "" && studentId != 0) {
            setTimeout(() => {
                onSubmit({ studentId: studentId, semesterId: filteredSem, schoolYear: filteredSy })

            }, 500)

        }
    }, [studentId])

    useEffect(() => {
        if (student !== null) {
            let data = {
                studentId: studentId,
                semesterId: filteredSem,
                schoolYear: filteredSy
            }
            executeSearchStudent(data)
        }
    }, [filteredSem, filteredSy])




    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? activeSy : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    const onSubmit = (formValues) => {
        console.log(formValues)

        let data = {
            ...formValues,
            semesterId: filteredSem,
            schoolYear: filteredSy
        }
        executeSearchStudent(data)

    }



    const onCancelSearch = () => {
        setStudent(null)
        setStudentNo("")
        setStudentId(0)
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? activeSem : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    console.log(auth)
    console.log(student)


    console.log(sySelect)


    return (
        <>

            <div className="container">
                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>


                                <hr />


                            </div>


                        </div>
                        <div className="col-12 col-xl-8">

                            <div className="p-5 mb-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Search for a Student</h3>

                                <section className="p-5">
                                    <div className="bg-white mb-3 p-0">{imSelectDropdownSchoolYear()}</div>
                                    <div className="bg-white mb-3 p-0">{imSelectDropdownSemester()}</div>
                                    <form onSubmit={handleSubmit(onSubmit)} method="post">

                                        {/* <TextField
                                            id="outlined-basic"
                                            label="Student No"
                                            variant="outlined"
                                            name="studentNo"
                                            value={studentNo}
                                            inputRef={register({
                                                required: true
                                            })}
                                            className="bg-white"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                if (e.target.value.length === 11) {
                                                    setStudentNo(e.target.value)

                                                }
                                            }}
                                        /> */}

                                        <Autocomplete
                                            id="studentNo"
                                            options={studentList}
                                            getOptionLabel={(option) => option.StudentName}
                                            onChange={(event, value) => {
                                                if (value) {
                                                    handleGetStudent(value)
                                                    // Handle the selected option
                                                } else {
                                                    // Handle the case when no option is selected
                                                }
                                            }}
                                            renderInput={(params) => (
                                                <TextField
                                                    {...params}
                                                    label="Student Number"
                                                    variant="outlined"
                                                    inputRef={register}
                                                    name="studentNo"
                                                    value={studentNo}
                                                    onChange={
                                                        (e) => { handleStudentInputChange(e.target.value) }

                                                    }
                                                />
                                            )}
                                        />


                                    </form>
                                    {isLoading == false ?
                                        <>
                                            {student !== null ?
                                                <>
                                                    <div className="mt-5">
                                                        {student.EnrollmentId !== null ?

                                                            <Link to={{ pathname: `/admin/enrollment/enrollment-subject-dropping/${student.EnrollmentId}`, data: student }} className="btn btn-primary mr-5">
                                                                Enrollment Subjects Record
                                                            </Link>
                                                            :
                                                            <>
                                                                <div className="alert alert-warning" role="alert">
                                                                    No Enrollment Record Found
                                                                </div>

                                                            </>
                                                            //  <button onClick={() => { onStudentProgressHandler() }} type="button" className="mt-5 btn btn-success mr-5 ">Create Registration</button>

                                                        }
                                                        <button onClick={() => { onCancelSearch() }} type="button" className="btn btn-secondary ">Cancel</button>
                                                    </div>


                                                </>
                                                :
                                                ''

                                            }
                                        </>
                                        :
                                        <>
                                            <div className="spinner-border" role="status">
                                                <span className="sr-only">Loading...</span>
                                            </div>
                                        </>
                                    }

                                </section>



                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}