import { getPaymentMode } from "./__hooks__";
import {
    getAssessmentStudentSubjectScheduleDetail, getStudentAssessmentTemplateDetail, getAccountingStudentAccount,
    getStudentAssessmentOtherFees, getStudentDiscount, getStudentAstraSubjects, getAccountingStudentListOfAssessment,
    getStudentCampusOtherFee, getCampusBatchkey, getStudentAddOns, getStudentDiscountParticulars,
    getStudentAddDropSubjects, checkIsAddSubjectPaid, getPaymentSchedule, addUpdateEnrollmentDiscount,
    getStudentBackBalance
} from "./__hooks__/proc"
import {
    getYearLevel
} from "./__hooks__/index"
import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ReAssessment from "./Form.js";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import history from "../../../../history";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { useAppContext } from "../../../../contexts/useAppContext";
import { Today } from "@material-ui/icons";
export default function Assessment(props) {
    // Student Accounts
    const { id } = useParams()
    const { state: { auth } } = useAppContext()
    const [studentAccount, setStudentAccount] = useState(null)
    const [astraStudentEnrollmentSubjects, setAstraStudentEnrollmentSubjects] = useState(null)
    const [studentSubjectSchedules, setStudentSubjectSchedules] = useState(null)
    const [studentTotalUnits, setStudentTotalUnits] = useState(null)
    const [paymentSchedule, setPaymentSchedule] = useState(null)
    const [studentTotalAddedUnits, setStudentTotalAddedUnits] = useState(0)
    const [isValidated, setIsValidated] = useState(true)
    const [studentTotalLabModes, setStudentTotalLabModes] = useState(null)
    const [subjectCategoryIds, setSubjectCategoryIds] = useState(null)
    const [studentAssessmentFees, setStudentAssessmentFees] = useState(null)
    const [studentOtherFees, setStudentOtherFees] = useState(null)
    const [studentAddOns, setStudentAddOns] = useState(null)
    const [studentDiscount, setStudentDiscount] = useState(0)
    const [studentDiscountWithSequence, setStudentDiscountWithSequence] = useState(0)
    const [totalAssessmentFee, setTotalAssessmentFee] = useState(0)
    const [totalOtherFee, setTotalOtherFee] = useState(null)
    const [totalAddOns, setTotalAddOns] = useState(0)
    const [totalFee, setTotalFee] = useState(null)
    const [totalInterest, setTotalInterest] = useState(null)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalScholarshipDiscount, setTotalScholarshipDiscount] = useState(0)
    const [listOfAssessment, setListOfAssessment] = useState(null)
    const [studentDiscountParticulars, setStudentDiscountParticulars] = useState(null)

    const [studentAddDropSubjects, setStudentAddDropSubjects] = useState(null)
    const [studentAddDropSelectedSub, setStudentAddDropSelectedSub] = useState([])
    const [isAddDropValidated, setIsAddDropValidated] = useState(false)
    const [labSubjects, setLabSubjects] = useState([])
    const [discountSeq, setDiscountSeq] = useState({ seq1: 0, seq2: 0, seq3: 0, seq4: 0, seq5: 0 })
    const [totalFees, setTotalFees] = useState({ tf: 0, ms: 0, rg: 0, lb: 0 })
    const [studentYearLevel, setStudentYearLevel] = useState(null)
    const [totalPayment, setTotalPayment] = useState(0)
    const [studentTotalBackBalance, setStudentTotalBackBalance] = useState(0)
    const [isWithBalance, setIsWithBalance] = useState(false)

    const [isPaid, setIsPaid] = useState(true)
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    //Subject Schedules
    const [subjectId, setSubjectId] = useState(null)
    const [subjectCodes, setSubjectCodes] = useState(null)


    //Selects
    const [paymentModeSelect, setPaymentModeSelect] = useState(null)
    const [YearLevelSelect, setYearLevelSelect] = useState(null)

    //Assets
    const [studentBatchkey, setStudentBatchkey] = useState([])


    const getStudentAccount = async (val) => {
        await getAccountingStudentAccount(val)
            .then(res => {
                setStudentAccount(res.data)
            })
            .catch(e => {

            })
    }

    const getStudentAdds = async (val) => {
        await getStudentAddOns(val)
            .then(res => {
                console.log(res)
                setIsAddDropValidated(res.withAddDrop)
                setTotalAddOns(res.totalAmount)
                setStudentAddOns(res.data)
            })
    }




    const getStudentSubjectsScheduleDetails = async (val) => {
        await getAssessmentStudentSubjectScheduleDetail(val)
            .then(res => {
                console.log(res)
                console.log(res.data.data)
                setStudentSubjectSchedules(res.data.data)
                setLabSubjects(res.data.labSubjects)
                setStudentTotalUnits(res.data.totalUnits)
                setStudentTotalLabModes(res.data.labModes)
                setSubjectId(res.data.subjectIds)
                setSubjectCodes(res.data.subjectCodes)
                if (res.data.subjectCategoryIds.length > 0) {
                    setSubjectCategoryIds(res.data.subjectCategoryIds)

                } else {
                    setSubjectCategoryIds([1])
                }
            })
            .catch(e => {

            })
    }

    const getStudentCampusBatchId = async (val) => {
        await getCampusBatchkey(val)
            .then(res => {
                let arr = []
                for (let i = 0; i < res.data.length; i++) {
                    arr.push(res.data[i].Batchkey)
                }
                setStudentBatchkey(arr)
            })
            .catch(e => {

            })
    }

    const getStudentFees = async (val) => {
        await getStudentAssessmentTemplateDetail(val)
            .then(async res => {
                console.log(res)
                getStudentOtherFees({
                    assessmentTemplateId: res.data.data.length > 0 ? res.data.data[0].AssessmentTemplateId : 0,
                    yearLevelId: studentYearLevel,
                    courseId: studentAccount[0].CourseId,
                    instituteId: studentAccount[0].InstituteId,
                    isCampus: false,
                    isMale: studentAccount[0].Gender == 'M' ? true : false
                })
                setTotalFees({ tf: res.data.totalTuitionFee, rg: res.data.totalRegFee, lb: parseFloat(res.data.totalLabFee), ms: res.data.totalMiscFee })

                console.log(res)
                let data = res.data.data.map(d => {
                    console.log(d.FeeCode)
                    console.log(d)
                    console.log(res.data.totalLabFee)
                    if (d.FeeCode == 'TF' || d.FeeCode == 'TT') {
                        return { ...d, TotalGAmount: res.data.totalTuitionFee };
                    } else if (d.FeeCode == 'RG') {
                        return { ...d, TotalGAmount: res.data.totalRegFee + res.data.totalOtherFee };
                    } else if (d.FeeCode == 'MS') {
                        return { ...d, TotalGAmount: res.data.totalMiscFee };
                    } else if (d.FeeCode == 'LB') {
                        return { ...d, TotalGAmount: res.data.totalLabFee };
                    } else if (d.FeeCode == 'OLB') {
                        return { ...d, TotalGAmount: res.data.totalOtherLabFee };
                    }
                    return d;
                });
                console.log(data)
                setStudentAssessmentFees(data)
                if (res.data.totalFee > 0) {
                    console.log(res.data.totalFee)
                    setTotalAssessmentFee(res.data.totalFee)
                } else {
                    setTotalAssessmentFee(0)

                }

                setTotalInterest(res.data.totalInterest)
            })
            .catch(e => {

            })
    }

    const getStudentOtherFees = async (val) => {
        await getStudentAssessmentOtherFees(val)
            .then(res => {
                setStudentOtherFees(res.data.data)
                setTotalOtherFee(res.data.totalOtherFee)
            })
            .catch(e => {

            })
    }



    const getStudentDiscountOnAstra = async (val, interest) => {
        await getStudentDiscount(val)
            .then(async res => {
                console.log(res)

                // setTotalDiscount(res.totalDiscount)
                setStudentDiscount(res.data)
            })
            .catch(e => {

            })
    }

    const getStudentSubjects = async (val) => {
        await getStudentAstraSubjects(val)
            .then(res => {
                console.log(res.data)
                let arr = []
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].SubjectStatusId !== 2 && res.data[i].SubjectStatusId !== 5)
                            arr.push(res.data[i].DetailId)
                    }


                }
                console.log(arr)
                setAstraStudentEnrollmentSubjects(arr)
            })
            .catch(e => {

            })
    }


    const getStudentAssessmentList = async (val) => {
        await getAccountingStudentListOfAssessment(val)
            .then(res => {
                setListOfAssessment(res.data)
                getStudentFees({
                    assessmentTemplateId: res.data.length > 0 ? res.data[0].AssessmentTemplateId : 0,
                    discountRate: studentAccount[0].ApplyRate !== null ? studentAccount[0].ApplyRate : 0.00,
                    totalUnits: studentTotalUnits,
                    discountApplyTo: studentAccount[0].ApplyTo !== null ? studentAccount[0].ApplyTo : 'NA',
                    labMode: studentTotalLabModes,
                    subjectCategories: subjectCategoryIds.toString(),
                    isSyncToCampus: false,
                    labSubjects: labSubjects,
                    studentSchoolYear: studentAccount[0].StudentBatchYear
                })


            })
            .catch(e => {

            })
    }

    const getCampusOtherFee = async (val) => {
        await getStudentCampusOtherFee(val)
            .then(res => {

                console.log(res)
                setStudentOtherFees(res)
                let totalOther = 0.00
                for (let i = 0; i < res.length; i++) {
                    totalOther += parseFloat(res[i].Fee)
                }

                setTotalOtherFee(totalOther)


            })
            .catch(e => {

            })
    }

    const onChangePaymentMode = (data) => {
        getStudentAssessmentList({
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,
            paymentModeId: data,
            instituteId: studentAccount[0].InstituteId,
            yearLevelId: studentYearLevel,
            isIrregular: studentAccount[0].IsIrregular == 1 ? 1 : 0

        })
    }


    const onChangeStudentYearLevel = (data) => {
        setStudentYearLevel(data)
        getStudentAssessmentList({
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,
            paymentModeId: 1,
            instituteId: studentAccount[0].InstituteId,
            yearLevelId: data,
            isIrregular: studentAccount[0].IsIrregular == 1 ? 1 : 0

        })
    }

    const getStudentSubjectAddDrop = async (val) => {
        await getStudentAddDropSubjects(val)
            .then(res => {
                console.log(res)
                let arr = []
                let totalUnits = 0
                let validated = true
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].SubjectStatusId !== 1 && res.data[i].EnrollmentStatus == 0) {
                            arr.push(res.data[i])
                            if (res.data[i].SubjectStatusId == 3)
                                totalUnits += parseInt(res.data[i].TotalUnit)

                            if (res.data[i].IsValidated == 0)
                                validated = false

                        }
                    }
                    console.log(arr)
                    console.log(totalUnits)
                    setStudentAddDropSubjects(arr)
                    setIsValidated(validated)
                    setStudentAddDropSelectedSub(res.selectedSub)
                    setStudentTotalAddedUnits(totalUnits)
                }

            })
            .catch(e => {

            })
    }


    const checkAddSubjectPayment = async (val) => {
        await checkIsAddSubjectPaid(val)
            .then(res => {
                console.log(res)
                setIsPaid(res.isPaid)
            })
            .catch(e => {

            })
    }

    useEffect(() => {
        SelectCreator(getPaymentMode, setPaymentModeSelect, 'PaymentModeId', 'PaymentMode')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')

        getStudentAccount({ enrollmentId: parseInt(id) })

    }, [])

    useEffect(() => {
        if (!studentAccount)
            return
        console.log(astraStudentEnrollmentSubjects)
        setStudentYearLevel(studentAccount[0].YearLevelId)

        // getStudentOtherFees({
        //     assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
        //     yearLevelId: studentAccount[0].YearLevelId,
        //     courseId: studentAccount[0].CourseId,
        //     instituteId: studentAccount[0].InstituteId,
        //     isCampus: false
        // })


        getStudentSubjects({
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear
        })

        getStudentBackBalance({
            schoolYear: studentAccount[0].LastEnrolledSchoolYear,
            semesterId: studentAccount[0].LastEnrolledSemesterId,
            studentNo: studentAccount[0].StudentNo,
            enrollmentStatusId: 'E'
        }).then(res => {
            if (res.data.TotalAssessmentFee > 0) {
                setStudentTotalBackBalance(parseFloat(res.data.TotalAssessmentFee).toFixed(2))
                setIsWithBalance(true)

            }

        })

        getStudentCampusBatchId({
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            studentNo: studentAccount[0].StudentNo
        })


        getStudentAdds({
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            yearLevelId: studentAccount[0].YearLevelId,
            courseId: studentAccount[0].CourseId
        })

        checkAddSubjectPayment({
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear
        })

        if (studentAccount[0].EnrollmentStatusId == 'E' || studentAccount[0].EnrollmentStatusId == 'A')
            getStudentSubjectAddDrop({
                studentId: studentAccount[0].StudentId,
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId,
                subjectScheduleDetailId: null,
                createdBy: auth.data.Username,
                date: today

            })


    }, [studentAccount])


    useEffect(() => {


        if (studentSubjectSchedules == null || studentAccount == null || studentTotalUnits == null || studentTotalLabModes == null || subjectCategoryIds == null)
            return

        console.log(studentAccount[0])
        getStudentFees({
            assessmentTemplateId: studentAccount[0].EnrollmentStatusId !== 'PV' && studentAccount[0].EnrollmentStatusId !== 'E' && studentAccount[0].EnrollmentStatusId !== 'A' ? 0 : studentAccount[0].AssessmentTemplateId,
            discountRate: studentAccount[0].ApplyRate !== null ? studentAccount[0].ApplyRate : 0.00,
            totalUnits: studentTotalUnits,
            discountApplyTo: studentAccount[0].ApplyTo !== null ? studentAccount[0].ApplyTo : 'NA',
            labMode: studentTotalLabModes,
            subjectCategories: subjectCategoryIds.toString(),
            isSyncToCampus: false,
            labSubjects: labSubjects,
            studentSchoolYear: studentAccount[0].StudentBatchYear

        })


    }, [studentSubjectSchedules, studentAccount, studentTotalUnits, studentTotalLabModes, subjectCategoryIds])

    useEffect(() => {

        console.log(totalAssessmentFee)
        console.log(totalOtherFee)
        console.log(totalAddOns)

        setTotalFee(parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseInt(totalAddOns))


    }, [totalAssessmentFee, totalOtherFee, totalAddOns])


    useEffect(() => {
        if (!astraStudentEnrollmentSubjects)
            return
        console.log(astraStudentEnrollmentSubjects)
        getStudentSubjectsScheduleDetails({
            classSectionId: studentAccount[0].IsCampus == 1 ? 0 : studentAccount[0].ClassSectionId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            isCampus: studentAccount[0].IsCampus == 1 ? true : false,
            batchId: studentBatchkey.toString(),
            isExist: astraStudentEnrollmentSubjects !== null && studentAccount[0].EnrollmentStatusId !== 'DR' ? true : false,
            subjectScheduleDetailId: astraStudentEnrollmentSubjects.toString(),
            studentId: studentAccount[0].StudentId
        })
    }, [astraStudentEnrollmentSubjects])





    useEffect(() => {
        if (studentAssessmentFees !== null && studentAssessmentFees.length > 0 && studentAssessmentFees !== undefined
            && totalOtherFee !== null && totalOtherFee !== undefined) {
            let tuitionFee = studentAssessmentFees.find(data => data.FeeCode == 'TF') || { TotalAmount: 0 }
            let registrationFee = studentAssessmentFees.find(data => data.FeeCode == 'RG') || { TotalAmount: 0 }
            let miscFee = studentAssessmentFees.find(data => data.FeeCode == 'MS') || { TotalAmount: 0 }
            let labFee = studentAssessmentFees.find(data => data.FeeCode == 'LB') || { TotalAmount: 0 }
            let otherFee = studentAssessmentFees.find(data => data.FeeCode == 'OT') || { TotalAmount: 0 }


            console.log(tuitionFee.TotalAmount)
            console.log(registrationFee.TotalAmount)
            console.log(miscFee.TotalAmount)
            console.log(labFee.TotalAmount)
            console.log(totalOtherFee)
            console.log(studentAssessmentFees)
            console.log(totalInterest)
            getStudentDiscountOnAstra({
                studentId: studentAccount[0].StudentId,
                assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId.toString(),
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId,
                tuitionFee: tuitionFee.TotalAmount,
                regFee: registrationFee.TotalAmount,
                miscFee: miscFee.TotalAmount,
                labFee: labFee.TotalAmount,
                otherFee: totalOtherFee


            }, totalInterest)




        }
    }, [studentAssessmentFees, totalOtherFee, totalInterest])
    // with no squence
    // useEffect(() => {
    //     if (studentDiscount !== undefined && studentDiscount !== null && studentDiscount.length > 0) {
    //         console.log(studentDiscount)
    //         let tuitionFee = studentAssessmentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
    //         let registrationFee = studentAssessmentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
    //         let miscFee = studentAssessmentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
    //         let labFee = studentAssessmentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
    //         let otherFee = studentAssessmentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
    //         const getParticulars = async () => {
    //             let data = []
    //             for (let i = 0; i < studentDiscount.length; i++) {
    //                 await getStudentDiscountParticulars({
    //                     studentId: studentAccount[0].StudentId,
    //                     assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId.toString(),
    //                     tuitionFee: tuitionFee.TotalAmount,
    //                     regFee: registrationFee.TotalAmount,
    //                     miscFee: miscFee.TotalAmount,
    //                     labFee: labFee.TotalAmount,
    //                     otherFee: otherFee.TotalAmount,
    //                     scholarshipId: studentDiscount[i].ScholarshipId
    //                 })
    //                     .then(res => {
    //                         data.push(res.data[0])
    //                     })
    //             }
    //             setStudentDiscountParticulars(data)
    //             console.log(data)
    //         }

    //         getParticulars()


    //     }
    // }, [studentDiscount])

    // with  squence

    useEffect(() => {
        console.log(studentAssessmentFees)
        if (studentDiscount !== 0 && studentDiscount.length > 0) {
            let tuitionFee = studentAssessmentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
            let registrationFee = studentAssessmentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
            let miscFee = studentAssessmentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
            let labFee = studentAssessmentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
            let otherFee = studentAssessmentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
            let otherLabFee = studentAssessmentFees.find(data => data.FeeCode === 'OLB') || { TotalAmount: 0 };

            const getStudentDiscountWithParticulars = async (val) => {
                const res = await getStudentDiscountParticulars(val);
                setTotalDiscount(res.totalDiscount)
                return res;
            };
            console.log(labFee)
            const updateFees = async () => {
                let arr = [];
                let totalDiscount = 0.00
                let scholarshipDiscount = 0.00
                for (let i = 0; i < studentDiscount.length; i++) {
                    const res = await getStudentDiscountWithParticulars({
                        studentId: studentAccount[0].StudentId,
                        assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId.toString(),
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: parseFloat(labFee.TotalAmount),
                        otherFee: totalOtherFee,
                        otherLabFee: otherLabFee.TotalGAmount,
                        scholarshipId: studentDiscount[i].ScholarshipId
                    });
                    if (studentDiscount[i].ScholarshipId != 6383)
                        scholarshipDiscount += parseFloat(res.totalDiscount)
                    console.log(res.data)
                    tuitionFee.TotalGAmount = res.data[0].RemainingTuition;
                    registrationFee.TotalGAmount = res.data[0].RemainingReg;
                    miscFee.TotalGAmount = res.data[0].RemainingMisc;
                    labFee.TotalAmount = res.data[0].RemainingLab;
                    console.log(res.data[0]);
                    arr.push({ ...res.data[0], enrollmentId: parseInt(id), enrollmentDiscountId: 0, createdBy: auth.data.Username });
                    totalDiscount += parseFloat(res.totalDiscount)
                }

                setStudentDiscountWithSequence(arr);
                setTotalDiscount(parseFloat(totalDiscount))
                setTotalScholarshipDiscount(parseFloat(scholarshipDiscount))



                await getPaymentSchedule({
                    assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId,
                    tuitionFee: totalFees.tf,
                    regFee: totalFees.rg,
                    miscFee: totalFees.ms,
                    labFee: totalFees.lb,
                    discount: totalAddOns > 0 ? totalDiscount : totalDiscount,
                    interest: totalInterest,
                    studentId: studentAccount[0].StudentId,
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId
                }).then(ps => {
                    if (ps.data !== undefined)
                        setTotalPayment(parseFloat(ps.TotalPayment))
                    setPaymentSchedule(ps.data)
                })

            };



            updateFees();
        } else {
            if (studentAssessmentFees != null)
                getPaymentSchedule({
                    assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId,
                    tuitionFee: totalFees.tf,
                    regFee: totalFees.rg,
                    miscFee: totalFees.ms,
                    labFee: totalFees.lb,
                    discount: totalAddOns > 0 ? totalDiscount : totalDiscount,
                    interest: totalInterest,
                    studentId: studentAccount[0].StudentId,
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId
                }).then(ps => {
                    if (ps.data !== undefined)
                        setPaymentSchedule(ps.data)
                })
            setStudentDiscountWithSequence([])
        }
    }, [studentDiscount]);


    console.log(studentAccount)
    console.log(studentSubjectSchedules)
    console.log(paymentModeSelect)
    console.log(studentTotalUnits)
    console.log(studentTotalLabModes)
    console.log(studentAssessmentFees)
    console.log(subjectCategoryIds)
    console.log(studentOtherFees)




    console.log(totalFee)
    console.log(totalInterest)
    console.log(subjectId)
    console.log(subjectCodes)
    console.log(astraStudentEnrollmentSubjects)
    console.log(studentSubjectSchedules)
    console.log(totalOtherFee)

    console.log(studentDiscount)


    console.log(studentAddOns)
    console.log(totalDiscount)


    console.log(studentDiscountWithSequence)


    console.log(studentAddDropSubjects)
    console.log(studentAddDropSelectedSub)


    console.log(isPaid)
    console.log(paymentSchedule)
    console.log(listOfAssessment)
    console.log(YearLevelSelect)


    console.log(totalFees)
    console.log(studentTotalBackBalance)
    return (
        <>
            {
                totalFee !== null && totalFee !== NaN && totalInterest !== null
                    && subjectId !== null && subjectCodes !== null && studentSubjectSchedules !== null &&
                    studentSubjectSchedules.length > 0 && totalOtherFee !== null ?

                    <ReAssessment
                        studentAccount={studentAccount[0]}
                        studentSubjects={studentSubjectSchedules}
                        paymentModeSelect={paymentModeSelect}
                        studentFees={studentAssessmentFees}
                        studentOtherFees={studentOtherFees}
                        totalFee={totalFee}
                        totalOtherFee={totalOtherFee}
                        totalInterest={totalInterest}
                        studentDiscount={studentDiscountWithSequence}
                        subjectId={subjectId}
                        subjectCode={subjectCodes}
                        onChangePaymentMode={onChangePaymentMode}
                        onChangeStudentYearLevel={onChangeStudentYearLevel}
                        assessment={listOfAssessment}
                        studentAddOns={studentAddOns}
                        totalAddOns={totalAddOns}
                        totalDiscount={totalDiscount}
                        discountSeq={discountSeq}
                        studentDiscountParticulars={studentDiscountParticulars}
                        studentAddDropSubjects={studentAddDropSubjects}
                        studentAddDropSelectedSub={studentAddDropSelectedSub}
                        isAddDropValidated={isAddDropValidated}
                        isValidated={isValidated}
                        isPaid={isPaid}
                        paymentSchedule={paymentSchedule}
                        totalAssessmentFee={totalAssessmentFee}
                        studentYearLevel={studentYearLevel}
                        yearLevelSelect={YearLevelSelect}
                        totalPayment={totalPayment}
                        totalFees={totalFees}
                        studentTotalUnits={studentTotalUnits}
                        studentTotalBackBalance={studentTotalBackBalance}
                        isWithBalance={isWithBalance}
                        totalScholarshipDiscount={totalScholarshipDiscount}
                    />

                    :
                    // <h1>Wow Mali</h1>
                    <Loading />
            }

        </>




    )
}