import instance from '../../../../../apis/local/systemAPI';

// START OF CLASS SECTIONS
export const getActiveClassSections = async () => {
  const result = await instance.get("api/references/class-sections/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getActiveClassSectionByStudentCategoryIdAndInstituteId = async (studentCategoryId, instituteId) => {
  const params = {
    studentCategoryId: studentCategoryId,
    instituteId: instituteId,
    isActive: 1
  }
  const result = await instance.post("api/references/class-sections/q", params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getClassSections = async (page, rowsPerPage, keyword, courseId, instituteId) => {
  var result;
  if (keyword === '' && courseId == null && instituteId == null) {
    result = await instance.get(`api/references/class-sections/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Description": keyword,
            "InstituteId": instituteId,
            "CourseId": courseId
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/class-sections/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addUpdateClassSection = async (formValues) => {
  const result = await instance.post("api/references/class-sections/addUpdate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getClass = async (formValues) => {
  const result = await instance.post("api/references/class-sections/getClassSection", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateClassSection = async (id, formValues) => {
  const result = await instance.patch(`api/references/class-sections/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteClassSection = async (id) => {
  const result = await instance.delete(`api/references/class-sections/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitute = async () => {
  const result = await instance.get(`api/references/institutes/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getCourse = async () => {
  const result = await instance.get(`api/references/courses/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseFilter = async (con) => {
  const result = await instance.post(`api/references/courses/q`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getYearLevel = async () => {
  const result = await instance.get(`api/references/yearlevels/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getTest = async () => {
  const result = await instance.get(`api/registrar/facultyLoading/facultyReport`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF CLASS SECTIONS