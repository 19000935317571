import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSemester, getSchoolYear, getUser, getFacultyLoadingReport, getFacultyLoadingTableReport,
    getFacultyLoadingReportBySection, getFacultyLoadingReportByRoom, getFacultyReportInstructors,
    getFacultyReportInstructorsCheck
} from "./__hooks__";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import PrintFacultyLoadingReport from "./PrintFacultyLoadingReport";
import PrintFacultyLoadingReportBySection from "./PrintFacultyLoadingReportBySection";
import PrintFacultyLoadingReportByRoom from "./PrintFacultyLoadingReportByRoom";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";
import { useAppContext } from "../../../../contexts/useAppContext";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

export default function FacultyLoadingReport(props) {
    const { setValue } = useForm()
    const { state: { auth } } = useAppContext()
    const [results, setResults] = useState([])
    const [resultBySection, setResultBySection] = useState([])
    const [resultByRoom, setResultByRoom] = useState([])
    const [tableResults, setTableResults] = useState([])
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [userSelect, setUserSelect] = useState(null)
    const [instructorSelect, setInstructorSelect] = useState([{ tableId: 0, code: '' }])
    const [hasDisplay, setHasDisplay] = useState(null)


    const [filteredSem, setFilteredSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [filteredSy, setFilteredSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)
    const [filteredUser, setFilteredUser] = useState(null)
    const [filteredEmployee, setFilteredEmployee] = useState(null)

    const [onView, setOnView] = useState(false)


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectUserChange = name => event => { setFilteredUser(event) }
    const selectEmployeeChange = name => event => { setFilteredEmployee(event) }


    const [isLoading, setIsLoading] = useState(false)


    // functions

    const onEmployeeChecker = async () => {
        await getFacultyReportInstructorsCheck({
            userId: auth.data.UserId,
            schoolYear: filteredSy,
            semesterId: filteredSem,
        })
            .then(res => {
                setHasDisplay(res.data[0].DropdownDisplay);
                if (res.data[0].DropdownDisplay == 0) {
                    setFilteredEmployee(res.data[0].EmployeeId);
                }
            })
    }

    const onGetFacultyReport = async () => {
        setIsLoading(true)
        setOnView(true)
        setTableResults([])
        await getFacultyLoadingTableReport({
            schoolYear: filteredSy,
            semesterId: filteredSem,
            employeeId: filteredUser,
            withSchoolYear: filteredSy != null ? true : false,
            withSemesterId: filteredSem != null ? true : false,
            withEmployeeId: filteredUser != null ? true : false,
            userId: auth.data.UserId
        })
            .then(res => {
                setTableResults(res.data)
            })

        await getFacultyLoadingReport({
            schoolYear: filteredSy,
            semesterId: filteredSem,
            employeeId: filteredEmployee,
            withSchoolYear: filteredSy != null ? true : false,
            withSemesterId: filteredSem != null ? true : false,
            withEmployeeId: filteredEmployee != null ? true : false,
            userId: auth.data.UserId
        })
            .then(res => {
                setResults(res.data)

            })

        await getFacultyLoadingReportBySection({
            schoolYear: filteredSy,
            semesterId: filteredSem,
            employeeId: filteredUser,
            withSchoolYear: filteredSy != null ? true : false,
            withSemesterId: filteredSem != null ? true : false,
            withEmployeeId: filteredUser != null ? true : false,
            userId: auth.data.UserId
        })
            .then(res => {
                setResultBySection(res.data)

            })


        await getFacultyLoadingReportByRoom({
            schoolYear: filteredSy,
            semesterId: filteredSem
        })
            .then(res => {
                setResultByRoom(res.data)
            })



        setIsLoading(false)

    }


    const onReset = () => {
        setResults([])
        setResultBySection([])
        setResultByRoom([])
        setTableResults([])
        setFilteredSem(auth.data.schoolyears.enrollment.SemesterId)
        setFilteredSy(auth.data.schoolyears.enrollment.SchoolYear)
        setFilteredUser(null)
        if (instructorSelect !== null) {
            setFilteredEmployee(instructorSelect[0].tableId);
        }
        setOnView(false)
    }

    useEffect(() => {
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getUser, setUserSelect, 'EmployeeId', 'FullName')
        //SelectCreator(getFacultyReportInstructors, setInstructorSelect, 'EmployeeId', 'DropdownOption')

        onEmployeeChecker();
    }, [])

    useEffect(() => {
        let values = {
            userId: auth.data.UserId,
            schoolYear: filteredSy,
            semesterId: filteredSem
        }

        SelectCreator(getFacultyReportInstructors, setInstructorSelect, 'EmployeeId', 'DropdownOption', values);
    }, [filteredSy, filteredSem])

    useEffect(() => {
        if (instructorSelect !== null) {
            setFilteredEmployee(instructorSelect[0].tableId);
        }
    }, [instructorSelect])

    useEffect(() => {
        if (filteredEmployee == '') {
            setFilteredEmployee(instructorSelect[0].tableId);
        }
    }, [filteredEmployee])


    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect !== null &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? filteredSy === undefined : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemesters = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? filteredSem === undefined : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownFaculty = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={instructorSelect}
                        onHandleChange={selectEmployeeChange()}
                        refClassContainer=""
                        name="faculty"
                        isRequired={false}
                        withLabel={false}
                        label="Faculty"
                        placeHolder="Select Employee"
                        forwardRef={setValue}
                        selectedId={filteredEmployee === undefined ? filteredEmployee === undefined : filteredEmployee}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const columns = [
        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Faculty', label: 'Faculty', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectDesc', label: 'Subject', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TimeStart', label: 'Time Start', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TimeEnd', label: 'Time End', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', label: 'Day Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    ]

    const tableProps = {
        recordId: 'SubjectScheduleDetailId',
        sortField: '',
        columns: columns,
        rows: tableResults,
        totalRecords: tableResults.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'facultyLoadingReport',
        parentId: null,
        tableTitle: 'List of Faculty Subjects',

    }



    //console.log(results)
    //console.log(resultByRoom)
    //console.log(auth.data)
    //console.log("instructorSelect: ", instructorSelect);
    //console.log("filteredEmployee: ", filteredEmployee);
    //console.log("hasDisplay: ", hasDisplay);

    console.log(semesterSelect)
    console.log(schoolYearSelect)
    console.log(userSelect)
    return (
        <>
            {semesterSelect && schoolYearSelect && userSelect ?

                <>
                    <main className="card">
                        <header className="card-header">
                            <h4 className="d-block">Faculty Loading Report</h4>
                            <div className="row">
                                <section className="col">{imSelectDropdownSchoolYear()}</section>
                                <section className="col">{imSelectDropdownSemesters()}</section>
                                {hasDisplay != 0 ?
                                    <>
                                        <section className="col">{imSelectDropdownFaculty()}</section>
                                    </>
                                    :
                                    ''
                                }

                                {isLoading == false ?
                                    <>
                                        {onView == false &&
                                            <button onClick={() => { onGetFacultyReport() }} className="col btn btn-primary">View</button>
                                        }
                                    </>
                                    :
                                    ''
                                }
                                {results.length > 0 && isLoading == false ?
                                    <>
                                        <PrintFacultyLoadingReport
                                            results={results}
                                        />
                                    </>
                                    :
                                    <>
                                        {onView == true && isLoading == true &&
                                            <button className="btn btn-secondary mx-3 btn-sm">
                                                Please wait ...

                                                <div className="spinner-border mx-5" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </button>

                                        }
                                    </>

                                }

                                {/* {resultBySection.length > 0 && isLoading == false ?

                                    <PrintFacultyLoadingReportBySection
                                        results={resultBySection}

                                    />
                                    :

                                    <>
                                        {onView == true && isLoading == true &&
                                            <button className="btn btn-secondary mx-3 btn-sm">
                                                Please wait ...

                                                <div className="spinner-border mx-5" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </button>
                                        }
                                    </>

                                } */}

                                {/* {resultByRoom.length > 0 && isLoading == false ?

                                    <PrintFacultyLoadingReportByRoom
                                        results={resultByRoom}
                                    />
                                    :
                                    <>
                                        {onView == true && isLoading == true &&
                                            <button className="btn btn-secondary mx-3 btn-sm">
                                                Please wait ...
                                                <div className="spinner-border mx-5" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </button>
                                        }
                                    </>
                                } */}

                                {onView == true ?
                                    <button onClick={() => { onReset() }} className="ml-5 btn btn-secondary">Reset</button>
                                    :
                                    ''
                                }

                            </div>
                        </header>
                        <section className="card-body">


                            {/* <IMTable tableProps={tableProps} /> */}

                        </section>

                    </main>

                </>

                :

                <Loading />
            }

        </>
    )
}