import instance from '../../../../../apis/local/systemAPI';

// START OF PAYMENT SCHEDULE
export const getActivePaymentSchedules = async () => {
  const result = await instance.get("api/references/payment-schedules/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getPaymentSchedules = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/payment-schedules/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "paymentSchedule": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/payment-schedules/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createPaymentSchedule = async (formValues) => {
  const result = await instance.post("api/references/payment-schedules", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updatePaymentSchedule = async (id, formValues) => {
  const result = await instance.patch(`api/references/payment-schedules/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deletePaymentSchedule = async (id) => {
  const result = await instance.delete(`api/references/payment-schedules/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF PAYMENT SCHEDULE