import instance from '../../../../../apis/local/systemAPI';


export const getInstructionalGuide = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/grades/instructional-guide/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "S.SubjectCode": keyword,
                        "S.Description": keyword,
                        "EI.LastName": keyword,
                        "EI.FirstName": keyword,
                        "CS.Description": keyword,

                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/grades/instructional-guide/q`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createInstructionalGuide = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateInstructionalGuide = async (id, formValues) => {
    const result = await instance.patch(`api/grades/instructional-guide/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteInstructionalGuide = async (id) => {
    const result = await instance.delete(`api/grades/instructional-guide/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSubjects = async () => {
    const result = await instance.get(`api/references/subjects/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEmployees = async () => {
    const result = await instance.get(`api/employees/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSchoolYear = async () => {
    const result = await instance.get(`api/references/schoolyears/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradeTemplate = async () => {
    const result = await instance.get(`api/grades/grade-templates/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getInstructionalGuideQuery = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/f`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradingPeriod = async () => {
    const result = await instance.get(`api/references/grading-period/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentInstructors = async (val) => {
    const result = await instance.post(`api/student/account/instructors`, val);
    let instructorsId = []
    if (result.status === 200 || result.status === 201) {
        if (result.data.data.length > 0) {
            for (let i = 0; i < result.data.data.length; i++) {
                instructorsId.push(result.data.data[i].UserId)
            }
        }
        return instructorsId;
    }
}

export const getStudentGrades = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/gradeViewing`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDetails = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/gradePrintingDetails`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/student/student-grade-viewing/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getControlNumber = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/controlNumber`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClearanceStatus = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/checkClearance`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getReprintStatus = async (json) => {
    const result = await instance.post("api/student/student-grade-viewing/checkReprint", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getComponents = async (json) => {
    const result = await instance.post("api/student/student-grade-viewing/getComponents", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradingPeriods = async () => {
    const result = await instance.post("api/student/student-grade-viewing/getGradingPeriods", );
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getWeightedAverage = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getWeightedAverage`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getNumberOfUnits = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getNumberOfUnits`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getNumberOfUnitsPassed = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getNumberOfUnitsPassed`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getNumberOfUnitsFailed = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getNumberOfUnitsFailed`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getNumberOfUnitsDropped= async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getNumberOfUnitsDropped`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const hasPrintAccess= async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/hasPrintAccess`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
