import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import PeriodicalGradeCompositionList from './PeriodicalGradeComposition/List';
import FinalGradeCompositionList from './FinalGradeComposition/List'
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    chip: {
        margin: theme.spacing(1),
    },
}));

export default function Container(props) {
    const classes = useStyles();
    const { id } = useParams();
    const [expanded, setExpanded] = useState({
        periodicalGradeCompositionPanel: false,
        finalGradeCompositionPanel: false
    });

    //   if (assessmentTemplates.data.length === 0) {
    //     history.push(enrollmentTypes.LIST_LINK_TO_PARENT);
    //     return null;
    //   }
    //   const assessmentTemplate = assessmentTemplates.data[id];
    //   const { ReferenceName, SchoolYear, SemesterId, StudentCategory, Institute, Course, YearLevel } = assessmentTemplate;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };

    return (
        <>

            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header" style={{ paddingLeft: "15px" }}>
                    <div className="card-title ">
                        <h4 className='lead '>Grade Computation Template</h4>
                    </div>
                </div>
                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                    <div className={classes.root}>
                        {/* <Accordion expanded={expanded.periodicalGradeCompositionPanel} onChange={handleChange('periodicalGradeCompositionPanel')} >
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1bh-content"
                                id="panel1bh-header"
                                style={{ background: "#efefef" }}
                            >
                                <Typography className={classes.heading}><span className="text-primary lead"><b>Periodical Grade Composition</b></span></Typography>
                                <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", padding: "0" }}>
                                <PeriodicalGradeCompositionList id={parseInt(id)} />
                            </AccordionDetails>
                        </Accordion> */}
                        <Accordion expanded={expanded.finalGradeCompositionPanel} onChange={handleChange('finalGradeCompositionPanel')}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel2bh-content"
                                id="panel2bh-header"
                                style={{ background: "#efefef" }}
                            >
                                <Typography className={classes.heading}><span className="text-primary lead"><b>Final Grade Composition</b></span></Typography>
                                <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ flexDirection: "column", padding: "0" }}>
                                <FinalGradeCompositionList id={parseInt(id)} />
                            </AccordionDetails>
                        </Accordion>

                    </div>
                </div>
                <div className="card-footer" style={{ padding: '0px', margin: '0px' }}>
                    <Link to={referenceTypes.LIST_LINK_TO_PARENT} className="btn btn-secondary m-3">Back</Link>
                </div>
            </div>
        </>
    );
}