import React, { useState, useEffect } from 'react'
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable'
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import { getStudentMasterFile } from "./__hooks__"
export default function StudentMasterFile(props) {
    const { register, handleSubmit, setValue, error } = useForm()

    const [results, setResults] = useState([])
    const [totalRecords, setTotalRecord] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(null);
    const [onSearch, setOnSearch] = useState(false);
    const [switchState, setSwitchState] = useState({
        IsActive: false
    });


    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };



    useEffect(() => {
        getStudentMasterFile({
            rowsPerPage: rowsPerPage,
            page: page,
            keyword: keyword != null ? keyword.trim() : null,
            isForImageApproval: switchState.IsActive == true ? 1 : 0

        }).then(res => {
            setResults(res.data)

            if (res.data.length > 0) {

                setTotalRecord(res.data[0].TotalRecord)
            }
        })
    }, [])
    useEffect(() => {
        getStudentMasterFile({
            rowsPerPage: rowsPerPage,
            page: page,
            keyword: keyword != null ? keyword.trim() : null,
            isForImageApproval: switchState.IsActive == true ? 1 : 0

        }).then(res => {
            setResults(res.data)

            if (res.data.length > 0) {

                if (keyword != null) {
                    setTotalRecord(res.data[0].PaginationCountRecord)
                } else {
                    setTotalRecord(res.data[0].TotalRecord)

                }
            }
        })
    }, [page, keyword, rowsPerPage, switchState.IsActive])


    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]


    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'studentMasterFile',
        parentId: null,
        tableTitle: 'List of Students',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: 0,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch
    }

    console.log(onSearch)
    console.log(page)
    console.log(rowsPerPage)
    return (
        <>
            <div className="card card-custom gutter-b example example-compact mx-auto" >
                <div className="mt-2" style={{ padding: '0px', margin: '0px' }}>
                    <FormControl component="fieldset" className="ml-5">
                        <FormControlLabel
                            name="isActive"
                            inputRef={register()}
                            control={<Switch checked={switchState.IsActive}
                                onChange={switchHandleChange('IsActive')}
                                value={switchState.IsActive} />}
                            label="For Image/Signature Approval"

                        />
                    </FormControl>
                    <IMTable tableProps={tableProps} />
                </div>
            </div>

        </>
    )
}