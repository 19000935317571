import instance from '../../../apis/local/systemAPI';
import { authTypes } from './types';

// START OF AUTH

export const verifyUserByEmailAddress = async emailAddress => {
    let params = {
        key: authTypes.VERIFY_USER_BY_EMAIL_ADDRESS,
        emailAddress: emailAddress
    };

    const result = await instance.post("/im-user-request", params);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const loginWithGoogle = async (accessToken, emailAddress) => {
    let params = {
        key: authTypes.LOGIN_WITH_GOOGLE,
        accessToken: accessToken,
        emailAddress: emailAddress
    };

    const result = await instance.post("/im-user-request", params);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const logoutWithGoogle = async (accessToken, emailAddress) => {
    let params = {
        key: authTypes.LOGOUT_WITH_GOOGLE,
        accessToken: accessToken,
        emailAddress: emailAddress
    };

    const result = await instance.post("/im-user-request", params);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const login = async (formValues) => {
    const result = await instance.post("api/user/login", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const userHasAccess = async (formValues) => {
    const result = await instance.post("api/user/hasAccess", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

/*** API Calls for Sign Up/Forgot Password */
export const retrieveAccountStatus = async (json) => {
    const result = await instance.post("/api/user/user-signup/retrieveAccountStatus", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const sendMail = async (json) => {
    const result = await instance.post("/api/user/user-signup/sendMail", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDetailsByAccessCode = async (accessCode) => {
    const result = await instance.post("/api/user/user-signup/getStudentDetails", accessCode);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const changePassword = async (json) => {
    const result = await instance.post("/api/user/user-signup/changePassword", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// Check if user still have default password
export const isRequiredPWChange = async (json) => {
    const result = await instance.post("api/user/isRequiredPWChange", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// END OF AUTH