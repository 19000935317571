import instance from '../../../../../../../apis/local/systemAPI';

// START OF ASSESSMENT TEMPLATE OTHER RATE
export const getActiveAssessmentTemplateOtherRates = async () => {
  const result = await instance.get("api/assessment/template-other-rates/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateOtherRatesByAssessmentTemplateOtherId = async (id) => {
  const params = {
    "assessmentTemplateOtherId": id === undefined ? null : id
  }
  var result = await instance.post('api/assessment/template-other-rates/q', params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createAssessmentTemplateOtherRate = async (formValues) => {
  const result = await instance.post("api/assessment/template-other-rates", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplateOtherRate = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/template-other-rates/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteAssessmentTemplateOtherRate = async (id) => {
  const result = await instance.delete(`api/assessment/template-other-rates/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF ASSESSMENT TEMPLATE OTHER RATE