import instance from "../../../../../apis/local/systemAPI";


export const getApplicantInfo = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/student/infos/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "LastName": keyword,
                        "firstName": keyword,
                        "birthPlace": keyword
                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/student/infos`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}











export const createStudentDiscount = async (formValues) => {
    const result = await instance.post("api/student/discounts", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getStudentEnrollmentSubjects = async (formValues) => {
    const result = await instance.get("api/student/student-enrollment-subjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSubjectSchedule = async (formValues) => {
    const result = await instance.get("/api/schedules/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSubjectScheduleDetail = async (formValues) => {
    const result = await instance.get("/api/schedule/details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const getSub = async (formValues) => {
    const result = await instance.get("api/references/subjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubSchedDetailList = async (formValues) => {
    const result = await instance.get("api/schedule/detail-lists", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getAssessmentTemplate = async (formValues) => {
    const result = await instance.get("api/assessment/templates", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentTemplateDetail = async (formValues) => {
    const result = await instance.get("api/assessment/template-details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFees = async (formValues) => {
    const result = await instance.get("api/references/fees", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFeeGroup = async (formValues) => {
    const result = await instance.get("api/references/feegroups", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSubMain = async (formValues) => {
    const result = await instance.get("api/references/subject-main-copy", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getDiscount = async (formValues) => {
    const result = await instance.get("api/student/discounts", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentAccAddOn = async (formValues) => {
    const result = await instance.get("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createStudentEnrollmentSubjects = async (formValues) => {
    const result = await instance.post("api/student/student-enrollment-subjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createEnrollmentAccountAddsOn = async (formValues) => {
    const result = await instance.post("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteEnrollmentAccountAddsOn = async (id) => {
    const result = await instance.delete(`api/enrollment/account/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateEnrollment = async (id, formValues) => {
    const result = await instance.patch(`api/enrollments/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateStudentEnrollmentSubjects = async (id, formValues) => {
    const result = await instance.patch(`api/student/student-enrollment-subjects/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteStudentEnrollmentSubjects = async (id) => {
    const result = await instance.delete(`api/student/student-enrollment-subjects/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getCredential = async (formValues) => {
    const result = await instance.get("api/references/credential", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCredentialStudent = async (formValues) => {
    const result = await instance.get("api/references/credential-student", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteCredentialStudent = async (id) => {
    const result = await instance.delete(`api/references/credential-student/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createCredentialStudent = async (formValues) => {
    const result = await instance.post("api/references/credential-student", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const createUser = async (formValues) => {
    const result = await instance.post("api/user", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getUser = async (formValues) => {
    const result = await instance.get("api/user", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const sendMail = async (values) => {
    const result = await instance.post("api/nodemailer/send-mail", values);
    if (result.status === 200 || result.status === 201) {
        // alert('email send')
    }
}
export const createUserInRole = async (formValues) => {
    const result = await instance.post("api/role/user-in-role", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}





export const updateStudentDiscount = async (id, formValues) => {
    const result = await instance.patch(`api/student/discounts/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}







export const updateStudent = async (id, formValues) => {
    const result = await instance.patch(`api/students/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudyTypes = async (formValues) => {
    const result = await instance.get("api/references/study-types", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentInfos = async (condition) => {
    const result = await instance.post("api/student/infos/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

//

export const getEnrollmentStatus = async (formValues) => {
    const result = await instance.get("/api/enrollment/status/q", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudent = async (condition) => {
    const result = await instance.post("api/students/studentVerificationList", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolHistory = async (condition) => {
    const result = await instance.post("api/student/schoolhistory/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSem = async (condition) => {
    const result = await instance.post("api/references/semesters/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async (condition) => {
    const result = await instance.post("api/references/schoolyears/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollment = async (condition) => {
    const result = await instance.post("api/enrollments/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getScholarship = async (condition) => {
    const result = await instance.post("api/references/scholarships/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDiscount = async (condition) => {
    const result = await instance.post("api/student/discounts/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchool = async (condition) => {
    const result = await instance.post("api/references/schools/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentInfo = async (condition) => {
    const result = await instance.post("api/student/infos/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async (condition) => {
    const result = await instance.post("api/references/courses/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCoursesSelect = async () => {
    const result = await instance.get("api/references/courses");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPreferredCourses = async (condition) => {
    const result = await instance.post("api/student/student-preferred-course/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevel = async (condition) => {
    const result = await instance.post("api/references/yearlevels/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFamily = async (condition) => {
    const result = await instance.post("api/student/familybackground/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentVerificationList = async (formValues) => {
    const result = await instance.post("api/enrollments/studentVerificationList", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveEnrollmentStatus = async (condition) => {
    const result = await instance.get("api/enrollment/status");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentVerify = async (val) => {
    const result = await instance.post("api/students/studentVerify", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

