export const referenceTypes = {
    SELECTED_COURSE: 'SELECTED-COURSE',
    GET_COURSES: 'GET-COURSES',
    GET_COURSES_BY_STUDENT_CATEGORY_ID: 'GET-COURSES-BY-STUDENT-CATEGORY-ID',
    GET_COURSE: 'GET-COURSE-BY-ID',
    CREATE: 'CREATE-COURSE',
    UPDATE: 'UPDATE-COURSE',
    DELETE: 'DELETE-COURSE',
    LIST_LINK: '/admin/references/course-program',
    CREATE_LINK: '/admin/references/create-course-program/',
    UPDATE_LINK: '/admin/references/update-course-program/',
    DELETE_LINK: '/admin/references/delete-course-program/'
}