import { number } from "prop-types";
import React, { useRef, forwardRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './__hooks__/styles.css'

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

});



export default function PrintFacultyLoadingReportBySection(props) {
    const { results } = props
    let componentRef = useRef();
    const classes = useStyles();


    const Component = forwardRef((props, ref) => {

        return (
            <>



                <div className="d-none" >
                    <div ref={ref}>
                        <div className="container" >

                            <main className="card border-0 p-5">


                                <section className="card-body my-5" >
                                    {results.map((res) => {
                                        return (
                                            <>
                                                <div className="facultySub">

                                                    <img style={{ width: "200px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                                    <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                                    <h4 className="text-center">Faculty Teaching Assignment</h4>
                                                    <h4 className="text-center">A.Y. {res.SY}, {res.Semester}</h4>


                                                    <h6 className="mt-5 mb-5"><span className="lead">Section: </span>{res.ClassSection}</h6>
                                                    <TableContainer key={res.ClassSectionId} component={Paper} >
                                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    <TableCell>Class Code</TableCell>
                                                                    <TableCell align="center">Subject Code</TableCell>
                                                                    <TableCell align="center">Subject Description</TableCell>
                                                                    <TableCell align="center">Time</TableCell>
                                                                    <TableCell align="center">Day Code</TableCell>
                                                                    <TableCell align="center">Credit Unit</TableCell>
                                                                    <TableCell align="center">Lec Hours</TableCell>
                                                                    <TableCell align="center">Lab Hours</TableCell>
                                                                    <TableCell align="center">Instructor</TableCell>




                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {res.Details.map((row) => (
                                                                    <TableRow >
                                                                        <TableCell component="th" scope="row">
                                                                            {row.ClassCode}
                                                                        </TableCell>
                                                                        <TableCell align="left">{row.SubjectCode}</TableCell>
                                                                        <TableCell align="left">{row.SubjectDescription}</TableCell>
                                                                        <TableCell align="left">{row.Time}</TableCell>
                                                                        <TableCell align="left">{row.DayCode}</TableCell>
                                                                        <TableCell align="left">{row.CreditUnit}</TableCell>
                                                                        <TableCell align="left">{row.LecHours}</TableCell>
                                                                        <TableCell align="left">{row.LabHours}</TableCell>
                                                                        <TableCell align="left">{row.Instructor}</TableCell>



                                                                    </TableRow>
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    {/* <footer className="d-flex justify-content-around footer-subFaculty">
                                                        <div>
                                                            <h5>Conforme</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>
                                                            <h5>{res.Faculty}</h5>
                                                        </div>
                                                        <div>
                                                            <h5>Recommending Approval</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>
                                                        <div>
                                                            <h5>Approved</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>
                                                    </footer> */}
                                                </div>

                                            </>
                                        )
                                    })}


                                </section>

                            </main>


                        </div>

                    </div>



                </div>


            </>
        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn btn-sm btn-success mr-3">Enrollment By Section</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}

                />
            </div>



        </>
    )


}