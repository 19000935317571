import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getPaymentReport, getActiveSemester, getActiveSchoolYear, getEnrollmentNo,
    getAssessmentReport
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import { useAppContext } from "../../../../contexts/useAppContext";
import ExportExcell from "./ExportExcell";

export default function AssessmentReport(props) {
    const { state: { auth } } = useAppContext()

    const { setValue } = useForm();
    const [results, setResults] = useState([])
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const [filteredSem, setFilteredSem] = useState(activeSem)
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredDateFrom, setFilteredDateFrom] = useState(moment().format("YYYY-MM-DD"))
    const [filteredDateTo, setFilteredDateTo] = useState(moment().add({ days: 1 }).format("YYYY-MM-DD"))
    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [progress, setProgress] = useState(0);
    const [isGeneratingReport, setIsGeneratingReport] = useState(false);

    const [excelFormat, setExcelFormat] = useState([])
    const [enrollmentStudentNo, setEnrollmentStudentNo] = useState([])
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const [onExtract, setOnExtract] = useState(false)
    const onGetPaymentReport = async (v) => {
        await getPaymentReport(v)
            .then(res => {

                setResults(res.data)

            })
            .catch(e => {

            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }

    const onSubmit = async () => {
        setOnExtract(true);


        let excelData = []

        for (let i = 0; i < enrollmentStudentNo.length; i++) {
            await getAssessmentReport({
                schoolYear: filteredSy,
                semesterId: filteredSem,
                studentNo: enrollmentStudentNo[i].StudentNo,
                enrollmentStatusId: 'E'
            }).then(resp => {
                console.log(resp)
                excelData.push(...resp)
            })
            // Update progress
            const currentProgress = ((i + 1) / enrollmentStudentNo.length) * 100;
            setProgress(currentProgress.toFixed(2));
        }

        console.log(excelData)
        setExcelFormat((prevData) => {
            return excelData
        })
        setOnExtract(false);


    }



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')




    }, [])

    useEffect(() => {
        getEnrollmentNo({ schoolYear: filteredSy, semesterId: filteredSem, dateFrom: filteredDateFrom, dateTo: filteredDateTo })
            .then(res => {
                console.log(res.data)
                setEnrollmentStudentNo(res.data)
            })
    }, [filteredDateFrom, filteredDateTo, filteredSy, filteredSem])

    const columns = [
        { id: 'ORNo', label: 'OR No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SemesterId', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SchoolYear', label: 'School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'PaymentDate', label: 'Payment Date', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Fees', label: 'Fee Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Tuition', label: 'Payment Amount', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]

    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: results.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'applicantReport',
        parentId: null,
        tableTitle: '',

    }

    const imSelectDropdownSchoolYear = () => {
        return <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectSyChange()}
            refClassContainer=""
            name="SchoolYear"
            isRequired={false}
            withLabel={false}
            label="SchoolYear"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={filteredSy === undefined ? activeSy : filteredSy}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }


    const imSelectDropdownSemesters = () => {

        return <IMSelect
            data={semestersSelect}
            onHandleChange={selectSemChange()}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Semester"
            forwardRef={setValue}
            selectedId={filteredSem === undefined ? activeSem : filteredSem}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />


    }


    console.log(activeSem)
    console.log(activeSy)
    console.log(filteredSem)
    console.log(filteredSy)
    console.log(results)
    console.log(enrollmentStudentNo)
    console.log(filteredDateFrom)
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"))

    if (excelFormat.length > 0) {
        console.log(excelFormat[0])
        console.log(excelFormat[0][['Prototype']])

    }
    console.log(excelFormat)


    const sampleData = [
        {
            "firstname": "Venjo",
            "lastName": "Reyes"
        },
        {
            "firstname": "Jessca",
            "lastName": "Katigbak"
        }
    ]

    return (
        <>

            <div className="card p-5 mx-auto" style={{ width: '400px', marginTop: '5%' }}>
                <div className="card-header font-weight-bold p-2">
                    <h3 className="mb-0 d-block text-center"> Assessment Report</h3>


                </div>
                {onExtract == false && schoolYearSelect && semestersSelect ?
                    <>
                        {excelFormat[0] ? <button onClick={() => { setExcelFormat([]) }} className="btn btn-secondary d-inline mb-5">Reset</button> :



                            <>
                                <section className="mt-5" >
                                    <div className="col-12 bg-white my-0 p-0 ">
                                        <input
                                            type='date'
                                            onChange={(e) => { onDateFromHandler(e.target.value) }}
                                            className="form-control"
                                            defaultValue={filteredDateFrom}
                                        />
                                    </div>
                                    <div className="col-12 bg-white my-0 p-0 mt-5">
                                        <input
                                            type='date'
                                            onChange={(e) => { onDateToHandler(e.target.value) }}
                                            className="form-control"
                                            defaultValue={filteredDateTo}

                                        />
                                    </div>
                                    <div className="col-12 bg-white my-0 p-0  mt-5">
                                        <IMSelect
                                            data={schoolYearSelect}
                                            onHandleChange={selectSyChange()}
                                            refClassContainer=""
                                            name="SchoolYear"
                                            isRequired={false}
                                            withLabel={false}
                                            label="SchoolYear"
                                            placeHolder="Select Status"
                                            forwardRef={setValue}
                                            selectedId={filteredSy === undefined ? activeSy : filteredSy}
                                            refClassName={`col`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[

                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}

                                        />
                                    </div>
                                    <div className="col-12 bg-white my-0 p-0 mt-5">
                                        <IMSelect
                                            data={semestersSelect}
                                            onHandleChange={selectSemChange()}
                                            refClassContainer=""
                                            name="Semester"
                                            isRequired={false}
                                            withLabel={false}
                                            label="Semester"
                                            placeHolder="Select Semester"
                                            forwardRef={setValue}
                                            selectedId={filteredSem === undefined ? activeSem : filteredSem}
                                            refClassName={`col`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[

                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}

                                        />
                                    </div>


                                    <button onClick={() => { onSubmit() }} className=" mt-5 d-block btn btn-primary">Generate Report</button>
                                </section>


                            </>
                        }



                    </>


                    :

                    <>

                        <div>
                            <div className="text-center">{`Building Report Please wait ...: ${progress}%`}</div>
                            <div style={{ width: '100%', backgroundColor: '#ddd', borderRadius: 4, marginBottom: '1%' }}>
                                <div style={{ width: `${progress}%`, height: 20, backgroundColor: '#4CAF50', borderRadius: 4 }}></div>
                            </div>
                        </div>

                    </>

                }


                {excelFormat[0] && <ExportExcell excelData={excelFormat} fileName={'Assessment Report'} btnName="Export Report" />}

                {/* <IMTableCustom tableProps={tableProps} /> */}


            </div>


        </>
    )
}