import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IMDialogNotification(props) {
    const { isOpen, onShowDialog,
        title, description, clearance } = props;
    const [open, setOpen] = useState(isOpen);

    const handleClose = () => {
        onShowDialog(false);
        setOpen(false);
    }



    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {description}
                        {/* <br />
                        <br /> */}
                        <section className='container'>
                            <p>Please settle the following liabilities found on your account:</p>
                            <hr />
                            <ul>
                                {clearance.map(d => {
                                    if (d.Liabilities !== null)
                                        return (
                                            <>
                                                <li>{d.Liabilities}</li>
                                            </>
                                        )
                                })}
                            </ul>
                        </section>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>

                    <button onClick={handleClose} className="btn btn-secondary">
                        Close
                    </button>


                </DialogActions>
            </Dialog>
        </>
    );
}