import React, { useState, useEffect } from 'react';
import history from '../../../history';
import { useForm } from 'react-hook-form';
import IMSelect from '../../../../_metronic/layout/components/custom/select/IMSelect';
import SiblingTable from '../../../../_metronic/layout/components/custom/forms/SiblingForm';

import FaithLogo from './assets/FaithCollegesPNG.png'
import FetchLoad from './assets/dotLoad.gif'
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import Loading from '../../../../_metronic/layout/components/custom/forms/Loading';
import { applicantTypes } from './__hooks__/types'
import { useAppContext } from '../../../contexts/useAppContext';
import {
    getSchoolInfo, getCivilStatusInfo, getYearLevelInfo, getNationalityInfo, getSchoolYear, getSemester, createRegistration,
    getStudentBESInfo, sendMail, addUpdateStudentSiblings
} from './__hooks__';
import lodash from 'lodash';
import IMDialogSubmition from '../../../../_metronic/layout/components/custom/dialog/IMDialogSubmition';


export default function Form(props) {
    const { civilStatusSelect, religionSelect, courseSelect, yearLevelSelect,
        schoolSelect, provinceSelect, municipalitySelect, countrySelect, nationalitySelect, onChangeCountry, onChangeProvince,
        familyPositionSelect } = props
    const { state: { auth }, dispatch } = useAppContext();
    const activeSchoolYear = auth.data.schoolyears.admissions.SchoolYear
    const activeSem = auth.data.schoolyears.admissions.SemesterId
    const [isFestchingBES, setIsFetchingBES] = useState(false)
    const [sySelect, setSySelect] = useState()
    const isAllowedToTransact = false
    const isReadDataPrivacy = false
    const { register, handleSubmit, getValues, setValue, reset, formState: { errors } } = useForm({
        defaultValues: {
            lastName: '', firstName: '', middleName: '', gender: '', civil: '', religion: '', birthDate: '', birthPlace: '',
            landline: '', mobile: '', email: '', address: '', lastSchoolName: '', lastSchoolAddress: '', yearLevel: '', nameGuardian: '', addressGuardian: '', contactNoGuardian: '',
            relationshipGuardian: '', fatherName: '',
        }
    });

    const [switchState, setSwitchState] = useState({
        IsActive: props.IsActive === undefined ? true : props.IsActive === 0 ? false : true,
        IsForReservation: true,
        IsLivingWithParents: false
    });

    const [showDialog, setShowDialog] = useState({
        id: null,
        record: '',
        status: false
    });

    const [data, setData] = useState({})
    const [isFromFaith, setIsFromFaith] = useState(false)
    const [isNotListedSchool, setIsNotListedSchool] = useState({ preElem: false, elem: false, jrHigh: false, srHigh: false })
    const [processingRegistration, setProcessingRegistration] = useState(false)
    const [studentStatus, setStudentStatus] = useState('newStudent');
    const [studentSiblingIndex, setStudentSiblingIndex] = useState([0])

    const handleOptionChange = (event) => {
        console.log(event.target.value)
        setStudentStatus(event.target.value);
    };


    const onSendMail = (data) => {
        const execute = async (value) => {
            await sendMail(value)
                .then(response => {

                    // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Student Account Created' });
                    // history.push('/admin/student')
                    // setTimeout(() => {
                    //     history.push(`${referenceTypes.MORE_LINK}${id}`)
                    // }, 1000);
                })
                .catch(error => {
                    console.log(error)
                })
        }

        execute(data)
    }


    const executeGetBes = async () => {
        let studentData = getValues()
        let studentName = {
            lastName: studentData.lastName || 'n',
            firstName: studentData.firstName || 'n',
            middleName: studentData.middleName || 'n'
        }
        await getStudentBESInfo(studentName)
            .then(res => {
                console.log(res)
                if (res.length == 0) {
                    setIsFromFaith(false)
                } else {
                    setIsFromFaith(true)
                    setData({
                        ...data,
                        studentNo: res.StudentNo,
                        lastName: res.LastName,
                        firstName: res.FirstName,
                        middleName: res.MiddleName,
                        placeOfBirth: res.BirthPlace,
                        landline: res.TelNo,
                        mobile: res.CelNo,
                        email: res.EmailAddress,
                        barangay: res.Barangay,
                        lastSchoolName: 1,
                        civil: res.CivilStatusId,
                        religion: res.ReligionId,
                        country: res.CountryId,
                        province: res.ProvinceId,
                        municipality: res.MunicipalityId,
                        gender: res.Gender,
                        fatherName: res.FatherName,
                        fatherOccupation: res.FatherOcc,
                        fatherContactNumber: res.FatherContactNo,
                        fatherMaritalStatus: 3,
                        motherName: res.MotherName,
                        motherOccupation: res.MotherOcc,
                        motherContactNumber: res.MotherContactNo,
                        motherMaritalStatus: 3,
                        preElementaryId: res.PreSchoolId !== null ? res.PreSchoolId : -1,
                        elementaryId: res.ElementaryId !== null ? res.ElementaryId : -1,
                        juniorHighId: res.JrSchoolId !== null ? res.JrSchoolId : -1,
                        seniorHighId: 1,
                        nameGuardian: res.ContactPerson,
                        addressGuardian: res.ContactPersonAddress,
                        contactNoGuardian: res.ContactPersonCelNo,
                        relationshipGuardian: res.ContactRelationship,
                        birthDate: res.BirthDate.slice(0, 10)

                    })

                    setIsNotListedSchool((prevData) => {
                        return {
                            ...prevData,
                            preElem: res.PreSchoolId !== null ? false : true,
                            elem: res.ElementaryId !== null ? false : true,
                            jrHigh: res.JrSchoolId !== null ? false : true

                        }
                    })
                }
                setIsFetchingBES(false)


            })
            .catch(e => {

                console.log(e)
                setIsFetchingBES(false)
            })
    }


    // const handleChangeInput = (name) => (event) => {
    //     // Update the studentData when the input value changes
    //     setData((prevData) => {
    //         return { ...prevData, [name]: event.target.value }
    //     })
    // };


    // Define the debounced function



    const handleChangeInput = (name) => (event) => {
        // const value = event.target.value;
        // setData((prevData) => ({
        //     ...prevData,
        //     [name]: value,
        // }));
        // debouncedUpdate(name, value);
    };


    const [sibling, setSibling] = useState([
        {
            siblingKey: Math.floor(Math.random() * 1000000),
            siblingCount: Math.floor(Math.random() * 1000000),
            className: ' ',
        }
    ])
    const siblingsAddHandler = (data) => {
        setSibling((prevData) => {
            return [...prevData, data]
        })

    }
    const capitalizeFirstLetter = (words) => {
        if (words !== undefined) {
            var separateWord = words.toLowerCase().split(' ');
            for (var i = 0; i < separateWord.length; i++) {
                separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                    separateWord[i].substring(1);
            }
            return separateWord.join(' ');
        } else {
            return
        }

    }
    const selectHandleChange = name => event => {
        let studentData = getValues()
        setData((prevData) => {
            return { ...studentData, studentNo: data.studentNo, [name]: event }
        });
        if (name == 'lastSchoolName' && event == 1) {
            setIsFetchingBES(true)
            executeGetBes()

        } else if (name == 'preElementaryId') {
            setIsNotListedSchool((prevData) => {
                return { ...prevData, preElem: event !== -1 ? false : true }
            })

        } else if (name == 'elementaryId') {
            setIsNotListedSchool((prevData) => {
                return { ...prevData, elem: event !== -1 ? false : true }
            })

        } else if (name == 'juniorHighId') {
            setIsNotListedSchool((prevData) => {
                return { ...prevData, jrHigh: event !== -1 ? false : true }
            })

        } else if (name == 'seniorHighId') {
            setIsNotListedSchool((prevData) => {
                return { ...prevData, srHigh: event !== -1 ? false : true }
            })
        }
    }


    const siblingInfoHandler = (data) => {
        sibling.find((sib, index) => {
            if (sib.siblingCount === data.siblingCount) {
                sibling[index].siblingFullName = data.siblingFullName
                sibling[index].siblingsSchoolCompany = data.siblingsSchoolCompany
                sibling[index].siblingsYearLevel = data.siblingsYearLevel
                sibling[index].siblingsMaritalStatus = data.siblingsMaritalStatus
                sibling[index].siblingsContactNo = data.siblingsContactNo
                sibling[index].siblingsAge = data.siblingsAge

            }

        })
    }

    const onSubmit = formValues => {
        setProcessingRegistration(true)
        let today = new Date();
        let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
        let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
        let dateTime = date + ' ' + time;


        const {
            lastName, firstName, middleName, gender, civil, religion, birthDate, birthPlace, landline,
            coursese, mobile, email, address, barangay, municipality, province, country,
            lastSchoolName, yearLevel, nameGuardian, addressGuardian, contactNoGuardian, fatherContactNumber,
            fatherHomeAddress, fatherName, fatherOccupation, fatherOccupationAddress, motherContactNumber,
            motherHomeAddress, motherName, motherOccupation, motherOccupationAddress, fatherCitizenship, motherCitizenship,
            fatherHighestEducationalAttainment, motherHighestEducationalAttainment, preElementaryId, elementaryId, juniorHighId,
            seniorHighId, preElementaryNotListedSchool, elementaryNotListedSchool, juniorHighNotListedSchool, seniorHighNotListedSchool,
            familyPositionId, facebook, twitter, instagram, seniorHighStrand, preferredCourseId1, preferredCourseId2,
            preferredSchoolId1, preferredSchoolId2, occupationGuardian, familyMonthlyIncome,
            preElementaryToYear, preElementaryFromYear, elementaryToYear, elementaryFromYear, juniorHighFromYear, juniorHighToYear,
            seniorHighFromYear, seniorHighToYear, lastSchoolAttendedNotListed

        } = formValues

        let siblingData = [];

        console.log(formValues)


        const registrationValues = {
            studentNo: null,
            studentId: 0,
            studentInfoId: 0,
            lastName: capitalizeFirstLetter(lastName),
            firstName: capitalizeFirstLetter(firstName),
            middleName: middleName.length > 0 ? capitalizeFirstLetter(middleName) : " ",
            nameSuffix: null,
            gender: gender,
            birthDate: birthDate,
            birthPlace: birthPlace,
            isActive: 1,
            dateCreated: dateTime,
            createdBy: 'System',
            dateUpdated: dateTime,
            updatedBy: 'System',
            civilStatusId: civil,
            religionId: religion,
            barangay: capitalizeFirstLetter(barangay),
            municipalityId: municipality,
            provinceId: province,
            countryId: country,
            telNo: landline,
            cellNo: mobile,
            emailAddress: email.toLowerCase(),
            yearLevelId: yearLevel,
            schoolId: lastSchoolName,
            schoolYear: activeSchoolYear,
            semesterId: activeSem,
            studentType: 'N',
            contactPerson: capitalizeFirstLetter(nameGuardian),
            contactPersonAddress: capitalizeFirstLetter(addressGuardian),
            contactPersonCelNo: contactNoGuardian,
            courseId: coursese,
            studentCategoryId: 1,
            isWalkIn: 0,
            fatherName: capitalizeFirstLetter(fatherName),
            fatherCitizen: fatherCitizenship || null,
            fatherHomeAddress: capitalizeFirstLetter(fatherHomeAddress),
            fatherHighestEducationalAttainment: fatherHighestEducationalAttainment || null,
            fatherBusinessName: capitalizeFirstLetter(fatherOccupation),
            fatherBusinessAddress: capitalizeFirstLetter(fatherOccupationAddress),
            fatherBusinessContactNo: fatherContactNumber,
            motherName: capitalizeFirstLetter(motherName),
            motherCitizen: motherCitizenship || null,
            motherHomeAddress: capitalizeFirstLetter(motherHomeAddress),
            motherHighestEducationalAttainment: motherHighestEducationalAttainment || null,
            motherBusinessName: capitalizeFirstLetter(motherOccupation),
            motherBusinessAddress: capitalizeFirstLetter(motherOccupationAddress),
            motherBusinessContactNo: motherContactNumber,
            preElementary: preElementaryId,
            elementary: elementaryId,
            jrHigh: juniorHighId,
            srHigh: seniorHighId,
            isVerified: 0,
            isTransferee: studentStatus === "newStudent" ? 1 : 0,
            studentStatusId: studentStatus === "newStudent" ? 1 : 5,
            isOnline: true,
            isFromFaith: isFromFaith,
            preElemNotListed: preElementaryNotListedSchool || null,
            elemNotListed: elementaryNotListedSchool || null,
            jrHighNotListed: juniorHighNotListedSchool || null,
            srHighNotListed: seniorHighNotListedSchool || null,
            isForReservation: switchState.IsForReservation == true ? 1 : 0,
            oldStudentNo: data.studentNo,
            familyPositionId: familyPositionId,
            facebook: facebook,
            twitter: twitter,
            instagram: instagram,
            seniorHighStrand: seniorHighStrand,
            preferredCourseId1: preferredCourseId1,
            preferredCourseId2: preferredCourseId2,
            preferredSchoolId1: preferredSchoolId1,
            preferredSchoolId2: preferredSchoolId2,
            guardianOccupation: occupationGuardian,
            familyMonthlyIncome: familyMonthlyIncome,
            preElemFrom: preElementaryFromYear,
            elemFrom: elementaryFromYear,
            jrFrom: juniorHighFromYear,
            srFrom: seniorHighFromYear,
            preElemTo: preElementaryToYear,
            elemTo: elementaryToYear,
            jrTo: juniorHighToYear,
            srTo: seniorHighToYear,
            lastSchoolAttendedNotListed: lastSchoolAttendedNotListed

        }

        console.log(registrationValues)


        const executeRegister = async (formData) => {


            await createRegistration(formData)
                .then(async res => {
                    console.log(formValues)

                    for (let key in formValues) {
                        if (key.startsWith("sibling") && formValues[key]) {
                            let parts = key.split("_");
                            let identifier = parts[1];
                            if (!siblingData[identifier]) {
                                siblingData[identifier] = {};
                            }
                            let property = parts[0].substring("sibling".length);
                            siblingData[identifier][property] = data[key];
                            siblingData[identifier].createdBy = auth.data.Username
                            siblingData[identifier].studentInfoId = res.data.StudentInfoId
                        }
                    }

                    console.log(siblingData);
                    await addUpdateStudentSiblings(siblingData)
                        .then(resp => {
                            setProcessingRegistration(false)
                            onSendMail({
                                isApplicant: switchState.IsForReservation == true ? 1 : 0,
                                course: res.data.Course,
                                emailAddress: email,
                                fullName: res.data.FullName,
                                gender: res.data.Gender,
                                religion: res.data.Religion,
                                dateOfBirth: birthDate,
                                placeOfBirth: birthPlace,
                                lastSchoolAttended: res.data.LastSchoolAttended,
                                lastSchoolAddress: res.data.LastSchoolAddress,
                                schoolYear: res.data.SchoolYear,
                                semester: res.data.Semester,
                                institute: res.data.Institute,
                                course: res.data.Course,
                                yearLevel: res.data.YearLevel,
                                country: res.data.Country,
                                province: res.data.Province,
                                municipality: res.data.Municipality,
                                address: birthPlace,
                                fatherName: res.data.FatherName,
                                fatherOccupation: res.data.FatherOccupation,
                                fatherContactNo: res.data.FatherContactNo,
                                motherName: res.data.MotherName,
                                motherOccupation: res.data.MotherOccupation,
                                motherContactNo: res.data.MotherContactNo


                            })

                            history.push(`${applicantTypes.SUBMITTED}`);
                        })


                })
                .catch(e => {

                })
        }

        executeRegister(registrationValues)

    }

    const errorAlert = () => {
        return (
            <div class="alert alert-danger" role="alert">
                Kindly fill out all the information needed.
            </div>
        )
    }


    const errorCondition = () => {
        if (isAllowedToTransact && isReadDataPrivacy) {
            return errorAlert
        }
    }

    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };




    useEffect(() => {
        if (data.country !== undefined) {
            onChangeCountry(data.country)

        }

        if (data.province !== undefined) {
            onChangeProvince(data.province)

        }

    }, [data.province, data.municipality, data.country])



    useEffect(() => {
        register({ name: 'gender' },
            { required: true });
        register({ name: 'religion' },
            { required: true });
        register({ name: 'lastSchoolName' },
            { required: true });
        register({ name: 'civil' },
            { required: true });
        register({ name: 'yearLevel' },
            { required: true });
        register({ name: 'coursese' },
            { required: true });
        register({ name: 'juniorHighId' },
            { required: true });
        register({ name: 'seniorHighId' },
            { required: true });
        register({ name: 'elementaryId' },
            { required: true });
        register({ name: 'preElementaryId' },
            { required: true });
        register({ name: 'province' },
            { required: true });
        register({ name: 'municipality' },
            { required: true });
        register({ name: 'country' },
            { required: true });
        register({ name: 'preElementaryFromYear' },
            { required: true });
        register({ name: 'elementaryFromYear' },
            { required: true });
        register({ name: 'juniorHighFromYear' },
            { required: true });
        register({ name: 'seniorHighFromYear' },
            { required: true });
        register({ name: 'preElementaryToYear' },
            { required: true });
        register({ name: 'elementaryToYear' },
            { required: true });
        register({ name: 'juniorHighToYear' },
            { required: true });
        register({ name: 'seniorHighToYear' },
            { required: true });
        register({ name: 'fatherCitizenship' },
            { required: false });
        register({ name: 'motherCitizenship' },
            { required: false });
        register({ name: 'motherHighestEducationalAttainment' },
            { required: false });
        register({ name: 'fatherHighestEducationalAttainment' },
            { required: false });
        register({ name: 'motherMaritalStatus' },
            { required: false });
        register({ name: 'fatherMaritalStatus' },
            { required: false });
        register({ name: 'familyPositionId' },
            { required: true });



        register({ name: 'preferredCourseId1' },
            { required: false });
        register({ name: 'preferredCourseId2' },
            { required: false });
        register({ name: 'preferredSchoolId1' },
            { required: false });
        register({ name: 'preferredSchoolId2' },
            { required: false });


        register({ name: 'siblingcivilStatusId_0' },
            { required: false });
        register({ name: 'siblingcivilStatusId_1' },
            { required: false });
        register({ name: 'siblingcivilStatusId_2' },
            { required: false });
        register({ name: 'siblingcivilStatusId_3' },
            { required: false });
        register({ name: 'siblingcivilStatusId_4' },
            { required: false });
        register({ name: 'siblingcivilStatusId_5' },
            { required: false });
        register({ name: 'siblingcivilStatusId_6' },
            { required: false });
        register({ name: 'siblingcivilStatusId_7' },
            { required: false });
        register({ name: 'siblingcivilStatusId_8' },
            { required: false });
        register({ name: 'siblingcivilStatusId_9' },
            { required: false });
        register({ name: 'siblingcivilStatusId_10' },
            { required: false });


        register({ name: 'siblingyearLevelId_0' },
            { required: false });
        register({ name: 'siblingyearLevelId_1' },
            { required: false });
        register({ name: 'siblingyearLevelId_2' },
            { required: false });
        register({ name: 'siblingyearLevelId_3' },
            { required: false });
        register({ name: 'siblingyearLevelId_4' },
            { required: false });
        register({ name: 'siblingyearLevelId_5' },
            { required: false });
        register({ name: 'siblingyearLevelId_6' },
            { required: false });
        register({ name: 'siblingyearLevelId_7' },
            { required: false });
        register({ name: 'siblingyearLevelId_8' },
            { required: false });
        register({ name: 'siblingyearLevelId_9' },
            { required: false });
        register({ name: 'siblingyearLevelId_10' },
            { required: false });




    }, [register])




    useEffect(() => {

        let data
        let sySelectArr = []
        const executeGetSY = async () => {

            await getSchoolYear()
                .then(response => {
                    if (response.success) {
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i].IsActive === 1) {
                                data = response.data[i]
                            }
                        }

                        for (let i = 0; i < response.data.length; i++) {
                            sySelectArr.push({ tableId: response.data[i].SchoolYear, code: response.data[i].SchoolYear })

                        }
                        setSySelect(sySelectArr)

                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        alert("something went wrong")
                    } else {

                        alert("something went wrong 2")
                    }
                })
        }
        executeGetSY();

        const executeGetSem = async () => {

            await getSemester()
                .then(response => {
                    if (response.success) {
                        for (let i = 0; i < response.data.length; i++) {
                            if (response.data[i].IsActive === 1) {
                                data = response.data[i]
                            }
                        }

                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        alert("something went wrong")
                    } else {

                        alert("something went wrong 2")
                    }
                })
        }
        executeGetSem();

    }, [])

    useEffect(() => {
        if (switchState.IsLivingWithParents == true) {
            let studentData = getValues()
            let studentGuardian = {
                guardianName: studentData.fatherName || studentData.motherName || '',
                guardianHomeAddress: studentData.fatherHomeAddress || studentData.motherHomeAddress || '',
                guardianContactNo: studentData.fatherContactNumber || studentData.motherContactNumber || 0,
                guardianOccupation: studentData.fatherOccupation || studentData.motherOccupation || ''
            }
            setData((prevData) => {
                console.log(prevData)
                return {
                    ...prevData,
                    studentNo: studentData.studentNo,
                    lastName: studentData.lastName,
                    firstName: studentData.firstName,
                    middleName: studentData.middleName,
                    lastSchoolName: studentData.lastSchoolName,
                    placeOfBirth: studentData.placeOfBirth,
                    landline: studentData.landline,
                    mobile: studentData.mobile,
                    email: studentData.email,
                    barangay: studentData.barangay,
                    civil: studentData.civil,
                    religion: studentData.religion,
                    country: studentData.country,
                    province: studentData.province,
                    municipality: studentData.municipality,
                    gender: studentData.gender,
                    fatherName: studentData.fatherName,
                    fatherOccupation: studentData.fatherOccupation,
                    fatherContactNumber: studentData.fatherContactNumber,
                    fatherMaritalStatus: studentData.fatherMaritalStatus,
                    motherName: studentData.motherName,
                    motherOccupation: studentData.motherOccupation,
                    motherContactNumber: studentData.motherContactNumber,
                    motherMaritalStatus: studentData.motherMaritalStatus,
                    preElementaryId: studentData.preElementaryId !== null ? studentData.preElementaryId : -1,
                    elementaryId: studentData.elementaryId !== null ? studentData.elementaryId : -1,
                    juniorHighId: studentData.juniorHighId !== null ? studentData.juniorHighId : -1,
                    seniorHighId: 1,
                    birthDate: studentData.birthDate.slice(0, 10) || '',
                    fatherOccupationAddress: studentData.fatherOccupationAddress,
                    fatherHomeAddress: studentData.fatherHomeAddress,
                    motherOccupationAddress: studentData.motherOccupationAddress,
                    motherHomeAddress: studentData.motherHomeAddress,
                    nameGuardian: studentGuardian.guardianName,
                    addressGuardian: studentGuardian.guardianHomeAddress,
                    contactNoGuardian: studentGuardian.guardianContactNo,
                    relationshipGuardian: studentData.fatherName ? 'Father' : studentData.motherName ? 'Mother' : '',
                    occupationGuardian: studentGuardian.guardianName
                }
            })
        }
    }, [switchState.IsLivingWithParents])


    useEffect(() => {
        reset(data)
    }, [data])



    let siblingsComponent


    siblingsComponent = sibling.map((siblings) => {


        return (

            <SiblingTable
                key={siblings.siblingKey}
                siblingsCount={siblings.siblingCount}
                className={siblings.className}
                siblingsAddHandler={siblingsAddHandler}
                infoHandler={siblingInfoHandler}
                types={applicantTypes}
                getCivilStatus={getCivilStatusInfo}
                getYearLevelInfo={getYearLevelInfo}

            />


        )

    })

    console.log(switchState)
    console.log(data)
    console.log(getValues())
    console.log(errors)
    return (
        <>
            {sySelect !== undefined ?

                <>

                    <IMDialogSubmition
                        title={`Notification`}
                        content={<span>For Incoming College for SY 2024-2025 please click continue.
                            For transferees for 2nd Semester AY 2023-2024 please proceed to Admissions and Testing Center.</span>}
                        handleSubmit={() => {
                            setSySelect(undefined)
                            window.location.href = 'https://www.firstasia.edu.ph/'
                        }}
                        onShowDialog={setShowDialog}
                        isOpen={true}
                        isOnlineReg={1}
                    />
                    <main>
                        <section>
                            <h4>ONLINE APPLICATION</h4>
                        </section>
                        <div className="card card-custom gutter-b example example-compact  container my-5">
                            <div className="card-header">
                                <div className="card-title text-center my-5">
                                    <h3 className=" mb-0 p-1"> <i className="fas fa-list-alt fa-lg"></i> &nbsp;

                                        Online Application Form</h3>
                                </div>
                            </div>
                            <div className="card-body">
                                <div className='text-center'>
                                    <img style={{ width: '250px' }} src={FaithLogo} />
                                    {isFestchingBES == false &&
                                        <p className='my-5'><strong>NOTE: </strong> The personal information submitted herein shall be used for the initial phase of the application for Admission Examination.</p>
                                    }
                                    <p>{errors.lastName?.message}</p>



                                </div>
                                {isFestchingBES == false
                                    ?
                                    <>
                                        <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">

                                            <div className="kt-portlet__body ">
                                                <h4><i className="fa fa-child icon-xl ml-auto"></i> &nbsp; Applicant Information</h4>

                                                <hr />
                                                <section className='mb-5'>

                                                    <label className='mr-5'>
                                                        <input
                                                            type="radio"
                                                            value="newStudent"
                                                            checked={studentStatus === 'newStudent'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        &nbsp;New Student (Graduate of SHS AY 2023-2024)
                                                    </label>

                                                    <label className='mr-5'>
                                                        <input
                                                            type="radio"
                                                            value="transferee"
                                                            checked={studentStatus === 'transferee'}
                                                            onChange={handleOptionChange}
                                                        />
                                                        &nbsp;Transferee (Have Entered College in Other School)
                                                    </label>

                                                </section>


                                                {/* <FormControl component="fieldset" className="ml-auto">

                                                <FormControlLabel
                                                    name="isActive"
                                                    inputRef={register()}
                                                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} defaultValue={switchState.IsActive} />}
                                                    label="Are you a new student?"

                                                />
                                            </FormControl> */}
                                                {/* <FormControl component="fieldset" className="">
                                                <FormControlLabel
                                                    name="IsForReservation"
                                                    inputRef={register()}
                                                    control={<Switch checked={switchState.IsForReservation} onChange={switchHandleChange('IsForReservation')} defaultValue={switchState.IsForReservation} />}
                                                    label="Are you applying for reservation?"

                                                />
                                            </FormControl> */}
                                                <div className="row">

                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                        <label className="col-form-label p-0">Last Name <span className="text-danger">*</span>  </label>
                                                        <input
                                                            {...register('lastName')}
                                                            onChange={handleChangeInput('lastName')}
                                                            type="text"
                                                            name="lastName"
                                                            defaultValue={data.lastName}
                                                            className={`form-control ${!!errors.lastName && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.lastName && <span className="form-text text-danger">{errors.lastName && errors.lastName.type === "required" && 'Last Name is required'}</span>}



                                                    </div>
                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                        <label className="col-form-label p-0">First Name <span className="text-danger">*</span>  </label>
                                                        <input
                                                            {...register('firstName')}
                                                            onChange={handleChangeInput('firstName')}
                                                            type="text"
                                                            name="firstName"
                                                            defaultValue={data.firstName}
                                                            className={`form-control ${!!errors.firstName && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.firstName && <span className="form-text text-danger">{errors.firstName && errors.firstName.type === "required" && 'First Name is required'}</span>}


                                                    </div>
                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                        <label className="col-form-label p-0">Middle Name <span className="text-danger">*</span> </label>
                                                        <input
                                                            onChange={handleChangeInput('middleName')}
                                                            type="text"
                                                            name="middleName"
                                                            defaultValue={data.middleName}
                                                            className={`form-control ${!!errors.middleName && ' is-invalid'}`}
                                                            ref={register({
                                                                required: false
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.middleName && <span className="form-text text-danger">{errors.middleName && errors.middleName.type === "required" && 'Middle Name is required'}</span>}

                                                    </div>


                                                </div>

                                                <div className="row">
                                                    <div className="form-group col mb-3">
                                                        <IMSelect
                                                            data={schoolSelect}
                                                            onHandleChange={selectHandleChange('lastSchoolName')}
                                                            refClassContainer="form-group mb-3"
                                                            name="lastSchoolName"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Last School Attended"
                                                            placeHolder="Last School Attended"
                                                            forwardRef={setValue}
                                                            selectedId={data.lastSchoolName}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.lastSchoolName}
                                                            errorMessage={errors.lastSchoolName && errors.lastSchoolName.type === "required" && 'Last School Attended is required'}
                                                        />

                                                        {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                                                    </div>

                                                    {data.lastSchoolName == -1 &&
                                                        <div className="form-group col">
                                                            <label className="col-form-label p-0">Last School (If not Listed)<span className="text-danger">*</span>  </label>
                                                            <input
                                                                onChange={handleChangeInput('lastSchoolAttendedNotListed')}
                                                                type="text"
                                                                name="lastSchoolAttendedNotListed"
                                                                defaultValue={data.lastSchoolAttendedNotListed}
                                                                className={`form-control mt-2 ${!!errors.lastSchoolAttendedNotListed && ' is-invalid'}`}
                                                                ref={register({
                                                                    required: false
                                                                })}
                                                                autoComplete="off"
                                                                maxLength="100"

                                                            />
                                                            {!!errors.lastSchoolAttendedNotListed && <span className="form-text text-danger">{errors.lastSchoolAttendedNotListed && errors.lastSchoolAttendedNotListed.type === "required" && 'Last School Attended is required'}</span>}
                                                        </div>
                                                    }

                                                    <div className='col mb-0'>
                                                        <IMSelect
                                                            data={civilStatusSelect}
                                                            onHandleChange={selectHandleChange('civil')}
                                                            refClassContainer="form-group mb-3"
                                                            name="civil"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Civil Status"
                                                            placeHolder="Civil Status"
                                                            forwardRef={setValue}
                                                            selectedId={data.civil}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.civil}
                                                            errorMessage={errors.civil && errors.civil.type === "required" && 'Civil Status is required'}
                                                        />
                                                    </div>
                                                    <div className='col mb=0'>
                                                        <IMSelect
                                                            data={religionSelect}
                                                            onHandleChange={selectHandleChange('religion')}
                                                            refClassContainer="form-group mb-3"
                                                            name="religion"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Religion"
                                                            placeHolder="Religion"
                                                            forwardRef={setValue}
                                                            selectedId={data.religion}
                                                            refClassName={``}

                                                            withDescription={false}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.gender}
                                                            errorMessage={errors.religion && errors.religion.type === "required" && 'Religion is required'}
                                                        />
                                                    </div>
                                                    <div className="form-group col mb-3">
                                                        <label className="col-form-label p-0">Date of Birth <span className="text-danger">*</span> </label>
                                                        <input
                                                            onChange={handleChangeInput('birthDate')}
                                                            type="date"
                                                            name="birthDate"
                                                            defaultValue={data.birthDate}
                                                            className={`form-control mt-2 ${!!errors.birthDate && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}


                                                        />
                                                        {!!errors.birthDate && <span className="form-text text-danger">{errors.birthDate && errors.birthDate.type === "required" && 'Birth Date is required'}</span>}

                                                    </div>

                                                </div>
                                                <div className='row'>

                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Place of Birth <span className="text-danger">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('birthPlace')}
                                                            type="text"
                                                            name="birthPlace"
                                                            defaultValue={data.placeOfBirth}
                                                            className={`form-control mt-2 ${!!errors.birthPlace && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.birthPlace && <span className="form-text text-danger">{errors.birthPlace && errors.birthPlace.type === "required" && 'Place of Birth is required'}</span>}
                                                    </div>

                                                    <div className='col-12 col-md-3 mb-0'>
                                                        <IMSelect
                                                            data={familyPositionSelect}
                                                            onHandleChange={selectHandleChange('familyPositionId')}
                                                            refClassContainer="form-group mb-3"
                                                            name="familyPositionId"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Position in the Family"
                                                            placeHolder="Position in the Family"
                                                            forwardRef={setValue}
                                                            selectedId={data.familyPositionId}
                                                            refClassName={``}

                                                            withDescription={false}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.familyPositionId}
                                                            errorMessage={errors.familyPositionId && errors.familyPositionId.type === "required" && 'Family Position is required'}
                                                        />
                                                    </div>

                                                    <div className='col-12 col-md-3 mb=0'>
                                                        <IMSelect

                                                            data={yearLevelSelect}
                                                            onHandleChange={selectHandleChange('yearLevel')}
                                                            refClassContainer="form-group mb-3"
                                                            name="yearLevel"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Year Level"
                                                            placeHolder=""
                                                            forwardRef={setValue}
                                                            selectedId={data.yearLevel}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[


                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.yearLevel}
                                                            errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                                                        />
                                                    </div>
                                                    <div className='col-12 col-md-3 mb-0'>
                                                        <IMSelect
                                                            onHandleChange={selectHandleChange('gender')}
                                                            refClassContainer="form-group mb-3"
                                                            name="gender"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Gender"
                                                            placeHolder="Gender"
                                                            forwardRef={setValue}
                                                            selectedId={data.gender}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={true}
                                                            refStaticData={[
                                                                { id: '', label: '' },
                                                                { id: 'M', label: 'Male' },
                                                                { id: 'F', label: 'Female' }
                                                            ]}
                                                            field={{
                                                                tableId: '',
                                                                display: ''
                                                            }}
                                                            error={!!errors.gender}
                                                            errorMessage={errors.gender && errors.gender.type === "required" && 'Gender is required'}

                                                        />
                                                    </div>

                                                </div>
                                                <div className='row'>

                                                    {switchState.IsForReservation == false
                                                        &&
                                                        <div className='col-12 col-md-3 mb=0'>
                                                            <IMSelect
                                                                data={courseSelect}
                                                                onHandleChange={selectHandleChange('coursese')}
                                                                refClassContainer="form-group mb-3"
                                                                name="coursese"
                                                                isRequired={true}
                                                                withLabel={true}
                                                                label="Course"
                                                                placeHolder=""
                                                                forwardRef={setValue}
                                                                selectedId={data.coursese}
                                                                refClassName={``}
                                                                withDescription={false}
                                                                // description={`Please select your gender.`}
                                                                refDisabled={true}
                                                                refIsStatic={false}
                                                                refStaticData={[

                                                                ]}
                                                                field={{
                                                                    tableId: 'tableId',
                                                                    display: 'code'
                                                                }}
                                                                error={!!errors.coursese}
                                                                errorMessage={errors.coursese && errors.coursese.type === "required" && 'Course is required'}
                                                            />
                                                        </div>

                                                    }
                                                    {switchState.IsForReservation == false && <div className='col-12 col-md-3 mb=0'></div>}


                                                    {/* <div className='col-12 col-md-3 mb=0'></div> */}






                                                </div>

                                                {switchState.IsForReservation == true &&
                                                    <>
                                                        <div className='row mt-5'>
                                                            <div className='col-12 col-md-4 mb=0'>
                                                                <IMSelect
                                                                    data={courseSelect}
                                                                    onHandleChange={selectHandleChange('coursese')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="coursese"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Reserved Course"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.coursese}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.coursese}
                                                                    errorMessage={errors.coursese && errors.coursese.type === "required" && 'Reserved Course is required'}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-4 mb=0'>
                                                                <IMSelect
                                                                    data={courseSelect}
                                                                    onHandleChange={selectHandleChange('preferredCourseId1')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="preferredCourseId1"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Preferred Course 1"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preferredCourseId1}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.preferredCourseId1}
                                                                    errorMessage={errors.preferredCourseId1 && errors.preferredCourseId1.type === "required" && 'Preferred Course is required'}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-4 mb=0'>
                                                                <IMSelect
                                                                    data={courseSelect}
                                                                    onHandleChange={selectHandleChange('preferredCourseId2')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="preferredCourseId2"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Preferred Course 2"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preferredCourseId2}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.preferredCourseId2}
                                                                    errorMessage={errors.preferredCourseId2 && errors.preferredCourseId2.type === "required" && 'Preferred Course is required'}
                                                                />
                                                            </div>
                                                        </div>
                                                        <div className='row mt-5'>
                                                            <div className="form-group col-12 col-md-6 mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange('preferredSchoolId1')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="preferredSchoolId1"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Preferred School 1"
                                                                    placeHolder="Preferred School"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preferredSchoolId1}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.preferredSchoolId1}
                                                                    errorMessage={errors.preferredSchoolId1 && errors.preferredSchoolId1.type === "required" && 'Preferred School is required'}
                                                                />

                                                                {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                                                            </div>
                                                            <div className="form-group col-12 col-md-6 mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange('preferredSchoolId2')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="preferredSchoolId2"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Preferred School 2"
                                                                    placeHolder="Preferred School"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preferredSchoolId2}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.preferredSchoolId2}
                                                                    errorMessage={errors.preferredSchoolId2 && errors.preferredSchoolId2.type === "required" && 'Preferred School is required'}
                                                                />

                                                                {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                                                            </div>
                                                        </div>

                                                    </>
                                                }
                                                <br /> <br />
                                                <h4><i className="fa fa-id-card icon-xl ml-auto"></i> &nbsp; Applicant Contact Information</h4>

                                                <hr />
                                                <div className='row'>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Landline #<span className="text-danger">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('landline')}
                                                            type="text"
                                                            name="landline"
                                                            defaultValue={data.landline}
                                                            className={`form-control mt-2 ${!!errors.landline && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.landline && <span className="form-text text-danger">{errors.landline && errors.landline.type === "required" && 'Landline is required'}</span>}

                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Mobile # <span className="text-danger">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('mobile')}
                                                            type="text"
                                                            name="mobile"
                                                            defaultValue={data.mobile}
                                                            className={`form-control mt-2 ${!!errors.mobile && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.mobile && <span className="form-text text-danger">{errors.mobile && errors.mobile.type === "required" && 'Mobile Number is required'}</span>}

                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Email <span className="text-danger">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('email')}
                                                            type="email"
                                                            name="email"
                                                            defaultValue={data.email}
                                                            className={`form-control mt-2 ${!!errors.email && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.email && <span className="form-text text-danger">{errors.email && errors.email.type === "required" && 'Email is required'}</span>}

                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Facebook <span className="text-info">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('facebook')}
                                                            type="text"
                                                            name="facebook"
                                                            defaultValue={data.facebook}
                                                            className={`form-control mt-2 ${!!errors.facebook && ' is-invalid'}`}
                                                            ref={register({
                                                                required: false
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />

                                                    </div>
                                                </div>

                                                <div className='row'>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Twitter / X <span className="text-info">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('twitter')}
                                                            type="text"
                                                            name="twitter"
                                                            defaultValue={data.twitter}
                                                            className={`form-control mt-2 ${!!errors.twitter && ' is-invalid'}`}
                                                            ref={register({
                                                                required: false
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />

                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Instagram <span className="text-info">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('instagram')}
                                                            type="text"
                                                            name="instagram"
                                                            defaultValue={data.instagram}
                                                            className={`form-control mt-2 ${!!errors.instagram && ' is-invalid'}`}
                                                            ref={register({
                                                                required: false
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />

                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <IMSelect
                                                            data={countrySelect}
                                                            onHandleChange={selectHandleChange('country')}
                                                            refClassContainer="form-group mb-3"
                                                            name="country"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Country"
                                                            placeHolder="Country"
                                                            forwardRef={setValue}
                                                            selectedId={data.country}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.country}
                                                            errorMessage={errors.country && errors.country.type === "required" && 'Country is required'}
                                                        />
                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <IMSelect
                                                            data={provinceSelect}
                                                            onHandleChange={selectHandleChange('province')}
                                                            refClassContainer="form-group mb-3"
                                                            name="province"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Province"
                                                            placeHolder="Province"
                                                            forwardRef={setValue}
                                                            selectedId={data.province}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.province}
                                                            errorMessage={errors.province && errors.province.type === "required" && 'Province is required'}
                                                        />
                                                    </div>
                                                </div>

                                                <div className='row'>

                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <IMSelect
                                                            data={municipalitySelect}
                                                            onHandleChange={selectHandleChange('municipality')}
                                                            refClassContainer="form-group mb-3"
                                                            name="municipality"
                                                            isRequired={true}
                                                            withLabel={true}
                                                            label="Municipality"
                                                            placeHolder="Municipality"
                                                            forwardRef={setValue}
                                                            selectedId={data.municipality}
                                                            refClassName={``}
                                                            withDescription={false}
                                                            // description={`Please select your gender.`}
                                                            refDisabled={true}
                                                            refIsStatic={false}
                                                            refStaticData={[

                                                            ]}
                                                            field={{
                                                                tableId: 'tableId',
                                                                display: 'code'
                                                            }}
                                                            error={!!errors.municipality}
                                                            errorMessage={errors.municipality && errors.municipality.type === "required" && 'Municipality is required'}
                                                        />
                                                    </div>
                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                        <label className="col-form-label p-0">Barangay <span className="text-danger">*</span>  </label>
                                                        <input
                                                            onChange={handleChangeInput('barangay')}
                                                            type="text"
                                                            name="barangay"
                                                            defaultValue={data.barangay}
                                                            className={`form-control mt-2 ${!!errors.barangay && ' is-invalid'}`}
                                                            ref={register({
                                                                required: true
                                                            })}
                                                            autoComplete="off"
                                                            maxLength="100"

                                                        />
                                                        {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Barangay is required'}</span>}

                                                    </div>




                                                </div>




                                            </div>

                                            <div className="kt-portlet__body mt-5">
                                                <h4><i className="fas fa-users icon-xl"></i> Family Background</h4> <hr />

                                                <div className={'card mr-2'}  >

                                                    <h5 className="card-header bg-secondary p-5 is-invalid">{'Father\'s Information'}</h5>
                                                    <div className='card-body p-3'>
                                                        <div className='row'>




                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Full Name <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('fatherName')}
                                                                    type="text"
                                                                    name="fatherName"
                                                                    defaultValue={data.fatherName}
                                                                    className={`form-control mt-2 ${!!errors.fatherName && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Father Name is required'}</span>}

                                                            </div>

                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('fatherOccupation')}
                                                                    type="text"
                                                                    name="fatherOccupation"
                                                                    defaultValue={data.fatherOccupation}
                                                                    className={`form-control mt-2 ${!!errors.fatherOccupation && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Father Occupation is required'}</span>}

                                                            </div>


                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Occupation Address <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('fatherOccupationAddress')}
                                                                    type="text"
                                                                    name="fatherOccupationAddress"
                                                                    defaultValue={data.fatherOccupationAddress}
                                                                    className={`form-control mt-2 ${!!errors.fatherOccupationAddress && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Address is required'}</span>}

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-6 mb-3">
                                                                <label className="col-form-label p-0">Contact# <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('fatherContactNumber')}
                                                                    type="text"
                                                                    name="fatherContactNumber"
                                                                    defaultValue={data.fatherContactNumber}
                                                                    className={`form-control mt-2 ${!!errors.fatherContactNumber && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Contact No is required'}</span>}

                                                            </div>
                                                            <div className="form-group col-12 col-md-6 mb-3">
                                                                <label className="col-form-label p-0">Home Address <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('fatherHomeAddress')}
                                                                    type="text"
                                                                    name="fatherHomeAddress"
                                                                    defaultValue={data.fatherHomeAddress}
                                                                    className={`form-control mt-2 ${!!errors.fatherHomeAddress && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Address is required'}</span>}

                                                            </div>

                                                        </div>
                                                        <div className='row'>

                                                            <div className='col-12 col-md-4 mb-0'>
                                                                <IMSelect
                                                                    data={civilStatusSelect}
                                                                    onHandleChange={selectHandleChange('fatherMaritalStatus')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="fatherMaritalStatus"
                                                                    isRequired={false}
                                                                    withLabel={true}
                                                                    label="Marital Status"
                                                                    placeHolder="Marital Status"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.fatherMaritalStatus}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.civil}
                                                                    errorMessage={errors.civil && errors.civil.type === "required" && 'Civil Status is required'}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-4 mb-0'>
                                                                <IMSelect
                                                                    data={nationalitySelect}
                                                                    onHandleChange={selectHandleChange('fatherCitizenship')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="fatherCitizenship"
                                                                    isRequired={false}
                                                                    withLabel={true}
                                                                    label="Citizenship"
                                                                    placeHolder="Citizenship"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.fatherCitizenship}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.fatherCitizenship}
                                                                    errorMessage={errors.fatherCitizenship && errors.fatherCitizenship.type === "required" && 'Civil Status is required'}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-4 mb-0'>
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange(`fatherHighestEducationalAttainment`)}
                                                                    refClassContainer="form-group mb-3"
                                                                    name={`fatherHighestEducationalAttainment`}
                                                                    isRequired={false}
                                                                    withLabel={true}
                                                                    label="Highest Educational Attainment"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.fatherHighestEducationalAttainment}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: 1, label: 'Preschool' },
                                                                        { id: 2, label: 'Elementary' },
                                                                        { id: 3, label: 'Highschool' },
                                                                        { id: 4, label: 'Bachelor Degree' },
                                                                        { id: 5, label: 'Masteral Degree' },
                                                                        { id: 6, label: 'Doctoral Degree' }


                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.fatherHighestEducationalAttainment}
                                                                    errorMessage={errors.fatherHighestEducationalAttainment && errors.fatherHighestEducationalAttainment.type === "required" && 'Educational Attainment is required'}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>



                                                <div className={'card mt-5 mr-2'}  >

                                                    <h5 className="card-header bg-secondary p-5 is-invalid">{'Mother\'s Information'}</h5>
                                                    <div className='card-body p-3'>
                                                        <div className='row'>




                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Full Name <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('motherName')}
                                                                    type="text"
                                                                    name="motherName"
                                                                    defaultValue={data.motherName}
                                                                    className={`form-control mt-2 ${!!errors.motherName && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Mother Name is required'}</span>}

                                                            </div>

                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('motherOccupation')}
                                                                    type="text"
                                                                    name="motherOccupation"
                                                                    defaultValue={data.motherOccupation}
                                                                    className={`form-control mt-2 ${!!errors.motherOccupation && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Occupation is required'}</span>}

                                                            </div>


                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Occupation Address <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('motherOccupationAddress')}
                                                                    type="text"
                                                                    name="motherOccupationAddress"
                                                                    defaultValue={data.motherOccupationAddress}
                                                                    className={`form-control mt-2 ${!!errors.motherOccupationAddress && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Address is required'}</span>}

                                                            </div>
                                                        </div>
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-6 mb-3">
                                                                <label className="col-form-label p-0">Contact# <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('motherContactNumber')}
                                                                    type="text"
                                                                    name="motherContactNumber"
                                                                    defaultValue={data.motherContactNumber}
                                                                    className={`form-control mt-2 ${!!errors.motherContactNumber && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Contact No is required'}</span>}

                                                            </div>
                                                            <div className="form-group col-12 col-md-6 mb-3">
                                                                <label className="col-form-label p-0">Home Address <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('motherHomeAddress')}
                                                                    type="text"
                                                                    name="motherHomeAddress"
                                                                    defaultValue={data.fatherHomeAddress}
                                                                    className={`form-control mt-2 ${!!errors.motherHomeAddress && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Address is required'}</span>}

                                                            </div>

                                                        </div>
                                                        <div className='row'>

                                                            <div className='col-12 col-md-4 mb-0'>
                                                                <IMSelect
                                                                    data={civilStatusSelect}
                                                                    onHandleChange={selectHandleChange('motherMaritalStatus')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="motherMaritalStatus"
                                                                    isRequired={false}
                                                                    withLabel={true}
                                                                    label="Marital Status"
                                                                    placeHolder="Marital Status"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.motherMaritalStatus}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.motherMaritalStatus}
                                                                    errorMessage={errors.motherMaritalStatus && errors.motherMaritalStatus.type === "required" && 'Civil Status is required'}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-4 mb-0'>
                                                                <IMSelect
                                                                    data={nationalitySelect}
                                                                    onHandleChange={selectHandleChange('motherCitizenship')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="motherCitizenship"
                                                                    isRequired={false}
                                                                    withLabel={true}
                                                                    label="Citizenship"
                                                                    placeHolder="Citizenship"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.motherCitizenship}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.motherCitizenship}
                                                                    errorMessage={errors.motherCitizenship && errors.motherCitizenship.type === "required" && 'Civil Status is required'}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-4 mb-0'>
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange(`motherHighestEducationalAttainment`)}
                                                                    refClassContainer="form-group mb-3"
                                                                    name={`motherHighestEducationalAttainment`}
                                                                    isRequired={false}
                                                                    withLabel={true}
                                                                    label="Highest Educational Attainment"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.motherHighestEducationalAttainment}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: 1, label: 'Preschool' },
                                                                        { id: 2, label: 'Elementary' },
                                                                        { id: 3, label: 'Highschool' },
                                                                        { id: 4, label: 'Bachelor Degree' },
                                                                        { id: 5, label: 'Masteral Degree' },
                                                                        { id: 6, label: 'Doctoral Degree' }


                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.motherHighestEducationalAttainment}
                                                                    errorMessage={errors.motherHighestEducationalAttainment && errors.motherHighestEducationalAttainment.type === "required" && 'Gender is required'}
                                                                />

                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>


                                                {/* {siblingsComponent} */}


                                                {/* <FamilyIncomeForm
infoHandler={monthlyIncomeInfoHandler}
types={applicantTypes}
getFamilyNetIncome={getFamilyNetIncome}
getEducationalPlan={getEducationalPlan}
getScholarship={getScholarship}
getFamilyPosition={getFamilyPosition}
netIncomeSelect={familyIncomeSelect}
educationalPlanSelect={educationalPlanSelect}
scholarshipSelect={scholarshipSelect}
familyPositionSelect={familyPositionSelect}
/> */}



                                            </div>

                                            {studentSiblingIndex.map(d => {
                                                console.log(d)
                                                console.log(studentSiblingIndex.length)
                                                return (
                                                    <>
                                                        <div className={`card mr-2 mb-5  ${d == studentSiblingIndex.length - 1 ? 'slide-inStudentForm' : ''}`}  >
                                                            <section className='card-header bg-secondary p-5 is-invalid'>

                                                                <h5 className="float-left">{`Sibling #${d + 1} Information`}</h5>
                                                                {d == studentSiblingIndex.length - 1 &&
                                                                    <button type='button' onClick={() => { setStudentSiblingIndex((prevData) => { return [...prevData, d + 1] }) }} className='btn btn-primary btn-sm float-right'>Add more Sibling</button>

                                                                }
                                                            </section>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>




                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Full Name   </label>
                                                                        <input
                                                                            onChange={handleChangeInput(`siblingname_${d}`)}
                                                                            type="text"
                                                                            name={`siblingname_${d}`}
                                                                            defaultValue={data[`siblingname_${d}`]}
                                                                            className={`form-control mt-2 `}
                                                                            ref={register({
                                                                                required: false
                                                                            })}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>



                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">School / Company  </label>
                                                                        <input
                                                                            onChange={handleChangeInput(`siblingaffiliation_${d}`)}
                                                                            type="text"
                                                                            name={`siblingaffiliation_${d}`}
                                                                            defaultValue={data[`siblingaffiliation_${d}`]}
                                                                            className={`form-control mt-2 `}
                                                                            ref={register({
                                                                                required: false
                                                                            })}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Contact#  </label>
                                                                        <input
                                                                            onChange={handleChangeInput(`siblingcontactNo_${d}`)}
                                                                            type="number"
                                                                            name={`siblingcontactNo_${d}`}
                                                                            defaultValue={data[`siblingcontactNo_${d}`]}
                                                                            className={`form-control mt-2`}
                                                                            ref={register({
                                                                                required: false
                                                                            })}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>



                                                                </div>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Age  </label>
                                                                        <input
                                                                            onChange={handleChangeInput(`siblingage_${d}`)}
                                                                            type="text"
                                                                            name={`siblingage_${d}`}
                                                                            defaultValue={data[`siblingage_${d}`]}
                                                                            className={`form-control mt-2 `}
                                                                            ref={register({
                                                                                required: false
                                                                            })}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>
                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            data={civilStatusSelect}
                                                                            onHandleChange={selectHandleChange(`siblingcivilStatusId_${d}`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`siblingcivilStatusId_${d}`}
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Marital Status"
                                                                            placeHolder="Marital Status"
                                                                            forwardRef={setValue}
                                                                            selectedId={data[`siblingcivilStatusId_${d}`]}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}

                                                                        />
                                                                    </div>
                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect

                                                                            data={yearLevelSelect}
                                                                            onHandleChange={selectHandleChange(`siblingyearLevelId_${d}`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`siblingyearLevelId_${d}`}
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Year Level"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data[`siblingyearLevelId_${d}`]}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[


                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />
                                                                    </div>

                                                                </div>

                                                            </div>

                                                        </div>
                                                    </>
                                                )
                                            })}
                                            <div className="kt-portlet__body my-5">
                                                <div className={'card mr-2'}  >
                                                    <div className='card-header bg-secondary pt-4 pl-5 pr-5 pb-0 is-invalid '>
                                                        <h5 className="mr-5 mb-0">Guardian</h5>
                                                        <FormControl component="fieldset" className="p-0">
                                                            <FormControlLabel
                                                                name="IsLivingWithParents"
                                                                inputRef={register()}
                                                                control={<Switch checked={switchState.IsLivingWithParents} onChange={switchHandleChange('IsLivingWithParents')} defaultValue={switchState.IsLivingWithParents} />}
                                                                label="Living with parents"
                                                                className='p-0'

                                                            />
                                                        </FormControl>
                                                    </div>

                                                    <div className='card-body p-3'>

                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Guardian <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    type="text"
                                                                    name="nameGuardian"
                                                                    defaultValue={data.nameGuardian}
                                                                    className={`form-control ${!!errors.nameGuardian && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"
                                                                />
                                                                {!!errors.nameGuardian && <span className="form-text text-danger">{errors.nameGuardian && errors.nameGuardian.type === "required" && 'Name of Guardian is required'}</span>}


                                                            </div>
                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Address<span className="text-danger">*</span>  </label>
                                                                <input
                                                                    type="text"
                                                                    name="addressGuardian"
                                                                    defaultValue={data.addressGuardian}
                                                                    className={`form-control ${!!errors.addressGuardian && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"
                                                                />
                                                                {!!errors.addressGuardian && <span className="form-text text-danger">{errors.addressGuardian && errors.addressGuardian.type === "required" && 'Address is required'}</span>}

                                                            </div>
                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Contact No/s<span className="text-danger">*</span>  </label>
                                                                <input
                                                                    type="number"
                                                                    name="contactNoGuardian"
                                                                    defaultValue={data.contactNoGuardian}
                                                                    className={`form-control ${!!errors.contactNoGuardian && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"
                                                                />
                                                                {!!errors.contactNoGuardian && <span className="form-text text-danger">{errors.contactNoGuardian && errors.contactNoGuardian.type === "required" && 'Address is required'}</span>}

                                                            </div>
                                                            <div className="form-group col-12 col-md-4 mb-5">
                                                                <label className="col-form-label p-0">Relationship <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    type="text"
                                                                    name="relationshipGuardian"
                                                                    defaultValue={data.relationshipGuardian}
                                                                    className={`form-control ${!!errors.relationshipGuardian && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"
                                                                />
                                                                {!!errors.relationshipGuardian && <span className="form-text text-danger">{errors.relationshipGuardian && errors.relationshipGuardian.type === "required" && 'Relationship is required'}</span>}

                                                            </div>
                                                            <div className="form-group col-12 col-md-4 mb-5">
                                                                <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    type="text"
                                                                    name="occupationGuardian"
                                                                    defaultValue={data.occupationGuardian}
                                                                    className={`form-control ${!!errors.occupationGuardian && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"
                                                                />
                                                                {!!errors.occupationGuardian && <span className="form-text text-danger">{errors.occupationGuardian && errors.occupationGuardian.type === "required" && 'Occupation is required'}</span>}

                                                            </div>

                                                            <div className="form-group col-12 col-md-4 mb-5">
                                                                <label className="col-form-label p-0">Family Monthly Income <span className="text-danger">*</span>  </label>
                                                                <div class="input-group mb-3">
                                                                    <div class="input-group-prepend">
                                                                        <span class="input-group-text" id="basic-addon1">₱</span>
                                                                    </div>
                                                                    <input
                                                                        type="number"
                                                                        name="familyMonthlyIncome"
                                                                        defaultValue={data.familyMonthlyIncome}
                                                                        className={`form-control ${!!errors.familyMonthlyIncome && ' is-invalid'}`}
                                                                        ref={register({
                                                                            required: true
                                                                        })}
                                                                        autoComplete="off"
                                                                        maxLength="100"
                                                                    />
                                                                </div>
                                                                {!!errors.familyMonthlyIncome && <span className="form-text text-danger">{errors.familyMonthlyIncome && errors.familyMonthlyIncome.type === "required" && 'Family Income is required'}</span>}

                                                            </div>
                                                        </div>
                                                        {errorCondition()}
                                                    </div>
                                                </div>



                                            </div>

                                            <div className="kt-portlet__body mt-5">
                                                <h4><i className="fas fa-user-graduate icon-xl"></i> Educational Background</h4> <hr />
                                                {/* PRE ELEM */}
                                                <div className='card mt-5'>

                                                    <h5 className="card-header bg-secondary p-5">Pre - Elementary</h5>
                                                    <div className='card-body p-3'>
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange(`preElementaryId`)}
                                                                    refClassContainer="form-group mb-3"
                                                                    name={`preElementaryId`}
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="School Name"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preElementaryId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.preElementaryId}
                                                                    errorMessage={errors.preElementaryId && errors.preElementaryId.type === "required" && 'Previous School is required'}
                                                                />



                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                <input
                                                                    disabled={isNotListedSchool.preElem == false ? true : false}
                                                                    onChange={handleChangeInput('preElementaryNotListedSchool')}
                                                                    type="text"
                                                                    name="preElementaryNotListedSchool"
                                                                    defaultValue={data.preElementaryNotListedSchool}
                                                                    className={`form-control mt-2 ${!!errors.preElementaryNotListedSchool && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: false
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && ''}</span>}

                                                            </div>

                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('preElementaryFromYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="preElementaryFromYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="From"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preElementaryFromYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.preElementaryFromYear}
                                                                    errorMessage={errors.preElementaryFromYear && errors.preElementaryFromYear.type === "required" && 'Year is required'}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('preElementaryToYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="preElementaryToYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="To"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.preElementaryToYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.preElementaryToYear}
                                                                    errorMessage={errors.preElementaryToYear && errors.preElementaryToYear.type === "required" && 'Year is required'}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* ELEM */}
                                                <div className='card mt-5'>

                                                    <h5 className="card-header bg-secondary p-5">Elementary</h5>
                                                    <div className='card-body p-3'>
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange(`elementaryId`)}
                                                                    refClassContainer="form-group mb-3"
                                                                    name={`elementaryId`}
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="School Name"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.elementaryId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.elementaryId}
                                                                    errorMessage={errors.elementaryId && errors.elementaryId.type === "required" && 'Previous School is required'}
                                                                />



                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                <input
                                                                    disabled={isNotListedSchool.elem == false ? true : false}
                                                                    onChange={handleChangeInput('elementaryNotListedSchool')}
                                                                    type="text"
                                                                    name="elementaryNotListedSchool"
                                                                    defaultValue={data.elementaryNotListedSchool}
                                                                    className={`form-control mt-2 ${!!errors.elementaryNotListedSchool && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: false
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && ''}</span>}

                                                            </div>

                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('elementaryFromYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="elementaryFromYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="From"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.elementaryFromYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.elementaryFromYear}
                                                                    errorMessage={errors.elementaryFromYear && errors.elementaryFromYear.type === "required" && 'Year is required'}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('elementaryToYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="elementaryToYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="To"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.elementaryToYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.elementaryToYear}
                                                                    errorMessage={errors.elementaryToYear && errors.elementaryToYear.type === "required" && 'Year is required'}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* JR HIGH */}
                                                <div className='card mt-5'>

                                                    <h5 className="card-header bg-secondary p-5">Junior High</h5>
                                                    <div className='card-body p-3'>
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange(`juniorHighId`)}
                                                                    refClassContainer="form-group mb-3"
                                                                    name={`juniorHighId`}
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="School Name"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.juniorHighId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.juniorHighId}
                                                                    errorMessage={errors.juniorHighId && errors.juniorHighId.type === "required" && 'Previous School is required'}
                                                                />



                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                <input
                                                                    disabled={isNotListedSchool.jrHigh == false ? true : false}
                                                                    onChange={handleChangeInput('juniorHighNotListedSchool')}
                                                                    type="text"
                                                                    name="juniorHighNotListedSchool"
                                                                    defaultValue={data.juniorHighNotListedSchool}
                                                                    className={`form-control mt-2 ${!!errors.juniorHighNotListedSchool && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: false
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && ''}</span>}

                                                            </div>

                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('juniorHighFromYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="juniorHighFromYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="From"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.juniorHighFromYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.juniorHighFromYear}
                                                                    errorMessage={errors.juniorHighFromYear && errors.juniorHighFromYear.type === "required" && 'Year is required'}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('juniorHighToYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="juniorHighToYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="To"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.juniorHighToYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.juniorHighToYear}
                                                                    errorMessage={errors.juniorHighToYear && errors.juniorHighToYear.type === "required" && 'Year is required'}
                                                                />
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* SR HIGH */}
                                                <div className='card mt-5'>

                                                    <h5 className="card-header bg-secondary p-5">Senior High</h5>
                                                    <div className='card-body p-3'>
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange(`seniorHighId`)}
                                                                    refClassContainer="form-group mb-3"
                                                                    name={`seniorHighId`}
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="School Name"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.seniorHighId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                    error={!!errors.seniorHighId}
                                                                    errorMessage={errors.seniorHighId && errors.seniorHighId.type === "required" && 'Previous School is required'}
                                                                />



                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                <input
                                                                    disabled={isNotListedSchool.srHigh == false ? true : false}
                                                                    onChange={handleChangeInput('seniorHighNotListedSchool')}
                                                                    type="text"
                                                                    name="seniorHighNotListedSchool"
                                                                    defaultValue={data.juniorHighNotListedSchool}
                                                                    className={`form-control mt-2 ${!!errors.juniorHighNotListedSchool && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: false
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>

                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('seniorHighFromYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="seniorHighFromYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="From"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.seniorHighFromYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.seniorHighFromYear}
                                                                    errorMessage={errors.seniorHighFromYear && errors.seniorHighFromYear.type === "required" && 'Gender is required'}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('seniorHighToYear')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="seniorHighToYear"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="To"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.seniorHighToYear}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: '2023', label: '2023' },
                                                                        { id: '2022', label: '2022' },
                                                                        { id: '2021', label: '2021' },
                                                                        { id: '2020', label: '2020' },
                                                                        { id: '2019', label: '2019' },
                                                                        { id: '2018', label: '2018' },
                                                                        { id: '2017', label: '2017' },
                                                                        { id: '2016', label: '2016' },
                                                                        { id: '2015', label: '2015' },
                                                                        { id: '2014', label: '2014' },
                                                                        { id: '2013', label: '2013' },
                                                                        { id: '2012', label: '2012' },
                                                                        { id: '2011', label: '2011' },
                                                                        { id: '2010', label: '2010' },
                                                                        { id: '2009', label: '2009' },
                                                                        { id: '2008', label: '2008' },
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}
                                                                    error={!!errors.seniorHighToYear}
                                                                    errorMessage={errors.seniorHighToYear && errors.seniorHighToYear.type === "required" && 'Gender is required'}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-12 mb-3">
                                                                <label className="col-form-label p-0">Track / Strand <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={handleChangeInput('seniorHighStrand')}
                                                                    type="text"
                                                                    name="seniorHighStrand"
                                                                    defaultValue={data.seniorHighStrand}
                                                                    className={`form-control mt-2 ${!!errors.seniorHighStrand && ' is-invalid'}`}
                                                                    ref={register({
                                                                        required: true
                                                                    })}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                                {/* <EducationBackground
                                            label='Pre-Elementary'
                                            className='card mt-5'
                                            infoHandler={preElementaryInfoHandler}
                                            name='preElementary'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}
                                        />
                                        <EducationBackground
                                            label='Elementary'
                                            className='card mt-5'
                                            infoHandler={elementaryInfoHandler}
                                            name='elementary'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}


                                        />
                                        <EducationBackground
                                            label='Junior High School'
                                            className='card mt-5'
                                            infoHandler={jrHighSchoolInfoHandler}
                                            name='jrHighSchool'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}


                                        />
                                        <EducationBackground
                                            label='Senior High School'
                                            className='card mt-5'
                                            infoHandler={srHighSchoolInfoHandler}
                                            name='srHighSchool'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}


                                        /> */}






                                            </div>

                                            <div className="kt-portlet__body mt-5 float-left row">

                                                <div className="form-check col-12">
                                                    <input required name='isAllowedToTransact' type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">I Allow to transact with FAITH on my behalf and recieve confidential information / records as my authorized representative</label>
                                                </div>
                                                <br />
                                                <div className="form-check col-12">
                                                    <input required name='isReadDataPrivacy' type="checkbox" className="form-check-input" id="exampleCheck1" />
                                                    <label className="form-check-label" htmlFor="exampleCheck1">I have read the <a href='https://www.firstasia.edu.ph/privacy-notice/'>Data Privacy Notice</a></label>
                                                </div>


                                            </div>
                                            <hr />
                                            <div className="kt-portlet__body mt-5 float-left row">
                                                {processingRegistration == false ?
                                                    <button type="submit" className="btn btn-primary col-12">Submit</button>
                                                    :
                                                    <button className="btn btn-secondary" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        Processing Registration, Please wait...
                                                    </button>

                                                }



                                            </div>



                                        </form>
                                    </>
                                    :
                                    <>
                                        <section className='text-center'>
                                            {/* <div className="spinner-grow" style={{ 'width': '3rem', 'height': '3rem' }} role="status">
                                        <span className="sr-only">Loading...</span>
                                    </div> */}
                                            <img style={{ width: '50px' }} src={FetchLoad} />

                                            <p>Please wait while we fetch your previous information.</p>
                                        </section>

                                    </>
                                }

                            </div>

                        </div >
                    </main>

                </>







                : <Loading />

            }



        </>
    );
}