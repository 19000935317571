import React, { useState, useEffect } from "react";
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import {
   addingDroppingOfSubjects
} from "./__hooks__/index"

export default function AddDropExcelFormat({ fileName, btnName, page, keyword, rowsPerPage, schoolYear, semesterId, subjectId, studentId }) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const [extractReport, setExtractReport] = useState([]);
    const [excelFormat, setExcelFormat] = useState([]);

    let schoolYearTo = schoolYear + 1;

    //Initial API call
    useEffect(() => {
        addingDroppingOfSubjects({
            rowsPerPage: rowsPerPage,
            page: page,
            keyword: keyword != null ? keyword.trim() : null,
            schoolYear: schoolYear,
            semesterId: semesterId,
            //subjectId: subjectId,
            //studentId: studentId,
            //subjectStatusId: 1,
            extractAll: 1,
        }).then(res => {
            setExtractReport(res.data);
        })
    }, [schoolYear, semesterId, subjectId, studentId, keyword]);

    //useEffect when extracReport is updated
    useEffect(() => {
        console.log("extractReport: ",extractReport);
        if (typeof(extractReport) !== "undefined") {
            let arr = []
            for (let i = 0; i < extractReport.length; i++) {
                arr.push({
                    
                    '#': i+1,
                    'Student No': `${extractReport[i].StudentNo}`,
                    'Student Name': `${extractReport[i].StudentName}`,
                    'Class Code': `${extractReport[i].ClassCode.toString()}`,
                    'Subject Code': `${extractReport[i].SubjectCode}`,
                    'Subject Description': `${extractReport[i].Description}`,
                    'Institute': `${extractReport[i].Institute}`,
                    'Course': `${extractReport[i].Course}`,
                    'Year/Level': `${extractReport[i].YearLevel}`,
                    'Section': `${extractReport[i].ClassSection === null ? '' : extractReport[i].ClassSection}`,
                    'Date Processed': `${extractReport[i].DateCreated}`,
                    'Processed By': `${extractReport[i].CreatedBy}`,
                    'Action Taken': `${extractReport[i].ActionTaken}`
                })
            }
            console.log(arr)
            setExcelFormat(arr)
        }
    }, [extractReport])

    const exportToExcel = () => {

        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];

        var currentdate = new Date(); 
        var datetime = "as of " 
            + month[currentdate.getMonth()] + ' ' 
            + currentdate.getDate() + ', ' 
            + currentdate.getFullYear() + ' ' 
            + currentdate.getHours() + ":" 
            + currentdate.getMinutes() + ":" 
            + currentdate.getSeconds();

        let cellHeaderNameA1 = 'Add and Drop Report';
        let cellHeaderNameA2 = 'SY ' + schoolYear + '-' + schoolYearTo + ' - ' + semesterId;
        let cellHeaderNameA4 = '#';
        let cellHeaderNameB4 = 'Student No';
        let cellHeaderNameC4 = 'Student Name';
        let cellHeaderNameD4 = 'Class Code';
        let cellHeaderNameE4 = 'Subject Code';
        let cellHeaderNameF4 = 'Subject Description';
        let cellHeaderNameG4 = 'Institute';
        let cellHeaderNameH4 = 'Course';
        let cellHeaderNameI4 = 'Year/Level';
        let cellHeaderNameJ4 = 'Section';
        let cellHeaderNameK4 = 'Date Processed';
        let cellHeaderNameL4 = 'Processed By';
        let cellHeaderNameM4 = 'Action Taken';

                
        worksheet.getCell('A1').value = cellHeaderNameA1.toUpperCase();
        worksheet.getCell('A1').font = { bold: true, color: {argb:'ff000000'}, size: 20 };
        
        worksheet.getCell('A2').value = cellHeaderNameA2.toUpperCase();
        worksheet.getCell('A2').font = { bold: true, color: {argb:'ff000000'}, size: 15 };
        
        worksheet.getCell('C2').value = datetime;

        worksheet.getCell('A4').value = cellHeaderNameA4.toUpperCase();
        worksheet.getCell('B4').value = cellHeaderNameB4.toUpperCase();
        worksheet.getCell('C4').value = cellHeaderNameC4.toUpperCase();
        worksheet.getCell('D4').value = cellHeaderNameD4.toUpperCase();
        worksheet.getCell('E4').value = cellHeaderNameE4.toUpperCase();
        worksheet.getCell('F4').value = cellHeaderNameF4.toUpperCase();
        worksheet.getCell('G4').value = cellHeaderNameG4.toUpperCase();
        worksheet.getCell('H4').value = cellHeaderNameH4.toUpperCase();
        worksheet.getCell('I4').value = cellHeaderNameI4.toUpperCase();
        worksheet.getCell('J4').value = cellHeaderNameJ4.toUpperCase();
        worksheet.getCell('K4').value = cellHeaderNameK4.toUpperCase();
        worksheet.getCell('L4').value = cellHeaderNameL4.toUpperCase();
        worksheet.getCell('M4').value = cellHeaderNameM4.toUpperCase();
        
        worksheet.getCell('A4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('B4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('C4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('D4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('E4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('F4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('G4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('H4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('I4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('J4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('K4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('L4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        worksheet.getCell('M4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
        
        worksheet.getCell('A4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('B4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('C4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('D4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('E4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('F4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('G4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('H4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('I4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('J4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('K4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('L4').font = { bold: true, color: {argb:'ffffffff'} };
        worksheet.getCell('M4').font = { bold: true, color: {argb:'ffffffff'} };

        // Add headers
        const headers = Object.keys(excelFormat[0]);
        // worksheet.addRow(headers);

        // Add data rows
        excelFormat.forEach((data) => {
            const row = [];
            headers.forEach((header) => {
                row.push(data[header]);
            });
            worksheet.addRow(row);
        });


        // Adjust column widths based on content
        worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? (cell.value.toString().length + 3) : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        // Generate a blob from the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        });
    };

    return (
        <>
            <button onClick={exportToExcel} className="btn btn-success btn-block">{btnName}</button>
        </>
    );
}
