import instance from '../../../../../apis/local/systemAPI';

/**************************************************** */

export const instituteDropdown = async () => {
    const result = await instance.post("api/student/student-attendance/instituteDropdown");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const instructorDropdown = async (json) => {
    const result = await instance.post("api/student/student-attendance/instructorDropdown",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceSheet = async (json) => {
    const result = await instance.post("api/student/student-attendance/attendanceSheet",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceDropdownChecker = async (val) => {
    const result = await instance.post(`api/student/student-attendance/attendanceDropdownChecker`,val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceSearchSubjects = async (val) => {
    const result = await instance.post(`api/student/student-attendance/attendanceSearchSubjects`,val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceSubjectSchedId = async (val) => {
    const result = await instance.post(`api/student/student-attendance/attendanceSubjectSchedId`,val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCustomCourseList = async () => {
    const result = await instance.get("api/student/student-grade-viewing/getCustomCourseList/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/student/student-grade-viewing/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSummaryOfGradesReport = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getSummaryOfGradesReport`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}