import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { ScholarshipTagSelect } from '../ScholarshipTag/Select';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import { scholarshipApplySelect } from "./__hooks__/index"
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [selectApply, setSelectApply] = useState(null)
  const [isPayableScholarship, setIsPayableScholarship] = useState(false)
  const [data, setData] = useState({
    scholarshipTagId: null,
    applyType: null,
    scholarshipType: null,
  })
  const [switchState, setSwitchState] = useState({
    IsOverride: props.doc.IsOverride === undefined ? false : props.doc.IsOverride === 0 ? false : true,
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    const getSelect = async () => {
      await scholarshipApplySelect({ test: 1 })
        .then(res => {
          setSelectApply(res.data)
        })
    }
    getSelect()

  }, [])

  useEffect(() => {
    register({ name: 'scholarshipTagId' });
    register({ name: 'applyType' });
    register({ name: 'scholarshipType' });
    register({ name: 'payableAmount' });

  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    if (name == 'applyType' && event == 'AP') {
      setIsPayableScholarship(true)
      setData({ ...data, [name]: event });

    } else {
      setIsPayableScholarship(false)
      setData({ ...data, [name]: event });

    }
  }

  const onSubmit = formValues => {
    const { code, shortName, scholarship, applyRate, applyType, scholarshipType, scholarshipTagId, applySeq, admSeq, admRep, isOverride, isActive, applyTo } = formValues;

    const convertedFormValues = {
      code: code,
      shortName: shortName,
      scholarship: scholarship,
      applyRate: applyRate,
      applyType: applyType,
      scholarshipType: scholarshipType,
      scholarshipTagId: scholarshipTagId,
      applySeq: applySeq,
      admSeq: admSeq,
      admRep: admRep,
      isOverride: isOverride ? 1 : 0,
      isActive: isActive ? 1 : 0,
      applyTo: data.applyTo
    }

    console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  console.log(data)
  console.log(selectApply)

  return (
    <>
      {selectApply !== null ?

        <>
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label">Form Details</h3>
              </div>
            </div>
            <div className="card-body">
              <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
                <div className="kt-portlet__body">
                  <div className="form-group">
                    <label className="col-form-label">Code <span className="text-danger">*</span> : </label>
                    <input
                      type="text"
                      name="code"
                      defaultValue={props.doc.Code === undefined ? "" : props.doc.Code}
                      className={`form-control${errors.code === undefined ? "" : errors.code && " is-invalid"}`}
                      ref={register({
                        required: true
                      })}
                      autoComplete="off"
                    />
                    <span className="form-text text-muted">Please enter your code.</span>
                    {errors.code && errors.code.type === "required" && <div className="invalid-feedback">Code is required</div>}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Short Name <span className="text-danger">*</span> : </label>
                    <input
                      type="text"
                      name="shortName"
                      defaultValue={props.doc.ShortName === undefined ? "" : props.doc.ShortName}
                      className={`form-control${errors.shortName === undefined ? "" : errors.shortName && " is-invalid"}`}
                      ref={register({
                        required: true
                      })}
                      autoComplete="off"
                    />
                    <span className="form-text text-muted">Please enter your short name.</span>
                    {errors.shortName && errors.shortName.type === "required" && <div className="invalid-feedback">Short name is required</div>}
                  </div>
                  <div className="form-group">
                    <label className="col-form-label">Scholarship <span className="text-danger">*</span> : </label>
                    <input
                      type="text"
                      name="scholarship"
                      defaultValue={props.doc.Scholarship === undefined ? "" : props.doc.Scholarship}
                      className={`form-control${errors.scholarship === undefined ? "" : errors.scholarship && " is-invalid"}`}
                      ref={register({
                        required: true
                      })}
                      autoComplete="off"
                    />
                    <span className="form-text text-muted">Please enter your scholarship.</span>
                    {errors.scholarship && errors.scholarship.type === "required" && <div className="invalid-feedback">Scholarship is required</div>}
                  </div>
                  <IMSelect
                    onHandleChange={selectHandleChange('applyType')}
                    refClassContainer="form-group"
                    name="applyType"
                    isRequired={true}
                    withLabel={true}
                    label="Apply Type"
                    placeHolder="Apply Type"
                    forwardRef={setValue}
                    selectedId={data.applyType === null ? props.doc.ApplyType : data.applyType}
                    refClassName={``}
                    withDescription={true}
                    description={`Please enter your apply type.`}
                    refDisabled={true}
                    refIsStatic={true}
                    refStaticData={[
                      { id: '', label: '' },
                      { id: 'A', label: 'Amount' },
                      { id: 'P', label: 'Percentage' },
                      { id: 'AP', label: 'Amount Payable' }
                    ]}
                    field={{
                      tableId: '',
                      display: ''
                    }}
                    error={!!errors.applyType}
                    errorMessage={errors.applyType && errors.applyType.type === "required" && 'Apply type is required'}
                  />

                  {isPayableScholarship == false ?
                    <>
                      <div className="form-group">
                        <IMSelect
                          data={selectApply}
                          onHandleChange={selectHandleChange('applyTo')}
                          refClassContainer="form-group"
                          name="applyTo"
                          isRequired={true}
                          withLabel={true}
                          label="Apply To"
                          placeHolder="Apply To"
                          forwardRef={setValue}
                          selectedId={data.applyTo === null ? props.doc.ApplyTo : data.applyTo}
                          refClassName={``}
                          withDescription={true}
                          description={`Please enter your apply type.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                          error={!!errors.applyType}
                          errorMessage={errors.applyType && errors.applyType.type === "required" && 'Apply type is required'}
                        />
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Apply Rate <span className="text-danger">*</span> : </label>
                        <input
                          type="number"
                          name="applyRate"
                          defaultValue={props.doc.ApplyRate === undefined ? "" : props.doc.ApplyRate}
                          className={`form-control${errors.applyRate === undefined ? "" : errors.applyRate && " is-invalid"}`}
                          ref={register({
                            required: true
                          })}
                          autoComplete="off"
                        />
                        <span className="form-text text-muted">Please enter your apply rate.</span>
                        {errors.applyRate && errors.applyRate.type === "required" && <div className="invalid-feedback">Apply rate is required</div>}
                      </div>

                      <IMSelect
                        onHandleChange={selectHandleChange('scholarshipType')}
                        refClassContainer="form-group"
                        name="scholarshipType"
                        isRequired={true}
                        withLabel={true}
                        label="Scholarship Type"
                        placeHolder="Scholarship Type"
                        forwardRef={setValue}
                        selectedId={data.scholarshipType === null ? props.doc.ScholarshipType : data.scholarshipType}
                        refClassName={``}
                        withDescription={true}
                        description={`Please enter your scholarship type.`}
                        refDisabled={true}
                        refIsStatic={true}
                        refStaticData={[
                          { id: '', label: '' },
                          { id: 'D', label: 'Discount' },
                          { id: 'S', label: 'Scholarship' }
                        ]}
                        field={{
                          tableId: '',
                          display: ''
                        }}
                        error={!!errors.scholarshipType}
                        errorMessage={errors.scholarshipType && errors.scholarshipType.type === "required" && 'Scholarship type is required'}
                      />
                      <ScholarshipTagSelect
                        refOnHandleChange={selectHandleChange('scholarshipTagId')}
                        refClassContainer="form-group"
                        refWithLabel={true}
                        refIsRequired={true}
                        forwardRef={setValue}
                        selectedId={props.doc.ScholarshipTagId === undefined ? "" : props.doc.ScholarshipTagId}
                        refWithFilter={false}
                        refClassName={``}
                        refWithDescription={true}
                        refDisabled={true}
                        refError={!!errors.scholarshipTagId}
                        refErrorMessage={errors.scholarshipTagId && errors.scholarshipTagId.type === "required" && 'Scholarship tag is required'}
                      />
                      <div className="form-group">
                        <label className="col-form-label">Sequence <span className="text-danger">*</span> : </label>
                        <input
                          type="number"
                          name="applySeq"
                          defaultValue={props.doc.ApplySeq === undefined ? "" : props.doc.ApplySeq}
                          className={`form-control${errors.applySeq === undefined ? "" : errors.applySeq && " is-invalid"}`}
                          ref={register({
                            required: true
                          })}
                          autoComplete="off"
                        />
                        <span className="form-text text-muted">Please enter your sequence.</span>
                        {errors.applySeq && errors.applySeq.type === "required" && <div className="invalid-feedback">Sequence is required</div>}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Admission Sequence <span className="text-danger">*</span> : </label>
                        <input
                          type="number"
                          name="admSeq"
                          defaultValue={props.doc.AdmSeq === undefined ? "" : props.doc.AdmSeq}
                          className={`form-control${errors.admSeq === undefined ? "" : errors.admSeq && " is-invalid"}`}
                          ref={register({
                            required: true
                          })}
                          autoComplete="off"
                        />
                        <span className="form-text text-muted">Please enter your admission sequence.</span>
                        {errors.admSeq && errors.admSeq.type === "required" && <div className="invalid-feedback">Admission sequence is required</div>}
                      </div>
                      <div className="form-group">
                        <label className="col-form-label">Admission Report <span className="text-danger">*</span> : </label>
                        <input
                          type="number"
                          name="admRep"
                          defaultValue={props.doc.AdmRep === undefined ? "" : props.doc.AdmRep}
                          className={`form-control${errors.admRep === undefined ? "" : errors.admRep && " is-invalid"}`}
                          ref={register({
                            required: true
                          })}
                          autoComplete="off"
                        />
                        <span className="form-text text-muted">Please enter your admission report.</span>
                        {errors.admRep && errors.admRep.type === "required" && <div className="invalid-feedback">Admission report is required</div>}
                      </div>
                    </>
                    :
                    <>
                      <div className="form-group">
                        <label className="col-form-label">Apply Rate <span className="text-danger">*</span> : </label>
                        <input
                          type="number"
                          name="applyRate"
                          defaultValue={props.doc.ApplyRate === undefined ? "" : props.doc.ApplyRate}
                          className={`form-control${errors.applyRate === undefined ? "" : errors.applyRate && " is-invalid"}`}
                          ref={register({
                            required: true
                          })}
                          autoComplete="off"
                        />
                        <span className="form-text text-muted">Please enter your apply rate.</span>
                        {errors.applyRate && errors.applyRate.type === "required" && <div className="invalid-feedback">Apply rate is required</div>}
                      </div>
                    </>
                  }


                  <div className="form-group mt-5">
                    <FormControl component="fieldset">
                      <FormControlLabel
                        name="isOverride"
                        inputRef={register()}
                        control={<Switch checked={switchState.IsOverride} onChange={switchHandleChange('IsOverride')} value={switchState.IsOverride} />}
                        label="Override"
                      /><br />
                      <FormControlLabel
                        name="isActive"
                        inputRef={register()}
                        control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                        label="Active"
                      />
                    </FormControl>
                  </div>
                  <div className="form-group mb-0" align="right">
                    <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                    <button type="submit" className="btn btn-success">Submit</button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </>
        :
        <Loading />
      }
    </>


  );
}