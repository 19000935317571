import React, { useState, useEffect } from "react";
import MigratorGif from "../../../../../_metronic/_assets/logo/MigratorGif.gif"
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import MigratingFile from "../../../../../_metronic/_assets/logo/MigratingFile.gif";
import MigratingLoad from "../../../../../_metronic/_assets/logo/MigratingLoad.gif"
import DotLoad from "../../../../../_metronic/_assets/logo/dotLoad.gif"

import MigrateModal from "../../../../../_metronic/layout/components/custom/modal/MigrateModal";
import MigrateStudent201Modal from "../../../../../_metronic/layout/components/custom/modal/MigrateStudent201Modal";
import MigrateFinalGradeModal from "../../../../../_metronic/layout/components/custom/modal/FinalGradeModal";
import MigrateSubjectScheduleModal from "../../../../../_metronic/layout/components/custom/modal/SubjectScheduleModal";
import MigrateAssessmentTemplateModal from "../../../../../_metronic/layout/components/custom/modal/MigrateAssessmentTemplateModal";
import MigrateInstructionalGuideModal from "../../../../../_metronic/layout/components/custom/modal/MigrateInstructionalGuideModal";
import MigrateClassSectionModal from "../../../../../_metronic/layout/components/custom/modal/MigrateClassSectionModal";
import MigrateGradeBookModal from "../../../../../_metronic/layout/components/custom/modal/MigrateGradeBookModal"
import MigratePaymentModal from "../../../../../_metronic/layout/components/custom/modal/MigratePaymentModal";
import MigrateCourseOutlineModal from "../../../../../_metronic/layout/components/custom/modal/MigrateCourseOutlineModal";
import {
    getCourse, saveCourse, getFees, saveFees, getFeeGroups, saveFeeGroups, saveAssessmentTemplate, getSubjects,
    saveSubjects, getSem, getSchoolYear, getStudentFromCampus, saveStudent201, getFinalGrade,
    saveFinalGrade, getSubjectSchedule, saveSubjectSchedule, getAssessmentTemplate, getCourses, getYearLevels,
    getAssessmentTemplateDetail, saveAssessmentTemplateDetail, getSubjectScheduleDetail, getClassSection,
    saveClassSection, saveSubjectScheduleDetail, getStudentCategory, getSubjectScheduleBatch, saveSubjectScheduleBatch,
    getComputationType, saveComputationType, getSubjectCategory, saveSubjectCategory, getAssessmentTemplateRate,
    saveAssessmentTamplateRate, getAssessmentLabCategory, saveAssessmentLabCat, getAssessmentTemplateOther,
    saveAssessmentTemplateOther, getAssessmentTemplateOtherRate, saveAssessmentTemplateOtherRate, getScholarship,
    saveScholarship, saveScholarshipApplied, getCourseOutline, saveCourseOutline, getCourseOutlineDetail,
    saveCourseOutlineDetail, getCourseOutlineReq, saveCourseOutlineReq, computeStudentCampusEnrollmentAccount,
    createEnrollmentAccountAddsOn, getStudentCampusDiscount, getCampusUsers, saveUsers, getCampusGradeTemplates,
    saveGradeTemplate, getCampusGradeElement, saveGradeElement, getCampusGradeTemplateDetailPeriod, saveGradeTemplateDetailPeriod,
    getCampusGradeTemplateDetailFinal, saveGradeTemplateDetailFinal, getCampusInstructionalGuide, saveInstructionalGuide,
    getCampusExamType, saveExamType, getCampusInstructionalGuideDetail, saveInstructionalGuideDetail, getCampusGradeBook,
    saveGradeBook
} from "./__hooks__"
import Query from "../../../../../_metronic/functions/Query";
import SaveQuery from "../../../../../_metronic/functions/SaveQuery";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { Save } from "@material-ui/icons";
import { getGradeTemplateDetailPeriod } from "../../Student/GradeTemplate/PeriodicalGradeComposition/__hooks__";

export default function CampusMigrator(props) {
    // Modals
    const [onOpenStudent201, setOnOpenStudent201] = useState(false)
    const [onOpenCourses, setOnOpenCourse] = useState(false)
    const [onOpenFees, setOnOpenFees] = useState(false)
    const [onOpenSubjects, setOnOpenSubjects] = useState(false)
    const [onOpenFinalGrade, setOnOpenFinalGrade] = useState(false)
    const [onOpenSubjectSchedule, setOnOpenSubjectSchedule] = useState(false)
    const [onOpenAssessmentTemplate, setOnOpenAssessmentTemplate] = useState(false)
    const [onOpenClassSection, setOnOpenClassSection] = useState(false)
    const [onOpenScholarship, setOnOpenScholarship] = useState(false)
    const [onOpenCourseOutline, setOnOpenCourseOutline] = useState(false)
    const [onOpenUsers, setOnOpenUsers] = useState(false)
    const [onOpenExamType, setOnOpenExamType] = useState(false)
    const [onOpenGradeElement, setOnOpenGradeElement] = useState(false)
    const [onOpenGradeTemplate, setOnOpenGradeTemplate] = useState(false)
    const [onOpenInstructionalGuide, setOnOpenInstructionalGuide] = useState(false)
    const [onOpenGradeBook, setOnOpenGradeBook] = useState(false)

    //Migrated Data
    const [campusCourse, setCampusCourse] = useState()
    const [campusFees, setCampusFees] = useState()
    const [campusFeeGroup, setCampusFeeGroup] = useState()
    const [campusSubject, setCampusSubject] = useState()
    const [campusStudent201, setCampusStudent201] = useState()
    const [campusFinalGrade, setCampusFinalGrade] = useState()
    const [campusSubjectSchedule, setCampusSubjectSchedule] = useState()
    const [campusSubjectScheduleDetail, setCampusSubjectScheduleDetail] = useState()
    const [campusAssessmentTemplate, setCampusAssessmentTemplate] = useState()
    const [campusAssessmentTemplateDetail, setCampusAssessmentTemplateDetail] = useState()
    const [campusClassSection, setCampusClassSection] = useState()
    const [campusSubjectScheduleBatch, setCampusSubjectScheduleBatch] = useState()
    const [campusComputationType, setCampusComputationType] = useState()
    const [campusSubjectCategory, setCampusSubjectCategory] = useState()
    const [campusAssessmentTemplateRate, setCampusAssessmentTemplateRate] = useState()
    const [campusAssessmentLabCategories, setCampusAssessmentLabCategories] = useState()
    const [campusAssessmentTemplateOther, setCampusAssessmentTemplateOther] = useState()
    const [campusAssessmentTemplateOtherRate, setCampusAssessmentTemplateOtherRate] = useState()
    const [campusScholarship, setCampusScholarship] = useState()
    const [campusCourseOutline, setCampusCourseOutline] = useState()
    const [campusCourseOutlineDetail, setCampusCourseOutlineDetail] = useState()
    const [campusCourseOutlineReq, setCampusCourseOutlineReq] = useState()
    const [campusUsers, setCampusUsers] = useState()
    const [campusExamType, setCampusExamType] = useState()
    const [campusGradeElement, setCampusGradeElement] = useState()
    const [campusGradeTemplate, setCampusGradeTemplate] = useState()
    const [campusGradeTemplateDetailPeriod, setCampusGradeTemplateDetailPeriod] = useState()
    const [campusGradeTemplateDetailFinal, setCampusGradeTemplateDetailFinal] = useState()
    const [campusInstructionalGuide, setCampusInstructionalGuide] = useState()
    const [campusInstructionalGuideDetail, setCampusInstructionalGuideDetail] = useState()
    const [campusGradeBook, setCampusGradeBook] = useState()

    // Enrollment Account Creation
    const [studentCampusDiscount, setStudentCampusDiscount] = useState()
    const [studentEnrollmentAccount, setStudentEnrollmentAccount] = useState()

    // Loading State
    const [isMigrating, setIsMigrating] = useState(false)
    const [isMigratingStudent, setIsMigratingStudent] = useState(true)
    const [semSelect, setSemSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const [courseSelect, setCourseSelect] = useState()
    const [yearLevelSelect, setYearLevelSelect] = useState()
    const [studentCategorySelect, setStudentCategorySelect] = useState()

    // Add Enrollment Account For Migrated Students....
    const onCreateStudentEnrollmentAddOns = async (values) => {
        await createEnrollmentAccountAddsOn(values)
            .then(response => {
                if (response.success) {
                }
            })
            .catch(error => {
                // alert('student enrollment add ons did not saved')
            })
    }

    // Get migrated Student Discount...
    const executeFindDiscount = async (data) => {
        await getStudentCampusDiscount(data)
            .then(res => {
                console.log(res)
                setStudentCampusDiscount(res.data)
            })
            .catch(e => {
                console.log(e)
            })
    }

    // Student ----------------
    const handleCloseStudent201 = () => {
        setOnOpenStudent201(false)
    }

    const onMigrateStudent201 = () => {
        setOnOpenStudent201(true)
    }

    const onSubmitStudentHandler = (data) => {
        const execute = async (values) => {
            await getStudentFromCampus(values)
                .then(res => {
                    setCampusStudent201(res.data)
                })
                .catch(e => {

                })
        }

        execute(data)
    }

    // Final Grade --------------------
    const handleCloseFinalGrade = () => {
        setOnOpenFinalGrade(false)
    }

    const onMigrateFinalGrade = () => {
        setOnOpenFinalGrade(true)
    }

    const onSubmitFinalGradeHandler = (data) => {
        const execute = async (values) => {
            await getFinalGrade(values)
                .then(res => {
                    setCampusFinalGrade(res.data)
                })
                .catch(e => {

                })
        }

        execute(data)
    }

    // Subject Schedule -----------------

    const handleCloseSubjectSchedule = () => {
        setOnOpenSubjectSchedule(false)
    }

    const onMigrateSubjectSchedule = () => {
        setOnOpenSubjectSchedule(true)
    }

    const onSubmitSubjectScheduleHandler = (data) => {
        const execute = async (values) => {
            await getSubjectSchedule(values)
                .then(res => {
                    setCampusSubjectSchedule(res.data)
                })
                .catch(e => {

                })
        }

        execute(data)
    }

    // Assessment Template -------------------
    const handleCloseAssessmentTemplate = () => {
        setOnOpenAssessmentTemplate(false)
    }

    const onMigrateAssessmentTemplate = () => {
        setOnOpenAssessmentTemplate(true)
    }

    const onSubmitAssessmentTemplateHandler = (data) => {
        const execute = async (values) => {
            await getAssessmentTemplate(values)
                .then(res => {
                    setCampusAssessmentTemplate(res.data)
                })
                .catch(e => {

                })
        }

        execute(data)
    }


    // Course -------------------
    const handleSubmitCourse = () => {
        Query(getCourse, null, setCampusCourse)

        handleCloseCourse()
    }

    const handleCloseCourse = () => {
        setOnOpenCourse(false)
    }

    const onMigrateCourses = () => {
        setOnOpenCourse(true)
    }

    // Scholarship

    const handleSubmitScholarship = () => {
        Query(getScholarship, null, setCampusScholarship)
        handleCloseScholarship()
    }

    const handleCloseScholarship = () => {
        setOnOpenScholarship(false)
    }

    const onMigrateScholarship = () => {
        setOnOpenScholarship(true)
    }


    // Course Outline

    const handleSubmitCourseOutline = (data) => {
        const execute = async (values) => {
            await getCourseOutline(values)
                .then(res => {
                    setCampusCourseOutline(res.data)
                })
                .catch(e => {

                })
        }
        execute(data)
        handleCloseCourseOutline()
    }

    const handleCloseCourseOutline = () => {
        setOnOpenCourseOutline(false)
    }

    const onMigrateCourseOutline = () => {
        setOnOpenCourseOutline(true)
    }

    // Fees -----------------------

    const handleSubmitFees = () => {
        Query(getFeeGroups, null, setCampusFeeGroup)
        handleCloseFees()
    }

    const handleCloseFees = () => {
        setOnOpenFees(false)
    }

    const onMigrateFees = () => {
        setOnOpenFees(true)
    }


    // Class Section -----------------------

    const handleSubmitClassSection = (data) => {
        const execute = async (values) => {
            await getClassSection(values)
                .then(res => {
                    setCampusClassSection(res.data)
                })
                .catch(e => {

                })
        }

        execute(data)
        handleCloseClassSection()
    }

    const handleCloseClassSection = () => {
        setOnOpenClassSection(false)
    }

    const onMigrateClassSection = () => {
        setOnOpenClassSection(true)
    }


    //Subject --------------------------
    const handleSubmitSubject = () => {
        Query(getSubjectCategory, null, setCampusSubjectCategory)

        handleCloseSubjects()
    }

    const handleCloseSubjects = () => {
        setOnOpenSubjects(false)
    }

    const onMigrateSubject = () => {
        setOnOpenSubjects(true)
    }


    //Users --------------------------
    const handleSubmitUsers = () => {
        Query(getCampusUsers, null, setCampusUsers)

        handleCloseUsers()
    }

    const handleCloseUsers = () => {
        setOnOpenUsers(false)
    }

    const onMigrateUsers = () => {
        setOnOpenUsers(true)
    }



    //Exam Type --------------------------
    const handleSubmitExamType = () => {
        Query(getCampusExamType, null, setCampusExamType)
        handleCloseExamType()
    }

    const handleCloseExamType = () => {
        setOnOpenExamType(false)
    }

    const onMigrateExamType = () => {
        setOnOpenExamType(true)
    }


    // Grade Element
    const handleSubmitGradeElement = () => {
        Query(getCampusGradeElement, null, setCampusGradeElement)
        handleCloseGradeElement()
    }

    const handleCloseGradeElement = () => {
        setOnOpenGradeElement(false)
    }

    const onMigrateGradeElement = () => {
        setOnOpenGradeElement(true)
    }

    // Grade Template
    const handleSubmitGradeTemplate = () => {
        Query(getCampusGradeTemplates, null, setCampusGradeTemplate)
        handleCloseGradeTemplate()
    }

    const handleCloseGradeTemplate = () => {
        setOnOpenGradeTemplate(false)
    }

    const onMigrateGradeTemplate = () => {
        setOnOpenGradeTemplate(true)
    }

    // Instructional Guide

    const handleCloseInstructionalGuide = () => {
        setOnOpenInstructionalGuide(false)
    }

    const onSubmitInstructionalGuideHandler = (data) => {
        const execute = async (values) => {
            await getCampusInstructionalGuide(values)
                .then(res => {
                    setCampusInstructionalGuide(res.data)
                    handleCloseInstructionalGuide()

                })
                .catch(e => {

                })
        }

        execute(data)
    }

    const onMigrateInstructionalGuide = () => {
        setOnOpenInstructionalGuide(true)
    }

    // Grade Book

    const handleCloseGradeBook = () => {
        setOnOpenGradeBook(false)
    }

    const onSubmitGradeBookHandler = (data) => {
        const execute = async (values) => {
            await getCampusGradeBook(values)
                .then(res => {
                    setCampusGradeBook(res.data)
                    handleCloseGradeBook()

                })
                .catch(e => {

                })
        }

        execute(data)
    }

    const onMigrateGradeBook = () => {
        setOnOpenGradeBook(true)
    }


    useEffect(() => {
        SelectCreator(getSem, setSemSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
        SelectCreator(getCourses, setCourseSelect, 'CampusId', 'Description')
        SelectCreator(getYearLevels, setYearLevelSelect, 'CampusId', 'Description')
        SelectCreator(getStudentCategory, setStudentCategorySelect, 'StudentCategoryId', 'StudentCategory')



    }, [])


    useEffect(() => {
        if (campusCourse !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveCourse, campusCourse, setIsMigrating)
        }
    }, [campusCourse])

    useEffect(() => {
        if (campusScholarship !== undefined) {
            setIsMigrating(true)
            const saveScholarshipExe = async () => {
                await saveScholarship(campusScholarship)
                    .then(res => {
                        setTimeout(() => {
                            SaveQuery(saveScholarshipApplied, campusScholarship, setIsMigrating)

                        }, 20000)
                    })
                    .catch(e => {
                        console.log(e)
                    })
            }

            saveScholarshipExe()


        }
    }, [campusScholarship])


    useEffect(() => {
        if (campusCourseOutline !== undefined) {
            setIsMigrating(true)
            const saveCourseOutlines = async () => {
                await saveCourseOutline(campusCourseOutline)
                    .then(resp => {
                        const exeGetCourseOutlineDetail = async (d) => {
                            await getCourseOutlineDetail(d)
                                .then(res => {
                                    setCampusCourseOutlineDetail(res.data)
                                })
                                .catch(e => {

                                })
                        }

                        exeGetCourseOutlineDetail(campusCourseOutline)
                    })
            }

            saveCourseOutlines()

        }
    }, [campusCourseOutline])

    useEffect(() => {
        if (campusFees !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveFees, campusFees, setIsMigrating)

        }
    }, [campusFees])

    useEffect(() => {
        if (campusClassSection !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveClassSection, campusClassSection, setIsMigrating)

        }
    }, [campusClassSection])

    useEffect(() => {
        if (campusSubjectScheduleBatch !== undefined) {
            console.log(campusSubjectScheduleBatch)
            setIsMigrating(true)
            SaveQuery(saveSubjectScheduleBatch, campusSubjectScheduleBatch, setIsMigrating)

        }
    }, [campusSubjectScheduleBatch])




    useEffect(() => {
        if (campusFeeGroup !== undefined) {
            SaveQuery(saveFeeGroups, campusFeeGroup, setIsMigrating)
            setTimeout(() => {
                Query(getFees, null, setCampusFees)

            }, 5000)
        }
    }, [campusFeeGroup])

    useEffect(() => {
        if (campusAssessmentTemplate !== undefined) {
            SaveQuery(saveAssessmentTemplate, campusAssessmentTemplate, setIsMigrating)
            Query(getComputationType, null, setCampusComputationType)
            setTimeout(() => {
                for (let i = 0; i < campusAssessmentTemplate.length; i++) {
                    let data = { assessmentTemplateId: campusAssessmentTemplate[i].AssessmentTemplateId }
                    const executeGetAssessmentTemplateDetail = async (formValues) => {
                        await getAssessmentTemplateDetail(formValues)
                            .then(response => {
                                setCampusAssessmentTemplateDetail(response.data)

                            })
                            .catch(error => {
                                if (error) {
                                    return error
                                    // alert("something went wrong")
                                } else {
                                    return
                                }
                            })
                    }

                    const executeGetAssessmentTemplateOther = async (formValues) => {
                        await getAssessmentTemplateOther(formValues)
                            .then(response => {
                                setCampusAssessmentTemplateOther(response.data)

                            })
                            .catch(error => {
                                if (error) {
                                    return error

                                } else {
                                    return
                                }
                            })
                    }



                    executeGetAssessmentTemplateDetail(data)
                    executeGetAssessmentTemplateOther(data)
                }
            }, 5000)
        }
    }, [campusAssessmentTemplate])

    useEffect(() => {
        if (campusSubject !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveSubjects, campusSubject, setIsMigrating)

            // setTimeout(() => {
            //     Query(getSubjectCategory, null, setCampusSubjectCategory)
            // }, 5000);
        }
    }, [campusSubject])


    useEffect(() => {
        if (campusUsers !== undefined) {
            setIsMigrating(true)
            console.log(campusUsers)
            SaveQuery(saveUsers, campusUsers, setIsMigrating)
        }
    }, [campusUsers])


    useEffect(() => {
        if (campusExamType !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveExamType, campusExamType, setIsMigrating)
        }
    }, [campusExamType])


    useEffect(() => {
        if (campusGradeTemplate !== undefined) {
            setIsMigrating(true)
            const executeSaveGradeTemplate = async (val) => {
                await saveGradeTemplate(val)
                    .then(res => {
                        executeGetGradeTemplateDetailPeriod()
                        executeGetGradeTemplateDetailFinal()
                    })
                    .catch(e => {

                    })
            }

            const executeGetGradeTemplateDetailPeriod = async () => {
                await getCampusGradeTemplateDetailPeriod()
                    .then(res => {
                        setCampusGradeTemplateDetailPeriod(res.data)
                    })
                    .catch(e => {

                    })
            }


            const executeGetGradeTemplateDetailFinal = async () => {
                await getCampusGradeTemplateDetailFinal()
                    .then(res => {
                        setCampusGradeTemplateDetailFinal(res.data)
                    })
                    .catch(e => {

                    })
            }

            executeSaveGradeTemplate(campusGradeTemplate)

        }
    }, [campusGradeTemplate])


    useEffect(() => {
        if (campusGradeTemplateDetailPeriod !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveGradeTemplateDetailPeriod, campusGradeTemplateDetailPeriod, setIsMigrating)
        }
    }, [campusGradeTemplateDetailPeriod])


    useEffect(() => {
        if (campusGradeTemplateDetailFinal !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveGradeTemplateDetailFinal, campusGradeTemplateDetailFinal, setIsMigrating)
        }
    }, [campusGradeTemplateDetailFinal])


    useEffect(() => {
        if (campusGradeElement !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveGradeElement, campusGradeElement, setIsMigrating)
        }
    }, [campusGradeElement])

    useEffect(() => {
        if (campusSubjectCategory !== undefined) {
            setIsMigrating(true)
            const execute = async (values) => {
                await saveSubjectCategory(values)
                    .then(response => {
                        Query(getSubjects, null, setCampusSubject)
                        setIsMigrating(false)
                    })
                    .catch(error => {
                        setIsMigrating(false)
                        if (error) {
                            return error
                        } else {
                            return
                        }
                    })
            }

            execute(campusSubjectCategory)

        }
    }, [campusSubjectCategory])

    useEffect(() => {
        if (campusComputationType !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveComputationType, campusComputationType, setIsMigrating)
        }
    }, [campusComputationType])



    useEffect(() => {
        if (campusStudent201 !== undefined) {
            // setIsMigrating(true)
            console.log(campusStudent201)
            setIsMigratingStudent(true)
            console.log(campusStudent201)
            SaveQuery(saveStudent201, campusStudent201, setIsMigratingStudent)


        }
    }, [campusStudent201])

    useEffect(() => {
        if (campusFinalGrade !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveFinalGrade, campusFinalGrade, setIsMigrating)

        }
    }, [campusFinalGrade])

    useEffect(() => {
        if (campusSubjectSchedule !== undefined) {
            console.log(campusSubjectSchedule)
            setIsMigrating(true)
            const executeGetSubjectScheduleDetail = async (data) => {
                await getSubjectScheduleDetail(data)
                    .then(res => {
                        console.log(res.data)
                        setCampusSubjectScheduleDetail(res.data)
                    })
                    .catch(e => {

                    })
            }
            SaveQuery(saveSubjectSchedule, campusSubjectSchedule, setIsMigrating)
            executeGetSubjectScheduleDetail(campusSubjectSchedule)
        }
    }, [campusSubjectSchedule])


    useEffect(() => {
        if (campusSubjectScheduleDetail !== undefined) {
            setIsMigrating(true)
            // alert("test")
            console.log(campusSubjectScheduleDetail)
            //SaveQuery(saveSubjectScheduleDetail, campusSubjectScheduleDetail, setIsMigrating)
            const execute = async (data) => {
                await saveSubjectScheduleDetail(data)
                    .then(res => {

                    })
                    .catch(e => {
                        console.log(e)
                    })
            }

            execute(campusSubjectScheduleDetail)

        }
    }, [campusSubjectScheduleDetail])

    useEffect(() => {
        if (campusAssessmentTemplateRate !== undefined) {
            console.log(campusAssessmentTemplateRate)
            if (campusAssessmentTemplateRate.length > 0) {


                setIsMigrating(true)
                SaveQuery(saveAssessmentTamplateRate, campusAssessmentTemplateRate, setIsMigrating)


                const executeGetLabCat = async (formVal) => {
                    await getAssessmentLabCategory(formVal)
                        .then(res => {
                            setCampusAssessmentLabCategories(res.data)
                        })
                        .catch(e => {
                            console.log(e)
                        })
                }


                for (let i = 0; i < campusAssessmentTemplateRate.length; i++) {
                    let values = {
                        assessmentTemplateRateId: campusAssessmentTemplateRate[i].Autokey
                    }
                    executeGetLabCat(values)

                }
            }

        }
    }, [campusAssessmentTemplateRate])

    useEffect(() => {
        if (campusAssessmentTemplateDetail !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveAssessmentTemplateDetail, campusAssessmentTemplateDetail, setIsMigrating)

            for (let i = 0; i < campusAssessmentTemplateDetail.length; i++) {
                let data = { assessmentTemplateDetailId: campusAssessmentTemplateDetail[i].Autokey }
                console.log(data)
                const execute = async (formValue) => {
                    getAssessmentTemplateRate(formValue)
                        .then(res => {
                            setCampusAssessmentTemplateRate(res.data)
                        })
                        .catch(e => {

                        })
                }

                execute(data)
            }

        }
    }, [campusAssessmentTemplateDetail])

    useEffect(() => {
        if (campusCourseOutlineDetail !== undefined) {
            setIsMigrating(true)

            const saveCourseOutlineDetal = async () => {
                await saveCourseOutlineDetail(campusCourseOutlineDetail)
                    .then(async res => {

                        await getCourseOutlineReq(campusCourseOutlineDetail)
                            .then(res => {
                                setCampusCourseOutlineReq(res.data)
                            })
                            .catch(e => {

                            })


                    })
            }
            saveCourseOutlineDetal()


        }
    }, [campusCourseOutlineDetail])

    useEffect(() => {
        if (campusCourseOutlineReq !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveCourseOutlineReq, campusCourseOutlineReq, setIsMigrating)
        }
    }, [campusCourseOutlineReq])


    useEffect(() => {
        if (campusAssessmentLabCategories !== undefined && campusAssessmentLabCategories.length > 0) {
            setIsMigrating(true)
            SaveQuery(saveAssessmentLabCat, campusAssessmentLabCategories, setIsMigrating)

        }
    }, [campusAssessmentLabCategories])


    useEffect(() => {
        if (campusAssessmentTemplateOther !== undefined) {
            setTimeout(() => {
                setIsMigrating(true)
                SaveQuery(saveAssessmentTemplateOther, campusAssessmentTemplateOther, setIsMigrating)

                for (let i = 0; i < campusAssessmentTemplateOther.length; i++) {
                    let values = {
                        assessmentTemplateOtherRateId: campusAssessmentTemplateOther[i].Autokey
                    }

                    const getAssessmentOtherRate = async (frmVal) => {
                        await getAssessmentTemplateOtherRate(frmVal)
                            .then(res => {
                                setCampusAssessmentTemplateOtherRate(res.data)
                            })
                            .catch(e => {
                                console.log(e)
                            })
                    }

                    getAssessmentOtherRate(values)
                }


            }, 5000)

        }
    }, [campusAssessmentTemplateOther])

    useEffect(() => {
        if (campusAssessmentTemplateOtherRate !== undefined && campusAssessmentTemplateOtherRate.length > 0) {
            setIsMigrating(true)
            SaveQuery(saveAssessmentTemplateOtherRate, campusAssessmentTemplateOtherRate, setIsMigrating)
        }
    }, [campusAssessmentTemplateOtherRate])



    // useEffect(() => {
    //     if (isMigratingStudent == false && campusStudent201 !== undefined && campusStudent201.length > 0) {
    //         setIsMigrating(true)
    //         SaveQuery(computeStudentCampusEnrollmentAccount, campusStudent201, setIsMigrating)
    //     }
    // }, [isMigratingStudent, campusStudent201])



    useEffect(() => {
        if (campusInstructionalGuide !== undefined && campusInstructionalGuide.length > 0) {
            setIsMigrating(true)
            SaveQuery(saveInstructionalGuide, campusInstructionalGuide, setIsMigrating)
            const execute = async (values) => {
                await getCampusInstructionalGuideDetail(values)
                    .then(res => {
                        setCampusInstructionalGuideDetail(res.data)
                    })
                    .catch(e => {
                    })
            }

            execute(campusInstructionalGuide)
        }
    }, [campusInstructionalGuide])



    useEffect(() => {
        if (campusInstructionalGuideDetail !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveInstructionalGuideDetail, campusInstructionalGuideDetail, setIsMigrating)
        }
    }, [campusInstructionalGuideDetail])



    useEffect(() => {
        if (campusGradeBook !== undefined) {
            setIsMigrating(true)
            SaveQuery(saveGradeBook, campusGradeBook, setIsMigrating)
        }
    }, [campusGradeBook])


    console.log(campusCourse)
    console.log(campusFees)
    console.log(campusFeeGroup)
    console.log(campusSubject)
    console.log(semSelect)
    console.log(sySelect)
    console.log(campusStudent201)
    console.log(isMigrating)
    console.log(campusFinalGrade)
    console.log(campusSubjectSchedule)
    console.log(campusAssessmentTemplate)
    console.log(campusAssessmentTemplateDetail)
    console.log(campusSubjectScheduleDetail)
    console.log(campusClassSection)
    console.log(courseSelect)
    console.log(campusSubjectCategory)
    console.log(campusAssessmentTemplateRate)
    console.log(campusAssessmentLabCategories)
    console.log(campusAssessmentTemplateOther)
    console.log(campusAssessmentTemplateOtherRate)
    console.log(campusScholarship)
    console.log(campusCourseOutline)
    console.log(campusCourseOutlineDetail)
    console.log(campusUsers)
    console.log(campusGradeTemplate)
    console.log(campusGradeElement)
    console.log(campusInstructionalGuide)
    console.log(campusExamType)
    console.log(campusInstructionalGuideDetail)
    console.log(campusGradeBook)

    return (
        <>
            {/* Course Modal */}

            <MigrateStudent201Modal
                schoolYearSelect={sySelect}
                semestersSelect={semSelect}
                openMigrateModal={onOpenStudent201}
                handleClose={handleCloseStudent201}
                onSubmitStudentHandler={onSubmitStudentHandler}

            />
            <MigrateFinalGradeModal
                schoolYearSelect={sySelect}
                semestersSelect={semSelect}
                openMigrateModal={onOpenFinalGrade}
                handleClose={handleCloseFinalGrade}
                onSubmitStudentHandler={onSubmitFinalGradeHandler}

            />

            <MigrateSubjectScheduleModal
                schoolYearSelect={sySelect}
                semestersSelect={semSelect}
                courseSelect={courseSelect}
                openMigrateModal={onOpenSubjectSchedule}
                handleClose={handleCloseSubjectSchedule}
                onSubmitStudentHandler={onSubmitSubjectScheduleHandler}

            />

            <MigrateAssessmentTemplateModal
                schoolYearSelect={sySelect}
                semestersSelect={semSelect}
                courseSelect={courseSelect}
                yearLevelSelect={yearLevelSelect}
                openMigrateModal={onOpenAssessmentTemplate}
                handleClose={handleCloseAssessmentTemplate}
                onSubmitStudentHandler={onSubmitAssessmentTemplateHandler}
            />

            <MigrateCourseOutlineModal
                schoolYearSelect={sySelect}
                courseSelect={courseSelect}
                openMigrateModal={onOpenCourseOutline}
                handleClose={handleCloseCourseOutline}
                onSubmitStudentHandler={handleSubmitCourseOutline}
            />

            <MigrateModal
                open={onOpenCourses}
                handleSubmit={handleSubmitCourse}
                handleClose={handleCloseCourse}
                question="Are you sure you want to Migrate all Courses in Campus++ ?"
                title="Courses Migration"
            />

            <MigrateModal
                open={onOpenScholarship}
                handleSubmit={handleSubmitScholarship}
                handleClose={handleCloseScholarship}
                question="Are you sure you want to Migrate all Scholarship in Campus++ ?"
                title="Scholarship Migration"
            />



            <MigrateModal
                open={onOpenFees}
                handleSubmit={handleSubmitFees}
                handleClose={handleCloseFees}
                question="Are you sure you want to Migrate all Fees in Campus++ ?"
                title="Fees Migration"
            />

            {/* <MigrateModal
                open={onOpenClassSection}
                handleSubmit={handleSubmitClassSection}
                handleClose={handleCloseClassSection}
                question="Are you sure you want to Migrate all Class Section in Campus++ ?"
                title="Class Section Migration"
            /> */}

            <MigrateClassSectionModal
                // open={onOpenClassSection}
                // studentCategorySelect={studentCategorySelect}
                // handleSubmit={handleSubmitClassSection}
                // handleClose={handleCloseClassSection}
                // question="Are you sure you want to Migrate all Class Section in Campus++ ?"
                // title="Class Section Migration"
                studentCategorySelect={studentCategorySelect}
                open={onOpenClassSection}
                handleClose={handleCloseClassSection}
                onSubmitStudentHandler={handleSubmitClassSection}
            />

            <MigrateModal
                open={onOpenSubjects}
                handleSubmit={handleSubmitSubject}
                handleClose={handleCloseSubjects}
                question="Are you sure you want to Migrate all Subjects in Campus++ ?"
                title="Subject Migration"
            />


            <MigrateModal
                open={onOpenUsers}
                handleSubmit={handleSubmitUsers}
                handleClose={handleCloseUsers}
                question="Are you sure you want to Migrate all Users in Campus++ ?"
                title="User Migration"
            />

            <MigrateModal
                open={onOpenExamType}
                handleSubmit={handleSubmitExamType}
                handleClose={handleCloseExamType}
                question="Are you sure you want to Migrate all Exam Type in Campus++ ?"
                title="Exam Type Migration"
            />

            <MigrateModal
                open={onOpenGradeTemplate}
                handleSubmit={handleSubmitGradeTemplate}
                handleClose={handleCloseGradeTemplate}
                question="Are you sure you want to Migrate all Grade Template in Campus++ ?"
                title="Grade Template Migration"
            />

            <MigrateModal
                open={onOpenGradeElement}
                handleSubmit={handleSubmitGradeElement}
                handleClose={handleCloseGradeElement}
                question="Are you sure you want to Migrate all Grade Element in Campus++ ?"
                title="Grade Element Migration"
            />

            <MigrateInstructionalGuideModal
                schoolYearSelect={sySelect}
                semestersSelect={semSelect}
                openMigrateModal={onOpenInstructionalGuide}
                handleClose={handleCloseInstructionalGuide}
                onSubmitStudentHandler={onSubmitInstructionalGuideHandler}
            />


            <MigrateGradeBookModal
                schoolYearSelect={sySelect}
                semestersSelect={semSelect}
                openMigrateModal={onOpenGradeBook}
                handleClose={handleCloseGradeBook}
                onSubmitStudentHandler={onSubmitGradeBookHandler}
            />


            <main className="p-5">
                <header className="mx-5  ">
                    <h1 className="display-4">Campus <span className="font-weight-bold text-primary">Migration</span> </h1>
                    <p className="lead">Migration From Campus++ to AstraCollege</p>
                    {isMigrating === true ?
                        <div className="spinner-border" role="status">
                            <span className="sr-only">Loading...</span>
                        </div>
                        :
                        ''
                    }


                    <hr />
                </header>

                <article className="row  mt-5">

                    <section className="col-12 col-md-9 text-center rounded d-flex align-items-center justify-content-center" style={{ backgroundColor: "#b3e0fe" }}>
                        {/* {isMigrating === false ?
                            <img className="" src={MigratorGif}></img>
                            :
                            <img className="" src={MigratingFile}></img>
                        } */}
                        <img className="" src={MigratorGif}></img>

                    </section>
                    <section className="col-12 col-md-3" >
                        <div className="card p-5">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Student 201</h3>
                            <p>Migrate Student Basic Information</p>
                            <button onClick={() => { onMigrateStudent201() }} className="btn btn-success d-inline">Import Data</button>
                        </div>

                        <div className="card p-5 mt-2">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Courses  </h3>
                            <p>Migrate Courses </p>
                            <button onClick={() => { onMigrateCourses() }} className="btn btn-success d-inline">Import Data</button>
                        </div>

                        <div className="card mt-2 p-5">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Fees</h3>
                            <p>Migrate Fees</p>
                            <button onClick={() => { onMigrateFees() }} className="btn btn-success d-inline">Import Data</button>
                        </div>
                        <div className="card mt-2 p-5">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Class Section</h3>
                            <p>Migrate Class Section</p>
                            <button onClick={() => { onMigrateClassSection() }} className="btn btn-success d-inline">Import Data</button>
                        </div>



                    </section>

                    <section className="col">

                        <div className="card mt-5 p-5">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Subjects</h3>
                            <p>Migrate Subjects</p>
                            <button onClick={() => { onMigrateSubject() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>
                    <section className="col">

                        <div className="card mt-5 p-5">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Scholarship</h3>
                            <p>Migrate Scholarship</p>
                            <button onClick={() => { onMigrateScholarship() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>
                    <section className="col">

                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Subject Schedule</h3>
                            <p>Migrate Subject Schedule</p>
                            <button onClick={() => { onMigrateSubjectSchedule() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>

                </article>
                <article className="row">

                    <section className="col">

                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Grades</h3>
                            <p>Migrate Grades</p>
                            <button onClick={() => { onMigrateFinalGrade() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>
                    <section className="col">

                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Assessment Template</h3>
                            <p>Migrate Student Payment</p>
                            <button onClick={() => { onMigrateAssessmentTemplate() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>


                    <section className="col">

                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Course Outline</h3>
                            <p>Migrate Course Outline</p>
                            <button onClick={() => { onMigrateCourseOutline() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>
                </article>
                <article className="row">
                    <section className="col">

                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Users</h3>
                            <p>Migrate Users</p>
                            <button onClick={() => { onMigrateUsers() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>
                    <section className="col">

                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Grade Element</h3>
                            <p>Migrate Grade Element</p>
                            <button onClick={() => { onMigrateGradeElement() }} className="btn btn-success d-inline">Import Data</button>
                        </div>


                    </section>
                    <section className="col">
                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Grade Template</h3>
                            <p>Migrate Grade Template</p>
                            <button onClick={() => { onMigrateGradeTemplate() }} className="btn btn-success d-inline">Import Data</button>
                        </div>



                    </section>
                </article>


                <article className="row">
                    <section className="col">
                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Exam Type</h3>
                            <p>Migrate Exam Type</p>
                            <button onClick={() => { onMigrateExamType() }} className="btn btn-success d-inline">Import Data</button>
                        </div>



                    </section>
                    <section className="col">
                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Instructional Guide</h3>
                            <p>Migrate Instructional Guide</p>
                            <button onClick={() => { onMigrateInstructionalGuide() }} className="btn btn-success d-inline">Import Data</button>
                        </div>



                    </section>
                    <section className="col">
                        <div className="card mt-5 p-5 ">
                            <h3 className="font-weight-bold"> <i className="fa fa-check border p-3 rounded-circle bg-primary text-light"></i>&nbsp; Grade Book</h3>
                            <p>Migrate Grade Book</p>
                            <button onClick={() => { onMigrateGradeBook() }} className="btn btn-success d-inline">Import Data</button>
                        </div>




                    </section>
                </article>


            </main>
        </>
    )
}