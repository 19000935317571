import React, { useState, useEffect } from 'react';

import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuideOverride, deleteInstructionalGuideSchedule,
    addUpdateInstructionalGuideOverride,
    getCurrentInstructionalGuide,
    getScheduleClassSection, isWithLab
} from './__hooks__';
import { useParams } from 'react-router-dom';
import DeleteModal from '../../../../../../_metronic/layout/components/custom/modal/DeleteModal';
import { Switch, FormControl, FormControlLabel, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { data } = props
    const { state: { fees, auth }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [instructionalGuide, setInstructionalGuide] = useState(null)

    const [isLab, setIsLab] = useState(false)

    const classes = useStyles();
    const { id } = useParams()
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const [isOpenOverride, setIsOpenOverride] = useState(false)
    const [valuesToAdd, setValuesToAdd] = useState(null)
    const [classSectionSelect, setClassSectionSelect] = useState(null)


    let rowCnt = 0;


    const getIg = async (con) => {
        await getCurrentInstructionalGuide(con)
            .then(async res => {
                setInstructionalGuide(res.data)

            })
            .catch(e => {

            })
    }

    const getIGClassSections = async (con) => {
        await getScheduleClassSection(con)
            .then(res => {
                setClassSectionSelect(res.data)
            })
    }


    const checkIsLab = async (con) => {
        await isWithLab(con)
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    if (res.data[0].IsWithLab == 1) {
                        setIsLab(true)
                    } else {
                        setIsLab(false)

                    }
                } else {
                    setIsLab(false)
                }

            })
    }


    const onCheckSchedule = (event, val) => {
        if (event == true) {
            setIsOpenOverride(true)
            setValuesToAdd(val)
        } else {
            onSaveGradeElement({
                instructionalGuideOverrideId: val.InstructionalGuideOverrideId,
                instructionalGuideId: parseFloat(props.id),
                gradingPeriodId: val.GradingPeriodId,
                isOverride: 0,
                createdBy: auth.data.Username,
                dateUpdated: dateTime
            })
            props.executeIGReload()
        }
    }


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        await addUpdateInstructionalGuideOverride({ ...val, createdBy: auth.data.Username })
            .then(res => {
                getIG({ instructionalGuideId: parseFloat(props.id) })
                if (res.data.length > 0) {
                    if (res.data[0].Result == 1) {
                        dispatch({
                            type: componentTypes.SHOW_SNACKBAR,
                            snackBarStatus: 'success',
                            snackBarDuration: 3000,
                            snackBarMessage: 'Instructional Guide Override Added'
                        });

                    } else {
                        dispatch({
                            type: componentTypes.SHOW_SNACKBAR,
                            snackBarStatus: 'success',
                            snackBarDuration: 3000,
                            snackBarMessage: 'Instructional Guide Override Removed'
                        });
                    }
                }



            })


    }



    const getIG = async () => {
        await getInstructionalGuideOverride({ instructionalGuideId: parseInt(id) })
            .then(response => {
                if (response.success) {
                    setResults(response.data);
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                }
            })
            .catch(error => {

                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

            })
    }




    useEffect(() => {
        // SelectCreator(getGradingPeriod, setGradingPeriodSelect, "GradingPeriodId", "Description")
        // SelectCreator(getExamType, setExamTypeSelect, "ExamTypeId", "ShortName")

        getIG({ instructionalGuideId: parseFloat(props.id) })

    }, [])


    useEffect(() => {
        getIG({ instructionalGuideId: parseFloat(props.id) })

    }, [props.reloadOverride])


    useEffect(() => {
        if (instructionalGuide !== null && instructionalGuide.length > 0) {
            getIGClassSections({ userId: auth.data.UserId, schoolYear: instructionalGuide[0].SchoolYear, semesterId: instructionalGuide[0].SemesterId })
            checkIsLab({ subjectCode: data.SubjectCode, schoolYear: instructionalGuide[0].SchoolYear, semesterId: instructionalGuide[0].SemesterId })
        }
    }, [instructionalGuide])





    const executeSaveOverride = () => {
        onSaveGradeElement({
            instructionalGuideOverrideId: valuesToAdd.InstructionalGuideOverrideId,
            instructionalGuideId: parseFloat(props.id),
            gradingPeriodId: valuesToAdd.GradingPeriodId,
            isOverride: 1,
            createdBy: auth.data.Username,
            dateUpdated: dateTime
        })
            .then(res => {
                props.executeIGReload()
                setValuesToAdd(null)
                setIsOpenOverride(false)
            })
    }





    console.log(props.id)
    console.log(results)
    console.log(instructionalGuide)
    console.log(data)
    console.log(results)
    return (
        <>
            <DeleteModal
                isOpen={isOpenOverride}
                handleDelete={() => executeSaveOverride()}
                handleClose={() => setIsOpenOverride(false)}
                title={'Override Percentage Computation'}
                content={`Are you sure you want to override this grading period? Overriding will set the computation of ${props.instructionalTypeId[0] == 1 ? 'Lecture' : 'Laboratory'} grades to 0% and set the ${props.instructionalTypeId[0] == 1 ? 'Laboratory' : 'Lecture'} percentage to 100%`}
                type={'delete'}
            />

            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header d-block bg-white" style={{ minHeight: 0 }}>
                    <div className="card-title">
                        {/* <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> */}
                    </div>
                    {/* <div className="card-toolbar float-right">
                                <div className="example-tools">
                                    <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary p-1">
                                        <i className="fa fa-plus p-0"></i>
                                    </button>
                                </div>
                            </div> */}
                </div>
                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                    {/* <IMTable tableProps={tableProps} /> */}
                    <TableContainer >
                        <Table className={classes.table} size="small">
                            <TableHead>
                                <TableRow>
                                    <TableCell align="left">#</TableCell>
                                    <TableCell align='left'>Grading Period</TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>
                                    <TableCell></TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {results.length > 0 ? results.map((result) => {
                                    const { GradingPeriodId, GradingPeriod, IsOverride } = result;
                                    rowCnt += 1;
                                    return (
                                        <>
                                            <TableRow key={GradingPeriodId}>
                                                <TableCell align="left">
                                                    <input
                                                        type='checkbox'
                                                        onChange={e => onCheckSchedule(e.target.checked, result)}
                                                        checked={IsOverride == 0 || IsOverride == null ? false : true}
                                                    // disabled={IsOverride == 1 ? true : false}

                                                    />
                                                </TableCell>
                                                <TableCell align='left' component="th" scope="row">{GradingPeriod}</TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                                <TableCell></TableCell>
                                            </TableRow>


                                        </>

                                    );

                                }) : <TableRow></TableRow>}

                            </TableBody>

                        </Table>
                    </TableContainer>
                </div>
            </div>



        </>
    );
}