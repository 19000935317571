import { number } from "prop-types";
import React, { useState, useRef, forwardRef, useEffect } from "react";
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import {
    getStudentGradeForTOR
} from './__hooks__';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './__hooks__/styles.css'
import { result } from "lodash";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

const useStyles = makeStyles({
    // table: {
    //     minWidth: 430
    // },

    blueBGColor: {
        backgroundColor:"#b3ebfb !important"
    },

    redBGColor: {
        backgroundColor:"#ff6c6c !important"
    },

    topAndSideBorders: {
        borderBottomStyle:"none",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    sideBordersOnly: {
        borderBottomStyle:"none",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },
    
    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    allSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    }

});

export default function PrintAttendanceSheet(props) {
    const attendanceSheet = props["results"]["0"];
    const { dispatch } = useAppContext();
    let componentRef = useRef();
    const classes = useStyles();

    const Component = forwardRef((props, ref) => {
        return (
            <>        
                <style type="text/css" media="print">{"\
                    @page {\ size: 13in 8.5in;\ margin: 10mm;}\
                "}</style>

                <div id="main-div" className="">
                    <div ref={ref}>
                        <div id="container-div" className="container" >
                            <main id="main-semantic" className="card border-0 p-5">
                                <section id="section-semantic" className="card-body my-5" >
                                    <div id="faith-header" className="">
                                        <img style={{ width: "200px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                        <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                        <h4 className="text-center">Student Attendance Sheet</h4>
                                    </div>
                                    {attendanceSheet.map((res, index) => {
                                        return (
                                            <>
                                                <div className="break-div break-line"></div>
                                                <div key={index} style={{pageBreakAfter:"always"}}>

                                                    <div id="" className="faith-header-print-only" style={{display:"none"}}>
                                                        <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                                        <h4 className="text-center">Student Attendance Sheet</h4>
                                                    </div>

                                                    <table id="attendance-table-details-header" className="attendance-style-main">
                                                        <thead >
                                                            <tr>
                                                                <th className="attendance-header-odd" width="10%" >Subject:</th>
                                                                <th className="attendance-header-even" width="10%">{res.Description}</th>
                                                                <th className="attendance-header-odd" width="10%">A.Y.:</th>
                                                                <th className="attendance-header-even" width="10%">{res.SchoolYear}</th>
                                                                <th className="attendance-header-odd" width="10%">Days:</th>
                                                                <th className="attendance-header-even" width="10%">{res.DayCode}</th>
                                                                <th className="attendance-header-odd" width="10%">Time:</th>
                                                                <th className="attendance-header-even" width="10%">{res.Time}</th>
                                                                <th className="attendance-header-odd" width="10%">Room:</th>
                                                                <th className="attendance-header-even" width="10%">{res.Room}</th>
                                                                
                                                            </tr>
                                                            <tr>
                                                                <th className="attendance-header-odd">Instructor:</th>
                                                                <th className="attendance-header-even">{res.Instructor}</th>
                                                                <th className="attendance-header-odd">Semester:</th>
                                                                <th className="attendance-header-even">{res.Semester}</th>
                                                                <th className="attendance-header-odd">Class Code:</th>
                                                                <th className="attendance-header-even">{res.ClassCode}</th>
                                                                <th className="attendance-header-odd">Code:</th>
                                                                <th className="attendance-header-even">{res.SubjectCode}</th>
                                                                <th className="attendance-header-odd">Section:</th>
                                                                <th className="attendance-header-even">{res.ClassSection}</th>
                                                            </tr>
                                                        </thead>
                                                    </table>

                                                    <br></br>

                                                    <table id="attendance-table-details-main" className="attendance-style-main">
                                                        <thead>  
                                                            <tr >
                                                                <td className="students_id">#</td>
                                                                <td className="students_studentno">Student No.</td>
                                                                <td className="students">Students</td>
                                                                {res.Days.map((dates, index) => {
                                                                    return(
                                                                        <td className="dates"> <span className={res.Days.length <= 12 ? "lbl_alt" : "lbl"} >{dates}</span> </td>
                                                                    )
                                                                })}
                                                            </tr>
                                                        </thead>
                                                        <tbody className="attendance-style">
                                                            {res.Details.map((row, index) => (
                                                                <tr key={index} >
                                                                    <td className="attendance-style students_id_main" >{index + 1}</td>
                                                                    <td className="attendance-style students_main">{row.StudentNo}</td>
                                                                    <td className="attendance-style students_main" >{` ${row.LastName.toUpperCase()}, ${row.FirstName.toUpperCase()} ${row.MiddleName.toUpperCase()}`}</td>
                                                                    {res.Days.map((dates, index) => {
                                                                        return(
                                                                            <td className="attendance-style" > </td>
                                                                        )
                                                                    })}
                                                                </tr> 
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    <table width="100%">
                                                        <thead>  
                                                            <tr >
                                                                <td style={{textAlign:"center"}}>****************** NOTHING FOLLOWS ******************</td>
                                                            </tr>
                                                        </thead>
                                                    </table>

                                                </div>
                                            </>
                                        )
                                    })}
                                </section>
                            </main>
                        </div>
                    </div>
                </div>

                <div id="loading-div" className="d-none" >
                    <div className="container" style={{width:"1224px", maxWidth:"1224px"}}>
                        <main className="card border-0 p-5">
                            <section className="card-body my-5" >
                                <Loading />
                            </section>
                        </main>
                    </div>
                </div>
            </>
        )
    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling

        const breakline = document.querySelectorAll('.break-div');
        const main = document.getElementById('main-semantic');
        const section = document.getElementById('section-semantic');
        const faithHeader = document.getElementById('faith-header');
        const mainDiv = document.getElementById('main-div');
        const loadingDiv = document.getElementById('loading-div');
        const triggerButton = document.getElementById('print-tor');
        const resetButton = document.getElementById('btn-reset');
        const containerDiv = document.getElementById('container-div');
        const attendanceStyleMain = document.querySelectorAll(".attendance-style-main");
        const faithHeaderPrint = document.querySelectorAll(".faith-header-print-only");

        if (faithHeaderPrint) {
            for (let i = 0; i < faithHeaderPrint.length; i++) {
                faithHeaderPrint[i].style.display = 'block';
            }
        }

        if (attendanceStyleMain) {
            for (let i = 0; i < attendanceStyleMain.length; i++) {
                attendanceStyleMain[i].classList.add('attendance-style-main-onprint');
            }
        }

        if (breakline) {
            for (let i = 0; i < breakline.length; i++) {
                breakline[i].classList.remove('break-line');
              }
        }

        if (main) {
            main.classList.remove('card', 'border-0', 'p-5');
        }

        if (section) {
            section.classList.remove('card-body', 'my-5');
        }

        if (faithHeader) {
            faithHeader.style.display = 'none';
        }

        if (mainDiv) {
            mainDiv.style.display = 'none';
        }

        if (loadingDiv) {
            loadingDiv.classList.remove('d-none');
        }

        if (triggerButton) {
            triggerButton.setAttribute('disabled','disabled');
        }

        if (resetButton) {
            resetButton.setAttribute('disabled','disabled');
        }

        if (containerDiv) {
            containerDiv.classList.remove('container');
        }
        
    }

    const handleAfterPrint = () => {
        const breakline = document.querySelectorAll('.break-div');
        let main = document.getElementById('main-semantic');
        let section = document.getElementById('section-semantic');
        const faithHeader = document.getElementById('faith-header');
        const mainDiv = document.getElementById('main-div');
        const loadingDiv = document.getElementById('loading-div');
        const triggerButton = document.getElementById('print-tor');
        const resetButton = document.getElementById('btn-reset');
        const containerDiv = document.getElementById('container-div');
        const attendanceStyleMain = document.querySelectorAll(".attendance-style-main-onprint");
        const faithHeaderPrint = document.querySelectorAll(".faith-header-print-only");

        if (faithHeaderPrint) {
            for (let i = 0; i < faithHeaderPrint.length; i++) {
                faithHeaderPrint[i].style.display = 'none';
            }
        }

        if (attendanceStyleMain) {
            for (let i = 0; i < attendanceStyleMain.length; i++) {
                attendanceStyleMain[i].classList.remove('attendance-style-main-onprint');
            }
        }

        if (breakline) {
            for (let i = 0; i < breakline.length; i++) {
                breakline[i].classList.add('break-line');
              }
        }

        if (mainDiv) {
            mainDiv.style.display = 'block';
        }

        if (main) {
            let listMain = main.classList;
            listMain.add('card', 'border-0', 'p-5');
        }

        if (section) {
            let listSection = section.classList;
            listSection.add('card-body', 'my-5');
        }

        if (faithHeader) {
            faithHeader.style.display = 'block';
        }

        if (loadingDiv) {
            loadingDiv.classList.add('d-none');
        }

        if (triggerButton) {
            triggerButton.removeAttribute('disabled');
        }

        if (resetButton) {
            resetButton.removeAttribute('disabled');
        }

        if (containerDiv) {
            containerDiv.classList.add('container');
        }
        
    }

    const printReportCard = useReactToPrint({
        // onBeforePrint: handleBeforePrint,
        onBeforeGetContent: handleBeforePrint,
        onAfterPrint: handleAfterPrint,
        content: () => componentRef.current
    });

    return (
        <>
            <div>
                <button 
                    id="print-tor" 
                    //style={{width:"15%"}} 
                    className="mt-3 btn btn-success"
                    onClick={printReportCard} {...attendanceSheet.length == 0 ? {disabled:true} : {}}>
                    <i className="fa fa-print"></i>&nbsp;Print Attendance Sheet
                </button>
            </div>
            
            {/* button to trigger printing of target component */}
            {/* <ReactToPrint
                trigger={() => <button className="btn btn-success btn-block" {...clearanceLength > 0 ? {disabled:true} : {}}>Print</button>}
                onBeforePrint={handleBeforePrint}
                onBeforeGetContent={onGetControlNumber}
                content={() => componentRef.current}
            /> */}
            <Component ref={componentRef}/>
        </>
    )
}