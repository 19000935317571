import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    instituteDropdown, instructorDropdown, attendanceSheet, 
    attendanceDropdownChecker, attendanceSearchSubjects,
    attendanceSubjectSchedId, getSchoolYear, getSemester
} from './__hooks__';
import PrintAttendanceSheet from "./PrintAttendanceSheet";
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import moment from "moment";

export default function StudentAttendance() {

    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const { register, handleSubmit, errors } = useForm();
    const [attendance, setAttendance] = useState([]);
    const [onAlert, setOnAlert] = useState(false);
    const [hasDisplay, setHasDisplay] = useState([]);
    const [employeeId, setEmployeeId] = useState([]);
    const [subjectSelect, setSubjectSelect] = useState([]);
    const [institutes, setInstitutes] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [schoolYearSelect, setSchoolYearSelect] = useState(null);
    const [semesterSelect, setSemesterSelect] = useState(null);
    const [subject, setSubject] = useState('');
    //const [ssdIdList, setSsdIdList] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    var days = [];

    const startDate = moment().format('YYYY-MM-DD');
    const endDate = moment().add(30, 'days').format('YYYY-MM-DD');

    const onReset = async () => {
        setAttendance([]);
        setSubject('');
        setSubjectSelect([]);
        setOnAlert(false);
        handleSubjectInputChange('');
    }

    const [filter, setFilter] = useState({ 
        institutes: '', 
        instructors: '',
        schoolYear: auth.data.schoolyears.enrollment.SchoolYear, 
        semesterId: auth.data.schoolyears.enrollment.SemesterId
    });

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    const executeGetStudentAttendanceSSDId = async (formValues) => {
        let values = {
            employeeId: hasDisplay == 1 ? filter.instructors == '' ? null : filter.instructors : employeeId,
            classCodeStart: formValues.classCodeStart == '' ? null : formValues.classCodeStart,
            classCodeEnd: formValues.classCodeEnd == '' ? null : formValues.classCodeEnd,
            subjectId: subject == '' ? null : subject,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId
        }

        await attendanceSubjectSchedId(values)
            .then(res => {
                if(res.success){
                    //console.log("attendanceSubjectSchedId res: ",res)
                    if (res.data.length > 0) {
                        setOnAlert(false);
                        executeGetStudentAttendance(values, res.data, formValues.startDate, formValues.endDate);
                    } else {
                        setOnAlert(true);
                    }
                }
            })
            .catch(error => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                if (error.response.status === 401) {
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                }
            })

    }

    const executeGetStudentAttendance = async (formValues, ssdIdList, startDate, endDate) => {        
        const newArray = [];
        for(let i = 0; i < ssdIdList.length; i++) {
            setIsLoading(true);
            let values = {
                employeeId: ssdIdList[i].EmployeeId,
                subjectSchduleDetailId: ssdIdList[i].SubjectScheduleDetailId,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId
                // classCodeStart: formValues.classCodeStart == '' ? null : formValues.classCodeStart,
                // classCodeEnd: formValues.classCodeEnd == '' ? null : formValues.classCodeEnd,
                // subjectId: subject == '' ? null : subject,
                // instituteId: 0
            }

            await attendanceSheet(values)
                .then(res => {
                    //console.log("attendanceSheet res: ",res)
                    if(res.success){
                        //console.log("attendanceSheet: ",res);
                        
                        if (res.data.length > 0) {
                            setOnAlert(false);
                            let originalData = res.data;

                            const transformedArray = [];
                            var selectedDays = [];

                            for (const key in originalData) {
                                const entry = originalData[key];
                                const { UniqueIdentifier,Instructor, SubjectCode, Description, 
                                    ClassCode, ClassSection, DayCode, SubjectType, Time, Room,
                                    SchoolYear, Semester, StudentNo, LastName, FirstName, MiddleName } = entry;                        
                                const attendanceDetailIndex = transformedArray.findIndex(item => item.UniqueIdentifier === UniqueIdentifier);
                                
                                if (attendanceDetailIndex === -1) {
                                    for(let i = 0; i < DayCode.length; i++){
                                        switch(DayCode[i]){
                                            case 'M':
                                                selectedDays.push(1);
                                                break;
                                            case 'T':
                                                selectedDays.push(2);
                                                break;
                                            case 'W':
                                                selectedDays.push(3);
                                                break;
                                            case 'H':
                                                selectedDays.push(4);
                                                break;
                                            case 'F':
                                                selectedDays.push(5);
                                                break;
                                            case 'S':
                                                selectedDays.push(6);
                                                break;
                                            default:
                                                selectedDays.push(0);
                                                break;
                                        }
                                    }

                                    const currentDate = new Date(startDate);
                                    const endDateFormatted = new Date(endDate);
                                    let limit = 1;

                                    while (currentDate <= endDateFormatted && limit <= 30) {
                                      if (selectedDays.includes(currentDate.getDay())) {
                                            let newDate = (currentDate.getMonth() + 1) + '/' + currentDate.getDate() + '/' +  currentDate.getFullYear();
                                            days.push(newDate);
                                            limit++;
                                      }

                                      // Increment the current date by 1 day
                                      currentDate.setDate(currentDate.getDate() + 1);
                                    }

                                    transformedArray.push({ 
                                        UniqueIdentifier:UniqueIdentifier,
                                        Instructor: Instructor, 
                                        SubjectCode: SubjectCode,
                                        Description: Description, 
                                        ClassCode: ClassCode, 
                                        ClassSection: ClassSection,
                                        DayCode: DayCode,
                                        Time: Time,
                                        Room: Room,
                                        SchoolYear: SchoolYear,
                                        Semester: Semester,
                                        Days: days, 
                                        SubjectType: SubjectType,
                                        Details: [{ StudentNo, LastName, FirstName, MiddleName }]
                                    });

                                    selectedDays = [];
                                    days = [];
                                    
                                    //console.log("transformedArray: ",transformedArray);
                                    
                                } else {
                                  transformedArray[attendanceDetailIndex].Details.push({ StudentNo, LastName, FirstName, MiddleName });
                                }
                            }

                            for(let ii = 0; ii < transformedArray.length; ii++){
                                newArray.push(transformedArray[ii]);
                            }
                            //console.log("newArray: ",newArray);
                        } else {
                            setOnAlert(true);
                        }
                    }
                })
                .catch(error => {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                    if (error.response.status === 401) {
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        setAttendance(newArray);
        setIsLoading(false);
    }

    useEffect(() => {
        // SelectCreator(instituteDropdown, setInstitutes, "InstituteId", "Description");
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description");
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description");
        getAttendanceChecker();
        availableInstructors();
        handleSubjectInputChange('');
    }, [])

    useEffect(() => {
        availableInstructors();
    },[filter]);

    // useEffect(() => {
    //     if(filter.institutes != ''){
    //         availableInstructors();
    //         //SelectCreator(instructorDropdown, setInstructors, "EmployeeId", "DropdownOption",{});
    //     }
    // }, [filter.institutes])


    const handleSubjectInputSelect = async (data) => {
        setSubject(data.tableId);
        setOnAlert(false);
    }

    const handleSubjectInputChange = async (input) => {
        let values = {
            input: input 
        }

        //console.log("values: ",values);

        await attendanceSearchSubjects(values)
            .then(res => {
                setSubjectSelect(res.data);
                //console.log("attendanceSearchSubjects: ",res.data);
            })
            .catch(e => {
    
            })
    }


    const getAttendanceChecker = async () => {
        await attendanceDropdownChecker({
            userId: auth.data.UserId,
        })
            .then(res => {
                //console.log("res: ",res);
                if (res.success) {
                   setHasDisplay(res.data[0].DropdownDisplay);
                   setEmployeeId(res.data[0].EmployeeId);
                }
            })
            .catch(e => {
                return []
            })
    }

    const availableInstructors = async () => {
        let value = {
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId
        };

        await instructorDropdown(value)
            .then(res => {
                let initialExec = true;
                if (res.success) {
                    let arr = [];

                    if(initialExec){
                        initialExec = false;
                        arr.push({tableId: -1, code: "---All---"});
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].IsActive == 1)
                            arr.push({ tableId: res.data[i]["EmployeeId"], code: res.data[i]["DropdownOption"] })
                    }
                    setInstructors(arr)
                }
            })
            .catch(e => {
                return []
            })
    }

    const onSubmit = formValues => {

        //console.log("formValues: ",formValues);
        
        // if(document.getElementById("startDate") !== null){
        //     document.getElementById("startDate").addEventListener("change", function() {
        //         let input = this.value;
        //         let dateEntered = new Date(input);
        //         console.log(input); //e.g. 2015-11-13
        //         console.log(dateEntered); //e.g. Fri Nov 13 2015 00:00:00 GMT+0000 (GMT Standard Time)
        //     });
        // }
    
        // if(document.getElementById("endDate") !== null){
        //     document.getElementById("endDate").addEventListener("change", function() {
        //         let input = this.value;
        //         let dateEntered = new Date(input);
        //         console.log(input); //e.g. 2015-11-13
        //         console.log(dateEntered); //e.g. Fri Nov 13 2015 00:00:00 GMT+0000 (GMT Standard Time)
        //     });
        // }

        if(formValues.startDate > formValues.endDate){
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Start date should not be greater than End date." });
        } else {
            if(formValues.startDate.length == 0 || formValues.endDate.length == 0) {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Start date and End date required." });
                // setOnAlert(true);
            } 
            
            // else if(filter.instructors.length == 0){
            //     if(hasDisplay == 1){
            //         setOnAlert(true);
            //     } else {
            //         setOnAlert(false);
            //         executeGetStudentAttendance(formValues);
            //     }
            // } 
            
            else {
                executeGetStudentAttendanceSSDId(formValues)
                //executeGetStudentAttendance(formValues);
            }
        }
    }

    if(document.getElementById("startDate") !== null || document.getElementById("endDate") !== null){
        document.getElementById("startDate").addEventListener("change", function() {
            setOnAlert(false);
        });

        document.getElementById("endDate").addEventListener("change", function() {
            setOnAlert(false);
        });
    }

    return (
        <>
            {attendance.length == 0 && !isLoading && schoolYearSelect && semesterSelect &&

            
            <div className='d-flex align-items-center'>
                <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                    <header className='card-header p-5 bg-secondary'>
                        <h6 class="float-left m-0 mt-4 ml-5">Student Attendance Sheet</h6>
                    </header>

                    <section className="card-body">
                        <form onSubmit={handleSubmit(onSubmit)}>
                            {onAlert == true && attendance.length == 0 &&
                                <div className="alert alert-danger" role="alert">
                                    No Record Found
                                </div>
                            }

                            {/* School Year Dropdown */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={schoolYearSelect}
                                        onHandleChange={filterChange('schoolYear')}
                                        refClassContainer=""
                                        name="schoolYear"
                                        isRequired={false}
                                        withLabel={true}
                                        label="School Year"
                                        placeHolder="Select School Year"
                                        forwardRef={() => { }}
                                        selectedId={filter.schoolYear}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`School Year`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[
                                        ]
                                        }
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>
                                                
                            {/* Semester Dropdown */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={semesterSelect}
                                        onHandleChange={filterChange('semesterId')}
                                        refClassContainer=""
                                        name="semesterId"
                                        isRequired={false}
                                        withLabel={true}
                                        label="Semester"
                                        placeHolder="Select Semester"
                                        forwardRef={() => { }}
                                        selectedId={filter.semesterId}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`Semester`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[
                                        ]
                                        }
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>

                            {
                                hasDisplay == 1 ?

                                <>                                            
                                    {/* Instructors */}
                                    <div className="form-row">
                                        <div className="form-group col">
                                            <IMSelect
                                                data={instructors}
                                                onHandleChange={filterChange('instructors')}
                                                refClassContainer=""
                                                name="instructors"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Instructors"
                                                placeHolder="Select Instructor"
                                                forwardRef={() => { }}
                                                selectedId={filter.instructors}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                description={`Instructors`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                                isWithToolTip={true}
                                                toolTipText={'Selection based on faculty with load for the selected school year and semester'}
                                            />
                                        </div>
                                    </div>
                                </>

                                :

                                <></>
                            }

                            {/* Subject Code Autocomplete Dropdown */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="studentSubjects" style={{marginRight:"1%"}}>Search by Subject</label>
                                    <i class="fa fa-info-circle" title="Optional; Leave blank to search for all/available subjects"></i>
                                    <Autocomplete
                                        id="studentSubjects"
                                        options={subjectSelect}
                                        getOptionLabel={(option) => option.code}
                                        onChange={(event, value) => {
                                            if (value) {
                                                handleSubjectInputSelect(value)
                                                // Handle the selected option
                                            } else {
                                                // setEmployee(null);
                                                // setEmployeeId(null);
                                                // setEmployeeNo(null);
                                                // setEmployeeList([]);
                                                setSubject('');
                                                // Handle the case when no option is selected
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Search Subject Code/Description"
                                                variant="outlined"
                                                inputRef={register}
                                                name="studentSubjects"
                                                value={subjectSelect}
                                                onChange={
                                                    (e) => { handleSubjectInputChange(e.target.value) }
                                                }
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>

                            {/* Subject Code Autocomplete Dropdown */}
                            <div className="">
                                <div className="" style={{textAlign:"center",fontSize:"large",fontWeight:"100"}}>
                                    <span style={{letterSpacing:"-3px"}}>————————————</span> OR <span style={{letterSpacing:"-3px"}}>————————————</span>
                                </div>
                            </div>
                            <br></br>

                            {/* Class Code */}
                            <div className="form-row attendance-class-code">
                                <label htmlFor="" style={{marginRight:"1%"}}>Search by Class Code (Range)</label>
                                <i class="fa fa-info-circle" title="Optional; Leave blank to search for all/available class codes"></i>
                                <div className="row" style={{margin:"auto"}}>
                                    <div>
                                        <input
                                          ref={register({
                                            required: false
                                          })}
                                          name="classCodeStart"
                                          type="text"
                                          className="form-control"
                                          id="classCodeStart"
                                          placeholder="Class Code (From)"
                                          onBlur={(e) => { setOnAlert(false) }}
                                        />
                                    </div>

                                    <div style={{lineHeight:"1.6", margin:"0px 20px"}}>to</div>

                                    <div>
                                        <input
                                          ref={register({
                                            required: false
                                          })}
                                          name="classCodeEnd"
                                          type="text"
                                          className="form-control"
                                          id="classCodeEnd"
                                          placeholder="Class Code (To)"
                                          onBlur={(e) => { setOnAlert(false) }}
                                        />
                                    </div>
                                    
                                </div>
                            </div>
                            
                            <hr></hr>

                            
                            <div className="form-row attendance-class-code">
                                <div className="row" style={{margin:"auto"}}>
                                    
                                    {/* Start Date */}
                                    <div className="form-group col">
                                        <label htmlFor="startDate">Start Date<span className="text-danger">&nbsp;*</span></label>
                                        <input
                                          ref={register({
                                            required: false
                                          })}
                                          name="startDate"
                                          type="date"
                                          className="form-control"
                                          id="startDate"
                                          placeholder="Start Date"
                                          defaultValue={startDate}
                                          // defaultValue={props.value !== undefined && props.value !== null ? props.value.birthDate : ''}
                                          // onBlur={(e) => { setData({ ...data, birthDate: e.target.value }) }}
                                        />
                                    </div>

                                    {/* End Date */}
                                    <div className="form-group col">
                                        <label htmlFor="endDate">End Date<span className="text-danger">&nbsp;*</span></label>
                                        <input
                                          ref={register({
                                            required: false
                                          })}
                                          name="endDate"
                                          type="date"
                                          className="form-control"
                                          id="endDate"
                                          placeholder="End Date"
                                          defaultValue={endDate}
                                          // defaultValue={props.value !== undefined && props.value !== null ? props.value.birthDate : ''}
                                          // onBlur={(e) => { setData({ ...data, birthDate: e.target.value }) }}
                                        />
                                    </div>
                                </div>
                            </div>

                            {/* <div className="form-row bg-secondary"> */}
                            <div className="form-row">
                                <section className="col"> 
                                    {/* <button onClick={() => { onSearch() }} className='btn btn-success btn-block'>Search</button> */}
                                    <button type="submit" className='btn btn-success btn-block'>Search</button> 
                                </section>
                            </div>
                        </form>
                    </section>
                </main>
            </div>

            }

            {attendance.length > 0 && !isLoading &&

            <div>
                <div className="container" >
                    <main className="card border-0 p-5">
                        <header className="card-header"> 
                            <div className='row'>
                                <button id="btn-reset" style={{width:"11.65%"}} className="mt-3 btn btn-primary mr-3" onClick={() => { onReset() }} > 
                                    <i className="fa flaticon-reply"></i>&nbsp;Back
                                </button>   
                                <PrintAttendanceSheet results={[attendance]}/>
                            </div>
                        </header>
                    </main>
                </div>
            </div>
            }

            {
                isLoading &&
                <Loading />
            }
        </>
    )
}