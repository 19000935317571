import {
    getStudentInfo, getStudent, getSem, getSchoolYear, getEnrollment, getSubjectSchedule, getStudentEnrollmentSubjects, getSubjectScheduleDetail,
    getSubjectScheduleDetailSelect, getSubjects, getSubSchedDetailList, getEnrollmentAccAddOn, getStudentDiscount, getAssessmentTemplate, getFees, getAssessmentTemplateDetail,
    getFeeGroup, getPaymentAddOns, createEnrollment, getCourseOutline, getCourseOutlineDetail
} from "./__hooks__"
import {
    getAssessmentStudentSubjectScheduleDetail, getStudentAssessment
} from "./__hooks__/proc"
import React, { useState, useEffect } from "react";
import CustomError from "../../../../../_metronic/layout/components/custom/forms/CustomError";
import { useParams } from "react-router-dom";
import ReAssessment from "./ReAssessment";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import NoDocuments from "../../../../../_metronic/layout/components/custom/forms/NoDocuments";
import NoDataFound from "../../../../../_metronic/layout/components/custom/forms/NoDataFound";
import { Alert } from "bootstrap";

export default function Assessment(props) {


    const { id } = useParams()
    //status
    const [activeSy, setActiveSy] = useState()
    const [activeSem, setActiveSem] = useState()
    const [currentSem, setCurrentSem] = useState()
    const [currentSy, setCurrentSy] = useState()
    //student info
    const [studentInfo, setStudentInfo] = useState()
    const [student, setStudent] = useState()
    const [enrollment, setEnrollment] = useState([])
    const [enrollmentAcc, setEnrollmentAcc] = useState([])
    const [studentDiscount, setStudentDiscount] = useState()
    //subject schedule
    const [subjectSchedule, setSubjectSchedule] = useState([])
    const [studentEnrollmentSubjects, setStudentEnrollmentSubjects] = useState([])
    const [subjectScheduleDetail, setSubjectScheduleDetail] = useState([])
    const [classSectionDropdown, setClassSectionDropdown] = useState()
    const [subjectDropdown, setSubjectDropdown] = useState()
    const [iregSubSchedDetail, setIregSubSchedDetail] = useState([])


    const [subject, setSubject] = useState([])
    const [subjectIreg, setSubjectIreg] = useState()
    const [subjectScheduleDetailList, setSubjectScheduleDetailList] = useState([])
    const [subjectScheduleDetailIregList, setSubjectScheduleDetailIregList] = useState([])

    //assessment template
    const [assessmentTemplate, setAssessmentTemplate] = useState([])
    const [assessmentTemplateDetail, setAssessmentTemplateDetail] = useState([])
    const [studentAssessmentTemplate, setStudentAssessmentTemplate] = useState([])

    //Course Outline
    const [courseOutline, setCourseOutline] = useState()
    const [courseOutlineDetail, setCourseOutlineDetail] = useState()
    const [courseOutlineSubSched, setCourseOutlineSubSched] = useState()
    //others
    const [fees, setFees] = useState([])
    const [feeGroup, setFeeGroup] = useState([])
    const [paymentAddOns, setPaymentAddOns] = useState()
    const [paymentModeId, setPaymentModeId] = useState()
    const [subjectCode, setSubjectCode] = useState([])

    //logic
    const [subjectSchedClassId, setSubjectSchedClassId] = useState()
    const [keepLoading, setKeepLoading] = useState(true)
    const [oneTimeSavedEnrollment, setOneTimeSavedEnrollment] = useState(true)
    const [allEnrollmentRecord, setAllEnrollmentRecord] = useState()
    const [tfInterest, setTfInterest] = useState()
    const [totalInterestFee, setTotalInterestFee] = useState(0)
    const [isIrregular, setIsIrregular] = useState(false)
    const [selectedSubjectCodes, setSelectedSubjectCodes] = useState(['NOTHING'])
    const [selectedClassSections, setSelectedClassSections] = useState([])
    const [selectedClassSection, setSelectedClassSection] = useState(0)
    const [isIrregularFlag, setIsIrregularFlag] = useState(0)
    const [isIregSubSchedDetailId, setIsIregSubSchedDetailId] = useState([0])
    const [selectedYearLevelId, setSelectedYearLevelId] = useState(null)
    const createAccountHandler = async () => {


        const enrollmentValues = {
            studentId: student[0].StudentId,
            schoolYear: currentSy[0].SchoolYear,
            semesterId: currentSem[0].SemesterId,
            studentCategoryId: student[0].studentCategoryId,
            courseId: student[0].CourseId,
            yearLevelId: student[0].YearLevelId,
            enrollmentStatusId: 'DR'

        }

        const studentValues = {
            semesterId: currentSem[0].SemesterId,
            schoolYear: currentSy[0].SchoolYear
        }

        onCreateEnrollment(enrollmentValues)
        // onUpdateEnrollment(enrollment[0].EnrollmentId, enrollmentValues)


    }

    const onCreateEnrollment = (values) => {
        const execute = async (data) => {
            await createEnrollment(data)
                .then(response => {
                    if (response.success) {
                        // studentInfo
                        Query(getStudentInfo, studentInfoQuery, setStudentInfo)
                        //active sem
                        Query(getSem, getActive, setCurrentSem)
                        //active sy
                        Query(getSchoolYear, getActive, setCurrentSy)
                        //all enrollment
                        Query(getEnrollment, getAll, setAllEnrollmentRecord)
                        // let studentId = response.data.StudentId
                        // let studentVal = {
                        //     semesterId: response.data.SemesterId,
                        //     schoolYear: response.data.SchoolYear
                        // }

                        // await updateStudent(studentId, studentVal)
                        //     .then(res => {


                        //     })
                        //     .catch(e => {

                        //     })
                    }
                })
                .catch(error => {

                })
        }

        execute(values)
    }

    const paymentModeChangeHandler = (paymentMode) => {
        console.log(paymentMode)
        setPaymentModeId(paymentMode)
    }



    const onClassYearLevelChangeHandler = (yearLevelId) => {
        setSelectedYearLevelId(yearLevelId)
        const executeGetStudentAssess = async (yl) => {
            await getAssessmentTemplate({ where: `WHERE SchoolYear = ${currentSy[0].SchoolYear} AND SemesterId = '${currentSem[0].SemesterId}'  AND (YearLevelId = ${yl} OR YearLevelId IS NULL)  AND IsIrregular = ${isIrregularFlag} AND (InstituteId = ${student[0].InstituteId}  OR Institute IS NULL)  AND (CourseId = ${student[0].CourseId} OR CourseId IS NULL) ORDER BY CourseId DESC LIMIT 4` })
                .then(response => {
                    if (response.success) {
                        setStudentAssessmentTemplate(response.data)

                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }
        executeGetStudentAssess(yearLevelId)

    }

    const subjectScheduleChangeHandler = (subjectSchedule, subCode) => {
        console.log(subCode)
        console.log(subjectSchedule)
        setSubjectSchedClassId(subjectSchedule)
        setSubjectCode(subCode)
        setSelectedSubjectCodes(subCode)
        console.log(subCode)
    }

    const onIrregularHandler = (data) => {
        setIsIrregular(data)
        if (data === true) {
            setIsIrregularFlag(1)
        } else {
            setIsIrregularFlag(0)

        }
    }


    const onSelectedSubCodeHandler = (data) => {
        console.log(data)
        setSelectedSubjectCodes(data)
    }

    const onSelectedClassHandler = (data) => {
        setSelectedClassSection(data)
    }

    const onAddIregSub = (data) => {
        let query = { where: `WHERE SubjectScheduleDetailId = ${data} ORDER BY ClassCode` }
        console.log(data)
        const exeGetIregSubSchedDetl = async (q) => {
            await getSubjectScheduleDetail(q)
                .then(res => {
                    let arr = []
                    console.log(iregSubSchedDetail)
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push(...iregSubSchedDetail, res.data[i])
                    }
                    console.log(arr)
                    setIregSubSchedDetail(arr)
                })
                .catch(e => {

                })
        }
        exeGetIregSubSchedDetl(query)
    }

    const onExistingIregSub = (data) => {
        let query = { where: `WHERE SubjectScheduleDetailId IN (${data.map(d => { return d })})  ORDER BY ClassCode` }
        const exeGetIregSubSchedDetl = async (q) => {
            await getSubjectScheduleDetail(q)
                .then(res => {

                    setIregSubSchedDetail(res.data)
                })
                .catch(e => {

                })
        }
        exeGetIregSubSchedDetl(query)
    }

    const onByPassCourseOutline = (data) => {
        const createSubjectDropdown = async (q) => {
            await getSubjectScheduleDetailSelect(q)
                .then(res => {
                    console.log(res.data)
                    let arr = []
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push({ tableId: res.data[i].SubjectScheduleDetailId, code: `${res.data[i].ClassCode} - ${res.data[i].SubjectCode}` })
                    }
                    setSubjectDropdown(arr)
                })
                .catch(e => {

                })
        }
        if (data === true) {
            createSubjectDropdown({ where: `` })

        } else {



            if (courseOutlineSubSched !== undefined && courseOutlineSubSched.length > 0) {
                createSubjectDropdown({ where: `WHERE SubjectId IN (${courseOutlineDetail.map((d => { return d.SubjectId }))}) AND SubjectScheduleId = ${courseOutlineSubSched[0].SubjectScheduleId}` })

            } else {
                createSubjectDropdown({ where: `WHERE SubjectId IN (${courseOutlineDetail.map((d => { return d.SubjectId }))}) AND InstituteId = ${student[0].InstituteId} ` })

            }



        }
    }


    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }
    let getAll = "{where: ''}"
    let studentInfoQuery = "{ where: `WHERE student_infos.StudentInfoId = ${id}` }"
    let studentQuery = " { where: `WHERE s.StudentInfoId = ${studentInfo[0].StudentInfoId}` }"
    let getActiveQuerySem = "{ where: `WHERE SemesterId = '${student[0].SemesterId}'` }"
    let getActiveQuerySy = "{ where: `WHERE SchoolYear = ${student[0].SchoolYear}` }"
    let getActive = "{where: 'WHERE IsActive = 1'}"
    let getTheLatestActive = "{ where: `WHERE e.StudentId = ${student[0].StudentId} ORDER BY e.EnrollmentId DESC LIMIT 1` }"
    let getEnrollmentQuery = "{ where: `WHERE e.StudentId = ${student[0].StudentId} AND e.SemesterId = '${currentSem[0].SemesterId}' AND e.SchoolYear = ${currentSy[0].SchoolYear}` }"
    let getSubSchedQuery = "{where: `WHERE sched.SemesterId = '${currentSem[0].SemesterId}' AND sched.SchoolYear = ${currentSy[0].SchoolYear}`  }"
    let getStudentEnrollmentSubjectsQuery = "{where: `WHERE student_enrollment_subjects.StudentId = ${enrollment[0].StudentId} AND student_enrollment_subjects.SemesterId = '${currentSem[0].SemesterId}' AND student_enrollment_subjects.SchoolYear = ${currentSy[0].SchoolYear}`}"
    let getSubjectScheduleQuery = "{where: `WHERE  SemesterId = '${currentSem[0].SemesterId}' AND SchoolYear = ${currentSy[0].SchoolYear} AND  ClassSectionId = ${subjectSchedClassId} `}"
    let getSubjectQuery = "{where: `WHERE t1.SubjectId IN (${subjectScheduleDetail.map(d => { return d.SubjectId})})`}"
    let getSubjectScheduleDetailListQuery = "{where: `WHERE SubjectScheduleDetailId IN (${subjectScheduleDetail.map(d => { return d.SubjectScheduleDetailId})})`}"
    let getEnrollmentAccQuery = "{ where: `WHERE StudentId = ${student[0].StudentId} AND SemesterId = '${currentSem[0].SemesterId}' AND SchoolYear = ${currentSy[0].SchoolYear}` }"
    let getStudentDiscountQuery = " { where: `WHERE StudentId = ${enrollment[0].StudentId}` }"
    let getAssessmentTemplateDetailQuery = "{where: `WHERE AssessmentTemplateId = ${assessmentTemplate[0].AssessmentTemplateId}`}"
    let getFeesQuery = "{where: `WHERE FeeId IN (${assessmentTemplateDetail.map(d => { return d.FeeId})}) `}"
    let getFeeGroupQuery = "{ where: `WHERE feegroups.FeeGroupId IN (${fees.map(d => { return d.FeeGroupId })})`}"
    let getSubjectScheduleChangeQuery = "{where: `WHERE  ClassSectionId = ${subjectSchedClassId} AND SemesterId = '${currentSem[0].SemesterId}' AND SchoolYear = ${currentSy[0].SchoolYear}`}"
    let getSubjectExistingQuery = "{where: `WHERE t1.SubjectId IN (${studentEnrollmentSubjects.map(d => { return d.SubjectId})})`}"


    //let getStudentAssessmentTemplateQuery = "{ where: `WHERE SchoolYear = ${currentSy[0].SchoolYear} AND SemesterId = '${currentSem[0].SemesterId}'  AND YearLevelId = ${student[0].YearLevelId} AND IsIrregular = ${isIrregularFlag}`}"

    //let getAssessmentTemplateChangeQuery = "{ where: `WHERE SchoolYear = ${currentSy[0].SchoolYear} AND SemesterId = '${currentSem[0].SemesterId}' AND CourseId = ${enrollment[0].CourseId} AND YearLevelId = ${enrollment[0].YearLevelId} AND PaymentModeId = ${paymentModeId} AND IsIrregular = ${isIrregularFlag}` }" 

    //let getAssessmentTemplateQuery = "{ where: `WHERE SchoolYear = ${currentSy[0].SchoolYear} AND SemesterId = '${currentSem[0].SemesterId}' AND CourseId = ${enrollment[0].CourseId} AND YearLevelId = ${enrollment[0].YearLevelId} AND PaymentModeId = ${enrollment[0].PaymentModeId}` }"


    let getAssessmentTemplateQuery = "{ where: `WHERE SchoolYear = ${currentSy[0].SchoolYear} AND SemesterId = '${currentSem[0].SemesterId}'  AND (YearLevelId = ${student[0].YearLevelId}  OR YearLevelId IS NULL)  AND (InstituteId = ${student[0].InstituteId} OR Institute IS NULL)  AND (CourseId = ${student[0].CourseId} OR CourseId IS NULL) ORDER BY CourseId DESC LIMIT 4` }"
    let getAssessmentTemplateChangeQuery = "{where: `WHERE SchoolYear = ${currentSy[0].SchoolYear}  AND SemesterId = '${currentSem[0].SemesterId}'  AND PaymentModeId = ${paymentModeId} AND (YearLevelId = ${student[0].YearLevelId}  OR YearLevelId IS NULL)  AND IsIrregular = ${isIrregularFlag} AND (InstituteId = ${student[0].InstituteId}  OR Institute IS NULL)  AND (CourseId = ${student[0].CourseId}  OR CourseId IS NULL) ORDER BY CourseId DESC LIMIT 4`}"
    let getStudentAssessmentTemplateQuery = "{where: `WHERE SchoolYear = ${currentSy[0].SchoolYear} AND SemesterId = '${currentSem[0].SemesterId}'  AND (YearLevelId = ${student[0].YearLevelId} OR YearLevelId IS NULL)  AND IsIrregular = ${isIrregularFlag} AND (InstituteId = ${student[0].InstituteId}  OR Institute IS NULL)  AND (CourseId = ${student[0].CourseId} OR CourseId IS NULL) ORDER BY CourseId DESC LIMIT 4`}"
    let getPaymentAddOnsQuery = "{ where: `WHERE payment_addons.SchoolYear = ${currentSy[0].SchoolYear} AND payment_addons.SemesterId = '${currentSem[0].SemesterId}' AND payment_addons.CourseId = ${enrollment[0].CourseId} AND payment_addons.YearLevelId = ${enrollment[0].YearLevelId} AND payment_addons.InstituteId = ${enrollment[0].InstituteId} ` }"
    let getSubjectScheduleClassSectionQuery = "{where: `WHERE ClassSectionId IS NOT NULL AND SemesterId = '${currentSem[0].SemesterId}' AND SchoolYear = ${currentSy[0].SchoolYear}  AND (ClassSectionCourseId = ${student[0].CourseId} OR ClassSectionCourseId IS NULL) AND InstituteId = ${student[0].InstituteId}  GROUP BY ClassSectionId`}"
    let getSubjectScheduleChangeIrregularQuery = "{where: `WHERE (  SubjectCode IN (${selectedSubjectCodes.map(d => { return `'${d}'` })}) AND ClassSectionId IN  (${selectedClassSections.map(d => { return d })}) ) AND SemesterId = '${currentSem[0].SemesterId}' AND SchoolYear = ${currentSy[0].SchoolYear} OR ClassSectionId = ${selectedClassSection} ORDER BY ClassCode`}"
    let getCourseOutlineQuery = "{where: `WHERE CourseId = ${student[0].CourseId} ORDER BY CourseOutlineId DESC LIMIT 1`}"
    let getCourseOutlineDetailQuery = "{where: `WHERE CourseOutlineId = ${courseOutline[0].CourseOutlineId}`}"
    let getSubSchedCouseOutlineQuery = "{where: `WHERE cs.CourseId = ${courseOutline[0].CourseId}`}"
    let getSubjectIregQuery = "{where: `WHERE t1.SubjectId IN (${iregSubSchedDetail.map(d => { return d.SubjectId})})`}"
    let getSubjectScheduleDetailListIregQuery = "{where: `WHERE SubjectScheduleDetailId IN (${iregSubSchedDetail.map(d => { return d.SubjectScheduleDetailId})})`}"




    useEffect(() => {
        // studentInfo
        Query(getStudentInfo, studentInfoQuery, setStudentInfo)
        //active sem
        Query(getSem, getActive, setCurrentSem)
        //active sy
        Query(getSchoolYear, getActive, setCurrentSy)
        //all enrollment
        Query(getEnrollment, getAll, setAllEnrollmentRecord)


    }, [])



    useEffect(() => {
        if (studentInfo !== undefined) {
            // student
            Query(getStudent, studentQuery, setStudent)
        }
    }, [studentInfo])


    useEffect(() => {
        if (student !== undefined && currentSem !== undefined && currentSy !== undefined) {
            // enrollment
            Query(getEnrollment, getEnrollmentQuery, setEnrollment)
            // enrollment account
            Query(getEnrollmentAccAddOn, getEnrollmentAccQuery, setEnrollmentAcc)
            //active sem
            Query(getSem, getActiveQuerySem, setActiveSem)
            //active sy
            Query(getSchoolYear, getActiveQuerySy, setActiveSy)


            Query(getSubjectScheduleDetail, getSubjectScheduleClassSectionQuery, setClassSectionDropdown)
            //get course outline
            Query(getCourseOutline, getCourseOutlineQuery, setCourseOutline)
        }
    }, [student, currentSem, currentSy])

    useEffect(() => {
        if (student !== undefined && student.length > 0) {
            // all assessment template (for dropdown purposes)
            Query(getAssessmentTemplate, getStudentAssessmentTemplateQuery, setStudentAssessmentTemplate)
        }
    }, [student, isIrregularFlag])

    useEffect(() => {
        if (enrollment !== undefined && currentSem !== undefined && currentSy !== undefined) {
            if (enrollment.length > 0) {

                // student enrollment sub
                Query(getStudentEnrollmentSubjects, getStudentEnrollmentSubjectsQuery, setStudentEnrollmentSubjects)
                // student Discount
                Query(getStudentDiscount, getStudentDiscountQuery, setStudentDiscount)

                // get payment addons
                Query(getPaymentAddOns, getPaymentAddOnsQuery, setPaymentAddOns)




            }
        }
    }, [enrollment, currentSem, currentSy, paymentModeId])


    useEffect(() => {
        if (isIrregular === false) {
            // setSelectedSubjectCodes([])
            setSelectedClassSections([])
        }
    }, [isIrregular])



    useEffect(() => {
        if (assessmentTemplateDetail.length > 0 && tfInterest !== undefined) {
            console.log(assessmentTemplateDetail)
            Query(getFees, getFeesQuery, setFees)
            let totalAllInterest = 0
            for (let i = 0; i < assessmentTemplateDetail.length; i++) {
                if (assessmentTemplateDetail[i].FeeCode !== 'TF') {
                    totalAllInterest += parseFloat(assessmentTemplateDetail[i].InterestAmount)
                }

            }

            setTotalInterestFee(totalAllInterest)
        }
    }, [assessmentTemplateDetail, tfInterest])

    useEffect(() => {
        if (subjectScheduleDetail !== undefined && subjectScheduleDetail.length > 0) {
            console.log('run')
            if (studentEnrollmentSubjects === undefined || studentEnrollmentSubjects.length === 0) {
                // subject
                Query(getSubjects, getSubjectQuery, setSubject)
            } else {
                // subject
                Query(getSubjects, getSubjectQuery, setSubject)
            }
            // subject schedule detail list
            Query(getSubSchedDetailList, getSubjectScheduleDetailListQuery, setSubjectScheduleDetailList)

        }
    }, [subjectScheduleDetail, studentEnrollmentSubjects])

    useEffect(() => {
        if (iregSubSchedDetail.length > 0) {
            if (studentEnrollmentSubjects.length === 0) {
                // subject
                Query(getSubjects, getSubjectIregQuery, setSubjectIreg)
            } else {
                // subject
                Query(getSubjects, getSubjectExistingQuery, setSubjectIreg)
            }
            // subject schedule detail list
            Query(getSubSchedDetailList, getSubjectScheduleDetailListIregQuery, setSubjectScheduleDetailIregList)

        }
    }, [iregSubSchedDetail, studentEnrollmentSubjects])


    useEffect(() => {
        if (assessmentTemplateDetail !== undefined && assessmentTemplateDetail.length > 0) {

            let objCondition = eval("(" + getFeesQuery + ")");

            const execute = async (condition) => {
                await getFees(condition)
                    .then(response => {
                        if (response.success) {

                            setFees(response.data)
                        }
                        return
                    })
                    .catch(error => {
                        if (error) {
                            return error
                            // alert("something went wrong")
                        } else {
                            return
                        }
                    })
            }

            execute(objCondition)


        }
    }, [assessmentTemplateDetail])

    useEffect(() => {
        if (fees !== undefined) {
            Query(getFeeGroup, getFeeGroupQuery, setFeeGroup)

        }
    }, [fees])

    useEffect(() => {
        if (enrollment.length === 0 && oneTimeSavedEnrollment === true && student !== undefined && currentSem !== undefined && currentSy !== undefined && allEnrollmentRecord !== undefined) {
            let isExisted

            for (let i = 0; i < allEnrollmentRecord.length; i++) {
                if (allEnrollmentRecord[i].StudentId === student[0].StudentId && allEnrollmentRecord[i].SemesterId === currentSem[0].SemesterId && allEnrollmentRecord[i].SchoolYear === currentSy[0].SchoolYear) {
                    isExisted = true
                }
            }
            if (isExisted === true) {

            } else {
                // createAccountHandler()
                setOneTimeSavedEnrollment(false)
            }



        }
    }, [enrollment, student, currentSem, currentSy, allEnrollmentRecord])
    useEffect(() => {
        if (currentSem !== undefined && currentSy !== undefined) {
            // subject schedule
            Query(getSubjectSchedule, getSubSchedQuery, setSubjectSchedule)
        }

    }, [currentSem, currentSy])

    useEffect(() => {
        if (oneTimeSavedEnrollment === false && allEnrollmentRecord !== undefined && allEnrollmentRecord.length > 0) {

            // enrollment
            Query(getEnrollment, getEnrollmentQuery, setEnrollment)
        }

    }, [allEnrollmentRecord])



    useEffect(() => {
        if (classSectionDropdown !== undefined) {
            let arr = []
            for (let i = 0; i < classSectionDropdown.length; i++) {
                arr.push(classSectionDropdown[i].ClassSectionId)
            }
            setSelectedClassSections(arr)
        }
    }, [classSectionDropdown])

    useEffect(() => {
        if (courseOutline !== undefined && courseOutline.length > 0) {
            Query(getCourseOutlineDetail, getCourseOutlineDetailQuery, setCourseOutlineDetail)
            // subject schedule Course Outline
            Query(getSubjectSchedule, getSubSchedCouseOutlineQuery, setCourseOutlineSubSched)
        }
    }, [courseOutline])

    useEffect(() => {
        if (currentSem && currentSy) {
            const createSubjectDropdown = async () => {
                await getSubjectScheduleDetailSelect({ where: `WHERE SemesterId = '${currentSem[0].SemesterId}' AND SchoolYear = ${currentSy[0].SchoolYear}` })
                    .then(res => {
                        console.log(res.data)
                        let arr = []
                        for (let i = 0; i < res.data.length; i++) {
                            arr.push({ tableId: res.data[i].SubjectScheduleDetailId, code: `${res.data[i].ClassCode} - ${res.data[i].SubjectCode}` })
                        }
                        setSubjectDropdown(arr)
                    })
                    .catch(e => {

                    })
            }

            createSubjectDropdown()
        }
    }, [currentSem, currentSy])


    setTimeout(function () {
        setKeepLoading(false)
    }, 1000);


    // REVISION ( STORED PROC)
    useEffect(() => {
        if (iregSubSchedDetail.length > 0) {
            let arr = []
            for (let i = 0; i < iregSubSchedDetail.length; i++) {
                arr.push(iregSubSchedDetail[i].SubjectScheduleDetailId)
            }
            setIsIregSubSchedDetailId(arr)
        }
    }, [iregSubSchedDetail])


    useEffect(() => {
        if (student !== undefined) {
            console.log(paymentModeId)
            let assessmentTempVal = {
                paymentModeId: paymentModeId !== undefined ? paymentModeId : null,
                isIrregular: isIrregularFlag === 0 ? false : true,
                isExisting: false,
                assessmentTemplateId: 0,
                semesterId: currentSem[0].SemesterId,
                schoolYear: currentSy[0].SchoolYear,
                yearLevelId: selectedYearLevelId == null ? student[0].YearLevelId : selectedYearLevelId,
                instituteId: student[0].InstituteId,
                courseId: student[0].CourseId
            }

            getStudentAssessmentTemplate(assessmentTempVal)
        }

    }, [paymentModeId, student])

    useEffect(() => {
        if (student !== undefined) {
            if (student[0].IsCampus !== 1) {
                let subSchedDetlVal = {
                    classSectionId: subjectSchedClassId,
                    semesterId: currentSem[0].SemesterId,
                    schoolYear: currentSy[0].SchoolYear,
                    isCampus: false,
                    batchId: 0,
                    isExist: false,
                    subjectScheduleDetailId: ''
                }
                getStudentAssessmentSubjectScheduleDetail(subSchedDetlVal)
            } else {
                let subSchedDetlVal = {
                    classSectionId: subjectSchedClassId,
                    semesterId: student[0].SemesterId,
                    schoolYear: student[0].SchoolYear,
                    isCampus: true,
                    batchId: student[0].CampusBatchId,
                    isExist: false,
                    subjectScheduleDetailId: ''

                }
                getStudentAssessmentSubjectScheduleDetail(subSchedDetlVal)
            }
        }


    }, [student, selectedClassSection])

    useEffect(() => {
        if (studentEnrollmentSubjects.length > 0 && enrollment[0].EnrollmentStatus !== 'DR') {
            let subIds = []
            for (let i = 0; i < studentEnrollmentSubjects.length; i++) {
                subIds.push(studentEnrollmentSubjects[i].SubjectScheduleDetailId)
            }
            let subSchedDetlVal = {
                classSectionId: 0,
                semesterId: student[0].SemesterId,
                schoolYear: student[0].SchoolYear,
                isCampus: false,
                batchId: 0,
                isExist: true,
                subjectScheduleDetailId: subIds.toString()

            }
            getStudentAssessmentSubjectScheduleDetail(subSchedDetlVal)
        }
    }, [studentEnrollmentSubjects])

    const getStudentAssessmentSubjectScheduleDetail = async (val) => {
        await getAssessmentStudentSubjectScheduleDetail(val)
            .then(res => {
                console.log(res)
                setSubjectScheduleDetail(res.data)
            })
            .catch(e => {

            })
    }

    const getStudentAssessmentTemplate = async (val) => {
        await getStudentAssessment(val)
            .then(res => {
                console.log(res)
                setAssessmentTemplate(res.data)
            })
            .catch(e => {

            })
    }

    console.log(student)
    console.log(fees)
    console.log(feeGroup)
    console.log(assessmentTemplateDetail)
    console.log(assessmentTemplate)
    console.log(isIrregular)
    console.log(studentAssessmentTemplate)
    console.log(selectedYearLevelId)


    console.log(student)
    console.log(studentInfo)
    console.log(activeSem)
    console.log(activeSy)
    console.log(subjectSchedule)
    console.log(subjectScheduleDetail)
    console.log(subject)
    console.log(subjectScheduleDetailList)
    console.log(assessmentTemplate)
    console.log(assessmentTemplateDetail)
    console.log(feeGroup)
    console.log(fees)
    console.log(classSectionDropdown)

    console.log(selectedClassSection)


    return (
        <div >
            {student !== undefined
                && studentInfo !== undefined
                && activeSem !== undefined
                && activeSy !== undefined
                && subjectSchedule !== undefined
                && subjectScheduleDetail !== undefined
                && subject !== undefined
                && subjectScheduleDetailList !== undefined
                && assessmentTemplate !== undefined
                && assessmentTemplateDetail !== undefined
                && fees !== undefined
                && feeGroup !== undefined
                && classSectionDropdown !== undefined ?

                // <h1>Test1</h1>

                <ReAssessment
                    student={student}
                    studentInfo={studentInfo}
                    activeSem={currentSem}
                    activeSy={currentSy}
                    subjectScheduleList={subjectSchedule}
                    studentEnrollmentSub={studentEnrollmentSubjects}
                    subjectScheduleDetail={subjectScheduleDetail}
                    subject={subject}
                    subjectScheduleDetailList={subjectScheduleDetailList}
                    enrollmentAcc={enrollmentAcc}
                    studentDiscount={studentDiscount}
                    assessmentTemplate={assessmentTemplate}
                    assessmentTemplateDetail={assessmentTemplateDetail}
                    fees={fees}
                    feeGroup={feeGroup}
                    studentAssessmentTemplate={studentAssessmentTemplate}
                    paymentModeChangeHandler={paymentModeChangeHandler}
                    subjectScheduleChangeHandler={subjectScheduleChangeHandler}
                    paymentAddOns={paymentAddOns}
                    classSectionDropdown={classSectionDropdown}
                    subjectSchedClassId={subjectSchedClassId}
                    tfInterest={tfInterest}
                    totalInterestFee={totalInterestFee}
                    onIrregularHandler={onIrregularHandler}
                    onSelectedSubCodeHandler={onSelectedSubCodeHandler}
                    onSelectedClassHandler={onSelectedClassHandler}
                    subjectSelect={subjectDropdown}
                    onAddIregSub={onAddIregSub}
                    iregSubSchedDetail={iregSubSchedDetail}
                    onExistingIregSub={onExistingIregSub}
                    onByPassCourseOutline={onByPassCourseOutline}
                    courseOutlineDetail={courseOutlineDetail}
                    subjectIreg={subjectIreg}
                    subjectScheduleDetailIregList={subjectScheduleDetailIregList}
                    onClassYearLevelChangeHandler={onClassYearLevelChangeHandler}
                    isIregSubSchedDetailId={isIregSubSchedDetailId}
                />

                :

                <>
                    {enrollment === undefined || enrollment.length === 0
                        ?
                        <>
                            {keepLoading === true ?
                                <Loading />
                                :
                                <NoDocuments />
                            }
                        </>
                        :
                        <NoDataFound />

                    }
                </>


            }

        </div>

    )
}