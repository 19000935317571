import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { StudentCategorySelect, SubjectCategorySelect } from '../../../../References/';
import { result } from 'lodash';
import IMSelect from '../../../../../../../_metronic/layout/components/custom/select/IMSelect';
export default function Form(props) {
  const { state: { studentCategories, subjectCategories, institutes, courses, yearLevels, scholarships, studyTypes } } = useAppContext();
  const { schoolYearSelect, semesterSelect } = props
  const { AssessmentTemplateRateId, SubjectCategoryId, Amount, IsActive, SchoolYear, SemesterId } = props.doc;
  const { register, errors, setValue } = useForm();
  const recordCnt = props.doc.length;
  const initialState = recordCnt === 0 ? {
    assessmentTemplateDetailId: props.assessmentTemplateDetailId,
    subjectCategoryId: SubjectCategoryId,
    schoolYear: null,
    semesterId: null,
    amount: null,
    isActive: true
  } : {
    assessmentTemplateDetailId: props.assessmentTemplateDetailId,
    studentCategoryId: SubjectCategoryId,
    schoolYear: SchoolYear,
    semesterId: SemesterId,
    amount: Amount,
    isActive: IsActive === 1 ? true : false
  }
  const [data, setData] = useState(initialState);

  useEffect(() => {
    register({ name: 'subjectCategoryId' }, { required: true });
    register({ name: 'amount' }, { required: true });
  }, [register])

  const handleChange = name => event => {
    console.log(event)
    if (name === 'isActive') {
      setData({ ...data, [name]: event.target.checked });
    } else if (name === 'subjectCategoryId' || name === 'instituteId' || name === 'courseId' || name === 'yearLevelId' || name === 'scholarshipId' || name === 'studyTypeId' || name === 'schoolYear' || name === 'semesterId') {
      setData({ ...data, [name]: event });
    } else {
      setData({ ...data, [name]: event.target.value });
    }
  }

  console.log(data)
  console.log(SubjectCategoryId)
  console.log(props.doc)
  console.log(AssessmentTemplateRateId)
  console.log(recordCnt)
  console.log(studentCategories.data)
  return (
    <>
      {props.subjectCategorySelect && <TableRow key={AssessmentTemplateRateId}>
        <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
        <TableCell>


          <IMSelect
            name="schoolYear"
            isRequired={true}
            withLabel={false}
            label="School Year"
            placeHolder="School Year"
            forwardRef={setValue}
            selectedId={SchoolYear === undefined ? "" : SchoolYear}
            data={schoolYearSelect}
            field={{
              tableId: 'tableId',
              display: 'code'
            }}
            onHandleChange={handleChange('schoolYear')}
            withClassName={``}
            withDescription={false}
            withClassContainer=""
            description=""
            disabled={true}

          />
        </TableCell>
        <TableCell>


          <IMSelect
            name="semesterId"
            isRequired={true}
            withLabel={false}
            label="Semester"
            placeHolder="Semester"
            forwardRef={setValue}
            selectedId={SemesterId === undefined ? "" : SemesterId}
            data={semesterSelect}
            field={{
              tableId: 'tableId',
              display: 'code'
            }}
            onHandleChange={handleChange('semesterId')}
            withClassName={``}
            withDescription={false}
            withClassContainer=""
            description=""
            disabled={true}

          />
        </TableCell>
        <TableCell>


          <IMSelect
            name="subjectCategoryId"
            isRequired={true}
            withLabel={false}
            label="Subject Category"
            placeHolder="Subject Category"
            forwardRef={setValue}
            selectedId={SubjectCategoryId === undefined ? "" : SubjectCategoryId}
            data={props.subjectCategorySelect}
            field={{
              tableId: 'tableId',
              display: 'code'
            }}
            onHandleChange={handleChange('subjectCategoryId')}
            withClassName={``}
            withDescription={false}
            withClassContainer=""
            description="Please enter your subject category."
            disabled={true}
            error={!!errors.subjectCategoryId}
          />
        </TableCell>




        <TableCell>
          <input
            onChange={handleChange('amount')}
            type="number"
            name="amount"
            placeholder="Amount"
            defaultValue={Amount === undefined ? "" : Amount}
            className={`form-control${errors.amount === undefined ? "" : errors.amount && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            style={{ width: '150px' }}
          />
        </TableCell>

        <TableCell align="center">
          <FormControl>
            <FormControlLabel
              name="isActive"
              inputRef={register()}
              control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
              label="Active"
            />
          </FormControl>
        </TableCell>
        <TableCell align="center">
          {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => props.onHandleAdd(data)}>
            <i className="fa fa-plus"></i>
          </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
            <i className="fa fa-ban"></i>
          </button>&nbsp;
            <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(AssessmentTemplateRateId, data)}>
              <i className="fa fa-save text-white"></i>
            </button></>}
        </TableCell>
      </TableRow>}

    </>
  );
}