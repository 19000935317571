import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    addingDroppingOfSubjects, attendanceSearchSubjects,
    getActiveSemester, getActiveSchoolYear, studentAutoComplete,
    getSubjectsForDropdown
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import ExportExcel from "../ExportExcel";
import { useAppContext } from "../../../../contexts/useAppContext";
import { TextField } from "@material-ui/core";
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable'
import AddDropExcelFormat from "./AddDropExcelFormat";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

export default function AddDropReport(props) {
    const { state: { auth } } = useAppContext();
    const { setValue, handleSubmit, register, watch, errors } = useForm();
    const [results, setResults] = useState([])

    // START :: Test Pagination
    const [totalRecords, setTotalRecord] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(null);
    const [onSearch, setOnSearch] = useState(false);
    const [onAlert, setOnAlert] = useState(false);
    const [onSearchReset, setOnSearchReset] = useState(true);
    const [subjectSelect, setSubjectSelect] = useState([]);
    const [subject, setSubject] = useState(0);
    const [schoolYearSelect, setSchoolYearSelect] = useState([]);
    const [semesterSelect, setSemesterSelect] = useState([]);
    const [studentList, setStudentList] = useState([]);
    const [studentId, setStudentId] = useState();
    const [filter, setFilter] = useState({ 
        schoolYear: auth.data.schoolyears.grading.SchoolYear,
        semester: auth.data.schoolyears.grading.SemesterId
    });
    
    const onReset = async () => {
        setOnAlert(false);
        setSubject(0);
        setStudentId(0);
        setStudentList([]);
        setOnSearchReset(true);
        setSubjectSelect([]);
        setPage(0);
        setRowsPerPage(10);
        setKeyword(null);
        setOnSearch(false);

    }

    const handleGetStudent = async (data) => {
        setOnAlert(false);
        setOnAlert(false);
        setStudentId(data.StudentId);
    }

    // pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };

    // handle subject search
    const handleSubjectInputChange = async (input) => {
        setOnAlert(false);

        let values = {
            input: input 
        }

        await getSubjectsForDropdown(values)
            .then(res => {
                setSubjectSelect(res.data);
            })
            .catch(e => {
    
            })
    }

    const handleSubjectInputSelect = async (data) => {
        setOnAlert(false);
        setSubject(data.tableId);
    }

    const handleStudentInputChange = async (input) => {
        setOnAlert(false);
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    //When there are changes in Footer
    useEffect(() => {
        addingDroppingOfSubjects({
            rowsPerPage: rowsPerPage,
            page: page,
            keyword: (keyword !== null && keyword != '') ? keyword.trim() : null,
            schoolYear: filter.schoolYear,
            semesterId: filter.semester,
            //subjectId: subject,
            //studentId: studentId,
            //subjectStatusId: 1,
            extractAll: 0
        }).then(res => {
            setResults(res.data);
            if (res.data.length > 0) {
                setTotalRecord(res.data[0].TotalRecord);
            } else {
                setTotalRecord(0);
            }
        })
        //searchForReport();
    }, [page, keyword, rowsPerPage]);

    useEffect(() => {
        SelectCreator(getActiveSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description');
        SelectCreator(getActiveSemester, setSemesterSelect, 'SemesterId', 'Description');
    }, []);

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    const onAddDropSearch = () => {
        searchForReport();
    }

    const searchForReport = async() => {
        if(filter.schoolYear == '' || filter.semester == ''){ // || (subject == 0 && studentSelected.studentId == 0)
            setOnAlert(true);
            setOnSearchReset(true);
        } else {
            await addingDroppingOfSubjects({
                rowsPerPage: rowsPerPage,
                page: page,
                keyword: (keyword !== null && keyword != '') ? keyword.trim() : null,
                schoolYear: filter.schoolYear,
                semesterId: filter.semester,
                //subjectId: subject,
                //studentId: studentId,
                //subjectStatusId: 1,
                extractAll: 0
            }).then(res => {
                setResults(res.data);
                if (res.data.length > 0) {
                    setTotalRecord(res.data[0].TotalRecord);
                    setOnSearchReset(false);
                } else {
                    setTotalRecord(0);
                    setOnSearchReset(true);
                    setOnAlert(true);
                }
            })
        }
    }

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Institute', label: 'Institute', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Course', label: 'Course', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'YearLevel', label: 'Year/Level', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ClassSection', label: 'Section', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'DateCreated', label: 'Date Processed', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'CreatedBy', label: 'Processed By', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ActionTaken', label: 'Action Taken', align: 'left', withSorting: false, hidden: false, disablePadding: false }
        // { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'RowCnt',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'studentMasterFile',
        parentId: null,
        tableTitle: 'List of Students',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: 0,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: null,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch
    }

    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect !== null &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={filterChange('schoolYear')}
                        refClassContainer=""
                        name="schoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="School Year"
                        placeHolder="Select School Year"
                        forwardRef={() => { }}
                        selectedId={filter.schoolYear}
                        refClassName={`text-center`}
                        withDescription={true}
                        description={`School Year`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{tableId: 'tableId',display: 'code'}}
                    />
                }
            </>
        )
    }
    const imSelectDropdownSemesters = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={filterChange('semester')}
                        refClassContainer=""
                        name="semesterId"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={() => { }}
                        selectedId={filter.semester}
                        refClassName={`text-center`}
                        withDescription={true}
                        description={`Semester`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    return (
        <>
            {
                // onSearchReset ?

                // <div className='d-flex align-items-center'>
                //     <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                //         <header className='card-header p-5 bg-secondary'>
                //             <h6 class="float-left m-0 mt-4 ml-5">Add and Drop Report</h6>
                //         </header>

                //         <section className="card-body">
                //                 {onAlert == true &&
                //                     <div className="alert alert-danger" role="alert">
                //                         No Record Found
                //                     </div>
                //                 }

                //                 <div className="form-row">
                //                    <div className="form-group col">
                //                        <IMSelect
                //                            data={schoolYearSelect}
                //                            onHandleChange={filterChange('schoolYear')}
                //                            refClassContainer=""
                //                            name="schoolYear"
                //                            isRequired={false}
                //                            withLabel={false}
                //                            label="School Year"
                //                            placeHolder="Select School Year"
                //                            forwardRef={() => { }}
                //                            selectedId={filter.schoolYear}
                //                            refClassName={`text-center`}
                //                            withDescription={true}
                //                            description={`School Year`}
                //                            refDisabled={true}
                //                            refIsStatic={false}
                //                            refStaticData={[
                //                            ]
                //                            }
                //                            field={{
                //                                tableId: 'tableId',
                //                                display: 'code'
                //                            }}
                //                        />
                //                    </div>
                //                 </div>

                //                 <div className="form-row">
                //                     <div className="form-group col">
                //                         <IMSelect
                //                             data={semesterSelect}
                //                             onHandleChange={filterChange('semester')}
                //                             refClassContainer=""
                //                             name="semesterId"
                //                             isRequired={false}
                //                             withLabel={false}
                //                             label="Semester"
                //                             placeHolder="Select Semester"
                //                             forwardRef={() => { }}
                //                             selectedId={filter.semester}
                //                             refClassName={`text-center`}
                //                             withDescription={true}
                //                             description={`Semester`}
                //                             refDisabled={true}
                //                             refIsStatic={false}
                //                             refStaticData={[
                //                             ]
                //                             }
                //                             field={{
                //                                 tableId: 'tableId',
                //                                 display: 'code'
                //                             }}
                //                         />
                //                     </div>
                //                 </div>

                //                 <div className="form-row">
                //                     <div className="form-group col">
                //                         <label htmlFor="subjectId">Search by Subject</label>
                //                         <Autocomplete
                //                             id="subjectId"
                //                             options={subjectSelect}
                //                             getOptionLabel={(option) => option.code}
                //                             onChange={(event, value) => {
                //                                 if (value) {
                //                                     handleSubjectInputSelect(value)
                //                                 } else {
                //                                     setSubject(0);
                //                                 }
                //                             }}
                //                             renderInput={(params) => (
                //                                 <TextField
                //                                     {...params}
                //                                     label="Search Subject Code/Description"
                //                                     variant="outlined"
                //                                     inputRef={register}
                //                                     name="studentSubjects"
                //                                     value={subjectSelect}
                //                                     onChange={
                //                                         (e) => { handleSubjectInputChange(e.target.value) }
                //                                     }
                //                                 />
                //                             )}
                //                         /> 
                //                     </div>
                //                 </div>

                //                 <div className="">
                //                     <div className="" style={{textAlign:"center",fontSize:"large",fontWeight:"100"}}>
                //                         <span style={{letterSpacing:"-3px"}}>————————————</span> AND/OR <span style={{letterSpacing:"-3px"}}>————————————</span>
                //                     </div>
                //                 </div>
                //                 <br></br>

                //                 <div className="form-row">
                //                     <div className="form-group col">
                //                         <label htmlFor="studentId">Search by Student</label>
                //                         <Autocomplete
                //                             id="studentId"
                //                             options={studentList}
                //                             getOptionLabel={(option) => option.StudentName}
                //                             getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                //                             onChange={(event, value) => {
                //                                 if (value) {
                //                                     handleGetStudent(value)
                //                                 } else {                                         
                //                                     setStudentId(0);
                //                                     setStudentList([]);
                //                                     setOnAlert(false);
                //                                 }
                //                             }}
                //                             renderInput={(params) => (
                //                                 <TextField
                //                                     {...params}
                //                                     label="Student Number/Name"
                //                                     variant="outlined"
                //                                     inputRef={register}
                //                                     name="studentId"
                //                                     onChange={
                //                                         (e) => { handleStudentInputChange(e.target.value) }
                //                                     }
                //                                 />
                //                             )}
                //                         /> 
                //                     </div>
                //                 </div>

                //                 <div className="form-row">
                //                     <section className="col"> 
                //                         <button onClick={() => { onAddDropSearch() }}  className='btn btn-success btn-block'>Search</button> 
                //                     </section>
                //                 </div>
                //         </section>
                //     </main>
                // </div>

                // :

                <div>
                    <div className="form-row" style={{width:"50%",float:"right",marginRight:"2%"}}>
                        <section className="col">{imSelectDropdownSchoolYear()}</section>
                        <section className="col">{imSelectDropdownSemesters()}</section>
                        {
                            totalRecords > 0 && 
                            <section className="col">
                                <AddDropExcelFormat 
                                    fileName={'AddingDroppingReport'} 
                                    btnName={'Extract Report'} 
                                    page={page} 
                                    keyword={keyword} 
                                    rowsPerPage={rowsPerPage}
                                    schoolYear={filter.schoolYear}
                                    semesterId={filter.semester}
                                    subjectId={subject}
                                    studentId={studentId}
                                />
                            </section>
                        }
                        <section className="col">
                            <button onClick={() => { onAddDropSearch() }} className='btn btn-success btn-block'>Search</button>
                        </section>
                    </div>

                    <br></br>
                    <br></br>

                    <div className="card-body">
                        <div className="card card-custom gutter-b example example-compact mx-auto" >
                            <div className="mt-2" style={{ padding: '0px', margin: '0px' }}>
                                <IMTable tableProps={tableProps} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            
        </>
    )
}