import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getCourseFilter, addUpdateClassSection, deleteClassSection, getInstitute, getCourse, getYearLevel,
  getTest, getClass
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';

export default function List() {
  const { state: { fees, auth }, dispatch } = useAppContext();
  const { setValue } = useForm()

  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
  const [page, setPage] = useState(fees.page);
  const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
  const [keyword, setKeyword] = useState(fees.keyword);
  const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)
  const [filter, setFilter] = useState({ courseId: null, instituteId: null })
  const updateId = fees.id;
  const [switchState, setSwitchState] = useState({
    isActive: true,


  });

  const switchHandleChange = name => event => {
    console.log(event.target.checked)
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };
  const [instituteSelect, setInstituteSelect] = useState(null)
  const [courseSelect, setCourseSelect] = useState(null)
  const [yearLevelSelect, setYearLevelSelect] = useState(null)

  const selectHandleChange = name => event => { setFilter((prevData) => { return { ...prevData, [name]: event } }) }

  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // on save grade element  -----------------------------------------------------------
  const onSaveGradeElement = async (val) => {
    console.log(val)
    const values = {
      classSectionId: 0,
      description: val.description,
      instituteId: val.instituteId,
      courseId: val.courseId,
      yearLevelId: val.yearLevelId,
      studentCategoryId: val.studentCategoryId,
      classCapacity: val.classCapacity,
      isActive: val.isActive,
      createdBy: auth.data.Username
    }
    await addUpdateClassSection(values)
      .then(res => {
        setIsOpenAdd(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been saved.' });

      })
  }

  // on update grade element
  const onUpdateElement = async (id, val) => {
    const values = {
      classSectionId: parseInt(id),
      description: val.description,
      instituteId: val.instituteId,
      courseId: val.courseId,
      yearLevelId: val.yearLevelId,
      studentCategoryId: val.studentCategoryId,
      classCapacity: val.classCapacity,
      isActive: val.isActive,
      createdBy: auth.data.Username
    }
    await addUpdateClassSection(values)
      .then(res => {
        setIsOpenUpdate(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been updated.' });
      })
  }

  // on delete grade element
  const onDeleteElement = async (id) => {
    await deleteClassSection(id)
      .then(res => {
        setIsOpenDelete(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been Deleted.' });
      })
      .catch(e => {

      })
  }



  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    setIsOpenAdd(true)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {
    setRowValue(data)
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
  }


  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  useEffect(() => {
    SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Description')
    SelectCreator(getCourse, setCourseSelect, "CourseId", "Description")
    SelectCreator(getYearLevel, setYearLevelSelect, "YearLevelId", "Description")
  }, [])

  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      description: props.value !== undefined && props.value !== null ? props.value.gradeTemplateDesc : null,
      instituteId: props.value !== undefined && props.value !== null ? props.value.instituteId : null,
      courseId: props.value !== undefined && props.value !== null ? props.value.courseId : null,
      yearLevelId: props.value !== undefined && props.value !== null ? props.value.yearLevelId : null,
      studentCategoryId: props.value !== undefined && props.value !== null ? props.value.studentCategoryId : null,
      classCapacity: props.value !== undefined && props.value !== null ? props.value.classCapacity : null
    })


    const [switchState, setSwitchState] = useState({
      isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false,


    });

    const switchHandleChange = name => event => {
      console.log(event.target.checked)
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects

    const handleChange = name => event => {
      console.log(name)
      console.log(event)
      setData({ ...data, [name]: event })
    }


    useEffect(() => {
      if (data && switchState) {

        props.onSave({
          ...data,
          isActive: switchState.isActive == true ? 1 : 0
        })
      }

    }, [data, switchState])



    console.log(props.value)
    console.log(data)
    return (
      <>

        <main className='container'>
          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Description</label>
              <input
                required type="text"
                className="form-control"
                id="inputEmail4"
                placeholder="Short Name"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.description : ''}
                onBlur={(e) => { setData({ ...data, description: e.target.value }) }}
              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Capacity</label>
              <input
                required type="number"
                className="form-control"
                id="inputEmail4"
                placeholder="Capacity"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.classCapacity : ''}
                onBlur={(e) => { setData({ ...data, classCapacity: e.target.value }) }}
              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.instituteSelect}
                onHandleChange={handleChange('instituteId')}
                refClassContainer=""
                name="instituteId"
                isRequired={false}
                withLabel={false}
                label="Institute"
                placeHolder="Select Institute"
                forwardRef={() => { }}
                selectedId={data.instituteId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>
          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.courseSelect}
                onHandleChange={handleChange('courseId')}
                refClassContainer=""
                name="courseId"
                isRequired={false}
                withLabel={false}
                label="Course"
                placeHolder="Select Courses"
                forwardRef={() => { }}
                selectedId={data.courseId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.yearLevelSelect}
                onHandleChange={handleChange('yearLevelId')}
                refClassContainer=""
                name="yearLevelId"
                isRequired={false}
                withLabel={false}
                label="Year Level"
                placeHolder="Select Year Level"
                forwardRef={() => { }}
                selectedId={data.yearLevelId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                onHandleChange={handleChange('studentCategoryId')}
                name="studentCategoryId"
                isRequired={true}
                withLabel={true}
                label="Student Category"
                placeHolder=""
                forwardRef={setValue}
                selectedId={data.studentCategoryId}
                refClassName={``}
                withDescription={false}
                // description={`Please select your gender.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: '', label: '' },
                  { id: '1', label: 'Tertiary' },
                  { id: '10', label: 'Graduate Studies' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
              />

            </div>

          </div>
          <div className="form-row">

            <div className="form-group col">
              <FormControl component="fieldset">
                <FormControlLabel
                  name="isActive"
                  inputRef={() => { }}
                  control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                  label="Active"
                />
              </FormControl>
            </div>
          </div>
        </main>
      </>
    )
  }

  const DeleteContent = (props) => {

    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.description : ''} `}</span> </p>
        </main>



      </>
    )
  }



  useEffect(() => {
    const execute = async () => {
      await getClass({
        instituteId: filter.instituteId,
        courseId: filter.courseId,
        page: page,
        rowsPerPage: rowsPerPage,
        keyword: keyword,
        isActive: switchState.isActive == true ? 1 : 0
      })
        .then(response => {
          if (response.success) {
            console.log(response)
            console.log(response.data)
            setResults(response.data);
            setTotalRecord(response.data.length > 0 ? response.totalRecord : 0);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {

          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

        })
    }
    execute();

  }, [page, rowsPerPage, keyword, isOpenAdd, isOpenUpdate, isOpenDelete, filter, switchState])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])

  useEffect(() => {
    setFilter((prevData) => {
      return { ...prevData, courseId: null }
    })
    getCourseFilter({ where: `WHERE InstituteId = ${filter.instituteId}` })
      .then(res => {
        if (res.data.length > 0) {
          let select = []
          for (let i = 0; i < res.data.length; i++) {
            select.push({ tableId: res.data[i].CourseId, code: res.data[i].Code })
          }
          setCourseSelect(select)
        }
      })
  }, [filter.instituteId])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Institute', label: 'Institute', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'StudentCategory', label: 'Student Category', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'ClassSectionId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdate',
    parentId: null,
    tableTitle: 'List of Grade Template',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: referenceTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)
  console.log(keysToCamelCase(rowValue))
  console.log(instituteSelect)

  return (
    <>
      {instituteSelect && courseSelect && yearLevelSelect &&
        <>
          <AddUpdateModal
            title="Add Class Section"
            isOpen={isOpenAdd}
            Content={Content}
            handleClose={onAddGradeElementClose}
            isAdd={true}
            instituteSelect={instituteSelect}
            courseSelect={courseSelect}
            yearLevelSelect={yearLevelSelect}
            onSubmit={onSaveGradeElement}
            id="classSectionId"

          />

          <AddUpdateModal
            title="Update Class Section"
            isOpen={isOpenUpdate}
            Content={Content}
            value={keysToCamelCase(rowValue)}
            handleClose={onUpdateGradeElementClose}
            isUpdate={true}
            instituteSelect={instituteSelect}
            courseSelect={courseSelect}
            yearLevelSelect={yearLevelSelect}
            onSubmit={onUpdateElement}
            id="classSectionId"

          />

          <AddUpdateModal
            title="Delete Class Section"
            isOpen={isOpenDelete}
            Content={DeleteContent}
            handleClose={onDeleteGradeElementClose}
            isDelete={true}
            onSubmit={onDeleteElement}
            instituteSelect={instituteSelect}
            courseSelect={courseSelect}
            yearLevelSelect={yearLevelSelect}
            value={keysToCamelCase(rowDeleteValue)}
            id="classSectionId"

          />
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
              </div>
              <div className="card-toolbar">
                <div className="example-tools">
                  <div className='mr-5'>
                    <FormControl component="fieldset">
                      <FormControlLabel
                        name="isActive"
                        inputRef={() => { }}
                        control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                        label="Active"
                      />
                    </FormControl>
                  </div>
                  <div className='mr-5'>
                    <IMSelect
                      data={instituteSelect}
                      onHandleChange={selectHandleChange('instituteId')}
                      refClassContainer=""
                      name="instituteId"
                      isRequired={false}
                      withLabel={true}
                      label="Institute Filter"
                      placeHolder="Institute"
                      forwardRef={() => { }}
                      selectedId={filter.instituteId}
                      refClassName={`text-center`}
                      withDescription={false}
                      //description={`Enrollment Status`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[
                      ]
                      }
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>
                  <div className='mr-5'>
                    <IMSelect
                      data={courseSelect}
                      onHandleChange={selectHandleChange('courseId')}
                      refClassContainer="mr-5"
                      name="courseId"
                      isRequired={false}
                      withLabel={true}
                      label="Course Filter"
                      placeHolder="Course"
                      forwardRef={() => { }}
                      selectedId={filter.courseId}
                      refClassName={`text-center mr-5`}
                      withDescription={false}
                      //description={`Enrollment Status`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[
                      ]
                      }
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>

                  <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                    <i className="fa fa-plus"></i> Add New
                  </button>
                  {filter.instituteId != null || filter.courseId != null &&
                    <button onClick={() => { setFilter({ courseId: null, instituteId: null }) }} className="ml-5 btn btn-sm btn-icon-sm btn-secondary">
                      Reset Filter
                    </button>
                  }

                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>


              <IMTable tableProps={tableProps} />
            </div>
          </div>

        </>
      }

    </>
  );
}