import React, { forwardRef, useRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from "../../../../../_metronic/_assets/logo/FaithCollegesPNG.png"
import './__hooks__/styles.css'
import { LabelOff } from "@material-ui/icons";
//import FaithLogo from './assets/FaithCollegesPNG.png'
import moment from "moment";

const style = {

    border: 'none !important'


}

export default function CertificateOfMatriculation(props) {
    const { studentAccount, studentSubjectScheduleDetail, studentAssessmentFees, studentOtherFees, encoder,
        studentDiscount, studentAddOns, date, studentTotalCreditUnits, studentTotalUnits,
        paymentSchedule, totalOther, totalAssessmentFee, totalAddOns, studentPayment, interest,
        status } = props
    let componentRef = useRef();
    const tuitionFee = studentAssessmentFees.find(data => data.FeeCode == 'TF' || data.FeeCode == 'TF') || { FinalAmount: 0 }
    const miscFee = studentAssessmentFees.find(data => data.FeeCode == 'MS') || { FinalAmount: 0 }
    const regFee = studentAssessmentFees.find(data => data.FeeCode == 'RG') || { FinalAmount: 0 }
    const labFee = studentAssessmentFees.find(data => data.FeeCode == 'LB') || { FinalAmount: 0 }

    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
    let totalOtherFee = 0

    for (let i = 0; i < studentOtherFees.length; i++) {
        totalOtherFee += parseFloat(studentOtherFees[i].Fee)
    }

    for (let i = 0; i < studentAddOns.length; i++) {
        totalOtherFee += parseFloat(studentAddOns[i].Amount)
    }

    console.log("Test")
    console.log(tuitionFee)
    console.log(studentDiscount)
    console.log(labFee)
    const Component = forwardRef((props, ref) => {
        const pageStyle = `
        @media print {
          @page {
            size: 8.5in 13in;
            color: white;
          }
          body, html {
            background-color: white;
          }
        }
      `

        console.log(studentAssessmentFees)
        console.log(studentOtherFees)
        return (
            <div className="d-none">
                <style>{pageStyle}</style>
                <div ref={ref}>

                    <div className="container">
                        <main className="border-0">

                            <div className="table-header">

                                <section className="card-header table-head text-center border-0">
                                    <img style={{ width: '250px' }} src={FaithLogo} />
                                    <h1 className="font-weight-bold mt-5">Certificate of Matriculation</h1>



                                </section>

                                <section className="card-body">
                                    <section className="row">
                                        <div className="col-8 float-left">
                                            <div> <h6 className="font-weight-bold d-inline">Student No./Name: &nbsp;</h6> <p className="d-inline">{`${studentAccount.StudentNo} / ${studentAccount.StudentName}`}</p> </div>
                                            <div> <h6 className="font-weight-bold d-inline">Course/Year: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="d-inline">{studentAccount.CourseDesc} /  {studentAccount.YearLevelDesc}</p> </div>
                                            <div> <h6 className="font-weight-bold d-inline">Payment Mode: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className=" d-inline">{studentAccount.PaymentModeDesc}</p> </div>


                                        </div>
                                        <div className="col-4 float-right">
                                            <div> <h6 className="font-weight-bold d-inline">AY/Sem: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="d-inline">{studentAccount.SY}</p> </div>
                                            <div> <h6 className="font-weight-bold d-inline">Date/Time: &nbsp;</h6> <p className=" d-inline">{date}</p> </div>
                                            <div> <h6 className="font-weight-bold d-inline">Encoder: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="d-inline">{encoder}</p> </div>

                                        </div>
                                    </section>

                                    <section className="row">
                                        <table className="table table-hover mt-5  table-borderless table-sm ">
                                            <thead className="mb-5 text-center" style={{ borderTop: '3px solid #36454F', fontWeight: 'bold', borderBottom: '3px solid #36454F' }}>
                                                <tr>
                                                    <th scope="col">Code</th>
                                                    <th scope="col">Subject</th>
                                                    <th scope="col">UNIT</th>
                                                    <th scope="col">TIME</th>
                                                    <th scope="col">DAYS</th>
                                                    <th scope="col">ROOM</th>
                                                    <th scope="col">YT</th>

                                                </tr>

                                            </thead>
                                            <tbody className="text-center pt-5" >

                                                {studentSubjectScheduleDetail.map(data => {

                                                    return (
                                                        <>
                                                            {data.schedules[0].TimeStart !== null &&
                                                                <tr style={{ marginTop: '2%' }} >
                                                                    <th className="" scope="row" >{data.ClassCode}</th>
                                                                    <td >{data.SubjectCode}</td>
                                                                    <td >{data.Units}</td>
                                                                    <td >{`${data.schedules[0].TimeStartDesc} - ${data.schedules[0].TimeEndDesc}`}</td>
                                                                    <td >{data.schedules[0].DayCode}</td>
                                                                    <td >{data.schedules[0].Room}</td>
                                                                </tr>


                                                            }

                                                            {data.schedules.length > 0 && data.schedules.map(d => {


                                                                return (
                                                                    <>
                                                                        {d.LabTimeStart !== null &&
                                                                            <tr style={{ marginTop: '2%' }} >

                                                                                <th className="" scope="row" >{data.schedules.find(dt => dt.TimeStart != null) == undefined ? data.ClassCode : ''}</th>
                                                                                <td >{data.schedules.find(dt => dt.TimeStart != null) == undefined ? data.SubjectCode : ''}</td>
                                                                                <td >{data.schedules.find(dt => dt.TimeStart != null) == undefined ? data.Units : ''}</td>
                                                                                <td >{`${d.LabTimeStartDesc} - ${d.LabTimeEndDesc}`}</td>
                                                                                <td >{d.LabDayCode}</td>
                                                                                <td >{d.LabRoom}</td>
                                                                                <td >{data.SubCode}</td>
                                                                            </tr>

                                                                        }

                                                                    </>

                                                                )
                                                            })
                                                            }

                                                        </>
                                                    )



                                                })}

                                            </tbody>
                                        </table>
                                    </section>
                                    <p className="text-center">***NOTHING FOLLOWS***</p>
                                    <hr className="mx-5" />
                                    <p>Total Credit Units: {studentTotalCreditUnits} / Unit Hours: {studentTotalUnits}</p>

                                    <br />
                                    <br />
                                    <br />

                                    <section className="row mt-5" style={{ marginTop: '20cm' }}>
                                        <section className="col-6 ">
                                            <h5 className="mb-0 fw-bold">SCHOOL FEES </h5>
                                            <div className="row mt-5">
                                                <div className="float-left col-6">
                                                    <p className="mb-0">TUITION FEE</p>
                                                    <p className="mb-0">REGISTRATION FEE</p>
                                                    <p className="mb-0">MISCELLANEOUS</p>



                                                </div>
                                                <div className="float-right col-6">
                                                    <p className="mb-0 text-right">{`${numberWithCommas(parseFloat(tuitionFee.FinalAmount) + parseFloat(labFee.FinalAmount != null ? labFee.FinalAmount : 0))}.00`}</p>
                                                    <p className="mb-0 text-right">{`${numberWithCommas(parseFloat(regFee.FinalAmount))}.00`}</p>
                                                    <p className="mb-0 text-right ">{`${numberWithCommas(parseFloat(miscFee.FinalAmount))}.00`}</p>
                                                    <hr />
                                                    <p className="mb-0 text-right">{`${numberWithCommas(parseFloat(miscFee.FinalAmount) + parseFloat(regFee.FinalAmount) + parseFloat(tuitionFee.FinalAmount) + parseFloat(labFee.FinalAmount != null ? labFee.FinalAmount : 0))}.00`}</p>

                                                </div>
                                            </div>
                                            <h5 className="mb-0 fw-bold">NON SCHOOL FEES </h5>

                                            <div className="row mt-5">

                                                <div className="col-8">
                                                    {/* <p className="mb-0">TUITION FEE</p>
                                                <p className="mb-0">REGISTRATION FEE</p>
                                                <p className="mb-0">MISCELLANEOUS</p> */}
                                                    {studentOtherFees.map(data => {
                                                        return (
                                                            <>
                                                                <p className="mb-0 text-left">{data.FeeCode}</p>
                                                            </>
                                                        )
                                                    })}



                                                </div>

                                                <div className="col-4">
                                                    {/* <p className="mb-0 fw-bold text-right"><hr /></p> */}
                                                    {/* <p className="mb-0">TUITION FEE</p>
                                                <p className="mb-0">REGISTRATION FEE</p>
                                                <p className="mb-0">MISCELLANEOUS</p> */}
                                                    {studentOtherFees.map(data => {
                                                        return (
                                                            <>
                                                                <p className="mb-0 text-right">{data.Fee}</p>
                                                            </>
                                                        )
                                                    })}



                                                </div>

                                            </div>


                                        </section>
                                        <section className="col-6">

                                            <h5 className="mb-0 fw-bold">ACCOUNT SUMMARY </h5>

                                            {/* {paymentSchedule !== null && paymentSchedule.length > 0 &&
                                                <>
                                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                                        <thead className="">
                                                            <tr>
                                                                <th className="text-left pl-0" scope="col">SCHEDULE</th>
                                                                <th className="text-left" scope="col">DUE DATE</th>
                                                                <th className="text-center" scope="col">AMOUNT</th>
                                                            </tr>
                                                        </thead>
                                                        <tbody className=" pt-5">
                                                            {paymentSchedule.map(data => (
                                                                <tr className="p-0" style={style}>
                                                                    <th align="left" className="p-0" scope="row">{data.Period}</th>
                                                                    <td className="p-0" align="left">{moment(data.Date).format('YYYY-MM-DD')}</td>
                                                                    <td className="p-0" align="center">{parseFloat(data.Amount).toFixed(2)}</td>
                                                                </tr>
                                                            ))}

                                                            <tr className="mt-0 bg-secondary" >

                                                                <th align="left" className="py-2 bg-secondary" scope="row" >Total:</th>
                                                                <td className="py-2  text-left bg-secondary" align="left" ></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                </>
                                            } */}

                                            <div className="row mt-5">

                                                <div className="col-6">

                                                    <p className="mb-0 text-left">Total Fees Before Discount</p>
                                                    <p className="mb-0 text-left">Total Discount</p>




                                                </div>

                                                <div className="col-6">

                                                    <p className="mb-0 text-right">{numberWithCommas(parseFloat(totalAssessmentFee) + parseFloat(totalAddOns))}</p>
                                                    <p className="mb-0 text-right">-{numberWithCommas(parseFloat(studentDiscount))}</p>




                                                </div>

                                            </div>
                                            <hr />
                                            <div className="row">

                                                <div className="col-6">

                                                    <p className="mb-0 text-left">Total Fees After Discount</p>
                                                    <p className="mb-0 text-left">Non School Fees</p>
                                                    {interest != 0 && interest > 0 &&
                                                        <p className="mb-0 text-left">Interest</p>
                                                    }




                                                </div>

                                                <div className="col-6">

                                                    <p className="mb-0 text-right">{numberWithCommas(parseFloat(totalAssessmentFee) + parseFloat(totalAddOns) - parseFloat(studentDiscount))}</p>
                                                    <p className="mb-0 text-right">{numberWithCommas(parseFloat(totalOther))}</p>
                                                    {interest != 0 && interest > 0 &&
                                                        <p className="mb-0 text-right">{numberWithCommas(parseFloat(interest))}</p>
                                                    }



                                                </div>

                                            </div>

                                            <hr />
                                            <div className="row mt-5">

                                                <div className="col-6">
                                                    <p className="mb-0 text-left">Total Assessment</p>
                                                    <p className="mb-0 text-left">Payment Received</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="mb-0 text-right">{numberWithCommas(parseFloat(totalAssessmentFee) + parseFloat(totalAddOns) - parseFloat(studentDiscount) + parseFloat(totalOther) + parseFloat(interest))}</p>
                                                    <p className="mb-0 text-right">-{parseFloat(studentPayment)}</p>

                                                </div>
                                            </div>
                                            <hr />
                                            <div className="row mt-5">

                                                <div className="col-6">
                                                    <p className="mb-0 text-left">Total Balance</p>

                                                </div>
                                                <div className="col-6">
                                                    <p className="mb-0 text-right">{numberWithCommas(parseFloat(parseFloat(totalAssessmentFee) + parseFloat(totalAddOns) - parseFloat(studentDiscount) + parseFloat(totalOther) + parseFloat(interest) - parseFloat(studentPayment)).toFixed(2))}</p>


                                                </div>
                                            </div>

                                        </section>
                                    </section>
                                    <section className="row" style={{ marginTop: '35%' }}>
                                        <div className="col-6 text-left">
                                            <p className="mb-0">Printed by: {encoder}</p>
                                            <p>Date Printed: {date}</p>

                                        </div>
                                        <section className="col-6">

                                        </section>




                                    </section>

                                </section>

                            </div>
                        </main>
                    </div>
                </div>
            </div>
        )
    })


    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }

    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className={status == null || status == undefined ? "btn btn-link d-inline" : "btn btn-primary d-inline"}>Certificate of Matriculation</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}

                />
            </div>



        </>
    )
}