import React, { useEffect, useState } from "react";
import IMTableCredential from "../../../../../_metronic/layout/components/custom/table/IMTableCredential";
import { useParams } from "react-router-dom";
import { referenceTypes } from "./__hooks__/types";
import { useAppContext } from "../../../../contexts/useAppContext";

import { NoAccess } from "../../../../../_metronic/layout/components/custom/forms/NoAccess";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import PrintRecieveDocumentReceipt from "./PrintReceiveDocumentReceipt";

export default function RecieveDocumentsForm(props) {
    const { studentAccount, credential, dateTime, createAccountHandler, studentExistingCredentials, onCreateCredentialStudent, documentReport,
        onStudentReportHandler } = props
    const { state: { auth } } = useAppContext()
    const today = new Date();
    const date = (today.getMonth() + 1) + '/' + today.getDate() + '/' + today.getFullYear();
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const activeSem = auth.data.schoolyears.enrollment.SemesterId

    const clickHandler = (data) => {
        onCreateCredentialStudent(data)
        // onStudentReportHandler()
    }


    const handleChangePage = () => { return }
    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Credential', label: 'Credential', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DateReceived', label: 'Date Received', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
        { id: 'ReceivedBy', label: 'Received By', align: 'left', withSorting: true, hidden: false, disablePadding: false }


    ]
    let tableProps = {
        recordId: 'CredentialId',
        sortField: '',
        columns: columns,
        rows: credential,
        totalRecords: credential.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'List of Documents',
        onSearch: '',
        keyword: '',
        lastUpdateId: '',
        onIsLastUpdate: false,
        onPage: true,
        onRowsPerPage: 10,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,

    }


    console.log(credential)
    console.log(auth)
    console.log(studentAccount)
    return (
        <>



            <div className="row">
                <div className="col-12">
                    <div className="card p-5">
                        <div className="card-header ">
                            <h3 className="display-4">{studentAccount[0].FullName}</h3>
                            <p className="my-0 lead">{studentAccount[0].CourseDesc}</p>
                            <p className="my-0 lead">{studentAccount[0].StudentNo}</p>
                            <p className="my-0 lead text-primary">{studentAccount[0].StudentStatus}</p>



                        </div>
                        <div className="card-body">
                            <div className="row">
                                <h2 className="col mb-5  text-dark p-5 "> <i className="fa fa-file-alt fa-lg p-0"></i>  &nbsp; List of Documents</h2>
                                <div className="card-toolbar p-5">
                                    <div className="example-tools ">
                                        {studentAccount[0].UserId == null ?
                                            <>
                                                <div className="row">
                                                    {documentReport !== null ?
                                                        <PrintRecieveDocumentReceipt results={documentReport} date={date} encoder={`${auth.data.LastName}, ${auth.data.FirstName}`} sy={activeSy} sem={activeSem} credential={credential} />

                                                        :
                                                        <button className="btn btn-secondary mx-3 btn-sm">
                                                            Please wait ...

                                                            <div className="spinner-border mx-5" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </button>
                                                    }
                                                    {/* 
                                                    <button onClick={() => { createAccountHandler() }} className="btn btn-sm btn-icon-sm btn-primary">
                                                        <i className="fa fa-envelope"></i> Permit to Enroll
                                                    </button> */}
                                                </div>

                                            </>


                                            :
                                            <>
                                                <div className="row">

                                                    {documentReport !== null ?
                                                        <PrintRecieveDocumentReceipt results={documentReport} date={date} encoder={`${auth.data.LastName}, ${auth.data.FirstName}`} sy={activeSy} sem={activeSem} credential={credential} />

                                                        :
                                                        <button className="btn btn-secondary mx-3 btn-sm">
                                                            Please wait ...

                                                            <div className="spinner-border mx-5" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>
                                                        </button>
                                                    }
                                                    {/* <button onClick={() => { }} className="btn btn-sm btn-icon-sm btn-secondary">
                                                        <i className="fa fa-envelope"></i> Send Account Details
                                                    </button> */}
                                                </div>

                                            </>

                                        }


                                        {/* <PrintRecieveDocumentReceipt /> */}

                                    </div>
                                </div>
                            </div>

                            <IMTableCredential
                                tableProps={tableProps}
                                dateTime={dateTime}
                                user={auth.data.Username}
                                clickHandler={clickHandler}
                                selectedCredentials={studentExistingCredentials}
                            />


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}