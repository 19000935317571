export const referenceTypes = {
    SELECTED_SEMESTER: 'SELECTED-SEMESTER',
    GET_SEMESTERS: 'GET-SEMESTERS',
    GET_SEMESTER: 'GET-SEMESTER-BY-ID',
    CREATE: 'CREATE-SEMESTER',
    UPDATE: 'UPDATE-SEMESTER',
    DELETE: 'DELETE-SEMESTER',
    LIST_LINK: '/admin/references/semesters',
    CREATE_LINK: '/admin/references/create-semesters/',
    UPDATE_LINK: '/admin/references/update-semesters/',
    DELETE_LINK: '/admin/references/delete-semesters/'
}