import axios from 'axios';
import CryptoJS from 'crypto-js';

// Encryption setup

const encryptData = (data) => {
    const jsonData = JSON.stringify(data);
    return CryptoJS.AES.encrypt(jsonData, process.env.REACT_APP_SECRET_KEY).toString();
};


const instance = axios.create({
    baseURL: 'http://astracollege.firstasia.edu.ph:8085'
});

instance.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('AccessToken');
        config.headers.Authorization = token ? `Bearer ${token}` : '';
        if (config.data && (config.method === 'post' || config.method === 'put')) {
            config.data = encryptData(config.data);
        }

        return config;
    },
    (error) => {
        return Promise.reject(error);
    }
);

export default instance;
