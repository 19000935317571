import { number } from "prop-types";
import React, { useState, useRef, forwardRef, useEffect } from "react";
import ReactToPrint, { useReactToPrint } from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import {
    getControlNumber, getReprintStatus
} from './__hooks__';
import { makeStyles } from '@material-ui/core/styles';
import ReprintModal from "../../../../../_metronic/layout/components/custom/modal/IndividualreportCardReprinting";
import './__hooks__/styles.css'

const useStyles = makeStyles({
    table: {
        minWidth: 430
    },

    blueBGColor: {
        backgroundColor:"#b3ebfb !important"
    },

    redBGColor: {
        backgroundColor:"#ff6c6c !important"
    },

    topAndSideBorders: {
        borderBottomStyle:"none",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    sideBordersOnly: {
        borderBottomStyle:"none",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },
    
    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    allSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    }

});

export default function PrintStudentGradeViewing(props) {
    const [controlNum, setControlNum] = useState('');
    const academicYear = props["results"]["0"];
    const semester = props["results"]["1"];
    const generatedTime = props["results"]["2"];
    const studentDetails = props["results"]["3"];
    const results = props["results"]["4"];
    let gradingPeriod = props["results"]["5"];
    const totalUnits = props["results"]["6"];
    const weightedAverage = props["results"]["7"];
    const controlNumData = props["results"]["8"];
    const clearanceLength = props["results"]["9"];
    const totalUnitsPassed = props["results"]["10"];
    const totalUnitsFailed = props["results"]["11"];
    const totalUnitsDropped = props["results"]["12"];

    const [isReprint, setIsReprint] = useState(false);
    
    if(gradingPeriod == "Final Rating"){
        gradingPeriod = "Final";
    }

    const gradingPeriodUpper = gradingPeriod.toUpperCase();

    const onGetControlNumber = async () => {
        if(controlNumData.numOfGrades > 0){
            const values = {
                studentNo: controlNumData.studentNo,
                schoolYear: controlNumData.schoolYear,
                semesterId: controlNumData.semesterId,
                gradingPeriodId: controlNumData.gradingPeriodId,
                createdBy: controlNumData.createdBy
            }
    
            await getControlNumber(values)
                .then(result => {
                    if(result.success){
                        if(result.results > 0){
                            setControlNum(result.data[0].Result);
                        }
                    }
                })
        }
    }

    const executeGetReprintStatus = async () => {
        if(controlNumData.numOfGrades > 0){
            let values = {
                studentNo: controlNumData.studentNo,
                schoolYear: controlNumData.schoolYear,
                semesterId: controlNumData.semesterId,
                gradingPeriodId: controlNumData.gradingPeriodId,
                createdBy: controlNumData.createdBy
            }

            await getReprintStatus(values)
                .then(res => {
                    if(res.success){
                        if (res.data.length > 0) {
                            //console.log("REPRINT STATUS: ", res.data);
                            //setReprintStatus(res.data[0].ReprintStatus);

                            executePrint(res.data[0].ReprintStatus);
                        }
                    }
                })
        }
    }

    let isFinal = false;
    let componentRef = useRef();
    const classes = useStyles();
    const date = new Date();

    let day = date.getDate();
    let month = date.getMonth() + 1;
    let year = date.getFullYear();
    let currentDate = `${month}/${day}/${year}`;

    gradingPeriod == "Final" ? isFinal = true : isFinal = false;

    const Component = forwardRef((props, ref) => {
        return (
            <>
                {
                    controlNumData.numOfGrades > 0 ?
                   
                    <>
                        <style type="text/css" media="print">{"\
                          @page {\ size: portrait;\ margin: 0mm 0mm 0mm 0mm;}\
                        "}</style>

                        <div className="d-none" >
                            <div ref={ref}>
                                <div className="container" >
                                    <main className="card border-0 p-5">
                                        {/* <section className="card-body my-5" > */}
                                            <div className="form-row">

                                                {/* STUDENT'S COPY */}
                                                <div className="col">

                                                    {/* Headers */}
                                                    <img style={{ width: "150px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                                    <h6 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h6>
                                                    <h6 className="text-center">{gradingPeriodUpper} GRADE REPORT</h6>
                                                    <h6 className="text-center">A.Y. {academicYear}, {semester}</h6>
                                                    <div className="text-right">Ctrl No. {controlNum} </div>

                                                    {/* Student Details */}
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:"32%"}}><span style={{float:"right"}}>Student No. / Name: </span></td>
                                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                                {/* <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : "S2023999999"} / {studentDetails == null ? "" : "Lorem ipsum dolor sit amet consectetur" } </span></td> */}
                                                                <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : studentDetails.StudentNo} / {studentDetails == null ? "" : studentDetails.StudentName} </span></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"32%"}}><span style={{float:"right"}}>Course / Year: </span></td>
                                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                                <td><span>{studentDetails == null ? "" : studentDetails.CourseCode} / {studentDetails == null ? "" : studentDetails.YearLevel} </span></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"32%"}}><span style={{float:"right"}}>Address: </span></td>
                                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                                {/* <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : "<Dummy_Add_1>, Darasa, Tanauan, Batangas"} </span></td> */}
                                                                <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : studentDetails.Address} </span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    {/* Student Grades */}
                                                    <table style={{width:"100%"}}>
                                                        <thead style={{borderTop: "1px solid rgba(0,0,0,0.7)",borderBottom: "1px solid rgba(0,0,0,0.7)"}}>
                                                            <tr>
                                                                <td align="center">SUBJECT</td>
                                                                <td align="center">UNIT</td>
                                                                <td align="center">GRADE</td>
                                                                <td align="center">FACULTY</td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                                {results.map((res, i) => {
                                                                    return (
                                                                        <tr key={res.SubjectId}>
                                                                            <td style={{width:"30%"}} align="left">{res.SubjectCode}</td>
                                                                            <td style={{width:"23%"}} align="center">{res.Units}</td>
                                                                            <td style={{width:"23%"}} align="center">{res.Grade}</td>
                                                                            <td style={{width:"23%", textTransform:"uppercase"}} align="left">{res.Instructor}</td>
                                                                            {/* <td style={{width:"23%", textTransform:"uppercase"}} align="left">Dela Cruz</td> */}
                                                                        </tr>
                                                                    )
                                                                })}
                                                            <tr style={{borderTop: "1px dashed black"}}>
                                                                <td align="left">Total Units</td>
                                                                <td align="center">{totalUnits}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                            
                                                    {/* <TableContainer component={Paper} >
                                                        <Table className={classes.table} size="small" style={{pageBreakInside:"auto", float:"left"}}>
                                                            <TableHead >
                                                                <TableRow>
                                                                    <TableCell align="center">Subject</TableCell>
                                                                    <TableCell align="center">Unit</TableCell>
                                                                    <TableCell align="center">Grade</TableCell>
                                                                    <TableCell align="center">Faculty</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            
                                                            {results.map((res) => {
                                                                return (
                                                                    <>
                                                                        <TableBody>
                                                                            <TableRow>
                                                                                <TableCell align="center">{res.SubjectCode}</TableCell>
                                                                                <TableCell align="center">{res.Units}</TableCell>
                                                                                <TableCell align="center">{res.Grade}</TableCell>
                                                                                <TableCell align="center">{res.Instructor}</TableCell>
                                                                            </TableRow>
                                                                        </TableBody>
                                                                    </>
                                                                )
                                                            })}
                                                        </Table>
                                                    </TableContainer> */}

                                                    {/* Total Units */}
                                                    {isFinal ? 

                                                    <table style={{width:"100%", tableLayout: "auto"}}>
                                                        <thead>
                                                            <tr>
                                                                <td align="center"></td>
                                                                <td align="center">Passed</td>
                                                                <td align="center">Failed</td>
                                                                <td align="center">Dropped</td>
                                                                <td align="center">Others</td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td align="center">No. of Units</td>
                                                                <td align="center">{totalUnitsPassed}</td>
                                                                <td align="center">{totalUnitsFailed}</td>
                                                                <td align="center">{totalUnitsDropped}</td>
                                                                <td align="center">0.00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    : <></>}

                                                    {/* Footer Details */}
                                                    <div className="footer-test-2">
                                                        <table style={{fontSize: "0.875em"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span style={{float:"right",marginLeft:"15px"}}>Weighted Average: </span></td>
                                                                    <td><span style={{marginLeft:"10px"}}>{weightedAverage}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{float:"right",marginLeft:"15px"}}>Date Printed: </span></td>
                                                                    <td><span style={{marginLeft:"10px"}}>{currentDate} | {generatedTime}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    <div className="footer-test">STUDENT'S COPY</div>
                                                </div>
                                                    
                                                <div style={{background:"none", width:"3%"}}></div>
                                                    
                                                {/* REGISTRAR'S COPY */}
                                                <div className="col">
                                                    
                                                    {/* Headers */}
                                                    <img style={{ width: "150px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                                    <h6 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h6>
                                                    <h6 className="text-center">{gradingPeriodUpper} GRADE REPORT</h6>
                                                    <h6 className="text-center">A.Y. {academicYear}, {semester}</h6>
                                                    <div className="text-right">Ctrl No. {controlNum}</div>
                                                    
                                                    {/* Student Details */}
                                                    <table>
                                                        <tbody>
                                                            <tr>
                                                                <td style={{width:"32%"}}><span style={{float:"right"}}>Student No. / Name: </span></td>
                                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                                {/* <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : "S2023999999"} / {studentDetails == null ? "" : "Lorem ipsum dolor sit amet consectetur" } </span></td> */}
                                                                <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : studentDetails.StudentNo} / {studentDetails == null ? "" : studentDetails.StudentName} </span></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"32%"}}><span style={{float:"right"}}>Course / Year: </span></td>
                                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                                <td><span>{studentDetails == null ? "" : studentDetails.CourseCode} / {studentDetails == null ? "" : studentDetails.YearLevel} </span></td>
                                                            </tr>
                                                            <tr>
                                                                <td style={{width:"32%"}}><span style={{float:"right"}}>Address: </span></td>
                                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                                {/* <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : "<Dummy_Add_1>, Darasa, Tanauan, Batangas"} </span></td> */}
                                                                <td style={{width:"70%"}}><span style={{textTransform:"uppercase"}}>{studentDetails == null ? "" : studentDetails.Address} </span></td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                    
                                                    {/* Student Grades */}
                                                    <table style={{width:"100%", tableLayout: "auto"}}>
                                                        <thead style={{borderTop: "1px solid rgba(0,0,0,0.7)",borderBottom: "1px solid rgba(0,0,0,0.7)"}}>
                                                            <tr>
                                                                <td align="center">SUBJECT</td>
                                                                <td align="center">UNIT</td>
                                                                <td align="center">GRADE</td>
                                                                <td align="center">FACULTY</td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                                {results.map((res) => {
                                                                    return (
                                                                        <tr key={res.SubjectId}>
                                                                            <td style={{width:"30%"}} align="left">{res.SubjectCode}</td>
                                                                            <td style={{width:"23%"}} align="center">{res.Units}</td>
                                                                            <td style={{width:"23%"}} align="center">{res.Grade}</td>
                                                                            <td style={{width:"23%", textTransform:"uppercase"}} align="left">{res.Instructor}</td>
                                                                            {/* <td style={{width:"23%", textTransform:"uppercase"}} align="left">Dela Cruz</td> */}
                                                                        </tr>
                                                                    )
                                                                })}
                                                            <tr style={{borderTop: "1px dashed black"}}>
                                                                <td align="left">Total Units</td>
                                                                <td align="center">{totalUnits}</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                            
                                                    {/* Total Units */}
                                                    {isFinal ?

                                                    <table style={{width:"100%", tableLayout: "auto"}}>
                                                        <thead>
                                                            <tr>
                                                                <td align="center"></td>
                                                                <td align="center">Passed</td>
                                                                <td align="center">Failed</td>
                                                                <td align="center">Dropped</td>
                                                                <td align="center">Others</td>
                                                            </tr>
                                                        </thead>

                                                        <tbody>
                                                            <tr>
                                                                <td align="center">No. of Units</td>
                                                                <td align="center">{totalUnitsPassed}</td>
                                                                <td align="center">{totalUnitsFailed}</td>
                                                                <td align="center">{totalUnitsDropped}</td>
                                                                <td align="center">0.00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>

                                                    : <></>}

                                                    {/* Footer Details */}
                                                    <div className="footer-test-2">
                                                    
                                                        <table style={{width:"100%", tableLayout: "auto"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td>Acknowledgement:</td>
                                                                </tr>
                                                                <tr>
                                                                    <td>&nbsp;</td>
                                                                </tr>
                                                                <tr style={{borderBottom:"1px solid rgba(0,0,0,0.7)"}}>
                                                                    <td></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    
                                                        <table style={{fontSize: "0.875em"}}>
                                                            <tbody>
                                                                <tr>
                                                                    <td><span style={{float:"right",marginLeft:"15px"}}>Weighted Average: </span></td>
                                                                    <td><span style={{marginLeft:"10px"}}>{weightedAverage}</span></td>
                                                                </tr>
                                                                <tr>
                                                                    <td><span style={{float:"right",marginLeft:"15px"}}>Date Printed: </span></td>
                                                                    <td><span style={{marginLeft:"10px"}}>{currentDate} | {generatedTime}</span></td>
                                                                </tr>
                                                            </tbody>
                                                        </table>
                                                    </div>
                                                    
                                                    <div className="footer-test" >REGISTRAR'S COPY</div>
                                                </div>
                                            </div>
                                        {/* </section> */}
                                    </main>
                                </div>
                            </div>
                        </div>
                    </> 

                    :

                    <>
                        <style type="text/css" media="print">{"\
                          @page {\ size: portrait;\ }\
                        "}</style>

                        <div className="d-none" >
                            <div ref={ref}>
                                <p></p>
                            </div>
                        </div>
                    </>
                }
            
            </>
        )
    })

    const executePrint = (reprintStatus) => {
        if(reprintStatus == 1){
            setIsReprint(true);
        } else if (reprintStatus == 0) {
            printReportCard();
        }
    }

    const afterConfirmation = () => {
        setIsReprint(false);
        printReportCard();
    }

    const printReportCard = useReactToPrint({
        onBeforePrint: handleBeforePrint,
        onBeforeGetContent: onGetControlNumber, //get latest serial and reprint numbers
        content: () => componentRef.current
    });

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }

    return (
        <>
            <ReprintModal
                isOpen={isReprint}
                handleConfirm={() => afterConfirmation()}
                handleClose={() => setIsReprint(false)}
                title={'Reprint Individual Report Card'}
                content={'Individual Report Card already printed.'}
                controlNumData={controlNumData}
            />
            <div>
                <button onClick={executeGetReprintStatus} className="btn btn-success btn-block" {...clearanceLength > 0 ? {disabled:true} : {}}>Print</button>
                {/* <button onClick={executeGetReprintStatus} className="btn btn-success btn-block">Print</button> */}
                {/* button to trigger printing of target component */}
                {/* <ReactToPrint
                    trigger={() => <button className="btn btn-success btn-block" {...clearanceLength > 0 ? {disabled:true} : {}}>Print</button>}
                    onBeforePrint={handleBeforePrint}
                    onBeforeGetContent={onGetControlNumber}
                    content={() => componentRef.current}
                /> */}
                <Component ref={componentRef}/>
                
            </div>
        </>
    )
}