import React, { useState, useEffect } from "react";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { getStudentList, getSchoolYear, getSemester, studentAutoComplete } from "./__hooks__/index"
import { useAppContext } from "../../../../contexts/useAppContext";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { accountTypes } from "./__hooks__/types";
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from "lodash";


export default function CashieringList(props) {
    const { state: { auth } } = useAppContext()
    const { setValue, handleSubmit, register } = useForm()
    const [student, setStudent] = useState(null)
    const [studentId, setStudentId] = useState(0)
    const [studentNo, setStudentNo] = useState(null)
    const [error, setError] = useState(false)
    let activeSy = auth.data.schoolyears.enrollment.SchoolYear
    let activeSem = auth.data.schoolyears.enrollment.SemesterId

    // filter
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)
    //select
    const [sySelect, setSySelect] = useState(null)
    const [semSelect, setSemSelect] = useState(null)
    const [studentList, setStudentList] = useState([])


    // functions

    const getStudentListData = async (val) => {
        await getStudentList(val)
            .then(res => {
                if (res.data.length > 0)
                    setStudent(res.data[0])
                else {
                    setError(true)
                }
            })
    }

    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);

    }

    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {
            })
    }, 300);

    const handleGetStudent = async (data) => {
        setStudentId(data.StudentId)
        setStudentNo(data.StudentNo)

        console.log(data)
    }

    const selectChange = name => event => {
        if (name == 'SchoolYear') {
            setFilteredSy(event)
        } else if (name == 'SemesterId') {
            setFilteredSem(event)
        }
    }

    const onCashieringHandle = () => {

    }

    const onCashieringAccount = () => {

    }

    const onCancel = () => {
        setStudent(null)
        setStudentNo('')
        setStudentId(0)
        setError(false)
    }

    // effects

    useEffect(() => {
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')

    }, [])


    useEffect(() => {
        if (studentId !== null && studentId !== 0) {
            getStudentListData({ schoolYear: filteredSy, semesterId: filteredSem, studentId: studentId })
        }
    }, [studentId])



    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectChange('SchoolYear')}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectChange('SemesterId')}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    console.log(student)
    console.log(studentList)
    console.log(filteredSem)

    return (
        <>
            {sySelect && semSelect &&
                <>
                    <div className="container">
                        <div className="card">
                            <div className="row container my-5">
                                <div className="col-12 col-xl-4  border-right border-primary">
                                    <div className=" p-5">
                                        {student !== null ?
                                            <>
                                                <h3 className="">{`${student.StudentName}`}</h3>
                                                <p className="my-0  ">{student.CourseDesc}</p>
                                            </>

                                            :

                                            <>
                                                <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                                {/* //<p className="my-0  ">Student Course</p> */}
                                            </>

                                        }

                                        <hr />

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            }
                                        </div>



                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={student.YearLevel} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                            }
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={`${student.SY} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                            }
                                        </div>


                                        <hr />


                                    </div>


                                </div>
                                <div className="col-12 col-xl-8">

                                    <div className="p-5 mb-5 ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Search for a Student</h3>

                                        <section className="p-5">
                                            <IMSelect
                                                data={sySelect}
                                                onHandleChange={selectChange('SchoolYear')}
                                                refClassContainer=""
                                                name="SchoolYear"
                                                isRequired={false}
                                                withLabel={false}
                                                label="SchoolYear"
                                                placeHolder="Select School Year"
                                                forwardRef={setValue}
                                                selectedId={filteredSy}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                //description={`Enrollment Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[

                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                            <br />
                                            <IMSelect
                                                data={semSelect}
                                                onHandleChange={selectChange('SemesterId')}
                                                refClassContainer=""
                                                name="Semester"
                                                isRequired={false}
                                                withLabel={false}
                                                label="Semester"
                                                placeHolder="Select Semester"
                                                forwardRef={setValue}
                                                selectedId={filteredSem}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                //description={`Enrollment Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[

                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                            <br />
                                            {/* <div className="bg-white mb-3 p-0">{imSelectDropdownSchoolYear()}</div>
                                    <div className="bg-white mb-3 p-0">{imSelectDropdownSemester()}</div> */}
                                            {error &&
                                                <div className="alert alert-warning mb-5" role="alert">
                                                    No Student Found
                                                </div>
                                            }
                                            {/* <TextField
                                        id="outlined-basic"
                                        label="Student No"
                                        variant="outlined"
                                        name="studentNo"
                                        value={studentNo}
                                        className="bg-white"
                                        size="small"
                                        fullWidth
                                        inputRef={register({
                                            required: true
                                        })}
                                        onChange={(e) => {
                                            if (e.target.value.length === 11) {
                                                setStudentNo(e.target.value)

                                            }
                                        }}
                                    /> */}
                                            <Autocomplete
                                                id="studentNo"
                                                options={studentList}
                                                getOptionLabel={(option) => option.StudentName}
                                                onChange={(event, value) => {
                                                    if (value) {
                                                        handleGetStudent(value)
                                                        // Handle the selected option
                                                    } else {
                                                        // Handle the case when no option is selected
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Student Number"
                                                        variant="outlined"
                                                        inputRef={register}
                                                        name="studentNo"
                                                        value={studentNo}
                                                        onChange={
                                                            (e) => { handleStudentInputChange(e.target.value) }

                                                        }
                                                    />
                                                )}
                                            />

                                            {student !== null &&
                                                <section className="mt-5">

                                                    <Link to={{ pathname: `${accountTypes.MORE_LINK}${student['StudentId']}`, data: student }} className="btn btn-primary mr-1">
                                                        Cashiering
                                                    </Link>
                                                    {student.IsForReservation == 0 &&
                                                        <Link to={{ pathname: `${accountTypes.CHART_LINK}${student['StudentId']}`, data: student }} className="btn btn-info mr-1">
                                                            Student Account
                                                        </Link>
                                                    }

                                                    <button onClick={() => { onCancel() }} className="btn btn-secondary float-right">Cancel</button>

                                                </section>
                                            }

                                            {error &&
                                                <section className="mt-5">

                                                    <button onClick={() => { onCancel() }} className="btn btn-secondary col-3 float-right">Search Again</button>

                                                </section>


                                            }





                                        </section>



                                        {student == null &&
                                            <>
                                                <div className="container">
                                                    <div className="row justify-content-center align-items-center">
                                                        <div className="col-5">
                                                            <hr className="w-100" />
                                                        </div>
                                                        <div className="col-auto text-gray">OR</div>
                                                        <div className="col-5">
                                                            <hr className="w-100" />
                                                        </div>
                                                    </div>
                                                </div>
                                                <section className="text-center mx-auto">
                                                    <Link to={{ pathname: `/admin/accounting/direct-payment`, data: { SchoolYear: filteredSy, SemesterId: filteredSem } }} className="btn btn-primary">
                                                        Direct Payment
                                                    </Link>

                                                </section>
                                            </>

                                        }

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

            }

        </>
    )
}