export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_DETAIL_OTHER: 'SELECTED-ASSESSMENT-DETAIL-OTHER',
    GET_ASSESSMENT_DETAIL_OTHERS: 'GET-ASSESSMENT-DETAIL-OTHERS',
    GET_ASSESSMENT_DETAIL_OTHER: 'GET-ASSESSMENT-DETAIL-OTHER-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-DETAIL-OTHER',
    UPDATE: 'UPDATE-ASSESSMENT-DETAIL-OTHER',
    DELETE: 'DELETE-ASSESSMENT-DETAIL-OTHER',
    LIST_LINK_TO_PARENT: '/admin/enrollment/assessment-of-fee-details',
    LIST_LINK: '/admin/enrollment/assessment-detail-others',
    MORE_LINK: '/admin/enrollment/assessment-detail-others/',
    CREATE_LINK: '/admin/enrollment/create-assessment-detail-others/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-detail-others/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-detail-others/',
    LAB_MORE_LINK: '/admin/enrollment/assessment-lab/'
}