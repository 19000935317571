import React, { useState, useEffect } from 'react';
import AddUpdateModal from '../../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import AddCell from '../../../../../../_metronic/layout/components/cell/AddCell';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuideDetail, deleteInstructionalGuideDetail,
    getInstructionalGuide, getGradingPeriod, getExamType, addUpdateInstructionalGuideDetail,
    getCurrentInstructionalGuide, getGradeTemplateComponentSelect, getGradeTemplateGradingSelect,
    checkPercentage, checkIsEditable, checkIsDeletable
} from './__hooks__';
import { useParams } from 'react-router-dom';
import SelectCreator from '../../../../../../_metronic/functions/SelectCreator'
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import IGButtons from "../../../../../../_metronic/layout/components/buttons/IGButtons"
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { state: { fees, auth }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [instructionalGuide, setInstructionalGuide] = useState(null)
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)
    const classes = useStyles();
    const { id } = useParams()
    const [isEditable, setIsEditable] = useState(true)
    const [isDeletable, setIsDeletable] = useState(true)
    const updateId = fees.id;


    const [gradeElementSelect, setGradeElementSelect] = useState(null)
    const [instructionalGuideSelect, setInstructionalGuideSelect] = useState(null)
    const [gradingPeriodSelect, setGradingPeriodSelect] = useState(null)
    const [examTypeSelect, setExamTypeSelect] = useState(null)


    const [selectedEditIds, setSelectedEditIds] = useState(0)

    let rowCnt = 0;


    const getIg = async (con) => {
        await getCurrentInstructionalGuide(con)
            .then(async res => {
                setInstructionalGuide(res.data)
                if (res.data.length > 0) {
                    // await getGradeTemplateComponentSelect({
                    //     gradeTemplateId: res.data[0].GradeTemplateId
                    // })
                    //     .then(select => {
                    //         setExamTypeSelect(select.data)
                    //     })

                    await getGradeTemplateGradingSelect({
                        gradeTemplateId: res.data[0].GradeTemplateId,
                        instructionalGuideId: res.data[0].InstructionalGuideId
                    })
                        .then(select => {
                            setGradingPeriodSelect(select.data)
                        })
                }

            })
            .catch(e => {

            })
    }


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        console.log(val)
        await checkPercentage({
            instructionalGuideId: val.instructionalGuideId,
            gradingPeriodId: val.gradingPeriodId,
            percentage: val.percentage,
            isUpdate: false,
            instructionalGuideDetailId: 0,
            gradeElementId: val.examTypeId
        })
            .then(async resp => {
                console.log(resp)
                if (val.instructionalGuideId == null || val.gradingPeriodId == null || val.percentage == null || val.examTypeId == null) {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'danger', snackBarDuration: 3000, snackBarMessage: 'Please fill out the necessary fields.' });

                } else {
                    if (resp.data.length > 0 && resp.data[0].IsExceedsPercentage == 1) {
                        await addUpdateInstructionalGuideDetail({ ...val, instructionalGuideDetailId: 0 })
                            .then(res => {
                                getIG();
                                props.onExceedPercentageHandler(false)
                                props.executeReloadOverride()
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide Detail has been saved.' });

                            })
                    } else if (resp.data.length > 0 && resp.data[0].IsExceedsPercentage == 0) {
                        props.onExceedPercentageHandler(true)
                    }
                }

            })

    }

    // on update grade element
    const onUpdateElement = async (id, val) => {
        console.log(val)

        await addUpdateInstructionalGuideDetail({ ...val, instructionalGuideDetailId: parseInt(id), createdBy: auth.data.Username })
            .then(res => {
                setIsOpenUpdate(false)
                props.onExceedPercentageHandler(false)
                props.executeReloadOverride()
                getIG()
                setSelectedEditIds(0)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide Detail has been updated.' });
            })



    }

    // on delete grade element
    const onDeleteElement = async (id) => {
        await deleteInstructionalGuideDetail(id)
            .then(res => {
                setIsOpenDelete(false)
                props.executeReloadOverride()

                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide Detail has been Deleted.' });
            })
            .catch(e => {

            })
    }



    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
        props.onExceedPercentageHandler(false)
    }

    //Update
    const onUpdateGradeElement = (data, id) => {
        setSelectedEditIds(id)
        console.log(data)
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setSelectedEditIds(0)
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = () => {
        setIsOpenDelete(false)
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    const getIG = async () => {

        await checkIsDeletable({ instructionalGuideId: parseInt(id), scheduleTypeId: props.data.InstructionalScheduleTypeId })
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    if (res.data[0].IsDeletable == 1) {
                        setIsDeletable(true)
                    } else {
                        setIsDeletable(false)

                    }
                }
            })
        await getInstructionalGuideDetail({ where: `WHERE IGD.InstructionalGuideId = ${parseInt(id)}` })
            .then(response => {
                if (response.success) {
                    console.log(response.data)
                    setResults(response.data);
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                }
            })
            .catch(error => {

                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

            })
    }



    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            gradingPeriodId: props.value !== undefined && props.value !== null ? props.value.gradingPeriodId : null,
            topicDesc: props.value !== undefined && props.value !== null ? props.value.topicDescription : ' ',
            examTypeId: props.value !== undefined && props.value !== null ? props.value.examTypeId : null,
            highScore: props.value !== undefined && props.value !== null ? props.value.highScore : 0,
            percentage: props.value !== undefined && props.value !== null ? props.value.percentage : 0,
            computeBase: props.value !== undefined && props.value !== null ? props.value.computeBase : 0,
            instructionalGuideId: props.idValue
        })


        const [switchState, setSwitchState] = useState({
            isCancelled: props.value !== undefined && props.value !== null ? props.value.isCancelled === 1 ? true : false : false,
        });

        const switchHandleChange = name => event => {
            setSwitchState({ ...switchState, [name]: event.target.checked });
        };

        // Selects

        const selectGradingPeriodChange = name => event => { setData({ ...data, gradingPeriodId: event }) }
        const selectExamTypeChange = name => event => { setData({ ...data, examTypeId: event }) }


        useEffect(() => {
            if (data && switchState) {
                console.log(data)
                props.onSave({
                    ...data,
                    isActive: switchState.isActive == true ? 1 : 0

                })
            }

        }, [data, switchState])



        console.log(props.value)
        console.log(props.gradeElementSelect)
        console.log(props.examTypeSelect)

        console.log(data)
        return (
            <>

                {/* <main className='container'> */}
                {/* {props.isExceedsPercentage == true ?
                        <div className="alert alert-danger" role="alert">
                            The percentage surpasses 100% for a specific grading period.
                        </div>
                        :
                        ''
                    } */}
                <TableCell className='p-1' align='center'></TableCell>
                <TableCell className='p-1' align='left'>
                    <IMSelect
                        data={props.gradingPeriodSelect}
                        onHandleChange={selectGradingPeriodChange('gradingPeriodId')}
                        refClassContainer=""
                        name="gradingPeriodId"
                        isRequired={false}
                        withLabel={false}
                        label="Grading Period"
                        placeHolder="Select Grading Period"
                        forwardRef={() => { }}
                        selectedId={data.gradingPeriodId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />


                </TableCell>
                <TableCell className='p-1'>

                    <IMSelect
                        data={props.examTypeSelect}
                        onHandleChange={selectExamTypeChange('examTypeId')}
                        refClassContainer=""
                        name="examTypeId"
                        isRequired={false}
                        withLabel={false}
                        label="Exam Type"
                        placeHolder="Select Exam Type"
                        forwardRef={() => { }}
                        selectedId={data.examTypeId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />


                </TableCell>
                <TableCell>
                    <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Topic Descrition"
                        defaultValue={props.value !== undefined && props.value !== null ? props.value.topicDescription : ''}
                        onBlur={(e) => { setData({ ...data, topicDesc: e.target.value }) }}
                    />
                </TableCell>

                <TableCell className='p-1'>
                    <input
                        type="number"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="High Score"
                        defaultValue={props.value !== undefined && props.value !== null ? props.value.highScore : 0}
                        onBlur={(e) => { setData({ ...data, highScore: e.target.value }) }}
                    />


                </TableCell>

                <TableCell className="p-1">
                    <input
                        type="number"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Percentage"
                        defaultValue={props.value !== undefined && props.value !== null ? props.value.percentage : 0}
                        onBlur={(e) => { setData({ ...data, percentage: e.target.value }) }}
                    />

                </TableCell>



                <TableCell className='p-1' align='center'>
                    <input
                        type="number"
                        className="form-control"
                        id="inputEmail4"
                        placeholder="Compute Base"
                        defaultValue={props.value !== undefined && props.value !== null ? props.value.computeBase == '' && props.value.computeBase == undefined ? 0 : props.value.computeBase : 0}
                        onBlur={(e) => { setData({ ...data, computeBase: e.target.value }) }}
                    />

                </TableCell>


                {/* <TableCell>
                    <div className="form-group col">
                        <FormControl component="fieldset">
                            <FormControlLabel
                                name="isActive"
                                inputRef={() => { }}
                                control={<Switch checked={switchState.isCancelled} onChange={switchHandleChange('isCancelled')} value={switchState.isCancelled} />}
                                label="Active"
                            />
                        </FormControl>
                    </div>
                </TableCell> */}
                {/* </main> */}
            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.component : ''} `}</span> </p>
                </main>



            </>
        )
    }
    useEffect(() => {
        // SelectCreator(getInstructionalGuide, setInstructionalGuideSelect, "InstructionalGuideId", "ClassPlanDescription")
        // SelectCreator(getGradingPeriod, setGradingPeriodSelect, "GradingPeriodId", "Description")
        SelectCreator(getExamType, setExamTypeSelect, "GradeElementId", "ShortName")


        getIg({ instructionalGuideId: parseFloat(props.id) })

    }, [])


    useEffect(() => {
        getIg({ instructionalGuideId: parseFloat(props.id) })

    }, [props.reloadIg])


    useEffect(() => {
        if (instructionalGuide !== null) {

        }
    }, [instructionalGuide])


    useEffect(() => {
        getIG();
    }, [isOpenAdd, isOpenDelete, isOpenUpdate])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])


    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))
    console.log(gradeElementSelect)
    console.log(instructionalGuideSelect)
    console.log(gradingPeriodSelect)
    console.log(examTypeSelect)

    console.log(props.id)


    console.log(results)
    console.log(instructionalGuide)

    return (
        <>
            {gradingPeriodSelect && examTypeSelect &&
                <>


                    <AddUpdateModal
                        title="Delete Modified Outline"
                        isOpen={isOpenDelete}
                        Content={DeleteContent}
                        handleClose={onDeleteGradeElementClose}
                        isDelete={true}
                        onSubmit={onDeleteElement}
                        instructionalGuideSelect={instructionalGuideSelect}
                        gradingPeriodSelect={gradingPeriodSelect}
                        examTypeSelect={examTypeSelect}
                        value={keysToCamelCase(rowDeleteValue)}
                        id="instructionalGuideDetailId"
                        idValue={props.id}
                        isExceedsPercentage={props.isExceedsPercentage}

                    />
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header d-block bg-white" style={{ minHeight: 0 }}>
                            <div className="card-title">
                                {/* <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> */}
                            </div>
                            {/* <div className="card-toolbar float-right">
                                <div className="example-tools">
                                    <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary p-1">
                                        <i className="fa fa-plus p-0"></i>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                            {/* <IMTable tableProps={tableProps} /> */}
                            <TableContainer >
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">#</TableCell>
                                            <TableCell>Period</TableCell>
                                            <TableCell align="center">Component</TableCell>
                                            {/* <TableCell align="center">Topic No</TableCell> */}
                                            {/* <TableCell align="center">Topic Description</TableCell> */}
                                            <TableCell align="center">Topic Description</TableCell>
                                            <TableCell align="center">High Score</TableCell>
                                            <TableCell align="center">Percentage</TableCell>
                                            <TableCell align="center">Computational Base</TableCell>
                                            <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results.length > 0 ? results.map((result) => {
                                            const { InstructionalGuideDetailId, GradingPeriod, Component, TopicNo, TopicDescription, HighScore, Percentage, ComputeBase } = result;
                                            rowCnt += 1;

                                            return (
                                                <>
                                                    {selectedEditIds != InstructionalGuideDetailId ?
                                                        <TableRow key={InstructionalGuideDetailId}>
                                                            <TableCell align="center">{rowCnt}</TableCell>
                                                            <TableCell component="th" scope="row">{GradingPeriod}</TableCell>
                                                            <TableCell align="center">{Component}</TableCell>
                                                            {/* <TableCell align="center">{TopicNo}</TableCell> */}
                                                            <TableCell align="center">{TopicDescription}</TableCell>
                                                            <TableCell align="center">{HighScore}</TableCell>
                                                            <TableCell align="center">{Percentage}</TableCell>
                                                            <TableCell align="center">{ComputeBase}</TableCell>

                                                            <TableCell align="center">
                                                                <IGButtons
                                                                    periodId={result.GradingPeriodId}
                                                                    result={result}
                                                                    instructionalGuideDetailId={InstructionalGuideDetailId}
                                                                    checkIsEditable={checkIsEditable}
                                                                    onUpdateGradeElement={onUpdateGradeElement}
                                                                    onDeleteGradeElement={onDeleteGradeElement}
                                                                    scheduleTypeId={props.data.InstructionalScheduleTypeId}
                                                                    id={id}

                                                                />




                                                            </TableCell>
                                                        </TableRow>

                                                        :
                                                        <AddCell
                                                            title="Update Modified Outline"
                                                            Content={Content}
                                                            value={keysToCamelCase(rowValue)}
                                                            handleClose={onUpdateGradeElementClose}
                                                            isUpdate={true}
                                                            instructionalGuideSelect={instructionalGuideSelect}
                                                            gradingPeriodSelect={gradingPeriodSelect}
                                                            examTypeSelect={examTypeSelect}
                                                            onSubmit={onUpdateElement}
                                                            id="instructionalGuideDetailId"
                                                            idValue={props.id}
                                                            isExceedsPercentage={props.isExceedsPercentage}

                                                        />

                                                    }


                                                </>
                                            );

                                        }) :
                                            <TableRow></TableRow>
                                        }
                                        {/* <TableFooter> */}

                                        <AddCell
                                            title="Add Modified Outline"
                                            Content={Content}
                                            isAdd={true}
                                            instructionalGuideSelect={instructionalGuideSelect}
                                            gradingPeriodSelect={gradingPeriodSelect}
                                            examTypeSelect={examTypeSelect}
                                            onSubmit={onSaveGradeElement}
                                            id="instructionalGuideDetailId"
                                            idValue={props.id}
                                            isExceedsPercentage={props.isExceedsPercentage}
                                        />
                                        {/* </TableFooter> */}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </>
            }

        </>
    );
}