import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    instituteDropdown, instructorDropdown, getActiveSemester, 
    getActiveSchoolYear, getGradingPeriod, getSubjectSchedules,
    getClassRecordRawGrades, getReportType
} from './__hooks__';
//import PrintAttendanceSheet from "./PrintAttendanceSheet";
import PrintClassRecord from "./PrintClassRecord";
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../../../history';
import {
    TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TablePagination,
    Button, Modal, Box, Typography, TextField
} from '@material-ui/core';
import IMTablePromissoryNote from '../../../../../_metronic/layout/components/custom/table/IMTablePromissoryNote';

export default function ClassRecord() {

    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    //console.log("AUTTTTTTH: ", auth);
    const { register, handleSubmit, errors } = useForm();

    const [institutes, setInstitutes] = useState([]);
    const [instructors, setInstructors] = useState([]);
    const [subjectScheduleList, setSubjectScheduleList] = useState([]);
    const [subjectScheduleSelected, setSubjectScheduleSelected] = useState([]);

    const [instructionalGuides, setInstructionalGuides] = useState([]);
    const [scheduleType, setScheduleType] = useState([]);
    const [ssdIds, setSsdIds] = useState([]);

    
    
    const [gradingPeriodSelect, setGradingPeriodSelect] = useState([]);
    const [schoolYearSelect, setSchoolYearSelect] = useState([]);
    const [semesterSelect, setSemesterSelect] = useState([]);
    const [reportTypeSelect, setReportTypeSelect] = useState([]);
    const [schoolYearDisplay, setSchoolYearDisplay] = useState(null);
    const [semesterDisplay, setSemesterDisplay] = useState(null);
    const [reportTypeDisplay, setReportTypeDisplay] = useState(null);
    const [onAlert, setOnAlert] = useState(false);

    const [filter, setFilter] = useState({ 
        schoolYear: auth.data.schoolyears.grading.SchoolYear,
        semester: auth.data.schoolyears.grading.SemesterId,
        gradingPeriodId: 0,
        reportTypeId: 1,
        transactionType: '',
        period: '',
        courseId: '',
        status: '',
        institutes: '', 
        instructors: ''
    });

    const [newData, setNewData] = useState({ 
        employeeId: auth.data.EmployeeId,  //1865, //767, //1865
        schoolYear: filter.schoolYear,
        semester: filter.semester,
        instructionalGuide: subjectScheduleSelected.IG,
        gradingPeriodId: filter.gradingPeriodId == '' ? 0 : filter.gradingPeriodId,
        scheduleType: subjectScheduleSelected.ScheduleType,
        ssdIds: subjectScheduleSelected.SubjectScheduleDetailId,
        reportTypeId: filter.reportTypeId,
        schoolYearDisplay: '',
        semesterDisplay: '',
        reportTypeDisplay: ''
    });

    useEffect(() => {
        setNewData((prevData) => {
            return {...prevData, 
                employeeId: auth.data.EmployeeId,
                schoolYear: filter.schoolYear,
                semester: filter.semester,
                instructionalGuide: subjectScheduleSelected.IG,
                gradingPeriodId: filter.gradingPeriodId == '' ? 0 : filter.gradingPeriodId,
                scheduleType: subjectScheduleSelected.ScheduleType,
                ssdIds: subjectScheduleSelected.SubjectScheduleDetailId,
                reportTypeId: filter.reportTypeId,
                schoolYearDisplay: schoolYearDisplay,
                semesterDisplay: semesterDisplay,
                reportTypeDisplay: reportTypeDisplay
            }
        });

    },[filter.schoolYear,filter.semester,filter.gradingPeriodId,subjectScheduleSelected,
        filter.reportTypeId,schoolYearDisplay,semesterDisplay,reportTypeDisplay]);

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    useEffect(() => {
        SelectCreator(instituteDropdown, setInstitutes, "InstituteId", "Description");
    }, [])

    useEffect(() => {
        if(filter.institutes != ''){
            availableInstructors();
            //SelectCreator(instructorDropdown, setInstructors, "EmployeeId", "DropdownOption",{});
        }
    }, [filter.institutes])

    const availableInstructors = async () => {
        await instructorDropdown({
            instituteId:filter.institutes
        })
            .then(res => {
                let initialExec = true;
                if (res.success) {
                    let arr = [];

                    if(initialExec){
                        initialExec = false;
                        arr.push({tableId: -1, code: "---All---"});
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].IsActive == 1)
                            arr.push({ tableId: res.data[i]["EmployeeId"], code: res.data[i]["DropdownOption"] })
                    }
                    setInstructors(arr)
                }
            })
            .catch(e => {
                return []
            })
    }

    useEffect(() => {
        SelectCreator(getActiveSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description');
        SelectCreator(getActiveSemester, setSemesterSelect, 'SemesterId', 'Description');
        SelectCreator(getGradingPeriod, setGradingPeriodSelect, "GradingPeriodId", "Description");
        SelectCreator(getReportType, setReportTypeSelect, "ReportTypeId", "ReportTypeCode");
    }, []);

    useEffect(() => {
        if(schoolYearSelect !== null || typeof(schoolYearSelect) !== "undefined" ) {
            for(let i = 0; i < schoolYearSelect.length; i++){
                if(filter.schoolYear == schoolYearSelect[i].tableId){
                    setSchoolYearDisplay(schoolYearSelect[i].code);
                    break;
                }
            }
        }

        if(semesterSelect !== null || typeof(semesterSelect) !== "undefined" ) {
            for(let i = 0; i < semesterSelect.length; i++){
                if(filter.semester == semesterSelect[i].tableId){
                    setSemesterDisplay(semesterSelect[i].code);
                    break;
                }
            }
        }

        if(reportTypeSelect !== null || typeof(reportTypeSelect) !== "undefined" ) {
            for(let i = 0; i < reportTypeSelect.length; i++){
                if(filter.reportTypeId == reportTypeSelect[i].tableId){
                    setReportTypeDisplay(reportTypeSelect[i].code);
                    break;
                }
            }
        }
       
    }, [filter.schoolYear, filter.semester, filter.reportTypeId,schoolYearSelect,semesterSelect,reportTypeSelect]);

    const handleGetSubjectSched = async (data) => {
        setOnAlert(false);
        setSubjectScheduleSelected(data);

        let arrIG = [];
        let arrSched = [];
        let arrSsdId = [];

        if(data.IG == 0){
            for(let i = 1; i < subjectScheduleList.length; i++){
                arrIG.push(subjectScheduleList[i].IG);
                arrSched.push(subjectScheduleList[i].ScheduleType);
                arrSsdId.push(subjectScheduleList[i].SubjectScheduleDetailId);
            }

            setInstructionalGuides(arrIG);
            setScheduleType(arrSched);
            setSsdIds(arrSsdId);
        } else {
            setInstructionalGuides(data.IG);
            setScheduleType(data.ScheduleType);
            setSsdIds(data.SubjectScheduleDetailId);
        }
    }

    useEffect(() => {
        localStorage.setItem("class-record-school-year",filter.schoolYear);
        localStorage.setItem("class-record-semester",filter.semester);
        localStorage.setItem("class-record-grading-period",filter.gradingPeriodId == '' ? 0 : filter.gradingPeriodId,);
        localStorage.setItem("class-record-instructional-guide",subjectScheduleSelected.IG);
        localStorage.setItem("class-record-schedule-type",subjectScheduleSelected.ScheduleType);
        localStorage.setItem("class-record-instructional-guides",instructionalGuides);
        localStorage.setItem("class-record-schedule-types",scheduleType);
        localStorage.setItem("class-record-subj-sched-ids",ssdIds);
        localStorage.setItem("class-record-report-type-id",filter.reportTypeId);
        localStorage.setItem("class-record-sy-display",schoolYearDisplay);
        localStorage.setItem("class-record-sem-display",semesterDisplay);
        localStorage.setItem("class-record-rt-display",reportTypeDisplay);
    }, [filter.schoolYear,filter.semester,filter.gradingPeriodId,
        subjectScheduleSelected.IG,subjectScheduleSelected.ScheduleType,
        instructionalGuides,scheduleType,ssdIds,filter.reportTypeId,
        schoolYearDisplay,semesterDisplay,reportTypeDisplay]);    
    
    useEffect(() => {
        const getAvailableSubjectSchedules = async () => {
            await getSubjectSchedules(auth.data.EmployeeId,filter.schoolYear,filter.semester)
                .then(res => {
                    if(res.success){
                        if(res.data[0].SubjectScheduleDetailId == -1){
                            setSubjectScheduleList([]);
                        } else {
                            setSubjectScheduleList(res.data);
                        }
                    }
                })
                .catch(error => {

                });
        }

        getAvailableSubjectSchedules();

    }, [filter.schoolYear,filter.semester]);
    
    const onGenerateReport = () => {       
        if(filter.gradingPeriodId == '' || filter.schoolYear == '' || filter.semester == ''){
            setOnAlert(true);
        } else {
            setOnAlert(false);

            //getClassRecord();
        }
    }

    const getClassRecord = async() => {

        await getClassRecordRawGrades(auth.data.EmployeeId,filter.schoolYear,filter.semester,subjectScheduleSelected.IG,filter.gradingPeriodId,subjectScheduleSelected.ScheduleType)
            .then(res => {
                // if(res.success){
                //     if(res.data[0].SubjectScheduleDetailId == -1){
                //         setSubjectScheduleList([]);
                //     } else {
                //         setSubjectScheduleList(res.data);
                //     }
                // }
            })
            .catch(error => {

            });
    }

    const displayAlert = () => {    
        setOnAlert(true);
    }

    return (
        <>
            {             
                <div className='d-flex align-items-center'>
                    <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                        <header className='card-header p-5 bg-secondary'>
                            <h6 className="float-left m-0 mt-4 ml-5">Class Record</h6>
                        </header>

                        <section className="card-body">
                            {onAlert == true &&
                                <div className="alert alert-danger" role="alert">
                                    No Record Found
                                </div>
                            }

                            <p className="card-text">Search for Class Record:</p>
                                                
                            {/* School Year Dropdown */}
                            <div className="form-row">
                               <div className="form-group col">
                                   <IMSelect
                                       data={schoolYearSelect}
                                       onHandleChange={filterChange('schoolYear')}
                                       refClassContainer=""
                                       name="schoolYear"
                                       isRequired={false}
                                       withLabel={false}
                                       label="School Year"
                                       placeHolder="Select School Year"
                                       forwardRef={() => { }}
                                       selectedId={filter.schoolYear}
                                       refClassName={`text-center`}
                                       withDescription={true}
                                       description={`School Year`}
                                       refDisabled={true}
                                       refIsStatic={false}
                                       refStaticData={[
                                       ]
                                       }
                                       field={{
                                           tableId: 'tableId',
                                           display: 'code'
                                       }}
                                   />
                               </div>
                            </div>
                                                                
                            {/* Semester Dropdown */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={semesterSelect}
                                        onHandleChange={filterChange('semester')}
                                        refClassContainer=""
                                        name="semesterId"
                                        isRequired={false}
                                        withLabel={false}
                                        label="Semester"
                                        placeHolder="Select Semester"
                                        forwardRef={() => { }}
                                        selectedId={filter.semester}
                                        refClassName={`text-center`}
                                        withDescription={true}
                                        description={`Semester`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[
                                        ]
                                        }
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Report Type Dropdown */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={reportTypeSelect}
                                        onHandleChange={filterChange('reportTypeId')}
                                        refClassContainer=""
                                        name="reportTypeId"
                                        isRequired={false}
                                        withLabel={false}
                                        label="Report Type"
                                        placeHolder="Select Report Type"
                                        forwardRef={() => { }}
                                        selectedId={filter.reportTypeId}
                                        refClassName={`text-center`}
                                        withDescription={true}
                                        description={`Report Type`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[
                                        ]
                                        }
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>
                                    
                            {/* Grading Period */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={gradingPeriodSelect}
                                        onHandleChange={filterChange('gradingPeriodId')}
                                        refClassContainer=""
                                        name="gradingPeriod"
                                        isRequired={false}
                                        withLabel={false}
                                        label="Grading Period"
                                        placeHolder="Select Grading Period"
                                        forwardRef={() => { }}
                                        selectedId={filter.gradingPeriodId}
                                        refClassName={`text-center`}
                                        withDescription={true}
                                        description={`Grading Period`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Subject */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <Autocomplete
                                        id="subjectSchdule"
                                        options={subjectScheduleList}
                                        //defaultValue={{StudentId: defaultValues.studentId, StudentName: defaultValues.studentName}}
                                        getOptionLabel={(option) => option.SubjectSched}
                                        //getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                        onChange={(event, value) => {
                                            if (value) {
                                                // Handle the selected option
                                                //handleGetStudent(value)
                                                handleGetSubjectSched(value);
                                            } else {        
                                                setSsdIds([]);                                                                    
                                                // Handle the case when no option is selected
                                                // setStudentId(null);
                                                // setStudentList([]);
                                                // setStudent(null);
                                                // setResults([]);
                                                // setTotalUnits(0);
                                                // setWeightedAverage(0);
                                                // setTimeGenerated(null);
                                                // setClearance([]);
                                                // setOnAlert(false);
                                                // setStudentSelected((prevData) => {
                                                //     return {...prevData, 
                                                //         studentId: null, 
                                                //         studentName: '',
                                                //         fullName: '',
                                                //         course: ''}
                                                // });
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Subject Schdule/Section"
                                                variant="outlined"
                                                inputRef={register}
                                                name="subjectSchdule"
                                                //value={{StudentId: studentSelected.studentId,  StudentName: studentSelected.studentName}}
                                                // onChange={
                                                //     (e) => { handleStudentInputChange(e.target.value) }
                                                // }
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>

                            <div className="form-row">
                                <section className="col"> 
                                    {/* <button onClick={() => { onGenerateReport() }} className='btn btn-success btn-block'>Generate Report</button> */}
                                        {
                                            (ssdIds.toString().length > 0 && filter.reportTypeId != '' && filter.semester != '' && filter.schoolYear != '') ?
                                            <Link 
                                                to={{ pathname: `/report/class-record`, data: newData }} className='btn btn-success btn-block'>
                                                {/* <i className="fa fa-list-alt text-white"></i> */}
                                                Generate Report
                                            </Link>

                                            :

                                            <>
                                                <button className='btn btn-success btn-block' onClick={() => { displayAlert() }} > 
                                                    Generate Report
                                                </button> 
                                            </>
                                        }
                                </section>
                            </div>
                        </section>
                    </main>
                </div>
            }
        </>
    )

}