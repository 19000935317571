import instance from "../../../../../../apis/local/systemAPI";

export const getInstructionalGuideDetail = async (query) => {
    const result = await instance.post("api/grades/instructional-guide-detail/f", query);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getCurrentInstructionalGuide = async (query) => {
    const result = await instance.post("api/grades/instructional-guide/getInstructionalGuideListById", query);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGradeTemplateComponentSelect = async (query) => {
    const result = await instance.post("api/grades/grade-templates/componentSelect", query);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradeTemplateGradingSelect = async (query) => {
    const result = await instance.post("api/grades/grade-templates/gradingSelect", query);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createInstructionalGuideDetail = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide-detail/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateInstructionalGuideDetail = async (id, formValues) => {
    const result = await instance.patch(`api/grades/instructional-guide-detail/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteInstructionalGuideDetail = async (id) => {
    const result = await instance.delete(`api/grades/instructional-guide-detail/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstructionalGuide = async () => {
    const result = await instance.get(`api/grades/instructional-guide/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGradingPeriod = async () => {
    const result = await instance.get(`api/references/grading-period/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getExamType = async () => {
    const result = await instance.get(`api/references/grade-element/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateInstructionalGuideDetail = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide-detail/addUpdate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const checkPercentage = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide-detail/checkPercentage`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const checkIsEditable = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/isEditable`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const checkIsDeletable = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/isDeletable`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}