import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    instituteDropdown, instructorDropdown, attendanceSheet,
    autoCompleteRefNo, getPromissoryNote, dropdownValues,
    autoCompleteStudents, getStudentDetails, getActiveSemester,
    getActiveSchoolYear, addPromissoryNote
} from './__hooks__';
// import PrintAttendanceSheet from "./PrintAttendanceSheet";
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import history from '../../../../history';
import {
    TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TablePagination,
    Button, Modal, Box, Typography, TextField
} from '@material-ui/core';
import IMTablePromissoryNote from '../../../../../_metronic/layout/components/custom/table/IMTablePromissoryNote';

export default function PromissoryNote() {

    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const { register, handleSubmit, errors } = useForm();
    const [attendance, setAttendance] = useState([]);
    //const [onAlert, setOnAlert] = useState([]);
    var days = [];

    const onReset = async () => {
        setAttendance([]);
    }

    const executeGetStudentAttendance = async (formValues) => {
        let values = {
            employeeId: filter.instructors,
            instituteId: filter.institutes
        }

        await attendanceSheet(values)
            .then(res => {
            if(res.success){
                if (res.data.length > 0) {
                    console.log("Attendance Data: ", res.data);

                    let originalData = res.data;

                    const transformedArray = [];
                    var selectedDays = [];

                    for (const key in originalData) {
                        const entry = originalData[key];
                        const { UniqueIdentifier,Instructor, SubjectCode, Description, ClassCode, ClassSection, DayCode, SubjectType, LastName, FirstName, MiddleName } = entry;                        
                        const attendanceDetailIndex = transformedArray.findIndex(item => item.UniqueIdentifier === UniqueIdentifier);
                    
                        if (attendanceDetailIndex === -1) {
                            for(let i = 0; i < DayCode.length; i++){
                                switch(DayCode[i]){
                                    case 'M':
                                        selectedDays.push(1);
                                        break;
                                    case 'T':
                                        selectedDays.push(2);
                                        break;
                                    case 'W':
                                        selectedDays.push(3);
                                        break;
                                    case 'H':
                                        selectedDays.push(4);
                                        break;
                                    case 'F':
                                        selectedDays.push(5);
                                        break;
                                    case 'S':
                                        selectedDays.push(6);
                                        break;
                                    default:
                                        selectedDays.push(0);
                                        break;
                                }
                            }

                            const currentDate = new Date(formValues.startDate);
                            const endDateFormatted = new Date(formValues.endDate);
                            let limit = 1;

                            while (currentDate <= endDateFormatted && limit <= 30) {
                              if (selectedDays.includes(currentDate.getDay())) {
                                    let newDate = (currentDate.getMonth() + 1) + '/' + currentDate.getDate() + '/' +  currentDate.getFullYear();
                                    days.push(newDate);
                                    limit++;
                              }

                              // Increment the current date by 1 day
                              currentDate.setDate(currentDate.getDate() + 1);
                            }

                            transformedArray.push({ 
                                UniqueIdentifier:UniqueIdentifier,
                                Instructor: Instructor, 
                                SubjectCode: SubjectCode,
                                Description: Description, 
                                ClassCode: ClassCode, 
                                ClassSection: ClassSection, 
                                Days: days, 
                                SubjectType: SubjectType,
                                Details: [{ LastName, FirstName, MiddleName }]
                            });

                            selectedDays = [];
                            days = []
                        } else {
                          transformedArray[attendanceDetailIndex].Details.push({ LastName, FirstName, MiddleName });
                        }
                    }

                    setAttendance(transformedArray);
                }
            }
            })
            .catch(error => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                if (error.response.status === 401) {
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                }
            })
    }

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [studentList, setStudentList] = useState([]);
    const [referenceNumberList, setReferenceNumberList] = useState([]);
    const [student, setStudent] = useState(null);
    const [referenceNumber, setReferenceNumber] = useState(null);
    const [institutes, setInstitutes] = useState([]);
    const [instructors, setInstructors] = useState([]);

    const [filter, setFilter] = useState({ 
        schoolYear: '',
        semester: '',
        transactionType: '',
        period: '',
        courseId: '',
        status: '',
        institutes: '', 
        instructors: ''
    });

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    useEffect(() => {
        SelectCreator(instituteDropdown, setInstitutes, "InstituteId", "Description");
    }, [])

    useEffect(() => {
        if(filter.institutes != ''){
            availableInstructors();
            //console.log("lol");
            //SelectCreator(instructorDropdown, setInstructors, "EmployeeId", "DropdownOption",{});
        }
        console.log("filter.institutes: ",filter.institutes);
    }, [filter.institutes])

    const availableInstructors = async () => {
        await instructorDropdown({
            instituteId:filter.institutes
        })
            .then(res => {
                let initialExec = true;
                if (res.success) {
                    let arr = [];

                    if(initialExec){
                        initialExec = false;
                        arr.push({tableId: -1, code: "---All---"});
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].IsActive == 1)
                            arr.push({ tableId: res.data[i]["EmployeeId"], code: res.data[i]["DropdownOption"] })
                    }
                    setInstructors(arr)
                }
            })
            .catch(e => {
                return []
            })
    }

    // if(document.getElementById("startDate") !== null || document.getElementById("endDate") !== null){
    //     document.getElementById("startDate").addEventListener("change", function() {
    //         setOnAlert(false);
    //     });

    //     document.getElementById("endDate").addEventListener("change", function() {
    //         setOnAlert(false);
    //     });
    // }


    // --- Proper Codes for Promissory Notes --- //

    const [schoolYearSelect, setSchoolYearSelect] = useState([]);
    const [semesterSelect, setSemesterSelect] = useState([]);
    const [courses, setCourses] = useState([]);
    const [transactionTypes, setTransactionTypes] = useState([]);
    const [periods, setPeriods] = useState([]);
    const [statusList, setStatusList] = useState([]);
    const [onAlert, setOnAlert] = useState(false);
    const [onAdd, setOnAdd] = useState(false);
    const [onViewList, setOnViewList] = useState(false);
    const [onSearch, setOnSearch] = useState(true);
    const [onEdit, setOnEdit] = useState(false);
    const [studentDetails, setstudentDetails] = useState(null);
    const [promissoryNotesList, setPromissoryNotesList] = useState([]);
    const [totalRecords, setTotalRecord] = useState(0);
    const [isLastUpdate, setIsLastUpdate] = useState(true);
    const [keyword, setKeyword] = useState(null);
    const [onTableSearch, setOnTableSearch] = useState(true);
    const RowCnt = page * rowsPerPage;

    useEffect(() => {
        SelectCreator(dropdownValues, setCourses, "CourseId", "Description",{input:1});
        SelectCreator(dropdownValues, setStatusList, "PNStatusId", "Status",{input:2});
        SelectCreator(dropdownValues, setTransactionTypes, "TransactionTypeId", "TransactionType",{input:3});
        SelectCreator(dropdownValues, setPeriods, "PNPeriodId", "Period",{input:4});
        SelectCreator(getActiveSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getActiveSemester, setSemesterSelect, 'SemesterId', 'Description')
    }, [])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnTableSearch(event);
    };

    const onClickSearch = () => {
        setOnSearch(false);
        setOnAdd(true);
    }

    const onClickBack = () => {
        setOnSearch(true);
        setOnAdd(false);
        setOnEdit(false);
        setOnViewList(false);
    }

    const handlePNInputChange = async (input) => {
        setOnAlert(false);
        await autoCompleteRefNo({ input: input })
            .then(res => {
                console.log("Res: ",res);
                setReferenceNumberList(res.data);
            })
            .catch(e => {

            })
    }

    const handleStudentInputChange = async (input) => {
        setOnAlert(false);
        await autoCompleteStudents({ input: input })
            .then(res => {
                console.log("New Res: ",res);
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    const executeGetStudentDetails = async (studentId) => {
        await getStudentDetails({studentId:studentId})
            .then(res => {
                let enrollmentDate = new Date(res.data[0].EnrollmentDate);
                res.data[0].EnrollmentDate = enrollmentDate.toISOString().split('T')[0];
                setstudentDetails(res.data[0])
            })
            .catch(e => {
                
            })
    }

    const onReferenceNumSearch = () => {
        searchByReferenceNumber();
    }

    const onSearchByDetails = () => {
        let startDateValue = null;
        let endDateValue = null;
        
        if(document.getElementById("startDate") !== null){
            startDateValue = document.getElementById("startDate").value;
            if(startDateValue.length == 0){
                startDateValue = null;
            }
        }

        if(document.getElementById("endDate") !== null){
            endDateValue = document.getElementById("endDate").value;
            if(endDateValue.length == 0){
                endDateValue = null;
            }
        }

        if(startDateValue !== null && endDateValue !== null){
            if(startDateValue > endDateValue){
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Start date should not be greater than End date." });
            }
        }

        let json = {
            studentNo:student,
            courseId:(filter.courseId === null || filter.courseId == '') ? 0 : filter.courseId,
            dateStartRange:startDateValue,
            dateEndRange:endDateValue,
            status:(filter.status === null || filter.status == '') ? 0 : filter.status,
            referenceNo:null,
            isReferenceSearch:0,
            rowsPerPage:rowsPerPage,
            page:page
        };

        setOnAdd(false);
        setOnEdit(false);
        setOnSearch(false);
        setOnViewList(true);
        searchByDetails(json);
    }

    const onAddNewPromissoryRecord = () => {
        addNewPromissoryRecord();
    }

    const addNewPromissoryRecord = async () => {
        let promissoryNoteDateVal = null;
        let dueDateVal = null;
        let amountVal = 0;
        let remarksVal = '';
        
        if(document.getElementById("promissoryNoteDate") !== null){
            promissoryNoteDateVal = document.getElementById("promissoryNoteDate").value;
            if(promissoryNoteDateVal.length == 0){
                promissoryNoteDateVal = null;
            }
        }

        if(document.getElementById("dueDate") !== null){
            dueDateVal = document.getElementById("dueDate").value;
            if(dueDateVal.length == 0){
                dueDateVal = null;
            }
        }

        if(document.getElementById("amount") !== null){
            amountVal = document.getElementById("amount").value;
            if(amountVal.length == 0){
                amountVal = 0;
            }
        }

        if(document.getElementById("remarks") !== null){
            remarksVal = document.getElementById("remarks").value;
        }

        let values = {
            studentNo:student,
            transactionType:filter.transactionType,
            period:filter.period,
            amount:amountVal,
            promissoryNoteDate:promissoryNoteDateVal,
            dueDate:dueDateVal,
            remarks:remarksVal,
            schoolYear:filter.schoolYear,
            semesterId:filter.semester,
            createdBy: auth.data.Username
        }

        await addPromissoryNote(values)
            .then(result => {
                console.log("result:", result);
                if (result.success) {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'New Promissory Record has been added!' });
                    setOnEdit(true);
                    setOnAdd(false);
                    setReferenceNumber(result.data[0].Result);
                    document.body.scrollTop = 0;
                    document.documentElement.scrollTop = 0;
                    //setOnAlert(true);            
                } else {
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'There was an error adding the record. Please contact your administrator.' });
                }
            })
            .catch(error => {
              if (error.response.status === 401) {
                history.push('/auth/login');
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
              }
            });

    }

    // Search for student's grade
    const searchByReferenceNumber = async () => {

        if(referenceNumber === null){
            setOnAlert(true);
        } else {
            //setResults([]);
            setOnAlert(false);
    
            let json = {
                studentNo:null,
	            courseId:null,
	            dateStartRange:null,
	            dateEndRange:null,
	            status:null,
	            referenceNo:referenceNumber,
	            isReferenceSearch:1,
                rowsPerPage:100,
                page:0
            };
    
            await getPromissoryNote(json)
                .then(result => {
                    console.log("result:", result);
                    if (result.success) {
                        if(result.results > 0){
                            
                        }      
                        //setOnAlert(true);            
                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    history.push('/auth/login');
                  } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                  }
                });
        }
    }

    useEffect(() => {
        if(onViewList) {
            let startDateValue = null;
            let endDateValue = null;
            
            if(document.getElementById("startDate") !== null){
                startDateValue = document.getElementById("startDate").value;
                if(startDateValue.length == 0){
                    startDateValue = null;
                }
            }

            if(document.getElementById("endDate") !== null){
                endDateValue = document.getElementById("endDate").value;
                if(endDateValue.length == 0){
                    endDateValue = null;
                }
            }

            if(startDateValue !== null && endDateValue !== null){
                if(startDateValue > endDateValue){
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Start date should not be greater than End date." });
                }
            }

            let json = {
                studentNo:student,
                courseId:(filter.courseId === null || filter.courseId == '') ? 0 : filter.courseId,
                dateStartRange:startDateValue,
                dateEndRange:endDateValue,
                status:(filter.status === null || filter.status == '') ? 0 : filter.status,
                referenceNo:null,
                isReferenceSearch:0,
                rowsPerPage:rowsPerPage,
                page:page
            };

            searchByDetails(json);  
        }

    }, [dispatch, page, rowsPerPage])

    const searchByDetails = async (json) => {
        await getPromissoryNote(json)
            .then(result => {
                console.log("result:", result);
                if (result.success) {
                    if(result.results > 0){
                        setPromissoryNotesList(result.data);
                        setTotalRecord(result.data.length > 0 ? result.data[0].TotalCount : 0);
                    }      
                } else {
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                }
            })
            .catch(error => {
              if (error.response.status === 401) {
                history.push('/auth/login');
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
              }
            });
    }

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ReferenceNo', label: 'Reference No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Student Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TransactionType', label: 'Transaction Type', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DueDate', label: 'Due Date', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Status', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'ReferenceNo',
        sortField: '',
        columns: columns,
        rows: promissoryNotesList,
        totalRecords: totalRecords,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: null,
        parentId: null,
        tableTitle: '',
        onSearch: onTableSearch,
        keyword: keyword,
        lastUpdateId: 1,
        onIsLastUpdate: isLastUpdate,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        //onTypes: accountTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch
    }
    
    console.log("promissoryNotesList: ",promissoryNotesList);
    // console.log("RowCnt: ",RowCnt);
    // console.log("page: ",page);
    // console.log("rowsPerPage: ",rowsPerPage);
    // console.log("totalRecords: ",totalRecords);
    // console.log("columns: ",totalRecords);

    return (
        <>
            { (!onAdd && !onEdit) && onSearch && 
            
                <div className='d-flex align-items-center'>
                    <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                        <header className='card-header p-5 bg-secondary'>
                            <h6 className="float-left m-0 mt-4 ml-5">Promissory Note Maintenance</h6>
                            <section className='float-right'>
                                <button onClick={() => { onClickSearch() }} className='btn btn-sm btn-primary'>Add</button>
                            </section>
                        </header>

                        <section className="card-body">
                            {onAlert == true && attendance.length == 0 &&
                                <div className="alert alert-danger" role="alert">
                                    No Record Found
                                </div>
                            }

                            <p className="card-text">Search by Reference No:</p>

                            {/* Reference Number AutoComplete */}
                            <div className="form-row">
                                <div className="form-group col-9">
                                    <Autocomplete
                                        id="referenceNo"
                                        options={referenceNumberList}
                                        //defaultValue={{StudentId: defaultValues.studentId, StudentName: defaultValues.studentName}}
                                        getOptionLabel={(option) => option.ReferenceNo}
                                        //getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                        onChange={(event, value) => {
                                            if (value) {
                                                // Handle the selected option
                                                setReferenceNumber(value.ReferenceNo);
                                            } else {    
                                                setReferenceNumber(null);      
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Reference No."
                                                variant="outlined"
                                                inputRef={register}
                                                name="referenceNo"
                                                //value={{StudentId: studentSelected.studentId,  StudentName: studentSelected.studentName}}
                                                onChange={
                                                    (e) => { handlePNInputChange(e.target.value) }
                                                }
                                            />
                                        )}
                                    /> 
                                </div>

                                <div className="form-group col-3">
                                    <button onClick={() => { onReferenceNumSearch() }} className='btn btn-success btn-block' style={{height:"100%"}}>Search</button>
                                </div>
                            </div>

                            <hr></hr>
                            <br></br>

                            {/* <form onSubmit={handleSubmit(onSubmit)}> */}
                            <p className="card-text">Search by Student/Promissory Note Details:</p>
                                            
                            {/* Student No./Name */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <Autocomplete
                                        id="student"
                                        options={studentList}
                                        //defaultValue={{StudentId: defaultValues.studentId, StudentName: defaultValues.studentName}}
                                        getOptionLabel={(option) => option.StudentName}
                                        //getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                        onChange={(event, value) => {
                                            if (value) {
                                                // Handle the selected option
                                                setStudent(value.StudentNo);
                                            } else {    
                                                setStudent(null);      
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Student No./Name"
                                                variant="outlined"
                                                inputRef={register}
                                                name="student"
                                                //value={{StudentId: studentSelected.studentId,  StudentName: studentSelected.studentName}}
                                                onChange={
                                                    (e) => { handleStudentInputChange(e.target.value) }
                                                }
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                                                
                            {/* Courses */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={courses}
                                        onHandleChange={filterChange('courseId')}
                                        refClassContainer=""
                                        name="courses"
                                        isRequired={false}
                                        withLabel={true}
                                        label="Courses"
                                        placeHolder="Select Course Taken"
                                        forwardRef={() => { }}
                                        selectedId={filter.courseId}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`Courses`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>

                            {/* Range Date */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <label htmlFor="startDate">Record Date (Start)</label>
                                    <input
                                      ref={register({
                                        required: false
                                      })}
                                      name="startDate"
                                      type="date"
                                      className="form-control"
                                      id="startDate"
                                      placeholder="Start Date"
                                    />
                                </div>

                                <div className="form-group col">
                                    <label htmlFor="endDate">Record Date (End)</label>
                                    <input
                                      ref={register({
                                        required: false
                                      })}
                                      name="endDate"
                                      type="date"
                                      className="form-control"
                                      id="endDate"
                                      placeholder="End Date"
                                    />
                                </div>
                            </div>

                            {/* Status */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <IMSelect
                                        data={statusList}
                                        onHandleChange={filterChange('status')}
                                        refClassContainer=""
                                        name="status"
                                        isRequired={false}
                                        withLabel={true}
                                        label="Status"
                                        placeHolder="Select Status"
                                        forwardRef={() => { }}
                                        selectedId={filter.status}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`Status`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>

                            {/* <div className="form-row bg-secondary"> */}
                            <div className="form-row">
                                <section className="col"> 
                                    <button onClick={() => { onSearchByDetails() }} className='btn btn-success btn-block'>List Notifications</button>
                                    {/* <button type="submit" className='btn btn-success btn-block'>List Notifications</button>  */}
                                </section>
                            </div>
                            {/* </form> */}
                        </section>
                    </main>
                </div>
            }

            { (onAdd || onEdit) && !onSearch &&

                <div className='d-flex align-items-center'>
                    <main className="card mx-auto my-auto shadow-lg" style={{ width: "50%" }}>
                        <header className='card-header p-5 bg-secondary'>
                            <h6 className="float-left m-0 mt-4 ml-5">
                                {
                                    onAdd ? <>Add New Promissory Note Record</> : <>Edit Promissory Note Record</>
                                }
                            </h6>
                            <section className='float-right'>
                                <button onClick={() => { onClickBack() }} className='btn btn-sm btn-primary'>Back</button>
                            </section>
                        </header>

                        <section className="card-body">

                            {/* Student No./Name */}
                            <div className="form-row">
                                <div className="form-group col">
                                    <Autocomplete
                                        id="student"
                                        options={studentList}
                                        //defaultValue={{StudentId: defaultValues.studentId, StudentName: defaultValues.studentName}}
                                        getOptionLabel={(option) => option.StudentName}
                                        //getOptionSelected={(option, value) => option.StudentName === value.StudentName}
                                        onChange={(event, value) => {
                                            if (value) {
                                                // Handle the selected option
                                                setStudent(value.StudentNo);
                                                executeGetStudentDetails(value.StudentId);
                                            } else {    
                                                setStudent(null);      
                                            }
                                        }}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Student No./Name"
                                                variant="outlined"
                                                inputRef={register}
                                                name="student"
                                                //value={{StudentId: studentSelected.studentId,  StudentName: studentSelected.studentName}}
                                                onChange={
                                                    (e) => { handleStudentInputChange(e.target.value) }
                                                }
                                            />
                                        )}
                                    /> 
                                </div>
                            </div>
                                            
                            <p className="card-text" style={{fontWeight:"500"}}>Student Current Enrollment Info: </p>

                            <div className="form-row">

                                {/* Course */}
                                <div className="form-group col">
                                    <span className="form-text text-muted">Course: </span>
                                    {studentDetails !== null ?
                                        <>
                                            <input value={studentDetails.Course} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                        </>
                                        :
                                            <input placeholder="Course" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                    }
                                </div>
                            </div>

                            <div className="form-row">

                                {/* Year Level */}
                                <div className="form-group col">
                                    <span className="form-text">Year Level: </span>
                                    {studentDetails !== null ?
                                        <>
                                            <input value={studentDetails.YearLevel} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                        </>
                                        :
                                            <input placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                    }
                                </div>

                                {/* Enrolled Date */}
                                <div className="form-group col">
                                    <span className="form-text">Enrolled Date: &nbsp;&nbsp;</span>
                                    {studentDetails !== null ?
                                        <>
                                            <input value={studentDetails.EnrollmentDate} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                        </>
                                        :
                                            <input placeholder="Enrolled Date" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled" />
                                    }
                                </div>
                            </div>

                            <div className="form-row">

                                {/* School Year */}
                                <div className="form-group col">
                                    <span className="form-text">School Year: </span>
                                    {studentDetails !== null ?
                                        <>
                                            <input value={studentDetails.SchoolYear} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                        </>
                                        :
                                            <input placeholder="School Year" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                    }
                                </div>

                                {/* Semester */}
                                <div className="form-group col">
                                    <span className="form-text">Semester: </span>
                                    {studentDetails !== null ?
                                        <>
                                            <input value={studentDetails.Semester} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                        </>
                                        :
                                            <input placeholder="Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                    }
                                </div>
                            </div>

                            <hr></hr>
                            <br></br>
                                
                            <p className="card-text" style={{fontWeight:"500"}}>Promissory Note Details: </p>

                            {
                                referenceNumber !== null ?
                                <div className="form-row">
                                    <div className="form-group col">
                                        <label>Reference No. (PN No.): </label>
                                        <input value={referenceNumber} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" disabled="disabled"/>
                                    </div>
                                    <div className="form-group col">
                                       <></>
                                    </div>
                                </div>

                                :

                                <></>
                            }
                                
                            <div className="form-row">

                                {/* School Year */}
                                <div className="form-group col col-responsive">
                                    <IMSelect
                                        data={schoolYearSelect}
                                        onHandleChange={filterChange('schoolYear')}
                                        refClassContainer=""
                                        name="schoolYear"
                                        isRequired={true}
                                        withLabel={true}
                                        label="School Year"
                                        placeHolder="Select School Year"
                                        forwardRef={() => { }}
                                        selectedId={filter.schoolYear}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`School Year`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>

                                {/* Semester */}
                                <div className="form-group col col-responsive">
                                    <IMSelect
                                        data={semesterSelect}
                                        onHandleChange={filterChange('semester')}
                                        refClassContainer=""
                                        name="semester"
                                        isRequired={true}
                                        withLabel={true}
                                        label="Semester"
                                        placeHolder="Select Semester"
                                        forwardRef={() => { }}
                                        selectedId={filter.semester}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`Semester`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>
                            </div>

                            <div className="form-row">

                                {/* Promissory Note Date */}
                                <div className="form-group col col-responsive">
                                    <label htmlFor="promissoryNoteDate">Promissory Note Date<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                      ref={register({
                                        required: false
                                      })}
                                      name="promissoryNoteDate"
                                      type="date"
                                      className="form-control"
                                      id="promissoryNoteDate"
                                    />
                                </div>
                                  
                                {/* Due Date */}
                                <div className="form-group col col-responsive">
                                    <label htmlFor="dueDate">Due Date<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                      ref={register({
                                        required: false
                                      })}
                                      name="dueDate"
                                      type="date"
                                      className="form-control"
                                      id="dueDate"
                                    />
                                </div>
                            </div>

                            <div className="form-row">

                                {/* Transaction Type */}
                                <div className="form-group col col-responsive">
                                    <IMSelect
                                        data={transactionTypes}
                                        onHandleChange={filterChange('transactionType')}
                                        refClassContainer=""
                                        name="transactionType"
                                        isRequired={true}
                                        withLabel={true}
                                        label="Transaction Type"
                                        placeHolder="Select Transaction Type"
                                        forwardRef={() => { }}
                                        selectedId={filter.transactionType}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`Transaction Type`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>

                                {/* Period */}
                                <div className="form-group col col-responsive">
                                    <IMSelect
                                        data={periods}
                                        onHandleChange={filterChange('period')}
                                        refClassContainer=""
                                        name="period"
                                        isRequired={true}
                                        withLabel={true}
                                        label="Period"
                                        placeHolder="Select Period"
                                        forwardRef={() => { }}
                                        selectedId={filter.period}
                                        refClassName={`text-center`}
                                        withDescription={false}
                                        description={`Period`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                    />
                                </div>

                            </div>

                            <div className="form-row">

                                {/* Amount */}
                                <div className="form-group col col-responsive">
                                    <label htmlFor="amount">Amount<span className="text-danger">&nbsp;*</span></label>
                                    <input
                                      ref={register({
                                        required: false
                                      })}
                                      name="amount"
                                      type="number"
                                      className="form-control"
                                      id="amount"
                                      placeholder="Enter Amount"
                                      // pattern="[0-9]{11,14}"
                                      maxLength="30"
                                    />
                                </div>

                                {/* Remarks */}
                                <div className="form-group col col-responsive">
                                    <label htmlFor="remarks">Remarks</label>
                                    <div>
                                        <textarea 
                                            id="remarks" 
                                            name="remarks" 
                                            rows="4" 
                                            cols="55"
                                        >
                                        </textarea>
                                    </div>
                                    
                                </div>
                            </div>

                            <hr></hr>
                            <br></br>

                            <section className='float-right'>
                                {
                                    onAdd ? 
                                    <button onClick={() => { onAddNewPromissoryRecord() }} className='btn btn-sm btn-primary'>Submit</button> 
                                    :
                                    <button onClick={() => { onAddNewPromissoryRecord() }} className='btn btn-sm btn-primary'>Save</button>
                                }
                                
                            </section>

                            {/* <div className='row my-5 padding-side-25'>
                                <div className='col-12 col-xl-6' >
                                    <button onClick={() => { }} className='btn btn-success btn-block'>Search</button>
                                </div>
                            </div> */}
                        </section>
                    </main>
                </div>

                // <div>
                //     <div className="container" >
                //         <main className="card border-0 p-5">
                //             <header className="card-header"> 
                //                 <div className='row'>
                //                     <button id="btn-reset" style={{width:"11.65%"}} className="mt-3 btn btn-primary mr-3" onClick={() => { onReset() }} > 
                //                         <i className="fa flaticon-reply"></i>&nbsp;Back
                //                     </button>   
                //                     <PrintAttendanceSheet results={[attendance]}/>
                //                 </div>
                //             </header>
                //         </main>
                //     </div>
                // </div>
            }

            { onViewList &&

                <div className='d-flex align-items-center'>
                    <main className="card mx-auto my-auto shadow-lg" style={{ width: "80%" }}>
                        <header className='card-header p-5 bg-secondary'>
                            <h6 className="float-left m-0 mt-4 ml-5">List of Promissory Notes</h6>
                            <section className='float-right'>
                                <button onClick={() => { onClickBack() }} className='btn btn-sm btn-primary'>Back</button>
                            </section>
                        </header>

                        <section className="card-header" id="section-record-count">
                            <div className="card-title ">
                                <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                            </div>
                        </section>

                        <section className="card-body" style={{ padding: '0px', margin: '0px' }}>
                        
                            <IMTablePromissoryNote tableProps={tableProps} />
                        </section>
                    </main>
                </div>
            }
        </>
    )
}