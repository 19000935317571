import React, { useState, useEffect } from 'react'
import ProfileCover from "../../../../../_metronic/_assets/logo/profileCover.jpg"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import { useParams } from "react-router-dom";
import {
    getStudentMasterIndividualData, getCivilStatus, getReligion, getCountry, getProvince,
    getMunicipality, getCourses, getYearLevel, getSchool, getFamilyPositions, getNationality,
    updateApplicants, addUpdateStudentSiblings, getImage, getSignature, isImageSignatureApproved,
    sendMail
} from "./__hooks__"
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import moment from 'moment';
import PrintStudent201 from './PrintStudent201';
import CheckUploadedImageModal from '../../../../../_metronic/layout/components/custom/modal/CheckUploadedImageModal';

function convertKeysToCamelCase(obj) {
    const camelCaseObj = {};
    for (const key in obj) {
        if (Object.prototype.hasOwnProperty.call(obj, key)) {
            const camelCaseKey = key.charAt(0).toLowerCase() + key.slice(1);
            camelCaseObj[camelCaseKey] = obj[key];
        }
    }
    return camelCaseObj;
}

export default function StudentMasterFileDetails(props) {
    const { state: { auth }, dispatch } = useAppContext();
    const [data, setData] = useState()
    const [student, setStudent] = useState(null)
    const [imagePreview, setImagePreview] = useState(null)
    const [signaturePreview, setSignaturePreview] = useState(null)
    const { id } = useParams()
    const { setValue } = useForm()
    const [isOpenCheckUpload, setIsIpenCheckUpload] = useState(false)

    // select

    const [civilStatusSelect, setCivilStatusSelect] = useState(null)
    const [religionSelect, setReligionSelect] = useState(null)
    const [countrySelect, setCountrySelect] = useState(null)
    const [provinceSelect, setProvinceSelect] = useState(null)
    const [municipalitySelect, setMunicipalitySelect] = useState(null)
    const [courseSelect, setCourseSelect] = useState(null)
    const [yearLevelSelect, setYearLevelSelect] = useState(null)
    const [schoolSelect, setSchoolSelect] = useState(null)
    const [familyPositionSelect, setFamilyPositionSelect] = useState(null)
    const [nationalitySelect, setNationalitySelect] = useState(null)
    const [studentSiblingIndex, setStudentSiblingIndex] = useState([0])


    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + ' ' + time;


    const onUpdateStudentDetails = async () => {
        console.log(data)
        await updateApplicants({ ...convertKeysToCamelCase(data), createdBy: auth.data.Username, dateUpdated: moment(dateTime).format('YYYY-MM-DD'), dateCreated: moment(data.DateCreated).format('YYYY-MM-DD'), updatedBy: auth.data.Username, studentTypeId: null })
            .then(async res => {
                let studentSiblings = data.StudentSiblings || [];
                let siblingData = [];
                let updatedSiblingData = [];
                let siblingsMap = {};
                console.log(data)
                for (let key in data) {
                    if (key.startsWith("sibling") && data[key]) {
                        let parts = key.split("_");
                        let identifier = parts[1];
                        console.log(parts)
                        if (!siblingData[identifier]) {
                            siblingData[identifier] = {};
                        }
                        let property = parts[0].substring("sibling".length);
                        siblingData[identifier][property] = data[key];
                        siblingData[identifier].createdBy = auth.data.Username
                        siblingData[identifier].studentInfoId = res.data.StudentInfoId
                    }
                }
                for (const key in data) {
                    if (key.startsWith('StudentSiblings_')) {
                        const parts = key.split('_'); // Split the key into parts
                        const index = parts[1]; // Second part is the index
                        const prop = parts.slice(2).join('_'); // Join the remaining parts to form the property name

                        // Initialize the sibling object if it doesn't exist
                        if (!siblingsMap[index]) {
                            siblingsMap[index] = {};
                        }

                        // Add the property to the sibling object
                        siblingsMap[index][prop] = data[key];
                        siblingsMap[index].studentSiblingId = index
                        siblingsMap[index].studentInfoId = data.StudentInfoId
                    }
                }
                for (const index in siblingsMap) {
                    updatedSiblingData.push(siblingsMap[index]);
                }
                const updatedStudentSiblings = studentSiblings.map(sibling => {
                    return { ...sibling, studentInfoId: res.data.StudentInfoId };
                });
                console.log(updatedStudentSiblings)
                console.log(siblingData)
                console.log(updatedSiblingData)
                let filteredNewSiblingArray = siblingData.filter(item => item !== null);
                let filteredUpdatedSiblingArray = updatedSiblingData.filter(item => item !== null);

                await addUpdateStudentSiblings([...filteredUpdatedSiblingArray, ...filteredNewSiblingArray])
                    .then(async resp => {
                        await getStudentMasterIndividualData({ studentId: id })
                            .then(response => {
                                setStudent(response.data)
                                setData(response.data)
                            })
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Student Details Updated' });

                    })
                    .catch(e => {
                        console.log(e)
                    })
            })
    }


    const selectHandleChangeInput = name => event => {
        setData(prevData => {
            return { ...prevData, [name]: event.target.value };

        });
    };

    const selectHandleChange = name => event => {
        setData({ ...data, [name]: event })

    }

    const onHandleSignatureApproval = async (con) => {
        await isImageSignatureApproved({ studentId: id, isApproved: con })
            .then(async resp => {
                setIsIpenCheckUpload(false)
                if (con == 1) {
                    await getStudentMasterIndividualData({ studentId: id })
                        .then(response => {
                            setStudent(response.data)
                            setData(response.data)
                        })
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Uploaded Image/Signature Approved' });

                } else if (con == 0) {
                    await getStudentMasterIndividualData({ studentId: id })
                        .then(response => {
                            setStudent(response.data)
                            setData(response.data)
                        })
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Uploaded Image/Signature Disapproved' });

                }
            })
    }



    useEffect(() => {
        getStudentMasterIndividualData({ studentId: id })
            .then(async res => {
                await getImage(res.data.StudentNo)
                    .then(resp => {
                        console.log(resp)
                        setImagePreview(resp)
                    })
                    .catch(e => {
                        return
                    })
                await getSignature(res.data.StudentNo)
                    .then(resp => {
                        console.log(resp)
                        setSignaturePreview(resp)
                    })
                    .catch(e => {
                        return
                    })
                setStudent(res.data)
                setData(res.data)
                setStudentSiblingIndex([res.data.StudentSiblings.length])
            })


        SelectCreator(getCivilStatus, setCivilStatusSelect, 'CivilStatusId', 'CivilStatus')
        SelectCreator(getReligion, setReligionSelect, 'ReligionId', 'Religion')
        SelectCreator(getCountry, setCountrySelect, 'CountryId', 'Country')
        SelectCreator(getProvince, setProvinceSelect, 'ProvinceId', 'Province')
        SelectCreator(getMunicipality, setMunicipalitySelect, 'MunicipalityId', 'Municipality')
        SelectCreator(getFamilyPositions, setFamilyPositionSelect, 'FamilyPositionId', 'Description')
        SelectCreator(getNationality, setNationalitySelect, 'NationalityId', 'Nationality')
        SelectCreator(getCourses, setCourseSelect, 'CourseId', 'Description')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description', null, true)
        SelectCreator(getSchool, setSchoolSelect, 'SchoolId', 'School')

    }, [])




    console.log(civilStatusSelect)
    console.log(religionSelect)
    console.log(countrySelect)
    console.log(provinceSelect)
    console.log(municipalitySelect)
    console.log(courseSelect)
    console.log(yearLevelSelect)
    console.log(schoolSelect)
    console.log(familyPositionSelect)


    console.log(data)
    console.log(student)

    return (
        <>
            {student != null && data != undefined ?
                <>
                    <CheckUploadedImageModal
                        studentNo={student.StudentNo}
                        isOpen={isOpenCheckUpload}
                        handleClose={() => setIsIpenCheckUpload(false)}
                        image={imagePreview}
                        signature={signaturePreview}
                        isEdit={student.IsUploadImageSig == 1 && student.IsUploadImageSigApp == 1}
                        handleApprove={() => { onHandleSignatureApproval(1) }}
                        handleDisapprove={() => { sendMail({ emailAddress: student.Email, isDeclineImageSignature: 1 }); onHandleSignatureApproval(0); }}
                    />
                    <main className="row" >
                        <section className="col-lg-12 col-xl-3" >
                            <div className="card" style={{ height: '20%' }}>
                                <img className="card-img-top" src={ProfileCover} alt="Bologna" style={{ height: '200px', overflow: 'hidden' }} />
                                <div className="card-body bg-light">
                                    <section className='text-center'>
                                        <div style={{ position: 'relative', display: 'inline-block' }}>
                                            <img className="avatar rounded-circle" src={(student.IsUploadImageSigApp == 1 && imagePreview) || ProfileLogo} alt="Bologna" style={{ zIndex: '1', borderRadius: '50%', width: '100px', height: '100px' }} />
                                            {student.IsUploadImageSig == 1 && student.IsUploadImageSigApp == 1 &&
                                                <button onClick={() => { setIsIpenCheckUpload(true) }} className='btn btn-secondary btn-sm' style={{ position: 'absolute', bottom: '-10px', right: '-10px', borderRadius: '50%', zIndex: '2' }}>
                                                    <i className="fa fa-pencil-alt p-0"></i>
                                                </button>
                                            }
                                        </div>
                                        <h3 className="card-title mb-0">{ }</h3>
                                        <p className="card-text mt-0">{ }</p>
                                        <h3 className="card-title mb-0">{student.StudentName}</h3>
                                        <p className="card-text mt-0 mb-0">{student.CourseDesc}</p>
                                        <p className='lead'>{student.StudentNo}</p>
                                        {student.IsUploadImageSig == 1 && student.IsUploadImageSigApp == 0 &&
                                            <div style={{ borderRadius: '5px' }} className="p-2 bg-secondary">
                                                <i className="fa fa-info text-primary"></i> &nbsp; This student has uploaded an image and signature and is awaiting for approval.
                                                <button onClick={() => { setIsIpenCheckUpload(true) }} className='btn btn-link'>Check Uploaded Image/Signature</button>
                                            </div>
                                        }

                                    </section>

                                    <hr />
                                    {student != null &&
                                        <>

                                            <IMSelect
                                                onHandleChange={selectHandleChange('StudentStatusId')}
                                                refClassContainer="form-group"
                                                name="isIrregular"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Status"
                                                placeHolder="Student Status"
                                                forwardRef={setValue}
                                                selectedId={data.StudentStatusId === null ? student.StudentStatusId : data.StudentStatusId}
                                                refClassName={``}
                                                withDescription={false}
                                                description={``}
                                                refDisabled={true}
                                                refIsStatic={true}
                                                refStaticData={[
                                                    { id: 1, label: 'Regular' },
                                                    { id: 2, label: 'Returnee' },
                                                    { id: 3, label: 'Cross Enrollee' },
                                                    { id: 4, label: 'Eteapp' },
                                                    { id: 5, label: 'Transferee' },
                                                    { id: 7, label: 'Irregular' },
                                                    { id: 8, label: 'Masteral' },
                                                ]}
                                                field={{
                                                    tableId: '',
                                                    display: ''
                                                }}

                                            />


                                            <button onClick={() => { onUpdateStudentDetails() }} className='btn btn-sm btn-success mr-5'>Save Changes</button>

                                            <PrintStudent201
                                                results={student}
                                            />
                                        </>


                                    }


                                </div>
                            </div>
                        </section>
                        {civilStatusSelect != null && religionSelect != null && countrySelect != null && provinceSelect != null && municipalitySelect != null &&
                            courseSelect != null && yearLevelSelect != null && schoolSelect != null && familyPositionSelect != null
                            &&
                            <><section className="col-lg-12 col-xl-9" >
                                <div className="card">

                                    <div className="card-body">
                                        <div className="card-body">


                                            <>
                                                <section className="kt-form">

                                                    <div className="kt-portlet__body ">
                                                        <h4><i className="fa fa-child icon-xl ml-auto"></i> &nbsp; Basic Information</h4>

                                                        <hr />

                                                        <div className="row">

                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Last Name <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('LastName')}
                                                                    type="text"
                                                                    name="LastName"
                                                                    defaultValue={student.LastName}
                                                                    className={`form-control`}

                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />



                                                            </div>
                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">First Name <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('FirstName')}
                                                                    type="text"
                                                                    name="FirstName"
                                                                    defaultValue={student.FirstName}
                                                                    className={`form-control `}

                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />


                                                            </div>
                                                            <div className="form-group col-12 col-md-4 mb-3">
                                                                <label className="col-form-label p-0">Middle Name <span className="text-danger">*</span> </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('MiddleName')}
                                                                    type="text"
                                                                    name="MiddleName"
                                                                    defaultValue={student.MiddleName}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>


                                                        </div>

                                                        <div className="row">
                                                            <div className="form-group col mb-3">
                                                                <IMSelect
                                                                    data={schoolSelect}
                                                                    onHandleChange={selectHandleChange('SchoolId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="lastSchoolName"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Last School Attended"
                                                                    placeHolder="Last School Attended"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.SchoolId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />

                                                                {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                                                            </div>

                                                            {(student.SchoolId == null || student.SchoolId == -1) &&
                                                                <div className="form-group col">
                                                                    <label className="col-form-label p-0">Last School (If not Listed)<span className="text-danger">*</span>  </label>
                                                                    <input
                                                                        onChange={selectHandleChangeInput('LastSchoolAttendedNotListed')}
                                                                        type="text"
                                                                        name="lastSchoolAttendedNotListed"
                                                                        defaultValue={student.LastSchoolAttendedNotListed}
                                                                        className={`form-control mt-2`}
                                                                        ref={() => { }}
                                                                        autoComplete="off"
                                                                        maxLength="100"

                                                                    />
                                                                </div>
                                                            }

                                                            <div className='col mb-0'>
                                                                <IMSelect
                                                                    data={civilStatusSelect}
                                                                    onHandleChange={selectHandleChange('CivilStatusId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="civilStatusId"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Civil Status"
                                                                    placeHolder="Civil Status"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.CivilStatusId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />
                                                            </div>
                                                            <div className='col mb=0'>
                                                                <IMSelect
                                                                    data={religionSelect}
                                                                    onHandleChange={selectHandleChange('ReligionId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="religion"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Religion"
                                                                    placeHolder="Religion"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.ReligionId}
                                                                    refClassName={``}

                                                                    withDescription={false}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />
                                                            </div>
                                                            <div className="form-group col mb-3">
                                                                <label className="col-form-label p-0">Date of Birth <span className="text-danger">*</span> </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('BirthDate')}
                                                                    type="date"
                                                                    name="birthDate"
                                                                    defaultValue={student.BirthDate}
                                                                    className={`form-control `}


                                                                />

                                                            </div>

                                                        </div>
                                                        <div className='row'>

                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Place of Birth <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('BirthPlace')}
                                                                    type="text"
                                                                    name="birthPlace"
                                                                    defaultValue={student.BirthPlace}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />
                                                            </div>

                                                            <div className='col-12 col-md-3 mb-0'>
                                                                <IMSelect
                                                                    data={familyPositionSelect}
                                                                    onHandleChange={selectHandleChange('FamilyPositionId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="familyPositionId"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Position in the Family"
                                                                    placeHolder="Position in the Family"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.FamilyPositionId}
                                                                    refClassName={``}

                                                                    withDescription={false}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </div>

                                                            <div className='col-12 col-md-3 mb=0'>
                                                                <IMSelect

                                                                    data={yearLevelSelect}
                                                                    onHandleChange={selectHandleChange('YearLevelId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="yearLevel"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Year Level"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.YearLevelId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[


                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className='col-12 col-md-3 mb-0'>
                                                                <IMSelect
                                                                    onHandleChange={selectHandleChange('Gender')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="gender"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Gender"
                                                                    placeHolder="Gender"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.Gender}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={true}
                                                                    refStaticData={[
                                                                        { id: '', label: '' },
                                                                        { id: 'M', label: 'Male' },
                                                                        { id: 'F', label: 'Female' }
                                                                    ]}
                                                                    field={{
                                                                        tableId: '',
                                                                        display: ''
                                                                    }}

                                                                />
                                                            </div>

                                                        </div>
                                                        <div className='row'>


                                                            <div className='col-12 col-md-3 mb=0'>
                                                                <IMSelect
                                                                    data={courseSelect}
                                                                    onHandleChange={selectHandleChange('CourseId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="coursese"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Course"
                                                                    placeHolder=""
                                                                    forwardRef={setValue}
                                                                    selectedId={data.CourseId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </div>




                                                            {/* <div className='col-12 col-md-3 mb=0'></div> */}






                                                        </div>


                                                        <br /> <br />
                                                        <h4><i className="fa fa-id-card icon-xl ml-auto"></i> &nbsp; Contact Information</h4>

                                                        <hr />
                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Landline #<span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('TelNo')}
                                                                    type="text"
                                                                    name="landline"
                                                                    defaultValue={student.TelNo}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Mobile # <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('CellNo')}
                                                                    type="text"
                                                                    name="mobile"
                                                                    defaultValue={student.CellNo}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Email <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('EmailAddress')}
                                                                    type="email"
                                                                    name="emailAddress"
                                                                    defaultValue={student.EmailAddress}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Facebook <span className="text-info">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('Facebook')}
                                                                    type="text"
                                                                    name="facebook"
                                                                    defaultValue={student.Facebook}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                        </div>

                                                        <div className='row'>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Twitter / X <span className="text-info">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('Twitter')}
                                                                    type="text"
                                                                    name="twitter"
                                                                    defaultValue={student.TwitterX}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Instagram <span className="text-info">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('Instagram')}
                                                                    type="text"
                                                                    name="instagram"
                                                                    defaultValue={student.Instagram}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={countrySelect}
                                                                    onHandleChange={selectHandleChange('CountryId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="countryId"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Country"
                                                                    placeHolder="Country"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.CountryId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={provinceSelect}
                                                                    onHandleChange={selectHandleChange('ProvinceId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="provinceId"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Province"
                                                                    placeHolder="Province"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.ProvinceId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </div>
                                                        </div>

                                                        <div className='row'>

                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <IMSelect
                                                                    data={municipalitySelect}
                                                                    onHandleChange={selectHandleChange('MunicipalityId')}
                                                                    refClassContainer="form-group mb-3"
                                                                    name="municipalityId"
                                                                    isRequired={true}
                                                                    withLabel={true}
                                                                    label="Municipality"
                                                                    placeHolder="Municipality"
                                                                    forwardRef={setValue}
                                                                    selectedId={data.MunicipalityId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </div>
                                                            <div className="form-group col-12 col-md-3 mb-3">
                                                                <label className="col-form-label p-0">Barangay <span className="text-danger">*</span>  </label>
                                                                <input
                                                                    onChange={selectHandleChangeInput('Barangay')}
                                                                    type="text"
                                                                    name="barangay"
                                                                    defaultValue={student.Barangay}
                                                                    className={`form-control `}
                                                                    autoComplete="off"
                                                                    maxLength="100"

                                                                />

                                                            </div>




                                                        </div>




                                                    </div>

                                                    <div className="kt-portlet__body mt-5">
                                                        <h4><i className="fas fa-users icon-xl"></i> Family Background</h4> <hr />

                                                        <div className={'card mr-2'}  >

                                                            <h5 className="card-header bg-secondary p-5 is-invalid">{'Father\'s Information'}</h5>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>




                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Full Name <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('FatherName')}
                                                                            type="text"
                                                                            name="fatherName"
                                                                            defaultValue={student.FatherName}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('FatherBusinessName')}
                                                                            type="text"
                                                                            name="fatherOccupation"
                                                                            defaultValue={student.FatherBusinessName}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>


                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Occupation Address <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('FatherBusinessAddress')}
                                                                            type="text"
                                                                            name="fatherOccupationAddress"
                                                                            defaultValue={student.FatherBusinessAddress}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-6 mb-3">
                                                                        <label className="col-form-label p-0">Contact# <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('FatherBusinessContactNo')}
                                                                            type="text"
                                                                            name="fatherContactNumber"
                                                                            defaultValue={student.FatherBusinessContactNo}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>
                                                                    <div className="form-group col-12 col-md-6 mb-3">
                                                                        <label className="col-form-label p-0">Home Address <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('FatherHomeAddress')}
                                                                            type="text"
                                                                            name="fatherHomeAddress"
                                                                            defaultValue={student.FatherHomeAddress}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                </div>
                                                                <div className='row'>

                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            data={civilStatusSelect}
                                                                            onHandleChange={selectHandleChange('FatherMaritalStatus')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="fatherMaritalStatus"
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Marital Status"
                                                                            placeHolder="Marital Status"
                                                                            forwardRef={setValue}
                                                                            selectedId={data.FatherMaritalStatus}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            data={nationalitySelect}
                                                                            onHandleChange={selectHandleChange('FatherCitizen')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="fatherCitizenship"
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Citizenship"
                                                                            placeHolder="Citizenship"
                                                                            forwardRef={setValue}
                                                                            selectedId={data.FatherCitizen}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange(`FatherHighestEducationalAttainment`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`fatherHighestEducationalAttainment`}
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Highest Educational Attainment"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.FatherHighestEducationalAttainment}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 1, label: 'Preschool' },
                                                                                { id: 2, label: 'Elementary' },
                                                                                { id: 3, label: 'Highschool' },
                                                                                { id: 4, label: 'Bachelor Degree' },
                                                                                { id: 5, label: 'Masteral Degree' },
                                                                                { id: 6, label: 'Doctoral Degree' }


                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>



                                                        <div className={'card mt-5 mr-2'}  >

                                                            <h5 className="card-header bg-secondary p-5 is-invalid">{'Mother\'s Information'}</h5>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>




                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Full Name <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('MotherName')}
                                                                            type="text"
                                                                            name="motherName"
                                                                            defaultValue={student.MotherName}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('MotherBusinessName')}
                                                                            type="text"
                                                                            name="motherOccupation"
                                                                            defaultValue={student.MotherBusinessName}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>


                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Occupation Address <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('MotherBusinessAddress')}
                                                                            type="text"
                                                                            name="motherOccupationAddress"
                                                                            defaultValue={student.MotherBusinessAddress}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>
                                                                </div>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-6 mb-3">
                                                                        <label className="col-form-label p-0">Contact# <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('MotherBusinessContactNo')}
                                                                            type="text"
                                                                            name="motherContactNumber"
                                                                            defaultValue={student.MotherBusinessContactNo}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>
                                                                    <div className="form-group col-12 col-md-6 mb-3">
                                                                        <label className="col-form-label p-0">Home Address <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('MotherHomeAddress')}
                                                                            type="text"
                                                                            name="motherHomeAddress"
                                                                            defaultValue={student.FatherHomeAddress}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                </div>
                                                                <div className='row'>

                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            data={civilStatusSelect}
                                                                            onHandleChange={selectHandleChange('MotherMaritalStatus')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="motherMaritalStatus"
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Marital Status"
                                                                            placeHolder="Marital Status"
                                                                            forwardRef={setValue}
                                                                            selectedId={data.MotherMaritalStatus}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            data={nationalitySelect}
                                                                            onHandleChange={selectHandleChange('MotherCitizen')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="motherCitizen"
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Citizenship"
                                                                            placeHolder="Citizenship"
                                                                            forwardRef={setValue}
                                                                            selectedId={data.MotherCitizen}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className='col-12 col-md-4 mb-0'>
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange(`MotherHighestEducationalAttainment`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`motherHighestEducationalAttainment`}
                                                                            isRequired={false}
                                                                            withLabel={true}
                                                                            label="Highest Educational Attainment"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.MotherHighestEducationalAttainment}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 1, label: 'Preschool' },
                                                                                { id: 2, label: 'Elementary' },
                                                                                { id: 3, label: 'Highschool' },
                                                                                { id: 4, label: 'Bachelor Degree' },
                                                                                { id: 5, label: 'Masteral Degree' },
                                                                                { id: 6, label: 'Doctoral Degree' }


                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />

                                                                    </div>

                                                                </div>
                                                            </div>

                                                        </div>



                                                        {student.StudentSiblings != null && student.StudentSiblings != undefined && student.StudentSiblings.length > 0 && student.StudentSiblings.map((d, index) => {
                                                            console.log(d)
                                                            console.log(student.StudentSiblings.length)
                                                            return (
                                                                <>
                                                                    <div className={`card mr-2 mb-5  ${index == student.StudentSiblings.length - 1 ? 'slide-inStudentForm' : ''}`}  >
                                                                        <section className='card-header bg-secondary p-5 is-invalid'>

                                                                            <h5 className="float-left">{`Sibling #${index + 1} Information`}</h5>
                                                                            {/* {d == studentSiblingIndex.length - 1 &&
                                                <button type='button' onClick={() => { setStudentSiblingIndex((prevData) => { return [...prevData, d + 1] }) }} className='btn btn-primary btn-sm float-right'>Add more Sibling</button>

                                            } */}
                                                                        </section>
                                                                        <div className='card-body p-3'>
                                                                            <div className='row'>




                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">Full Name   </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_name`)}
                                                                                        type="text"
                                                                                        name={`name`}
                                                                                        defaultValue={student.StudentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).name}
                                                                                        className={`form-control mt-2 `}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>



                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">School / Company  </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_affiliation`)}
                                                                                        type="text"
                                                                                        name={`affiliation`}
                                                                                        defaultValue={student.StudentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).affiliation}
                                                                                        className={`form-control mt-2 `}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>

                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">Contact#  </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_contactNo`)}
                                                                                        type="number"
                                                                                        name={`contactNo`}
                                                                                        defaultValue={student.StudentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).contactNo}
                                                                                        className={`form-control mt-2`}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>



                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">Age  </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_age`)}
                                                                                        type="text"
                                                                                        name={`age`}
                                                                                        defaultValue={student.StudentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).age}
                                                                                        className={`form-control mt-2 `}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>
                                                                                <div className='col-12 col-md-4 mb-0'>
                                                                                    <IMSelect
                                                                                        data={civilStatusSelect}
                                                                                        onHandleChange={selectHandleChange(`StudentSiblings_${d.studentSiblingId}_civilStatusId`)}
                                                                                        refClassContainer="form-group mb-3"
                                                                                        name={`civilStatusId`}
                                                                                        isRequired={false}
                                                                                        withLabel={true}
                                                                                        label="Marital Status"
                                                                                        placeHolder="Marital Status"
                                                                                        forwardRef={setValue}
                                                                                        selectedId={!data[`StudentSiblings_${d.studentSiblingId}_civilStatusId`] ? data.StudentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId) != undefined ? data.StudentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).civilStatusId : data[`StudentSiblings_${d.studentSiblingId}_civilStatusId`] : ''}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[

                                                                                        ]}
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}

                                                                                    />
                                                                                </div>
                                                                                <div className='col-12 col-md-4 mb-0'>
                                                                                    <IMSelect

                                                                                        data={yearLevelSelect}
                                                                                        onHandleChange={selectHandleChange(`StudentSiblings_${d.studentSiblingId}_yearLevelId`)}
                                                                                        refClassContainer="form-group mb-3"
                                                                                        name={`yearLevelId`}
                                                                                        isRequired={false}
                                                                                        withLabel={true}
                                                                                        label="Year Level"
                                                                                        placeHolder=""
                                                                                        forwardRef={setValue}
                                                                                        selectedId={!data[`StudentSiblings_${d.studentSiblingId}_yearLevelId`] ? d.yearLevelId : data[`StudentSiblings_${d.studentSiblingId}_yearLevelId`]}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[


                                                                                        ]}
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })}

                                                        {studentSiblingIndex.map(d => {
                                                            console.log(d)
                                                            console.log(studentSiblingIndex.length)
                                                            return (
                                                                <>
                                                                    <div className={`card mr-2 mb-5  ${d == studentSiblingIndex.length - 1 ? 'slide-inStudentForm' : ''}`}  >
                                                                        <section className='card-header bg-secondary p-5 is-invalid'>

                                                                            <h5 className="float-left">{`Sibling #${d + 1} Information`}</h5>

                                                                            <button type='button' onClick={() => { setStudentSiblingIndex((prevData) => { return [...prevData, d + 1] }) }} className='btn btn-primary btn-sm float-right'>Add more Sibling</button>


                                                                        </section>
                                                                        <div className='card-body p-3'>
                                                                            <div className='row'>




                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">Full Name   </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`siblingname_${d}`)}
                                                                                        type="text"
                                                                                        name={`siblingname_${d}`}
                                                                                        defaultValue={data[`siblingname_${d}`]}
                                                                                        className={`form-control mt-2 `}
                                                                                        ref={() => { }}
                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>



                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">School / Company  </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`siblingaffiliation_${d}`)}
                                                                                        type="text"
                                                                                        name={`siblingaffiliation_${d}`}
                                                                                        defaultValue={data[`siblingaffiliation_${d}`]}
                                                                                        className={`form-control mt-2 `}
                                                                                        ref={() => { }}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>

                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">Contact#  </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`siblingcontactNo_${d}`)}
                                                                                        type="number"
                                                                                        name={`siblingcontactNo_${d}`}
                                                                                        defaultValue={data[`siblingcontactNo_${d}`]}
                                                                                        className={`form-control mt-2`}
                                                                                        ref={() => { }}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>



                                                                            </div>
                                                                            <div className='row'>
                                                                                <div className="form-group col-12 col-md-4 mb-3">
                                                                                    <label className="col-form-label p-0">Age  </label>
                                                                                    <input
                                                                                        onChange={selectHandleChangeInput(`siblingage_${d}`)}
                                                                                        type="text"
                                                                                        name={`siblingage_${d}`}
                                                                                        defaultValue={data[`siblingage_${d}`]}
                                                                                        className={`form-control mt-2 `}
                                                                                        ref={() => { }}

                                                                                        autoComplete="off"
                                                                                        maxLength="100"

                                                                                    />

                                                                                </div>
                                                                                <div className='col-12 col-md-4 mb-0'>
                                                                                    <IMSelect
                                                                                        data={civilStatusSelect}
                                                                                        onHandleChange={selectHandleChange(`siblingcivilStatusId_${d}`)}
                                                                                        refClassContainer="form-group mb-3"
                                                                                        name={`siblingcivilStatusId_${d}`}
                                                                                        isRequired={false}
                                                                                        withLabel={true}
                                                                                        label="Marital Status"
                                                                                        placeHolder="Marital Status"
                                                                                        forwardRef={setValue}
                                                                                        selectedId={data[`siblingcivilStatusId_${d}`]}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[

                                                                                        ]}
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}

                                                                                    />
                                                                                </div>
                                                                                <div className='col-12 col-md-4 mb-0'>
                                                                                    <IMSelect

                                                                                        data={yearLevelSelect}
                                                                                        onHandleChange={selectHandleChange(`siblingyearLevelId_${d}`)}
                                                                                        refClassContainer="form-group mb-3"
                                                                                        name={`siblingyearLevelId_${d}`}
                                                                                        isRequired={false}
                                                                                        withLabel={true}
                                                                                        label="Year Level"
                                                                                        placeHolder=""
                                                                                        forwardRef={setValue}
                                                                                        selectedId={data[`siblingyearLevelId_${d}`]}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[


                                                                                        ]}
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}
                                                                                    />
                                                                                </div>

                                                                            </div>

                                                                        </div>

                                                                    </div>
                                                                </>
                                                            )
                                                        })}

                                                    </div>
                                                    <div className="kt-portlet__body my-5">
                                                        <div className={'card mr-2'}  >
                                                            <div className='card-header bg-secondary pt-4 pl-5 pr-5 pb-0 is-invalid '>
                                                                <h5 className="mr-5 mb-0">Guardian</h5>

                                                            </div>

                                                            <div className='card-body p-3'>

                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Guardian <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            type="text"
                                                                            name="contactPerson"
                                                                            defaultValue={student.ContactPerson}
                                                                            className={`form-control `}
                                                                            onChange={selectHandleChangeInput('ContactPerson')}
                                                                            autoComplete="off"
                                                                            maxLength="100"
                                                                        />


                                                                    </div>
                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Address<span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            type="text"
                                                                            name="contactPersonAddress"
                                                                            defaultValue={student.ContactPersonAddress}
                                                                            className={`form-control`}
                                                                            onChange={selectHandleChangeInput('ContactPersonAddress')}
                                                                            autoComplete="off"
                                                                            maxLength="100"
                                                                        />

                                                                    </div>
                                                                    <div className="form-group col-12 col-md-4 mb-3">
                                                                        <label className="col-form-label p-0">Contact No/s<span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            type="number"
                                                                            name="contactPersonCelNo"
                                                                            defaultValue={student.ContactPersonCelNo}
                                                                            className={`form-control`}
                                                                            onChange={selectHandleChangeInput('ContactPersonCelNo')}
                                                                            autoComplete="off"
                                                                            maxLength="100"
                                                                        />

                                                                    </div>
                                                                    <div className="form-group col-12 col-md-4 mb-5">
                                                                        <label className="col-form-label p-0">Relationship <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            type="text"
                                                                            name="relationshipGuardian"
                                                                            defaultValue={student.ContactPersonRelationship}
                                                                            className={`form-control `}
                                                                            onChange={selectHandleChangeInput('ContactPersonRelationship')}
                                                                            autoComplete="off"
                                                                            maxLength="100"
                                                                        />

                                                                    </div>
                                                                    <div className="form-group col-12 col-md-4 mb-5">
                                                                        <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            type="text"
                                                                            name="guardianOccupation"
                                                                            defaultValue={student.ContactPersonOccupation}
                                                                            className={`form-control `}
                                                                            onChange={selectHandleChangeInput('GuardianOccupation')}
                                                                            autoComplete="off"
                                                                            maxLength="100"
                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-4 mb-5">
                                                                        <label className="col-form-label p-0">Family Monthly Income <span className="text-danger">*</span>  </label>
                                                                        <div class="input-group mb-3">
                                                                            <div class="input-group-prepend">
                                                                                <span class="input-group-text" id="basic-addon1">₱</span>
                                                                            </div>
                                                                            <input
                                                                                type="number"
                                                                                name="familyMonthlyIncome"
                                                                                defaultValue={student.FamilyMonthlyIncome}
                                                                                className={`form-control `}
                                                                                onChange={selectHandleChangeInput('FamilyMonthlyIncome')}
                                                                                autoComplete="off"
                                                                                maxLength="100"
                                                                            />
                                                                        </div>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>



                                                    </div>

                                                    <div className="kt-portlet__body mt-5">
                                                        <h4><i className="fas fa-user-graduate icon-xl"></i> Educational Background</h4> <hr />
                                                        {/* PRE ELEM */}
                                                        <div className='card mt-5'>

                                                            <h5 className="card-header bg-secondary p-5">Pre - Elementary</h5>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            data={schoolSelect}
                                                                            onHandleChange={selectHandleChange(`PreElementary`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`preElementary`}
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="School Name"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.PreElementary}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />



                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                        <input
                                                                            disabled={data.PreElementary == null ? false : true}
                                                                            onChange={selectHandleChangeInput('PreElemNotListed')}
                                                                            type="text"
                                                                            name="preElementaryNotListedSchool"
                                                                            defaultValue={student.PreElemNotListedSchool}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('PreElemTo')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="preElemTo"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="From"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.PreElemTo}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('PreElemFrom')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="preElemFrom"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="To"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.PreElemFrom}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* ELEM */}
                                                        <div className='card mt-5'>

                                                            <h5 className="card-header bg-secondary p-5">Elementary</h5>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            data={schoolSelect}
                                                                            onHandleChange={selectHandleChange(`Elementary`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`elementary`}
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="School Name"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.Elementary}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />



                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                        <input
                                                                            disabled={data.Elementary == null ? false : true}
                                                                            onChange={selectHandleChangeInput('ElemNotListed')}
                                                                            type="text"
                                                                            name="elementaryNotListedSchool"
                                                                            defaultValue={student.ElemNotListedSchool}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('ElemFrom')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="elemFrom"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="From"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.ElemFrom}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('ElemTo')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="elemTo"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="To"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.ElemTo}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* JR HIGH */}
                                                        <div className='card mt-5'>

                                                            <h5 className="card-header bg-secondary p-5">Junior High</h5>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            data={schoolSelect}
                                                                            onHandleChange={selectHandleChange(`JrHigh`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`jrHigh`}
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="School Name"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.JrHigh}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />



                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                        <input
                                                                            disabled={data.JrHigh == null ? false : true}
                                                                            onChange={selectHandleChangeInput('JrHighNotListed')}
                                                                            type="text"
                                                                            name="juniorHighNotListedSchool"
                                                                            defaultValue={student.JrHighNotListedSchool}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('JrFrom')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="jrFrom"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="From"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.JrFrom}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('JrTo')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="jrTo"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="To"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.JrTo}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>

                                                        {/* SR HIGH */}
                                                        <div className='card mt-5'>

                                                            <h5 className="card-header bg-secondary p-5">Senior High</h5>
                                                            <div className='card-body p-3'>
                                                                <div className='row'>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            data={schoolSelect}
                                                                            onHandleChange={selectHandleChange(`SrHigh`)}
                                                                            refClassContainer="form-group mb-3"
                                                                            name={`srHigh`}
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="School Name"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.SrHigh}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={false}
                                                                            refStaticData={[

                                                                            ]}
                                                                            field={{
                                                                                tableId: 'tableId',
                                                                                display: 'code'
                                                                            }}
                                                                        />



                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                                                                        <input
                                                                            disabled={data.SrHigh == null ? false : true}
                                                                            onChange={selectHandleChangeInput('SrHighNotListed')}
                                                                            type="text"
                                                                            name="seniorHighNotListedSchool"
                                                                            defaultValue={student.SrHighNotListedSchool}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>

                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('SrFrom')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="srFrom"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="From"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.SrFrom}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-12 col-md-3 mb-3">
                                                                        <IMSelect
                                                                            onHandleChange={selectHandleChange('SrTo')}
                                                                            refClassContainer="form-group mb-3"
                                                                            name="srTo"
                                                                            isRequired={true}
                                                                            withLabel={true}
                                                                            label="To"
                                                                            placeHolder=""
                                                                            forwardRef={setValue}
                                                                            selectedId={data.SrTo}
                                                                            refClassName={``}
                                                                            withDescription={false}
                                                                            // description={`Please select your gender.`}
                                                                            refDisabled={true}
                                                                            refIsStatic={true}
                                                                            refStaticData={[
                                                                                { id: '', label: '' },
                                                                                { id: 2023, label: '2023' },
                                                                                { id: 2022, label: '2022' },
                                                                                { id: 2021, label: '2021' },
                                                                                { id: 2020, label: '2020' },
                                                                                { id: 2019, label: '2019' },
                                                                                { id: 2018, label: '2018' },
                                                                                { id: 2017, label: '2017' },
                                                                                { id: 2016, label: '2016' },
                                                                                { id: 2015, label: '2015' },
                                                                                { id: 2014, label: '2014' },
                                                                                { id: 2013, label: '2013' },
                                                                                { id: 2012, label: '2012' },
                                                                                { id: 2011, label: '2011' },
                                                                                { id: 2010, label: '2010' },
                                                                                { id: 2009, label: '2009' },
                                                                                { id: 2008, label: '2008' },
                                                                            ]}
                                                                            field={{
                                                                                tableId: '',
                                                                                display: ''
                                                                            }}
                                                                        />
                                                                    </div>
                                                                    <div className="form-group col-12 col-md-12 mb-3">
                                                                        <label className="col-form-label p-0">Track / Strand <span className="text-danger">*</span>  </label>
                                                                        <input
                                                                            onChange={selectHandleChangeInput('SeniorHighStrand')}
                                                                            type="text"
                                                                            name="seniorHighStrand"
                                                                            defaultValue={student.Strand}
                                                                            className={`form-control `}
                                                                            autoComplete="off"
                                                                            maxLength="100"

                                                                        />

                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>






                                                    </div>


                                                    <hr />
                                                    <button onClick={() => { onUpdateStudentDetails() }} className='btn btn-success'>Save Changes</button>


                                                </section>
                                            </>


                                        </div>
                                    </div>
                                </div>
                            </section>


                            </>
                        }




                    </main>
                </>
                :
                <>
                    <Loading />
                </>
            }


        </>
    )
}