import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../history';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getAssessmentDiscount,
    deleteAssessmentTemplateDetail,
    addUpdateAssessmentDiscount,

} from './__hooks__';
import Form from './Form';
import { id } from 'date-fns/locale';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const classes = useStyles();
    const { AssessmentTemplateId } = props.assessmentTemplate;
    const { dispatch } = useAppContext();
    const [selectedId, setSelectedId] = useState(0);
    const [results, setResults] = useState([]);
    const [retrieve, setRetrieve] = useState(true);



    const [showDialog, setShowDialog] = useState({
        id: null,
        record: '',
        status: false
    });
    var rowCnt = 0;



    useEffect(() => {
        if (retrieve) {
            const execute = async () => {
                await getAssessmentDiscount({ assessmentTemplateId: AssessmentTemplateId })
                    .then(response => {
                        setResults(response.data);
                        setRetrieve(false);

                    })
                    .catch(error => {

                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                    })
            }
            execute();
        }
    }, [dispatch, AssessmentTemplateId, retrieve])

    const handleAdd = (data, isDuplicate) => {
        // if (isDuplicate !== 'd') {
        const execute = async () => {
            await addUpdateAssessmentDiscount({ ...data, assessmentDiscountId: 0, assessmentTemplateId: AssessmentTemplateId })
                .then(response => {
                    if (response.success) {
                        setRetrieve(true);
                        setSelectedId(0);
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();


    }

    const handleEdit = (id) => {
        setSelectedId(id);
    }

    const handleCancel = () => {
        setSelectedId(0);
    }

    const handleSave = (id, data) => {
        console.log(data)
        const execute = async () => {
            await addUpdateAssessmentDiscount({ ...data, assessmentDiscountId: id, assessmentTemplateId: parseInt(AssessmentTemplateId) })
                .then(response => {
                    if (response.success) {
                        setRetrieve(true);
                        setSelectedId(0);
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();
    }

    const handleDelete = () => {
        const execute = async () => {
            await deleteAssessmentTemplateDetail({ assessmentDiscountId: showDialog.id })
                .then(response => {

                    setRetrieve(true);
                    setShowDialog({ id: null, record: '', status: false })

                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();
    }

    console.log(results)
    console.log(AssessmentTemplateId)
    console.log(selectedId)
    return (
        <>
            <TableContainer component={Paper}>
                <Table className={classes.table} size="small">
                    <TableHead>
                        <TableRow>
                            <TableCell align="center">#</TableCell>
                            <TableCell>Discount</TableCell>
                            <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {results.length > 0 ? results.map((result) => {
                            const { AssessmentDiscountId, Scholarship, IsActive } = result;
                            console.log(result)
                            rowCnt += 1;
                            if (selectedId !== 0 && selectedId === result.AssessmentDiscountId) {
                                console.log(result)
                                const data = results.find(d => d.AssessmentDiscountId == selectedId)
                                return (
                                    <Form key={AssessmentDiscountId} doc={data} assessmentDiscountId={selectedId} onHandleCancel={handleCancel} onHandleSave={handleSave} index={rowCnt} />
                                );
                            } else {
                                return (

                                    <TableRow key={AssessmentDiscountId}>
                                        <TableCell align="center">{rowCnt}</TableCell>
                                        <TableCell component="th" scope="row">{Scholarship}</TableCell>
                                        <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                                        <TableCell align="center">
                                            <button
                                                className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                                                onClick={() => handleEdit(AssessmentDiscountId)}
                                            >
                                                <i className="fa fa-pencil-alt text-white"></i>
                                            </button>

                                            <button
                                                className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                                                onClick={() => setShowDialog({ id: AssessmentDiscountId, record: `${Scholarship}`, status: !showDialog.status })}
                                            >
                                                <i className="fa fa-trash"></i>
                                            </button>
                                        </TableCell>
                                    </TableRow>
                                );
                            }
                        }) : ''}
                    </TableBody>
                    <TableFooter>
                        <Form doc={[]} AssessmentTemplateId={AssessmentTemplateId} onHandleAdd={handleAdd} />
                    </TableFooter>
                </Table>
            </TableContainer>
            {showDialog.status && <IMDialog
                title={`Question`}
                content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
                onHandleDelete={handleDelete}
                onShowDialog={setShowDialog}
                isOpen={true}
            />}
        </>
    );
}