import React, { useState } from "react";
import { TableCell, TableRow } from '@material-ui/core';


export default function AddCell(props) {
    const { title, value, Content, isOpen, handleClose, onSubmit, isUpdate, isAdd, isDelete,
        instituteSelect, id, gradeElementSelect, idValue, gradingPeriodSelect, semesterSelect,
        schoolYearSelect, employeeSelect, gradeTemplateSelect, subjectCodeSelect,
        instructionalGuideSelect, examTypeSelect, subjectSchedSelect, userSelect, courseSelect,
        yearLevelSelect, subjectSelect, classSectionSelect, subjectCategorySelect, civilStatusSelect,
        religionSelect, countrySelect, provinceSelect, schoolSelect, type, isMerger, isExceedsPercentage, subTotalSelect,
        getClassSectionSelectHandler } = props;
    const [formValues, setFormValues] = useState({})
    const onSave = (data) => {
        setFormValues(data)
    }
    console.log(formValues)

    const areAllValuesNotNull = (obj) => {
        for (const key in obj) {
            if (obj[key] != 0 && (obj[key] === null || obj[key] == "")) {
                console.log(obj[key])
                return false;
            }
        }
        return true;
    };

    const isButtonDisabled = !areAllValuesNotNull(formValues);
    console.log(formValues)
    return (
        <>
            <TableRow>
                {value != null && value != undefined ?
                    <Content
                        onSave={onSave}
                        value={value}
                        instituteSelect={instituteSelect}
                        gradeElementSelect={gradeElementSelect}
                        gradingPeriodSelect={gradingPeriodSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        gradeTemplateSelect={gradeTemplateSelect}
                        employeeSelect={employeeSelect}
                        subjectCodeSelect={subjectCodeSelect}
                        instructionalGuideSelect={instructionalGuideSelect}
                        examTypeSelect={examTypeSelect}
                        subjectSchedSelect={subjectSchedSelect}
                        userSelect={userSelect}
                        courseSelect={courseSelect}
                        yearLevelSelect={yearLevelSelect}
                        subTotalSelect={subTotalSelect}
                        subjectSelect={subjectSelect}
                        classSectionSelect={classSectionSelect}
                        subjectCategorySelect={subjectCategorySelect}
                        civilStatusSelect={civilStatusSelect}
                        religionSelect={religionSelect}
                        countrySelect={countrySelect}
                        provinceSelect={provinceSelect}
                        schoolSelect={schoolSelect}
                        idValue={idValue}
                        isExceedsPercentage={isExceedsPercentage}
                        getClassSectionSelectHandler={getClassSectionSelectHandler}
                    />
                    :
                    <Content
                        onSave={onSave}
                        instituteSelect={instituteSelect}
                        gradeElementSelect={gradeElementSelect}
                        gradingPeriodSelect={gradingPeriodSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        gradeTemplateSelect={gradeTemplateSelect}
                        employeeSelect={employeeSelect}
                        subjectCodeSelect={subjectCodeSelect}
                        instructionalGuideSelect={instructionalGuideSelect}
                        examTypeSelect={examTypeSelect}
                        subjectSchedSelect={subjectSchedSelect}
                        userSelect={userSelect}
                        courseSelect={courseSelect}
                        yearLevelSelect={yearLevelSelect}
                        subjectSelect={subjectSelect}
                        classSectionSelect={classSectionSelect}
                        subjectCategorySelect={subjectCategorySelect}
                        civilStatusSelect={civilStatusSelect}
                        religionSelect={religionSelect}
                        countrySelect={countrySelect}
                        subTotalSelect={subTotalSelect}
                        provinceSelect={provinceSelect}
                        schoolSelect={schoolSelect}
                        idValue={idValue}
                        isExceedsPercentage={isExceedsPercentage}
                        getClassSectionSelectHandler={getClassSectionSelectHandler}
                    />

                }

                <TableCell align="center">
                    {
                        isUpdate == true &&
                        <>
                            <button onClick={() => { onSubmit(props.value[id], { ...props.value, ...formValues }) }} className="btn btn-sm btn-icon-sm btn-success p-1 mr-1">
                                <i className="fa fa-save p-0"></i>
                            </button>
                            <button onClick={() => { handleClose() }} className="btn btn-sm btn-icon-sm btn-secondary p-1 ">
                                <i className="fa fa-ban p-0"></i>
                            </button>
                        </>

                    }

                    {
                        isAdd == true &&
                        <button onClick={() => { onSubmit(formValues) }} className="btn btn-sm btn-icon-sm btn-primary p-1"
                            disabled={isButtonDisabled}>
                            <i className="fa fa-plus p-0"></i>
                        </button>
                    }


                </TableCell>
            </TableRow>
        </>
    )
}