import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getPaymentSchedule,
    createPaymentSchedule,
    updatePaymentSchedule,
    deletePaymentSchedule,
    updateAssessmentTemplate,
    getAssessmentTemplates,
    addDuplicatePaymentSchedule
} from './__hooks__';
import Form from './Form';
import moment from 'moment';
import { useParams } from 'react-router-dom';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { id } = useParams()
    const classes = useStyles();
    const { AssessmentTemplateId } = props.assessmentTemplate;
    const { dispatch } = useAppContext();
    const [selectedId, setSelectedId] = useState(0);
    const [results, setResults] = useState([]);
    const [retrieve, setRetrieve] = useState(true);
    const [assessmetnTemplate, setAssessmentTemplate] = useState()
    const [paymentMode, setPaymentMode] = useState()

    const insId = props.assessmentTemplate.InstituteId
    const courId = props.assessmentTemplate.CourseId
    const yearLevId = props.assessmentTemplate.YearLevelId
    const syId = props.assessmentTemplate.SchoolYear
    const semId = props.assessmentTemplate.SemesterId


    console.log(insId)
    console.log(courId)
    console.log(yearLevId)
    console.log(syId)
    console.log(semId)

    const [showDialog, setShowDialog] = useState({
        id: null,
        record: '',
        status: false
    });
    var rowCnt = 0;

    console.log(props)
    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {

                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }
        execute()
    }


    useEffect(() => {
        let query = "{where: `WHERE AssessmentTemplateId = ${parseInt(id)}`}"
        Query(getAssessmentTemplates, query, setAssessmentTemplate)

    }, [])

    useEffect(() => {
        if (retrieve) {
            const execute = async () => {
                await getPaymentSchedule({ where: `WHERE PS.AssessmentTemplateId = ${AssessmentTemplateId}` })
                    .then(response => {
                        if (response.success) {
                            //   dispatch({ type: enrollmentTypes.GET_ASSESSMENT_DETAILS, data: response.data });
                            props.handleGetPaymentSched(response.data)
                            setResults(response.data);
                            setRetrieve(false);
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            //   localStorage.clear();
                            //   history.push('/auth/login');
                        } else {
                            //   dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                        }
                    })
            }
            execute();
        }
    }, [dispatch, AssessmentTemplateId, retrieve])

    const handleAdd = (data) => {
        let valuesPaymentSched = {
            assessmentTemplateId: data.assessmentTemplateId,
            date: data.date,
            paymentModeId: data.paymentModeId,
            quarter: data.quarter,
            isActive: data.isActive
        }

        let valuesAssessmentTemplate = {
            initialFee: parseFloat(data.initialFee),

        }

        console.log(valuesAssessmentTemplate)

        const execute = async (values) => {
            await createPaymentSchedule(values)
                .then(response => {
                    if (response.success) {


                        const executeUpdate = async (a, b) => {
                            await updateAssessmentTemplate(a, b)
                                .then(response => {
                                    if (response.success) {
                                        setRetrieve(true);
                                        setSelectedId(0);
                                        let query = "{where: `WHERE AssessmentTemplateId = ${parseInt(id)}`}"
                                        Query(getAssessmentTemplates, query, setAssessmentTemplate)
                                    }
                                })
                                .catch(error => {
                                    if (error.response.status === 401) {
                                        // localStorage.clear();
                                        // history.push('/auth/login');
                                    } else {
                                        // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                    }
                                })
                        }
                        executeUpdate(parseInt(id), valuesAssessmentTemplate);
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        //   localStorage.clear();
                        //   history.push('/auth/login');
                    } else {
                        //   dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }


        execute(valuesPaymentSched);

    }

    const handleDuplicate = (data) => {
        let valuesPaymentSched = {
            assessmentTemplateId: data.assessmentTemplateId,
            date: data.date,
            quarter: data.quarter
        }
        const execute = async () => {
            await addDuplicatePaymentSchedule({ ...valuesPaymentSched, schoolYear: assessmetnTemplate[0].SchoolYear, semesterId: assessmetnTemplate[0].SemesterId })
                .then(response => {
                    setRetrieve(true);
                    setSelectedId(0);
                })
                .catch(error => {

                })
        }
        execute();

    }

    const handleEdit = (id) => {
        setSelectedId(id);
    }

    const handleCancel = () => {
        setSelectedId(0);
    }

    const handleSave = (ids, data) => {
        let valuesPaymentSched = {
            assessmentTemplateId: data.assessmentTemplateId,
            date: data.date.slice(0, 10),
            paymentModeId: data.paymentModeId,
            quarter: data.quarter,
            isActive: data.isActive,
            initialFee: parseFloat(data.initialFee),
        }

        let valuesAssessmentTemplate = {
            initialFee: parseFloat(data.initialFee),
            date: data.date

        }
        const execute = async (a, b) => {
            await updatePaymentSchedule(a, b)
                .then(response => {
                    if (response.success) {
                        const executeUpdate = async (c, d) => {
                            await updateAssessmentTemplate(c, d)
                                .then(response => {
                                    if (response.success) {
                                        setRetrieve(true);
                                        setSelectedId(0);
                                        let query = "{where: `WHERE AssessmentTemplateId = ${parseInt(id)}`}"
                                        Query(getAssessmentTemplates, query, setAssessmentTemplate)
                                    }
                                })
                                .catch(error => {
                                    if (error.response.status === 401) {
                                        // localStorage.clear();
                                        // history.push('/auth/login');
                                    } else {
                                        // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                    }
                                })
                        }
                        executeUpdate(parseInt(id), valuesAssessmentTemplate);
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // localStorage.clear();
                        // history.push('/auth/login');
                    } else {
                        // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute(ids, valuesPaymentSched);
    }

    const handleDelete = () => {
        const execute = async () => {
            await deletePaymentSchedule(showDialog.id)
                .then(response => {
                    if (response.success) {
                        setRetrieve(true);
                        setShowDialog({ id: null, record: '', status: false })
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // localStorage.clear();
                        // history.push('/auth/login');
                    } else {
                        // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();
    }

    console.log(results)
    console.log(assessmetnTemplate)
    return (
        <>
            {assessmetnTemplate && assessmetnTemplate.length > 0 &&

                < TableContainer component={Paper}>
                    <Table className={classes.table} size="small">
                        <TableHead>
                            <TableRow>
                                <TableCell align="center">#</TableCell>
                                <TableCell align="left">Term</TableCell>
                                <TableCell align="center">Date</TableCell>
                                <TableCell align="center">Amount</TableCell>
                                <TableCell align="center">Active</TableCell>
                                <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results.length > 0 ? results.map((result) => {
                                const { PaymentScheduleId, AssessmentTemplateId, PaymentMode, PaymentModeId, Date, Quarter, IsActive, Amount } = result;
                                rowCnt += 1;
                                if (selectedId !== 0 && selectedId === result.PaymentScheduleId) {
                                    return (
                                        <Form
                                            key={PaymentScheduleId}
                                            doc={result}
                                            assessmentTemplateId={AssessmentTemplateId}
                                            onHandleCancel={handleCancel}
                                            onHandleSave={handleSave}
                                            index={rowCnt}
                                            isUE={Quarter === 'UE' ? true : false}
                                            assessmentTemplateInitialFee={assessmetnTemplate[0].InitialFee}
                                        />
                                    );
                                } else {
                                    return (

                                        <TableRow key={PaymentScheduleId}>
                                            <TableCell align="center">{rowCnt}</TableCell>
                                            <TableCell component="th" scope="row">{
                                                Quarter === 'UE' ? 'Upon Enrollment' :
                                                    Quarter === 'P' ? 'Prelim' :
                                                        Quarter === 'M' ? 'Midterm' :
                                                            Quarter === 'SF' ? 'Semi Finals' :
                                                                Quarter === 'F' ? 'Finals' : ''

                                            }</TableCell>
                                            <TableCell align="center">{moment(Date).format('YYYY-MM-DD')}</TableCell>
                                            {Quarter === 'UE' ?
                                                <TableCell align="center">{assessmetnTemplate[0].InitialFee}</TableCell>
                                                :
                                                <TableCell align="center"></TableCell>


                                            }
                                            <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                                            <TableCell align="center">
                                                <button
                                                    className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                                                    onClick={() => handleEdit(PaymentScheduleId)}
                                                >
                                                    <i className="fa fa-pencil-alt text-white"></i>
                                                </button>

                                                <button
                                                    className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                                                    onClick={() => setShowDialog({ id: PaymentScheduleId, record: `${AssessmentTemplateId}  - ${PaymentMode}`, status: !showDialog.status })}
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>
                                            </TableCell>
                                        </TableRow>
                                    );
                                }
                            }) : ''}
                        </TableBody>
                        <TableFooter>
                            <Form
                                doc={[]}
                                assessmentTemplateId={AssessmentTemplateId}
                                onHandleAdd={handleAdd}
                                assessmentTemplateInitialFee={assessmetnTemplate[0].InitialFee}
                                onHandleDuplicate={handleDuplicate}
                            />
                        </TableFooter>
                    </Table>
                </TableContainer>


            }

            {
                showDialog.status && <IMDialog
                    title={`Question`}
                    content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
                    onHandleDelete={handleDelete}
                    onShowDialog={setShowDialog}
                    isOpen={true}
                />
            }
        </>
    );
}