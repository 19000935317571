import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getStudentToRecieveDocs, getSemester, getSchoolYear, studentAutoComplete, searchStudent } from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Autocomplete } from '@material-ui/lab';
import { TextField } from "@material-ui/core";
import { useForm } from 'react-hook-form';
import { Link } from 'react-router-dom';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import { debounce } from 'lodash';

export default function List() {
    const { state: { fees, auth }, dispatch } = useAppContext();
    const { setValue, handleSubmit, register } = useForm()

    const [student, setStudent] = useState(null)

    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
    const [keyword, setKeyword] = useState('0');
    const [rowValue, setRowValue] = useState(null)
    const [studentList, setStudentList] = useState([])
    const [studentNo, setStudentNo] = useState()
    const [studentId, setStudentId] = useState(0)

    const activeSem = auth.data.schoolyears.assessment.SemesterId
    const activeSy = auth.data.schoolyears.assessment.SchoolYear

    const [filter, setFilter] = useState({ schoolYear: activeSy, semesterId: activeSem, keyword: '0' })


    const handleChange = name => event => { setFilter({ ...filter, [name]: event }) }


    const [instituteSelect, setInstituteSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [isSearching, setIsSearching] = useState(false)

    const executeSearchStudent = async (val) => {
        setIsSearching(true)
        await searchStudent(val)
            .then(res => {
                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found, Try Migrating Student In Campus'
                    });

                    setStudent(null)
                }
                setIsSearching(false)

            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
                setIsSearching(false)

            })
    }
    const onSubmit = (formValues) => {
        console.log(formValues)

        let data = {
            ...formValues,
            semesterId: filter.semesterId,
            schoolYear: filter.schoolYear
        }
        executeSearchStudent(data)

    }


    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);

    }
    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }, 300);


    const handleGetStudent = async (data) => {
        setStudentNo(data.StudentNo)
        setStudentId(data.StudentId)
        console.log(data)
    }

    const onCancelSearch = () => {
        setStudent(null)
        setStudentNo("")
        setStudentList([])
    }



    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')

    }, [])




    useEffect(() => {
        const execute = async () => {
            await getStudentToRecieveDocs({ schoolYear: filter.schoolYear, semesterId: filter.semesterId, keyword: keyword })
                .then(response => {
                    if (response.success) {
                        setResults(response.data);
                        setTotalRecord(response.data.length > 0 ? response.records : 0);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        }
        execute();

    }, [page, rowsPerPage, keyword, filter])



    useEffect(() => {
        if (studentId != 0) {
            setTimeout(() => {
                onSubmit({ studentId: studentId, semesterId: filter.schoolYear, schoolYear: filter.schoolYear })

            }, 500)

        }
    }, [studentId])
    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))
    console.log(instituteSelect)
    console.log(studentId)

    return (
        <>
            {semesterSelect && schoolYearSelect ?
                <>

                    <div className="container">
                        <div className="card">


                            <div className="row container my-5">
                                <div className="col-12 col-xl-4  border-right border-primary">
                                    <div className=" p-5">
                                        {student !== null ?
                                            <>
                                                <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                                <p className="my-0  ">{student.CourseDesc}</p>
                                            </>

                                            :

                                            <>
                                                <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                                {/* //<p className="my-0  ">Student Course</p> */}
                                            </>

                                        }


                                        <hr />

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                            }
                                        </div>



                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                            }
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                            }
                                        </div>

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "Old Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                                </>

                                                :
                                                <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                            }
                                        </div>
                                        <hr />


                                    </div>


                                </div>
                                <div className="col-12 col-xl-8">

                                    <div className="p-5 mb-5 ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Search for a Student</h3>

                                        <section className="p-5">
                                            <div className="bg-white mb-3 p-0">
                                                <IMSelect
                                                    data={schoolYearSelect}
                                                    onHandleChange={handleChange('schoolYear')}
                                                    refClassContainer=""
                                                    name="schoolYear"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="SchoolYear"
                                                    placeHolder="Select School Year"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.schoolYear}
                                                    refClassName={`text-center`}
                                                    withDescription={false}
                                                    //description={`Enrollment Status`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />

                                            </div>
                                            <div className="bg-white mb-3 p-0">  <IMSelect
                                                data={semesterSelect}
                                                onHandleChange={handleChange('semesterId')}
                                                refClassContainer=""
                                                name="semesterId"
                                                isRequired={false}
                                                withLabel={false}
                                                label="Semester"
                                                placeHolder="Select Semester"
                                                forwardRef={() => { }}
                                                selectedId={filter.semesterId}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                //description={`Enrollment Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            /></div>
                                            <form onSubmit={handleSubmit(onSubmit)} method="post">


                                                <Autocomplete
                                                    id="studentNo"
                                                    options={studentList}
                                                    getOptionLabel={(option) => option.StudentName}
                                                    onChange={(event, value) => {
                                                        if (value) {
                                                            handleGetStudent(value)
                                                            // Handle the selected option
                                                        } else {
                                                            // Handle the case when no option is selected
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Student Number"
                                                            variant="outlined"
                                                            inputRef={register}
                                                            name="studentNo"
                                                            value={studentNo}
                                                            onChange={
                                                                (e) => { handleStudentInputChange(e.target.value) }

                                                            }
                                                        />
                                                    )}
                                                />


                                            </form>
                                            {isSearching == true &&
                                                <>
                                                    <div class="spinner-border" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                    <p>Loading Please wait ...</p>

                                                </>

                                            }
                                            {student !== null ?
                                                <>
                                                    <div className="mt-5">

                                                        {student.IsForReservation == 0 ?
                                                            <Link to={{ pathname: `/admin/student/receive-documents-form/${student.StudentId}` }} className="btn btn-success mr-5" >View Documents</Link>
                                                            :
                                                            <div className="alert alert-warning" role="alert">
                                                                Student need to pay first its reservation fee before receiving Documents
                                                            </div>

                                                        }


                                                        <button onClick={() => { onCancelSearch() }} type="button" className="btn btn-secondary ">Cancel</button>
                                                    </div>


                                                </>
                                                :
                                                ''

                                            }
                                        </section>



                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </>

                :


                <Loading />
            }

        </>
    );
}