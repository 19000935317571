import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import history from "../../../../history";
import CashierForm from "./CashierForm"
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import {
    getStudentCashieringAccount, getAssessmentStudentSubjectScheduleDetail,
    getStudentTotalPayments, getCashieringPaymentSchedule,
    getStudentAstraSubjects, getStudentAvailableFees, getStudentAvailableOtherFees,
    studentPayment, getAssignOr, getStudentListOfFees,
    getLatestORNo, getStudentOtherFees, getStudentPayments,
    getExistingPaymentScheduleId, getStudentExistingAssessments, getEnrollmentDiscount,
    getStudentAddDropSubjects, getStudentExcessFee, getStudentReservationFees,
    getFees, getStudentAdditionalFees
} from "./__hooks__/proc"
import { useAppContext } from "../../../../contexts/useAppContext";

import SelectCreator from "../../../../../_metronic/functions/SelectCreator"
export default function Cashier(props) {
    const { state: { auth } } = useAppContext()
    const { id } = useParams()
    const accountData = props.location.data
    const [studentAccount, setStudentAccount] = useState(null)
    const [studentPayments, setStudentPayments] = useState([])
    const [studentAdditionalFees, setStudentAdditionalFees] = useState([])
    const [studentListOfAssessments, setStudentListOfAssessments] = useState(null)
    const [studentExistingPaymentSchedule, setStudentExistingPaymentSchedule] = useState([])
    const [isCampus, setIsCampus] = useState(false)
    const [studentSubSchedIds, setStudentSubSchedIds] = useState(null)
    const [studentSubjectCategoriesId, setStudentSubjectCategoriesId] = useState(null)
    const [studentTotalUnits, setStudentTotalUnits] = useState(1)
    const [studentTotalAddedUnits, setStudentTotalAddedUnits] = useState(0)
    const [studentLabModes, setStudentLabModes] = useState(1)
    const [studentFees, setStudentFees] = useState(null)
    const [studentOtherFees, setStudentOtherFees] = useState(null)
    const [studentAdditionalPayments, setStudentAdditionalPayments] = useState(null)
    const [studentReservationFees, setStudentReservationFees] = useState(null)
    const [studentTotalPayment, setStudentTotalPayment] = useState(null)
    const [discounts, setDiscounts] = useState({ tuitionFee: 0.00, regFee: 0.00, miscFee: 0.00, labFee: 0.00, otherLabFee: 0.00, otherFee: 0.00 })
    const [totalFee, setTotalFee] = useState(null)
    const [remainingBalance, setRemainingBalance] = useState(null)
    const [paymentSchedule, setPaymentSchedule] = useState(null)
    const [orLimit, setOrLimit] = useState({ starting: 'NO ASSIGN STARTING RECEIPT NO', ending: "NO ASSIGN ENDING RECEIPT NO" })
    const [ORNo, setORNo] = useState(null)
    const [isComputing, setIsComputing] = useState(false)
    const [totalExcessFee, setTotalExcessFee] = useState(0)
    const [labSubjects, setLabSubjects] = useState([])
    const [isOverride, setIsOverride] = useState(false)
    const [feeSelect, setFeeSelect] = useState(null)
    // select

    const [availableFees, setAvailableFees] = useState(null)
    const [availableOtherFees, setAvailableOtherFees] = useState(null)

    if (accountData == null) {
        history.push("/admin/accounting/cashier")
    }

    const exeStudentCashieringAccount = async (val) => {
        await getStudentCashieringAccount(val)
            .then(res => {
                console.log(res)
                setStudentAccount(res.data)
                if (res.data[0].IsCampus == 1) {
                    setIsCampus(false)
                } else {
                    setIsCampus(false)
                }
            })
            .catch(e => {

            })
    }

    const getStudentSubjectAddDrop = async (val) => {
        await getStudentAddDropSubjects(val)
            .then(res => {
                console.log(res)
                let arr = []
                let totalUnits = 0
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].SubjectStatusId !== 1 && res.data[i].EnrollmentStatus == 0) {
                            arr.push(res.data[i])
                            if (res.data[i].SubjectStatusId == 3)
                                totalUnits += parseInt(res.data[i].TotalUnit)
                        }
                    }
                    setStudentTotalAddedUnits(totalUnits)
                }

            })
            .catch(e => {

            })
    }

    const exeStudentAssessmentSubjectScheduleDetail = async (val) => {
        await getAssessmentStudentSubjectScheduleDetail(val)
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    let subCatIds = []
                    let totalUnits = 0
                    let totalLabModes = 1
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].SubjectCategoryId !== null) {
                            subCatIds.push(res.data[i].SubjectCategoryId)
                            totalLabModes += 1
                        }
                        totalUnits += parseInt(res.data[i].LecHours)
                        totalUnits += parseInt(res.data[i].LabHours)

                    }
                    setLabSubjects(res.labSubjects)
                    setStudentSubjectCategoriesId(subCatIds)
                    setStudentTotalUnits(res.totalUnits)
                    setStudentLabModes(totalLabModes)
                }

            })
            .catch(e => {

            })
    }

    const exeGetStudentFees = async (val) => {
        console.log(val)
        setIsComputing(true)
        await getStudentListOfFees(val)
            .then(res => {
                if (res.data.length > 0) {

                    setStudentFees(res.data)



                    getAvailablePaymentSchedule({
                        assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                        tuitionFee: res.totalFee,
                        regFee: 0,
                        miscFee: 0,
                        labFee: 0,
                        discount: discounts.labFee + discounts.miscFee + discounts.otherFee + discounts.regFee + discounts.tuitionFee,
                        interest: res.totalInterest,
                        studentId: studentAccount[0].StudentId,
                        schoolYear: studentAccount[0].SchoolYear,
                        semesterId: studentAccount[0].SemesterId
                    })
                    setTotalFee(1)
                    console.log(res.data)
                    console.log(res)

                    setIsComputing(false)

                }

            })
            .catch(e => {

            })
    }


    const exeGetStudentOtherFees = async (val) => {
        await getStudentOtherFees(val)
            .then(res => {
                console.log(res)
                setStudentOtherFees(res.data.data)
            })
            .catch(e => {

            })
    }

    const exeGetStudentReservationFees = async (val) => {
        await getStudentReservationFees(val)
            .then(res => {
                console.log(res)
                setStudentReservationFees(res.data.data)
            })
            .catch(e => {

            })
    }


    const exeGetStudentTotalPayment = async (val) => {
        await getStudentTotalPayments(val)
            .then(res => {
                setStudentTotalPayment(res.data)
            })
            .catch(e => {

            })
    }

    const getStudentSubjects = async (val) => {
        await getStudentAstraSubjects(val)
            .then(res => {
                let arr = []
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push(res.data[i].DetailId)
                    }


                }
                setStudentSubSchedIds(arr)
            })
            .catch(e => {

            })
    }


    const getAvailableFees = async (val) => {
        await getStudentAvailableFees(val)
            .then(res => {
                setAvailableFees(res.data.data)
            })
            .catch(e => {

            })
    }


    const getAvailableOtherFees = async (val) => {
        await getStudentAvailableOtherFees(val)
            .then(res => {
                setAvailableOtherFees(res.data.data)
            })
            .catch(e => {

            })
    }

    const getAvailablePaymentSchedule = async (val) => {
        await getCashieringPaymentSchedule(val)
            .then(res => {
                setPaymentSchedule(res.data)
            })
            .catch(e => {

            })
    }

    const getStudentExistingPayments = async (val) => {
        await getStudentPayments(val)
            .then(res => {
                let list = []
                if (res.data.length > 0) {
                    setStudentPayments(res.data)

                }
            })
    }

    const getStudentEnrollmentDiscount = async (val) => {
        await getEnrollmentDiscount(val)
            .then(res => {
                let list = []
                if (res.data.length > 0) {
                    setDiscounts({
                        tuitionFee: res.totalTuitionFeeDiscount,
                        regFee: res.totalRegFeeDiscount,
                        miscFee: res.totalMiscFeeDiscount,
                        otherFee: res.totalOtherFeeDiscount,
                        labFee: res.totalLabFeeDiscount,
                        otherLabFee: res.totalOtherLabFeeDiscount

                    })

                }
            })
    }

    const getExcessFee = async (val) => {
        await getStudentExcessFee(val)
            .then(res => {
                if (res.data.Total != null && res.data.Total > 0.00) {
                    setTotalExcessFee(res.data.Total)
                }
            })
            .catch(e => {

            })
    }


    const payment = async (val) => {
        await studentPayment(val)
            .then(res => {

            })
            .catch(e => {

            })
    }


    const onStartNewTransaction = () => {
        // Check if the data prop is present in the location object
        if (props.location?.data) {
            // Update the URL with the new pathname and data prop
            history.push(`/admin/accounting`)
            setTimeout(() => {
                history.replace({
                    pathname: `/admin/accounting/cashier-payment/${id}`,
                    data: props.location.data,
                });
            }, 500)

        }
    }

    const onReloadOtherFee = async () => {
        await exeGetStudentOtherFees({
            assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
            yearLevelId: studentAccount[0].YearLevelId,
            courseId: studentAccount[0].CourseId,
            instituteId: studentAccount[0].InstituteId,
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            otherFeeDiscount: discounts.otherFee,
            isMale: studentAccount[0].Gender == 'M' ? true : false

        })
    }


    useEffect(() => {
        SelectCreator(getFees, setFeeSelect, 'FeeId', 'Description')

        getAssignOr({ where: `WHERE PORA.AssignTo = ${parseInt(auth.data.UserId)} LIMIT 1` })
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    setOrLimit({ ...orLimit, starting: res.data[0].ORFrom, ending: res.data[0].ORTo })

                }

            })


        getLatestORNo({ userId: auth.data.UserId })
            .then(res => {
                setORNo(res.data.LatestORNo)
                setIsOverride(res.data.IsOverride == 0 ? true : false)
            })

        // getStudentExistingAssessmentsIds({ studentId: parseInt(id) })
        //     .then(res => {
        //         console.log(res)
        //     })


    }, [])


    useEffect(() => {
        if (accountData !== undefined) {
            exeStudentCashieringAccount({
                studentId: parseInt(id),
                semesterId: accountData.SemesterId,
                schoolYear: accountData.SchoolYear
            })

            exeGetStudentTotalPayment({
                studentId: parseInt(id),
                semesterId: accountData.SemesteriD,
                schoolYear: accountData.SchoolYear
            })

            getStudentSubjectAddDrop({
                studentId: accountData.StudentId,
                schoolYear: accountData.SchoolYear,
                semesterId: accountData.SemesterId,
                subjectScheduleDetailId: null
            })


        }


    }, [accountData])


    useEffect(() => {
        if (studentAccount != null) {
            console.log((studentAccount[0].IsForReservation == 1 && studentAccount[0].IsReserved == 0) || (studentAccount[0].IsForReservation == 0 && studentAccount[0].IsReserved == 1))
            console.log(studentAccount)
            getStudentSubjects({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear
            })

            getAvailableFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear
            })

            getAvailableOtherFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                yearLevelId: studentAccount[0].YearLevelId,
                courseId: studentAccount[0].CourseId,
                instituteId: studentAccount[0].InstituteId,
                isCampus: false,
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear
            })



            getStudentExistingPayments({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                isBackAcct: false

            })

            getStudentEnrollmentDiscount({
                enrollmentId: studentAccount[0].EnrollmentId
            })

            getExcessFee({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
            })

            exeGetStudentReservationFees({
                schoolYear: studentAccount[0].StudentSchoolYear,
                semesterId: studentAccount[0].StudentSemesterId,
                yearLevelId: studentAccount[0].StudentYearLevelId,
                isMale: studentAccount[0].Gender == 'M' ? true : false,
                studentId: studentAccount[0].StudentId
            })

            getStudentAdditionalFees({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
            }).then(res => {
                setStudentAdditionalFees(res.data)
            })
        }
    }, [studentAccount])

    useEffect(() => {
        if (studentSubSchedIds !== null) {
            let subSchedDetlVal = {
                classSectionId: 0,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                isCampus: false,
                batchId: studentAccount[0].CampusBatchId,
                isExist: true,
                subjectScheduleDetailId: studentSubSchedIds !== null ? studentSubSchedIds.toString() : null,
                studentId: studentAccount[0].StudentId
            }

            exeStudentAssessmentSubjectScheduleDetail(subSchedDetlVal)
        }


    }, [studentSubSchedIds])


    useEffect(() => {
        if (studentPayments.length > 0) {
            console.log(studentPayments)
            let list = []
            for (let i = 0; i < studentPayments.length; i++) {
                list.push(studentPayments[i].PaymentId)
            }
            const getPaymentExisting = async () => {
                await getExistingPaymentScheduleId({ paymentId: list.toString() })
                    .then(res => {

                        // setStudentExistingPaymentSchedule(res.data)
                    })
            }
            getPaymentExisting()
        }
    }, [studentPayments])

    useEffect(() => {
        if (studentAccount !== null && studentTotalUnits !== null && studentTotalUnits > 1) {
            exeGetStudentFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId.toString(),
                discountRate: studentAccount[0].ApplyRate,
                totalUnits: studentTotalUnits,
                discountApplyTo: studentAccount[0].ApplyTo,
                labMode: studentLabModes,
                subjectCategoriesId: studentSubjectCategoriesId !== null && studentSubjectCategoriesId.length > 0 ? studentSubjectCategoriesId.toString() : null,
                isCampus: isCampus,
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                tuitionFeeDiscount: discounts.tuitionFee,
                regFeeDiscount: discounts.regFee,
                miscFeeDiscount: discounts.miscFee,
                otherFeeDiscount: discounts.otherFee,
                labFeeDiscount: discounts.labFee,
                otherLabFeeDiscount: discounts.otherLabFee,
                labSubjects: labSubjects,
                studentSchoolYear: studentAccount[0].StudentBatchYear

            })


            exeGetStudentOtherFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                yearLevelId: studentAccount[0].YearLevelId,
                courseId: studentAccount[0].CourseId,
                instituteId: studentAccount[0].InstituteId,
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                otherFeeDiscount: discounts.otherFee,
                isMale: studentAccount[0].Gender == 'M' ? true : false

            })



        }

    }, [studentTotalUnits, studentLabModes, discounts])


    // useEffect(() => {
    //     setReady(true)
    // }, [studentAccount, studentTotalUnits, studentLabModes, discounts])

    useEffect(() => {
        if (!totalFee)
            return


        let rem = parseFloat(totalFee) - parseFloat(studentTotalPayment[0].TotalPayment != null ? parseFloat(studentTotalPayment[0].TotalPayment) : 0)
        setRemainingBalance(rem)
    }, [totalFee, studentTotalPayment])

    console.log(studentAccount)
    console.log(isCampus)
    console.log(studentSubjectCategoriesId)
    console.log(studentTotalUnits)
    console.log(studentLabModes)
    console.log(studentFees)
    console.log(remainingBalance)
    console.log(studentOtherFees)
    console.log(studentReservationFees)
    console.log(paymentSchedule)



    console.log(availableFees)
    console.log(availableOtherFees)
    console.log(ORNo)
    console.log(studentListOfAssessments)


    console.log(discounts)
    console.log(studentExistingPaymentSchedule)
    console.log(studentAdditionalFees)
    console.log(feeSelect)

    return (
        <>
            {/* <h1>Test</h1> */}
            {studentAccount != null && studentAccount.length > 0 && feeSelect != null && feeSelect != undefined && feeSelect.length > 0 ?
                <>
                    {studentAccount[0].IsForReservation == 0 ?
                        <>
                            {studentFees !== null && studentOtherFees !== null ?
                                <CashierForm
                                    studentAccount={studentAccount[0]}
                                    studentFees={studentFees}
                                    studentOtherFees={studentOtherFees}
                                    totalPayment={studentTotalPayment[0].TotalPayment !== null ? studentTotalPayment[0].TotalPayment : 0}
                                    totalFee={totalFee}
                                    accountData={accountData}
                                    remainingBalance={remainingBalance}
                                    availableFees={availableFees}
                                    availableOtherFees={availableOtherFees}
                                    paymentSchedule={paymentSchedule}
                                    payment={payment}
                                    onStartNewTransaction={onStartNewTransaction}
                                    orLimit={orLimit}
                                    ORNo={ORNo}
                                    studentExistingPaymentSchedule={studentExistingPaymentSchedule}
                                    isComputing={isComputing}
                                    totalExcessFee={totalExcessFee}
                                    isOverride={isOverride}
                                    onReloadOtherFee={onReloadOtherFee}
                                    feeSelect={feeSelect}
                                    studentAdditionalFees={studentAdditionalFees}
                                />
                                :
                                <CashierForm
                                    studentAccount={studentAccount[0]}
                                    studentFees={[]}
                                    studentOtherFees={studentReservationFees}
                                    totalPayment={studentTotalPayment !== null && studentTotalPayment[0].TotalPayment !== null ? studentTotalPayment[0].TotalPayment : 0}
                                    totalFee={totalFee}
                                    accountData={accountData}
                                    remainingBalance={remainingBalance}
                                    availableFees={availableFees}
                                    availableOtherFees={availableOtherFees}
                                    paymentSchedule={paymentSchedule}
                                    payment={payment}
                                    onStartNewTransaction={onStartNewTransaction}
                                    orLimit={orLimit}
                                    ORNo={ORNo}
                                    studentExistingPaymentSchedule={studentExistingPaymentSchedule}
                                    isComputing={isComputing}
                                    totalExcessFee={totalExcessFee}
                                    isOverride={isOverride}
                                    onReloadOtherFee={onReloadOtherFee}
                                    feeSelect={feeSelect}
                                    studentAdditionalFees={studentAdditionalFees}

                                />
                            }

                        </>
                        :
                        <>
                            {studentReservationFees !== null ?
                                <CashierForm
                                    studentAccount={studentAccount[0]}
                                    studentFees={[]}
                                    studentOtherFees={studentReservationFees}
                                    totalPayment={studentTotalPayment[0].TotalPayment !== null ? studentTotalPayment[0].TotalPayment : 0}
                                    totalFee={totalFee}
                                    accountData={accountData}
                                    remainingBalance={remainingBalance}
                                    availableFees={availableFees}
                                    availableOtherFees={availableOtherFees}
                                    paymentSchedule={paymentSchedule}
                                    payment={payment}
                                    onStartNewTransaction={onStartNewTransaction}
                                    orLimit={orLimit}
                                    ORNo={ORNo}
                                    studentExistingPaymentSchedule={studentExistingPaymentSchedule}
                                    isComputing={isComputing}
                                    totalExcessFee={totalExcessFee}
                                    isOverride={isOverride}
                                    onReloadOtherFee={onReloadOtherFee}
                                    feeSelect={feeSelect}
                                    studentAdditionalFees={studentAdditionalFees}
                                />
                                :
                                <Loading />
                            }

                        </>
                    }
                </>
                :
                <Loading />
            }

        </>
    )
}   