import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { EducationSelect } from '../';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    educationId: null,
    instituteGroup: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'educationId' }, { required: true });
    register({ name: 'instituteGroup' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { code, description, educationId, instituteGroup, isActive } = formValues;

    const convertedFormValues = {
      code: code,
      description: description,
      educationId: educationId,
      instituteGroup: instituteGroup,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Code <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="code"
                  defaultValue={props.doc.Code === undefined ? "" : props.doc.Code}
                  className={`form-control${errors.code === undefined ? "" : errors.code && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                  maxLength="100"
                />
                <span className="form-text text-muted">Please enter your code.</span>
                {errors.code && errors.code.type === "required" && <div className="invalid-feedback">Code is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <EducationSelect
                refOnHandleChange={selectHandleChange('educationId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.EducationId === undefined ? "" : props.doc.EducationId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.educationId}
                refErrorMessage={errors.educationId && errors.educationId.type === "required" && 'Education is required'}
              />
              <IMSelect
                onHandleChange={selectHandleChange('instituteGroup')}
                refClassContainer="form-group"
                name="instituteGroup"
                isRequired={true}
                withLabel={true}
                label="Institute Group"
                placeHolder="Institute Group"
                forwardRef={setValue}
                selectedId={data.instituteGroup === null ? props.doc.InstituteGroup : data.instituteGroup}
                refClassName={``}
                withDescription={true}
                description={`Please enter your institute group.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: '', label: '' },
                  { id: 'CO', label: 'College' },
                  { id: 'GS', label: 'Grade School' },
                  { id: 'JH', label: 'Junior High School' },
                  { id: 'PS', label: 'Pre School' },
                  { id: 'SG', label: 'School of Graduate Studies' },
                  { id: 'SH', label: 'Senior High School' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
                error={!!errors.instituteGroup}
                errorMessage={errors.instituteGroup && errors.instituteGroup.type === "required" && 'Institute group is required'}
              />
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}