import instance from '../../../../../../apis/local/systemAPI';

// START OF ASSESSMENT TEMPLATE DETAIL
export const getActiveAssessmentTemplateDetails = async () => {
  const result = await instance.get("api/assessment/template-details/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getPaymentMode = async (con) => {
  const result = await instance.post("api/references/mode-of-payments/q", con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplate = async () => {
  const result = await instance.get("api/assessment/templates");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateQuery = async (con) => {
  const result = await instance.post("api/assessment/templates/q", con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateDetailsByAssessmentTemplateId = async (id) => {
  const params = {
    "assessmentTemplateId": id === undefined ? null : id
  }
  var result = await instance.post('api/assessment/template-details/f', params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createAssessmentTemplateDetail = async (formValues) => {
  const result = await instance.post("api/assessment/template-details", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplateDetail = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/template-details/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const addUpdateAssessmentTemplateDetail = async (formValues) => {
  const result = await instance.post("api/assessment/template-details/addUpdate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addDuplicateAssessmentTemplateDetail = async (formValues) => {
  const result = await instance.post("api/assessment/template-details/addDuplicate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteAssessmentTemplateDetail = async (formValues) => {
  const result = await instance.post("api/assessment/template-details/delete", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF ASSESSMENT TEMPLATE DETAIL