import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  createAssessmentLabCategoryId
  , updateAssessmentLabCategory
  , deleteAssessmentLabCategory,
  getAssessmentTemplateDetail,
  getAssessmentLabMode
} from './__hooks__';
import Form from './Form';
import Query from '../../../../../../../_metronic/functions/Query';
const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function List(props) {
  const classes = useStyles();
  const { assessmentTemplateId, id, detailId, labId } = props.match.params;
  const { state: { assessmentTemplateDetails }, dispatch } = useAppContext();
  const [selectedId, setSelectedId] = useState(0);
  const [results, setResults] = useState([]);
  const [retrieve, setRetrieve] = useState(false);
  const [assessmentTemplateDetail, setAssessmentTemplateDetail] = useState()
  const [showDialog, setShowDialog] = useState({
    id: null,
    record: '',
    status: false
  });
  var rowCnt = 0;
  //const assessmentTemplateDetail = assessmentTemplateDetails.data[id];

  let assessmentRateQuery = "{where: `WHERE AssessmentTemplateDetailId = ${parseInt(id)}`}"

  useEffect(() => {
    const executeGetLabMode = async (con) => {
      await getAssessmentLabMode(con)
        .then(res => {
          console.log(res.data)
          if (res.data.length > 0) {
            setResults(res.data)
          } else {
            setResults([])

          }

        })
        .catch(e => {
          console.log(e)
        })
    }

    executeGetLabMode({ where: `WHERE AssessmentTemplateRateId = ${parseInt(labId)}` })
  }, [retrieve])

  // useEffect(() => {
  //   if (retrieve) {
  //     const execute = async (con) => {
  //       await getAssessmentTemplateOtherRate(id)
  //         .then(response => {
  //           if (response.success) {
  //             dispatch({ type: enrollmentTypes.GET_ASSESSMENT_DETAIL_OTHERS, data: response.data });
  //             setResults(response.data);
  //             setRetrieve(false);
  //           } else {
  //             dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
  //           }
  //         })
  //         .catch(error => {
  //           if (error.response.status === 401) {
  //             localStorage.clear();
  //             history.push('/auth/login');
  //           } else {
  //             dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
  //           }
  //         })
  //     }
  //     execute();
  //   }
  // }, [dispatch, id, retrieve])

  const handleAdd = (data) => {
    const execute = async () => {
      await createAssessmentLabCategoryId(data)
        .then(response => {
          if (response.success) {
            alert("added")
            setRetrieve(!retrieve);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleEdit = (id) => {
    setSelectedId(id);
  }

  const handleCancel = () => {
    setSelectedId(0);
  }

  const handleSave = (id, data) => {
    console.log(data);
    const execute = async () => {
      await updateAssessmentLabCategory(id, data)
        .then(response => {
          if (response.success) {
            setRetrieve(!retrieve);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            // localStorage.clear();
            // history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleDelete = () => {
    const execute = async () => {
      await deleteAssessmentLabCategory(showDialog.id)
        .then(response => {
          if (response.success) {
            setRetrieve(!retrieve);
            setShowDialog({ id: null, record: '', status: false })
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  console.log(results)
  return (
    <> {


      <>
        <div className="card card-custom gutter-b example example-compact">
          <div className="card-header" style={{ paddingLeft: "15px" }}>
            <div className="card-title">
              {assessmentTemplateDetail !== undefined ? <h5>{`${assessmentTemplateDetail.FeeCode} - ${assessmentTemplateDetail.FeeGroup}`}</h5> : ""}
            </div>
          </div>
          <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
            <TableContainer component={Paper}>
              <Table className={classes.table} size="small">
                <TableHead>
                  <TableRow>
                    <TableCell align="center">#</TableCell>
                    <TableCell>Lab Mode</TableCell>
                    <TableCell>Amount</TableCell>
                    <TableCell align="center">Active</TableCell>
                    <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {results.length > 0 ? results.map((result) => {
                    const { AssessmentLabCategoryId, AssessmentTemplateRateId, LabMode, Amount, IsActive } = result;
                    rowCnt += 1;
                    if (selectedId !== 0 && selectedId === result.AssessmentLabCategoryId) {
                      return (
                        <Form
                          key={AssessmentLabCategoryId}
                          doc={result}
                          assessmentTemplateDetailId={detailId}
                          onHandleCancel={handleCancel}
                          onHandleSave={handleSave}
                          index={rowCnt}
                          assessmentTemplateRateId={AssessmentTemplateRateId}
                        />
                      );
                    } else {
                      return (
                        <TableRow key={AssessmentLabCategoryId}>
                          <TableCell align="center">{rowCnt}</TableCell>
                          <TableCell component="th" scope="row">{LabMode}</TableCell>
                          <TableCell>{Amount}</TableCell>
                          <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                          <TableCell align="center">
                            <button
                              className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                              onClick={() => handleEdit(AssessmentLabCategoryId)}
                            >
                              <i className="fa fa-pencil-alt text-white"></i>
                            </button>

                            <button
                              className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                              onClick={() =>
                                setShowDialog({
                                  id: AssessmentLabCategoryId,
                                  record: `${LabMode === null ? "" : LabMode} - ${Amount === null ? "" : Amount}`,
                                  status: !showDialog.status
                                })
                              }
                            >
                              <i className="fa fa-trash"></i>
                            </button>
                          </TableCell>
                        </TableRow>
                      );
                    }
                  }) : <TableRow><TableCell colSpan="11">No data available in the table</TableCell></TableRow>}
                </TableBody>
                <TableFooter>

                  <Form
                    doc={[]}
                    assessmentTemplateDetailId={id}
                    onHandleAdd={handleAdd}
                    assessmentTemplateRateId={labId}
                  />
                </TableFooter>
              </Table>
            </TableContainer>
          </div>
          <div className="card-footer" style={{ padding: '0px', margin: '0px' }}>
            <Link to={`/admin/enrollment/assessment-of-fee-detail-others/${id}/${detailId}`} className="btn btn-secondary m-3">Back</Link>
          </div>
        </div>
        {showDialog.status && <IMDialog
          title={`Question`}
          content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
          onHandleDelete={handleDelete}
          onShowDialog={setShowDialog}
          isOpen={true}
        />}
      </>



    }

    </>
  );
}