export const referenceTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_CLASS_SECTION: 'SELECTED-CLASS-SECTION',
    GET_CLASS_SECTIONS: 'GET-CLASS-SECTIONS',
    GET_YEAR_LEVELS: 'GET-YEAR-LEVELS',
    GET_CLASS_SECTION: 'GET-CLASS-SECTION-BY-ID',
    CREATE: 'CREATE-CLASS-SECTION',
    UPDATE: 'UPDATE-CLASS-SECTION',
    DELETE: 'DELETE-CLASS-SECTION',
    LIST_LINK: '/admin/references/class-section-entry',
    CREATE_LINK: '/admin/references/create-class-section/',
    UPDATE_LINK: '/admin/references/update-class-section/',
    DELETE_LINK: '/admin/references/delete-class-section/'
}