export const referenceTypes = {
    SELECTED_STUDY_TYPE: 'SELECTED-STUDY-TYPE',
    GET_STUDY_TYPES: 'GET-STUDY-TYPES',
    GET_STUDY_TYPE: 'GET-STUDY-TYPE-BY-ID',
    CREATE: 'CREATE-STUDY-TYPE',
    UPDATE: 'UPDATE-STUDY-TYPE',
    DELETE: 'DELETE-STUDY-TYPE',
    LIST_LINK: '/admin/references/study-types',
    CREATE_LINK: '/admin/references/create-study-types/',
    UPDATE_LINK: '/admin/references/update-study-types/',
    DELETE_LINK: '/admin/references/delete-study-types/'
}