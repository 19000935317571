export const referenceTypes = {
    SELECTED_PAYMENT_SCHEDULE: 'SELECTED-PAYMENT-SCHEDULE',
    GET_PAYMENT_SCHEDULES: 'GET-PAYMENT-SCHEDULES',
    GET_PAYMENT_SCHEDULE: 'GET-PAYMENT-SCHEDULE-BY-ID',
    CREATE: 'CREATE-PAYMENT-SCHEDULE',
    UPDATE: 'UPDATE-PAYMENT-SCHEDULE',
    DELETE: 'DELETE-PAYMENT-SCHEDULE',
    LIST_LINK: '/admin/references/payment-schedules',
    CREATE_LINK: '/admin/references/create-payment-schedules/',
    UPDATE_LINK: '/admin/references/update-payment-schedules/',
    DELETE_LINK: '/admin/references/delete-payment-schedules/'
}