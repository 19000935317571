import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Button } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function BackAccountNotification(props) {
    const { title, amount, isOpen, handleClose, handleProceed, sy } = props;

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>{title}</DialogTitle>

            <hr />
            <DialogContent>
                <p className=''>This student has a back account amounting to ₱ {amount} for {sy}. Do you want to proceed?`</p>
            </DialogContent>
            <DialogActions>
                <button onClick={handleProceed} variant="contained" className='btn btn-success btn-sm'>
                    Validate
                </button>
                <button onClick={handleClose} variant="contained" className='btn btn-secondary btn-sm'>
                    Close
                </button>
            </DialogActions>
        </Dialog>
    );
}