import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getAssessmentTemplateOtherRatesByAssessmentTemplateOtherId
  , createAssessmentTemplateOtherRate
  , updateAssessmentTemplateOtherRate
  , deleteAssessmentTemplateOtherRate
} from './__hooks__';
import Form from './Form';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function List(props) {
  const classes = useStyles();
  const { assessmentTemplateId, id } = props.match.params;
  const { state: { assessmentOthers }, dispatch } = useAppContext();
  const [selectedId, setSelectedId] = useState(0);
  const [results, setResults] = useState([]);
  const [retrieve, setRetrieve] = useState(true);
  const [showDialog, setShowDialog] = useState({
    id: null,
    record: '',
    status: false
  });
  var rowCnt = 0;
  const assessmentOther = assessmentOthers.data[id];

  useEffect(() => {
    if (retrieve) {
      const execute = async () => {
        await getAssessmentTemplateOtherRatesByAssessmentTemplateOtherId(id)
          .then(response => {
            if (response.success) {
              dispatch({ type: enrollmentTypes.GET_ASSESSMENT_OTHER_RATES, data: response.data });
              setResults(response.data);
              setRetrieve(false);
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
            }
          })
      }
      execute();
    }
  }, [dispatch, id, retrieve])

  const handleAdd = (data) => {
    const execute = async () => {
      await createAssessmentTemplateOtherRate(data)
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleEdit = (id) => {
    setSelectedId(id);
  }

  const handleCancel = () => {
    setSelectedId(0);
  }

  const handleSave = (id, data) => {
    console.log(data);
    const execute = async () => {
      await updateAssessmentTemplateOtherRate(id, data)
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleDelete = () => {
    const execute = async () => {
      await deleteAssessmentTemplateOtherRate(showDialog.id)
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setShowDialog({ id: null, record: '', status: false })
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  console.log(results)

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header" style={{ paddingLeft: "15px" }}>
          <div className="card-title">
            <h5>{`${assessmentOther.FeeCode}`}</h5>
          </div>
        </div>
        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
          <TableContainer component={Paper}>
            <Table className={classes.table} size="small">
              <TableHead>
                <TableRow>
                  <TableCell align="center">#</TableCell>
                  <TableCell>Student Category</TableCell>
                  <TableCell>Year Level</TableCell>
                  <TableCell>Male Fee</TableCell>
                  <TableCell>Female Fee</TableCell>
                  <TableCell>Due Date</TableCell>
                  <TableCell align="center">Active</TableCell>
                  <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {results.length > 0 ? results.map((result) => {
                  const { AssessmentTemplateOtherRateId, MFee, FFee, AssessmentTemplateScheduleDetailId, StudentCategory, Institute, Course, YearLevel, Scholarship, StudyType, Amount, DueDate, IsActive } = result;
                  rowCnt += 1;
                  if (selectedId !== 0 && selectedId === result.AssessmentTemplateOtherRateId) {
                    return (
                      <Form key={AssessmentTemplateOtherRateId} doc={result} assessmentTemplateScheduleId={id} onHandleCancel={handleCancel} onHandleSave={handleSave} index={rowCnt} />
                    );
                  } else {
                    return (
                      <TableRow key={AssessmentTemplateScheduleDetailId}>
                        <TableCell align="center">{rowCnt}</TableCell>

                        <TableCell>{Course}</TableCell>
                        <TableCell>{YearLevel}</TableCell>
                        <TableCell>{MFee}</TableCell>
                        <TableCell>{FFee}</TableCell>

                        <TableCell>{DueDate !== null ? moment(DueDate).format('YYYY-MM-DD') : ""}</TableCell>
                        <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                        <TableCell align="center">
                          <button
                            className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                            onClick={() => handleEdit(AssessmentTemplateOtherRateId)}
                          >
                            <i className="fa fa-pencil-alt text-white"></i>
                          </button>
                          <button
                            className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                            onClick={() => setShowDialog({ id: AssessmentTemplateOtherRateId, record: `${StudentCategory === null ? "" : StudentCategory} - ${Institute === null ? "" : Institute} - ${Course === null ? "" : Course} - ${YearLevel === null ? "" : YearLevel} - ${Amount === null ? "" : Amount} - ${DueDate === null ? "" : moment(DueDate).format('YYYY-MM-DD')}`, status: !showDialog.status })}
                          >
                            <i className="fa fa-trash"></i>
                          </button>
                        </TableCell>
                      </TableRow>
                    );
                  }
                }) : <TableRow><TableCell colSpan="11">No data available in the table</TableCell></TableRow>}
              </TableBody>
              <TableFooter>
                <Form doc={[]} assessmentTemplateScheduleId={id} onHandleAdd={handleAdd} assessmentTemplateOtherId={id} />
              </TableFooter>
            </Table>
          </TableContainer>
        </div>
        <div className="card-footer" style={{ padding: '0px', margin: '0px' }}>
          <Link to={{ pathname: `${enrollmentTypes.LIST_LINK_TO_PARENT}/${assessmentTemplateId}`, data: {} }} className="btn btn-secondary m-3">Back</Link>
        </div>
      </div>
      {showDialog.status && <IMDialog
        title={`Question`}
        content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
        onHandleDelete={handleDelete}
        onShowDialog={setShowDialog}
        isOpen={true}
      />}
    </>
  );
}