import React, { useState, useEffect } from "react";
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import {
    getSummaryOfGradesReport
} from "./__hooks__/index";
import moment from "moment";
import SummaryOfGradesConfirmation from "../../../../../_metronic/layout/components/custom/modal/SummaryOfGradesConfirmation";

export default function SummaryOfGradesExcelFormat({ fileName, btnName, schoolYear, semesterId, instituteId, 
    courseId, studentId, instituteArray, schoolYearDisplay, semesterDisplay }) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const [extractReport, setExtractReport] = useState(null);
    const [excelFormat, setExcelFormat] = useState(null); 
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [onAlert, setOnAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isForConfirmation, setIsForConfirmation] = useState(false);
    
    const currDate = moment().format('YYYYMMDD');

    let newInstituteList = instituteArray;
    let rowStart = 5;
        
    const handleExtractClick = () => {
        if((instituteId === null || instituteId == "" || instituteId == 90)){
            if( (courseId === null || courseId == "" || courseId == 0) && studentId == 0){
                setIsForConfirmation(true);
            } else {
                searchForGrades();
            }
        } else {
            searchForGrades();
        }
    };
    
    const searchForGrades = async () => {
        const newArray = [];
        const consolidated = [];
        let noDataCtr = 0;
        setIsLoading(true);

        if(instituteId != 90 || (instituteId == 90 && (studentId != 0 || courseId != 0))){
          newInstituteList = [];
          newInstituteList.push(instituteId);
        } 
    
        for(let i = 0; i < newInstituteList.length; i++){
          let values = {
            studentId: studentId === null ? 0 : studentId,
            schoolYear: schoolYear,
            semesterId: semesterId,
            instituteId: newInstituteList[i], //data.instituteId === null ? 90 : data.instituteId,
            courseId: courseId === null ? 0 : courseId
            //instituteArray: newInstituteList
          };
    
          await getSummaryOfGradesReport(values)
            .then(result => {
                if (result.success) {
                    if(result.success){
    
                        if(result.data.length > 0){
                          newArray.push(result.data);
                        } else {
                          noDataCtr++;
                        }
                        
                        //setIsLoading(false);
                    } else {
                        setOnAlert(true);  
                        //setIsLoading(false);
                    }          
                } else {
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                }
            })
            .catch(error => {
              if (error.response.status === 401) {
                //history.push('/auth/login');
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
              }
            });
        }
    
        if(noDataCtr == newInstituteList.length){
          setOnAlert(true); 
        } else {
          if(newInstituteList.length > 1){
            consolidated.push(newArray);
            setExtractReport(consolidated[0]);
          } else {
            setExtractReport(newArray[0]);
          }
        }
        
        setIsLoading(false);
    }

    //useEffect when extracReport is updated
    useEffect(() => {
        let arr = [];
        let ctr = 0;
        if (extractReport !== null && typeof(extractReport) !== "undefined") {
            if(instituteId != 90 || (instituteId == 90 && (studentId != 0 || courseId != 0))){
                //let arr = [];
                for (let i = 0; i < extractReport.length; i++) { // student loop
                    for (let ii = 0; ii < extractReport[i].Details.length; ii++) { // student's detail loop
                        arr.push({
                            '#': ii == 0 ? i+1 : '',
                            'Student Name': ii == 0 ? `${extractReport[i].StudentName}` : '',
                            'Subject': `${extractReport[i].Details[ii].SubjectCode}`,
                            'Units': `${extractReport[i].Details[ii].Units}`,
                            'TotalUnits': extractReport[i].Details.length - 1 == ii ? `${extractReport[i].Details[ii].TotalUnits}` : '',
                            'Prelim': `${extractReport[i].Details[ii].Prelim === null ? '' : extractReport[i].Details[ii].Prelim}`,
                            'Midterm': `${extractReport[i].Details[ii].Midterm === null ? '' : extractReport[i].Details[ii].Midterm}`,
                            'Finals': `${extractReport[i].Details[ii].Finals === null ? '' : extractReport[i].Details[ii].Finals}`,
                            'ComputedFinalRating': `${extractReport[i].Details[ii].ComputedFinalRating === null ? '' : extractReport[i].Details[ii].ComputedFinalRating}`,
                            'WeightedAverage': extractReport[i].Details.length - 1 == ii ? `${extractReport[i].Details[ii].WeightedAverage === null ? '' : extractReport[i].Details[ii].WeightedAverage}` : '',
                            'StudentNo': `${extractReport[i].Details[ii].StudentNo}`,
                            'Course': `${extractReport[i].Details[ii].Course}`,
                            'Proper Name': 
                                `${extractReport[i].Details[ii].FirstName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}` + ' ' + 
                                `${extractReport[i].Details[ii].MiddleName.length > 0 ? (extractReport[i].Details[ii].MiddleName.charAt(0).toUpperCase() + '. ') : ''}`  +
                                `${extractReport[i].Details[ii].LastName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}`
                        });
                    }
                    //console.log(arr);
                }
                setExcelFormat(arr);
            } else {
                let consolidated = [];
                let collegeArray = [];
                for (let colCnt = 0; colCnt < extractReport.length; colCnt++) {
                    for (let stuCnt = 0; stuCnt < extractReport[colCnt].length; stuCnt++) {
                        for (let detCnt = 0; detCnt < extractReport[colCnt][stuCnt].Details.length; detCnt++) {
                            collegeArray.push({
                                '#': detCnt == 0 ? ctr + 1 : '',
                                'Student Name': 
                                    detCnt == 0 ? `${extractReport[colCnt][stuCnt].StudentName.toUpperCase()}` : '',
                                'Subject': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].SubjectCode}`,
                                'Units': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].Units}`,
                                'TotalUnits': 
                                    extractReport[colCnt][stuCnt].Details.length - 1 == detCnt ? 
                                        `${extractReport[colCnt][stuCnt].Details[detCnt].TotalUnits}` : '',
                                'Prelim': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].Prelim === null ? 
                                        '' : extractReport[colCnt][stuCnt].Details[detCnt].Prelim}`,
                                'Midterm': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].Midterm === null ? 
                                        '' : extractReport[colCnt][stuCnt].Details[detCnt].Midterm}`,
                                'Finals': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].Finals === null ? 
                                        '' : extractReport[colCnt][stuCnt].Details[detCnt].Finals}`,
                                'ComputedFinalRating': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].ComputedFinalRating === null ? 
                                        '' : extractReport[colCnt][stuCnt].Details[detCnt].ComputedFinalRating}`,
                                'WeightedAverage': 
                                    extractReport[colCnt][stuCnt].Details.length - 1 == detCnt ? 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].WeightedAverage === null ? 
                                        '' : extractReport[colCnt][stuCnt].Details[detCnt].WeightedAverage}` : '',
                                'StudentNo': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].StudentNo}`,
                                'Course': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].Course}`,
                                'Proper Name': 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].FirstName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}` + ' ' + 
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].MiddleName.length > 0 ? (extractReport[colCnt][stuCnt].Details[detCnt].MiddleName.charAt(0).toUpperCase() + '. ') : ''}`  +
                                    `${extractReport[colCnt][stuCnt].Details[detCnt].LastName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}`
                            })
                        }
                        ctr++;
                    } 
                    consolidated.push(collegeArray);
                    collegeArray = [];
                }
                setExcelFormat(consolidated);
            }
        }
    }, [extractReport]);

    useEffect(() => {
        if (excelFormat !== null && typeof(excelFormat) !== "undefined") {
            exportToExcel();
        }
    }, [excelFormat]);

    const exportToExcel = () => {

        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const columns = "ABCDEFGHIJKLM"; //string of spreadsheet columns from A to M

        var currentdate = new Date(); 
        var datetime = "as of " 
            + month[currentdate.getMonth()] + ' ' 
            + currentdate.getDate() + ', ' 
            + currentdate.getFullYear() + ' ' 
            + currentdate.getHours() + ":" 
            + currentdate.getMinutes() + ":" 
            + currentdate.getSeconds();

        //report header names
        let cellHeaderNameA1 = 'Summary of Grades Report';
        let cellHeaderNameA2 = schoolYearDisplay + " - " + semesterDisplay;
        let cellHeaderNameA4 = '#';
        let cellHeaderNameB4 = 'Student Name';
        let cellHeaderNameC4 = 'Subject';
        let cellHeaderNameD4 = 'Units';
        let cellHeaderNameE4 = 'Total Units';
        let cellHeaderNameF4 = 'Prelim';
        let cellHeaderNameG4 = 'Midterm';
        let cellHeaderNameH4 = 'Finals';
        let cellHeaderNameI4 = 'F. Rating';
        let cellHeaderNameJ4 = 'Average';
        let cellHeaderNameK4 = 'Student No.';
        let cellHeaderNameL4 = 'Course';
        let cellHeaderNameM4 = 'Proper Name';

        worksheet.getCell('A1').value = cellHeaderNameA1.toUpperCase();
        worksheet.getCell('A1').font = { bold: true, color: {argb:'ff000000'}, size: 20 };
        
        worksheet.getCell('A2').value = cellHeaderNameA2.toUpperCase();
        worksheet.getCell('A2').font = { bold: true, color: {argb:'ff000000'}, size: 15 };
        
        worksheet.getCell('C2').value = datetime;

        //Update all headers to upper cases
        worksheet.getCell('A4').value = cellHeaderNameA4.toUpperCase();
        worksheet.getCell('B4').value = cellHeaderNameB4.toUpperCase();
        worksheet.getCell('C4').value = cellHeaderNameC4.toUpperCase();
        worksheet.getCell('D4').value = cellHeaderNameD4.toUpperCase();
        worksheet.getCell('E4').value = cellHeaderNameE4.toUpperCase();
        worksheet.getCell('F4').value = cellHeaderNameF4.toUpperCase();
        worksheet.getCell('G4').value = cellHeaderNameG4.toUpperCase();
        worksheet.getCell('H4').value = cellHeaderNameH4.toUpperCase();
        worksheet.getCell('I4').value = cellHeaderNameI4.toUpperCase();
        worksheet.getCell('J4').value = cellHeaderNameJ4.toUpperCase();
        worksheet.getCell('K4').value = cellHeaderNameK4.toUpperCase();
        worksheet.getCell('L4').value = cellHeaderNameL4.toUpperCase();
        worksheet.getCell('M4').value = cellHeaderNameM4.toUpperCase();

        //Add fill and font style to every fourth row of A to M columns
        for(let i = 0; i < columns.length; i++){
            worksheet.getCell(columns[i] + '4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
            worksheet.getCell(columns[i] + '4').font = { bold: true, color: {argb:'ffffffff'} };
        }

        // Add data rows
        if(instituteId != 90 || (instituteId == 90 && (studentId != 0 || courseId != 0))){
            
            // Add headers
            const headers = Object.keys(excelFormat[0]);
            let isInitialExec = true;

            excelFormat.forEach((data) => {
                const row = [];
                headers.forEach((header) => {
                    row.push(data[header]);
                });

                console.log("data: ", data);
                console.log("row: ", row);
                worksheet.addRow(row);

                if(row[0] !== "" && !isInitialExec){
                    for(let i = 0; i < columns.length; i++){
                        worksheet.getCell(columns[i] + rowStart).border = {top: {style:'thin', color: {argb:'FF000000'}}}; //only add on succeeding #s
                    }
                }

                rowStart++;
                isInitialExec = false;
            });
        } else {
            let ctr = 0;

            // Add headers
            const headers = Object.keys(excelFormat[0][0]);
            let isInitialExec = true;

            for(let i = 0; i < excelFormat.length; i++){ // loop by available college
                    excelFormat[i].forEach((data) => {
                        ctr++;
                        const row = [];
                        headers.forEach((header) => {
                            row.push(data[header]);
                        });
                        worksheet.addRow(row);
    
                        if(row[0] !== ""){
                            for(let i = 0; i < columns.length; i++){
                                worksheet.getCell(columns[i] + rowStart).border = {top: {style:'thin', color: {argb:'FF000000'}}}; //only add on succeeding #s
                            }
                        }
    
                        rowStart++;
    
                        //Added headers again
                        if(excelFormat[i].length == ctr && (excelFormat.length - 1 != i)){
                            worksheet.getCell('A' + rowStart).value = cellHeaderNameA4.toUpperCase();
                            worksheet.getCell('B' + rowStart).value = cellHeaderNameB4.toUpperCase();
                            worksheet.getCell('C' + rowStart).value = cellHeaderNameC4.toUpperCase();
                            worksheet.getCell('D' + rowStart).value = cellHeaderNameD4.toUpperCase();
                            worksheet.getCell('E' + rowStart).value = cellHeaderNameE4.toUpperCase();
                            worksheet.getCell('F' + rowStart).value = cellHeaderNameF4.toUpperCase();
                            worksheet.getCell('G' + rowStart).value = cellHeaderNameG4.toUpperCase();
                            worksheet.getCell('H' + rowStart).value = cellHeaderNameH4.toUpperCase();
                            worksheet.getCell('I' + rowStart).value = cellHeaderNameI4.toUpperCase();
                            worksheet.getCell('J' + rowStart).value = cellHeaderNameJ4.toUpperCase();
                            worksheet.getCell('K' + rowStart).value = cellHeaderNameK4.toUpperCase();
                            worksheet.getCell('L' + rowStart).value = cellHeaderNameL4.toUpperCase();
                            worksheet.getCell('M' + rowStart).value = cellHeaderNameM4.toUpperCase();

                            //Add fill and font style to every fourth row of A to M columns
                            for(let i = 0; i < columns.length; i++){
                                worksheet.getCell(columns[i] + rowStart).fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
                                worksheet.getCell(columns[i] + rowStart).font = { bold: true, color: {argb:'ffffffff'} };
                            }

                            rowStart++;
                            ctr = 0;
                        }
                    });
            }
        }


        // Adjust column widths based on content
        worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? (cell.value.toString().length + 3) : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        // Generate a blob from the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + "_" + currDate + fileExtension);
        });
    };

    const afterConfirmation = () => {
        setIsForConfirmation(false);
        searchForGrades();
    };

    return (
        <>
            <SummaryOfGradesConfirmation
                isOpen={isForConfirmation}
                handleConfirm={() => afterConfirmation()}
                handleClose={() => setIsForConfirmation(false)}
                title={'Summary of Grades Report'}
                content={'The selected filters will list all available data. Would you like to proceed?'}
            />

            <button onClick={handleExtractClick} className="btn btn-success btn-block" disabled={isLoading ? "disabled" : ""}>
                {
                    isLoading ?
                    <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp; Loading...
                    </>

                    :
                    
                    <>
                        <i className="fa fa-download"></i>
                        {btnName}
                    </>
                }
            </button>
            {/* <button className="btn btn-success btn-block">{btnName}</button> */}
        </>
    );
}
