import instance from "../../../../../apis/local/systemAPI";

export const checkEnrollment = async (data) => {
    const result = await instance.post("api/campus/enrollment-isExist/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const checkSubjectSchedule = async (data) => {
    const result = await instance.post("api/schedules/search", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getPayment = async (data) => {
    const result = await instance.post("api/campus/payment-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const checkAssessmentTemplate = async (data) => {
    const result = await instance.post("api/assessment/templates/search", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}
export const getYearLevels = async () => {
    const result = await instance.get("api/references/yearlevels/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async () => {
    const result = await instance.get("api/references/courses/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async (condition) => {
    const result = await instance.post("api/references/semesters/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async (condition) => {
    const result = await instance.post("api/references/schoolyears/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const searchStudent = async (data) => {
    const result = await instance.post("api/enrollments/search", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const savePayments = async (formValues) => {
    const result = await instance.post("api/payment/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudent = async (condition) => {
    const result = await instance.post("api/students/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


