import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { SubjectCategorySelect, SubjectTypeSelect } from '../';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    subjectCategoryId: null,
    subjectTypeId: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'subjectCategoryId' });
    register({ name: 'subjectTypeId' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { subjectCode, nomenclature, description, units, labUnits, labHours, lecUnits, lecHours, subjectCategoryId, subjectTypeId, displaySeq, isActive } = formValues;

    const convertedFormValues = {
      subjectCode: subjectCode,
      nomenclature: nomenclature,
      description: description,
      units: units,
      labUnits: labUnits,
      labHours: labHours,
      lecUnits: lecUnits,
      lecHours: lecHours,
      subjectCategoryId: subjectCategoryId,
      subjectTypeId: subjectTypeId,
      displaySeq: displaySeq,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Subject Code <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="subjectCode"
                  defaultValue={props.doc.SubjectCode === undefined ? "" : props.doc.SubjectCode}
                  className={`form-control${errors.subjectCode === undefined ? "" : errors.subjectCode && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                  maxLength="45"
                />
                <span className="form-text text-muted">Please enter your subject code.</span>
                {errors.subjectCode && errors.subjectCode.type === "required" && <div className="invalid-feedback">Subject code is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Nomenclature <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="nomenclature"
                  defaultValue={props.doc.Nomenclature === undefined ? "" : props.doc.Nomenclature}
                  className={`form-control${errors.nomenclature === undefined ? "" : errors.nomenclature && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your nomenclature.</span>
                {errors.nomenclature && errors.nomenclature.type === "required" && <div className="invalid-feedback">Nomenclature is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Units <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="units"
                  defaultValue={props.doc.Units === undefined ? "" : props.doc.Units}
                  className={`form-control${errors.units === undefined ? "" : errors.units && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your units.</span>
                {errors.units && errors.units.type === "required" && <div className="invalid-feedback">Units is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Lab Units <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="labUnits"
                  defaultValue={props.doc.LabUnits === undefined ? "" : props.doc.LabUnits}
                  className={`form-control${errors.labUnits === undefined ? "" : errors.labUnits && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your lab units.</span>
                {errors.labUnits && errors.labUnits.type === "required" && <div className="invalid-feedback">Lab units is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Lab Hours <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="labHours"
                  defaultValue={props.doc.LabHours === undefined ? "" : props.doc.LabHours}
                  className={`form-control${errors.labHours === undefined ? "" : errors.labHours && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your lab hours.</span>
                {errors.labHours && errors.labHours.type === "required" && <div className="invalid-feedback">Lab hours is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Lec Units <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="lecUnits"
                  defaultValue={props.doc.LecUnits === undefined ? "" : props.doc.LecUnits}
                  className={`form-control${errors.lecUnits === undefined ? "" : errors.lecUnits && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your lec units.</span>
                {errors.lecUnits && errors.lecUnits.type === "required" && <div className="invalid-feedback">Lec units is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Lec Hours <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="lecHours"
                  defaultValue={props.doc.LecHours === undefined ? "" : props.doc.LecHours}
                  className={`form-control${errors.lecHours === undefined ? "" : errors.lecHours && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your lec hours.</span>
                {errors.lecHours && errors.lecHours.type === "required" && <div className="invalid-feedback">Lec hours is required</div>}
              </div>
              <SubjectCategorySelect
                refOnHandleChange={selectHandleChange('subjectCategoryId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={false}
                forwardRef={setValue}
                selectedId={props.doc.SubjectCategoryId === undefined ? "" : props.doc.SubjectCategoryId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.subjectCategoryId}
                refErrorMessage={errors.subjectCategoryId && errors.subjectCategoryId.type === "required" && 'Subject category is required'}
              />
              <SubjectTypeSelect
                refOnHandleChange={selectHandleChange('subjectTypeId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.SubjectTypeId === undefined ? "" : props.doc.SubjectTypeId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.subjectTypeId}
                refErrorMessage={errors.subjectTypeId && errors.subjectTypeId.type === "required" && 'Subject is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Sequence : </label>
                <input
                  type="number"
                  name="displaySeq"
                  defaultValue={props.doc.DisplaySeq === undefined ? "" : props.doc.DisplaySeq}
                  className={`form-control`}
                  ref={register()}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your sequence.</span>
              </div>
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}