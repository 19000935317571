import { number } from "prop-types";
import React, { useRef, useEffect } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

});



export default function PrintReceipt(props) {
    let componentRef = useRef();
    const classes = useStyles();
    const { results } = props



    return (
        <>


            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn btn-success  btn-block">Print Receipt</button>}
                    content={() => componentRef}
                />


            </div>

            <div className="d-none" >``
                <div className="container" ref={(el) => (componentRef = el)}>
                    {/* <div className="" style={{ marginTop: "50px", marginLeft: "50px" }}>
                        {props.paymentDetails.map(data => {

                            return (
                                <>
                                    <p className="mb-1 mt-0 mx-o">{`${data.feeDesc}   :  ${data.totalAmount}`}</p>
                                </>
                            )
                        })}

                    </div> */}
                    <div className="" style={{ marginTop: "265px", marginLeft: "600px" }}>
                        <p className="">{`OR# ${props.orNo}`} </p>
                        <p className=" ">{props.date}</p>

                    </div>
                    <p className="mr-5 " style={{ marginLeft: "300px" }}>{`${props.studentNo} - ${props.name} `}</p>


                </div>


            </div>


        </>
    )
}