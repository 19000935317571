import React, { useState, useEffect } from "react";

import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { searchOfficialReceipt, getStudentPayments, savePayments } from "./__hooks__/index"
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import { useAppContext } from "../../../../contexts/useAppContext";
import SaveQuery from "../../../../../_metronic/functions/SaveQuery";

export default function PrintReceipt() {
    const { dispatch } = useAppContext();
    const [student, setStudent] = useState(null)
    const [ORNo, setORNo] = useState(undefined)
    const [payments, setPayments] = useState(null)
    const [isMigrating, setIsMigrating] = useState(false)
    const { setValue, handleSubmit, register } = useForm()
    const [isStudentSearch, setIsStudentSearch] = useState(false)


    const onSubmit = (or) => {
        executeSearchOfficialReceipt({ orNo: or })
    }

    const onSearchStudentPayment = () => {
        onSubmit(ORNo)
    }

    const onCancelSearch = () => {
        setStudent(null)
        setORNo(undefined)
    }

    const onSavePayments = () => {
        setIsMigrating(true)
        SaveQuery(savePayments, payments, setIsMigrating)
    }

    const executeSearchOfficialReceipt = async (val) => {
        await searchOfficialReceipt(val)
            .then(res => {
                if (res.length > 0) {
                    setStudent(res[0])
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Official Reciept Not Found'
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Official Reciept Not Found'
                });
            })
    }

    const executeGetAllPayments = async (val) => {
        await getStudentPayments(val)
            .then(res => {
                console.log(res)
                if (res.length > 0) {
                    setPayments(res)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student have no payments'
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student have no payments'
                });
            })
    }



    useEffect(() => {
        if (student !== null) {
            executeGetAllPayments({ studentNo: student.StudentNo })
        }
    }, [student])


    // useEffect(() => {
    //     if (payments !== null) {


    //     }
    // }, [payments])


    console.log(student)

    return (
        <>

            <div className="container">
                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MidName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>


                                {student !== null &&
                                    <>

                                        <button onClick={() => { onSavePayments() }} type="button" className="mt-5 btn btn-success mr-5 ">Import Student Payments</button>

                                    </>

                                }




                            </div>


                        </div>
                        <div className="col-12 col-xl-8">

                            <div className="p-5 mb-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Search Student Official Receipt</h3>

                                <section className="p-5">


                                    <TextField
                                        id="outlined-basic"
                                        label="Official Receipt"
                                        variant="outlined"
                                        name="ORNo"
                                        value={ORNo || ''}
                                        inputRef={register({
                                            required: true
                                        })}
                                        className="bg-white"
                                        size="small"
                                        fullWidth
                                        onChange={(e) => {
                                            setORNo(e.target.value)
                                        }}
                                    />
                                    {student === null &&
                                        <button onClick={() => { onSearchStudentPayment() }} type="button" className="mt-5 btn btn-primary mr-5 ">Search OR</button>
                                    }

                                    {student !== null &&
                                        <>

                                            <button onClick={() => { onCancelSearch() }} type="button" className="mt-5 btn btn-secondary mr-5 ">Cancel Search</button>

                                        </>

                                    }

                                </section>



                            </div>


                        </div>
                    </div>
                </div>
            </div>

















        </>
    )

}