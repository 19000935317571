import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';

export default function Form(props) {
  const { register, handleSubmit, errors } = useForm();
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true,
    IsDefault: props.doc.IsDefault === undefined ? false : props.doc.IsDefault === 0 ? false : true,
    AdmissionSem: props.doc.AdmissionSem === undefined ? false : props.doc.AdmissionSem === 0 ? false : true,
    AssessmentSem: props.doc.AssessmentSem === undefined ? false : props.doc.AssessmentSem === 0 ? false : true,
    EnrollmentSem: props.doc.EnrollmentSem === undefined ? false : props.doc.EnrollmentSem === 0 ? false : true,
    GradingSem: props.doc.GradingSem === undefined ? false : props.doc.GradingSem === 0 ? false : true
  });
  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const onSubmit = formValues => {
    const { semester, description, isActive, isDefault, admissionSem, assessmentSem, enrollmentSem, gradingSem } = formValues;

    const convertedFormValues = {
      semesterId: semester,
      semester: semester,
      description: description,
      isActive: isActive ? 1 : 0,
      isDefault: isDefault ? 1 : 0,
      admissionSem: admissionSem ? 1 : 0,
      assessmentSem: assessmentSem ? 1 : 0,
      enrollmentSem: enrollmentSem ? 1 : 0,
      gradingSem: gradingSem ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Semester <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="semester"
                  defaultValue={props.doc.Semester === undefined ? "" : props.doc.Semester}
                  className={`form-control${errors.semester === undefined ? "" : errors.semester && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                  maxLength="2"
                />
                <span className="form-text text-muted">Please enter your semester.</span>
                {errors.semester && errors.semester.type === "required" && <div className="invalid-feedback">Semester is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  /><br />
                  <FormControlLabel
                    name="isDefault"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsDefault} onChange={switchHandleChange('IsDefault')} value={switchState.IsDefault} />}
                    label="Default"
                  /><br />
                  <FormControlLabel
                    name="admissionSem"
                    inputRef={register()}
                    control={<Switch checked={switchState.AdmissionSem} onChange={switchHandleChange('AdmissionSem')} value={switchState.AdmissionSem} />}
                    label="Admission Sem"
                  /><br />
                  <FormControlLabel
                    name="assessmentSem"
                    inputRef={register()}
                    control={<Switch checked={switchState.AssessmentSem} onChange={switchHandleChange('AssessmentSem')} value={switchState.AssessmentSem} />}
                    label="Assessment Sem"
                  /><br />
                  <FormControlLabel
                    name="enrollmentSem"
                    inputRef={register()}
                    control={<Switch checked={switchState.EnrollmentSem} onChange={switchHandleChange('EnrollmentSem')} value={switchState.EnrollmentSem} />}
                    label="Enrollment Sem"
                  /><br />
                  <FormControlLabel
                    name="gradingSem"
                    inputRef={register()}
                    control={<Switch checked={switchState.GradingSem} onChange={switchHandleChange('GradingSem')} value={switchState.GradingSem} />}
                    label="Grading Sem"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}