import React, { useState, useEffect } from 'react';

const IGButtons = ({ periodId, result, instructionalGuideDetailId, checkIsEditable, onUpdateGradeElement, onDeleteGradeElement, id, scheduleTypeId }) => {
    const [isEditable, setIsEditable] = useState(false);

    useEffect(() => {
        async function fetchData() {
            const res = await checkIsEditable({
                schoolYear: result.SchoolYear,
                semesterId: result.SemesterId,
                subjectId: result.SubjectId,
                gradingPeriodId: periodId,
                scheduleTypeId: scheduleTypeId,
                employeeId: result.EmployeeId,
                classSectionId: result.ClassSectionId
            });
            setIsEditable(res.data.length > 0 && res.data[0].IsEditable === 1);
        }

        fetchData();
    }, [periodId]);

    const handleUpdateGrade = () => {
        onUpdateGradeElement(result, instructionalGuideDetailId);
    };

    const handleDeleteGrade = () => {
        onDeleteGradeElement(result);
    };

    const editButton = (
        <button
            className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
            onClick={handleUpdateGrade}
        >
            <i className="fa fa-pencil-alt text-white"></i>
        </button>
    );

    const deleteButton = (
        <button
            className="btn btn-xs btn-icon btn-icon-xs btn-danger"
            onClick={handleDeleteGrade}
        >
            <i className="fa fa-trash"></i>
        </button>
    );

    const disabledButton = (
        <button
            disabled
            className="btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1"
        >
            <i className="fa fa-pencil-alt text-white"></i>
        </button>
    );
    console.log(result)
    return (
        <>
            {isEditable ? editButton : disabledButton}
            {isEditable ? deleteButton : disabledButton}
        </>
    );
};

export default IGButtons;
