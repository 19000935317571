import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function MergeFacultyModal(props) {
    const { title, Content, isOpen, handleClose, onSubmit, classCodeSelect, isError, ErrorContent } = props;


    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <hr />

                {isError == true &&
                    <ErrorContent />

                }

                <Content
                    classCodeSelect={classCodeSelect}
                />

                <DialogActions>


                    <button onClick={() => { onSubmit() }} className="btn btn-success">
                        Merge
                    </button>


                    <button onClick={handleClose} className="btn btn-secondary">
                        Cancel
                    </button>

                </DialogActions>
            </Dialog>
        </>
    );
}