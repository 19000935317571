import React, { useState, useEffect } from "react"
import { getStudentCurriculum, getStudentAccount } from './__hooks__/index'
import { useAppContext } from "../../../../contexts/useAppContext"
import FaithLogo from "../../../../../_metronic/_assets/logo/FaithCollegesPNG.png"
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading"


export default function StudentCurriculumList() {
    const { state: { auth } } = useAppContext()
    const [studentAcc, setStudentAcc] = useState(null)
    const [studentCurriculum, setStudentCurriculum] = useState(null)

    // functions
    const getStudentAcc = async (val) => {
        await getStudentAccount(val)
            .then(resp => {
                if (resp.data.length > 0)
                    setStudentAcc(resp.data[0])
            })
            .catch(e => {

            })

    }

    const getStudentCurr = async (val) => {
        await getStudentCurriculum(val)
            .then(resp => {
                console.log(resp)
                setStudentCurriculum(resp)
            })
            .catch(e => {

            })
    }

    useEffect(() => {
        getStudentAcc({ studentId: auth.data.StudentId })
    }, [])


    useEffect(() => {
        if (studentAcc !== null)
            getStudentCurr({ courseId: studentAcc.CourseId, schoolYear: studentAcc.SchoolYear, semesterId: studentAcc.SemesterId })
    }, [studentAcc])


    console.log(auth)
    console.log(studentAcc)
    console.log(studentCurriculum)
    return (
        <>
            {studentCurriculum !== null && studentCurriculum !== undefined ?

                <div className="container">
                    <main className="card border-0 table-panel">

                        <div className="table-header">

                            <section className="card-header table-head text-center border-0">

                                <img style={{ width: '150px' }} src={FaithLogo} />
                                <h6 className="mt-5">{studentCurriculum[0].CourseDesc}</h6>
                                {/* <h4 className="">Course Outline Printing</h4> */}



                            </section>
                            <section className="card-body">
                                {studentCurriculum.length > 0 &&

                                    studentCurriculum.map(items => {

                                        return (
                                            <>
                                                <div className="">

                                                    <h3 className=" mt-2 lead">{`${items.YearSemester}`}</h3>

                                                    <hr />


                                                    <div className=" table-report">
                                                        {/* <h6 className="table-title">{det.Period}</h6> */}
                                                        <table className="table table-bordered ">

                                                            <thead>
                                                                <tr>
                                                                    <th scope="col">Subject Code</th>
                                                                    <th scope="col">Subject Description</th>
                                                                    <th scope="col">Units</th>
                                                                    <th scope="col">Lec / Lab Hours</th>
                                                                    {/* <th scope="col">Co-Requisite</th>
                                                                    <th scope="col">Pre-Requisite</th> */}


                                                                </tr>
                                                            </thead>
                                                            <tbody>
                                                                {items.Details.map(sub => {

                                                                    return (

                                                                        <>
                                                                            <tr>
                                                                                <th>{sub.SubjectCode}</th>
                                                                                <td>{sub.SubjectDescription}</td>
                                                                                <td>{sub.Units}</td>
                                                                                <td>{sub.LecLabHours}</td>
                                                                                {/* <td>{sub.CoRequisite}</td>
                                                                                <td>{sub.PreRequisite}</td> */}

                                                                            </tr>
                                                                        </>
                                                                    )
                                                                })}
                                                            </tbody>
                                                        </table>
                                                        {/* <p className="lead mb-5"> The total number of lecture and laboratory units per hour: <span><b>{det.TotalLabHours + det.TotalLecHours}</b> </span> </p> */}

                                                    </div>








                                                </div>
                                            </>
                                        )


                                    })



                                }
                            </section>
                        </div>
                    </main>
                </div>

                :

                <Loading />

            }


        </>
    )
}