import instance from "../../../../../../apis/local/systemAPI";

export const getInstructionalGuideSchedule = async (values) => {
    const result = await instance.post("api/grades/instructional-guide-detail/schedule", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradeBookSummaryList = async (values) => {
    const result = await instance.post("api/grades/gbook-raw-grade/gradeBookSummaryList", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradeBookSummary = async (values) => {
    const result = await instance.post("api/grades/gbook-raw-grade/gradeBookSummary", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createInstructionalGuideDetail = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide-detail/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateInstructionalGuideDetail = async (id, formValues) => {
    const result = await instance.patch(`api/grades/instructional-guide-detail/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteInstructionalGuideDetail = async (id) => {
    const result = await instance.delete(`api/grades/instructional-guide-detail/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstructionalGuide = async (data) => {
    const result = await instance.post(`api/grades/instructional-guide/getInstructionalGuideListById`, data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGradeBookSummaryFinals = async (data) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/gradeBookSummaryFinals`, data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGradingPeriod = async () => {
    const result = await instance.get(`api/references/grading-period/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getExamType = async () => {
    const result = await instance.get(`api/student/exam-types/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateGradeFinalsDetails = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/addUpdateGBookFinalDetails`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGBookFinalGradeDetailByStudentId = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/getGBookFinalDetailByStudentId`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deanPosting = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/deanPosting`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const chairPosting = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/chairPosting`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGBookFinalGradeIds = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/getGBookFinalGradeId`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const unPostGrades = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/unPost`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const authenticateGrades = async (val) => {
    const result = await instance.post(`api/grades/gbook-raw-grade/authenticate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getExistingFinalGrade = async (val) => {
    const result = await instance.post(`api/grades/final-grades/getExistingFinalGrade`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const postGrades = async (val) => {
    const result = await instance.post(`api/grades/final-grades/postGrades`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const saveFinalGrade = async (val) => {
    const result = await instance.post(`api/grades/final-grades/addUpdate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const saveFinalGradeAll = async (val) => {
    const result = await instance.post(`api/grades/final-grades/addUpdateAll`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchedule = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/getSchedule`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}