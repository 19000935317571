export const referenceTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_SUBJECT_SCHEDULE: 'SELECTED-SUBJECT-SCHEDULE',
    GET_SUBJECT_SCHEDULES: 'GET-SUBJECT-SCHEDULES',
    GET_SUBJECT_SCHEDULE: 'GET-SUBJECT-SCHEDULE-BY-ID',
    CREATE: 'CREATE-SUBJECT-SCHEDULE',
    UPDATE: 'UPDATE-SUBJECT-SCHEDULE',
    DELETE: 'DELETE-SUBJECT-SCHEDULE',
    LIST_LINK: '/admin/enrollment/subject-schedules',
    CREATE_LINK: '/admin/enrollment/create-subject-schedules/',
    UPDATE_LINK: '/admin/enrollment/update-subject-schedules/',
    MORE_LINK: '/admin/enrollment/faculty-loading-details/',
    DELETE_LINK: '/admin/enrollment/delete-subject-schedules/'
}