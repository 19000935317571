import instance from '../../../../../apis/local/systemAPI';

// START OF SCHOOL YEAR
export const getActiveSchoolYears = async () => {
  const result = await instance.get("api/references/schoolyears");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchoolYears = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/schoolyears/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "schoolYear": keyword,
            "description": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/schoolyears/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createSchoolYear = async (formValues) => {
  const result = await instance.post("api/references/schoolyears", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateSchoolYear = async (id, formValues) => {
  const result = await instance.patch(`api/references/schoolyears/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteSchoolYear = async (id) => {
  const result = await instance.delete(`api/references/schoolyears/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF SCHOOL YEAR