import instance from "../../../../../apis/local/systemAPI";


export const getRecieptAssignment = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/payment/official-receipt-assignments/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "EI.LastName": keyword,
                        "EI.FirstName": keyword
                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/payment/official-receipt-assignments/q`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createRecieptAssignment = async (formValues) => {
    const result = await instance.post("api/payment/official-receipt-assignments/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateRecieptAssignment = async (id, formValues) => {
    const result = await instance.patch(`api/payment/official-receipt-assignments/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const deleteRecieptAssignment = async (id) => {
    const result = await instance.delete(`api/payment/official-receipt-assignments/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getUser = async (q) => {
    const result = await instance.post("api/user/qr", q);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateRecieptAssignment = async (formValues) => {
    const result = await instance.post("api/payment/official-receipt-assignments/addUpdate", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}