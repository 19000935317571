import React, { useState, useRef, forwardRef, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Button } from '@material-ui/core';
import './__hooks__/modal-styles.css';
import { useForm } from 'react-hook-form';import {
  verifyCredentials, getLastestPrintingDetails
} from './__hooks__';
import { useAppContext } from '../../../../../app/contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function ReprintModal(props) {
    const { title, content, isOpen, handleConfirm, handleClose, controlNumData } = props;
    const { register, handleSubmit, errors } = useForm();
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const [onAlert, setOnAlert] = useState(false);
    const [onEmptyFields, setOnEmptyFields] = useState(false);
    const [lastCreatedBy, setLastCreatedBy] = useState(null);
    const [lastDateCreated, setLastDateCreated] = useState(null);
    const [lastRemarks, setLastRemarks] = useState(null);

    const onModalClose = () => {

      document.getElementById('password').value = '';
      document.getElementById('reprint').value = '';

      setOnAlert(false);
      setOnEmptyFields(false);
      handleClose();
    }

    const onGetLatestReprintDetails = async () => {
      let values = {
          studentNo: controlNumData.studentNo,
          schoolYear: controlNumData.schoolYear,
          semesterId: controlNumData.semesterId,
          gradingPeriodId: controlNumData.gradingPeriodId,
      };

      await getLastestPrintingDetails(values)
          .then(result => {
              if (result.success) {
                if(result.data !== null){
                  setLastCreatedBy(result.data.CreatedBy == null ? '' : result.data.CreatedBy);
                  setLastDateCreated(result.data.DateCreated == null ? '' : result.data.DateCreated);
                  setLastRemarks(result.data.Remarks == null ? '' : result.data.Remarks);
                }
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
              }
          })
          .catch(error => {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          });
    }

    const onSubmit = formValues => {
      const onVerifyCredentials = async () => {
        setOnAlert(false);
        setOnEmptyFields(false);

        let values = {
            username: formValues.username,
            password: formValues.password,
            studentNo: controlNumData.studentNo,
            schoolYear: controlNumData.schoolYear,
            semesterId: controlNumData.semesterId,
            gradingPeriodId: controlNumData.gradingPeriodId,
            createdby: controlNumData.createdBy,
            remarks: formValues.reprint
        };

        await verifyCredentials(values)
            .then(result => {
                if (result.success) {
                    if(result.data.Result == 0){
                      setOnAlert(true);
                    } else {
                      document.getElementById('username').value = '';
                      document.getElementById('password').value = '';
                      document.getElementById('reprint').value = '';
                      handleConfirm();
                    }           
                } else {
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                }
            })
            .catch(error => {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
            });
      }

      if(formValues.password == '' || formValues.reprint == ''){
          setOnEmptyFields(true);
          setOnAlert(false);
      } else {
        onVerifyCredentials();
      }
    };

    useEffect(() => {
      onGetLatestReprintDetails();
    }, []);

    

    return (
        <>
          <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onConfirm={handleConfirm}
            onClose={handleClose}
            maxWidth="sm"
            disableBackdropClick="true"
            fullWidth>

            <DialogTitle>{title}</DialogTitle>

            <hr />
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>

            <div className="form-row reprint-card-modal">
                <div className="form-group col">
                    <label >Last Printed By: <span style={{fontWeight:"600"}}>{lastCreatedBy}</span></label>
                </div>
            </div>

            <div className="form-row reprint-card-modal">
                <div className="form-group col">
                    <label >Last Printed Date: <span style={{fontWeight:"600"}}>{lastDateCreated}</span></label>
                </div>
            </div>
            
            <div className="form-row reprint-card-modal">
                <div className="form-group col">
                    <label >Remark/Reason: <span style={{fontWeight:"600"}}>{lastRemarks}</span></label>
                </div>
            </div> 

            <hr />
            <DialogContent>
                <DialogContentText>Print again?</DialogContentText>
            </DialogContent>

            {onAlert == true &&
              <div className="alert alert-danger reprint-card-modal" role="alert">
                  Wrong Credentials!
              </div>
            }

            
            {onEmptyFields == true &&
              <div className="alert alert-danger reprint-card-modal" role="alert">
                  Credentials and Remarks are required!
              </div>
            }

              <div className="form-row reprint-card-modal">
                <div className="form-group col">
                  <label htmlFor="username">Username</label>
                  <input
                    type="username"
                    className={`form-control`}
                    id="username"
                    name="username"
                    placeholder="Username"
                    ref={register({
                      required: false
                    })}
                  />
                </div>
              </div>

              <div className="form-row reprint-card-modal">
                <div className="form-group col">
                  <label htmlFor="password">Password</label>
                  <input
                    type="password"
                    className={`form-control`}
                    id="password"
                    name="password"
                    placeholder="Password"
                    ref={register({
                      required: false
                    })}
                  />
                </div>
              </div>

              <div className="form-row reprint-card-modal">
                <div className="form-group col">
                  <label htmlFor="reprint">Remarks</label>
                  <input
                    type="text"
                    className={`form-control`}
                    id="reprint"
                    name="reprint"
                    placeholder="Enter Remarks/Reason for Reprint"
                    ref={register({
                      required: false
                    })}
                  />
                </div>
              </div>

              <div className="form-group d-flex flex-wrap justify-content-between align-items-center" style={{margin:"0% 5%"}}>
                <button onClick={handleSubmit(onSubmit)} className={`btn btn-success`}>Confirm</button>
                <button onClick={onModalClose} className={`btn btn-secondary`}>Cancel</button>
              </div> 

            <DialogActions>
                {/* <Button onClick={handleConfirm} variant="contained" color="danger">
                    Confirm
                </Button>

                <Button onClick={handleClose} variant="contained" color="danger">
                    Cancel
                </Button> */}
            </DialogActions>
          </Dialog>
        </>
    );
}