import combineReducers from "./combineReducers";
// AUTH
import AuthReducer from './modules/Auth/__hooks__/reducer';
// ADMIN - REFERENCES
import FeeReducer from './modules/Admin/References/Fee/__hooks__/reducer';
import FeeGroupReducer from './modules/Admin/References/FeeGroup/__hooks__/reducer';
import ScholarshipReducer from './modules/Admin/References/Scholarship/__hooks__/reducer';
import ScholarshipFeeGroupReducer from './modules/Admin/References/Scholarship/ScholarshipFeeGroup/__hooks__/reducer';
import ScholarshipTagReducer from './modules/Admin/References/ScholarshipTag/__hooks__/reducer';
import InstituteReducer from './modules/Admin/References/Institute/__hooks__/reducer';
import StudentCategoryReducer from './modules/Admin/References/StudentCategory/__hooks__/reducer';
import CourseReducer from './modules/Admin/References/Course/__hooks__/reducer';
import YearLevelReducer from './modules/Admin/References/YearLevel/__hooks__/reducer';
import ClassSectionReducer from './modules/Admin/References/ClassSection/__hooks__/reducer';
import SchoolYearReducer from './modules/Admin/References/SchoolYear/__hooks__/reducer';
import SemesterReducer from './modules/Admin/References/Semester/__hooks__/reducer';
import CivilStatusReducer from './modules/Admin/References/CivilStatus/__hooks__/reducer';
import ModeOfPaymentReducer from './modules/Admin/References/ModeOfPayment/__hooks__/reducer';
import PaymentScheduleReducer from './modules/Admin/References/PaymentSchedule/__hooks__/reducer';
import ReligionReducer from './modules/Admin/References/Religion/__hooks__/reducer';
import StudyTypeReducer from './modules/Admin/References/StudyType/__hooks__/reducer';
import SubjectTypeReducer from './modules/Admin/References/SubjectType/__hooks__/reducer';
import SubjectCategoryReducer from './modules/Admin/References/SubjectCategory/__hooks__/reducer';
import SubjectReducer from './modules/Admin/References/Subject/__hooks__/reducer';
import CountryReducer from './modules/Admin/References/Country/__hooks__/reducer';
import ProvinceReducer from './modules/Admin/References/Province/__hooks__/reducer';
import MunicipalityReducer from './modules/Admin/References/Municipality/__hooks__/reducer';
import NationalityReducer from './modules/Admin/References/Nationality/__hooks__/reducer';
import SchoolReducer from './modules/Admin/References/School/__hooks__/reducer';
import EducationReducer from './modules/Admin/References/Education/__hooks__/reducer';
import CourseOutlineReducer from './modules/Admin/References/CourseOutline/__hooks__/reducer';
import CourseOutlineDetailReducer from './modules/Admin/References/CourseOutline/CourseOutlineDetail/__hooks__/reducer';
import CourseOutlineDetailReqReducer from './modules/Admin/References/CourseOutline/CourseOutlineDetail/CourseOutlineReq/__hooks__/reducer';
import RoomReducer from './modules/Admin/References/Room/__hooks__/reducer';
import ComputationTypeReducer from './modules/Admin/References/ComputationType/__hooks__/reducer';
import FamilyNetIncomeReducer from './modules/Admin/References/FamilyNetIncome/__hooks__/reducer';
import EducationalPlanReducer from './modules/Admin/References/EducationalPlan/__hooks__/reducer';
import FamilyPositionReducer from './modules/Admin/References/FamilyPosition/__hooks__/reducer';

// ADMIN - ENROLLMENT
import SubjectScheduleReducer from './modules/Admin/Enrollment/SubjectSchedule/__hooks__/reducer';
import SubjectScheduleDetailReducer from './modules/Admin/Enrollment/SubjectSchedule/SubjectScheduleDetail/__hooks__/reducer';
import AssessmentOfFeeReducer from './modules/Admin/Enrollment/AssessmentOfFee/__hooks__/reducer';
import AssessmentTemplateDetailReducer from './modules/Admin/Enrollment/AssessmentOfFee/AssessmentDetail/__hooks__/reducer';
import AssessmentTemplateSchedule from './modules/Admin/Enrollment/AssessmentOfFee/AssessmentSchedule/__hooks__/reducer';
import AssessmentTemplateScheduleDetail from './modules/Admin/Enrollment/AssessmentOfFee/AssessmentDetail/AssessmentDetailOther/__hooks__/reducer';
import AssessmentTemplateOther from './modules/Admin/Enrollment/AssessmentOfFee/AssessmentOther/__hooks__/reducer';
import AssessmentTemplateOtherRate from './modules/Admin/Enrollment/AssessmentOfFee/AssessmentOther/AssessmentOtherRate/__hooks__/reducer';
// MENUS
import MenuReducer from '../_metronic/layout/components/aside/aside-menu/__hooks__/reducer';
// COMPONENTS
import SnackbarReducer from '../_metronic/layout/components/snackbar/__hooks__/reducer';
// ADMIN - ADMISSIONS 
import ApplicantDataReducer from './modules/Admin/Admissions/ApplicantDataEntry/__hooks__/reducer';
//import ApplicantDataEntryDetailReducer from './modules/Admin/Admissions/ApplicantDataEntry/ApplicantDataEntryDetail/__hooks__/reducer';

const rootReducer = combineReducers({
  auth: AuthReducer,
  menus: MenuReducer,
  snackbar: SnackbarReducer,
  fees: FeeReducer,
  feeGroups: FeeGroupReducer,
  scholarships: ScholarshipReducer,
  scholarshipFeeGroups: ScholarshipFeeGroupReducer,
  scholarshipTags: ScholarshipTagReducer,
  institutes: InstituteReducer,
  studentCategories: StudentCategoryReducer,
  courses: CourseReducer,
  yearLevels: YearLevelReducer,
  classSections: ClassSectionReducer,
  schoolYears: SchoolYearReducer,
  semesters: SemesterReducer,
  civilStatuses: CivilStatusReducer,
  modeOfPayments: ModeOfPaymentReducer,
  paymentSchedules: PaymentScheduleReducer,
  religions: ReligionReducer,
  studyTypes: StudyTypeReducer,
  subjectTypes: SubjectTypeReducer,
  subjectCategories: SubjectCategoryReducer,
  subjects: SubjectReducer,
  countries: CountryReducer,
  provinces: ProvinceReducer,
  municipalities: MunicipalityReducer,
  nationalities: NationalityReducer,
  schools: SchoolReducer,
  educations: EducationReducer,
  courseOutlines: CourseOutlineReducer,
  courseOutlineDetails: CourseOutlineDetailReducer,
  courseOutlineDetailReqs: CourseOutlineDetailReqReducer,
  subjectSchedules: SubjectScheduleReducer,
  subjectScheduleDetails: SubjectScheduleDetailReducer,
  assessmentTemplates: AssessmentOfFeeReducer,
  assessmentTemplateDetails: AssessmentTemplateDetailReducer,
  assessmentSchedules: AssessmentTemplateSchedule,
  assessmentScheduleDetails: AssessmentTemplateScheduleDetail,
  assessmentOthers: AssessmentTemplateOther,
  assessmentOtherRates: AssessmentTemplateOtherRate,
  rooms: RoomReducer,
  computationTypes: ComputationTypeReducer,
  applicantData:ApplicantDataReducer,
  familyNetIncome:FamilyNetIncomeReducer,
  educationalPlan:EducationalPlanReducer,
  familyPosition:FamilyPositionReducer
});

export default rootReducer;
