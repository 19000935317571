import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import IMTable from '../table/IMTable';
import Loading from '../forms/Loading';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal, Box } from '@material-ui/core';
import { useEffect } from 'react';
import { create } from 'lodash';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function GradeBookPostingFinalModal(props) {
    const { results, handleClose, isOpen, isInstructor, onPostGrades,
        onLoad, createdBy, date, isPosted, listToBePosted, getGBookFinalGradeDetailByStudentId,
        gBookFinalGradeIds, isAuthenticated, role, gradingPeriods } = props;
    const [formValues, setFormValues] = useState({})
    const classes = useRowStyles();
    const [rawGrades, setRawGrades] = useState()
    const [isValidScore, setIsValidScore] = useState(false)
    let cnt = 0

    const [toBePosted, setToBePosted] = useState(gBookFinalGradeIds)


    const onSave = () => {
        onPostGrades({ detailId: toBePosted.toString(), isDean: role.includes(7) ? true : false, isChair: role.includes(6) ? true : false })
        handleClose()
    }

    useEffect(() => {
        setToBePosted(gBookFinalGradeIds)
    }, [gBookFinalGradeIds])

    useEffect(() => {
        let updatedRawGrades = []; // create a copy of the rawGrades state

        results.map(row => {

            let gradingPeriods = [1, 2, 4]
            // if no existing grade is found, add a new grade object to the array
            for (let i = 0; i < gradingPeriods.length; i++) {
                updatedRawGrades.push({
                    finalGrade: row.ComputedGrades,
                    gBookFinalGradeId: row.GBookFinalGradeId,
                    studentId: row.StudentId,
                    semesterId: row.SemesterId,
                    schoolYear: row.SchoolYear,
                    createdBy: createdBy,
                    dateUpdated: date,
                    gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                    gradingPeriodId: gradingPeriods[i],
                    subjectId: row.SubjectId,
                    grade: gradingPeriods[i] == 1 ? parseFloat(row.PrelimGrade) : gradingPeriods[i] == 2 ? parseFloat(row.MidtermGrade) : gradingPeriods[i] == 4 ? parseFloat(row.FinalGrade) : 0.00,
                    isPosted: 1,
                    deanIsPosted: 0,
                    chairIsPosted: 0,
                    postingDate: date,
                    chairValidationDate: null,
                    deanValidationDate: null,
                    createdBy: createdBy,
                    dateUpdated: date,
                    remarks: '',
                    isForDeanValidation: false,
                    isForChairValidation: false


                });
            }

        })
        setRawGrades(updatedRawGrades); // update the rawGrades state with the new array


    }, [])

    useEffect(() => {
        if (rawGrades !== undefined && rawGrades !== null) {
            console.log(rawGrades)
            console.log(results)
            let totalRawGradesData = rawGrades.length
            let checked = 0
            for (let i = 0; i < rawGrades.length; i++) {
                console.log(parseInt(rawGrades[i].rawScore))
                console.log(parseInt(results[0].HighScore))
                if (parseInt(rawGrades[i].rawScore) <= parseInt(results[0].HighScore)) {
                    checked++
                }
            }
            console.log(checked + '- -' + totalRawGradesData)
            if (checked == totalRawGradesData) {
                setIsValidScore(true)
            } else {
                setIsValidScore(false)
            }
        }
    }, [rawGrades])





    console.log(props.value)
    console.log(formValues)
    console.log({ ...props.value, ...formValues })
    console.log(rawGrades)
    console.log(results)
    console.log(listToBePosted)
    console.log(toBePosted)
    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
                fullWidth
            >
                <section className='row'>
                    <div className='col-9'>
                        <DialogTitle id="alert-dialog-slide-title">Grade Book Details</DialogTitle>
                    </div>
                    <div className='col-3'>
                        <section className='mt-5 card p-5 bg-secondary mr-3'>
                            <table style={{ borderCollapse: 'collapse' }}>

                                <tbody>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Subject Code: </td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].SubjectCode}</td>
                                    </tr>
                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lec Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].LecPercentageShare}</td>
                                    </tr>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lab Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].LabPercentageShare}</td>
                                    </tr>


                                </tbody>
                            </table>
                        </section>
                    </div>
                </section>

                <hr />

                {results.length > 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center">#</TableCell>
                                        <TableCell align="center">Student No</TableCell>
                                        <TableCell align="left">Student Name</TableCell>
                                        {parseFloat(results[0].PrelimPercentage) > 0.00 && <TableCell align="left">Prelim</TableCell>}
                                        {parseFloat(results[0].MidtermPercentage) > 0.00 && <TableCell align="left">Midterm</TableCell>}
                                        {parseFloat(results[0].FinalPercentage) > 0.00 && <TableCell align="left">Finals</TableCell>}
                                        {/* <TableCell align="left">Remarks</TableCell> */}
                                        <TableCell align="center">Computed Grade</TableCell>
                                        {/* <TableCell align="center">Authenticate</TableCell> */}




                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((row) => {
                                        cnt++
                                        const handleCheckboxChange = async (item, studentId, schoolYear, semesterId) => {
                                            console.log(studentId)
                                            console.log(schoolYear)
                                            console.log(semesterId)

                                            await getGBookFinalGradeDetailByStudentId({
                                                studentId: studentId,
                                                schoolYear: schoolYear,
                                                semesterId: semesterId
                                            })
                                                .then(res => {
                                                    res.data.map(data => {
                                                        setToBePosted((prevSelectedItems) =>
                                                            prevSelectedItems.includes(data.GBookFinalGradeDetailsId)
                                                                ? prevSelectedItems.filter((selectedItem) => selectedItem !== data.GBookFinalGradeDetailsId)
                                                                : [...prevSelectedItems, data.GBookFinalGradeDetailsId]
                                                        );
                                                    })

                                                })

                                        };
                                        return (
                                            <>
                                                <TableRow className={classes.root}>
                                                    <TableCell align="center">{cnt}</TableCell>
                                                    <TableCell align="center">{row.StudentNo}</TableCell>
                                                    <TableCell align="left">{row.StudentName}</TableCell>


                                                    {parseFloat(row.PrelimPercentage) > 0.00 &&
                                                        <>
                                                            {row.PrelimIsPosted == 1
                                                                ? <TableCell className={parseFloat(row.CombinedPrelim) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.CombinedPrelim).toFixed(2)}</TableCell>
                                                                : <TableCell align="left"></TableCell>}
                                                        </>

                                                    }
                                                    {parseFloat(row.MidtermPercentage) > 0.00 &&
                                                        <>
                                                            {row.MidtermIsPosted == 1
                                                                ? <TableCell className={parseFloat(row.CombinedMidterm) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.CombinedMidterm).toFixed(2)}</TableCell>
                                                                : <TableCell align="left"></TableCell>}
                                                        </>

                                                    }
                                                    {parseFloat(row.FinalPercentage) > 0.00 &&
                                                        <>
                                                            {row.FinalIsPosted == 1
                                                                ? <TableCell className={parseFloat(row.CombinedFinals) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.CombinedFinals).toFixed(2)}</TableCell>
                                                                : <TableCell align="left"></TableCell>}
                                                        </>

                                                    }


                                                    <TableCell align="center">{parseFloat(row.PostedComputedGrades).toFixed(2)}</TableCell>


                                                    {/* <TableCell align="left">{row.Remarks}</TableCell> */}

                                                    {/* <TableCell align="center">
                                                        <input
                                                            onChange={e => { handleCheckboxChange(row.GBookFinalGradeDetailsId, row.StudentId, row.SchoolYear, row.SemesterId) }}
                                                            disabled={role.includes(7) ? (row.PrelimChairIsPosted == 0 || row.MidtermChairIsPosted == 0 || row.FinalChairIsPosted == 0) || (row.PrelimDeanIsPosted == 1 && row.MidtermDeanIsPosted == 1 && row.FinalDeanIsPosted == 1) : role.includes(6) ? row.IsPosted == 1 && row.PrelimChairIsPosted == 1 && row.MidtermChairIsPosted == 1 && row.FinalChairIsPosted == 1 : false}
                                                            checked={role.includes(6) ? row.IsPosted == 1 && row.PrelimChairIsPosted == 1 && row.MidtermChairIsPosted == 1 && row.FinalChairIsPosted == 1 ? true : toBePosted.includes(row.GBookFinalGradeDetailsId) : role.includes(7) ? row.IsPosted == 1 && row.PrelimChairIsPosted == 1 && row.MidtermChairIsPosted == 1 && row.FinalChairIsPosted == 1 ? true : toBePosted.includes(row.GBookFinalGradeDetailsId) : toBePosted.includes(row.GBookFinalGradeDetailsId)}
                                                            type='checkbox'
                                                        />
                                                    </TableCell> */}

                                                </TableRow>
                                            </>

                                        )

                                    })}

                                </TableBody>



                            </Table>


                        </TableContainer>
                    </>
                    :
                    <>
                        <Loading />
                    </>
                }
                {/* table where details will go */}


                <DialogActions>


                    {/* <button onClick={onSave} className="btn btn-success">
                        Authenticate Grades
                    </button> */}



                    <button onClick={handleClose} className="btn btn-secondary">
                        Back
                    </button>
                </DialogActions>
            </Dialog>
        </>




    );
}