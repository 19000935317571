import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import InstructionalGuideSchedule from './GradeBookSummary/List'
// import FinalGradeCompositionList from './FinalGradeComposition/List'
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';
import { getInstructionalGuideQuery, getSchedule } from "./__hooks__/index"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.png"
import { result } from 'lodash';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    chip: {
        margin: theme.spacing(1),
    },
}));

export default function GradeAuthenticationDetail(props) {
    const classes = useStyles();
    const [results, setResults] = useState(null)
    const { id } = useParams();
    const [expanded, setExpanded] = useState({
        periodicalGradeCompositionPanel: false,
        finalGradeCompositionPanel: false
    });
    const [scheduleType, setScheduleType] = useState(null)

    //   if (assessmentTemplates.data.length === 0) {
    //     history.push(enrollmentTypes.LIST_LINK_TO_PARENT);
    //     return null;
    //   }
    //   const assessmentTemplate = assessmentTemplates.data[id];
    //   const { ReferenceName, SchoolYear, SemesterId, StudentCategory, Institute, Course, YearLevel } = assessmentTemplate;

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };


    useEffect(() => {
        if (props.location.classSectionId == undefined) {
            history.push(`/admin/student/grade-authentication`)
        }
        const getCurrentInstructGuide = async (data) => {
            await getInstructionalGuideQuery(data)
                .then(res => {
                    setResults(res.data)
                })
        }

        const getScheduleType = async (data) => {
            await getSchedule(data)
                .then(res => {
                    setScheduleType(res.data.Description)
                })
        }

        getCurrentInstructGuide({ instructionalGuideId: parseInt(id) })
        getScheduleType({ instructionalGuideId: parseInt(id) })
    }, [])


    console.log(results)
    console.log(props.location.classSectionId)
    console.log(props.location)
    console.log(scheduleType)
    return (
        <>
            {results && results.length > 0 &&

                <>

                    <article className='card p-5'>
                        <section className='card-header'>
                            <h4> BASIC INFORMATION</h4>
                        </section>
                        <section className='card-body row'>
                            <div className='col-4'>
                                <div className="card ">
                                    <div className="card-header bg-secondary">
                                        <h5 className='text-center'>Instructor Details</h5>
                                    </div>
                                    <div className="card-body">
                                        <section className='border-bottom border-secondary text-center pb-5 text-center'>
                                            <img src={ProfileLogo} style={{ width: '150px' }} />
                                            <h5 className="card-title mt-5">{results[0].FacultyName}</h5>
                                            <p className="card-text lead">{results[0].Title}</p>
                                        </section>
                                        <section className='mt-5'>
                                            <table style={{ borderCollapse: 'collapse' }}>

                                                <tbody>
                                                    <tr className='mb-3'>
                                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Subject Type</td>
                                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{props.location.instructionalScheduleTypeId == 3 ? 'Lecture / Laboratory' : props.location.instructionalScheduleTypeId == 2 ? 'Laboratory' : props.location.instructionalScheduleTypeId == 1 ? 'Lecture' : ''}</td>
                                                    </tr>
                                                    <tr className='mb-3'>
                                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Class Section: </td>
                                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{props.location.classSection}</td>
                                                    </tr>
                                                    <tr className='mb-3'>
                                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Subject Code: </td>
                                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].SubjectCode}</td>
                                                    </tr>

                                                    <tr className='mb-3'>
                                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Number of Units</td>
                                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].Units}</td>
                                                    </tr>


                                                </tbody>
                                            </table>
                                        </section>
                                    </div>
                                    <div className="card-footer text-muted">
                                        {/* Units: <span>{results[0].Units}</span> */}
                                    </div>
                                </div>
                            </div>
                            <div className='col-8 text-left'>

                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header" style={{ paddingLeft: "15px" }}>
                                        <div className="card-title ">
                                            <h4 className='lead '>Grade Book</h4>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                        <div className={classes.root}>

                                            <Accordion expanded={true} onChange={handleChange('finalGradeCompositionPanel')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2bh-content"
                                                    id="panel2bh-header"
                                                    style={{ background: "#efefef" }}
                                                >
                                                    <Typography className={classes.heading}><span className="text-primary lead"><b>Summary of Grades</b></span></Typography>
                                                    <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ flexDirection: "column", padding: "0" }}>
                                                    <InstructionalGuideSchedule
                                                        id={parseInt(id)}
                                                        data={results[0]}
                                                        classSectionId={props.location.classSectionId}
                                                        subjectScheduleDetailId={props.location.subjectScheduleDetailId}
                                                        scheduleType={scheduleType}
                                                        scheduleTypeId={props.location.instructionalScheduleTypeId}
                                                    />
                                                </AccordionDetails>
                                            </Accordion>

                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ padding: '0px', margin: '0px' }}>
                                        <Link to={referenceTypes.LIST_LINK_TO_PARENT} className="btn btn-secondary m-3">Back</Link>
                                    </div>
                                </div>


                            </div>
                        </section>
                    </article>



                </>

            }

        </>
    );
}