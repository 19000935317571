export const referenceTypes = {
    SELECTED_SCHOLARSHIP_FEEGROUP: 'SELECTED-SCHOLARSHIP-FEEGROUP',
    GET_SCHOLARSHIP_FEEGROUPS: 'GET-SCHOLARSHIP-FEEGROUPS',
    GET_SCHOLARSHIP_FEEGROUPS_BY_SCHOLARSHIP_ID: 'GET-SCHOLARSHIP-FEEGROUPS-BY-SCHOLARSHIP-ID',
    GET_SCHOLARSHIP_FEEGROUP: 'GET-SCHOLARSHIP-FEEGROUP',
    CREATE: 'CREATE-SCHOLARSHIP-FEEGROUP',
    UPDATE: 'UPDATE-SCHOLARSHIP-FEEGROUP',
    DELETE: 'DELETE-SCHOLARSHIP-FEEGROUP',
    LIST_LINK_TO_PARENT: '/admin/references/scholarships',
    LIST_LINK: '/admin/references/scholarship-feegroups',
    CREATE_LINK: '/admin/references/create-scholarship-feegroups/',
    UPDATE_LINK: '/admin/references/update-scholarship-feegroups/',
    DELETE_LINK: '/admin/references/delete-scholarship-feegroups/'
}