import instance from "../../../../../apis/local/systemAPI";



export const getStudentAssessmentTemplateDetail = async (val) => {
    const result = await instance.post(`/api/assessment/template-details/studentAssessmentTemplateDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const processStudentPayment = async (val) => {
    const result = await instance.post(`/api/payment/studentPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const processStudentPaymentDetails = async (val) => {
    const result = await instance.post(`/api/payment-details/studentPaymentDetails`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const processStudentPaymentParticulars = async (val) => {
    const result = await instance.post(`/api/payment-details/studentPaymentParticulars`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentStudentSubjectScheduleDetail = async (val) => {
    const result = await instance.post(`api/schedule/details/studentSubjectScheduleDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentCashieringAccount = async (val) => {
    const result = await instance.post(`api/cashiering/student/account`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCashieringPaymentSchedule = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierPaymentSchedule`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCashieringPaymentScheduleSOA = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierPaymentScheduleSOA`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCashieringPaymentDetailsSOA = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierPaymentDetailsSOA`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDetailsSOA = async (val) => {
    const result = await instance.post(`api/cashiering/student/studentDetailsSOA`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentRegistrarAccount = async (val) => {
    const result = await instance.post(`api/registrar/student/account`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDiscount = async (val) => {
    const result = await instance.post(`api/cashiering/student/discount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/fees`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentListOfFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/listOfFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentPayments = async (val) => {
    const result = await instance.post(`api/cashiering/student/payments`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentCampusDiscount = async (data) => {
    const result = await instance.post("api/campus/discount-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getStudentFeesWithParticulars = async (val) => {
    const result = await instance.post(`api/cashiering/student/feesWithParticulars`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getStudentTotalPayments = async (val) => {
    const result = await instance.post(`api/cashiering/student/totalPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentAstraSubjects = async (val) => {
    const result = await instance.post(`api/schedule/details/studentSubjects`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentPaidFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/paidFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getStudentPaidOtherFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/paidOtherFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentOtherFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/listOfOtherFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentReservationFees = async (val) => {
    const result = await instance.post(`api/assessment/student-other-fees/studentReservationFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentAvailableFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/availableFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const getStudentAvailableOtherFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/availableOtherFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const studentPayment = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierDirectPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const studentCashierPayment = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentCashierDetailPayment = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierDetailPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getAssignOr = async (val) => {
    const result = await instance.post(`api/payment/official-receipt-assignments/qr`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateORNo = async (val) => {
    const result = await instance.post(`api/payment/official-receipt-assignments/updateOr`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getLatestORNo = async (val) => {
    const result = await instance.post(`api/payment/official-receipt-assignments/latestOr`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getExistingPaymentScheduleId = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierGetPaymentSchedule`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentExistingAssessments = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierGetStudentListOfPayment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAdditionalFees = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierGetAdditionalFees`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollmentDiscount = async (val) => {
    const result = await instance.post(`api/accounting/student/getEnrollmentDiscount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentAssessmentOtherFees = async (val) => {
    const result = await instance.post(`api/assessment/student-other-fees/studentOtherFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getStudentDiscountParticulars = async (val) => {
    const result = await instance.post(`api/cashiering/student/discountParticulars`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentAddOns = async (data) => {
    const result = await instance.post("api/assessment/student-other-fees/studentAddOns", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateEnrollment = async (id, formValues) => {
    const result = await instance.patch(`api/enrollments/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const addUpdateEnrollmentDiscount = async (val) => {
    const result = await instance.post(`api/accounting/student/addUpdateEnrollmentDiscount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getStudentAddDropSubjects = async (data) => {
    const result = await instance.post("api/student/student-enrollment-subjects/getStudentSubjectsWithSchedule", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const assignStudentCurriculum = async (val) => {
    const result = await instance.post(`api/student/course-outlines/assignStudentCurriculum`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentExcessFee = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierGetExcessFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const sendMail = async (values) => {
    const result = await instance.post("api/nodemailer/send-mail", values);
    if (result.status === 200 || result.status === 201) {
        // alert('email send')
    }
}

export const createAssessmentAddOns = async (formValues) => {
    const result = await instance.post("api/assessment/add-ons", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFees = async () => {
    const result = await instance.get("api/references/fees");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAvailableSOA = async (val) => {
    const result = await instance.post(`api/cashiering/student/getAvailableSOA`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}