import instance from '../../../../../apis/local/systemAPI';
import { referenceTypes } from './types';

// START OFSCHOOL
export const getSchools = async () => {
  const result = await instance.get("api/references/schools");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchoolInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/schools/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "School": keyword,
            "ShortName": keyword


          }
          ]
        }
      ]
      , start: page + 1
      , limit: 1
    }
    result = await instance.get(`api/references/schools`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getStudentCategory = async id => {
  let params = {
    key: referenceTypes.GET_SCHOOL_BY_ID,
    id: id
  };

  const result = await instance.post("/i-reference-request", params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createSchoolDetail = async (formValues) => {
  const result = await instance.post("api/references/schools/addUpdate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateSchoolEntry = async (id, formValues) => {
  const result = await instance.patch(`api/references/schools/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteSchoolEntry = async (id) => {
  const result = await instance.post(`api/references/schools/delete`, id);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addUpdateSchool = async (val) => {
  const result = await instance.post(`api/references/schools/addUpdate`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

// END OF SCHOOL