import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControlLabel, Switch } from '@material-ui/core';
import { YearLevelSelect, SemesterSelect, SubjectSelect } from '../../';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    subjectId: null,
    semesterId: null,
    yearLevelId: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'subjectId' }, { required: true });
    register({ name: 'semesterId' }, { required: true });
    register({ name: 'yearLevelId' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { yearLevelId, semesterId, subjectId, overrideDescription, isActive } = formValues;

    const convertedFormValues = {
      courseOutlineId: props.doc.CourseOutlineId,
      yearLevelId: yearLevelId,
      semesterId: semesterId,
      subjectId: subjectId,
      overrideDescription: overrideDescription,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <SubjectSelect
                refOnHandleChange={selectHandleChange('subjectId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.SubjectId === undefined ? "" : props.doc.SubjectId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.subjectId}
                refErrorMessage={errors.subjectId && errors.subjectId.type === "required" && 'Subject is required'}
              />
              <SemesterSelect
                refOnHandleChange={selectHandleChange('semesterId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.EffectiveSemesterId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.semesterId}
                refErrorMessage={errors.semesterId && errors.semesterId.type === "required" && 'Semester is required'}
              />
              <YearLevelSelect
                refOnHandleChange={selectHandleChange('yearLevelId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.YearLevelId === undefined ? "" : props.doc.YearLevelId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.yearLevelId}
                refErrorMessage={errors.yearLevelId && errors.yearLevelId.type === "required" && 'Year level is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Override Description : </label>
                <input
                  type="text"
                  name="overrideDescription"
                  defaultValue={props.doc.OverrideDescription === undefined ? "" : props.doc.OverrideDescription}
                  className={`form-control${errors.overrideDescription === undefined ? "" : errors.overrideDescription && " is-invalid"}`}
                  ref={register()}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your override description.</span>
                {errors.overrideDescription && errors.overrideDescription.type === "required" && <div className="invalid-feedback">Override description is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControlLabel
                  name="isActive"
                  inputRef={register()}
                  control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                  label="Active"
                />
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={`${referenceTypes.LIST_LINK}/${props.doc.CourseOutlineId}`} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}