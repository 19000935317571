import React, { useState, forwardRef, useRef } from 'react';
import { Dialog, DialogActions, DialogTitle, Slide } from '@material-ui/core';
import Loading from '../forms/Loading';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { useEffect } from 'react';
import Notification from '../notification/Notification';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function StudentComponentModal(props) {
    const { results, handleClose, isOpen, onSubmit, data, onSaveRawGrades, onSaveIsVisible, isStudent,
        isViewingOfSummary, onLoad, classSectionId, isDean, hps, isPosted, onPostGrades, date, createdBy,
        isPercentageClear, scheduleTypeId } = props;
    const [formValues, setFormValues] = useState({})
    const classes = useRowStyles();
    const [isLoading, setIsLoading] = useState(false)
    const [rawGrades, setRawGrades] = useState()
    const [isComputing, setIsComputing] = useState(false)
    const [currentIndex, setCurrentIndex] = useState(0)
    const [showNotification, setShowNotification] = useState(false);

    const [isValidScore, setIsValidScore] = useState(false)
    const onSave = () => {
        onPostGrades(rawGrades)
        setIsValidScore(false)
        handleClose()
    }

    


    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
                fullWidth
            >
                <DialogTitle className='pb-1' id="alert-dialog-slide-title mb-0">Raw Grades</DialogTitle>
                {/* <DialogTitle className='pt-0 text-primary' id="alert-dialog-slide-title ">{results.length > 0 && results[0].ComponentDesc}</DialogTitle> */}

                {/* {showNotification && <Notification className='m-5 col-4' message={`The highest score is ${hps}`} />} */}

                {/* <hr /> */}

                {results.length > 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center" style={{fontWeight:"bold"}}>Components</TableCell>
                                        <TableCell align="center" style={{fontWeight:"bold"}}>Raw Grades</TableCell>
                                        <TableCell align="center" style={{fontWeight:"bold"}}>High Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((row, index) => {
                                        return (
                                            <>
                                                <TableRow className={classes.root}>
                                                    <TableCell align="center">
                                                        {row.IsLecture == 1 ? "Lecture" : "Laboratory"}
                                                    </TableCell>
                                                    <TableCell align="center">{row.ShortName}</TableCell>
                                                    <TableCell align="center">{row.RawScore}</TableCell>
                                                    <TableCell align="center">{row.HighScore}</TableCell>
                                                </TableRow>
                                            </>
                                        )
                                    })}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </>
                    :
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center">Components</TableCell>
                                        <TableCell align="center">Raw Grades</TableCell>
                                        <TableCell align="center">High Score</TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    <TableRow className={classes.root}>
                                        <TableCell align="center">No Data available</TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                    </TableRow>
                                </TableBody>
                            </Table>
                        </TableContainer>
                        {/* <Loading /> */}
                    </>
                }
                {/* table where details will go */}


                <DialogActions>
                    {isLoading == false ?
                        <button onClick={handleClose} className="btn btn-secondary">
                            Back
                        </button>
                        :
                        <button className="btn btn-secondary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            <span className="sr-only">Loading...</span>
                        </button>
                    }
                </DialogActions>
            </Dialog>
        </>




    );
}