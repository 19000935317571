export const referenceTypes = {
    SELECTED_SUBJECT: 'SELECTED-SUBJECT',
    GET_SUBJECTS: 'GET-SUBJECS',
    GET_SUBJECT: 'GET-SUBJECT-BY-ID',
    CREATE: 'CREATE-SUBJECT',
    UPDATE: 'UPDATE-SUBJECT',
    DELETE: 'DELETE-SUBJECT',
    LIST_LINK: '/admin/references/subjects',
    CREATE_LINK: '/admin/references/create-subjects/',
    UPDATE_LINK: '/admin/references/update-subjects/',
    DELETE_LINK: '/admin/references/delete-subjects/',
    MORE_LINK: '/admin/references/subject-details/'
}