import React, { useState, useEffect } from "react";
import Form from "./Form"
import {
    createAssessmentAddOns, getAllInstitute, getAllCourse, getAllYearLevel, getAllSchoolYear,
    getAllSem, getAssessmentAddOns, getFees, studentAutoComplete, getAssessmentAddOnsQuery
} from "./__hooks__"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";

export default function CreateAssessmentAddOns() {
    const [results, setResults] = useState()
    const [isUpdated, setIsUpdated] = useState(false)
    const [institute, setInstitute] = useState()
    const [courses, setCourses] = useState()
    const [yearLevel, setYearLevel] = useState()
    const [sy, setSy] = useState()
    const [sem, setSem] = useState()
    const [instituteSelect, setInstituteSelect] = useState()
    const [coursesSelect, setCoursesSelect] = useState()
    const [yearLevelSelect, setYearLevelSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const [semSelect, setSemSelect] = useState()
    const [feeSelect, setFeeSelect] = useState(null)
    const [studentList, setStudentList] = useState([])
    const [studentId, setStudentId] = useState(null)


    const handleGetStudent = async (data) => {
        setStudentId(data.StudentId)
    }


    const handleStudentInputChange = async (input) => {
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }


    const Query = (operation, condition, setData) => {
        console.log(condition)
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        console.log(response.data)
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }


    const onSubmit = (values) => {
        const execute = async () => {
            await createAssessmentAddOns(values)
                .then(response => {

                })
                .catch(error => {

                })


        }
        execute()

    }

    const resultUpdated = (data) => {
        setIsUpdated(data)

    }
    let resultCon = "{ where: `ORDER BY DateUpdated DESC` }"

    const onFilterHandler = (courseId, yearLevelId, isIndividual) => {
        console.log(isIndividual)
        console.log(courseId)
        console.log(yearLevelId)
        let query = `{ where: "WHERE ${isIndividual == true ? 'S.StudentId IS NOT NULL' : 'S.StudentId IS NULL'} ${courseId != null ? `AND PA.CourseId = ${courseId}` : ''} ${yearLevelId != null ? `AND PA.YearLevelId = ${yearLevelId}` : ' '}  ORDER BY SI.LastName DESC" }`
        console.log(query)

        Query(getAssessmentAddOnsQuery, query, setResults)

    }

    useEffect(() => {
        // institute
        Query(getAllInstitute, null, setInstitute)
        // course
        Query(getAllCourse, null, setCourses)
        // year level
        Query(getAllYearLevel, null, setYearLevel)
        // school year
        Query(getAllSchoolYear, null, setSy)
        // semester
        Query(getAllSem, null, setSem)
        if (isUpdated === false) {
            // add ons
            Query(getAssessmentAddOns, resultCon, setResults)
        }

        SelectCreator(getFees, setFeeSelect, 'FeeId', 'Description')

    }, [])


    useEffect(() => {
        if (isUpdated === true) {
            // add ons
            Query(getAssessmentAddOns, resultCon, setResults)
            setIsUpdated(false)
        }
    }, [isUpdated])






    useEffect(() => {
        if (institute !== undefined && courses !== undefined && yearLevel !== undefined
            && sy !== undefined && sem !== undefined) {
            let instituteArr = []
            let coursesArr = []
            let yearLevelArr = []
            let syArr = []
            let semArr = []

            for (let c = 0; c < institute.length; c++) {
                instituteArr.push({ tableId: institute[c].InstituteId, code: institute[c].Code })
            }
            for (let v = 0; v < courses.length; v++) {
                coursesArr.push({ tableId: courses[v].CourseId, code: courses[v].Code })
            }
            for (let l = 0; l < yearLevel.length; l++) {
                yearLevelArr.push({ tableId: yearLevel[l].YearLevelId, code: yearLevel[l].Description })
            }
            for (let r = 0; r < sy.length; r++) {
                syArr.push({ tableId: sy[r].SchoolYear, code: sy[r].Description })
            }
            for (let k = 0; k < sem.length; k++) {
                semArr.push({ tableId: sem[k].SemesterId, code: sem[k].Description })
            }


            setInstituteSelect(instituteArr)
            setCoursesSelect(coursesArr)
            setYearLevelSelect(yearLevelArr)
            setSySelect(syArr)
            setSemSelect(semArr)


        }

    }, [institute, courses, yearLevel, sy, sem,])

    console.log(instituteSelect)
    console.log(coursesSelect)
    console.log(yearLevelSelect)
    console.log(sySelect)
    console.log(semSelect)
    console.log(results)


    console.log(feeSelect)

    return (
        <>
            {results !== undefined && instituteSelect !== undefined && coursesSelect !== undefined && yearLevelSelect !== undefined
                && sySelect !== undefined && semSelect !== undefined && feeSelect !== null


                ?
                <Form
                    results={results}
                    instituteSelect={instituteSelect}
                    courseSelect={coursesSelect}
                    yearLevelSelect={yearLevelSelect}
                    sySelect={sySelect}
                    semSelect={semSelect}
                    isResultUpdated={resultUpdated}
                    feeSelect={feeSelect}
                    handleGetStudent={handleGetStudent}
                    handleStudentInputChange={handleStudentInputChange}
                    studentList={studentList}
                    studentId={studentId}
                    onFilterHandler={onFilterHandler}
                />
                :
                ''
            }

        </>
    )
}