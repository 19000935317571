export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_OF_FEE: 'SELECTED-ASSESSMENT-OF-FEE',
    GET_ASSESSMENT_OF_FEES: 'GET-ASSESSMENT-OF-FEES',
    GET_ASSESSMENT_OF_FEE: 'GET-ASSESSMENT-OF-FEE-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-OF-FEE',
    UPDATE: 'UPDATE-ASSESSMENT-OF-FEE',
    DELETE: 'DELETE-ASSESSMENT-OF-FEE',
    LIST_LINK: '/admin/enrollment/assessment-of-fees',
    MORE_LINK: '/admin/enrollment/assessment-of-fee-details/',
    CREATE_LINK: '/admin/enrollment/create-assessment-of-fees/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-of-fees/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-of-fees/'
}