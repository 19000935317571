import instance from "../../../../../../apis/local/systemAPI";


export const getPaymentSchedule = async (con) => {
    const result = await instance.post("api/references/payment-schedules/s", con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createPaymentSchedule = async (formValues) => {
    const result = await instance.post("api/references/payment-schedules", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updatePaymentSchedule = async (id, formValues) => {
    const result = await instance.patch(`api/references/payment-schedules/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const deletePaymentSchedule = async (id) => {
    const result = await instance.delete(`api/references/payment-schedules/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const updateAssessmentTemplate = async (id, formValues) => {
    const result = await instance.patch(`api/assessment/templates/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentTemplates = async (con) => {
    const result = await instance.post("api/assessment/templates/q", con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addDuplicatePaymentSchedule = async (formValues) => {
    const result = await instance.post("api/references/payment-schedules/addDuplicate", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
