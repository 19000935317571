import React from 'react';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { deleteCivilStatus } from './__hooks__';

export default function Delete(props) {
  const { state: { civilStatuses }, dispatch } = useAppContext();
  const { id } = props.match.params;
  if (civilStatuses.data.length === 0) {
    history.push(referenceTypes.LIST_LINK);
    return null;
  }
  const civilStatus = civilStatuses.data[id];

  const onClickDelete = () => {
    const execute = async () => {
      await deleteCivilStatus(id)
        .then(response => {
          if (response.success) {
            dispatch({ type: referenceTypes.DELETE, data: id });
            history.push(referenceTypes.LIST_LINK);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  return (
    <>
      <div className="kt-container kt-grid__item kt-grid__item--fluid">
        <div className="row ac-minimum-height-container-350">
          <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
            <div className="card card-custom gutter-b example example-compact">
              <div className="card-body">
                <h3>Question</h3>
                <p>Are you sure you want to delete this record ( <b className="text-primary">{civilStatus === undefined ? null : civilStatus.CivilStatus}</b> ) ?</p>
                <Link to={referenceTypes.LIST_LINK} className="btn btn-lg btn-bold btn-upper btn-font-sm btn-secondary">No</Link>&nbsp;
                <button className="btn btn-lg btn-bold btn-upper btn-font-sm btn-success" onClick={onClickDelete} >Yes</button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}