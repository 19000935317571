import React, { useState, useEffect } from 'react';
import AddUpdateModal from '../../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import AddCell from '../../../../../../_metronic/layout/components/cell/AddCell';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuideSchedule, deleteInstructionalGuideSchedule,
    addUpdateInstructionalGuideSchedule,
    getCurrentInstructionalGuide,
    getScheduleClassSection, isWithLab
} from './__hooks__';
import { useParams } from 'react-router-dom';
import SelectCreator from '../../../../../../_metronic/functions/SelectCreator'
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { data } = props
    const { state: { fees, auth }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [instructionalGuide, setInstructionalGuide] = useState(null)
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)

    const [selectedEditIds, setSelectedEditIds] = useState(0)
    const [isLab, setIsLab] = useState(false)

    const classes = useStyles();
    const { id } = useParams()
    const [isExceedsPercentage, setIsExceedsPercentage] = useState(false)
    const updateId = fees.id;
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;

    const [classSectionSelect, setClassSectionSelect] = useState(null)


    let rowCnt = 0;


    const getIg = async (con) => {
        await getCurrentInstructionalGuide(con)
            .then(async res => {
                setInstructionalGuide(res.data)

            })
            .catch(e => {

            })
    }

    const getIGClassSections = async (con) => {
        await getScheduleClassSection(con)
            .then(res => {
                setClassSectionSelect(res.data)
            })
    }


    const checkIsLab = async (con) => {
        await isWithLab(con)
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    if (res.data[0].IsWithLab == 1) {
                        setIsLab(true)
                    } else {
                        setIsLab(false)

                    }
                } else {
                    setIsLab(false)
                }

            })
    }


    const onCheckSchedule = (event, val) => {
        let isLec = val.InstructionalScheduleTypeId == 1 ? true : false
        if (event == true) {
            onSaveGradeElement({
                instructionalGuideScheduleId: val.InstructionalGuideScheduleId,
                instructionalGuideId: val.InstructionalGuideId,
                instructionalScheduleTypeId: val.InstructionalScheduleTypeId,
                classSectionId: val.ClassSectionId,
                createdBy: auth.data.Username,
                dateUpdated: dateTime
            })
        } else {
            onDeleteElement(val.InstructionalGuideScheduleId, isLec)
        }
    }


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        await addUpdateInstructionalGuideSchedule({ ...val, instructionalGuideScheduleId: 0, createdBy: auth.data.Username, dateUpdated: null, instructionalGuideId: parseInt(id), subjectScheduleDetailId: data.SubjectScheduleDetailId })
            .then(res => {
                if (res.data.length > 0) {
                    setIsOpenAdd(false)
                    getIG();
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Schedule has been saved.' });
                } else {
                    setIsOpenAdd(false)
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'Schedule is already exists' });

                }


            })


    }

    // on update grade element
    const onUpdateElement = async (ids, val) => {
        await addUpdateInstructionalGuideSchedule({ ...val, dateUpdated: dateTime, createdBy: auth.data.Username, instructionalGuideId: parseInt(id) })
            .then(res => {
                setIsOpenUpdate(false)
                setIsExceedsPercentage(false)
                getIG()
                setSelectedEditIds(0)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide Detail has been updated.' });
            })

    }

    // on delete grade element
    const onDeleteElement = async (id, isLec) => {
        await deleteInstructionalGuideSchedule({ instructionalGuideScheduleId: id, subjectScheduleDetailId: data.SubjectScheduleDetailId, isLec: isLec })
            .then(res => {
                setIsOpenDelete(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Schedule has been Removed.' });
            })
            .catch(e => {

            })
    }



    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
        setIsExceedsPercentage(false)
    }

    //Update
    const onUpdateGradeElement = (data, id) => {
        console.log(data)
        setSelectedEditIds(id)
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setSelectedEditIds(0)
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = () => {
        setIsOpenDelete(false)
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    const getIG = async () => {
        await getInstructionalGuideSchedule({ instructionalGuideId: parseInt(id), classSectionId: data.ClassSectionId, subjectScheduleDetailId: data.SubjectScheduleDetailId })
            .then(response => {
                if (response.success) {
                    props.getInstructionalType(response.InstructionalTypes)
                    setResults(response.data);
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                }
            })
            .catch(error => {

                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

            })
    }



    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            instructionalScheduleTypeId: props.value !== undefined && props.value !== null ? props.value.instructionalScheduleTypeId : null,
            classSectionId: props.value !== undefined && props.value !== null ? props.value.classSectionId : null,
        })

        // Selects
        const selectHandleChange = name => event => { setData({ ...data, [name]: event }) }



        useEffect(() => {
            if (data) {

                props.onSave({
                    ...data
                })
            }

        }, [data])



        console.log(props.value)
        console.log(data)
        return (
            <>

                {/* <main className='container'> */}

                <TableCell className='p-1' align='center'></TableCell>
                <TableCell className='p-1' align='left'>
                    <IMSelect
                        onHandleChange={selectHandleChange('instructionalScheduleTypeId')}
                        refClassContainer=""
                        name="instructionalScheduleTypeId"
                        isRequired={false}
                        withLabel={false}
                        label="Type"
                        placeHolder="Select Type"
                        forwardRef={() => { }}
                        selectedId={data.instructionalScheduleTypeId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={true}
                        refStaticData={isLab == true ? [
                            { id: '', label: '' },
                            { id: 1, label: 'Lecture' },
                            { id: 2, label: 'Laboratory' }
                        ] : [
                            { id: '', label: '' },
                            { id: 1, label: 'Lecture' },
                        ]

                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                </TableCell>
                <TableCell className='p-1' align='left'>
                    <IMSelect
                        data={props.classSectionSelect}
                        onHandleChange={selectHandleChange('classSectionId')}
                        refClassContainer=""
                        name="classSectionId"
                        isRequired={false}
                        withLabel={false}
                        label="Class Section"
                        placeHolder="Select Class Section"
                        forwardRef={() => { }}
                        selectedId={data.classSectionId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                </TableCell>

                {/* </main> */}
            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.component : ''} `}</span> </p>
                </main>



            </>
        )
    }
    useEffect(() => {
        // SelectCreator(getGradingPeriod, setGradingPeriodSelect, "GradingPeriodId", "Description")
        // SelectCreator(getExamType, setExamTypeSelect, "ExamTypeId", "ShortName")

        getIg({ instructionalGuideId: parseFloat(props.id) })

    }, [])


    useEffect(() => {
        if (instructionalGuide !== null && instructionalGuide.length > 0) {
            getIGClassSections({ userId: auth.data.UserId, schoolYear: instructionalGuide[0].SchoolYear, semesterId: instructionalGuide[0].SemesterId })
            checkIsLab({ subjectCode: data.SubjectCode, schoolYear: instructionalGuide[0].SchoolYear, semesterId: instructionalGuide[0].SemesterId })
        }
    }, [instructionalGuide])



    useEffect(() => {
        getIG();
    }, [isOpenAdd, isOpenDelete, isOpenUpdate])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])


    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))


    console.log(props.id)


    console.log(results)
    console.log(instructionalGuide)
    console.log(data)

    return (
        <>
            {classSectionSelect &&
                <>




                    <AddUpdateModal
                        title="Delete Schedule"
                        isOpen={isOpenDelete}
                        Content={DeleteContent}
                        handleClose={onDeleteGradeElementClose}
                        isDelete={true}
                        onSubmit={onDeleteElement}
                        value={keysToCamelCase(rowDeleteValue)}
                        classSectionSelect={classSectionSelect}
                        id="instructionalGuideScheduleId"
                        idValue={props.id}
                        isExceedsPercentage={isExceedsPercentage}

                    />
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header d-block bg-white" style={{ minHeight: 0 }}>
                            <div className="card-title">
                                {/* <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> */}
                            </div>
                            {/* <div className="card-toolbar float-right">
                                <div className="example-tools">
                                    <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary p-1">
                                        <i className="fa fa-plus p-0"></i>
                                    </button>
                                </div>
                            </div> */}
                        </div>
                        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                            {/* <IMTable tableProps={tableProps} /> */}
                            <TableContainer >
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">#</TableCell>
                                            <TableCell>Schedule Type</TableCell>
                                            <TableCell align="center">Block Section</TableCell>
                                            <TableCell align="center">Schedule</TableCell>
                                            <TableCell align="center">Total Enrolled</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results.length > 0 ? results.map((result) => {
                                            const { InstructionalGuideScheduleId, Description, BlockSection, SchedTime, TotalEnrolled, IsTaken } = result;
                                            rowCnt += 1;
                                            console.log(InstructionalGuideScheduleId)
                                            return (
                                                <>
                                                    <TableRow key={InstructionalGuideScheduleId}>
                                                        <TableCell align="center">
                                                            <input
                                                                type='checkbox'
                                                                onChange={e => onCheckSchedule(e.target.checked, result)}
                                                                defaultChecked={InstructionalGuideScheduleId == 0 ? false : true}
                                                                disabled={IsTaken == 1 ? true : false}

                                                            />
                                                        </TableCell>
                                                        <TableCell component="th" scope="row">{Description}</TableCell>
                                                        <TableCell align="center">{BlockSection}</TableCell>
                                                        <TableCell align="center">{SchedTime}</TableCell>
                                                        <TableCell align="center">{TotalEnrolled}</TableCell>

                                                    </TableRow>


                                                </>

                                            );

                                        }) : <TableRow></TableRow>}
                                        {/* <AddCell
                                            title="Add Schedule"
                                            Content={Content}
                                            handleClose={onAddGradeElementClose}
                                            isAdd={true}
                                            onSubmit={onSaveGradeElement}
                                            classSectionSelect={classSectionSelect}
                                            id="instructionalGuideScheduleId"
                                            idValue={props.id}
                                            isExceedsPercentage={isExceedsPercentage}
                                        /> */}
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </>
            }

        </>
    );
}