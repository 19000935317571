import React from 'react';

function DashLine() {
    return (
        <div >
            <i className="fa fa-cut"></i><hr style={{ border: '0.5px dotted #D3D3D3' }} />
        </div>
    );
}

export default DashLine;