import instance from "../../../../../apis/local/systemAPI";



export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async () => {
    const result = await instance.post("api/references/semesters/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async () => {
    const result = await instance.post("api/references/schoolyears/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentReport = async (formValues) => {
    const result = await instance.post("api/payment/payment-report/search", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentReportHeaders = async (formValues) => {
    const result = await instance.post("api/payment/payment-report/getPaymentReportHeaders", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourse = async (json) => {
    const result = await instance.post("api/payment/payment-report/getCourses/",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevels = async () => {
    const result = await instance.get("api/payment/payment-report/getYearLevels/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentReportCashiers = async () => {
    const result = await instance.get("api/payment/payment-report/getPaymentReportCashiers/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}