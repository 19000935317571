import instance from '../../../../../apis/local/systemAPI';


// GET STUDENT INFO
export const getStudentInfo = async (condition) => {
  const result = await instance.post("api/student/infos/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getApplicantInfo = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/student/infos/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "LastName": keyword,
            "firstName": keyword,
            "birthPlace": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/student/infos`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const createApplicantDataEntry = async (formValues) => {
  const result = await instance.post("api/student/infos", formValues);
  if (result.status === 200 || result.status === 201) {
    console.log(result.data);
    return result.data;
  }
}
export const getApplicantDataEntry = async (id) => {
  const result = await instance.get(`api/student/infos/${id}`);
  if (result.status === 200 || result.status === 201) {
    console.log(result.data);
    return result.data;
  }
}


export const updateApplicantEntry = async (id, formValues) => {
  const result = await instance.patch(`api/student/infos/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateEnrollment = async (id, formValues) => {
  const result = await instance.patch(`api/enrollments/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteApplicantEntry = async (id) => {
  const result = await instance.delete(`api/student/infos/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getEnrollment = async (condition) => {
  const result = await instance.post("api/enrollments/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSem = async (condition) => {
  const result = await instance.post("api/references/semesters/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchoolYear = async (formValues) => {
  const result = await instance.post("api/references/schoolyears/q", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getStudentEnrollmentSubjects = async (formValues) => {
  const result = await instance.get("api/student/student-enrollment-subjects", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getFamily = async (formValues) => {
  const result = await instance.get("api/student/familybackground", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteEnrollment = async (id) => {
  const result = await instance.delete(`api/enrollments/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteFamily = async (id) => {
  const result = await instance.delete(`api/student/familybackground/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteStudent = async (id) => {
  const result = await instance.delete(`api/students/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteStudentInfo = async (id) => {
  const result = await instance.delete(`api/student/infos/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getEnrollmentAcc = async (formValues) => {
  const result = await instance.get("api/enrollment/account", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getCourses = async (condition) => {
  const result = await instance.post("api/references/courses/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getEnrollmentStatus = async (formValues) => {
  const result = await instance.get("/api/enrollment/status/q", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}




// start of revisionssss

export const getApplicantList = async (id) => {
  const result = await instance.post(`/api/admission/applicant-list`, id);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCivilStatus = async (condition) => {
  const result = await instance.post(`api/references/civilstatus/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getReligion = async (condition) => {
  const result = await instance.post(`api/references/religions/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourse = async (condition) => {
  const result = await instance.post(`api/references/courses/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getYearLevel = async (condition) => {
  const result = await instance.post("api/references/yearlevels/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getSchool = async (condition) => {
  const result = await instance.post("api/references/schools/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getStudentSchoolHistory = async (condition) => {
  const result = await instance.post("api/student/schoolhistory/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getProvinces = async (condition) => {
  const result = await instance.post(`api/references/provinces/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getMunicipalities = async (condition) => {
  const result = await instance.post(`api/references/municipalities/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCountries = async (condition) => {
  const result = await instance.post(`api/references/countries/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getDiscount = async (condition) => {
  const result = await instance.post(`api/student/discounts/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getStudent = async (condition) => {
  const result = await instance.post(`api/students/q`, condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}




export const getNationality = async (condition) => {
  const result = await instance.post("api/references/nationalities/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getFamilyNetIncome = async (condition) => {
  const result = await instance.post("api/references/family-net-income/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getEducationalPlan = async (condition) => {
  const result = await instance.post("api/references/educational-plan/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getScholarship = async (condition) => {
  const result = await instance.post("api/references/scholarships/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getFamilyPosition = async (condition) => {
  const result = await instance.post("api/references/family-position/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


// migration -----

export const getStudentFromCampus = async (data) => {
  const result = await instance.post("api/campus/migrate", data);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createStudentInfo = async (data) => {
  const result = await instance.post("api/student/infos/m", data);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createStudents = async (data) => {
  const result = await instance.post("api/students/", data);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createStudentFamily = async (data) => {
  const result = await instance.post("api/student/familybackground", data);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createEnrollment = async (data) => {
  const result = await instance.post("api/enrollments/", data);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createEnrollmentMigration = async (data) => {
  const result = await instance.post("api/enrollments/migrateCampus", data);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



