export const referenceTypes = {
    SELECTED_CIVIL_STATUS: 'SELECTED-CIVIL-STATUS',
    GET_CIVIL_STATUSES: 'GET-CIVIL-STATUSES',
    GET_CIVIL_STATUS: 'GET-CIVIL-STATUS-BY-ID',
    CREATE: 'CREATE-CIVIL-STATUS',
    UPDATE: 'UPDATE-CIVIL-STATUS',
    DELETE: 'DELETE-CIVIL-STATUS',
    LIST_LINK: '/admin/references/civilstatus',
    CREATE_LINK: '/admin/references/create-civilstatus/',
    UPDATE_LINK: '/admin/references/update-civilstatus/',
    DELETE_LINK: '/admin/references/delete-civilstatus/'
}