import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import Form from './Form';
import { createAssessmentTemplate, getPaymentMode } from './__hooks__';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import { NoAccess } from '../../../../../_metronic/layout/components/custom/forms/NoAccess';
import NoDataFound from '../../../../../_metronic/layout/components/custom/forms/NoDataFound';

export default function Create() {
  const { state: { auth }, dispatch } = useAppContext();
  const [paymentMode, setPaymentMode] = useState()
  const [keepLoading, setKeepLoading] = useState(true)
  const useEffectOperation = (operation, condition, setData, trigger, trigger2, trigger3) => {
    let dataStorer = []
    useEffect(() => {
      const execute = async () => {
        await operation()
          .then(response => {
            if (response.success) {
              for (let i = 0; i < response.data.length; i++) {
                if (eval(condition)) {
                  dataStorer.push(response.data[i])
                }
              }
              setData(dataStorer)
            } else {
            }
          })
          .catch(error => {
            console.log(error)
            if (error) {
              // alert("something went wrong")
              console.log(error)
            } else {
            }
          })
      }
      execute()

    }, [trigger, trigger2, trigger3])
  }



  const onSubmit = formValues => {
    const execute = async () => {
      let submittedData = []
      for (let i = 0; i < paymentMode.length; i++) {
        submittedData.push({ ...formValues, paymentModeId: paymentMode[i].PaymentModeId })
      }

      for (let v = 0; v < submittedData.length; v++) {


        await createAssessmentTemplate(submittedData[v])
          .then(response => {
            if (response.success) {
              if (v === submittedData.length - 1) {
                dispatch({ type: enrollmentTypes.CREATE, data: response.data });
                history.push(enrollmentTypes.LIST_LINK);
              }

            } else {
              console.log(response)
              // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              console.log(error)
              // localStorage.clear();
              // history.push('/auth/login');
            } else {
              // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
              console.log(error)

            }
          })



      }


    }
    execute();
  }

  let conDefault = 'response.data'
  useEffectOperation(getPaymentMode, conDefault, setPaymentMode)

  setTimeout(function () {
    setKeepLoading(false)
  }, 5000);


  return (
    <>
      {auth.data.UserType === 'ADM' || auth.data.UserType === 'ACC'

        ?

        <div className="kt-container  kt-grid__item kt-grid__item--fluid">
          <div className="row ac-minimum-height-container-350">
            <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
              <Form onSubmit={onSubmit} doc={[]} />
            </div>
          </div>
        </div>


        :

        <>
          <div className="card p-5">
            {keepLoading === true
              ?
              <Loading />
              :
              <>
                {auth.data.UserType === 'ADM' || auth.data.UserType === 'ACC' ?
                  <NoDataFound />
                  :
                  <NoAccess />
                }
              </>
            }
          </div>
        </>

      }

    </>
  );
}