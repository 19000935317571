export const referenceTypes = {

    SELECTED_SCHOOL: 'SELECTED-SCHOOL',
    GET_SCHOOLS: 'GET-SCHOOLS',
    GET_SCHOOL_BY_ID: 'GET-SCHOOL-BY-ID',
    CREATE: 'CREATE-SCHOOL',
    UPDATE: 'UPDATE-SCHOOL',
    DELETE: 'DELETE-SCHOOL',
    LIST_LINK: '/admin/references/schools',
    CREATE_LINK: '/admin/references/create-schools/',
    UPDATE_LINK: '/admin/references/update-schools/',
    DELETE_LINK: '/admin/references/delete-schools/',
    MORE_LINK: '/admin/references/detailed-schools/'

}