import instance from "../../../../../apis/local/systemAPI";


export const getStudentList = async (formValues) => {
    const result = await instance.post("api/cashiering/student/studentList", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentInfo = async (formValues) => {
    const result = await instance.get("api/student/infos", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getPaymentTender = async () => {
    const result = await instance.get("api/payment/payment-tender-types");
    if (result.status === 200 || result.status === 201) {

        return result.data;
    } else {

    }
}

export const createPayment = async (formValues) => {
    const result = await instance.post("api/payment", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateEnrollment = async (id, formValues) => {
    const result = await instance.patch(`api/enrollments/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollment = async (condition) => {
    const result = await instance.post("api/enrollments/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSubjectSchedule = async (formValues) => {
    const result = await instance.get("/api/schedules/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const getStudentEnrollmentSubjects = async (condition) => {
    const result = await instance.post("api/student/student-enrollment-subjects/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectScheduleDetail = async (formValues) => {
    const result = await instance.get("/api/schedule/details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const getSub = async (formValues) => {
    const result = await instance.get("api/references/subjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubSchedDetailList = async (formValues) => {
    const result = await instance.get("api/schedule/detail-lists", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSem = async (condition) => {
    const result = await instance.post("api/references/semesters/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const getAssessmentTemplate = async (formValues) => {
    const result = await instance.get("api/assessment/templates", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentTemplateDetail = async (formValues) => {
    const result = await instance.get("api/assessment/template-details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getFeeGroup = async (formValues) => {
    const result = await instance.get("api/references/feegroups", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSubMain = async (formValues) => {
    const result = await instance.get("api/references/subject-main-copy", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getDiscount = async (formValues) => {
    const result = await instance.get("api/student/discounts", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentAccAddOn = async (formValues) => {
    const result = await instance.get("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createStudentEnrollmentSubjects = async (formValues) => {
    const result = await instance.post("api/student/student-enrollment-subjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createEnrollmentAccountAddsOn = async (formValues) => {
    const result = await instance.post("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createStudentDiscount = async (formValues) => {
    const result = await instance.post("api/student/discounts", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteEnrollmentAccountAddsOn = async (id) => {
    const result = await instance.delete(`api/enrollment/account/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const updateStudentEnrollmentSubjects = async (id, formValues) => {
    const result = await instance.patch(`api/student/student-enrollment-subjects/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteStudentEnrollmentSubjects = async (id) => {
    const result = await instance.delete(`api/student/student-enrollment-subjects/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateEnrollmentAcc = async (id, formValues) => {
    const result = await instance.patch(`api/enrollment/account/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createPaymentDetails = async (formValues) => {
    const result = await instance.post("api/payment-details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const updatePaymentDetails = async (id, formValues) => {
    const result = await instance.patch(`api/payment-details/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollmentStatus = async (condition) => {
    const result = await instance.post("/api/enrollment/status/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}






// revisions 

export const getStudentEnrollmentSubs = async (condition) => {
    const result = await instance.post("api/student/student-enrollment-subjects/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrolledStudent = async (condition) => {
    const result = await instance.post("api/enrollments/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentDiscount = async (condition) => {
    const result = await instance.post("api/student/discounts/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFees = async (condition) => {
    const result = await instance.post("api/references/fees/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFee = async () => {
    const result = await instance.get("api/references/fees");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getScholarship = async (condition) => {
    const result = await instance.post("api/references/scholarships/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudent = async (condition) => {
    const result = await instance.post("api/students/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async (condition) => {
    const result = await instance.post("api/references/courses/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPayment = async (condition) => {
    const result = await instance.post("api/payment/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollmentAddsOn = async (condition) => {
    const result = await instance.post("api/enrollment/account/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getModeOfPayment = async (condition) => {
    const result = await instance.post("api/references/mode-of-payments/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentDetails = async (condition) => {
    const result = await instance.post("api/payment-details/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    } else {
        console.log(result)
    }
}
export const getPaymentAddOns = async (condition) => {
    const result = await instance.post("/api/assessment/add-ons/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoCompleteForPayment", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
