import instance from '../../../../../../../apis/local/systemAPI';

// START OF COURSE OUTLINE DETAIL REQ


export const getCourseOutlineReq = async (con) => {
  const result = await instance.post(`api/course-outline/reqs/s`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createCourseOutlineReq = async (formValues) => {
  const result = await instance.post("api/course-outline/reqs/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCourseOutlineReq = async (id, formValues) => {
  const result = await instance.patch(`api/course-outline/reqs/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteCourseOutlineReq = async (id) => {
  const result = await instance.delete(`api/course-outline/reqs/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitute = async () => {
  const result = await instance.get(`api/references/institutes/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getSubject = async () => {
  const result = await instance.get(`api/references/subjects/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createCourseOutlineDetailReq = async (formValues) => {
  const result = await instance.post("api/course-outline/reqs", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteCourseOutlineDetailReq = async (id) => {
  const result = await instance.delete(`api/course-outline/reqs/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCourseOutlineDetailReq = async (id, formValues) => {
  const result = await instance.patch(`api/course-outline/reqs/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

// END OF COURSE OUTLINE DETAIL REQ