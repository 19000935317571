export const referenceTypes = {
    SELECTED_EDUCATION: 'SELECTED-EDUCATION',
    GET_EDUCATIONS: 'GET-EDUCATIONS',    
    GET_EDUCATION: 'GET-EDUCATION-BY-ID',
    CREATE: 'CREATE-EDUCATION',
    UPDATE: 'UPDATE-EDUCATION',
    DELETE: 'DELETE-EDUCATION',
    LIST_LINK: '/admin/references/educations',
    CREATE_LINK: '/admin/references/create-educations/',
    UPDATE_LINK: '/admin/references/update-educations/',
    DELETE_LINK: '/admin/references/delete-educations/'
}