export const referenceTypes = {
    SELECTED_SCHOLARSHIP: 'SELECTED-SCHOLARSHIP',
    GET_SCHOLARSHIPS: 'GET-SCHOLARSHIPS',    
    GET_SCHOLARSHIP: 'GET-SCHOLARSHIP',
    CREATE: 'CREATE-SCHOLARSHIP',
    UPDATE: 'UPDATE-SCHOLARSHIP',
    DELETE: 'DELETE-SCHOLARSHIP',
    LIST_LINK: '/admin/references/scholarships',
    MORE_LINK: '/admin/references/scholarship-feegroups/',
    CREATE_LINK: '/admin/references/create-scholarships/',
    UPDATE_LINK: '/admin/references/update-scholarships/',
    DELETE_LINK: '/admin/references/delete-scholarships/',
    TEST_LINK: 'CREATE-DETAILS'
}