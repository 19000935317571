import React, { useState, useEffect } from "react";
import history from "../../../../../history";
import { getSubjects, getSubjectScheduleFacultyList, addUpdateFacultyList, deleteFacultyList } from "./__hooks__"
import { useParams } from 'react-router-dom';
import { TableContainer, Table, TableRow, TableCell, TableHead, Paper, TableBody } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SelectCreator from "../../../../../../_metronic/functions/SelectCreator";
import IMSelect from "../../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useAppContext } from "../../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import NotificationModal from "../../../../../../_metronic/layout/components/custom/modal/NotificationModal";
import Loading from "../../../../../../_metronic/layout/components/custom/forms/Loading";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

export default function FacultyLoadingDetailLists(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const { schedule, employeeSelect } = props.location
    const { id } = useParams()
    const [scheduleFaculty, setScheduleFaculty] = useState([])
    const [selectedId, setSelectedId] = useState(null)
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const classes = useStyles();
    const [data, setData] = useState({ employeeId: null, scheduleStatusId: null })
    const [isToDelete, setIsToDelete] = useState(false)

    // selects
    const [subjectSelects, setSubjectSelect] = useState([])
    const statusSelect = [
        { tableId: 1, code: 'Lecture' },
        { tableId: 2, code: 'Laboratory' }
    ]
    if (schedule == undefined || schedule == null) {
        history.push(`/admin/enrollment/faculty-loading`)
    }

    const executeAddUpdateFacultyList = async (facultyListId, row) => {
        await addUpdateFacultyList({
            subjectScheduleDetailFacultyId: facultyListId,
            subjectScheduleDetailId: id,
            employeeId: data.employeeId == null ? row.EmployeeId : data.employeeId,
            scheduleStatusId: data.scheduleStatusId == null ? row.ScheduleStatusId : data.scheduleStatusId,
            createdBy: auth.data.Username
        }).then(res => {
            setSelectedId(null)
            setData({ subjectId: null, type: null })
            getSubjectScheduleFacultyList({ subjectScheduleDetailId: id })
                .then(resp => {
                    setScheduleFaculty(resp.data)
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'success',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Schedule Faculty List Updated'
                    });
                })
        })
    }

    const executeDeleteFacultyList = async (facultyListId) => {
        await deleteFacultyList({ subjectScheduleDetailFacultyId: facultyListId })
            .then(res => {
                setSelectedId(null)
                setData({ subjectId: null, type: null })
                getSubjectScheduleFacultyList({ subjectScheduleDetailId: id })
                    .then(resp => {
                        setScheduleFaculty(resp.data)
                        setSelectedToDelete(null)
                        setIsToDelete(false)
                        dispatch({
                            type: componentTypes.SHOW_SNACKBAR,
                            snackBarStatus: 'success',
                            snackBarDuration: 5000,
                            snackBarMessage: 'Schedule Faculty List Deleted'
                        });
                    })
            })
    }

    const selectHandleChange = name => event => { setData((prevData) => { return { ...prevData, [name]: event } }); }

    useEffect(() => {
        if (schedule !== undefined || schedule !== null) {
            getSubjectScheduleFacultyList({ subjectScheduleDetailId: id })
                .then(res => {
                    setScheduleFaculty(res.data)
                })
        }
        SelectCreator(getSubjects, setSubjectSelect, 'SubjectId', 'SubjectCode')
    }, [])

    console.log(schedule)
    console.log(scheduleFaculty)
    console.log(subjectSelects)
    console.log(data)
    return (
        <>
            {schedule !== undefined && employeeSelect.length > 0 ?
                <>

                    <NotificationModal
                        isOpen={isToDelete}
                        handleClose={() => executeDeleteFacultyList(selectedToDelete)}
                        title={'Delete Faculty List'}
                        content={'Are you sure you want to delete?'}
                    />
                    <div className="container">
                        <div className="card">


                            <div className="row container my-5">
                                <div className="col-12 col-xl-4  border-right border-primary">
                                    <div className=" p-5">
                                        <h5 className="text-primary">
                                            <i className="fa fa-book"></i> &nbsp;&nbsp;
                                            {schedule.SubjectCode}</h5>


                                        <hr />

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Class Code &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            <input readOnly value={schedule.ClassCode} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Class Section &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            <input readOnly value={schedule.ClassSection} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Lecture Instructor</span>
                                            </div>
                                            <input readOnly value={schedule.EmployeeName} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>
                                        {schedule.LabTimeStart != null &&
                                            <div className="input-group mb-3">
                                                <div className="input-group-prepend">
                                                    <span className="input-group-text" id="inputGroup-sizing-default">Laboratory Instructor</span>
                                                </div>
                                                <input readOnly value={schedule.EmployeeLabName} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            </div>

                                        }



                                        <button className="btn btn-secondary" onClick={() => { history.push(`/admin/enrollment/faculty-loading-details/${schedule.SubjectScheduleId}`) }}>
                                            Back
                                        </button>


                                    </div>


                                </div>
                                <div className="col-12 col-xl-8">
                                    <div className="p-5  ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Schedule co-faculty</h3>

                                        <Paper className={classes.root}>
                                            <TableContainer className={classes.container}>
                                                <Table stickyHeader size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell style={{ width: '55%' }}>Faculty</TableCell>
                                                            <TableCell style={{ width: '15%' }}>Schedule Type</TableCell>
                                                            <TableCell align="center" style={{ width: '30%' }}>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {scheduleFaculty.length > 0 && scheduleFaculty.map((row, index) => {
                                                            console.log(row)
                                                            return (
                                                                <>
                                                                    {selectedId !== row.SubjectScheduleDetailFacultyId ?
                                                                        <>
                                                                            <TableRow hover tabIndex={-1} key={index}>
                                                                                <TableCell >{row.FacultyName}</TableCell>
                                                                                <TableCell >{row.ScheduleStatus}</TableCell>
                                                                                <TableCell align="center" >
                                                                                    <button className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                                                                                        onClick={() => { setSelectedId(row.SubjectScheduleDetailFacultyId) }}>
                                                                                        <i className="fa fa-pencil-alt text-white"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                                                                                        onClick={() => { setSelectedToDelete(row.SubjectScheduleDetailFacultyId); setIsToDelete(true); }}
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TableRow hover tabIndex={-1} >
                                                                                <TableCell >
                                                                                    <IMSelect
                                                                                        data={employeeSelect}
                                                                                        onHandleChange={selectHandleChange('employeeId')}
                                                                                        refClassContainer=""
                                                                                        name="Faculty"
                                                                                        isRequired={true}
                                                                                        withLabel={false}
                                                                                        placeHolder="Faculty"
                                                                                        forwardRef={() => { }}
                                                                                        selectedId={data.employeeId == null ? row.EmployeeId : data.employeeId}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[

                                                                                        ]
                                                                                        }
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}

                                                                                    />
                                                                                </TableCell>

                                                                                <TableCell >
                                                                                    <IMSelect
                                                                                        data={statusSelect}
                                                                                        onHandleChange={selectHandleChange('scheduleStatusId')}
                                                                                        refClassContainer=""
                                                                                        name="Schedule Status"
                                                                                        isRequired={true}
                                                                                        withLabel={false}
                                                                                        placeHolder="Type"
                                                                                        forwardRef={() => { }}
                                                                                        selectedId={data.scheduleStatusId == null ? row.ScheduleStatusId : data.scheduleStatusId}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[

                                                                                        ]
                                                                                        }
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}

                                                                                    />
                                                                                </TableCell >


                                                                                <TableCell align="center">
                                                                                    <button
                                                                                        className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1"
                                                                                        onClick={() => { executeAddUpdateFacultyList(row.SubjectScheduleDetailFacultyId, row) }}
                                                                                    >
                                                                                        <i className="fa fa-save"></i>
                                                                                    </button>
                                                                                    <button className="btn btn-xs btn-icon btn-icon-xs btn-secondary"
                                                                                        onClick={() => { setSelectedId(null) }}>
                                                                                        <i className="fa fa-window-close"></i>
                                                                                    </button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                    }


                                                                </>
                                                            )

                                                        })}
                                                        <TableRow hover tabIndex={-1} size="small" >
                                                            <TableCell >
                                                                <IMSelect
                                                                    data={employeeSelect}
                                                                    onHandleChange={selectHandleChange('employeeId')}
                                                                    refClassContainer=""
                                                                    name="Faculty"
                                                                    isRequired={true}
                                                                    withLabel={false}
                                                                    placeHolder="Faculty"
                                                                    forwardRef={() => { }}
                                                                    selectedId={data.employeeId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]
                                                                    }
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />
                                                            </TableCell>
                                                            <TableCell >
                                                                <IMSelect
                                                                    data={statusSelect}
                                                                    onHandleChange={selectHandleChange('scheduleStatusId')}
                                                                    refClassContainer=""
                                                                    name="scheduleStatusId"
                                                                    isRequired={true}
                                                                    withLabel={false}
                                                                    placeHolder="Type"
                                                                    forwardRef={() => { }}
                                                                    selectedId={data.scheduleStatusId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]
                                                                    }
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />
                                                            </TableCell >


                                                            <TableCell align="center">
                                                                <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1"
                                                                    onClick={() => { executeAddUpdateFacultyList(0, { SubjectId: null, Type: null }) }}
                                                                >
                                                                    <i className="fa fa-save"></i>
                                                                </button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Paper>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </>

                :

                <Loading />



            }

        </>
    )
}