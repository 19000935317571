export const referenceTypes = {
    SELECTED_FEE: 'SELECTED-FEE',
    GET_FEES: 'GET-FEES',
    GET_FEE: 'GET-FEE-BY-ID',
    CREATE: 'CREATE-FEE',
    UPDATE: 'UPDATE-FEE',
    DELETE: 'DELETE-FEE',
    LIST_LINK: '/admin/references/fees',
    CREATE_LINK: '/admin/references/create-fees/',
    UPDATE_LINK: '/admin/references/update-fees/',
    DELETE_LINK: '/admin/references/delete-fees/',
    MORE_LINK: '/admin/student/grade-computation-template/',
    LIST_LINK_TO_PARENT: '/admin/student/grade-template'

}