import React, { useState } from 'react';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import Form from './Form';
import { createCourseOutline } from './__hooks__';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import { NoAccess } from '../../../../../_metronic/layout/components/custom/forms/NoAccess';



export default function Create() {
  const { state: { auth }, dispatch } = useAppContext();
  const [keepLoading, setKeepLoading] = useState()
  const onSubmit = formValues => {
    const execute = async () => {
      await createCourseOutline(formValues)
        .then(response => {
          if (response.success) {
            dispatch({ type: referenceTypes.CREATE, data: response.data });
            history.push(referenceTypes.LIST_LINK);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  setTimeout(function () {
    setKeepLoading(false)
  }, 5000);


  return (
    <>

      {
        auth.data.UserType === 'DEN'
          || auth.data.UserType === 'RGR'
          || auth.data.UserType === 'ADM'

          ?

          <div className="kt-container  kt-grid__item kt-grid__item--fluid">
            <div className="row ac-minimum-height-container-350">
              <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
                <Form onSubmit={onSubmit} doc={[]} />
              </div>
            </div>
          </div>

          :

          <>
            <div className="card p-5">

              {keepLoading === true
                ?
                <Loading />
                :

                <>
                  {auth.data.UserType === 'DEN'
                    || auth.data.UserType === 'RGR'
                    || auth.data.UserType === 'ADM'

                    ? ''

                    : <NoAccess />
                  }
                </>
              }
            </div>

          </>
      }

    </>
  );
}