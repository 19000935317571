import React, { useState, useEffect } from "react";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import {
    getSem, getSchoolYear, getEnrollment, getStudent, getStudentInfo, getStudentEnrollmentSubjects,
    getRoleCourse, getEnrollmentStatus

} from "./__hooks__";
import { EnrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import NoDataFound from "../../../../../_metronic/layout/components/custom/forms/NoDataFound";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading"
import { NoAccess } from "../../../../../_metronic/layout/components/custom/forms/NoAccess";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import { Alert } from "bootstrap";

export default function RecieveDocumentsList(props) {
    const { state: { applicantData, auth } } = useAppContext()
    const { setValue } = useForm();
    const [data, setData] = useState(undefined)
    const [results, setResults] = useState([]);
    const [searchResults, setSearchResult] = useState([])
    const [onSearch, setOnSearch] = useState(false);
    const [keyword, setKeyword] = useState()
    const [page, setPage] = useState(0);
    const [activeSem, setActiveSem] = useState()
    const [activeSchoolYear, setActiveSchoolYear] = useState()
    const [enrollment, setEnrollment] = useState()
    const [student, setStudent] = useState()
    const [studentInfo, setStudentInfo] = useState()
    const [studentSubjects, setStudentSubjects] = useState()
    const [dataPerPage, setDataPerPage] = useState()
    const [totalRecords, setTotalRecord] = useState(results.length);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [roleCourse, setRoleCourse] = useState()
    const [isSearch, setIsSearch] = useState(false)
    const [particularInstitute, setParticularInstitute] = useState()
    const [references, setReferences] = useState()
    const [enrollmentStatus, setEnrollmentStatus] = useState()
    const [semesters, setSemesters] = useState()
    const [semestersSelect, setSemestersSelect] = useState()
    const [eStatus, setEStatus] = useState()


    const updateId = applicantData.id;
    const isLastUpdate = true;
    let RowCnt = page * rowsPerPage;

    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }

    const useEffectOperation = (operation, condition, setData, trigger, trigger2, trigger3) => {
        let dataStorer = []
        useEffect(() => {
            const execute = async () => {
                await operation()
                    .then(response => {
                        if (response.success) {
                            for (let i = 0; i < response.data.length; i++) {
                                if (eval(condition)) {
                                    dataStorer.push(response.data[i])
                                }
                            }
                            setData(dataStorer)
                        } else {
                        }
                    })
                    .catch(error => {
                        console.log(error)
                        if (error) {
                            // alert("something went wrong")
                            console.log(error)
                        } else {
                        }
                    })
            }
            execute()

        }, [trigger, trigger2, trigger3])
    }

    const getSemQuery = "{where: `WHERE semesters.SemesterId = '${data.Semester}'`}"
    const getActiveQuery = "{ where: 'WHERE IsActive = 1' }"
    const getAll = " {where: ''}"
    const getEnrollmentQuery = "{where: `WHERE e.SemesterId = '${activeSem[0].SemesterId}' AND e.SchoolYear = ${activeSchoolYear[0].SchoolYear} AND e.EnrollmentStatusId = 'FV' AND e.InstituteId = ${roleCourse[0].InstituteId}  `}"
    const getStudentQuery = "{where: `WHERE s.StudentId IN (${enrollment.map(d => {return d.StudentId})})`}"
    const getStudentInfoQuery = "{where: `WHERE student_infos.StudentInfoId IN (${student.map(d=> {return d.StudentInfoId})})`}"
    let conGetStudentEnrollmentSub = 'response.data[i].StudentId === student[0].StudentId && response.data[i].SemesterId === activeSem[0].SemesterId && response.data[i].SchoolYear === activeSchoolYear[0].SchoolYear'


    useEffectOperation(getStudentEnrollmentSubjects, conGetStudentEnrollmentSub, setStudentSubjects, student, activeSem, activeSchoolYear)


    useEffect(() => {
        //sy
        Query(getSchoolYear, getActiveQuery, setActiveSchoolYear)
        // sem
        Query(getSem, getActiveQuery, setActiveSem)
        // sem all
        Query(getSem, getAll, setSemesters)
        // role Course
        Query(getRoleCourse, getAll, setRoleCourse)
        // enrollmentStatys
        Query(getEnrollmentStatus, getAll, setEnrollmentStatus)
    }, [])
    useEffect(() => {
        if (data !== undefined && data.Semester !== undefined) {
            Query(getSem, getSemQuery, setActiveSem)
        }
    }, [data])

    useEffect(() => {
        if (enrollment !== undefined) {
            Query(getStudent, getStudentQuery, setStudent)
        }
    }, [enrollment])


    useEffect(() => {
        if (student !== undefined) {
            Query(getStudentInfo, getStudentInfoQuery, setStudentInfo)
        }
    }, [student])


    useEffect(() => {
        if (activeSem !== undefined && activeSchoolYear !== undefined && roleCourse !== undefined) {
            //Enrollment
            Query(getEnrollment, getEnrollmentQuery, setEnrollment)

        }
    }, [activeSem, setActiveSchoolYear, roleCourse])

    useEffect(() => {
        let arr = []
        if (student !== undefined && studentInfo !== undefined && enrollment !== undefined
            && activeSem !== undefined && activeSchoolYear !== undefined
            && enrollmentStatus !== undefined && eStatus !== undefined && roleCourse !== undefined) {


            for (let r = 0; r < enrollment.length; r++) {


                for (let z = 0; z < enrollmentStatus.length; z++) {
                    if (enrollmentStatus[z].EnrollmentStatusId === enrollment[r].EnrollmentStatusId && enrollment[r].InstituteId === roleCourse[0].InstituteId) {
                        if (data !== undefined) {
                            if (enrollment[r].EnrollmentStatusId === data.EnrollmentStatus && data.EnrollmentStatus === enrollmentStatus[z].EnrollmentStatusId) {

                                arr.push({
                                    studentId: enrollment[r].StudentId,
                                    studentNo: enrollment[r].StudentNo,
                                    studentName: `${enrollment[r].LastName}, ${enrollment[r].FirstName}`,
                                    studentInfoId: enrollment[r].StudentInfoId,
                                    sy: `${enrollment[r].SchoolYear} / ${enrollment[r].SemesterId}`,
                                    semester: enrollment[r].SemesterId,
                                    schoolYear: enrollment[r].SchoolYear,
                                    enrollmentStatus: enrollmentStatus[z].EnrollmentStatus,
                                    // enrollmentDate: new Date(enrollment[r].DateCreated).toLocaleDateString("en-US"),
                                    course: enrollment[r].Course,
                                    yearLevel: enrollment[r].YearLevel,
                                    status: enrollment[r].EnrollmentStatusId
                                })
                            }
                        } else {
                            if (enrollment[r].EnrollmentStatusId === eStatus) {

                                arr.push({
                                    studentId: enrollment[r].StudentId,
                                    studentNo: enrollment[r].StudentNo,
                                    studentName: `${enrollment[r].LastName}, ${enrollment[r].FirstName}`,
                                    studentInfoId: enrollment[r].StudentInfoId,
                                    sy: `${enrollment[r].SchoolYear} / ${enrollment[r].SemesterId}`,
                                    semester: enrollment[r].SemesterId,
                                    schoolYear: enrollment[r].SchoolYear,
                                    enrollmentStatus: enrollmentStatus[z].EnrollmentStatus,
                                    // enrollmentDate: new Date(enrollment[r].DateCreated).toLocaleDateString("en-US"),
                                    course: enrollment[r].Course,
                                    yearLevel: enrollment[r].YearLevel,
                                    status: enrollment[r].EnrollmentStatusId

                                })
                            }
                        }
                    }

                }

            }

        }
        console.log(arr)
        const keys = ['studentId'],
            filtered = arr.reverse().filter(
                (s => o =>
                    (k => !s.has(k) && s.add(k))
                        (keys.map(k => o[k]).join('|'))
                )
                    (new Set)
            );
        console.log(filtered)
        setResults(filtered)

    }, [student, studentInfo, enrollment, activeSem, activeSchoolYear, page, data, eStatus])

    useEffect(() => {
        let referencessArr = []
        let semestersArr = []

        if (enrollment !== undefined && enrollmentStatus !== undefined && semesters !== undefined) {
            if (enrollment.length > 0 && enrollmentStatus.length > 0) {

                for (let i = 0; i < enrollment.length; i++) {
                    for (let v = 0; v < enrollmentStatus.length; v++) {
                        if (enrollmentStatus[v].EnrollmentStatusId === enrollment[i].EnrollmentStatusId) {
                            referencessArr.push({ tableId: enrollmentStatus[v].EnrollmentStatusId, code: enrollmentStatus[v].EnrollmentStatus, })

                        }
                    }
                }
                for (let s = 0; s < semesters.length; s++) {
                    semestersArr.push({ tableId: semesters[s].SemesterId, code: semesters[s].Description, })
                }


                console.log(referencessArr)
                setSemestersSelect(semestersArr)
            }
            const keys = ['tableId'],
                filtered = referencessArr.filter(
                    (s => o =>
                        (k => !s.has(k) && s.add(k))
                            (keys.map(k => o[k]).join('|'))
                    )
                        (new Set)
                );

            console.log(filtered)
            setReferences(filtered)
        }


    }, [enrollment, enrollmentStatus, semesters])

    useEffect(() => {
        if (results !== undefined) {
            if (results.length > 0) {
                let arr = []
                for (let i = RowCnt; i !== RowCnt + rowsPerPage; i++) {
                    if (results[i] === undefined) {

                    } else {
                        arr.push(results[i])

                    }
                }
                setDataPerPage(arr)
            } else {
                setDataPerPage([])
            }

        }

    }, [results, page, data])

    useEffect(() => {
        if (enrollment !== undefined) {
            if (enrollment.length > 0) {

                setEStatus(enrollment[0].EnrollmentStatusId)

            }
        }
    }, [enrollment, data])

    useEffect(() => {
        if (data === undefined && eStatus !== undefined && activeSem !== undefined) {
            setData({ 'EnrollmentStatus': eStatus, 'Semester': activeSem[0].SemesterId })
        }
    }, [data, eStatus, activeSem])
    const handleChangeKeyword = (event) => {
        setIsSearch(true)

        arr = []
        let arr = []
        const regex = new RegExp(event);
        if (event === '') {
            setSearchResult(dataPerPage)
        } else {
            for (let i = 0; i < results.length; i++) {
                let findStudentName = results[i].studentName
                let findStudentNo = results[i].studentNo
                if (regex.test(findStudentName.toLowerCase()) || regex.test(findStudentName.toUpperCase())) {
                    arr.push(results[i])
                } else if (regex.test(findStudentNo)) {
                    arr.push(results[i])
                } else {

                }
            }
            if (arr.length > 0) {
                setSearchResult(arr)

            } else {
                setSearchResult([])
            }
        }
        setKeyword(event);
        setTotalRecord(searchResults.length)
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event)
        if (event === false) {
            setTotalRecord(rowsPerPage)

        } else {
            setTotalRecord(searchResults.length)

        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };
    const selectHandleChange = name => event => { setData({ ...data, [name]: event }); }

    const imSelectDropdownReference = () => {

        return <IMSelect
            data={references}
            onHandleChange={selectHandleChange('EnrollmentStatus')}
            refClassContainer=""
            name="SubjectScheduleId"
            isRequired={false}
            withLabel={false}
            label="Enrollment Status"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={data === undefined ? eStatus : data.EnrollmentStatus}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />


    }
    const imSelectDropdownSemesters = () => {

        return <IMSelect
            data={semestersSelect}
            onHandleChange={selectHandleChange('Semester')}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={data === undefined ? activeSem[0].SemesterId : data.Semester}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />


    }

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'studentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'studentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'sy', label: 'SY / Semester', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'enrollmentStatus', label: 'Enrollment Status', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false },

    ]
    let tableProps
    if (activeSem && activeSchoolYear) {
        if (isSearch === true) {
            tableProps = {
                recordId: 'studentId',
                sortField: '',
                columns: columns,
                rows: searchResults,
                totalRecords: results.length,
                withMoreButton: true,
                childWithMoreButton: true,
                withFooter: false,
                tableType: 'preAssessmentValidation',
                parentId: null,
                tableTitle: '',
                onSearch: onSearch,
                keyword: keyword,
                lastUpdateId: updateId,
                onIsLastUpdate: isLastUpdate,
                onPage: page,
                onRowsPerPage: rowsPerPage,
                onRowsPerPageOptions: [10, 25, 50, 100],
                onTypes: EnrollmentTypes,
                onHandleChangePage: handleChangePage,
                onHandleChangeRowsPerPage: handleChangeRowsPerPage,
                onHandleChangeKeyword: handleChangeKeyword,
                onHandleChangeOnSearch: handleChangeOnSearch,
                activeSem: activeSem[0].SemesterId,
                activeSchoolYear: activeSchoolYear[0].SchoolYear,
                studentSubjects: studentSubjects
            }
        } else {
            tableProps = {
                recordId: 'studentId',
                sortField: '',
                columns: columns,
                rows: dataPerPage,
                totalRecords: results.length,
                withMoreButton: true,
                childWithMoreButton: true,
                withFooter: false,
                tableType: 'preAssessmentValidation',
                parentId: null,
                tableTitle: '',
                onSearch: onSearch,
                keyword: keyword,
                lastUpdateId: updateId,
                onIsLastUpdate: isLastUpdate,
                onPage: page,
                onRowsPerPage: rowsPerPage,
                onRowsPerPageOptions: [10, 25, 50, 100],
                onTypes: EnrollmentTypes,
                onHandleChangePage: handleChangePage,
                onHandleChangeRowsPerPage: handleChangeRowsPerPage,
                onHandleChangeKeyword: handleChangeKeyword,
                onHandleChangeOnSearch: handleChangeOnSearch,
                activeSem: activeSem[0].SemesterId,
                activeSchoolYear: activeSchoolYear[0].SchoolYear,
                studentSubjects: studentSubjects
            }
        }
    }



    const [keepLoading, setKeepLoading] = useState(true)
    setTimeout(function () {
        setKeepLoading(false)
    }, 5000);

    console.log(dataPerPage)
    console.log(data)
    console.log(results)
    console.log(particularInstitute)
    console.log(roleCourse)

    return (
        <> {activeSem !== undefined && activeSchoolYear !== undefined && results !== undefined
            && dataPerPage !== undefined && eStatus !== undefined && semesters !== undefined
            && roleCourse !== undefined && auth.data.UserType === 'DEN' || auth.data.UserType === 'ADM' ?
            <div className="card">
                <div className="card-header  ">
                    <h3 className="mb-0 font-weight-bold ">   Student for Validation</h3>
                    <p > Your Assigned Institute: <span className="lead">{roleCourse[0].RoleCourse}</span></p>
                </div>
                <ListFilters
                    selectDropdownStatus={imSelectDropdownReference}
                    selectDropdownSemester={imSelectDropdownSemesters}
                    onSearch={onSearch}
                    keyword={keyword}
                    onHandleChangeOnSearch={handleChangeOnSearch}
                    onHandleChangeKeyword={handleChangeKeyword}
                    tableTitle='Filter: '
                />
                <IMTableCustom tableProps={tableProps} />

            </div>

            :
            <>
                {roleCourse === undefined

                    ?
                    <div className="card">
                        <div className="card-header ">
                            <h3 className="mb-0  font-weight-bold ">   Student for Validation</h3>
                            {/* <p > Your Assigned Institute: <span className="lead">{roleCourse[0].RoleCourse}</span></p> */}
                        </div>

                        {keepLoading === true
                            ?
                            <Loading />
                            :
                            <>
                                {auth.data.UserType === 'DEN' || auth.data.UserType === 'ADM'

                                    ? <NoDataFound />

                                    : <NoAccess />
                                }
                            </>
                        }

                    </div>

                    :

                    <div className="card">
                        <div className="card-header ">
                            <h3 className="mb-0  font-weight-bold ">   Student for Validation</h3>
                            <p > Your Assigned Institute: <span className="lead">{roleCourse[0].RoleCourse}</span></p>
                        </div>

                        {keepLoading === true
                            ?
                            <Loading />
                            :
                            <>
                                {auth.data.UserType === 'DEN' || auth.data.UserType === 'ADM'

                                    ? <NoDataFound />

                                    : <NoAccess />
                                }
                            </>
                        }

                    </div>

                }

            </>

        }

        </>
    )
}