export const referenceTypes = {
    SELECTED_NATIONALITY: 'SELECTED-NATIONALITY',
    GET_NATIONALITIES: 'GET-NATIONALITIES',
    GET_NATIONALITY: 'GET-NATIONALITY-BY-ID',
    CREATE: 'CREATE-NATIONALITY',
    UPDATE: 'UPDATE-NATIONALITY',
    DELETE: 'DELETE-NATIONALITY',
    LIST_LINK: '/admin/references/nationalities',
    CREATE_LINK: '/admin/references/create-nationalities/',
    UPDATE_LINK: '/admin/references/update-nationalities/',
    DELETE_LINK: '/admin/references/delete-nationalities/'
}