export const referenceTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_COURSE_OUTLINE_DETAIL: 'SELECTED-COURSE-OUTLINE-DETAIL',
    GET_COURSE_OUTLINE_DETAILS: 'GET-COURSE-OUTLINE-DETAILS',
    GET_COURSE_OUTLINE_DETAIL: 'GET-COURSE-OUTLINE-DETAIL-BY-ID',
    CREATE: 'CREATE-COURSE-OUTLINE-DETAIL',
    UPDATE: 'UPDATE-COURSE-OUTLINE-DETAIL',
    DELETE: 'DELETE-COURSE-OUTLINE-DETAIL',
    LIST_LINK_TO_PARENT: '/admin/references/course-outlines',
    LIST_LINK: '/admin/references/course-outline-details',
    MORE_LINK: '/admin/references/course-outline-detail-reqs/',
    CREATE_LINK: '/admin/references/create-course-outline-details/',
    UPDATE_LINK: '/admin/references/update-course-outline-details/',
    DELETE_LINK: '/admin/references/delete-course-outline-details/'
}