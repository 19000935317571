import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TableContainer, TextField } from "@material-ui/core";
import {
    searchStudentSubjects, getSubjectScheduleDetail, createAddDropSubjects,
    deleteAddDropSubjects, getAddDropSubjects, getSubjectScheduleDetailSelect,
    studentAutoComplete, getSchoolYear, getSemester, deleteStudentSubjects,
    addUpdateStudentSubjects, getStudentSubjects, createAssessmentAddOns, addUpdateAddOns,
    getAssessmentAddOns, deleteAddOns
} from "./__hooks__/index"
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import { useAppContext } from "../../../../contexts/useAppContext";
import { enrollmentTypes } from "./__hooks__/types";
import { Table, TableBody, TableHead, TableRow, TableFooter, TableCell, Box, Collapse, IconButton, Checkbox } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import Autocomplete from '@material-ui/lab/Autocomplete';
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import history from "../../../../history";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import { debounce } from "lodash";
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function StudentAddDropSubjects(props) {
    const { dispatch, state: { auth } } = useAppContext();
    const classes = useStyles();

    const [data, setData] = useState({ SubSchedDetailId: null })
    const { setValue } = useForm();
    const { handleSubmit, register } = useForm();
    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState(null)
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    //All Subject Added or Drop
    const [subSchedDetail, setSubSchedDetail] = useState([])

    let today = new Date();
    let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    let dateTime = date + ' ' + time;


    const [selectedSubjectCode, setSelectedSubjectCode] = useState([])
    const [existingSelectedIregSub, setExistingSelectedIregSub] = useState([])
    const [subjectSelect, setSubjectDropdown] = useState([])
    const [sySelect, setSySelect] = useState()
    const [semSelect, setSemSelect] = useState()



    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)

    const [dropSubjects, setDropSubjects] = useState([])
    const [addSubjects, setAddSubjects] = useState([])
    const [assessedSubjects, setAssessedSubject] = useState([])

    const [addSubjectDetailId, setAddSubjectDetailId] = useState([])


    const [studentList, setStudentList] = useState([])


    const [isLoading, setIsLoading] = useState(false)
    const [isLoadingScreen, setIsLoadingScreen] = useState(false)
    const [isWithAddOns, setIsWithAddOns] = useState(false)
    const [addOnsAmount, setAddOnsAmount] = useState(150)
    const [currentAddOnsAmount, setCurrentAddOnsAmount] = useState(0)
    const [addOnsId, setAddOnsId] = useState(0)
    const [isValidated, setIsValidated] = useState(true)
    const [isNotProcessed, setIsNotProcessed] = useState(false)



    const selectHandleChange = name => event => {

        getStudentSubjects({
            studentId: student[0].StudentId,
            schoolYear: filteredSy,
            semesterId: filteredSem,
            subjectScheduleDetailId: event,
            createdBy: auth.data.Username,
            date: dateTime
        }).then(res => {
            let mergedData = []
            console.log(res.data)
            mergedData = [...subSchedDetail, ...res.data];
            setSubSchedDetail(mergedData)
        })
            .catch(e => {
                console.log(e)
            })
        setData({ ...data, [name]: event });
    }

    const onSearchStudentSubjects = async (val) => {
        await searchStudentSubjects(val)
            .then(res => {
                console.log(res)
                if (res.data.length > 0 && res.data[0].EnrollmentStatusId !== 'DR') {
                    setIsNotProcessed(false)
                } else {
                    setIsNotProcessed(true)

                }
                setStudent(res.data)

                setIsLoadingScreen(false)
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })


    }

    const onSubmitSearch = (formValues) => {
        console.log(filteredSem)
        console.log(filteredSy)
        onSearchStudentSubjects({ studentNo: studentNo, schoolYear: filteredSy, semesterId: filteredSem })
    }
    const handleGetStudent = async (data) => {
        setStudentNo(data.StudentNo)

        console.log(data)
    }

    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);

    }

    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }, 300);


    const selectChange = (name, event) => {
        if (name == 'semesterId') {
            setFilteredSem(event)
        } else if (name == 'schoolYear') {
            setFilteredSy(event)

        }
    }

    const onCheckBox = (id, event, row) => {
        let isChecked = event.target.checked
        console.log(isChecked + '_' + id)
        console.log(isChecked == true)
        console.log(id)
        console.log(row)

        const updatedArrayFalse = () => {
            // Check if the current object has the specified StudentEnrollmentSubjectsId
            let newData = []
            const options = { month: 'short', day: 'numeric', year: 'numeric' };

            newData.push({
                ...row,
                SubjectStatusId: 2,
                SubjectStatus: "Dropped",
                CreatedBy: auth.data.Username,
                DateCreated: today.toLocaleDateString('en-US', options),
                OrigStudentEnrollmentSubjectsId: 0,
                IsHistory: 0

            })

            subSchedDetail.filter((obj) => {
                // Check if the current object has the specified StudentEnrollmentSubjectsId
                if (obj.StudentEnrollmentSubjectsId === id) {
                    if (obj.OrigSubjectStatusId == 3) {
                        //     // return obj.StudentEnrollmentSubjectsId !== id;
                        //     return false
                        // } else {
                        // Update the values as per your requirements

                        obj.IsHistory = 1
                    } else if (obj.OrigSubjectStatusId == 1 && isChecked == false) {

                        obj.IsHistory = 1
                    }

                }
                return obj;
            })
            // console.log(updateHistorySubject())
            return newData
        };

        const updatedArrayTrue = () => subSchedDetail.map((obj) => {
            let newData = []
            const options = { month: 'short', day: 'numeric', year: 'numeric' };

            // Check if the current object has the specified StudentEnrollmentSubjectsId
            if (obj.StudentEnrollmentSubjectsId === id) {
                if (obj.OrigSubjectStatusId == 1) {
                    newData.push({
                        ...obj,
                        SubjectStatusId: 1,
                        SubjectStatus: "Assessed"
                    })
                    // Update the values as per your requirements
                } else {
                    newData.push({
                        ...obj,
                        SubjectStatusId: 1,
                        SubjectStatus: "Added",
                        CreatedBy: auth.data.Username,
                        DateCreated: today.toLocaleDateString('en-US', options),
                        IsHistory: 0
                    })
                }
            }
            return newData;
        });

        if (isChecked == true) {
            console.log(updatedArrayTrue())
            // setSubSchedDetail((prevData) => {
            //     return [...prevData, updatedArrayTrue()]
            // })
        } else {
            setSubSchedDetail((prevData) => {
                return [...prevData, ...updatedArrayFalse()]
            })
        }


    }


    const onSubmitWithoutPayment = async () => {
        setIsLoading(true)
        let values = []
        for (let i = 0; i < subSchedDetail.length; i++) {
            values.push({
                studentEnrollmentSubjectId: subSchedDetail[i].OrigStudentEnrollmentSubjectsId,
                studentId: student[0].StudentId,
                subjectId: subSchedDetail[i].SubjectId,
                semesterId: filteredSem,
                schoolYear: filteredSy,
                subjectScheduleDetailId: subSchedDetail[i].SubjectScheduleDetailId,
                createdBy: auth.data.Username,
                subjectStatusId: subSchedDetail[i].SubjectStatusId,
                enrollmentStatus: 1,
                isPending: 0,
                isValidated: subSchedDetail[i].SubjectStatusId == 1 ? 1 : 0,
                isHistory: subSchedDetail[i].IsHistory
            })
        }

        for (let i = 0; i < values.length; i++) {
            console.log(values[i])
            await addUpdateStudentSubjects(values[i])
        }
        console.log(subSchedDetail)
        setIsLoading(false)
        console.log(values)
        setStudent(null)
        // setIsLoadingScreen(true)
        setSubSchedDetail([])

        // setTimeout(() => {
        //     onSubmitSearch()
        // }, 1000)
    }

    const onSubmitWithPayment = async () => {
        setIsLoading(true)
        let values = []
        for (let i = 0; i < subSchedDetail.length; i++) {
            values.push({
                studentEnrollmentSubjectId: subSchedDetail[i].OrigStudentEnrollmentSubjectsId,
                studentId: student[0].StudentId,
                subjectId: subSchedDetail[i].SubjectId,
                semesterId: filteredSem,
                schoolYear: filteredSy,
                subjectScheduleDetailId: subSchedDetail[i].SubjectScheduleDetailId,
                createdBy: auth.data.Username,
                subjectStatusId: subSchedDetail[i].SubjectStatusId,
                enrollmentStatus: subSchedDetail[i].SubjectStatusId == 1 ? 1 : 0,
                isPending: 1,
                isValidated: subSchedDetail[i].SubjectStatusId == 1 ? 1 : 0,
                isHistory: subSchedDetail[i].IsHistory
            })
        }

        for (let i = 0; i < values.length; i++) {
            console.log(values[i])
            await addUpdateStudentSubjects(values[i])
        }
        console.log(subSchedDetail)
        setIsLoading(false)
        console.log(values)
        setStudent(null)
        setIsLoadingScreen(true)
        setSubSchedDetail([])

        setTimeout(() => {
            onSubmitSearch()
        }, 1000)


    }

    useEffect(() => {
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')

    }, [])

    useEffect(() => {
        if (student != null && student.length > 0) {
            getSubjectScheduleDetailSelect({ where: `WHERE SchoolYear = ${student[0].SchoolYear} AND SemesterId = "${student[0].SemesterId}" ORDER BY ClassCode ASC` })
                .then(res => {
                    console.log(res.data)
                    let arr = []
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push({ tableId: res.data[i].SubjectScheduleDetailId, code: `${res.data[i].ClassCode} - ${res.data[i].SubjectCode}` })
                    }
                    setSubjectDropdown(arr)

                })
            getStudentSubjects({
                studentId: student[0].StudentId,
                schoolYear: filteredSy,
                semesterId: filteredSem,
                subjectScheduleDetailId: null,
                createdBy: auth.data.Username,
                date: dateTime
            }).then(res => {
                setSubSchedDetail(res.data)
            })

        }

    }, [student])




    const imSelectDropdownSubject = () => {

        return <IMSelect
            data={subjectSelect}
            onHandleChange={selectHandleChange('SubSchedDetailId')}
            refClassContainer=""
            name="SubSchedDetailId"
            isRequired={true}
            withLabel={true}
            label="Choose Subject"
            placeHolder="Subject"
            forwardRef={setValue}
            selectedId={data.SubSchedDetailId === null ? '' : data.SubSchedDetailId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />


    }




    const columns = [

        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectStatusId', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', type: 'action', label: 'Select Subject', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }


    ]

    const childColumns = [
        { id: 'RowCnt', type: 'index', label: '', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'TimeStartDesc', type: 'time', label: 'Time Start', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TimeEndDesc', type: 'time', label: 'Time End', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', type: 'string', label: 'Day Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Room', type: 'string', label: 'Room', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    ]


    let tablePropsIreg = {
        recordId: 'SubjectScheduleDetailId',
        recordIdMore: 'SubjectScheduleDetailListId',
        sortField: '',
        columns: columns,
        childColumns: childColumns,
        rows: subSchedDetail,
        totalRecords: subSchedDetail.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: true,
        tableType: 'child',
        // parentId: id,
        childArray: 'schedules',
        tableTitle: 'List of Subjects',
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: enrollmentTypes,

    }


    console.log(dropSubjects)
    console.log(student)


    console.log(existingSelectedIregSub)

    console.log(selectedSubjectCode)
    console.log(subSchedDetail)

    console.log(addSubjects)
    console.log(assessedSubjects)



    console.log(isWithAddOns)
    console.log(isValidated)


    console.log(currentAddOnsAmount)
    console.log(addOnsId)
    console.log(subjectSelect)


    console.log(filteredSy)
    console.log(filteredSem)
    return (
        <>
            {isLoadingScreen == false
                ?
                <div className="container">
                    <div className="card">
                        <div className="row container my-5">
                            <div className="col-12 col-xl-4  border-right border-primary">
                                <div className=" p-5">
                                    {student !== null && student.length > 0 ?
                                        <>
                                            <h3 className="">{`${student[0].LastName}, ${student[0].FirstName} ${student[0].MiddleName}`}</h3>
                                            <p className="my-0  ">{student[0].CourseDesc}</p>
                                        </>

                                        :

                                        <>
                                            <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                            {/* //<p className="my-0  ">Student Course</p> */}
                                        </>

                                    }


                                    <hr />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null && student.length > 0 ?
                                            <>
                                                <input readOnly value={student[0].StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>



                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null && student.length > 0 ?
                                            <>
                                                <input readOnly value={student[0].YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null && student.length > 0 ?
                                            <>
                                                <input readOnly value={`${student[0].SchoolYear} - ${student[0].SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "New Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>
                                    <hr />
                                    <button onClick={() => { onSubmitSearch() }} className="btn btn-secondary">Reset</button>

                                </div>


                            </div>
                            <div className="col-12 col-xl-8">

                                <div className="p-5 mb-5 ">
                                    {student === null && sySelect && semSelect ?
                                        <>
                                            <IMSelect
                                                data={sySelect}
                                                onHandleChange={(e) => { selectChange("schoolYear", e) }}
                                                refClassContainer=""
                                                name="SchoolYear"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Choose School Year"
                                                placeHolder="Select School Year"
                                                forwardRef={setValue}
                                                selectedId={filteredSy}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                //description={`Enrollment Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[

                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                            <br />
                                            <IMSelect
                                                data={semSelect}
                                                onHandleChange={(e) => { selectChange('semesterId', e) }}
                                                refClassContainer=""
                                                name="Semester"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Choose Semester"
                                                placeHolder="Select Semester"
                                                forwardRef={setValue}
                                                selectedId={filteredSem}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                //description={`Enrollment Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[

                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                            <br />
                                            <form onSubmit={handleSubmit(onSubmitSearch)} method="post">

                                                <Autocomplete
                                                    id="studentNo"
                                                    options={studentList}
                                                    getOptionLabel={(option) => option.StudentName}
                                                    onChange={(event, value) => {
                                                        if (value) {
                                                            handleGetStudent(value)
                                                            // Handle the selected option
                                                        } else {
                                                            // Handle the case when no option is selected
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Student Number"
                                                            variant="outlined"
                                                            inputRef={register}
                                                            name="studentNo"
                                                            value={studentNo}
                                                            onChange={
                                                                (e) => { handleStudentInputChange(e.target.value) }

                                                            }
                                                        />
                                                    )}
                                                />

                                                <button type="submit" className="mt-3 btn btn-primary">Search</button>
                                            </form>
                                        </>


                                        :
                                        <>

                                            <div className="card  border-0 container mb-5">
                                                {isNotProcessed == false ?
                                                    <>

                                                        <p >Note:<span className="font-italic"> Unchecked the Checkbox to Drop Student Subject</span> </p>
                                                        {subSchedDetail.length > 0 &&
                                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                                <TableHead>
                                                                    <TableRow>
                                                                        <TableCell>Class Code</TableCell>
                                                                        <TableCell>Subject Code</TableCell>
                                                                        <TableCell>Status</TableCell>
                                                                        <TableCell>Date</TableCell>
                                                                        <TableCell>User</TableCell>
                                                                        <TableCell align="center">Select Subject</TableCell>
                                                                    </TableRow>

                                                                </TableHead>
                                                                <TableBody>
                                                                    {subSchedDetail.map(subject => {

                                                                        return (
                                                                            <>
                                                                                <TableRow style={{ height: '20%' }} className={`${subject.SubjectStatusId == 2 ? 'bg-warning' : subject.SubjectStatusId == 3 ? 'bg-success' : subject.SubjectStatusId == 4 ? 'bg-danger' : ''}`}>
                                                                                    <TableCell>{subject.ClassCode}</TableCell>
                                                                                    <TableCell>{subject.SubjectCode}</TableCell>
                                                                                    <TableCell>{subject.SubjectStatus}</TableCell>
                                                                                    <TableCell>{subject.DateCreated}</TableCell>
                                                                                    <TableCell>{subject.CreatedBy}</TableCell>

                                                                                    <TableCell align="center">
                                                                                        <input
                                                                                            type="checkbox"
                                                                                            defaultChecked={(subject.SubjectStatusId == 1 || subject.SubjectStatusId == 3) ? true : false}
                                                                                            onClick={(event) => { onCheckBox(subject.StudentEnrollmentSubjectsId, event, subject) }}
                                                                                        />

                                                                                    </TableCell>
                                                                                </TableRow>


                                                                            </>
                                                                        )
                                                                    })}
                                                                </TableBody>
                                                            </Table>

                                                        }
                                                        <div className='my-5 '>{imSelectDropdownSubject()}</div>
                                                    </>
                                                    :
                                                    <>
                                                    </>
                                                }


                                                <section>
                                                    {isLoading == false ?

                                                        <>
                                                            {isNotProcessed == false ?
                                                                <>
                                                                    <button onClick={() => { onSubmitWithPayment() }} className="mt-5 btn btn-primary mr-5">Submit with Payment</button>
                                                                    <button onClick={() => { onSubmitWithoutPayment() }} className="mt-5 btn btn-success">Submit without Payment</button>
                                                                    <button onClick={() => { setStudent(null) }} className="mt-5 btn btn-secondary float-right">Search Again</button>
                                                                </>
                                                                :
                                                                <>
                                                                    <div className="alert alert-danger" role="alert">
                                                                        This student has not yet been accessed for the Academic Year {filteredSy} - {filteredSy + 1} / {filteredSem}
                                                                    </div>
                                                                    <button onClick={() => { setStudent(null) }} className="mt-5 float-right btn btn-secondary mr-5">Search Again</button>

                                                                </>


                                                            }


                                                        </>

                                                        :
                                                        <button disabled className="mt-5 btn btn-secondary">
                                                            <div className="spinner-border" role="status">
                                                                <span className="sr-only">Loading...</span>
                                                            </div>

                                                        </button>

                                                    }

                                                </section>


                                            </div>
                                        </>

                                    }

                                </div>


                            </div>
                        </div>
                    </div>
                </div>
                :
                <Loading />
            }

        </>
    )
}