import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import IMTable from '../table/IMTable';
import Loading from '../forms/Loading';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal, Box } from '@material-ui/core';
import { useEffect } from 'react';
import { useAppContext } from '../../../../../app/contexts/useAppContext';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function GradeBookPostingModal(props) {
    const { results, handleClose, isOpen, onSubmit, data, onAuthenticateGrades, isStudent,
        isViewingOfSummary, onLoad, classSectionId, isDean, listToBePosted, date, createdBy, isAuthenticated,
        role, unPostingGrades, verifyingGrades, scheduleType, dateTime } = props;
    const { state: { auth } } = useAppContext()
    const [formValues, setFormValues] = useState({})
    const classes = useRowStyles();
    const [rawGrades, setRawGrades] = useState()
    const [saveData, setSaveData] = useState(null)
    const [isValidScore, setIsValidScore] = useState(false)
    const [toBePosted, setToBePosted] = useState(listToBePosted)
    const [checkedItems, setCheckedItems] = useState([])
    const [toBeSubmitted, setToBeSubmitted] = useState([])
    const [toBeAuthenticate, setToBeAuthenticate] = useState([])
    let cnt = 0
    const [loadingState, setLoadingState] = useState(new Array(results.length).fill(false));
    const [isLoadingSubmition, setIsLoadingSubmition] = useState(false)


    const onSave = () => {
        setIsLoadingSubmition(true)
        setLoadingState(new Array(results.length).fill(true))
        onAuthenticateGrades(saveData)
            .then(res => {


                onLoad(results[0].GradingPeriodId)
                    .then(() => {
                        setIsLoadingSubmition(false)
                    })
                setLoadingState(new Array(results.length).fill(false))

            })
        // handleClose()
    }

    const onSubmitSelectedItems = () => {
        setIsLoadingSubmition(true)
        verifyingGrades(toBeSubmitted, toBeAuthenticate)
            .then(res => {
                onLoad(toBeAuthenticate[0].GradingPeriodId)
                    .then(resp => {
                        setToBeSubmitted([])
                        setToBeAuthenticate([])
                        setIsLoadingSubmition(false)
                    })
                // setLoadingState(prevData => {
                //     const updatedLoadingState = [...prevData];
                //     updatedLoadingState[index] = false;
                //     return updatedLoadingState;
                // });
            })
    }


    const onCheckAllItems = (e) => {
        console.log(e.target.checked);
        let isChecked = e.target.checked;

        if (isChecked) {
            // If checkbox is checked, add the 'toSubmit' parameter to 'toBeSubmitted'
            setToBeSubmitted(prevData => [
                ...prevData,
                ...results.map(d => ({
                    gBookFinalGradeDetailsId: d.GBookFinalGradeDetailsId,
                    userId: auth.data.UserId,
                    gradingPeriodId: d.GradingPeriodId
                }))
            ]);

            setToBeAuthenticate(prevData => [
                ...prevData,
                ...results.map(d => ({

                    ...d, SchoolYear: data.SchoolYear, SemesterId: data.SemesterId, IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                    IsLab: scheduleType == 'Laboratory' ? true : false,
                    LecFinalGrade: d.LecGrade,
                    LabFinalGrade: d.LabGrade,
                    CreatedBy: createdBy,
                    DateUpdated: dateTime

                }))
            ])
        } else {
            // If checkbox is unchecked, remove the 'toSubmit' parameter from 'toBeSubmitted'
            setToBeSubmitted([]);
            setToBeAuthenticate([])
        }
    }

    const onCheckItems = (e, toSubmit, toBeAuthenticate) => {
        console.log(e.target.checked);
        let isChecked = e.target.checked;

        if (isChecked) {
            // If checkbox is checked, add the 'toSubmit' parameter to 'toBeSubmitted'
            setToBeSubmitted((prevData) => [...prevData, toSubmit]);
            setToBeAuthenticate((prevData) => [...prevData, toBeAuthenticate])
        } else {
            // If checkbox is unchecked, remove the 'toSubmit' parameter from 'toBeSubmitted'
            setToBeSubmitted((prevData) => prevData.filter(item =>
                item.gBookFinalGradeDetailsId !== toSubmit.gBookFinalGradeDetailsId
                || item.userId !== toSubmit.userId
            ));
            setToBeAuthenticate((prevData => prevData.filter(item =>
                item.GBookFinalGradeDetailsId !== toBeAuthenticate.GBookFinalGradeDetailsId
            )))
        }
    }


    const onUnpostAll = () => {
        for (let i = 0; i < toBePosted.length; i++) {
            unPostingGrades([{ gBookFinalGradeDetailsId: toBePosted[i] }])
        }
        handleClose()

    }

    // useEffect(() => {
    //     if (rawGrades !== undefined && rawGrades !== null) {
    //         console.log(rawGrades)
    //         console.log(results)
    //         let totalRawGradesData = rawGrades.length
    //         let checked = 0
    //         for (let i = 0; i < rawGrades.length; i++) {
    //             console.log(parseInt(rawGrades[i].rawScore))
    //             console.log(parseInt(results[0].HighScore))
    //             if (parseInt(rawGrades[i].rawScore) <= parseInt(results[0].HighScore)) {
    //                 checked++
    //             }
    //         }
    //         console.log(checked + '- -' + totalRawGradesData)

    //         onAuthenticateGrades(rawGrades)
    //             .then(res => {
    //                 onLoad(results[0].GivenInstructionalGuideDetailId, results[0].GivenInstructionalGuideId, results[0].GradingPeriodId)

    //             })
    //     }
    // }, [rawGrades])


    useEffect(() => {
        console.log(toBePosted);
        console.log(results);
        function formatDate(inputDate) {
            const date = new Date(inputDate);
            const year = date.getFullYear();
            const month = (date.getMonth() + 1).toString().padStart(2, '0');
            const day = date.getDate().toString().padStart(2, '0');
            const hours = date.getHours().toString().padStart(2, '0');
            const minutes = date.getMinutes().toString().padStart(2, '0');
            const seconds = date.getSeconds().toString().padStart(2, '0');

            const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;
            return formattedDate;
        }
        let chairIsPostedFlag = results.some(res => res.ChairIsPosted === 1);
        let deanIsPostedFlag = results.some(res => res.DeanIsPosted === 1);
        let toBeSaved
        console.log(chairIsPostedFlag)
        if (role == 'DCI' || role == 'RGR') {
            toBeSaved = chairIsPostedFlag && deanIsPostedFlag ? [] : results.filter(res => toBePosted.includes(res.GBookFinalGradeDetailsId && (res.IsPosted == 1 || res.ChairIsPosted == 1)));
        } else if (role == 'DEN' || role == 'RGD') {
            toBeSaved = deanIsPostedFlag ? [] : results.filter(res => toBePosted.includes(res.GBookFinalGradeDetailsId && res.ChairIsPosted == 1));

        } else if (role == 'CHR') {
            toBeSaved = chairIsPostedFlag == true ? [] : results.filter(res => toBePosted.includes(res.GBookFinalGradeDetailsId && res.IsPosted == 1));

        }



        //dean and chair
        if (role == 'DCI' || role == 'DEN' || role == 'RGR' || role == 'RGD') {
            toBeSaved = toBeSaved.map(res => ({
                ...res,
                DeanIsPosted: 1,
                ChairIsPosted: 1,
                ChairValidationDate: date,
                PostingDate: formatDate(res.PostingDate),
                DeanValidationDate: date,
                CreatedBy: createdBy,
                DateUpdated: date,
                IsForDeanValidation: true,
                IsForChairValidation: true,
                IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                IsLab: scheduleType == 'Laboratory' ? true : false,
                LecFinalGrade: scheduleType == 'Lecture' ? res.Grade : null,
                LabFinalGrade: scheduleType == 'Laboratory' ? res.Grade : null,
                SchoolYear: data.SchoolYear,
                SemesterId: data.SemesterId,
                ScheduleTypeId: scheduleType == 'Lecture' ? 1 : 2

            }));
        } else if (role == 'DEN' || role == 'RGD') {
            toBeSaved = toBeSaved.map(res => ({
                ...res,
                DeanIsPosted: 1,
                ChairValidationDate: null,
                DeanValidationDate: date,
                CreatedBy: createdBy,
                DateUpdated: date,
                IsForDeanValidation: true,
                IsForChairValidation: false,
                IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                IsLab: scheduleType == 'Laboratory' ? true : false,
                LecFinalGrade: scheduleType == 'Lecture' ? res.Grade : null,
                LabFinalGrade: scheduleType == 'Laboratory' ? res.Grade : null,
                SchoolYear: data.SchoolYear,
                SemesterId: data.SemesterId,
                ScheduleTypeId: scheduleType == 'Lecture' ? 1 : 2

            }));
        } else if (role == 'CHR') {
            toBeSaved = toBeSaved.map(res => ({
                ...res,
                ChairIsPosted: 1,
                DeanIsPosted: 0,
                ChairValidationDate: null,
                DeanValidationDate: date,
                CreatedBy: createdBy,
                DateUpdated: date,
                IsForDeanValidation: false,
                IsForChairValidation: true,
                IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                IsLab: scheduleType == 'Laboratory' ? true : false,
                LecFinalGrade: scheduleType == 'Lecture' ? res.Grade : null,
                LabFinalGrade: scheduleType == 'Laboratory' ? res.Grade : null,
                SchoolYear: data.SchoolYear,
                SemesterId: data.SemesterId,
                ScheduleTypeId: scheduleType == 'Lecture' ? 1 : 2

            }));
        }

        // Update the DeanIsPosted field to 1 for all the filtered elements


        console.log(toBeSaved);
        setSaveData(toBeSaved)
    }, [toBePosted]);


    useEffect(() => {
        setToBePosted(listToBePosted)
    }, [listToBePosted])




    console.log(props.value)
    console.log(formValues)
    console.log({ ...props.value, ...formValues })
    console.log(rawGrades)
    console.log(results)
    console.log(toBePosted)
    console.log(saveData)
    console.log(isDean)
    console.log(role)
    console.log(toBeSubmitted)
    console.log(toBeAuthenticate)
    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
                fullWidth
            >
                <section className='row'>
                    <div className='col-9'>
                        <DialogTitle className='pt-0 text-primary mt-5' id="alert-dialog-slide-title ">{results.length > 0 && results[0].GradingPeriod}</DialogTitle>

                    </div>
                    <div className='col-3'>
                        <section className='mt-5 card p-5 bg-secondary mr-3'>
                            <table style={{ borderCollapse: 'collapse' }}>

                                <tbody>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Subject Code: </td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].SubjectCode}</td>
                                    </tr>
                                    {results.length > 0 &&
                                        <>
                                            {results[0].IsLecOverride != 1 ?
                                                <tr className='mb-3'>
                                                    <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lec Percentage</td>
                                                    <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].IsLabOverride != 1 ? results[0].LecPercentageShare : '100.00'}</td>
                                                </tr>
                                                :
                                                <tr className='mb-3'>
                                                    <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lec Percentage</td>
                                                    <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>0 <span className='text-danger'>(Override)</span> </td>
                                                </tr>
                                            }

                                            {results[0].IsLabOverride != 1 ?
                                                <tr className='mb-3'>
                                                    <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lab Percentage</td>
                                                    <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].IsLecOverride != 1 ? results[0].LabPercentageShare : '100.00'}</td>
                                                </tr>
                                                :
                                                <tr className='mb-3'>
                                                    <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lab Percentage</td>
                                                    <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>0 <span className='text-danger'>(Override)</span>  </td>
                                                </tr>
                                            }

                                        </>

                                    }



                                </tbody>
                            </table>
                        </section>
                    </div>
                </section>

                <hr />

                {results.length > 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center">#</TableCell>
                                        <TableCell align="center">Student No</TableCell>
                                        <TableCell align="left">Student Name</TableCell>
                                        {/* {isDean == true && */}
                                        <TableCell align="left">Remarks</TableCell>


                                        {isViewingOfSummary !== true &&
                                            <>
                                                <TableCell align="center">Score</TableCell>
                                                <TableCell align="center">Equivalent Grade</TableCell>
                                            </>}
                                        {results[0].IsWithLab == 1 && results[0].IsWithLec == 1 ?
                                            <>
                                                <TableCell align="center">Lec Grade</TableCell>
                                                <TableCell align="center">Lab Grade</TableCell>
                                            </>

                                            :
                                            <TableCell align="center">{results[0].GradingPeriod} Grade</TableCell>
                                        }
                                        {results[0].IsWithLab == 1 && results[0].IsWithLec == 1 &&
                                            <TableCell align="center">Combined</TableCell>
                                        }
                                        {/* <TableCell align="center">Authenticate</TableCell> */}
                                        <TableCell align="center">

                                            <br />
                                            <label>Check All</label>
                                            <br />
                                            <input
                                                style={{ marginTop: '2px' }}
                                                type='checkbox'
                                                onChange={(e) => { onCheckAllItems(e) }}
                                            />
                                        </TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {results.map((row, index) => {
                                        // const [isChecked, setIsChecked] = useState(row.IsPosted === 1 && row.DeanIsPosted === 1);
                                        cnt++
                                        const handleCheckboxChange = (item) => {
                                            setToBePosted((prevSelectedItems) =>
                                                prevSelectedItems.includes(item)
                                                    ? prevSelectedItems.filter((selectedItem) => selectedItem !== item)
                                                    : [...prevSelectedItems, item]
                                            );
                                        };
                                        console.log(row)
                                        return (
                                            <>
                                                <TableRow className={classes.root}>
                                                    <TableCell align="center">{cnt}</TableCell>
                                                    <TableCell align="center">{row.StudentNo}</TableCell>
                                                    <TableCell align="left">{row.StudentName}</TableCell>
                                                    {/* {isDean == true && */}
                                                    <TableCell align="left">{row.Remarks}</TableCell>

                                                    {/* } */}

                                                    {isViewingOfSummary !== true &&
                                                        <>
                                                            {isStudent != true ?
                                                                <>
                                                                    <input
                                                                        className='form-control'
                                                                        defaultValue={row.Score}
                                                                        onChange={(event) => {
                                                                            const updatedRawGrades = rawGrades ? [...rawGrades] : []; // create a copy of the rawGrades state
                                                                            const existingGradeIndex = updatedRawGrades.findIndex((grade) => grade.studentId === row.StudentId); // find the index of the existing grade with the same id
                                                                            if (existingGradeIndex >= 0) {
                                                                                // if an existing grade is found, update its rawScore value
                                                                                updatedRawGrades[existingGradeIndex].rawScore = event.target.value;
                                                                            } else {
                                                                                // if no existing grade is found, add a new grade object to the array
                                                                                updatedRawGrades.push({
                                                                                    gBookRawGradeId: row.GBookRawGradeId !== null ? row.GBookRawGradeId : 0,
                                                                                    rawScore: parseFloat(event.target.value),
                                                                                    instructionalGuideId: row.GivenInstructionalGuideId,
                                                                                    instructionalGuideDetailId: row.GivenInstructionalGuideDetailId,
                                                                                    subjectScheduleDetailId: row.SubjectScheduleDetailId,
                                                                                    subjectId: row.SubjectId,
                                                                                    userId: data.UserId,
                                                                                    schoolYear: data.SchoolYear,
                                                                                    semesterId: data.SemesterId,
                                                                                    studentId: row.StudentId,
                                                                                    classSectionId: classSectionId

                                                                                });
                                                                            }
                                                                            setRawGrades(updatedRawGrades); // update the rawGrades state with the new array
                                                                        }}
                                                                        type='number'
                                                                    />
                                                                </>

                                                                :

                                                                <TableCell align="center">
                                                                    {row.RawScore}
                                                                </TableCell>



                                                            }
                                                            <TableCell align="center">{parseFloat(row.EquivalentGrade).toFixed(2)}</TableCell>



                                                        </>
                                                    }
                                                    {results[0].IsWithLab == 1 && results[0].IsWithLec == 1 ?
                                                        <>
                                                            <TableCell align="center"
                                                                className={parseFloat(row.LecGrade) < 75.00 && 'text-danger'}
                                                            >
                                                                {row.IsLecOverride == 0 ?
                                                                    <>
                                                                        {row.LecIsPosted == 1 ? parseFloat(row.LecGrade).toFixed(2) : ''}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Override
                                                                    </>

                                                                }

                                                            </TableCell>
                                                            <TableCell align="center"
                                                                className={parseFloat(row.LabGrade) < 75.00 && 'text-danger'}
                                                            >
                                                                {row.IsLabOverride == 0 ?
                                                                    <>
                                                                        {row.LabIsPosted == 1 ? parseFloat(row.LabGrade).toFixed(2) : ''}
                                                                    </>
                                                                    :
                                                                    <>
                                                                        Override
                                                                    </>
                                                                }


                                                            </TableCell>
                                                            {row.IsLecOverride == 0 && row.IsLabOverride == 0 ?
                                                                <>
                                                                    {row.IsRle == 0 &&
                                                                        <TableCell align="center"
                                                                            className={parseFloat((row.LecGrade * (row.LecPercentage / 100)) + (row.LabGrade * (row.LabPercentage / 100))).toFixed(2) < 75.00 && 'text-danger'}
                                                                        >

                                                                            {row.LabIsPosted == 1 && row.LecIsPosted == 1 ? parseFloat((row.LecGrade * (row.LecPercentage / 100)) + (row.LabGrade * (row.LabPercentage / 100))).toFixed(2) : ''}

                                                                        </TableCell>

                                                                    }
                                                                    {row.IsRle == 1 &&
                                                                        <TableCell align="center"
                                                                            className={parseFloat(row.LecGrade) < 75.00 || parseFloat(row.LabGrade) < 75.00 ? 'text-danger' : ''}
                                                                        >

                                                                            {row.LecGrade > 74.99 && row.LabGrade > 74.99 ?
                                                                                <>
                                                                                    {row.LabIsPosted == 1 && row.LecIsPosted == 1 ? parseFloat((row.LecGrade * (row.LecPercentage / 100)) + (row.LabGrade * (row.LabPercentage / 100))).toFixed(2) : ''}

                                                                                </>
                                                                                :
                                                                                <>
                                                                                    70.00
                                                                                </>
                                                                            }

                                                                        </TableCell>

                                                                    }

                                                                </>

                                                                :

                                                                <>
                                                                    {row.IsLecOverride == 1 && row.IsLabOverride == 0 &&
                                                                        <>
                                                                            <TableCell align="center"
                                                                                className={parseFloat(row.LabGrade).toFixed(2) < 75.00 && 'text-danger'}
                                                                            >

                                                                                {parseFloat(row.LabGrade).toFixed(2)}

                                                                            </TableCell>
                                                                        </>
                                                                    }

                                                                    {row.IsLecOverride == 0 && row.IsLabOverride == 1 &&
                                                                        <>
                                                                            <TableCell align="center"
                                                                                className={parseFloat(row.LecGrade).toFixed(2) < 75.00 && 'text-danger'}
                                                                            >

                                                                                {parseFloat(row.LecGrade).toFixed(2)}

                                                                            </TableCell>
                                                                        </>
                                                                    }

                                                                </>

                                                            }

                                                        </>

                                                        :
                                                        <TableCell align="center"
                                                            className={parseFloat(row.Grade).toFixed(2) < 75.00 && 'text-danger'}
                                                        >{parseFloat(row.Grade).toFixed(2)}

                                                        </TableCell>
                                                    }

                                                    {/* {row.IsPosted == 1 && row.IsLec == data.IsLec ?
                                                        <TableCell align="center">
                                                            <input
                                                                onChange={e => { handleCheckboxChange(row.GBookFinalGradeDetailsId) }}
                                                                disabled={role.includes(7) ? (row.IsPosted === 1 && row.ChairIsPosted === 1 && row.DeanIsPosted === 1) || (row.IsPosted === 1 && row.ChairIsPosted === 0 && row.DeanIsPosted === 0) : role.includes(6) ? row.IsPosted === 1 && row.ChairIsPosted === 1 : false}
                                                                checked={role.includes(7) ? row.IsPosted === 1 && row.DeanIsPosted === 1 ? true : toBePosted.includes(row.GBookFinalGradeDetailsId) : role.includes(6) ? row.IsPosted === 1 && row.ChairIsPosted === 1 ? true : toBePosted.includes(row.GBookFinalGradeDetailsId) : false}
                                                                type='checkbox'

                                                            />

                                                        </TableCell>
                                                        :
                                                        <TableCell align="center">
                                                            <input
                                                                disabled
                                                                type='checkbox'
                                                            />

                                                        </TableCell>
                                                    } */}
                                                    {(role == 'CHR' || role == 'DEN' || role == 'DCI' || role == 'RGR' || role == 'DCG' || role == 'RGD') ?
                                                        <>
                                                            {(role == 'CHR' || role == 'DCI' || role == 'RGR' || role == 'DCG') && row.IsPosted == 1 && (row.ChairIsPosted == 0 || row.ChairIsPosted == null) && (row.DeanIsPosted == 0 || row.DeanIsPosted == null) && row.IsLec == data.IsLec ?
                                                                <>
                                                                    <TableCell align="center">
                                                                        {
                                                                            loadingState[index] ?
                                                                                <div className="spinner-border  spinner-border-sm" role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div>
                                                                                :
                                                                                <>
                                                                                    <input
                                                                                        disabled={row.LabIsPosted == 0 || row.LecIsPosted == 0}
                                                                                        type='checkbox'
                                                                                        onChange={(e) => {
                                                                                            onCheckItems(
                                                                                                e,
                                                                                                { gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId, userId: auth.data.UserId, gradingPeriodId: row.GradingPeriodId },
                                                                                                {
                                                                                                    ...row, SchoolYear: data.SchoolYear, SemesterId: data.SemesterId, IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                                                                                                    IsLab: scheduleType == 'Laboratory' ? true : false,
                                                                                                    LecFinalGrade: row.LecGrade,
                                                                                                    LabFinalGrade: row.LabGrade,
                                                                                                    CreatedBy: createdBy,
                                                                                                    DateUpdated: dateTime
                                                                                                }
                                                                                            )
                                                                                        }}
                                                                                        checked={toBeSubmitted.some(d => d.gBookFinalGradeDetailsId === row.GBookFinalGradeDetailsId)}

                                                                                    />

                                                                                </>

                                                                        }

                                                                        {/* <button
                                                                            disabled={
                                                                                row.IsWithLab == 1 && row.IsWithLec == 1 ? row.LecIsPosted != 1 || row.LabIsPosted != 1
                                                                                    : row.IsWithLab == 0 && row.IsWithLec == 1 ? row.LecIsPosted != 1
                                                                                        : row.IsWithLab == 1 && row.IsWithLec == 0 ? row.LabIsPosted != 1
                                                                                            : false
                                                                            }
                                                                            onClick={() => {
                                                                                setLoadingState(prevData => {
                                                                                    const updatedLoadingState = [...prevData];
                                                                                    updatedLoadingState[index] = true;
                                                                                    return updatedLoadingState;
                                                                                });
                                                                                verifyingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId, userId: auth.data.UserId }], {
                                                                                    ...row, SchoolYear: data.SchoolYear, SemesterId: data.SemesterId, IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                                                                                    IsLab: scheduleType == 'Laboratory' ? true : false,
                                                                                    LecFinalGrade: scheduleType == 'Lecture' ? row.Grade : null,
                                                                                    LabFinalGrade: scheduleType == 'Laboratory' ? row.Grade : null,
                                                                                    CreatedBy: createdBy,
                                                                                    DateUpdated: dateTime
                                                                                })
                                                                                    .then(res => {
                                                                                        onLoad(row.GradingPeriodId)
                                                                                        setLoadingState(prevData => {
                                                                                            const updatedLoadingState = [...prevData];
                                                                                            updatedLoadingState[index] = false;
                                                                                            return updatedLoadingState;
                                                                                        });
                                                                                    })


                                                                            }
                                                                            }
                                                                            className={`btn  btn-sm px-3 py-1 mr-5 ${loadingState[index] ? 'btn-secondary' : 'btn-primary'}`}>
                                                                            {
                                                                                loadingState[index] ?
                                                                                    <div className="spinner-border  spinner-border-sm" role="status">
                                                                                        <span class="sr-only">Loading...</span>
                                                                                    </div>
                                                                                    : role == 'CHR' ? 'Submit for Authentication'
                                                                                        : 'Authenticate'
                                                                            }
                                                                        </button> */}

                                                                    </TableCell>



                                                                </>


                                                                :
                                                                <>
                                                                    {row.IsPosted == 0 || row.IsPosted == null &&
                                                                        <TableCell align="center">
                                                                            <p>
                                                                                For Instructor Submission
                                                                            </p>
                                                                        </TableCell>
                                                                    }
                                                                    {row.IsPosted == 1 && row.ChairIsPosted == 1 && row.DeanIsPosted != 1 && (role == 'DEN' || role == 'DCI' || role == 'RGR' || role == 'DCG' || role == 'RGD') &&
                                                                        <TableCell align="center">
                                                                            <input
                                                                                type='checkbox'
                                                                                onChange={(e) => {
                                                                                    onCheckItems(
                                                                                        e,
                                                                                        { gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId, userId: auth.data.UserId, gradingPeriodId: row.GradingPeriodId },
                                                                                        {
                                                                                            ...row, SchoolYear: data.SchoolYear, SemesterId: data.SemesterId, IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                                                                                            IsLab: scheduleType == 'Laboratory' ? true : false,
                                                                                            LecFinalGrade: row.LecGrade,
                                                                                            LabFinalGrade: row.LabGrade,
                                                                                            CreatedBy: createdBy,
                                                                                            DateUpdated: dateTime
                                                                                        }
                                                                                    )
                                                                                }}
                                                                                checked={toBeSubmitted.some(d => d.gBookFinalGradeDetailsId === row.GBookFinalGradeDetailsId)}

                                                                            />
                                                                            {/* <button onClick={() => {
                                                                                setLoadingState(prevData => {
                                                                                    const updatedLoadingState = [...prevData];
                                                                                    updatedLoadingState[index] = true;
                                                                                    return updatedLoadingState;
                                                                                });
                                                                                verifyingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId, userId: auth.data.UserId }], {
                                                                                    ...row, SchoolYear: data.SchoolYear, SemesterId: data.SemesterId, IsLecForGrade: scheduleType == 'Lecture' ? true : false,
                                                                                    IsLab: scheduleType == 'Laboratory' ? true : false,
                                                                                    LecFinalGrade: scheduleType == 'Lecture' ? row.Grade : null,
                                                                                    LabFinalGrade: scheduleType == 'Laboratory' ? row.Grade : null,
                                                                                    CreatedBy: createdBy,
                                                                                    DateUpdated: dateTime
                                                                                })
                                                                                    .then(res => {
                                                                                        onLoad(row.GradingPeriodId)
                                                                                        setLoadingState(prevData => {
                                                                                            const updatedLoadingState = [...prevData];
                                                                                            updatedLoadingState[index] = false;
                                                                                            return updatedLoadingState;
                                                                                        });
                                                                                    })


                                                                            }
                                                                            }
                                                                                className={`btn  btn-sm px-3 py-1 mr-5 ${loadingState[index] ? 'btn-secondary' : 'btn-primary'}`}>{loadingState[index] ? <div className="spinner-border  spinner-border-sm" role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div> : 'Authenticate'}</button> */}
                                                                            {/* <button onClick={() => {
                                                                                setLoadingState(prevData => {
                                                                                    const updatedLoadingState = [...prevData];
                                                                                    updatedLoadingState[index] = true;
                                                                                    return updatedLoadingState;
                                                                                });
                                                                                unPostingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId }], row.GradingPeriodId)
                                                                                    .then(res => {
                                                                                        onLoad(row.GradingPeriodId)
                                                                                        setLoadingState(prevData => {
                                                                                            const updatedLoadingState = [...prevData];
                                                                                            updatedLoadingState[index] = false;
                                                                                            return updatedLoadingState;
                                                                                        });
                                                                                    })
                                                                            }
                                                                            }
                                                                                className={`btn btn-sm px-3 py-1 ${loadingState[index] ? 'btn-secondary' : 'btn-warning'}`} >{loadingState[index] ? <div className="spinner-border  spinner-border-sm" role="status">
                                                                                    <span class="sr-only">Loading...</span>
                                                                                </div> : 'Cancel'}</button> */}
                                                                        </TableCell>
                                                                    }

                                                                    {row.IsPosted == 1 && row.ChairIsPosted == 1 && row.DeanIsPosted != 1 && role == 'CHR' &&
                                                                        <TableCell align="center">
                                                                            <p> <i className="fa fa-check-circle"></i> Validated</p>



                                                                        </TableCell>
                                                                    }

                                                                    {row.IsPosted == 1 && row.ChairIsPosted !== 1 && row.DeanIsPosted != 1 && (role == 'DEN' || role == 'DCI' || role == 'RGD') &&
                                                                        <TableCell align="center">
                                                                            <p> <i className="fa fa-check-circle"></i> For Validation</p>


                                                                        </TableCell>
                                                                    }

                                                                    {row.IsPosted == 1 && row.ChairIsPosted == 1 && row.DeanIsPosted == 1 &&
                                                                        <TableCell align="center">
                                                                            <p> <i className="fa fa-check-circle"></i> Authenticated</p>



                                                                        </TableCell>
                                                                    }



                                                                </>


                                                            }
                                                        </>
                                                        :
                                                        <>
                                                            {role == 'CHR' &&
                                                                <>
                                                                    {row.IsPosted == 1 && row.ChairIsPosted == 0 && row.IsLec == data.IsLec ?
                                                                        <>
                                                                            <TableCell align="center">
                                                                                <button onClick={() => {

                                                                                    verifyingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId, userId: auth.data.UserId, gradingPeriodId: row.GradingPeriodId }])
                                                                                        .then(res => {
                                                                                            onLoad(row.GradingPeriodId)
                                                                                        })


                                                                                }
                                                                                }
                                                                                    className='btn btn-primary btn-sm px-3 py-1 mr-5'>Authenticate</button>
                                                                                {/* <button onClick={() => {
                                                                                    unPostingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId }], row.GradingPeriodId)
                                                                                        .then(res => {
                                                                                            onLoad(row.GradingPeriodId)
                                                                                        })
                                                                                }
                                                                                }
                                                                                    className='btn btn-warning btn-sm px-3 py-1'>Cancel</button> */}
                                                                            </TableCell>



                                                                        </>


                                                                        :
                                                                        <>
                                                                            {row.IsPosted == 0 || row.IsPosted == null &&
                                                                                <TableCell align="center">
                                                                                    <p>

                                                                                    </p>
                                                                                </TableCell>
                                                                            }

                                                                            {row.IsPosted == 1 && row.ChairIsPosted == 1 &&
                                                                                <TableCell align="center">
                                                                                    <button disabled className='btn btn-secondary btn-sm px-3 py-1 mr-5'>Authenticated</button>
                                                                                    {/* <button disabled className='btn btn-secondary btn-sm px-3 py-1'>Cancel</button> */}
                                                                                </TableCell>
                                                                            }



                                                                        </>


                                                                    }
                                                                </>
                                                            }

                                                            {role == 'DEN' || role == 'DCI' || role == 'RGR' || role == 'RGD' &&
                                                                <>
                                                                    {row.IsPosted == 1 && row.ChairIsPosted == 1 && row.DeanIsPosted == 0 && row.IsLec == data.IsLec ?
                                                                        <>
                                                                            <TableCell align="center">
                                                                                <button onClick={() => {

                                                                                    verifyingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId, userId: auth.data.UserId, gradingPeriodId: row.GradingPeriodId }])
                                                                                        .then(res => {
                                                                                            onLoad(row.GradingPeriodId)
                                                                                        })


                                                                                }
                                                                                }
                                                                                    className='btn btn-primary btn-sm px-3 py-1 mr-5'>Authenticate</button>
                                                                                {/* <button onClick={() => {
                                                                                    unPostingGrades([{ gBookFinalGradeDetailsId: row.GBookFinalGradeDetailsId }], row.GradingPeriodId)
                                                                                        .then(res => {
                                                                                            onLoad(row.GradingPeriodId)
                                                                                        })
                                                                                }
                                                                                }
                                                                                    className='btn btn-warning btn-sm px-3 py-1'>Cancel</button> */}
                                                                            </TableCell>



                                                                        </>


                                                                        :


                                                                        <>
                                                                            {row.IsPosted == 0 || row.IsPosted == null &&
                                                                                <TableCell align="center">
                                                                                    <p>

                                                                                    </p>
                                                                                </TableCell>
                                                                            }

                                                                            {row.IsPosted == 1 && row.DeanIsPosted == 1 &&
                                                                                <TableCell align="center">
                                                                                    <button disabled className='btn btn-secondary btn-sm px-3 py-1 mr-5'>Authenticated</button>
                                                                                    {/* <button disabled className='btn btn-secondary btn-sm px-3 py-1'>Cancel</button> */}
                                                                                </TableCell>
                                                                            }



                                                                        </>

                                                                    }
                                                                </>
                                                            }
                                                        </>
                                                    }









                                                </TableRow>
                                            </>
                                        )


                                    })}

                                </TableBody>



                            </Table>


                        </TableContainer>
                    </>
                    :
                    <>
                        <Loading />
                    </>
                }
                {/* table where details will go */}


                <DialogActions>
                    {/* {isAuthenticated == false ? */}
                    {/* <> */}
                    {isLoadingSubmition == false && saveData !== null && saveData !== undefined && saveData.length > 0 ?
                        <>
                            {saveData !== undefined && saveData !== null && saveData.length > 0 &&
                                <>
                                    <button
                                        onClick={() => { onSave() }}
                                        className="btn btn-success">
                                        {
                                            role == 'CHR' ? 'Submit All for Authentication' :
                                                (role == 'DEN' || role == 'DCI' || role == 'RGR' || role == 'RGD') ? 'Authenticate All Grades' : ''

                                        }
                                    </button>

                                </>
                            }

                        </>
                        :

                        <>
                            {saveData !== undefined && saveData !== null && saveData.length > 0 &&
                                <button className="btn btn-secondary" type="button" disabled>
                                    <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                    &nbsp; Loading...
                                </button>
                            }


                        </>

                    }

                    {isLoadingSubmition == false ?
                        <>
                            {saveData !== undefined && saveData !== null && saveData.length > 0 ?
                                <>
                                    <button
                                        onClick={() => { onSave() }}
                                        className="btn btn-success">
                                        {
                                            role == 'CHR' ? 'Submit All for Authentication' :
                                                (role == 'DEN' || role == 'DCI' || role == 'RGR' || role == 'RGD') ? 'Authenticate All Grades' : ''

                                        }
                                    </button>

                                </>

                                :

                                <button
                                    disabled={toBeSubmitted.length == 0}
                                    onClick={() => { onSubmitSelectedItems() }}
                                    className='btn btn-primary'
                                >
                                    Submit All Checked
                                </button>
                            }

                        </>

                        :
                        <button className="btn btn-secondary" type="button" disabled>
                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                            &nbsp; Loading...
                        </button>
                    }


                    {/* <button
                                onClick={() => { onUnpostAll() }}
                                className="btn btn-warning">
                                Cancel All
                            </button> */}




                    {isLoadingSubmition == false ?
                        <button onClick={handleClose} className="btn btn-secondary">
                            Back
                        </button>
                        :
                        <button disabled className="btn btn-secondary">
                            Back
                        </button>
                    }
                </DialogActions>
            </Dialog>
        </>




    );
}