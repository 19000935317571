import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { FeeSelect, ScholarshipSelect } from '../../../References';

export default function Form(props) {
    const { state: { fees, auth } } = useAppContext();
    const { AssessmentDiscountId, ScholarshipId, IsActive } = props.doc;
    const { register, errors, setValue } = useForm();
    const recordCnt = props.doc.length;
    const initialState = recordCnt === 0 ? {
        assessmentDiscountId: props.AssessmentDiscountId,
        scholarshipId: ScholarshipId,
        isActive: true,
        createdBy: auth.data.Username
    } : {
        assessmentDiscountId: props.AssessmentDiscountId,
        scholarshipId: ScholarshipId,
        isActive: IsActive === 1 ? true : false,
        createdBy: auth.data.Username

    }
    const [data, setData] = useState(initialState);

    useEffect(() => {
        register({ name: 'scholarshipId' }, { required: true });
    }, [register])

    const handleChange = name => event => {
        if (name === 'isOptional' || name === 'isActive') {
            setData({ ...data, [name]: event.target.checked });
        } else if (name === 'scholarshipId') {
            setData({ ...data, [name]: event });
        } else {
            setData({ ...data, [name]: event.target.value });
        }
    }

    console.log(data)
    console.log(ScholarshipId)
    console.log(props.doc)

    return (
        <>
            <TableRow key={AssessmentDiscountId}>
                <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
                <TableCell>
                    <ScholarshipSelect
                        refOnHandleChange={handleChange('scholarshipId')}
                        refClassContainer=""
                        refWithLabel={false}
                        refIsRequired={true}
                        forwardRef={setValue}
                        selectedId={ScholarshipId}
                        refWithFilter={false}
                        refClassName={``}
                        refWithDescription={false}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={recordCnt === undefined ? fees.data : []}
                        refError={!!errors.feeId}
                        refErrorMessage={errors.scholarshipId && errors.scholarshipId.type === "required" && 'Fee is required'}
                    />
                </TableCell>


                <TableCell align="center">
                    <FormControl>
                        <FormControlLabel
                            name="isActive"
                            inputRef={register()}
                            control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
                            label="Active"
                        />
                    </FormControl>
                </TableCell>
                <TableCell align="center">
                    {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => props.onHandleAdd(data)}>
                        <i className="fa fa-plus"></i>
                    </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
                        <i className="fa fa-ban"></i>
                    </button>&nbsp;
                        <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(AssessmentDiscountId, data)}>
                            <i className="fa fa-save text-white"></i>
                        </button></>}
                </TableCell>
            </TableRow>
        </>
    );
}