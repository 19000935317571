import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import IMTable from '../table/IMTable';
import Loading from '../forms/Loading';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal, Box } from '@material-ui/core';
import { useEffect } from 'react';
import { create } from 'lodash';
const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

export default function GradeBookDetailFinalModal(props) {
    const { results, handleClose, isOpen, isInstructor, isDeanChair, onPostGrades,
        onLoad, createdBy, date, isPosted, isPrelimPosted, isMidtermPosted, isFinalPosted,
        data, gradingPeriods, deleteDetailsFinal,
        scheduleTypeId } = props;
    const [formValues, setFormValues] = useState({})
    const classes = useRowStyles();
    const [rawGrades, setRawGrades] = useState()
    const [isValidScore, setIsValidScore] = useState(false)
    let cnt = 0
    const onSave = async () => {
        let updatedRawGrades = []; // create a copy of the rawGrades state
        rawGrades.map(async row => {
            updatedRawGrades.push({
                finalGrade: row.computedGrades,
                gBookFinalGradeId: row.gBookFinalGradeId != null ? row.gBookFinalGradeId : 0,
                studentId: row.studentId,
                semesterId: row.semesterId,
                schoolYear: row.schoolYear,
                createdBy: createdBy,
                dateUpdated: date,
                gBookFinalGradeDetailId: row.gBookFinalGradeDetailsId != null ? row.gBookFinalGradeDetailsId : 0,
                gradingPeriodId: row.gradingPeriodId,
                subjectId: row.subjectId,
                grade: row.grade,
                isPosted: 1,
                deanIsPosted: 0,
                chairIsPosted: 0,
                postingDate: date,
                chairValidationDate: null,
                deanValidationDate: null,
                createdBy: createdBy,
                dateUpdated: date,
                remarks: '',
                isForDeanValidation: false,
                isForChairValidation: false,
                instructionalGuideId: row.InstructionalGuideId
            });

        })
        console.log(updatedRawGrades)
        await deleteDetailsFinal(updatedRawGrades)
            .then(d => {
                onPostGrades(updatedRawGrades)
                setIsValidScore(false)
                handleClose()
            })

    }

    const onSaveIndividual = async (val) => {
        let updatedRawGrades = []; // create a copy of the rawGrades state
        for (let i = 0; i < gradingPeriods.length; i++) {
            updatedRawGrades.push({
                finalGrade: val.ComputedGrades,
                gBookFinalGradeId: val.GBookFinalGradeId != null ? val.GBookFinalGradeId : 0,
                studentId: val.StudentId,
                semesterId: val.SemesterId,
                schoolYear: val.SchoolYear,
                createdBy: createdBy,
                dateUpdated: date,
                gBookFinalGradeDetailId: val.GBookFinalGradeDetailsId != null ? val.GBookFinalGradeDetailsId : 0,
                gradingPeriodId: gradingPeriods[i],
                subjectId: val.SubjectId,
                grade: val.Grade,
                isPosted: 1,
                deanIsPosted: 0,
                chairIsPosted: 0,
                postingDate: date,
                chairValidationDate: null,
                deanValidationDate: null,
                createdBy: createdBy,
                dateUpdated: date,
                remarks: '',
                isForDeanValidation: false,
                isForChairValidation: false,
                instructionalGuideId: val.InstructionalGuideId
            });
        }


        console.log(updatedRawGrades)
        await deleteDetailsFinal(updatedRawGrades)
            .then(d => {
                onPostGrades(updatedRawGrades)
                    .then(async res => {
                        await onLoad(0, results[0].InstructionalGuideId, 0)
                            .then(d => {
                                const updatedRawGrades = rawGrades ? [...rawGrades] : []; // create a copy of the rawGrades state
                                const existingGradeIndex = updatedRawGrades.findIndex((grade) =>
                                    grade.studentId === val.StudentId &&
                                    grade.semesterId === val.SemesterId &&
                                    grade.schoolYear === val.SchoolYear &&
                                    grade.subjectId === val.SubjectId
                                )
                                console.log(updatedRawGrades)
                                console.log(existingGradeIndex)

                                if (existingGradeIndex >= 0) {
                                    // if an existing grade is found, update its rawScore value
                                    updatedRawGrades[existingGradeIndex].remarks = val.Remarks;
                                } else {
                                    // if no existing grade is found, add a new grade object to the array
                                    updatedRawGrades.push({
                                        finalGrade: val.ComputedGrades,
                                        gBookFinalGradeId: val.GBookFinalGradeId,
                                        studentId: val.StudentId,
                                        semesterId: val.SemesterId,
                                        schoolYear: val.SchoolYear,
                                        createdBy: createdBy,
                                        dateUpdated: date,
                                        gBookFinalGradeDetailId: val.GBookFinalGradeDetailsId,
                                        gradingPeriodId: val.GradingPeriodId,
                                        subjectId: val.SubjectId,
                                        grade: val.GradingPeriodId == 1 ? parseFloat(val.PrelimGrade) : val.GradingPeriodId == 2 ? parseFloat(val.MidtermGrade) : val.GradingPeriodId == 4 ? parseFloat(val.FinalGrade) : 0.00,
                                        isPosted: 1,
                                        deanIsPosted: 0,
                                        chairIsPosted: 0,
                                        postingDate: date,
                                        chairValidationDate: null,
                                        deanValidationDate: null,
                                        createdBy: createdBy,
                                        dateUpdated: date,
                                        remarks: val.Remarks,
                                        isForDeanValidation: false,
                                        isForChairValidation: false,
                                        finalRemarks: '',
                                        instructionalGuideId: val.InstructionalGuideId



                                    });


                                }
                                setRawGrades(updatedRawGrades); // update the rawGrades state with the new array



                            })
                        setIsValidScore(false)
                    })
                setIsValidScore(false)
                // handleClose()
            })

    }

    useEffect(() => {
        let updatedRawGrades = []; // create a copy of the rawGrades state
        console.log(results)
        results.map(row => {


            // if no existing grade is found, add a new grade object to the array
            for (let i = 0; i < gradingPeriods.length; i++) {
                updatedRawGrades.push({
                    finalGrade: row.ComputedGrades,
                    gBookFinalGradeId: row.GBookFinalGradeId,
                    studentId: row.StudentId,
                    semesterId: row.SemesterId,
                    schoolYear: row.SchoolYear,
                    createdBy: createdBy,
                    dateUpdated: date,
                    gBookFinalGradeDetailId: row.GBookFinalGradeDetailsId,
                    gradingPeriodId: gradingPeriods[i],
                    subjectId: row.SubjectId,
                    grade: gradingPeriods[i] == 1 ? parseFloat(row.PrelimGrade) : gradingPeriods[i] == 2 ? parseFloat(row.MidtermGrade) : gradingPeriods[i] == 4 ? parseFloat(row.FinalGrade) : 0.00,
                    isPosted: 1,
                    deanIsPosted: 0,
                    chairIsPosted: 0,
                    postingDate: date,
                    chairValidationDate: null,
                    deanValidationDate: null,
                    createdBy: createdBy,
                    dateUpdated: date,
                    remarks: '',
                    isForDeanValidation: false,
                    isForChairValidation: false,
                    instructionalGuideId: row.InstructionalGuideId


                });
            }

        })
        setRawGrades(updatedRawGrades); // update the rawGrades state with the new array


    }, [results])

    useEffect(() => {
        if (rawGrades !== undefined && rawGrades !== null) {
            console.log(rawGrades)
            console.log(results)
            let totalRawGradesData = rawGrades.length
            let checked = 0
            for (let i = 0; i < rawGrades.length; i++) {
                console.log(parseInt(rawGrades[i].rawScore))
                console.log(parseInt(results[0].HighScore))
                if (parseInt(rawGrades[i].rawScore) <= parseInt(results[0].HighScore)) {
                    checked++
                }
            }
            console.log(checked + '- -' + totalRawGradesData)
            if (checked == totalRawGradesData) {
                setIsValidScore(true)
            } else {
                setIsValidScore(false)
            }
        }
    }, [rawGrades])





    console.log(props.value)
    console.log(formValues)
    console.log({ ...props.value, ...formValues })
    console.log(rawGrades)
    console.log(results)
    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="lg"
                fullWidth
            >
                <section className='row'>
                    <div className='col-9'>
                        <DialogTitle id="alert-dialog-slide-title">Grade Book Details</DialogTitle>
                    </div>
                    <div className='col-3'>
                        <section className='mt-5 card p-5 bg-secondary mr-3'>
                            <table style={{ borderCollapse: 'collapse' }}>

                                <tbody>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lec Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].LecPercentageShare}</td>
                                    </tr>
                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Lab Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].LabPercentageShare}</td>
                                    </tr>

                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Prelim Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].PrelimPercentage}</td>
                                    </tr>
                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Midterm Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].MidtermPercentage}</td>
                                    </tr>
                                    <tr className='mb-3'>
                                        <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Finals Percentage</td>
                                        <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results.length > 0 && results[0].FinalPercentage}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </section>
                    </div>
                </section>

                <hr />

                {results.length > 0 ?
                    <>
                        <TableContainer component={Paper}>
                            <Table size="small" aria-label="a dense table">
                                <TableHead className=''>
                                    <TableRow>
                                        <TableCell align="center">#</TableCell>
                                        <TableCell align="center">Student No</TableCell>
                                        <TableCell align="left">Student Name</TableCell>
                                        {parseFloat(results[0].PrelimPercentage) > 0.00 && <TableCell align="left">Prelim</TableCell>}
                                        {parseFloat(results[0].MidtermPercentage) > 0.00 && <TableCell align="left">Midterm</TableCell>}
                                        {parseFloat(results[0].FinalPercentage) > 0.00 && <TableCell align="left">Finals</TableCell>}


                                        {/* <TableCell align="left">Remarks</TableCell> */}
                                        <TableCell align="center">Computed Grade</TableCell>
                                        {/* <TableCell align="center">Action</TableCell> */}




                                    </TableRow>
                                    {/* <TableRow className='border-top-0'>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell>
                                        <TableCell align="left"></TableCell>
                                        {parseFloat(results[0].PrelimPercentage) > 0.00 && <TableCell align="left">Lec</TableCell>}
                                        {parseFloat(results[0].PrelimPercentage) > 0.00 && <TableCell align="left">Lab</TableCell>}
                                        {parseFloat(results[0].PrelimPercentage) > 0.00 && <TableCell align="left">Sum</TableCell>}
                                        {parseFloat(results[0].MidtermPercentage) > 0.00 && <TableCell align="left">Lec</TableCell>}
                                        {parseFloat(results[0].MidtermPercentage) > 0.00 && <TableCell align="left">Lab</TableCell>}
                                        {parseFloat(results[0].MidtermPercentage) > 0.00 && <TableCell align="left">Sum</TableCell>}
                                        {parseFloat(results[0].FinalPercentage) > 0.00 && <TableCell align="left">Lec</TableCell>}
                                        {parseFloat(results[0].FinalPercentage) > 0.00 && <TableCell align="left">Lab</TableCell>}
                                        {parseFloat(results[0].FinalPercentage) > 0.00 && <TableCell align="left">Sum</TableCell>}


                                 
                                        <TableCell align="center"></TableCell>
                                      




                                    </TableRow> */}
                                </TableHead>
                                <TableBody>
                                    {results.map((row) => {
                                        cnt++
                                        return (
                                            <>
                                                <TableRow className={classes.root}>
                                                    <TableCell align="center">{cnt}</TableCell>
                                                    <TableCell align="center">{row.StudentNo}</TableCell>
                                                    <TableCell align="left">{row.StudentName}</TableCell>
                                                    {/* Prelim -----------------------------------*/}
                                                    {/* {parseFloat(row.PrelimPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.PrelimGrade) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.PrelimGradeLec).toFixed(2)}</TableCell>
                                                    }
                                                    {parseFloat(row.PrelimPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.PrelimGrade) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.PrelimGradeLab).toFixed(2)}</TableCell>
                                                    } */}
                                                    {parseFloat(row.PrelimPercentage) > 0.00
                                                        && <TableCell
                                                            className={parseFloat(row.CombinedPrelim) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.CombinedPrelim).toFixed(2)}</TableCell>
                                                    }
                                                    {/* Midterm -----------------------------------  */}
                                                    {/* {parseFloat(row.MidtermPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.MidtermGrade) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.MidtermGradeLec).toFixed(2)}</TableCell>
                                                    }
                                                    {parseFloat(row.MidtermPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.MidtermGrade) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.MidtermGradeLab).toFixed(2)}</TableCell>
                                                    } */}
                                                    {parseFloat(row.MidtermPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.CombinedMidterm) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.CombinedMidterm).toFixed(2)}</TableCell>
                                                    }
                                                    {/* Finals ----------------------------------- */}
                                                    {/* {parseFloat(row.FinalPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.FinalGrade) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.FinalGradeLec).toFixed(2)}</TableCell>
                                                    }
                                                    {parseFloat(row.FinalPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.FinalGrade) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.FinalGradeLab).toFixed(2)}</TableCell>
                                                    } */}
                                                    {parseFloat(row.FinalPercentage) > 0.00
                                                        && <TableCell className={parseFloat(row.CombinedFinals) < 75 ? 'text-danger' : ''} align="left">{parseFloat(row.CombinedFinals).toFixed(2)}</TableCell>
                                                    }



                                                    <TableCell align="center">{parseFloat(row.PostedComputedGrades).toFixed(2)}</TableCell>





                                                </TableRow>

                                            </>
                                        )


                                    })}

                                </TableBody>



                            </Table>


                        </TableContainer>
                    </>
                    :
                    <>
                        <Loading />
                    </>
                }
                {/* table where details will go */}


                <DialogActions>
                    {/* {isInstructor &&
                        <>
                            {results[0].IsFinalPosted == 0 ?
                                <button
                                    onClick={() => { onSave() }}
                                    className="btn btn-success">
                                    Post Grades
                                </button>

                                :
                                <div className="alert alert-secondary mb-0" role="alert">
                                    Grades already Posted
                                </div>

                            }


                        </>

                    } */}

                    {/* {isDeanChair &&
                        <button
                            disabled
                            className="btn btn-secondary">
                            Validate Grade
                        </button>
                    } */}

                    <button onClick={handleClose} className="btn btn-secondary">
                        Back
                    </button>
                </DialogActions>
            </Dialog>
        </>




    );
}