import instance from "../../../../../apis/local/systemAPI";



export const getAssessmentStudentSubjectScheduleDetail = async (val) => {
    const result = await instance.post(`api/schedule/details/studentSubjectScheduleDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentEnrollmentSubjects = async (val) => {
    const result = await instance.post(`api/student/student-enrollment-subjects/getStudentEnrollmentSubjects`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAssessmentTemplateDetail = async (val) => {
    const result = await instance.post(`/api/assessment/template-details/studentAssessmentTemplateDetail`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentAssessmentOtherFees = async (val) => {
    const result = await instance.post(`api/assessment/student-other-fees/studentOtherFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentAssessment = async (val) => {
    const result = await instance.post(`api/assessment/templates/studentAssessment`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentLabFee = async (val) => {
    const result = await instance.post(`api/assessment/student-lab-fees/studentLabFee`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentEnrollmentAccount = async (val) => {
    const result = await instance.post(`api/enrollment/account/studentEnrollmentAccount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAssessmentStudentTotalUnits = async (val) => {
    const result = await instance.post(`api/schedule/details/studentTotalUnits`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAccountingStudentAccount = async (val) => {
    const result = await instance.post(`api/accounting/student/account`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentBackBalance = async (val) => {
    const result = await instance.post(`api/accounting/student/studentBackBalance`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAccountingStudentListOfAssessment = async (val) => {
    const result = await instance.post(`api/accounting/student/assessmentList`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getStudentDiscount = async (val) => {
    const result = await instance.post(`api/cashiering/student/discount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDiscountParticulars = async (val) => {
    const result = await instance.post(`api/cashiering/student/discountParticulars`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAstraSubjects = async (val) => {
    const result = await instance.post(`api/schedule/details/studentSubjects`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentCampusOtherFee = async (val) => {
    const result = await instance.post(`api/campus/otherFee-migrate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getCampusBatchkey = async (data) => {
    const result = await instance.post("api/campus/student-batchkey-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentAddOns = async (data) => {
    const result = await instance.post("api/assessment/student-other-fees/studentAddOns", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getStudentAddDropSubjects = async (data) => {
    const result = await instance.post("api/student/student-enrollment-subjects/getStudentSubjectsWithSchedule", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createAssessmentAddOns = async (formValues) => {
    const result = await instance.post("api/assessment/add-ons", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAssessmentAddOns = async () => {
    const result = await instance.get("api/assessment/add-ons");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCurriculumSubjectFilter = async (con) => {
    const result = await instance.post(`api/course-outlines/subjectFilter`, con);

    if (result.status === 200 || result.status === 201) {
        return result.data.data;
    }

}

export const checkIsAddSubjectPaid = async (formValues) => {
    const result = await instance.post("api/assessment/add-ons/isAddSubjectPaid", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentSchedule = async (val) => {
    const result = await instance.post(`api/cashiering/student/cashierPaymentSchedule`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateEnrollmentDiscount = async (val) => {
    const result = await instance.post(`api/accounting/student/addUpdateEnrollmentDiscount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}