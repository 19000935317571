import React from 'react';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import Forms from './Forms';
import { createSchoolDetail } from './__hooks__';

export default function Create(props) {
    const { state: { auth }, dispatch } = useAppContext();


    const onSubmit = formValues => {
        const execute = async () => {
            await createSchoolDetail({ ...formValues, schoolId: 0, createdBy: auth.data.Username })
                .then(response => {
                    if (response.success) {
                        dispatch({ type: referenceTypes.LIST_LINK, data: response.data });

                        history.push(`${referenceTypes.LIST_LINK}`);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        localStorage.clear();
                        history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();
    }

    return (
        <>
            <div className="kt-container  kt-grid__item kt-grid__item--fluid">
                <div className="row ac-minimum-height-container-350">
                    <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
                        <Forms onSubmit={onSubmit} doc={[]} />
                    </div>
                </div>
            </div>
        </>
    );
}