import { number } from "prop-types";
import React, { useState, useRef, forwardRef, useEffect } from "react";
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import ReactToPrint, { useReactToPrint } from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import {
    getStudentGradeForTOR
} from './__hooks__';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './__hooks__/styles.css'
import { result } from "lodash";

const useStyles = makeStyles({
    table: {
        minWidth: 430
    },

    blueBGColor: {
        backgroundColor:"#b3ebfb !important"
    },

    redBGColor: {
        backgroundColor:"#ff6c6c !important"
    },

    topAndSideBorders: {
        borderBottomStyle:"none",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    sideBordersOnly: {
        borderBottomStyle:"none",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },
    
    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    allSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    }

});

export default function PrintTOR(props) {
    const student = props["results"]["0"];
    const [prevStudentNo, setPrevStudentNo] = useState({
        studentNo: null
    });

    const [count, setCount] = useState(0);
    //const studentGradeAssessment = props["results"]["1"];
    const [studentGrades, setStudentGrades] = useState([]);
    //const [curriculum, setCurriculum] = useState([]);

    // useEffect(() => {
    //     if(studentGradeAssessment.length != 0 ){
    //         setGradeAssessment(studentGradeAssessment);
    //     }
    // }, []);
    

    // const [controlNum, setControlNum] = useState('');
    // const academicYear = props["results"]["0"];
    // const semester = props["results"]["1"];
    // const generatedTime = props["results"]["2"];
    // const studentDetails = props["results"]["3"];
    // const results = props["results"]["4"];
    // let gradingPeriod = props["results"]["5"];
    // const totalUnits = props["results"]["6"];
    // const weightedAverage = props["results"]["7"];
    // const controlNumData = props["results"]["8"];
    // const clearanceLength = props["results"]["9"];

    const { dispatch } = useAppContext();
    
    // if(gradingPeriod == "Final Rating"){
    //     gradingPeriod = "Final";
    // }

    //const gradingPeriodUpper = gradingPeriod.toUpperCase();

    // const onGetControlNumber = async () => {
    //     if(controlNumData.numOfGrades > 0){
    //         const values = {
    //             studentNo: controlNumData.studentNo,
    //             schoolYear: controlNumData.schoolYear,
    //             semesterId: controlNumData.semesterId,
    //             gradingPeriodId: controlNumData.gradingPeriodId,
    //             createdBy: controlNumData.createdBy
    //         }
    
    //         await getControlNumber(values)
    //             .then(result => {
    //                 if(result.success){
    //                     if(result.results > 0){
    //                         console.log(result);
    //                         setControlNum(result.data[0].CtrlNo);
    //                     }
    //                 }
    //             })
    //     }
    // }

    const executeGetStudentGrades = async () => {
        // if(studentGrades.length == 0){
        let isSuccess = false;

        if(student !== null) {
            if(student.StudentNo != prevStudentNo.studentNo){
                setPrevStudentNo((prevData) => {
                    return {...prevData, 
                        studentNo: student.StudentNo, 
                    }
                });

                let values = {
                    studentNo: student.StudentNo
                }
    
                await getStudentGradeForTOR(values)
                    .then(res => {
                    if(res.success){
                        if (res.data.length > 0) {
                            setCount(res.data.length);
                            let originalData = res.data;
    
                            const transformedArray = [];
    
                            for (const key in originalData) {
                                const entry = originalData[key];
                                const { Period, SubjectCode, FinalGrade, Remarks, Description, Units, Equivalent } = entry;
                            
                                const yearLevelIndex = transformedArray.findIndex(item => item.Period === Period);
                            
                                if (yearLevelIndex === -1) {
                                  transformedArray.push({ Period: Period, Details: [{ SubjectCode, FinalGrade, Remarks, Description, Units, Equivalent }]});
                                } else {
                                  transformedArray[yearLevelIndex].Details.push({ SubjectCode, FinalGrade, Remarks, Description, Units, Equivalent });
                                }
                            }
            
                            setStudentGrades(transformedArray);
                            printReportCard();
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Student has no grades yet." });
                        }
                    }
                    })
                    .catch(error => {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                      if (error.response.status === 401) {
                        //history.push('/auth/login');
                      } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                      }
                    })
            } else {
                if(count > 0){
                    printReportCard();
                } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Student has no grades yet." });
                }
            }
        } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Please select a student." });
        }
    }

    //let isFinal = false;
    let componentRef = useRef();
    const classes = useStyles();
    //const date = new Date();

    //let day = date.getDate();
    //let month = date.getMonth() + 1;
    //let year = date.getFullYear();
    //let currentDate = `${month}/${day}/${year}`;

    //gradingPeriod == "Final" ? isFinal = true : isFinal = false;

    const Component = forwardRef((props, ref) => {
        return (
            <>                  
                <div className="d-none">
                    <div ref={ref}>
                        <div className="container" >
                            <main className="card border-0 p-5">
                                <section className="card-body my-5" >
                                    <img style={{ width: "200px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                    <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                    <h4 className="text-center">Transcript of Record</h4>
                                    <br></br>
                                    <table>
                                        <tbody  style={{fontSize:"1.1em"}}>
                                            <tr>
                                                <td style={{width:"50%"}}>
                                                    <span>Student No. / Name: </span>
                                                    <span style={{fontWeight:"500"}}>{student == null ? "" : student.StudentNo} - {student == null ? "" : `${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</span>
                                                </td>
                                                {/* <td>&nbsp;&nbsp;&nbsp;</td>
                                                <td style={{width:"50%"}}>
                                                    <span>Program: </span>
                                                    <span style={{fontWeight:"500"}}>{student == null ? "" : student.CourseDesc}</span>
                                                </td> */}
                                            </tr>
                                            {/* <tr>
                                                <td style={{width:"50%"}}>
                                                    <span>Year Level: </span>
                                                    <span style={{fontWeight:"500"}}>{student == null ? "" : student.YearLevel}</span>
                                                </td>
                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                <td style={{width:"50%"}}>
                                                    <span>Curriculum Code: </span>
                                                    <span style={{fontWeight:"500"}}>{student == null ? "" : student.CurriculumCode}</span>
                                                </td>
                                            </tr>
                                            <tr>
                                                <td style={{width:"50%"}}>
                                                    <span>Last Enrollment: </span>
                                                    <span style={{fontWeight:"500"}}>{student == null ? "" : `${student.AcademicYear} - ${student.SemesterText}`}</span>
                                                </td>
                                                <td>&nbsp;&nbsp;&nbsp;</td>
                                                <td style={{width:"50%"}}></td>
                                            </tr> */}
                                        </tbody>
                                    </table>
                                    {studentGrades.map((res, index) => {
                                        return (
                                            <div key={index} >
                                                <div className="facultySub">
                                                    <br></br>
                                                    <h6 className=""><span className="lead facultySub"></span>{res.Period}</h6>
                                                    <TableContainer component={Paper} >
                                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                                            <TableHead >
                                                                <TableRow>
                                                                    <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Subject Code</TableCell>
                                                                    <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Description</TableCell>
                                                                    <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Final Grade</TableCell>
                                                                    <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Units</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {res.Details.map((row, index) => (
                                                                    <TableRow key={index} >
                                                                        <TableCell style={{fontWeight:"bold"}}>{row.SubjectCode}</TableCell>
                                                                        <TableCell>{row.Description}</TableCell>
                                                                        <TableCell>{row.FinalGrade}</TableCell>
                                                                        <TableCell>{row.Units}</TableCell>
                                                                    </TableRow> 
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <br></br>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </section>
                            </main>
                        </div>
                    </div>
                </div>
            </>
        )
    })

    // const executePrint = (reprintStatus) => {
    //     if(reprintStatus == 1){
    //         alert("Test; Check if reports has already been generated");
    //         printReportCard();
    //     } else if (reprintStatus == 0) {
    //         alert("LOL");
    //         printReportCard();
    //     }
    // }

    const printReportCard = useReactToPrint({
        onBeforePrint: handleBeforePrint,
        //onBeforeGetContent: onGetControlNumber,
        content: () => componentRef.current
    });

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }

    return (
        <>
            <button 
                id="print-tor" 
                //style={{width:"15%"}} 
                className="mt-3 btn btn-success"
                onClick={executeGetStudentGrades} {...student === null ? {disabled:true} : {}}>
                <i className="fa fa-print"></i>&nbsp;Generate TOR
            </button>
            
            {/* button to trigger printing of target component */}
            {/* <ReactToPrint
                trigger={() => <button className="btn btn-success btn-block" {...clearanceLength > 0 ? {disabled:true} : {}}>Print</button>}
                onBeforePrint={handleBeforePrint}
                onBeforeGetContent={onGetControlNumber}
                content={() => componentRef.current}
            /> */}
            <Component ref={componentRef}/>
        </>
    )
}