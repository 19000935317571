import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { admissionsTypes } from "./__hooks__/types";
import {
    updateStudentInfo, updateStudent, updateEnrollment, updateStudentFamilyBackground, updateSchoolHistory,
    getCivilStatusInfo, getNationalityInfo, getSchoolInfo, createScholarship, updateStudentDiscount
} from "./__hooks__";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import ParentForm from "../../../../../_metronic/layout/components/custom/forms/ParentForm";
import EducationBackground from "../../../../../_metronic/layout/components/custom/forms/EducationBackground";
import history from "../../../../history";
import { useParams } from "react-router-dom";
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";


export default function FormUpdate(props) {
    const { dispatch } = useAppContext();

    const { register, handleSubmit, errors, setValue } = useForm()
    const { id } = useParams()
    const [data, setData] = useState({ scholarship: 0, barangay: 0, municipality: 0, province: 0, country: 0 })
    const [fatherData, setFatherData] = useState()
    const [motherData, setMotherData] = useState()
    const [preElementaryData, setPreElementaryData] = useState()
    const [elementaryData, setElementaryData] = useState()
    const [jrHighSchoolData, setJrHighSchoolData] = useState()
    const [srHighSchoolData, setSrHighSchoolData] = useState()
    const currDate = new Date(props.studentDetails.birthDate).toISOString().split('T')[0]
    const today = new Date()
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate()
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds()
    const dateTime = date + ' ' + time

    const capitalizeFirstLetter = (words) => {
        console.log(words)
        if (words === null) {
            return null;

        } else {


            var separateWord = words.toLowerCase().split(' ');
            for (var i = 0; i < separateWord.length; i++) {
                separateWord[i] = separateWord[i].charAt(0).toUpperCase() +
                    separateWord[i].substring(1);
            }
            return separateWord.join(' ');




        }

    }

    const selectHandleChange = name => event => {
        setData({ ...data, [name]: event });
    }

    const onUpdate = formValues => {


        const { lastName, firstName, middleName, gender, civilStatus, religion,
            birthDate, tel, cel, email, address, course, yearLevel, school, studentNo, barangay, province, country, municipality } = formValues

        console.log(formValues)

        const indexCourse = props.courseSelect.findIndex(el => el.tableId === data.course);

        const enrollmentValues = {
            instituteId: props.courseSelect[indexCourse],
            courseId: course,
            yearLevelId: yearLevel,
            dateUpdated: dateTime,
            studentNo: studentNo

        }

        const studentValues = {
            civilStatusId: civilStatus,
            religionId: religion,
            telNo: tel,
            celNo: cel,
            emailAddress: email,
            address1: address,
            courseId: course,
            yearLevelId: yearLevel,
            schoolId: school,
            dateUpdated: dateTime,
            studentNo: studentNo,
            barangay: barangay,
            provinceId: province,
            municipalityId: municipality,
            countryId: country
        }

        const studentsInfoValue = {
            lastName: lastName,
            firstName: firstName,
            middleName: middleName,
            gender: gender,
            birthDate: birthDate,
            birthPlace: address,
            dateUpdated: dateTime,
            studentNo: studentNo
        }


        const studentDiscountValue = {
            studentId: props.student[0].StudentId,
            scholarshipId: data.scholarship,
            isActive: 1
        }





        const studentFamilyBackgroundValues = {
            fatherName: capitalizeFirstLetter(fatherData.fatherName),
            fatherCitizen: fatherData.fatherCitizenship,
            fatherHomeAddress: capitalizeFirstLetter(fatherData.fatherHomeAddress),
            fatherHighestEducationalAttainment: fatherData.fatherHighestEducationalAttainment,
            fatherBusinessName: capitalizeFirstLetter(fatherData.fatherOccupation),
            fatherBusinessAddress: capitalizeFirstLetter(fatherData.fatherOccupationAddress),
            fatherBusinessContactNo: fatherData.fatherContactNumber,
            motherName: capitalizeFirstLetter(motherData.motherName),
            motherCitizen: motherData.motherCitizenship,
            motherHomeAddress: capitalizeFirstLetter(motherData.motherHomeAddress),
            motherHighestEducationalAttainment: motherData.motherHighestEducationalAttainment,
            motherBusinessName: capitalizeFirstLetter(motherData.motherOccupation),
            motherBusinessContactNo: motherData.motherContactNumber,
            motherBusinessAddress: capitalizeFirstLetter(motherData.motherOccupationAddress),
            createdBy: 'System',
            dateUpdated: dateTime,

        }


        const preElementaryInfoValues = {
            schoolId: preElementaryData.preElementarySchoolName,
            dateUpdated: dateTime,
        }

        const elementaryInfoValues = {
            schoolId: elementaryData.elementarySchoolName,
            dateUpdated: dateTime,
        }


        const jrHighSchoolInfoValues = {
            schoolId: jrHighSchoolData.jrHighSchoolName,
            dateUpdated: dateTime,

        }


        const srHighSchoolInfoValues = {
            schoolId: srHighSchoolData.srHighSchoolName,
            dateUpdated: dateTime,


        }




        onUpdateEnrollment(props.studentDetails.enrollmentId, enrollmentValues)
        onUpdateStudent(props.studentDetails.studentId, studentValues)
        if (props.studentDiscount.length > 0) {
            onUpdateStudentDiscount(props.studentDiscount[0].StudentDiscountId, studentDiscountValue)
        } else {
            onCreateStudentDiscount(studentDiscountValue)
        }
        onUpdateStudentInfo(props.studentDetails.studentInfoId, studentsInfoValue)
        onUpdateStudentFamilyBackground(props.studentDetails.familyBackgroundId, studentFamilyBackgroundValues)

        for (let i = 0; i < props.schoolHistory.length; i++) {
            if (props.schoolHistory[i].SchoolLevelId === 1) {
                onUpdateSchoolHistory(props.schoolHistory[i].StudentSchoolHistoryId, preElementaryInfoValues)
            } else if (props.schoolHistory[i].SchoolLevelId === 2) {
                onUpdateSchoolHistory(props.schoolHistory[i].StudentSchoolHistoryId, elementaryInfoValues)
            } else if (props.schoolHistory[i].SchoolLevelId === 3) {
                onUpdateSchoolHistory(props.schoolHistory[i].StudentSchoolHistoryId, jrHighSchoolInfoValues)
            } else if (props.schoolHistory[i].SchoolLevelId === 4) {
                onUpdateSchoolHistory(props.schoolHistory[i].StudentSchoolHistoryId, srHighSchoolInfoValues)
            }
        }
        dispatch({
            type: componentTypes.SHOW_SNACKBAR,
            snackBarStatus: 'success',
            snackBarDuration: 5000,
            snackBarMessage: 'Student Detail Updated'
        });


        console.log(enrollmentValues)
        console.log(studentValues)
        if (props.identifier === undefined) {
            history.push(`${admissionsTypes.LIST_LINK_MORE}`)

        } else if (props.identifier == 'applicantList') {
            history.push(`/admin/admissions/applicant-data-entry`)

        } else if (props.identifier == 'student201') {
            history.push(`/admin/student/student-201`)

        }




    }

    const onUpdateEnrollment = async (id, values) => {
        await updateEnrollment(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }
    const onUpdateStudent = async (id, values) => {
        await updateStudent(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }

    const onUpdateStudentInfo = async (id, values) => {
        await updateStudentInfo(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }

    const onUpdateStudentFamilyBackground = async (id, values) => {
        await updateStudentFamilyBackground(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }


    const onUpdateSchoolHistory = async (id, values) => {
        await updateSchoolHistory(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }

    const onCreateStudentDiscount = async (values) => {
        await createScholarship(values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }

    const onUpdateStudentDiscount = async (id, values) => {
        await updateStudentDiscount(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
            })
    }

    const fatherInfoHandler = (fatherData) => {
        console.log(fatherData)
        const fatherInfo = {
            fatherName: fatherData.fatherName, fatherOccupation: fatherData.fatherOccupation, fatherOccupationAddress: fatherData.fatherOccupationAddress,
            fatherContactNumber: fatherData.fatherContactNumber, fatherHomeAddress: fatherData.fatherHomeAddress, fatherMaritalStatus: fatherData.fatherMaritalStatus,
            fatherCitizenship: fatherData.fatherCitizenship, fatherHighestEducationalAttainment: fatherData.fatherHighestEducationalAttainment
        }

        setFatherData(fatherInfo)

    }

    const motherInfoHandler = (data) => {
        const motherInfo = {
            motherName: data.motherName, motherOccupation: data.motherOccupation, motherOccupationAddress: data.motherOccupationAddress,
            motherContactNumber: data.motherContactNumber, motherHomeAddress: data.motherHomeAddress, motherMaritalStatus: data.motherMaritalStatus,
            motherCitizenship: data.motherCitizenship, motherHighestEducationalAttainment: data.motherHighestEducationalAttainment
        }
        setMotherData(motherInfo)

    }

    const preElementaryInfoHandler = (data) => {
        const preElementaryInfo = {
            preElementarySchoolName: data.preElementaryName,
            preElementarySchoolAddress: data.preElementaryAddress,
            preElementarySchoolAward: data.preElementaryAward,
            preElementaryFromYear: data.fromYear,
            preElementaryToYear: data.toYear,
        }

        setPreElementaryData(preElementaryInfo)
    }

    const elementaryInfoHandler = (data) => {
        const elementaryInfo = {
            elementarySchoolName: data.elementaryName,
            elementarySchoolAddress: data.elementaryAddress,
            elementarySchoolAward: data.elementaryAward,
            elementaryFromYear: data.fromYear,
            elementaryToYear: data.toYear,
        }

        setElementaryData(elementaryInfo)
    }


    const jrHighSchoolInfoHandler = (data) => {
        const jrHighSchoolInfo = {
            jrHighSchoolName: data.jrHighSchoolName,
            jrHighSchoolAddress: data.jrHighSchoolAddress,
            jrHighSchoolAward: data.jrHighSchoolAward,
            jrHighFromYear: data.fromYear,
            jrHighToYear: data.toYear,
        }

        setJrHighSchoolData(jrHighSchoolInfo)
    }


    const srHighSchoolInfoHandler = (data) => {
        const srHighSchoolInfo = {
            srHighSchoolName: data.srHighSchoolName,
            srHighSchoolAddress: data.srHighSchoolAddress,
            srHighSchoolAward: data.srHighSchoolAward,
            srHighFromYear: data.fromYear,
            srHighToYear: data.toYear,
        }

        setSrHighSchoolData(srHighSchoolInfo)
    }

    const onBack = () => {
        if (props.identifier == undefined) {
            history.push(`/admin/student/student-master-lists`)

        } else if (props.identifier == 'applicantList') {
            history.push(`/admin/admissions/applicant-data-entry`)

        } else if (props.identifier == 'student201') {
            history.push(`/admin/student/student-201`)

        }
    }

    useEffect(() => {
        register({ name: 'civilStatus' },
            { required: false });
    }, [register])

    useEffect(() => {
        register({ name: 'religion' },
            { required: false });
    }, [register])
    useEffect(() => {
        register({ name: 'gender' },
            { required: false });
    }, [register])
    useEffect(() => {
        register({ name: 'course' },
            { required: true });
    }, [register])
    useEffect(() => {
        register({ name: 'province' },
            { required: false });
    }, [register])
    useEffect(() => {
        register({ name: 'country' },
            { required: false });
    }, [register])
    useEffect(() => {
        register({ name: 'municipality' },
            { required: false });
    }, [register])





    console.log(props.studentDetails)
    console.log(props.studentDetails.studentId)
    console.log(props.schoolHistory)
    console.log(props.student)
    console.log(fatherData)
    return (
        <>
            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label">Student Details</h3>
                    </div>
                </div>

                <div className="card-body">
                    <div className='text-center'>
                        <img style={{ width: '250px' }} src={FaithLogo} />
                        <p className='my-5'><strong>NOTE: </strong> The personal information submitted herein shall be used for the initial phase of the application for Admission Examination.</p>
                        <p>{errors.lastName?.message}</p>





                    </div>
                    <form onSubmit={handleSubmit(onUpdate)} method="post" className="kt-form">
                        <div className="col-4 p-0">

                            <label className="col-form-label p-0">Student Number: <span className="text-danger">*</span> </label>
                            <input
                                type="text"
                                name="studentNo"
                                defaultValue={props.data.studentNo}
                                className={`form-control`}
                                ref={register({
                                    required: false
                                })}


                            />
                        </div>

                        <div className="col-4 p-0 mt-3">
                            <IMSelect
                                data={props.scholarshipSelect}
                                onHandleChange={selectHandleChange('scholarship')}
                                refClassContainer="form-group mb-3"
                                name="scholarship"
                                isRequired={true}
                                withLabel={true}
                                label="Scholarship"
                                placeHolder="Scholarship"
                                forwardRef={setValue}
                                selectedId={data.scholarship === 0 ? props.studentDiscount.length > 0 ? props.studentDiscount[0].ScholarshipId : "" : data.scholarship}
                                refClassName={``}
                                withDescription={false}
                                // description={`Please select your gender.`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[

                                ]}
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                                error={!!errors.scholarship}
                                errorMessage={errors.scholarship && errors.scholarship.type === "required" && 'Scholarship is required'}
                            />
                        </div>
                        <div className="row">
                            <section className="col">
                                <div className="form-group">
                                    <label className="col-form-label">Last Name : </label>
                                    <input
                                        type="text"
                                        name="lastName"
                                        defaultValue={props.studentDetails.lastName === undefined ? "" : props.studentDetails.lastName}
                                        className="form-control"
                                        ref={register({
                                            required: true
                                        })}
                                        autoComplete="off"
                                        maxLength="100"
                                    />

                                </div>
                            </section>
                            <section className="col">
                                <div className="form-group">
                                    <label className="col-form-label">First Name : </label>
                                    <input
                                        type="text"
                                        name="firstName"
                                        defaultValue={props.studentDetails.firstName === undefined ? "" : props.studentDetails.firstName}
                                        className="form-control"
                                        ref={register({
                                            required: true
                                        })}
                                        autoComplete="off"
                                        maxLength="100"
                                    />

                                </div>
                            </section>
                            <section className="col">
                                <div className="form-group">
                                    <label className="col-form-label">Middle Name : </label>
                                    <input
                                        type="text"
                                        name="middleName"
                                        defaultValue={props.studentDetails.middleName === undefined ? "" : props.studentDetails.middleName}
                                        className="form-control"
                                        ref={register({
                                            required: true
                                        })}
                                        autoComplete="off"
                                        maxLength="100"
                                    />

                                </div>
                            </section>

                        </div>
                        <div className="row">
                            <section className='col'>
                                <IMSelect
                                    onHandleChange={selectHandleChange('gender')}
                                    refClassContainer="form-group mb-3"
                                    name="gender"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Gender"
                                    placeHolder="Gender"
                                    forwardRef={setValue}
                                    selectedId={data.gender === undefined ? props.studentDetails.gender : data.gender}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={true}
                                    refStaticData={[
                                        { id: '', label: '' },
                                        { id: 'M', label: 'Male' },
                                        { id: 'F', label: 'Female' }
                                    ]}
                                    field={{
                                        tableId: '',
                                        display: ''
                                    }}
                                    error={!!errors.gender}
                                    errorMessage={errors.gender && errors.gender.type === "required" && 'Gender is required'}

                                />
                            </section>
                            <section className='col'>
                                <IMSelect
                                    data={props.civilStatusSelect}
                                    onHandleChange={selectHandleChange('civilStatus')}
                                    refClassContainer="form-group mb-3"
                                    name="civilStatus"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Civil Status"
                                    placeHolder="Civil Status"
                                    forwardRef={setValue}
                                    selectedId={data.civilStatus === undefined ? props.studentDetails.civilStatus : data.civilStatus}
                                    refClassName={``}

                                    withDescription={false}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </section>

                            <section className='col'>
                                <IMSelect
                                    data={props.religionSelect}
                                    onHandleChange={selectHandleChange('religion')}
                                    refClassContainer="form-group mb-3"
                                    name="religion"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Religion"
                                    placeHolder="Religion"
                                    forwardRef={setValue}
                                    selectedId={data.religion === undefined ? props.studentDetails.religion : data.religion}
                                    refClassName={``}

                                    withDescription={false}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </section>

                            <div className="form-group col">
                                <label className="col-form-label p-0">Date of Birth <span className="text-danger">*</span> </label>
                                <input
                                    type="date"
                                    name="birthDate"
                                    defaultValue={currDate}
                                    className={`form-control mt-2 ${!!errors.birthDate && ' is-invalid'}`}
                                    ref={register({
                                        required: true
                                    })}


                                />
                                {!!errors.birthDate && <span className="form-text text-danger">{errors.birthDate && errors.birthDate.type === "required" && 'Birth Date is required'}</span>}

                            </div>



                        </div>
                        <div className="row">
                            <section className="form-group col">
                                <label className="col-form-label p-0">Landline : </label>
                                <input
                                    type="text"
                                    name="tel"
                                    defaultValue={props.studentDetails.telNo === undefined ? "" : props.studentDetails.telNo}
                                    className="form-control mt-2"
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    maxLength="100"
                                />

                            </section>
                            <section className="form-group col">
                                <label className="col-form-label p-0">Mobile : </label>
                                <input
                                    type="text"
                                    name="cel"
                                    defaultValue={props.studentDetails.celNo === undefined ? "" : props.studentDetails.celNo}
                                    className="form-control mt-2"
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    maxLength="100"
                                />

                            </section>
                            <section className="form-group col">
                                <label className="col-form-label p-0">Email : </label>
                                <input
                                    type="text"
                                    name="email"
                                    defaultValue={props.studentDetails.email === undefined ? "" : props.studentDetails.email}
                                    className="form-control mt-2"
                                    ref={register({
                                        required: false
                                    })}
                                    autoComplete="off"
                                    maxLength="100"
                                />

                            </section>


                        </div>
                        <div className="row">
                            <div className="form-group col-12 col-md-3 mb-3">
                                <label className="col-form-label p-0">Barangay <span className="text-danger">*</span>  </label>
                                <input
                                    type="text"
                                    name="barangay"
                                    defaultValue={data.barangay === 0 ? props.student.length > 0 ? props.student[0].Barangay : "" : data.barangay}
                                    className={`form-control mt-2 ${!!errors.barangay && ' is-invalid'}`}
                                    ref={register({
                                        required: false
                                    })}
                                    autoComplete="off"
                                    maxLength="100"

                                />
                                {!!errors.barangay && <span className="form-text text-danger">{errors.barangay && errors.barangay.type === "required" && 'Barangay is required'}</span>}

                            </div>
                            <div className="form-group col-12 col-md-3 mb-3">
                                <IMSelect
                                    data={props.municipalitySelect}
                                    onHandleChange={selectHandleChange('municipality')}
                                    refClassContainer="form-group mb-3"
                                    name="municipality"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Municipality"
                                    placeHolder="Municipality"
                                    forwardRef={setValue}
                                    selectedId={data.municipality === 0 ? props.student.length > 0 ? props.student[0].MunicipalityId : "" : data.municipality}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    error={!!errors.municipality}
                                    errorMessage={errors.municipality && errors.municipality.type === "required" && 'Municipality is required'}
                                />
                            </div>
                            <div className="form-group col-12 col-md-3 mb-3">
                                <IMSelect
                                    data={props.provinceSelect}
                                    onHandleChange={selectHandleChange('province')}
                                    refClassContainer="form-group mb-3"
                                    name="province"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Province"
                                    placeHolder="Province"
                                    forwardRef={setValue}
                                    selectedId={data.province === 0 ? props.student.length > 0 ? props.student[0].ProvinceId : "" : data.province} refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    error={!!errors.province}
                                    errorMessage={errors.province && errors.province.type === "required" && 'Province is required'}
                                />
                            </div>

                            <div className="form-group col-12 col-md-3 mb-3">
                                <IMSelect
                                    data={props.countrySelect}
                                    onHandleChange={selectHandleChange('country')}
                                    refClassContainer="form-group mb-3"
                                    name="country"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Country"
                                    placeHolder="Country"
                                    forwardRef={setValue}
                                    selectedId={data.country === 0 ? props.student.length > 0 ? props.student[0].CountryId : "" : data.country}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    error={!!errors.country}
                                    errorMessage={errors.country && errors.country.type === "required" && 'Country is required'}
                                />
                            </div>
                        </div>
                        <div className="row">
                            <section className='col'>
                                <IMSelect
                                    data={props.courseSelect}
                                    onHandleChange={selectHandleChange('course')}
                                    refClassContainer="form-group mb-3"
                                    name="course"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Course"
                                    placeHolder="Course"
                                    forwardRef={setValue}
                                    selectedId={data.course === undefined ? props.studentDetails.courseId : data.course}
                                    refClassName={``}

                                    withDescription={false}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </section>
                            <section className='col'>
                                <IMSelect
                                    data={props.yearLevelSelect}
                                    onHandleChange={selectHandleChange('yearLevel')}
                                    refClassContainer="form-group mb-3"
                                    name="yearLevel"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Year Level"
                                    placeHolder="Year Level"
                                    forwardRef={setValue}
                                    selectedId={data.yearLevel === undefined ? props.studentDetails.yearLevel : data.yearLevel}
                                    refClassName={``}

                                    withDescription={false}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </section>
                            <section className='col'>
                                <IMSelect
                                    data={props.schoolSelect}
                                    onHandleChange={selectHandleChange('school')}
                                    refClassContainer="form-group mb-3"
                                    name="school"
                                    isRequired={true}
                                    withLabel={true}
                                    label="Last School Attended"
                                    placeHolder="Last School Attended"
                                    forwardRef={setValue}
                                    selectedId={data.school === undefined ? props.studentDetails.lastSchool : data.school}
                                    refClassName={``}

                                    withDescription={false}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[

                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </section>

                        </div>
                        <div className="kt-portlet__body mt-5">
                            <h4><i className="fas fa-users icon-xl"></i> Family Background</h4> <hr />



                            <ParentForm
                                className={'card mr-2'}
                                label={'Father\'s Information'}
                                infoHandler={fatherInfoHandler}
                                name='father'
                                types={admissionsTypes}
                                getCivilStatusInfo={getCivilStatusInfo}
                                getNationalityInfo={getNationalityInfo}
                                data={props.studentDetails}
                                parentData={props.fatherDetails}
                                isUpdate={true}

                            />
                            <ParentForm
                                className={'card mr-2 mt-5'}
                                label={'Mother\'s Information'}
                                infoHandler={motherInfoHandler}
                                name='mother'
                                types={admissionsTypes}
                                getCivilStatusInfo={getCivilStatusInfo}
                                getNationalityInfo={getNationalityInfo}
                                data={props.studentDetails}
                                parentData={props.motherDetails}
                                isUpdate={true}



                            />

                        </div>
                        <div className="kt-portlet__body mt-5">
                            <h4><i className="fas fa-user-graduate icon-xl"></i> Educational Background</h4> <hr />

                            <EducationBackground
                                label='Pre-Elementary'
                                className='card mt-5'
                                infoHandler={preElementaryInfoHandler}
                                name='preElementary'
                                getSchoolInfo={getSchoolInfo}
                                types={admissionsTypes}
                                data={props.preSchool}
                                isUpdate={true}

                            />
                            <EducationBackground
                                label='Elementary'
                                className='card mt-5'
                                infoHandler={elementaryInfoHandler}
                                name='elementary'
                                getSchoolInfo={getSchoolInfo}
                                types={admissionsTypes}
                                data={props.elem}
                                isUpdate={true}



                            />
                            <EducationBackground
                                label='Junior High School'
                                className='card mt-5'
                                infoHandler={jrHighSchoolInfoHandler}
                                name='jrHighSchool'
                                getSchoolInfo={getSchoolInfo}
                                types={admissionsTypes}
                                data={props.jrHigh}
                                isUpdate={true}



                            />
                            <EducationBackground
                                label='Senior High School'
                                className='card mt-5'
                                infoHandler={srHighSchoolInfoHandler}
                                name='srHighSchool'
                                getSchoolInfo={getSchoolInfo}
                                types={admissionsTypes}
                                data={props.srHigh}
                                isUpdate={true}



                            />






                        </div>
                        <button onClick={() => { onBack() }} type="button" className="btn btn-secondary float-left mt-5">Back</button>
                        <button type="submit" className="btn btn-success float-right mt-5">Update Details</button>
                    </form>

                </div>

            </div>
        </>
    )
}