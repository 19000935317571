import React, { useState, useEffect } from "react";
import { getStudentAccount, getSchoolYear, getSemester, getActiveSemester, getActiveSchoolYear } from "./__hooks__/index"
import { useForm } from "react-hook-form";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { TextField } from "@material-ui/core";
import history from "../../../../history";
import { Link } from 'react-router-dom';

export default function AddDropSubject(props) {

    // utilities
    const { setValue, handleSubmit, register, watch } = useForm()

    // account
    const [student, setStudent] = useState(null)
    const [isNoRecordFound, setIsNoRecordFound] = useState(false)

    // filteredData
    const [filteredSy, setFilteredSy] = useState(null)
    const [filteredSem, setFilteredSem] = useState(null)

    // selects
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)


    // active
    const [activeSem, setActiveSem] = useState(null)
    const [activeSy, setActiveSy] = useState(null)

    // functions
    const onSubmit = (formValues) => {
        let data = {
            ...formValues,
            semesterId: filteredSem,
            schoolYear: filteredSy
        }

        executeGetStudentAccount(data)

    }






    const executeGetStudentAccount = async (val) => {
        await getStudentAccount(val)
            .then(res => {
                if (res.data.length > 0) {
                    setStudent(res.data)
                    setIsNoRecordFound(false)

                } else {
                    setIsNoRecordFound(true)
                }
            })
            .catch(e => {

            })
    }


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }


    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect && activeSy &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === null ? activeSy : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    const imSelectDropdownSemester = () => {
        return (
            <>
                {semesterSelect && activeSem &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === null ? activeSem : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description")
        SelectCreator(getSemester, setSemesterSelect, "Semester", "Description")
        const getActiveSem = async (con) => {
            await getActiveSemester(con)
                .then(res => {
                    setActiveSem(res.data[0].SemesterId)
                    setFilteredSem(res.data[0].SemesterId)
                })
                .catch(e => {
                    console.log(e)
                })
        }

        const getActiveSy = async (con) => {
            await getActiveSchoolYear(con)
                .then(res => {
                    setActiveSy(res.data[0].SchoolYear)
                    setFilteredSy(res.data[0].SchoolYear)
                })
                .catch(e => {
                    console.log(e)
                })
        }

        getActiveSem({ where: "WHERE IsActive = 1" })
        getActiveSy({ where: "WHERE IsActive = 1" })
    }, [])


    console.log(activeSem)
    console.log(activeSy)
    return (
        <>

            <div className="container">
                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student[0].StudentName}`}</h3>
                                        <p className="my-0  ">{student[0].CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                {isNoRecordFound == true &&
                                    <div className="alert alert-danger" role="alert">
                                        No Record Found.
                                    </div>

                                }

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student[0].StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student[0].YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student[0].SY} / ${student[0].SemesterDesc}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>


                                <hr />


                            </div>


                        </div>
                        <div className="col-12 col-xl-8">

                            <div className="p-5 mb-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Search for a Student</h3>

                                <section className="p-5">
                                    <div className="bg-white mb-3 p-0">{imSelectDropdownSchoolYear()}</div>
                                    <div className="bg-white mb-3 p-0">{imSelectDropdownSemester()}</div>
                                    <form onSubmit={handleSubmit(onSubmit)} method="post">

                                        <TextField
                                            id="outlined-basic"
                                            label="Student No"
                                            variant="outlined"
                                            name="studentNo"
                                            inputRef={register({
                                                required: true
                                            })}
                                            className="bg-white"
                                            size="small"
                                            fullWidth
                                        />


                                        {student == null ?
                                            <button
                                                disabled={!watch("studentNo")}
                                                type="submit"
                                                className={`btn ${watch("studentNo") ? "btn-success" : "btn-secondary"} mt-5`}>
                                                Search
                                            </button>

                                            :
                                            <Link to={{ pathname: `/admin/enrollment/add-drop-subjects-details/${student[0].StudentId}`, studentData: student[0] }} className="btn btn-success mt-5">
                                                Go to Registration
                                            </Link>



                                        }



                                    </form>

                                </section>



                            </div>


                        </div>
                    </div>
                </div>
            </div>


        </>
    )
}