import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getAssessmentTemplateOthersByAssessmentTemplateId
  , createAssessmentTemplateOther
  , updateAssessmentTemplateOther
  , deleteAssessmentTemplateOther,
  addUpdateAssessmentTemplateOther,
  addDuplicateAssessmentTemplateOther,
  getAssessmentTemplateQuery
} from './__hooks__';
import Form from './Form';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function List(props) {
  const classes = useStyles();
  const { AssessmentTemplateId } = props.assessmentTemplate;
  const [assessmentTemplate, setAssessmentTemplate] = useState(null)
  const { dispatch } = useAppContext();
  const [selectedId, setSelectedId] = useState(0);
  const [results, setResults] = useState([]);
  const [retrieve, setRetrieve] = useState(true);
  const [showDialog, setShowDialog] = useState({
    id: null,
    record: '',
    status: false
  });
  var rowCnt = 0;

  useEffect(() => {
    const getAssessment = async (con) => {
      await getAssessmentTemplateQuery(con)
        .then(res => {
          if (res.data.length > 0)
            setAssessmentTemplate(res.data[0])
        })
    }
    getAssessment({ where: `WHERE AssessmentTemplateId = ${parseInt(AssessmentTemplateId)}` })
  }, [])

  useEffect(() => {
    if (retrieve) {
      const execute = async () => {
        await getAssessmentTemplateOthersByAssessmentTemplateId(AssessmentTemplateId)
          .then(response => {
            if (response.success) {
              dispatch({ type: enrollmentTypes.GET_ASSESSMENT_OTHERS, data: response.data });
              props.handleGetOthers(response.data)
              setResults(response.data);
              setRetrieve(false);
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
            }
          })
      }
      execute();
    }
  }, [dispatch, AssessmentTemplateId, retrieve])

  const handleAdd = (data) => {
    const execute = async () => {
      await addUpdateAssessmentTemplateOther({ ...data, assessmentTemplateOtherId: 0 })
        .then(response => {

          setRetrieve(true);
          setSelectedId(0);

        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleDuplicate = (data) => {
    // if (isDuplicate !== 'd') {
    const execute = async () => {
      await addDuplicateAssessmentTemplateOther({ ...data, schoolYear: assessmentTemplate.SchoolYear, semesterId: assessmentTemplate.SemesterId })
        .then(response => {
          setRetrieve(true);
          setSelectedId(0);
        })
        .catch(error => {

        })
    }
    execute();

  }

  const handleEdit = (id) => {
    setSelectedId(id);
  }

  const handleCancel = () => {
    setSelectedId(0);
  }

  const handleSave = (id, data) => {
    const execute = async () => {
      await addUpdateAssessmentTemplateOther({ ...data, assessmentTemplateOtherId: id })
        .then(response => {
          setRetrieve(true);
          setSelectedId(0);

        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleDelete = () => {
    const execute = async () => {
      await deleteAssessmentTemplateOther({ assessmentTemplateOtherId: showDialog.id })
        .then(response => {
          setRetrieve(true);
          setShowDialog({ id: null, record: '', status: false })

        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }


  console.log(assessmentTemplate)

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell>Fee</TableCell>
              <TableCell>Male Fee</TableCell>
              <TableCell>Female Fee</TableCell>
              <TableCell align="center">Optional</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.length > 0 ? results.map((result) => {
              const { AssessmentTemplateOtherId, FeeCode, MFee, FFee, PaymentSchedule, IsOptional, IsActive } = result;
              rowCnt += 1;
              if (selectedId !== 0 && selectedId === result.AssessmentTemplateOtherId) {
                return (
                  <Form key={AssessmentTemplateOtherId} doc={result} assessmentTemplateId={AssessmentTemplateId} onHandleCancel={handleCancel} onHandleSave={handleSave} index={rowCnt} />
                );
              } else {
                return (
                  <TableRow key={AssessmentTemplateOtherId}>
                    <TableCell align="center">{rowCnt}</TableCell>
                    <TableCell component="th" scope="row">
                      {FeeCode}
                    </TableCell>
                    <TableCell>{MFee}</TableCell>
                    <TableCell>{FFee}</TableCell>
                    <TableCell align="center">{IsOptional === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                    <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                    <TableCell align="center">
                      <button
                        className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                        onClick={() => handleEdit(AssessmentTemplateOtherId)}
                      >
                        <i className="fa fa-pencil-alt text-white"></i>
                      </button>
                      <Link
                        to={`${enrollmentTypes.MORE_LINK}${AssessmentTemplateId}/${AssessmentTemplateOtherId}`}
                        className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                        <i className="fa fa-list-alt text-white"></i>
                      </Link>
                      <button
                        className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                        onClick={() => setShowDialog({ id: AssessmentTemplateOtherId, record: `${FeeCode} - ${MFee} - ${FFee} - ${PaymentSchedule}`, status: !showDialog.status })}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </TableCell>
                  </TableRow>
                );
              }
            }) : <TableRow><TableCell colSpan="8">No data available in the table</TableCell></TableRow>}
          </TableBody>
          <TableFooter>
            <Form doc={[]} assessmentTemplateId={AssessmentTemplateId} onHandleAdd={handleAdd} onHandleDuplicate={handleDuplicate} />
          </TableFooter>
        </Table>
      </TableContainer>
      {showDialog.status && <IMDialog
        title={`Question`}
        content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
        onHandleDelete={handleDelete}
        onShowDialog={setShowDialog}
        isOpen={true}
      />}
    </>
  );
}