export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_OTHER: 'SELECTED-ASSESSMENT-OTHER',
    GET_ASSESSMENT_OTHERS: 'GET-ASSESSMENT-OTHERS',
    GET_ASSESSMENT_OTHER: 'GET-ASSESSMENT-OTHER-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-OTHER',
    UPDATE: 'UPDATE-ASSESSMENT-OTHER',
    DELETE: 'DELETE-ASSESSMENT-OTHER',
    LIST_LINK_TO_PARENT: '/admin/enrollment/assessment-of-fees',
    LIST_LINK: '/admin/enrollment/assessment-of-fee-others',
    MORE_LINK: '/admin/enrollment/assessment-of-fee-other-rates/',
    CREATE_LINK: '/admin/enrollment/create-assessment-of-fee-others/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-of-fee-others/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-of-fee-others/'
}