import React, { useState, useEffect } from "react";
import { getStudentList } from "./__hooks__"
import { useParams } from "react-router-dom";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";

export default function StudentList() {
    const { id } = useParams()
    const [students, setStudents] = useState([])

    const getStudents = async () => {
        await getStudentList({ subjectScheduleDetailId: parseInt(id) })
            .then(res => {
                setStudents(res.data)
            })
            .catch(e => {

            })
    }



    useEffect(() => {
        getStudents()
    }, [])

    const columns = [
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Student Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]


    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: students,
        totalRecords: students.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'instructorStudentList',
        parentId: null,
        tableTitle: 'List of Students',
    }


    console.log(students)

    return (
        <>
            <main className="card">
                <section className="card-header">
                    <h4>List of Students</h4>
                </section>
                <section className="card-body">
                    <IMTable tableProps={tableProps} />

                </section>


            </main>
        </>
    )
}