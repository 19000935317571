import instance from '../../../../../apis/local/systemAPI';


export const getGradeTemplate = async (page, rowsPerPage, keyword, instituteRoleId, courseId) => {
    var result;
    if (courseId !== undefined && courseId !== null) {
        const params = {
            where: [
                {
                    "o": "",
                    "p": ["LIKE", "",
                        {
                            "C.CourseId": courseId

                        }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/grades/grade-templates/q`, params);
    } else if (instituteRoleId !== undefined && instituteRoleId !== null) {
        const params = {
            where: [
                {
                    "o": "",
                    "p": ["LIKE", "",
                        {
                            "GT.InstituteId": instituteRoleId

                        }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/grades/grade-templates/q`, params);
    } else {
        if (keyword === '') {
            result = await instance.get(`api/grades/grade-templates/page/${page + 1}/${rowsPerPage}`);
        } else {
            const params = {
                where: [
                    {
                        "o": "", "p": ["LIKE", "||", {
                            "gradeTemplateCode": keyword,
                            "GT.Description": keyword
                        }
                        ]
                    }
                ]
                , start: page + 1
                , limit: rowsPerPage
            }
            result = await instance.post(`api/grades/grade-templates/q`, params);
        }
    }



    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradeTemplateQuery = async (formValues) => {
    const result = await instance.post("api/grades/grade-templates/q", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createGradeTemplate = async (formValues) => {
    const result = await instance.post("api/grades/grade-templates/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateGradeTemplate = async (id, formValues) => {
    const result = await instance.patch(`api/grades/grade-templates/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteGradeTemplate = async (id) => {
    const result = await instance.delete(`api/grades/grade-templates/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getInstitute = async () => {
    const result = await instance.get(`api/references/institutes/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getTest = async () => {
    const result = await instance.get(`api/registrar/facultyLoading/facultyReport`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


