import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuide, getGradeBookSummaryList, getGradeBookSummary, getGradeBookSummaryFinals,
    addUpdateGradeFinals, unPostGrades, deleteGBookFinalDetails
} from './__hooks__';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import GradeBookDetailsModal from "../../../../../../_metronic/layout/components/custom/modal/GradeBookDetailsModal"
import GradeBookDetailFinalModal from '../../../../../../_metronic/layout/components/custom/modal/GradeBookDetailFinalModal';
import GradeBookDetailFinalPeriodModal from '../../../../../../_metronic/layout/components/custom/modal/GradeBookDetailFinalPeriodModal';
import { fi } from 'date-fns/locale';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { data, classSectionId, subjectScheduleDetailId, isPostedHandler, instructionalScheduleTypeId } = props
    const { state: { auth }, dispatch } = useAppContext();
    const [instructionalGuide, setInstructionalGuide] = useState(null)
    const [results, setResults] = useState([]);
    const [totalPercentage, setTotalPercentage] = useState(100)
    const classes = useStyles();
    const { id } = useParams()
    const [isOpenGbookDetails, setIsOpenGbookDetails] = useState(false)
    const [isOpenGbookDetailsFinals, setIsOpenGbookDetailsFinals] = useState(false)
    const [isOpenGbookDetailsFinalPeriod, setIsOpenGbookDetailsFinalPeriod] = useState(false)

    const [rawDetails, setRawDetails] = useState([])
    const [finalRawDetails, setFinalRawDetails] = useState([])
    const [finalRawPeriodicDetails, setFinalRawPeriodicDetails] = useState([])


    const [isLoadingStudent, setIsLoadingStudent] = useState(false)
    const [isPosted, setIsPosted] = useState(false)
    const [isPrelimPosted, setIsPrelimPosted] = useState(false)
    const [isMidtermPosted, setIsMidtermPosted] = useState(false)
    const [isFinalPosted, setIsFinalPosted] = useState(false)
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [periodicalPeriod, setPeriodicalPeriod] = useState({ id: null, period: null })

    const [gradingPeriods, setGradingPeriods] = useState([])
    const [currentData, setCurrentData] = useState({ ig: null, igDetailId: null })

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;


    let rowCnt = 0;

    // functions


    const handleClose = () => {
        setIsOpenGbookDetails(false)
        setRawDetails([])
    }

    const handleCloseFinals = () => {
        setIsOpenGbookDetailsFinals(false)
        setFinalRawDetails([])
    }

    const handleCloseFinalsPeriod = () => {
        setIsOpenGbookDetailsFinalPeriod(false)
        setFinalRawPeriodicDetails([])
    }

    const onLoad = async (detailId, guideId, periodId) => {
        // setFinalRawPeriodicDetails([])
        await getSummaryFinalDetailsPeriod(currentData.igDetailId, currentData.ig, periodId)

    }

    const onLoadFinals = async (detailId, guideId, periodId) => {
        // setFinalRawPeriodicDetails([])
        await getSummaryFinalDetails(currentData.igDetailId, currentData.ig, periodId)

    }

    const getSummaryDetails = async (guideId, guideDetailId, gradingPeriodId) => {
        console.log('openedFunction')
        await getGradeBookSummary({
            instructionalGuideId: guideId,
            instructionalGuideDetailId: guideDetailId,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            gradingPeriodId: gradingPeriodId,
            subjectScheduleDetailId: subjectScheduleDetailId,
            classSectionId: classSectionId
        })
            .then(res => {
                setRawDetails(res.data)
            })
            .catch(e => {

            })
    }


    const getSummaryFinalDetails = async (detailId, guideId, gradingPeriodId) => {
        await getGradeBookSummaryFinals({
            instructionalGuideId: guideId,
            instructionalGuideDetailId: detailId,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            gradingPeriodId: gradingPeriodId,
            subjectScheduleDetailId: subjectScheduleDetailId,
            classSectionId: classSectionId,
            isLec: data.IsLec,
            scheduleTypeId: instructionalScheduleTypeId

        })
            .then(res => {
                console.log(res)
                setFinalRawDetails(res.data)
                setIsPosted(res.isPosted)
                setIsPrelimPosted(res.isPrelimPosted)
                setIsMidtermPosted(res.isMidtermPosted)
                setIsFinalPosted(res.isFinalPosted)
                setIsAuthenticated(res.isAuthenticated)
                isPostedHandler(res.isPosted)
            })
            .catch(e => {

            })
    }

    const getSummaryFinalDetailsPeriod = async (detailId, guideId, gradingPeriodId) => {
        await getGradeBookSummaryFinals({
            instructionalGuideId: guideId,
            instructionalGuideDetailId: detailId,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            gradingPeriodId: gradingPeriodId,
            subjectScheduleDetailId: subjectScheduleDetailId,
            classSectionId: classSectionId,
            isLec: data.IsLec,
            scheduleTypeId: instructionalScheduleTypeId
        })
            .then(res => {
                console.log(res)
                setFinalRawPeriodicDetails(res.data)
                setIsPosted(res.isPosted)
                setIsPrelimPosted(res.isPrelimPosted)
                setIsMidtermPosted(res.isMidtermPosted)
                setIsFinalPosted(res.isFinalPosted)
                setIsAuthenticated(res.isPostedByIns)
                isPostedHandler(res.isPosted)
            })
            .catch(e => {

            })
    }

    const handleOpen = (detailId, guideId, gradingPeriod) => {
        setIsLoadingStudent(true)
        getSummaryDetails(guideId, detailId, gradingPeriod)

    }

    const handleOpenFinalGrades = async (detailId, guideId, gradingPeriod) => {
        setIsLoadingStudent(true)
        getSummaryFinalDetails(detailId, guideId, gradingPeriod)
    }

    const handleOpenFinalPeriodicGrades = async (detailId, guideId, gradingPeriod, periodName) => {
        setIsLoadingStudent(true)
        getSummaryFinalDetailsPeriod(detailId, guideId, gradingPeriod)
        setPeriodicalPeriod({ id: gradingPeriod, period: periodName })
        setCurrentData({ ig: guideId, igDetailId: detailId })
        // getSummaryFinalDetails()
    }

    const onSaveFinalGrade = async (val) => {
        console.log(val)
        await addUpdateGradeFinals(val)
            .then(res => {
                // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grades have been posted' });
            })
            .catch(e => {

            })
    }

    const unPostingGrades = async (val, gradingPeriod) => {
        await unPostGrades(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grade have been Unpost' });
                // onLoad(gradingPeriod)
            })
            .catch(e => {

            })
    }


    const deleteDetailsFinal = async (val) => {
        await deleteGBookFinalDetails(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grade have been Unpost' });
                // onLoad(gradingPeriod)
            })
            .catch(e => {

            })
    }

    useEffect(() => {
        const execute = async () => {
            await getInstructionalGuide({ instructionalGuideId: parseInt(id) })
                .then(response => {
                    if (response.success) {
                        setInstructionalGuide(response.data);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        }
        execute();


        getGradeBookSummaryFinals({
            instructionalGuideId: data.instructionalGuideId,
            instructionalGuideDetailId: 0,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            gradingPeriodId: 0,
            subjectScheduleDetailId: subjectScheduleDetailId,
            classSectionId: classSectionId,
            isLec: data.IsLec

        })
            .then(res => {
                console.log(res)
                // setFinalRawDetails(res.data)
                // setIsPosted(res.isPosted)
                isPostedHandler(res.isPosted)
            })
            .catch(e => {

            })

    }, [])

    useEffect(() => {
        console.log('teeest')
        console.log(rawDetails)
        if (rawDetails.length > 0) {
            setIsOpenGbookDetails(true)
            setIsLoadingStudent(false)
        } else {
            if (isLoadingStudent == true) {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'There is no student associated with this section.'
                });
                setIsLoadingStudent(false)
            }

        }
    }, [rawDetails])

    useEffect(() => {

        if (finalRawDetails.length > 0) {
            setIsOpenGbookDetailsFinals(true)
            setIsLoadingStudent(false)
        } else {
            if (isLoadingStudent == true) {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'There is no student associated with this section.'
                });
                setIsLoadingStudent(false)
            }

        }
    }, [finalRawDetails])

    useEffect(() => {

        if (finalRawPeriodicDetails.length > 0) {
            setIsOpenGbookDetailsFinalPeriod(true)
            setIsLoadingStudent(false)
        } else {
            if (isLoadingStudent == true) {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'There is no student associated with this section.'
                });
                setIsLoadingStudent(false)
            }

        }

    }, [finalRawPeriodicDetails])


    useEffect(() => {
        if (instructionalGuide !== null) {
            const getSchedule = async (val) => {
                await getGradeBookSummaryList(val)
                    .then(res => {
                        console.log(res)
                        setGradingPeriods(res.gradingPeriods)
                        let percentage = 0.00
                        if (res.data.length > 0) {
                            for (let i = 0; i < res.data.length; i++) {
                                percentage += parseFloat(res.data[i].GradeTemplatePercentage)
                            }
                        }
                        setResults(res.data)
                        setTotalPercentage(percentage)
                    })
            }

            getSchedule({
                instructionalGuideId: instructionalGuide[0].InstructionalGuideId

            })
        }
    }, [instructionalGuide])

    console.log(rawDetails)
    console.log(results)
    console.log(data)

    console.log(isPosted)
    console.log(isPrelimPosted)
    console.log(isMidtermPosted)
    console.log(isFinalPosted)
    return (
        <>
            {results &&
                <>

                    {/* Modal for Editing Grades -------------------------------------*/}

                    {rawDetails.length > 0 &&
                        <GradeBookDetailsModal
                            isOpen={isOpenGbookDetails}
                            results={rawDetails}
                            handleClose={handleClose}
                            data={data}
                            isViewingOfSummary={true}
                            onSaveRawGrades={() => { }}
                            onPostGrades={onSaveFinalGrade}
                            createdBy={auth.data.Username}
                            date={dateTime}
                            onLoad={onLoad}
                        />
                    }

                    {/* Modal for Posting Grades Periodically ------------------------*/}

                    {finalRawPeriodicDetails.length > 0 &&
                        <GradeBookDetailFinalPeriodModal
                            isOpen={isOpenGbookDetailsFinalPeriod}
                            results={finalRawPeriodicDetails}
                            handleClose={handleCloseFinalsPeriod}
                            createdBy={auth.data.Username}
                            date={dateTime}
                            data={data}
                            isInstructor={true}
                            isDeanChair={false}
                            isPosted={isPosted}
                            isPrelimPosted={isPrelimPosted}
                            isMidtermPosted={isMidtermPosted}
                            isFinalPosted={isFinalPosted}
                            isAuthenticated={isAuthenticated}
                            onPostGrades={onSaveFinalGrade}
                            gradingPeriodId={periodicalPeriod.id}
                            period={periodicalPeriod.period}
                            onLoad={onLoad}
                            unPostingGrades={unPostingGrades}
                            scheduleTypeId={instructionalScheduleTypeId}
                        />
                    }

                    {/* Modal for Posting All Grades  -----------------------*/}

                    {finalRawDetails.length > 0 &&
                        <GradeBookDetailFinalModal
                            isOpen={isOpenGbookDetailsFinals}
                            results={finalRawDetails}
                            handleClose={handleCloseFinals}
                            createdBy={auth.data.Username}
                            date={dateTime}
                            data={data}
                            isInstructor={true}
                            isDeanChair={false}
                            isPosted={isPosted}
                            isPrelimPosted={isPrelimPosted}
                            isMidtermPosted={isMidtermPosted}
                            isFinalPosted={isFinalPosted}
                            onPostGrades={onSaveFinalGrade}
                            gradingPeriods={gradingPeriods}
                            deleteDetailsFinal={deleteDetailsFinal}
                            onLoad={onLoadFinals}
                            scheduleTypeId={instructionalScheduleTypeId}
                        />


                    }

                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header d-block bg-white" style={{ minHeight: 0 }}>
                            <div className="card-title">
                                {/* <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> */}
                            </div>
                            <div className="card-toolbar float-right">
                                <div className="example-tools">
                                    {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary p-1">
                                        <i className="fa fa-plus p-0"></i>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                            {/* <IMTable tableProps={tableProps} /> */}
                            <TableContainer >
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">#</TableCell>
                                            <TableCell>Grading</TableCell>
                                            <TableCell>Percentage</TableCell>

                                            <TableCell>Action</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results.length > 0 ? results.map((result) => {
                                            const { InstructionalGuideId, InstructionalGuideDetailId, GradingPeriod, GradingPeriodId, SYDesc, SEMDesc,
                                                GradeTemplatePercentage } = result;
                                            rowCnt += 1;

                                            return (

                                                <TableRow key={InstructionalGuideId}>
                                                    <TableCell align="center">{rowCnt}</TableCell>

                                                    <TableCell component="th" scope="row">{GradingPeriod}</TableCell>
                                                    <TableCell component="th" scope="row">{GradeTemplatePercentage}</TableCell>

                                                    <TableCell align="left">
                                                        {isLoadingStudent == false ?

                                                            <button onClick={() => { handleOpenFinalPeriodicGrades(InstructionalGuideDetailId, InstructionalGuideId, GradingPeriodId, GradingPeriod) }} className='btn btn-xs btn-icon btn-icon-xs btn-primary mr-1'>
                                                                <i className="fa fa-users"></i>
                                                            </button>
                                                            :
                                                            <button className="btn btn-sm btn-primary" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm p-0" role="status" aria-hidden="true"></span>
                                                                <span className="sr-only">Loading...</span>
                                                            </button>
                                                        }


                                                    </TableCell>

                                                </TableRow>
                                            );

                                        }) :
                                            //  <p className='p-1 text-center'>No data available in the table</p>
                                            ''
                                        }
                                        <TableRow className='bg-secondary'>
                                            <TableCell align="center"></TableCell>
                                            <TableCell component="th" scope="row">Summary of Grades</TableCell>
                                            <TableCell component="th" scope="row">{`${totalPercentage}.00`}</TableCell>


                                            <TableCell align="left">
                                                <button onClick={() => handleOpenFinalGrades(0, data.InstructionalGuideId, 0)} className='btn btn-xs btn-icon btn-icon-xs btn-info mr-1'>
                                                    <i className="fa fa-users"></i>
                                                </button>
                                            </TableCell>

                                        </TableRow>
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </>
            }

        </>
    );
}