import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TextField } from "@material-ui/core";
import {
    searchStudent, getActiveSemester, getActiveSchoolYear, getSchoolYear, getSemester, getCourse,
    updateStudent, updateEnrollment, createStudentShiftHistory, shiftCourse,
    studentAutoComplete
} from "./__hooks__";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useAppContext } from "../../../../contexts/useAppContext";
import moment from 'moment';
import { debounce } from "lodash";
import Autocomplete from '@material-ui/lab/Autocomplete';


export default function ChangeCourse(props) {
    const { setValue, handleSubmit, register } = useForm()
    const { state: { auth }, dispatch } = useAppContext();


    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear

    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState('')
    const [studentId, setStudentId] = useState(null)
    const [studentPrevCourse, setStudentPrevCourse] = useState(null)
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)
    const [filteredCourse, setFilteredCourse] = useState(null)
    const [studentList, setStudentList] = useState([])


    const [semSelect, setSemSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const [courseSelect, setCourseSelect] = useState()


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectCourseChange = name => event => { setFilteredCourse(event) }


    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }, 300);

    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);
    }

    const handleGetStudent = async (data) => {
        setStudentNo(data.StudentNo)
        setStudentId(data.StudentId)
        console.log(data)
    }


    const executeSearchStudent = async (val) => {
        await searchStudent(val)
            .then(res => {
                console.log(res)
                if (res.data !== null) {
                    setFilteredCourse(res.data.CourseId)
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found'
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }


    const executeUpdateEnrollment = async (val) => {
        await shiftCourse(val)
            .then(res => {

            })
            .catch(e => {

            })
    }

    const executeUpdateStudent = async (studentId, val) => {
        await updateStudent(studentId, val)
            .then(res => {

            })
            .catch(e => {

            })
    }

    const executeCreateHistoryLog = async (val) => {
        await createStudentShiftHistory(val)
            .then(res => {

            })
            .catch(e => {

            })
    }


    const onUpdateStudentCourse = () => {
        let historyLog = {
            studentId: student.StudentId,
            prevCourseId: studentPrevCourse,
            newCourseId: filteredCourse,
            createdBy: auth.data.Username,
            dateCreated: moment().format('YYYY-MM-DD HH:MM:SS')

        }
        executeUpdateEnrollment({ studentId: student.StudentId, schoolYear: filteredSy, semesterId: filteredSem, courseId: filteredCourse })
            .then(res => {

                executeCreateHistoryLog(historyLog)
                    .then(re => {
                        setStudent(null)
                        setStudentNo('')
                        dispatch({
                            type: componentTypes.SHOW_SNACKBAR,
                            snackBarStatus: 'success',
                            snackBarDuration: 5000,
                            snackBarMessage: 'Student Course Updated'
                        });
                    })



            })


    }

    const onSubmit = (formValues) => {
        let data = {
            studentId: studentId,
            semesterId: filteredSem,
            schoolYear: filteredSy
        }

        executeSearchStudent(data)
    }







    useEffect(() => {
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
        SelectCreator(getCourse, setCourseSelect, 'CourseId', 'Description')


    }, [])

    useEffect(() => {
        if (student !== null) {
            setStudentPrevCourse(student.CourseId)
        }
    }, [student])

    useEffect(() => {
        if (activeSem !== undefined && activeSy !== undefined) {
            setFilteredSem(activeSem)
            setFilteredSy(activeSy)
        }
    }, [activeSem, activeSy])


    useEffect(() => {
        if (studentId !== undefined && studentId !== "" && studentId != 0) {
            setTimeout(() => {
                onSubmit({ studentId: studentId, semesterId: filteredSem, schoolYear: filteredSy })

            }, 500)

        }
    }, [studentId])


    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? activeSy : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? activeSem : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownCourse = () => {
        return (
            <>
                {courseSelect && student !== null &&
                    <IMSelect
                        data={courseSelect}
                        onHandleChange={selectCourseChange()}
                        refClassContainer=""
                        name="Course"
                        isRequired={false}
                        withLabel={false}
                        label="Course"
                        placeHolder="Select Course"
                        forwardRef={setValue}
                        selectedId={filteredCourse}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    console.log(student)
    console.log(auth)
    console.log(moment().format('YYYY-MM-DD HH:MM:SS'))
    return (
        <>
            <div className="container">
                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "Old Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <hr />


                            </div>


                        </div>
                        <div className="col-12 col-xl-8">

                            <div className="p-5 mb-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Student Course Program</h3>
                                {student === null ?
                                    <section className="p-5">
                                        <div className="bg-white mb-3 p-0">{imSelectDropdownSchoolYear()}</div>
                                        <div className="bg-white mb-3 p-0">{imSelectDropdownSemester()}</div>
                                        <form onSubmit={handleSubmit(onSubmit)} method="post">

                                            {/*     <TextField
                                                id="outlined-basic"
                                                label="Student No"
                                                variant="outlined"
                                                name="studentNo"
                                                value={studentNo}
                                                inputRef={register({
                                                    required: true
                                                })}
                                                className="bg-white"
                                                size="small"
                                                fullWidth
                                                onChange={(e) => {
                                                    setStudentNo(e.target.value)
                                                }}
                                            />  */}

                                            <Autocomplete
                                                id="studentNo"
                                                options={studentList}
                                                getOptionLabel={(option) => option.StudentName}
                                                onChange={(event, value) => {
                                                    if (value) {
                                                        handleGetStudent(value)
                                                        // Handle the selected option
                                                    } else {
                                                        // Handle the case when no option is selected
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Student Number"
                                                        variant="outlined"
                                                        inputRef={register}
                                                        name="studentNo"
                                                        value={studentNo}
                                                        onChange={
                                                            (e) => { handleStudentInputChange(e.target.value) }

                                                        }
                                                    />
                                                )}
                                            />

                                            <button type="submit" className="btn btn-primary mt-3">Search Student</button>
                                        </form>

                                    </section>
                                    :
                                    <>
                                        <section className="mt-5">
                                            <p className="lead m-0">Change Course</p>
                                            <div className="bg-white mb-3 p-0">{imSelectDropdownCourse()}</div>
                                            <button onClick={() => { onUpdateStudentCourse() }} className="btn btn-primary">Change Course</button>
                                        </section>

                                    </>

                                }


                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}