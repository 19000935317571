import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Table, TableBody, TableHead, TableRow, TableFooter, TableCell, Box, Collapse, IconButton, Checkbox } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from 'moment'
import { CheckBox } from '@material-ui/icons';
import { array } from 'prop-types';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});



const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}
const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

const remove_duplicates_safe = (arr) => {
    var seen = {};
    var ret_arr = [];
    if (arr.length > 0)
        for (var i = 0; i < arr.length; i++) {
            if (!(arr[i] in seen)) {
                ret_arr.push(arr[i]);
                seen[arr[i]] = true;
            }
        }
    return ret_arr;

}

export default function IMCollapsibleTableStudentAAddDropSubjects(props) {
    const [selectedSubject, setSelectedSubject] = useState([]);
    const [selectedSubCode, setSelectedSubCode] = useState([])
    const [selectedSubSchedDetailId, setSelectedSubSchedDetailId] = useState([])
    const [selectedSched, setSelectedSched] = useState([])
    const [selectedDetailIdList, setSelectedDetailIdList] = useState([])
    const [oneTimeRun, setOneTimeRun] = useState(false)
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const { tableProps } = props;
    const columns = tableProps.columns;
    const results = tableProps.rows;
    const isProcessed = props.isProcessed
    const detailList = props.subSchedDetailList
    const recordId = props.recordId
    const recordIdMore = props.tableProps.recordIdMore
    var RowCnt = tableProps.onPage * tableProps.onRowsPerPage;
    const isItemSelected = true
    const lastUpdateId = props.tableProps.lastUpdateId
    console.log(isProcessed)
    useEffect(() => {
        setSelectedSubject(props.selectedSub)
        console.log(props.selectedSub)
        setSelectedSched(props.selectedSched)
        setSelectedSubCode(props.selectedSubCode)
        if (props.selectedSubSchedId !== undefined)
            setSelectedSubSchedDetailId(props.selectedSubSchedId)


    }, [props.selectedSub, props.selectedSubSchedId])


    const clickSubjectHandler = (event, selectedSub, subCode, subSchedDetailId) => {
        event.persist()
        let arr = []
        let arr2 = []
        let arr3 = []
        let status
        arr = []
        arr2 = []
        if (event.target.checked === true) {
            console.log(selectedSubSchedDetailId)
            arr.push(...selectedSubject, selectedSub)
            arr2.push(...selectedSubCode, subCode)
            arr3.push(...selectedSubSchedDetailId, subSchedDetailId)
            status = true
        } else if (event.target.checked === false) {

            console.log(selectedSub)
            console.log(selectedSubCode)
            console.log(remove_duplicates_safe(selectedSubject))
            let value = selectedSub
            let value2 = subCode
            let value3 = subSchedDetailId
            let recentSelect = selectedSubject
            let recentSelect2 = selectedSubCode
            let recentSelect3 = selectedSubSchedDetailId
            console.log(value3)
            console.log(remove_duplicates_safe(recentSelect3))
            recentSelect = recentSelect.filter(item => item !== value)
            recentSelect2 = recentSelect2.filter(item => item !== value2)
            recentSelect3 = remove_duplicates_safe(recentSelect3).filter(item => item !== value3)
            console.log(recentSelect)
            arr = recentSelect
            arr2 = recentSelect2
            arr3 = recentSelect3
            status = false
        }

        console.log(remove_duplicates_safe(arr))
        console.log(arr)
        console.log(arr2)
        props.subjectCodeHandler(remove_duplicates_safe(arr), subSchedDetailId, status, remove_duplicates_safe(arr2))
        console.log(subCode)
        setSelectedSubCode(arr2)
        setSelectedSubject(arr)
        setSelectedSubSchedDetailId(arr3)
    };
    const [isDuplicate, setIsDuplicate] = useState(false)




    const clickSchedHandler = (event, schedule, scheduleDetailId) => {
        console.log(event.target.checked)

        let arr = []
        let arr2 = []
        let selectedVal
        console.log(event.target.checked)
        if (event.target.checked === true) {

            selectedVal = schedule
            if (selectedDetailIdList.length > 0 && selectedDetailIdList.includes(scheduleDetailId)) {
                let index = selectedDetailIdList.indexOf(scheduleDetailId)
                console.log(selectedSched)

                let data = selectedSched.splice(index, 1)
                console.log(selectedSched)

                console.log(index)
                console.log(data)
                arr.push(...selectedSched, schedule)
                arr2.push(...selectedDetailIdList)
            } else {
                arr.push(...selectedSched, schedule)
                arr2.push(...selectedDetailIdList, scheduleDetailId)
            }



        }


        console.log(arr)
        console.log(arr2)
        props.scheduleCodeHandler(arr)
        setSelectedSched(arr)
        setSelectedDetailIdList(arr2)
    };
    console.log(selectedSubject)
    console.log(selectedSubSchedDetailId)
    console.log(results)

    useEffect(() => {
        props.subjectCodeHandler(props.selectedSubSchedId)
    }, [props.selectedSubSchedId])
    const Row = (props) => {


        const { row, rowDetail, rowCnt, index } = props;
        const findExisted = () => {
            if (row[rowDetail.childArray]) {
                for (let i = 0; i < selectedSched.length; i++) {
                    for (let v = 0; v < row[rowDetail.childArray].length; v++) {
                        if (row[rowDetail.childArray][v].SubjectScheduleDetailListId === selectedSched[i]) {
                            return row[rowDetail.childArray][v].SubjectScheduleDetailListId === selectedSched[i]
                        }
                    }
                }
            }

        }
        const [open, setOpen] = useState(false);
        const classes = useRowStyles();
        const recordId = rowDetail.recordId;
        const recordIdMore = rowDetail.recordIdMore;
        const labelId = `enhanced-table-checkbox-${index}`;

        return (
            <>
                <TableRow style={{ background: "#ffffff" }}>
                    <TableCell>
                        {open === true ? <button className='btn btn-secondary btn-sm p-1' aria-label="expand row" size="small" onClick={() => { setOpen(false) }}>
                            <i className="fa fa-calendar-check p-0 fa-sm"></i>
                        </button> : <button className='btn btn-primary btn-sm p-1' aria-label="expand row" size="small" onClick={() => { setOpen(true) }}>
                            <i className="fa fa-calendar-check p-0 fa-sm"></i>
                        </button>}

                    </TableCell>
                    {rowDetail.columns.map((column) => {
                        const value = row[column.id];
                        var dataUI;
                        const isSelected = (name) => selectedSubject.indexOf(name) !== -1;
                        console.log(column.id)
                        if (!column.hidden) {
                            if (column.id === 'SubjectStatusId' && props.type === 'addDrop') {
                                if (row.SubjectStatusId === 2 && !isSelected(row[recordId])) {
                                    dataUI = 'Drop';
                                } else if (row.SubjectStatusId === 2 && isSelected(row[recordId])) {
                                    dataUI = 'Add';
                                } else if (row.SubjectStatusId === 5 && !isSelected(row[recordId])) {
                                    dataUI = 'Cancelled';
                                } else if (row.SubjectStatusId === 5 && isSelected(row[recordId])) {
                                    dataUI = 'Add';
                                } else if (row.SubjectStatusId === 3) {
                                    dataUI = 'Add';
                                }
                                // else if (row.SubjectStatusId === 3 && !isSelected(row[recordId])) {
                                //     dataUI = 'Cancelled';
                                // } 
                                else if (row.SubjectStatusId === 1 && isSelected(row[recordId])) {
                                    dataUI = 'Assessed';
                                } else if (row.SubjectStatusId === 1 && !isSelected(row[recordId])) {
                                    dataUI = 'Drop';
                                } else {
                                    dataUI = '';
                                }
                            }
                            else if (column.id == 'SubjectStatusId' && props.type == 'assessment') {
                                dataUI = row.SubjectStatusId == 2 ? 'Drop' : row.SubjectStatusId == 5 ? 'Cancelled' : row.SubjectStatusId == 3 ? 'Add' : row.SubjectStatusId == 1 ? 'Assessed' : '';
                            } else if (column.id === 'Action') {
                                {
                                    row.StudentEnrollmentSubjectsId == undefined ?
                                        dataUI = <>
                                            <input
                                                disabled
                                                className='subCheckBox'
                                                type='checkbox'
                                                checked={isSelected(row[recordId])}
                                                onChange={(event) => { clickSubjectHandler(event, row[recordId], row['SubjectCode'], row['SubjectScheduleDetailId']) }}
                                            />
                                        </>

                                        :

                                        dataUI = <>
                                            <input
                                                className='subCheckBox'
                                                type='checkbox'
                                                checked={isSelected(row[recordId])}
                                                onChange={(event) => { clickSubjectHandler(event, row[recordId], row['SubjectCode'], row['SubjectScheduleDetailId']) }}
                                            />
                                        </>

                                }



                            } else {
                                dataUI = column.format && typeof value === 'number' ? column.format(value) : column.withDateFormat ? moment(value).format('YYYY-MM-DD') : value;

                            }
                            // switch (column.type) {

                            //     case 'index':
                            //         dataUI = rowCnt;
                            //         break;
                            //     case 'boolean':
                            //         dataUI = row[column.id] === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>;
                            //         break;
                            //     case 'action':
                            //         if (rowDetail.tableType === 'child') {
                            //             dataUI = <>
                            //                 {isProcessed === false ? <input
                            //                     className='subCheckBox'
                            //                     type='checkbox'
                            //                     checked={isSelected(row[recordId])}
                            //                     onChange={(event) => { clickSubjectHandler(event, row[recordId], row['SubjectCode'], row['SubjectScheduleDetailId']) }}
                            //                 /> :
                            //                     <input
                            //                         disabled
                            //                         className='subCheckBox'
                            //                         type='checkbox'
                            //                         checked={isSelected(row[recordId])}
                            //                         onChange={(event) => { clickSubjectHandler(event, row[recordId], row['SubjectCode'], row['SubjectScheduleDetailId']) }}
                            //                     />

                            //                 }


                            //             </>
                            //         } else if (rowDetail.tableType === 'childWithMore') {
                            //             dataUI = <>
                            //                 <Link to={`${rowDetail.onTypes.UPDATE_LINK}${rowDetail.childId}/${rowDetail.parentId}/${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1">
                            //                     <i className="fa fa-pencil-alt text-white"></i>
                            //                 </Link>
                            //                 {rowDetail.withMoreButton && rowDetail.childWithMoreButton && <Link to={`${rowDetail.onTypes.MORE_LINK}${rowDetail.parentId}/${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                            //                     <i className="fa fa-list-alt text-white"></i>
                            //                 </Link>}
                            //                 {rowDetail.withMoreButton && !rowDetail.childWithMoreButton && <Link to={`${rowDetail.onTypes.MORE_LINK}${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                            //                     <i className="fa fa-list-alt text-white"></i>
                            //                 </Link>}
                            //                 <Link to={`${rowDetail.onTypes.DELETE_LINK}${rowDetail.childId}/${rowDetail.parentId}/${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-danger">
                            //                     <i className="fa fa-trash"></i>
                            //                 </Link>
                            //             </>
                            //         } else {
                            //             dataUI = <>
                            //                 <Link to={`${rowDetail.onTypes.UPDATE_LINK}${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1">
                            //                     <i className="fa fa-pencil-alt text-white"></i>
                            //                 </Link>
                            //                 {rowDetail.withMoreButton && rowDetail.childWithMoreButton && <Link to={`${rowDetail.onTypes.MORE_LINK}${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                            //                     <i className="fa fa-list-alt text-white"></i>
                            //                 </Link>}
                            //                 <Link to={`${rowDetail.onTypes.DELETE_LINK}${row[recordId]}`} className="btn btn-xs btn-icon btn-icon-xs btn-danger">
                            //                     <i className="fa fa-trash"></i>
                            //                 </Link>
                            //             </>
                            //         }
                            //         break;
                            //     default:
                            //         dataUI = column.format && typeof value === 'number' ? column.format(value) : column.withDateFormat ? moment(value).format('YYYY-MM-DD') : value;

                            // }
                        }
                        console.log(dataUI)
                        console.log(row[recordId])
                        console.log(isSelected(row[recordId]))
                        return (
                            !column.hidden &&
                            <>
                                {props.type == 'addDrop' ?
                                    <>
                                        {
                                            row.SubjectStatusId === 2 && row.EnrollmentStatus == 1 && !isSelected(row[recordId]) ? (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            ) : row.SubjectStatusId === 2 && row.EnrollmentStatus == 1 && isSelected(row[recordId]) ? (
                                                <TableCell className='bg-success' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            ) : row.SubjectStatusId === 3 && row.EnrollmentStatus == 1 && isSelected(row[recordId]) ? (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            ) : row.SubjectStatusId === 3 && row.EnrollmentStatus == 1 && !isSelected(row[recordId]) ? (
                                                <TableCell className='bg-danger' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            ) : row.SubjectStatusId === 4 && row.EnrollmentStatus == 1 ? (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            ) : row.SubjectStatusId === 5 && row.EnrollmentStatus == 1 && !isSelected(row[recordId]) ? (
                                                <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            ) : row.SubjectStatusId === 5 && row.EnrollmentStatus == 1 && isSelected(row[recordId]) ? (
                                                <TableCell className='bg-success' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                    {dataUI}
                                                </TableCell>
                                            )

                                                : row.SubjectStatusId === 2 && !isSelected(row[recordId]) ? (
                                                    <TableCell className='bg-warning' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : row.SubjectStatusId === 2 && isSelected(row[recordId]) ? (
                                                    <TableCell className='bg-success' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : row.SubjectStatusId === 5 && isSelected(row[recordId]) ? (
                                                    <TableCell className='bg-success' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : row.SubjectStatusId === 5 && !isSelected(row[recordId]) ? (
                                                    <TableCell className='bg-danger' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : row.SubjectStatusId === 3 & isSelected(row[recordId]) ? (
                                                    <TableCell className='bg-success' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : row.SubjectStatusId === 1 & isSelected(row[recordId]) ? (
                                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : row.SubjectStatusId === 1 & !isSelected(row[recordId]) ? (
                                                    <TableCell className='bg-warning' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                ) : (
                                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>
                                                        {dataUI}
                                                    </TableCell>
                                                )}
                                    </>

                                    :
                                    <>
                                        {row.SubjectStatusId == 2 ?
                                            <TableCell className='bg-warning' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>{dataUI}</TableCell>

                                            :
                                            row.SubjectStatusId == 5 ?
                                                <TableCell className='bg-danger' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>{dataUI}</TableCell>

                                                :
                                                row.SubjectStatusId == 3 && row.EnrollmentStatus == 0 ?
                                                    <TableCell className='bg-success' key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>{dataUI}</TableCell>
                                                    :
                                                    <TableCell key={column.id} align={column.align} style={{ minWidth: column.minWidth }}>{dataUI}</TableCell>


                                        }
                                    </>


                                }


                            </>

                        );
                    })}

                </TableRow>

            </>
        );
    }


    console.log(selectedSched)
    console.log(selectedDetailIdList)


    return (
        <>
            <Table size="small" aria-label="collapsible table" className='container' >
                <TableHead>
                    <TableRow>
                        <TableCell />
                        {columns.map((column) => (
                            <TableCell
                                key={column.id}
                                align={column.align}
                                padding={column.disablePadding ? 'none' : 'default'}
                                style={{ minWidth: column.minWidth }}
                            >
                                {column.label}
                            </TableCell>
                        ))}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {stableSort(results, getComparator(order, orderBy)).map((row, index) => (
                        <Row
                            key={row[tableProps.recordId]}
                            rowDetail={tableProps}
                            row={row}
                            rowCnt={RowCnt += 1}
                            index={index}
                            type={props.type}
                        />
                    ))}
                </TableBody>
                <TableFooter>
                    {results.length === 0 && <TableRow><TableCell className='text-center font-weight-bold' colSpan={columns.length + 1}>--- No data available in the table ---</TableCell></TableRow>}

                </TableFooter>
            </Table>
            {tableProps.withFooter && tableProps.tableType === 'childWithMore' && <Link to={`${tableProps.onTypes.LIST_LINK_TO_PREVIOUS_PARENT}/${tableProps.childId}`} className="btn btn-secondary m-3">Back</Link>}
        </>
    );
}