import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getSubjectScheduleFilter,
    deleteSubjectSchedule, getInstitute, getSchoolYear, getSemester, getClassSection, getCourses, getReport,
    addUpdateSubjectSchedule, getInstituteQuery, finalizeSubjectSchedule, unFinalizeSubjectSchedule
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import PrintedForm from './PrintedForm';



export default function List() {
    const { state: { fees, auth }, dispatch } = useAppContext();
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const { setValue } = useForm()
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
    const [keyword, setKeyword] = useState(fees.keyword);
    const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
    const [onAlert, setOnAlert] = useState(false)

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [isOpenFinalizeIndividual, setIsOpenFinalizeIndividual] = useState(false)
    const [isOpenFinalizeAll, setIsOpenFinalizeAll] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)
    const [rowFinalizeValue, setRowFinalizeValue] = useState(null)
    const [toFinalize, setToFinalize] = useState(null)

    const [filter, setFilter] = useState({ schoolYear: activeSy, semesterId: activeSem, instituteId: null })
    const updateId = fees.id;


    const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }
    const [instituteSelect, setInstituteSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [courseSelect, setCourseSelect] = useState(null)
    const [classSectionSelect, setClassSectionSelect] = useState(null)


    const [report, setReport] = useState([])


    const [formData, setFormData] = useState({
        schoolYear: filter.schoolYear,
        semesterId: filter.semesterId,
        instituteId: null,
        isActive: 1,
        createdBy: auth.data.UserName
    })

    const formSelectChange = name => event => { setFormData({ ...formData, [name]: event }) }

    // table pagination and search -----------------------------------------------------


    const finalizeSubSchedIndividual = async () => {
        let values = {
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isIndividual: true,
            subjectScheduleId: toFinalize
        }
        await finalizeSubjectSchedule(values)
            .then(res => {
                onSearchFilters()
                setIsOpenFinalizeIndividual(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The subject schedule has been finalized.' });

            })
    }

    const unFinalizeSubSchedIndividual = async (id) => {
        let values = {
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isIndividual: true,
            subjectScheduleId: id
        }
        await unFinalizeSubjectSchedule(values)
            .then(res => {
                onSearchFilters()

                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The subject schedule is Unfinalized.' });

            })
    }


    const finalizeSubSchedAll = async () => {
        let values = {
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isIndividual: false,
            subjectScheduleId: null
        }
        await finalizeSubjectSchedule(values)
            .then(res => {
                onSearchFilters()
                setIsOpenFinalizeAll(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'All subject schedule has been finalized.' });

            })
    }

    const UnFinalizeSubSchedAll = async () => {
        let values = {
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId,
            isIndividual: false,
            subjectScheduleId: null
        }
        await unFinalizeSubjectSchedule(values)
            .then(res => {
                onSearchFilters()
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'All subject schedule is unfinalized.' });

            })
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        const values = {
            subjectScheduleId: 0,
            schoolYear: val.schoolYear,
            semesterId: val.semesterId,
            instituteId: val.instituteId,
            createdBy: auth.data.Username,
            isActive: val.isActive
        }
        await addUpdateSubjectSchedule(values)
            .then(res => {
                if (res.success == false) {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: res.message });

                } else {
                    setIsOpenAdd(false)
                    onSearchFilters()
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Subject Schedule has been saved.' });
                    if (results.length == 0) {
                        history.push(`/admin/references/subject-schedule-details/${res.data.SubjectScheduleId}`)
                    } else {
                        onSearchFilters()
                        setFormData({
                            schoolYear: filter.schoolYear,
                            semesterId: filter.semesterId,
                            instituteId: null,
                            isActive: 1,
                            createdBy: auth.data.UserName
                        })
                    }
                }

            })
            .catch(e => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: e.message });

            })
    }

    // on update grade element
    const onUpdateElement = async (id, val) => {
        const values = {
            subjectScheduleId: parseInt(id),
            schoolYear: val.schoolYear,
            semesterId: val.semesterId,
            instituteId: val.instituteId,
            createdBy: auth.data.Username,
            isActive: val.isActive
        }
        await addUpdateSubjectSchedule(values)
            .then(res => {
                setIsOpenUpdate(false)
                onSearchFilters()
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Subject Schedule has been updated.' });
            })
    }

    // on delete grade element
    const onDeleteElement = async (id) => {
        await deleteSubjectSchedule({ subjectScheduleId: id })
            .then(res => {
                setIsOpenDelete(false)
                onSearchFilters()
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Subject Schedule has been Deleted.' });
            })
            .catch(e => {

            })
    }



    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
    }

    //Update
    const onUpdateGradeElement = (data) => {
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = () => {
        setIsOpenDelete(false)
    }

    //Finalize
    const onCloseFinalizeIndvidual = () => {
        setIsOpenFinalizeIndividual(false)
    }

    const onCloseFinalizeAll = () => {
        setIsOpenFinalizeAll(false)
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    const getSubjectScheduleReport = async (val) => {
        await getReport(val)
            .then(res => {
                // console.log(res)
                // let arr = []
                // if (res.data.length > 0)
                //     res.data.map(items => {
                //         arr.push({ ...items, Details: JSON.parse(items.Details) })
                //     })
                // setReport(arr)
            })
    }


    const onSearchFilters = async () => {
        await getSubjectScheduleFilter(page, rowsPerPage, filter.schoolYear, filter.semesterId, auth.data.UserType == 'CHR' || auth.data.UserType == 'DEN' ? auth.data.InstituteId : filter.instituteId)
            .then(res => {
                setResults(res.data)
                getSubjectScheduleReport({
                    schoolYear: filter.schoolYear,
                    semesterId: filter.semesterId,
                    instituteId: auth.data.UserType == 'CHR' || auth.data.UserType == 'DEN' ? auth.data.InstituteId : filter.instituteId,
                    withInstitute: filter.instituteId !== null && filter.instituteId !== '' ? true : false
                })
                dispatch({ type: 'GET-SUBJECT-SCHEDULES', data: res.data });
                setOnAlert(true)
                setTotalRecord(res.data.length > 0 ? res.data.length : 0);
            })
    }


    const onSeachAgain = () => {
        setResults([])
        setOnAlert(false)

    }

    useEffect(() => {
        SelectCreator(getClassSection, setClassSectionSelect, "ClassSectionId", "Description")
        SelectCreator(getCourses, setCourseSelect, "CourseId", "Description")
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description")
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description")
        if (auth.data.UserType == 'CHR' || auth.data.UserType == 'CHR') {
            SelectCreator(getInstituteQuery, setInstituteSelect, 'InstituteId', 'Description', { where: `WHERE I.IsActive = 1 AND I.InstituteId IN ( ${auth.data.InstituteId} ) ORDER BY I.Description` })

        } else if (auth.data.UserType == 'DEN') {
            SelectCreator(getInstituteQuery, setInstituteSelect, 'InstituteId', 'Description', { where: `WHERE I.IsActive = 1 AND I.InstituteId IN ( ${auth.data.InstituteId} ) ORDER BY I.Description` })

        } else {
            SelectCreator(getInstituteQuery, setInstituteSelect, 'InstituteId', 'Description', { where: 'WHERE I.IsActive = 1 ORDER BY I.Description' })

        }
    }, [])

    //Modal Content
    const Content = (props) => {
        console.log(auth)
        const [data, setData] = useState({
            schoolYear: props.value !== undefined && props.value !== null ? props.value.schoolYear : null,
            semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : null,
            instituteId: auth.data.UserType != 'CHR' && auth.data.UserType != 'DEN' ? null : props.instituteSelect[0].tableId,

        })


        const [switchState, setSwitchState] = useState({
            isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false,


        });

        const switchHandleChange = name => event => {
            console.log(event.target.checked)
            setSwitchState({ ...switchState, [name]: event.target.checked });
        };

        // Selects
        const selectSemChange = name => event => { setData({ ...data, semesterId: event }) }
        const selectSYChange = name => event => { setData({ ...data, schoolYear: event }) }
        const selectStudentCatChange = name => event => { setData({ ...data, studentCategoryId: event }) }
        const selectInstituteChange = name => event => { setData({ ...data, instituteId: event }) }





        useEffect(() => {
            if (data && switchState) {

                props.onSave({
                    ...data,
                    isActive: switchState.isActive == true ? 1 : 0
                })
            }

        }, [data, switchState])



        console.log(props.value)
        console.log(props.instituteSelect)
        console.log(data)
        return (
            <>

                <main className='container'>



                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.schoolYearSelect}
                                onHandleChange={selectSYChange('schoolYear')}
                                refClassContainer=""
                                name="schoolYear"
                                isRequired={false}
                                withLabel={false}
                                label="School Year"
                                placeHolder="Select School Year"
                                forwardRef={() => { }}
                                selectedId={data.schoolYear}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.semesterSelect}
                                onHandleChange={selectSemChange('semesterId')}
                                refClassContainer=""
                                name="semesterId"
                                isRequired={false}
                                withLabel={false}
                                label="Semester"
                                placeHolder="Select Semester"
                                forwardRef={() => { }}
                                selectedId={data.semesterId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>

                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.instituteSelect}
                                onHandleChange={selectInstituteChange('instituteId')}
                                refClassContainer=""
                                name="instituteId"
                                isRequired={false}
                                withLabel={false}
                                label="Institute"
                                placeHolder="Select Institute"
                                forwardRef={() => { }}
                                selectedId={data.instituteId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>

                    <div className="form-row">

                        <div className="form-group col">
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    name="isActive"
                                    inputRef={() => { }}
                                    control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                                    label="Active"
                                />
                            </FormControl>
                        </div>
                    </div>
                </main>
            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.instituteDesc : ''} `}</span> </p>
                </main>



            </>
        )
    }

    const FinalizeContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to finalize <span className='text-primary'>{`${props.value !== undefined && props.value !== null ? props.value.instituteDesc : ''} `}</span> ?</p>
                </main>



            </>
        )
    }

    const FinalizeAllContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to finalize the subject schedule for the particular school year and semester ?</p>
                </main>



            </>
        )
    }



    // useEffect(() => {
    //     const execute = async () => {
    //         await getSubjectSchedule(page, rowsPerPage, keyword)
    //             .then(response => {
    //                 if (response.success) {
    //                     setResults(response.data);
    //                     setTotalRecord(response.data.length > 0 ? response.records : 0);
    //                 } else {
    //                     dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
    //                 }
    //             })
    //             .catch(error => {

    //                 dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

    //             })
    //     }
    //     execute();

    // }, [keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SchoolYear', label: 'SchoolYear', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Semester', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'InstituteDesc', label: 'Department', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'IsFinal', label: 'Is Finalized', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'SubjectScheduleId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'addUpdateSubjectSchedule',
        parentId: null,
        tableTitle: 'List of Subject Schedule',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: updateId,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch,
        onUpdate: onUpdateGradeElement,
        onDelete: onDeleteGradeElement,
        userType: auth.data.UserType,
        onFinalize: (id, value) => { setRowFinalizeValue(value); setToFinalize(id); setIsOpenFinalizeIndividual(true) },
        unFinalize: (id) => { unFinalizeSubSchedIndividual(id) }
    }
    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))
    console.log(instituteSelect)



    console.log(auth.data.schoolyears.enrollment.SchoolYear)
    console.log(report)
    console.log(auth)

    return (
        <>
            {instituteSelect && schoolYearSelect && semesterSelect &&
                <>
                    <AddUpdateModal
                        title="Add Subject Schedule"
                        isOpen={isOpenAdd}
                        Content={Content}
                        handleClose={onAddGradeElementClose}
                        isAdd={true}
                        instituteSelect={instituteSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        classSectionSelect={classSectionSelect}
                        courseSelect={courseSelect}
                        onSubmit={onSaveGradeElement}
                        id="subjectScheduleId"

                    />

                    <AddUpdateModal
                        title="Update Subject Schedule"
                        isOpen={isOpenUpdate}
                        Content={Content}
                        value={keysToCamelCase(rowValue)}
                        handleClose={onUpdateGradeElementClose}
                        isUpdate={true}
                        instituteSelect={instituteSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        classSectionSelect={classSectionSelect}
                        courseSelect={courseSelect}
                        onSubmit={onUpdateElement}
                        id="subjectScheduleId"

                    />

                    <AddUpdateModal
                        title="Delete Subject Schedule"
                        isOpen={isOpenDelete}
                        Content={DeleteContent}
                        handleClose={onDeleteGradeElementClose}
                        isDelete={true}
                        onSubmit={onDeleteElement}
                        instituteSelect={instituteSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        classSectionSelect={classSectionSelect}
                        courseSelect={courseSelect}
                        value={keysToCamelCase(rowDeleteValue)}
                        id="subjectScheduleId"

                    />

                    <AddUpdateModal
                        title="Finalize Subject Schedule"
                        isOpen={isOpenFinalizeIndividual}
                        Content={FinalizeContent}
                        handleClose={onCloseFinalizeIndvidual}
                        isFinalize={true}
                        onSubmit={finalizeSubSchedIndividual}
                        instituteSelect={instituteSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        classSectionSelect={classSectionSelect}
                        courseSelect={courseSelect}
                        value={keysToCamelCase(rowFinalizeValue)}
                        id="subjectScheduleId"

                    />

                    <AddUpdateModal
                        title="Finalize All Subject Schedule"
                        isOpen={isOpenFinalizeAll}
                        Content={FinalizeAllContent}
                        handleClose={onCloseFinalizeAll}
                        isFinalizeAll={true}
                        onSubmit={finalizeSubSchedAll}
                        instituteSelect={instituteSelect}
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        classSectionSelect={classSectionSelect}
                        courseSelect={courseSelect}
                        value={keysToCamelCase(rowFinalizeValue)}
                        id="subjectScheduleId"

                    />

                    {results.length > 0 ?
                        <>

                            <div className="card card-custom gutter-b example example-compact">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                                    </div>
                                    <div className="card-toolbar">
                                        <div className="example-tools">
                                            {auth.data.UserType == 'RGR' || auth.data.UserType == 'RGD' &&
                                                <>
                                                    {results[0].IsAllFinalized == 0 ?
                                                        <button onClick={() => { setIsOpenFinalizeAll(true) }} className="btn btn-sm btn-icon-sm btn-info mr-3">
                                                            Finalized All
                                                        </button>

                                                        :

                                                        <button disabled className="btn btn-sm btn-icon-sm btn-secondary mr-3">
                                                            Finalized All
                                                        </button>
                                                    }


                                                </>

                                            }

                                            <button onClick={() => { onSeachAgain() }} className="btn btn-sm btn-icon-sm btn-secondary mr-3">
                                                Search Again
                                            </button>
                                            {/* <PrintedForm
                                                report={report}
                                            /> */}
                                            {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                                                <i className="fa fa-plus"></i> Add New
                                            </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                    <IMTable tableProps={tableProps} />

                                    <section className='card p-2'>

                                        <div className="d-flex align-items-center" style={{ width: '100%' }}>

                                            <div className="form-group mb-0 col">
                                                <IMSelect
                                                    data={schoolYearSelect}
                                                    onHandleChange={formSelectChange('schoolYear')}
                                                    refClassContainer=""
                                                    name="schoolYear"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="School Year"
                                                    placeHolder="Select School Year"
                                                    forwardRef={() => { }}
                                                    selectedId={formData.schoolYear}
                                                    refClassName={`text-center mr-3`}
                                                    withDescription={false}
                                                    //description={`Enrollment Status`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[]}
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code',
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-0 col">
                                                <IMSelect
                                                    data={semesterSelect}
                                                    onHandleChange={formSelectChange('semesterId')}
                                                    refClassContainer=""
                                                    name="semesterId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Semester"
                                                    placeHolder="Select Semester"
                                                    forwardRef={() => { }}
                                                    selectedId={formData.semesterId}
                                                    refClassName={`text-center mr-3`}
                                                    withDescription={false}
                                                    //description={`Enrollment Status`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[]}
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code',
                                                    }}
                                                />
                                            </div>
                                            <div className="form-group mb-0 col">
                                                <IMSelect
                                                    data={instituteSelect}
                                                    onHandleChange={formSelectChange('instituteId')}
                                                    refClassContainer=""
                                                    name="instituteId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Institute"
                                                    placeHolder="Select Department / Institute"
                                                    forwardRef={() => { }}
                                                    selectedId={formData.instituteId}
                                                    refClassName={`text-center mr-3`}
                                                    withDescription={false}
                                                    //description={`Enrollment Status`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[]}
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code',
                                                    }}
                                                />
                                            </div>

                                            {formData.yearLevelId !== null && formData.yearLevelId !== ''
                                                && formData.semesterId !== null && formData.semesterId !== ''
                                                && formData.subjectId !== null && formData.subjectId !== ''
                                                && formData.instituteId !== null && formData.instituteId !== '' ?
                                                <button
                                                    onClick={() => { onSaveGradeElement(formData) }}
                                                    className="btn btn-sm btn-primary col-1">
                                                    Add
                                                </button>
                                                :
                                                <button
                                                    disabled
                                                    className="btn btn-sm btn-secondary col-1">
                                                    Add
                                                </button>

                                            }
                                        </div>
                                    </section>


                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex align-items-center'>
                                <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                                    <header className='card-header p-5 bg-secondary'>
                                        <h6 className="float-left m-0 mt-4 ml-5">Subject Schedule</h6>
                                        <button onClick={() => { onAddGradeElement() }} className='btn btn-sm btn-primary float-right'>Add</button>
                                    </header>
                                    <section className="card-body">
                                        {onAlert == true && results.length == 0 &&
                                            <div className="alert alert-danger" role="alert">
                                                No Record Found
                                            </div>
                                        }
                                        <p className="card-text">Search for Subject Schedules</p>



                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={schoolYearSelect}
                                                    onHandleChange={filterChange('schoolYear')}
                                                    refClassContainer=""
                                                    name="schoolYear"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="School Year"
                                                    placeHolder="Select School Year"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.schoolYear}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`School Year`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={semesterSelect}
                                                    onHandleChange={filterChange('semesterId')}
                                                    refClassContainer=""
                                                    name="semesterId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Semester"
                                                    placeHolder="Select Semester"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.semesterId}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Semester`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        {auth.data.UserType == 'RGR' &&
                                            <div className="form-row">
                                                <div className="form-group col">
                                                    <IMSelect
                                                        data={instituteSelect}
                                                        onHandleChange={filterChange('instituteId')}
                                                        refClassContainer=""
                                                        name="instituteId"
                                                        isRequired={false}
                                                        withLabel={false}
                                                        label="Institute"
                                                        placeHolder="Institute"
                                                        forwardRef={() => { }}
                                                        selectedId={filter.instituteId}
                                                        refClassName={`text-center`}
                                                        withDescription={true}
                                                        description={`School / College:`}
                                                        refDisabled={true}
                                                        refIsStatic={false}
                                                        refStaticData={[
                                                        ]
                                                        }
                                                        field={{
                                                            tableId: 'tableId',
                                                            display: 'code'
                                                        }}
                                                    />
                                                </div>
                                            </div>

                                        }


                                        <div className="form-row bg-secondary">
                                            <button onClick={() => { onSearchFilters() }} className='btn btn-success btn-block'>Search</button>

                                        </div>

                                    </section>
                                </main>

                            </div>

                        </>

                    }


                </>
            }

        </>
    );
}