import React, { useState, forwardRef, useRef, useEffect } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Button } from '@material-ui/core';
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import SignatureLogo from "../../../../../_metronic/_assets/logo/SignatureLogo.png"
import _ from 'lodash';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function UploadImageModal(props) {
    const { content, isOpen, handleClose, onSubmit } = props;
    const fileInputRefImage = useRef();
    const fileInputRefSignature = useRef();
    const [imagePreview, setImagePreview] = useState(null);
    const [signaturePreview, setSignaturePreview] = useState(null);


    const handleImageChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setImagePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    const handleSignatureChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            const reader = new FileReader();
            reader.onloadend = () => {
                setSignaturePreview(reader.result);
            };
            reader.readAsDataURL(file);
        }
    };

    console.log(fileInputRefImage)
    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Upload Image</DialogTitle>

            <hr />
            <DialogContent>
                <h4>Photo Requirements</h4>
                <p className="mb-0">- Taken in plain white background with sufficient lighting with no shadows/silhouette.</p>
                <p className="mb-0">- Photo taken within the last six (6) months.</p>
                <p className="mb-0">- Angle or Framing, “Look forward, head and shoulders should be clearly visible”.</p>
                <p className="mb-0">- Wear FAITH School Uniform.</p>
                <p className="mb-0">- Girls can wear natural make-up. Hair must not cover the face.</p>
                <p className="mb-0">- Boys no make-up. Clean cut hair is advisable.</p>
                <p className="mb-0">- Avoid wearing eyeglasses, extreme hair color, big earrings, laces.</p>
                <br />

                <h4>Signature Requirements</h4>
                <p className="mb-0">- Use plain white background</p>
                <p className="mb-0">- Use only black ink.</p>
                <p className="mb-0">- Afix a clear and uncut copy of your signature</p>
                <hr />
                <form
                    onSubmit={(e) => { onSubmit(e, fileInputRefImage, fileInputRefSignature) }}
                    method="post"
                    encType="multipart/form-data"
                    style={{ marginBottom: '15px' }}
                >
                    <section className='row'>
                        <div className='col-6 text-center'>
                            <p>Image</p>
                            <img
                                className=""
                                src={imagePreview || ProfileLogo}
                                alt="Profile Logo"
                                style={{ width: '150px', height: '100px', marginBottom: '15px' }}
                            />
                            <input
                                type="file"
                                name="image"
                                accept=".jpg"
                                ref={fileInputRefImage}
                                onChange={handleImageChange}
                                required
                                style={{ marginBottom: '10px', marginLeft: '20%' }}
                            />
                        </div>
                        <div className='col-6 text-center'>
                            <p>Signature</p>
                            <img
                                className=""
                                src={signaturePreview || SignatureLogo}
                                alt="Profile Logo"
                                style={{ width: '150px', height: '100px', marginBottom: '15px' }}
                            />
                            <input
                                type="file"
                                name="image"
                                accept=".jpg"
                                ref={fileInputRefSignature}
                                onChange={handleSignatureChange}
                                required
                                style={{ marginBottom: '10px', marginLeft: '20%' }}
                            />
                        </div>
                    </section>


                    <button
                        type="submit"
                        className="btn btn-primary"
                        style={{ width: '100%' }}
                    >
                        Upload
                    </button>
                </form>
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose} variant="contained" color="danger">
                    Exit
                </Button>
            </DialogActions>
        </Dialog>
    );
}