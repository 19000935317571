import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
// import { login, hasAccount, getStudentDetailsByStudentNo, setStudentDetails, sendMail, updateAccessCode, retrieveAccountStatus } from '../__hooks__';
import { retrieveAccountStatus, sendMail } from '../__hooks__';
import { authTypes } from '../__hooks__/types';
import { componentTypes } from '../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { useAppContext } from '../../../contexts/useAppContext';
import firebase from '../../../apis/firebase/firebase.config';
import history from '../../../history';

export default function SignUpInitial() {
    const { state: { auth }, dispatch } = useAppContext();
    const { register, handleSubmit, errors } = useForm();
    //const [ accountExists, setAccountExists] = useState(null);
    //const [ values, setValues] = useState(null);

    let url = window.location.pathname;
    var title = "";
    var sourcePage;
    var userType;

    // Set title of the page based on the link/URL
    if(url == '/auth/forgotpassword'){
        title = 'FORGOT PASSWORD';
        sourcePage = 0;
    } else {
        title = 'SIGN UP';
        sourcePage = 1;
    }

    const onSubmit = formValues => {
        /*** SIGN UP / FORGOT PASSWORD */

        /*** 
         * const sendRequest:
         * 
         * Send arguments to API to determine 
         * whether user already have an account or not.
         * 
         * If no account yet, create new one; 
         * Else, update access code to reset password.
         * 
         * Finally, send email (sendMailWitchAccessCode) to 
         * student's registered email with a direct link and access code.
         * 
         ***/

        const sendRequest = async () => {
          const { v4: randomKey } = require('uuid');

          //console.log(randomKey());

            let values = {
                studentNo: formValues.userInput,
                sourcePage: sourcePage,
                accessTokenKey: randomKey()
            }

          await retrieveAccountStatus(values) //create account or update access code; also check if account already exists or otherwise
            .then(result => {
                if (result.success) {
                  //console.log("Retrieve Account Result: ", result);
                  userType = result.data[0].UserType;

                  switch(result.data[0].AcctStatus){
                    case "UsernameDoesNotExist":
                      if(formValues.userInput.includes('@firstasia.edu.ph')){
                          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: `Username does not exist in the organization.` });
                      } else {
                          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: `Provided Username is invalid` });
                      }
                      break;
                    case "StudentNoDoesNotExist":
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: `Student Number is invalid and/or does not exist in the organization.` });
                      break;
                    case "AcctAlreadyExist":
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: `Account for ${formValues.userInput} already exists!` });
                      break;
                    case "AcctNoPasswordYet":
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: `Account for ${formValues.userInput} has no password yet!` });
                      break;
                    case "AcctCreated":
                    case "AcctUpdated":
                      sendMailWithAccessCode(result.data[0]); 
                      break;
                    // case "AlreadyHaveAccessCode":
                    //   dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: `Link already sent! Kindly check your FAITH email.` });
                    //   break;
                    default:
                      history.push('/auth/login');
                      break;
                  }

                } else {
                  dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                }
            })
            .catch(error => {
              if (error.response.status === 401) {
                history.push('/auth/login');
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
              }
            })
        }

        /*** Send mail with link and access code */
        const sendMailWithAccessCode = async (json) => {
          let values = {
            emailAddress: json.EmailAddress,
            firstName: json.FirstName,
            lastName: json.LastName,
            accessCode: json.AccessCode,
            sourcePage: sourcePage
          }

            await sendMail(values) //Call sendMail API
                .then(response => {
                  //console.log(response);
                    let message;
                    message = userType == 'Employee' ? 'A link has been sent to your email. Please check your company Email Address.' : 'A link has been sent to your email. Please check your FAITH Email Address.';
                    if(response.success){
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: message });
                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'An error has occured. Please try again later.' });
                    }
                })
                .catch(error => {
                    console.log(error)
                })
        }

        sendRequest();
      }

    return (
        <div className="login-form login-signin" id="kt_login_signin_form">
          {/* begin::Head */}
          <div className="text-center mb-5 mb-lg-5">
            <h3 className="font-size-h1 mb-5">
              <b>{title}</b>
            </h3><br />
          </div>
          {/* end::Head */}
    
          {/*begin::Form*/}
          <form onSubmit={handleSubmit(onSubmit)} className="form fv-plugins-bootstrap fv-plugins-framework mt-5">
            {/* Student Number Textbox */}
            <div className="form-group fv-plugins-icon-container">
              <label>{sourcePage == 0 ? "Username" : "Student Number"}<span className="text-danger">*</span></label>
              <input
                placeholder={sourcePage == 0 ? "Username" : "Student Number"}
                type="text"
                name="userInput"
                className={`form-control h-auto py-5 px-6${errors.userInput === undefined ? "" : " is-invalid"}`}
                ref={register({
                  required: true
                })}
                autoFocus
              />
              <span className="form-text text-muted">Please enter your {sourcePage == 0 ? "username" : "student number"}.</span>
              {errors.userInput && errors.userInput.type === "required" && <div className="invalid-feedback">{sourcePage == 0 ? "Username" : "Student Number"} is required</div>}
            </div>

            {/* Buttons Div (Request Access Code and Back) */}
            <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
              <button type="submit" className={`btn btn-primary`} >
                <i className="fa flaticon-paper-plane-1"></i>&nbsp;Request Access Code
              </button>
              <button onClick={ () => history.push('/auth/login') } className={`btn btn-primary`} >
                <i className="fa flaticon-reply"></i>&nbsp;Back
              </button>
            </div>   
          </form>
          {/*end::Form*/}
        </div>
      );
}