export const referenceTypes = {
    SELECTED_INSTITUTE: 'SELECTED-INSTITUTE',
    GET_INSTITUTES: 'GET-INSTITUTES',
    GET_INSTITUTE: 'GET-INSTITUTE-BY-ID',
    CREATE: 'CREATE-INSTITUTE',
    UPDATE: 'UPDATE-INSTITUTE',
    DELETE: 'DELETE-INSTITUTE',
    LIST_LINK: '/admin/references/institutes',
    CREATE_LINK: '/admin/references/create-institutes/',
    UPDATE_LINK: '/admin/references/update-institutes/',
    DELETE_LINK: '/admin/references/delete-institutes/'
}