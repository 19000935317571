import instance from "../../../../../../apis/local/systemAPI";


export const getGradeTemplateDetailFinal = async (query) => {
    const result = await instance.post("api/grades/grade-template-detail-finals/f", query);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createGradeTemplateDetailFinal = async (formValues) => {
    const result = await instance.post("api/grades/grade-template-detail-finals/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateGradeTemplateDetailFinal = async (id, formValues) => {
    const result = await instance.patch(`api/grades/grade-template-detail-finals/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteGradeTemplateDetailFinal = async (id) => {
    const result = await instance.delete(`api/grades/grade-template-detail-finals/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getInstitute = async () => {
    const result = await instance.get(`api/references/institutes/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGradingPeriod = async () => {
    const result = await instance.get(`api/references/grading-period/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}