import { number } from "prop-types";
import React, { useRef, forwardRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';

import './__hooks__/styles.css'




export default function PrintReceiveDocumentReceipt(props) {
    const { results, encoder, date, sy, sem, credential } = props
    let componentRef = useRef();


    const pageStyle = " @page { size: 7in 4.25in; }"



    const Component = forwardRef((props, ref) => {


        console.log(results.CredentialSubmitted)
        console.log(results.LastSchoolAttended)
        return (
            <>



                <div className="d-none" >
                    {/* <style>{pageStyle}</style> */}
                    <style type="text/css" media="print">{"\
                          @page {\ size: portrait;\ margin: 5mm 2mm 5mm 2mm;}\
                        "}</style>

                    <div ref={ref}>
                        <div className="container" >

                            <main className="card border-0 p-5">

                                {/* ACKNOWLEDGEMENT REPORT */}
                                {results.CredentialSubmitted !== null && results.CredentialSubmitted.length > 0 ?

                                    <>
                                        <section className="row" style={{pageBreakAfter:"always"}}>
                                            <div style={{width:"49%"}}>
                                                <main className="col-12">
                                                {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                <h1 className="text-center mb-0 font-weight-bold text-dark">FIRST ASIA INSTITUTE</h1>
                                                <h4 className="text-center mt-0 font-weight-bold text-dark">OF TECHNOLOGY AND HUMANITIES</h4>
                                                <br />
                                                <h4 className="text-center mb-3">ACKNOWLEDGEMENT FORM</h4>

                                                <section className="row justify-content-between mt-5">
                                                    <div className="col-8">
                                                        <p className="mb-0">AY/SEM: {`${sy} - ${sem}`}</p>
                                                        <p className="mb-0">Student No: {results.StudentNo}</p>
                                                        <p className="mb-0">Name: {results.StudentName}</p>
                                                        <p className="mb-0">Course / Year:  {results.CourseYearLevelDesc}</p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="float-right">Date: {date}</p>

                                                    </div>
                                                </section>
                                                <p className="mb-0">Last School Attended</p>
                                                {results.LastSchoolAttended !== null ?
                                                    <h4 className="text-center mt-5">{results.LastSchoolAttended}</h4>
                                                    :
                                                    <h4 className="text-center mt-5 "> N/A </h4>

                                                }
                                                <h2 className="lead text-center mt-5">DOCUMENT(S) RECEIVED</h2>


                                                <section className="text-center" style={{ position: 'absolute', top: '53%', left: '42%' }}>
                                                    {results.CredentialSubmitted.map((item) => {
                                                        return (
                                                            <>
                                                                <p className="mb-0">{item}</p>
                                                            </>
                                                        )
                                                    })}


                                                </section>


                                                <section className="row justify-content-between " style={{ marginTop: '35%' }}>
                                                    <div className="col">
                                                        <p>Received by:</p>
                                                        <br />
                                                        <p className="text-center mb-0" style={{ width: '100%' }}>{encoder}</p>
                                                        <span className="horizontal-line-subFaculty"></span>
                                                        <p className="text-center">Office of the Registrar</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>Comforme</p>
                                                        <br />
                                                        <p className="text-center mb-0">{results.StudentName}</p>
                                                        <span className="horizontal-line-subFaculty"></span>
                                                        <p className="text-center">Signature over Printed Name</p>

                                                    </div>

                                                </section>
                                                </main>
                                            </div>

                                            <div style={{background:"none", width:"2%"}}></div>

                                            <div style={{width:"49%"}}>
                                                <main className="col-12">
                                                {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                <h1 className="text-center mb-0 font-weight-bold text-dark">FIRST ASIA INSTITUTE</h1>
                                                <h4 className="text-center mt-0 font-weight-bold text-dark">OF TECHNOLOGY AND HUMANITIES</h4>
                                                <br />
                                                <h4 className="text-center mb-3">ACKNOWLEDGEMENT FORM</h4>

                                                <section className="row justify-content-between mt-5">
                                                    <div className="col-8">
                                                        <p className="mb-0">AY/SEM: {`${sy} - ${sem}`}</p>
                                                        <p className="mb-0">Student No: {results.StudentNo}</p>
                                                        <p className="mb-0">Name: {results.StudentName}</p>
                                                        <p className="mb-0">Course / Year:  {results.CourseYearLevelDesc}</p>
                                                    </div>
                                                    <div className="col-4">
                                                        <p className="float-right">Date: {date}</p>

                                                    </div>
                                                </section>
                                                <p className="mb-0">Last School Attended</p>
                                                {results.LastSchoolAttended !== null ?
                                                    <h4 className="text-center mt-5">{results.LastSchoolAttended}</h4>
                                                    :
                                                    <h4 className="text-center mt-5 "> N/A </h4>

                                                }
                                                <h2 className="lead text-center mt-5">DOCUMENT(S) RECEIVED</h2>


                                                <section className="text-center" style={{ position: 'absolute', top: '53%', left: '42%' }}>
                                                    {results.CredentialSubmitted.map((item) => {
                                                        return (
                                                            <>
                                                                <p className="mb-0">{item}</p>
                                                            </>
                                                        )
                                                    })}


                                                </section>


                                                <section className="row justify-content-between " style={{ marginTop: '35%' }}>
                                                    <div className="col">
                                                        <p>Received by:</p>
                                                        <br />
                                                        <p className="text-center mb-0" style={{ width: '100%' }}>{encoder}</p>
                                                        <span className="horizontal-line-subFaculty"></span>
                                                        <p className="text-center">Office of the Registrar</p>
                                                    </div>
                                                    <div className="col">
                                                        <p>Comforme</p>
                                                        <br />
                                                        <p className="text-center mb-0">{results.StudentName}</p>
                                                        <span className="horizontal-line-subFaculty"></span>
                                                        <p className="text-center">Signature over Printed Name</p>

                                                    </div>

                                                </section>
                                                </main>
                                            </div>
                                        </section>

                                        {/* TEMPORARY ENROLLMENT AGREEMENT FORM */}
                                        <section className="row temp-agreement">
                                            <div style={{width:"49%"}}>
                                                <main className="col-12">
                                                    {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                    <h1 className="text-center mb-0 font-weight-bold text-dark">FIRST ASIA INSTITUTE</h1>
                                                    <h4 className="text-center mt-0 font-weight-bold text-dark">OF TECHNOLOGY AND HUMANITIES</h4>
                                                    <br />
                                                    <h4 className="text-center mb-3">TEMPORARY ENROLLMENT AGREEMENT FORM</h4>


                                                    <p className="float-left">Date: {date}</p>
                                                    <br />
                                                    <br />

                                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{`I ${results.StudentName}, ${results.CourseYearLevelDesc}, understand 
                                                that I am temporary enrolled this ${sem == "1S" ? '1st' : sem == "2S" ? '2nd' : 'Summer'} 
                                                semester of Academic Year ${sy}- ${sy + 1}. I agree to submit/comply with the following requirements`}</p>

                                                    <br />
                                                    <br />
                                                    <section className="text-center" style={{ position: 'absolute', top: '37%', left: '35%' }}>
                                                        {credential.map((item) => {
                                                            return (
                                                                <>
                                                                    <p className="mb-0">{item.Credential}</p>
                                                                </>
                                                            )
                                                        })}


                                                    </section>

                                                    <br />
                                                    <br />

                                                    <p style={{ marginTop: '10%' }}>{`Failure on my part to comply with the above within 60 days would  mean
                                                automatic cancellation of my enrollment at FAITH. Refund, should there to be any,
                                                will be subject to the terms and conditions stated in the student's handbook`}</p>


                                                    <section className="row justify-content-between" style={{ marginTop: '5%' }}>

                                                        <div className="col">
                                                            <p>Comforme</p>
                                                            <br />
                                                            <p className="text-center mb-0">{results.StudentName}</p>
                                                            <span className="horizontal-line-subFaculty"></span>
                                                            <p className="text-center">Signature over Printed Name</p>

                                                        </div>
                                                        <div className="col">
                                                            <p>If unified school</p>
                                                            <br />
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>

                                                    </section>
                                                </main>
                                            </div>

                                            <div style={{background:"none", width:"2%"}}></div>

                                            <div style={{width:"49%"}}>
                                                <main className="col-12">
                                                    {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                    <h1 className="text-center mb-0 font-weight-bold text-dark">FIRST ASIA INSTITUTE</h1>
                                                    <h4 className="text-center mt-0 font-weight-bold text-dark">OF TECHNOLOGY AND HUMANITIES</h4>
                                                    <br />
                                                    <h4 className="text-center mb-3">TEMPORARY ENROLLMENT AGREEMENT FORM</h4>
                                                        
                                                        
                                                    <p className="float-left">Date: {date}</p>
                                                    <br />
                                                    <br />
                                                        
                                                    <p>&nbsp;&nbsp;&nbsp;&nbsp;{`I ${results.StudentName}, ${results.CourseYearLevelDesc}, understand 
                                                that I am temporary enrolled this ${sem == "1S" ? '1st' : sem == "2S" ? '2nd' : 'Summer'} 
                                                semester of Academic Year ${sy}- ${sy + 1}. I agree to submit/comply with the following requirements`}</p>
    
                                                    <br />
                                                    <br />
                                                    <section className="text-center" style={{ position: 'absolute', top: '37%', left: '35%' }}>
                                                        {credential.map((item) => {
                                                            return (
                                                                <>
                                                                    <p className="mb-0 ">{item.Credential}</p>
                                                                </>
                                                            )
                                                        })}
    
                                                    
                                                    </section>
                                                    
                                                    <br />
                                                    <br />
                                                    
                                                    <p style={{ marginTop: '10%' }}>{`Failure on my part to comply with the above within 60 days would  mean
                                                automatic cancellation of my enrollment at FAITH. Refund, should there to be any,
                                                will be subject to the terms and conditions stated in the student's handbook`}</p>
    
                                                    
                                                    <section className="row justify-content-between" style={{ marginTop: '5%' }}>
                                                    
                                                        <div className="col">
                                                            <p>Comforme</p>
                                                            <br />
                                                            <p className="text-center mb-0">{results.StudentName}</p>
                                                            <span className="horizontal-line-subFaculty"></span>
                                                            <p className="text-center">Signature over Printed Name</p>
                                                    
                                                        </div>
                                                        <div className="col">
                                                            <p>If unified school</p>
                                                            <br />
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>
                                                    
                                                        </div>
                                                    
                                                    </section>
                                                </main>
                                            </div>
                                        </section>
                                    </>


                                    :

                                    <>
                                        {/* TEMPORARY ENROLLMENT AGREEMENT FORM */}
                                        <section className="row">
                                            <main className="col-6">
                                                {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                <h1 className="text-center mb-0 font-weight-bold text-dark">FIRST ASIA INSTITUTE</h1>
                                                <h4 className="text-center mt-0 font-weight-bold text-dark">OF TECHNOLOGY AND HUMANITIES</h4>
                                                <br />
                                                <h4 className="text-center mb-3">TEMPORARY ENROLLMENT AGREEMENT FORM</h4>


                                                <p className="float-left">Date: {date}</p>
                                                <br />
                                                <br />

                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;{`I ${results.StudentName}, ${results.CourseYearLevelDesc}, understand 
                                            that I am temporary enrolled this ${sem == "1S" ? '1st' : sem == "2S" ? '2nd' : 'Summer'} 
                                            semester of Academic Year ${sy}- ${sy + 1}. I agree to submit/comply with the following requirements`}</p>

                                                <br />
                                                <br />
                                                <section className="text-center" style={{ position: 'absolute', top: '37%', left: '35%' }}>
                                                    {credential.map((item) => {
                                                        return (
                                                            <>
                                                                <p className="mb-0">{item.Credential}</p>
                                                            </>
                                                        )
                                                    })}


                                                </section>

                                                <br />
                                                <br />

                                                <p style={{ marginTop: '10%' }}>{`Failure on my part to comply with the above within 60 days would  mean
                                            automatic cancellation of my enrollment at FAITH. Refund, should there to be any,
                                            will be subject to the terms and conditions stated in the student's handbook`}</p>


                                                <section className="row justify-content-between" style={{ marginTop: '5%' }}>

                                                    <div className="col">
                                                        <p>Comforme</p>
                                                        <br />
                                                        <p className="text-center mb-0">{results.StudentName}</p>
                                                        <span className="horizontal-line-subFaculty"></span>
                                                        <p className="text-center">Signature over Printed Name</p>

                                                    </div>
                                                    <div className="col">
                                                        <p>If unified school</p>
                                                        <br />
                                                        <br />
                                                        <span className="horizontal-line-subFaculty"></span>

                                                    </div>

                                                </section>
                                            </main>
                                            <main className="col-6">
                                                {/* <img style={{ width: "100px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" /> */}
                                                <h1 className="text-center mb-0 font-weight-bold text-dark">FIRST ASIA INSTITUTE</h1>
                                                <h4 className="text-center mt-0 font-weight-bold text-dark">OF TECHNOLOGY AND HUMANITIES</h4>
                                                <br />
                                                <h4 className="text-center mb-3">TEMPORARY ENROLLMENT AGREEMENT FORM</h4>


                                                <p className="float-left">Date: {date}</p>
                                                <br />
                                                <br />

                                                <p>&nbsp;&nbsp;&nbsp;&nbsp;{`I ${results.StudentName}, ${results.CourseYearLevelDesc}, understand 
                                            that I am temporary enrolled this ${sem == "1S" ? '1st' : sem == "2S" ? '2nd' : 'Summer'} 
                                            semester of Academic Year ${sy}- ${sy + 1}. I agree to submit/comply with the following requirements`}</p>

                                                <br />
                                                <br />
                                                <section className="text-center" style={{ position: 'absolute', top: '37%', left: '35%' }}>
                                                    {credential.map((item) => {
                                                        return (
                                                            <>
                                                                <p className="mb-0 ">{item.Credential}</p>
                                                            </>
                                                        )
                                                    })}


                                                </section>

                                                <br />
                                                <br />

                                                <p style={{ marginTop: '10%' }}>{`Failure on my part to comply with the above within 60 days would  mean
                                            automatic cancellation of my enrollment at FAITH. Refund, should there to be any,
                                            will be subject to the terms and conditions stated in the student's handbook`}</p>


                                                <section className="row justify-content-between" style={{ marginTop: '5%' }}>

                                                    <div className="col">
                                                        <p>Comforme</p>
                                                        <br />
                                                        <p className="text-center mb-0">{results.StudentName}</p>
                                                        <span className="horizontal-line-subFaculty"></span>
                                                        <p className="text-center">Signature over Printed Name</p>

                                                    </div>
                                                    <div className="col">
                                                        <p>If unified school</p>
                                                        <br />
                                                        <br />
                                                        <span className="horizontal-line-subFaculty"></span>

                                                    </div>

                                                </section>
                                            </main>
                                        </section>
                                    </>

                                }




                            </main>


                        </div>

                    </div>



                </div>


            </>
        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    // pageStyle="@page { size: 2.5in 4in }"

                    trigger={() => <button className="btn btn-sm btn-success mr-5 d-inline">Print Form</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}

                />
            </div>



        </>
    )


}