import instance from "../../../../../apis/local/systemAPI";



export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async () => {
    const result = await instance.post("api/references/semesters/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async () => {
    const result = await instance.post("api/references/schoolyears/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentReport = async (formValues) => {
    const result = await instance.post("api/payment/payment-report/search", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getEnrollmentNo = async (formValues) => {
    const result = await instance.post("api/assessment/templates/getEnrollmentStudentNo", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentReport = async (formValues) => {
    const result = await instance.post("api/assessment/templates/getAssessmentReport", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


