export const referenceTypes = {
    SELECTED_SUBJECT_CATEGORY: 'SELECTED-SUBJECT-CATEGORY',
    GET_SUBJECT_CATEGORIES: 'GET-SUBJECT-CATEGORIES',
    GET_SUBJECT_CATEGORY: 'GET-SUBJECT-CATEGORY-BY-ID',
    CREATE: 'CREATE-SUBJECT-CATEGORY',
    UPDATE: 'UPDATE-SUBJECT-CATEGORY',
    DELETE: 'DELETE-SUBJECT-CATEGORY',
    LIST_LINK: '/admin/references/subject-categories',
    CREATE_LINK: '/admin/references/create-subject-categories/',
    UPDATE_LINK: '/admin/references/update-subject-categories/',
    DELETE_LINK: '/admin/references/delete-subject-categories/'
}