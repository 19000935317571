import instance from "../../../../../apis/local/systemAPI";


export const getStudentCurriculum = async (val) => {
    const result = await instance.post("api/student/account/curriculum", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAccount = async (val) => {
    const result = await instance.post("api/student/account/account", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}