
import {
    getStudentRegistrarAccount, getStudentClassSection, getStudentSubjectSelect,
    getAssessmentStudentSubjectScheduleDetail, getStudentAstraSubjects, getStudentRegistrarNewAccount,
    getStudentAssessmentTemplateDetail, getAccountingStudentListOfAssessment, getStudentAssessmentOtherFees,
    getStudentDiscount, getStudentEnrollmentSubjects, checkCurriculum, getAssessmentStudentIregSubjectScheduleDetail,
    getAssessmentStudentTotalUnits, getStudentLatestEnrollmentAccount, getStudentYearLevel, createUpdateEnrollment,
    getStudentAssessmentTemplateId, addUpdateStudentEnrollmentSubject, getStudentDiscountParticulars,
    getPaymentSchedule, getStudentPayment, assignStudentCurriculum, getPaymentModeSelect,
    sendMail
} from "./__hooks__/proc"
import {
    getStudentCampusDiscount, updateEnrollment, createStudentEnrollmentSubjects, deleteStudentEnrollmentSubjects,
    createEnrollment, getCampusEnrollment, getCampusStudentOtherFee, getSubjectScheduleBatch, saveSubjectScheduleBatch,
    saveSyncEnrollment, getCampusBatchkey, getStudentAddOns,
    getYearLevel
} from "./__hooks__/index"
import { getPaymentMode } from "./__hooks__";
import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../../contexts/useAppContext";
import { useParams } from "react-router-dom";
import ReAssessment from "./ReAssessment";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import history from "../../../../history";
import { admissionsTypes } from "./__hooks__/types";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import FullCapacityModal from "../../../../../_metronic/layout/components/custom/modal/FullCapacityModal";
export default function Assessment(props) {

    // student account
    const { id } = useParams()
    const [studentAccount, setStudentAccount] = useState(null)
    const [studentSubjectScheduleDetail, setStudentSubjectScheduleDetail] = useState(null)
    const [studentSubjectScheduleDetailPending, setStudentSubjectScheduleDetailPending] = useState(null)
    const [studentAssessmentFees, setStudentAssessmentFees] = useState([])
    const [studentOtherFees, setStudentOtherFees] = useState([])
    const [studentDiscount, setStudentDiscount] = useState(0)
    const [studentTotalDiscount, setStudentTotalDiscount] = useState(0.00)
    const { state: { auth }, dispatch } = useAppContext();
    const [turnOffDiscounting, setTurnOffDiscounting] = useState(false)

    // Assets
    const [studentClassSectionDropdown, setStudentClassSectionDropdown] = useState(null)
    const [studentSubjectDropdown, setStudentSubjectDropdown] = useState(null)
    const [astraStudentEnrollmentSubjects, setAstraStudentEnrollmentSubjects] = useState(null)
    const [totalAssessmentFee, setTotalAssessmentFee] = useState(0)
    const [totalInterest, setTotalInterest] = useState(0)
    const [studentTotalUnits, setStudentTotalUnits] = useState(1)
    const [studentTotalCreditUnits, setStudentTotalCreditUnits] = useState(0)
    const [studentTotalLabModes, setStudentTotalLabModes] = useState(0)
    const [subjectSchedDetailIds, setSubjectScheduleDetailsIds] = useState(null)
    const [subjectIds, setSubjectIds] = useState(null)
    const [subjectCodes, setSubjectCodes] = useState(null)
    const [subjectCategoryIds, setSubjectCategoryIds] = useState(null)
    const [paymentModeSelect, setPaymentModeSelect] = useState(null)
    const [totalOtherFee, setTotalOtherFee] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalLabFee, setTotalLabFee] = useState(0)
    const [totalTuitionFee, setTotalTuitionFee] = useState(0)
    const [totalMiscFee, setTotalMiscFee] = useState(0)
    const [totalRegFee, setTotalRegFee] = useState(0)
    const [subSchedYearLevel, setSubSchedYearLevel] = useState(null)
    const [studentAssessmentId, setStudentAssessmentId] = useState([])
    const [studentAddOns, setStudentAddOns] = useState([])
    const [totalAddOns, setTotalAddOns] = useState(0)
    const [studentDiscountWithSequence, setStudentDiscountWithSequence] = useState([])
    const [paymentSchedule, setPaymentSchedule] = useState(null)
    const [paymentScheduleNoDiscount, setPaymentScheduleNoDiscount] = useState(null)
    const [studentPayment, setStudentPayment] = useState(0)
    const [labSubjects, setLabSubjects] = useState([])
    const [yearLevelSelect, setYearLevelSelect] = useState(null)
    const [listOfFullClassCodes, setListOfFullClassCodes] = useState([])
    // Dates and Time
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;

    // States
    const [currentSelectedIregSub, setCurrentSelectedSub] = useState()
    const [isSubjectLoading, setIsSubjectLoading] = useState(null)
    const [isAssessmentLoading, setIsAssessmentLoading] = useState(null)
    const [onLoadingSubmition, setOnLoadingSubmition] = useState(null)
    const [studentYearLevel, setStudentYearLevel] = useState(null)


    // Logics
    const status = props.location.data
    const [isCreateAccount, setIsCreateAccount] = useState(false)
    const [isProcessed, setIsProcessed] = useState(false)
    const [isRegular, setIsRegular] = useState(true)
    const [selectIregSubjects, setSelectIregSubjects] = useState([])
    const [selectedClassSectionId, setSelectedClassSectionId] = useState(null)
    const [selectedPaymentModeId, setSelectedPaymentModeId] = useState(1)
    const [selectedAssessmentTemplateId, setSelectedAssessmentTemplateId] = useState(0)
    const [selectedSubSchedDetailId, setSelectedSubSchedDetailId] = useState([])
    const [selectedYearLevelId, setSelectedYearLevelId] = useState(null)
    const [isOnCurriculum, setIsOnCurriculum] = useState(null)
    const [isSyncing, setIsSyncing] = useState(false)
    const [isWithDiscount, setIsWithDiscount] = useState(false)
    const [isFullCapacity, setIsFullCapacity] = useState(false)
    const [showDialog, setShowDialog] = useState({
        id: null,
        record: '',
        status: false
    });
    const [isOpenFullCapacity, setIsOpenFullCapacity] = useState(false)

    // calculated data
    const [studentEnrollmentSubject, setStudentEnrollmentSubject] = useState()
    console.log(status)

    // Sync to Campus Account
    const [syncData, setSyncData] = useState(null)
    const [campusSubjectScheduleBatch, setCampusSubjectScheduleBatch] = useState(null)
    const [studentBatchkey, setStudentBatchkey] = useState([])
    const [reRunSync, setReRunSync] = useState(false)



    // Get Student Account ------------------------------------------------------


    const getStudentAccount = async (val) => {

        await getStudentRegistrarAccount(val)
            .then(res => {
                if (res.data.length > 0) {
                    setSelectedYearLevelId(res.data[0].YearLevelId)
                    setStudentAccount(res.data)
                    if (res.data[0].EnrollmentStatusId == 'DR' || res.data[0].EnrollmentStatusId == 'W')
                        setIsProcessed(true)
                    else
                        setIsProcessed(false)
                } else {
                    history.push("/admin/enrollment/enrollment-registration")
                }



            })
            .catch(e => {

            })
    }

    const getStudentNewAccount = async (val) => {
        await getStudentRegistrarNewAccount(val)
            .then(res => {
                if (res.data.length > 0)
                    setSelectedYearLevelId(res.data[0].YearLevelId)
                setStudentAccount(res.data)
                setIsProcessed(false)

            })
            .catch(e => {

            })
    }


    const getLatestEnrollmentAccount = async (val) => {
        await getStudentLatestEnrollmentAccount(val)
            .then(res => {
                console.log(res.data)
                if (status != undefined && status.isIntent == true) {
                    history.push('/admin/dashboard')
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'success',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Intent to Enroll has been recorded.'
                    });
                } else {
                    history.push('/admin/student')
                    setTimeout(() => {
                        history.push(`${admissionsTypes.LIST_LINK}${res.data[0].EnrollmentId}`)
                    }, 50);
                }


            })
            .catch()
    }


    const getStudentYearLevelId = async (val) => {
        await getStudentYearLevel(val)
            .then(res => {
                console.log(res.data)
                setSelectedYearLevelId(res.data[0].YearLevelId)
            })
    }


    // Save and Update
    const getStudentAdds = async (val) => {
        await getStudentAddOns(val)
            .then(res => {
                console.log(res)
                setTotalAddOns(res.totalAmount)
                setStudentAddOns(res.data)
            })
    }


    // Get Class Selections ----------------------------------------------------------------------------------

    const getStudentClassDropDown = async (val) => {
        await getStudentClassSection(val)
            .then(res => {
                console.log(res)
                setStudentClassSectionDropdown(res.data)
            })
            .catch(e => {

            })
    }

    // Get Subject Selections if Irregular -----------------------------------------------------------------------

    const getStudentSubjectDropDown = async (val) => {
        await getStudentSubjectSelect(val)
            .then(res => {
                setStudentSubjectDropdown(res.data)
            })
            .catch(e => {

            })
    }

    // Get Student Registered Subjects ----------------------------------------------------------------------------

    const getStudentSubjects = async (val) => {
        await getStudentAstraSubjects(val)
            .then(res => {
                console.log(res.data)
                let arr = []
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push(res.data[i].DetailId)
                    }


                }
                console.log(arr)
                setAstraStudentEnrollmentSubjects(arr)
            })
            .catch(e => {

            })
    }

    // Get Student Subject Schedules -------------------------------------------


    const getStudentSubjectSchedule = async (val) => {
        setStudentSubjectScheduleDetail([])
        setStudentSubjectScheduleDetailPending([])
        await getAssessmentStudentSubjectScheduleDetail(val)
            .then(res => {
                console.log(res.data)
                if (isSyncing == true && res.data.data.length == 0) {
                    executeGetSubjectScheduleBatch({ subjectScheduleId: syncData[0].SubjectSchedUsed })
                } else {
                    setStudentTotalUnits(res.data.totalUnits)
                    setStudentTotalCreditUnits(res.data.totalCreditUnits)
                    setStudentTotalLabModes(res.data.labModes)
                    setSubjectIds(res.data.subjectIds)
                    setSubjectScheduleDetailsIds(res.data.subjectScheduleDetailId)
                    setSubjectCodes(res.data.subjectCodes)
                    setSubSchedYearLevel(res.data.yearLevels)
                    setStudentSubjectScheduleDetail(res.data.data)
                    setIsFullCapacity(res.data.isFullCapacity)
                    setListOfFullClassCodes(res.data.fullClassCode)
                    setStudentSubjectScheduleDetailPending(res.data.pendingSubSched)
                    setLabSubjects(res.data.labSubjects)

                    if (res.data.subjectCategoryIds.length > 0) {
                        setSubjectCategoryIds(res.data.subjectCategoryIds)
                    } else {
                        setSubjectCategoryIds([1])
                    }
                }

            })
            .catch(e => {

            })
    }

    const getStudentIregSubjectSchedule = async (val) => {
        setStudentSubjectScheduleDetail([])
        setStudentSubjectScheduleDetailPending([])
        await getAssessmentStudentIregSubjectScheduleDetail(val)
            .then(res => {

                console.log(res.data)
                setStudentTotalUnits(res.data.totalUnits)
                setStudentTotalCreditUnits(res.data.totalCreditUnits)
                setStudentTotalLabModes(res.data.labModes)
                setSubjectIds(res.data.subjectIds)
                setSubjectScheduleDetailsIds(res.data.subjectScheduleDetailId)
                setSubjectCodes(res.data.subjectCodes)
                setStudentSubjectScheduleDetail(res.data.data)
                setIsFullCapacity(res.data.isFullCapacity)
                setListOfFullClassCodes(res.data.fullClassCode)
                setStudentSubjectScheduleDetailPending(res.data.pendingSubSched)
                setLabSubjects(res.data.labSubjects)

                if (res.data.subjectCategoryIds.length > 0) {
                    setSubjectCategoryIds(res.data.subjectCategoryIds)

                } else {
                    setSubjectCategoryIds([1])
                }
            })
            .catch(e => {

            })
    }


    // Get Student Assessment Fees ------------------------------------------------

    const getStudentFees = async (val) => {
        setStudentAssessmentFees([])
        setIsAssessmentLoading(true)
        await getStudentAssessmentTemplateDetail(val)
            .then(res => {
                setIsAssessmentLoading(false)

                console.log(res.data.data)
                setStudentAssessmentFees(res.data.data)
                setTotalAssessmentFee(res.data.totalFee)
                setTotalInterest(res.data.totalInterest)
                setTotalLabFee(res.data.totalLabFee)
                setTotalTuitionFee(res.data.totalTuitionFee)
                setTotalMiscFee(res.data.totalMiscFee)
                setTotalRegFee(res.data.totalRegFee)
                setSelectedAssessmentTemplateId(res.data.data[0].AssessmentTemplateId)
                console.log(res.data.data[0].AssessmentTemplateId)
                getStudentOtherFees({
                    assessmentTemplateId: res.data.data[0].AssessmentTemplateId.toString(),
                    yearLevelId: studentYearLevel !== null ? studentYearLevel : studentAccount[0].YearLevelId,
                    courseId: studentAccount[0].CourseId,
                    instituteId: studentAccount[0].InstituteId,
                    isCampus: false,
                    isMale: studentAccount[0].Gender == 'M' ? true : false
                })
                getAvailablePaymentSchedule({
                    assessmentTemplateId: res.data.data[0].AssessmentTemplateId,
                    tuitionFee: res.data.totalTuitionFee,
                    regFee: res.data.totalRegFee,
                    miscFee: res.data.totalMiscFee,
                    labFee: res.data.totalLabFee,
                    discount: studentTotalDiscount,
                    interest: res.data.totalInterest,
                    studentId: studentAccount[0].StudentId,
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId
                })

                getAvailablePaymentScheduleNoDiscount({
                    assessmentTemplateId: res.data.data[0].AssessmentTemplateId,
                    tuitionFee: res.data.totalTuitionFee,
                    regFee: res.data.totalRegFee,
                    miscFee: res.data.totalMiscFee,
                    labFee: res.data.totalLabFee,
                    discount: 0,
                    interest: res.data.totalInterest,
                    studentId: studentAccount[0].StudentId,
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId
                })

            })
            .catch(e => {

            })
    }


    const getStudentAssessmentIds = async (val) => {
        await getStudentAssessmentTemplateId(val)
            .then(res => {
                console.log(res)
                let arr = []
                for (let i = 0; i < res.data.data.length; i++) {
                    arr.push(res.data.data[i].AssessmentTemplateId)
                }
                setStudentAssessmentId(arr)
            })
            .catch(e => {

            })
    }

    // Get Student Assessment Fees Depends on PaymentMode selected by the student-----------------------------------------------

    const getStudentAssessmentList = async (val) => {
        await getAccountingStudentListOfAssessment(val)
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {

                    setSelectedAssessmentTemplateId(res.data[0].AssessmentTemplateId)
                    getStudentFees({
                        assessmentTemplateId: res.data[0].AssessmentTemplateId.toString(),
                        discountRate: studentAccount[0].ApplyRate !== undefined && studentAccount[0].ApplyRate !== null ? studentAccount[0].ApplyRate : 0.00,
                        totalUnits: studentTotalUnits,
                        discountApplyTo: studentAccount[0].ApplyTo !== undefined && studentAccount[0].ApplyTo !== null ? studentAccount[0].ApplyTo : 'NA',
                        labMode: studentTotalLabModes,
                        subjectCategories: subjectCategoryIds.toString(),
                        isSyncToCampus: false,
                        labSubjects: labSubjects,
                        studentSchoolYear: studentAccount[0].StudentBatchYear
                    })

                } else {
                    setStudentAssessmentFees([])
                }

            })
            .catch(e => {

            })
    }


    // Get Student Other Fees ------------------------------------------------------------------------------------
    const getStudentOtherFees = async (val) => {
        await getStudentAssessmentOtherFees(val)
            .then(res => {
                if (res.data.data.length > 0) {
                    setStudentOtherFees(res.data.data)
                    setTotalOtherFee(res.data.totalOtherFee)
                }

            })
            .catch(e => {

            })
    }


    // Student Discounts ---------------------------------------------------------------------------------------------
    const getStudentDiscountOnAstra = async (val) => {
        await getStudentDiscount(val)
            .then(res => {
                console.log(res.data)
                setStudentDiscount(res.data)
                // if (res.totalDiscount !== null)
                //     setStudentTotalDiscount(res.totalDiscount)
            })
            .catch(e => {

            })
    }


    // Handlers -------------------------------------------------------------------------------------------------------------------------

    const onIrregularHandler = (data) => {
        setIsRegular(!data)
    }

    const onClassSectionHandler = (ssdId) => {
        setSelectedClassSectionId(ssdId)
        getStudentYearLevelId({ subjectScheduleDetailId: ssdId })
    }


    const onChangeStudentYearLevel = (data) => {
        setStudentYearLevel(data)
        getStudentAssessmentList({
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,
            paymentModeId: 1,
            instituteId: studentAccount[0].InstituteId,
            yearLevelId: data,
            isIrregular: isRegular == true ? 0 : 1

        })
    }

    const onPaymentModeHandler = async (data) => {
        setSelectedPaymentModeId(data)
        await getStudentAssessmentList({
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,
            paymentModeId: data,
            instituteId: studentAccount[0].InstituteId,
            yearLevelId: selectedYearLevelId,
            isIrregular: isRegular == true ? 0 : 1

        })
    }

    const onSelectedSubjectSchedDetails = (data) => {
        // alert('test')
        console.log(data)
        if (data !== null)
            setSelectedSubSchedDetailId(data)
    }

    const assessStudentTotalUnits = async (val) => {
        await getAssessmentStudentTotalUnits(val)
            .then(res => {

                console.log(res.data)
                if (res.data[0].TotalUnits !== null) {
                    setStudentTotalUnits(res.data[0].TotalUnits)

                } else {
                    setStudentTotalUnits(1)

                }
            })
            .catch(e => {

            })
    }

    const onAddIrregularSubject = (subSchedDetailId) => {
        onSubjectInCurriculum({
            courseId: studentAccount[0].CourseId,
            subjectScheduleDetailId: subSchedDetailId
        }, subSchedDetailId)


    }

    const onSubjectInCurriculum = async (data, subSchedDetailId) => {
        await checkCurriculum(data)
            .then(res => {
                console.log(res)
                if (res.data[0].TotalRecord === 0) {

                    setIsOnCurriculum(false)
                    setCurrentSelectedSub(subSchedDetailId)
                    setShowDialog({ id: 1, record: ``, status: null, data: res.data[0] })

                } else {

                    setIsOnCurriculum(true)
                    setSelectIregSubjects([...selectIregSubjects, subSchedDetailId])

                    // onAddIregSub(data.subjectScheduleDetailId)

                }
            })
            .catch(e => {
                console.log(e)
            })
    }

    const rejectOutsideCurriculum = () => {
        setIsOnCurriculum(null)

    }

    const continueInCurriculum = () => {
        setIsOnCurriculum(true)
        setSelectIregSubjects([...selectIregSubjects, currentSelectedIregSub])

    }


    // on Save and Update ---------------------------------------------------------------------------------------------------------------------------------------------
    const onUpdateEnrollment = async (id, values) => {
        await updateEnrollment(id, values)
            .then(response => {
                return true
            })
            .catch(error => {
                return false
            })
    }

    // save student enrollment subjects 
    const onCreateStudentEnrollmentSub = async (values) => {
        await addUpdateStudentEnrollmentSubject(values)
            .then(response => {
                return true
            })
            .catch(error => {
                return false
            })
    }
    // delete student enrollment subjects \
    const onDeleteStudentEnrollmentSub = async (val) => {
        await deleteStudentEnrollmentSubjects(val)
            .then(response => {
                return true
            })
            .catch(error => {
                return false
            })
    }
    // create student enrollment data 

    const onCreateEnrollment = async (values) => {
        await createEnrollment(values)
            .then(response => {

                getLatestEnrollmentAccount({
                    schoolYear: status != undefined && status.isIntent == true ? status.schoolYear : studentAccount[0].SchoolYear,
                    semesterId: status != undefined && status.isIntent == true ? status.semesterId : studentAccount[0].SemesterId,
                    studentId: status != undefined && status.isIntent == true ? status.studentId : studentAccount[0].StudentId
                })

            })
            .catch(error => {
                getLatestEnrollmentAccount({
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId,
                    studentId: studentAccount[0].StudentId
                })
            })
    }

    // on Calculate Student Needs ------------------------------------------------------------------------------------------------------------------------------------------
    // Get Student Enrollment Subjects
    const studentEnrollmentSubjects = async (val) => {
        await getStudentEnrollmentSubjects(val)
            .then(res => {
                console.log(res)
                setStudentEnrollmentSubject(res.data)
            })
            .catch(e => {

            })
    }

    //onSubmits
    // Submit Assessment
    const onSubmitAssessment = async () => {
        setOnLoadingSubmition(true)
        let enrollmentValues = {
            assessmentTemplateId: selectedAssessmentTemplateId,
            studentId: studentAccount[0].StudentId,
            assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            enrollmentStatusId: 'A',
            paymentModeId: selectedPaymentModeId,
            classSectionId: selectedClassSectionId,
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,
            instituteId: studentAccount[0].InstituteId,
            yearLevelId: isRegular == true ? selectedYearLevelId : studentYearLevel,
            isIrregular: isSyncing == false ? isRegular == true ? 0 : 1 : selectedClassSectionId !== null ? 0 : 1
        }

        await onDeleteStudentEnrollmentSub({
            studentId: studentAccount[0].StudentId,
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId
        })
        // update enrollment
        await onUpdateEnrollment(studentAccount[0].EnrollmentId, enrollmentValues)
            .then(async resp => {
                if ((status != undefined && status.isIntent == true) || studentAccount[0].EnrollmentStatusId == 'IE') {
                    await sendMail({
                        emailAddress: studentAccount[0].EmailAddress,
                        isAssessmentVerified: 1
                    })
                } else {
                    return
                }

            })
        // await assignStudentCurriculum({
        //     enrollmentId: studentAccount[0].EnrollmentId,
        //     schoolYear: studentAccount[0].SchoolYear,
        //     semesterId: studentAccount[0].SemesterId,
        //     courseId: studentAccount[0].CourseId,
        //     createdBy: auth.data.Username
        // })

        // save subjects
        for (let i = 0; i < studentEnrollmentSubject.length; i++) {
            await onCreateStudentEnrollmentSub({
                ...studentEnrollmentSubject[i],
                studentEnrollmentSubjectId: 0,
                subjectStatusId: 1,
                enrollmentStatus: 1,
                isPending: 0,
                isValidated: 1

            })
        }

        history.push('/admin/student')
        setTimeout(() => {
            history.push(`${admissionsTypes.LIST_LINK}${id}`)
        }, 50);
    }

    const onCreateAssessment = async () => {
        if (isFullCapacity == false) {
            setOnLoadingSubmition(true)
            let enrollmentValues = {
                assessmentTemplateId: selectedAssessmentTemplateId,
                studentId: studentAccount[0].StudentId,
                assessmentDate: status != undefined && status.isIntent == true ? null : dateTime,
                // enrollmentDate: dateTime,
                enrollmentStatusId: status != undefined && status.isIntent == true ? 'IE' : 'A',
                paymentModeId: selectedPaymentModeId,
                classSectionId: selectedClassSectionId,
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId,
                courseId: studentAccount[0].CourseId,
                instituteId: studentAccount[0].InstituteId,
                yearLevelId: isRegular == true ? selectedYearLevelId : studentYearLevel,
                isIrregular: selectedClassSectionId == null ? 1 : 0,
                studentNo: studentAccount[0].StudentNo,
                isIntent: status != undefined && status.isIntent == true ? 1 : 0,
                isAcceptGrant: status != undefined && status.isIntent == true && isWithDiscount == true ? 1 : 0,
            }
            // delete subject
            await onDeleteStudentEnrollmentSub({
                studentId: studentAccount[0].StudentId,
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId
            })
            // save subjects
            for (let i = 0; i < studentEnrollmentSubject.length; i++) {
                await onCreateStudentEnrollmentSub({
                    ...studentEnrollmentSubject[i],
                    studentEnrollmentSubjectId: 0,
                    subjectStatusId: 1,
                    enrollmentStatus: 1,
                    isPending: 0,
                    isValidated: 1
                })
            }
            //CREATE enrollment
            //onCreateUpdateEnrollment(enrollmentValues)
            if (studentAccount[0].LatestSchoolYearEnrolled == status.schoolYear && studentAccount[0].LatestSemesterEnrolled == status.semesterId) {
                await onUpdateEnrollment(studentAccount[0].LatestEnrollmentId, enrollmentValues)
                    .then(async respond => {
                        await getLatestEnrollmentAccount({
                            schoolYear: status != undefined && status.isIntent == true ? status.schoolYear : studentAccount[0].SchoolYear,
                            semesterId: status != undefined && status.isIntent == true ? status.semesterId : studentAccount[0].SemesterId,
                            studentId: status != undefined && status.isIntent == true ? status.studentId : studentAccount[0].StudentId
                        })
                    })
            } else {

                onCreateEnrollment(enrollmentValues)
                    .then(async resp => {
                        if (status != undefined && status.isIntent == true) {
                            await sendMail({
                                isForAssessment: 1,
                                emailAddress: studentAccount[0].EmailAddress,
                            })
                        } else {
                            return
                        }

                    })

            }
        } else {
            setIsOpenFullCapacity(true)
        }



    }

    const onSubmitSyncData = async () => {
        let enrollmentValues = {
            assessmentTemplateId: selectedAssessmentTemplateId,
            studentId: studentAccount[0].StudentId,
            assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            enrollmentStatusId: 'E',
            paymentModeId: selectedPaymentModeId,
            classSectionId: selectedClassSectionId,
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,
            instituteId: studentAccount[0].InstituteId,
            yearLevelId: isRegular == true ? studentYearLevel !== null ? studentYearLevel : studentAccount[0].YearLevelId : studentYearLevel,
            isIrregular: isSyncing == false ? isRegular == true ? 0 : 1 : selectedClassSectionId !== null ? 0 : 1,
            studentNo: studentAccount[0].StudentNo,
            isCampus: 1
        }
        console.log(enrollmentValues)
        console.log(studentEnrollmentSubject)
        console.log(selectedAssessmentTemplateId)


        if (studentAccount[0].EnrollmentId !== undefined) {
            // update enrollment
            await onUpdateEnrollment(studentAccount[0].EnrollmentId, enrollmentValues)
            // save subjects
            for (let i = 0; i < studentEnrollmentSubject.length; i++) {
                await onCreateStudentEnrollmentSub({
                    ...studentEnrollmentSubject[i],
                    classSectionId: selectedClassSectionId,
                })
            }
            history.push('/admin/student')
            setTimeout(() => {
                history.push(`${admissionsTypes.LIST_LINK}${id}`)
            }, 50);
        } else {
            for (let i = 0; i < studentEnrollmentSubject.length; i++) {
                await onCreateStudentEnrollmentSub({
                    ...studentEnrollmentSubject[i],
                    classSectionId: selectedClassSectionId,
                })
            }
            await onCreateEnrollment(enrollmentValues)

        }




    }
    // Withraw Assessment
    const onWithraw = () => {
        onUpdateEnrollment(studentAccount[0].EnrollmentId, { enrollmentStatusId: 'W' })

    }

    // Delete Student Assessment
    const onDeleteStudentAssessment = async () => {
        setOnLoadingSubmition(true)
        let enrollmentValues = {
            enrollmentStatusId: 'DR',
            paymentModeId: null,
            assessmentTemplateId: null,
            classSectionId: null

        }

        await onUpdateEnrollment(studentAccount[0].EnrollmentId, enrollmentValues)
            .then(async res => {
                await onDeleteStudentEnrollmentSub({
                    studentId: studentAccount[0].StudentId,
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId
                })
                    .then(() => {
                        history.push('/admin/student')
                        setTimeout(() => {
                            history.push(`${admissionsTypes.LIST_LINK}${id}`)
                        }, 50);
                    })
            })


    }

    // Widrawn Student Assessment

    const onWidrawnStudentAssessment = async () => {
        setOnLoadingSubmition(true)
        let enrollmentValues = {
            enrollmentStatusId: 'W',
            paymentModeId: null,
            assessmentTemplateId: null,
            classSectionId: null

        }

        await onUpdateEnrollment(studentAccount[0].EnrollmentId, enrollmentValues)
            .then(async res => {
                await onDeleteStudentEnrollmentSub({
                    studentId: studentAccount[0].StudentId,
                    schoolYear: studentAccount[0].SchoolYear,
                    semesterId: studentAccount[0].SemesterId
                }).then(() => {
                    history.push('/admin/student')
                    setTimeout(() => {
                        history.push(`${admissionsTypes.LIST_LINK}${id}`)
                    }, 100);
                })
            })



    }

    // Sync to Campus --------------------------------------------------------------------------------------------------------
    const onSyncToCampus = () => {
        const execute = async (data) => {
            await getCampusEnrollment(data)
                .then(res => {
                    setIsSyncing(true)
                    setSyncData(res.data)
                })
                .catch(e => {
                    console.log(e)
                })

        }
        execute({ schoolYear: studentAccount[0].SchoolYear, semesterId: studentAccount[0].SemesterId, studentNo: studentAccount[0].StudentNo })
    }


    const getStudentCampusBatchId = async (val) => {
        await getCampusBatchkey(val)
            .then(res => {
                let arr = []
                for (let i = 0; i < res.data.length; i++) {
                    arr.push(res.data[i].Batchkey)
                }
                setStudentBatchkey(arr)
            })
            .catch(e => {

            })
    }

    const getAvailablePaymentSchedule = async (val) => {
        await getPaymentSchedule(val)
            .then(res => {
                setPaymentSchedule(res.data)
            })
            .catch(e => {

            })
    }

    const getAvailablePaymentScheduleNoDiscount = async (val) => {
        await getPaymentSchedule(val)
            .then(res => {
                setPaymentScheduleNoDiscount(res.data)
            })
            .catch(e => {

            })
    }

    const getPayment = async (val) => {
        await getStudentPayment(val)
            .then(res => {
                if (res.data.length > 0)
                    setStudentPayment(res.data[0].Payment)
            })
            .catch(e => {

            })
    }


    const executeGetSubjectScheduleBatch = async (data) => {
        await getSubjectScheduleBatch(data)
            .then(async res => {
                setCampusSubjectScheduleBatch(res.data)
                await saveSubjectScheduleBatch(res.data)
                    .then(batch => {
                        setReRunSync(!reRunSync)
                    })
                    .catch(e => {

                    })
            })
            .catch(e => {

            })
    }




    useEffect(() => {
        if (status != null && status != undefined) {
            if ((status.isNewStudent != 1 && status.isIntent != 1) || status.isCom == 1) {
                if (auth.data.UserType == 'S' && (status == undefined || status == null)) {
                    history.push('/admin/dashboard')
                } else {
                    getStudentAccount({ enrollmentId: parseInt(id) })
                }
            } else {

                getStudentNewAccount({ studentId: parseInt(id), semesterId: status.semesterId, schoolYear: status.schoolYear })
            }
        } else {

            getStudentAccount({ enrollmentId: parseInt(id) })

        }

        // SelectCreator(getPaymentMode, setPaymentModeSelect, 'PaymentModeId', 'PaymentMode')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')

    }, [])

    useEffect(() => {
        if (!studentAccount)
            return


        if (studentAccount[0].ClassSectionId !== null && studentAccount[0].ClassSectionId !== undefined) {
            setSelectedClassSectionId(studentAccount[0].ClassSectionId)
        }
        if ((status != undefined || status != null) && studentAccount[0].EnrollmentStatusId != 'E' && studentAccount[0].EnrollmentStatusId != 'A' && studentAccount[0].EnrollmentStatusId != 'PV') {
            setStudentYearLevel(status.nextYearLevelId)

        } else {
            setStudentYearLevel(studentAccount[0].YearLevelId)

        }

        getStudentClassDropDown({
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            courseId: studentAccount[0].CourseId,
            instituteId: studentAccount[0].InstituteId
        })

        getStudentSubjectDropDown({
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear
        })

        getStudentSubjects({
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear
        })

        getStudentCampusBatchId({
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            studentNo: studentAccount[0].StudentNo
        })


        getStudentAdds({
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            yearLevelId: studentAccount[0].YearLevelId,
            courseId: studentAccount[0].CourseId
        })

        getPayment({
            studentId: studentAccount[0].StudentId,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear
        })


        if (studentAccount[0].IsIrregular == 1) {
            setIsRegular(false)
        } else {
            setIsRegular(true)

        }




    }, [studentAccount])


    useEffect(() => {
        if (astraStudentEnrollmentSubjects !== null) {
            console.log(isRegular)
            if (!studentAccount)
                return
            if (isRegular == true) {
                getStudentSubjectSchedule({
                    classSectionId: studentAccount[0].EnrollmentStatusId == 'DR' || studentAccount[0].EnrollmentStatusId == 'W' || studentAccount[0].EnrollmentStatusId == 'IE' || studentAccount[0].EnrollmentStatusId == undefined ? selectedClassSectionId : studentAccount[0].ClassSectionId,
                    semesterId: studentAccount[0].SemesterId,
                    schoolYear: studentAccount[0].SchoolYear,
                    isCampus: false,
                    batchId: studentBatchkey.toString(),
                    isExist: astraStudentEnrollmentSubjects !== null && studentAccount[0].EnrollmentStatusId !== undefined && studentAccount[0].EnrollmentStatusId !== 'DR' && studentAccount[0].EnrollmentStatusId !== 'IE' && studentAccount[0].EnrollmentStatusId !== 'W' ? true : false,
                    subjectScheduleDetailId: astraStudentEnrollmentSubjects !== null && astraStudentEnrollmentSubjects.length > 0 && studentAccount[0].EnrollmentStatusId != 'IE' ? astraStudentEnrollmentSubjects.toString() : '',
                    studentId: studentAccount[0].StudentId
                })
            } else {

                if (studentAccount[0].EnrollmentStatusId == 'DR' || studentAccount[0].EnrollmentStatusId == 'W') {

                    getStudentIregSubjectSchedule({
                        subjectScheduleDetailId: selectIregSubjects.toString()
                    })
                } else {
                    console.log(selectIregSubjects)
                    console.log(astraStudentEnrollmentSubjects)
                    getStudentSubjectSchedule({
                        classSectionId: studentAccount[0].EnrollmentStatusId == 'DR' || studentAccount[0].EnrollmentStatusId == 'W' || studentAccount[0].EnrollmentStatusId == undefined ? selectedClassSectionId : studentAccount[0].ClassSectionId,
                        semesterId: studentAccount[0].SemesterId,
                        schoolYear: studentAccount[0].SchoolYear,
                        isCampus: false,
                        batchId: studentBatchkey.toString(),
                        isExist: true,
                        subjectScheduleDetailId: selectIregSubjects !== null && selectIregSubjects.length > 0 ? selectIregSubjects.toString() : astraStudentEnrollmentSubjects.toString(),
                        studentId: studentAccount[0].StudentId

                    })
                }

            }
        }

    }, [isRegular, selectIregSubjects, astraStudentEnrollmentSubjects, selectedClassSectionId])




    useEffect(() => {
        if (studentTotalLabModes !== null && studentTotalUnits !== null && subjectCategoryIds !== null) {
            console.log(studentTotalUnits)
            console.log(studentAssessmentId)
            console.log(selectedAssessmentTemplateId)

            getStudentFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId !== null && studentAccount[0].AssessmentTemplateId !== undefined ? studentAccount[0].AssessmentTemplateId.toString() : selectedAssessmentTemplateId.toString(),
                discountRate: studentAccount[0].ApplyRate !== undefined && studentAccount[0].ApplyRate !== null ? studentAccount[0].ApplyRate : 0.00,
                totalUnits: studentTotalUnits,
                discountApplyTo: studentAccount[0].ApplyTo !== undefined && studentAccount[0].ApplyTo !== null ? studentAccount[0].ApplyTo : 'NA',
                labMode: studentTotalLabModes,
                subjectCategories: subjectCategoryIds.toString(),
                isSyncToCampus: false,
                labSubjects: labSubjects,
                studentSchoolYear: studentAccount[0].StudentBatchYear

            })





        }

    }, [studentTotalUnits])


    useEffect(() => {
        if (studentAccount !== null && selectedSubSchedDetailId.length > 0)
            studentEnrollmentSubjects({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                createdBy: auth.data.Username,
                subjectScheduleDetailId: selectedSubSchedDetailId.toString()

            })

        assessStudentTotalUnits({ selectedSubjectCode: selectedSubSchedDetailId.toString() })

    }, [selectedSubSchedDetailId])



    // useEffect(() => {
    //     if (studentDiscount.length > 0) {
    //         let total = 0
    //         for (let i = 0; i < studentDiscount.length; i++) {
    //             total += parseFloat(studentDiscount[i].Discount)

    //         }
    //         // setTotalDiscount(total)
    //     }
    // }, [studentDiscount])




    // SYNCING FROM CAMPUS THIS CODE IS TEMPORARY, REMOVE WHEN ALL PROCESS DEPENDS ON ASTRA COLLEGE --------------------------------

    // useEffect(() => {
    //     if (syncData !== null) {
    //         if (syncData.length > 0) {
    //             let paymentMode = 1
    //             if (syncData[0].PaymentMode == 1) {
    //                 paymentMode = 2
    //             } else if (syncData[0].PaymentMode == 2) {
    //                 paymentMode = 3
    //             } else if (syncData[0].PaymentMode == 3) {
    //                 paymentMode = 1

    //             } else if (syncData[0].PaymentMode == 4) {
    //                 paymentMode = 4

    //             }

    //             console.log(syncData)
    //             let campusSubSchedVal = {
    //                 classSectionId: 0,
    //                 semesterId: 0,
    //                 schoolYear: 0,
    //                 isCampus: true,
    //                 batchId: studentBatchkey.toString(),
    //                 isExist: false,
    //                 subjectScheduleDetailId: ''
    //             }
    //             const exe = async () => {
    //                 await getStudentSubjectSchedule(campusSubSchedVal)
    //                 await onPaymentModeHandler(paymentMode)
    //             }

    //             exe()
    //         } else {
    //             setIsSyncing(false)
    //             dispatch({
    //                 type: componentTypes.SHOW_SNACKBAR,
    //                 snackBarStatus: 'error',
    //                 snackBarDuration: 5000,
    //                 snackBarMessage: 'No Record Found in Campus++'
    //             });
    //         }


    //     }
    // }, [syncData, reRunSync])


    useEffect(() => {
        if (isSyncing === true && selectedAssessmentTemplateId !== null && studentEnrollmentSubject !== undefined) {
            onSubmitSyncData()
        }
    }, [selectedAssessmentTemplateId, studentEnrollmentSubject])


    // useEffect(() => {
    //     if (studentAccount !== null && studentBatchkey.length > 0)
    //         if (studentAccount[0].IsCampus == 1)
    //             getStudentDiscountOnCampus({
    //                 batchId: studentBatchkey
    //             })
    // }, [studentBatchkey])


    useEffect(() => {
        if (studentAccount !== null && subSchedYearLevel !== null) {
            getStudentAssessmentIds({
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                yearLevelId: subSchedYearLevel.toString(),
                paymentModeId: studentAccount[0].EnrollmentStatusId == 'DR' || studentAccount[0].EnrollmentStatusId == 'W' ? selectedPaymentModeId : studentAccount[0].PaymentModeId,
                isIrregular: studentAccount[0].EnrollmentStatusId == 'DR' || studentAccount[0].EnrollmentStatusId == 'W' ? isRegular == true ? 0 : 1 : studentAccount[0].IsIrregular == 0 ? 1 : 0
            })
        }
    }, [studentAccount, selectedPaymentModeId, subSchedYearLevel, isRegular])


    useEffect(() => {
        if (studentAssessmentFees.length > 0 && studentAssessmentFees !== null && studentAssessmentFees !== undefined
            && totalOtherFee !== null && totalOtherFee !== undefined) {
            let tuitionFee = studentAssessmentFees.find(data => data.FeeCode == 'TF') || { TotalAmount: 0 }
            let registrationFee = studentAssessmentFees.find(data => data.FeeCode == 'RG') || { TotalAmount: 0 }
            let miscFee = studentAssessmentFees.find(data => data.FeeCode == 'MS') || { TotalAmount: 0 }
            let labFee = studentAssessmentFees.find(data => data.FeeCode == 'LB') || { TotalAmount: 0 }
            let otherFee = studentAssessmentFees.find(data => data.FeeCode == 'OT') || { TotalAmount: 0 }


            console.log(tuitionFee.TotalAmount)
            console.log(registrationFee.TotalAmount)
            console.log(miscFee.TotalAmount)
            console.log(labFee.TotalAmount)
            console.log(totalOtherFee)

            getStudentDiscountOnAstra({
                studentId: studentAccount[0].StudentId,
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId !== null && studentAccount[0].AssessmentTemplateId !== undefined ? studentAccount[0].AssessmentTemplateId.toString() : selectedAssessmentTemplateId.toString(),
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId,
                tuitionFee: tuitionFee.TotalAmount,
                regFee: registrationFee.TotalAmount,
                miscFee: miscFee.TotalAmount,
                labFee: labFee.TotalAmount,
                otherFee: totalOtherFee


            })


        }
    }, [studentAssessmentFees, totalOtherFee])


    useEffect(() => {
        if (studentDiscount !== 0 && studentDiscount.length > 0 && studentDiscountWithSequence.length == 0 && turnOffDiscounting == false) {
            let tuitionFee = studentAssessmentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
            let registrationFee = studentAssessmentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
            let miscFee = studentAssessmentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
            let labFee = studentAssessmentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
            let otherFee = studentAssessmentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
            let otherLabFee = studentAssessmentFees.find(data => data.FeeCode === 'OLB') || { TotalAmount: 0 };
            let totalDiscount = 0.00



            const getStudentDiscountWithParticulars = async (val) => {
                const res = await getStudentDiscountParticulars(val);
                setTotalDiscount(res.totalDiscount)
                return res;
            };
            console.log(labFee.TotalAmount)
            console.log(otherLabFee.TotalAmount)
            console.log(parseFloat(labFee.TotalAmount) - parseFloat(otherLabFee.TotalAmount))
            const updateFees = async () => {
                let arr = [];

                for (let i = 0; i < studentDiscount.length; i++) {
                    const res = await getStudentDiscountWithParticulars({
                        studentId: studentAccount[0].StudentId,
                        assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId.toString(),
                        tuitionFee: tuitionFee.TotalAmount,
                        regFee: registrationFee.TotalAmount,
                        miscFee: miscFee.TotalAmount,
                        labFee: labFee.TotalAmount,
                        otherFee: totalOtherFee,
                        otherLabFee: otherLabFee.TotalAmount,
                        scholarshipId: studentDiscount[i].ScholarshipId
                    });
                    console.log(res.data)
                    if (res.data[0].ScholarshipId != 6383) {
                        setIsWithDiscount(true)
                    }
                    tuitionFee.TotalAmount = res.data[0].RemainingTuition;
                    registrationFee.TotalAmount = res.data[0].RemainingReg;
                    miscFee.TotalAmount = res.data[0].RemainingMisc;
                    labFee.TotalAmount = res.data[0].RemainingLab;
                    console.log(res.data[0]);
                    arr.push({ ...res.data[0], enrollmentId: parseInt(id), enrollmentDiscountId: 0, createdBy: auth.data.Username });
                    totalDiscount += parseFloat(res.totalDiscount)
                }

                console.log(arr)
                console.log(totalDiscount)
                setStudentDiscountWithSequence(arr);
                setTurnOffDiscounting(true)
                setStudentTotalDiscount(totalDiscount)


            };
            if (studentAssessmentFees.length > 0)
                updateFees().then(() => {
                    getAvailablePaymentSchedule({
                        assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId,
                        tuitionFee: studentAssessmentFees.find(data => data.FeeCode === 'TF').FinalAmount,
                        regFee: studentAssessmentFees.find(data => data.FeeCode === 'RG').FinalAmount,
                        miscFee: studentAssessmentFees.find(data => data.FeeCode === 'MS').FinalAmount,
                        labFee: totalLabFee,
                        discount: totalDiscount,
                        interest: totalInterest,
                        studentId: studentAccount[0].StudentId,
                        schoolYear: studentAccount[0].SchoolYear,
                        semesterId: studentAccount[0].SemesterId
                    })
                    getAvailablePaymentScheduleNoDiscount({
                        assessmentTemplateId: studentAssessmentFees[0].AssessmentTemplateId,
                        tuitionFee: studentAssessmentFees.find(data => data.FeeCode === 'TF').FinalAmount,
                        regFee: studentAssessmentFees.find(data => data.FeeCode === 'RG').FinalAmount,
                        miscFee: studentAssessmentFees.find(data => data.FeeCode === 'MS').FinalAmount,
                        labFee: totalLabFee,
                        discount: 0,
                        interest: totalInterest,
                        studentId: studentAccount[0].StudentId,
                        schoolYear: studentAccount[0].SchoolYear,
                        semesterId: studentAccount[0].SemesterId
                    })

                });


        }
    }, [studentDiscount]);


    useEffect(() => {
        if (studentSubjectScheduleDetail !== null) {
            if (studentSubjectScheduleDetail.length > 0) {
                setIsSubjectLoading(false)
            } else if (studentSubjectScheduleDetail.length == 0) {
                setIsSubjectLoading(true)

            }

        }
    }, [studentSubjectScheduleDetail])


    useEffect(() => {
        console.log(studentAccount)
        if (studentAccount != null && studentAccount.length > 0)
            getPaymentModeSelect({
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId,
                courseId: studentAccount[0].CourseId,
                instituteId: studentAccount[0].InstituteId,
                yearLevelId: selectedYearLevelId,
                isIrregular: isRegular == true ? 0 : 1

            }).then(res => {
                setPaymentModeSelect(res.data)
            })
    }, [selectedYearLevelId, studentAccount])

    // useEffect(() => {
    //     if (studentAssessmentFees !== null) {
    //         alert('test1')
    //         if (studentAssessmentFees.length > 0) {
    //             setIsAssessmentLoading(false)
    //         } else if (studentAssessmentFees.length == 0) {
    //             setIsAssessmentLoading(true)

    //         }

    //     }
    // }, [studentAssessmentFees])



    // displays -----------
    console.log(studentAccount)
    console.log(studentSubjectScheduleDetail)
    console.log(studentSubjectScheduleDetailPending)
    console.log(studentAssessmentFees)
    console.log(studentOtherFees)
    console.log(studentDiscount)
    console.log(studentTotalDiscount)

    // utilities
    console.log(studentClassSectionDropdown)
    console.log(studentSubjectDropdown)
    console.log(paymentModeSelect)

    // totals
    console.log(totalAssessmentFee)
    console.log(totalOtherFee)
    console.log(totalInterest)
    console.log(totalDiscount)
    console.log(studentTotalUnits)
    console.log(totalLabFee)
    console.log(totalTuitionFee)

    // logics
    console.log(isRegular)

    // Selected Data
    console.log(selectedAssessmentTemplateId)
    console.log(selectedPaymentModeId)
    console.log(selectedClassSectionId)
    console.log(selectedSubSchedDetailId)
    // to be saved
    console.log(studentEnrollmentSubject)
    console.log(astraStudentEnrollmentSubjects)

    //all about sync
    console.log(studentBatchkey)

    // Misc
    console.log(studentAssessmentId)
    console.log(status)


    console.log(selectIregSubjects)


    console.log(studentDiscountWithSequence)
    console.log(selectedYearLevelId)


    console.log(paymentSchedule)
    console.log(studentAddOns)
    console.log(isSubjectLoading)
    console.log(isAssessmentLoading)


    console.log(subjectIds)
    console.log(studentYearLevel)
    console.log(auth)

    return (

        <>
            {studentAccount !== null && studentSubjectScheduleDetail !== null && studentAssessmentFees !== null
                && studentOtherFees !== null && paymentModeSelect !== null && studentClassSectionDropdown !== null &&
                studentSubjectDropdown !== null && paymentModeSelect !== null
                ?
                <>

                    {isOpenFullCapacity == true &&
                        <FullCapacityModal
                            title={'Class Codes has reached its maximum capacity.'}
                            isOpen={isOpenFullCapacity}
                            handleClose={() => { setIsOpenFullCapacity(false) }}
                            classCodes={listOfFullClassCodes}
                        />

                    }
                    <ReAssessment
                        studentAccount={studentAccount[0]}
                        studentSubjectScheduleDetail={studentSubjectScheduleDetail}
                        studentSubjectScheduleDetailPending={studentSubjectScheduleDetailPending}
                        studentAssessmentFees={studentAssessmentFees}
                        studentOtherFees={studentOtherFees}
                        studentDiscount={studentDiscountWithSequence}
                        studentTotalDiscount={studentTotalDiscount}
                        studentClassSectionDropdown={studentClassSectionDropdown}
                        studentSubjectDropdown={studentSubjectDropdown}
                        paymentModeSelect={paymentModeSelect}
                        totalAssessmentFee={totalAssessmentFee}
                        totalOtherFee={totalOtherFee}
                        totalInterest={totalInterest}
                        totalDiscount={studentTotalDiscount}
                        onIrregularHandler={onIrregularHandler}
                        onClassSectionHandler={onClassSectionHandler}
                        onPaymentModeHandler={onPaymentModeHandler}
                        onSelectedSubjectDetail={onSelectedSubjectSchedDetails}
                        onSubmitAssessment={onSubmitAssessment}
                        onCreateAssessment={onCreateAssessment}
                        onWithraw={onWithraw}
                        onDeleteStudentAssessment={onDeleteStudentAssessment}
                        showDialog={showDialog}
                        setShowDialog={setShowDialog}
                        isOnCurriculum={isOnCurriculum}
                        rejectOutsideCurriculum={rejectOutsideCurriculum}
                        continueInCurriculum={continueInCurriculum}
                        existingSelectedSub={subjectIds}
                        existingSubSchedDetailId={subjectSchedDetailIds}
                        selectedSubjectCodes={subjectCodes}
                        onAddIrregularSubject={onAddIrregularSubject}
                        dateTime={dateTime}
                        totalLabFee={totalLabFee}
                        studentTotalLabModes={studentTotalLabModes}
                        totalTuitionFee={totalTuitionFee}
                        totalRegFee={totalRegFee}
                        totalMiscFee={totalMiscFee}
                        studentTotalUnits={studentTotalUnits}
                        studentTotalCreditUnits={studentTotalCreditUnits}
                        isSyncing={isSyncing}
                        onSyncToCampus={onSyncToCampus}
                        studentAddOns={studentAddOns}
                        totalAddOns={totalAddOns}
                        paymentSchedule={paymentSchedule}
                        paymentScheduleNoDiscount={paymentScheduleNoDiscount}
                        studentPayment={studentPayment}
                        isSubjectLoading={isSubjectLoading}
                        isAssessmentLoading={isAssessmentLoading}
                        onLoadingSubmition={onLoadingSubmition}
                        onWidrawnStudentAssessment={onWidrawnStudentAssessment}
                        onChangeStudentYearLevel={onChangeStudentYearLevel}
                        studentYearLevel={studentYearLevel}
                        yearLevelSelect={yearLevelSelect}
                        status={status}
                        isWithDiscount={isWithDiscount}
                        isFullCapacity={isFullCapacity}
                    />
                </>


                :


                <Loading />

            }



        </>





    )
}