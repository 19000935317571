import instance from "../../../../apis/local/systemAPI";

export const getUserInRole = async () => {
    const result = await instance.get("api/role/user-in-role");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollment = async () => {
    const result = await instance.get("api/enrollments/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getUser = async () => {
    const result = await instance.get("api/user");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const updateUser = async (id, formValues) => {
    const result = await instance.patch(`api/user/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentAccount = async (val) => {
    const result = await instance.post("api/student/account/account", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentClearance = async (val) => {
    const result = await instance.post("api/students/studentClearance", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const intentToEnroll = async (val) => {
    const result = await instance.post("api/enrollments/intentToEnroll", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const isAllowedToIntent = async (val) => {
    const result = await instance.post("api/enrollments/isAllowedToIntent", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentLiabilities = async (val) => {
    const result = await instance.post("api/enrollments/liabilities", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getImage = async (studentNo) => {
    const result = await instance.get(`/getImage/${studentNo}.jpg`);
    if (result.status === 200 || result.status === 201) {
        console.log(result)
        return `${result.config.baseURL}${result.config.url}`;
    } else {
        return false
    }
}