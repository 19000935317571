import instance from '../../../../../../app/apis/local/systemAPI';

export const verifyCredentials= async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/verifyCredentials`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getLastestPrintingDetails= async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getLastestPrintingDetails`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}