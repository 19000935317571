import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSemester, getSchoolYear, getUser, getFacultyLoadingReport, getFacultyLoadingTableReport, getFacultyLoadingReportBySection,
    getFacultyLoadingReportByRoom, getSubjSchedIds, getRoomIds, getInstructorIds
} from "./__hooks__";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import PrintFacultyLoadingReportBySection from "./PrintFacultyLoadingReportBySection";
import PrintFacultyLoadingReportBySubject from "./PrintFacultyLoadingReportBySubject";
import PrintFacultyLoadingReportByRoom from "./PrintFacultyLoadingReportByRoom";
import PrintFacultyLoadingReportByInstructor from "./PrintFacultyLoadingReportByInstructor";
import PrintSummarySubjectReport from "./PrintSummarySubjectOffered";
import PrintFacultyPayrollUnits from "./PrintFacultyPayrollUnits";
import PrintFacultyLoadingReportBySubjectForPosting from "./PrintFacultyLoadingReportBySubjectForPosting";
import PrintFacultyLoadingReportBySectionForPosting from "./PrintFacultyLoadingReportBySectionForPosting";
import ExportExcel from "../ExportExcel";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";
import { useAppContext } from "../../../../contexts/useAppContext";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import { getInstitute } from "../EnrollmentReports/__hooks__";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

});


export default function EnrollmentScheduleReport(props) {
    const classes = useStyles();
    const { setValue } = useForm()
    const { state: { auth } } = useAppContext()
    const [results, setResults] = useState([])
    const [excelFormat, setExcelFormat] = useState()
    const [resultBySection, setResultBySection] = useState([])
    const [resultByRoom, setResultByRoom] = useState([])
    const [tableResults, setTableResults] = useState([])
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [instituteSelect, setInstituteSelect] = useState(null)
    const [userSelect, setUserSelect] = useState(null)
    const [selectedOption, setSelectedOption] = useState(1);
    const [subjSchedIds, setSubSchedIds] = useState([])
    const [roomIds, setRoomIds] = useState([])
    const [instructorIds, setInstructorIds] = useState([])

    const [filteredSem, setFilteredSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [filteredSy, setFilteredSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)
    const [filteredUser, setFilteredUser] = useState(null)
    const [filteredDepartment, setFilteredDepartment] = useState(null)

    const [onView, setOnView] = useState(false)


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectUserChange = name => event => { setFilteredUser(event) }
    const selectInstituteChange = name => event => { setFilteredDepartment(event) }


    const [isLoading, setIsLoading] = useState(false)


    // functions

    const onGetFacultyReport = async () => {
        setIsLoading(true)
        setOnView(true)
        let reportArray = []
        let excellArray = []
        if (selectedOption == 3) {
            for (let i = 0; i < roomIds.length; i++) {
                await getFacultyLoadingReportBySection({
                    schoolYear: filteredSy,
                    semesterId: filteredSem,
                    employeeId: filteredUser,
                    instituteId: filteredDepartment,
                    withSchoolYear: filteredSy != null ? true : false,
                    withSemesterId: filteredSem != null ? true : false,
                    withEmployeeId: filteredUser != null ? true : false,
                    withInstitute: filteredDepartment != null && filteredDepartment != 90 ? true : false,
                    groupBy: selectedOption,
                    orderBy: selectedOption,
                    subjectScheduleDetailId: roomIds[i].RoomId
                })
                    .then(res => {
                        reportArray.push(...res.data)
                        excellArray.push(...res.excelFormat)


                    })
            }
        } else if (selectedOption == 4 || selectedOption == 6) {
            for (let i = 0; i < instructorIds.length; i++) {
                await getFacultyLoadingReportBySection({
                    schoolYear: filteredSy,
                    semesterId: filteredSem,
                    employeeId: filteredUser,
                    instituteId: filteredDepartment,
                    withSchoolYear: filteredSy != null ? true : false,
                    withSemesterId: filteredSem != null ? true : false,
                    withEmployeeId: filteredUser != null ? true : false,
                    withInstitute: filteredDepartment != null && filteredDepartment != 90 ? true : false,
                    groupBy: selectedOption,
                    orderBy: selectedOption,
                    subjectScheduleDetailId: instructorIds[i].EmployeeId
                })
                    .then(res => {
                        reportArray.push(...res.data)
                        excellArray.push(...res.excelFormat)


                    })
            }
        } else {
            for (let i = 0; i < subjSchedIds.length; i++) {
                await getFacultyLoadingReportBySection({
                    schoolYear: filteredSy,
                    semesterId: filteredSem,
                    employeeId: filteredUser,
                    instituteId: filteredDepartment,
                    withSchoolYear: filteredSy != null ? true : false,
                    withSemesterId: filteredSem != null ? true : false,
                    withEmployeeId: filteredUser != null ? true : false,
                    withInstitute: filteredDepartment != null && filteredDepartment != 90 ? true : false,
                    groupBy: selectedOption,
                    orderBy: selectedOption,
                    subjectScheduleDetailId: (selectedOption == 1 || selectedOption == 8) ? subjSchedIds[i].ClassSectionId :
                        (selectedOption == 2 || selectedOption == 7) ? subjSchedIds[i].SubjectId : subjSchedIds[i].SubjectScheduleDetailId
                })
                    .then(res => {
                        reportArray.push(...res.data)
                        excellArray.push(...res.excelFormat)


                    })
            }
        }



        setExcelFormat(excellArray)
        setResultBySection(reportArray)
        // setTableResults(res.tableData)




        // await getFacultyLoadingReportByRoom({
        //     schoolYear: filteredSy,
        //     semesterId: filteredSem
        // })
        //     .then(res => {
        //         setResultByRoom(res.data)
        //     })



        setIsLoading(false)

    }


    const handleOptionChange = (event) => {
        setSelectedOption(event);
    };

    const onReset = () => {
        setResults([])
        setResultBySection([])
        setResultByRoom([])
        setTableResults([])
        setFilteredSem(auth.data.schoolyears.enrollment.SemesterId)
        setFilteredSy(auth.data.schoolyears.enrollment.SchoolYear)
        setFilteredUser(null)
        setFilteredDepartment(null)
        setOnView(false)
    }

    useEffect(() => {
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')

        SelectCreator(getUser, setUserSelect, 'EmployeeId', 'FullName')

        getSubjSchedIds({ schoolYear: auth.data.schoolyears.enrollment.SchoolYear, semesterId: auth.data.schoolyears.enrollment.SemesterId })
            .then(resp => {
                setSubSchedIds(resp.data)
            })


    }, [])


    useEffect(() => {
        getSubjSchedIds({ schoolYear: filteredSy, semesterId: filteredSem, groupBy: selectedOption })
            .then(resp => {
                setSubSchedIds(resp.data)
            })

        getRoomIds({ schoolYear: auth.data.schoolyears.enrollment.SchoolYear, semesterId: auth.data.schoolyears.enrollment.SemesterId, instituteId: filteredDepartment })
            .then(resp => {
                setRoomIds(resp.data)
            })

        getInstructorIds({ schoolYear: auth.data.schoolyears.enrollment.SchoolYear, semesterId: auth.data.schoolyears.enrollment.SemesterId, instituteId: filteredDepartment })
            .then(resp => {
                setInstructorIds(resp.data)
            })
    }, [filteredSem, filteredSy, selectedOption, filteredDepartment])





    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect !== null &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        isDisabled={resultBySection.length > 0}
                        selectedId={filteredSy === undefined ? filteredSy === undefined : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemesters = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? filteredSem === undefined : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        isDisabled={resultBySection.length > 0}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownFaculty = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={userSelect}
                        onHandleChange={selectUserChange()}
                        refClassContainer=""
                        name="faculty"
                        isRequired={false}
                        withLabel={false}
                        label="Faculty"
                        placeHolder="Select Employee"
                        forwardRef={setValue}
                        selectedId={filteredUser === undefined ? filteredUser === undefined : filteredUser}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownInstitute = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={instituteSelect}
                        onHandleChange={selectInstituteChange()}
                        refClassContainer=""
                        name="institute"
                        isRequired={false}
                        withLabel={false}
                        label="Department"
                        placeHolder="Department"
                        forwardRef={setValue}
                        isDisabled={resultBySection.length > 0}
                        selectedId={filteredDepartment === undefined ? filteredDepartment === undefined : filteredDepartment}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const columns = [
        { id: 'Institute', label: 'College', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Time', label: 'Time', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', label: 'Day', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'CreditUnit', label: 'Credit Unit', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LecLab', label: 'Lec / Lab', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ClassStatus', label: 'Class Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Capacity', label: 'Capacity', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Instructor', label: 'Instructor', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]





    console.log(results)
    console.log(resultByRoom)
    console.log(resultBySection)
    console.log(subjSchedIds)
    return (
        <>
            {semesterSelect && schoolYearSelect && userSelect && instituteSelect ?

                <>
                    <main className="card">
                        <header className="card-header">
                            <h4 className="d-block">Enrollment Schedule Report</h4>


                            <>
                                <main className="row">
                                    <section className="col">
                                        <div className="row mt-5 mx-auto card bg-secondary p-4">
                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={1}
                                                        checked={selectedOption === 1}
                                                        onChange={() => handleOptionChange(1)}
                                                    />
                                                    &nbsp; Report By Section
                                                </label>
                                            </section>

                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={2}
                                                        checked={selectedOption === 2}
                                                        onChange={() => handleOptionChange(2)}
                                                    />
                                                    &nbsp;  Report By Subject
                                                </label>
                                            </section>

                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={3}
                                                        checked={selectedOption === 3}
                                                        onChange={() => handleOptionChange(3)}
                                                    />
                                                    &nbsp;  Report By Room
                                                </label>
                                            </section>

                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={4}
                                                        checked={selectedOption === 4}
                                                        onChange={() => handleOptionChange(4)}
                                                    />
                                                    &nbsp;  Report By Instructor
                                                </label>
                                            </section>

                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={5}
                                                        checked={selectedOption === 5}
                                                        onChange={() => handleOptionChange(5)}
                                                    />
                                                    &nbsp;  Summary of Subject Offered
                                                </label>
                                            </section>


                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={6}
                                                        checked={selectedOption === 6}
                                                        onChange={() => handleOptionChange(6)}
                                                    />
                                                    &nbsp;  Summary of Faculty Payroll Units
                                                </label>
                                            </section>

                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={7}
                                                        checked={selectedOption === 7}
                                                        onChange={() => handleOptionChange(7)}
                                                    />
                                                    &nbsp;  Report By Subject (For Posting)
                                                </label>
                                            </section>
                                            <section className="mr-5">
                                                <label>
                                                    <input
                                                        disabled={onView}
                                                        type="radio"
                                                        value={8}
                                                        checked={selectedOption === 8}
                                                        onChange={() => handleOptionChange(8)}
                                                    />
                                                    &nbsp;  Report By Section(For Posting)
                                                </label>
                                            </section>

                                        </div>
                                    </section>
                                    <section className="col">
                                        <div className="row mt-5">
                                            <section className="col-12 mt-4">{imSelectDropdownSchoolYear()}</section>
                                            <section className="col-12 mt-4">{imSelectDropdownSemesters()}</section>
                                            <section className="col-12 mt-4">{imSelectDropdownInstitute()}</section>
                                        </div>
                                        <div className="mt-5">
                                            {isLoading == false ?
                                                <>
                                                    {onView == false &&
                                                        <button onClick={() => { onGetFacultyReport() }} className=" btn btn-primary mt-5">Generate</button>
                                                    }
                                                </>
                                                :
                                                ''
                                            }
                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 1 &&
                                                <>
                                                    <PrintFacultyLoadingReportBySection
                                                        results={resultBySection}

                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'ReportBySection'} btnName="Export Report" />
                                                </>


                                            }

                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 2 &&
                                                <>
                                                    <PrintFacultyLoadingReportBySubject
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'ReportBySubject'} btnName="Export Report" />
                                                </>

                                            }


                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 3 &&
                                                <>
                                                    <PrintFacultyLoadingReportByRoom
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'ReportByRoom'} btnName="Export Report" />

                                                </>

                                            }

                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 4 &&
                                                <>
                                                    <PrintFacultyLoadingReportByInstructor
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'ReportByInstructor'} btnName="Export Report" />

                                                </>

                                            }
                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 5 &&
                                                <>
                                                    <PrintSummarySubjectReport
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'SummarySubjectReport'} btnName="Export Report" />
                                                </>

                                            }
                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 6 &&
                                                <>
                                                    <PrintFacultyPayrollUnits
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'FacultyPayrolUnitsReport'} btnName="Export Report" />

                                                </>

                                            }

                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 7 &&
                                                <>
                                                    <PrintFacultyLoadingReportBySubjectForPosting
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'ReportBySubject(ForPosting)'} btnName="Export Report" />

                                                </>

                                            }

                                            {resultBySection.length > 0 && isLoading == false && selectedOption == 8 &&
                                                <>
                                                    <PrintFacultyLoadingReportBySectionForPosting
                                                        results={resultBySection}
                                                    />
                                                    <ExportExcel excelData={excelFormat} fileName={'ReportBySection(ForPosting)'} btnName="Export Report" />

                                                </>

                                            }

                                            {onView == true && isLoading == true &&
                                                <>
                                                    <button className="btn btn-secondary mt-5 btn-sm">
                                                        Please wait ...

                                                        <div className="spinner-border " role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>
                                                    </button>

                                                </>
                                            }



                                            {onView == true && resultBySection.length > 0 && isLoading == false ?
                                                <button onClick={() => { onReset() }} className="ml-5 btn btn-secondary">Reset</button>
                                                :
                                                ''
                                            }
                                        </div>

                                    </section>




                                </main>



                            </>











                        </header>
                        <section className="card-body">


                            {/* <IMTable tableProps={tableProps} /> */}



                        </section>

                    </main>

                </>

                :

                <Loading />
            }

        </>
    )
}