import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getSchoolYear, getGradingPeriod, getStudentGrades, studentAutoComplete, 
    getStudentDetails, getClearanceStatus, getStudentGradeAssessment, gradeAssessmentAutoComplete
} from './__hooks__';
import PrintTOR from "./PrintTOR";
import IMTableGradeViewing from '../../../../../_metronic/layout/components/custom/table/IMTableGradeViewing';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { getSemester } from '../ChangeCourse/__hooks__';
import { getStudentAccount } from "../../_Dashboards/__hooks__"
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import ProfileCover from "../../../../../_metronic/_assets/logo/profileCover.jpg"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

    blueBGColor: {
        backgroundColor:"#b3ebfb !important"
    },

    redBGColor: {
        backgroundColor:"#ff6c6c !important"
    },

    topAndSideBorders: {
        borderBottomStyle:"none",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    sideBordersOnly: {
        borderBottomStyle:"none",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },
    
    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    },

    allSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
    }

});


export default function StudentGradeAssessment() {

    const [gradeAssessment, setGradeAssessment] = useState([]);
    const [curriculum, setCurriculum] = useState([]);

    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const activeSem = auth.data.schoolyears.grading.SemesterId
    const activeSy = auth.data.schoolyears.grading.SchoolYear    
    const [clearance, setClearance] = useState([]);
    const { register, handleSubmit, errors } = useForm();
    const [student, setStudent] = useState(null);
    const [studentList, setStudentList] = useState([]);
    
    const [studentSelected, setStudentSelected] = useState({
        studentNo: '',
        option: ''
    });

    const [defaultValues, setDefaultValues] = useState({
        studentNo: '',
        option: '',
    });

    const onSearch = () => {
        executeGetStudentGradeAssessment();
    }

    const onReset = async () => {
        setGradeAssessment([]);
        setDefaultValues((prevData) => {
            return {...prevData, 
                studentNo: studentSelected.studentNo, 
                option: studentSelected.option
            }
        });
    }

    const handleStudentInputChange = async (input) => {
        await gradeAssessmentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    // Set Student ID based on the selected value from the Auto-Complete field
    const handleGetStudent = async (data) => {
        setStudent(data);;

        if(studentList !== null){
            for(let i = 0; i < studentList.length; i++){
                if(studentList[i].StudentNo == data.StudentNo){
                    setStudentSelected((prevData) => {
                        return {...prevData, 
                            studentNo: studentList[i].StudentNo, 
                            option: studentList[i].DropdownOption,
                        }
                    });
                    break;
                }
            }
        }
    }

    const executeGetStudentGradeAssessment = async () => {

        if(student !== null){
            let values = {
                // studentNo: 'S2020103854'
                studentNo: student.StudentNo
            }

            await getStudentGradeAssessment(values)
                .then(res => {
                if(res.success){
                    if (res.data.length > 0) {
                        console.log("Assessment Data: ", res.data);

                        setCurriculum(res.data[0].CourseDescription);

                        let originalData = res.data;

                        const transformedArray = [];

                        for (const key in originalData) {
                            const entry = originalData[key];
                            const { Period, SubjectCode, FinalGrade, Status, Remarks, Description, Units } = entry;                        
                            const yearLevelIndex = transformedArray.findIndex(item => item.Period === Period);
                        
                            if (yearLevelIndex === -1) {
                              transformedArray.push({ Period: Period, Details: [{ SubjectCode, FinalGrade, Status, Remarks, Description, Units }]});
                            } else {
                              transformedArray[yearLevelIndex].Details.push({ SubjectCode, FinalGrade, Status, Remarks, Description, Units });
                            }
                        }
    
                        setGradeAssessment(transformedArray);
                    }
                }
                })
                .catch(error => {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: "An error has occurred. Please contact your administrator." });
                  if (error.response.status === 401) {
                  } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                  }
                })
        } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "Please select a student." });
        }
    }
    
    const classes = useStyles();

    return (
        <>
            { gradeAssessment.length == 0 ? //Check if gradeAssessment variable has data
                <>
                    <div className="container">
                        <div className="card">
                            <div className="row container my-5">
                                <div className="col-12 col-xl-4  border-right border-primary">
                                    <div className=" p-5">
                                        {student !== null ?
                                            <>
                                                <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                                <p className="my-0  ">{student.CourseDesc}</p>
                                            </>
                                            :
                                            <>
                                                <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                            </>
                                        }
                                        <hr />
                                        {/* Student No. */}
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            
                                                </>

                                                :

                                                <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            }
                                        </div>

                                        {/* Year Level */}
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={student.YearLevel} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            
                                                </>

                                                :

                                                <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            }
                                        </div>

                                        {/* SY/Sem */}
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem:&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                            </div>
                                            {student !== null ?
                                                <>
                                                    <input readOnly value={`${student.SchoolYear} / ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            
                                                </>

                                                :
                                                <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                            }
                                        </div>
                                        <hr />
                                    </div>
                                </div>
                                <div className="col-12 col-xl-8">
                                    <div className="p-5 mb-5 ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Search for Student Grade Assessment</h3>
                                        <hr />
                                        <div className="form-row">
                                            <div className="form-group col">
                                                <Autocomplete
                                                    id="studentList"
                                                    options={studentList}
                                                    defaultValue={{StudentNo: defaultValues.studentNo, DropdownOption: defaultValues.option}}
                                                    getOptionLabel={(option) => option.DropdownOption}
                                                    getOptionSelected={(option, value) => option.DropdownOption === value.DropdownOption}
                                                    onChange={(event, value) => {
                                                        if (value) {
                                                            // Handle the selected option
                                                            handleGetStudent(value)
                                                        } else {                                                                            
                                                            // Handle the case when no option is selected
                                                            setStudentList([]);
                                                            setStudent(null);
                                                            setStudentSelected((prevData) => {
                                                                return {...prevData, 
                                                                    studentNo: '', 
                                                                    option: ''}
                                                            });
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Student Number/Name"
                                                            variant="outlined"
                                                            inputRef={register}
                                                            name="studentList"
                                                            //value={{StudentId: studentSelected.studentId,  StudentName: studentSelected.studentName}}
                                                            onChange={
                                                                (e) => { handleStudentInputChange(e.target.value) }
                                                            }
                                                        />
                                                    )}
                                                /> 
                                            </div>
                                        </div>
                                    </div>

                                    <div className="card-toolbar">
                                        <div className="border-0 p-5">
                                            {student !== null ?
                                                student.IsArchive == 0 ?
                                                    <>
                                                    <button id="view-grade-assessment" onClick={() => { onSearch() }} className='mt-3 btn btn-primary mr-3'>
                                                        <i className="fa fa-file"></i>&nbsp;View Grade Assessment
                                                    </button>

                                                    <PrintTOR results={[student]}/>
                                                    </>
                                                    :
                                                    <PrintTOR results={[student]}/>
                                                : 
                                                <></>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </>

                :
                   
                <>                    
                    <div className="" >
                        <div>
                            <div className="container" >
                                <main className="card border-0 p-5">
                                    <header className="card-header"> 
                                        <div className='row'>
                                            <button id="btn-reset" style={{width:"11.65%"}} className="mt-3 btn btn-primary mr-3" onClick={() => { onReset() }} > 
                                                <i className="fa flaticon-reply"></i>&nbsp;Back
                                            </button>
                                        </div>
                                    </header>
                                    <section className="card-body my-5" >
                                        <img style={{ width: "200px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                        <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                        <h4 className="text-center">Student Grade Assessment</h4>
                                        <br></br>

                                        <table>
                                            <tbody  style={{fontSize:"1.1em"}}>
                                                <tr>
                                                    <td style={{width:"50%"}}>
                                                        <span>Student No. / Name: </span>
                                                        <span style={{fontWeight:"500"}}>{student == null ? "" : student.StudentNo} - {student == null ? "" : `${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</span>
                                                    </td>
                                                    <td>&nbsp;&nbsp;&nbsp;</td>
                                                    <td style={{width:"50%"}}>
                                                        <span>Program: </span>
                                                        <span style={{fontWeight:"500"}}>{student == null ? "" : student.CourseDesc}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:"50%"}}>
                                                        <span>Year Level: </span>
                                                        <span style={{fontWeight:"500"}}>{student == null ? "" : student.YearLevel}</span>
                                                    </td>
                                                    <td>&nbsp;&nbsp;&nbsp;</td>
                                                    <td style={{width:"50%"}}>
                                                        <span>Curriculum Code: </span>
                                                        <span style={{fontWeight:"500"}}>{student == null ? "" : student.CurriculumCode}</span>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td style={{width:"50%"}}>
                                                        <span>Last Enrollment: </span>
                                                        <span style={{fontWeight:"500"}}>{student == null ? "" : `${student.AcademicYear} - ${student.SemesterText}`}</span>
                                                    </td>
                                                    <td>&nbsp;&nbsp;&nbsp;</td>
                                                    <td style={{width:"50%"}}></td>
                                                </tr>
                                            </tbody>
                                        </table>

                                        {gradeAssessment.map((res, index) => {
                                            return (
                                                <div key={index}>
                                                    <div className="facultySub">
                                                        <br></br>
                                                        <h6 className=""><span className="lead facultySub"></span>{res.Period}</h6>
                                                        <TableContainer component={Paper} >
                                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                                <TableHead >
                                                                    <TableRow>
                                                                        <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Subject Code</TableCell>
                                                                        <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Final Grade/s</TableCell>
                                                                        <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Status</TableCell>
                                                                        <TableCell style={{backgroundColor: "#FAFAFA"}}width="25%">Remarks</TableCell>
                                                                    </TableRow>
                                                                </TableHead>
                                                                <TableBody>
                                                                    {res.Details.map((row, index) => (
                                                                        <TableRow key={index}>
                                                                            <TableCell style={{fontWeight:"bold"}}>{row.SubjectCode}</TableCell>
                                                                            <TableCell>{row.FinalGrade}</TableCell>
                                                                            <TableCell>{row.Status}</TableCell>
                                                                            <TableCell>{row.Remarks}</TableCell>
                                                                        </TableRow> 
                                                                    ))}
                                                                </TableBody>
                                                            </Table>
                                                        </TableContainer>
                                                        <br></br>
                                                    </div>
                                                </div>
                                            )
                                        })}
                                    </section>
                                </main>
                            </div>
                        </div>
                    </div>
                </>
            }
        </>
    )
}