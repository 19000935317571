import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getGradeElement, createGradeElement, updateGradeElement, deleteGradeElement } from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';

export default function List() {
    const { state: { fees }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
    const [keyword, setKeyword] = useState(fees.keyword);
    const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)
    const updateId = fees.id;


    // table pagination and search -----------------------------------------------------

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        await createGradeElement(val)
            .then(res => {
                setIsOpenAdd(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been saved.' });

            })
    }

    // on update grade element
    const onUpdateElement = async (id, val) => {
        await updateGradeElement(id, val)
            .then(res => {
                setIsOpenUpdate(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been updated.' });
            })
    }

    // on delete grade element
    const onDeleteElement = async (id) => {
        await deleteGradeElement(id)
            .then(res => {
                setIsOpenDelete(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been Deleted.' });
            })
            .catch(e => {

            })
    }



    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
    }

    //Update
    const onUpdateGradeElement = (data) => {
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = () => {
        setIsOpenDelete(false)
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }



    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            shortName: props.value !== undefined && props.value !== null ? props.value.shortName : null,
            description: props.value !== undefined && props.value !== null ? props.value.description : null,
            reportCode: props.value !== undefined && props.value !== null ? props.value.reportCode : null
        })

        useEffect(() => {
            props.onSave(data)
        }, [data])

        console.log(props.value)
        return (
            <>
                <main className='container'>
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="inputEmail4">Short Name</label>
                            <input
                                required type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Short Name"
                                defaultValue={props.value !== undefined && props.value !== null ? props.value.shortName : ''}
                                onBlur={(e) => { setData({ ...data, shortName: e.target.value }) }}
                            />
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="inputEmail4">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Description"
                                defaultValue={props.value !== undefined && props.value !== null ? props.value.description : ''}
                                onBlur={(e) => { setData({ ...data, description: e.target.value }) }}

                            />
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="inputEmail4">Report Code</label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Report Code"
                                defaultValue={props.value !== undefined && props.value !== null ? props.value.reportCode : ''}
                                onBlur={(e) => { setData({ ...data, reportCode: e.target.value }) }}

                            />
                        </div>

                    </div>
                </main>



            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.shortName : ''} `}</span> </p>
                </main>



            </>
        )
    }



    useEffect(() => {
        const execute = async () => {
            await getGradeElement(page, rowsPerPage, keyword)
                .then(response => {
                    if (response.success) {
                        setResults(response.data);
                        setTotalRecord(response.data.length > 0 ? response.records : 0);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        }
        execute();

    }, [dispatch, page, rowsPerPage, keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ReportCode', label: 'Report Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'GradeElementId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'addUpdate',
        parentId: null,
        tableTitle: 'List of Grade Elements',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: updateId,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch,
        onUpdate: onUpdateGradeElement,
        onDelete: onDeleteGradeElement
    }
    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))

    return (
        <>
            <AddUpdateModal
                title="Add Grade Element"
                isOpen={isOpenAdd}
                Content={Content}
                handleClose={onAddGradeElementClose}
                isAdd={true}
                onSubmit={onSaveGradeElement}
                id="gradeElementId"
            />

            <AddUpdateModal
                title="Update Grade Element"
                isOpen={isOpenUpdate}
                Content={Content}
                value={keysToCamelCase(rowValue)}
                handleClose={onUpdateGradeElementClose}
                isUpdate={true}
                onSubmit={onUpdateElement}
                id="gradeElementId"

            />

            <AddUpdateModal
                title="Delete Grade Element"
                isOpen={isOpenDelete}
                Content={DeleteContent}
                handleClose={onDeleteGradeElementClose}
                isDelete={true}
                onSubmit={onDeleteElement}
                value={keysToCamelCase(rowDeleteValue)}
                id="gradeElementId"

            />
            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                    </div>
                    <div className="card-toolbar">
                        <div className="example-tools">
                            <button className="btn btn-sm btn-icon-sm btn-success mr-1"><i className="flaticon-download-1"></i> Export</button>
                            <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                                <i className="fa fa-plus"></i> Add New
                            </button>
                        </div>
                    </div>
                </div>
                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                    <IMTable tableProps={tableProps} />
                </div>
            </div>
        </>
    );
}