import React, { useState, useEffect } from "react";
import { searchStudent } from "./__hooks__/index"
import { TextField } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import { useAppContext } from "../../../../contexts/useAppContext";
import { Link } from "react-router-dom";
export default function Student201(props) {
    const { dispatch } = useAppContext();

    const { handleSubmit, register } = useForm();
    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState(null)
    const [student201Data, setStudent201Data] = useState()

    const onSubmitSearch = (formValues) => {
        let studentData = {
            ...formValues,
            semesterId: null,
            schoolYear: null
        }

        executeSearchStudent(studentData)
    }

    const executeSearchStudent = async (val) => {
        await searchStudent(val)
            .then(res => {
                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found, Try Migrating Student In Campus'
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }

    const onBack = () => {
        setStudent(null)
        setStudentNo(null)
    }

    useEffect(() => {
        if (student !== null) {
            let data = {
                enrollmentId: student.EnrollmentId,
                studentId: student.StudentId,
                studentNo: student.StudentNo,
                studentName: `${student.LastName}, ${student.FirstName}`,
                firstName: `${student.FirstName}`,
                lastName: `${student.LastName}`,
                middleName: `${student.MiddleName}`,
                studentInfoId: student.StudentInfoId,
                sy: `${student.SchoolYear} / ${student.SemesterId}`,
                semester: student.SemesterId,
                schoolYear: student.SchoolYear,
                civilStatusId: student.CivilStatusId,
                religionId: student.ReligionId,
                birthDate: student.BirthDate,
                birthPlace: student.BirthPlace,
                telNo: student.TelNo,
                celNo: student.CelNo,
                email: student.EmailAddress,
                address: student.Address1,
                lastSchool: student.LastSchoolId,
                course: student.Course,
                courseId: student.CourseId,
                courseDesc: student.CourseDesc,
                yearLevel: student.YearLevelDesc,
                yearLevelId: student.YearLevelId,
                status: student.EnrollmentStatusId,
                statusDesc: student.EnrollmentStatusId,
                gender: student.Gender,
                fatherName: student.FatherName,
                fatherOccupation: student.FatherBusinessName,
                fatherOccupationAddress: student.FatherBusinessAddress,
                fatherContactNo: student.FatherCellNo,
                fatherAddress: student.FatherHomeAddress,
                fatherMaritalStatus: student.FatherMaritalStatus,
                fatherEducation: student.FatherHighestEducationalAttainment,
                fatherCitizen: student.FatherCitizen,
                motherName: student.MotherName,
                motherOccupation: student.MotherBusinessName,
                motherOccupationAddress: student.MotherBusinessAddress,
                motherContactNo: student.MotherCellNo,
                motherAddress: student.MotherHomeAddress,
                motherMaritalStatus: student.MotherMaritalStatus,
                motherCitizen: student.MotherCitizen,
                motherEducation: student.MotherHighestEducationalAttainment,
                familyBackgroundId: student.FamilyBackgroundId,
                municipalityId: student.MunicipalityId,
                provinceId: student.ProvinceId,
                countryId: student.CountryId,
                barangay: student.Barangay,
                studentInfoId: student.StudentInfoId

            }

            setStudent201Data(data)
        }
    }, [student])

    console.log(student)
    return (
        <>
            <div className="container">

                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "New Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <hr />


                            </div>


                        </div>
                        <div className="col-12 col-xl-8">



                            <div className="p-5 mb-5 ">
                                {student === null ?

                                    <form onSubmit={handleSubmit(onSubmitSearch)} method="post">
                                        {/* <h3 className="bg-secondary lead p-3 font-weight-bold">Student Search</h3> */}

                                        <TextField
                                            id="outlined-basic"
                                            label="Student No"
                                            variant="outlined"
                                            name="studentNo"
                                            value={studentNo}
                                            inputRef={register({
                                                required: true
                                            })}
                                            className="bg-white mt-4"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                setStudentNo(e.target.value)
                                            }}
                                        />

                                        <button type="submit" className="mt-3 btn btn-primary">Search</button>
                                    </form>

                                    :
                                    <>

                                        <Link to={{ pathname: `/admin/student/update-student/${student.StudentInfoId}`, data: student201Data, identifier: 'student201' }} className="btn-success btn" >Student 201</Link>
                                        <button onClick={() => { onBack() }} className="btn btn-secondary ml-5">Search Another Student</button>
                                    </>


                                }

                            </div>


                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}