import React, { useEffect, useState } from "react";
import { useForm } from 'react-hook-form';
import { getStudentDetailsByAccessCode, changePassword } from '../__hooks__';
import { authTypes } from '../__hooks__/types';
import { componentTypes } from '../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { useAppContext } from '../../../contexts/useAppContext';
import firebase from '../../../apis/firebase/firebase.config';
import history from '../../../history';

export default function SignUpMain(props) {
    const { state: { auth }, dispatch } = useAppContext();
    const { register, handleSubmit, errors } = useForm();
    const [ accessCode, setAccessCode] = useState(props.match.params.accessCode);
    const [ userDetails, setUserDetails] = useState(null);
    //console.log(accessCode);

    const baseUrl = fullpathName => {
      return fullpathName.slice(0, fullpathName.lastIndexOf('/'));
    }

    let fullpathName = window.location.pathname;
    var basePathName = baseUrl(fullpathName);
    var title;
    var res;

    //console.log("Base URL: ", fullpathName);
    //console.log("Access Code: ", accessCode);

    if(basePathName == '/auth/changepassword'){
        title = 'CHANGE PASSWORD';
    } else {
        title = 'USER SIGN UP';
    }

    const fetchData = async () => {
      let value = {
        accessCode:accessCode
      }

      await getStudentDetailsByAccessCode(value)
          .then(res => {
              if(res.results != 0){
                setUserDetails(res);
                let passwordReminderText = document.querySelector('#passwordReminder');
                passwordReminderText.innerText = `REMINDER: Password must contain at least one (1) digit, one (1) lowercase letter, 
                                                  one (1)  uppercase letter, one (1) special character, and must be at least eight (8) characters long.`;
              } else {
                history.push('/auth/login');
              }
          })
    };

    useEffect(() => {
      fetchData();
    }, []);

    const onSubmit = formValues => {
        //console.log(formValues);
        const result = validatePassword(formValues);
        //console.log("Result: ", result);
        
        /*** If student has no account, get info by student number */
        const updateUserPassword = async () => {
          if(accessCode != null){
            let values = {
              accessCode: accessCode,
              userName: userDetails.data[0].Username,
              password: formValues.password
            }
            setAccessCode(null);
            //console.log(values);
            await changePassword(values)
                .then(result => {
                    if (result.success) {
                        //console.log(result);
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Password has been set successfully! You may now return to Login page.'});
                        //createNewUserAccount(result.data[0]);
                        //history.push('/auth/login');
                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {

                    alert("Error!");
                  if (error.response.status === 401) {
                    //history.push('/auth/login');
                  } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                  }
                })
              
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: 'Your reset password code has been used already.' });
          }
        }

        if(result){
          updateUserPassword();
        }
    }

    function validatePassword(formValues) {
      // const password = document.getElementById('password').value;
      // const confirmPassword = document.getElementById('confirmPassword').value;
      const confirmPasswordErrorDiv = document.querySelector('#unmatchPassword');
      const confirmPasswordErrorTextBox = document.querySelector('#confirmPassword');

      const newPasswordErrorDiv = document.querySelector('#invalidPassword');
      const newPasswordErrorTextBox = document.querySelector('#password');

      const password = formValues.password;
      const confirmPassword = formValues.confirmPassword;
      //const passwordPattern = /^(?=.*\d)(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
      const passwordPattern = /^(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}$/;

      // Check if password matches the pattern
      const isPasswordValid = passwordPattern.test(password);

      // Check if password and confirm password match
      const doPasswordsMatch = password === confirmPassword;

      // Error messages for password requirements
      const errors = [];

     if (!isPasswordValid) {
        //let {numericFlag,lowercaseFlag,upperCaseFlag,spCharFlag,pwLengthFlag} = false; 
        newPasswordErrorDiv.innerText = "Password is missing the following: ";
        if (!/(?=.*\d)/.test(password)) {
          // errors.push("Password must include at least 1 number.");
          newPasswordErrorDiv.innerText += " At least one (1) digit. "
        }
        if (!/(?=.*[!@#$%^&*])/.test(password)) {
          // errors.push("Password must include at least 1 special character.");
          newPasswordErrorDiv.innerText += " At least one (1) special character. "
        }
        if (!/(?=.*[a-z])/.test(password)) {
          // errors.push("Password must include at least 1 lowercase letter.");
          newPasswordErrorDiv.innerText += " At least one (1) lowercase letter. "
        }
        if (!/(?=.*[A-Z])/.test(password)) {
          // errors.push("Password must include at least 1 uppercase letter.");
          newPasswordErrorDiv.innerText += " At least one (1) uppercase letter. "
        }
        if (password.length < 8) {
          errors.push("Password must be at least 8 characters long.");
          newPasswordErrorDiv.innerText += " Length of at least eight (8) characters. "
        }

        // newPasswordErrorDiv.innerText = 
        //     `Password is invalid! Must contain at least one (1) digit, 
        //       one (1) lowercase letter, one (1)  uppercase letter, 
        //       one (1) special character, and must be at least 
        //       eight (8) characters long.`;
        newPasswordErrorDiv.style.display = "block";
        newPasswordErrorTextBox.classList.add("is-invalid");
        errors.push(newPasswordErrorDiv.innerText);
      } else {
        newPasswordErrorDiv.style.display = "none";
        newPasswordErrorTextBox.classList.remove("is-invalid");
      }

     if (!doPasswordsMatch) {
        confirmPasswordErrorDiv.innerText = "Passwords provided do not match.";
        confirmPasswordErrorDiv.style.display = "block";
        confirmPasswordErrorTextBox.classList.add("is-invalid");
        errors.push(confirmPasswordErrorDiv.innerText);
        //errorContainer.appendChild(errorElement);
      } else {
        confirmPasswordErrorDiv.style.display = "none";
        confirmPasswordErrorTextBox.classList.remove("is-invalid");
      }

      const validation = {isValid: isPasswordValid && doPasswordsMatch, errors: errors};
      //console.log(validation.isValid); // Output: false
      //console.log(validation.errors); // Output: [ 'Password must include at least 1 special character.' ]

      // if(validation.isValid){
      //   return true;
      // } else {
      //   return false;
      // }

      return validation.isValid ? true : false;
    }

    res = userDetails;
    //res = {results:0,data:[]};
    //execute();
    // if(res != null || res === undefined){
    //   console.log(res.results);
    // }

    if(res != null || res === undefined){
        return (
          <div style={{width:"75%"}}>
            {/* begin::Head */}
            <div className="text-center mb-5 mb-lg-5">
              <h3 className="font-size-h1 mb-5">
                <b>{title}</b>
              </h3><br />
            </div>
            {/* end::Head */}
						<div className="row">
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* Student Number */}
                  <div className="form-group fv-plugins-icon-container">
                      <label>{res.data[0].UserType == "Student" ? "Student Number" : "Employee Number"}</label>
                      <input
                        placeholder={res.data[0].UserType == "Student" ? "Student Number" : "Employee Number"}
                        type="text"
                        value={res.results > 0 ? res.data[0].UserType == "Student" ? res.data[0].StudentNo : res.data[0].EmployeeNo : ''}
                        className={`form-control h-auto py-5 px-6`}
                        disabled
                      />
                  </div>
            
                  {/* Student's Username */}
                  <div className="form-group fv-plugins-icon-container">
                      <label>Username</label>
                      <input
                        placeholder="Username"
                        type="text"
                        value={res.results > 0 ? res.data[0].Username : ''}
                        className={`form-control h-auto py-5 px-6`}
                        disabled
                      />
                  </div>
                </div>
                
                <div className="col-lg-6 col-md-6 col-sm-12">
                  {/* Student's Name */}
                  <div className="form-group fv-plugins-icon-container">
                     <label>Name</label>
                      <input
                        placeholder="Name"
                        type="text"
                        value={res.results > 0 ? res.data[0].FirstName + " " + res.data[0].LastName : ''}
                        className={`form-control h-auto py-5 px-6`}
                        disabled
                      />
                  </div> 
              
                  {/* Student's Course */}
                  <div className="form-group fv-plugins-icon-container">
                      <label>{res.data[0].UserType == "Student" ? "Course" : "Department"}</label>
                      <input
                        placeholder={res.data[0].UserType == "Student" ? "Course" : "Department"}
                        type="text"
                        value={res.results > 0 ? res.data[0].UserType == "Student" ? res.data[0].Course : res.data[0].DepartmentCode : ''}
                        className={`form-control h-auto py-5 px-6`}
                        disabled
                      />
                  </div>
                </div>
            </div>

            <div className="login-form login-signin" id="kt_login_signin_form" style={{margin:"0 auto"}}>
              {/*begin::Form*/}
              <form onSubmit={handleSubmit(onSubmit)} className="form fv-plugins-bootstrap fv-plugins-framework mt-5">

                {/* New Password Textbox */}
                <div className="form-group fv-plugins-icon-container">
                  <label>Password <span className="text-danger">*</span></label>
                  {/* <i className="fa fa-info-circle" style={{float:"right"}} title="Click for more details"></i> */}
                  <input
                    placeholder="New Password"
                    //pattern="(?=.*\d)(?=.*[a-z])(?=.*[A-Z])(?=.*[^a-zA-Z0-9]).{8,}"
                    title="Choose a password that is unique and is a combination of alphanumeric characters and special symbols."
                    type="password"
                    name="password"
                    id="password"
                    className={`form-control h-auto py-5 px-6${errors.password === undefined ? "" : " is-invalid"}`}
                    ref={register({
                      required: true
                    })}
                    autoFocus
                    disabled = {res.results > 0 ? false : true}
                  />
                  <span className="form-text text-muted">Please enter your new password.</span>
                  {errors.password && errors.password.type === "required" && <div className="invalid-feedback">Password is required</div>}
                  {errors.password === undefined ? <div id="invalidPassword" className="invalid-feedback"></div> : ""}
                </div>
                  
                {/* Confirm Password Textbox */}
                <div className="form-group fv-plugins-icon-container">
                  <label>Confirm Password <span className="text-danger">*</span></label>
                  <input
                    placeholder="Confirm Password"
                    type="password"
                    name="confirmPassword"
                    id="confirmPassword"
                    className={`form-control h-auto py-5 px-6${errors.confirmPassword === undefined ? "" : " is-invalid"}`}
                    ref={register({
                      required: true
                    })}
                    disabled = {res.results > 0 ? false : true}
                  />
                  {errors.confirmPassword && errors.confirmPassword.type === "required" && <div className="invalid-feedback">Confirm Password is required</div>}
                  {errors.password === undefined ? <div id="unmatchPassword" className="invalid-feedback"></div> : ""}
                  {<br></br>}
                  {<div id="passwordReminder" style={{fontStyle:"italic", fontWeight:"500", fontSize:"11.7px", textAlign:"justify"}}></div>}
                </div>
                  
                {/* Form Buttons */}
                <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                  <button 
                    type="submit" 
                    className={`btn btn-primary`} 
                    disabled = {res.results > 0 ? false : true}
                  >
                    <i className="fa flaticon-paper-plane-1"></i>&nbsp;Submit
                  </button>
                  <button 
                    //onClick={ () => basePathName == '/auth/changepassword' ? history.push('/auth/forgotpassword') : history.push('/auth/signup')} 
                    onClick={ () => history.push('/auth/login') } 
                    className={`btn btn-primary`} 
                  >
                    <i className="fa flaticon-reply"></i>&nbsp;Back
                  </button>
                </div>    

              </form>
              {/*end::Form*/}
            </div>
          </div>
        );   
    } else {
      return (true);
    }
}