import React, { useState } from "react"
import { Modal, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IMSelect from "../select/IMSelect";


export default function MigrateInstructionalGuideModal(props) {
    const { register, handleSubmit, setValue } = useForm()
    const [filteredSem, setFilteredSem] = useState()
    const [filteredSy, setFilteredSy] = useState()
    const [filteredCourse, setFilteredCourse] = useState()
    const [filteredYearLevel, setFilteredYearLevel] = useState()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };

    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectCourseChange = name => event => { setFilteredCourse(event) }
    const selectYearLevelChange = name => event => { setFilteredYearLevel(event) }


    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {props.schoolYearSelect &&
                    <IMSelect
                        data={props.schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? filteredSy === undefined : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemesters = () => {
        return (
            <>
                {props.semestersSelect &&
                    <IMSelect
                        data={props.semestersSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? filteredSem === undefined : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownCourse = () => {
        return (
            <>
                {props.courseSelect &&
                    <IMSelect
                        data={props.courseSelect}
                        onHandleChange={selectCourseChange()}
                        refClassContainer=""
                        name="Course"
                        isRequired={false}
                        withLabel={false}
                        label="Course"
                        placeHolder="Select Course"
                        forwardRef={setValue}
                        selectedId={filteredCourse === undefined ? '' : filteredCourse}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownYearLevel = () => {
        return (
            <>
                {props.courseSelect &&
                    <IMSelect
                        data={props.yearLevelSelect}
                        onHandleChange={selectYearLevelChange()}
                        refClassContainer=""
                        name="Year Level"
                        isRequired={false}
                        withLabel={false}
                        label="Year Level"
                        placeHolder="Select Year Level"
                        forwardRef={setValue}
                        selectedId={filteredYearLevel === undefined ? '' : filteredYearLevel}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    const onSubmit = (formValues) => {
        let migrationData = {
            semesterId: filteredSem,
            schoolYear: filteredSy,
            // courseId: filteredCourse,
            // yearLevelId: filteredYearLevel
        }
        props.onSubmitStudentHandler(migrationData)
        console.log(migrationData)
        props.handleClose()
    }

    return (
        <Modal
            open={props.openMigrateModal}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="card p -5" style={style}>
                <div className="card-header"> <h3>Migrate Instructional Guide</h3> </div>
                <div className="card-body">

                    <div className="col bg-white mr-3 my-0 p-0">{imSelectDropdownSchoolYear()}</div>
                    <div className="my-4 col bg-white my-0 p-0">{imSelectDropdownSemesters()}</div>
                    {/* <div className="my-4 col bg-white my-0 p-0">{imSelectDropdownCourse()}</div>
                    <div className="my-4 col bg-white my-0 p-0">{imSelectDropdownYearLevel()}</div> */}


                    <form onSubmit={handleSubmit(onSubmit)} method="post">
                        {/* <input
                            className="form-control"
                            name="studentNo"
                            placeholder="Student No."
                            ref={register({
                                required: false
                            })}
                        /> */}
                        <button type="submit" className="mt-5 mr-5 btn btn-primary ">Migrate</button>
                        <button type="button" onClick={() => { props.handleClose() }} className="mt-5 btn btn-secondary ">Exit</button>
                    </form>


                </div>
            </Box>


        </Modal>
    )
}