import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddCell from '../../../../../_metronic/layout/components/cell/AddCell';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuide, deleteInstructionalGuide,
    getSubjects, getEmployees, getGradeTemplate, getSchoolYear, getInstructionalGuideQuery, getInstructionalGuideSubjects,
    addUpdateInstructionalGuide, isWithLab, checkIsEditable,
    getInstructionalGuideList, getClassSectionSelect
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { getSemester } from '../ChangeCourse/__hooks__';
import Loading from '../../../../../_metronic/layout/components/custom/forms/Loading';
import { useForm } from 'react-hook-form';
import { checkIsDeletable } from './InstructionalGuideDetails/__hooks__';
import { makeStyles } from '@material-ui/core/styles';
import { Link } from 'react-router-dom';

import { Switch, FormControl, FormControlLabel, TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const { prevData } = props.location
    const { register } = useForm()
    const activeSem = auth.data.schoolyears.grading.SemesterId
    const activeSy = auth.data.schoolyears.grading.SchoolYear
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(fees.totalRecords);

    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)
    const [onAlert, setOnAlert] = useState(false)
    const [isSearch, setIsSearch] = useState(true)
    const [filter, setFilter] = useState({ schoolYear: prevData == undefined ? activeSy : prevData[0].SchoolYear, semesterId: prevData == undefined ? activeSem : prevData[0].SemesterId })
    const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }
    const classes = useStyles();


    console.log(auth.data.schoolyears.grading.SemesterId)
    console.log(auth.data.schoolyears.grading.SchoolYear)

    const [subjectCodeSelect, setSubjectCodeSelect] = useState(null)
    const [employeeSelect, setEmployeeSelect] = useState(null)
    const [gradeTemplateSelect, setGradeTemplateSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)


    const [selectedEditIds, setSelectedEditIds] = useState(0)


    let rowCnt = 0;
    // table pagination and search -----------------------------------------------------

    const onSeachAgain = () => {
        setResults([])
        setIsSearch(true)

    }

    const getIG = async () => {

        await getInstructionalGuideList({
            userId: auth.data.UserId,
            schoolYear: filter.schoolYear,
            semesterId: filter.semesterId
        }).then(res => {
            setResults(res.data);
            setTotalRecord(res.data.length > 0 ? res.data.length : 0);
            setIsSearch(false)


        })


    }


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        await addUpdateInstructionalGuide({ ...val, instructionalGuideId: 0, createdBy: auth.data.Username })
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    if (res.data[0].Result == 1) {
                        getSubjectOnCurrentFaculty({ userId: auth.data.UserId, schoolYear: filter.schoolYear, semesterId: filter.semesterId })
                        setIsOpenAdd(false)
                        getIG()
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide has been saved.' });

                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide already exists' });

                    }


                    // history.push(`/admin/student/instructional-guide-details/${res.data[0].InstructionalGuideId}`)
                } else {
                    setIsOpenAdd(false)
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'The instructional guide already exists' });
                }


            })
    }

    // on update grade element
    const onUpdateElement = async (id, val) => {
        console.log(val)
        await addUpdateInstructionalGuide({ ...val, instructionalGuideId: parseInt(id), createdBy: auth.data.Username })
            .then(res => {
                getIG()
                    .then(resp => {
                        setIsOpenUpdate(false)
                        setSelectedEditIds(0)
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide has been updated.' });
                    })

            })
    }

    // on delete grade element
    const onDeleteElement = async (id, val) => {
        await checkIsDeletable({ instructionalGuideId: id, instructionalScheduleTypeId: val.InstructionalScheduleTypeId })
            .then(async res => {
                if (res.data.length > 0) {
                    if (res.data[0].IsDeletable == 1) {
                        await deleteInstructionalGuide({ instructionalGuideId: id })
                            .then(async res => {

                                await getSubjectOnCurrentFaculty({ userId: auth.data.UserId, schoolYear: filter.schoolYear, semesterId: filter.semesterId })
                                    .then(() => {
                                        getIG()
                                        setIsOpenDelete(false)
                                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Instructional Guide has been Deleted.' });
                                    })

                            })
                            .catch(e => {

                            })
                    } else {
                        setIsOpenDelete(false)
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'IG with Posted Grades Cannot be Deleted' });

                    }
                }
            })

    }



    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
    }

    //Update
    const onUpdateGradeElement = (data, id) => {
        setSelectedEditIds(id)
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setSelectedEditIds(0)
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = (event, reason) => {
        if (reason && reason === "backdropClick") {
            return;

        } else {
            setIsOpenDelete(false)
            setRowDeleteValue(null)
        }
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    const getSubjectOnCurrentFaculty = async (val) => {
        await getInstructionalGuideSubjects(val)
            .then(res => {
                console.log(res)
                if (res.data.length > 0) {
                    setSubjectCodeSelect(res.data)
                } else {
                    setSubjectCodeSelect([{ tableId: 0, code: 'No Subject for This Instructor' }])

                }
            })
            .catch(e => {

            })
    }




    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            // classPlanDescription: props.value !== undefined && props.value !== null ? props.value.classPlanDescription : null,
            subjectCode: props.value !== undefined && props.value !== null ? props.value.subjectCode : null,
            employeeId: props.value !== undefined && props.value !== null ? props.value.employeeId : null,
            gradeTemplateId: props.value !== undefined && props.value !== null ? props.value.gradeTemplateId : null,
            schoolYear: props.value !== undefined && props.value !== null ? props.value.schoolYear : filter.schoolYear,
            semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : filter.semesterId,
            // isLec: props.value !== undefined && props.value !== null ? props.value.isLec : null,
            classSectionId: props.value !== undefined && props.value !== null ? props.value.classSectionId : null

        })
        const [withLab, setIsWithLab] = useState(false)
        const [classSectionSelect, setClassSectionSelect] = useState([])

        const [switchState, setSwitchState] = useState({
            isLec: false,

        });

        const getClassSectionSelectHandler = async (subCode) => {
            await getClassSectionSelect({
                subjectCode: subCode,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                userId: auth.data.UserId

            })
                .then(res => {
                    console.log(res)
                    setClassSectionSelect(res.data)
                })
        }

        const switchHandleChange = name => event => {
            console.log(event.target.checked)
            setSwitchState({ ...switchState, [name]: event.target.checked });
        };

        // Selects

        const selectSubjectChange = (name) => async (event) => {
            setData({ ...data, subjectCode: event })
            getClassSectionSelectHandler(event)

        }
        const selectClassSectionChange = (name) => async (event) => {
            setData({ ...data, classSectionId: event })

        }


        const selectEmployeeChange = name => event => { setData({ ...data, employeeId: event }) }
        const selectGradeTemplateChange = name => event => { setData({ ...data, gradeTemplateId: event }) }
        const selectSchoolYearChange = name => event => { setData({ ...data, schoolYear: event }) }
        const selectSemesterChange = name => event => { setData({ ...data, semesterId: event }) }


        useEffect(() => {
            if (data) {
                getClassSectionSelect({
                    subjectCode: data.subjectCode,
                    schoolYear: filter.schoolYear,
                    semesterId: filter.semesterId,
                    userId: auth.data.UserId

                })
                    .then(res => {
                        console.log(res)
                        setClassSectionSelect(res.data)
                    })
                props.onSave({
                    ...data,
                    employeeId: auth.data.UserType !== 'RGR' && auth.data.EmployeeId
                })
            }

        }, [data, switchState])


        console.log(props.value)
        console.log(auth.data.UserType)


        console.log(semesters)
        console.log(data)
        return (
            <>

                <TableCell className='p-1' align='center'></TableCell>
                <TableCell className='p-1' align='left'>
                    <IMSelect
                        data={props.subjectCodeSelect}
                        onHandleChange={selectSubjectChange('subjectCode')}
                        refClassContainer=""
                        name="subjectCode"
                        isRequired={false}
                        withLabel={false}
                        label="Subject Code"
                        placeHolder="Select Subject"
                        forwardRef={() => { }}
                        selectedId={data.subjectCode}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                </TableCell>



                <TableCell className='p-1' align='left'>

                    <IMSelect
                        data={props.gradeTemplateSelect}
                        onHandleChange={selectGradeTemplateChange('gradeTemplateId')}
                        refClassContainer=""
                        name="gradeTemplateId"
                        isRequired={false}
                        withLabel={false}
                        label="Grade Template"
                        placeHolder="Select Grade Template"
                        forwardRef={() => { }}
                        selectedId={data.gradeTemplateId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                </TableCell>
                <TableCell className='p-1' align='left'>
                    <IMSelect
                        data={classSectionSelect}
                        onHandleChange={selectClassSectionChange('classSectionId')}
                        refClassContainer=""
                        name="classSectionId"
                        isRequired={false}
                        withLabel={false}
                        label="Class Section"
                        placeHolder="Select Class Section"
                        forwardRef={() => { }}
                        selectedId={data.classSectionId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />

                </TableCell>
                {auth.data.UserType == 'RGR' ?

                    <TableCell className='p-1' align='left'>
                        <IMSelect
                            data={props.employeeSelect}
                            onHandleChange={selectEmployeeChange('employeeId')}
                            refClassContainer=""
                            name="employeeId"
                            isRequired={false}
                            withLabel={false}
                            label="Instructor"
                            placeHolder="Select Instructor"
                            forwardRef={() => { }}
                            selectedId={data.employeeId}
                            refClassName={`text-center`}
                            withDescription={false}
                            //description={`Enrollment Status`}
                            refDisabled={true}
                            refIsStatic={false}
                            refStaticData={[
                            ]
                            }
                            field={{
                                tableId: 'tableId',
                                display: 'code'
                            }}
                        />

                    </TableCell>
                    :
                    <TableCell className='p-1' align='left'></TableCell>


                }
                <TableCell className='p-1' align='left'></TableCell>


            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.subjectCode : ''} `}</span> </p>
                </main>



            </>
        )
    }
    useEffect(() => {
        if (prevData !== undefined) {
            console.log(prevData)
            getIG()
        }
        getSubjectOnCurrentFaculty({ userId: auth.data.UserId, schoolYear: filter.schoolYear, semesterId: filter.semesterId })

        SelectCreator(getEmployees, setEmployeeSelect, "EmployeeId", "EmployeeName")
        SelectCreator(getGradeTemplate, setGradeTemplateSelect, "GradeTemplateId", "GradeTemplateCode")
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description")
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description")
        // SelectCreator(getSubjectSchedDetail, setInstituteSelect, 'InstituteId', 'Description')
    }, [])

    useEffect(() => {
        getSubjectOnCurrentFaculty({ userId: auth.data.UserId, schoolYear: filter.schoolYear, semesterId: filter.semesterId })
    }, [filter])


    // useEffect(() => {

    //     getIG();

    // }, [page, rowsPerPage, keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Title', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FacultyName', label: 'Faculty Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'BlockSection', label: 'Block Section', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectType', label: 'Type', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    // const tableProps = {
    //     recordId: 'InstructionalGuideId',
    //     sortField: '',
    //     columns: columns,
    //     rows: results,
    //     totalRecords: totalRecords,
    //     withMoreButton: false,
    //     childWithMoreButton: false,
    //     withFooter: false,
    //     tableType: 'addUpdateInstructionalGuide',
    //     parentId: null,
    //     tableTitle: 'List of Grade Instructional Guide',
    //     onSearch: onSearch,
    //     keyword: keyword,
    //     lastUpdateId: updateId,
    //     onPage: page,
    //     onRowsPerPage: rowsPerPage,
    //     onRowsPerPageOptions: [10, 25, 50, 100],
    //     onTypes: referenceTypes,
    //     onHandleChangePage: handleChangePage,
    //     onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    //     onHandleChangeKeyword: handleChangeKeyword,
    //     onHandleChangeOnSearch: handleChangeOnSearch,
    //     onUpdate: onUpdateGradeElement,
    //     onDelete: onDeleteGradeElement
    // }
    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))


    console.log(schoolYearSelect)
    console.log(semesterSelect)
    console.log(gradeTemplateSelect)
    console.log(employeeSelect)
    console.log(subjectCodeSelect)


    console.log(auth.data)
    console.log(results)
    console.log(rowDeleteValue)
    return (
        <>
            {schoolYearSelect && semesterSelect && gradeTemplateSelect && employeeSelect && subjectCodeSelect ?


                <>


                    <AddUpdateModal
                        title="Delete Instructional Guide"
                        isOpen={isOpenDelete}
                        Content={DeleteContent}
                        handleClose={onDeleteGradeElementClose}
                        isDelete={true}
                        onSubmit={onDeleteElement}
                        value={keysToCamelCase(rowDeleteValue)}
                        id="instructionalGuideId"
                        schoolYearSelect={schoolYearSelect}
                        semesterSelect={semesterSelect}
                        gradeTemplateSelect={gradeTemplateSelect}
                        employeeSelect={employeeSelect}
                        subjectCodeSelect={subjectCodeSelect}

                    />
                    {isSearch == false ?
                        <>
                            <div className="card card-custom gutter-b example example-compact">
                                <div className="card-header">
                                    <div className="card-title">
                                        <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> <br />
                                        <button onClick={() => { onSeachAgain() }} className='btn btn-secondary float-right'>Search Again</button>


                                    </div>
                                    <div className="card-toolbar">

                                    </div>
                                </div>
                                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                    {/* <IMTable tableProps={tableProps} /> */}
                                    <TableContainer >
                                        <Table className={classes.table} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">#</TableCell>
                                                    <TableCell>Subject Code</TableCell>
                                                    <TableCell align="center">Template</TableCell>
                                                    <TableCell align="center">Block Section</TableCell>
                                                    <TableCell align="center">Faculty Name</TableCell>
                                                    <TableCell align="center">Type</TableCell>
                                                    <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {results.length > 0 ? results.map((result) => {
                                                    const { InstructionalGuideId, SubjectCode, GradeTemplate, FacultyName, BlockSection, SubjectType, IsGradeEncoded } = result;
                                                    rowCnt += 1;

                                                    const handleUpdateGrade = () => {
                                                        onUpdateGradeElement(result, InstructionalGuideId);
                                                    };

                                                    const handleDeleteGrade = () => {
                                                        onDeleteGradeElement(result);
                                                    };

                                                    return (
                                                        <>
                                                            {selectedEditIds != InstructionalGuideId ?
                                                                <TableRow key={InstructionalGuideId}>
                                                                    <TableCell align="center">{rowCnt}</TableCell>
                                                                    <TableCell component="th" scope="row">{SubjectCode}</TableCell>
                                                                    <TableCell align="center">{GradeTemplate}</TableCell>
                                                                    <TableCell align="center">{BlockSection}</TableCell>
                                                                    <TableCell align="center">{FacultyName}</TableCell>
                                                                    <TableCell align="center">{SubjectType}</TableCell>

                                                                    <TableCell align="center">
                                                                        <button
                                                                            disabled={IsGradeEncoded == 1 ? true : false}
                                                                            className={`btn btn-xs btn-icon btn-icon-xs  mr-1 ${IsGradeEncoded == 1 ? 'btn-secondary' : 'btn-info'}`}
                                                                            onClick={handleUpdateGrade}
                                                                        >
                                                                            <i className="fa fa-pencil-alt text-white"></i>
                                                                        </button>

                                                                        <Link to={{ pathname: `${referenceTypes.MORE_LINK}${result['InstructionalGuideId']}`, data: result }} className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                                                                            <i className="fa fa-list-alt text-white"></i>
                                                                        </Link>

                                                                        <button
                                                                            disabled={IsGradeEncoded == 1 ? true : false}
                                                                            className={`btn btn-xs btn-icon btn-icon-xs  ${IsGradeEncoded == 1 ? 'btn-secondary' : 'btn-danger'}`}
                                                                            onClick={handleDeleteGrade}
                                                                        >
                                                                            <i className="fa fa-trash"></i>
                                                                        </button>

                                                                    </TableCell>
                                                                </TableRow>

                                                                :
                                                                <AddCell
                                                                    title="Update Modified Outline"
                                                                    Content={Content}
                                                                    value={keysToCamelCase(rowValue)}
                                                                    handleClose={onUpdateGradeElementClose}
                                                                    isUpdate={true}
                                                                    subjectCodeSelect={subjectCodeSelect}
                                                                    gradeTemplateSelect={gradeTemplateSelect}
                                                                    schoolYearSelect={schoolYearSelect}
                                                                    semesterSelect={semesterSelect}
                                                                    employeeSelect={employeeSelect}
                                                                    onSubmit={onUpdateElement}
                                                                    id="instructionalGuideId"

                                                                />

                                                            }


                                                        </>
                                                    );

                                                }) :
                                                    <TableRow></TableRow>
                                                }
                                                {/* <TableFooter> */}

                                                <AddCell
                                                    title="Add Modified Outline"
                                                    Content={Content}
                                                    isAdd={true}
                                                    subjectCodeSelect={subjectCodeSelect}
                                                    gradeTemplateSelect={gradeTemplateSelect}
                                                    schoolYearSelect={schoolYearSelect}
                                                    semesterSelect={semesterSelect}
                                                    onSubmit={onSaveGradeElement}
                                                    employeeSelect={employeeSelect}
                                                    id="instructionalGuideId"

                                                />
                                                {/* </TableFooter> */}
                                            </TableBody>

                                        </Table>
                                    </TableContainer>
                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex align-items-center'>
                                <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                                    <header className='card-header p-5 bg-secondary'>
                                        <h6 className="float-left m-0 mt-4 ml-5">Instructional Guide</h6>
                                    </header>
                                    <section className="card-body">
                                        {onAlert == true && results.length == 0 &&
                                            <div className="alert alert-danger" role="alert">
                                                No Record Found
                                            </div>
                                        }
                                        <p className="card-text">Search Grade Book</p>



                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={schoolYearSelect}
                                                    onHandleChange={filterChange('schoolYear')}
                                                    refClassContainer=""
                                                    name="schoolYear"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="School Year"
                                                    placeHolder="Select School Year"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.schoolYear}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`School Year`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={semesterSelect}
                                                    onHandleChange={filterChange('semesterId')}
                                                    refClassContainer=""
                                                    name="semesterId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Semester"
                                                    placeHolder="Select Semester"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.semesterId}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Semester`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row bg-secondary">
                                            <button onClick={() => { getIG() }} className='btn btn-success btn-block'>Search</button>

                                        </div>

                                    </section>
                                </main>

                            </div>
                        </>
                    }


                </>

                :

                <Loading />

            }

        </>

    );
}