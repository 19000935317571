import React, { useState } from "react"
import { Modal, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IMSelect from "../select/IMSelect";


export default function MigrateClassSectionModal(props) {
    const { register, handleSubmit, setValue } = useForm()
    const [filteredStudentCategory, setFilteredStudentCategory] = useState()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };

    const selectCategoryChange = name => event => { setFilteredStudentCategory(event) }



    const imSelectDropdownStudentCategory = () => {
        return (
            <>
                {props.studentCategorySelect &&
                    <IMSelect
                        data={props.studentCategorySelect}
                        onHandleChange={selectCategoryChange()}
                        refClassContainer=""
                        name="StudentCategory"
                        isRequired={false}
                        withLabel={false}
                        label="Student Category"
                        placeHolder="Select Student Category"
                        forwardRef={setValue}
                        selectedId={filteredStudentCategory === undefined ? filteredStudentCategory === undefined : filteredStudentCategory}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }




    const onSubmit = (formValues) => {
        let migrationData = {
            studentCategoryId: filteredStudentCategory

        }
        props.onSubmitStudentHandler(migrationData)
        console.log(migrationData)
        props.handleClose()
    }
    console.log(props.openMigrateModal)
    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="card p -5" style={style}>
                <div className="card-header"> <h3>Migrate Class Section</h3> </div>
                <div className="card-body">

                    <div className="col bg-white mr-3 my-0 p-0">{imSelectDropdownStudentCategory()}</div>

                    <form onSubmit={handleSubmit(onSubmit)} method="post">
                        {/* <input
                            className="form-control"
                            name="studentNo"
                            placeholder="Student No."
                            ref={register({
                                required: false
                            })}
                        /> */}
                        <button type="submit" className="mt-5 mr-5 btn btn-primary ">Migrate</button>
                        <button type="button" onClick={() => { props.handleClose() }} className="mt-5 btn btn-secondary ">Exit</button>
                    </form>


                </div>
            </Box>


        </Modal>
    )
}