export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_SCHEDULE: 'SELECTED-ASSESSMENT-SCHEDULE',
    GET_ASSESSMENT_SCHEDULES: 'GET-ASSESSMENT-SCHEDULES',
    GET_ASSESSMENT_SCHEDULE: 'GET-ASSESSMENT-SCHEDULE-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-SCHEDULE',
    UPDATE: 'UPDATE-ASSESSMENT-SCHEDULE',
    DELETE: 'DELETE-ASSESSMENT-SCHEDULE',
    LIST_LINK_TO_PARENT: '/admin/enrollment/assessment-of-fees',
    LIST_LINK: '/admin/enrollment/assessment-of-fee-schedules',
    MORE_LINK: '/admin/enrollment/assessment-of-fee-schedule-details/',
    CREATE_LINK: '/admin/enrollment/create-assessment-of-fee-schedules/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-of-fee-schedules/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-of-fee-schedules/'
}