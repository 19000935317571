import instance from "../../../../../apis/local/systemAPI";

export const getUserInRole = async () => {
    const result = await instance.get("api/role/user-in-role");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollment = async () => {
    const result = await instance.get("api/enrollments/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async (q) => {
    const result = await instance.post("api/references/semesters/q", q);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async (q) => {
    const result = await instance.post("api/references/schoolyears/q", q);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudent = async () => {
    const result = await instance.get("api/students/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentQuery = async (con) => {
    const result = await instance.post("api/enrollments/q", con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollmentCurrentWeekReport = async () => {
    const result = await instance.get("api/enrollments/currentWeekEnrolledReport");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentLastWeekReport = async () => {
    const result = await instance.get("api/enrollments/lastWeekEnrolledReport");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollmentYearLevelStatistic = async (val) => {
    const result = await instance.post("api/enrollments/yearLevelStatistic", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEnrollmentInstituteStatistic = async (val) => {
    const result = await instance.post("api/enrollments/instituteStatistic", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




