import instance from '../../../../../apis/local/systemAPI';

/************* TO BE DELETED ************** */

export const instituteDropdown = async () => {
    const result = await instance.post("api/student/student-attendance/instituteDropdown");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const instructorDropdown = async (json) => {
    const result = await instance.post("api/student/student-attendance/instructorDropdown",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceSheet = async (json) => {
    const result = await instance.post("api/student/student-attendance/attendanceSheet",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

/**************************************************** */

export const addPromissoryNote = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/addPromissoryNote", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const autoCompleteRefNo = async (input) => {
    const result = await instance.post("api/accounting/promissory-notes/autoCompleteRefNo", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPromissoryNote = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/getPromissoryNote", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const dropdownValues = async (input) => {
    const result = await instance.post("api/accounting/promissory-notes/dropdownValues", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const autoCompleteStudents = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/autoCompleteStudents", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const statusDropdown = async () => {
    const result = await instance.post("api/accounting/promissory-notes/statusDropdown");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDetails = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/getStudentDetails", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getReportType = async (json) => {
    const result = await instance.get("api/student/class-record/getReportType", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// Reused from References
export const getActiveSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// Reused from References
export const getActiveSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// export const getGradingPeriod = async () => {
//     const result = await instance.get(`api/references/grading-period/`);
//     if (result.status === 200 || result.status === 201) {
//         return result.data;
//     }
// }

export const getSubjectSchedules = async (employeeId,schoolYear,semester) => {
    const result = await instance.get(`api/student/class-record/getSubjectSchedules/${employeeId}/${schoolYear}/${semester}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClassRecordRawGrades = async (employeeId,schoolYear,semester,instructionalGuideId,gradingPeriodId,isLec) => {
    const result = await instance.get(`api/student/class-record/getClassRecordRawGrades/${employeeId}/${schoolYear}/${semester}/${instructionalGuideId}/${gradingPeriodId}/${isLec}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClassRecordHeaders = async (schoolYear,semester,instructionalGuideId,gradingPeriodId) => {
    const result = await instance.get(`api/student/class-record/getClassRecordHeaders/${schoolYear}/${semester}/${instructionalGuideId}/${gradingPeriodId}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFinalRatingHeaders = async (schoolYear,semester,subjectScheduleDetailId,gradingPeriodId) => {
    const result = await instance.get(`api/student/class-record/getFinalRatingHeaders/${schoolYear}/${semester}/${subjectScheduleDetailId}/${gradingPeriodId}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClassRecordFinalRating = async (schoolYear,semester,subjectScheduleDetailId,gradingPeriodId,isLec) => {
    const result = await instance.get(`api/student/class-record/getClassRecordFinalRating/${schoolYear}/${semester}/${subjectScheduleDetailId}/${gradingPeriodId}/${isLec}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClassRecordFinalRatingByGradingPeriod = async (schoolYear,semester,subjectScheduleDetailId,gradingPeriodId,isLec) => {
    const result = await instance.get(`api/student/class-record/getClassRecordFinalRatingByGradingPeriod/${schoolYear}/${semester}/${subjectScheduleDetailId}/${gradingPeriodId}/${isLec}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getclassRecordFinalRatingAll = async (schoolYear,semester,subjectScheduleDetailId,isLec) => {
    const result = await instance.get(`api/student/class-record/getclassRecordFinalRatingAll/${schoolYear}/${semester}/${subjectScheduleDetailId}/${isLec}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClassRecordRoomDetails = async (schoolYear,semester,subjectScheduleDetailId,isLec) => {
    const result = await instance.get(`api/student/class-record/getClassRecordRoomDetails/${schoolYear}/${semester}/${subjectScheduleDetailId}/${isLec}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradingPeriod = async () => {
    const result = await instance.get(`api/student/class-record/getClassRecordGradingPeriod`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}