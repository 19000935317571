import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSemester, getSchoolYear, getUser, getFacultyLoadingReport, getFacultyLoadingTableReport, getFacultyLoadingReportBySection,
    getFacultyLoadingReportByRoom
} from "./__hooks__";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import PrintFacultyLoadingReportBySection from "./PrintFacultyLoadingReportBySection";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";
import { useAppContext } from "../../../../contexts/useAppContext";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import { getInstitute } from "../EnrollmentReports/__hooks__";
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { makeStyles } from '@material-ui/core/styles';


const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

});


export default function ReportBySection(props) {
    const classes = useStyles();
    const { setValue } = useForm()
    const { state: { auth } } = useAppContext()
    const [results, setResults] = useState([])
    const [resultBySection, setResultBySection] = useState([])
    const [resultByRoom, setResultByRoom] = useState([])
    const [tableResults, setTableResults] = useState([])
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [instituteSelect, setInstituteSelect] = useState(null)
    const [userSelect, setUserSelect] = useState(null)


    const [filteredSem, setFilteredSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [filteredSy, setFilteredSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)
    const [filteredUser, setFilteredUser] = useState(null)
    const [filteredDepartment, setFilteredDepartment] = useState(null)

    const [onView, setOnView] = useState(false)


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectUserChange = name => event => { setFilteredUser(event) }
    const selectInstituteChange = name => event => { setFilteredDepartment(event) }


    const [isLoading, setIsLoading] = useState(false)


    // functions

    const onGetFacultyReport = async () => {
        setIsLoading(true)
        setOnView(true)
        // setTableResults([])
        await getFacultyLoadingTableReport({
            schoolYear: filteredSy,
            semesterId: filteredSem,
            employeeId: filteredUser,
            withSchoolYear: filteredSy != null ? true : false,
            withSemesterId: filteredSem != null ? true : false,
            withEmployeeId: filteredUser != null ? true : false
        })
            .then(res => {
                // setTableResults(res.data)
            })

        // await getFacultyLoadingReport({
        //     schoolYear: filteredSy,
        //     semesterId: filteredSem,
        //     employeeId: filteredUser,
        //     withSchoolYear: filteredSy != null ? true : false,
        //     withSemesterId: filteredSem != null ? true : false,
        //     withEmployeeId: filteredUser != null ? true : false
        // })
        //     .then(res => {
        //         setResults(res.data)

        //     })

        await getFacultyLoadingReportBySection({
            schoolYear: filteredSy,
            semesterId: filteredSem,
            employeeId: filteredUser,
            instituteId: filteredDepartment,
            withSchoolYear: filteredSy != null ? true : false,
            withSemesterId: filteredSem != null ? true : false,
            withEmployeeId: filteredUser != null ? true : false,
            withInstitute: filteredDepartment != null && filteredDepartment != 90 ? true : false
        })
            .then(res => {
                setResultBySection(res.data)
                setTableResults(res.tableData)

            })


        // await getFacultyLoadingReportByRoom({
        //     schoolYear: filteredSy,
        //     semesterId: filteredSem
        // })
        //     .then(res => {
        //         setResultByRoom(res.data)
        //     })



        setIsLoading(false)

    }


    const onReset = () => {
        setResults([])
        setResultBySection([])
        setResultByRoom([])
        setTableResults([])
        setFilteredSem(auth.data.schoolyears.enrollment.SemesterId)
        setFilteredSy(auth.data.schoolyears.enrollment.SchoolYear)
        setFilteredUser(null)
        setFilteredDepartment(null)
        setOnView(false)
    }

    useEffect(() => {
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')

        SelectCreator(getUser, setUserSelect, 'EmployeeId', 'FullName')




    }, [])




    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect !== null &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        isDisabled={resultBySection.length > 0}
                        selectedId={filteredSy === undefined ? filteredSy === undefined : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemesters = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? filteredSem === undefined : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        isDisabled={resultBySection.length > 0}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownFaculty = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={userSelect}
                        onHandleChange={selectUserChange()}
                        refClassContainer=""
                        name="faculty"
                        isRequired={false}
                        withLabel={false}
                        label="Faculty"
                        placeHolder="Select Employee"
                        forwardRef={setValue}
                        selectedId={filteredUser === undefined ? filteredUser === undefined : filteredUser}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownInstitute = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={instituteSelect}
                        onHandleChange={selectInstituteChange()}
                        refClassContainer=""
                        name="institute"
                        isRequired={false}
                        withLabel={false}
                        label="Department"
                        placeHolder="Department"
                        forwardRef={setValue}
                        isDisabled={resultBySection.length > 0}
                        selectedId={filteredDepartment === undefined ? filteredDepartment === undefined : filteredDepartment}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const columns = [
        { id: 'Institute', label: 'College', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Time', label: 'Time', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', label: 'Day', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'CreditUnit', label: 'Credit Unit', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LecLab', label: 'Lec / Lab', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'ClassStatus', label: 'Class Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Capacity', label: 'Capacity', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Instructor', label: 'Instructor', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]

    const tableProps = {
        recordId: 'SubjectScheduleDetailId',
        sortField: '',
        columns: columns,
        rows: tableResults,
        totalRecords: tableResults.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'facultyLoadingReport',
        parentId: null,
        tableTitle: 'List of Faculty Subjects',

    }



    console.log(results)
    console.log(resultByRoom)
    console.log(resultBySection)
    return (
        <>
            {semesterSelect && schoolYearSelect && userSelect ?

                <>
                    <main className="card">
                        <header className="card-header">
                            <h4 className="d-block">Report by Section</h4>
                            <div className="row">
                                {onView == false &&
                                    <>
                                        <section className="col">{imSelectDropdownSchoolYear()}</section>
                                        <section className="col">{imSelectDropdownSemesters()}</section>
                                        <section className="col">{imSelectDropdownInstitute()}</section>
                                    </>

                                }


                                {/* <section className="col">{imSelectDropdownFaculty()}</section> */}
                                {isLoading == false ?
                                    <>
                                        {onView == false &&
                                            <button onClick={() => { onGetFacultyReport() }} className="col btn btn-primary">View</button>
                                        }
                                    </>
                                    :
                                    ''
                                }


                                {resultBySection.length > 0 && isLoading == false ?

                                    <PrintFacultyLoadingReportBySection
                                        results={resultBySection}

                                    />
                                    :

                                    <>
                                        {onView == true && isLoading == true &&
                                            <button className="btn btn-secondary mx-3 btn-sm">
                                                Please wait ...

                                                <div className="spinner-border mx-5" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </button>
                                        }
                                    </>

                                }



                                {onView == true && resultBySection.length > 0 && isLoading == false ?
                                    <button onClick={() => { onReset() }} className="ml-5 btn btn-secondary">Reset</button>
                                    :
                                    ''
                                }

                            </div>
                        </header>
                        <section className="card-body">


                            {/* <IMTable tableProps={tableProps} /> */}

                            {resultBySection.length > 0 && resultBySection.map((res, index) => {
                                console.log(res.ClassSectionId)
                                console.log(res.EmployeeId)
                                console.log(res.Details)
                                return (
                                    <>
                                        <div className={`mt-5 mb-5 classSectionReport`} style={{ pageBreakInside: 'avoid' }}>

                                            <h6 className="mt-5"><span className="lead">Section: </span>{res.ClassSection} ({res.Institute})</h6>

                                            <hr />
                                            <TableContainer key={res.ClassSectionId} component={Paper} >
                                                <Table className={classes.table} size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow>
                                                            {/* <TableCell align="left">Class</TableCell> */}
                                                            <TableCell align="center">Code</TableCell>
                                                            <TableCell align="center">Subject</TableCell>
                                                            <TableCell align="center">Description</TableCell>
                                                            <TableCell align="center">Time</TableCell>
                                                            <TableCell align="center">Days</TableCell>
                                                            <TableCell align="center">Room</TableCell>
                                                            <TableCell align="center">Unit</TableCell>
                                                            <TableCell align="center">Lec/Lab</TableCell>
                                                            <TableCell align="center">Status</TableCell>
                                                            <TableCell align="center">Enr/Max</TableCell>
                                                            <TableCell align="center">Instructor</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {res.Details.map((row) => (
                                                            <>
                                                                <TableRow >
                                                                    {/* <TableCell align="left" >{row.Institute}</TableCell> */}
                                                                    <TableCell align="left">{row.ClassCode}</TableCell>
                                                                    <TableCell align="left">{row.SubjectCode}</TableCell>
                                                                    <TableCell align="left">{row.SubjectDescription}</TableCell>
                                                                    <TableCell align="center">{row.LecSchedules[0].LecTime}</TableCell>
                                                                    <TableCell align="center">{row.LecSchedules[0].LecDayCode}</TableCell>
                                                                    <TableCell align="center">{row.LecSchedules[0].LecRoom}</TableCell>
                                                                    <TableCell align="center">{row.CreditUnit}</TableCell>
                                                                    <TableCell align="center">{row.LecLab}</TableCell>
                                                                    <TableCell align="left">{row.ClassStatus}</TableCell>
                                                                    <TableCell align="left">{row.Capacity}</TableCell>
                                                                    <TableCell align="left">{row.Instructor}</TableCell>
                                                                </TableRow>

                                                                {row.LecSchedules.length > 1 &&
                                                                    row.LecSchedules.slice(1).map(lec => (
                                                                        <TableRow className="bg-secondary">
                                                                            {/* <TableCell align="left"></TableCell> */}
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="center">{lec.LecTime}</TableCell>
                                                                            <TableCell align="center">{lec.LecDayCode}</TableCell>
                                                                            <TableCell align="center">{lec.LecRoom}</TableCell>
                                                                            <TableCell align="center"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>

                                                                        </TableRow>
                                                                    ))


                                                                }

                                                                {row.LabSchedules.length > 0 &&
                                                                    row.LabSchedules.map(lab => (
                                                                        <TableRow style={{ backgroundColor: '#c0ddeb' }}>
                                                                            {/* <TableCell align="left" ></TableCell> */}
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="center">{lab.LabTime}</TableCell>
                                                                            <TableCell align="center">{lab.LabDayCode}</TableCell>
                                                                            <TableCell align="center">{lab.LabRoom}</TableCell>
                                                                            <TableCell align="center"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>
                                                                            <TableCell align="left"></TableCell>

                                                                        </TableRow>
                                                                    ))


                                                                }
                                                            </>

                                                        ))}
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>
                                            <section className="d-flex mt-5">
                                                <p className="mr-5">Total Unit: <span className="text-dark">{res.TotalCreditUnit}</span></p>
                                                <p className="mr-5" >Total Lec Hour: <span className="text-dark" >{res.TotalLecHours}</span></p>
                                                <p className="mr-5" >Total Lab Hour: <span className="text-dark" >{res.TotalLabHours}</span></p>
                                            </section>


                                        </div>

                                    </>
                                )
                            })}

                        </section>

                    </main>

                </>

                :

                <Loading />
            }

        </>
    )
}