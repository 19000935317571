import React, { useState, useEffect } from 'react';

const Notification = ({ message }) => {
    const [show, setShow] = useState(true);

    // Function to hide the notification after a delay
    useEffect(() => {
        const timer = setTimeout(() => {
            setShow(false);
        }, 3000); // Change 3000 to your desired delay (in milliseconds)

        return () => clearTimeout(timer);
    }, []);

    return (
        <>
            {show && (
                <div className="ml-5 alert alert-warning fade show col-4" role="alert">
                    {message}
                </div>
            )}
        </>
    );
};

export default Notification;
