import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getActiveSemester,
    getActiveSchoolYear, getInstitute, getCourse, getYearLevel, getEnrollmentListChedReport, getEnrollmentStatisticReport
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";

export default function EnrollmentStatistics(props) {
    const { setValue } = useForm();
    const [results, setResults] = useState([])
    const [chedResult, setChedResult] = useState([])




    const [filteredSem, setFilteredSem] = useState()
    const [filteredSy, setFilteredSy] = useState()
    const [filteredIns, setFilteredIns] = useState(null)
    const [filteredCourse, setFilteredCourse] = useState(null)
    const [filteredYearLevel, setFilteredYearLevel] = useState(null)
    const [filteredDateFrom, setFilteredDateFrom] = useState(moment().format("YYYY-MM-DD"))
    const [filteredDateTo, setFilteredDateTo] = useState(moment().add({ days: 1 }).format("YYYY-MM-DD"))

    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [instituteSelect, setInstituteSelect] = useState([])
    const [courseSelect, setCourseSelect] = useState([])
    const [yearLevelSelect, setYearLevelSelect] = useState([])


    const [activeSem, setActiveSem] = useState('1S')
    const [activeSy, setActiveSy] = useState('')
    const [excelFormat, setExcelFormat] = useState([])
    const [excelChedFormat, setExcelChedFormat] = useState([])
    const [isGenerationgReport, setIsGeneratingReport] = useState(false)

    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectYearLevelChange = name => event => { setFilteredYearLevel(event) }




    const onGetEnrollmentReport = async () => {
        await getEnrollmentStatisticReport()
            .then(res => {
                console.log(res)
                setIsGeneratingReport(false)
                setResults(res.data)
            })
            .catch(e => {
                setIsGeneratingReport(false)
            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }



    const onSubmit = (isChed) => {
        setIsGeneratingReport(true)
        onGetEnrollmentReport()
    }



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')
        SelectCreator(getCourse, setCourseSelect, 'CourseId', 'Code')
        SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')



        const exeGetActiveSy = async () => {
            await getActiveSchoolYear()
                .then(res => {
                    console.log(res)

                    setActiveSy(res.data[0].SchoolYear)
                    setFilteredSy(res.data[0].SchoolYear)
                })
        }

        const exeGetActiveSem = async () => {
            await getActiveSemester()
                .then(res => {
                    console.log(res)
                    setActiveSem(res.data[0].SemesterId)
                    setFilteredSem(res.data[0].SemesterId)
                })
        }

        exeGetActiveSy()
        exeGetActiveSem()

    }, [])


    useEffect(() => {
        if (results !== undefined) {
            let report = []
            for (let i = 0; i < results.length; i++) {
                report.push({
                    'School': `${results[i].School}`,
                    'College': `${results[i].College}`,
                    'Program': `${results[i].Program}`,
                    '2018': `${results[i].SY2018}`,
                    '2019': `${results[i].SY2019}`,
                    '2020': `${results[i].SY2020}`,
                    '2021': `${results[i].SY2021}`,
                    '2022': `${results[i].SY2022}`,
                    'Grand Total': `${results[i].GrandTotal}`

                })

            }
            console.log(report)
            setExcelFormat(report)
        }
    }, [results])


    const columns = [
        { id: 'School', label: 'School', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'College', label: 'College', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Program', label: 'Program', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY2018', label: '2018', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY2019', label: '2019', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY2020', label: '2020', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY2021', label: '2021', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY2022', label: '2022', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'GrandTotal', label: 'Grand Total', align: 'left', withSorting: true, hidden: false, disablePadding: false }

    ]


    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: results.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'applicantReport',
        parentId: null,
        tableTitle: '',
        onPage: 0,
        onRowsPerPage: 10

    }


    console.log(excelFormat)
    console.log(results)

    return (
        <>

            <div className="card">
                <div className="card-header font-weight-bold">
                    <h3 className="mb-0 d-block">  &nbsp;Ched Enrollment Report</h3>
                    <div className="ml-2 mt-2" >
                        <p className=" d-block mb-0">{`SY ${activeSy} - ${parseInt(activeSy) + 1} / ${activeSem}`}</p>

                    </div>

                </div>


                <ListFilters
                    tableTitle='Filter:'
                    withTitle={false}
                    type={'enrollmentStatisticReport'}
                    onSubmit={onSubmit}
                    results={results}
                    excelFormat={excelFormat}
                    enrollmentStatisticReport={1}
                    isGeneratingReport={isGenerationgReport}

                />

                <IMTableCustom tableProps={tableProps} />


            </div>


        </>
    )
}