import _ from 'lodash';
import { referenceTypes } from './types';

const reducer = (state, action) => {
    // console.log('state',state,'action',action);
    switch (action.type) {
        case referenceTypes.SELECTED_COMPUTATION_TYPE:
            return { ...state, data: action.data };
        case referenceTypes.CREATE:
            return { ...state, data: { ...state.data, [action.data.AssessmentComputationTypeId]: action.data } };
        case referenceTypes.GET_COMPUTATION_TYPES:
            return { ...state, data: { ..._.mapKeys(action.data, 'AssessmentComputationTypeId') } };
        case referenceTypes.UPDATE:
            return { ...state, data: { ...state.data, [action.data.AssessmentComputationTypeId]: action.data }, id: action.id }
        case referenceTypes.DELETE:
            return { ...state, data: { ..._.omit(state.data, action.data) } };
        case referenceTypes.CLEAR_LAST_UPDATE_ID:
            return { ...state, ...state.id, id: null }
        default:
            return state;
    }
}

export default reducer;