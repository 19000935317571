import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { Link } from 'react-router-dom';
import { accountTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getStudentTracer, getActiveSemester, getActiveSchoolYear, getActiveEnrollmentStatus } from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect"
import { useForm } from 'react-hook-form';
import SelectCreator from "../../../../../_metronic/functions/SelectCreator"
import { Select } from '@material-ui/core';

export default function StudentEnrollmentTracer(props) {
    const { state: { auth }, dispatch } = useAppContext();
    const { setValue } = useForm()
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(null);
    const [onSearch, setOnSearch] = useState(false);
    const [isLastUpdate, setIsLastUpdate] = useState(true);


    const [statusSelect, setStatusSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [semesterSelect, setSemesterSelect] = useState([])

    const [filteredStatus, setFilteredStatus] = useState('E')
    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)


    const selectHandleChange = name => event => {
        if (name == 'EnrollmentStatusId') {
            setFilteredStatus(event)
        } else if (name == 'SchoolYear') {
            setFilteredSy(event)
        } else if (name == 'SemesterId') {
            setFilteredSem(event)
        }
    }

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };


    useEffect(() => {
        SelectCreator(getActiveSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getActiveSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getActiveEnrollmentStatus, setStatusSelect, 'EnrollmentStatusId', 'EnrollmentStatus')

    }, [])


    useEffect(() => {
        const execute = async (val) => {
            await getStudentTracer(val)
                .then(response => {
                    // dispatch({ type: referenceTypes.GET_SCHOLARSHIPS, data: response.data, keyword: keyword, page: page, rowsPerPage: rowsPerPage });
                    setResults(response.data);
                    setTotalRecord(response.data.length > 0 ? response.data[0].TotalCount : 0);

                })
                .catch(error => {

                })
        }
        execute({
            enrollmentStatusId: filteredStatus,
            rowsPerPage: rowsPerPage,
            page: page,
            schoolYear: filteredSy,
            semesterId: filteredSem,
            search: keyword
        });


    }, [dispatch, page, rowsPerPage, keyword, filteredSem, filteredSy, filteredStatus])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY', label: 'School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'EnrollmentStatus', label: 'Enrollment Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'EnrollmentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'enrollmentTracer',
        parentId: null,
        tableTitle: 'Student Masterlist',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: 1,
        onIsLastUpdate: isLastUpdate,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: accountTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch
    }


    const imSelectDropdownReference = () => {

        return <IMSelect
            data={statusSelect}
            onHandleChange={selectHandleChange('EnrollmentStatusId')}
            refClassContainer=""
            name="EnrollmentStatus"
            isRequired={false}
            withLabel={false}
            label="Enrollment Status"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={filteredStatus}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />


    }

    const imSelectDropdownSemesters = () => {
        return <IMSelect
            data={semesterSelect}
            onHandleChange={selectHandleChange('SemesterId')}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={filteredSem}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }
    const imSelectDropdownSchoolYear = () => {
        return <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectHandleChange('SchoolYear')}
            refClassContainer=""
            name="SchoolYear"
            isRequired={false}
            withLabel={false}
            label="SchoolYear"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={filteredSy}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }

    console.log(results)
    return (
        <>
            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header ">
                    <div className="card-title ">
                        <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                    </div>




                </div>
                <section className='row p-5'>
                    <div className='p-5 col'>{imSelectDropdownReference()}</div>
                    <div className='p-5 col'>{imSelectDropdownSchoolYear()}</div>
                    <div className='p-5 col'>{imSelectDropdownSemesters()}</div>
                </section>
                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                    <IMTable tableProps={tableProps} />
                </div>
            </div>
        </>
    );
}