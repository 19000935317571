export const referenceTypes = {
    SELECTED_RELIGION: 'SELECTED-RELIGION',
    GET_RELIGIONS: 'GET-RELIGIONS',
    GET_RELIGION: 'GET-RELIGION-BY-ID',
    CREATE: 'CREATE-RELIGION',
    UPDATE: 'UPDATE-RELIGION',
    DELETE: 'DELETE-RELIGION',
    LIST_LINK: '/admin/references/religions',
    CREATE_LINK: '/admin/references/create-religions/',
    UPDATE_LINK: '/admin/references/update-religions/',
    DELETE_LINK: '/admin/references/delete-religions/'
}