import instance from "../../../../../apis/local/systemAPI";

export const subjectSummaryReport = async (val) => {
    const result = await instance.post(`api/schedule/details/subjectSummaryReport`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFacultyLoadingReport = async (val) => {
    const result = await instance.post(`api/registrar/facultyLoading/facultyReport`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFacultyLoadingReportBySection = async (val) => {
    const result = await instance.post(`api/registrar/facultyLoading/facultyReportBySection`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFacultyLoadingReportByRoom = async (val) => {
    const result = await instance.post(`api/registrar/facultyLoading/facultyReportByRoom`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFacultyLoadingTableReport = async (val) => {
    const result = await instance.post(`api/registrar/facultyLoading/facultyTableReport`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}





export const getSemester = async () => {
    const result = await instance.get(`api/references/semesters/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchoolYear = async () => {
    const result = await instance.get(`api/references/schoolyears/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getUser = async () => {
    const result = await instance.get(`api/user/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}