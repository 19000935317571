import React, { useState, useEffect } from "react"
import { useParams } from "react-router-dom"
import Form from "./Form"
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading"
import {
    getStudentCredentials, getStudentAccount, sendMail, createCredentialStudent, createStudentAccount,
    studentDocumentReport, createStudentNo
} from "./__hooks__"

import CryptoJS from 'crypto-js'
import { useAppContext } from "../../../../contexts/useAppContext"
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types"
import history from "../../../../history"
import { referenceTypes } from "./__hooks__/types"
export default function RecieveDoc() {
    const { state: { auth }, dispatch } = useAppContext()
    const { id } = useParams()
    const activeSem = auth.data.schoolyears.assessment.SemesterId
    const activeSy = auth.data.schoolyears.assessment.SchoolYear
    const [studentAccount, setStudentAccount] = useState(null)
    const [studentCredential, setStudentCredential] = useState(null)
    const [studentExistingCredentials, setStudentExistingCredentials] = useState([])
    const [documentReport, setDocumentReport] = useState(null)

    // utils

    const today = new Date();
    const month = today.getMonth() + 1
    const date = today.getFullYear() + '-' + month + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;

    const { v4: randomKey } = require('uuid')
    let passwordDefault = 'faith123'
    let accessTokenGenerated = ("ef" + CryptoJS.SHA256(CryptoJS.SHA256(randomKey()))).toLowerCase();
    let encyptionResult = ("*" + CryptoJS.SHA1(CryptoJS.SHA1(passwordDefault))).toUpperCase();

    // functions


    const createAccountHandler = async () => {

        let values = {
            userType: 'S',
            username: `${studentAccount[0].StudentNo}@firstasia.edu.ph`,
            password: studentAccount[0].EnryptedDefaultPassword,
            pass: studentAccount[0].Pass,
            emailAddress: studentAccount[0].EmailAddress,
            studentNo: studentAccount[0].StudentNo,
            firstName: studentAccount[0].FirstName,
            lastName: studentAccount[0].LastName,
            passwordRead: passwordDefault,
            accessToken: accessTokenGenerated,
            userKey: studentAccount[0].StudentKey,
            isActive: 1
        }


        await createStudentAccount({
            userType: 'S',
            userName: `${studentAccount[0].StudentNo}@firstasia.edu.ph`,
            password: studentAccount[0].EnryptedDefaultPassword,
            pass: studentAccount[0].Pass,
            emailAddress: studentAccount[0].EmailAddress,
            studentNo: studentAccount[0].StudentNo,
            firstName: studentAccount[0].FirstName,
            lastName: studentAccount[0].LastName,
            accessToken: accessTokenGenerated,
            studentId: studentAccount[0].StudentId,
            schoolYear: activeSy,
            semesterId: activeSem,
            studentCategoryId: studentAccount[0].StudentCategoryId,
            courseId: studentAccount[0].CourseId,
            yearLevelId: studentAccount[0].YearLevelId,
            instituteId: studentAccount[0].InstituteId,
            enrollmentStatusId: 'DR',
            enrollmentDate: dateTime,
            createdBy: auth.data.Username

        })
            .then(resp => {
                console.log(resp)
                if (resp.data[0].Result == 1) {
                    const values = {
                        userType: 'S',
                        username: resp.data[0].UserName,
                        password: studentAccount[0].EnryptedDefaultPassword,
                        pass: studentAccount[0].Pass,
                        emailAddress: studentAccount[0].EmailAddress,
                        studentNo: studentAccount[0].StudentNo,
                        firstName: studentAccount[0].FirstName,
                        lastName: studentAccount[0].LastName,
                        passwordRead: passwordDefault,
                        accessToken: accessTokenGenerated,
                        userKey: studentAccount[0].StudentKey,
                        isActive: 1,
                        isReceivedDoc: 1
                    }
                    onSendMail(values)
                } else {
                    return
                }

            })



    }

    const onSubmitAgain = async () => {
        let passwordDefault = 'faith123'
        let accessTokenGenerated = ("ef" + CryptoJS.SHA256(CryptoJS.SHA256(randomKey()))).toLowerCase();
        let encyptionResult = ("*" + CryptoJS.SHA1(CryptoJS.SHA1(passwordDefault))).toUpperCase();
        const values = {
            userType: 'S',
            username: `${studentAccount[0].StudentNo}@firstasia.edu.ph`,
            password: studentAccount[0].EnryptedDefaultPassword,
            pass: studentAccount[0].Pass,
            emailAddress: studentAccount[0].EmailAddress,
            studentNo: studentAccount[0].StudentNo,
            firstName: studentAccount[0].FirstName,
            lastName: studentAccount[0].LastName,
            passwordRead: passwordDefault,
            accessToken: accessTokenGenerated,
            userKey: studentAccount[0].StudentKey,
            isActive: 1,
            isReceivedDoc: 1
        }


        onSendMail(values)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Email Sent' });
        history.push('/admin/student')
        setTimeout(() => {
            history.push(`${referenceTypes.MORE_LINK}${id}`)
        }, 50);
    }

    const onCreateCredentialStudent = (data) => {
        console.log(data)
        const execute = async (d) => {
            await createCredentialStudent({
                studentId: studentAccount[0].StudentId,
                credentialId: data,
                dateReceived: dateTime,
                receivedBy: auth.data.Username
            })
                .then(async response => {
                    await createAccountHandler()
                        .then(async respp => {
                            await onGetStudentCredentials({ studentId: studentAccount[0].StudentId, status: studentAccount[0].StudentStatusId })
                                .then(docs => {
                                    onGetStudentReport({ studentId: studentAccount[0].StudentId })
                                })

                        })

                })

        }

        execute(data)
    }

    const onGetStudentAccount = async (data) => {
        await getStudentAccount(data)
            .then(res => {
                setStudentAccount(res.data)
            })

    }


    const onGetStudentCredentials = async (data) => {
        await getStudentCredentials(data)
            .then(res => {
                console.log(res)
                setStudentExistingCredentials(res.studentCredentials)
                setStudentCredential(res.data)
            })

    }


    const onGetStudentReport = async (data) => {
        await studentDocumentReport(data)
            .then(res => {
                console.log(res)
                setDocumentReport((prevData) => {
                    return res.data
                })
            })

    }

    const onSendMail = (data) => {
        const execute = async (value) => {
            await sendMail(value)
                .then(response => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Student Account Created' });
                    history.push('/admin/student')
                    setTimeout(() => {
                        history.push(`${referenceTypes.MORE_LINK}${id}`)
                    }, 1000);
                })
                .catch(error => {
                    console.log(error)
                })
        }

        execute(data)
    }


    const onStudentReportHandler = () => {
        setDocumentReport(null)
        onGetStudentReport({ studentId: studentAccount[0].StudentId })

    }


    useEffect(() => {
        onGetStudentAccount({ studentId: parseInt(id) })
    }, [])


    useEffect(() => {
        if (studentAccount !== null) {
            onGetStudentCredentials({ studentId: studentAccount[0].StudentId, status: studentAccount[0].StudentStatusId })
            onGetStudentReport({ studentId: studentAccount[0].StudentId })
        }
    }, [studentAccount])


    console.log(studentAccount)
    console.log(studentCredential)
    console.log(documentReport)
    return (
        <>
            {studentAccount !== null && studentCredential !== null

                ?
                <Form
                    studentAccount={studentAccount}
                    credential={studentCredential}
                    studentExistingCredentials={studentExistingCredentials}
                    dateTime={dateTime}
                    onCreateCredentialStudent={onCreateCredentialStudent}
                    createAccountHandler={createAccountHandler}
                    documentReport={documentReport}
                    onStudentReportHandler={onStudentReportHandler}
                />
                :
                <Loading />

            }

        </>
    )



}