import React, { useState, useEffect } from "react"
import { Modal, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IMSelect from "../select/IMSelect";
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';



export default function SubjectScheduleDetailModal(props) {
    const { register, handleSubmit, setValue, error } = useForm()
    const [data, setData] = useState({ subjectId: 0, classSectionId: 0, roomId: 0, labRoomId: 0 })
    const [rerun, setReRun] = useState(false)
    const [subSchedData, setSubSchedData] = useState()
    const [filteredSem, setFilteredSem] = useState()
    const [filteredSy, setFilteredSy] = useState()
    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: "40%",
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };
    useEffect(() => {
        setSubSchedData()
    }, [])

    const reset = () => {
        setSubSchedData()
        props.handleClose()
    }
    const onHandleChange = name => event => { setData({ ...data, [name]: event }) }
    const [switchState, setSwitchState] = useState({
        IsActive: props.subSchedDetailData === undefined ? false : props.subSchedDetailData[0].schedules.length > 0 && props.subSchedDetailData[0].schedules[0].LabTimeStart === null ? false : true
    });


    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    const onSubmit = (formValues) => {
        console.log(formValues)
        if (switchState.IsActive === false) {
            props.onUpdateSubSchedDetail({
                ...formValues,
                classCode: subSchedData[0].ClassCode,
                timeLabStart: null,
                timeLabEnd: null,
                labDayCode: null,
                labRoomId: null,
                labPercentageShare: null,
                subjectScheduleDetailId: subSchedData[0].SubjectScheduleDetailId,
                subjectScheduleId: subSchedData[0].SubjectScheduleId,
                lecPercentageShare: 100.00,
                schoolYear: subSchedData[0].SchoolYear,
                semesterId: subSchedData[0].SemesterId,
                subjectScheduleDetailListId: subSchedData[0].schedules[0].SubjectScheduleDetailListId
            })
        } else {
            props.onUpdateSubSchedDetail({
                ...formValues,
                classCode: subSchedData[0].ClassCode,
                subjectScheduleDetailId: subSchedData[0].SubjectScheduleDetailId,
                subjectScheduleId: subSchedData[0].SubjectScheduleId,
                schoolYear: subSchedData[0].SchoolYear,
                semesterId: subSchedData[0].SemesterId,
                lecPercentageShare: 100 - parseFloat(formValues.labPercentageShare),
                subjectScheduleDetailListId: subSchedData[0].schedules[0].SubjectScheduleDetailListId
            })

        }
        reset()
    }


    useEffect(() => {
        setSubSchedData(props.subSchedDetailData)
        // useEffect(() => {
        if (props.subSchedDetailData !== undefined && props.subSchedDetailData.length > 0) {
            if (props.subSchedDetailData[0].schedules.length > 0 && props.subSchedDetailData[0].schedules[0].LabTimeStart === null && props.subSchedDetailData[0].schedules[0].LabTimeEnd === null) {
                setSwitchState({ ...switchState, IsActive: false });

            } else {
                setSwitchState({ ...switchState, IsActive: true });

            }
        }
        // }, [props.subSchedDetailData])
    }, [props.subSchedDetailData])

    useEffect(() => {
        register({ name: 'subjectId' },
            { required: false });
    }, [register])

    useEffect(() => {
        register({ name: 'classSectionId' },
            { required: false });
    }, [register])

    useEffect(() => {
        register({ name: 'roomId' },
            { required: false });
    }, [register])
    useEffect(() => {
        register({ name: 'labRoomId' },
            { required: false });
    }, [register])

    console.log(subSchedData)
    // if (subSchedData !== undefined) {
    //     console.log(subSchedData[0].schedules[0].LabTimeStart)
    // }
    console.log(error)
    console.log(data)
    return (

        <>
            {subSchedData !== undefined &&

                <Modal
                    open={props.openMigrateModal}
                    onClose={props.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="card p -5" style={{ ...style, maxHeight: '80vh', overflowY: 'auto' }}>

                        <div className="card-header"> <h3>Subject Schedule Detail</h3> </div>
                        <div className="card-body">




                            <form onSubmit={handleSubmit(onSubmit)} method="post">
                                {/* <div className="form-group">
                                    <label className="col-form-label p-0">Class Code<span className="text-danger">*</span>  </label>
                                    <input
                                        className="form-control"
                                        defaultValue={subSchedData === undefined ? "" : subSchedData[0].ClassCode}
                                        name="classCode"
                                        placeholder="Class Code"
                                        ref={register({
                                            required: false
                                        })}
                                    />
                                </div> */}
                                <div className="row">
                                    <div className="form-group col-6">
                                        <IMSelect
                                            data={props.subSelect}
                                            onHandleChange={onHandleChange(`subjectId`)}
                                            refClassContainer=""
                                            name="subjectId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Subject"
                                            placeHolder="Select Subject"
                                            forwardRef={setValue}
                                            selectedId={data.subjectId === 0 ? subSchedData !== undefined ? subSchedData[0].SubjectId : data.subjectId : data.subjectId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>


                                    {/* <div className="form-group col-6">
                                        <label className="col-form-label p-0">Day Code <span className="text-danger">*</span>  </label>
                                        <input
                                            className="form-control"
                                            defaultValue={subSchedData === undefined ? "" : subSchedData[0].DayCode}
                                            name="dayCode"
                                            placeholder="Day Code"
                                            ref={register({
                                                required: false
                                            })}
                                        />
                                    </div> */}
                                    <div className="form-group mb-0 col">
                                        <label className="col-form-label">Day Code <span className="text-danger">*</span> :</label>
                                        <input
                                            className="form-control align-middle mb-5"
                                            onChange={(event) => {
                                                const input = event.target.value;
                                                const filteredInput = input.toUpperCase().replace(/[^MTWHFS]/g, '');
                                                event.target.value = filteredInput;
                                                setData({ ...data, 'dayCode': event.target.value });

                                            }}
                                            defaultValue={subSchedData === undefined ? "" : subSchedData[0].DayCode}
                                            type="text"
                                            name="dayCode"
                                            placeholder="Day Code"
                                            autoComplete="off"
                                            ref={register({
                                                required: false
                                            })}
                                        />
                                    </div>
                                </div>

                                <div className="row">
                                    <div className="form-group col-6">
                                        <IMSelect
                                            data={props.classSectionSelect}
                                            onHandleChange={onHandleChange(`classSectionId`)}
                                            refClassContainer=""
                                            name="classSectionId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Class Section"
                                            placeHolder="Class Section"
                                            forwardRef={setValue}
                                            selectedId={data.classSectionId === 0 ? subSchedData !== undefined ? subSchedData[0].ClassSectionId : data.classSectionId : data.classSectionId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>
                                    <div className="form-group col-6">
                                        <label className="col-form-label p-0">Capacity <span className="text-danger">*</span>  </label>
                                        <input
                                            className="form-control mt-2"
                                            defaultValue={subSchedData === undefined ? "" : subSchedData[0].Capacity}
                                            name="capacity"
                                            type="number"
                                            placeholder="Capacity"
                                            ref={register({
                                                required: false
                                            })}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="form-group col-6">
                                        <IMSelect
                                            data={props.roomSelect}
                                            onHandleChange={onHandleChange(`roomId`)}
                                            refClassContainer=""
                                            name="roomId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Rooms"
                                            placeHolder="Room"
                                            forwardRef={setValue}
                                            selectedId={data.roomId === 0 ? subSchedData !== undefined ? subSchedData[0].RoomId : data.roomId : data.roomId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>


                                </div>

                                <div className="row">
                                    <div className="form-group col-6">
                                        <label className="col-form-label">Time Start <span className="text-danger">*</span> :</label>

                                        <input
                                            className="form-control mb-5"
                                            type="time"
                                            name="timeStart"
                                            placeholder="Time Start"
                                            defaultValue={subSchedData === undefined ? "" : subSchedData[0].TimeStart}
                                            ref={register({
                                                required: false
                                            })}
                                            autoComplete="off"
                                            list="time-options1"
                                            onKeyDown={e => e.preventDefault()}
                                        />

                                        <datalist id="time-options1">
                                            <option value="07:00" />
                                            <option value="07:30" />
                                            <option value="08:00" />
                                            <option value="08:30" />
                                            <option value="09:00" />
                                            <option value="09:30" />
                                            <option value="10:00" />
                                            <option value="10:30" />
                                            <option value="11:00" />
                                            <option value="11:30" />
                                            <option value="12:00" />
                                            <option value="12:30" />
                                            <option value="13:00" />
                                            <option value="13:30" />
                                            <option value="14:00" />
                                            <option value="14:30" />
                                            <option value="15:00" />
                                            <option value="15:30" />
                                            <option value="16:00" />
                                            <option value="16:30" />
                                            <option value="17:00" />
                                            <option value="17:30" />
                                            <option value="18:00" />
                                            <option value="18:30" />
                                            <option value="19:00" />
                                            <option value="19:30" />
                                            <option value="20:00" />
                                            <option value="20:30" />
                                            <option value="21:00" />
                                            <option value="21:30" />

                                        </datalist>

                                    </div>
                                    <div className="form-group col-6">
                                        <label className="col-form-label">Time End <span className="text-danger">*</span> : </label>

                                        <input
                                            className="form-control mb-5"
                                            type="time"
                                            name="timeEnd"
                                            placeholder="Time End"
                                            defaultValue={subSchedData === undefined ? "" : subSchedData[0].TimeEnd} ref={register({
                                                required: false
                                            })}
                                            autoComplete="off"
                                            list="time-option2"
                                            onKeyDown={e => e.preventDefault()}


                                        />


                                        <datalist id="time-options2">
                                            <option value="07:00" />
                                            <option value="07:30" />
                                            <option value="08:00" />
                                            <option value="08:30" />
                                            <option value="09:00" />
                                            <option value="09:30" />
                                            <option value="10:00" />
                                            <option value="10:30" />
                                            <option value="11:00" />
                                            <option value="11:30" />
                                            <option value="12:00" />
                                            <option value="12:30" />
                                            <option value="13:00" />
                                            <option value="13:30" />
                                            <option value="14:00" />
                                            <option value="14:30" />
                                            <option value="15:00" />
                                            <option value="15:30" />
                                            <option value="16:00" />
                                            <option value="16:30" />
                                            <option value="17:00" />
                                            <option value="17:30" />
                                            <option value="18:00" />
                                            <option value="18:30" />
                                            <option value="19:00" />
                                            <option value="19:30" />
                                            <option value="20:00" />
                                            <option value="20:30" />
                                            <option value="21:00" />
                                            <option value="21:30" />
                                        </datalist>
                                    </div>
                                </div>
                                <div className="row">
                                    {/* <div className="form-group col-6">
                                        <label className="col-form-label p-0">Lecture Percentage Share <span className="text-danger">*</span>  </label>
                                        <input
                                            className="form-control mt-2"
                                            defaultValue={subSchedData === undefined ? null : subSchedData[0].LecPercentageShare}
                                            name="lecPercentageShare"
                                            type="number"
                                            placeholder="Lecture Percentage Share"
                                            ref={register({
                                                required: false
                                            })}
                                        />
                                    </div> */}

                                </div>

                                <hr />

                                <div className="row">
                                    <div className="form-group col-6">
                                        <FormControl component="fieldset" className="mr-auto">
                                            <FormControlLabel
                                                name="isActive"
                                                inputRef={register()}
                                                control={<Switch checked={switchState.IsActive}
                                                    onChange={switchHandleChange('IsActive')}
                                                    value={switchState.IsActive} />}
                                                label="With Laboratory"

                                            />
                                        </FormControl>
                                    </div>

                                </div>



                                {switchState.IsActive === true ?
                                    <>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label className="col-form-label">Laboratory Time Start <span className="text-danger">*</span> : </label>

                                                <input
                                                    className="form-control mb-5"
                                                    type="time"
                                                    name="labTimeStart"
                                                    placeholder="Time End"
                                                    defaultValue={subSchedData === undefined ? "" : subSchedData[0].schedules[0].LabTimeStart} ref={register({
                                                        required: false
                                                    })}
                                                    autoComplete="off"
                                                    list="time-options8"
                                                    onKeyDown={e => e.preventDefault()}


                                                />


                                                <datalist id="time-options8">
                                                    <option value="07:00" />
                                                    <option value="07:30" />
                                                    <option value="08:00" />
                                                    <option value="08:30" />
                                                    <option value="09:00" />
                                                    <option value="09:30" />
                                                    <option value="10:00" />
                                                    <option value="10:30" />
                                                    <option value="11:00" />
                                                    <option value="11:30" />
                                                    <option value="12:00" />
                                                    <option value="12:30" />
                                                    <option value="13:00" />
                                                    <option value="13:30" />
                                                    <option value="14:00" />
                                                    <option value="14:30" />
                                                    <option value="15:00" />
                                                    <option value="15:30" />
                                                    <option value="16:00" />
                                                    <option value="16:30" />
                                                    <option value="17:00" />
                                                    <option value="17:30" />
                                                    <option value="18:00" />
                                                    <option value="18:30" />
                                                    <option value="19:00" />
                                                    <option value="19:30" />
                                                    <option value="20:00" />
                                                    <option value="20:30" />
                                                    <option value="21:00" />
                                                    <option value="21:30" />
                                                </datalist>
                                            </div>
                                            <div className="form-group col-6">
                                                <label className="col-form-label">Laboratory Time End <span className="text-danger">*</span> : </label>

                                                <input
                                                    className="form-control mb-5"
                                                    type="time"
                                                    name="labTimeEnd"
                                                    placeholder="Time End"
                                                    defaultValue={subSchedData === undefined ? "" : subSchedData[0].schedules[0].LabTimeEnd} ref={register({
                                                        required: false
                                                    })}
                                                    autoComplete="off"
                                                    list="time-options4"
                                                    onKeyDown={e => e.preventDefault()}


                                                />


                                                <datalist id="time-options4">
                                                    <option value="07:00" />
                                                    <option value="07:30" />
                                                    <option value="08:00" />
                                                    <option value="08:30" />
                                                    <option value="09:00" />
                                                    <option value="09:30" />
                                                    <option value="10:00" />
                                                    <option value="10:30" />
                                                    <option value="11:00" />
                                                    <option value="11:30" />
                                                    <option value="12:00" />
                                                    <option value="12:30" />
                                                    <option value="13:00" />
                                                    <option value="13:30" />
                                                    <option value="14:00" />
                                                    <option value="14:30" />
                                                    <option value="15:00" />
                                                    <option value="15:30" />
                                                    <option value="16:00" />
                                                    <option value="16:30" />
                                                    <option value="17:00" />
                                                    <option value="17:30" />
                                                    <option value="18:00" />
                                                    <option value="18:30" />
                                                    <option value="19:00" />
                                                    <option value="19:30" />
                                                    <option value="20:00" />
                                                    <option value="20:30" />
                                                    <option value="21:00" />
                                                    <option value="21:30" />
                                                </datalist>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="form-group col-6">
                                                <IMSelect
                                                    data={props.roomSelect}
                                                    onHandleChange={onHandleChange(`labRoomId`)}
                                                    refClassContainer=""
                                                    name="labRoomId"
                                                    isRequired={false}
                                                    withLabel={true}
                                                    label="Rooms"
                                                    placeHolder="Room"
                                                    forwardRef={setValue}
                                                    selectedId={data.labRoomId === 0 ? subSchedData !== undefined ? subSchedData[0].LabRoomId : data.labRoomId : data.labRoomId}
                                                    refClassName={`text-center`}
                                                    withDescription={false}
                                                    //description={`Enrollment Status`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                            {/* <div className="form-group col-6">
                                                <label className="col-form-label p-0">Day Code <span className="text-danger">*</span>  </label>
                                                <input
                                                    className="form-control"
                                                    defaultValue={subSchedData === undefined ? "" : subSchedData[0].LabDayCode}
                                                    name="labDayCode"
                                                    placeholder="Day Code"
                                                    ref={register({
                                                        required: false
                                                    })}
                                                />
                                            </div> */}
                                            <div className="form-group mb-0 col">
                                                <label className="col-form-label">Day Code <span className="text-danger">*</span> :</label>
                                                <input
                                                    className="form-control align-middle mb-5"
                                                    onChange={(event) => {
                                                        const input = event.target.value;
                                                        const filteredInput = input.toUpperCase().replace(/[^MTWHFS]/g, '');
                                                        event.target.value = filteredInput;
                                                        setData({ ...data, 'dayCode': event.target.value });

                                                    }}
                                                    defaultValue={subSchedData === undefined ? "" : subSchedData[0].LabDayCode}
                                                    type="text"
                                                    name="labDayCode"
                                                    placeholder="Day Code"
                                                    autoComplete="off"
                                                    ref={register({
                                                        required: false
                                                    })}
                                                />
                                            </div>

                                        </div>


                                        <div className="row">
                                            <div className="form-group col-6">
                                                <label className="col-form-label p-0">Laboratory Percentage Share <span className="text-danger">*</span>  </label>
                                                <input
                                                    className="form-control mt-2"
                                                    defaultValue={subSchedData === undefined ? 0.00 : subSchedData[0].LabPercentageShare}
                                                    name="labPercentageShare"
                                                    type="number"
                                                    placeholder="Laboratory Percentage Share"
                                                    ref={register({
                                                        required: false
                                                    })}
                                                />
                                            </div>

                                        </div>

                                    </>

                                    :
                                    ""


                                }


                                <button type="submit" className="mt-5 mr-5 btn btn-primary ">Save</button>
                                <button type="button" onClick={() => { reset() }} className="mt-5 btn btn-secondary ">Exit</button>
                            </form>


                        </div>
                    </Box>


                </Modal>
            }




        </>


    )
}