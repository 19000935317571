import instance from "../../../../../apis/local/systemAPI";


export const getScholarship = async (condition) => {
    const result = await instance.post("api/references/scholarships/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const searchStudentScholarship = async (data) => {
    const result = await instance.post("api/enrollments/searchScholarship", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createStudentDiscount = async (formValues) => {
    const result = await instance.post("api/student/discounts/addUpdate", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createStudentHistory = async (formValues) => {
    const result = await instance.post("api/student/student-change-history", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateStudentDiscount = async (id, formValues) => {
    const result = await instance.patch(`api/student/discounts/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentSearchHistory = async (condition) => {
    const result = await instance.post("api/student/student-change-history/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentScholarHistory = async (condition) => {
    const result = await instance.post("api/student/student-change-history/studentScholarshipHistory", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentAddsOn = async (condition) => {
    const result = await instance.post("api/enrollment/account/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createEnrollmentAddOn = async (formValues) => {
    const result = await instance.post("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const updateEnrollmentAddOn = async (id, formValues) => {
    const result = await instance.patch(`api/enrollment/account/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const deleteEnrollmentAddOn = async (id) => {
    const result = await instance.delete(`api/enrollment/account/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters",);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentLaboratoryComputation = async (values) => {
    const result = await instance.post("api/assessment/lab-computation/", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteStudentDiscount = async (id) => {
    const result = await instance.delete(`api/student/discounts/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getScholarshipSelect = async (val) => {
    const result = await instance.post("api/references/scholarships/scholarshipSelection", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const unTagScholarship = async (val) => {
    const result = await instance.post("api/references/scholarships/scholarshipUntag", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}