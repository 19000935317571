import instance from '../../../../../../../apis/local/systemAPI';

// START OF ASSESSMENT TEMPLATE DETAIL OTHER
export const getActiveAssessmentTemplateDetailOthers = async () => {
  const result = await instance.get("api/assessment/template-detail-others/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateDetailOthersByAssessmentTemplateDetailId = async (id) => {
  const params = {
    "assessmentTemplateDetailId": id === undefined ? null : id
  }
  var result = await instance.post('api/assessment/template-detail-others/q', params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateOtherRate = async (condition) => {
  const result = await instance.post("api/assessment/rate/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getAssessmentTemplateDetail = async (condition) => {
  const result = await instance.post("api/assessment/template-details/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectCategory = async () => {
  const result = await instance.get("api/references/subject-categories/");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchoolYear = async () => {
  const result = await instance.get("api/references/schoolyears");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSemester = async () => {
  const result = await instance.get("api/references/semesters");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createAssessmentTemplateDetailOther = async (formValues) => {
  const result = await instance.post("api/assessment/template-detail-others", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createAssessmentTemplateRate = async (formValues) => {
  const result = await instance.post("api/assessment/rate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplateDetailOther = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/template-detail-others/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplateRate = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/rate/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteAssessmentTemplateDetailOther = async (id) => {
  const result = await instance.delete(`api/assessment/template-detail-others/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteAssessmentTemplateRate = async (id) => {
  const result = await instance.delete(`api/assessment/rate/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF ASSESSMENT TEMPLATE DETAIL OTHER