import { number } from "prop-types";
import React, { useRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from "../../../../../_metronic/_assets/logo/FaithCollegesPNG.png"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.png"
import moment from "moment";

export default function AssessmentFormWithDiscount(props) {
    let componentRef = useRef();
    const { subjectScheduleDetail, student, currentDate, encoder, assessmentTemplate,
        finalOtherFees, finalOtherFeesSpecs, finalLabFees, labCount, labCode,
        tuitionFee, regFee, miscFee, interest, enrollment, totalUnit, fees, paymentSchedule,
        totalFee, studentDiscount, studentTotalDiscount, total, totalWithoutOther, totalOtherFee,
        studentPayment, studentTotalCreditUnits, totalAddOns } = props

    console.log(finalOtherFeesSpecs)
    console.log(finalLabFees)
    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }

    const style = {

        border: 'none !important'


    }
    function convertTo12HourFormat(timeString) {
        // Split the time string into hours, minutes, and seconds
        const [hour, minute, second] = timeString.split(':').map(Number);

        // Determine whether it's AM or PM
        const period = hour >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        const twelveHour = hour % 12 || 12; // Ensure 12:00:00 is converted to 12:00:00 PM

        // Format the components with leading zeros
        const formattedHour = twelveHour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const formattedSecond = second.toString().padStart(2, '0');

        // Create the formatted time string
        const formattedTime = `${formattedHour}:${formattedMinute}:${formattedSecond} ${period}`;

        return formattedTime;
    }
    numberWithCommas(parseFloat(miscFee) + parseFloat(regFee) + parseFloat(tuitionFee) + parseFloat(finalLabFees) + parseFloat(interest))

    console.log(miscFee)
    console.log(regFee)
    console.log(tuitionFee)
    console.log(finalLabFees)
    console.log(interest)
    console.log()


    return (
        <>

            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn btn-link d-inline col">Print Assessment w/o Discount</button>}
                    content={() => componentRef}
                />


            </div>

            <div className="d-none">
                <div className="container" ref={(el) => (componentRef = el)}>

                    <main className="card border-0 container">
                        <section className="card-header text-center border-0">
                            <div className='text-center'>
                                <img style={{ width: '250px' }} src={FaithLogo} />
                                <h3 className="font-weight-bold mt-5">STUDENT ASSESSMENT FORM</h3>
                            </div>
                        </section>
                        <section className="card-body">
                            <div className="row">
                                <div className="col-8 float-left">
                                    <div> <p className="font-weight-bold d-inline">School Year: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p> <p className=" d-inline">{`${student.SY}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Semester: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${student.SemesterDesc}`}</p> </div>


                                </div>
                                <div className="col-4 float-right">
                                    <div> <p className="font-weight-bold d-inline">Assessment Date: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${currentDate}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Payment Mode: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className=" d-inline">{`${student.AssPaymentModeDesc}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Reference: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${student.AssessmentName}`}</p> </div>

                                </div>
                            </div>
                            <br />
                            <hr style={{ borderTop: '3px solid #36454F', fontWeight: 'bold', }} />

                            <div className="row">
                                <div className="col-8 float-left">
                                    <div className="row">
                                        <div className="col-1 p-0">
                                            <img className="float-right" style={{ width: '50px' }} src={ProfileLogo} />

                                        </div>
                                        <div className="col-11">
                                            <div> <h6 className="font-weight-bold d-inline">Student #: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp; </h6> <p className="lead d-inline">{`${student.StudentNo}`}</p> </div>
                                            <div> <h6 className="font-weight-bold d-inline">Name: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="lead d-inline">{`${student.StudentName}`}</p> </div>

                                        </div>

                                    </div>



                                </div>
                                <div className="col-4 float-right">
                                    <div> <h6 className="font-weight-bold d-inline">Program: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="lead d-inline">{`${student.CourseCode}`}</p> </div>
                                    <div> <h6 className="font-weight-bold d-inline">Year Level: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="lead d-inline">{`${student.YearLevelDesc}`}</p> </div>

                                </div>
                            </div>

                            <hr style={{ borderTop: '3px solid #36454F', fontWeight: 'bold', }} />

                            <br />


                            <section className="row">
                                <div className="col-7  border-right border-dark">
                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <thead className="text-center">
                                            <tr>
                                                <th className="text-left pl-0" scope="col">CODE</th>
                                                <th className="text-left" scope="col">SUBJECT</th>
                                                <th className="text-center" scope="col">UNITS</th>
                                                <th className="text-center" scope="col">DAYS</th>
                                                <th className="text-center" scope="col">TIME</th>
                                                <th className="text-left" scope="col">ROOM</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center pt-5">
                                            {subjectScheduleDetail.map(data => (
                                                <>
                                                    <tr className="" style={style}>
                                                        <th className="p-0 text-left" scope="row">{data.ClassCode}</th>
                                                        <td className="p-0 text-left"  >{data.SubjectCode}</td>
                                                        <td className="p-0 text-center"  >{data.Units}</td>

                                                        {data.schedules.length > 0 ? (
                                                            <>
                                                                <td className="p-0 text-center">{data.schedules[0].DayCode}</td>
                                                                <td className="p-0 text-center">{`${data.schedules[0].TimeStartDesc} - ${data.schedules[0].TimeEndDesc}`}</td>
                                                            </>
                                                        ) : (
                                                            <>
                                                                <td className="p-0 text-center">N/A</td>
                                                                <td className="p-0 text-center">N/A</td>
                                                            </>
                                                        )}
                                                        <td className="p-0 text-left"  >{data.schedules[0].Room}</td>
                                                    </tr>
                                                    {data.schedules.length > 0 && data.schedules.map(d => {


                                                        return (
                                                            <>
                                                                {d.LabTimeStart !== null &&
                                                                    <tr style={{ marginTop: '2%' }} >
                                                                        <th className="" scope="row" ></th>
                                                                        <td ></td>
                                                                        <td ></td>
                                                                        <td >{d.LabDayCode}</td>
                                                                        <td >{`${d.LabTimeStartDesc} - ${d.LabTimeEndDesc}`}</td>

                                                                        <td className="p-0 text-left">{d.LabRoom}</td>
                                                                        {/* <td >{data.SubCode}</td> */}
                                                                    </tr>

                                                                }

                                                            </>

                                                        )
                                                    })
                                                    }
                                                </>


                                            ))}
                                        </tbody>
                                    </table>
                                    <p className="mb-0 font-weight-bold">Total Units : {studentTotalCreditUnits}</p>
                                    <p className="mb-0 font-weight-bold">Total Hours : {totalUnit}</p>

                                    <br />
                                    <br />
                                    <br />
                                    {paymentSchedule !== null && paymentSchedule.length > 0 &&
                                        <>
                                            <table className="table table-hover mt-5 table-sm  table-borderless">
                                                <thead className="">
                                                    <tr>
                                                        <th className="text-left pl-0" scope="col">SCHEDULE</th>
                                                        <th className="text-left" scope="col">DUE DATE</th>
                                                        <th className="text-center" scope="col">AMOUNT</th>
                                                    </tr>
                                                </thead>
                                                <tbody className=" pt-5">
                                                    {paymentSchedule.map(data => (
                                                        <tr className="p-0" style={style}>
                                                            <th align="left" className="p-0" scope="row">{data.Period}</th>
                                                            <td className="p-0" align="left">{moment(data.Date).format('YYYY-MM-DD')}</td>
                                                            <td className="p-0" align="center">{parseFloat(data.Amount).toFixed(2)}</td>
                                                        </tr>
                                                    ))}
                                                    <tr className="p-0" style={style}>
                                                        <th align="left" className="p-0" scope="row">Other Fee</th>
                                                        <td className="p-0" align="left">{moment(paymentSchedule[paymentSchedule.length - 1].Date).format('YYYY-MM-DD')}</td>
                                                        <td className="p-0" align="center">{parseFloat(totalOtherFee).toFixed(2)}</td>
                                                    </tr>
                                                    <tr className="mt-0 bg-secondary" >

                                                        <th align="left" className="py-2 bg-secondary" scope="row" >Total:</th>
                                                        <td className="py-2  text-left bg-secondary" align="left" ></td>
                                                        <td className="py-2  text-left bg-secondary" align="left" style={{ borderTop: '1px solid gray', width: '10%' }}>₱{numberWithCommas(parseFloat(parseFloat(paymentSchedule[paymentSchedule.length - 1].Test) + parseFloat(totalOtherFee)).toFixed(2))}</td>

                                                    </tr>
                                                </tbody>
                                            </table>

                                        </>
                                    }
                                    <br />




                                </div>
                                <div className="col-5">
                                    <p className="font-weight-bold text-center" style={{ fontWeight: 'bold' }}>ASSESSMENT OF FEES</p>

                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <thead className="text-center">
                                            <tr>
                                                <th className="text-center pl-0" scope="col">CODE</th>
                                                <th className="text-left pl-0" scope="col">FEES</th>
                                                <th className="text-right pl-0" scope="col">AMOUNT</th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center pt-5">
                                            {fees.map(data => (
                                                <tr className="mt-5 border-0">
                                                    {data.CodeDesc == 'LB' ?
                                                        <>
                                                            {data.FinalAmount !== null &&
                                                                <th className="p-0 text-center" align="left" scope="row">{data.CodeDesc}</th>

                                                            }

                                                        </>
                                                        :
                                                        <th className="p-0 text-center" align="left" scope="row">{data.CodeDesc}</th>
                                                    }
                                                    {data.CodeDesc == 'TF' ?
                                                        <td className="p-0 text-left" align="left">{`${data.Description} (${totalUnit} @ ${data.Amount})`}</td>
                                                        :
                                                        <>
                                                            {data.CodeDesc == 'LB' ?
                                                                <>
                                                                    {data.FinalAmount !== null &&
                                                                        <td className="p-0 text-left" align="left">{data.Description}</td>

                                                                    }

                                                                </>
                                                                :
                                                                <td className="p-0 text-left" align="left">{data.Description}</td>
                                                            }
                                                        </>
                                                    }


                                                    {data.FinalAmount !== null &&
                                                        <td className="p-0 text-right" align="right">{parseFloat(data.FinalAmount).toFixed(2)}</td>

                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                    </table>
                                </div>

                            </section>
                            <br />
                            <br />

                            <section className="row justify-content-between">
                                <div className="col-7 row">
                                    <div className="col-8">
                                        <table className="table table-hover mt-5 table-sm  table-borderless">
                                            <thead className="">
                                                <tr>
                                                    <th className="pl-0 pb-0" scope="col">DISCOUNT/SCHOLARSHIP/VOUCHER</th>
                                                    <th className="pb-0" scope="col">AMOUNT</th>


                                                </tr>
                                            </thead>
                                            <tbody className="pt-5 mt-0 mr-3">
                                                {/* {studentDiscount.map(data => (
                                                    <tr className="" style={style}>
                                                        <td className="p-0" scope="row">{data.Scholarship}</td>
                                                        <td className="p-0" >{parseFloat(data.DiscountAmount).toFixed(2)}</td>
                                                    </tr>
                                                ))} */}
                                            </tbody>
                                        </table>
                                        <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5"><span className="float-left">Total:</span> ₱{numberWithCommas(parseFloat(0.00).toFixed(2))}</h6> </div>

                                    </div>
                                    <div className="col-4">

                                    </div>

                                </div>
                                <div className="col-5">
                                    <p className="text-center">ACCOUNT SUMMARY</p>
                                    <table className="table table-hover mt-5 table-sm  table-borderless">

                                        <tbody className=" pt-5 mt-0 container">
                                            <tr className="" style={style}>
                                                <td className="p-0" scope="row">Tuition Fee</td>
                                                <td className="p-0 text-right" >{parseFloat(tuitionFee + finalLabFees).toFixed(2)}</td>
                                            </tr>
                                            <tr className="" style={style}>
                                                <td className="p-0" scope="row">Registration Fee</td>
                                                <td className="p-0 text-right" >{parseFloat(regFee).toFixed(2)}</td>
                                            </tr>
                                            <tr className="" style={style}>
                                                <td className="p-0" scope="row">Miscellaneous Fee</td>
                                                <td className="p-0 text-right" >{parseFloat(miscFee).toFixed(2)}</td>
                                            </tr>
                                            <tr className="" style={{ borderTop: '1px solid gray' }}>
                                                <td className="p-0" scope="row">TOTAL FEES</td>
                                                <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas(parseFloat(tuitionFee + finalLabFees + regFee + miscFee).toFixed(2))}</td>
                                            </tr>
                                            {studentDiscount.map(data => (
                                                <tr className="" style={style}>
                                                    <td className="p-0" scope="row">DISCOUNT/SCHOLARSHIP/VOUCHER</td>
                                                    {/* <td className="p-0 text-right" >-{numberWithCommas(parseFloat(data.DiscountAmount).toFixed(2))}</td> */}
                                                </tr>
                                            ))}

                                            <tr className="" >
                                                <td className="p-0" scope="row">Non School Fees</td>
                                                <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas(parseFloat(totalOtherFee).toFixed(2))}</td>
                                            </tr>
                                            {totalAddOns != 0 && totalAddOns > 0 &&
                                                <tr className="" >
                                                    <td className="p-0" scope="row">Add Ons</td>
                                                    <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas(parseFloat(totalAddOns).toFixed(2))}</td>
                                                </tr>

                                            }
                                            {interest != 0 && interest > 0 &&
                                                <tr className="" >
                                                    <td className="p-0" scope="row">Interest</td>
                                                    <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas(parseFloat(interest).toFixed(2))}</td>
                                                </tr>

                                            }
                                            <tr className="" style={{ borderTop: '1px solid gray' }}>
                                                <td className="p-0" scope="row">TOTAL:</td>
                                                <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas(parseFloat(total).toFixed(2))}</td>
                                            </tr>
                                            <tr className="">
                                                <td className="p-0" scope="row">PAYMENT:</td>
                                                <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas(parseFloat(studentPayment).toFixed(2))}</td>
                                            </tr>
                                            <tr className="alert alert-secondary" style={{ borderTop: '1px solid gray' }}>
                                                <td className="p-0" scope="row">BALANCE</td>
                                                <td className="p-0 text-right" style={{ fontWeight: 'bold' }} >{numberWithCommas((parseFloat(total) - parseFloat(studentPayment)).toFixed(2))}</td>
                                            </tr>
                                        </tbody>
                                    </table>




                                </div>
                            </section>

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />

                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />


                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <br />
                            <section className="row">
                                <div className="col-6 text-left">
                                    <p className="mb-0">Printed by: {encoder}</p>
                                    <p>Date Printed: {currentDate}</p>

                                </div>
                            </section>

                        </section>


                    </main>


                </div>


            </div>



        </>
    )
}