import React, { useEffect, useMemo, useState } from "react";
import { Switch, Route } from 'react-router-dom';
import objectPath from "object-path";
// LAYOUT CONTEXT
import { useHtmlClassService } from "../../../../_core/MetronicLayout";
import { Header } from "../../../../components/header/Header";
import { HeaderMobile } from "../../../../components/header-mobile/HeaderMobile";
import { Aside } from "../../../../components/aside/Aside";
import { Footer } from "../../../../components/footer/Footer";
import { LayoutInit } from "../../../LayoutInit";
import { SubHeader } from "../../../../components/subheader/SubHeader";
import { QuickPanel } from "../../../../components/extras/offcanvas/QuickPanel";
import { QuickUser } from "../../../../components/extras/offcanvas/QuickUser";
import { ScrollTop } from "../../../../components/extras/ScrollTop";
// import { StickyToolbar } from "./extras/StickyToolbar";
import routes from '../../../../../../app/routes';
import history from '../../../../../../app/history';
import firebase from '../../../../../../app/apis/firebase/firebase.config';
import { loginWithGoogle } from '../../../../../../app/modules/Auth/__hooks__';
import { useAppContext } from '../../../../../../app/contexts/useAppContext';
import { authTypes } from '../../../../../../app/modules/Auth/__hooks__/types';
import { login, userHasAccess, isRequiredPWChange } from '../../../../../../app/modules/Auth/__hooks__/';
import { componentTypes } from '../../../snackbar/__hooks__/types';
import CustomSnackbar from '../../../../components/snackbar/snackbar';
import PageNotFound from "../../../redirects/PageNotFound/PageNotFound";
import { NoAccess } from "../../forms/NoAccess";
import Profile from "../../../../../../app/modules/Admin/_Navigation/Profile/Profile"
import CryptoJS from "crypto-js";

export default function AdminLayout(props) {
  const { state: { auth, snackbar }, dispatch } = useAppContext();
  const [routePaths, setRoutePaths] = useState([])
  const [url, setUrl] = useState(history.location.pathname)


  const executeLogin = async (formValues) => {
    await login(formValues)

      .then(result => {
        if (result.success) {
          dispatch({ type: authTypes.LOGIN, data: result.data, loginType: 'L' });
        } else {
          history.push('/auth/login');
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
        }
      })
  }



  useEffect(() => {
    if (auth.loginType === 'L') {
      // LOCAL LOGIN
      if (!localStorage.getItem('Username') && !localStorage.getItem('Password')) {
        history.push('/auth/login');
      } else {

        if (!auth.isUserVerified) {
          const formValues = {
            emailAddress: localStorage.getItem('Username'),
            password: CryptoJS.AES.decrypt(localStorage.getItem('Password'), localStorage.getItem('AccessToken')).toString(CryptoJS.enc.Utf8)
          }
          console.log(CryptoJS.AES.decrypt(localStorage.getItem('Password'), localStorage.getItem('AccessToken')))
          console.log(formValues)
          if (formValues.emailAddress.trim() == '' || formValues.password.trim() == '') {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            executeLogin(formValues);
          }

        } else {

        }
      }
    } else if (auth.loginType === 'G') {
      // FIREBASE GOOGLE AUTH
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          const execute = async () => {
            const response = await loginWithGoogle(user.refreshToken, user.email);
            if (response.success) {
              dispatch({ type: authTypes.LOGIN_WITH_GOOGLE, accessToken: user.refreshToken, data: user.providerData[0], isAdmin: response.data.IsAdmin, roleId: response.data.RoleId, designation: response.data.Designation, defaults: response.defaults.data });
            }
          }
          execute();
        } else {
          // USER IS SIGNED OUT
          history.push('../auth/login');
        }
      });
    } else if (auth.loginType === 'F') {
      // FIREBASE FACEBOOK AUTH
    }
  }, [dispatch, auth])

  useEffect(() => {
    const url = history.location.pathname.split('/');
    const mainPath = url
      .slice(1)
      .map((part) => (isNaN(part) ? part : ':id'))
      .join('/');


    console.log(auth.data)
    console.log(url)
    console.log(mainPath)

    if (auth.data.UserId != undefined) {
      isRequiredPWChange({ userId: auth.data.UserId })
        .then(res => {
          console.log("isRequiredPWChange: ", );
          if (res.data.IsDefaultPassword == 1) {
            // setChangePassword(1);
            history.push({
              pathname: "/admin/user/profile",
              data: 1
            });
          }
        })
    }

    if (auth.data.UserId != undefined) {
      userHasAccess({ userId: auth.data.UserId, routePath: '/' + mainPath })
        .then(res => {
          console.log(res.data.HasAccess)
          if (res.data.HasAccess == 1) {
            setRoutePaths(() => {
              return routes
            })

          } else {
            history.push('/admin/NoAccess')
            setRoutePaths(() => {
              return (
                [
                  {
                    path: "/NoAccess",
                    name: "Page Not Found",
                    component: NoAccess,
                    layout: "/admin",
                    subLayout: "",
                    breadcrumbs: [{
                      title: "Page Not Found",
                      pathname: "/admin/NoAccess"
                    }]
                  }
                ]
              );
            })

          }
        })
      setRoutePaths(() => {
        return routes
      })
    }

  }, [auth, history.location.pathname])


  const getRoutes = routes => {
    console.log(auth)
    console.log(routePaths)
    // alert('TestROute')


    return (
      <>
        {routePaths &&
          <Switch>
            {routePaths.map((props, key) => {
              console.log(props)
              if (props.layout === "/admin") {
                console.log(props);
                console.log(key);
                return (
                  <Route
                    path={props.layout + props.subLayout + props.path}
                    component={props.component}
                    key={key}
                  />
                );
              } else {
                return null;
              }
            })}
          </Switch>
        }

      </>
    )



  };

  const uiService = useHtmlClassService();
  // LAYOUT SETTINGS (cssClasses/cssAttributes)
  const layoutProps = useMemo(() => {
    return {
      layoutConfig: uiService.config,
      selfLayout: objectPath.get(uiService.config, "self.layout"),
      asideDisplay: objectPath.get(uiService.config, "aside.self.display"),
      subheaderDisplay: objectPath.get(uiService.config, "subheader.display"),
      desktopHeaderDisplay: objectPath.get(
        uiService.config,
        "header.self.fixed.desktop"
      ),
      contentCssClasses: uiService.getClasses("content", true),
      contentContainerClasses: uiService.getClasses("content_container", true),
      contentExtended: objectPath.get(uiService.config, "content.extended")
    };
  }, [uiService]);


  console.log(routePaths)
  console.log(auth)
  console.log(history.location.pathname)
  console.log(routes)

  return (
    <>
      {routePaths &&
        <>
          {/*begin::Main*/}
          <HeaderMobile />
          <div className="d-flex flex-column flex-root">
            {/*begin::Page*/}
            <div className="d-flex flex-row flex-column-fluid page">
              {layoutProps.asideDisplay && (<Aside />)}
              {/*begin::Wrapper*/}
              <div className="d-flex flex-column flex-row-fluid wrapper" id="kt_wrapper">
                <Header />
                {/*begin::Content*/}
                <div
                  id="kt_content"
                  className={`content ${layoutProps.contentCssClasses} d-flex flex-column flex-column-fluid`}
                >
                  {layoutProps.subheaderDisplay && <SubHeader />}
                  {/*begin::Entry*/}
                  {!layoutProps.contentExtended && (
                    <div className="d-flex flex-column-fluid">
                      {/*begin::Container*/}
                      <div className="container-fluid">
                        {auth.isUserVerified && getRoutes(routePaths)}
                      </div>
                      {/*end::Container*/}
                    </div>
                  )}
                  {layoutProps.contentExtended && { props }}
                  {/*end::Entry*/}
                </div>
                {/*end::Content*/}
                <Footer />
              </div>
              {/*end::Wrapper*/}
            </div>
            {/*end::Page*/}
          </div>
          <QuickUser />
          <QuickPanel />
          {snackbar.hasMessage ? <CustomSnackbar /> : ''}
          <ScrollTop />
          {/* <StickyToolbar /> */}
          {/*end::Main*/}
          <LayoutInit />
        </>
      }

    </>
  );
}