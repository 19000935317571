import React, { useState, useEffect } from 'react';
import history from '../../../../../history';
import IMDialogSubmition from "../../../../../../_metronic/layout/components/custom/dialog/IMDialogSubmition"
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getClassSections, getSubjects, getRooms, getSubjectType, getSubjSchedDetails,
  updateSubjectScheduleDetail, updateSubjectScheduleDetailList,
  deleteSubjectScheduleDetail, deleteSubjectScheduleDetailList,
  getSubjectScheduleDetailsQuery, getSubjectScheduleQuery, getCurriculumSubjectFilter, SubjectScheduleDetailAddUpdate,
  SubjectScheduleDetailDelete, getFacultyMergerSelect, mergeFaculty, getYearLevelAndCourseId, deleteSched,
  SubjectScheduleDetailConflictCheck
} from './__hooks__';
import IMTableCollapsible from '../../../../../../_metronic/layout/components/custom/table/IMTableCollapsible'
import { useParams } from 'react-router-dom';
import momentjs from 'moment';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import MergeFacultyModal from '../../../../../../_metronic/layout/components/custom/modal/MergeFacultyModal';
import SelectCreator from '../../../../../../_metronic/functions/SelectCreator';
import NotificationModal from '../../../../../../_metronic/layout/components/custom/modal/NotificationModal';
import Loading from '../../../../../../_metronic/layout/components/custom/forms/Loading';





export default function List(props) {
  const { state: { auth }, dispatch } = useAppContext();
  const [results, setResults] = useState([]);
  const [selectedId, setSelectedId] = useState()
  const { id } = useParams();
  const subjectSchedule = props.location.data == undefined ? undefined : props.location.data
  const [classSection, setClassSection] = useState()
  const [classSectionSelect, setClassSectionSelect] = useState()
  const [subject, setSubject] = useState()
  const [subSelect, setSubSelect] = useState([{ tableId: '', code: 'Set subjects in Course Outline' }])
  const [room, setRooms] = useState()
  const [roomSelect, setRoomSelect] = useState()
  const [subjectType, setSubjectType] = useState()
  const [subjectTypeSelect, setSubjectTypeSelect] = useState()
  const [isConflict, setIsConflict] = useState(false)

  const [subSchedSemester, setSubSchedSemester] = useState(props.location.data == undefined ? undefined : props.location.data.SemesterId)
  const [subSchedSchoolYear, setSubSchedSchoolYear] = useState(props.location.data == undefined ? undefined : props.location.data.SchoolYear)
  const [startingCode, setStartingCode] = useState(props.location.data == undefined ? undefined : props.location.data.StartingClassCode)
  const [subSchedInstitute, setSubSchedInstitute] = useState()
  const [subSchedSySem, setSubSchedSySem] = useState()
  const [classCode, setClassCode] = useState()
  const [classCodeSelect, setClassCodeSelect] = useState([{ tableId: null, code: 'Unassign' }])


  const [merging, setMerging] = useState({ currentId: null, subjectScheduleDetailIdMerger: null })
  const [isNotAbleToMerge, setIsNotAbleToMerge] = useState(false)

  const [onOpenSubSchedDetail, setOnOpenSubSchedDetail] = useState(false)
  const [subSchedDetailData, setSubSchedDetailData] = useState()


  const [isOpenFacultyMerger, setIsOpenFacultyMerger] = useState(false)


  const [selectedYearLevel, setSelectedYearLevel] = useState(0)
  const [selectedCourseId, setSelectedCourseId] = useState(null)
  const [selectedInstituteId, setSelectedInstituteId] = useState(null)
  const [conflictClassCodes, setConflictClassCodes] = useState([])

  const [showDialog, setShowDialog] = useState({
    id: null,
    record: '',
    status: false
  });

  const exeAddSubSchedDetl = async (subSchedVal) => {
    await SubjectScheduleDetailAddUpdate(subSchedVal)
      .then(res => {
        console.log(res)
        if (res.success == false) {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: res.message });
          return
        } else {
          getFacultyMergeSelect()
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Subject schedule added successfully' });
          executeGetSubjectScheduleDetl(resultQuery)
          return
          console.log(res)
        }

      })
      .catch(e => {
        console.log(e)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 5000, snackBarMessage: e.message });
        return
      })

  }

  const handleClassSectionChange = async (classSectionId) => {
    setSelectedYearLevel(0)
    setSelectedInstituteId(null)
    setSelectedCourseId(null)
    await getYearLevelAndCourseId({ classSectionId: classSectionId })
      .then(res => {
        console.log(res)
        if (res.data.length > 0) {
          setSelectedYearLevel(res.data[0].YearLevelId)
          setSelectedInstituteId(res.data[0].InstituteId)
          setSelectedCourseId(res.data[0].CourseId)
        }

      })
  }

  if (subjectSchedule == undefined) {
    history.push(`/admin/references/subject-schedules`)
  }


  let resultQuery = { where: `WHERE SubjectScheduleId = ${parseInt(id)} ORDER BY ClassCode ASC` }

  const executeGetSubjectScheduleDetl = async (q) => {
    await getSubjectScheduleDetailsQuery(q)
      .then(async response => {
        if (response.success) {
          let resLength = response.data.length
          let nextIndex = resLength - 1
          console.log(resLength)
          console.log(response.data)
          setResults(response.data);
          // setSelectedYearLevel(response.data[0].YearLevelId)
          // setSelectedInstituteId(response.data[0].InstituteId)
          // setSelectedCourseId(response.data[0].CourseId)

          let chairCon = { where: `WHERE IsActive = 1 AND InstituteId IN ( ${auth.data.InstituteId} )  ORDER BY ClassSection` }
          SelectCreator(getClassSections, setClassSectionSelect, 'ClassSectionId', 'ClassSection',
            auth.data.UserType != 'CHR' ?
              {
                where: `WHERE IsActive = 1 AND InstituteId IN (
                          ${auth.data.UserType != 'ADM'
                    && auth.data.UserType != 'RGR'
                    && auth.data.UserType != 'RGD'
                    ? auth.data.InstituteId
                    :
                    resLength > 0
                      ? response.data[0].InstituteId
                      : subjectSchedule.InstituteId
                  } ) ORDER BY ClassSection`
              }
              : chairCon)
          return
        } else {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          return
        }
      })
      .catch(error => {
        console.log(error)
      })
  }


  const handleCloseSubSchedDetail = () => {
    setOnOpenSubSchedDetail(false)
    setSubSchedDetailData()
  }

  const getFacultyMergeSelect = async () => {
    await getFacultyMergerSelect({ schoolYear: subSchedSchoolYear, semesterId: subSchedSemester, subjectScheduleId: parseInt(id) })
      .then(res => {

        setClassCodeSelect((prevData) => {
          console.log(prevData)
          console.log(res.data)
          return [{ tableId: null, code: 'Unassign' }, ...res.data]
        })
      })
      .catch(e => {
        console.log(e)
      })
  }


  const onHandleDeleteSched = async (id) => {
    await deleteSched({ subjectScheduleDetailListId: id, createdBy: auth.data.Username })
      .then(res => {
        executeGetSubjectScheduleDetl(resultQuery)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 5000, snackBarMessage: 'Time Schedule deleted successfully' });

      })
  }


  const handleOpenSubSchedDetail = (subSchedDetailId, yl, sy, courseId) => {
    let query = { where: `WHERE SubjectScheduleDetailId = ${subSchedDetailId}` }
    const exe = async (q) => {
      await getSubjSchedDetails(q)
        .then(res => {
          console.log(res)
          setSubSchedDetailData(res.data)
        })
        .catch(e => {

        })
    }

    exe(query)
    getCurriculumSubjectFilter({ yearLevelId: yl, currentSchoolYear: sy, courseId: courseId })
      .then(data => {
        if (data.length > 0)
          setSubSelect(data)
        setOnOpenSubSchedDetail(true)

      })

  }


  const onUpdateSubSchedDetail = async (values, id, listId) => {

    console.log(values)
    let conflictVal = []
    for (let i = 0; i < values.length; i++) {
      if (values[i].isCheckConflict != 0) {
        if (values[i].isLab == 1) {
          conflictVal.push({
            schoolYear: values[i].schoolYear,
            semesterId: values[i].semesterId,
            timeStart: values[i].labTimeStart,
            timeEnd: values[i].labTimeEnd,
            dayCode: values[i].labDayCode,
            roomId: values[i].labRoomId,
            subjectScheduleDetailListId: values[i].subjectScheduleDetailListId,
            subjectId: values[i].subjectId,
            subjectScheduleId: values[i].subjectScheduleId,
            classSectionId: values[i].classSectionId
          })
        } else {
          conflictVal.push({
            schoolYear: values[i].schoolYear,
            semesterId: values[i].semesterId,
            timeStart: values[i].timeStart,
            timeEnd: values[i].timeEnd,
            dayCode: values[i].dayCode,
            roomId: values[i].roomId,
            subjectScheduleDetailListId: values[i].subjectScheduleDetailListId,
            subjectId: values[i].subjectId,
            subjectScheduleId: values[i].subjectScheduleId,
            classSectionId: values[i].classSectionId
          })
        }
      }

    }

    if (conflictVal.length > 0) {
      await SubjectScheduleDetailConflictCheck(conflictVal)
        .then(async res => {
          if (res.data.length == 0 || res.data[0].HasConflict == 0) {
            const updateSchedDetl = async (values, isMerge) => {
              await SubjectScheduleDetailAddUpdate(values)
                .then(async res => {
                  console.log(res)
                  await executeGetSubjectScheduleDetl(resultQuery)

                  if (res.success == false) {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: res.message });
                  } else {
                    await executeGetSubjectScheduleDetl(resultQuery)
                      .then(res => {
                        console.log(isNotAbleToMerge)
                        console.log(isMerge)
                        dispatch({
                          type: componentTypes.SHOW_SNACKBAR,
                          snackBarStatus: isMerge == null ? 'success' : isMerge == false ? 'error' : 'success',
                          snackBarDuration: 5000,
                          snackBarMessage: isMerge == null ? 'Subject schedule updated successfully' : isMerge == false ? 'Unable to merge' : 'Subject schedule updated successfully'
                        });
                        setIsNotAbleToMerge(null)
                      })

                  }

                })
                .catch(e => {
                  console.log(e)
                })
            }

            if (values.length > 0) {
              let subValues = []
              for (let i = 0; i < values.length; i++) {
                subValues.push({
                  subjectScheduleDetailId: values[0].subjectScheduleDetailId,
                  subjectScheduleDetailListId: values[i].subjectScheduleDetailListId,
                  subjectScheduleId: values[0].subjectScheduleId,
                  classCode: null,
                  subjectId: values[i].subjectId,
                  classSectionId: values[i].classSectionId,
                  capacity: values[i].capacity,
                  schoolYear: values[i].schoolYear,
                  semesterId: values[i].semesterId,
                  timeStart: values[i].timeStart,
                  timeEnd: values[i].timeEnd,
                  labTimeStart: values[i].labTimeStart,
                  labTimeEnd: values[i].labTimeEnd,
                  dayCode: values[i].dayCode,
                  roomId: values[i].roomId,
                  createdBy: auth.data.Username,
                  labRoomId: values[i].labRoomId,
                  labDayCode: values[i].labDayCode,
                  lecPercentageShare: values[i].lecPercentageShare,
                  labPercentageShare: values[i].labPercentageShare
                })
              }

              if (values[0].mergeSubjectScheduleDetailId != null) {
                await mergeFaculty({
                  subjectScheduleDetailIdMerger: values[0].mergeSubjectScheduleDetailId,
                  currentId: values[0].subjectScheduleDetailId,
                  mergeStatus: values[0].mergeStatus == 'isLec' ? 1 : values[0].mergeStatus == 'isLab' ? 2 : 3
                }).then(async res => {
                  console.log(res)
                  if (res.data[0].Result === 1) {
                    setIsNotAbleToMerge(true)
                    // await updateSchedDetl({ ...subValues, labTimeStart: res.data[0].LabTimeStart, labTimeEnd: res.data[0].LabTimeEnd, labRoomId: res.data[0].LabRoomId, labDayCode: res.data[0].LabDayCode }, true)
                    await updateSchedDetl(subValues, true)

                  } else {
                    setIsNotAbleToMerge(false)
                    await updateSchedDetl(subValues, false)
                  }
                })
                  .catch(e => {
                    console.log(e)
                  })

              } else {
                setIsNotAbleToMerge(null)
                await mergeFaculty({
                  subjectScheduleDetailIdMerger: values[0].mergeSubjectScheduleDetailId,
                  currentId: values[0].subjectScheduleDetailId,
                  mergeStatus: values[0].mergeStatus == 'isLec' ? 1 : values[0].mergeStatus == 'isLab' ? 2 : 3
                })
                // console.log(values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId)
                await updateSchedDetl(subValues, values[0].mergeSubjectScheduleDetailId == values[0].subjectScheduleDetailId ? 0 : null)

              }
            } else {
              const subValues = {
                subjectScheduleDetailId: values.subjectScheduleDetailId,
                subjectScheduleDetailListId: values.subjectScheduleDetailListId,
                subjectScheduleId: values.subjectScheduleId,
                classCode: null,
                subjectId: values.subjectId,
                classSectionId: values.classSectionId,
                capacity: values.capacity,
                schoolYear: values.schoolYear,
                semesterId: values.semesterId,
                timeStart: values.timeStart,
                timeEnd: values.timeEnd,
                labTimeStart: values.labTimeStart,
                labTimeEnd: values.labTimeEnd,
                dayCode: values.dayCode,
                roomId: values.roomId,
                createdBy: auth.data.Username,
                labRoomId: values.labRoomId,
                labDayCode: values.labDayCode,
                lecPercentageShare: values.labPercentageShare != null ? 100 - parseFloat(values.labPercentageShare) : null,
                labPercentageShare: values.labPercentageShare
              }




              if (values.mergeSubjectScheduleDetailId != null) {
                await mergeFaculty({
                  subjectScheduleDetailIdMerger: values.mergeSubjectScheduleDetailId,
                  currentId: values.subjectScheduleDetailId,
                  mergeStatus: values.mergeStatus == 'isLec' ? 1 : values.mergeStatus == 'isLab' ? 2 : 3
                }).then(async res => {
                  console.log(res)

                  if (res.data[0].Result === 1) {
                    setIsNotAbleToMerge(true)
                    await updateSchedDetl({ ...subValues, labTimeStart: null, labTimeEnd: null, labRoomId: null, labDayCode: null }, true)


                  } else {
                    setIsNotAbleToMerge(false)
                    await updateSchedDetl(subValues, false)
                  }
                })
                  .catch(e => {

                    console.log(e)
                  })

              } else {
                setIsNotAbleToMerge(null)
                await mergeFaculty({
                  subjectScheduleDetailIdMerger: values.mergeSubjectScheduleDetailId,
                  currentId: values.subjectScheduleDetailId,
                  mergeStatus: values.mergeStatus == 'isLec' ? 1 : values.mergeStatus == 'isLab' ? 2 : 3
                })
                console.log(values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId)
                await updateSchedDetl(subValues, values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId ? 0 : null)

              }
            }


          } else {
            setConflictClassCodes(res.ClassCodes)
            setIsConflict(true)
            // alert('have conflict')

          }
        })
    } else {
      const updateSchedDetl = async (values, isMerge) => {
        await SubjectScheduleDetailAddUpdate(values)
          .then(async res => {
            console.log(res)
            await executeGetSubjectScheduleDetl(resultQuery)

            if (res.success == false) {

              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: res.message });
            } else {
              await executeGetSubjectScheduleDetl(resultQuery)
                .then(res => {
                  console.log(isNotAbleToMerge)
                  console.log(isMerge)
                  dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: isMerge == null ? 'success' : isMerge == false ? 'error' : 'success',
                    snackBarDuration: 5000,
                    snackBarMessage: isMerge == null ? 'Subject schedule updated successfully' : isMerge == false ? 'Unable to merge' : 'Subject schedule updated successfully'
                  });
                  setIsNotAbleToMerge(null)
                })

            }

          })
          .catch(e => {
            console.log(e)
          })
      }

      if (values.length > 0) {
        let subValues = []
        for (let i = 0; i < values.length; i++) {
          subValues.push({
            subjectScheduleDetailId: values[0].subjectScheduleDetailId,
            subjectScheduleDetailListId: values[i].subjectScheduleDetailListId,
            subjectScheduleId: values[0].subjectScheduleId,
            classCode: null,
            subjectId: values[i].subjectId,
            classSectionId: values[i].classSectionId,
            capacity: values[i].capacity,
            schoolYear: values[i].schoolYear,
            semesterId: values[i].semesterId,
            timeStart: values[i].timeStart,
            timeEnd: values[i].timeEnd,
            labTimeStart: values[i].labTimeStart,
            labTimeEnd: values[i].labTimeEnd,
            dayCode: values[i].dayCode,
            roomId: values[i].roomId,
            createdBy: auth.data.Username,
            labRoomId: values[i].labRoomId,
            labDayCode: values[i].labDayCode,
            lecPercentageShare: values[i].lecPercentageShare,
            labPercentageShare: values[i].labPercentageShare
          })
        }

        if (values[0].mergeSubjectScheduleDetailId != null) {
          await mergeFaculty({
            subjectScheduleDetailIdMerger: values[0].mergeSubjectScheduleDetailId,
            currentId: values[0].subjectScheduleDetailId,
            mergeStatus: values[0].mergeStatus == 'isLec' ? 1 : values[0].mergeStatus == 'isLab' ? 2 : 3
          }).then(async res => {
            console.log(res)
            if (res.data[0].Result === 1) {
              setIsNotAbleToMerge(true)
              // await updateSchedDetl({ ...subValues, labTimeStart: res.data[0].LabTimeStart, labTimeEnd: res.data[0].LabTimeEnd, labRoomId: res.data[0].LabRoomId, labDayCode: res.data[0].LabDayCode }, true)
              await updateSchedDetl(subValues, true)

            } else {
              setIsNotAbleToMerge(false)
              await updateSchedDetl(subValues, false)
            }
          })
            .catch(e => {
              console.log(e)
            })

        } else {
          setIsNotAbleToMerge(null)
          await mergeFaculty({
            subjectScheduleDetailIdMerger: values[0].mergeSubjectScheduleDetailId,
            currentId: values[0].subjectScheduleDetailId,
            mergeStatus: values[0].mergeStatus == 'isLec' ? 1 : values[0].mergeStatus == 'isLab' ? 2 : 3
          })
          // console.log(values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId)
          await updateSchedDetl(subValues, values[0].mergeSubjectScheduleDetailId == values[0].subjectScheduleDetailId ? 0 : null)

        }
      } else {
        const subValues = {
          subjectScheduleDetailId: values.subjectScheduleDetailId,
          subjectScheduleDetailListId: values.subjectScheduleDetailListId,
          subjectScheduleId: values.subjectScheduleId,
          classCode: null,
          subjectId: values.subjectId,
          classSectionId: values.classSectionId,
          capacity: values.capacity,
          schoolYear: values.schoolYear,
          semesterId: values.semesterId,
          timeStart: values.timeStart,
          timeEnd: values.timeEnd,
          labTimeStart: values.labTimeStart,
          labTimeEnd: values.labTimeEnd,
          dayCode: values.dayCode,
          roomId: values.roomId,
          createdBy: auth.data.Username,
          labRoomId: values.labRoomId,
          labDayCode: values.labDayCode,
          lecPercentageShare: values.labPercentageShare != null ? 100 - parseFloat(values.labPercentageShare) : null,
          labPercentageShare: values.labPercentageShare
        }




        if (values.mergeSubjectScheduleDetailId != null) {
          await mergeFaculty({
            subjectScheduleDetailIdMerger: values.mergeSubjectScheduleDetailId,
            currentId: values.subjectScheduleDetailId,
            mergeStatus: values.mergeStatus == 'isLec' ? 1 : values.mergeStatus == 'isLab' ? 2 : 3
          }).then(async res => {
            console.log(res)

            if (res.data[0].Result === 1) {
              setIsNotAbleToMerge(true)
              await updateSchedDetl({ ...subValues, labTimeStart: null, labTimeEnd: null, labRoomId: null, labDayCode: null }, true)


            } else {
              setIsNotAbleToMerge(false)
              await updateSchedDetl(subValues, false)
            }
          })
            .catch(e => {

              console.log(e)
            })

        } else {
          setIsNotAbleToMerge(null)
          await mergeFaculty({
            subjectScheduleDetailIdMerger: values.mergeSubjectScheduleDetailId,
            currentId: values.subjectScheduleDetailId,
            mergeStatus: values.mergeStatus == 'isLec' ? 1 : values.mergeStatus == 'isLab' ? 2 : 3
          })
          console.log(values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId)
          await updateSchedDetl(subValues, values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId ? 0 : null)

        }
      }
    }






    //  SubjectScheduleDetailAddUpdate

    // updateSchedDetlList(listId, subSchedDetlListValues)
  }


  const onDeleteLab = async (values, id, listId) => {


    const updateSchedDetl = async (values, isMerge) => {
      await SubjectScheduleDetailAddUpdate(values)
        .then(async res => {
          console.log(res)
          await executeGetSubjectScheduleDetl(resultQuery)

          if (res.success == false) {

            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: res.message });
          } else {
            await executeGetSubjectScheduleDetl(resultQuery)
              .then(res => {
                console.log(isNotAbleToMerge)
                console.log(isMerge)
                dispatch({
                  type: componentTypes.SHOW_SNACKBAR,
                  snackBarStatus: isMerge == null ? 'success' : isMerge == false ? 'error' : 'success',
                  snackBarDuration: 5000,
                  snackBarMessage: isMerge == null ? 'Subject schedule updated successfully' : isMerge == false ? 'Unable to merge' : 'Subject schedule updated successfully'
                });
                setIsNotAbleToMerge(null)
              })

          }

        })
        .catch(e => {
          console.log(e)
        })
    }

    if (values.length > 0) {
      let subValues = []
      for (let i = 0; i < values.length; i++) {
        subValues.push({
          subjectScheduleDetailId: values[0].subjectScheduleDetailId,
          subjectScheduleDetailListId: values[i].subjectScheduleDetailListId,
          subjectScheduleId: values[0].subjectScheduleId,
          classCode: null,
          subjectId: values[i].subjectId,
          classSectionId: values[i].classSectionId,
          capacity: values[i].capacity,
          schoolYear: values[i].schoolYear,
          semesterId: values[i].semesterId,
          timeStart: values[i].timeStart,
          timeEnd: values[i].timeEnd,
          labTimeStart: values[i].labTimeStart,
          labTimeEnd: values[i].labTimeEnd,
          dayCode: values[i].dayCode,
          roomId: values[i].roomId,
          createdBy: auth.data.Username,
          labRoomId: values[i].labRoomId,
          labDayCode: values[i].labDayCode,
          lecPercentageShare: values[i].lecPercentageShare,
          labPercentageShare: values[i].labPercentageShare
        })
      }

      if (values[0].mergeSubjectScheduleDetailId != null) {
        await mergeFaculty({
          subjectScheduleDetailIdMerger: values[0].mergeSubjectScheduleDetailId,
          currentId: values[0].subjectScheduleDetailId,
          mergeStatus: values[0].mergeStatus == 'isLec' ? 1 : values[0].mergeStatus == 'isLab' ? 2 : 3
        }).then(async res => {
          console.log(res)
          if (res.data[0].Result === 1) {
            setIsNotAbleToMerge(true)
            // await updateSchedDetl({ ...subValues, labTimeStart: res.data[0].LabTimeStart, labTimeEnd: res.data[0].LabTimeEnd, labRoomId: res.data[0].LabRoomId, labDayCode: res.data[0].LabDayCode }, true)
            await updateSchedDetl(subValues, true)

          } else {
            setIsNotAbleToMerge(false)
            await updateSchedDetl(subValues, false)
          }
        })
          .catch(e => {
            console.log(e)
          })

      } else {
        setIsNotAbleToMerge(null)
        await mergeFaculty({
          subjectScheduleDetailIdMerger: values[0].mergeSubjectScheduleDetailId,
          currentId: values[0].subjectScheduleDetailId,
          mergeStatus: values[0].mergeStatus == 'isLec' ? 1 : values[0].mergeStatus == 'isLab' ? 2 : 3
        })
        // console.log(values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId)
        await updateSchedDetl(subValues, values[0].mergeSubjectScheduleDetailId == values[0].subjectScheduleDetailId ? 0 : null)

      }
    } else {
      const subValues = {
        subjectScheduleDetailId: values.subjectScheduleDetailId,
        subjectScheduleDetailListId: values.subjectScheduleDetailListId,
        subjectScheduleId: values.subjectScheduleId,
        classCode: null,
        subjectId: values.subjectId,
        classSectionId: values.classSectionId,
        capacity: values.capacity,
        schoolYear: values.schoolYear,
        semesterId: values.semesterId,
        timeStart: values.timeStart,
        timeEnd: values.timeEnd,
        labTimeStart: values.labTimeStart,
        labTimeEnd: values.labTimeEnd,
        dayCode: values.dayCode,
        roomId: values.roomId,
        createdBy: auth.data.Username,
        labRoomId: values.labRoomId,
        labDayCode: values.labDayCode,
        lecPercentageShare: values.labPercentageShare != null ? 100 - parseFloat(values.labPercentageShare) : null,
        labPercentageShare: values.labPercentageShare
      }




      if (values.mergeSubjectScheduleDetailId != null) {
        await mergeFaculty({
          subjectScheduleDetailIdMerger: values.mergeSubjectScheduleDetailId,
          currentId: values.subjectScheduleDetailId,
          mergeStatus: values.mergeStatus == 'isLec' ? 1 : values.mergeStatus == 'isLab' ? 2 : 3
        }).then(async res => {
          console.log(res)

          if (res.data[0].Result === 1) {
            setIsNotAbleToMerge(true)
            await updateSchedDetl({ ...subValues, labTimeStart: null, labTimeEnd: null, labRoomId: null, labDayCode: null }, true)


          } else {
            setIsNotAbleToMerge(false)
            await updateSchedDetl(subValues, false)
          }
        })
          .catch(e => {

            console.log(e)
          })

      } else {
        setIsNotAbleToMerge(null)
        await mergeFaculty({
          subjectScheduleDetailIdMerger: values.mergeSubjectScheduleDetailId,
          currentId: values.subjectScheduleDetailId,
          mergeStatus: values.mergeStatus == 'isLec' ? 1 : values.mergeStatus == 'isLab' ? 2 : 3
        })
        console.log(values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId)
        await updateSchedDetl(subValues, values.mergeSubjectScheduleDetailId == values.subjectScheduleDetailId ? 0 : null)

      }
    }








    //  SubjectScheduleDetailAddUpdate

    // updateSchedDetlList(listId, subSchedDetlListValues)
  }

  const onSaveSubSchedDetail = async (values, cc) => {


    if (values.length > 0) {
      let nextClsCode
      let resLength = results.length
      let nextIndex = resLength - 1
      if (results.length == 0) {
        nextClsCode = startingCode
      } else {
        nextClsCode = parseInt(results[nextIndex].ClassCode) + 1
      }

      let subValues = []
      let conflictCheckerValues = []

      for (let i = 0; i < values.length; i++) {
        subValues.push({
          subjectScheduleDetailId: 0,
          subjectScheduleDetailListId: 0,
          subjectScheduleId: parseInt(id),
          classCode: nextClsCode,
          subjectId: values[i].subjectId,
          classSectionId: values[i].classSectionId,
          capacity: values[i].capacity,
          schoolYear: values[i].schoolYear,
          semesterId: values[i].semesterId,
          timeStart: values[i].timeStart,
          timeEnd: values[i].timeEnd,
          labTimeStart: null,
          labTimeEnd: null,
          dayCode: values[i].dayCode,
          roomId: values[i].roomId,
          createdBy: auth.data.Username,
          labRoomId: values[i].labRoomId,
          labDayCode: values[i].labDayCode,
          lecPercentageShare: values[i].lecPercentageShare,
          labPercentageShare: values[i].labPercentageShare
        })
        conflictCheckerValues.push({
          schoolYear: values[i].schoolYear,
          semesterId: values[i].semesterId,
          timeStart: values[i].timeStart,
          timeEnd: values[i].timeEnd,
          dayCode: values[i].dayCode,
          roomId: values[i].roomId,
          subjectScheduleDetailListId: values[i].subjectScheduleDetailListId,
          subjectId: values[i].subjectId,
          subjectScheduleId: values[i].subjectScheduleId,
          classSectionId: values[i].classSectionId
        })

      }
      console.log(subValues)
      await SubjectScheduleDetailConflictCheck(conflictCheckerValues)
        .then(async res => {
          console.log(res)
          console.log(res.data.HasConflict)
          if (res.data[0].HasConflict == 0) {
            await exeAddSubSchedDetl(subValues)
              .then(res => {
                return
              })
          } else {
            setIsConflict(true)
            setConflictClassCodes(res.ClassCodes)
          }
        })


    } else {
      let nextClsCode
      let resLength = results.length
      let nextIndex = resLength - 1
      if (results.length == 0) {
        nextClsCode = startingCode
      } else {
        nextClsCode = parseInt(results[nextIndex].ClassCode) + 1
      }

      const subValues = {
        subjectScheduleDetailId: 0,
        subjectScheduleDetailListId: 0,
        subjectScheduleId: parseInt(id),
        classCode: nextClsCode,
        subjectId: values.subjectId,
        classSectionId: values.classSectionId,
        capacity: values.capacity,
        schoolYear: values.schoolYear,
        semesterId: values.semesterId,
        timeStart: values.timeStart,
        timeEnd: values.timeEnd,
        labTimeStart: null,
        labTimeEnd: null,
        dayCode: values.dayCode,
        roomId: values.roomId,
        createdBy: auth.data.Username,
        labRoomId: values.labRoomId,
        labDayCode: values.labDayCode,
        lecPercentageShare: values.lecPercentageShare,
        labPercentageShare: values.labPercentageShare
      }
      console.log(subValues)
      await exeAddSubSchedDetl(subValues)
        .then(res => {
          return
        })
    }



  }


  const handleDelete = (subSchedDetlId, subSchedDetlListId) => {
    const exeDeleteSubSchedDetl = async (id) => {
      await SubjectScheduleDetailDelete(id)
        .then(res => {
          executeGetSubjectScheduleDetl(resultQuery)
        })
        .catch(e => {

        })
    }
    exeDeleteSubSchedDetl({ subjectScheduleDetailId: subSchedDetlId, createdBy: auth.data.Username })

  }



  const Query = (operation, condition, setData) => {
    let obj = eval("(" + condition + ")");
    const execute = async () => {
      await operation(obj)
        .then(response => {
          if (response.success) {

            setData(response.data)
          }
          return
        })
        .catch(error => {
          if (error) {
            return error
          } else {
            return
          }
        })
    }
    execute()
  }

  const MergeContent = (props) => {

    const selectChange = name => event => { setMerging({ ...merging, subjectScheduleDetailIdMerger: event }) }



    return (
      <>
        <main className='p-5'>
          <IMSelect
            data={props.classCodeSelect}
            onHandleChange={selectChange()}
            refClassContainer=""
            name=""
            isRequired={false}
            withLabel={true}
            label="Select Class Code to Merge"
            placeHolder="Class Code"
            forwardRef={() => { }}
            selectedId={merging.subjectScheduleDetailIdMerger !== null ? merging.subjectScheduleDetailIdMerger : ''}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[
            ]
            }
            field={{
              tableId: 'tableId',
              display: 'code'
            }}
          />
        </main>

      </>

    )
  }

  const mergeSubSchedFaculty = async (id) => {
    setIsOpenFacultyMerger(true)
    setMerging({ ...merging, currentId: id })
  }

  const onMerge = async () => {
    await mergeFaculty(merging)
      .then(res => {
        if (res.data[0].Result === 1) {
          executeGetSubjectScheduleDetl(resultQuery)
          setIsNotAbleToMerge(true)
        } else {
          executeGetSubjectScheduleDetl(resultQuery)
          setIsOpenFacultyMerger(false)
        }


      })
      .catch(e => {

      })
  }

  const onCloseMerging = () => {
    setIsOpenFacultyMerger(false)
    setIsNotAbleToMerge(false)
    executeGetSubjectScheduleDetl(resultQuery)


  }



  useEffect(() => {

    Query(getSubjects, null, setSubject)
    Query(getRooms, null, setRooms)
    Query(getSubjectType, null, setSubjectType)

    const getStartingClassCode = async (q) => {
      await getSubjectScheduleQuery(q)
        .then(res => {
          let classSectionSelectCondition = (auth.data.UserType == 'CHR') ? `IsActive = 1 AND InstituteId IN ( ${auth.data.InstituteId} ) ORDER BY ClassSection` : (auth.data.UserType == 'DEN') ? `IsActive = 1 AND InstituteId IN ( ${auth.data.InstituteId} ) ORDER BY ClassSection` : ``
          setStartingCode(res.data[0].StartingClassCode)
          setSubSchedSemester(res.data[0].SemesterId)
          setSubSchedSchoolYear(res.data[0].SchoolYear)
          setSubSchedInstitute(res.data[0].InstituteDesc)
          setSubSchedSySem(`${res.data[0].SYDesc} - ${res.data[0].Semester}`)

          getClassSections({ where: `WHERE InstituteId IN ( ${res.data[0].InstituteId} ) OR InstituteId IS NULL ORDER BY ClassSection` })
            .then(data => {
              setClassSection(data)
            })

          if (subjectSchedule != undefined)
            SelectCreator(getClassSections, setClassSectionSelect, 'ClassSectionId', 'ClassSection', auth.data.UserType == 'CHR' || auth.data.UserType == 'DEN' ? { where: `WHERE ${classSectionSelectCondition}` } : { where: `WHERE IsActive = 1 AND InstituteId IN ( ${subjectSchedule.InstituteId} ) ORDER BY ClassSection` })


        })
    }

    getStartingClassCode({ where: `WHERE sched.SubjectScheduleId = ${parseInt(id)}` })
  }, [])


  useEffect(() => {
    console.log(selectedCourseId)
    console.log(selectedYearLevel)
    if (selectedYearLevel !== 0 && selectedCourseId != null && selectedYearLevel != null) {
      getCurriculumSubjectFilter({ yearLevelId: selectedYearLevel, currentSchoolYear: subSchedSchoolYear, courseId: selectedCourseId, instituteId: selectedInstituteId })
        .then(data => {
          if (data.length > 0)
            setSubSelect(data)
        })
    }

  }, [selectedCourseId, selectedYearLevel, selectedInstituteId])


  useEffect(() => {
    if (subSchedSchoolYear !== undefined && subSchedSemester !== undefined) {

      getFacultyMergeSelect()

    }
  }, [subSchedSchoolYear, subSchedSchoolYear])




  useEffect(() => {
    if (classSection !== undefined && subject !== undefined && room !== undefined && subjectType !== undefined) {
      let subSelectArr = []
      let roomsArr = []
      let subTypeArr = []

      for (let i = 0; i < subject.length; i++) {
        subSelectArr.push({ tableId: subject[i].SubjectId, code: subject[i].SubjectCode })
      }

      for (let i = 0; i < room.length; i++) {
        if (room[i].IsActive == 1)
          roomsArr.push({ tableId: room[i].RoomId, code: room[i].Room })
      }
      for (let i = 0; i < subjectType.length; i++) {
        subTypeArr.push({ tableId: subjectType[i].SubjectTypeId, code: subjectType[i].SubjectType })
      }
      setRoomSelect(roomsArr)
      setSubjectTypeSelect(subTypeArr)

    }
  }, [classSection, subject, room, subjectType])



  const handleEdit = (id) => {
    console.log(id)
    setSelectedId(id);
  }



  useEffect(() => {
    executeGetSubjectScheduleDetl(resultQuery);
  }, [])


  useEffect(() => {
    if (results !== null && results.length > 0) {
      setClassCode(results[0].ClassCodeLimit)
    }
  }, [results])



  console.log(subSchedDetailData)
  console.log(isNotAbleToMerge)
  console.log(room)
  console.log(results)
  console.log(classSectionSelect)
  console.log(subSelect)
  console.log(roomSelect)
  console.log(subjectTypeSelect)
  console.log(subjectSchedule)
  console.log(auth)
  console.log(subjectType)
  console.log(subject)
  console.log(classCodeSelect)

  return (
    <>
      {results !== null && classSectionSelect && subSelect.length > 0 && roomSelect && subjectTypeSelect && classCodeSelect ?



        <>



          {/* <MergeFacultyModal
            title="Merge Faculty"
            isOpen={isOpenFacultyMerger}
            Content={MergeContent}
            handleClose={onCloseMerging}
            classCodeSelect={classCodeSelect}
            isError={isNotAbleToMerge}
            ErrorContent={() => {
              return (
                <>
                  <div className='container'>
                    <div className="alert alert-warning" role="alert">
                      Merge error: Only identical subject schedules can be merged.
                    </div>
                  </div>

                </>
              )
            }}
            onSubmit={onMerge}
          /> */}

          {/* <NotificationModal
            title="Alert"
            isOpen={isNotAbleToMerge}
            handleClose={onCloseMerging}

            Content={() => {
              return (
                <>
                  <div className="alert alert-warning" role="alert">
                    Merge error: Only identical subject schedules can be merged.
                  </div>
                </>
              )
            }}

          /> */}

          <NotificationModal
            isOpen={isConflict}
            handleClose={() => { setIsConflict(false); setConflictClassCodes([]) }}
            title={'Time Conflict'}
            content={`Time conflict has been detected (${conflictClassCodes.map(d => d)}) `}
          />

          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label text-primary">{subSchedInstitute} <span className='lead text-dark'>({subSchedSySem})</span> </h3>
                <br />
              </div>

            </div>
            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>


              <IMTableCollapsible
                results={results}
                handleEdit={handleEdit}
                selectedId={selectedId}
                classSectionSelect={classSectionSelect}
                subSelect={subSelect}
                roomSelect={roomSelect}
                subjectTypeSelect={subjectTypeSelect}
                handleDelete={handleDelete}
                subSchedId={parseInt(id)}
                handleCloseSubSchedDetail={handleCloseSubSchedDetail}
                handleOpenSubSchedDetail={handleOpenSubSchedDetail}
                onOpenSubSchedDetail={onOpenSubSchedDetail}
                subSchedDetailData={subSchedDetailData}
                onUpdateSubSchedDetail={onUpdateSubSchedDetail}
                onSaveSubSchedDetail={onSaveSubSchedDetail}
                semesterId={subSchedSemester}
                schoolYear={subSchedSchoolYear}
                classCode={classCode}
                executeGetSubjectScheduleDetl={executeGetSubjectScheduleDetl}
                mergeSubSchedFaculty={mergeSubSchedFaculty}
                handleClassSectionChange={handleClassSectionChange}
                classCodeSelect={classCodeSelect}
                onHandleDeleteSched={onHandleDeleteSched}
                onDeleteLab={onDeleteLab}

              />

            </div>
          </div>

        </>

        :
        <Loading />


      }

    </>
  );
}