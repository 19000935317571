export const referenceTypes = {
    SELECTED_COUNTRY: 'SELECTED-COUNTRY',
    GET_COUNTRIES: 'GET-COUNTRIES',
    GET_COUNTRY: 'GET-COUNTRY-BY-ID',
    CREATE: 'CREATE-COUNTRY',
    UPDATE: 'UPDATE-COUNTRY',
    DELETE: 'DELETE-COUNTRY',
    LIST_LINK: '/admin/references/countries',
    CREATE_LINK: '/admin/references/create-countries/',
    UPDATE_LINK: '/admin/references/update-countries/',
    DELETE_LINK: '/admin/references/delete-countries/'
}