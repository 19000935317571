import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { getActiveYearLevels, getActiveYearLevelsByInstituteGroup } from './__hooks__';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export function YearLevelSelect(props) {
  const { refDisabled, refIsStatic, refStaticData, refIsRequired, refWithLabel, forwardRef, selectedId
    , refOnHandleChange, refClassName, refWithDescription, refClassContainer, refError, refErrorMessage } = props;
  const { state: { institutes }, dispatch } = useAppContext();
  const [disabled, setDisabled] = useState(refDisabled);
  const [results, setResults] = useState(refIsStatic ? Object.values(refStaticData) : []);
  console.log(institutes.data)
  useEffect(() => {
    if (!refIsStatic) {
      localStorage.removeItem('withSelectedYearLevel');
      const execute = async () => {
        if (props.refWithFilter) {
          if (institutes.length !== 0) {

            if (Object.values(institutes.data).length > 0) {

              setResults([]);
              if (props.institute === '') {




                await getActiveYearLevelsByInstituteGroup(Object.values(institutes.data)[0])
                  .then(response => {

                    if (response.success) {
                      dispatch({ type: referenceTypes.GET_YEAR_LEVELS, data: response.data });
                      setResults(response.data);
                      setDisabled(false);
                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                  })
                  .catch(error => {
                    if (error.response.status === 401) {
                      localStorage.clear();
                      history.push('/auth/login');
                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                  })

              } else {
                if (Object.values(institutes.data).length > 0) {
                  if (localStorage.getItem('withSelectedInstitute')) {
                    await getActiveYearLevelsByInstituteGroup(Object.values(institutes.data)[0])
                      .then(response => {
                        if (response.success) {
                          dispatch({ type: referenceTypes.GET_YEAR_LEVELS, data: response.data });
                          setResults(response.data);
                          setDisabled(false);
                        } else {
                          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                        }
                      })
                      .catch(error => {
                        if (error.response.status === 401) {
                          localStorage.clear();
                          history.push('/auth/login');
                        } else {
                          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                        }
                      })
                  } else {
                    if (Object.values(institutes.data).filter(row => row.InstituteId === props.institute)[0] !== undefined) {
                      await getActiveYearLevelsByInstituteGroup(Object.values(institutes.data).filter(row => row.InstituteId === props.institute)[0])
                        .then(response => {
                          if (response.success) {
                            dispatch({ type: referenceTypes.GET_YEAR_LEVELS, data: response.data });
                            setResults(response.data);
                            setDisabled(false);
                          } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                          }
                        })
                        .catch(error => {
                          if (error.response.status === 401) {
                            localStorage.clear();
                            history.push('/auth/login');
                          } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                          }
                        })
                    }
                  }
                }
              }
            }
          }
        } else {
          await getActiveYearLevels()
            .then(response => {
              if (response.success) {
                dispatch({ type: referenceTypes.GET_YEAR_LEVELS, data: response.data });
                setResults(response.data);
                setDisabled(false);
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
              }
            })
            .catch(error => {
              if (error.response.status === 401) {
                localStorage.clear();
                history.push('/auth/login');
              } else {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
              }
            })
        }
      }
      execute();

      return () => {
        dispatch({ type: referenceTypes.SELECTED_YEAR_LEVEL, data: [] });
      }
    }
  }, [dispatch, props.refWithFilter, institutes, props.institute, refIsStatic])

  // const handleChange = val => {
  //   dispatch({ type: referenceTypes.SELECTED_YEAR_LEVEL, data: results.filter(row => row.YearLevelId === Number(val)) });
  //   localStorage.setItem('withSelectedYearLevel', true);
  // }

  return (
    <IMSelect
      name="yearLevelId"
      isRequired={refIsRequired}
      withLabel={refWithLabel}
      label="Year Level"
      placeHolder="Year Level"
      forwardRef={forwardRef}
      selectedId={selectedId}
      data={results}
      field={{
        tableId: 'YearLevelId',
        display: 'Description'
      }}
      onHandleChange={refOnHandleChange}
      withClassName={refClassName}
      withDescription={refWithDescription}
      withClassContainer={refClassContainer}
      description="Please enter your year level."
      disabled={disabled}
      error={refError}
      errorMessage={refErrorMessage}
    />
  );
}