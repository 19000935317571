import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { getActiveSubjects, getFilteredSubjects } from './__hooks__';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export function SubjectSelect(props) {
    const { refDisabled, refIsStatic, refStaticData, refIsRequired, refWithLabel, forwardRef, selectedId
        , refOnHandleChange, refClassName, refWithDescription, refClassContainer, refError, refErrorMessage, data } = props;
    const { dispatch } = useAppContext();
    const [disabled, setDisabled] = useState(refDisabled);
    const [results, setResults] = useState(refIsStatic ? Object.values(refStaticData) : []);

    useEffect(() => {
        if (!refIsStatic) {
            const execute = async () => {
                await getActiveSubjects()
                    .then(response => {
                        if (response.success) {
                            dispatch({ type: referenceTypes.GET_SUBJECTS, data: response.data });
                            setResults(response.data);
                            setDisabled(false);
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                        }
                    })
                    .catch(error => {
                        if (error.response.status === 401) {
                            localStorage.clear();
                            history.push('/auth/login');
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                        }
                    })
            }
            if (data !== undefined && data === 'courseOutline') {
                const executeFiltered = async (conn) => {
                    await getFilteredSubjects(conn)
                        .then(response => {
                            if (response.success) {
                                let arr = []
                                dispatch({ type: referenceTypes.GET_SUBJECTS, data: response.data });
                                console.log(response.data)
                                for (let i = 0; i < response.data.length; i++) {
                                    arr.push({ SubjectId: response.data[i].SubjectId, SubjectCode: response.data[i].SubjectCode, Nomenclature: response.data[i].Nomenclature })
                                }
                                setResults(arr);
                                setDisabled(false);
                            } else {
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                localStorage.clear();
                                history.push('/auth/login');
                            } else {
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                            }
                        })
                }
                executeFiltered({ where: `WHERE SubjectId IN (${props.outlineDetail.map(d => { return d.SubjectId })})` })
            } else {
                execute();
            }

        }
    }, [dispatch, refIsStatic])

    // const handleChange = val => {
    //     dispatch({ type: referenceTypes.SELECTED_SUBJECT, data: results.filter(row => row.SubjectId === Number(val)) });
    // }
    console.log(results)
    return (
        <>
            {results !== undefined &&
                <IMSelect
                    name="subjectId"
                    isRequired={refIsRequired}
                    withLabel={refWithLabel}
                    label="Subject"
                    placeHolder="Subject"
                    forwardRef={forwardRef}
                    selectedId={selectedId}
                    data={results}
                    field={{
                        tableId: 'SubjectId',
                        code: 'SubjectCode',
                        display: 'Nomenclature'
                    }}
                    onHandleChange={refOnHandleChange}
                    withClassName={refClassName}
                    withDescription={refWithDescription}
                    withClassContainer={refClassContainer}
                    description="Please enter your subject."
                    disabled={disabled}
                    error={refError}
                    errorMessage={refErrorMessage}
                />

            }
        </>

    );
}