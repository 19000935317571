import React, { useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal, Box } from '@material-ui/core';
import SubjectScheduleDetailModal from '../modal/FacultyLoadingDetailModal';
import { Link } from 'react-router-dom';
import RowList from "../../../../../app/modules/Admin/Enrollment/SubjectSchedule/SubjectScheduleDetail/RowList"
import { SignalCellularConnectedNoInternet2BarRounded } from '@material-ui/icons';
import history from '../../../../../app/history';

const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.transparent',

    boxShadow: 24,
    p: 4,
};

const convertTime = (timeString) => {
    const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
        .toLocaleTimeString('en-US',
            { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
        );

    return timeString12hr;
};

const DeleteModal = (props) => {

    return (
        <Modal
            open={props.isOpen}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box className="card p -5" style={style}>
                <div className="card-header"> <h3>Delete Confirmation</h3> </div>
                <div className="card-body">

                    <p className="card-text">Are you sure you want to delete this Subject Schedule?</p>
                    <button onClick={() => { props.handleDelete(props.id1, props.id2) }} className="btn btn-danger mr-3">Yes</button>
                    <button onClick={() => { props.handleClose(props.id) }} className="btn btn-secondary ">No</button>

                </div>
            </Box>


        </Modal>
    )
}

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
        ],
    };
}

function Row(props) {
    const { row, mergeSubSchedFaculty } = props;
    const classes = useRowStyles();
    const [isOpenDelete, setIsOpenDelete] = useState(false)


    const onHandleCloseDeleteConfirmation = () => {
        setIsOpenDelete(false)
    }

    const onHandleOpenDeleteConfirmation = () => {
        setIsOpenDelete(true)
    }

    const onDeleteSubSchedDetl = (id1, id2) => {
        props.handleDelete(id1, id2)
        onHandleCloseDeleteConfirmation()


    }

    const onHandleEdit = () => {
        props.handleOpenSubSchedDetail(row.SubjectScheduleDetailId)

    }

    const onHandleAddMore = () => {
        history.push(`/admin/enrollment/faculty-loading-detail-lists/${row.SubjectScheduleDetailId}`)
    }

    const onHandleDelete = () => {
        props.onDeleteFaculty(row.SubjectScheduleDetailId)
    }
    console.log(row)
    console.log(row.schedules)

    return (
        <>

            <React.Fragment>
                <DeleteModal
                    handleDelete={onDeleteSubSchedDetl}
                    handleClose={onHandleCloseDeleteConfirmation}
                    isOpen={isOpenDelete}
                    id1={row.SubjectScheduleDetailId}
                    id2={row.schedules.length > 0 ? row.schedules[0].SubjectScheduleDetailListId : 0}
                />

                <TableRow className={classes.root}>

                    <TableCell align="center">{row.ClassCode}</TableCell>
                    <TableCell align="center">Lecture</TableCell>
                    <TableCell align="center">{row.SubjectCode}</TableCell>
                    <TableCell align="center">{row.ClassSection}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].DayCode}</TableCell>
                    <TableCell align="center">{row.Capacity}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].TimeStartDesc}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].TimeEndDesc}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].Room}</TableCell>
                    <TableCell align="center">{row.MergeClassCode}</TableCell>
                    <TableCell align="center">{row.EmployeeName}</TableCell>


                    <TableCell align="center" style={{ width: "10%" }}>
                        {row.MergeClassCode == null ?
                            <button className="btn btn-xs btn-icon btn-icon-xs btn-warning mr-1" onClick={() => { onHandleEdit() }}>
                                <i className="fa fa-chalkboard-teacher text-white"></i>
                            </button>

                            :

                            <button disabled className="btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1" >
                                <i className="fa fa-user-slash text-white"></i>
                            </button>
                        }
                        <Link to={{ pathname: `/admin/enrollment/faculty-loading-detail-lists/${row.SubjectScheduleDetailId}`, schedule: row, employeeSelect: props.employeeSelect }} className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                            <i className="fa fa-list-alt text-white"></i>
                        </Link>
                        {(row.EmployeeName !== null || row.EmployeeLabName !== null) && row.MergeClassCode == null ?
                            <button className="btn btn-xs btn-icon btn-icon-xs btn-danger mr-1" onClick={() => { onHandleDelete() }}>
                                <i className="fa fa-user-slash text-white"></i>
                            </button>

                            :
                            <button disabled className="btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1" >
                                <i className="fa fa-user-slash text-white"></i>
                            </button>

                        }
                        {/* {row.EmployeeName == null && row.EmployeeLabName == null ?
                            <button onClick={() => { mergeSubSchedFaculty(row.SubjectScheduleDetailId) }} className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" >
                                M
                            </button>
                            :
                            <button disabled className="btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1" >
                                M
                            </button>
                        } */}


                    </TableCell>


                </TableRow>
                {row.schedules.length > 1 &&
                    <>
                        {row.schedules.slice(1).map((sched, index) => {
                            console.log(index)
                            return (
                                <>

                                    {/* <TableCell align="center">{row.ClassCode}</TableCell>
                    <TableCell align="center">Lecture</TableCell>
                    <TableCell align="center">{row.SubjectCode}</TableCell>
                    <TableCell align="center">{row.ClassSection}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].DayCode}</TableCell>
                    <TableCell align="center">{row.Capacity}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].TimeStartDesc}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].TimeEndDesc}</TableCell>
                    <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].Room}</TableCell>
                    <TableCell align="center">{row.MergeClassCode}</TableCell>
                    <TableCell align="center">{row.EmployeeName}</TableCell> */}
                                    {sched.TimeStart != null &&
                                        <TableRow className='bg-light' key={index}>
                                            <TableCell align="center">{row.ClassCode}</TableCell>
                                            <TableCell align="center">Lecture</TableCell>
                                            <TableCell align="center">{row.SubjectCode}</TableCell>
                                            <TableCell align="center">{row.ClassSection}</TableCell>
                                            <TableCell align="center">{sched.DayCode}</  TableCell>
                                            <TableCell align="center">{row.Capacity}</TableCell>
                                            <TableCell align="center">{convertTime(sched.TimeStart)}</TableCell>
                                            <TableCell align="center">{convertTime(sched.TimeEnd)}</TableCell>
                                            <TableCell align="center">{sched.Room}</TableCell>
                                            <TableCell align="center">{row.MergeClassCode}</TableCell>
                                            <TableCell align="center">{row.EmployeeName}</TableCell>
                                            {/* <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell> */}
                                        </TableRow>
                                    }





                                </>
                            )
                        })}
                    </>
                }

                {row.schedules.length > 0 &&
                    <>
                        {row.schedules[0].LabTimeStart !== null || row.schedules[0].LabTimeEnd !== null ?
                            <TableRow className="" style={{ backgroundColor: '#3699ff54' }}>




                                <TableCell align="center">{row.ClassCode}</TableCell>
                                <TableCell align="center">Laboratory</TableCell>
                                <TableCell align="center">{row.SubjectCode}</TableCell>
                                <TableCell align="center">{row.ClassSection}</TableCell>
                                <TableCell align="center">{row.schedules[0].LabDayCode}</TableCell>
                                <TableCell align="center">{row.Capacity}</TableCell>
                                <TableCell align="center">{row.schedules[0].LabTimeStartDesc}</TableCell>
                                <TableCell align="center">{row.schedules[0].LabTimeEndDesc}</TableCell>
                                <TableCell align="center">{row.schedules[0].LabRoom}</TableCell>

                                <TableCell align="center">{row.MergeClassCode}</TableCell>
                                <TableCell align="center">{row.EmployeeLabName}</TableCell>





                            </TableRow>
                            :
                            ""
                        }
                    </>

                }




            </React.Fragment>
        </>


    );
}



export default function CollapsibleTable(props) {
    const { handleEdit, selectedId, classSectionSelect,
        subSelect, subSchedId, onOpenSubSchedDetail,
        handleCloseSubSchedDetail, onSaveSubSchedDetail,
        handleOpenSubSchedDetail, subSchedDetailData,
        onUpdateSubSchedDetail, semesterId, schoolYear, subjectTypeSelect,
        roomSelect, handleDelete, classCode, executeGetSubjectScheduleDetl, employeeSelect, onDeleteFaculty,
        mergeSubSchedFaculty } = props


    console.log(classCode)
    console.log(props.results)
    return (

        <>
            {props.results !== undefined ?
                <>
                    <SubjectScheduleDetailModal

                        openMigrateModal={onOpenSubSchedDetail}
                        handleClose={handleCloseSubSchedDetail}
                        onSubmitStudentHandler={onSaveSubSchedDetail}
                        subSchedDetailData={subSchedDetailData}
                        subSelect={subSelect}
                        roomSelect={roomSelect}
                        classSectionSelect={classSectionSelect}
                        employeeSelect={employeeSelect}
                        onUpdateSubSchedDetail={onUpdateSubSchedDetail}

                    />

                    <TableContainer component={Paper}>
                        <Table size="small" aria-label="a dense table">
                            <TableHead className=''>
                                <TableRow>
                                    <TableCell align="center">Class Code</TableCell>
                                    <TableCell align="center">Type</TableCell>
                                    <TableCell align="center">Subject Code</TableCell>
                                    <TableCell align="center">Class Section</TableCell>
                                    <TableCell align="center">Day</TableCell>
                                    <TableCell align="center">Capacity</TableCell>
                                    <TableCell align="center">Time Start</TableCell>
                                    <TableCell align="center">Time End</TableCell>
                                    <TableCell align="center">Room</TableCell>
                                    <TableCell align="center">Merged Class</TableCell>
                                    <TableCell align="center">Faculty</TableCell>

                                    <TableCell align="center">Action</TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {props.results.map((row) => (
                                    <Row
                                        key={row.name}
                                        row={row}
                                        handleEdit={handleEdit}
                                        classSectionSelect={classSectionSelect}
                                        subSelect={subSelect}
                                        subSchedId={subSchedId}
                                        selectedId={selectedId}
                                        handleOpenSubSchedDetail={handleOpenSubSchedDetail}
                                        handleDelete={handleDelete}
                                        executeGetSubjectScheduleDetl={executeGetSubjectScheduleDetl}
                                        onDeleteFaculty={onDeleteFaculty}
                                        mergeSubSchedFaculty={mergeSubSchedFaculty}
                                        employeeSelect={employeeSelect}
                                    />
                                ))}

                            </TableBody>



                        </Table>


                    </TableContainer>

                </>
                :
                <></>
            }

        </>

    );
}