import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TableRow, TableCell, FormControlLabel, Switch } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { useAppContext } from "../../../../../contexts/useAppContext";
import IMSelect from "../../../../../../_metronic/layout/components/custom/select/IMSelect";
import { componentTypes } from "../../../../../../_metronic/layout/components/snackbar/__hooks__/types";
const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
});
export default function RowForm(props) {

    const { result, handleCancel, onHandleSave, courseSelect, subSelect, onHandleAdd, classSectionSelect,
        semesterId, schoolYear, subSchedId, limitClassCode, roomSelect, handleClassSectionChange } = props
    const { register, setValue } = useForm();
    const { dispatch } = useAppContext();

    const recordCnt = props.result.length
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const [isAddTime, setIsAddTime] = useState(false)
    const [addTimeCount, setAddTimeCount] = useState(0)
    const [isLoading, setIsLoading] = useState(false)
    const dateTime = date + ' ' + time;
    const initialState = recordCnt === 0 ? {
        subjectScheduleId: subSchedId,
        classSectionId: null,
        subjectId: null,
        classCode: 0,
        capacity: null,
        schoolYear: schoolYear,
        semesterId: semesterId,
        timeStart: null,
        timeEnd: null,
        roomId: null,
        dayCode: null



    } : {
        subjectScheduleId: subSchedId,
        classSectionId: result.ClassSectionId,
        subjectId: result.SubjectId,
        classCode: result.ClassCode,
        capacity: result.Capacity,
        schoolYear: schoolYear,
        semesterId: semesterId,
        timeStart: result.TimeStart,
        timeEnd: result.TimeEnd,
        roomId: result.RoomId,
        dayCode: result.DayCode
    }


    const [data, setData] = useState(initialState);
    const [dynamicData, setDynamicData] = useState(initialState)

    const selectHandleChange = name => event => {
        if (name === 'courseId') {
            let foundValue = courseSelect.filter(obj => obj.tableId === event);
            console.log(foundValue)
            setData((prevData) => {
                return { ...prevData, [name]: event, instituteId: foundValue[0].institute }
            })
            // setData({ ...data, [name]: event, instituteId: foundValue[0].institute });

        } else if (name == 'classSectionId') {
            handleClassSectionChange(event)
            setData((prevData) => {
                return { ...prevData, [name]: event, subjectId: null }
            })
            // setData({ ...data, [name]: event });

        } else {
            console.log(event)
            setData((prevData) => {
                return { ...prevData, [name]: event }
            })
            // setData({ ...data, [name]: event });
        }

    }

    const handleChange = name => event => {
        console.log(name)
        setData((prevData) => {
            return { ...prevData, [name]: event.target.value }
        })
        // setData({ ...data, [name]: event.target.value });
    }

    const onHandleSaveData = () => {
        setIsLoading(true)
        if (dynamicData.length > 0) {
            setAddTimeCount(0)
            setData({
                subjectScheduleId: subSchedId,
                classSectionId: null,
                subjectId: null,
                classCode: 0,
                capacity: null,
                schoolYear: schoolYear,
                semesterId: semesterId,
                timeStart: null,
                timeEnd: null,
                roomId: null,
                dayCode: null
            })
            onHandleAdd(dynamicData, limitClassCode)
                .then(res => {
                    setIsLoading(false)
                })
        } else {
            setAddTimeCount(0)
            setData({
                subjectScheduleId: subSchedId,
                classSectionId: null,
                subjectId: null,
                classCode: 0,
                capacity: null,
                schoolYear: schoolYear,
                semesterId: semesterId,
                timeStart: null,
                timeEnd: null,
                roomId: null,
                dayCode: null
            })
            onHandleAdd(data, limitClassCode)
                .then(res => {
                    setIsLoading(false)
                })
        }
    }

    const onAddTime = () => {
        setAddTimeCount((prevData) => {
            return prevData + 1
        })
        setIsAddTime(true)
    }

    const AddTimeRow = ({ addTimeCount }) => {
        // Create an array with the length of addTimeCount
        const timeArray = Array.from({ length: addTimeCount }, (_, index) => index);
        console.log(timeArray)
        console.log(addTimeCount)
        return (
            <div>
                {addTimeCount > 0 && (
                    // Use map to loop through the array and render JSX
                    timeArray.map((index) => (
                        <div className="row mt-0" style={{ width: '100%', height: '70%' }} key={index}>

                            <div className="form-group mb-0 col-md-3 col-lg"></div>
                            <div className="form-group mb-0 col-md-3 col-lg"></div>
                            <div className="form-group mb-0 col-md-3 col-lg"></div>
                            <div className="form-group mb-0 col-md-3 col-lg"></div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Time Start <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange(`timeStart_${index}`)}
                                    type="time"
                                    name={`timeStart_${index}`}
                                    placeholder="Time Start"
                                    value={data[`timeStart_${index}`]}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}


                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />

                                </datalist>

                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Time End <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange(`timeEnd_${index}`)}
                                    type="time"
                                    name={`timeEnd_${index}`}
                                    placeholder="Time End"
                                    value={data[`timeEnd_${index}`]}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}

                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />

                                </datalist>
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Room : </label>

                                <IMSelect
                                    data={roomSelect}
                                    onHandleChange={selectHandleChange(`roomId_${index}`)}
                                    refClassContainer="form-control align-middle mb-5"
                                    name={`roomId_${index}`}
                                    isRequired={false}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Select Room"
                                    forwardRef={setValue}
                                    selectedId={data[`roomId_${index}`]}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    withClassContainer="mb-5"

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg"></div>
                        </div>
                    ))
                )}
            </div>
        );
    };

    useEffect(() => {
        const filteredObject = Object.keys(data)
            .filter(key => !/_\d+$/.test(key))
            .reduce((result, key) => {
                result[key] = data[key];
                return result;
            }, {});
        const dynamicScheduleArray = [filteredObject];

        // Check for properties starting with 'timeStart_'
        for (let i = 0; data.hasOwnProperty(`timeStart_${i}`); i++) {
            dynamicScheduleArray.push({
                "subjectScheduleId": data.subjectScheduleId,
                "classSectionId": data.classSectionId,
                // ... (other properties)
                "timeStart": data[`timeStart_${i}`],
                "timeEnd": data[`timeEnd_${i}`],
                "roomId": data[`roomId_${i}`],
            });
        }

        // If there are no dynamic properties, add a default object
        if (dynamicScheduleArray.length === 0) {
            dynamicScheduleArray.push(data);
        }
        console.log(dynamicScheduleArray)
        setDynamicData((prevData) => {

            return dynamicScheduleArray



        });
    }, [data]);

    console.log(data)
    console.log(result)
    console.log(classSectionSelect)
    console.log(dynamicData)
    return (
        <>
            <section className='card p-2'>
                {addTimeCount <= 0 ?
                    <>
                        <div className="row" style={{ width: '100%' }}>

                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Class Section <span className="text-danger">*</span> : </label>

                                <IMSelect
                                    data={classSectionSelect}
                                    onHandleChange={selectHandleChange('classSectionId')}
                                    refClassContainer="form-control align-middle mb-5"
                                    name="classSectionId"
                                    isRequired={true}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Class Section"
                                    forwardRef={setValue}
                                    selectedId={data.classSectionId === null ? result.ClassSectionId : data.classSectionId}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    withClassContainer="mb-5"

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Subject <span className="text-danger">*</span> : </label>

                                <IMSelect
                                    withClassContainer="mb-5"
                                    data={subSelect}
                                    onHandleChange={selectHandleChange('subjectId')}
                                    refClassContainer="form-control align-middle "
                                    name="subjectId"
                                    isRequired={true}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Subject"
                                    forwardRef={setValue}
                                    selectedId={data.subjectId === null ? result.SubjectId : data.subjectId}
                                    refClassName={` `}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg" style={{ whiteSpace: 'nowrap' }}>
                                <label style={{ fontSize: '85%' }} className="col-form-label">Day Code <span > (M,T,W,H,F,S,U)  <span className="text-danger">*:</span>  </span> </label>
                                <input
                                    className="form-control align-middle mb-5"
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                        const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                        event.target.value = uniqueFilteredInput;
                                        setData((prevData) => { return { ...prevData, 'dayCode': uniqueFilteredInput } });

                                    }}
                                    type="text"
                                    name="dayCode"
                                    placeholder="Day Code"
                                    autoComplete="off"
                                    value={data.dayCode == null ? '' : data.dayCode}
                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Capacity <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange('capacity')}
                                    type="number"
                                    name="capacity"
                                    placeholder="Capacity"
                                    value={data.capacity == null ? '' : data.capacity}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Time Start <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange('timeStart')}
                                    type="time"
                                    name="timeStart"
                                    placeholder="Time Start"
                                    value={data.timeStart == null ? '' : data.timeStart}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}


                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />

                                </datalist>

                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Time End <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange('timeEnd')}
                                    type="time"
                                    name="timeEnd"
                                    placeholder="Time End"
                                    value={data.timeEnd == null ? '' : data.timeEnd}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}

                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />

                                </datalist>
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Room : </label>

                                <IMSelect
                                    data={roomSelect}
                                    onHandleChange={selectHandleChange('roomId')}
                                    refClassContainer="form-control align-middle mb-5"
                                    name="roomId"
                                    isRequired={false}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Select Room"
                                    forwardRef={setValue}
                                    selectedId={data.roomId === null ? result.RoomId : data.roomId}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    withClassContainer="mb-5"

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                {isLoading == false ?
                                    <>
                                        {result.length === 0 ?
                                            <>
                                                {data.classSectionId == null || data.classSectionId == '' ||
                                                    data.subjectId == null || data.subjectId == '' ||
                                                    data.dayCode == null || data.dayCode == '' ||
                                                    data.capacity == null || data.capacity == '' ||
                                                    data.timeStart == null || data.timeStart == '' ||
                                                    data.timeEnd == null || data.timeEnd == '' ?
                                                    <>
                                                        <button disabled className="btn btn-secondary btn-sm btn-block" onClick={() => onHandleSaveData()}>
                                                            <i className="fa fa-plus p-0"></i>
                                                        </button>
                                                        <button disabled className="btn btn-secondary btn-sm btn-block" >
                                                            Add Time
                                                        </button>
                                                    </>

                                                    :
                                                    <>
                                                        <button className="btn btn-primary btn-sm btn-block" onClick={() => onHandleSaveData()}>
                                                            <i className="fa fa-plus p-0"></i>
                                                        </button>
                                                        <button className="btn btn-success btn-sm btn-block" onClick={() => { onAddTime() }} >
                                                            Add Time
                                                        </button>
                                                    </>

                                                }

                                            </>

                                            :
                                            <>
                                                <button className="btn btn-xs btn-icon btn-icon-xs btn-default" onClick={() => handleCancel()}>
                                                    <i className="fa fa-ban"></i>
                                                </button>&nbsp;

                                                <button className="btn btn-xs btn-icon btn-icon-xs btn-success mt-1" onClick={() => onHandleSave(result.SubjectScheduleDetailId, data, result.SubjectScheduleDetailListId)}>
                                                    <i className="fa fa-save text-white"></i>
                                                </button>

                                            </>
                                        }
                                    </>
                                    :
                                    <>
                                        <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            Loading...
                                        </button>
                                    </>
                                }

                            </div>
                        </div>
                    </>
                    :
                    <>

                        <div className="row" style={{ width: '100%' }}>

                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Class Section <span className="text-danger">*</span> : </label>

                                <IMSelect
                                    data={classSectionSelect}
                                    onHandleChange={selectHandleChange('classSectionId')}
                                    refClassContainer="form-control align-middle mb-5"
                                    name="classSectionId"
                                    isRequired={true}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Class Section"
                                    forwardRef={setValue}
                                    selectedId={data.classSectionId === null ? result.ClassSectionId : data.classSectionId}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    withClassContainer="mb-5"

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Subject <span className="text-danger">*</span> : </label>

                                <IMSelect
                                    withClassContainer="mb-5"
                                    data={subSelect}
                                    onHandleChange={selectHandleChange('subjectId')}
                                    refClassContainer="form-control align-middle "
                                    name="subjectId"
                                    isRequired={true}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Subject"
                                    forwardRef={setValue}
                                    selectedId={data.subjectId === null ? result.SubjectId : data.subjectId}
                                    refClassName={` `}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg" style={{ whiteSpace: 'nowrap' }}>
                                <label style={{ fontSize: '85%' }} className="col-form-label">Day Code <span > (M,T,W,H,F,S,U)  <span className="text-danger">*:</span>  </span> </label>
                                <input
                                    className="form-control align-middle mb-5"
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                        const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                        event.target.value = uniqueFilteredInput;
                                        setData((prevData) => { return { ...prevData, 'dayCode': uniqueFilteredInput } });

                                    }}
                                    type="text"
                                    name="dayCode"
                                    placeholder="Day Code"
                                    autoComplete="off"
                                    value={data.dayCode == null ? '' : data.dayCode}
                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Capacity <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange('capacity')}
                                    type="number"
                                    name="capacity"
                                    placeholder="Capacity"
                                    value={data.capacity == null ? '' : data.capacity}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Time Start <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange('timeStart')}
                                    type="time"
                                    name="timeStart"
                                    placeholder="Time Start"
                                    value={data.timeStart == null ? '' : data.timeStart}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}


                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />

                                </datalist>

                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Time End <span className="text-danger">*</span> : </label>

                                <input
                                    className="form-control mb-5"
                                    onChange={handleChange('timeEnd')}
                                    type="time"
                                    name="timeEnd"
                                    placeholder="Time End"
                                    value={data.timeEnd == null ? '' : data.timeEnd}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}

                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />

                                </datalist>
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                <label className="col-form-label">Room : </label>

                                <IMSelect
                                    data={roomSelect}
                                    onHandleChange={selectHandleChange('roomId')}
                                    refClassContainer="form-control align-middle mb-5"
                                    name="roomId"
                                    isRequired={false}
                                    withLabel={false}
                                    label=""
                                    placeHolder="Select Room"
                                    forwardRef={setValue}
                                    selectedId={data.roomId === null ? result.RoomId : data.roomId}
                                    refClassName={``}
                                    withDescription={false}
                                    // description={`Please select your gender.`}
                                    refDisabled={true}
                                    refIsStatic={false}
                                    refStaticData={[
                                    ]}
                                    field={{
                                        tableId: 'tableId',
                                        display: 'code'
                                    }}
                                    withClassContainer="mb-5"

                                />
                            </div>
                            <div className="form-group mb-0 col-md-3 col-lg">
                                {result.length === 0 ?
                                    <>
                                        {data.classSectionId == null || data.classSectionId == '' ||
                                            data.subjectId == null || data.subjectId == '' ||
                                            data.dayCode == null || data.dayCode == '' ||
                                            data.capacity == null || data.capacity == '' ||
                                            data.timeStart == null || data.timeStart == '' ||
                                            data.timeEnd == null || data.timeEnd == '' ?
                                            <>
                                                <button disabled className="btn btn-secondary btn-sm btn-block" onClick={() => onHandleSaveData()}>
                                                    <i className="fa fa-plus p-0"></i>
                                                </button>
                                                <button disabled className="btn btn-secondary btn-sm btn-block" >
                                                    Add Time
                                                </button>
                                            </>

                                            :
                                            <>
                                                <button className="btn btn-primary btn-sm btn-block" onClick={() => onHandleSaveData()}>
                                                    <i className="fa fa-plus p-0"></i>
                                                </button>
                                                <button className="btn btn-success btn-sm btn-block" onClick={() => { onAddTime() }} >
                                                    Add Time
                                                </button>
                                            </>

                                        }

                                    </>

                                    :
                                    <>
                                        <button className="btn btn-xs btn-icon btn-icon-xs btn-default" onClick={() => handleCancel()}>
                                            <i className="fa fa-ban"></i>
                                        </button>&nbsp;

                                        <button className="btn btn-xs btn-icon btn-icon-xs btn-success mt-1" onClick={() => onHandleSave(result.SubjectScheduleDetailId, data, result.SubjectScheduleDetailListId)}>
                                            <i className="fa fa-save text-white"></i>
                                        </button>

                                    </>
                                }
                            </div>
                        </div>
                        <AddTimeRow addTimeCount={addTimeCount} />
                    </>
                }

            </section>

        </>
    )

}