import instance from '../../../../../apis/local/systemAPI';

// START OF MODE OF PAYMENT
export const getActiveModeOfPayments = async () => {
  const result = await instance.get("api/references/mode-of-payments/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getModeOfPayments = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/mode-of-payments/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "paymentCode": keyword,
            "paymentMode": keyword,
            "description": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/mode-of-payments/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createModeOfPayment = async (formValues) => {
  const result = await instance.post("api/references/mode-of-payments", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateModeOfPayment = async (id, formValues) => {
  const result = await instance.patch(`api/references/mode-of-payments/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteModeOfPayment = async (id) => {
  const result = await instance.delete(`api/references/mode-of-payments/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF MODE OF PAYMENT