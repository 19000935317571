import React, { useState, useEffect } from 'react';
import { NavLink } from "react-router-dom";
import { checkIsActive } from "../../../../_helpers";
import { useAppContext } from '../../../../../app/contexts/useAppContext';


export function AsideChildMenuList(props) {
    const { state: { auth } } = useAppContext()
    const [childMenus, setChildMenus] = useState([]);

    const getMenuItemActive = (url, hasSubmenu = false) => {
        return checkIsActive(props.refLocation, url)
            ? ` ${!hasSubmenu && "menu-item-active"} menu-item-open `
            : "";
    };

    useEffect(() => {
        setChildMenus(props.refChildren);
    }, [props])
    console.log(auth.data.UserType)
    return (
        childMenus.map(child => {
            const { MenuId, Description, RoutePath } = child;
            return (
                <>
                    {MenuId == 54 ?
                        <>

                            <li
                                className={`menu-item ${getMenuItemActive(RoutePath, false)}`}
                                aria-haspopup="true"
                                key={MenuId}
                            >
                                <NavLink className="menu-link" to={RoutePath} key={MenuId}>
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    {auth.data.UserType == 'CHR' && <span className="menu-text">Grade Validation</span>}
                                    {(auth.data.UserType == 'DEN' || auth.data.UserType == 'RGD') && <span className="menu-text">Grade Validation / Grade Authentication</span>}
                                    {(auth.data.UserType == 'DCI' || auth.data.UserType == 'DCG' || auth.data.UserType == 'RGR') && <span className="menu-text">Grade Validation / Authentication </span>}

                                </NavLink>
                            </li>
                        </>
                        :
                        <>
                            <li
                                className={`menu-item ${getMenuItemActive(RoutePath, false)}`}
                                aria-haspopup="true"
                                key={MenuId}
                            >
                                <NavLink className="menu-link" to={RoutePath} key={MenuId}>
                                    <i className="menu-bullet menu-bullet-dot">
                                        <span />
                                    </i>
                                    <span className="menu-text">{Description}</span>
                                </NavLink>
                            </li>
                        </>
                    }


                </>

            );
        })
    );
}