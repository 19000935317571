import instance from '../../../../../apis/local/systemAPI';

// START OF ASSESSMENT TEMPLATE
export const getActiveAssessmentTemplates = async () => {
  const result = await instance.get("api/assessment/templates/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getYearLevel = async () => {
  const result = await instance.get(`api/references/yearlevels`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getPaymentModeSelect = async () => {
  const result = await instance.get(`api/references/mode-of-payments`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getCourses = async () => {
  const result = await instance.get("api/references/courses");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitutes = async () => {
  const result = await instance.get("api/references/institutes");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getStudentCategory = async () => {
  const result = await instance.get("api/references/student-categories");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getModeOfPayment = async () => {
  const result = await instance.get("api/references/mode-of-payments");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getSchoolYears = async () => {
  const result = await instance.get("api/references/schoolyears");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSemesters = async () => {
  const result = await instance.get(`api/references/semesters`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplates = async (con) => {
  const result = await instance.post("api/assessment/templates/q", con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createAssessmentTemplate = async (formValues) => {
  const result = await instance.post("api/assessment/templates", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplate = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/templates/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getPaymentMode = async () => {
  const result = await instance.get("api/references/mode-of-payments");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}





export const getAssessmentTemplateFilter = async (page, rowsPerPage, Sy, Sem, yl, pm) => {
  var result;
  const params = {
    where: [
      {
        "o": "", "p": ["LIKE", "&&", {
          "SchoolYear": Sy,
          "SemesterId": Sem,
          "YearLevelId": yl,
          "PaymentModeId": pm

        }
        ]
      }
    ]
    , start: page + 1
    , limit: 50000
  }
  result = await instance.post(`api/assessment/templates/f`, params);

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const addUpdateAssessmentTemplate = async (formValues) => {
  const result = await instance.post("api/assessment/templates/addUpdate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteAssessmentTemplate = async (val) => {
  const result = await instance.post(`api/assessment/templates/delete`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitute = async () => {
  const result = await instance.get(`api/references/institutes/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchoolYear = async () => {
  const result = await instance.get(`api/references/schoolyears/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSemester = async () => {
  const result = await instance.get(`api/references/semesters/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getAssessmentTemplateDetailQuery = async (val) => {
  const result = await instance.post(`api/assessment/template-details/q`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const duplicateAssessmentTemplate = async (val) => {
  const result = await instance.post(`api/assessment/templates/duplicateAdd`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const cloneAssessmentTemplate = async (val) => {
  const result = await instance.post(`api/assessment/templates/cloneAssessmentTemplate`, val);
  if (result.status === 200 || result.status === 201) {
    return result;
  }
}


// END OF ASSESSMENT TEMPLATE