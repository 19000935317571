import instance from "../../../../../apis/local/systemAPI";



export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async () => {
    const result = await instance.post("api/references/semesters/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async () => {
    const result = await instance.post("api/references/schoolyears/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getApplicantReport = async (formValues) => {
    const result = await instance.post("api/enrollment/applicant-report/search", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getApplicantDataForUpdate = async (formValues) => {
    const result = await instance.post("api/enrollment/applicant-report/search", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async (condition) => {
    const result = await instance.get("api/references/courses", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevel = async (formValues) => {
    const result = await instance.get("api/references/yearlevels", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



