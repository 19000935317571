import _ from 'lodash';
import { referenceTypes } from './types';


const reducer = (state, action) => {

    switch (action.type) {
        case referenceTypes.SELECTED_SCHOOL:
            return { ...state, data: action.data };
        case referenceTypes.CREATE:
            return { ...state, data: { ...state.data, [action.data.SchoolId]: action.data } };
        case referenceTypes.GET_SCHOOLS:
            // console.log('state', state, 'action', action.data[1].IsActive);

            return {
                ...state
                , data: { ..._.mapKeys(action.data, 'SchoolId') }
                , keyword: action.keyword === undefined ? state.keyword : action.keyword
                , page: action.page === undefined ? state.page : action.page
                , rowsPerPage: action.rowsPerPage === undefined ? state.rowsPerPage : action.rowsPerPage
            };
        case referenceTypes.UPDATE:
            return { ...state, data: { ...state.data, [action.data.SchoolId]: action.data }, id: action.id }
        case referenceTypes.DELETE:
            return { ...state, data: { ..._.omit(state.data, action.data) } };

        default:
            return state;
    }
}

export default reducer;