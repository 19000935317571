import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import history from "../../../../history";
import { referenceTypes } from "../../References/Fee/__hooks__/types";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";
import { getStudentSubjects, createStudentSubject, getStudentSubjectSelect, updateStudentEnrollmentSubjects } from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import AddUpdateModal from "../../../../../_metronic/layout/components/custom/modal/AddUpdateModal";
import ProfileLogo from '../../../../../_metronic/_assets/logo/profileLogo.png'





export default function AddDropStudentDetails(props) {
    const { id } = useParams()

    const { studentData } = props.location
    const [studentSubjects, setStudentSubjects] = useState([])
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)


    // Selects
    const [subjectSchedSelect, setSubjectSchedSelect] = useState(null)

    // functions
    const executeGetStudentSubs = async (val) => {
        await getStudentSubjects(val)
            .then(res => {
                setStudentSubjects(res.data)
            })
            .catch(e => {

            })
    }

    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    const getStudentSubjectDropDown = async (val) => {
        await getStudentSubjectSelect(val)
            .then(res => {
                setSubjectSchedSelect(res.data)
            })
            .catch(e => {

            })
    }

    const onAddStudentSubject = async (data) => {
        console.log({
            ...data,
            studentId: studentData.StudentId,
            semesterId: studentData.SemesterId,
            schoolYear: studentData.SchoolYear,
            status: 'ADD'

        })

        let formVal = {
            ...data,
            studentId: studentData.StudentId,
            semesterId: studentData.SemesterId,
            schoolYear: studentData.SchoolYear,
            status: 'ADD'

        }
        await createStudentSubject(formVal)
            .then(res => {
                setIsOpenAdd(false)
            })
    }


    const onUpdateStudentSubject = async (id, data) => {
        await updateStudentEnrollmentSubjects(id, data)
            .then(res => {
                setIsOpenUpdate(false)
            })
    }



    // modal content and logics -------------------------------------------------------


    //Update
    const onUpdateGradeElement = (data) => {
        setIsOpenUpdate(true)
        setRowValue({ ...data, isPending: 1, status: 'DEL' })
    }



    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', label: 'Days', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'IsPending', label: 'Is Approve', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Status', label: 'Operation', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]


    const tableProps = {
        recordId: 'StudentEnrollmentSubjectsId',
        sortField: '',
        columns: columns,
        rows: studentSubjects,
        totalRecords: studentSubjects.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'addDropSubject',
        parentId: null,
        tableTitle: 'List of Student Subjects',
        onSearch: false,
        keyword: '',
        lastUpdateId: 0,
        onPage: 0,
        onRowsPerPage: 10,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onDelete: onUpdateGradeElement,

    }




    useEffect(() => {
        if (studentData == undefined) {
            history.push(`/admin/enrollment/add-drop-subjects`)
        } else {
            executeGetStudentSubs({
                studentId: studentData.StudentId,
                schoolYear: studentData.SchoolYear,
                semesterId: studentData.SemesterId
            })
            getStudentSubjectDropDown({
                semesterId: studentData.SemesterId,
                schoolYear: studentData.SchoolYear
            })

        }
    }, [studentData, isOpenAdd, isOpenUpdate])


    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            studentId: props.value !== undefined && props.value !== null ? props.value.studentId : null,
            semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : null,
            schoolYear: props.value !== undefined && props.value !== null ? props.value.schoolYear : null,
            subjectScheduleDetailId: props.value !== undefined && props.value !== null ? props.value.subjectScheduleDetailId : null,
            isPending: props.value !== undefined && props.value !== null ? props.value.isPending : 1
        })


        // const [switchState, setSwitchState] = useState({
        //     isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false,
        //     isPeriodicOverride: props.value !== undefined && props.value !== null ? props.value.isPeriodicOverride === 1 ? true : false : false,
        //     isFinalsOverride: props.value !== undefined && props.value !== null ? props.value.isFinalsOverride === 1 ? true : false : false

        // });

        // const switchHandleChange = name => event => {
        //     console.log(event.target.checked)
        //     setSwitchState({ ...switchState, [name]: event.target.checked });
        // };

        // Selects

        const selectSubjectChange = name => event => { setData({ ...data, subjectScheduleDetailId: event }) }



        useEffect(() => {
            if (data) {

                props.onSave({
                    ...data,

                })
            }

        }, [data])



        console.log(props.value)
        return (
            <>

                <main className='container'>

                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.subjectSchedSelect}
                                onHandleChange={selectSubjectChange('subjectScheduleDetailId')}
                                refClassContainer=""
                                name="subjectScheduleDetailId"
                                isRequired={false}
                                withLabel={false}
                                label="Subject"
                                placeHolder="Select Subject"
                                forwardRef={() => { }}
                                selectedId={data.subjectScheduleDetailId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>




                </main>
            </>
        )
    }

    const UpdateContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.subjectCode : ''} `}</span> </p>
                </main>



            </>
        )
    }

    console.log(studentData)
    console.log(subjectSchedSelect)
    console.log(rowValue)
    console.log(studentSubjects)
    return (
        <>
            {studentData !== undefined && subjectSchedSelect &&


                <>
                    <AddUpdateModal
                        title="Add Student Subjects"
                        isOpen={isOpenAdd}
                        Content={Content}
                        handleClose={() => { setIsOpenAdd(false) }}
                        isAdd={true}
                        onSubmit={onAddStudentSubject}
                        id="studentEnrollmentSubjectsId"
                        subjectSchedSelect={subjectSchedSelect}
                        value={keysToCamelCase(rowValue)}

                    />

                    <AddUpdateModal
                        title="Remove Student Subjects"
                        isOpen={isOpenUpdate}
                        Content={UpdateContent}
                        value={keysToCamelCase(rowValue)}
                        handleClose={() => { setIsOpenUpdate(false) }}
                        isDelete={true}
                        onSubmit={onUpdateStudentSubject}
                        id="studentEnrollmentSubjectsId"
                        subjectSchedSelect={subjectSchedSelect}


                    />


                    <main className="card">
                        <section className="card-header">
                            <h4>Adding and Dropping of Subjects</h4>
                        </section>
                        <article className="card-body row">
                            <div className="card text-center col-4">
                                <div className="card-header bg-secondary p-5">
                                    Student Information
                                </div>
                                <div className="card-body">
                                    <img src={ProfileLogo} style={{ width: '60%' }} />
                                    <h5 className="card-title mt-5">{studentData.StudentName}</h5>
                                    <p className="card-text">{studentData.CourseDesc}</p>
                                    <p className="card-text">{`${studentData.SY} / ${studentData.SemesterDesc}`}</p>

                                </div>
                                <div className="card-footer text-muted">
                                    {studentData.StudentNo}
                                </div>
                            </div>

                            <div className="col 8 p-5" style={{ padding: '0px', margin: '0px' }}>
                                <div className="example-tools ">
                                    <button onClick={() => { setIsOpenAdd(true) }} className="btn btn-sm btn-icon-sm btn-primary">
                                        <i className="fa fa-plus"></i> Add New
                                    </button>
                                </div>
                                <IMTable tableProps={tableProps} />
                            </div>
                        </article>
                    </main>

                </>



            }


        </>
    )
}