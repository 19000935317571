import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { StudentCategorySelect, InstituteSelect, CourseSelect, YearLevelSelect, ScholarshipSelect, StudyTypeSelect } from '../../../../References';
import moment from 'moment';

export default function Form(props) {
  const { state: { studentCategories, institutes, courses, yearLevels, scholarships, studyTypes } } = useAppContext();
  const { AssessmentTemplateOtherRateId, StudentCategoryId, InstituteId, CourseId, YearLevelId, ScholarshipId, StudyTypeId, Amount, DueDate, IsActive, MFee, FFee } = props.doc;
  const { register, errors, setValue } = useForm();
  const recordCnt = props.doc.length;
  const initialState = recordCnt === 0 ? {
    assessmentTemplateOtherId: props.assessmentTemplateOtherId,
    courseId: null,
    yearLevelId: null,
    mFee: null,
    fFee: null,
    amount: null,
    dueDate: null,
    isActive: true
  } : {
    assessmentTemplateOtherId: props.assessmentTemplateOtherId,
    courseId: CourseId,
    yearLevelId: YearLevelId,
    mFee: MFee,
    fFee: FFee,
    amount: Amount,
    dueDate: DueDate !== null ? moment(DueDate).format('YYYY-MM-DD') : "",
    isActive: IsActive === 1 ? true : false
  }
  const [data, setData] = useState(initialState);

  useEffect(() => {
    register({ name: 'courseId' }, { required: true });
    register({ name: 'yearLevelId' }, { required: true });
  }, [register])

  const handleChange = name => event => {
    if (name === 'isActive') {
      setData({ ...data, [name]: event.target.checked });
    } else if (name === 'studentCategoryId' || name === 'instituteId' || name === 'courseId' || name === 'yearLevelId' || name === 'scholarshipId' || name === 'studyTypeId') {
      setData({ ...data, [name]: event });
    } else {
      setData({ ...data, [name]: event.target.value });
    }
  }

  console.log(props.doc)
  return (
    <>
      <TableRow key={AssessmentTemplateOtherRateId}>
        <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
        {/* <TableCell>
          <StudentCategorySelect
            refOnHandleChange={handleChange('studentCategoryId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={StudentCategoryId === undefined ? "" : StudentCategoryId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? studentCategories.data : []}
            refError={!!errors.studentCategoryId}
            refErrorMessage={errors.studentCategoryId && errors.studentCategoryId.type === "required" && 'Student Category is required'}
          />
        </TableCell>
        <TableCell>
          <InstituteSelect
            refOnHandleChange={handleChange('instituteId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={InstituteId === undefined ? "" : InstituteId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? institutes.data : []}
            refError={!!errors.instituteId}
            refErrorMessage={errors.instituteId && errors.instituteId.type === "required" && 'Institute is required'}
          />
        </TableCell> */}
        <TableCell>
          <CourseSelect
            refOnHandleChange={handleChange('courseId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={CourseId === undefined ? "" : CourseId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? courses.data : []}
            refError={!!errors.courseId}
            refErrorMessage={errors.courseId && errors.courseId.type === "required" && 'Institute is required'}
          />
        </TableCell>
        <TableCell>
          <YearLevelSelect
            refOnHandleChange={handleChange('yearLevelId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={YearLevelId === undefined ? "" : YearLevelId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? yearLevels.data : []}
            refError={!!errors.yearLevelId}
            refErrorMessage={errors.yearLevelId && errors.yearLevelId.type === "required" && 'Year level is required'}
          />
        </TableCell>
        {/* <TableCell>
          <ScholarshipSelect
            refOnHandleChange={handleChange('scholarshipId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={ScholarshipId === undefined ? "" : ScholarshipId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? scholarships.data : []}
            refError={!!errors.scholarshipId}
            refErrorMessage={errors.scholarshipId && errors.scholarshipId.type === "required" && 'Scholarship is required'}
          />
        </TableCell> */}
        {/* <TableCell>
          <StudyTypeSelect
            refOnHandleChange={handleChange('studyTypeId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={StudyTypeId === undefined ? "" : StudyTypeId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? studyTypes.data : []}
            refError={!!errors.studyTypeId}
            refErrorMessage={errors.studyTypeId && errors.studyTypeId.type === "required" && 'Study type is required'}
          />
        </TableCell> */}
        <TableCell>
          <input
            onChange={handleChange('mFee')}
            type="number"
            name="mFee"
            placeholder="Male Fee"
            defaultValue={MFee === undefined ? "" : MFee}
            className={`form-control${errors.mFee === undefined ? "" : errors.mFee && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            style={{ width: '150px' }}
          />
        </TableCell>
        <TableCell>
          <input
            onChange={handleChange('fFee')}
            type="number"
            name="fFee"
            placeholder="Female Fee"
            defaultValue={FFee === undefined ? "" : FFee}
            className={`form-control${errors.fFee === undefined ? "" : errors.fFee && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            style={{ width: '150px' }}
          />
        </TableCell>
        <TableCell>
          <input
            onChange={handleChange('dueDate')}
            type="date"
            name="dueDate"
            placeholder="Due Date"
            defaultValue={DueDate === undefined ? "" : moment(DueDate).format('YYYY-MM-DD')}
            className={`form-control${errors.dueDate === undefined ? "" : errors.dueDate && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            style={{ width: '150px' }}
          />
        </TableCell>
        <TableCell align="center">
          <FormControl>
            <FormControlLabel
              name="isActive"
              inputRef={register()}
              control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
              label="Active"
            />
          </FormControl>
        </TableCell>
        <TableCell align="center">
          {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => props.onHandleAdd(data)}>
            <i className="fa fa-plus"></i>
          </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
            <i className="fa fa-ban"></i>
          </button>&nbsp;
            <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(AssessmentTemplateOtherRateId, data)}>
              <i className="fa fa-save text-white"></i>
            </button></>}
        </TableCell>
      </TableRow>
    </>
  );
}