import { number } from "prop-types";
import React, { useRef, forwardRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './__hooks__/styles.css'
import DashLine from "../../../../../_metronic/layout/components/custom/line/DashLine";
const useStyles = makeStyles({
    table: {
        minWidth: 650,
        backgroundColor: 'transparent'
    },

});



export default function PrintFacultyLoadingReport(props) {
    const { results } = props
    let componentRef = useRef();
    const classes = useStyles();
    const [facultySubHeights, setFacultySubHeights] = useState([]);

    function convertTo12HourFormat(timeString) {
        // Split the time string into hours, minutes, and seconds
        const [hour, minute, second] = timeString.split(':').map(Number);

        // Determine whether it's AM or PM
        const period = hour >= 12 ? 'PM' : 'AM';

        // Convert to 12-hour format
        const twelveHour = hour % 12 || 12; // Ensure 12:00:00 is converted to 12:00:00 PM

        // Format the components with leading zeros
        const formattedHour = twelveHour.toString().padStart(2, '0');
        const formattedMinute = minute.toString().padStart(2, '0');
        const formattedSecond = second.toString().padStart(2, '0');

        // Create the formatted time string
        const formattedTime = `${formattedHour}:${formattedMinute}:${formattedSecond} ${period}`;

        return formattedTime;
    }

    useEffect(() => {
        // Function to calculate and set the heights of facultySub elements
        const adjustHeightForPrint = () => {
            const updatedHeights = [];

            results.forEach((res) => {
                const facultySub = document.getElementById(`facultySub_${res.EmployeeId}`);
                if (facultySub) {
                    const table = facultySub.querySelector('.table');
                    const footer = facultySub.querySelector('.footer-subFaculty');

                    const pageHeight = window.innerHeight;
                    const contentHeight = table.offsetHeight + footer.offsetHeight;

                    if (contentHeight > pageHeight) {
                        const newHeight = pageHeight - footer.offsetHeight;
                        updatedHeights.push({ id: res.EmployeeId, height: newHeight });
                    }
                }
            });

            setFacultySubHeights(updatedHeights);
        };

        // Attach the adjustHeightForPrint function to the beforeprint event
        window.addEventListener('beforeprint', adjustHeightForPrint);

        // Clean up the event listener when the component unmounts
        return () => {
            window.removeEventListener('beforeprint', adjustHeightForPrint);
        };
    }, [results]);


    const Component = forwardRef((props, ref) => {
        const pageStyle = " @page { size:  8.5in 13in; }"

        return (




            <div className="d-none" >
                <style>{pageStyle}</style>
                <div ref={ref}>
                    <div className="container" >

                        <main className="card border-0 " >


                            <section className="card-body" >
                                {results.map((res) => {
                                    return (
                                        <>
                                            <section style={{ display: 'flex', flexDirection: 'column', height: '100vh', pageBreakInside: 'avoid', whiteSpace: 'nowrap' }}>
                                                <div
                                                    id={`facultySub_${res.EmployeeId}`}
                                                    className=""
                                                    style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '50%' }}
                                                >

                                                    <div className="mt-5">
                                                        <img style={{ width: "200px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                                        <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                                        <h4 className="text-center">Faculty Teaching Assignment</h4>
                                                        <h4 className="text-center">A.Y. {res.SY}, {res.Semester}</h4>
                                                    </div>



                                                    <h6 className="mt-5"><span className="lead">Name: </span>{res.Faculty}</h6>
                                                    {/* <TableContainer key={res.EmployeeId} component={Paper} > */}
                                                    <table className='table table-hover  table-borderless table-sm ' size="small" aria-label="a dense table" style={{ background: 'transparent', whiteSpace: 'nowrap' }}>
                                                        <thead>
                                                            <tr>
                                                                <TableCell>Class</TableCell>
                                                                <TableCell align="left" style={{ width: '12%' }}>Section</TableCell>
                                                                <TableCell align="left">Subject Code</TableCell>
                                                                <TableCell align="left">Subject Description</TableCell>
                                                                <TableCell align="right">Units</TableCell>
                                                                <TableCell align="right">Hours</TableCell>
                                                                <TableCell align="right" style={{ width: '10%' }}>Time Start</TableCell>
                                                                <TableCell align="right" style={{ width: '10%' }}>Time End</TableCell>
                                                                <TableCell align="right">Day</TableCell>
                                                                <TableCell align="right">Room</TableCell>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {res.Details.map((row) => (
                                                                <tr className="mt-5 ">
                                                                    <td component="th" scope="row">
                                                                        {row.ClassCode}
                                                                    </td>
                                                                    <td align="left">{row.ClassSection}</td>
                                                                    <td align="left">{row.SubjectCode}</td>
                                                                    <td align="left">{row.SubjectDescription}</td>
                                                                    <td align="right">{row.Units == 0 ? '' : row.Units}</td>
                                                                    <td align="right">{row.Hours}</td>
                                                                    <td align="right">{row.TimeStart}</td>
                                                                    <td align="right">{row.TimeEnd}</td>
                                                                    <td align="right">{row.DayCode}</td>
                                                                    <td align="right">{row.Room}</td>


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {/* </TableContainer> */}
                                                    <br />
                                                    <h5 className="lead"> Total Units: <span className="font-weight-bold">{res.TotalUnits}</span>  Total Hours: <span className="font-weight-bold">{res.TotalHours}</span> </h5>
                                                    <br />

                                                    <footer className="d-flex justify-content-around footer-subFaculty">
                                                        <div>
                                                            <h5>Conforme</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>
                                                            <h5>{res.Faculty}</h5>
                                                        </div>
                                                        <div>
                                                            <h5>Recommending Approval</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>
                                                        <div>
                                                            <h5>Approved</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>
                                                    </footer>
                                                </div>
                                                <DashLine />
                                                <div
                                                    id={`facultySub_${res.EmployeeId}`}
                                                    className=""
                                                    style={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'flex-start', height: '50%' }}
                                                >

                                                    <div className="mt-5">
                                                        <img style={{ width: "200px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                                        <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                                        <h4 className="text-center">Faculty Teaching Assignment</h4>
                                                        <h4 className="text-center">A.Y. {res.SY}, {res.Semester}</h4>
                                                    </div>



                                                    <h6 className="mt-5"><span className="lead">Name: </span>{res.Faculty}</h6>
                                                    {/* <TableContainer key={res.EmployeeId} component={Paper} > */}
                                                    <table className='table table-hover  table-borderless table-sm ' size="small" aria-label="a dense table" style={{ background: 'transparent', whiteSpace: 'nowrap' }}>
                                                        <thead>
                                                            <tr>
                                                                <TableCell>Class</TableCell>
                                                                <TableCell align="left" style={{ width: '12%' }}>Section</TableCell>
                                                                <TableCell align="left">Subject Code</TableCell>
                                                                <TableCell align="left">Subject Description</TableCell>
                                                                <TableCell align="right">Units</TableCell>
                                                                <TableCell align="right">Hours</TableCell>
                                                                <TableCell align="right" style={{ width: '10%' }}>Time Start</TableCell>
                                                                <TableCell align="right" style={{ width: '10%' }}>Time End</TableCell>
                                                                <TableCell align="right">Day</TableCell>
                                                                <TableCell align="right">Room</TableCell>
                                                            </tr>
                                                        </thead>
                                                        <tbody>
                                                            {res.Details.map((row) => (
                                                                <tr className="mt-5 ">
                                                                    <td component="th" scope="row">
                                                                        {row.ClassCode}
                                                                    </td>
                                                                    <td align="left">{row.ClassSection}</td>
                                                                    <td align="left">{row.SubjectCode}</td>
                                                                    <td align="left">{row.SubjectDescription}</td>
                                                                    <td align="right">{row.Units == 0 ? '' : row.Units}</td>
                                                                    <td align="right">{row.Hours}</td>
                                                                    <td align="right">{row.TimeStart}</td>
                                                                    <td align="right">{row.TimeEnd}</td>
                                                                    <td align="right">{row.DayCode}</td>
                                                                    <td align="right">{row.Room}</td>


                                                                </tr>
                                                            ))}
                                                        </tbody>
                                                    </table>
                                                    {/* </TableContainer> */}
                                                    <br />
                                                    <h5 className="lead"> Total Units: <span className="font-weight-bold">{res.TotalUnits}</span>  Total Hours: <span className="font-weight-bold">{res.TotalHours}</span> </h5>
                                                    <br />

                                                    <footer className="d-flex justify-content-around footer-subFaculty">
                                                        <div>
                                                            <h5>Conforme</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>
                                                            <h5>{res.Faculty}</h5>
                                                        </div>
                                                        <div>
                                                            <h5>Recommending Approval</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>
                                                        <div>
                                                            <h5>Approved</h5>
                                                            <br />
                                                            <span className="horizontal-line-subFaculty"></span>

                                                        </div>
                                                    </footer>
                                                </div>
                                            </section>


                                        </>
                                    )
                                })}


                            </section>

                        </main>


                    </div>

                </div>



            </div>



        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn btn-sm btn-success mr-3">Faculty Teaching Assign</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}

                />
            </div>



        </>
    )


}