import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import InstructionalGuideDetailList from './InstructionalGuideDetails/List';
import InstructionalGuideSchedule from './InstructionalGudeSchedule/List'
import InstructionalGuideOverride from './InstructionalGuideOverride/List'
// import FinalGradeCompositionList from './FinalGradeComposition/List'
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { useParams } from 'react-router-dom';
import { getInstructionalGuideQuery, instructionalGuideIsOverride } from "./__hooks__/index"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.png"
import { result } from 'lodash';
const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    heading: {
        fontSize: theme.typography.pxToRem(15),
        fontWeight: theme.typography.fontWeightRegular
    },
    chip: {
        margin: theme.spacing(1),
    },
}));




export default function Container(props) {
    const { data } = props.location
    if (data == undefined) {
        history.push(`/admin/student/instructional-guide`);
    }
    const classes = useStyles();
    const [results, setResults] = useState(null)
    const { id } = useParams();
    const [expanded, setExpanded] = useState({
        periodicalGradeCompositionPanel: false,
        finalGradeCompositionPanel: false,
        overrideIG: false
    });
    const [isExceedsPercentage, setIsExceedsPercentage] = useState(false)
    const [isOverride, setIsOverride] = useState(true)
    const [reloadIg, setReloadIg] = useState(true)
    const [reloadOverride, setReloadOverride] = useState(true)
    const [instructionalTypeId, setInstructionalTypeId] = useState([])

    const handleChange = (panel) => (event, isExpanded) => {
        setExpanded({ ...expanded, [panel]: isExpanded });
    };


    const onExceedPercentageHandler = (val) => {
        setIsExceedsPercentage(val)
    }

    const executeIGReload = () => {
        setReloadIg((prevData) => { return !prevData })
    }

    const executeReloadOverride = () => {
        setReloadOverride((prevData) => { return !prevData })
    }


    const getInstructionalType = (val) => {
        setInstructionalTypeId(val)
    }

    useEffect(() => {
        const getCurrentInstructGuide = async (data) => {
            await getInstructionalGuideQuery(data)
                .then(res => {
                    setResults(res.data)
                })
        }

        const getIsOverride = async () => {
            await instructionalGuideIsOverride({
                subjectScheduleDetailId: data.SubjectScheduleDetailId,
                schoolYear: data.SchoolYear,
                semesterId: data.SemesterId
            }).then(res => {
                if (res.data[0].IsOverride == 1) {
                    setIsOverride(true)
                } else
                    setIsOverride(false)
            })
        }
        getIsOverride()
        getCurrentInstructGuide({ instructionalGuideId: parseInt(id) })
    }, [])

    useEffect(() => {
        if (isExceedsPercentage == true) {
            setTimeout(() => {
                setIsExceedsPercentage(false)
            }, 3000)
        }
    }, [isExceedsPercentage])


    console.log(results)
    console.log(data)
    return (
        <>
            {results && results.length > 0 &&

                <>

                    <article className='card p-5'>
                        <section className='card-header'>
                            <h4> BASIC INFORMATION</h4>
                        </section>
                        <section className='card-body row'>
                            <div className='col-12'>
                                <div className="card ">
                                    <div className="card-header bg-secondary">
                                        <h5>Instructional Guide Details</h5>
                                    </div>
                                    <div className="card-body">
                                        <section className='row'>
                                            <div className='col-4 text-center border-right border-primary'>
                                                <img src={ProfileLogo} style={{ width: '150px' }} />
                                                <h5 className="card-title mt-5">{results[0].FacultyName}</h5>
                                                <p className="card-text lead">{data.Title}</p>

                                            </div>
                                            <div className='col-8'>
                                                <table style={{ borderCollapse: 'collapse' }}>

                                                    <tbody>
                                                        <tr className='mb-3'>
                                                            <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Class Section: </td>
                                                            <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].BlockSection}</td>
                                                        </tr>
                                                        <tr className='mb-3'>
                                                            <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Subject Code: </td>
                                                            <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].SubjectCode}</td>
                                                        </tr>
                                                        <tr className='mb-3'>
                                                            <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Subject Type</td>
                                                            <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].InstructionalScheduleTypeId == 3 ? 'Lecture / Laboratory' : results[0].InstructionalScheduleTypeId == 2 ? 'Laboratory' : results[0].InstructionalScheduleTypeId == 1 ? 'Lecture' : ''}</td>
                                                        </tr>
                                                        <tr className='mb-3'>
                                                            <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Number of Units</td>
                                                            <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].Units}</td>
                                                        </tr>
                                                        <tr className='mb-3'>
                                                            <td style={{ paddingRight: '8px', paddingLeft: '8px' }}>Grading Computation Template</td>
                                                            <td className='font-weight-bold' style={{ paddingRight: '8px', paddingLeft: '8px' }}>{results[0].GradeTemplate}</td>
                                                        </tr>

                                                    </tbody>
                                                </table>
                                                {/* <div className='row'>
                                                    <div className='col-2'>
                                                        <p className=' mb-1'>Class Section:</p>
                                                        <p className=' mb-1'>Subject Code:</p>
                                                        <p className=' mb-1'>Description / Title:</p>
                                                        <p className=' mb-1'>Subject Type:</p>
                                                    </div>
                                                    <div className='col-10'>
                                                        <p className='font-weight-bold mb-1'>{results[0].SubjectCode}</p>
                                                        <p className='font-weight-bold mb-1'>Subject Code:</p>
                                                        <p className='font-weight-bold mb-1'>Description / Title:</p>
                                                        <p className='font-weight-bold mb-1'>Subject Type:</p>
                                                    </div>
                                                </div> */}
                                            </div>
                                        </section>

                                        {/* <p className="card-text lead text-success">{data.SubjectType}</p> */}

                                    </div>
                                    <div className="card-footer text-muted">
                                        Units: <span>{results[0].Units}</span>
                                    </div>
                                </div>
                            </div>
                            <div className='col-12 text-left'>
                                {/* <div className="jumbotron">
                                    <h5 className="display-4">Instructional Guide Details</h5>
                                    <hr className="my-4" />
                                    <p className='lead'>Grading Computation Template:</p>
                                    <p className='lead'>Prelim</p>
                                    <p className='lead'>Midterms</p>
                                    <p className='lead'>Finals</p>  <p className="lead">
                                        <a className="btn btn-primary btn-lg" href="#" role="button">Learn more</a>
                                    </p>
                                </div> */}
                                <div className="card card-custom gutter-b example example-compact">
                                    <div className="card-header" style={{ paddingLeft: "15px" }}>
                                        <div className="card-title ">
                                            <h4 className='lead '>Instructional Guide</h4>
                                        </div>
                                    </div>
                                    <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                        <div className={classes.root}>
                                            <Accordion expanded={expanded.periodicalGradeCompositionPanel} onChange={handleChange('periodicalGradeCompositionPanel')} >
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel1bh-content"
                                                    id="panel1bh-header"
                                                    style={{ background: "#efefef" }}
                                                >
                                                    {isExceedsPercentage ? (
                                                        <div
                                                            className="alert"
                                                            role="alert"
                                                            style={{
                                                                backgroundColor: '#ffcccb',
                                                                opacity: '1',
                                                                transition: 'opacity 5.0s ease-in-out',
                                                            }}
                                                        >
                                                            <i className="fa fa-exclamation-circle"></i> &nbsp; &nbsp; Total Percentage Should be equal or less than 100
                                                        </div>
                                                    ) : (
                                                        <Typography
                                                            className={`${classes.heading}`}
                                                            style={{
                                                                opacity: '1',
                                                                transition: 'opacity 5.0s ease-in-out',
                                                            }}
                                                        >
                                                            <span className="text-primary lead"><b>Modified Outline</b></span>
                                                        </Typography>
                                                    )}
                                                    <Typography className={classes.secondaryHeading}>&nbsp;</Typography>



                                                </AccordionSummary>
                                                <AccordionDetails style={{ flexDirection: "column", padding: "0" }}>
                                                    < InstructionalGuideDetailList id={parseInt(id)} onExceedPercentageHandler={onExceedPercentageHandler} isExceedsPercentage={isExceedsPercentage} data={data} reloadIg={reloadIg} executeReloadOverride={executeReloadOverride} />
                                                </AccordionDetails>
                                            </Accordion>
                                            <Accordion expanded={expanded.finalGradeCompositionPanel} onChange={handleChange('finalGradeCompositionPanel')}>
                                                <AccordionSummary
                                                    expandIcon={<ExpandMoreIcon />}
                                                    aria-controls="panel2bh-content"
                                                    id="panel2bh-header"
                                                    style={{ background: "#efefef" }}
                                                >
                                                    <Typography className={classes.heading}><span className="text-primary lead"><b>Schedule / Section</b></span></Typography>
                                                    <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                                                </AccordionSummary>
                                                <AccordionDetails style={{ flexDirection: "column", padding: "0" }}>
                                                    <InstructionalGuideSchedule id={parseInt(id)} data={data} getInstructionalType={getInstructionalType} />
                                                </AccordionDetails>
                                            </Accordion>
                                            {isOverride == true &&
                                                <Accordion expanded={expanded.overrideIG} onChange={handleChange('overrideIG')}>
                                                    <AccordionSummary
                                                        expandIcon={<ExpandMoreIcon />}
                                                        aria-controls="panel2bh-content"
                                                        id="panel2bh-header"
                                                        style={{ background: "#efefef" }}
                                                    >
                                                        <Typography className={classes.heading}><span className="text-primary lead"><b>Override</b></span></Typography>
                                                        <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                                                    </AccordionSummary>
                                                    <AccordionDetails style={{ flexDirection: "column", padding: "0" }}>
                                                        <InstructionalGuideOverride id={parseInt(id)} data={data} executeIGReload={executeIGReload} reloadOverride={reloadOverride} instructionalTypeId={instructionalTypeId} />
                                                    </AccordionDetails>
                                                </Accordion>

                                            }


                                        </div>
                                    </div>
                                    <div className="card-footer" style={{ padding: '0px', margin: '0px' }}>
                                        <Link to={{ pathname: referenceTypes.LIST_LINK_TO_PARENT, prevData: results }} className="btn btn-secondary m-3">Back</Link>
                                    </div>
                                </div>


                            </div>
                        </section>
                    </article>



                </>

            }

        </>
    );
}