import instance from "../../../../../apis/local/systemAPI";

export const searchOfficialReceipt = async (data) => {
    const result = await instance.post("api/campus/official-receipt-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentPayments = async (data) => {
    const result = await instance.post("api/campus/student-payment-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const savePayments = async (formValues) => {
    const result = await instance.post("api/payment/migrateCampus", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



