import instance from "../../../../../apis/local/systemAPI";


export const getStudentSubjects = async (data) => {
    const result = await instance.post("api/registrar/addDropSubject/report", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentSubjectSelect = async (val) => {
    const result = await instance.post(`api/registrar/student/subjectSelect`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const addingDroppingOfSubjects = async (val) => {
    const result = await instance.post(`api/registrar/addDropSubject/addingDroppingOfSubjects`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceSearchSubjects = async (val) => {
    const result = await instance.post(`api/student/student-attendance/attendanceSearchSubjects`,val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// // Reused from References
// export const getActiveSemester = async () => {
//     const result = await instance.get("api/references/semesters");
//     if (result.status === 200 || result.status === 201) {
//         return result.data;
//     }
// }

// // Reused from References
// export const getActiveSchoolYear = async () => {
//     const result = await instance.get("api/references/schoolyears");
//     if (result.status === 200 || result.status === 201) {
//         return result.data;
//     }
// }

// Reused from Student Grade Viewing
export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/student/student-grade-viewing/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectsForDropdown = async (val) => {
    const result = await instance.post(`api/registrar/addDropSubject/getSubjectsForDropdown`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentClearanceReport = async (val) => {
    const result = await instance.post(`api/students/studentClearanceReport`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYearListForClearance = async () => {
    const result = await instance.get("api/students/getSchoolYearListForClearance");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemesterListForClearance = async () => {
    const result = await instance.get("api/students/getSemesterListForClearance");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCustomCourseList = async () => {
    const result = await instance.get("api/student/student-grade-viewing/getCustomCourseList/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClearanceStatus = async () => {
    const result = await instance.get("api/students/getClearanceStatus");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}