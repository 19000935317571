export const referenceTypes = {
    SELECTED_PROVINCE: 'SELECTED-PROVINCE',
    GET_PROVINCES: 'GET-PROVINCES',
    GET_PROVINCE: 'GET-PROVINCE-BY-ID',
    CREATE: 'CREATE-PROVINCE',
    UPDATE: 'UPDATE-PROVINCE',
    DELETE: 'DELETE-PROVINCE',
    LIST_LINK: '/admin/references/provinces',
    CREATE_LINK: '/admin/references/create-provinces/',
    UPDATE_LINK: '/admin/references/update-provinces/',
    DELETE_LINK: '/admin/references/delete-provinces/'
}