import { number } from "prop-types";
import React, { useRef, forwardRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './__hooks__/styles.css'

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },

});



export default function PrintFacultyLoadingReportBySection(props) {
    const { results } = props
    let componentRef = useRef();
    const classes = useStyles();


    const Component = forwardRef((props, ref) => {
        const pageStyle = " @page { size: 13in 8.5in; }"

        return (
            <>



                <div
                    style={{ display: 'none' }}
                >
                    <style>{pageStyle}</style>
                    <div ref={ref}>
                        <div  >

                            <main className="card border-0 p-5">

                                <img style={{ width: "150px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                <h4 className="text-center">Subject Schedule Report</h4>
                                <h4 className="text-center">A.Y. {results[0].SY}, {results[0].Semester}</h4>
                                {results[0].IsFilteredReport == 1 && <h4 className="text-center">{results[0].InstituteDesc}</h4>}
                                <section className="card-body " >
                                    {results.map((res, index) => {
                                        return (
                                            <>
                                                <div style={{ pageBreakInside: 'avoid' }}>

                                                    {/* <p className="mr-auto">
                                                        <span style={{ backgroundColor: 'lightgray', color: 'white', padding: '2px 2px' }}>&nbsp;</span> Additional Lecture Schedule
                                                        <span style={{ backgroundColor: '#87CEEB', color: 'white', padding: '2px 2px' }}>&nbsp;</span> Laboratory Schedule
                                                    </p> */}



                                                    <TableContainer key={res.ClassSectionId} component={Paper}>
                                                        <h6 className="ml-auto mt-5" style={{ pageBreakInside: 'avoid' }}><span className="lead">   &nbsp;&nbsp;&nbsp;&nbsp;Section: </span>{res.ClassSection} {results[0].IsFilteredReport == 0 && `(${res.Institute})`}</h6>
                                                        <hr />
                                                        <Table className={classes.table} size="small" aria-label="a dense table">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {/* <TableCell align="left">Class</TableCell> */}
                                                                    <TableCell align="center">Code</TableCell>
                                                                    <TableCell align="center">Subject</TableCell>
                                                                    <TableCell align="center">Description</TableCell>
                                                                    <TableCell align="center" style={{ width: '15%' }}>Time</TableCell>
                                                                    <TableCell align="center">Days</TableCell>
                                                                    <TableCell align="center">Room</TableCell>
                                                                    <TableCell align="center">Unit</TableCell>
                                                                    <TableCell align="center">Lec/Lab</TableCell>
                                                                    <TableCell align="center">Status</TableCell>
                                                                    <TableCell align="center">Enr/Max</TableCell>
                                                                    <TableCell align="center">Instructor</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {res.Details.map((row) => (
                                                                    <>
                                                                        <TableRow >
                                                                            {/* <TableCell align="left" >{row.Institute}</TableCell> */}
                                                                            <TableCell align="center">{row.ClassCode}</TableCell>
                                                                            <TableCell align="center">{row.SubjectCode}</TableCell>
                                                                            <TableCell align="center">{row.SubjectDescription}</TableCell>
                                                                            <TableCell align="center">{row.LecSchedules[0].LecTime}</TableCell>
                                                                            <TableCell align="center">{row.LecSchedules[0].LecDayCode}</TableCell>
                                                                            <TableCell align="center">{row.LecSchedules[0].LecRoom}</TableCell>
                                                                            <TableCell align="center">{row.CreditUnit}</TableCell>
                                                                            <TableCell align="center">{row.LecLab}</TableCell>
                                                                            <TableCell align="center">{row.ClassStatus}</TableCell>
                                                                            <TableCell align="center">{row.Capacity}</TableCell>
                                                                            <TableCell align="center">{row.Instructor}</TableCell>
                                                                        </TableRow>

                                                                        {row.LecSchedules.length > 1 &&
                                                                            row.LecSchedules.slice(1).map(lec => (
                                                                                <TableRow className="bg-secondary">
                                                                                    {/* <TableCell component="th" scope="row"></TableCell> */}
                                                                                    <TableCell align="left"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center">{lec.LecTime}</TableCell>
                                                                                    <TableCell align="center">{lec.LecDayCode}</TableCell>
                                                                                    <TableCell align="center">{lec.LecRoom}</TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>

                                                                                </TableRow>
                                                                            ))


                                                                        }

                                                                        {row.LabSchedules.length > 0 &&
                                                                            row.LabSchedules.map(lab => (
                                                                                <TableRow style={{ backgroundColor: '#c0ddeb' }}>
                                                                                    {/* <TableCell component="th" scope="row"></TableCell> */}
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center">{lab.LabTime}</TableCell>
                                                                                    <TableCell align="center">{lab.LabDayCode}</TableCell>
                                                                                    <TableCell align="center">{lab.LabRoom}</TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>
                                                                                    <TableCell align="center"></TableCell>

                                                                                </TableRow>
                                                                            ))


                                                                        }
                                                                    </>

                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                        <section className="d-flex mt-5">
                                                            &nbsp;&nbsp;&nbsp;&nbsp;
                                                            <p className="mr-5">Total Unit: <span className="text-dark">{res.TotalCreditUnit}</span></p>
                                                            <p className="mr-5" >Total Lec Hour: <span className="text-dark" >{res.TotalLecHours}</span></p>
                                                            <p className="mr-5" >Total Lab Hour: <span className="text-dark" >{res.TotalLabHours}</span></p>
                                                        </section>
                                                    </TableContainer>



                                                </div>

                                            </>
                                        )
                                    })}


                                </section>

                            </main>


                        </div>

                    </div>



                </div>


            </>
        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn ml-5 btn-success mr-3">Report</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}
                    pageStyle={`
                        @page {
                        size: 2.5in 4in;
                        margin: 20mm 0mm 25mm 0mm;
                        }
                  `}

                />
            </div>



        </>
    )


}