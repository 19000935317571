import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch, Modal, Box } from '@material-ui/core';
import { ModeOfPaymentSelect } from '../../../References/';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import moment from 'moment';
import IMDialogDuplicateAssessmentFee from '../../../../../../_metronic/layout/components/custom/dialog/IMDialogDuplicateAssessmentFee';



export default function Form(props) {
    const { state: { fees, computationTypes } } = useAppContext();
    const { PaymentScheduleId, PaymentMode, PaymentModeId, Amount, Date, Quarter, InitialFee, IsActive } = props.doc;
    const { assessmentTemplateInitialFee, isUE } = props
    const { register, errors, setValue } = useForm();
    const [isOpen, setIsOpen] = useState(false)
    const [isUponEnrollment, setIsUponEnrollment] = useState(false)
    const [duplicateDialogue, setDuplicateDialogue] = useState(false)



    const recordCnt = props.doc.length;

    const onClose = () => {
        setIsOpen(false)
    }
    const onOpen = () => {
        setIsOpen(true)
    }

    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };

    const initialState = recordCnt === 0 ? {
        assessmentTemplateId: props.assessmentTemplateId,
        date: null,
        paymentModeId: null,
        quarter: null,
        initialFee: null,
        isActive: true
    } : {
        assessmentTemplateId: props.assessmentTemplateId,
        date: Date,
        paymentModeId: PaymentModeId,
        quarter: Quarter,
        initialFee: InitialFee,
        isActive: true
    }
    const [data, setData] = useState(initialState);

    useEffect(() => {
        register({ name: 'paymentModeId' }, { required: true });

    }, [register])

    const handleChange = name => event => {
        if (name === 'isActive') {
            setData({ ...data, [name]: event.target.checked });
        } else if (name === 'amount') {
            setData({ ...data, [name]: event.target.value });
        } else if (name === 'date') {
            setData({ ...data, [name]: event.target.value });
        } else if (name === 'quarter') {
            setData({ ...data, [name]: event });
        } else {
            setData({ ...data, [name]: event });
        }
        console.log(event)
    }

    const AddModal = (props) => {
        const noHandle = () => {
            props.onHandleAdd(data)
            props.onClose()
        }

        return (
            <Modal
                open={props.isOpen}
                onClose={props.onClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="card p -5" style={style}>
                    <div className="card-header"> <h3>Duplicate Entry</h3> </div>
                    <div className="card-body">

                        <p className="card-text">Do you want to Duplicate Entry</p>
                        <button onClick={() => { props.onHandleAdd(data, "d") }} className="btn btn-primary mr-3">Yes</button>
                        <button onClick={() => { noHandle() }} className="btn btn-secondary mr-3">No</button>
                        <button onClick={() => { props.onClose() }} className="btn btn-secondary ">Back</button>

                    </div>
                </Box>


            </Modal>
        )
    }

    const selectHandleChange = name => event => {
        if (event === 'UE') {
            setIsUponEnrollment(true)
        } else {
            setIsUponEnrollment(false)

        }
        let count = props.siblingsCount
        setData({ ...data, [name]: event, 'siblingCount': count });
    }

    console.log(PaymentScheduleId)
    console.log(PaymentModeId === undefined)
    console.log(data)
    console.log(Quarter)
    console.log(assessmentTemplateInitialFee)
    return (
        <>
            {duplicateDialogue && <IMDialogDuplicateAssessmentFee
                title={`Question`}
                onHandleAdd={() => props.onHandleAdd(data, 'd')}
                onHandleDuplicate={() => props.onHandleDuplicate(data)}
                onShowDialog={setDuplicateDialogue}
                isOpen={true}
            />}
            <TableRow key={PaymentScheduleId}>
                <AddModal
                    isOpen={isOpen}

                    onHandleAdd={props.onHandleAdd}
                    onClose={onClose}
                />
                <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
                <TableCell>
                    <IMSelect
                        onHandleChange={selectHandleChange(`quarter`)}
                        refClassContainer="form-group mb-3"
                        name={`quarter`}
                        isRequired={true}
                        withLabel={false}
                        label="Quarter"
                        placeHolder=""
                        forwardRef={setValue}
                        selectedId={data.quarter === null ? Quarter : data.quarter}
                        refClassName={``}
                        withDescription={false}
                        // description={`Please select your gender.`}
                        refDisabled={true}
                        refIsStatic={true}
                        refStaticData={[
                            { id: '', label: '' },
                            { id: 'UE', label: 'Upon Enrollment' },
                            { id: 'P', label: 'Prelim' },
                            { id: 'M', label: 'Midterm' },
                            { id: 'SF', label: 'Semi Finals' },
                            { id: 'F', label: 'Finals' }


                        ]}
                        field={{
                            tableId: '',
                            display: ''
                        }}
                    // error={!!errors.gender}
                    // errorMessage={errors.gender && errors.gender.type === "required" && 'Gender is required'}
                    />
                </TableCell>
                <TableCell>
                    <input
                        onChange={handleChange('date')}
                        type="date"
                        name="amount"
                        placeholder="Amount"
                        defaultValue={Date === undefined ? "" : moment(Date).format('YYYY-MM-DD')}
                        className={`form-control${errors.date === undefined ? "" : errors.date && " is-invalid"}`}
                        ref={register({
                            required: true
                        })}
                        autoComplete="off"
                    />
                </TableCell>
                {(isUponEnrollment === true || isUE === true) && data.quarter === 'UE' ?

                    <TableCell>
                        <input
                            className="form-control align-middle"
                            onChange={(e) => { setData({ ...data, initialFee: e.target.value }) }}
                            type="number"
                            name="initialFee"
                            placeholder="Initial Fee"
                            defaultValue={assessmentTemplateInitialFee === undefined ? "" : assessmentTemplateInitialFee}
                            ref={register({
                                required: true
                            })}
                            autoComplete="off"
                        />

                    </TableCell>

                    :
                    <TableCell>

                    </TableCell>
                }



                <TableCell align="center">
                    <FormControl>
                        <FormControlLabel
                            name="isActive"
                            inputRef={register()}
                            control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
                            label="Active"
                        />
                    </FormControl>
                </TableCell>
                <TableCell align="center">
                    {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => { props.onHandleAdd(data) }}>
                        <i className="fa fa-plus"></i>
                    </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
                        <i className="fa fa-ban"></i>
                    </button>&nbsp;
                        <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(PaymentScheduleId, data)}>
                            <i className="fa fa-save text-white"></i>
                        </button></>}
                </TableCell>
            </TableRow>
        </>
    );
}