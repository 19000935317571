import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function AddUpdateModal(props) {
    const { title, Content, isOpen, handleClose, onSubmit, isUpdate, isAdd, isDelete,
        instituteSelect, id, gradeElementSelect, idValue, gradingPeriodSelect, semesterSelect,
        schoolYearSelect, employeeSelect, gradeTemplateSelect, subjectCodeSelect,
        instructionalGuideSelect, examTypeSelect, subjectSchedSelect, userSelect, courseSelect,
        yearLevelSelect, subjectSelect, classSectionSelect, subjectCategorySelect, civilStatusSelect,
        religionSelect, countrySelect, provinceSelect, schoolSelect, type, isMerger, isExceedsPercentage, subTotalSelect,
        paymentModeSelect, familyPositionSelect, nationalitySelect, municipalitySelect, isFinalize,
        isFinalizeAll, isProcessing } = props;
    const [formValues, setFormValues] = useState({})

    const onSave = (data) => {
        setFormValues(data)
    }

    console.log(props.value)
    console.log(formValues)
    console.log({ ...props.value, ...formValues })
    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth={type == 'studentAccount' ? 'lg' : 'sm'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <hr />
                <DialogContent>
                    {props.value != null && props.value != undefined ?
                        <Content
                            onSave={onSave}
                            value={props.value}
                            instituteSelect={instituteSelect}
                            gradeElementSelect={gradeElementSelect}
                            gradingPeriodSelect={gradingPeriodSelect}
                            schoolYearSelect={schoolYearSelect}
                            semesterSelect={semesterSelect}
                            gradeTemplateSelect={gradeTemplateSelect}
                            employeeSelect={employeeSelect}
                            subjectCodeSelect={subjectCodeSelect}
                            instructionalGuideSelect={instructionalGuideSelect}
                            examTypeSelect={examTypeSelect}
                            subjectSchedSelect={subjectSchedSelect}
                            userSelect={userSelect}
                            courseSelect={courseSelect}
                            yearLevelSelect={yearLevelSelect}
                            subTotalSelect={subTotalSelect}
                            subjectSelect={subjectSelect}
                            classSectionSelect={classSectionSelect}
                            subjectCategorySelect={subjectCategorySelect}
                            civilStatusSelect={civilStatusSelect}
                            religionSelect={religionSelect}
                            countrySelect={countrySelect}
                            provinceSelect={provinceSelect}
                            schoolSelect={schoolSelect}
                            paymentModeSelect={paymentModeSelect}
                            familyPositionSelect={familyPositionSelect}
                            nationalitySelect={nationalitySelect}
                            municipalitySelect={municipalitySelect}
                            idValue={idValue}
                            isExceedsPercentage={isExceedsPercentage}
                        />
                        :
                        <Content
                            onSave={onSave}
                            instituteSelect={instituteSelect}
                            gradeElementSelect={gradeElementSelect}
                            gradingPeriodSelect={gradingPeriodSelect}
                            schoolYearSelect={schoolYearSelect}
                            semesterSelect={semesterSelect}
                            gradeTemplateSelect={gradeTemplateSelect}
                            employeeSelect={employeeSelect}
                            subjectCodeSelect={subjectCodeSelect}
                            instructionalGuideSelect={instructionalGuideSelect}
                            examTypeSelect={examTypeSelect}
                            subjectSchedSelect={subjectSchedSelect}
                            userSelect={userSelect}
                            courseSelect={courseSelect}
                            yearLevelSelect={yearLevelSelect}
                            subjectSelect={subjectSelect}
                            classSectionSelect={classSectionSelect}
                            subjectCategorySelect={subjectCategorySelect}
                            civilStatusSelect={civilStatusSelect}
                            religionSelect={religionSelect}
                            countrySelect={countrySelect}
                            subTotalSelect={subTotalSelect}
                            provinceSelect={provinceSelect}
                            schoolSelect={schoolSelect}
                            paymentModeSelect={paymentModeSelect}
                            familyPositionSelect={familyPositionSelect}
                            nationalitySelect={nationalitySelect}
                            municipalitySelect={municipalitySelect}

                            idValue={idValue}
                            isExceedsPercentage={isExceedsPercentage}
                        />


                    }

                    {/* <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                    </DialogContentText>
                </DialogContent> */}

                </DialogContent>


                <DialogActions>
                    {
                        isUpdate == true &&
                        <>
                            {isProcessing == true ?
                                <>
                                    <button className="btn btn-secondary" type="button" disabled>
                                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        Loading, Please wait...
                                    </button>

                                </>
                                :
                                <>
                                    <button onClick={() => { onSubmit(props.value[id], { ...props.value, ...formValues }) }} className="btn btn-success">
                                        Update
                                    </button>
                                </>
                            }

                        </>

                    }

                    {isAdd == true &&
                        <button onClick={() => { onSubmit(formValues) }} className="btn btn-primary">
                            Save
                        </button>

                    }

                    {isMerger == true &&
                        <button onClick={() => { onSubmit() }} className="btn btn-primary">
                            Save
                        </button>

                    }

                    {isDelete == true &&
                        <button onClick={() => { onSubmit(props.value[id], props.value) }} className="btn btn-danger">
                            Delete
                        </button>
                    }

                    {isFinalize == true &&
                        <button onClick={() => { onSubmit(props.value[id]) }} className="btn btn-primary">
                            Finalize
                        </button>
                    }

                    {isFinalizeAll == true &&
                        <button onClick={() => { onSubmit() }} className="btn btn-primary">
                            Finalize
                        </button>
                    }

                    <button onClick={handleClose} className="btn btn-secondary">
                        Cancel
                    </button>

                </DialogActions>

            </Dialog>
        </>
    );
}