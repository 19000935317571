import instance from '../../../../../apis/local/systemAPI';

/************* TO BE DELETED ************** */

export const instituteDropdown = async () => {
    const result = await instance.post("api/student/student-attendance/instituteDropdown");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const instructorDropdown = async (json) => {
    const result = await instance.post("api/student/student-attendance/instructorDropdown",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const attendanceSheet = async (json) => {
    const result = await instance.post("api/student/student-attendance/attendanceSheet",json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

/**************************************************** */

export const addPromissoryNote = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/addPromissoryNote", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const autoCompleteRefNo = async (input) => {
    const result = await instance.post("api/accounting/promissory-notes/autoCompleteRefNo", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPromissoryNote = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/getPromissoryNote", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const dropdownValues = async (input) => {
    const result = await instance.post("api/accounting/promissory-notes/dropdownValues", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const autoCompleteStudents = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/autoCompleteStudents", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const statusDropdown = async () => {
    const result = await instance.post("api/accounting/promissory-notes/statusDropdown");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDetails = async (json) => {
    const result = await instance.post("api/accounting/promissory-notes/getStudentDetails", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// Reused from References
export const getActiveSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

// Reused from References
export const getActiveSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


