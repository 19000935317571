import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch, Modal, Box } from '@material-ui/core';
import { FeeSelect, ComputationTypeSelect } from '../../../References/';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import IMDialogDuplicateAssessmentFee from '../../../../../../_metronic/layout/components/custom/dialog/IMDialogDuplicateAssessmentFee';

export default function Form(props) {
  const { state: { fees, computationTypes } } = useAppContext();
  const { AssessmentTemplateDetailId, FeeId, AssessmentComputationTypeId, Amount, InterestRate, InterestType, FeeOrder, IsActive } = props.doc;
  const { register, errors, setValue } = useForm();
  const [isOpen, setIsOpen] = useState(false)
  const [duplicateDialogue, setDuplicateDialogue] = useState(false)

  const recordCnt = props.doc.length;


  const onClose = () => {
    setIsOpen(false)
  }
  const onOpen = () => {
    setIsOpen(true)
  }

  const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.paper',

    boxShadow: 24,
    p: 4,
  };

  const initialState = recordCnt === 0 ? {
    assessmentTemplateId: props.assessmentTemplateId,
    feeId: null,
    assessmentComputationTypeId: null,
    amount: null,
    interestRate: null,
    interestType: null,
    feeOrder: null,
    isActive: true
  } : {
    assessmentTemplateId: props.assessmentTemplateId,
    feeId: FeeId,
    assessmentComputationTypeId: AssessmentComputationTypeId,
    amount: Amount,
    interestRate: InterestRate,
    interestType: InterestType,
    feeOrder: FeeOrder,
    isActive: IsActive === 1 ? true : false
  }
  const [data, setData] = useState(initialState);

  useEffect(() => {
    register({ name: 'feeId' }, { required: true });
    // register({ name: 'assessmentComputationTypeId' }, { required: true });
    // register({ name: 'interestType' }, { required: true });
  }, [register])

  const handleChange = name => event => {
    if (name === 'isActive') {
      setData({ ...data, [name]: event.target.checked });
    } else if (name === 'feeId' || name === 'assessmentComputationTypeId' || name === 'interestType') {
      setData({ ...data, [name]: event });
    } else {
      setData({ ...data, [name]: event.target.value });
    }
  }

  const AddModal = (props) => {
    const noHandle = () => {
      props.onHandleAdd(data)
      props.onClose()
    }

    return (
      <Modal
        open={props.isOpen}
        onClose={props.onClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box className="card p -5" style={style}>
          <div className="card-header"> <h3>Duplicate Entry</h3> </div>
          <div className="card-body">

            <p className="card-text">Do you want to Duplicate Entry</p>
            <button onClick={() => { props.onHandleAdd(data, "d") }} className="btn btn-primary mr-3">Yes</button>
            <button onClick={() => { noHandle() }} className="btn btn-secondary mr-3">No</button>
            <button onClick={() => { props.onClose() }} className="btn btn-secondary ">Back</button>

          </div>
        </Box>


      </Modal>
    )
  }

  return (
    <>
      {duplicateDialogue && <IMDialogDuplicateAssessmentFee
        title={`Question`}
        onHandleAdd={() => props.onHandleAdd(data, 'd')}
        onHandleDuplicate={() => props.onHandleDuplicate(data)}
        onShowDialog={setDuplicateDialogue}
        isOpen={true}
      />}



      <TableRow key={AssessmentTemplateDetailId}>
        <AddModal
          isOpen={isOpen}
          onHandleAdd={props.onHandleAdd}
          onClose={onClose}
        />
        <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
        <TableCell>
          <FeeSelect
            refOnHandleChange={handleChange('feeId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={FeeId === undefined ? "" : FeeId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? fees.data : []}
            refError={!!errors.feeId}
            refErrorMessage={errors.feeId && errors.feeId.type === "required" && 'Fee is required'}
          />
        </TableCell>
        <TableCell></TableCell>
        <TableCell>
          <ComputationTypeSelect
            refOnHandleChange={handleChange('assessmentComputationTypeId')}
            refClassContainer=""
            refWithLabel={false}
            refIsRequired={true}
            forwardRef={setValue}
            selectedId={AssessmentComputationTypeId === undefined ? "" : AssessmentComputationTypeId}
            refWithFilter={false}
            refClassName={``}
            refWithDescription={false}
            refDisabled={true}
            refIsStatic={recordCnt === undefined ? true : false}
            refStaticData={recordCnt === undefined ? computationTypes.data : []}
            refError={!!errors.assessmentComputationTypeId}
            refErrorMessage={errors.assessmentComputationTypeId && errors.assessmentComputationTypeId.type === "required" && 'Computation type is required'}
          />
        </TableCell>
        <TableCell>
          <input
            onChange={handleChange('amount')}
            type="number"
            name="amount"
            placeholder="Amount"
            defaultValue={Amount === undefined ? "" : Amount}
            className={`form-control${errors.amount === undefined ? "" : errors.amount && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
          />
        </TableCell>
        <TableCell>
          <input
            onChange={handleChange('interestRate')}
            type="number"
            name="interestRate"
            placeholder="Interest Rate"
            defaultValue={InterestRate === undefined ? "" : InterestRate}
            className={`form-control${errors.interestRate === undefined ? "" : errors.interestRate && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            maxLength="100"
          />
        </TableCell>
        <TableCell>
          <IMSelect
            onHandleChange={handleChange('interestType')}
            refClassContainer=""
            name="interestType"
            isRequired={true}
            withLabel={false}
            label="Fee Type"
            placeHolder="Fee Type"
            forwardRef={setValue}
            selectedId={data.interestType == null ? '' : data.interestType}
            refClassName={``}
            withDescription={false}
            description={`Please enter your interest type.`}
            refDisabled={true}
            refIsStatic={true}
            refStaticData={[
              { id: '', label: '' },
              { id: 'A', label: 'Amount' },
              { id: 'P', label: 'Percentage' }
            ]}
            field={{
              tableId: '',
              display: ''
            }}
            error={!!errors.interestType}
            errorMessage={errors.interestType && errors.interestType.type === "required" && 'Interest type is required'}
          />
        </TableCell>

        <TableCell align="center">
          <FormControl>
            <FormControlLabel
              name="isActive"
              inputRef={register()}
              control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
              label="Active"
            />
          </FormControl>
        </TableCell>
        <TableCell align="center">
          {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => { props.onHandleAdd(data) }}>
            <i className="fa fa-plus"></i>
          </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
            <i className="fa fa-ban"></i>
          </button>&nbsp;
            <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(AssessmentTemplateDetailId, data)}>
              <i className="fa fa-save text-white"></i>
            </button></>}
        </TableCell>
      </TableRow>
    </>
  );
}