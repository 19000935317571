export const accountTypes = {


    LIST_LINK: '/admin/accounting/cashier',
    CREATE_LINK: '/admin/accounting/cashier-payment/',
    CHART_LINK: '/admin/accounting/cashier-chart/',
    DATA_LINK: '/admin/accounting/cashier-assessment/',
    MORE_LINK: '/admin/accounting/cashier-payment/',
    ACCOUNT_SUMMARY: '/admin/student/student-account-summary'

}