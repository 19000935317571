import instance from '../../../../../apis/local/systemAPI';

// START OF FEE
export const getActiveFees = async () => {
  const result = await instance.get("api/references/fees/active")
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFees = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/fees/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "ShortName": keyword,
            "Description": keyword,
            "FeeGroup": keyword,
            "Amount": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/fees/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createFee = async (formValues) => {
  const result = await instance.post("api/references/fees", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateFee = async (id, formValues) => {
  const result = await instance.patch(`api/references/fees/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteFee = async (id) => {
  const result = await instance.delete(`api/references/fees/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF FEE