import React, { useState, useEffect } from "react";

import { useAppContext } from "../../../../contexts/useAppContext";
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.png"
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import moment from "moment";
import {
    updateORNo, studentCashierPayment, studentCashierDetailPayment,
    assignStudentCurriculum, sendMail, createAssessmentAddOns, studentPayment
} from "./__hooks__/proc";
import history from "../../../../history";
import PrintReceipt from "./PrintReciept";
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function CreatePayment(props) {
    const { studentAccount, studentFees, studentOtherFees,
        availableOtherFees, availableFees, orLimit, ORNo,
        paymentSchedule, studentExistingPaymentSchedule, isComputing, totalExcessFee,
        isOverride, feeSelect, studentAdditionalFees } = props
    console.log(studentAdditionalFees)
    // styles
    const classes = useStyles();


    // context
    const { state: { auth }, dispatch } = useAppContext()
    const { setValue } = useForm()

    // utils
    let rowCnt = 0
    const today = moment().format("YYYY-MM-DD");
    const [changeFee, setChangeFee] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    const [totalOriginalBalance, setTotalOriginalBalance] = useState(null)
    const [totalOriginalPayment, setTotalOriginalPayment] = useState(null)
    const [totalRemainingBalance, setTotalRemainingBalance] = useState(null)
    const [totalAdditionalBalance, setTotalAdditionalBalance] = useState(0)
    const [totalAdditionalPaid, setTotalAdditionalPaid] = useState(0)
    const [totalAdditionalReceived, setTotalAdditionalReceived] = useState(0)
    // data
    const fees = studentFees.concat(studentOtherFees)
    const [paymentAmount, setPaymentAmount] = useState(0)
    const [currentOrNo, setCurrentOrNo] = useState(ORNo)
    const [currentDate, setCurrentDate] = useState(today)
    const [checkboxValues, setCheckboxValues] = useState([])
    const [checkBoxValuesWithPayment, setCheckBoxValuesWithPayment] = useState([])
    const [tenderTypeId, setTenderTypeId] = useState(2)
    const [paymentId, setPaymentId] = useState(null)
    const [referenceNumber, setReferenceNumber] = useState(null)
    // state
    const [isPaid, setIsPaid] = useState(false)
    const [enrollmentFeesChecked, setEnrollmentFeesChecked] = useState(false)
    const [nonEnrollmentFeesChecked, setNonEnrollmentFeesChecked] = useState(false)
    const [studentAddOns, setStudentAddOns] = useState({ amount: null, feeId: null })
    const [additionalData, setAdditionalData] = useState({ feeId: null, amount: 0 })
    const [studentDirectPayments, setStudentDirectPayments] = useState(studentAdditionalFees)
    const [isOnProcess, setIsOnProcess] = useState(false)

    // functions
    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
    const onSubmit = async () => {
        setIsOnProcess(true)
        const semesterId = studentAccount.SemesterId || studentAccount.StudentSemesterId;
        const schoolYear = studentAccount.SchoolYear || studentAccount.StudentSchoolYear;
        const studentNo = studentAccount.StudentNo;
        const description = studentAccount.StudentName;
        const createdBy = auth.data.Username
        const studentId = studentAccount.StudentId
        const payorType = 'S'

        const paymentValues = {
            orNo: currentOrNo,
            orDate: currentDate,
            studentId: studentId,
            studentNo: studentNo,
            payorType: payorType,
            paymentTenderTypeId: tenderTypeId,
            description: description,
            totalAmount: totalPayment,
            schoolYear: schoolYear,
            semesterId: semesterId,
            createdBy: createdBy,
            dateCreated: currentDate,
            amountReceived: paymentAmount,
            referenceNumber: referenceNumber,
            totalFees: parseFloat(totalOriginalBalance),
            totalPaid: parseFloat(totalOriginalPayment),
        }




        await studentCashierPayment(paymentValues)
            .then(async res => {
                if (res.data[0].Result == 1) {
                    if (studentAccount.IsForReservation == 0) {
                        if (res.data.length > 0) {

                            await assignStudentCurriculum({
                                enrollmentId: studentAccount.EnrollmentId,
                                schoolYear: studentAccount.SchoolYear,
                                semesterId: studentAccount.SemesterId,
                                courseId: studentAccount.CourseId,
                                createdBy: auth.data.Username
                            })
                                .then(resp => {
                                    let filteredValues = checkBoxValuesWithPayment.filter(data => data.payment > 0)
                                    if (changeFee > 0.00) {

                                        let values = filteredValues.map(data => ({ ...data, paymentId: res.data[0].PaymentId, studentId: studentAccount.StudentId }))
                                        let directPaymentValues = studentDirectPayments.filter(d => d.TotalPaid == 0).map(data => ({
                                            ...data,
                                            orNo: currentOrNo,
                                            orDate: currentDate,
                                            payorType: payorType,
                                            description: null,
                                            totalAmount: data.Amount,
                                            schoolYear: schoolYear,
                                            semesterId: semesterId,
                                            studentId: studentAccount.StudentId,
                                            studentNo: studentNo,
                                            feeId: data.FeeId,
                                            otherFeeDescription: null,
                                            createdBy: createdBy,
                                            paymentId: res.data[0].PaymentId

                                        }))
                                        let finalValuesWithChange = [...values, {
                                            paymentId: paymentId,
                                            studentId: studentAccount.StudentId,
                                            FeeId: 553,
                                            payment: changeFee,
                                            SchoolYear: studentAccount.SchoolYear,
                                            SemesterId: studentAccount.SemesterId,
                                            createdBy: auth.data.Username,
                                            paymentScheduleId: null

                                        }]
                                        console.log(values)
                                        if (studentDirectPayments.length > 0) {

                                            studentPayment(directPaymentValues)
                                                .then(direct => {
                                                    studentCashierDetailPayment(values)
                                                        .then(async resp => {
                                                            await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                                            setIsPaid(true)
                                                            setIsOnProcess(false)

                                                        })
                                                })
                                        } else {

                                            studentCashierDetailPayment(values)
                                                .then(async resp => {
                                                    await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                                    setIsPaid(true)
                                                    setIsOnProcess(false)

                                                })
                                        }

                                    } else {

                                        let values = filteredValues.map(data => ({ ...data, paymentId: res.data[0].PaymentId, studentId: studentAccount.StudentId }))
                                        let directPaymentValues = studentDirectPayments.filter(d => d.TotalPaid == 0).map(data => ({
                                            ...data,
                                            orNo: currentOrNo,
                                            orDate: currentDate,
                                            payorType: payorType,
                                            description: null,
                                            totalAmount: data.Amount,
                                            schoolYear: schoolYear,
                                            semesterId: semesterId,
                                            studentId: studentAccount.StudentId,
                                            studentNo: studentNo,
                                            feeId: data.FeeId,
                                            otherFeeDescription: null,
                                            createdBy: createdBy,
                                            paymentId: res.data[0].PaymentId

                                        }))
                                        console.log(values)
                                        if (studentDirectPayments.length > 0) {
                                            studentPayment(directPaymentValues)
                                                .then(direct => {
                                                    studentCashierDetailPayment(values)
                                                        .then(async resp => {
                                                            await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                                            setIsPaid(true)
                                                            setIsOnProcess(false)

                                                        })
                                                })
                                        } else {
                                            studentCashierDetailPayment(values)
                                                .then(async resp => {
                                                    await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                                    setIsPaid(true)
                                                    setIsOnProcess(false)

                                                })
                                        }

                                    }
                                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Payment has been successfully processed' });
                                })
                        }
                    } else {

                        // dito magsesend ng email for reservation
                        sendMail({
                            isPaymentForReservation: 1,
                            emailAddress: studentAccount.EmailAddress,
                            course: studentAccount.CourseDesc,
                            schoolYear: studentAccount.StudentSY
                        })

                        if (res.data.length > 0) {
                            let filteredValues = checkBoxValuesWithPayment.filter(data => data.payment > 0)
                            if (changeFee > 0.00) {
                                let values = filteredValues.map(data => ({ ...data, paymentId: res.data[0].PaymentId, studentId: studentAccount.StudentId }))
                                let finalValuesWithChange = [...values, {
                                    paymentId: paymentId,
                                    studentId: studentAccount.StudentId,
                                    FeeId: 553,
                                    payment: changeFee,
                                    SchoolYear: studentAccount.SchoolYear,
                                    SemesterId: studentAccount.SemesterId,
                                    createdBy: auth.data.Username,
                                    paymentScheduleId: null

                                }]
                                let directPaymentValues = studentDirectPayments.filter(d => d.TotalPaid == 0).map(data => ({
                                    ...data,
                                    orNo: currentOrNo,
                                    orDate: currentDate,
                                    payorType: payorType,
                                    description: null,
                                    totalAmount: data.Amount,
                                    schoolYear: schoolYear,
                                    semesterId: semesterId,
                                    studentId: studentAccount.StudentId,
                                    studentNo: studentNo,
                                    feeId: data.FeeId,
                                    otherFeeDescription: null,
                                    createdBy: createdBy,
                                    paymentId: res.data[0].PaymentId

                                }))
                                console.log(values)
                                if (studentDirectPayments.length > 0) {

                                    studentPayment(directPaymentValues)
                                        .then(direct => {
                                            studentCashierDetailPayment(values)
                                                .then(async resp => {
                                                    await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                                    setIsPaid(true)
                                                    setIsOnProcess(false)

                                                })
                                        })
                                } else {

                                    studentCashierDetailPayment(values)
                                        .then(async resp => {
                                            await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                            setIsPaid(true)
                                            setIsOnProcess(false)

                                        })
                                }

                            } else {
                                let values = filteredValues.map(data => ({ ...data, paymentId: res.data[0].PaymentId, studentId: studentAccount.StudentId }))
                                console.log(values)
                                studentCashierDetailPayment(values)
                                    .then(async resp => {
                                        await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                                        setIsPaid(true)
                                        setIsOnProcess(false)

                                    })
                            }
                            // setPaymentId(res.data[0].PaymentId)
                        }
                    }
                } else {
                    setIsOnProcess(false)

                    setPaymentId(null)
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 3000,
                        snackBarMessage: 'Unable to proceed with payment using existing OR number'
                    });

                }

            })
    }

    const handleChange = (event) => {
        setTenderTypeId(event)
    }

    const handlePaymentChange = (event) => {
        setPaymentAmount(parseFloat(event.target.value));
    };

    const handleReferenceNumber = (event) => {
        setReferenceNumber(event.target.value)
    }

    const onStartNewTransaction = () => {
        props.onStartNewTransaction()
    }


    const returnOnSearch = () => {
        history.push('/admin/accounting/cashier')
    }



    const handleSelectAllChangeEnrollmentFees = (event) => {
        console.log(event.target.checked)
        const checkedValue = event.target.checked;
        setCheckboxValues((prevCheckboxValues) => {
            return fees.map((fee) => {
                return { ...fee, isChecked: checkedValue, payment: 0 };
            });
        })

        setEnrollmentFeesChecked(checkedValue)

    }


    const handleCheckboxChange = (id, name) => (event) => {

        const checkedValue = event.target.checked;
        let findFee = fees.find(fee => fee.AssessmentTemplateDetailId == id || fee.AssessmentTemplateOtherId == id)
        setCheckboxValues((prevCheckboxValues) => {
            const existingItemIndex = prevCheckboxValues.findIndex(
                (checkbox) => checkbox[name] === findFee[name]
            );

            if (existingItemIndex !== -1) {
                const updatedValues = [...prevCheckboxValues];
                updatedValues[existingItemIndex] = { ...findFee, isChecked: checkedValue, payment: checkedValue == false && 0 };
                return updatedValues;
            } else {
                return [...prevCheckboxValues, { ...findFee, isChecked: checkedValue, payment: checkedValue == false && 0 }];
            }
        });



    };


    const selectHandleChange = name => event => {
        setStudentAddOns({ ...studentAddOns, [name]: event });
    }

    const selectHandleChangeAdditional = (name) => event => {
        setAdditionalData({ ...additionalData, feeId: event });
    };

    const handleChangeAmountAdditional = (name) => event => {
        setAdditionalData({ ...additionalData, amount: event.target.value });
    }

    const handleRemoveAdditional = (id) => {
        setStudentDirectPayments((prevData) => {
            return prevData.filter(item => item.PaymentDetailId !== id);
        });
    };

    const handleChangeAmount = name => event => {

        setStudentAddOns({ ...studentAddOns, [name]: event.target.value });

    }

    const handleAddingAddOns = () => {
        const execute = async () => {
            await createAssessmentAddOns({
                studentId: studentAccount.StudentId,
                feeId: studentAddOns.feeId,
                amount: studentAddOns.amount,
                schoolYear: studentAccount.SchoolYear,
                semesterId: studentAccount.SemesterId,
                createdBy: auth.data.Username

            })
                .then(response => {
                    if (response.success) {
                        setStudentAddOns({ amount: null, feeId: null })
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'info', snackBarDuration: 3000, snackBarMessage: "Addons Added" });

                        props.onReloadOtherFee()
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: "Error Found, Please contact MIS Dev" });

                })
        }
        execute();
    }

    const handleAddingAdditional = () => {
        let id = Math.floor(Math.random() * 1000000);
        setStudentDirectPayments((prevData) => {
            // Check if there is an existing entry with the same PaymentDetailId

            // Add a new object
            return [
                ...prevData,
                {
                    PaymentDetailId: id,
                    FeeId: additionalData.feeId,
                    Amount: additionalData.amount,
                    TotalPaid: 0
                }
            ];

        });
    }



    // useEffects

    useEffect(() => {
        const checkedValue = true;
        setCheckboxValues((prevCheckboxValues) => {
            return fees.map((fee) => {
                return { ...fee, isChecked: checkedValue, payment: checkedValue == false && 0 };
            });
        })

        setEnrollmentFeesChecked(checkedValue)
    }, [studentFees])

    useEffect(() => {
        if (checkboxValues.length > 0) {
            console.log(checkboxValues)

            let remainingPayment = paymentAmount;
            const updatedValues = checkboxValues.map(data => {
                console.log(data)
                if (data.isChecked && remainingPayment > 0 && data.isEdited != true && data.TotalBalance > 0) {
                    const totalBalance = data.TotalBalance || data.TotalAmount;
                    const payment = Math.min(totalBalance, remainingPayment);
                    remainingPayment -= payment;
                    return {
                        ...data,
                        payment: payment
                    };
                } else if (data.isEdited == true) {
                    return {
                        ...data
                    };
                }
                return data;
            });
            setCheckBoxValuesWithPayment(updatedValues)
        }
    }, [checkboxValues, paymentAmount])


    useEffect(() => {
        if (paymentId !== null) {
            let filteredValues = checkBoxValuesWithPayment.filter(data => data.payment > 0)
            if (changeFee > 0.00) {
                let values = filteredValues.map(data => ({ ...data, paymentId: paymentId, studentId: studentAccount.StudentId }))
                let finalValuesWithChange = [...values, {
                    paymentId: paymentId,
                    studentId: studentAccount.StudentId,
                    FeeId: 553,
                    payment: changeFee,
                    SchoolYear: studentAccount.SchoolYear,
                    SemesterId: studentAccount.SemesterId,
                    createdBy: auth.data.Username,
                    paymentScheduleId: null

                }]
                console.log(values)
                studentCashierDetailPayment(values)
                    .then(async resp => {
                        await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                        setIsPaid(true)
                        setIsOnProcess(false)

                    })
            } else {
                let values = filteredValues.map(data => ({ ...data, paymentId: paymentId, studentId: studentAccount.StudentId }))
                console.log(values)
                studentCashierDetailPayment(values)
                    .then(async resp => {
                        await updateORNo({ userId: auth.data.UserId, orNo: currentOrNo })
                        setIsPaid(true)
                        setIsOnProcess(false)

                    })
            }

        }
    }, [paymentId])


    useEffect(() => {
        if (fees != null && fees.length > 0) {
            let totalFee = parseFloat(totalAdditionalBalance)
            let totalPaid = parseFloat(totalAdditionalPaid)
            console.log(fees)
            for (let i = 0; i < fees.length; i++) {

                if (fees[i] != null && fees[i].TotalAmount != null)
                    totalFee += parseFloat(fees[i].TotalAmount)


                if (fees[i] != null && fees[i].TotalPaid !== null) {
                    console.log(parseFloat(fees[i].TotalPaid))
                    totalPaid += parseFloat(fees[i].TotalPaid)
                }

            }
            console.log(totalPaid)
            setTotalOriginalBalance(totalFee)
            setTotalOriginalPayment(totalPaid)
            setTotalRemainingBalance(totalFee - totalPaid)
        }

    }, [fees, totalAdditionalBalance, totalAdditionalPaid])


    useEffect(() => {
        if (checkBoxValuesWithPayment.length > 0) {
            let totalPay = 0
            console.log(checkBoxValuesWithPayment)
            checkBoxValuesWithPayment.map(data => {
                console.log(data.payment)
                if (data.payment !== undefined && data.payment !== false && parseFloat(data.payment) > 0.00) {
                    console.log(data.payment)
                    totalPay += parseFloat(data.payment)
                }

            })
            console.log(totalPay)
            setTotalPayment(totalPay)
            setChangeFee(paymentAmount - totalPay)
        }
    }, [checkBoxValuesWithPayment])


    useEffect(() => {
        setAdditionalData({ feeId: null, amount: 0 })
        if (studentDirectPayments.length > 0) {
            let total = 0
            let totalAdditionalPaid = 0
            let totalRecievedAmount = 0
            for (let i = 0; i < studentDirectPayments.length; i++) {
                total += parseFloat(studentDirectPayments[i].Amount)
                totalAdditionalPaid += parseFloat(studentDirectPayments[i].TotalPaid)
                if (studentDirectPayments[i].TotalPaid == 0) {
                    totalRecievedAmount += parseFloat(studentDirectPayments[i].Amount)
                }
            }

            setTotalAdditionalBalance(total)
            setTotalAdditionalPaid(totalAdditionalPaid)
            setTotalAdditionalReceived(totalRecievedAmount)
        } else {
            setTotalAdditionalBalance(0)
        }
    }, [studentDirectPayments])

    useEffect(() => {
        setStudentDirectPayments(studentAdditionalFees)
    }, [studentAdditionalFees])

    console.log(checkboxValues)
    console.log(enrollmentFeesChecked)
    console.log(checkBoxValuesWithPayment)
    console.log(tenderTypeId)
    console.log(fees)
    console.log(totalOriginalBalance)
    console.log(studentAccount)
    console.log(studentOtherFees)
    console.log(totalPayment)
    console.log(studentFees)
    console.log(studentDirectPayments)


    console.log(totalPayment + ' - ' + paymentAmount)
    console.log(totalAdditionalBalance)
    console.log(checkBoxValuesWithPayment)
    console.log(totalRemainingBalance)

    return (
        <>{availableFees && availableOtherFees &&
            <main className="card p-5">
                <header className="card-header p-5">
                    <h4>Cashiering</h4>
                </header>
                <article className="card-body">
                    <section className="row">


                        <section className="col-12">
                            <div className="row mb-5">
                                <section className="col-lg-4">
                                    <div className="card text-center">
                                        <div className="card-header bg-secondary p-5">
                                            <h4>Student Information</h4>
                                        </div>
                                        <div className="card-body">
                                            <img src={ProfileLogo} style={{ width: "150px" }} />
                                            <h5 className="mt-5 mb-0">{studentAccount.StudentName}</h5>
                                            <h6 className="card-title mb-1">
                                                {studentAccount.StudentNo}
                                            </h6>
                                            <h6 className="card-title mb-1">
                                                {studentAccount.CourseDesc}
                                            </h6>
                                            <h6 className="card-title mb-1">
                                                {studentAccount.YearLevelDesc}
                                            </h6>
                                            {studentAccount.IsForReservation == 0 &&
                                                <h6 className="card-title mt-5 mb-1 text-primary">
                                                    {studentAccount.SY} / {studentAccount.Semester}
                                                </h6>
                                            }

                                        </div>
                                    </div>
                                </section>
                                <section className="col-lg-4">

                                </section>
                                <section className="col-lg-4">
                                    <div className="">
                                        <div class="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    Official Receipt No
                                                </span>
                                            </div>
                                            <input
                                                disabled={isOverride}
                                                type="text"
                                                className="form-control"
                                                value={currentOrNo}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                onChange={(e) => { setCurrentOrNo(e.target.value) }}
                                            />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    Date &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                                                </span>
                                            </div>
                                            <input
                                                type="date"
                                                className="form-control"
                                                value={currentDate}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                                onChange={(e) => { setCurrentDate(e.target.value) }}
                                            />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    Payment Mode   &nbsp;&nbsp;&nbsp;
                                                </span>
                                            </div>
                                            <input
                                                readOnly
                                                type="text"
                                                className="form-control"
                                                value={studentAccount.PaymentMode}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                        <div class="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">
                                                    School Year &nbsp;&nbsp;&nbsp; &nbsp;   &nbsp;&nbsp;&nbsp;
                                                </span>
                                            </div>
                                            <input
                                                readOnly
                                                type="text"
                                                className="form-control"
                                                value={studentAccount.SY}
                                                aria-label="Username"
                                                aria-describedby="basic-addon1"
                                            />
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="basic-addon1">Payment</span>
                                            </div>
                                            {/* this is where i put the amount to be distributted */}
                                            <input
                                                type="number"
                                                className='form-control'
                                                placeholder="Payment"
                                                aria-label="Payment"
                                                aria-describedby="basic-addon1"
                                                value={paymentAmount}
                                                onChange={handlePaymentChange}
                                            />
                                        </div>
                                    </div>
                                </section>
                            </div>

                            {/* <button onClick={handleDistributePayment} className="btn btn-primary">Distribute Payment</button> */}
                            {studentAccount.IsForReservation == 0 &&
                                <main className="card  mb-5">

                                    <header className="card-header p-5 bg-secondary">
                                        <h4 className="mb-0 float-left">Enrollment Related Fees</h4>
                                        {/* <button onClick={() => { addEnrollmentFeeRows() }} className="btn btn-primary btn-sm float-right">Add</button> */}
                                    </header>


                                    <TableContainer className="mt-5">
                                        <Table className={classes.table} size="small">
                                            <TableHead>
                                                <TableRow>
                                                    <TableCell align="center">
                                                        <input
                                                            type="checkbox"
                                                            aria-label="Checkbox for following text input"
                                                            checked={enrollmentFeesChecked}
                                                            onChange={handleSelectAllChangeEnrollmentFees}
                                                        />
                                                    </TableCell>
                                                    <TableCell>SY/Sem</TableCell>
                                                    <TableCell align="center">Description</TableCell>
                                                    <TableCell align="center">Amount</TableCell>
                                                    <TableCell align="center">Payment Made</TableCell>
                                                    <TableCell align="center">Balance</TableCell>
                                                    <TableCell align="center">Amount to Pay</TableCell>

                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {studentFees.length > 0 ? studentFees.map(result => {
                                                    const { AssessmentTemplateDetailId, FeeDesc, SY, TotalAmount, TotalPaid, FeeId, TotalBalance, } = result;
                                                    rowCnt += 1;

                                                    const isChecked = checkboxValues.find(data => data.AssessmentTemplateDetailId == AssessmentTemplateDetailId && data.isChecked == true);
                                                    const paymentValue = checkBoxValuesWithPayment.find(data => data.AssessmentTemplateDetailId == AssessmentTemplateDetailId)

                                                    console.log(paymentValue)
                                                    const payment = paymentValue !== undefined && paymentValue.payment !== false ? paymentValue.payment : 0;
                                                    console.log(payment)


                                                    const onChangeValue = (event, id) => {

                                                        const particularPayment = event.target.value
                                                        let findFee = fees.find(fee => fee.AssessmentTemplateDetailId == id)
                                                        console.log(findFee)
                                                        setCheckboxValues((prevCheckboxValues) => {
                                                            const existingItemIndex = prevCheckboxValues.findIndex(
                                                                (checkbox) => checkbox.AssessmentTemplateDetailId === findFee.AssessmentTemplateDetailId
                                                            );

                                                            if (existingItemIndex !== -1) {
                                                                const updatedValues = [...prevCheckboxValues];
                                                                updatedValues[existingItemIndex] = { ...findFee, isEdited: true, payment: particularPayment, isChecked: true };
                                                                return updatedValues;
                                                            } else {
                                                                return [...prevCheckboxValues, { ...findFee, isEdited: true, payment: particularPayment, isChecked: true }];
                                                            }
                                                        });

                                                    }


                                                    return (
                                                        <>
                                                            <TableRow key={AssessmentTemplateDetailId}>
                                                                <TableCell align="center">
                                                                    <input
                                                                        type="checkbox"
                                                                        aria-label="Checkbox for following text input"
                                                                        checked={isChecked}
                                                                        onChange={handleCheckboxChange(AssessmentTemplateDetailId, 'AssessmentTemplateDetailId')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{SY}</TableCell>
                                                                <TableCell align="center">{FeeDesc}</TableCell>
                                                                <TableCell align="center">{TotalAmount}</TableCell>
                                                                <TableCell align="center">{TotalPaid !== null ? TotalPaid : 0}</TableCell>
                                                                <TableCell align="center">{TotalBalance != null ? TotalBalance : parseFloat(TotalAmount) - parseFloat(TotalPaid)}</TableCell>


                                                                <TableCell align="center">
                                                                    <input
                                                                        onChange={(e) => { onChangeValue(e, AssessmentTemplateDetailId) }}
                                                                        type="number"
                                                                        className='form-control text-center'
                                                                        placeholder={TotalBalance}
                                                                        value={payment}
                                                                        disabled={TotalAmount < 1.00 || TotalBalance != null ? TotalBalance < 1.00 : TotalAmount < 1.00}
                                                                    />

                                                                </TableCell>

                                                            </TableRow>
                                                        </>
                                                    )
                                                })

                                                    :
                                                    <>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;Loading Please wait...
                                                    </>
                                                }

                                            </TableBody>

                                        </Table>

                                    </TableContainer>




                                </main>

                            }


                            <main className="card mt-5 mb-5">
                                <header className="card-header p-5 bg-secondary">
                                    <h4 className="mb-0 float-left">Non-Enrollment Related Fees</h4>
                                </header>
                                <TableContainer className="mt-5">
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center">
                                                    {/* <input
                                                        type="checkbox"
                                                        aria-label="Checkbox for following text input"
                                                        checked={nonEnrollmentFeesChecked}
                                                        onChange={handleSelectAllChangeNonEnrollmentFees}

                                                    /> */}

                                                </TableCell>
                                                <TableCell>SY/Sem</TableCell>
                                                <TableCell align="center">Description</TableCell>
                                                <TableCell align="center">Amount</TableCell>
                                                <TableCell align="center">Payment Made</TableCell>
                                                <TableCell align="center">Balance</TableCell>

                                                <TableCell align="center">Amount to Pay</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                studentOtherFees != null && studentOtherFees.length > 0 ?
                                                    studentOtherFees.filter(result => result.IsAddOns != 1).map((result) => {
                                                        const { AssessmentTemplateOtherId, FeeDesc, SY, TotalAmount, TotalPaid, TenderType, TotalBalance, FeeId } = result;
                                                        rowCnt += 1;



                                                        const isChecked = checkboxValues.find(data => data.AssessmentTemplateOtherId == AssessmentTemplateOtherId && data.isChecked == true);
                                                        const paymentValue = checkBoxValuesWithPayment.find(data => data.AssessmentTemplateOtherId == AssessmentTemplateOtherId)

                                                        const payment = paymentValue !== undefined && paymentValue.payment !== false ? paymentValue.payment : 0;



                                                        const onChangeValue = (event, id) => {
                                                            const particularPayment = event.target.value
                                                            let findFee = fees.find(fee => fee.AssessmentTemplateOtherId == id)
                                                            setCheckboxValues((prevCheckboxValues) => {
                                                                const existingItemIndex = prevCheckboxValues.findIndex(
                                                                    (checkbox) => checkbox.AssessmentTemplateOtherId === findFee.AssessmentTemplateOtherId
                                                                );

                                                                if (existingItemIndex !== -1) {
                                                                    const updatedValues = [...prevCheckboxValues];
                                                                    updatedValues[existingItemIndex] = { ...findFee, isEdited: true, payment: particularPayment, isChecked: true };
                                                                    return updatedValues;
                                                                } else {
                                                                    return [...prevCheckboxValues, { ...findFee, isEdited: true, payment: particularPayment, isChecked: true }];
                                                                }
                                                            });

                                                        }

                                                        return (

                                                            <TableRow key={AssessmentTemplateOtherId}>
                                                                <TableCell align="center">
                                                                    <input
                                                                        type="checkbox"
                                                                        aria-label="Checkbox for following text input"
                                                                        checked={isChecked}
                                                                        onChange={handleCheckboxChange(AssessmentTemplateOtherId, 'AssessmentTemplateOtherId')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell component="th" scope="row">{SY}</TableCell>
                                                                <TableCell align="center">{FeeDesc}</TableCell>
                                                                <TableCell align="center">{TotalAmount}</TableCell>
                                                                <TableCell align="center">{TotalPaid}</TableCell>

                                                                <TableCell align="center">{TotalBalance}</TableCell>


                                                                <TableCell align="center">
                                                                    <input
                                                                        disabled={TotalAmount < 1.00 || TotalBalance != null ? TotalBalance < 1.00 : TotalAmount < 1.00}

                                                                        type="number"
                                                                        className='form-control text-center'
                                                                        placeholder={TotalBalance}
                                                                        value={payment}
                                                                        onChange={(e) => { onChangeValue(e, AssessmentTemplateOtherId) }}
                                                                    />

                                                                </TableCell>





                                                            </TableRow>
                                                        );


                                                    })
                                                    :
                                                    <>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;Loading Please wait...
                                                        </>Loading Please wait...
                                                    </>
                                            }

                                        </TableBody>

                                    </Table>

                                </TableContainer>

                            </main>


                            <main className="card mt-5 mb-5">
                                <header className="card-header p-5 bg-secondary">
                                    <h4 className="mb-0 float-left">Enrollment Add Ons</h4>
                                </header>
                                <TableContainer className="mt-5">
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center" >Fee</TableCell>
                                                <TableCell align="center">Amount</TableCell>
                                                <TableCell align="center">Payment Made</TableCell>
                                                <TableCell align="center">Balance</TableCell>
                                                <TableCell align="center">Amount to Pay</TableCell>
                                                {/* <TableCell align="center">Delete</TableCell> */}



                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {
                                                studentOtherFees != null && studentOtherFees.length > 0 ?
                                                    studentOtherFees.filter(result => result.IsAddOns === 1).map((result) => {
                                                        const { AssessmentTemplateOtherId, FeeDesc, SY, TotalAmount, TotalPaid, TenderType, TotalBalance, FeeId } = result;
                                                        const isChecked = checkboxValues.find(data => data.AssessmentTemplateOtherId == AssessmentTemplateOtherId && data.isChecked == true);
                                                        const paymentValue = checkBoxValuesWithPayment.find(data => data.AssessmentTemplateOtherId == AssessmentTemplateOtherId)
                                                        const payment = paymentValue !== undefined && paymentValue.payment !== false ? paymentValue.payment : 0;
                                                        rowCnt += 1;



                                                        const onChangeValue = (event, id) => {
                                                            const particularPayment = event.target.value
                                                            let findFee = fees.find(fee => fee.AssessmentTemplateOtherId == id)
                                                            setCheckboxValues((prevCheckboxValues) => {
                                                                const existingItemIndex = prevCheckboxValues.findIndex(
                                                                    (checkbox) => checkbox.AssessmentTemplateOtherId === findFee.AssessmentTemplateOtherId
                                                                );

                                                                if (existingItemIndex !== -1) {
                                                                    const updatedValues = [...prevCheckboxValues];
                                                                    updatedValues[existingItemIndex] = { ...findFee, isEdited: true, payment: particularPayment, isChecked: true };
                                                                    return updatedValues;
                                                                } else {
                                                                    return [...prevCheckboxValues, { ...findFee, isEdited: true, payment: particularPayment, isChecked: true }];
                                                                }
                                                            });

                                                        }

                                                        return (

                                                            <TableRow key={AssessmentTemplateOtherId}>
                                                                <TableCell align="center">
                                                                    <input
                                                                        type="checkbox"
                                                                        aria-label="Checkbox for following text input"
                                                                        checked={isChecked}
                                                                        onChange={handleCheckboxChange(AssessmentTemplateOtherId, 'AssessmentTemplateOtherId')}
                                                                    />
                                                                </TableCell>
                                                                <TableCell align="center">{FeeDesc}</TableCell>
                                                                <TableCell align="center">{TotalAmount}</TableCell>
                                                                <TableCell align="center">{TotalPaid}</TableCell>

                                                                <TableCell align="center">{TotalBalance}</TableCell>


                                                                <TableCell align="center">
                                                                    <input
                                                                        disabled={TotalAmount < 1.00 || TotalBalance != null ? TotalBalance < 1.00 : TotalAmount < 1.00}

                                                                        type="number"
                                                                        className='form-control text-center'
                                                                        placeholder={TotalBalance}
                                                                        value={payment}
                                                                        onChange={(e) => { onChangeValue(e, AssessmentTemplateOtherId) }}
                                                                    />

                                                                </TableCell>
                                                                {/* <TableCell align="right">
                                                                    <button
                                                                        className="btn btn-xs btn-icon btn-icon-xs btn-danger m-1 "

                                                                    >
                                                                        <i className="fa fa-trash-alt text-white"></i>
                                                                    </button>
                                                                </TableCell> */}




                                                            </TableRow>
                                                        );


                                                    })
                                                    :
                                                    <>
                                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                        <>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;Loading Please wait...
                                                        </>Loading Please wait...
                                                    </>
                                            }
                                            <TableRow>
                                                <TableCell className="py-0"></TableCell>
                                                <TableCell>
                                                    <IMSelect
                                                        data={feeSelect}
                                                        onHandleChange={selectHandleChange('feeId')}
                                                        refClassContainer="form-control py-0"
                                                        name="feeId"
                                                        isRequired={true}
                                                        withLabel={false}
                                                        label=""
                                                        placeHolder="Fee"
                                                        forwardRef={setValue}
                                                        selectedId={studentAddOns.feeId}
                                                        refClassName={``}
                                                        withDescription={true}
                                                        description={`Fee`}
                                                        refDisabled={true}
                                                        refIsStatic={false}
                                                        refStaticData={[
                                                        ]}
                                                        field={{
                                                            tableId: 'tableId',
                                                            display: 'code'
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <input
                                                        className=""
                                                        onChange={handleChangeAmount('amount')}
                                                        type="number"
                                                        name="amount"
                                                        placeholder="Amount"
                                                        autoComplete="off"
                                                        style={{
                                                            flex: 1,
                                                            padding: '0.375rem 0.75rem',
                                                            fontSize: '1rem',
                                                            lineHeight: '1.5',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                            width: '100%'
                                                        }}
                                                        value={studentAddOns.amount || ''}
                                                    />
                                                    <p className="text-muted mb-0">Amount</p>

                                                </TableCell>
                                                <TableCell className="py-0" align="center"></TableCell>
                                                <TableCell className="py-0" align="center"></TableCell>


                                                <TableCell className="py-0" align="center">
                                                    <button disabled={studentAddOns.feeId == null || studentAddOns.amount == null} className="btn btn-sm btn-success py-2" onClick={() => handleAddingAddOns()}>
                                                        Create Add Ons
                                                    </button>
                                                </TableCell>

                                            </TableRow>

                                        </TableBody>

                                    </Table>

                                </TableContainer>

                            </main>



                            <main className="card mt-5 mb-5">
                                <header className="card-header p-5 bg-secondary">
                                    <h4 className="mb-0 float-left">Additional Payment</h4>
                                </header>
                                <TableContainer className="mt-5">
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="left" >Fee</TableCell>
                                                <TableCell align="center">Amount to Pay</TableCell>
                                                <TableCell align="center">Total Paid</TableCell>
                                                <TableCell align="center">Action</TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {studentDirectPayments.map(directPayment => {
                                                const { PaymentDetailId, FeeId, Amount, TotalPaid } = directPayment;

                                                return (
                                                    <>
                                                        <TableRow key={PaymentDetailId}>
                                                            <TableCell align="center"></TableCell>
                                                            <TableCell align="center">

                                                                <IMSelect
                                                                    data={feeSelect}
                                                                    refClassContainer="form-control py-0"
                                                                    name="feeId"
                                                                    isRequired={true}
                                                                    withLabel={false}
                                                                    label=""
                                                                    placeHolder="Fee"
                                                                    forwardRef={setValue}
                                                                    selectedId={FeeId}
                                                                    refClassName={``}
                                                                    withDescription={true}
                                                                    description={``}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    isDisabled={true}
                                                                    refStaticData={[
                                                                    ]}
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}
                                                                />
                                                            </TableCell>
                                                            <TableCell align="center">{Amount}</TableCell>
                                                            <TableCell align="center">{TotalPaid}</TableCell>
                                                            <TableCell align="center">
                                                                {TotalPaid == 0 ?
                                                                    <button onClick={() => { handleRemoveAdditional(PaymentDetailId) }} className="btn btn-xs btn-icon btn-icon-xs btn-danger">
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>
                                                                    :
                                                                    <button disabled className="btn btn-xs btn-icon btn-icon-xs btn-secondary">
                                                                        <i className="fa fa-trash"></i>
                                                                    </button>

                                                                }

                                                            </TableCell>
                                                        </TableRow>
                                                    </>
                                                )

                                            })}

                                            <TableRow>
                                                <TableCell className="py-0"></TableCell>
                                                <TableCell>
                                                    <IMSelect
                                                        data={feeSelect}
                                                        onHandleChange={selectHandleChangeAdditional('feeId')}
                                                        refClassContainer="form-control py-0"
                                                        name="feeId"
                                                        isRequired={true}
                                                        withLabel={false}
                                                        label=""
                                                        placeHolder="Fee"
                                                        forwardRef={setValue}
                                                        selectedId={additionalData.feeId}
                                                        refClassName={``}
                                                        withDescription={true}
                                                        description={`Fee`}
                                                        refDisabled={true}
                                                        refIsStatic={false}
                                                        refStaticData={[
                                                        ]}
                                                        field={{
                                                            tableId: 'tableId',
                                                            display: 'code'
                                                        }}
                                                    />
                                                </TableCell>

                                                <TableCell>
                                                    <input
                                                        className=""
                                                        onChange={handleChangeAmountAdditional('amount')}
                                                        type="number"
                                                        name="amount"
                                                        placeholder="Amount"
                                                        autoComplete="off"
                                                        style={{
                                                            flex: 1,
                                                            padding: '0.375rem 0.75rem',
                                                            fontSize: '1rem',
                                                            lineHeight: '1.5',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                            width: '100%'
                                                        }}
                                                        value={additionalData.amount || ''}
                                                    />
                                                    <p className="text-muted mb-0">Amount</p>

                                                </TableCell>
                                                <TableCell className="py-0" align="center"></TableCell>

                                                <TableCell className="py-0" align="center">
                                                    <button disabled={additionalData.feeId == null || additionalData.amount == 0} className="btn btn-sm btn-success py-2" onClick={() => handleAddingAdditional()}>
                                                        Create Additional Payment
                                                    </button>
                                                </TableCell>

                                            </TableRow>

                                        </TableBody>

                                    </Table>

                                </TableContainer>

                            </main>



                        </section>

                    </section>






                    <main className="mt-5 row">
                        <section className="col-6">
                            <main className="card mb-5">
                                <header className="card-header p-5 bg-secondary">
                                    <h4 className="mb-0 float-left">Payment Schedule</h4>

                                </header>
                                <TableContainer className="mt-5">
                                    <Table className={classes.table} size="small">
                                        <TableHead>
                                            <TableRow>
                                                <TableCell>Period</TableCell>
                                                <TableCell align="center">Date</TableCell>
                                                <TableCell align="center">Amount to Pay</TableCell>

                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {paymentSchedule !== null && paymentSchedule !== undefined && paymentSchedule.length > 0 && paymentSchedule.map((result) => {
                                                const { PaymentScheduleId, Period, Date, Amount } = result;
                                                rowCnt += 1;

                                                let isChecked = studentExistingPaymentSchedule.find(data => data.PaymentScheduleId === PaymentScheduleId);
                                                console.log(isChecked);
                                                console.log(PaymentScheduleId);
                                                console.log(isChecked?.PaymentScheduleId === PaymentScheduleId);
                                                console.log(isChecked?.PaymentScheduleId + '__' + PaymentScheduleId);

                                                return (
                                                    <TableRow key={PaymentScheduleId}>
                                                        {/* <TableCell align="center">
                                                            <input
                                                                disabled={isChecked && isChecked.PaymentScheduleId === PaymentScheduleId}
                                                                type="checkbox"
                                                                aria-label="Checkbox for following text input"
                                                                checked={selectedPaymentScheduleId === PaymentScheduleId}
                                                                onChange={() => { handleChangePaymentSchedule(PaymentScheduleId) }}
                                                            />
                                                        </TableCell> */}
                                                        <TableCell component="th" scope="row">{Period}</TableCell>
                                                        <TableCell align="center">{moment(Date).format('YYYY-MM-DD')}</TableCell>
                                                        <TableCell align="center" component="th" scope="row">{parseFloat(Amount).toFixed(2)}</TableCell>

                                                    </TableRow>
                                                );
                                            })}
                                        </TableBody>

                                    </Table>

                                </TableContainer>


                            </main>
                            <section className="container">
                                <div className="mt-0 alert alert-secondary p-3 row justify-content-between"><h6 className="col mb-0">Total Fees: </h6><h6 className="mb-0 text-right pr-5 mr-5 col">₱{totalOriginalBalance !== null ? numberWithCommas(parseFloat(totalOriginalBalance).toFixed(2)) : ''}</h6> </div>
                                <div className="mt-0 alert alert-secondary p-3 row  justify-content-between"><h6 className="col mb-0">Total Paid: </h6><h6 className="mb-0 text-right pr-5 mr-5 col">₱{totalOriginalBalance !== null ? numberWithCommas(parseFloat(totalOriginalPayment).toFixed(2)) : ''}</h6> </div>
                                {totalExcessFee > 0.00 &&
                                    <div className="mt-0 alert alert-warning p-3 row  justify-content-between"><h6 className="col mb-0">Total Excess Fee: </h6><h6 className="mb-0 text-right text-light pr-5 mr-5 col">₱{numberWithCommas(parseFloat(totalExcessFee).toFixed(2))}</h6> </div>
                                }
                                <hr />
                                <section className="row justify-content-between">
                                    <h6 className="col mb-0 text-left col">Total Balance: </h6>
                                    <h6 className="mb-0 text-right text-dark pr-5 mr-5 col ">₱{totalOriginalBalance !== null ? numberWithCommas(parseFloat(totalRemainingBalance).toFixed(2)) : ''}</h6>
                                </section>


                            </section>
                        </section>
                        <section className="col-6">
                            <div className="card p-0">
                                <header className="card-header p-5 bg-secondary">
                                    <h4 className="mb-0 float-left">Payment</h4>

                                </header>
                                <div className="card-body p-5">


                                    {/* <p className="d-block">{`OR LIMIT `}<span className="text-primary">{`${orLimit.starting} - ${orLimit.ending}`}</span> </p> */}


                                    {/* <hr /> */}
                                    <div className="mb-0">

                                        <IMSelect
                                            onHandleChange={(e) => { handleChange(e) }}
                                            refClassContainer="form-group mb-0"
                                            name="stype"
                                            isRequired={true}
                                            withLabel={true}
                                            label="Select Tender Type"
                                            placeHolder="Stype"
                                            forwardRef={setValue}
                                            selectedId={tenderTypeId}
                                            refClassName={``}
                                            withDescription={false}
                                            description={`Please enter your stype.`}
                                            refDisabled={true}
                                            refIsStatic={true}
                                            refStaticData={[
                                                { id: 2, label: 'Cash' },
                                                { id: 1, label: 'Check' },
                                                { id: 3, label: 'Direct Deposit' },
                                                { id: 4, label: 'Salary Deduction' },
                                                { id: 5, label: 'Credit Card' },
                                                { id: 6, label: 'Debit Card' }

                                            ]}
                                            field={{
                                                tableId: '',
                                                display: ''
                                            }}
                                        />
                                    </div>
                                    <p className="mb-0 mt-1">Reference #</p>
                                    <input onChange={(e) => handleReferenceNumber(e)} name="referenceNumber" className="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" type="text" />
                                    <div className="input-group mt-5">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend2">Received Amount</span>
                                        </div>
                                        <input readOnly value={paymentAmount + totalAdditionalReceived} name="orNumber" className="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" type="number" />
                                    </div>
                                    <div className="input-group mt-5">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend2">Total Payment</span>
                                        </div>
                                        <input readOnly value={totalPayment + totalAdditionalReceived} name="orNumber" className="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" type="number" />
                                    </div>
                                    <div className="input-group mt-5">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend2">Changed</span>
                                        </div>
                                        <input readOnly value={changeFee} name="orNumber" className="form-control" id="validationDefaultUsername" aria-describedby="inputGroupPrepend2" type="number" />
                                    </div>

                                    <>
                                        {totalPayment > paymentAmount ?
                                            <>
                                                <button disabled type="submit" className="mt-5 p-3 btn btn-secondary float-right btn-block">Proceed Payment</button>

                                            </>
                                            :
                                            <>
                                                {isOnProcess == false ?
                                                    <>
                                                        {isPaid == false ?
                                                            <button
                                                                disabled={totalPayment + totalAdditionalBalance < 1 || currentOrNo == null || parseFloat(totalPayment) > parseFloat(totalRemainingBalance) || totalRemainingBalance == 0}
                                                                onClick={() => { onSubmit() }}
                                                                type="submit"
                                                                className="mt-5 p-3 btn btn-primary float-right btn-block"
                                                            >Proceed Payment</button>
                                                            :
                                                            <>
                                                                {studentAccount.IsForReservation == 0 ?
                                                                    <button onClick={() => { onStartNewTransaction() }} className="mt-5 p-3 btn btn-secondary float-right btn-block">Start new transaction</button>
                                                                    :
                                                                    <>
                                                                        {parseFloat(totalOriginalPayment) + parseFloat(totalPayment) == totalOriginalBalance ?
                                                                            <>
                                                                                <div className="alert alert-success" role="alert">
                                                                                    Student are now Ready to Receive Documents
                                                                                </div>
                                                                                <button onClick={() => { returnOnSearch() }} className="mt-5 p-3 btn btn-secondary float-right btn-block">Search for another Student</button>

                                                                            </>

                                                                            :
                                                                            <button onClick={() => { onStartNewTransaction() }} className="mt-5 p-3 btn btn-secondary float-right btn-block">Start new transaction</button>




                                                                        }


                                                                    </>
                                                                }
                                                            </>

                                                        }
                                                    </>
                                                    :
                                                    <>
                                                        <button class="btn btn btn-secondary float-right btn-block" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            Processing Payment...
                                                        </button>
                                                    </>
                                                }

                                            </>
                                        }


                                    </>
                                </div>

                            </div>


                        </section>

                    </main>




                </article>
            </main>



        }






        </>
    )
}