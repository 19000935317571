import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';

export default function Form(props) {
  const { register, handleSubmit, errors } = useForm();
  const [selectState, setSelectState] = useState({
    ScheduleType: props.doc.ScheduleType === undefined ? "" : props.doc.ScheduleType,
  })
  const selectHandleChange = name => event => {
    setSelectState({ ...selectState, [name]: event.target.value });
  };

  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });
  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const onSubmit = formValues => {
    const { paymentSchedule, scheduleType, monthNo, isActive } = formValues;

    const convertedFormValues = {
      paymentSchedule: paymentSchedule,
      scheduleType: scheduleType,
      monthNo: monthNo === "" ? null : monthNo,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Payment Schedule <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="paymentSchedule"
                  defaultValue={props.doc.PaymentSchedule === undefined ? "" : props.doc.PaymentSchedule}
                  className={`form-control${errors.paymentSchedule === undefined ? "" : errors.paymentSchedule && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your payment schedule.</span>
                {errors.paymentSchedule && errors.paymentSchedule.type === "required" && <div className="invalid-feedback">Payment schedule is required</div>}
              </div>
              <div className="form-group">
                <label>Schedule Type <span className="text-danger">*</span></label>
                <select
                  name="scheduleType"
                  className={`form-control${errors.scheduleType === undefined ? "" : " is-invalid"}`}
                  placeholder="Schedule Type"
                  value={selectState.ScheduleType}
                  onChange={selectHandleChange('ScheduleType')}
                  ref={register({
                    required: true
                  })}
                >
                  <option value="">&nbsp;</option>
                  <option value="F">First Payment</option>
                  <option value="M">Monthly</option>
                  <option value="O">Others</option>
                  <option value="P">Periodical</option>
                </select>
                <span className="form-text text-muted">Please enter your schedule type.</span>
                {errors.scheduleType && errors.scheduleType.type === "required" && <div className="invalid-feedback">Schedule type is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Month No. : </label>
                <input
                  type="number"
                  name="monthNo"
                  defaultValue={props.doc.MonthNo === undefined ? "" : props.doc.MonthNo}
                  className={`form-control${errors.monthNo === undefined ? "" : errors.monthNo && " is-invalid"}`}
                  ref={register()}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your month no.</span>
                {errors.monthNo && errors.monthNo.type === "required" && <div className="invalid-feedback">Month no. is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}