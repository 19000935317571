import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getPaymentReport, getActiveSemester,
    getActiveSchoolYear, getPaymentReportHeaders, getCourse, getInstitute,
    getYearLevels, getPaymentReportCashiers
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import { useAppContext } from "../../../../contexts/useAppContext";


export default function PaymentReport(props) {
    const { state: { auth } } = useAppContext()

    const { setValue } = useForm();
    const [results, setResults] = useState([])
    const [newResults, setNewResults] = useState([])
    const [paymentHeaders, setPaymentHeaders] = useState([])
    const [newColumns, setNewColumns] = useState([])

    const [courseSelect, setCourseSelect] = useState([])
    const [instituteSelect, setInstituteSelect] = useState([])
    const [yearLevelSelect, setYearLevelSelect] = useState([])
    const [cashierSelect, setCashierSelect] = useState([])

    // const date = new Date(), y = date.getFullYear(), m = date.getMonth();
    // const firstDay = new Date(y, m, 1);
    // const lastDay = new Date(y, m + 1, 0);

    const startOfMonth = moment().startOf('month').format('YYYY-MM-DD');
    const endOfMonth = moment().endOf('month').format('YYYY-MM-DD');

    const [activeSem, setActiveSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [activeSy, setActiveSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)

    const [filteredSem, setFilteredSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [filteredSy, setFilteredSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)
    const [filteredIns, setFilteredIns] = useState(null)
    const [filteredCourse, setFilteredCourse] = useState(null)
    const [filteredYearLevel, setFilteredYearLevel] = useState(null)
    const [filteredCashier, setFilteredCashier] = useState(null)

    const [filteredDateFrom, setFilteredDateFrom] = useState(startOfMonth)
    const [filteredDateTo, setFilteredDateTo] = useState(endOfMonth)
    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [excelFormat, setExcelFormat] = useState([])

    const [isReportGenerated, setIsReportGenerated] = useState(false);
    const [isGeneratingReport, setIsGeneratingReport] = useState(false)

    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectInsChange = name => event => { setFilteredIns(event) }
    const selectCourseChange = name => event => { setFilteredCourse(event) }
    const selectYearLevelChange = name => event => { setFilteredYearLevel(event) }
    const selectCashierChange = name => event => { setFilteredCashier(event) }

    const onGetPaymentReport = async (dataSubmitted) => {
        console.log("dataSubmitted: ", dataSubmitted);

        // Check if Institute is ALL (90)
        if (dataSubmitted.institute == 90 || dataSubmitted.institute === null || dataSubmitted.institute == '') {

            dataSubmitted.institute = -1;

            //If Course is ALL (-1), loop for multiple call per courses
            if (dataSubmitted.course == -1 || dataSubmitted.course === null || dataSubmitted.course == '') {
                console.log("RESULT: ALL INSTITUTES, ALL COURSES");
                //setIsGeneratingReport(true);
                let newArray = []
                for (let i = 0; i < courseSelect.length; i++) {
                    dataSubmitted.course = courseSelect[i].tableId;
                    await getPaymentReport(dataSubmitted)
                        .then(res => {
                            //setResults(res.data);

                            if (typeof (res.data) !== "undefined") {
                                for (let items in res.data) {
                                    newArray.push(res.data[items]);
                                }
                            }
                        })
                        .catch(e => {
                            //setIsGeneratingReport(false);
                        })
                }
                setResults(newArray);
                //setIsGeneratingReport(false);
            }

            //Else, execute once for specified data submitted
            else {
                console.log("RESULT: SPECIFIC COURSES - A");
                await getPaymentReport(dataSubmitted)
                    .then(res => {
                        setIsGeneratingReport(false)
                        setResults(res.data)
                        setIsReportGenerated(true)
                    })
                    .catch(e => {
                        //setIsGeneratingReport(false)
                    })
            }

            // If specific Institute was selected
        } else {

            //If ALL courses, loop for multiple call per courses
            if (dataSubmitted.course == -1 || dataSubmitted.course === null || dataSubmitted.course == '') {
                //setIsGeneratingReport(true);

                console.log("RESULT: SPECIFIC INSTITUTE; ALL COURSES");
                let newArray = []
                for (let i = 0; i < courseSelect.length; i++) {
                    dataSubmitted.course = courseSelect[i].tableId;
                    await getPaymentReport(dataSubmitted)
                        .then(res => {
                            //setResults(res.data);

                            if (typeof (res.data) !== "undefined") {
                                for (let items in res.data) {
                                    newArray.push(res.data[items]);
                                }
                            }
                        })
                        .catch(e => {
                            //setIsGeneratingReport(false);
                        })
                }
                setResults(newArray);
                //setIsGeneratingReport(false);
            }

            //Else, execute once for specified data submitted
            else {
                console.log("RESULT: SPECIFIC COURSES - B");
                await getPaymentReport(dataSubmitted)
                    .then(res => {
                        setIsGeneratingReport(false)
                        setResults(res.data)
                        setIsReportGenerated(true)
                    })
                    .catch(e => {
                        //setIsGeneratingReport(false)
                    })
            }
        }

        setIsGeneratingReport(false);
    }

    const onGetPaymentReportHeaders = async (val) => {
        await getPaymentReportHeaders(val)
            .then(res => {
                console.log("getPaymentReportHeaders: ", res);
                if (typeof (res.data) !== "undefined") {
                    setPaymentHeaders(res.data);
                    onGetPaymentReport(val);
                } else {
                    setPaymentHeaders([]);
                }
            })
            .catch(e => {

            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }

    const onSubmit = () => {

        setIsGeneratingReport(true);
        let dataToBeSubmit = {
            dateFrom: filteredDateFrom,
            dateTo: filteredDateTo,
            cashier: filteredCashier == '' ? null : filteredCashier,
            schoolYear: filteredSy == '' ? null : filteredSy,
            semester: filteredSem == '' ? null : filteredSem,
            institute: filteredIns == '' || filteredIns === null ? 90 : filteredIns,
            course: filteredCourse == '' || filteredCourse === null ? -1 : filteredCourse,
            yearLevel: filteredYearLevel == '' || filteredYearLevel === null ? -1 : filteredYearLevel
        }

        onGetPaymentReportHeaders(dataToBeSubmit);
        //onGetPaymentReport(dataToBeSubmit);

    }

    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')
        SelectCreator(getCourse, setCourseSelect, 'CourseIdx', 'Code', 90)
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')
        SelectCreator(getYearLevels, setYearLevelSelect, 'YearLevelId', 'YearLevel')
        SelectCreator(getPaymentReportCashiers, setCashierSelect, 'Username', 'CashierName')


        const exeGetActiveSy = async () => {
            await getActiveSchoolYear()
                .then(res => {
                    //console.log(res)

                    setActiveSy(res.data[0].SchoolYear)
                })
        }

        const exeGetActiveSem = async () => {
            await getActiveSemester()
                .then(res => {
                    //console.log(res)
                    setActiveSem(res.data[0].SemesterId)
                })
        }

        exeGetActiveSy()
        exeGetActiveSem()

    }, [])

    useEffect(() => {
        console.log("filteredIns: ", filteredIns);

        if (filteredIns !== null) {
            const val = {
                instituteId: filteredIns
            }
            SelectCreator(getCourse, setCourseSelect, 'CourseIdx', 'Code', val)
        }
    }, [filteredIns]);

    useEffect(() => {
        if (paymentHeaders !== null || paymentHeaders !== undefined) {
            let dynamicColumns = [
                { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
                { id: 'Student No', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'Student Name', label: 'Name:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'ORDate', label: 'ORDate:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'ORNo', label: 'OR No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'SYSem', label: 'SYSem:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'Total Amount', label: 'Total Amount:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'Tuition Fee', label: 'Tuition:', align: 'left', withSorting: true, hidden: false, disablePadding: false }
            ];

            for (let i = 0; i < paymentHeaders.length; i++) {
                dynamicColumns.push({ id: paymentHeaders[i].Fees, label: paymentHeaders[i].Fees, align: 'left', withSorting: true, hidden: false, disablePadding: false });
            }

            dynamicColumns.push(
                { id: 'Payment Reference', label: 'Payment Reference:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'Institute', label: 'Institute:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'Course', label: 'Course:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'YearLevel', label: 'YearLevel:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'ClassSection', label: 'ClassSection:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
                { id: 'Cashier', label: 'Cashier:', align: 'left', withSorting: true, hidden: false, disablePadding: false }
            )

            setNewColumns(dynamicColumns);
            //console.log("dynamicColumns: ", dynamicColumns);
        }
    }, [paymentHeaders]);


    useEffect(() => {
        if (results !== undefined && paymentHeaders !== undefined) {
            let arr = [];
            for (let i = 0; i < results.length; i++) { //all payment; one row per OR

                let obj = {};
                let totalTuition = 0;
                let totalAmount = 0;

                //Constant Headers/Columns
                obj['Student No'] = results[i].StudentNo;
                obj['Student Name'] = results[i].StudentName;
                obj['ORDate'] = results[i].ORDate; //.toISOString().split('T')[0];
                obj['ORNo'] = results[i].ORNo;
                obj['SYSem'] = results[i].SYSem;
                obj['Total Amount'] = 0;
                obj['Tuition Fee'] = 0;

                for (let ii = 0; ii < paymentHeaders.length; ii++) {
                    obj[paymentHeaders[ii].Fees] = 0;
                }

                for (let feeAmount = 0; feeAmount < results[i].Details.length; feeAmount++) {
                    switch (results[i].Details[feeAmount].FeeCode) {
                        case 'TF':
                        case 'RG':
                        case 'MS':
                        case 'LB':
                        case 'OLB':
                            totalTuition += parseFloat(results[i].Details[feeAmount].Amount || 0);
                            totalAmount += parseFloat(results[i].Details[feeAmount].Amount || 0);
                            obj['Tuition Fee'] = totalTuition;
                            break;
                        default:
                            for (let ii = 0; ii < paymentHeaders.length; ii++) {
                                if (paymentHeaders[ii].Fees == results[i].Details[feeAmount].Fees) {
                                    obj[paymentHeaders[ii].Fees] = parseFloat(results[i].Details[feeAmount].Amount || 0);
                                    totalAmount += parseFloat(results[i].Details[feeAmount].Amount || 0);
                                }
                            }
                            break;
                    }
                }

                obj['Total Amount'] = totalAmount;
                obj['Payment Reference'] = results[i].PaymentReference;
                obj['Institute'] = results[i].Institute;
                obj['Course'] = results[i].Course;
                obj['YearLevel'] = results[i].YearLevel;
                obj['ClassSection'] = results[i].ClassSection;
                obj['Cashier'] = results[i].Cashier;
                obj['Other Fee Description'] = results[i].OtherFeeDescription;

                arr.push(obj);
            }

            setExcelFormat(arr);
            setNewResults(arr);
        }
    }, [results, paymentHeaders])

    // useEffect(() => {
    //     if (results !== undefined) {
    //         let arr = [];
    //         for (let i = 0; i < results.length; i++) {

    //             let totalTuition = 0;
    //             for(let ii = 0; ii < results[i].Details.length; ii++){

    //                 switch (results[i].Details[ii].FeeCode){
    //                     case 'TF':
    //                     case 'RG':
    //                     case 'MS':
    //                     case 'LB':
    //                     case 'OLB':
    //                         totalTuition += parseFloat(results[i].Details[ii].Amount || 0);
    //                         break;
    //                     default:
    //                         break;
    //                 }
    //             }


    //             arr.push({
    //                 'ORNo': `${results[i].ORNo}`,
    //                 'Student No': `${results[i].StudentNo}`,
    //                 'Student Name': `${results[i].StudentName}`,
    //                 'Tuition Fee': totalTuition
    //                 //'OR Date': `${results[i].ORDate}`,
    //                 //'Fee Description': `${results[i].Fees}`,
    //                 //'SYSem': `${results[i].SYSem}`,
    //                 //'Amount': `${results[i].Tuition}`,
    //                 //'Total Amount': `${results[i].TotalAmount}`,
    //                 //'Payment Date': `${results[i].PaymentDate}`,
    //                 //'Payment Reference': `${results[i].PaymentReference}`,
    //                 //'Course': `${results[i].Course}`,
    //                 //'Year Level': `${results[i].YearLevel}`,
    //                 //'Student Group': `Tertiary`,
    //                 //'Year Level': `${results[i].ClassSection}`,
    //                 //'Cashier': `${results[i].CashierName}`
    //             })
    //         }

    //         console.log(arr);
    //         setExcelFormat(arr);
    //         setNewResults(arr);
    //     }
    // }, [results])

    // const columns = [
    //     { id: 'ORNo', label: 'OR No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     { id: 'StudentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     { id: 'TuitionFee', label: 'Tuition', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     //{ id: 'SemesterId', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     //{ id: 'SchoolYear', label: 'School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     //{ id: 'PaymentDate', label: 'Payment Date', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     //{ id: 'Fees', label: 'Fee Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     //{ id: 'Tuition', label: 'Payment Amount', align: 'left', withSorting: true, hidden: false, disablePadding: false }
    // ]

    //const columns = 

    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: newColumns,
        rows: newResults,
        totalRecords: newResults.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'applicantReport',
        parentId: null,
        tableTitle: '',
        onPage: 0,
        onRowsPerPage: 10

    }

    const imSelectDropdownSchoolYear = () => {
        return <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectSyChange()}
            refClassContainer=""
            name="SchoolYear"
            isRequired={false}
            withLabel={false}
            label="SchoolYear"
            placeHolder="Select School Year"
            forwardRef={setValue}
            selectedId={filteredSy === undefined ? activeSy : filteredSy}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }

    const imSelectDropdownSemesters = () => {

        return <IMSelect
            data={semestersSelect}
            onHandleChange={selectSemChange()}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Semester"
            forwardRef={setValue}
            selectedId={filteredSem === undefined ? activeSem : filteredSem}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />


    }

    const imSelectDropdownInstitute = () => {
        return <IMSelect
            data={instituteSelect}
            onHandleChange={selectInsChange()}
            refClassContainer=""
            name="Institute"
            isRequired={false}
            withLabel={false}
            label="Institute"
            placeHolder="Select Institute"
            forwardRef={setValue}
            selectedId={filteredIns === undefined ? "" : filteredIns}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }

    const imSelectDropdownCourse = () => {
        return <IMSelect
            data={courseSelect}
            onHandleChange={selectCourseChange()}
            refClassContainer=""
            name="Course"
            isRequired={false}
            withLabel={false}
            label="Course"
            placeHolder="Select Course"
            forwardRef={setValue}
            selectedId={filteredCourse === undefined ? "" : filteredCourse}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }

    const imSelectDropdownYearLevel = () => {
        return (
            <IMSelect
                data={yearLevelSelect}
                onHandleChange={selectYearLevelChange()}
                refClassContainer=""
                name="Year Level"
                isRequired={false}
                withLabel={false}
                label="Year Level"
                placeHolder="Select Year Level"
                forwardRef={setValue}
                selectedId={filteredYearLevel === undefined ? '' : filteredYearLevel}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                    tableId: 'tableId',
                    display: 'code'
                }}
            />
        )
    }

    const imSelectDropdownCashier = () => {
        return (
            <IMSelect
                data={cashierSelect}
                onHandleChange={selectCashierChange()}
                refClassContainer=""
                name="Cashier"
                isRequired={false}
                withLabel={false}
                label="Cashier"
                placeHolder="Select Cashier"
                forwardRef={setValue}
                selectedId={filteredCashier === undefined ? '' : filteredCashier}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                    tableId: 'tableId',
                    display: 'code'
                }}
            />
        )
    }

    //console.log(filteredSem)
    //console.log(filteredSy)
    //console.log(results)

    //console.log(filteredDateFrom)
    //console.log(moment().format("YYYY-MM-DD HH:mm:ss"))

    // if (excelFormat.length > 0) {
    //    console.log(excelFormat[0])
    //    console.log(excelFormat[0][['Prototype']])

    // }


    // const sampleData = [
    //     {
    //         "firstname": "Venjo",
    //         "lastName": "Reyes"
    //     },
    //     {
    //         "firstname": "Jessca",
    //         "lastName": "Katigbak"
    //     }
    // ]

    console.log('newResults: ', newResults);
    //console.log('paymentHeaders: ',paymentHeaders);
    //console.log('columns: ',columns);
    // console.log('filteredYearLevel: ',filteredYearLevel);
    // console.log('filteredCashier: ',filteredCashier);
    // console.log('filteredSem: ',filteredSem);
    // console.log('filteredSy: ',filteredSy);
    console.log('filteredIns: ', filteredIns);
    console.log('institutes: ', instituteSelect);
    console.log('filteredCourse: ', filteredCourse);
    console.log('courses: ', courseSelect);

    return (
        <>

            <div className="card">
                <div className="card-header font-weight-bold">
                    <h3 className="mb-0 d-block">  &nbsp;Payment Report</h3>
                    <div className="ml-2 mt-2" >
                        <p className=" d-block mb-0">{`SY ${activeSy} - ${parseInt(activeSy) + 1} / ${activeSem}`}</p>

                    </div>

                </div>
                <ListFilters
                    selectDropdownSemester={imSelectDropdownSemesters}
                    selectDropdownSchoolYear={imSelectDropdownSchoolYear}
                    selectDropdownInstitute={imSelectDropdownInstitute}
                    selectDropdownCourses={imSelectDropdownCourse}
                    selectDropdownYearLevel={imSelectDropdownYearLevel}
                    selectDropdownCashier={imSelectDropdownCashier}
                    onDateFromHandler={onDateFromHandler}
                    onDateToHandler={onDateToHandler}
                    dateFrom={filteredDateFrom}
                    dateTo={filteredDateTo}
                    tableTitle='Filter: '
                    type={'paymentReport'}
                    onSubmit={onSubmit}
                    results={results}
                    excelFormat={excelFormat}
                    paymentReport={1}
                    isGeneratingReport={isGeneratingReport}

                />

                <section className="" style={{ maxHeight: "500px", display: "flex" }}>
                    {/* <IMTableCustom tableProps={tableProps} /> */}
                </section>
            </div>
        </>
    )
}