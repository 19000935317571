import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    addingDroppingOfSubjects, attendanceSearchSubjects,
    getSchoolYearListForClearance, getSemesterListForClearance, studentAutoComplete,
    getSubjectsForDropdown, studentClearanceReport, getInstitute, getCustomCourseList,
    getClearanceStatus
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";
import ExportExcel from "../ExportExcel";
import { useAppContext } from "../../../../contexts/useAppContext";
import { TextField } from "@material-ui/core";
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable'
import ClearanceReportExcelFormat from "./ClearanceReportExcelFormat";
import Autocomplete from '@material-ui/lab/Autocomplete';
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import SummaryOfGradesConfirmation from "../../../../../_metronic/layout/components/custom/modal/SummaryOfGradesConfirmation";

export default function ClearanceReport(props) {
    const { state: { auth } } = useAppContext();
    const { setValue, handleSubmit, register, watch, errors } = useForm();
    const [results, setResults] = useState([])

    // START :: Test Pagination
    const [totalRecords, setTotalRecord] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(null);
    const [onSearch, setOnSearch] = useState(false);
    const [onAlert, setOnAlert] = useState(false);
    const [onSearchReset, setOnSearchReset] = useState(true);
    const [subjectSelect, setSubjectSelect] = useState([]);
    const [subject, setSubject] = useState(0);
    const [studentList, setStudentList] = useState([]);
    const [studentId, setStudentId] = useState();
    const [schoolYearDisplay, setSchoolYearDisplay] = useState(null);
    const [semesterDisplay, setSemesterDisplay] = useState(null);
    const [filter, setFilter] = useState({ 
        schoolYear: auth.data.schoolyears.enrollment.SchoolYear,
        semester: auth.data.schoolyears.enrollment.SemesterId,
        instituteId: 90,
        courseId: 0,
        clearanceId: 2
    });
    
    const [schoolYearSelect, setSchoolYearSelect] = useState([]);
    const [semesterSelect, setSemesterSelect] = useState([]);
    const [instituteSelect, setInstituteSelect] = useState([]);
    const [courseSelect, setCourseSelect] = useState([]);
    const [clearanceStatusSelect, setClearanceStatusSelect] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [preventExec, setPreventExec] = useState(true);

    const [isForConfirmation, setIsForConfirmation] = useState(false);

    const afterConfirmation = () => {
    };
    
    const onReset = async () => {
        setResults([]);
        setOnAlert(false);
        setPreventExec(true);
        setPage(0);
        setRowsPerPage(10);
        setKeyword(null);
        setOnSearch(false);
        // setFilter({ 
        //     schoolYear: auth.data.schoolyears.enrollment.SchoolYear,
        //     semester: auth.data.schoolyears.enrollment.SemesterId,
        //     instituteId: null,
        //     courseId: null,
        //     clearanceId: 2
        // });
    }

    const handleGetStudent = async (data) => {
        setOnAlert(false);
        setOnAlert(false);
        setStudentId(data.StudentId);
    }

    // pagination
    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };

    const handleReportSearch = (event) => {
        if(preventExec){
            setPreventExec(false);
        }
        searchForReport();
    };

    // handle subject search
    const handleSubjectInputChange = async (input) => {
        setOnAlert(false);

        let values = {
            input: input 
        }

        await getSubjectsForDropdown(values)
            .then(res => {
                setSubjectSelect(res.data);
            })
            .catch(e => {
    
            })
    }

    const handleSubjectInputSelect = async (data) => {
        setOnAlert(false);
        setSubject(data.tableId);
    }

    const handleStudentInputChange = async (input) => {
        setOnAlert(false);
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    //When there are changes in Footer
    useEffect(() => {
        if(!preventExec){
            searchForReport();
        }
    }, [page, keyword, rowsPerPage]);

    useEffect(() => {
        SelectCreator(getSchoolYearListForClearance, setSchoolYearSelect, 'SchoolYear', 'Description');
        SelectCreator(getSemesterListForClearance, setSemesterSelect, 'SemesterId', 'Description');
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code');
        SelectCreator(getCustomCourseList, setCourseSelect, 'CourseIdx', 'Code');
        SelectCreator(getClearanceStatus, setClearanceStatusSelect, 'ClearanceStatusId', 'Description');
    }, []);

    useEffect(() => {
        if(schoolYearSelect !== null && typeof(schoolYearSelect) !== "undefined" ) {
            if(filter.schoolYear == 0){
                setSchoolYearDisplay("All School Years");
            } else {
                for(let i = 0; i < schoolYearSelect.length; i++){
                    if(filter.schoolYear == schoolYearSelect[i].tableId){
                        setSchoolYearDisplay(schoolYearSelect[i].code);
                        break;
                    }
                }
            }

            console.log("SY Display triggered");
        }

        if(semesterSelect !== null && typeof(semesterSelect) !== "undefined" ) {
            if(filter.semester == 0){
                setSemesterDisplay("All Semesters");
            } else {
                for(let i = 0; i < semesterSelect.length; i++){
                    if(filter.semester == semesterSelect[i].tableId){
                        setSemesterDisplay(semesterSelect[i].code);
                        break;
                    }
                }
            }
            console.log("Sem Display triggered");
        }
       
    }, [filter.schoolYear, filter.semester, schoolYearSelect, semesterSelect]);

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    const onAddDropSearch = () => {
        searchForReport();
    }

    const searchForReport = async() => {
        
            await studentClearanceReport({
                rowsPerPage: rowsPerPage,
                page: page,
                keyword: (keyword !== null && keyword != '') ? keyword.trim() : null,
                schoolYear: filter.schoolYear,
                semesterId: filter.semester,
                instituteId: filter.instituteId = '' || filter.instituteId === null ? 90 : filter.instituteId,
                courseId: filter.courseId = '' || filter.courseId === null ? 0 : filter.courseId,
                clearanceId: filter.clearanceId = '' || filter.clearanceId === null ? 0 : filter.clearanceId,
                extractAll: 0
            }).then(res => {
                setResults(res.data);
                if (res.data.length > 0) {
                    setTotalRecord(res.data[0].TotalRecord);
                    setOnSearchReset(false);
                } else {
                    setTotalRecord(0);
                    setOnSearchReset(true);
                    setOnAlert(true);
                }
            })
    }

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Institute', label: 'College', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Course', label: 'Program', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Accountability', label: 'Accountability', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Remarks', label: 'Remarks', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Department', label: 'Department', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SchoolYear', label: 'School Year', align: 'left', minWidth: '120px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Semester', label: 'Semester', align: 'left', minWidth: '150px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ClearanceStatus', label: 'Clearance Status', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'HoldBy', label: 'Hold By', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'HoldDate', label: 'Hold Date', align: 'left', minWidth: '120px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ClearBy', label: 'Cleared By', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'ClearDate', label: 'Cleared Date', align: 'left', minWidth: '120px', withSorting: false, hidden: false, disablePadding: false }
        // { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'RowCnt',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'studentMasterFile',
        parentId: null,
        tableTitle: 'List of Cleared/Uncleared Students',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: 0,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: null,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch
    }

    // const imSelectDropdownSchoolYear = () => {
    //     return (
    //         <>
    //             {schoolYearSelect !== null &&
    //                 <IMSelect
    //                     data={schoolYearSelect}
    //                     onHandleChange={filterChange('schoolYear')}
    //                     refClassContainer=""
    //                     name="schoolYear"
    //                     isRequired={false}
    //                     withLabel={false}
    //                     label="School Year"
    //                     placeHolder="Select School Year"
    //                     forwardRef={() => { }}
    //                     selectedId={filter.schoolYear}
    //                     refClassName={`text-center`}
    //                     withDescription={true}
    //                     description={`School Year`}
    //                     refDisabled={true}
    //                     refIsStatic={false}
    //                     refStaticData={[]}
    //                     field={{tableId: 'tableId',display: 'code'}}
    //                 />
    //             }
    //         </>
    //     )
    // }

    // const imSelectDropdownSemesters = () => {
    //     return (
    //         <>
    //             {semesterSelect !== null &&
    //                 <IMSelect
    //                     data={semesterSelect}
    //                     onHandleChange={filterChange('semester')}
    //                     refClassContainer=""
    //                     name="semesterId"
    //                     isRequired={false}
    //                     withLabel={false}
    //                     label="Semester"
    //                     placeHolder="Select Semester"
    //                     forwardRef={() => { }}
    //                     selectedId={filter.semester}
    //                     refClassName={`text-center`}
    //                     withDescription={true}
    //                     description={`Semester`}
    //                     refDisabled={true}
    //                     refIsStatic={false}
    //                     refStaticData={[
    //                     ]
    //                     }
    //                     field={{
    //                         tableId: 'tableId',
    //                         display: 'code'
    //                     }}
    //                 />
    //             }
    //         </>
    //     )
    // }

    return (
        <>

            {
                results.length == 0 && !isLoading && schoolYearSelect && semesterSelect && instituteSelect && courseSelect && clearanceStatusSelect &&
                <>
                    <SummaryOfGradesConfirmation
                        isOpen={isForConfirmation}
                        handleConfirm={() => afterConfirmation()}
                        handleClose={() => setIsForConfirmation(false)}
                        title={'Clearance Report'}
                        content={'The selected filters will list all available data. Would you like to proceed?'}
                    />

                    <div className='d-flex align-items-center'>
                        <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                            <header className='card-header p-5 bg-secondary'>
                                <h6 class="float-left m-0 mt-4 ml-5">Clearance Report</h6>
                            </header>

                            <section className="card-body">
                                   {
                                        onAlert == true && 
                                        // results.length == 0 &&
                                       <div className="alert alert-danger" role="alert">
                                           No Record Found
                                       </div>
                                   }

                                   {/* School Year Dropdown */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={schoolYearSelect}
                                               onHandleChange={filterChange('schoolYear')}
                                               refClassContainer=""
                                               name="schoolYear"
                                               isRequired={false}
                                               withLabel={true}
                                               label="School Year"
                                               placeHolder="Select School Year"
                                               forwardRef={() => { }}
                                               selectedId={filter.schoolYear}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`School Year`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                           />
                                       </div>
                                   </div>

                                   {/* Semester Dropdown */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={semesterSelect}
                                               onHandleChange={filterChange('semester')}
                                               refClassContainer=""
                                               name="semester"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Semester"
                                               placeHolder="Select Semester"
                                               forwardRef={() => { }}
                                               selectedId={filter.semester}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Semester`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                           />
                                       </div>
                                   </div>

                                   {/* Institutes */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={instituteSelect}
                                               onHandleChange={filterChange('instituteId')}
                                               refClassContainer=""
                                               name="instituteId"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Search by Institute"
                                               placeHolder="Select Institute"
                                               forwardRef={() => { }}
                                               selectedId={filter.instituteId}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Institutes`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                               isWithToolTip={true}
                                               toolTipText={'Optional; Leave blank to search "All" by default'}
                                           />
                                       </div>
                                   </div>

                                   {/* Courses */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={courseSelect}
                                               onHandleChange={filterChange('courseId')}
                                               refClassContainer=""
                                               name="courseId"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Search by Course"
                                               placeHolder="Select Course"
                                               forwardRef={() => { }}
                                               selectedId={filter.courseId}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Courses`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                               isWithToolTip={true}
                                               toolTipText={'Optional; Leave blank to search "All" by default'}
                                           />
                                       </div>
                                   </div>
                                   
                                   {/* Clearance Status */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={clearanceStatusSelect}
                                               onHandleChange={filterChange('clearanceId')}
                                               refClassContainer=""
                                               name="clearanceId"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Search by Clearance Status"
                                               placeHolder="Select Clearance Status"
                                               forwardRef={() => { }}
                                               selectedId={filter.clearanceId}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Clearance Status`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                               isWithToolTip={true}
                                               toolTipText={'Optional; Leave blank to search "All" by default'}
                                           />
                                       </div>
                                   </div>

                                    <div className='row'>
                                        <div className="form-group col">
                                            {
                                                <>
                                                    <button className='btn btn-success btn-block' onClick={() => { handleReportSearch() }} > 
                                                        Search
                                                    </button> 
                                                </>
                                            }
                                        </div>
                                        
                                        <div className="form-group col">
                                            {
                                                <ClearanceReportExcelFormat 
                                                    fileName={'ClearanceReport'} 
                                                    btnName={'Extract Report'} 
                                                    page={page} 
                                                    keyword={keyword} 
                                                    rowsPerPage={rowsPerPage}
                                                    schoolYear={filter.schoolYear}
                                                    semesterId={filter.semester}
                                                    instituteId={filter.instituteId}
                                                    courseId={filter.courseId}
                                                    clearanceId={filter.clearanceId}
                                                    schoolYearDisplay={schoolYearDisplay}
                                                    semesterDisplay={semesterDisplay}
                                                />
                                            }
                                        </div>
                                    </div>
                            </section>
                        </main>
                    </div>
                </>
            }
            
            {   results.length > 0 &&
                <div>
                    <div className="form-row" style={{width:"25%",float:"right",marginRight:"2%"}}>
                        <section className="col"> <button onClick={() => { onReset() }} className='btn btn-success btn-block'>Reset</button></section>
                        {/* <section className="col">{imSelectDropdownSchoolYear()}</section>
                        <section className="col">{imSelectDropdownSemesters()}</section> */}
                        {
                            totalRecords > 0 && 
                            <section className="col">
                                <ClearanceReportExcelFormat 
                                    fileName={'ClearanceReport'} 
                                    btnName={'Extract Report'} 
                                    page={page} 
                                    keyword={keyword} 
                                    rowsPerPage={rowsPerPage}
                                    schoolYear={filter.schoolYear}
                                    semesterId={filter.semester}
                                    instituteId={filter.instituteId}
                                    courseId={filter.courseId}
                                    clearanceId={filter.clearanceId}
                                    schoolYearDisplay={schoolYearDisplay}
                                    semesterDisplay={semesterDisplay}
                                />
                            </section>
                        }
                        {/* <section className="col">
                            <button onClick={() => { onAddDropSearch() }} className='btn btn-success btn-block'>Search</button>
                        </section> */}
                    </div>

                    <br></br>
                    <br></br>

                    <div className="card-body">
                        <div className="card card-custom gutter-b example example-compact mx-auto" >
                            <div className="mt-2" style={{ padding: '0px', margin: '0px' }}>
                                <IMTable tableProps={tableProps} />
                            </div>
                        </div>
                    </div>
                </div>
            }
            
        </>
    )
}