import _ from 'lodash';
import { enrollmentTypes } from './types';

const reducer = (state, action) => {
    // console.log('state',state,'action',action);
    switch (action.type) {
        case enrollmentTypes.SELECTED_ASSESSMENT_OTHER:
            return { ...state, data: action.data };
        case enrollmentTypes.CREATE:
            return { ...state, data: { ...state.data, [action.data.AssessmentTemplateOtherId]: action.data } };
        case enrollmentTypes.GET_ASSESSMENT_OTHERS:
            return { ...state, data: { ..._.mapKeys(action.data, 'AssessmentTemplateOtherId') } };
        case enrollmentTypes.UPDATE:
            return { ...state, data: { ...state.data, [action.data.AssessmentTemplateOtherId]: action.data }, id: action.id }
        case enrollmentTypes.DELETE:
            return { ...state, data: { ..._.omit(state.data, action.data) } };
        case enrollmentTypes.CLEAR_LAST_UPDATE_ID:
            return { ...state, ...state.id, id: null }
        default:
            return state;
    }
}

export default reducer;