import instance from '../../../../../apis/local/systemAPI';

// START OF CIVIL STATUS
export const getActiveCivilStatuses = async () => {
  const result = await instance.get("api/references/civilstatus/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCivilStatuses = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/civilstatus/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "code": keyword,
            "civilStatus": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/civilstatus/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createCivilStatus = async (formValues) => {
  const result = await instance.post("api/references/civilstatus", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCivilStatus = async (id, formValues) => {
  const result = await instance.patch(`api/references/civilstatus/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteCivilStatus = async (id) => {
  const result = await instance.delete(`api/references/civilstatus/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF CIVIL STATUS