import _ from 'lodash';
import { referenceTypes } from './types';

const reducer = (state, action) => {
    // console.log('state',state,'action',action);
    switch (action.type) {
        case referenceTypes.GET_SUBJECT_SCHEDULES:
            return { ...state, data: action.data };
        default:
            return state;
    }
}

export default reducer;