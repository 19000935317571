import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CloneAssessmentTemplateModal(props) {
    const { title, Content, isOpen, handleClose, onSubmit, schoolYearSelect,
        semesterSelect, isError, ErrorContent, isCloned } = props;
    const [formValues, setFormValues] = useState([])
    const [isCloaning, setIsCloaning] = useState(false)


    const onSave = () => {
        setIsCloaning(true)
        onSubmit(formValues)
            .then(res => {
                setIsCloaning(false)

            })
    }

    const onGetValues = (data) => {
        setFormValues(data)
    }

    return (
        <>

            <Dialog
                open={isOpen}
                TransitionComponent={Transition}
                keepMounted
                onClose={(event, reason) => {
                    console.log(reason)
                    if (reason !== 'backdropClick' && reason !== 'escapeKeyDown') {
                        handleClose()

                    }
                }}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth={'sm'}
                fullWidth
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <hr />

                <section className='container'>
                    {isCloned == false ?
                        <>
                            <div className="alert alert-danger" role="alert">
                                <i className="fas fa-exclamation-triangle text-white"></i>
                                &nbsp;
                                Unable to clone. Verify whether the assessment template already exists
                                or if the assessment intended for cloning does not exist.
                            </div>

                        </>
                        :
                        <>
                            {isCloned == true &&
                                <div className="alert alert-success" role="alert">
                                    <i className="fa fa-check text-white"></i>
                                    &nbsp;
                                    Successfully Cloned
                                </div>
                            }


                        </>

                    }
                </section>




                <Content
                    schoolYearSelect={schoolYearSelect}
                    semesterSelect={semesterSelect}
                    onSave={onGetValues}
                />

                <DialogActions>
                    {isCloaning == false ?
                        <>
                            {isCloned == null || isCloned == false ?
                                <>
                                    <button onClick={() => { onSave() }} className="btn btn-success">
                                        Clone
                                    </button>

                                    <button onClick={handleClose} className="btn btn-secondary">
                                        Cancel
                                    </button>
                                </>
                                :
                                <>
                                    {isCloned == true &&
                                        <>
                                            <button onClick={handleClose} className="btn btn-secondary">
                                                Close
                                            </button>
                                        </>
                                    }
                                </>
                            }
                        </>
                        :
                        <>
                            <div className="spinner-border spinner-border-sm" role="status">
                                <span class="sr-only">Loading...</span>
                            </div> &nbsp; Cloaning Please wait...

                        </>
                    }




                </DialogActions>
            </Dialog>
        </>
    );
}