export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_DETAIL: 'SELECTED-ASSESSMENT-DETAIL',
    GET_ASSESSMENT_DETAILS: 'GET-ASSESSMENT-DETAILS',
    GET_ASSESSMENT_DETAIL: 'GET-ASSESSMENT-DETAIL-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-DETAIL',
    UPDATE: 'UPDATE-ASSESSMENT-DETAIL',
    DELETE: 'DELETE-ASSESSMENT-DETAIL',
    LIST_LINK_TO_PARENT: '/admin/enrollment/assessment-of-fees',
    LIST_LINK: '/admin/enrollment/assessment-of-fee-details',
    MORE_LINK: '/admin/enrollment/assessment-of-fee-detail-others/',
    CREATE_LINK: '/admin/enrollment/create-assessment-of-fee-details/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-of-fee-details/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-of-fee-details/'
}