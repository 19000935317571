export const referenceTypes = {
    SELECTED_MUNICIPALITY: 'SELECTED-MUNICIPALITY',
    GET_MUNICIPALITIES: 'GET-MUNICIPALITIES',
    GET_MUNICIPALITY: 'GET-MUNICIPALITY-BY-ID',
    CREATE: 'CREATE-MUNICIPALITY',
    UPDATE: 'UPDATE-MUNICIPALITY',
    DELETE: 'DELETE-MUNICIPALITY',
    LIST_LINK: '/admin/references/municipalities',
    CREATE_LINK: '/admin/references/create-municipalities/',
    UPDATE_LINK: '/admin/references/update-municipalities/',
    DELETE_LINK: '/admin/references/delete-municipalities/'
}