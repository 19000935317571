export const referenceTypes = {
    SELECTED_SCHOOL_YEAR: 'SELECTED-SCHOOL-YEAR',
    GET_SCHOOL_YEARS: 'GET-SCHOOL-YEARS',
    GET_SCHOOL_YEAR: 'GET-SCHOOL-YEAR-BY-ID',
    CREATE: 'CREATE-SCHOOL-YEAR',
    UPDATE: 'UPDATE-SCHOOL-YEAR',
    DELETE: 'DELETE-SCHOOL-YEAR',
    LIST_LINK: '/admin/references/schoolyears',
    CREATE_LINK: '/admin/references/create-schoolyears/',
    UPDATE_LINK: '/admin/references/update-schoolyears/',
    DELETE_LINK: '/admin/references/delete-schoolyears/'
}