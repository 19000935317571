import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import history from '../../../../history';
import AssessmentDetailList from './AssessmentDetail/List';
import AssessmentScheduleList from './AssessmentSchedule/List';
import PaymentScheduleList from './PaymentSchedule/List'
import AssessmentOtherList from './AssessmentOther/List';
import AssessmentDiscountList from "./AssessmentDiscount/List"
import { enrollmentTypes } from './AssessmentDetail/__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { makeStyles } from '@material-ui/core/styles';
import { Accordion, AccordionSummary, AccordionDetails, Typography } from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import { getAssessmentTemplates, duplicateAssessmentTemplate } from "./__hooks__"
import { useParams } from 'react-router-dom';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular
  },
  chip: {
    margin: theme.spacing(1),
  },
}));

export default function Container(props) {
  const { state: { auth }, dispatch } = useAppContext()
  let ReferenceName;
  if (props.location.data == undefined) {
    history.push(`/admin/enrollment/assessment-of-fees`)
  } else {
    console.log(props.location.data)
    ReferenceName = props.location.data.ReferenceName

  }
  const classes = useStyles();
  const [results, setResults] = useState(null)
  const [details, setDetails] = useState(null)
  const [others, setOthers] = useState(null)
  const [paymentScheds, setPaymentScheds] = useState(null)
  const { state: { assessmentTemplates } } = useAppContext();
  const { id } = useParams()
  const [expanded, setExpanded] = useState({
    assessmentDetailPanel: true,
    assessmentSchedulePanel: true,
    assessmentOtherPanel: true,
    assessmentDiscountPanel: true,
    paymentSchedulePanel: true

  });


  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded({ ...expanded, [panel]: isExpanded });
  };

  const handleGetDatails = (data) => {
    console.log(data)
    setDetails(data)
  }

  const handleGetOthers = (data) => {
    console.log(data)
    setOthers(data)

  }

  const handleGetPaymentSched = (data) => {
    console.log(data)
    setPaymentScheds(data)

  }

  const onHandleDuplicateAll = async () => {
    let formValues = {
      schoolYear: results.SchoolYear,
      semesterId: results.SemesterId,
      referenceName: results.ReferenceName,
      studentCategoryId: results.StudentCategoryId,
      instituteId: results.InstituteId,
      courseId: results.CourseId,
      yearLevelId: results.YearLevelId,
      isActive: 1,
      createdBy: auth.data.Username,
      details: details,
      others: others,
      paymentSched: paymentScheds
    }


    await duplicateAssessmentTemplate(formValues)
      .then(res => {
        if (res.success == false) {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: res.message });
        } else {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Assessment Template Duplicated' });

        }
        console.log(res)

      })
  }



  useEffect(() => {
    const getData = async () => {
      await getAssessmentTemplates({ where: `WHERE AssessmentTemplateId = ${parseInt(id)}` })
        .then(res => {
          console.log(res)
          if (res.data.length > 0)
            setResults(res.data[0])
          else
            setResults({ AssessmentTemplateId: parseInt(id) })
        })
    }

    getData()
  }, [])


  console.log(results)

  return (
    <> {results !== null &&
      <>
        <div className="card card-custom gutter-b example example-compact">
          <div className="card-header" style={{ paddingLeft: "15px" }}>
            <div className="card-title text-dark">
              {ReferenceName}
            </div>
          </div>
          <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
            <div className={classes.root}>
              <Accordion expanded={expanded.assessmentDetailPanel} onChange={handleChange('assessmentDetailPanel')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                  style={{ background: "#efefef" }}
                >
                  <Typography className={classes.heading}><span className="text-primary"><b>ASSESSMENT DETAIL/S</b></span></Typography>
                  <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <AssessmentDetailList assessmentTemplate={results} handleGetDatails={handleGetDatails} />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded.assessmentSchedulePanel} onChange={handleChange('assessmentSchedulePanel')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  style={{ background: "#efefef" }}
                >
                  <Typography className={classes.heading}><span className="text-primary"><b>ASSESSMENT TEMPLATE OTHER/S</b></span></Typography>
                  <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <AssessmentOtherList assessmentTemplate={results} handleGetOthers={handleGetOthers} />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded.paymentSchedulePanel} onChange={handleChange('paymentSchedulePanel')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel2bh-content"
                  id="panel2bh-header"
                  style={{ background: "#efefef" }}
                >
                  <Typography className={classes.heading}><span className="text-primary"><b>PAYMENT SCHEDULE/S</b></span></Typography>
                  <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <PaymentScheduleList assessmentTemplate={results} handleGetPaymentSched={handleGetPaymentSched} />
                </AccordionDetails>
              </Accordion>
              <Accordion expanded={expanded.assessmentDiscountPanel} onChange={handleChange('assessmentDiscountPanel')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel3bh-content"
                  id="panel3bh-header"
                  style={{ background: "#efefef" }}
                >
                  <Typography className={classes.heading}><span className="text-primary"><b>ASSESSMENT DISCOUNT/S</b></span></Typography>
                  <Typography className={classes.secondaryHeading}>&nbsp;</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ padding: "0px" }}>
                  <AssessmentDiscountList assessmentTemplate={results} />
                </AccordionDetails>
              </Accordion>
            </div>
          </div>
          <div className="card-footer" style={{ padding: '0px', margin: '0px' }}>
            {results.PaymentModeId == 1 &&
              <button onClick={() => { onHandleDuplicateAll() }} className='btn btn-success ml-5'>Duplicate</button>

            }

            <Link to={enrollmentTypes.LIST_LINK_TO_PARENT} className="btn btn-secondary m-3">Back</Link>
          </div>
        </div>
      </>
    }

    </>
  );
}