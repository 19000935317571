export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_SCHEDULE_DETAIL: 'SELECTED-ASSESSMENT-SCHEDULE-DETAIL',
    GET_ASSESSMENT_SCHEDULE_DETAILS: 'GET-ASSESSMENT-SCHEDULE-DETAILS',
    GET_ASSESSMENT_SCHEDULE_DETAIL: 'GET-ASSESSMENT-SCHEDULE-DETAIL-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-SCHEDULE-DETAIL',
    UPDATE: 'UPDATE-ASSESSMENT-SCHEDULE-DETAIL',
    DELETE: 'DELETE-ASSESSMENT-SCHEDULE-DETAIL',
    LIST_LINK_TO_PARENT: '/admin/enrollment/assessment-of-fee-details',
    LIST_LINK: '/admin/enrollment/assessment-schedule-detail',
    MORE_LINK: '/admin/enrollment/assessment-schedule-detail/',
    CREATE_LINK: '/admin/enrollment/create-assessment-schedule-detail/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-schedule-detail/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-schedule-detail/'
}