import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import {
    getFamily, updateEnrollment, createStudentDiscount, getStudentDiscount,
    updateStudent, getStudyTypes, updateStudentDiscount,
    studentVerify, sendMail
} from "./__hooks__";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { enrollmentTypes } from './__hooks__/types'
import { useAppContext } from "../../../../contexts/useAppContext";
import history from "../../../../history";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import { FormControlLabel, Switch } from "@material-ui/core";
import { NoAccess } from "../../../../../_metronic/layout/components/custom/forms/NoAccess";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import NoDataFound from "../../../../../_metronic/layout/components/custom/forms/NoDataFound";


export default function VerifiedApplicantsForm(props) {
    const { student, schoolHistory, scholarship, studentDiscount, studentInfo,
        course, yearLevel, school, studentPreferred, courseSelect } = props
    const { state: { auth }, dispatch } = useAppContext();
    const { setValue, register } = useForm();
    const [keepLoading, setKeepLoading] = useState(true)
    const { id } = useParams()
    const [scholarshipRef, setScholarshipRef] = useState()
    const [prePrimary, setPrePrimary] = useState('N/A')
    const [primarySchool, setPrimarySchool] = useState('N/A')
    const [jrSchool, setJrSchool] = useState('N/A')
    const [srSchool, setSrSchool] = useState('N/A')
    const [familybackground, setFamilyBackground] = useState()
    const [data, setData] = useState({ ScholarshipId: null, StudyTypeId: null, StudentStatus: student[0].StudentStatusId, courseId: student[0].CourseId })
    const [particularStudentDiscount, setParticularStudentDiscount] = useState(undefined)
    const [taggedScholarship, setTaggedScholarship] = useState()
    const [isTag, setIsTag] = useState(0)
    const [studyType, setStudyType] = useState(null)
    const [allStudyTypes, setAllStudyTypes] = useState()
    const [switchState, setSwitchState] = useState({
        IsActive: isTag === undefined ? true : isTag === 0 ? false : true,
        isPending: studentDiscount.length > 0 ? studentDiscount[0].IsPending === 1 ? true : false : false,
        IsForReservation: student[0].IsForReservation == 1 ? true : false,

    });
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const [onLoadingVerify, setOnLoadingVerify] = useState(false)
    const [isChangeCourse, setIsChangeCourse] = useState(false)

    const onChangeCourse = () => {
        setIsChangeCourse(true)
    }

    const onVerify = async () => {
        setOnLoadingVerify(true)

        const studentValues = {
            studentId: student[0].StudentId,
            studyTypeId: studyType,
            isVerified: 1,
            isForReservation: switchState.IsForReservation == true ? 1 : 0,
            verifiedBy: auth.data.Username,
            verificationDate: dateTime,
            isTransferee: data.StudentStatus,
            isVerified: student[0].IsVerified,
            studentAppNo: student[0].StudentNo,
            courseId: data.courseId

        }
        const studentDiscountValues = {
            studentId: student[0].StudentId,
            scholarshipId: data.ScholarshipId,
            isActive: 1,
            isPending: switchState.isPending === true ? 1 : 0

        }

        await studentVerify(studentValues)
            .then(respond => {
                console.log(respond)
                if (switchState.IsActive === true) {
                    sendMail({
                        isVerifiedAndReserved: switchState.IsForReservation == true ? 1 : 0,
                        emailAddress: student[0].EmailAddress,
                        fullName: `${studentInfo[0].LastName}, ${studentInfo[0].FirstName} ${studentInfo[0].MiddleName}`,
                        studentNo: respond.data[0].StudentNo,
                        schoolYear: `${student[0].SchoolYear} - ${student[0].SchoolYear + 1}`,
                        course: course[0].Description
                    })
                        .then(d => {
                            onCreateStudentDiscount(studentDiscountValues)
                                .then(() => {
                                    dispatch({
                                        type: componentTypes.SHOW_SNACKBAR,
                                        snackBarStatus: 'success',
                                        snackBarDuration: 5000,
                                        snackBarMessage: 'Student Verified'
                                    });
                                    setOnLoadingVerify(false)
                                    history.push('/admin/enrollment')
                                    console.log(studentDiscountValues)
                                    setTimeout(() => {
                                        history.push(`${enrollmentTypes.MORE_LINK}${id}`)
                                    }, 50);
                                })
                        })

                } else {
                    sendMail({
                        isVerifiedAndReserved: switchState.IsForReservation == true ? 1 : 0,
                        emailAddress: student[0].EmailAddress,
                        fullName: `${studentInfo[0].LastName}, ${studentInfo[0].FirstName} ${studentInfo[0].MiddleName}`,
                        studentNo: respond.data[0].StudentNo,
                        schoolYear: `${student[0].SchoolYear} - ${student[0].SchoolYear + 1}`,
                        course: course[0].Description
                    })
                        .then(d => {
                            dispatch({
                                type: componentTypes.SHOW_SNACKBAR,
                                snackBarStatus: 'success',
                                snackBarDuration: 5000,
                                snackBarMessage: 'Student Verified'
                            });
                            history.push('/admin/enrollment')
                            console.log(studentDiscountValues)
                            setTimeout(() => {
                                history.push(`${enrollmentTypes.MORE_LINK}${id}`)
                            }, 50);
                        })

                }
            })

    }

    const onUpdate = () => {


        const studentValues = {
            studentId: student[0].StudentId,
            studyTypeId: studyType,
            isVerified: 1,
            isForReservation: switchState.IsForReservation == true ? 1 : 0,
            verifiedBy: auth.data.Username,
            verificationDate: dateTime,
            isTransferee: data.StudentStatus,
            isVerified: student[0].IsVerified,
            studentAppNo: student[0].StudentNo,
            courseId: data.courseId
        }
        const studentDiscountValues = {
            studentId: student[0].StudentId,
            scholarshipId: data.ScholarshipId,
            isActive: 1,
            isPending: switchState.isPending === true ? 1 : 0

        }

        onUpdateStudent(studentValues)
        if (switchState.IsActive === true) {
            onUpdateStudentDiscount(studentDiscount[0].StudentDiscountId, studentDiscountValues)

        }

        dispatch({
            type: componentTypes.SHOW_SNACKBAR,
            snackBarStatus: 'success',
            snackBarDuration: 5000,
            snackBarMessage: 'Student Status Updated'
        });
        history.push('/admin/enrollment')
        console.log(studentDiscountValues)
        setTimeout(() => {
            history.push(`${enrollmentTypes.MORE_LINK}${id}`)
        }, 50);
    }




    const onCreateStudentDiscount = async (values) => {
        await createStudentDiscount(values)
            .then(response => {
                if (response.success) {
                }
            })
            .catch(error => {
                // alert('student enrollment add ons did not saved')
            })
    }

    const onUpdateStudentDiscount = async (id, values) => {
        await updateStudentDiscount(id, values)
            .then(response => {
                if (response.success) {
                }
            })
            .catch(error => {
                // alert('student enrollment add ons did not saved')
            })
    }

    const onUpdateEnrollment = async (id, values) => {
        await updateEnrollment(id, values)
            .then(response => {
                if (response.success) {
                } else {

                }
            })
            .catch(error => {
                alert('enrollment did not update')
            })
    }

    const onUpdateStudent = async (values) => {
        await studentVerify(values)
            .then(response => {
                console.log(response)
                return response
            })
            .catch(error => {
                alert('student did not update')
            })
    }

    const selectHandleChange = name => event => { setData({ ...data, [name]: event }); }
    const switchHandleChange = name => event => {
        console.log({ [name]: event.target.checked })
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };
    const useEffectOperation = (operation, condition, setData, trigger, trigger2, trigger3) => {
        let dataStorer = []
        useEffect(() => {
            const execute = async () => {
                await operation()
                    .then(response => {
                        if (response.success) {
                            for (let i = 0; i < response.data.length; i++) {
                                if (eval(condition)) {
                                    dataStorer.push(response.data[i])
                                }
                            }
                            setData(dataStorer)
                        } else {
                        }
                    })
                    .catch(error => {
                        if (error) {
                            // alert("something went wrong")
                        } else {
                        }
                    })
            }
            execute()

        }, [trigger, trigger2, trigger3])
    }

    const useEffectLoopOperation = (operation, condition, setData, trigger, trigger2, trigger3) => {
        let dataStorer = []
        useEffect(() => {
            const execute = async () => {
                await operation()
                    .then(response => {
                        if (response.success && trigger) {
                            for (let i = 0; i < response.data.length; i++) {
                                for (let v = 0; v < trigger.length; v++) {
                                    if (eval(condition)) {
                                        dataStorer.push(response.data[i])
                                    }
                                }
                            }
                            setData(dataStorer)
                        } else {
                        }
                    })
                    .catch(error => {
                        if (error) {
                            // alert("something went wrong")
                        } else {
                        }
                    })
            }
            execute()
        }, [trigger, trigger2, trigger3])
    }
    let conGetDefault = 'response.data'
    let conGetSchool = 'response.data[i].SchoolId === schoolHistory[v].SchoolId'
    let conGetStudentFam = 'response.data[i].StudentInfoId === studentInfo[0].StudentInfoId'
    let conGetParticularStudentDiscount = 'response.data[i].StudentId === student[0].StudentId && response.data[i].IsActive === 1'
    useEffectOperation(getStudentDiscount, conGetParticularStudentDiscount, setParticularStudentDiscount, student)
    useEffectOperation(getStudyTypes, conGetDefault, setAllStudyTypes)
    useEffectLoopOperation(getFamily, conGetStudentFam, setFamilyBackground, studentInfo)

    useEffect(() => {
        if (school !== undefined && schoolHistory !== undefined) {
            let prePr
            let primary
            let jr
            let sr
            for (let i = 0; i < school.length; i++) {
                for (let v = 0; v < schoolHistory.length; v++) {
                    if (school[i].SchoolId === schoolHistory[v].SchoolId) {
                        if (schoolHistory[v].SchoolLevelId === 1) {
                            prePr = school[i].School
                        } else if (schoolHistory[v].SchoolLevelId === 2) {
                            primary = school[i].School
                        } else if (schoolHistory[v].SchoolLevelId === 3) {
                            jr = school[i].School
                        } else if (schoolHistory[v].SchoolLevelId === 4) {
                            sr = school[i].School
                        }
                    }
                }
            }



            setPrePrimary(prePr)
            setPrimarySchool(primary)
            setJrSchool(jr)
            setSrSchool(sr)
        }
    }, [school, schoolHistory])

    useEffect(() => {
        let scholarshipData = []

        if (scholarship !== undefined) {
            for (let i = 0; i < scholarship.length; i++) {

                scholarshipData.push({ tableId: scholarship[i].ScholarshipId, code: scholarship[i].Scholarship })

            }
            setScholarshipRef(scholarshipData)
        }
    }, [scholarship])


    useEffect(() => {
        if (studentDiscount !== undefined) {
            if (studentDiscount.length > 0) {
                setData({ ...data, ScholarshipId: studentDiscount[0].ScholarshipId })

                setSwitchState({ ...switchState, IsActive: true })

            }

        } else {
            setSwitchState({ ...switchState, IsActive: false })
        }

        if (particularStudentDiscount !== undefined && scholarship !== undefined) {

            if (particularStudentDiscount.length > 0) {
                for (let i = 0; i < scholarship.length; i++) {
                    if (scholarship[i].ScholarshipId === particularStudentDiscount[0].ScholarshipId) {
                        setTaggedScholarship(scholarship[i].Scholarship)
                    }
                }
            }

        }


    }, [studentDiscount, particularStudentDiscount, scholarship])



    const imSelectDropdownScholarship = () => {

        return <IMSelect
            data={scholarshipRef}
            onHandleChange={selectHandleChange('ScholarshipId')}
            refClassContainer=""
            name="ScholarshipId"
            isRequired={true}
            withLabel={true}
            label="Choose Scholarship"
            placeHolder="Scholarship"
            forwardRef={setValue}
            selectedId={data.ScholarshipId === undefined ? '' : data.ScholarshipId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />

    }
    const studyTypeHandler = (values) => {
        console.log(values)
        setStudyType(values)
    }


    setTimeout(function () {
        setKeepLoading(false)
    }, 5000);

    console.log(studentInfo)
    console.log(school)
    console.log(prePrimary)
    console.log(primarySchool)
    console.log(jrSchool)
    console.log(srSchool)
    console.log(course)
    console.log(yearLevel)
    console.log(familybackground)
    console.log(scholarship)
    console.log(allStudyTypes)
    console.log(student)
    console.log(particularStudentDiscount)
    console.log(student[0].IsVerified)
    console.log(switchState)



    console.log(data)

    return (
        <> {studentInfo !== undefined && course !== undefined && yearLevel !== undefined &&
            familybackground !== undefined && scholarship !== undefined && allStudyTypes !== undefined && student !== undefined
            ?
            <div className="container">
                <div className="card">
                    <div className="text-center my-5">
                        <img className="my-5" style={{ width: '200px' }} src={FaithLogo} />

                        <h3 className="mt-5">Student Application</h3>
                        <p style={{ 'width': '80%' }} className='text-center mx-auto'><strong>NOTE: </strong> The personal information submitted herein shall be used for the initial phase of the application for Admission Examination.</p>

                    </div>

                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                <h3 className="">{`${studentInfo[0].LastName}, ${studentInfo[0].FirstName} ${studentInfo[0].MiddleName}`}</h3>
                                {isChangeCourse == false ?
                                    <>
                                        <p className="my-0  ">{course[0].Description}</p>
                                        <a onClick={onChangeCourse} className="text-primary">Change Course</a>

                                    </>
                                    :
                                    <>
                                        <IMSelect
                                            data={courseSelect}
                                            onHandleChange={selectHandleChange('courseId')}
                                            refClassContainer="col px-0"
                                            name="courseId"
                                            isRequired={true}
                                            withLabel={true}
                                            selectedId={data.courseId}
                                            label="Choose Course"
                                            placeHolder="Course"
                                            forwardRef={setValue}
                                            refClassName={``}
                                            withDescription={false}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[

                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}


                                        />
                                    </>

                                }

                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Address &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    <input readOnly value={studentInfo[0].BirthPlace} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                {studentInfo[0].Gender === 'M' ?
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Gender&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        <input readOnly value='Male' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    : ''
                                }
                                {studentInfo[0].Gender === 'F' ?
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Gender</span>
                                        </div>
                                        <input readOnly value='Female' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    : ''
                                }
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    <input readOnly value={yearLevel[0].Description} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    <input readOnly value={`${student[0].SchoolYear} - ${student[0].SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend col d-inline px-0 border-right rounded-0">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student Type</span>
                                    </div>
                                    {/* {student[0].IsTransferee === 1 ?
                                        <input readOnly value='New Student' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        :
                                        <input readOnly value='Transferee' type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    } */}
                                    <IMSelect
                                        onHandleChange={selectHandleChange('StudentStatus')}
                                        refClassContainer="col px-0"
                                        name="studentStatus"
                                        isRequired={true}
                                        withLabel={false}
                                        // label="Choose Scholarship"
                                        placeHolder="Student Status"
                                        forwardRef={setValue}
                                        selectedId={data.StudentStatus}
                                        refClassName={``}
                                        withDescription={false}
                                        // description={`Please select your gender.`}
                                        refDisabled={true}
                                        refIsStatic={true}
                                        refStaticData={[
                                            { id: 1, label: 'New Student' },
                                            { id: 2, label: 'Returnee' },
                                            { id: 3, label: 'Cross Enrollee' },
                                            { id: 4, label: 'Eteapp' },
                                            { id: 5, label: 'Transferee' },
                                            { id: 7, label: 'Irregular' },
                                            { id: 8, label: 'Masteral' },
                                        ]
                                        }
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}
                                        style={{
                                            width: '100%'
                                        }}

                                    />

                                </div>
                                <hr />
                                <p>Contact Details:</p>
                                <p className="m-0 p-0 d-block">{` ${student[0].CelNo == null ? '' : student[0].CelNo}`}</p>
                                <p className="m-0 p-0 d-block">{` ${student[0].EmailAddress == null ? '' : student[0].EmailAddress}`}</p>
                            </div>


                        </div>
                        <div className="col-12 col-xl-8">
                            <div className="p-5  ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Educational Background</h3>
                                <p className="mt-5 mb-0 ">Preschool:  <span className="my-0  float-right"> {student[0].PreSchool}</span></p>
                                <p className=" mb-0  ">Primary:  <span className="my-0 float-right"> {student[0].Elementary}</span></p>
                                <p className=" mb-0  ">Junior High School:  <span className="my-0 float-right"> {student[0].JuniorHigh}</span></p>
                                <p className=" mb-0  ">Senior High School:  <span className="my-0 float-right"> {student[0].SeniorHigh}</span></p>

                            </div>
                            <div className="p-5 mt-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Family Background</h3>
                                <p className="mt-5 mb-0  ">Father's Name:  <span className="my-0 float-right"> {familybackground.length > 0 ? familybackground[0].FatherName : 'N/A'}</span></p>
                                <p className="mb-0  ">Father's Occupation:  <span className="my-0 float-right"> {familybackground.length > 0 ? familybackground[0].FatherBusinessName : 'N/A'}</span></p>
                                <p className="mb-0  ">Father's Contact Number:  <span className="my-0 float-right"> {familybackground.length > 0 ? familybackground[0].FatherBusinessContactNo : 'N/A'}</span></p>
                                <p className="mt-5 mb-0  ">Mother's Name:  <span className="my-0 float-right"> {familybackground.length > 0 ? familybackground[0].MotherName : 'N/A'}</span></p>
                                <p className="mb-0  ">Mother's Occupation:  <span className="my-0 float-right"> {familybackground.length > 0 ? familybackground[0].MotherBusinessName : 'N/A'}</span></p>
                                <p className="mb-0  ">Mother's Contact Number:  <span className="my-0 float-right"> {familybackground.length > 0 ? familybackground[0].MotherBusinessContactNo : 'N/A'}</span></p>
                            </div>

                            {switchState.IsForReservation == true &&
                                <>
                                    <div className="p-5 mt-5 ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Preferred Course / School</h3>
                                        <p className="mb-0  ">Reserved Course:  <span className="my-0 float-right"> {studentPreferred[0].ReservedCourse}</span></p>
                                        <p className="mt-5 mb-0">Preferred Course 1:  <span className="my-0 float-right"> {studentPreferred[0].PreferredCourse1}</span></p>
                                        <p className="mb-0  ">Preferred Course 2:  <span className="my-0 float-right"> {studentPreferred[0].PreferredCourse2}</span></p>
                                        <p className="mt-5 mb-0">Preferred School 1:  <span className="my-0 float-right"> {studentPreferred[0].PreferredSchool1}</span></p>
                                        <p className="mb-0  ">Preferred School 2:  <span className="my-0 float-right"> {studentPreferred[0].PreferredSchool2}</span></p>
                                    </div>

                                </>


                            }

                            <div className="p-5 mt-5 ">
                                <h3 className="bg-secondary lead p-3 font-weight-bold">Student Scholarship Selection</h3>

                                {studentDiscount.length > 0 ?
                                    ' '
                                    :
                                    <>
                                        <FormControlLabel
                                            name="isActive"
                                            inputRef={register()}
                                            control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                                            label="Tag Scholarship"
                                        />

                                        {student[0].IsReserved == 0 &&
                                            <FormControlLabel
                                                name="IsForReservation"
                                                inputRef={register()}
                                                control={<Switch checked={switchState.IsForReservation} onChange={switchHandleChange('IsForReservation')} value={switchState.IsForReservation} />}
                                                label="For Reservation"
                                            />
                                        }






                                    </>

                                }

                                {allStudyTypes.map(data => {
                                    return (
                                        <>
                                            <div className="custom-control custom-radio">
                                                {student[0].StudyTypeId === data.StudyTypeId
                                                    ?
                                                    <input
                                                        disabled
                                                        defaultChecked
                                                        onClick={() => { studyTypeHandler(data.StudyTypeId) }}
                                                        type="radio"
                                                        id={data.StudyTypeId}
                                                        name="customRadio"
                                                        className="custom-control-input"
                                                    />
                                                    :
                                                    <input

                                                        onClick={() => { studyTypeHandler(data.StudyTypeId) }}
                                                        type="radio"
                                                        id={data.StudyTypeId}
                                                        name="customRadio"
                                                        className="custom-control-input"
                                                    />
                                                }
                                                <label className="custom-control-label" htmlFor={data.StudyTypeId}>{data.Description}</label>
                                            </div>
                                        </>
                                    )
                                })}

                                {switchState.IsActive === true ?
                                    <>
                                        <div className='my-5'>{imSelectDropdownScholarship()}</div>
                                        <FormControlLabel
                                            name="isPending"
                                            inputRef={register()}
                                            control={<Switch checked={switchState.isPending} onChange={switchHandleChange('isPending')} />}
                                            label="Is Pending"
                                        />
                                    </>

                                    : ''
                                }

                                {particularStudentDiscount !== undefined && taggedScholarship !== undefined ?
                                    <div className="input-group mt-5">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroupPrepend2">Scholarship</span>
                                        </div>
                                        <input value={taggedScholarship} type="text" className="form-control" id="validationDefaultUsername" placeholder="Username" aria-describedby="inputGroupPrepend2" />
                                    </div>
                                    : ''
                                }

                                {onLoadingVerify == false ?
                                    <>

                                        {student[0].IsVerified === 0 ?
                                            <>
                                                {studyType !== null ?
                                                    <button type="button" onClick={() => { onVerify() }} className="mt-5 btn btn-success float-right">Verify</button>
                                                    :
                                                    <button disabled type="button" onClick={() => { onVerify() }} className="mt-5 btn btn-secondary float-right">Verify</button>


                                                }
                                            </>
                                            : ''


                                        }
                                    </>

                                    :

                                    <>
                                        <button clasNames="btn btn-secondary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Verifying...
                                        </button>
                                    </>

                                }


                                {student[0].IsVerified === 1
                                    ?
                                    <>
                                        <div style={{ 'backgroundColor': 'rgba(34, 255, 145, 0.2)' }} className="alert text-center mt-5" role="alert">
                                            Student is Verified
                                        </div>
                                        {student[0].IsReserved == 0 &&
                                            <button type="button" onClick={() => { onUpdate() }} className="mt-5 btn btn-primary float-right">Save Changes</button>

                                        }
                                    </>


                                    : ''}

                            </div>
                        </div>
                    </div>
                </div>
            </div>

            :


            <>
                <div className="card p-5">
                    <Loading />
                </div>
            </>

        }




        </>
    )
}