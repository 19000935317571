import React, { useState, useEffect } from "react";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import {
    getScholarship, searchStudentScholarship, createStudentDiscount, updateStudentDiscount,
    createStudentHistory, getStudentSearchHistory, getEnrollmentAddsOn, getSemester, getSchoolYear,
    updateEnrollmentAddOn, deleteEnrollmentAddOn, createEnrollmentAddOn, getStudentLaboratoryComputation,
    deleteStudentDiscount, getScholarshipSelect, unTagScholarship, getStudentScholarHistory
} from "./__hooks__/index"
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import { TextField, FormControlLabel, Switch } from "@material-ui/core";
import moment from "moment";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import { Alert } from "bootstrap";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";


export default function ScholarshipTagging() {
    const { dispatch, state: { auth } } = useAppContext();
    const CurrentDate = moment().format();
    const [totalRecords, setTotalRecords] = useState()

    const [student, setStudent] = useState(null)
    const activeSem = auth.data.schoolyears.admissions.SemesterId
    const activeSy = auth.data.schoolyears.admissions.SchoolYear
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [semesterSelect, setSemesterSelect] = useState([])
    const [enrollmentAccount, setEnrollmentAccount] = useState(null)
    const [newEnrollmentAccount, setNewEnrollmentAccount] = useState(null)
    const [selectedScholarship, setSelectedScholarship] = useState(null)
    const [studentNo, setStudentNo] = useState()
    const [astraLabFees, setAstraLabFees] = useState()
    const [remarks, setRemarks] = useState()
    const [onSearch, setOnSearch] = useState(false);
    const [keyword, setKeyword] = useState()
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [historyResults, setHistoryResults] = useState([])
    const [studentDiscount, setStudentDiscount] = useState([])
    const { setValue, handleSubmit, register } = useForm();
    const [scholarshipRef, setScholarshipRef] = useState([])
    const [recordPage, setRecordPage] = useState(0)
    let getEnrollmentAccountQuery = "{ where: `WHERE StudentId = ${student.StudentId} AND SemesterId = '${activeSem.SemesterId}' AND SchoolYear = ${activeSy.SchoolYear}` }"

    const [data, setData] = useState({ ScholarshipId: null, Scholarship: null, SchoolYear: activeSy, SemesterId: activeSem })
    const [switchState, setSwitchState] = useState({
        isPending: student !== null ? student.IsPending === 1 ? true : false : false,
        isActive: student !== null ? student.IsActive === 1 ? true : false : false,
        isNone: student !== null ? student.ScholarshipDesc !== undefined || student.ScholarshipDesc !== null ? true : false : false
    });

    const getStudentSearchHistoryQuery = "{ where: `WHERE StudentChangeHistoryId IS NOT NULL AND StudentId = ${student.StudentId}`, pagination: `LIMIT ${rowsPerPage} OFFSET ${recordPage}`}"

    const selectHandleChange = name => event => {
        console.log(scholarshipRef.find(element => element.tableId == event))
        let scholarshipDescription
        if (name === 'ScholarshipId') {
            scholarshipDescription = scholarshipRef.find(element => element.tableId == event)
            console.log(scholarshipRef)
            setData({ ...data, [name]: event, 'Scholarship': scholarshipDescription.code });

            if (event === 0) {
                setSwitchState({ ...switchState, isNone: true })
            } else {
                setSwitchState({ ...switchState, isNone: false })

            }

        } else {
            setData({ ...data, [name]: event });

        }

    }

    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data[0])
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }
    const switchHandleChange = name => event => {

        setSwitchState({ ...switchState, [name]: event.target.checked });

    };


    const onCancelSearch = () => {
        setStudent(null)
        setStudentNo("")
    }


    const onSubmitSearch = (formValues) => {
        let val = {
            ...formValues,
            semesterId: data.SemesterId,
            schoolYear: data.SchoolYear
        }
        executeSearchStudent(val)
    }


    const onSubmitUpdatedScholarship = (formValues) => {
        let dataStorer = {
            ...formValues,
            studentDiscountId: student.StudentDiscountId,
            scholarshipId: data.ScholarshipId,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            isPending: switchState.isPending === true ? 1 : 0,
            isActive: switchState.isActive === true ? 1 : 0,
            studentId: student.StudentId,
            remarks: remarks,
            createdBy: auth.data.Username
        }

        let studentHis
        if (data.ScholarshipId !== null && switchState.isPending == student.IsPending) {
            studentHis = {
                studentId: student.StudentId,
                description: `From ${student.ScholarshipDesc} to ${data.Scholarship} `,
                createdBy: auth.data.Username,
                dateCreated: CurrentDate,
                remarks: remarks
            }
        } else if (data.ScholarshipId !== null && switchState.isPending != student.IsPending) {
            studentHis = {
                studentId: student.StudentId,
                description: `From ${student.ScholarshipDesc} to ${data.Scholarship}; Pending from ${student.IsPending === 1 ? true : false} to ${switchState.isPending}`,
                createdBy: auth.data.Username,
                dateCreated: CurrentDate,
                remarks: remarks

            }
        } else if (data.ScholarshipId === null && switchState.isPending != student.IsPending) {
            studentHis = {
                studentId: student.StudentId,
                description: `Pending from ${student.IsPending === 1 ? true : false} to ${switchState.isPending}`,
                createdBy: auth.data.Username,
                dateCreated: CurrentDate,
                remarks: remarks

            }
        } else if (data.ScholarshipId === null && switchState.isPending == student.IsPending) {
            studentHis = {
                studentId: student.StudentId,
                description: ``,
                createdBy: auth.data.Username,
                dateCreated: CurrentDate,
                remarks: remarks

            }
        }

        console.log(dataStorer)


        createStudentScholarship(dataStorer)
        onCreateStudentHistory(studentHis)

        if (enrollmentAccount !== null && enrollmentAccount.length > 0 && newEnrollmentAccount !== null && newEnrollmentAccount.length > 0 && (student.EnrollmentStatusId === 'PV' || student.EnrollmentStatusId === 'E')) {
            for (let i = 0; i < enrollmentAccount.length; i++) {
                onDeleteEnrollmentAccount(enrollmentAccount[i].EnrollmentAccountId)
            }
        }

        if (newEnrollmentAccount !== null && newEnrollmentAccount.length > 0 && (student.EnrollmentStatusId === 'PV' || student.EnrollmentStatusId === 'E')) {
            for (let i = 0; i < newEnrollmentAccount.length; i++) {

                let newData = {

                    studentId: student.StudentId,
                    schoolYear: activeSy.SchoolYear,
                    semesterId: activeSem.SemesterId,
                    feeId: newEnrollmentAccount[i].FeeId,
                    feeGroupId: newEnrollmentAccount[i].FeeGroupId,
                    amount: parseFloat(newEnrollmentAccount[i].Amount),
                    //     enrollmentId: enrollment[0].EnrollmentId,
                    dateCreated: CurrentDate,
                    createdBy: auth.data.Username


                }


                onCreateEnrollmentAccount(newData)

            }
        }

        if (switchState.isNone === true) {
            onDeleteStudentDiscount(student.StudentDiscountId)
        }


        setHistoryResults([])
        onGetStudentHistory(getStudentSearchHistoryQuery)
        setStudent(null)

    }


    const getScholarshipExe = async () => {
        await getScholarshipSelect({ test: 1 })
            .then(res => {
                setScholarshipRef(res.data)
            })
            .catch(e => {
            })
    }

    const executeSearchStudent = async (val) => {
        await searchStudentScholarship(val)
            .then(res => {
                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found'
                    });
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }


    const createStudentScholarship = async (val) => {
        await createStudentDiscount(val)
            .then(res => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'success',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Scholarship Status Created'
                });
            })
            .catch(e => {

            })
    }

    const onCreateStudentHistory = async (val) => {
        await createStudentHistory(val)
            .then(res => {

            })
            .catch(e => {

            })
    }

    const onCreateEnrollmentAccount = async (val) => {
        await createEnrollmentAddOn(val)
            .then(res => {

            })
            .catch(e => {

            })
    }

    const updateStudentScholarship = async (id, val) => {
        await updateStudentDiscount(id, val)
            .then(res => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'success',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Scholarship Status Updated'
                });
            })
            .catch(e => {

            })
    }

    // const onGetStudentHistory = async (val) => {
    //     let obj = eval("(" + val + ")");
    //     await getStudentSearchHistory(obj)
    //         .then(res => {
    //             if (res.success) {
    //                 setTotalRecords(res.totalRecords)
    //                 setHistoryResults(res.data)
    //             }

    //         })
    //         .catch(e => {

    //         })
    // }

    const onGetStudentHistory = async (val) => {
        await getStudentScholarHistory(val)
            .then(res => {
                if (res.success) {
                    setTotalRecords(res.totalRecords)
                    setHistoryResults(res.data)
                }

            })
            .catch(e => {

            })
    }

    const onGetEnrollmentAccount = async (val) => {
        let obj = eval("(" + val + ")");
        await getEnrollmentAddsOn(obj)
            .then(res => {
                setEnrollmentAccount(res.data)
            })
            .catch(e => {

            })
    }


    const onDeleteEnrollmentAccount = async (id) => {
        await deleteEnrollmentAddOn(id)
            .then(res => {

            })
            .catch(e => {

            })
    }

    const onDeleteStudentDiscount = async (id) => {
        await deleteStudentDiscount(id)
            .then(res => {

            })
            .catch(e => {

            })
    }


    const createAstraOtherLabFee = async (values) => {
        await getStudentLaboratoryComputation(values)
            .then(res => {
                setAstraLabFees(res)
            })
            .catch(e => {
                console.log(e)
            })
    }

    const onBack = () => {
        setStudent(null)
        setStudentNo()
    }

    const handleChangeOnSearch = (event) => {
        console.log(event)
        setOnSearch(event)

    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);

    };

    const handleChangePage = (event, newPage) => {
        console.log(event)
        console.log(newPage)
        let pageInserted = `${newPage}0`
        console.log(pageInserted)
        let pageConv = parseInt(pageInserted)
        setRecordPage(pageInserted)
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };


    const onUntagScholarship = async (id) => {
        await unTagScholarship({ studentId: id, schoolYear: data.SchoolYear, semesterId: data.SemesterId })
            .then(async res => {
                let studentHis = {
                    studentId: student.StudentId,
                    description: `UnTag ${student.ScholarshipDesc}`,
                    createdBy: auth.data.Username,
                    dateCreated: CurrentDate,
                    remarks: remarks

                }
                await onCreateStudentHistory(studentHis)
                    .then(resp => {
                        setHistoryResults([])
                        onGetStudentHistory(getStudentSearchHistoryQuery)
                        setStudent(null)
                    })


            })
    }

    useEffect(() => {
        getScholarshipExe()
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')

    }, [])

    // useEffect(() => {
    //     if (student !== null) {

    //         onGetStudentHistory({studentId: student.StudentId, studentDiscountId: student.StudentDiscountId})
    //     }
    // }, [recordPage, rowsPerPage, student])
    useEffect(() => {
        if (student !== null) {
            console.log(student.StudentDiscountId)
            setSwitchState({ isPending: student.IsPending === 1 ? true : false, isActive: student.IsActive === 1 ? true : false })
            setRemarks(student.Remarks)
            setData({ ...data, ScholarshipId: student.ScholarshipId })
            onGetStudentHistory({ studentId: student.StudentId, studentDiscountId: student.StudentDiscountId })

        }
    }, [student])

    useEffect(() => {
        let query = "{where:`WHERE ScholarshipId = ${data.ScholarshipId}`}"
        Query(getScholarship, query, setSelectedScholarship)
    }, [data.ScholarshipId])

    useEffect(() => {
        if (enrollmentAccount !== null && enrollmentAccount.length > 0 && selectedScholarship !== null && selectedScholarship !== undefined) {
            let isLab = false
            let newEnrollmentAccArr = []
            let duplicateData = []

            console.log(student.ApplyTo)

            if (selectedScholarship.ApplyTo === 'ML' || selectedScholarship.ApplyTo === 'TL' || selectedScholarship.ApplyTo === 'TB' || selectedScholarship.ApplyTo === 'TM') {
                for (let i = 0; i < enrollmentAccount.length; i++) {
                    if (selectedScholarship.ApplyTo === 'TB') {
                        isLab = true
                        if (parseFloat(enrollmentAccount[i].Amount) > 0) {
                            console.log(selectedScholarship.ApplyTo)
                            if (enrollmentAccount[i].FeeCode === 'TF' ||
                                enrollmentAccount[i].FeeCode === 'TT') {
                                isLab = true
                                let interestRate = parseFloat(selectedScholarship.ApplyRate) / 100
                                let principal = parseFloat(enrollmentAccount[i].Amount)
                                let discount = principal * interestRate
                                let newObj = {
                                    ...enrollmentAccount[i],
                                    Amount: -Math.abs(discount)
                                }
                                newEnrollmentAccArr.push(newObj)
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            } else {
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            }


                        }
                    }

                    if (selectedScholarship.ApplyTo === 'ML') {
                        isLab = true

                        if (parseFloat(enrollmentAccount[i].Amount) > 0) {
                            console.log(selectedScholarship.ApplyTo)
                            if (enrollmentAccount[i].FeeCode === 'MS') {
                                isLab = true
                                let interestRate = parseFloat(selectedScholarship.ApplyRate) / 100
                                let principal = parseFloat(enrollmentAccount[i].Amount)
                                let discount = principal * interestRate
                                let newObj = {
                                    ...enrollmentAccount[i],
                                    Amount: -Math.abs(discount)
                                }
                                newEnrollmentAccArr.push(newObj)
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            } else {
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            }


                        }
                    }

                    if (selectedScholarship.ApplyTo === 'TL') {
                        isLab = true
                        if (parseFloat(enrollmentAccount[i].Amount) > 0) {
                            console.log(selectedScholarship.ApplyTo)
                            if (enrollmentAccount[i].FeeCode === 'TF' ||
                                enrollmentAccount[i].FeeCode === 'TT' ||
                                enrollmentAccount[i].FeeCode === 'MS') {
                                isLab = true
                                let interestRate = parseFloat(selectedScholarship.ApplyRate) / 100
                                let principal = parseFloat(enrollmentAccount[i].Amount)
                                let discount = principal * interestRate
                                let newObj = {
                                    ...enrollmentAccount[i],
                                    Amount: -Math.abs(discount)
                                }
                                newEnrollmentAccArr.push(newObj)
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            } else {
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            }


                        }
                    }

                    if (selectedScholarship.ApplyTo === 'TM') {
                        if (parseFloat(enrollmentAccount[i].Amount) > 0) {
                            console.log(selectedScholarship.ApplyTo)
                            if (enrollmentAccount[i].FeeCode === 'TF' ||
                                enrollmentAccount[i].FeeCode === 'TT' ||
                                enrollmentAccount[i].FeeCode === 'MS') {
                                isLab = true
                                let interestRate = parseFloat(selectedScholarship.ApplyRate) / 100
                                let principal = parseFloat(enrollmentAccount[i].Amount)
                                let discount = principal * interestRate
                                let newObj = {
                                    ...enrollmentAccount[i],
                                    Amount: -Math.abs(discount)
                                }
                                newEnrollmentAccArr.push(newObj)
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            } else {
                                newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                            }


                        }
                    }

                }

            } else if (selectedScholarship.ApplyTo === 'TF' || selectedScholarship.ApplyTo === 'TT' || selectedScholarship.ApplyTo === 'MS' || selectedScholarship.ApplyTo === 'RG') {
                Alert("ASDASD")
                for (let i = 0; i < enrollmentAccount.length; i++) {
                    if (parseFloat(enrollmentAccount[i].Amount) > 0) {
                        console.log(selectedScholarship.ApplyTo)
                        //LABORATORY
                        if (enrollmentAccount[i].FeeCode === selectedScholarship.ApplyTo) {
                            let interestRate = parseFloat(selectedScholarship.ApplyRate) / 100
                            let principal = parseFloat(enrollmentAccount[i].Amount)
                            let discount = principal * interestRate
                            let newObj = {
                                ...enrollmentAccount[i],
                                Amount: -Math.abs(discount)
                            }
                            newEnrollmentAccArr.push(newObj)
                            newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                        } else {
                            newEnrollmentAccArr.push({ ...enrollmentAccount[i] })

                        }
                    }
                }

            }


            if (isLab === true) {
                if (astraLabFees !== undefined && astraLabFees.length > 0) {
                    for (let i = 0; i < enrollmentAccount.length; i++) {
                        if (enrollmentAccount[i].FeeId == 108 && parseFloat(enrollmentAccount[i].Amount) > 0) {

                            let totalOtherFee = 0
                            for (let i = 0; i < astraLabFees.length; i++) {
                                totalOtherFee += (parseFloat(astraLabFees[i].Amount))
                            }
                            let interestRate = parseFloat(selectedScholarship.ApplyRate) / 100
                            let principal = parseFloat(totalOtherFee)
                            let discount = principal * interestRate

                            let newObj = {
                                ...enrollmentAccount[i],
                                Amount: -Math.abs(discount)
                            }
                            newEnrollmentAccArr.push(newObj)
                        }

                    }

                }
            }



            console.log(duplicateData)

            setNewEnrollmentAccount(newEnrollmentAccArr)
        }
    }, [enrollmentAccount, selectedScholarship, astraLabFees, data])


    useEffect(() => {
        if (student !== null && activeSem !== null && activeSy !== null) {
            let getEnrollmentAccountQuery = "{ where: `WHERE StudentId = ${student.StudentId} AND SemesterId = '${activeSem.SemesterId}' AND SchoolYear = ${activeSy.SchoolYear}` }"
            let astraLabFeesQuery = {
                assessmentTemplateId: student.AssessmentTemplateId,
                studentId: student.StudentId,
                semesterId: activeSem.SemesterId,
                schoolYear: activeSy.SchoolYear
            }
            onGetEnrollmentAccount(getEnrollmentAccountQuery)

            createAstraOtherLabFee(astraLabFeesQuery)
        }
    }, [student, activeSem, activeSy, data])


    const imSelectDropdownScholarship = () => {

        return <IMSelect
            data={scholarshipRef}
            onHandleChange={selectHandleChange('ScholarshipId')}
            refClassContainer=""
            name="ScholarshipId"
            isRequired={true}
            withLabel={true}
            label="Choose Scholarship"
            placeHolder="Scholarship"
            forwardRef={setValue}
            selectedId={data.ScholarshipId === null ? student !== null ? student.ScholarshipId : "" : data.ScholarshipId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />

    }
    const imSelectDropdownSchoolYear = () => {

        return <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectHandleChange('SchoolYear')}
            refClassContainer=""
            name="SchoolYear"
            isRequired={true}
            withLabel={false}
            label="Choose School Year"
            placeHolder="School Year"
            forwardRef={setValue}
            selectedId={data.SchoolYear === null ? student !== null ? student.SchoolYear : activeSy : data.SchoolYear}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />

    }

    const imSelectDropdownSemester = () => {

        return <IMSelect
            data={semesterSelect}
            onHandleChange={selectHandleChange('SemesterId')}
            refClassContainer=""
            name="Semester"
            isRequired={true}
            withLabel={false}
            label="Choose Semester"
            placeHolder="Semester"
            forwardRef={setValue}
            selectedId={data.SemesterId === null ? student !== null ? student.SemesterId : activeSem : data.SemesterId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />

    }


    // const columns = [
    //     { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    //     { id: 'DateCreated', label: 'Date', align: 'left', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    //     { id: 'Description', label: 'Processed', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    //     { id: 'Remarks', label: 'Remarks', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    //     { id: 'CreatedBy', label: 'Created By', align: 'center', withSorting: true, hidden: false, disablePadding: false },

    // ]

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SchoolYear', label: 'School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SemesterId', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Scholarship', label: 'Scholarship', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'CreatedBy', label: 'Created By', align: 'center', withSorting: true, hidden: false, disablePadding: false },

    ]

    const tableProps = {
        recordId: 'StudentChangeHistoryId',
        sortField: '',
        columns: columns,
        rows: historyResults,
        totalRecords: totalRecords === undefined ? historyResults.length : totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'scholarshipHistory',
        parentId: null,
        tableTitle: '',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: '',
        onIsLastUpdate: false,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch,


    }

    console.log(student)
    console.log(scholarshipRef)
    console.log(data)
    console.log(remarks)

    console.log(historyResults)
    console.log(enrollmentAccount)
    console.log(newEnrollmentAccount)

    console.log(astraLabFees)
    console.log(selectedScholarship)

    console.log(auth)
    console.log(switchState.isNone)

    console.log(scholarshipRef)

    return (
        <>
            {scholarshipRef.length > 0 && schoolYearSelect.length > 0 && semesterSelect.length > 0 &&
                <div className="container">
                    <div className="card">


                        <div className="row container my-5">
                            <div className="col-12 col-xl-4  border-right border-primary">
                                <div className=" p-5">
                                    {student !== null ?
                                        <>
                                            <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                            <p className="my-0  ">{student.CourseDesc}</p>
                                        </>

                                        :

                                        <>
                                            <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                            {/* //<p className="my-0  ">Student Course</p> */}
                                        </>

                                    }


                                    <hr />

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>



                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={`${data.SchoolYear} - ${data.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                        </div>
                                        {student !== null ?
                                            <>
                                                <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "Old Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                            </>

                                            :
                                            <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                        }
                                    </div>
                                    <hr />


                                </div>


                            </div>
                            <div className="col-12 col-xl-8">

                                {student !== null ?
                                    <>
                                        <ul className="nav nav-tabs" id="myTab" role="tablist">
                                            <li className="nav-item">
                                                <a className="nav-link active" id="home-tab" data-toggle="tab" href="#home" role="tab" aria-controls="home" aria-selected="true">Tagging</a>
                                            </li>
                                            <li className="nav-item">
                                                <a className="nav-link" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">History</a>
                                            </li>

                                        </ul>

                                    </>

                                    :

                                    <>

                                    </>

                                }

                                <div className="p-5 mb-5 ">
                                    {student === null ?

                                        <form onSubmit={handleSubmit(onSubmitSearch)} method="post">
                                            <h3 className="bg-secondary lead p-3 font-weight-bold">Student Search</h3>

                                            <TextField
                                                id="outlined-basic"
                                                label="Student No"
                                                variant="outlined"
                                                name="studentNo"
                                                value={studentNo}
                                                inputRef={register({
                                                    required: true
                                                })}
                                                className="bg-white mt-4"
                                                size="small"
                                                fullWidth
                                                onChange={(e) => {
                                                    setStudentNo(e.target.value)
                                                }}
                                            />
                                            <div className='my-5'>{imSelectDropdownSchoolYear()}</div>
                                            <div className='my-5'>{imSelectDropdownSemester()}</div>

                                            <button type="submit" className="mt-3 btn btn-primary">Search</button>
                                        </form>

                                        :
                                        ""
                                    }



                                    {student !== null ?


                                        <>
                                            <div class="tab-content" id="myTabContent">
                                                <div class="tab-pane fade show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                                                    <h3 className="bg-secondary lead p-3 font-weight-bold">Student Scholarship Selection</h3>


                                                    <div className='my-5'>{imSelectDropdownScholarship()}</div>


                                                    {/* // <h4 className="lead my-5">Tag Scholarship:  <span className="font-weight-bold text-primary lead">{student.ScholarshipDesc}</span> </h4> */}


                                                    <form onSubmit={handleSubmit(onSubmitUpdatedScholarship)} method="post">

                                                        <div className="form-group">
                                                            <label htmlFor="exampleFormControlTextarea1">Remarks</label>
                                                            <textarea
                                                                name="remarks"
                                                                className="form-control"
                                                                id="exampleFormControlTextarea1"
                                                                onChange={e => setRemarks(e.target.value)}
                                                                rows="3">{student.Remarks}</textarea>
                                                        </div>




                                                        <FormControlLabel
                                                            name="isPending"
                                                            inputRef={register()}
                                                            control={<Switch checked={switchState.isPending} onChange={switchHandleChange('isPending')} />}
                                                            label="Is Pending"
                                                        />


                                                        <FormControlLabel
                                                            name="isActive"
                                                            inputRef={register()}
                                                            control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} />}
                                                            label="Is Active"
                                                        />
                                                        {/* {auth.data.UserType === "ACC" &&
                                                            <FormControlLabel
                                                                name="isNone"
                                                                inputRef={register()}
                                                                control={<Switch checked={switchState.isNone} onChange={switchHandleChange('isNone')} />}
                                                                label="NONE"
                                                            />
                                                        } */}

                                                        <div className="d-block">

                                                            <button type="submit" className="btn btn-primary ">Tag Scholarship</button>
                                                            <button onClick={() => { onUntagScholarship(student.StudentId) }} type='button' className="btn btn-danger ml-5">Untag Scholarship</button>



                                                            <button type="button" onClick={() => { onBack() }} className="btn btn-secondary ml-5">Tag Another Student</button>
                                                        </div>


                                                    </form>



                                                </div>
                                                <div class="tab-pane fade" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                                                    <IMTableCustom

                                                        tableProps={tableProps} />



                                                </div>

                                            </div>




                                        </>
                                        :
                                        <>

                                        </>

                                    }



                                </div>


                            </div>
                        </div>
                    </div>
                </div>

            }

        </>
    )
}