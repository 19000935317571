export const referenceTypes = {
    SELECTED_FEE_GROUP: 'SELECTED-FEE-GROUP',
    GET_FEE_GROUPS: 'GET-FEE-GROUPS',
    GET_FEE_GROUP: 'GET-FEE-GROUP',
    CREATE: 'CREATE-FEE-GROUP',
    UPDATE: 'UPDATE-FEE-GROUP',
    DELETE: 'DELETE-FEE-GROUP',
    LIST_LINK: '/admin/references/fee-groups',
    CREATE_LINK: '/admin/references/create-fee-groups/',
    UPDATE_LINK: '/admin/references/update-fee-groups/',
    DELETE_LINK: '/admin/references/delete-fee-groups/'
}