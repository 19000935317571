import React, { useState, useEffect } from "react";
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import {
    getGradeSubmissionStatus, getGradeSubmissionStatusByGradingPeriod
} from "./__hooks__/index";
import moment from "moment";
import SummaryOfGradesConfirmation from "../../../../../_metronic/layout/components/custom/modal/SummaryOfGradesConfirmation";

export default function GradeSubmissionMonitoringExcelFormat({ fileName, btnName, schoolYear, 
    semesterId, gradingPeriodId, instituteId, schoolYearDisplay, semesterDisplay, gradingPeriodDisplay, hasIG }) {

    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const [extractReport, setExtractReport] = useState(null);
    const [excelFormat, setExcelFormat] = useState(null); 
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [onAlert, setOnAlert] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isForConfirmation, setIsForConfirmation] = useState(false);
    const [collegeName, setCollegeName] = useState('');
    const currDate = moment().format('YYYYMMDD');
    let rowStart = 5;
        
    const handleExtractClick = () => {
        if((instituteId === null || instituteId == "" || instituteId == 90)){
            setIsForConfirmation(true);
        } else {
            searchForGrades();
        }
    };
    
    const searchForGrades = async () => {
        const newArray = [];
        setIsLoading(true);

        let values = {
            schoolYear: schoolYear,
            semesterId: semesterId,
            instituteId: instituteId,
            gradingPeriodId: gradingPeriodId,
            hasIG: hasIG
        };
    
        if(gradingPeriodId == 0){
            await getGradeSubmissionStatus(values)
                .then(result => {
                    if (result.success) {
                        if(result.data.length > 0){
                            setExtractReport(result.data);
                        }

                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    //history.push('/auth/login');
                  } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                  }
                });
        } else {
            await getGradeSubmissionStatusByGradingPeriod(values)
                .then(result => {
                    if (result.success) {
                        if(result.data.length > 0){
                            setExtractReport(result.data);
                        }
                    
                    } else {
                      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
                    }
                })
                .catch(error => {
                  if (error.response.status === 401) {
                    //history.push('/auth/login');
                  } else {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                  }
                });
        }
          
        
        //setExtractReport(newArray[0]);
        setIsLoading(false);
    }

    //useEffect when extracReport is updated
    useEffect(() => {
        let arr = [];
        if (extractReport !== null && typeof(extractReport) !== "undefined") {
            if((instituteId === null || instituteId == "" || instituteId == 90)){
                setCollegeName("ALL");
            } else {
                setCollegeName(extractReport[0].Institute);
            }

            if(gradingPeriodId == 0){
                for (let i = 0; i < extractReport.length; i++) { // student loop
                    arr.push({
                        '#': i+1,
                        'FacultyName': `${extractReport[i].EmployeeName}`,
                        'ClassCode': `${extractReport[i].ClassCode}`,
                        'Institute': `${extractReport[i].Institute}`,
                        'SubjectCode': `${extractReport[i].SubjectCode}`,
                        'Section': `${extractReport[i].ClassSections}`,
                        'Description': `${extractReport[i].Description}`,
                        'Units': `${extractReport[i].Units}`,
                        'PostingStatusPrelim': `${extractReport[i].PostingStatusPrelim}`,
                        'PostingStatusMidterm': `${extractReport[i].PostingStatusMidterm}`,
                        'PostingStatusFinals': `${extractReport[i].PostingStatusFinals}`
                    });
                }
            } else {
                for (let i = 0; i < extractReport.length; i++) { // student loop
                    arr.push({
                        '#': i+1,
                        'FacultyName': `${extractReport[i].EmployeeName}`,
                        'ClassCode': `${extractReport[i].ClassCode}`,
                        'Institute': `${extractReport[i].Institute}`,
                        'SubjectCode': `${extractReport[i].SubjectCode}`,
                        'Section': `${extractReport[i].ClassSections}`,
                        'Description': `${extractReport[i].Description}`,
                        'Units': `${extractReport[i].Units}`,
                        'DateSubmittedForValidation': `${extractReport[i].DateSubmittedForValidation}`,
                        'DateValidated': `${extractReport[i].DateValidated}`,
                        'DateAuthenticated': `${extractReport[i].DateAuthenticated}`,
                        'DatePosted': `${extractReport[i].DatePosted}`,
                        'GradePostingStatus': `${extractReport[i].GradePostingStatus}`
                    });
                }
            }
            //console.log("ARRAY: ", arr);
            setExcelFormat(arr);
        }
    }, [extractReport]);

    useEffect(() => {
        if (excelFormat !== null && typeof(excelFormat) !== "undefined") {
            exportToExcel();
        }
    }, [excelFormat]);

    const exportToExcel = () => {

        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const columns = "ABCDEFGHIJKLM"; //string of spreadsheet columns from A to M
        const columnsLength = gradingPeriodId == 0 ? columns.length - 2 : columns.length;

        var currentdate = new Date(); 
        var datetime = "as of " 
            + month[currentdate.getMonth()] + ' ' 
            + currentdate.getDate() + ', ' 
            + currentdate.getFullYear() + ' ' 
            + currentdate.getHours() + ":" 
            + currentdate.getMinutes() + ":" 
            + currentdate.getSeconds();

        
        //report header names
        let cellHeaderNameA1 = 'Grade Submission Monitoring';
        let cellHeaderNameA2 = schoolYearDisplay + " - " + semesterDisplay;
        let cellHeaderNameA3 = gradingPeriodDisplay;
        let cellHeaderNameA5 = '#';
        let cellHeaderNameB5 = 'FACULTY NAME';
        let cellHeaderNameC5 = 'CLASS CODE';
        let cellHeaderNameD5 = 'INSTITUTE';
        let cellHeaderNameE5 = 'SUBJECT CODE';
        let cellHeaderNameF5 = 'SECTION';
        let cellHeaderNameG5 = 'DESCRIPTION';
        let cellHeaderNameH5 = 'UNITS';
        let cellHeaderNameI5 = gradingPeriodId == 0 ? 'PRELIM STATUS' : 'Date Submitted for Validation';
        let cellHeaderNameJ5 = gradingPeriodId == 0 ? 'MIDTERM STATUS' : 'Date Validated';
        let cellHeaderNameK5 = gradingPeriodId == 0 ? 'FINALS STATUS' : 'Date Authenticated';      
        let cellHeaderNameL5 = 'Date Posted';
        let cellHeaderNameM5 = 'Grade Posting Status';

        //Update all headers to upper cases
        worksheet.getCell('A5').value = cellHeaderNameA5;
        worksheet.getCell('B5').value = cellHeaderNameB5;
        worksheet.getCell('C5').value = cellHeaderNameC5;
        worksheet.getCell('D5').value = cellHeaderNameD5;
        worksheet.getCell('E5').value = cellHeaderNameE5;
        worksheet.getCell('F5').value = cellHeaderNameF5;
        worksheet.getCell('G5').value = cellHeaderNameG5;
        worksheet.getCell('H5').value = cellHeaderNameH5;
        worksheet.getCell('I5').value = cellHeaderNameI5;
        worksheet.getCell('J5').value = cellHeaderNameJ5;
        worksheet.getCell('K5').value = cellHeaderNameK5;

        if(gradingPeriodId != 0){
            worksheet.getCell('L5').value = cellHeaderNameL5;
            worksheet.getCell('M5').value = cellHeaderNameM5;
        }

        //Add fill and font style to every fourth row of A to M columns
        for(let i = 0; i < columnsLength; i++){
            worksheet.getCell(columns[i] + '5').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
            worksheet.getCell(columns[i] + '5').font = { bold: true, color: {argb:'ffffffff'} };
        }

        // Add data rows            
        // Add headers
        const headers = Object.keys(excelFormat[0]);
        let isInitialExec = true;

        excelFormat.forEach((data) => {
            const row = [];
            headers.forEach((header) => {
                row.push(data[header]);
            });
            
            worksheet.addRow(row);

            for(let i = 0; i < columnsLength; i++){
                worksheet.getCell(columns[i] + rowStart).border = {
                    top: {style:'thin', color: {argb:'FF000000'}},
                    left: {style:'thin', color: {argb:'FF000000'}},
                    bottom: {style:'thin', color: {argb:'FF000000'}},
                    right: {style:'thin', color: {argb:'FF000000'}}
                };
            }

            rowStart++;
        });


        // Adjust column widths based on content
        worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? (cell.value.toString().length + 3) : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        worksheet.getCell('A1').value = cellHeaderNameA1.toUpperCase();
        worksheet.getCell('A1').font = { bold: true, color: {argb:'ff000000'}, size: 20 };
        
        worksheet.getCell('A2').value = cellHeaderNameA2.toUpperCase();
        worksheet.getCell('A2').font = { bold: true, color: {argb:'ff000000'}, size: 15 };

        worksheet.getCell('A3').value = cellHeaderNameA3.toUpperCase();
        worksheet.getCell('A3').font = { bold: true, color: {argb:'ff000000'}, size: 15 };
        
        worksheet.getCell('D3').value = datetime;

        // Generate a blob from the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + "_" + currDate + "_" + gradingPeriodDisplay + "_" + collegeName + fileExtension);
        });
    };

    const afterConfirmation = () => {
        setIsForConfirmation(false);
        searchForGrades();
    };

    return (
        <>
            <SummaryOfGradesConfirmation
                isOpen={isForConfirmation}
                handleConfirm={() => afterConfirmation()}
                handleClose={() => setIsForConfirmation(false)}
                title={'Grade Submission Monitoring'}
                content={'The selected filters will list all available data. Would you like to proceed?'}
            />

            <button onClick={handleExtractClick} className="btn btn-success btn-block" disabled={isLoading ? "disabled" : ""}>
                {
                    isLoading ?
                    <>
                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                        &nbsp; Loading...
                    </>

                    :
                    
                    <>
                        <i className="fa fa-download"></i>
                        {btnName}
                    </>
                }
            </button>
            {/* <button className="btn btn-success btn-block">{btnName}</button> */}
        </>
    );
}
