import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import ChartForm from "./ChartForm"
import history from "../../../../history";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import {
    getAssessmentStudentSubjectScheduleDetail, getStudentCashieringAccount, getStudentFees, getStudentPayments,
    getStudentDiscount, getStudentCampusDiscount, getStudentAstraSubjects, getStudentListOfFees,
    getEnrollmentDiscount, getStudentAssessmentTemplateDetail, getStudentAssessmentOtherFees,
    getStudentDiscountParticulars, getStudentAddOns, updateEnrollment,
    addUpdateEnrollmentDiscount, getCashieringPaymentSchedule, getCashieringPaymentScheduleSOA,
    getCashieringPaymentDetailsSOA, getStudentDetailsSOA, getAvailableSOA
} from "./__hooks__/proc"
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";

export default function Chart(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const accountData = props.location.data
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const { id } = useParams()
    const [studentAccount, setStudentAccount] = useState(null)
    const [isCampus, setIsCampus] = useState(false)
    const [astraStudentEnrollmentSubjects, setAstraStudentEnrollmentSubjects] = useState(null)
    //const [discounts, setDiscounts] = useState({ tuitionFee: 0.00, regFee: 0.00, miscFee: 0.00, labFee: 0.00, otherFee: 0.00 })
    const [discounts, setDiscounts] = useState({ tuitionFee: 0.00, regFee: 0.00, miscFee: 0.00, labFee: 0.00, otherLabFee: 0.00, otherFee: 0.00 })
    const [studentDiscountWithSequence, setStudentDiscountWithSequence] = useState([])
    const [studentAddOns, setStudentAddOns] = useState(null)
    const [totalAddOns, setTotalAddOns] = useState(0)
    const [studentDiscountParticulars, setStudentDiscountParticulars] = useState(null)
    const [isRecomputed, setIsRecomputed] = useState(false)


    const [subjectCategoryIds, setSubjectCategoryIds] = useState([])

    const [studentTotalUnits, setStudentTotalUnits] = useState(0)
    const [studentLabModes, setStudentLabModes] = useState(1)
    const [studentFees, setStudentFees] = useState(null)
    const [studentOtherFees, setStudentOtherFees] = useState([])

    const [totalOtherFee, setTotalOtherFee] = useState(0)
    const [totalPayment, setTotalPayment] = useState(0)

    const [studentDiscount, setStudentDiscount] = useState([])
    const [payments, setPayments] = useState(null)
    const [backAccPayments, setBackAccPayments] = useState(null)
    const [isPending, setIsPending] = useState(false)
    const [totalFees, setTotalFees] = useState(0)
    const [totalAssessmentFee, setTotalAssessmentFee] = useState(0)
    const [totalDiscount, setTotalDiscount] = useState(0)
    const [totalInterest, setTotalInterest] = useState(0)
    const [labSubjects, setLabSubjects] = useState([])

    // For Payment Schdule
    const [studentSubjectCategoriesId, setStudentSubjectCategoriesId] = useState(null);
    const [studentLabModesPS, setStudentLabModesPS] = useState(1);
    const [studentFeesPS, setStudentFeesPS] = useState(null);
    const [paymentSchedule, setPaymentSchedule] = useState(null);
    const [paymentDetails, setPaymentDetails] = useState(null);
    const [totalFee, setTotalFee] = useState(null);
    const [isComputing, setIsComputing] = useState(false)


    const [studentAssessmentFees, setStudentAssessmentFees] = useState([])
    const [studentAccountSOA, setStudentAccountSOA] = useState(null)

    // useStates of back accounts
    const [studentAccountBack, setStudentAccountBack] = useState(null);
    const [astraStudentEnrollmentSubjectsBack, setAstraStudentEnrollmentSubjectsBack] = useState(null);
    const [studentTotalUnitsBack, setStudentTotalUnitsBack] = useState(0);
    const [studentLabModesBack, setStudentLabModesBack] = useState(1);
    const [subjectCategoryIdsBack, setSubjectCategoryIdsBack] = useState([]);
    const [totalAssessmentFeeBack, setTotalAssessmentFeeBack] = useState(0);
    const [totalOtherFeeBack, setTotalOtherFeeBack] = useState(0);
    const [totalAddOnsBack, setTotalAddOnsBack] = useState(0);
    const [studentFeesBack, setStudentFeesBack] = useState(null);
    const [studentDiscountBack, setStudentDiscountBack] = useState([]);
    const [labSubjectsBack, setLabSubjectsBack] = useState([]);
    const [totalInterestBack, setTotalInterestBack] = useState(null);
    const [totalDiscountBack, setTotalDiscountBack] = useState(null);
    const [totalPaymentBack, setTotalPaymentBack] = useState(null);
    const [totalFeesBack, setTotalFeesBack] = useState(null);
    const [isGettingBackAccount, setIsGettingBackAccount] = useState(false);
    const [listOfBackAccounts, setListOfBackAccounts] = useState([]);

    // variables for testing

    const [soaLoop, setSOALoop] = useState(0);
    const [soaArray, setSOAArray] = useState([]);



    if (accountData == null) {
        history.push("/admin/accounting/cashier")
    }


    const onRecompute = async () => {


        let enrollmentValues = {
            assessmentTemplateId: studentFees[0].AssessmentTemplateId,
            studentId: studentAccount[0].StudentId,
            assessmentDate: dateTime,
            // enrollmentDate: dateTime,
            // enrollmentStatusId: 'PV',
            paymentModeId: studentAccount[0].PaymentModeId,
            schoolYear: studentAccount[0].SchoolYear,
            semesterId: studentAccount[0].SemesterId,
            courseId: studentAccount[0].CourseId,

        }



        // console.log(enrollmentValues)

        //save enrollment
        let tuitionFee = studentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
        let registrationFee = studentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
        let miscFee = studentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
        let labFee = studentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
        let otherFee = studentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
        await updateEnrollment(studentAccount[0].EnrollmentId, enrollmentValues)
            .then(async response => {
                if (studentDiscount !== 0 && studentDiscount.length > 0) {


                    const updatedStudentDiscount = studentDiscountParticulars.map((obj, index) => ({
                        ...obj,
                        enrollmentId: studentAccount[0].EnrollmentId,
                        enrollmentDiscountId: index === 0 ? 1 : 0,
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: labFee.TotalGAmount,
                        createdBy: auth.data.Username
                    }));
                    // console.log(updatedStudentDiscount)
                    // console.log(tuitionFee)
                    await addUpdateEnrollmentDiscount(updatedStudentDiscount)
                } else {
                    await addUpdateEnrollmentDiscount([{
                        enrollmentDiscountId: 1,
                        enrollmentId: studentAccount[0].EnrollmentId,
                        DiscountAmount: 0,
                        TuitionDiscount: 0,
                        RegDiscount: 0,
                        MiscDiscount: 0,
                        OtherDiscount: 0,
                        LabDiscount: 0,
                        OtherLabDiscount: 0,
                        ApplyTo: 'TF',
                        ApplyRate: 100,
                        ApplyType: 'P',
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: labFee.TotalGAmount,
                        createdBy: auth.data.Username

                    }])
                }

            })
            .catch(error => {
                //  alert('enrollment did not update')
            })



        dispatch({
            type: componentTypes.SHOW_SNACKBAR,
            snackBarStatus: 'success',
            snackBarDuration: 5000,
            snackBarMessage: 'Student Discount Recomputed'
        });

        // history.push('/admin/accounting')
        // setTimeout(() => {
        //     history.push(`${accountTypes.MORE_LINK}${id}`)
        // }, 200);


        setIsRecomputed(true)


    }


    const exeStudentAssessmentSubjectScheduleDetail = async (val) => {
        await getAssessmentStudentSubjectScheduleDetail(val)
            .then(res => {
                // console.log(res)
                setLabSubjects(res.labSubjects)
                if (res.data.length > 0) {

                    if (res.subjectCategoryIds.length > 0) {
                        setSubjectCategoryIds(res.subjectCategoryIds)

                    } else {
                        setSubjectCategoryIds([1])
                    }
                    setStudentTotalUnits(res.totalUnits)
                    setStudentLabModes(res.labModes)

                    // For Payment Schedule:

                    let subCatIds = []
                    let totalUnits = 0
                    let totalLabModes = 1
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].SubjectCategoryId !== null) {
                            subCatIds.push(res.data[i].SubjectCategoryId)
                            totalLabModes += 1
                        }
                        totalUnits += parseInt(res.data[i].LecHours)
                        totalUnits += parseInt(res.data[i].LabHours)

                    }
                    //setLabSubjects(res.labSubjects)
                    setStudentSubjectCategoriesId(subCatIds)
                    //setStudentTotalUnits(res.totalUnits)
                    setStudentLabModesPS(totalLabModes)
                }
            })
            .catch(e => {

            })
    }

    const exeStudentCashieringAccount = async (val) => {
        await getStudentCashieringAccount(val)
            .then(res => {
                // console.log(res)
                setStudentAccount(res.data)
                if (res.data[0].IsCampus == 1) {
                    setIsCampus(false)
                } else {
                    setIsCampus(false)
                }
            })
            .catch(e => {

            })
    }

    const getStudentAccount = async (val) => {
        await getStudentDetailsSOA(val)
            .then(res => {
                if (res.data.length > 0) {
                    setStudentAccountSOA(res.data)
                } else {
                    history.push("/admin/accounting/cashier")
                }
            })
            .catch(e => {

            })
    }

    const exeGetStudentFees = async (val) => {
        await getStudentAssessmentTemplateDetail(val)
            .then(res => {
                setStudentAssessmentFees(res.data);
                let data = res.data.map(d => {
                    // console.log(d.FeeCode)
                    // console.log(d)

                    if (d.FeeCode == 'TF' || d.FeeCode == 'TT') {
                        return { ...d, TotalGAmount: res.totalTuitionFee };
                    } else if (d.FeeCode == 'RG') {
                        return { ...d, TotalGAmount: res.totalRegFee + res.totalOtherFee };
                    } else if (d.FeeCode == 'MS') {
                        return { ...d, TotalGAmount: res.totalMiscFee };
                    } else if (d.FeeCode == 'LB') {
                        return { ...d, TotalGAmount: d.TotalAmount };
                    } else if (d.FeeCode == 'OLB') {
                        return { ...d, TotalGAmount: res.totalOtherLabFee };
                    }
                    return d;
                });
                // console.log(data)
                setTotalAssessmentFee(res.totalFee)
                setTotalInterest(res.totalInterest)

                setStudentFees(data)
            })
            .catch(e => {

            })
    }


    const getStudentOtherFees = async (val) => {
        await getStudentAssessmentOtherFees(val)
            .then(res => {
                setStudentOtherFees(res.data.data)
                setTotalOtherFee(res.data.totalOtherFee)
            })
            .catch(e => {

            })
    }

    const exeGetStudentPayments = async (val) => {
        await getStudentPayments(val)
            .then(res => {
                setTotalPayment(res.totalPayment)
                setPayments(res.data)
            })
            .catch(e => {

            })
    }

    const exeGetStudentPaymentsBackAcc = async (val) => {
        await getStudentPayments(val)
            .then(res => {
                setBackAccPayments(res.data)
            })
            .catch(e => {

            })
    }


    const exeGetStudentDiscount = async (val) => {
        await getStudentDiscount(val)
            .then(res => {
                // console.log(res)
                setTotalDiscount(res.totalDiscount)
                setStudentDiscount(res.data)
                return res.data

            })
            .catch(e => {

            })

    }


    const getStudentAdds = async (val) => {
        await getStudentAddOns(val)
            .then(res => {
                // console.log(res)
                setTotalAddOns(res.totalAmount)
                setStudentAddOns(res.data)
            })
    }
    // Get Student Registered Subjects ----------------------------------------------------------------------------

    const getStudentSubjects = async (val) => {
        await getStudentAstraSubjects(val)
            .then(res => {
                let arr = []
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push(res.data[i].DetailId)
                    }


                }
                // console.log(arr)
                setAstraStudentEnrollmentSubjects(arr)
            })
            .catch(e => {

            })
    }

    const getStudentEnrollmentDiscount = async (val) => {
        await getEnrollmentDiscount(val)
            .then(res => {
                let list = []
                if (res.data.length > 0) {
                    setDiscounts({
                        tuitionFee: res.totalTuitionFeeDiscount,
                        regFee: res.totalRegFeeDiscount,
                        miscFee: res.totalMiscFeeDiscount,
                        otherFee: res.totalOtherFeeDiscount,
                        labFee: res.totalLabFeeDiscount,
                        otherLabFee: res.totalOtherLabFeeDiscount // for payment schedule

                    })

                }
            })
    }


    useEffect(() => {

        if (accountData !== undefined) {
            let studentAccountVal = {
                studentId: parseInt(id),
                semesterId: accountData.SemesterId,
                schoolYear: accountData.SchoolYear
            }
            let paymentVal = {
                studentId: parseInt(id),
                semesterId: accountData.SemesterId,
                schoolYear: accountData.SchoolYear,
                isBackAcct: false
            }
            let backPaymentVal = {
                studentId: parseInt(id),
                semesterId: accountData.SemesterId,
                schoolYear: accountData.SchoolYear,
                isBackAcct: true
            }
            exeStudentCashieringAccount(studentAccountVal)
            exeGetStudentPayments(paymentVal)
            exeGetStudentPaymentsBackAcc(backPaymentVal)
            getStudentAccount(studentAccountVal);
            execGetAvailableSOA();
        }

    }, [])


    useEffect(() => {
        if (studentAccount !== null) {
            getStudentSubjects({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear
            })

            getStudentEnrollmentDiscount({
                enrollmentId: studentAccount[0].EnrollmentId
            })

            getStudentOtherFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                yearLevelId: studentAccount[0].YearLevelId,
                courseId: studentAccount[0].CourseId,
                instituteId: studentAccount[0].InstituteId,
                isCampus: false,
                isMale: studentAccount[0].Gender == 'M' ? true : false
            })

            getStudentAdds({
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear
            })
        }
    }, [studentAccount])


    useEffect(() => {
        if (!astraStudentEnrollmentSubjects)
            return
        let subSchedDetlVal = {
            classSectionId: 0,
            semesterId: studentAccount[0].SemesterId,
            schoolYear: studentAccount[0].SchoolYear,
            isCampus: isCampus,
            batchId: studentAccount[0].CampusBatchId,
            isExist: true,
            subjectScheduleDetailId: astraStudentEnrollmentSubjects !== null ? astraStudentEnrollmentSubjects.toString() : null,
            studentId: studentAccount[0].StudentId
        }
        exeStudentAssessmentSubjectScheduleDetail(subSchedDetlVal)



    }, [astraStudentEnrollmentSubjects])


    useEffect(() => {

        if (studentAccount !== null && studentAccount.length > 0 && studentTotalUnits !== 0)

            exeGetStudentFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId.toString(),
                discountRate: studentAccount[0].ApplyRate !== null ? studentAccount[0].ApplyRate : 0.00,
                totalUnits: studentTotalUnits,
                discountApplyTo: studentAccount[0].ApplyTo !== null ? studentAccount[0].ApplyTo : 'NA',
                labMode: studentLabModes,
                subjectCategories: subjectCategoryIds.toString(),
                isSyncToCampus: false,
                labSubjects: labSubjects,
                studentSchoolYear: studentAccount[0].StudentBatchYear
            })


    }, [studentAccount, studentTotalUnits, studentLabModes, subjectCategoryIds])



    useEffect(() => {
        if (studentFees !== null) {
            let tuitionFee = studentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
            let registrationFee = studentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
            let miscFee = studentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
            let labFee = studentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
            let otherFee = studentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };

            exeGetStudentDiscount({
                studentId: studentAccount[0].StudentId,
                schoolYear: studentAccount[0].SchoolYear,
                semesterId: studentAccount[0].SemesterId,
                assessmentTemplateId: studentFees[0].AssessmentTemplateId.toString(),
                tuitionFee: tuitionFee.TotalAmount,
                regFee: registrationFee.TotalAmount,
                miscFee: miscFee.TotalAmount,
                labFee: labFee.TotalAmount,
                otherFee: otherFee.TotalAmount
            })

        }
    }, [studentFees]);

    // with no sequence
    // useEffect(() => {
    //     if (studentDiscount !== undefined && studentDiscount !== null && studentDiscount.length > 0) {
    //         console.log(studentDiscount)
    //         let tuitionFee = studentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
    //         let registrationFee = studentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
    //         let miscFee = studentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
    //         let labFee = studentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
    //         let otherFee = studentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
    //         const getParticulars = async () => {
    //             let data = []
    //             for (let i = 0; i < studentDiscount.length; i++) {
    //                 await getStudentDiscountParticulars({
    //                     studentId: studentAccount[0].StudentId,
    //                     assessmentTemplateId: studentFees[0].AssessmentTemplateId.toString(),
    //                     tuitionFee: tuitionFee.TotalAmount,
    //                     regFee: registrationFee.TotalAmount,
    //                     miscFee: miscFee.TotalAmount,
    //                     labFee: labFee.TotalAmount,
    //                     otherFee: otherFee.TotalAmount,
    //                     scholarshipId: studentDiscount[i].ScholarshipId
    //                 })
    //                     .then(res => {
    //                         data.push(res.data[0])
    //                     })
    //             }
    //             setStudentDiscountParticulars(data)
    //             console.log(data)
    //         }

    //         getParticulars()


    //     }
    // }, [studentDiscount])

    // with sequence
    useEffect(() => {
        if (studentDiscount !== 0 && studentDiscount.length > 0) {
            let tuitionFee = studentFees.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
            let registrationFee = studentFees.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
            let miscFee = studentFees.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
            let labFee = studentFees.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
            let otherFee = studentFees.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
            let otherLabFee = studentFees.find(data => data.FeeCode === 'OLB') || { TotalAmount: 0 };

            let data = []

            const getStudentDiscountWithParticulars = async (val) => {
                const res = await getStudentDiscountParticulars(val);
                // setStudentDiscountParticulars(res.data)
                setTotalDiscount(res.totalDiscount)
                return res;
            };

            const updateFees = async () => {
                let arr = [];
                let totalDiscount = 0.00
                for (let i = 0; i < studentDiscount.length; i++) {
                    const res = await getStudentDiscountWithParticulars({
                        studentId: studentAccount[0].StudentId,
                        assessmentTemplateId: studentFees[0].AssessmentTemplateId.toString(),
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: labFee.TotalGAmount,
                        otherFee: totalOtherFee,
                        otherLabFee: otherLabFee.TotalGAmount,
                        scholarshipId: studentDiscount[i].ScholarshipId
                    });
                    tuitionFee.TotalGAmount = res.data[0].RemainingTuition;
                    registrationFee.TotalGAmount = res.data[0].RemainingReg;
                    miscFee.TotalGAmount = res.data[0].RemainingMisc;
                    labFee.TotalGAmount = res.data[0].RemainingLab;
                    // console.log(res.data[0]);
                    arr.push({ ...res.data[0], enrollmentId: parseInt(id), enrollmentDiscountId: 0, createdBy: auth.data.Username });
                    totalDiscount += parseFloat(res.totalDiscount)
                }

                for (let i = 0; i < arr.length; i++) {
                    await getStudentDiscountParticulars({
                        studentId: studentAccount[0].StudentId,
                        assessmentTemplateId: studentFees[0].AssessmentTemplateId.toString(),
                        tuitionFee: tuitionFee.TotalAmount,
                        regFee: registrationFee.TotalAmount,
                        miscFee: miscFee.TotalAmount,
                        labFee: labFee.TotalAmount,
                        otherFee: otherFee.TotalAmount,
                        scholarshipId: arr[i].ScholarshipId
                    })
                        .then(res => {
                            data.push(res.data[0])
                        })
                }
                // console.log(data)
                setStudentDiscountWithSequence(arr);
                setStudentDiscountParticulars(arr)

                setTotalDiscount(totalDiscount)

            };

            updateFees();
        }
    }, [studentDiscount]);




    useEffect(() => {
        // console.log(totalAssessmentFee)
        // console.log(totalOtherFee)
        setTotalFees(parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalAddOns))
    }, [totalAssessmentFee, totalOtherFee, totalAddOns])


    /**** START: Codes to get Payment Due */

    useEffect(() => {
        if (studentAccount !== null && studentTotalUnits !== null && studentTotalUnits > 1) {
            exeGetStudentFees_PaymentSched({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId.toString(),
                discountRate: studentAccount[0].ApplyRate,
                totalUnits: studentTotalUnits,
                discountApplyTo: studentAccount[0].ApplyTo,
                labMode: studentLabModesPS,
                subjectCategoriesId: studentSubjectCategoriesId !== null && studentSubjectCategoriesId.length > 0 ? studentSubjectCategoriesId.toString() : null,
                isCampus: isCampus,
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                tuitionFeeDiscount: discounts.tuitionFee,
                regFeeDiscount: discounts.regFee,
                miscFeeDiscount: discounts.miscFee,
                otherFeeDiscount: discounts.otherFee,
                labFeeDiscount: discounts.labFee,
                otherLabFeeDiscount: discounts.otherLabFee,
                labSubjects: labSubjects,
                studentSchoolYear: studentAccount[0].StudentBatchYear

            })


            exeGetStudentOtherFees({
                assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                yearLevelId: studentAccount[0].YearLevelId,
                courseId: studentAccount[0].CourseId,
                instituteId: studentAccount[0].InstituteId,
                studentId: studentAccount[0].StudentId,
                semesterId: studentAccount[0].SemesterId,
                schoolYear: studentAccount[0].SchoolYear,
                otherFeeDiscount: discounts.otherFee,
                isMale: studentAccount[0].Gender == 'M' ? true : false

            })



        }

    }, [studentTotalUnits, studentLabModes, discounts])

    const exeGetStudentFees_PaymentSched = async (val) => {
        // console.log(val)
        setIsComputing(true)
        await getStudentListOfFees(val)
            .then(res => {
                if (res.data.length > 0) {

                    setStudentFeesPS(res.data)
                    getAvailablePaymentSchedule({
                        assessmentTemplateId: studentAccount[0].AssessmentTemplateId,
                        tuitionFee: res.totalFee,
                        regFee: 0,
                        miscFee: 0,
                        labFee: 0,
                        discount: discounts.labFee + discounts.miscFee + discounts.otherFee + discounts.regFee + discounts.tuitionFee,
                        interest: res.totalInterest,
                        studentId: studentAccount[0].StudentId,
                        schoolYear: studentAccount[0].SchoolYear,
                        semesterId: studentAccount[0].SemesterId
                    })

                    getAvailablePaymentDetails({
                        studentId: studentAccount[0].StudentId,
                        schoolYear: studentAccount[0].SchoolYear,
                        semesterId: studentAccount[0].SemesterId
                    })
                    setTotalFee(1)
                    // console.log(res.data)
                    // console.log(res)

                    setIsComputing(false)

                }

            })
            .catch(e => {

            })
    }

    const exeGetStudentOtherFees = async (val) => {
        await getStudentOtherFees(val)
            .then(res => {
                // console.log(res)
                setStudentOtherFees(res.data.data)
            })
            .catch(e => {

            })
    }

    const getAvailablePaymentSchedule = async (val) => {
        await getCashieringPaymentScheduleSOA(val)
            .then(res => {
                setPaymentSchedule(res.data)
            })
            .catch(e => {

            })
    }

    const getAvailablePaymentDetails = async (val) => {
        await getCashieringPaymentDetailsSOA(val)
            .then(res => {
                setPaymentDetails(res.data)
            })
            .catch(e => {

            })
    }

    /**** END: Codes to get Payment Due */


    /**** START: Codes to get Back Account */

    useEffect(() => {
        if(soaArray.length > 0){
            execBackAccounts();
        }
    }, [soaArray]);

    //BACK ACCOUNT
    const execBackAccounts = () => {
        if(soaArray.length > 0){
            if(soaArray.length > soaLoop){
                if (accountData !== null) {
                    let studentAccountVal = {
                        studentId: parseInt(id),
                        semesterId: soaArray[soaLoop].SemesterId, // previous sem
                        schoolYear: soaArray[soaLoop].SchoolYear  // previous sy
                    }
                    let paymentVal = {
                        studentId: parseInt(id),
                        semesterId: soaArray[soaLoop].SemesterId, // previous sem
                        schoolYear: soaArray[soaLoop].SchoolYear, // previous sy
                        isBackAcct: false
                    }
                
                    exeStudentCashieringAccountBack(studentAccountVal);
                    exeGetStudentPaymentsBack(paymentVal);
                }
            }
        }
    }

    //BACK ACCOUNT
    const exeGetStudentPaymentsBack = async (val) => {
        await getStudentPayments(val)
            .then(res => {
                setTotalPaymentBack(res.totalPayment);
            })
            .catch(e => {})
    }

    //BACK ACCOUNT
    const exeStudentCashieringAccountBack = async (val) => {
        await getStudentCashieringAccount(val)
            .then(res => {
                setStudentAccountBack(res.data);
            })
            .catch(e => {})
    }

    useEffect(() => {
        if (studentAccountBack !== null && typeof(studentAccountBack) != "undefined") {
            getStudentSubjectsBack({
                studentId: studentAccountBack[0].StudentId,
                semesterId: studentAccountBack[0].SemesterId,
                schoolYear: studentAccountBack[0].SchoolYear
            });

            // getStudentEnrollmentDiscountBack({
            //     enrollmentId: studentAccount[0].EnrollmentId
            // })

            getStudentOtherFeesBack({
                assessmentTemplateId: studentAccountBack[0].AssessmentTemplateId,
                yearLevelId: studentAccountBack[0].YearLevelId,
                courseId: studentAccountBack[0].CourseId,
                instituteId: studentAccountBack[0].InstituteId,
                isCampus: false,
                isMale: studentAccountBack[0].Gender == 'M' ? true : false
            });

            getStudentAddsBack({
                studentId: studentAccountBack[0].StudentId,
                semesterId: studentAccountBack[0].SemesterId,
                schoolYear: studentAccountBack[0].SchoolYear
            });
        }
    }, [studentAccountBack]);

    useEffect(() => {
        if (!astraStudentEnrollmentSubjectsBack)
            return
        let subSchedDetlVal = {
            classSectionId: 0,
            semesterId: studentAccountBack[0].SemesterId,
            schoolYear: studentAccountBack[0].SchoolYear,
            isCampus: isCampus,
            batchId: studentAccountBack[0].CampusBatchId,
            isExist: true,
            subjectScheduleDetailId: astraStudentEnrollmentSubjectsBack !== null ? astraStudentEnrollmentSubjectsBack.toString() : null,
            studentId: studentAccountBack[0].StudentId
        }
        exeStudentAssessmentSubjectScheduleDetailBack(subSchedDetlVal);

    }, [astraStudentEnrollmentSubjectsBack]);

    useEffect(() => {
        if (studentAccountBack !== null && studentAccountBack.length > 0 && studentTotalUnitsBack !== 0)
            exeGetStudentFeesBack({
                assessmentTemplateId: studentAccountBack[0].AssessmentTemplateId.toString(),
                discountRate: studentAccountBack[0].ApplyRate !== null ? studentAccountBack[0].ApplyRate : 0.00,
                totalUnits: studentTotalUnitsBack,
                discountApplyTo: studentAccountBack[0].ApplyTo !== null ? studentAccountBack[0].ApplyTo : 'NA',
                labMode: studentLabModesBack,
                subjectCategories: subjectCategoryIdsBack.toString(),
                isSyncToCampus: false,
                labSubjects: labSubjectsBack,
                studentSchoolYear: studentAccountBack[0].StudentBatchYear
            })
    //}, [studentAccountBack, studentTotalUnitsBack, studentLabModesBack, subjectCategoryIdsBack])
    }, [studentAccountBack, studentTotalUnitsBack])

    useEffect(() => {
        if (studentFeesBack !== null) {
            let tuitionFee = studentFeesBack.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
            let registrationFee = studentFeesBack.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
            let miscFee = studentFeesBack.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
            let labFee = studentFeesBack.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
            let otherFee = studentFeesBack.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };

            exeGetStudentDiscountBack({
                studentId: studentAccountBack[0].StudentId,
                schoolYear: studentAccountBack[0].SchoolYear,
                semesterId: studentAccountBack[0].SemesterId,
                assessmentTemplateId: studentFeesBack[0].AssessmentTemplateId.toString(),
                tuitionFee: tuitionFee.TotalAmount,
                regFee: registrationFee.TotalAmount,
                miscFee: miscFee.TotalAmount,
                labFee: labFee.TotalAmount,
                otherFee: otherFee.TotalAmount
            })

        }
    }, [studentFeesBack]);

    useEffect(() => {
        if (studentDiscountBack !== 0 && studentDiscountBack.length > 0 && studentFeesBack !== null) {
            let tuitionFee = studentFeesBack.find(data => data.FeeCode === 'TF') || { TotalAmount: 0 };
            let registrationFee = studentFeesBack.find(data => data.FeeCode === 'RG') || { TotalAmount: 0 };
            let miscFee = studentFeesBack.find(data => data.FeeCode === 'MS') || { TotalAmount: 0 };
            let labFee = studentFeesBack.find(data => data.FeeCode === 'LB') || { TotalAmount: 0 };
            let otherFee = studentFeesBack.find(data => data.FeeCode === 'OT') || { TotalAmount: 0 };
            let otherLabFee = studentFeesBack.find(data => data.FeeCode === 'OLB') || { TotalAmount: 0 };

            let data = []

            const getStudentDiscountWithParticulars = async (val) => {
                const res = await getStudentDiscountParticulars(val);
                // setStudentDiscountParticulars(res.data)
                setTotalDiscountBack(res.totalDiscount)
                return res;
            };

            const updateFees = async () => {
                let arr = [];
                let totalDiscount = 0.00
                for (let i = 0; i < studentDiscountBack.length; i++) {
                    const res = await getStudentDiscountWithParticulars({
                        studentId: studentAccountBack[0].StudentId,
                        assessmentTemplateId: studentFeesBack[0].AssessmentTemplateId.toString(),
                        tuitionFee: tuitionFee.TotalGAmount,
                        regFee: registrationFee.TotalGAmount,
                        miscFee: miscFee.TotalGAmount,
                        labFee: labFee.TotalGAmount,
                        otherFee: totalOtherFeeBack,
                        otherLabFee: otherLabFee.TotalGAmount,
                        scholarshipId: studentDiscountBack[i].ScholarshipId
                    });
                    tuitionFee.TotalGAmount = res.data[0].RemainingTuition;
                    registrationFee.TotalGAmount = res.data[0].RemainingReg;
                    miscFee.TotalGAmount = res.data[0].RemainingMisc;
                    labFee.TotalGAmount = res.data[0].RemainingLab;
                    //console.log(res.data[0]);
                    arr.push({ ...res.data[0], enrollmentId: parseInt(id), enrollmentDiscountId: 0, createdBy: auth.data.Username });
                    totalDiscount += parseFloat(res.totalDiscount)
                }

                for (let i = 0; i < arr.length; i++) {
                    await getStudentDiscountParticulars({
                        studentId: studentAccountBack[0].StudentId,
                        assessmentTemplateId: studentFeesBack[0].AssessmentTemplateId.toString(),
                        tuitionFee: tuitionFee.TotalAmount,
                        regFee: registrationFee.TotalAmount,
                        miscFee: miscFee.TotalAmount,
                        labFee: labFee.TotalAmount,
                        otherFee: otherFee.TotalAmount,
                        scholarshipId: arr[i].ScholarshipId
                    })
                        .then(res => {
                            data.push(res.data[0])
                        })
                }
                setTotalDiscountBack(totalDiscount);
            };

            updateFees();
        }
    }, [studentDiscountBack]);

    useEffect(() => {
        setTotalFeesBack(parseFloat(totalAssessmentFeeBack) + parseFloat(totalOtherFeeBack) + parseFloat(totalAddOnsBack))
    }, [totalAssessmentFeeBack, totalOtherFeeBack, totalAddOnsBack]);

    useEffect(() => {
        if(totalFeesBack !== null && totalInterestBack !== null && totalDiscountBack !== null && totalPaymentBack !== null){
            let backAccountBal = (totalFeesBack + totalInterestBack - totalDiscountBack) - totalPaymentBack;
            if(backAccountBal > 0){
                setListOfBackAccounts([...listOfBackAccounts, [
                    backAccountBal, 
                    soaArray[soaLoop].SchoolYear, 
                    soaArray[soaLoop].SemesterId
                ]]);
            }
            
            setTotalFeesBack(null);
            setTotalInterestBack(null);
            setTotalDiscountBack(null);
            setTotalPaymentBack(null);
            setAstraStudentEnrollmentSubjectsBack(null);
            setStudentTotalUnitsBack(0);
            setStudentLabModesBack(1);
            setSubjectCategoryIdsBack([]);
            setTotalAssessmentFeeBack(0);
            setTotalOtherFeeBack(0);
            setTotalAddOnsBack(0);
            setStudentFeesBack(null);
            setStudentDiscountBack([]);
            setLabSubjectsBack([]);
            setIsGettingBackAccount(true);
            //setStudentAccountBack(null);

            setSOALoop(soaLoop + 1)
        }
    }, [totalFeesBack, totalInterestBack, totalDiscountBack, totalPaymentBack]);

    useEffect(() => {
        if(soaLoop > 0){          
            execBackAccounts();
        }
    }, [soaLoop]);

    const getStudentSubjectsBack = async (val) => {
        await getStudentAstraSubjects(val)
            .then(res => {
                let arr = []
                if (res.data.length > 0) {
                    for (let i = 0; i < res.data.length; i++) {
                        arr.push(res.data[i].DetailId)
                    }
                }
                setAstraStudentEnrollmentSubjectsBack(arr)
            })
            .catch(e => {

            })
    }
    
    const getStudentOtherFeesBack = async (val) => {
        await getStudentAssessmentOtherFees(val)
            .then(res => {
                //setStudentOtherFees(res.data.data)
                setTotalOtherFeeBack(res.data.totalOtherFee)
            })
            .catch(e => {})
    }

    const getStudentAddsBack = async (val) => {
        await getStudentAddOns(val)
            .then(res => {
                ////console.log(res)
                setTotalAddOnsBack(res.totalAmount);
                //setStudentAddOnsBack(res.data)
            })
    }

    const exeStudentAssessmentSubjectScheduleDetailBack = async (val) => {
        await getAssessmentStudentSubjectScheduleDetail(val)
            .then(res => {
                //console.log(res)
                setLabSubjectsBack(res.labSubjects)
                if (res.data.length > 0) {

                    if (res.subjectCategoryIds.length > 0) {
                        setSubjectCategoryIdsBack(res.subjectCategoryIds)

                    } else {
                        setSubjectCategoryIdsBack([1])
                    }
                    setStudentLabModesBack(res.labModes);
                    setStudentTotalUnitsBack(res.totalUnits);
                }
            })
            .catch(e => {})
    }

    const exeGetStudentFeesBack = async (val) => {
        await getStudentAssessmentTemplateDetail(val)
            .then(res => {
                let data = res.data.map(d => {
                    //console.log(d.FeeCode)
                    //console.log(d)

                    if (d.FeeCode == 'TF' || d.FeeCode == 'TT') {
                        return { ...d, TotalGAmount: res.totalTuitionFee };
                    } else if (d.FeeCode == 'RG') {
                        return { ...d, TotalGAmount: res.totalRegFee + res.totalOtherFee };
                    } else if (d.FeeCode == 'MS') {
                        return { ...d, TotalGAmount: res.totalMiscFee };
                    } else if (d.FeeCode == 'LB') {
                        return { ...d, TotalGAmount: d.TotalAmount };
                    } else if (d.FeeCode == 'OLB') {
                        return { ...d, TotalGAmount: res.totalOtherLabFee };
                    }
                    return d;
                });
                setTotalAssessmentFeeBack(res.totalFee);
                setTotalInterestBack(res.totalInterest);
                setStudentFeesBack(data);
            })
            .catch(e => {})
    }

    const exeGetStudentDiscountBack = async (val) => {
        await getStudentDiscount(val)
            .then(res => {
                setTotalDiscountBack(res.totalDiscount)
                setStudentDiscountBack(res.data)
                return res.data

            })
            .catch(e => {});
    }

    const execGetAvailableSOA = async () => {
        let val = {
            studentId: parseInt(id),
            schoolYear: accountData.SchoolYear,
            semesterId: accountData.SemesterId
        }

        await getAvailableSOA(val)
            .then(res => {
                setSOAArray(res.data);
            })
            .catch(e => {})
    }


    /**** END: Codes to get Back Account */

    return (
        <>
            {
                studentFees !== null && totalFees !== null && studentOtherFees !== null && listOfBackAccounts !== null
                    ?
                    //"test"
                    <ChartForm
                        studentAccount={studentAccount}
                        studentFees={studentFees}
                        studentDiscount={studentDiscountWithSequence}
                        payments={payments}
                        backPayments={backAccPayments}
                        totalFees={totalFees}
                        totalDiscount={totalDiscount}
                        totalInterest={totalInterest}
                        isPending={isPending}
                        studentOtherFees={studentOtherFees}
                        totalOtherFee={totalOtherFee}
                        totalAssessmentFee={totalAssessmentFee}
                        studentAddOns={studentAddOns}
                        totalAddOns={totalAddOns}
                        onRecompute={onRecompute}
                        totalPayment={totalPayment}
                        paymentSchedule={paymentSchedule}
                        studentAssessmentFees={studentAssessmentFees}
                        studentTotalUnits={studentTotalUnits}
                        paymentDetails={paymentDetails}
                        studentAccountSOA={studentAccountSOA}
                        listOfBackAccounts={listOfBackAccounts}
                    />
                    :
                    <Loading />
            }

        </>
    )
}