import React, { useEffect, useState } from "react";
import { useAppContext } from "../../../../contexts/useAppContext";
import { getSubjectList, getStudentList, getSchoolYear, getSemester } from "./__hooks__"
import ListOfStudentModal from "../../../../../_metronic/layout/components/custom/modal/ListOfStudentModal";
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import './__hooks__/styles.css'
export default function List() {
    const { state: { auth } } = useAppContext()
    const activeSy = auth.data.schoolyears.grading.SchoolYear
    const activeSem = auth.data.schoolyears.grading.SemesterId
    const [isOpenStudentList, setIsOpenStudentList] = useState(false)
    const [subjects, setSubjects] = useState([])
    const [students, setStudents] = useState([])
    const [filter, setFilter] = useState({ schoolYear: activeSy, semesterId: activeSem, })
    const [onAlert, setOnAlert] = useState(false)

    const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }

    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)



    //functions
    const onClick = async (id) => {

        await getStudentList({ subjectScheduleDetailId: parseInt(id) })
            .then(res => {
                setStudents(res.data)
                setIsOpenStudentList(true)
            })
            .catch(e => {
            })
    }

    const onClose = () => {
        setIsOpenStudentList(false)
    }

    const getSubjects = async () => {
        await getSubjectList({ userId: auth.data.UserId, schoolYear: filter.schoolYear, semesterId: filter.semesterId })
            .then(res => {
                console.log(res)
                setSubjects(res.data)
                setOnAlert(true)

            })
            .catch(e => {
            })
    }

    const onSeachAgain = () => {
        setSubjects([])
        setOnAlert(false)

    }



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description")
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description")
    }, [])


    useEffect(() => {
    }, [students])


    console.log(auth)
    return (
        <>
            {semesterSelect && schoolYearSelect &&
                <>
                    <ListOfStudentModal
                        isOpen={isOpenStudentList}
                        handleClose={onClose}
                        students={students}
                    />


                    {subjects.length > 0 ?
                        <main className="">
                            <div className="p-5 card ">
                                <section className="card-header p-3">
                                    <h3 className="font-weight-bold float-left">List of Subjects  </h3>
                                    <button onClick={() => { onSeachAgain() }} className="btn btn-secondary float-right">Search Again</button>
                                </section>
                                <section className="card-body row justify-content-start">
                                    {subjects.map(sub => {
                                        return (
                                            <>
                                                <div key={sub.SubjectScheduleDetailId}
                                                    className="card-customs col mx-3 my-3"
                                                    style={{ minWidth: '30%', maxWidth: '30%' }}
                                                    onClick={() => { onClick(sub.SubjectScheduleDetailId) }}>
                                                    <h3> <i className="fa fa-book mr-2"></i>  {sub.SubjectCode}</h3>
                                                    <p className="lead">{sub.SubjectDesc}</p>
                                                    <hr />
                                                    <p className="font-weight-bold">Schedule of Lecture</p>
                                                    <p className="mb-0">Day Code: <span className="font-weight-bold">{sub.DayCode}</span> </p>
                                                    <p className="mb-0">Time Start: <span className="font-weight-bold">{sub.TimeStart}</span></p>
                                                    <p className="mb-0">Time Start: <span className="font-weight-bold">{sub.TimeEnd}</span></p>
                                                    <p className="mb-0">Lecutre Room: {sub.RoomDesc}</p>



                                                </div>
                                            </>
                                        )
                                    })}
                                </section>

                            </div>

                        </main>

                        :

                        <>
                            <div className='d-flex align-items-center'>
                                <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                                    <header className='card-header p-5 bg-secondary'>
                                        <h6 className="float-left m-0 mt-4 ml-5">Instructor Subjects</h6>
                                    </header>
                                    <section className="card-body">
                                        {onAlert == true && subjects.length == 0 &&
                                            <div className="alert alert-danger" role="alert">
                                                No Record Found
                                            </div>
                                        }
                                        <p className="card-text">Search for your Subjects</p>


                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={schoolYearSelect}
                                                    onHandleChange={filterChange('schoolYear')}
                                                    refClassContainer=""
                                                    name="schoolYear"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="School Year"
                                                    placeHolder="Select School Year"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.schoolYear}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`School Year`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={semesterSelect}
                                                    onHandleChange={filterChange('semesterId')}
                                                    refClassContainer=""
                                                    name="semesterId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Semester"
                                                    placeHolder="Select Semester"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.semesterId}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Semester`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>



                                        <div className="form-row bg-secondary">
                                            <button onClick={() => { getSubjects() }} className='btn btn-success btn-block'>Search</button>

                                        </div>

                                    </section>
                                </main>

                            </div>

                        </>
                    }






                </>
            }
        </>


    )
}