import instance from "../../../../../apis/local/systemAPI";


export const getStudentCampus = async (id) => {
    // const studNo = {studentNo: req.body.studNo};
    const result = await instance.post(`api/testing/campus?studentNo=${id}`,);
    if (result.status === 200 || result.status === 201) {
        console.log(result);
        return result.data;

    }
}


export const getInstitute = async (formValues) => {
    const result = await instance.get("api/references/institutes", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevel = async (formValues) => {
    const result = await instance.get("api/references/yearlevels", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentData = async (formValues) => {
    const result = await instance.get("api/enrollments/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getClassSections = async (formValues) => {
    const result = await instance.get("api/references/class-sections", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getAllSubjectScheduleDetail = async (formValues) => {
    const result = await instance.get("api/schedule/details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAllSubjectMainCopy = async (formValues) => {
    const result = await instance.get("api/references/subject-main-copy", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getAssessmentTemplateDetails = async (formValues) => {
    const result = await instance.get("api/assessment/template-details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentTemplateOther = async (formValues) => {
    const result = await instance.get("api/assessment/template-others", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentMode = async (formValues) => {
    const result = await instance.get("api/references/mode-of-payments", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFeeGroups = async (formValues) => {
    const result = await instance.get("api/references/feegroups", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getStudentDiscounts = async (formValues) => {
    const result = await instance.get("api/student/discounts", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getScholarship = async (formValues) => {
    const result = await instance.get("api/references/scholarships", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getScholarshipFeeGroups = async (formValues) => {
    const result = await instance.get("api/references/scholarship-feegroups", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getStudentEnrollmentBatches = async (formValues) => {
    const result = await instance.get("api/enrollment/batches", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentAccountAddOns = async (formValues) => {
    const result = await instance.get("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentAcc = async (formValues) => {
    const result = await instance.get("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getApplicantDataEntryDetail2 = async (id, formValues) => {
    const result = await instance.get(`api/students/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getApplicantDataEntryDetail = async () => {
    const result = await instance.get("api/students");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createEnrollment = async (formValues) => {
    const result = await instance.post("api/enrollments", formValues);
    console.log(result)
    if (result.status === 200 || result.status === 201) {
        // console.log(JSON.stringify(result.data) + 'here in index ')
        return result.data;
    }
}

export const createEnrollmentBatches = async (formValues) => {
    const result = await instance.post("api/enrollment/batches", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createStudentEnrollmentSubjects = async (formValues) => {
    const result = await instance.post("api/student/student-enrollment-subjects", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createEnrollmentAccountAddsOn = async (formValues) => {
    const result = await instance.post("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createEnrollmentAccount = async (formValues) => {
    const result = await instance.post("api/enrollment/account", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getActiveSubjectScheduleDetails = async () => {
    const result = await instance.get("api/schedule/details/active");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectScheduleDetails = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/schedule/details/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "subjectSchedule": keyword,
                        "schoolYear": keyword,
                        "semesterId": keyword,
                        "classSection": keyword,
                        "subject": keyword,
                        "classCode": keyword,
                        "capacity": keyword
                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/schedule/details/q`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getStudentCategory = async (formValues) => {
    const result = await instance.get("api/references/student-categories", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getActiveSemester = async (formValues) => {
    const result = await instance.get("api/references/semesters", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const createSubjectScheduleDetail = async (formValues) => {
    const result = await instance.post("api/schedule/details", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const updateEnrollment = async (id, formValues) => {
    const result = await instance.patch(`api/enrollments/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateSubjectScheduleDetail = async (id, formValues) => {
    const result = await instance.patch(`api/schedule/details/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteSubjectScheduleDetail = async (id) => {
    const result = await instance.delete(`api/schedule/details/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const createSubjectScheduleDetailList = async (formValues) => {
    const result = await instance.post("api/schedule/detail-lists", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateSubjectScheduleDetailList = async (id, formValues) => {
    const result = await instance.patch(`api/schedule/detail-lists/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateStudentEnrollmentSubject = async (id, formValues) => {
    const result = await instance.patch(`api/student/student-enrollment-subjects/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteSubjectScheduleDetailList = async (id) => {
    const result = await instance.delete(`api/schedule/detail-lists/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getApplicantDataEntryDetailById = async (id, page, rowsPerPage, keyword) => {
    console.log(keyword);
    var result;
    if (keyword === '') {
        const params = {
            where: [
                { "o": "", "p": ["=", "", { "StudentInfoId": id === undefined ? null : id }] }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post("api/students", params);
    } else {
        const params = {
            where: [
                { "o": "", "p": ["=", "", { "StudentInfoId": id === undefined ? null : id }] },
                {
                    "o": "AND", "p": ["LIKE", "||", {
                        "StudentNo": keyword,
                        "ApplicantNo": keyword,
                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/students`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const createApplicantDataEntryDetail = async (formValues) => {
    const result = await instance.post("api/students", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateApplicantDataEntryDetail = async (id, formValues) => {
    const result = await instance.patch(`api/students/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const deleteApplicantDataEntryDetail = async (id) => {
    const result = await instance.delete(`api/students/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const deleteStudentEnrollmentSubjects = async (id) => {
    const result = await instance.delete(`api/student/student-enrollment-subjects/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteEnrollmentAccountAddOns = async (id) => {
    const result = await instance.delete(`api/enrollment/account/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteEnrollmentAccount = async (id) => {
    const result = await instance.delete(`api/enrollment/account/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const deleteEnrollmentAccountAddsOn = async (id) => {
    const result = await instance.delete(`api/enrollment/account/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getEnrollmentBatches = async (formValues) => {
    const result = await instance.get("api/enrollment/batches", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getModeOfPayment = async (formValues) => {
    const result = await instance.get("api/references/mode-of-payments", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSubMain = async (formValues) => {
    const result = await instance.get("api/references/subject-main-copy", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}







// ---------------- revised


export const getStudent = async (condition) => {
    const result = await instance.post("api/students/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSem = async (condition) => {
    const result = await instance.post("api/references/semesters/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async (condition) => {
    const result = await instance.post("api/references/schoolyears/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYearForSelect = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentSchedule = async (condition) => {
    const result = await instance.post("api/references/payment-schedules/s", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollment = async (condition) => {
    const result = await instance.post("api/enrollments/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentInfo = async (condition) => {
    const result = await instance.post("api/student/infos/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSubjectSchedule = async (condition) => {
    const result = await instance.post("/api/schedules/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectScheduleDetail = async (condition) => {
    const result = await instance.post(`api/schedule/details/q`, condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSubjects = async (condition) => {
    const result = await instance.post("api/references/subjects/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentEnrollmentSubjects = async (condition) => {
    const result = await instance.post("api/student/student-enrollment-subjects/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubSchedDetailList = async (condition) => {
    const result = await instance.post("api/schedule/detail-lists/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentAccAddOn = async (condition) => {
    const result = await instance.post("api/enrollment/account/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentDiscount = async (condition) => {
    const result = await instance.post("api/student/discounts/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentTemplate = async (condition) => {
    const result = await instance.post("api/assessment/templates/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFees = async (condition) => {
    const result = await instance.post("api/references/fees/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAssessmentTemplateDetail = async (condition) => {
    const result = await instance.post("api/assessment/template-details/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getFeeGroup = async (condition) => {
    const result = await instance.post("api/references/feegroups/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getPaymentAddOns = async (condition) => {
    const result = await instance.post("/api/assessment/add-ons/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentStatus = async (formValues) => {
    const result = await instance.get("/api/enrollment/status/q", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const getCampusEnrollment = async (data) => {
    const result = await instance.post("api/campus/enrollment-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const saveSyncEnrollment = async (formValues) => {
    const result = await instance.post("api/enrollments/sync", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const saveStudentPaymentSchedule = async (formValues) => {
    const result = await instance.post("api/student/student-payment-schedules", formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}



export const getStudentAssessmentOther = async (condition) => {
    const result = await instance.post("api/assessment/student-other-fees/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentAssessmentLab = async (condition) => {
    const result = await instance.post("api/assessment/student-lab-fees/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentLaboratoryComputation = async (values) => {
    const result = await instance.post("api/assessment/lab-computation/", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getCampusClassSection = async (data) => {
    const result = await instance.post("api/campus/get-classSection/", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCampusSubjectLabFee = async (data) => {
    const result = await instance.post("api/campus/subjectLabFee-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCampusSubjectScheduleBatch = async (condition) => {
    const result = await instance.post("api/schedule/batch/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentCampusDiscount = async (data) => {
    const result = await instance.post("api/campus/discount-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getStudentOtherFee = async (data) => {
    const result = await instance.post("api/campus/otherFee-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCampusStudentOtherFee = async (data) => {
    const result = await instance.post("api/campus/otherFee-migrate/", data);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const getCourseOutline = async (condition) => {
    const result = await instance.post(`api/course-outlines/s`, condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourseOutlineDetail = async (condition) => {
    const result = await instance.post(`api/course-outline/details/s`, condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectScheduleDetailSelect = async (condition) => {
    const result = await instance.post(`api/schedule/details/qy`, condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteEnrollment = async (id) => {
    const result = await instance.delete(`api/enrollments/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateEnrollmentDiscount = async (val) => {
    const result = await instance.post(`api/accounting/student/addUpdateEnrollmentDiscount`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const searchStudent = async (data) => {
    const result = await instance.post("api/enrollments/search", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoCompleteForValidation", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
//

export const createAssessmentAddOns = async (formValues) => {
    const result = await instance.post("api/assessment/add-ons", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateStudentEnrollmentSubject = async (formValues) => {
    const result = await instance.post("api/student/student-enrollment-subjects/addUpdate", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const sendMail = async (values) => {
    const result = await instance.post("api/nodemailer/send-mail", values);
    if (result.status === 200 || result.status === 201) {
        // alert('email send')
    }
}