import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter } from '@material-ui/core';
import IMDialog from '../../../../../../_metronic/layout/components/custom/dialog/IMDialog';
import history from '../../../../../history';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getAssessmentTemplateSchedulesByAssessmentTemplateId
  , createAssessmentTemplateSchedule
  , updateAssessmentTemplateSchedule
  , deleteAssessmentTemplateSchedule
} from './__hooks__';
import Form from './Form';
import moment from 'moment';

const useStyles = makeStyles({
  table: {
    minWidth: 650,
  },
});

export default function List(props) {
  const classes = useStyles();
  const { AssessmentTemplateId } = props.assessmentTemplate;
  const { dispatch } = useAppContext();
  const [selectedId, setSelectedId] = useState(0);
  const [results, setResults] = useState([]);
  const [retrieve, setRetrieve] = useState(true);
  const [showDialog, setShowDialog] = useState({
    id: null,
    record: '',
    status: false
  });
  var rowCnt = 0;

  useEffect(() => {
    if (retrieve) {
      const execute = async () => {
        await getAssessmentTemplateSchedulesByAssessmentTemplateId(AssessmentTemplateId)
          .then(response => {
            if (response.success) {
              dispatch({ type: enrollmentTypes.GET_ASSESSMENT_SCHEDULES, data: response.data });
              setResults(response.data);
              setRetrieve(false);
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
            }
          })
      }
      execute();
    }
  }, [dispatch, AssessmentTemplateId, retrieve])

  const handleAdd = (data) => {
    const execute = async () => {
      await createAssessmentTemplateSchedule(data)
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleEdit = (id) => {
    setSelectedId(id);
  }

  const handleCancel = () => {
    setSelectedId(0);
  }

  const handleSave = (id, data) => {
    const execute = async () => {
      await updateAssessmentTemplateSchedule(id, data)
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setSelectedId(0);
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  const handleDelete = () => {
    const execute = async () => {
      await deleteAssessmentTemplateSchedule(showDialog.id)
        .then(response => {
          if (response.success) {
            setRetrieve(true);
            setShowDialog({ id: null, record: '', status: false })
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  return (
    <>
      <TableContainer component={Paper}>
        <Table className={classes.table} size="small">
          <TableHead>
            <TableRow>
              <TableCell align="center">#</TableCell>
              <TableCell>Payment Schedule</TableCell>
              <TableCell>Due Date</TableCell>
              <TableCell>Amount</TableCell>
              <TableCell align="center">Sequence</TableCell>
              <TableCell align="center">Active</TableCell>
              <TableCell align="center" style={{ minWidth: "120px" }}>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {results.length > 0 ? results.map((result) => {
              const { AssessmentTemplateScheduleId, PaymentSchedule, DueDate, Amount, SchedSeq, IsActive } = result;
              rowCnt += 1;
              if (selectedId !== 0 && selectedId === result.AssessmentTemplateScheduleId) {
                return (
                  <Form key={AssessmentTemplateScheduleId} doc={result} assessmentTemplateId={AssessmentTemplateId} onHandleCancel={handleCancel} onHandleSave={handleSave} index={rowCnt} />
                );
              } else {
                return (
                  <TableRow key={AssessmentTemplateScheduleId}>
                    <TableCell align="center">{rowCnt}</TableCell>
                    <TableCell component="th" scope="row">
                      {PaymentSchedule}
                    </TableCell>
                    <TableCell>{moment(DueDate).format('YYYY-MM-DD')}</TableCell>
                    <TableCell>{Amount}</TableCell>
                    <TableCell align="center">{SchedSeq}</TableCell>
                    <TableCell align="center">{IsActive === 1 ? <i className="flaticon2-check-mark text-success"></i> : <i className="flaticon2-delete text-danger"></i>}</TableCell>
                    <TableCell align="center">
                      <button
                        className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                        onClick={() => handleEdit(AssessmentTemplateScheduleId)}
                      >
                        <i className="fa fa-pencil-alt text-white"></i>
                      </button>
                      <Link
                        to={`${enrollmentTypes.MORE_LINK}${AssessmentTemplateId}/${AssessmentTemplateScheduleId}`}
                        className="btn btn-xs btn-icon btn-icon-xs btn-primary mr-1">
                        <i className="fa fa-list-alt text-white"></i>
                      </Link>
                      <button
                        className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                        onClick={() => setShowDialog({ id: AssessmentTemplateScheduleId, record: `${PaymentSchedule} - ${moment(DueDate).format('YYYY-MM-DD')} - ${Amount} - ${SchedSeq}`, status: !showDialog.status })}
                      >
                        <i className="fa fa-trash"></i>
                      </button>
                    </TableCell>
                  </TableRow>
                );
              }
            }) : <TableRow><TableCell colSpan="7">No data available in the table</TableCell></TableRow>}
          </TableBody>
          <TableFooter>
            <Form doc={[]} assessmentTemplateId={AssessmentTemplateId} onHandleAdd={handleAdd} />
          </TableFooter>
        </Table>
      </TableContainer>
      {showDialog.status && <IMDialog
        title={`Question`}
        content={<span>Are you sure you want to delete this record (<span className="text-primary"><b>{showDialog.record}</b></span>) ?</span>}
        onHandleDelete={handleDelete}
        onShowDialog={setShowDialog}
        isOpen={true}
      />}
    </>
  );
}