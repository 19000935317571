import instance from '../../../../../../apis/local/systemAPI';

// START OF SCHOLARSHIP
export const getScholarshipFeeGroupsByScholarshipId = async (id, page, rowsPerPage, keyword) => {
  console.log(keyword);
  var result;
  if (keyword === '') {
    const params = {
      where: [
        { "o": "", "p": ["=", "", { "scholarshipId": id === undefined ? null : id }] }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post("api/references/scholarship-feegroups/q", params);
  } else {
    const params = {
      where: [
        { "o": "", "p": ["=", "", { "scholarshipId": id === undefined ? null : id }] },
        {
          "o": "AND", "p": ["LIKE", "||", {
            "scholarship": keyword,
            "description": keyword,
            "applyRate": keyword,
            "applyType": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/scholarship-feegroups/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getScholarshipFeeGroups = async () => {
  const result = await instance.get("api/references/scholarship-feegroups");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createScholarshipFeeGroup = async (formValues) => {
  const result = await instance.post("api/references/scholarship-feegroups", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateScholarshipFeeGroup = async (id, formValues) => {
  const result = await instance.patch(`api/references/scholarship-feegroups/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteScholarshipFeeGroup = async (id) => {
  const result = await instance.delete(`api/references/scholarship-feegroups/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF SCHOLARSHIP