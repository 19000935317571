// ----------------------------------------------------------------AUTH ------------------------------------------------------------------ //
import Login from "./modules/Auth/pages/Login";
import SignUpInitial from "./modules/Auth/pages/SignUpInitial";
import SignUpMain from "./modules/Auth/pages/SignUpMain";


// ------------------------ ------------------------------------NAVIGATION ------------------------------------------------------------ //

import Profile from "./modules/Admin/_Navigation/Profile/Profile"

// -------------------------------------------------------------- ADMIN --------------------------------------------------------------------- //
import Dashboard from "./modules/Admin/Dashboard";

// ------------------------ ------------------------------ACCOUNTING & FINANCE ------------------------------------------------------------ //
// -- CASHIERING -- //
import Cashiering from "./modules/Admin/Accounting/Cashiering/List";
import CreatePayment from "./modules/Admin/Accounting/Cashiering/Cashier"
import ChartPayment from "./modules/Admin/Accounting/Cashiering/Chart";
import DirectCashierPayment from "./modules/Admin/Accounting/Cashiering/DirectCashierPayment";

// -- STUDENT ACCOUNT-- //
import StudentAccountList from "./modules/Admin/Accounting/StudentAccount/List";
import StudentAccountAssessment from "./modules/Admin/Accounting/StudentAccount/Assessment";

// -- PRINT RECEIPT-- //
import PrintReceipt from "./modules/Admin/Accounting/PrintReceipt/PrintReceipt";

// -- PAYMENT REPORT-- //
import PaymentReport from "./modules/Admin/Accounting/PaymentReport/PaymentReport";

// -- PAYMENT REPORT-- //
import StudentBalanceReports from "./modules/Admin/Accounting/StudentBalanceReport/StudentBalanceReport";

// -- ASSIGN RECEIPT -- //
import RecieptAssignment from "./modules/Admin/Accounting/RecieptAssignment/RecieptAssignment";

// -- PROMISSORY NOTE MAINTENANCE -- //
import PromissoryNote from "./modules/Admin/Accounting/PromissoryNote/PromissoryNote";


// -- PAYMENT REQUEST -- //
import PaymentRequest from "./modules/Admin/Accounting/PaymentRequest/PaymentRequest";


// -- OR Reclassification -- //
import ORReclassification from "./modules/Admin/Accounting/ORReclassification/ORReclassification"

// -- Official Reciept Details -- //
import OfficialRecieptDetails from "./modules/Admin/Accounting/OfficialRecieptDetails/OfficialRecieptDetails";


// -- Grant Disclosure List
import GrantDisclosureList from "./modules/Admin/Accounting/GrantDisclosureList/GrantDisclosureList";
// ----------------------------------------------------------- ADMISSION -------------------------------------------------------------------- //


// -- APPLICANT DATA ENTRY -- //

import WalkInRegistration from "./modules/Admin/Admissions/ApplicantDataEntry/WalkInRegistration/WalkInApplication"
import FormSubmitted from "./modules/Admin/Admissions/ApplicantDataEntry/WalkInRegistration/FormCompleted"
import FormSubmittedOnline from "./modules/Registration/OnlineRegistration/FormCompleted";

// -- APPLICANT DATA ENTRY DETAILS - //
import ApplicantList from "./modules/Admin/Admissions/ApplicantDataEntry/Applicant/List";


// ----------------------------------------------------------- ENROLLMENT -------------------------------------------------------------------- //
// -- SUBJECT SCHEDULE -- //
import SubjectScheduleForm from "./modules/Admin/Enrollment/SubjectSchedule/Form"
import SubjectSchedule from "./modules/Admin/Enrollment/SubjectSchedule/List";

// -- SUBJECT SCHEDULE DETAIL -- //
import SubjectScheduleDetail from "./modules/Admin/Enrollment/SubjectSchedule/SubjectScheduleDetail/List";


// -- ASSESSMENT TEMPLATE -- //
import AssessmentOfFee from "./modules/Admin/Enrollment/AssessmentOfFee/List";
import CreateAssessmentOfFee from "./modules/Admin/Enrollment/AssessmentOfFee/Create";
import UpdateAssessmentOfFee from "./modules/Admin/Enrollment/AssessmentOfFee/Update";
import DeleteAssessmentOfFee from "./modules/Admin/Enrollment/AssessmentOfFee/Delete";

// -- PRE ASSESSMENT VALIDATION -- //
import PreAssessmentValidationList from "./modules/Admin/Enrollment/PreAssessmentValidation/List";
import StudentPreValidationForm from "./modules/Admin/Enrollment/PreAssessmentValidation/Assessment";
// -- FACULTY LOADING -- //
import FacultyLoadingList from "./modules/Admin/Enrollment/FacultyLoading/List"
import FacultyLoadingDetail from "./modules/Admin/Enrollment/FacultyLoading/FacultyLoadingDetail/List"
import FacultyLoadingDetailLists from "./modules/Admin/Enrollment/FacultyLoading/FacultyLoadingDetail/FacultyLoadingDetailLists";
// -- ASSESSMENT TEMPLATE DETAIL CONTAINER -- //
import AssessmentTemplateDetailContainer from "./modules/Admin/Enrollment/AssessmentOfFee/Container";
// -- ASSESSMENT TEMPLATE DETAIL -- //
import AssessmentTemplateDetailOther from "./modules/Admin/Enrollment/AssessmentOfFee/AssessmentDetail/AssessmentDetailOther/List";
// -- ASSESSMENT TEMPLATE LAB MODE DETAIL -- //
import AssessmentLabModeDetail from "./modules/Admin/Enrollment/AssessmentOfFee/AssessmentDetail/AssessmentLabMode/List";
// -- ASSESSMENT TEMPLATE SCHEDULE DETAIL -- //
import AssessmentTemplateScheduleDetail from "./modules/Admin/Enrollment/AssessmentOfFee/AssessmentSchedule/AssessmentScheduleDetail/List";
// -- ASSESSMENT TEMPLATE OTHER RATE -- //
import AssessmentTemplateOtherRate from "./modules/Admin/Enrollment/AssessmentOfFee/AssessmentOther/AssessmentOtherRate/List";
// -- APPLICANT VERIFICATION -- //
import ApplicantVerificationList from "./modules/Admin/Enrollment/ApplicantVerification/List";
import VerifiedApplicantsForm from "./modules/Admin/Enrollment/ApplicantVerification/ApplicantVerification";

// -- ASSESSMENT-ADD-ONS-ENTRY -- //
import AssessmentAddOnsList from "./modules/Admin/Enrollment/AssessmentAddOnsEntry/List";

// -- Campus Migrator -- //

import CampusMigrator from "./modules/Admin/Enrollment/CampusMigrator/CampusMigrator"

// -- Enrollment Progress -- //
import EnrollmentRegistration from "./modules/Admin/Enrollment/EnrollmentProgress/EnrollmentProgress";


// -- Scholarship Tagging -- //
import ScholarshipTagging from "./modules/Admin/Enrollment/ScholarshipTagging/ScholarshipTagging";

// -- Student Add Drop Subjects -- //
import StudentAddDropSubjects from "./modules/Admin/Enrollment/StudentAddDropSubjects/StudentAddDropSubjects";

// -- Assessment Printing -- //
import AssessmentPrinting from "./modules/Admin/Enrollment/AssessmentPrinting/AssessmentPrinting";

// -- Applicant Report -- //
import ApplicantReport from "./modules/Admin/Enrollment/ApplicantReport/ApplicantReport";


// -- Add and Drop Subject -- //
import AddDropSubject from "./modules/Admin/Enrollment/AddDropSubject/AddDropSubject";
import AddDropStudentDetails from "./modules/Admin/Enrollment/AddDropSubject/Details";


// -- STUDENT ENROLLMENT TRACER -- //
import StudentEnrollmentTracer from "./modules/Admin/Enrollment/StudentEnrollmentTracer/List";

// -- INTENT TO ENROLL VERIFICATION -- //
import IntentToEnrollVerification from "./modules/Admin/Enrollment/IntentToEnrollVerification/IntentToEnrollVerification";

// -- ENROLLMENT DROPPING == //
import EnrollmentDropping from "./modules/Admin/Enrollment/EnrollmentDropping/EnrollmentDropping";
import EnrollmentRecord from "./modules/Admin/Enrollment/EnrollmentDropping/EnrollmentRecord";

// ----------------------------------------------------------- STUDENT -------------------------------------------------------------------- //
// -- PRE-ASSESSMENT -- //
import StudentPreAssessment from "./modules/Admin/Student/PreAssessment/Assessment";

// -- RECIEVE-DOCUMENTS -- //
import RecieveDocumentsList from "./modules/Admin/Student/RecieveDocuments/List";
import RecieveDocumentsForm from "./modules/Admin/Student/RecieveDocuments/RecieveDoc";

// -- STUDENT-MASTER-FILE -- //
import StudentMasterFile from "./modules/Admin/Student/StudentMasterFile/StudentMasterFile";
import StudentMasterFileDetails from "./modules/Admin/Student/StudentMasterFile/StudentMasterFileDetails";
import ApplicantDataEntry from "./modules/Admin/Student/ApplicantDataEntryDetail/List";
import StudentAssessment from "./modules/Admin/Student/ApplicantDataEntryDetail/Assessment";
import NewStudentAssessment from "./modules/Admin/Student/ApplicantDataEntryDetail/CreateAssessment"
import updateStudent from "./modules/Admin/Student/ApplicantDataEntryDetail/Update";

// -- STUDENT-201 -- //
import Student201 from "./modules/Admin/Student/Student201/Student201";

// -- CHANGE COURSE -- //
import ChangeCourse from "./modules/Admin/Student/ChangeCourse/ChangeCourse";

// -- GRADE TEMPLATE -- //
import GradeTemplate from "./modules/Admin/Student/GradeTemplate/List"
import GradeTemplateComposition from "./modules/Admin/Student/GradeTemplate/GradeTemplate"


// -- INSTRUCTIONAL GUIDE -- //
import InstructionalGuide from "./modules/Admin/Student/InstructionalGuide/List"
import InstructionalGuideDetails from "./modules/Admin/Student/InstructionalGuide/IntructionalGuide"



// -- GRADE BOOK -- //
import GradeBook from "./modules/Admin/Student/GradeBook/List"
import GradeBookDetails from "./modules/Admin/Student/GradeBook/GradeBook"


// -- LIST OF SUBJECTS -- //
import ListOfSubjects from "./modules/Admin/Student/ListOfSubjects/List";
import ListOfStudents from "./modules/Admin/Student/ListOfSubjects/StudentList"


// -- STUDENT-CURRICULUM -- //
import StudentCurriculumList from "./modules/Admin/Student/StudentCurriculum/List";


// -- GRADE BOOK -- //
import StudentGradeViewing from "./modules/Admin/Student/StudentGrades/StudentGradeViewing"

// -- STUDENT CLEARANCE -- //
import StudentClearance from "./modules/Admin/Student/StudentClearance/StudentClearance";


// -- GRADE AUTHENTICATION -- //
import GradeAuthentication from "./modules/Admin/Student/GradeAuthentication/GradeAuthentication";
import GradeAuthenticationDetail from "./modules/Admin/Student/GradeAuthentication/GradeAuthenticationDetail";

// -- STUDENT GRADE ASSESMENT -- //
import StudentGradeAssessment from "./modules/Admin/Student/StudentGradeAssessment/StudentGradeAssessment"

// -- STUDENT ATTENDANCE -- //
import StudentAttendance from "./modules/Admin/Student/StudentAttendance/StudentAttendance"

// -- CLASS RECORD -- //
import ClassRecord from "./modules/Admin/Student/ClassRecord/ClassRecord";
import PrintClassRecord from "./modules/Admin/Student/ClassRecord/PrintClassRecord";

// -- PLAIN REPORT LAYOUT -- //
import PlainReportLayout from "./modules/Report/ClassRecord/PlainReportLayout";

// -- STUDENT ACCOUNT SUMMARY-- This is similar to Cashiering (Student Account) module w/o Recompute and Printing //
import AccountSummarySearch from "./modules/Admin/Student/AccountSummary/AccountSummarySearch";
import AccountSummary from "./modules/Admin/Student/AccountSummary/AccountSummary";
// ----------------------------------------------------------- REFERENCES ------------------------------------------------------------------- //
// -- FEE -- //
import Fee from "./modules/Admin/References/Fee/List";
import CreateFee from "./modules/Admin/References/Fee/Create";
import UpdateFee from "./modules/Admin/References/Fee/Update";
import DeleteFee from "./modules/Admin/References/Fee/Delete";
// -- FEE GROUP -- //
import FeeGroup from "./modules/Admin/References/FeeGroup/List";
import CreateFeeGroup from "./modules/Admin/References/FeeGroup/Create";
import UpdateFeeGroup from "./modules/Admin/References/FeeGroup/Update";
import DeleteFeeGroup from "./modules/Admin/References/FeeGroup/Delete";
// -- SCHOLARSHIP TAG -- //
import ScholarshipTag from "./modules/Admin/References/ScholarshipTag/List";
import CreateScholarshipTag from "./modules/Admin/References/ScholarshipTag/Create";
import UpdateScholarshipTag from "./modules/Admin/References/ScholarshipTag/Update";
import DeleteScholarshipTag from "./modules/Admin/References/ScholarshipTag/Delete";
// -- SCHOLARSHIP -- //
import Scholarship from "./modules/Admin/References/Scholarship/List";
import CreateScholarship from "./modules/Admin/References/Scholarship/Create";
import UpdateScholarship from "./modules/Admin/References/Scholarship/Update";
import DeleteScholarship from "./modules/Admin/References/Scholarship/Delete";
// -- SCHOLARSHIP FEE GROUP -- //
import ScholarshipFeeGroup from "./modules/Admin/References/Scholarship/ScholarshipFeeGroup/List";
import CreateScholarshipFeeGroup from "./modules/Admin/References/Scholarship/ScholarshipFeeGroup/Create";
import UpdateScholarshipFeeGroup from "./modules/Admin/References/Scholarship/ScholarshipFeeGroup/Update";
import DeleteScholarshipFeeGroup from "./modules/Admin/References/Scholarship/ScholarshipFeeGroup/Delete";
// -- INSTITUTE -- //
import Institute from "./modules/Admin/References/Institute/List";
import CreateInstitute from "./modules/Admin/References/Institute/Create";
import UpdateInstitute from "./modules/Admin/References/Institute/Update";
import DeleteInstitute from "./modules/Admin/References/Institute/Delete";
// -- STUDENT CATEGORY -- //
import StudentCategory from "./modules/Admin/References/StudentCategory/List";
import CreateStudentCategory from "./modules/Admin/References/StudentCategory/Create";
import UpdateStudentCategory from "./modules/Admin/References/StudentCategory/Update";
import DeleteStudentCategory from "./modules/Admin/References/StudentCategory/Delete";
// -- COURSE PROGRAM -- //
import CourseProgram from "./modules/Admin/References/Course/List";
import CreateCourseProgram from "./modules/Admin/References/Course/Create";
import UpdateCourseProgram from "./modules/Admin/References/Course/Update";
import DeleteCourseProgram from "./modules/Admin/References/Course/Delete";
// -- YEAR LEVEL -- //
import YearLevel from "./modules/Admin/References/YearLevel/List";
import CreateYearLevel from "./modules/Admin/References/YearLevel/Create";
import UpdateYearLevel from "./modules/Admin/References/YearLevel/Update";
import DeleteYearLevel from "./modules/Admin/References/YearLevel/Delete";
// -- CLASS SECTION -- //
import ClassSection from "./modules/Admin/References/ClassSection/List";
import UpdateClassSection from "./modules/Admin/References/ClassSection/Update";
import DeleteClassSection from "./modules/Admin/References/ClassSection/Delete";
// -- SCHOOL YEAR -- //
import SchoolYear from "./modules/Admin/References/SchoolYear/List";
import CreateSchoolYear from "./modules/Admin/References/SchoolYear/Create";
import UpdateSchoolYear from "./modules/Admin/References/SchoolYear/Update";
import DeleteSchoolYear from "./modules/Admin/References/SchoolYear/Delete";
// -- SEMESTER -- //
import Semester from "./modules/Admin/References/Semester/List";
import CreateSemester from "./modules/Admin/References/Semester/Create";
import UpdateSemester from "./modules/Admin/References/Semester/Update";
import DeleteSemester from "./modules/Admin/References/Semester/Delete";
// -- CIVIL STATUS -- //
import CivilStatus from "./modules/Admin/References/CivilStatus/List";
import CreateCivilStatus from "./modules/Admin/References/CivilStatus/Create";
import UpdateCivilStatus from "./modules/Admin/References/CivilStatus/Update";
import DeleteCivilStatus from "./modules/Admin/References/CivilStatus/Delete";
// -- MODE OF PAYMENT -- //
import ModeOfPayment from "./modules/Admin/References/ModeOfPayment/List";
import CreateModeOfPayment from "./modules/Admin/References/ModeOfPayment/Create";
import UpdateModeOfPayment from "./modules/Admin/References/ModeOfPayment/Update";
import DeleteModeOfPayment from "./modules/Admin/References/ModeOfPayment/Delete";
// -- PAYMENT SCHEDULE -- //
import PaymentSchedule from "./modules/Admin/References/PaymentSchedule/List";
import CreatePaymentSchedule from "./modules/Admin/References/PaymentSchedule/Create";
import UpdatePaymentSchedule from "./modules/Admin/References/PaymentSchedule/Update";
import DeletePaymentSchedule from "./modules/Admin/References/PaymentSchedule/Delete";
// -- RELIGION -- //
import Religion from "./modules/Admin/References/Religion/List";
import CreateReligion from "./modules/Admin/References/Religion/Create";
import UpdateReligion from "./modules/Admin/References/Religion/Update";
import DeleteReligion from "./modules/Admin/References/Religion/Delete";
// -- SCHOOL -- //
import School from "./modules/Admin/References/School/List";
import CreateSchool from "./modules/Admin/References/School/Create";
import UpdateSchool from "./modules/Admin/References/School/Update";
import DeleteSchool from "./modules/Admin/References/School/Delete";
// -- STUDY TYPE -- //
import StudyType from "./modules/Admin/References/StudyType/List";
import CreateStudyType from "./modules/Admin/References/StudyType/Create";
import UpdateStudyType from "./modules/Admin/References/StudyType/Update";
import DeleteStudyType from "./modules/Admin/References/StudyType/Delete";
// -- SUBJECT TYPE -- //
import SubjectType from "./modules/Admin/References/SubjectType/List";
import CreateSubjectType from "./modules/Admin/References/SubjectType/Create";
import UpdateSubjectType from "./modules/Admin/References/SubjectType/Update";
import DeleteSubjectType from "./modules/Admin/References/SubjectType/Delete";
// -- SUBJECT CATEGORY -- //
import SubjectCategory from "./modules/Admin/References/SubjectCategory/List";
import CreateSubjectCategory from "./modules/Admin/References/SubjectCategory/Create";
import UpdateSubjectCategory from "./modules/Admin/References/SubjectCategory/Update";
import DeleteSubjectCategory from "./modules/Admin/References/SubjectCategory/Delete";
// -- SUBJECT -- //
import Subject from "./modules/Admin/References/Subject/List";
import CreateSubject from "./modules/Admin/References/Subject/Create";
import UpdateSubject from "./modules/Admin/References/Subject/Update";
import DeleteSubject from "./modules/Admin/References/Subject/Delete";
import SubjectDetails from "./modules/Admin/References/Subject/SubjectDetails";
// -- COUNTRY -- //
import Country from "./modules/Admin/References/Country/List";
import CreateCountry from "./modules/Admin/References/Country/Create";
import UpdateCountry from "./modules/Admin/References/Country/Update";
import DeleteCountry from "./modules/Admin/References/Country/Delete";
// -- PROVINCE -- //
import Province from "./modules/Admin/References/Province/List";
import CreateProvince from "./modules/Admin/References/Province/Create";
import UpdateProvince from "./modules/Admin/References/Province/Update";
import DeleteProvince from "./modules/Admin/References/Province/Delete";
// -- MUNICIPALITY -- //
import Municipality from "./modules/Admin/References/Municipality/List";
import CreateMunicipality from "./modules/Admin/References/Municipality/Create";
import UpdateMunicipality from "./modules/Admin/References/Municipality/Update";
import DeleteMunicipality from "./modules/Admin/References/Municipality/Delete";
// -- NATIONALITY -- //
import Nationality from "./modules/Admin/References/Nationality/List";
import CreateNationality from "./modules/Admin/References/Nationality/Create";
import UpdateNationality from "./modules/Admin/References/Nationality/Update";
import DeleteNationality from "./modules/Admin/References/Nationality/Delete";
// -- EDUCATION -- //
import Education from "./modules/Admin/References/Education/List";
import CreateEducation from "./modules/Admin/References/Education/Create";
import UpdateEducation from "./modules/Admin/References/Education/Update";
import DeleteEducation from "./modules/Admin/References/Education/Delete";
// -- COURSE OUTLINE -- //
import CourseOutline from "./modules/Admin/References/CourseOutline/List";
import CreateCourseOutline from "./modules/Admin/References/CourseOutline/Create";
import UpdateCourseOutline from "./modules/Admin/References/CourseOutline/Update";
import DeleteCourseOutline from "./modules/Admin/References/CourseOutline/Delete";
// -- COURSE OUTLINE DETAIL -- //
import CourseOutlineDetail from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/List";
import CreateCourseOutlineDetail from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/Create";
import UpdateCourseOutlineDetail from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/Update";
import DeleteCourseOutlineDetail from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/Delete";
// -- COURSE OUTLINE DETAIL REQ -- //
import CourseOutlineDetailReq from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/CourseOutlineReq/List";
import CreateCourseOutlineDetailReq from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/CourseOutlineReq/Create";
import UpdateCourseOutlineDetailReq from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/CourseOutlineReq/Update";
import DeleteCourseOutlineDetailReq from "./modules/Admin/References/CourseOutline/CourseOutlineDetail/CourseOutlineReq/Delete";
// -- ROOM -- //
import Room from "./modules/Admin/References/Room/List";
import CreateRoom from "./modules/Admin/References/Room/Create";
import UpdateRoom from "./modules/Admin/References/Room/Update";
import DeleteRoom from "./modules/Admin/References/Room/Delete";
// -- ASSESSMENT COMPUTATION TYPE -- //
import ComputationType from "./modules/Admin/References//ComputationType/List";
import CreateComputationType from "./modules/Admin/References/ComputationType/Create";
import UpdateComputationType from "./modules/Admin/References/ComputationType/Update";
import DeleteComputationType from "./modules/Admin/References/ComputationType/Delete";

// -- FAMILY NET INCOME -- //
import FamilyNetIncome from "./modules/Admin/References/FamilyNetIncome/List";
import CreateFamilyNetIncome from "./modules/Admin/References/FamilyNetIncome/Create";
import UpdateFamilyNetIncome from "./modules/Admin/References/FamilyNetIncome/Update";
import DeleteFamilyNetIncome from "./modules/Admin/References/FamilyNetIncome/Delete";
// -- EDUCATIONAL PLAN -- //
import EducationalPlan from "./modules/Admin/References/EducationalPlan/List";
import CreateEducationalPlan from "./modules/Admin/References/EducationalPlan/Create";
import UpdateEducationalPlan from "./modules/Admin/References/EducationalPlan/Update";
import DeleteEducationalPlan from "./modules/Admin/References/EducationalPlan/Delete";
// -- FAMILY POSITION -- //
import FamilyPosition from "./modules/Admin/References/FamilyPosition/List";
import CreateFamilyPosition from "./modules/Admin/References/FamilyPosition/Create";
import UpdateFamilyPosition from "./modules/Admin/References/FamilyPosition/Update";
import DeleteFamilyPosition from "./modules/Admin/References/FamilyPosition/Delete";


import GradeElement from "./modules/Admin/References/GradeElement/List"


// ----------------------------------------------------------- REPORTS ------------------------------------------------------------------- //
// -- ENROLLMENT REPORTS -- //
import EnrollmentReports from "./modules/Admin/Reports/EnrollmentReports/EnrollmentReports";
import ChedEnrollmentReport from "./modules/Admin/Reports/ChedEnrollmentReport/ChedEnrollmentReport";
import EnrollmentStatistics from "./modules/Admin/Reports/EnrollmentStatistics/EnrollmentStatistics";


// -- ADD DROP REPORTS -- //
import AddDropReport from "./modules/Admin/Reports/AddDropReport/AddDropReport"

// -- CLEARANCE REPORTS -- //
import ClearanceReport from "./modules/Admin/Reports/ClearanceReport/ClearanceReport"

// -- GRADE SUBMISSION MONITORING -- //
import GradeSubmissionMonitoring from "./modules/Admin/Reports/GradeSubmissionMonitoring/GradeSubmissionMonitoring"
import PrintGradeSubmissionMonitoring from "./modules/Admin/Reports/GradeSubmissionMonitoring/PrintGradeSubmissionMonitoring";

// -- FACULTY LOADING REPORT -- //
import FacultyLoadingReport from "./modules/Admin/Reports/FacultyLoadingReports/FacultyLoadingReport";

// -- ROOM FACILITY REPORT -- //
import RoomFacilityReport from "./modules/Admin/Reports/RoomFacilityReport/RoomFacilityReport";

// -- CURRICULUM REPORT -- //

import CurriculumReport from "./modules/Admin/Reports/CurriculumReport/CurriculumReport";


// -- SUBJECT SCHEDULE REPORT -- //
import SubjectScheduleReport from "./modules/Admin/Reports/SubjectScheduleReport/SubjectScheduleReport";


// -- SUBJECT SCHEDULE REPORT -- //
import ReportBySection from "./modules/Admin/Reports/ReportBySection/ReportBySection";
import { Grade } from "@material-ui/icons";


// -- SUBJECT SUMMARY REPORT -- //
import SummarySubjectReport from "./modules/Admin/Reports/SummarySubjectReport/SummarySubjectReport";

// -- ENROLLMENT SCHEDULE REPORT -- //
import EnrollmentScheduleReport from "./modules/Admin/Reports/EnrollmentScheduleReport/EnrollmentScheduleReport";


// -- ASSESSMENT REPORT -- //
import AssessmentReport from "./modules/Admin/Reports/AssessmentReport/AssessmentReport";



// -- SPECIAL EXAM PERMIT -- //
import SpecialExamPermit from "./modules/Admin/Reports/SpecialExamPermit/SpecialExamPermit";


// -- SUMMARY OF GRADES -- //
import SummaryOfGrades from "./modules/Admin/Student/SummaryOfGrades/SummaryOfGrades";
import PrintSummaryOfGrades from "./modules/Admin/Student/SummaryOfGrades/PrintSummaryOfGrades";


// -- HONOR LIST -- //
import HonorList from "./modules/Admin/Student/HonorList/HonorList";
import PrintHonorList from "./modules/Admin/Student/HonorList/PrintHonorList";

// ----------------------------------------------------------- SETTINGS ------------------------------------------------------------------- //
// -- USER MAINTENANCE -- //
import UserMaintenance from "./modules/Admin/Settings/UserMaintenance/UserMaintenance";
import SystemConfigurations from "./modules/Admin/Settings/SystemConfigurations/SystemConfigurations";


var routes = [
  // AUTH
  {
    path: "/login",
    name: "Login",
    component: Login,
    layout: "/auth"
  },

  {
    path: "/forgotpassword",
    name: "Forgot Password",
    component: SignUpInitial,
    layout: "/auth"
  },
  {
    path: "/signup",
    name: "Sign Up",
    component: SignUpInitial,
    layout: "/auth"
  },
  {
    path: "/changepassword/:accessCode",
    name: "Forgot Password",
    component: SignUpMain,
    layout: "/auth"
  },
  {
    path: "/user-signup/:accessCode",
    name: "Sign Up",
    component: SignUpMain,
    layout: "/auth"
  },

  //Navigation
  {
    path: "/profile",
    name: "Profile",
    component: Profile,
    layout: "/admin",
    subLayout: "/user",
    breadcrumbs: [{
      title: "User Profile",
      pathname: "/admin/user/profile"
    }]
  },



  // ADMIN
  {
    path: "/dashboard",
    name: "Dashboard",
    component: Dashboard,
    layout: "/admin",
    subLayout: "",
    breadcrumbs: [{
      title: "Dashboard",
      pathname: "/admin/dashboard"
    }]
  },


  {
    path: "/cashier",
    name: "Cashier",
    component: Cashiering,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Cashiering",
      pathname: "/admin/accounting/cashier"
    }]
  },

  {
    path: "/cashier-payment/:id",
    name: "Payment",
    component: CreatePayment,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Cashiering",
      pathname: "/admin/accounting/cashier-payment"
    }]
  },

  {
    path: "/direct-payment",
    name: "Payment",
    component: DirectCashierPayment,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Cashiering",
      pathname: "/admin/accounting/direct-payment"
    }]
  },

  {
    path: "/cashier-chart/:id",
    name: "Payment",
    component: ChartPayment,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Cashiering",
      pathname: "/admin/accounting/cashier-chart"
    }]
  },




  {
    path: "/student-account",
    name: "Student Account",
    component: StudentAccountList,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Student Account",
      pathname: "/admin/accounting/student-account"
    }]
  },

  {
    path: "/student-account-assessment/:id",
    name: "Student Account",
    component: StudentAccountAssessment,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Cashiering",
      pathname: "/admin/accounting/student-account-assessment"
    }]
  },

  {
    path: "/print-receipt",
    name: "Student Account",
    component: PrintReceipt,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Print Receipt (Campus)",
      pathname: "/admin/accounting/print-receipt"
    }]
  },

  {
    path: "/payment-reports",
    name: "Payment Reports",
    component: PaymentReport,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Payment Reports",
      pathname: "/admin/accounting/payment-reports"
    }]
  },

  {
    path: "/student-balance-reports",
    name: "Student Balance Reports",
    component: StudentBalanceReports,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Student Balance Reports",
      pathname: "/admin/reports/student-balance-reports"
    }]
  },


  {
    path: "/assign-official-receipt",
    name: "Assign Official Receipt",
    component: RecieptAssignment,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Assign Official Receipt",
      pathname: " /admin/accounting/assign-official-receipt"
    }]
  },


  {
    path: "/payment-request",
    name: "Payment Request",
    component: PaymentRequest,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Payment Request",
      pathname: " /admin/accounting/payment-request"
    }]
  },

  {
    path: "/or-reclassification",
    name: "OR Reclassification",
    component: ORReclassification,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "OR Reclassification",
      pathname: "/admin/accounting/or-reclassification"
    }]
  },


  {
    path: "/official-receipt-details",
    name: "Official Receipt Details",
    component: OfficialRecieptDetails,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "OR Reclassification",
      pathname: "/admin/accounting/official-receipt-details"
    }]
  },


  {
    path: "/student-grant-disclosure",
    name: "Grant Disclosure List",
    component: GrantDisclosureList,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Grant Disclosure List",
      pathname: "/admin/accounting/student-grant-disclosure"
    }]
  },




  {
    path: "/fees",
    name: "Fees",
    component: Fee,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Fees",
      pathname: "/admin/references/fees"
    }]
  },
  {
    path: "/create-fees",
    name: "Create Fees",
    component: CreateFee,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Fees",
      pathname: "/admin/references/create-fees"
    }]
  },
  {
    path: "/update-fees/:id",
    name: "Update Fees",
    component: UpdateFee,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Fees",
      pathname: "/admin/references/update-fees"
    }]
  },
  {
    path: "/delete-fees/:id",
    name: "Delete Fees",
    component: DeleteFee,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Fees",
      pathname: "/admin/references/delete-fees"
    }]
  },
  {
    path: "/fee-groups",
    name: "Fee Groups",
    component: FeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Fee Groups",
      pathname: "/admin/references/fee-groups"
    }]
  },
  {
    path: "/create-fee-groups",
    name: "Create Fee Groups",
    component: CreateFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Fee Groups",
      pathname: "/admin/references/create-fee-groups"
    }]
  },
  {
    path: "/update-fee-groups/:id",
    name: "Update Fee Groups",
    component: UpdateFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Fee Groups",
      pathname: "/admin/references/update-fee-groups"
    }]
  },
  {
    path: "/delete-fee-groups/:id",
    name: "Delete Fee Groups",
    component: DeleteFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Fee Groups",
      pathname: "/admin/references/delete-fee-groups"
    }]
  },
  {
    path: "/scholarships",
    name: "Scholarships",
    component: Scholarship,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Scholarships",
      pathname: "/admin/references/scholarships"
    }]
  },
  {
    path: "/create-scholarships",
    name: "Create Scholarship",
    component: CreateScholarship,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Scholarship",
      pathname: "/admin/references/create-scholarships"
    }]
  },
  {
    path: "/update-scholarships/:id",
    name: "Update Scholarship",
    component: UpdateScholarship,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Scholarship",
      pathname: "/admin/references/update-scholarships"
    }]
  },
  {
    path: "/delete-scholarships/:id",
    name: "Delete Scholarship",
    component: DeleteScholarship,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Scholarship",
      pathname: "/admin/references/delete-scholarships"
    }]
  },
  {
    path: "/scholarship-feegroups/:id",
    name: "Scholarship Fee Group",
    component: ScholarshipFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Scholarship Fee Group",
      pathname: "/admin/references/scholarship-feegroups"
    }]
  },
  {
    path: "/create-scholarship-feegroups/:scholarshipId",
    name: "Create Scholarship Fee Group",
    component: CreateScholarshipFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Scholarship Fee Group",
      pathname: "/admin/references/create-scholarship-feegroups"
    }]
  },
  {
    path: "/update-scholarship-feegroups/:scholarshipId/:id",
    name: "Update Scholarship Fee Group",
    component: UpdateScholarshipFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Scholarship Fee Group",
      pathname: "/admin/references/update-scholarship-feegroups"
    }]
  },
  {
    path: "/delete-scholarship-feegroups/:scholarshipId/:id",
    name: "Delete Scholarship Fee Group",
    component: DeleteScholarshipFeeGroup,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Scholarship Fee Group",
      pathname: "/admin/references/delete-scholarship-feegroups"
    }]
  },
  {
    path: "/scholarship-tags",
    name: "Scholarship Tags",
    component: ScholarshipTag,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Scholarship Tags",
      pathname: "/admin/references/scholarship-tags"
    }]
  },
  {
    path: "/create-scholarship-tags",
    name: "Create Scholarship Tags",
    component: CreateScholarshipTag,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Scholarship Tags",
      pathname: "/admin/references/create-scholarship-tags"
    }]
  },
  {
    path: "/update-scholarship-tags/:id",
    name: "Update Scholarship Tags",
    component: UpdateScholarshipTag,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Scholarship Tags",
      pathname: "/admin/references/update-scholarship-tags"
    }]
  },
  {
    path: "/delete-scholarship-tags/:id",
    name: "Delete Scholarship Tags",
    component: DeleteScholarshipTag,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Scholarship Tags",
      pathname: "/admin/references/delete-scholarship-tags"
    }]
  },
  {
    path: "/institutes",
    name: "Institute",
    component: Institute,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Institute",
      pathname: "/admin/references/institutes"
    }]
  },
  {
    path: "/create-institutes",
    name: "Create Institute",
    component: CreateInstitute,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Institute",
      pathname: "/admin/references/create-institutes"
    }]
  },
  {
    path: "/update-institutes/:id",
    name: "Update Institute",
    component: UpdateInstitute,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Institute",
      pathname: "/admin/references/update-institutes"
    }]
  },
  {
    path: "/delete-institutes/:id",
    name: "Delete Institute",
    component: DeleteInstitute,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Institute",
      pathname: "/admin/references/delete-institutes"
    }]
  },
  {
    path: "/student-categories",
    name: "Student Category",
    component: StudentCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Student Category",
      pathname: "/admin/references/student-categories"
    }]
  },
  {
    path: "/create-student-categories",
    name: "Create Student Category",
    component: CreateStudentCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Student Category",
      pathname: "/admin/references/create-student-categories"
    }]
  },
  {
    path: "/update-student-categories/:id",
    name: "Update Student Category",
    component: UpdateStudentCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Student Category",
      pathname: "/admin/references/update-student-categories"
    }]
  },
  {
    path: "/delete-student-categories/:id",
    name: "Delete Student Category",
    component: DeleteStudentCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Student Category",
      pathname: "/admin/references/delete-student-categories"
    }]
  },
  {
    path: "/course-program",
    name: "Course Program",
    component: CourseProgram,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Course Program",
      pathname: "/admin/references/course-program"
    }]
  },
  {
    path: "/create-course-program",
    name: "Create Course Program",
    component: CreateCourseProgram,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Course Program",
      pathname: "/admin/references/create-course-program"
    }]
  },
  {
    path: "/update-course-program/:id",
    name: "Update Course Program",
    component: UpdateCourseProgram,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Course Program",
      pathname: "/admin/references/update-course-program"
    }]
  },
  {
    path: "/delete-course-program/:id",
    name: "Delete Course Program",
    component: DeleteCourseProgram,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Course Program",
      pathname: "/admin/references/delete-course-program"
    }]
  },
  {
    path: "/yearlevels",
    name: "Year Level",
    component: YearLevel,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Year Level",
      pathname: "/admin/references/yearlevels"
    }]
  },
  {
    path: "/create-yearlevels",
    name: "Create Year Level",
    component: CreateYearLevel,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Year Level",
      pathname: "/admin/references/create-yearlevels"
    }]
  },
  {
    path: "/update-yearlevels/:id",
    name: "Update Year Level",
    component: UpdateYearLevel,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Year Level",
      pathname: "/admin/references/update-yearlevels"
    }]
  },
  {
    path: "/delete-yearlevels/:id",
    name: "Delete Year Level",
    component: DeleteYearLevel,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Year Level",
      pathname: "/admin/references/delete-yearlevels"
    }]
  },
  {
    path: "/class-section-entry",
    name: "Class Section Entry",
    component: ClassSection,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Class Section Entry",
      pathname: "/admin/references/class-section-entry"
    }]
  },

  {
    path: "/update-class-section/:id",
    name: "Update Class Section",
    component: UpdateClassSection,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Class Section",
      pathname: "/admin/references/update-class-section"
    }]
  },
  {
    path: "/delete-class-section/:id",
    name: "Delete Class Section",
    component: DeleteClassSection,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Class Section",
      pathname: "/admin/references/delete-class-section"
    }]
  },
  {
    path: "/schoolyears",
    name: "School Year",
    component: SchoolYear,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "School Year",
      pathname: "/admin/references/schoolyears"
    }]
  },
  {
    path: "/create-schoolyears",
    name: "Create School Year",
    component: CreateSchoolYear,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create School Year",
      pathname: "/admin/references/create-schoolyears"
    }]
  },
  {
    path: "/update-schoolyears/:id",
    name: "Update School Year",
    component: UpdateSchoolYear,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update School Year",
      pathname: "/admin/references/update-schoolyears"
    }]
  },
  {
    path: "/delete-schoolyears/:id",
    name: "Delete School Year",
    component: DeleteSchoolYear,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete School Year",
      pathname: "/admin/references/delete-schoolyears"
    }]
  },
  {
    path: "/semesters",
    name: "Semester",
    component: Semester,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Semester",
      pathname: "/admin/references/semesters"
    }]
  },
  {
    path: "/create-semesters",
    name: "Create Semester",
    component: CreateSemester,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Semester",
      pathname: "/admin/references/create-semesters"
    }]
  },
  {
    path: "/update-semesters/:id",
    name: "Update Semester",
    component: UpdateSemester,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Semester",
      pathname: "/admin/references/update-semesters"
    }]
  },
  {
    path: "/delete-semesters/:id",
    name: "Delete Semester",
    component: DeleteSemester,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Semester",
      pathname: "/admin/references/delete-semesters"
    }]
  },
  {
    path: "/civilstatus",
    name: "Civil Status",
    component: CivilStatus,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Civil Status",
      pathname: "/admin/references/civilstatus"
    }]
  },
  {
    path: "/create-civilstatus",
    name: "Create Civil Status",
    component: CreateCivilStatus,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Civil Status",
      pathname: "/admin/references/create-civilstatus"
    }]
  },
  {
    path: "/update-civilstatus/:id",
    name: "Update Civil Status",
    component: UpdateCivilStatus,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Civil Status",
      pathname: "/admin/references/update-civilstatus"
    }]
  },
  {
    path: "/delete-civilstatus/:id",
    name: "Delete Civil Status",
    component: DeleteCivilStatus,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Civil Status",
      pathname: "/admin/references/delete-civilstatus"
    }]
  },
  {
    path: "/mode-of-payments",
    name: "Mode of Payment",
    component: ModeOfPayment,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Mode of Payment",
      pathname: "/admin/references/mode-of-payments"
    }]
  },
  {
    path: "/create-mode-of-payments",
    name: "Create Mode of Payment",
    component: CreateModeOfPayment,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Mode of Payment",
      pathname: "/admin/references/create-mode-of-payments"
    }]
  },
  {
    path: "/update-mode-of-payments/:id",
    name: "Update Mode of Payment",
    component: UpdateModeOfPayment,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Mode of Payment",
      pathname: "/admin/references/update-mode-of-payments"
    }]
  },
  {
    path: "/delete-mode-of-payments/:id",
    name: "Delete Mode of Payment",
    component: DeleteModeOfPayment,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Mode of Payment",
      pathname: "/admin/references/delete-mode-of-payments"
    }]
  },
  {
    path: "/payment-schedules",
    name: "Payment Schedule",
    component: PaymentSchedule,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Payment Schedule",
      pathname: "/admin/references/payment-schedules"
    }]
  },
  {
    path: "/create-payment-schedules",
    name: "Create Payment Schedule",
    component: CreatePaymentSchedule,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Payment Schedule",
      pathname: "/admin/references/create-payment-schedules"
    }]
  },
  {
    path: "/update-payment-schedules/:id",
    name: "Update Payment Schedule",
    component: UpdatePaymentSchedule,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Payment Schedule",
      pathname: "/admin/references/update-payment-schedules"
    }]
  },
  {
    path: "/delete-payment-schedules/:id",
    name: "Delete Payment Schedule",
    component: DeletePaymentSchedule,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Payment Schedule",
      pathname: "/admin/references/delete-payment-schedules"
    }]
  },
  {
    path: "/religions",
    name: "Religion",
    component: Religion,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Religion",
      pathname: "/admin/references/religions"
    }]
  },
  {
    path: "/create-religions",
    name: "Create Religion",
    component: CreateReligion,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Religion",
      pathname: "/admin/references/create-religions"
    }]
  },
  {
    path: "/update-religions/:id",
    name: "Update Religion",
    component: UpdateReligion,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Religion",
      pathname: "/admin/references/update-religions"
    }]
  },
  {
    path: "/delete-religions/:id",
    name: "Delete Religion",
    component: DeleteReligion,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Religion",
      pathname: "/admin/references/delete-religions"
    }]
  },
  {
    path: "/study-types",
    name: "Study Type",
    component: StudyType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Study Type",
      pathname: "/admin/references/study-types"
    }]
  },
  {
    path: "/create-study-types",
    name: "Create Study Type",
    component: CreateStudyType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Study Type",
      pathname: "/admin/references/create-study-types"
    }]
  },
  {
    path: "/update-study-types/:id",
    name: "Update Study Type",
    component: UpdateStudyType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Study Type",
      pathname: "/admin/references/update-study-types"
    }]
  },
  {
    path: "/delete-study-types/:id",
    name: "Delete Study Type",
    component: DeleteStudyType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Study Type",
      pathname: "/admin/references/delete-study-types"
    }]
  },
  {
    path: "/subject-types",
    name: "Subject Type",
    component: SubjectType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Subject Type",
      pathname: "/admin/references/subject-types"
    }]
  },
  {
    path: "/create-subject-types",
    name: "Create Subject Type",
    component: CreateSubjectType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Subject Type",
      pathname: "/admin/references/create-subject-types"
    }]
  },
  {
    path: "/update-subject-types/:id",
    name: "Update Subject Type",
    component: UpdateSubjectType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Subject Type",
      pathname: "/admin/references/update-subject-types"
    }]
  },
  {
    path: "/delete-subject-types/:id",
    name: "Delete Subject Type",
    component: DeleteSubjectType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Subject Type",
      pathname: "/admin/references/delete-subject-types"
    }]
  },
  {
    path: "/subject-categories",
    name: "Subject Category",
    component: SubjectCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Subject Category",
      pathname: "/admin/references/subject-categories"
    }]
  },
  {
    path: "/create-subject-categories",
    name: "Create Subject Category",
    component: CreateSubjectCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Subject Category",
      pathname: "/admin/references/create-subject-categories"
    }]
  },
  {
    path: "/update-subject-categories/:id",
    name: "Update Subject Category",
    component: UpdateSubjectCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Subject Category",
      pathname: "/admin/references/update-subject-categories"
    }]
  },
  {
    path: "/delete-subject-categories/:id",
    name: "Delete Subject Category",
    component: DeleteSubjectCategory,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Subject Category",
      pathname: "/admin/references/delete-subject-categories"
    }]
  },
  {
    path: "/subjects",
    name: "Subject",
    component: Subject,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Subject",
      pathname: "/admin/references/subjects"
    }]
  },
  {
    path: "/subject-details/:id",
    name: "Subject Detail",
    component: SubjectDetails,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Subject Detail",
      pathname: "/admin/references/subject-details"
    }]
  },


  {
    path: "/create-subjects",
    name: "Create Subject",
    component: CreateSubject,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Subject",
      pathname: "/admin/references/create-subjects"
    }]
  },
  {
    path: "/update-subjects/:id",
    name: "Update Subject",
    component: UpdateSubject,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Subject",
      pathname: "/admin/references/update-subjects"
    }]
  },
  {
    path: "/delete-subjects/:id",
    name: "Delete Subject",
    component: DeleteSubject,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Subject",
      pathname: "/admin/references/delete-subjects"
    }]
  },
  {
    path: "/countries",
    name: "Country",
    component: Country,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Country",
      pathname: "/admin/references/countries"
    }]
  },
  {
    path: "/create-countries",
    name: "Create Country",
    component: CreateCountry,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Country",
      pathname: "/admin/references/create-countries"
    }]
  },
  {
    path: "/update-countries/:id",
    name: "Update Country",
    component: UpdateCountry,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Country",
      pathname: "/admin/references/update-countries"
    }]
  },
  {
    path: "/delete-countries/:id",
    name: "Delete Country",
    component: DeleteCountry,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Country",
      pathname: "/admin/references/delete-countries"
    }]
  },
  {
    path: "/provinces",
    name: "Province",
    component: Province,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Province",
      pathname: "/admin/references/provinces"
    }]
  },
  {
    path: "/create-provinces",
    name: "Create Province",
    component: CreateProvince,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Province",
      pathname: "/admin/references/create-provinces"
    }]
  },
  {
    path: "/update-provinces/:id",
    name: "Update Province",
    component: UpdateProvince,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Province",
      pathname: "/admin/references/update-provinces"
    }]
  },
  {
    path: "/delete-provinces/:id",
    name: "Delete Province",
    component: DeleteProvince,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Province",
      pathname: "/admin/references/delete-provinces"
    }]
  },
  {
    path: "/municipalities",
    name: "Municipality",
    component: Municipality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Municipality",
      pathname: "/admin/references/municipalities"
    }]
  },
  {
    path: "/create-municipalities",
    name: "Create Municipality",
    component: CreateMunicipality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Municipality",
      pathname: "/admin/references/create-municipalities"
    }]
  },
  {
    path: "/update-municipalities/:id",
    name: "Update Municipality",
    component: UpdateMunicipality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Municipality",
      pathname: "/admin/references/update-municipalities"
    }]
  },
  {
    path: "/delete-municipalities/:id",
    name: "Delete Municipality",
    component: DeleteMunicipality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Municipality",
      pathname: "/admin/references/delete-municipalities"
    }]
  },
  {
    path: "/nationalities",
    name: "Nationality",
    component: Nationality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Nationality",
      pathname: "/admin/references/nationalities"
    }]
  },
  {
    path: "/create-nationalities",
    name: "Create Nationality",
    component: CreateNationality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Nationality",
      pathname: "/admin/references/create-nationalities"
    }]
  },
  {
    path: "/update-nationalities/:id",
    name: "Update Nationality",
    component: UpdateNationality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Nationality",
      pathname: "/admin/references/update-nationalities"
    }]
  },
  {
    path: "/delete-nationalities/:id",
    name: "Delete Nationality",
    component: DeleteNationality,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Nationality",
      pathname: "/admin/references/delete-nationalities"
    }]
  },
  {
    path: "/educations",
    name: "Education",
    component: Education,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Education",
      pathname: "/admin/references/educations"
    }]
  },
  {
    path: "/create-educations",
    name: "Create Education",
    component: CreateEducation,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Education",
      pathname: "/admin/references/create-educations"
    }]
  },
  {
    path: "/update-educations/:id",
    name: "Update Education",
    component: UpdateEducation,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Education",
      pathname: "/admin/references/update-educations"
    }]
  },
  {
    path: "/delete-educations/:id",
    name: "Delete Education",
    component: DeleteEducation,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Education",
      pathname: "/admin/references/delete-educations"
    }]
  },
  {
    path: "/course-outlines",
    name: "Course Outline",
    component: CourseOutline,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Course Outline",
      pathname: "/admin/references/course-outlines"
    }]
  },
  {
    path: "/create-course-outline",
    name: "Create Course Outline",
    component: CreateCourseOutline,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Course Outline",
      pathname: "/admin/references/create-course-outline"
    }]
  },
  {
    path: "/update-course-outline/:id",
    name: "Update Course Outline",
    component: UpdateCourseOutline,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Course Outline",
      pathname: "/admin/references/update-course-outline"
    }]
  },
  {
    path: "/delete-course-outline/:id",
    name: "Delete Course Outline",
    component: DeleteCourseOutline,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Course Outline",
      pathname: "/admin/references/delete-course-outline"
    }]
  },
  {
    path: "/course-outline-details/:id",
    name: "Course Outline Detail",
    component: CourseOutlineDetail,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Course Outline Detail",
      pathname: "/admin/references/course-outline-details"
    }]
  },
  {
    path: "/create-course-outline-details/:id",
    name: "Create Course Outline Detail",
    component: CreateCourseOutlineDetail,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Course Outline Detail",
      pathname: "/admin/references/create-course-outline-details"
    }]
  },
  {
    path: "/update-course-outline-details/:courseOutlineId/:id",
    name: "Update Course Outline Detail",
    component: UpdateCourseOutlineDetail,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Course Outline Detail",
      pathname: "/admin/references/update-course-outline-details"
    }]
  },
  {
    path: "/delete-course-outline-details/:courseOutlineId/:id",
    name: "Delete Course Outline Detail",
    component: DeleteCourseOutlineDetail,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Course Outline Detail",
      pathname: "/admin/references/delete-course-outline-details"
    }]
  },
  {
    path: "/course-outline-detail-reqs/:id",
    name: "Course Outline Detail Req",
    component: CourseOutlineDetailReq,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Course Outline Detail Req",
      pathname: "/admin/references/course-outline-detail-reqs"
    }]
  },
  {
    path: "/create-course-outline-detail-reqs/:courseOutlineId/:courseOutlineDetailId",
    name: "Create Course Outline Detail Req",
    component: CreateCourseOutlineDetailReq,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Course Outline Detail Req",
      pathname: "/admin/references/create-course-outline-detail-reqs"
    }]
  },
  {
    path: "/update-course-outline-detail-reqs/:courseOutlineId/:courseOutlineDetailId/:id",
    name: "Update Course Outline Detail Req",
    component: UpdateCourseOutlineDetailReq,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Course Outline Detail Req",
      pathname: "/admin/references/update-course-outline-detail-reqs"
    }]
  },
  {
    path: "/delete-course-outline-detail-reqs/:courseOutlineId/:courseOutlineDetailId/:id",
    name: "Delete Course Outline Detail Req",
    component: DeleteCourseOutlineDetailReq,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Course Outline Detail Req",
      pathname: "/admin/references/delete-course-outline-detail-reqs"
    }]
  },
  {
    path: "/rooms",
    name: "Room",
    component: Room,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Room",
      pathname: "/admin/references/rooms"
    }]
  },
  {
    path: "/create-rooms",
    name: "Create Room",
    component: CreateRoom,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Room",
      pathname: "/admin/references/create-rooms"
    }]
  },
  {
    path: "/update-rooms/:id",
    name: "Update Room",
    component: UpdateRoom,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Room",
      pathname: "/admin/references/update-rooms"
    }]
  },
  {
    path: "/delete-rooms/:id",
    name: "Delete Room",
    component: DeleteRoom,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Room",
      pathname: "/admin/references/delete-rooms"
    }]
  },
  {
    path: "/computation-types",
    name: "Computation Type",
    component: ComputationType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Computation Type",
      pathname: "/admin/references/computation-types"
    }]
  },
  {
    path: "/create-computation-types",
    name: "Create Computation Type",
    component: CreateComputationType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Computation Type",
      pathname: "/admin/references/create-computation-types"
    }]
  },
  {
    path: "/update-computation-types/:id",
    name: "Update Computation Type",
    component: UpdateComputationType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Computation Type",
      pathname: "/admin/references/update-computation-types"
    }]
  },
  {
    path: "/delete-computation-types/:id",
    name: "Delete Computation Type",
    component: DeleteComputationType,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Computation Type",
      pathname: "/admin/references/delete-computation-types"
    }]
  },


  {
    path: "/grade-element",
    name: "Grade Element",
    component: GradeElement,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Grade Elements",
      pathname: "/admin/references/grade-element"
    }]
  },

  // {
  //   path: "/subject-schedules",
  //   name: "Subject Schedule",
  //   component: SubjectScheduleForm,
  //   layout: "/admin",
  //   subLayout: "/enrollment",
  //   breadcrumbs: [{
  //     title: "Subject Schedule",
  //     pathname: "/admin/enrollment/subject-schedules"
  //   }]
  // },
  {
    path: "/subject-schedules",
    name: "Subject Schedule",
    component: SubjectSchedule,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Subject Schedule",
      pathname: "/admin/references/subject-schedules"
    }]
  },


  {
    path: "/subject-schedule-details/:id",
    name: "Subject Schedule Detail",
    component: SubjectScheduleDetail,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Subject Schedule Detail",
      pathname: "/admin/references/subject-schedule-details"
    }]
  },




  {
    path: "/assessment-of-fees",
    name: "Assessment of Fee",
    component: AssessmentOfFee,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment of Fee",
      pathname: "/admin/enrollment/assessment-of-fees"
    }]
  },
  {
    path: "/create-assessment-of-fees",
    name: "Create Assessment of Fee",
    component: CreateAssessmentOfFee,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Create Assessment of Fee",
      pathname: "/admin/enrollment/create-assessment-of-fees"
    }]
  },
  {
    path: "/update-assessment-of-fees/:id",
    name: "Update Assessment of Fee",
    component: UpdateAssessmentOfFee,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Update Assessment of Fee",
      pathname: "/admin/enrollment/update-assessment-of-fees"
    }]
  },
  {
    path: "/delete-assessment-of-fees/:id",
    name: "Delete Assessment of Fee",
    component: DeleteAssessmentOfFee,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Delete Assessment of Fee",
      pathname: "/admin/enrollment/delete-assessment-of-fees"
    }]
  },
  {
    path: "/assessment-of-fee-details/:id",
    name: "Assessment of Fee Details",
    component: AssessmentTemplateDetailContainer,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment of Fee Details",
      pathname: "/admin/enrollment/assessment-of-fee-details"
    }]
  },
  {
    path: "/assessment-lab/:id/:detailId/:labId",
    name: "Assessment Laboratory",
    component: AssessmentLabModeDetail,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment of Fee Detail Others",
      pathname: "/admin/enrollment/assessment-lab"
    }]
  },
  {
    path: "/assessment-of-fee-detail-others/:assessmentTemplateId/:id",
    name: "Assessment of Fee Detail Others",
    component: AssessmentTemplateDetailOther,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment of Fee Detail Others",
      pathname: "/admin/enrollment/assessment-of-fee-detail-others"
    }]
  },
  {
    path: "/assessment-of-fee-schedule-details/:assessmentTemplateId/:id",
    name: "Assessment of Fee Schedule Details",
    component: AssessmentTemplateScheduleDetail,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment of Fee Schedule Details",
      pathname: "/admin/enrollment/assessment-of-fee-schedule-details"
    }]
  },
  {
    path: "/assessment-of-fee-other-rates/:assessmentTemplateId/:id",
    name: "Assessment of Fee Other Rates",
    component: AssessmentTemplateOtherRate,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment of Fee Other Rates",
      pathname: "/admin/enrollment/assessment-of-fee-other-rates"
    }]
  },
  {
    path: "/applicant-verification",
    name: "Applicant Verification",
    component: ApplicantVerificationList,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Applicant Verification",
      pathname: "/admin/enrollment/applicant-verification"
    }]
  },

  {
    path: "/applicant-verification-form/:id",
    name: "Applicant Verification",
    component: VerifiedApplicantsForm,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Applicant Verification",
      pathname: "/admin/enrollment/applicant-verification-form"
    }]
  },


  {
    path: "/faculty-loading",
    name: "Faculty Loading",
    component: FacultyLoadingList,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Faculty Loading",
      pathname: "/admin/enrollment/faculty-loading"
    }]
  },

  {
    path: "/faculty-loading-details/:id",
    name: "Faculty Loading",
    component: FacultyLoadingDetail,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Faculty Loading Detail",
      pathname: "/admin/enrollment/faculty-loading-details"
    }]
  },

  {
    path: "/faculty-loading-detail-lists/:id",
    name: "Faculty Loading Faculty List",
    component: FacultyLoadingDetailLists,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Faculty Loading Faculty List",
      pathname: "/admin/enrollment/faculty-loading-detail-lists"
    }]
  },


  {
    path: "/pre-assessment-validation",
    name: "Pre-Assessment Validation",
    component: PreAssessmentValidationList,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Pre-Assessment Validation",
      pathname: "/admin/enrollment/pre-assessment-validation"
    }]
  },

  {
    path: "/pre-assessment-validation-form/:id",
    name: "Pre-Assessment Validation",
    component: StudentPreValidationForm,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Pre-Assessment Validation",
      pathname: "/admin/enrollment/pre-assessment-validation-form"
    }]
  },



  {
    path: "/assessment-add-on",
    name: "Assessment Add-On Entry",
    component: AssessmentAddOnsList,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment Add-On Entry",
      pathname: "/admin/enrollment/assessment-add-on"
    }]
  },

  {
    path: "/campus-migrator",
    name: "Campus Migrator",
    component: CampusMigrator,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Campus Migrator",
      pathname: "/admin/enrollment/campus-migrator"
    }]
  },

  {
    path: "/enrollment-registration",
    name: "Enrollment Registration",
    component: EnrollmentRegistration,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Enrollment Registration",
      pathname: "/admin/enrollment/enrollment-registration"
    }]
  },

  {
    path: "/scholarship-tagging",
    name: "Scholarship Tagging",
    component: ScholarshipTagging,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Enrollment Registration",
      pathname: "/admin/enrollment/scholarship-tagging"
    }]
  },
  {
    path: "/student-add-drop-subjects",
    name: "Student Add Drop Subjects",
    component: StudentAddDropSubjects,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Student Add Drop Subjects",
      pathname: "/admin/enrollment/student-add-drop-subjects"
    }]
  },



  {
    path: "/assessment-printing",
    name: "Assessment Printing",
    component: AssessmentPrinting,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Assessment Printing",
      pathname: "/admin/enrollment/assessment-printing"
    }]
  },


  {
    path: "/applicant-report",
    name: "Applicant Report",
    component: ApplicantReport,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Applicant Report",
      pathname: "/admin/enrollment/applicant-report"
    }]
  },


  {
    path: "/add-drop-subjects",
    name: "Add and Drop Subjects",
    component: AddDropSubject,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Add Drop Subject",
      pathname: "/admin/enrollment/add-drop-subjects"
    }]
  },



  {
    path: "/add-drop-subjects-details/:id",
    name: "Add and Drop Subjects",
    component: AddDropStudentDetails,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Add Drop Subject",
      pathname: "/admin/enrollment/add-drop-subjects"
    }]
  },


  {
    path: "/student-enrollment-tracer",
    name: "Student Enrollment Tracer",
    component: StudentEnrollmentTracer,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Cashiering",
      pathname: "/admin/enrollment/student-enrollment-tracer"
    }]
  },

  {
    path: "/intentToEnroll-verification",
    name: "Intent to Enroll Verification",
    component: IntentToEnrollVerification,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Intent to Enroll Verification",
      pathname: "/admin/enrollment/intentToEnroll-verification"
    }]
  },

  {
    path: "/enrollment-dropping",
    name: "Enrollment Dropping",
    component: EnrollmentDropping,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Enrollment Dropping",
      pathname: "/admin/enrollment/enrollment-dropping"
    }]
  },

  {
    path: "/enrollment-subject-dropping/:id",
    name: "Enrollment Dropping",
    component: EnrollmentRecord,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Enrollment Dropping",
      pathname: "/admin/enrollment/enrollment-subject-dropping"
    }]
  },


  {
    path: "/form-submitted",
    name: "Applicant Data",
    component: FormSubmitted,
    layout: "/admin",
    subLayout: "/admissions",
    breadcrumbs: [{
      title: "Applicant Data",
      pathname: "/admin/admissions/form-submitted"
    }]
  },
  {
    path: "/form-submitted-online",
    name: "Applicant Data",
    component: FormSubmittedOnline,
    layout: "/admin",
    subLayout: "/admissions",
    breadcrumbs: [{
      title: "Applicant Data",
      pathname: "/admin/admissions/form-submitted-online"
    }]
  },
  {
    path: "/student-master-lists",
    name: "Student Masterlists",
    component: StudentMasterFile,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student Masterlist",
      pathname: "/admin/student/student-master-lists"
    }]
  },

  {
    path: "/student-master-details/:id",
    name: "Student Details",
    component: StudentMasterFileDetails,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student Details",
      pathname: "/admin/student/student-master-details"
    }]
  },




  {
    path: "/applicant-data-entry",
    name: "List of Applicant",
    component: ApplicantList,
    layout: "/admin",
    subLayout: "/admissions",
    breadcrumbs: [{
      title: "List of Applicant",
      pathname: "/admin/admissions/applicant-data-entry"
    }]
  },

  {
    path: "/student-201",
    name: "Student 201",
    component: Student201,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student 201",
      pathname: "/admin/student/student-201"
    }]
  },

  {
    path: "/change-course",
    name: "Change Course",
    component: ChangeCourse,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Change Course",
      pathname: "/admin/student/change-course"
    }]
  },

  {
    path: "/grade-template",
    name: "Grade Template",
    component: GradeTemplate,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Grade Template",
      pathname: "/admin/student/grade-template"
    }]
  },

  {
    path: "/grade-computation-template/:id",
    name: "Grade Computation Template",
    component: GradeTemplateComposition,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Grade Computation Template",
      pathname: "/admin/student/grade-computation-template"
    }]
  },



  {
    path: "/instructional-guide",
    name: "InstructionalGuide",
    component: InstructionalGuide,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "InstructionalGuide",
      pathname: "/admin/student/instructional-guide"
    }]
  },

  {
    path: "/instructional-guide-details/:id",
    name: "Instructional Guide Detail",
    component: InstructionalGuideDetails,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Instructional Guide Detail",
      pathname: "/admin/student/instructional-guide-details"
    }]
  },

  {
    path: "/gradebook",
    name: "GradeBook",
    component: GradeBook,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Grade Book",
      pathname: "/admin/student/gradebook"
    }]
  },

  {
    path: "/gradebook-details/:id",
    name: "GradeBookDetails",
    component: GradeBookDetails,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Grade Book Detail",
      pathname: "/admin/student/gradebook-details"
    }]
  },

  {
    path: "/list-of-subjects",
    name: "List of Subjects",
    component: ListOfSubjects,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "List of Subjects",
      pathname: "/admin/student/list-of-subjects"
    }]
  },


  {
    path: "/list-of-student/:id",
    name: "List of Students",
    component: ListOfStudents,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "List of Students",
      pathname: "/admin/student/list-of-students"
    }]
  },


  {
    path: "/student-curriculum",
    name: "Student Curriculum",
    component: StudentCurriculumList,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student Curriculum",
      pathname: "/admin/student/student-curriculum"
    }]
  },

  {
    path: "/individual-report-card",
    name: "Individual Report Card",
    component: StudentGradeViewing,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Individual Report Card",
      pathname: "/admin/student/individual-report-card"
    }]
  },

  {
    path: "/student-clearance",
    name: "Student Clearance",
    component: StudentClearance,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student Clearance",
      pathname: "/admin/student/student-clearance"
    }]
  },


  {
    path: "/grade-authentication",
    name: "Grade Authentication",
    component: GradeAuthentication,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Grade Authentication",
      pathname: "/admin/student/grade-authentication"
    }]
  },

  {
    path: "/grade-authentication-details/:id",
    name: "Grade Authentication Details",
    component: GradeAuthenticationDetail,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Grade Authentication Detail",
      pathname: "/admin/student/grade-authentication-details"
    }]
  },

  {
    path: "/create-applicant-data-entry/:id",
    name: "Create Applicant Data",
    component: WalkInRegistration,
    layout: "/admin",
    subLayout: "/admissions",
    breadcrumbs: [{
      title: "Create Applicant",
      pathname: "/admin/admissions/create-applicant-data-entry"
    }]
  },


  {
    path: "/create-assessment/:id",
    name: "Student-Masterfile",
    component: StudentAssessment,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student-Masterfile",
      pathname: "/admin/student/create-assessment"
    }]
  },
  {
    path: "/create-new-assessment/:id",
    name: "Student-Masterfile",
    component: NewStudentAssessment,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student-Masterfile",
      pathname: "/admin/student/create-new-assessment"
    }]
  },
  {
    path: "/update-student/:id",
    name: "Update Student Detail",
    component: updateStudent,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Update Student Detail",
      pathname: "/admin/student/update-student"
    }]
  },


  {
    path: "/family-net-income",
    name: "Family Net Income",
    component: FamilyNetIncome,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Family Net Income",
      pathname: "/admin/references/family-net-income"
    }]
  },
  {
    path: "/create-family-net-income",
    name: "Create Family Net Income",
    component: CreateFamilyNetIncome,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Family Net Income",
      pathname: "/admin/references/create-family-net-income"
    }]
  },
  {
    path: "/update-family-net-income/:id",
    name: "Update Family Net Income",
    component: UpdateFamilyNetIncome,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Family Net Income",
      pathname: "/admin/references/update-family-net-income"
    }]
  },
  {
    path: "/delete-family-net-income/:id",
    name: "Delete Family Net Income",
    component: DeleteFamilyNetIncome,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Family Net Income",
      pathname: "/admin/references/delete-family-net-income"
    }]
  },
  {
    path: "/educational-plan",
    name: "Educational Plan",
    component: EducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "EducationalPlan",
      pathname: "/admin/references/educational-plan"
    }]
  },
  {
    path: "/create-educational-plan",
    name: "Create Educational Plan",
    component: CreateEducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Educational Plan",
      pathname: "/admin/references/create-educational-plan"
    }]
  },
  {
    path: "/update-educational-plan/:id",
    name: "Update Educational Plan",
    component: UpdateEducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Educational Plan",
      pathname: "/admin/references/update-educational-plan"
    }]
  },
  {
    path: "/delete-educational-plan/:id",
    name: "Delete Educational Plan",
    component: DeleteEducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Educational Plan",
      pathname: "/admin/references/delete-educational-plan"
    }]
  },
  {
    path: "/educational-plan",
    name: "Educational Plan",
    component: EducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "EducationalPlan",
      pathname: "/admin/references/educational-plan"
    }]
  },
  {
    path: "/create-educational-plan",
    name: "Create Educational Plan",
    component: CreateEducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Educational Plan",
      pathname: "/admin/references/create-educational-plan"
    }]
  },
  {
    path: "/update-educational-plan/:id",
    name: "Update Educational Plan",
    component: UpdateEducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Educational Plan",
      pathname: "/admin/references/update-educational-plan"
    }]
  },
  {
    path: "/delete-educational-plan/:id",
    name: "Delete Educational Plan",
    component: DeleteEducationalPlan,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Educational Plan",
      pathname: "/admin/references/delete-educational-plan"
    }]
  },
  {
    path: "/family-position",
    name: "Family Position",
    component: FamilyPosition,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Family Position",
      pathname: "/admin/references/family-position"
    }]
  },
  {
    path: "/create-family-position",
    name: "Create Family Position",
    component: CreateFamilyPosition,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Create Family Position",
      pathname: "/admin/references/create-family-position"
    }]
  },
  {
    path: "/update-family-position/:id",
    name: "Update Family Position",
    component: UpdateFamilyPosition,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Update Family Position",
      pathname: "/admin/references/update-family-position"
    }]
  },
  {
    path: "/delete-family-position/:id",
    name: "Delete Family Position",
    component: DeleteFamilyPosition,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Delete Family Position",
      pathname: "/admin/references/delete-family-position"
    }]
  },
  {
    path: "/schools",
    name: "Schools",
    component: School,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Schools",
      pathname: "/admin/references/schools"
    }]
  },
  {
    path: "/create-schools",
    name: "Schools",
    component: CreateSchool,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Schools",
      pathname: "/admin/references/create-schools"
    }]
  },
  {
    path: "/update-schools/:id",
    name: "Schools",
    component: UpdateSchool,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Schools",
      pathname: "/admin/references/update-school"
    }]
  },
  {
    path: "/delete-schools/:id",
    name: "Schools",
    component: DeleteSchool,
    layout: "/admin",
    subLayout: "/references",
    breadcrumbs: [{
      title: "Schools",
      pathname: "/admin/references/delete-school"
    }]
  },


  {
    path: "/student-pre-assessment",
    name: "Pre-Assessment",
    component: StudentPreAssessment,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Pre-Assessment",
      pathname: "/admin/student/student-pre-assessment"
    }]
  },


  {
    path: "/receive-documents",
    name: "Receive Documents",
    component: RecieveDocumentsList,
    layout: "/admin",
    subLayout: "/enrollment",
    breadcrumbs: [{
      title: "Receive Documents",
      pathname: "/admin/enrollment/receive-documents"
    }]
  },

  {
    path: "/receive-documents-form/:id",
    name: "Receive Documents",
    component: RecieveDocumentsForm,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Receive Documents",
      pathname: "/admin/student/receive-documents-form"
    }]
  },


  {
    path: "/enrollment-report",
    name: "Enrollment Reports",
    component: EnrollmentReports,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Enrollment Reports",
      pathname: "/admin/reports/enrollment-report"
    }]
  },

  {
    path: "/ched-enrollment-report",
    name: "Ched Enrollment Reports",
    component: ChedEnrollmentReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Ched Enrollment Reports",
      pathname: "/admin/reports/ched-enrollment-report"
    }]
  },

  {
    path: "/enrollment-statistics",
    name: "Enrollment Statistics",
    component: EnrollmentStatistics,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Enrollment Statistics",
      pathname: "/admin/reports/enrollment-statistics"
    }]
  },

  {
    path: "/add-drop-reports",
    name: "Add and Drop Report",
    component: AddDropReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Add and Drop Report",
      pathname: "/admin/reports/add-drop-reports"
    }]
  },


  {
    path: "/faculty-loading-report",
    name: "Faculty Loading Report",
    component: FacultyLoadingReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Faculty Loading Report",
      pathname: "/admin/reports/faculty-loading-report"
    }]
  },

  {
    path: "/room-facility-reporting",
    name: "Room Facility Report",
    component: RoomFacilityReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Room Facility Report",
      pathname: "/admin/reports/room-facility-reporting"
    }]
  },

  {
    path: "/curriculum-report",
    name: "Curriculum Report",
    component: CurriculumReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Curriculum Report",
      pathname: "/admin/reports/curriculum-report"
    }]
  },

  {
    path: "/subject-schedule-report",
    name: "Subject Schedule Report",
    component: SubjectScheduleReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Subject Schedule Report",
      pathname: "/admin/reports/subject-schedule-report"
    }]
  },


  {
    path: "/report-section",
    name: "Report by Section",
    component: ReportBySection,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Report by Section",
      pathname: "/admin/reports/report-section"
    }]
  },

  {
    path: "/subjectSummary",
    name: "Report by Section",
    component: SummarySubjectReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Summary Subject Report",
      pathname: "/admin/reports/subjectSummary"
    }]
  },

  {
    path: "/enrollment-schedule-report",
    name: "Enrollment Schedule Report",
    component: EnrollmentScheduleReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Enrollment Schedule Report",
      pathname: "/admin/reports/enrollment-schedule-report"
    }]
  },

  {
    path: "/assessment-report",
    name: "Assessment Report",
    component: AssessmentReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Assessment Report",
      pathname: "/admin/reports/assessment-report"
    }]
  },

  {
    path: "/special-exam-permit",
    name: "Special Exam Permit",
    component: SpecialExamPermit,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Special Exam Permit",
      pathname: "/admin/reports/special-exam-permit"
    }]
  },


  {
    path: "/user-maintenance",
    name: "User Maintenance",
    component: UserMaintenance,
    layout: "/admin",
    subLayout: "/settings",
    breadcrumbs: [{
      title: "UserMaintenance",
      pathname: "/admin/settings/user-maintenance"
    }]
  },

  {
    path: "/system-configurations",
    name: "System Configurations",
    component: SystemConfigurations,
    layout: "/admin",
    subLayout: "/settings",
    breadcrumbs: [{
      title: "System Configurations",
      pathname: "/admin/settings/system-configurations"
    }]
  },

  {
    path: "/student-grade-assessment",
    name: "Student Grade Assessment",
    component: StudentGradeAssessment,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student Grade Assessment",
      pathname: "/admin/student/student-grade-assessment"
    }]
  },

  {
    path: "/student-attendance",
    name: "Student Attendance",
    component: StudentAttendance,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Student Attendance",
      pathname: "/admin/student/student-attendance"
    }]
  },

  {
    path: "/promissory-note-maintenance",
    name: "Promissory Note Maintenance",
    component: PromissoryNote,
    layout: "/admin",
    subLayout: "/accounting",
    breadcrumbs: [{
      title: "Promissory Note Maintenance",
      pathname: "/admin/accounting/promissory-note-maintenance"
    }]
  },

  {
    path: "/class-record",
    name: "Class Record",
    component: ClassRecord,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Class Record",
      pathname: "/admin/student/class-record"
    }]
  },

  {
    path: "/report",
    name: "Plain Report",
    component: PlainReportLayout,
    layout: "/report",
    breadcrumbs: [{
      title: "Plain Report",
      pathname: "/report"
    }]
  },

  {
    path: "/class-record",
    name: "Class Record",
    component: PrintClassRecord,
    layout: "/report",
    breadcrumbs: [{
      title: "Class Record",
      pathname: "/report/class-record"
    }]
  },

  {
    path: "/student-account-search",
    name: "Statement of Account",
    component: AccountSummarySearch,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Statement of Account",
      pathname: "/admin/student/student-account-search"
    }]
  },

  {
    path: "/student-account-summary",
    name: "Statement of Account",
    component: AccountSummary,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Statement of Account",
      pathname: "/admin/student/student-account-summary"
    }]
  },
  
  {
    path: "/summary-of-grades",
    name: "Summary of Grades",
    component: SummaryOfGrades,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Summary of Grades",
      pathname: "/admin/student/summary-of-grades"
    }]
  },

  {
    path: "/summary-of-grades",
    name: "Summary of Grades",
    component: PrintSummaryOfGrades,
    layout: "/report",
    breadcrumbs: [{
      title: "Summary of Grades",
      pathname: "/report/summary-of-grades"
    }]
  },
  
  {
    path: "/honor-list",
    name: "Honor List",
    component: HonorList,
    layout: "/admin",
    subLayout: "/student",
    breadcrumbs: [{
      title: "Honor List",
      pathname: "/admin/student/honor-list"
    }]
  },

  {
    path: "/honor-list",
    name: "Honor List",
    component: PrintHonorList,
    layout: "/report",
    breadcrumbs: [{
      title: "Honor List",
      pathname: "/report/honor-list"
    }]
  },

  {
    path: "/clearance-report",
    name: "Clearance Report",
    component: ClearanceReport,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Clearance Report",
      pathname: "/admin/reports/clearance-report"
    }]
  },

  {
    path: "/grade-submission-monitoring",
    name: "Grade Submission Monitoring",
    component: GradeSubmissionMonitoring,
    layout: "/admin",
    subLayout: "/reports",
    breadcrumbs: [{
      title: "Grade Submission Monitoring",
      pathname: "/admin/reports/grade-submission-monitoring"
    }]
  },

  {
    path: "/grade-submission-monitoring",
    name: "Grade Submission Monitoring",
    component: PrintGradeSubmissionMonitoring,
    layout: "/report",
    breadcrumbs: [{
      title: "Grade Submission Monitoring",
      pathname: "/report/grade-submission-monitoring"
    }]
  },

];

export default routes;
