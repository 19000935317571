import React from "react";
import MigratingLogo from '../../../../_assets/logo/MigratingFile.gif'




export default function MigratingLoading() {


    return (
        <>
            <div className="d-flex flex-row justify-content-center align-items-start" style={{ height: "500px" }}>
                <div className="text-center  " >

                    <div className="mx-auto d-block mb-5">
                        <img style={{ width: '70%', cursor: "none" }} src={MigratingLogo} /> <br />
                        <h3 className="text-success lead">Migrating please wait ...</h3>


                    </div>



                    {/* {setTimeout(() => {
                            return <p>Taking too Long? Refresh the Page</p>
                        }, 10000)} */}

                </div></div>


        </>
    )
}