import instance from '../../../../../apis/local/systemAPI';

/**************************************************** */

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCustomCourseList = async () => {
    const result = await instance.get("api/student/student-grade-viewing/getCustomCourseList/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/student/student-grade-viewing/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSummaryOfGradesReport = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/getSummaryOfGradesReport`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const generateHonorList = async (json) => {
    const result = await instance.post(`api/student/student-grade-viewing/generateHonorList`, json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}