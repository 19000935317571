export const enrollmentTypes = {
    CLEAR_LAST_UPDATE_ID: 'CLEAR-LAST-UPDATE-ID',
    SELECTED_ASSESSMENT_OTHER_RATE: 'SELECTED-ASSESSMENT-OTHER-RATE',
    GET_ASSESSMENT_OTHER_RATES: 'GET-ASSESSMENT-OTHER-RATES',
    GET_ASSESSMENT_OTHER_RATE: 'GET-ASSESSMENT-OTHER-RATE-BY-ID',
    CREATE: 'CREATE-ASSESSMENT-OTHER-RATE',
    UPDATE: 'UPDATE-ASSESSMENT-OTHER-RATE',
    DELETE: 'DELETE-ASSESSMENT-OTHER-RATE',
    LIST_LINK_TO_PARENT: '/admin/enrollment/assessment-of-fee-details',
    LIST_LINK: '/admin/enrollment/assessment-other-rate',
    MORE_LINK: '/admin/enrollment/assessment-other-rate/',
    CREATE_LINK: '/admin/enrollment/create-assessment-other-rate/',
    UPDATE_LINK: '/admin/enrollment/update-assessment-other-rate/',
    DELETE_LINK: '/admin/enrollment/delete-assessment-other-rate/'
}