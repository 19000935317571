import instance from "../../../../../apis/local/systemAPI";


export const getSubjectSchedule = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/schedules/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "s.Description": keyword,
                        "sched.SchoolYear": keyword,
                        "i.Description": keyword
                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/schedules/f`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSubjectScheduleFilter = async (page, rowsPerPage, Sy, Sem, Institute) => {
    var result;
    const params = {
        where: [
            {
                "o": "", "p": ["LIKE", "&&", {
                    "sched.SchoolYear": Sy,
                    "sched.SemesterId": Sem,
                    "sched.InstituteId": Institute !== null && Institute !== 90 ? Institute : ''
                }
                ]
            }
        ]
        , start: page + 1
        , limit: 50000
    }
    result = await instance.post(`api/schedules/f`, params);

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const createSubjectSchedule = async (formValues) => {
    const result = await instance.post("api/schedules/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateSubjectSchedule = async (id, formValues) => {
    const result = await instance.patch(`api/schedules/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}




export const getInstitute = async () => {
    const result = await instance.get(`api/references/institutes/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstituteQuery = async (con) => {
    const result = await instance.post(`api/references/institutes/q`, con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get(`api/references/schoolyears/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get(`api/references/semesters/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async () => {
    const result = await instance.get(`api/references/courses/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getClassSection = async () => {
    const result = await instance.get(`api/references/class-sections/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAllSubSched = async () => {
    const result = await instance.get("api/schedules");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getReport = async (values) => {
    const result = await instance.post("api/schedules/report", values);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateSubjectSchedule = async (formValues) => {
    const result = await instance.post("api/schedules/addUpdate", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteSubjectSchedule = async (val) => {
    const result = await instance.post(`api/schedules/delete`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

