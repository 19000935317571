import React from "react";


export default function Query(operation, condition, setData, data) {

    const execute = async () => {

        let obj = eval("(" + condition + ")");
        await operation(obj)
            .then(response => {
                console.log(response)
                setData(response.data)
            })
            .catch(error => {
                if (error) {
                    return error
                    // alert("something went wrong")
                } else {
                    return
                }
            })
    }

    execute()
}