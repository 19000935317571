import { Alert } from "bootstrap";
import React from "react";


export default function SaveQuery(operation, data, setLoading) {

    const execute = async (values) => {

        await operation(values)
            .then(response => {
                if (response.success) {
                    alert('success')

                    // return response.data
                }
                setLoading(false)
                return

            })
            .catch(error => {
                setLoading(false)
                // alert('error!')
                // console.log(error)
                if (error) {
                    return error
                    // alert("something went wrong")
                } else {
                    return
                }
            })
    }

    execute(data)
}