import React, { useState, useEffect } from "react"
import { Modal, Box } from "@material-ui/core";
import { useForm } from "react-hook-form";
import IMSelect from "../select/IMSelect";
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';



export default function FacultyLoadingDetailModal(props) {
    const { register, handleSubmit, setValue } = useForm()
    const [data, setData] = useState({ employeeId: null, employeeLabId: null })
    const [subSchedData, setSubSchedData] = useState()

    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: "30%",
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };
    useEffect(() => {
        setSubSchedData()
    }, [])

    const reset = () => {
        setSubSchedData()
        props.handleClose()
    }
    const onHandleChange = name => event => { setData({ ...data, [name]: event }) }
    const [switchState, setSwitchState] = useState({
        IsActive: props.subSchedDetailData === undefined ? false : props.subSchedDetailData[0].schedules.length > 0 && props.subSchedDetailData[0].schedules[0].LabTimeStart === null ? false : true
    });


    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    const onSubmit = (formValues) => {
        console.log(formValues)
        props.onUpdateSubSchedDetail({ ...formValues, id: props.subSchedDetailData[0].SubjectScheduleDetailId })
        reset()
    }


    useEffect(() => {
        setSubSchedData(props.subSchedDetailData)
        // useEffect(() => {
        if (props.subSchedDetailData !== undefined && props.subSchedDetailData.length > 0) {
            if (props.subSchedDetailData[0].schedules.length > 0 && props.subSchedDetailData[0].schedules[0].LabTimeStart === null && props.subSchedDetailData[0].schedules[0].LabTimeEnd === null) {
                setSwitchState({ ...switchState, IsActive: false });

            } else {
                setSwitchState({ ...switchState, IsActive: true });

            }
        }

    }, [props.subSchedDetailData])

    useEffect(() => {
        register({ name: 'employeeId' });
    }, [register])

    useEffect(() => {
        register({ name: 'employeeLabId' });
    }, [register])


    console.log(subSchedData)
    console.log(props.subSchedDetailData)
    console.log(data)


    return (

        <>
            {subSchedData !== undefined &&

                <Modal
                    open={props.openMigrateModal}
                    onClose={props.handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box className="card p -5" style={style}>

                        <div className="card-header"> <h3>Select Faculty</h3> </div>
                        <div className="card-body">




                            <form onSubmit={handleSubmit(onSubmit)} method="post">

                                <div className="row">
                                    <div className="form-group col-12">
                                        <IMSelect
                                            data={props.employeeSelect}
                                            onHandleChange={onHandleChange(`employeeId`)}
                                            refClassContainer=""
                                            name="employeeId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Lecture Faculty"
                                            placeHolder="Select Faculty"
                                            forwardRef={setValue}
                                            selectedId={data.employeeId !== null ? data.employeeId : subSchedData[0].EmployeeId}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />
                                    </div>
                                    {props.subSchedDetailData[0].schedules.length > 0
                                        && (props.subSchedDetailData[0].schedules[0].LabTimeStart !== null || props.subSchedDetailData[0].schedules[0].LabTimeEnd !== null) &&
                                        <div className="form-group col-12">
                                            <IMSelect
                                                data={props.employeeSelect}
                                                onHandleChange={onHandleChange(`employeeLabId`)}
                                                refClassContainer=""
                                                name="employeeLabId"
                                                isRequired={false}
                                                withLabel={true}
                                                label="Laboratory Faculty"
                                                placeHolder="Select Faculty"
                                                forwardRef={setValue}
                                                selectedId={data.employeeLabId !== null ? data.employeeLabId : subSchedData[0].EmployeeLabId}
                                                refClassName={`text-center`}
                                                withDescription={false}
                                                //description={`Enrollment Status`}
                                                refDisabled={true}
                                                refIsStatic={false}
                                                refStaticData={[
                                                ]
                                                }
                                                field={{
                                                    tableId: 'tableId',
                                                    display: 'code'
                                                }}
                                            />
                                        </div>

                                    }




                                </div>







                                <button type="submit" className="mt-5 mr-5 btn btn-primary ">Save</button>
                                <button type="button" onClick={() => { reset() }} className="mt-5 btn btn-secondary ">Exit</button>
                            </form>


                        </div>
                    </Box>


                </Modal>
            }




        </>


    )
}