import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSemester, getSchoolYear, getRoomFacilityReport,getAvailableRooms
} from "./__hooks__";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import PrintRoomFacilityReport from "./PrintRoomFacilityReport";
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import './__hooks__/styles.css'

export default function RoomFacilityReport(props) {
    const { setValue } = useForm()
    const { state: { auth }, dispatch } = useAppContext()
    const [resultByRoom, setResultByRoom] = useState([])
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [roomSelect, setRoomSelect] = useState(null)
    const [filteredSem, setFilteredSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [filteredSy, setFilteredSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)
    const [filteredRoom, setFilteredRoom] = useState(null)
    const [academicYear, setAcademicYearSelect] = useState(null)
    const [semesterString, setSemesterString] = useState(null)
    const [selectedRoom, setSelectedRoom] = useState(null)
    const [selectedRoomId, setSelectedRoomId] = useState(null)
    const [onView, setOnView] = useState(false)
    const [isLoading, setIsLoading] = useState(false)

    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectRoomChange = name => event => { setFilteredRoom(event) }

    // get schoolyears, semesters, and available rooms
    useEffect(() => {
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        availableRooms();
    }, [])

    const availableRooms = async () => {
        await getAvailableRooms({
            // schoolYear: filteredSy,
            // semesterId: filteredSem
        })
            .then(res => {
                let initialExec = true;
                if (res.success) {
                    let arr = [];

                    if(initialExec){
                        initialExec = false;
                        arr.push(
                            {tableId: -1, code: "---All---"}
                            ,{tableId: -2, code: "---With Classes Only---"}
                            ,{tableId: -3, code: "---Vacant Rooms Only---"});
                    }
                    for (let i = 0; i < res.data.length; i++) {
                        if (res.data[i].IsActive == 1)
                            arr.push({ tableId: res.data[i]["RoomId"], code: res.data[i]["Room"] })
                    }
                    //console.log(arr)
                    setRoomSelect(arr)
                }
            })
            .catch(e => {
                return []
            })
    }

    // functions

    // const onGetRoomFacilityReport_test = async () => {
    //     await getRoomFacilityReport(filteredSy,filteredSem,selectedRoomId
    //         )
    //             .then(res => {

    //                 console.log("res: ",res)

    //             })
    // }

    const onGetRoomFacilityReport = async () => {
        setIsLoading(true)
        setOnView(true)

        const masterArray = [];
        let ctr = 0;

        if(selectedRoomId == -1 || selectedRoomId == -2){
            for(let i = 3; i < roomSelect.length; i++){

                let roomId = roomSelect[i].tableId;

                //console.log("roomId: ",roomId);

                if(selectedRoom !== null && filteredSy !== "" && filteredSem !== ""){
                    await getRoomFacilityReport(filteredSy,filteredSem,roomId
                    )
                        .then(res => {
                            //console.log("res: ",res);
                            if(res.data.length > 0){
                                if(res.data.length == 1 && res.data[0].TimeId == -1){
                                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'info', snackBarDuration: 3000, snackBarMessage: 'No data available.' });
                                } else if (selectedRoomId == -1 || (selectedRoomId == -2 && res.data[0].TimeId > 0)){
                                    let originalData = res.data; //ORIGINAL
                                    //let originalData = newData; //FOR TEST ONLY
        
                                    const transformedArray = [];
                                    let prevMon, prevTue, prevWed, prevThu, prevFri, prevSat = null;
                                    let isMonVacant, isTueVacant, isWedVacant, isThuVacant, isFriVacant, isSatVacant = false;
                                    let MonSpan = 0, TueSpan = 0, WedSpan = 0, ThuSpan = 0, FriSpan = 0, SatSpan = 0;
                                    let vacantRoom = false;

                                    let currMonSubj = null, currTueSubj = null, currWedSubj = null, currThuSubj = null, currFriSubj = null, currSatSubj = null;
                                    let rowMonSpan = 1, rowTueSpan = 1, rowWedSpan = 1, rowThuSpan = 1, rowFriSpan = 1, rowSatSpan = 1;
                                    let currMonKey = 0, currTueKey = 0, currWedKey = 0, currThuKey = 0, currFriKey = 0, currSatKey = 0;
    
        
                                    for (const key in originalData) {
                                        const entry = originalData[key];
                                        const { Room, TimeId, TimeStart, TimeEnd, Mon, Tue, Wed, Thu, Fri, Sat } = entry;
        
                                        const roomIndex = masterArray.findIndex(item => item.Room === Room);
        
                                        var [MonBorderChk, MonSub, MonSec, MonIns, MonVac] = TableBorderFormatter(Mon,prevMon,isMonVacant,TimeId);
                                        var [TueBorderChk, TueSub, TueSec, TueIns, TueVac] = TableBorderFormatter(Tue,prevTue,isTueVacant,TimeId);
                                        var [WedBorderChk, WedSub, WedSec, WedIns, WedVac] = TableBorderFormatter(Wed,prevWed,isWedVacant,TimeId);
                                        var [ThuBorderChk, ThuSub, ThuSec, ThuIns, ThuVac] = TableBorderFormatter(Thu,prevThu,isThuVacant,TimeId);
                                        var [FriBorderChk, FriSub, FriSec, FriIns, FriVac] = TableBorderFormatter(Fri,prevFri,isFriVacant,TimeId);
                                        var [SatBorderChk, SatSub, SatSec, SatIns, SatVac] = TableBorderFormatter(Sat,prevSat,isSatVacant,TimeId);
        
                                        isMonVacant = MonVac;
                                        isTueVacant = TueVac;
                                        isWedVacant = WedVac;
                                        isThuVacant = ThuVac;
                                        isFriVacant = FriVac;
                                        isSatVacant = SatVac;
        
                                        if(TimeId != 0){
                                            prevMon = Mon;
                                            prevTue = Tue;
                                            prevWed = Wed;
                                            prevThu = Thu;
                                            prevFri = Fri;
                                            prevSat = Sat;
                                            vacantRoom = false;
                                        } else {
                                            prevMon = null;
                                            prevTue = null;
                                            prevWed = null;
                                            prevThu = null;
                                            prevFri = null;
                                            prevSat = null;
                                            vacantRoom = true;
                                        }
        
                                        if (roomIndex === -1) {
                                            masterArray.push({ 
                                                Room: Room, 
                                                Semester: semesterString, 
                                                SchoolYear: academicYear, 
                                                VacantRoom: vacantRoom, 
                                                Details: [{ 
                                                    TimeId, TimeStart, TimeEnd, 
                                                    MonBorderChk, MonSub, MonSec, MonIns, MonSpan,
                                                    TueBorderChk, TueSub, TueSec, TueIns, TueSpan,
                                                    WedBorderChk, WedSub, WedSec, WedIns, WedSpan,
                                                    ThuBorderChk, ThuSub, ThuSec, ThuIns, ThuSpan,
                                                    FriBorderChk, FriSub, FriSec, FriIns, FriSpan,
                                                    SatBorderChk, SatSub, SatSec, SatIns, SatSpan 
                                                }] 
                                            });
                                        } else {
                                            masterArray[roomIndex].Details.push({ 
                                                TimeId, TimeStart, TimeEnd, 
                                                MonBorderChk, MonSub, MonSec, MonIns, MonSpan,
                                                TueBorderChk, TueSub, TueSec, TueIns, TueSpan,
                                                WedBorderChk, WedSub, WedSec, WedIns, WedSpan,
                                                ThuBorderChk, ThuSub, ThuSec, ThuIns, ThuSpan,
                                                FriBorderChk, FriSub, FriSec, FriIns, FriSpan,
                                                SatBorderChk, SatSub, SatSec, SatIns, SatSpan 
                                            });
                                        }

                                        if(currMonSubj === null){
                                            currMonSubj = Mon;
                                            currMonKey = key;
                                        } else {
                                            if(currMonSubj == Mon){
                                                rowMonSpan++;
                                            } else {
                                                //console.log("ctr: ",ctr);
                                                masterArray[ctr].Details[currMonKey].MonSpan = rowMonSpan;
                                                currMonSubj = Mon;
                                                currMonKey = key;
                                                rowMonSpan = 1;
                                                if(TimeId == 29){
                                                    masterArray[ctr].Details[currMonKey].MonSpan = rowMonSpan;
                                                }
                                            }
                                        }
    
                                        if(currTueSubj === null){
                                            currTueSubj = Tue;
                                            currTueKey = key;
                                        } else {
                                            if(currTueSubj == Tue){
                                                rowTueSpan++;
                                            } else {
                                                masterArray[ctr].Details[currTueKey].TueSpan = rowTueSpan;
                                                currTueSubj = Tue;
                                                currTueKey = key;
                                                rowTueSpan = 1;
                                                if(TimeId == 29){
                                                    masterArray[ctr].Details[currTueKey].TueSpan = rowTueSpan;
                                                }
                                            }
                                        }
    
                                        if(currWedSubj === null){
                                            currWedSubj = Wed;
                                            currWedKey = key;
                                        } else {
                                            if(currWedSubj == Wed){
                                                rowWedSpan++;
                                            } else {
                                                masterArray[ctr].Details[currWedKey].WedSpan = rowWedSpan;
                                                currWedSubj = Wed;
                                                currWedKey = key;
                                                rowWedSpan = 1;
                                                if(TimeId == 29){
                                                    masterArray[ctr].Details[currWedKey].WedSpan = rowWedSpan;
                                                }
                                            }
                                        }
    
                                        if(currThuSubj === null){
                                            currThuSubj = Thu;
                                            currThuKey = key;
                                        } else {
                                            if(currThuSubj == Thu){
                                                rowThuSpan++;
                                            } else {
                                                masterArray[ctr].Details[currThuKey].ThuSpan = rowThuSpan;
                                                currThuSubj = Thu;
                                                currThuKey = key;
                                                rowThuSpan = 1;
                                                if(TimeId == 29){
                                                    masterArray[ctr].Details[currThuKey].ThuSpan = rowThuSpan;
                                                }
                                            }
                                        }
    
                                        if(currFriSubj === null){
                                            currFriSubj = Fri;
                                            currFriKey = key;
                                        } else {
                                            if(currFriSubj == Fri){
                                                rowFriSpan++;
                                            } else {
                                                masterArray[ctr].Details[currFriKey].FriSpan = rowFriSpan;
                                                currFriSubj = Fri;
                                                currFriKey = key;
                                                rowFriSpan = 1;
                                                if(TimeId == 29){
                                                    masterArray[ctr].Details[currFriKey].FriSpan = rowFriSpan;
                                                }
                                            }
                                        }
    
                                        if(currSatSubj === null){
                                            currSatSubj = Sat;
                                            currSatKey = key;
                                        } else {
                                            if(currSatSubj == Sat){
                                                rowSatSpan++;
                                            } else {
                                                masterArray[ctr].Details[currSatKey].SatSpan = rowSatSpan;
                                                currSatSubj = Sat;
                                                currSatKey = key;
                                                rowSatSpan = 1;
                                                if(TimeId == 29){
                                                    masterArray[ctr].Details[currSatKey].SatSpan = rowSatSpan;
                                                }
                                            }
                                        }
                                    }
                                    
                                    ctr++;
                                }
                            }
                        })
                } else {
                    setResultByRoom([]);
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'info', snackBarDuration: 3000, snackBarMessage: 'School Year, Semester, and Room cannot be blank.' });
                }


                setResultByRoom(masterArray);
            }
        }

        else {
            if(selectedRoom !== null && filteredSy !== "" && filteredSem !== ""){
                await getRoomFacilityReport(filteredSy,filteredSem,selectedRoomId
                )
                    .then(res => {
                        //console.log("res: ",res);
                        if(res.data.length > 0){
                            if(res.data.length == 1 && res.data[0].TimeId == -1){
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'info', snackBarDuration: 3000, snackBarMessage: 'No data available.' });
                            } else {
                                let originalData = res.data; //ORIGINAL
                                //let originalData = newData; //FOR TEST ONLY
    
                                const transformedArray = [];
                                let prevMon, prevTue, prevWed, prevThu, prevFri, prevSat = null;
                                let isMonVacant, isTueVacant, isWedVacant, isThuVacant, isFriVacant, isSatVacant = false;
                                let MonSpan = 0, TueSpan = 0, WedSpan = 0, ThuSpan = 0, FriSpan = 0, SatSpan = 0;
                                let vacantRoom = false;

                                let currMonSubj = null, currTueSubj = null, currWedSubj = null, currThuSubj = null, currFriSubj = null, currSatSubj = null;
                                let rowMonSpan = 1, rowTueSpan = 1, rowWedSpan = 1, rowThuSpan = 1, rowFriSpan = 1, rowSatSpan = 1;
                                let currMonKey = 0, currTueKey = 0, currWedKey = 0, currThuKey = 0, currFriKey = 0, currSatKey = 0;
    
                                for (const key in originalData) {
                                    const entry = originalData[key];
                                    const { Room, TimeId, TimeStart, TimeEnd, Mon, Tue, Wed, Thu, Fri, Sat } = entry;  
                                    const roomIndex = transformedArray.findIndex(item => item.Room === Room);
    
                                    var [MonBorderChk, MonSub, MonSec, MonIns, MonVac] = TableBorderFormatter(Mon,prevMon,isMonVacant,TimeId);
                                    var [TueBorderChk, TueSub, TueSec, TueIns, TueVac] = TableBorderFormatter(Tue,prevTue,isTueVacant,TimeId);
                                    var [WedBorderChk, WedSub, WedSec, WedIns, WedVac] = TableBorderFormatter(Wed,prevWed,isWedVacant,TimeId);
                                    var [ThuBorderChk, ThuSub, ThuSec, ThuIns, ThuVac] = TableBorderFormatter(Thu,prevThu,isThuVacant,TimeId);
                                    var [FriBorderChk, FriSub, FriSec, FriIns, FriVac] = TableBorderFormatter(Fri,prevFri,isFriVacant,TimeId);
                                    var [SatBorderChk, SatSub, SatSec, SatIns, SatVac] = TableBorderFormatter(Sat,prevSat,isSatVacant,TimeId);
    
                                    isMonVacant = MonVac;
                                    isTueVacant = TueVac;
                                    isWedVacant = WedVac;
                                    isThuVacant = ThuVac;
                                    isFriVacant = FriVac;
                                    isSatVacant = SatVac;
    
                                    if(TimeId != 0){
                                        prevMon = Mon;
                                        prevTue = Tue;
                                        prevWed = Wed;
                                        prevThu = Thu;
                                        prevFri = Fri;
                                        prevSat = Sat;
                                        vacantRoom = false;
                                    } else {
                                        prevMon = null;
                                        prevTue = null;
                                        prevWed = null;
                                        prevThu = null;
                                        prevFri = null;
                                        prevSat = null;
                                        vacantRoom = true;
                                    }
    
                                    if (roomIndex === -1) {
                                        transformedArray.push({ 
                                            Room: Room, 
                                            Semester: semesterString, 
                                            SchoolYear: academicYear, 
                                            VacantRoom: vacantRoom, 
                                            Details: [{ 
                                                TimeId, TimeStart, TimeEnd, 
                                                MonBorderChk, MonSub, MonSec, MonIns, MonSpan,
                                                TueBorderChk, TueSub, TueSec, TueIns, TueSpan,
                                                WedBorderChk, WedSub, WedSec, WedIns, WedSpan,
                                                ThuBorderChk, ThuSub, ThuSec, ThuIns, ThuSpan,
                                                FriBorderChk, FriSub, FriSec, FriIns, FriSpan,
                                                SatBorderChk, SatSub, SatSec, SatIns, SatSpan 
                                            }] 
                                        });
                                    } else {
                                        transformedArray[roomIndex].Details.push({ 
                                            TimeId, TimeStart, TimeEnd, 
                                            MonBorderChk, MonSub, MonSec, MonIns, MonSpan,
                                            TueBorderChk, TueSub, TueSec, TueIns, TueSpan,
                                            WedBorderChk, WedSub, WedSec, WedIns, WedSpan,
                                            ThuBorderChk, ThuSub, ThuSec, ThuIns, ThuSpan,
                                            FriBorderChk, FriSub, FriSec, FriIns, FriSpan,
                                            SatBorderChk, SatSub, SatSec, SatIns, SatSpan 
                                        });
                                    }

                                    if(currMonSubj === null){
                                        currMonSubj = Mon;
                                        currMonKey = key;
                                    } else {
                                        if(currMonSubj == Mon){
                                            rowMonSpan++;
                                        } else {
                                            transformedArray[0].Details[currMonKey].MonSpan = rowMonSpan;
                                            currMonSubj = Mon;
                                            currMonKey = key;
                                            rowMonSpan = 1;
                                        }
                                        if(TimeId == 29){
                                            transformedArray[0].Details[currMonKey].MonSpan = rowMonSpan;
                                        }
                                    }

                                    if(currTueSubj === null){
                                        currTueSubj = Tue;
                                        currTueKey = key;
                                    } else {
                                        if(currTueSubj == Tue){
                                            rowTueSpan++;
                                        } else {
                                            transformedArray[0].Details[currTueKey].TueSpan = rowTueSpan;
                                            currTueSubj = Tue;
                                            currTueKey = key;
                                            rowTueSpan = 1;
                                            if(TimeId == 29){
                                                transformedArray[0].Details[currTueKey].TueSpan = rowTueSpan;
                                            }
                                        }
                                    }

                                    if(currWedSubj === null){
                                        currWedSubj = Wed;
                                        currWedKey = key;
                                    } else {
                                        if(currWedSubj == Wed){
                                            rowWedSpan++;
                                        } else {
                                            transformedArray[0].Details[currWedKey].WedSpan = rowWedSpan;
                                            currWedSubj = Wed;
                                            currWedKey = key;
                                            rowWedSpan = 1;
                                            if(TimeId == 29){
                                                transformedArray[0].Details[currWedKey].WedSpan = rowWedSpan;
                                            }
                                        }
                                    }

                                    if(currThuSubj === null){
                                        currThuSubj = Thu;
                                        currThuKey = key;
                                    } else {
                                        if(currThuSubj == Thu){
                                            rowThuSpan++;
                                        } else {
                                            transformedArray[0].Details[currThuKey].ThuSpan = rowThuSpan;
                                            currThuSubj = Thu;
                                            currThuKey = key;
                                            rowThuSpan = 1;
                                            if(TimeId == 29){
                                                transformedArray[0].Details[currWedKey].ThuSpan = rowThuSpan;
                                            }
                                        }
                                    }

                                    if(currFriSubj === null){
                                        currFriSubj = Fri;
                                        currFriKey = key;
                                    } else {
                                        if(currFriSubj == Fri){
                                            rowFriSpan++;
                                        } else {
                                            transformedArray[0].Details[currFriKey].FriSpan = rowFriSpan;
                                            currFriSubj = Fri;
                                            currFriKey = key;
                                            rowFriSpan = 1;
                                            if(TimeId == 29){
                                                transformedArray[0].Details[currWedKey].FriSpan = rowFriSpan;
                                            }
                                        }
                                    }

                                    if(currSatSubj === null){
                                        currSatSubj = Sat;
                                        currSatKey = key;
                                    } else {
                                        if(currSatSubj == Sat){
                                            rowSatSpan++;
                                        } else {
                                            transformedArray[0].Details[currSatKey].SatSpan = rowSatSpan;
                                            currSatSubj = Sat;
                                            currSatKey = key;
                                            rowSatSpan = 1;
                                            if(TimeId == 29){
                                                transformedArray[0].Details[currWedKey].SatSpan = rowSatSpan;
                                            }
                                        }
                                    }
                                }
    
                                //console.log("Result: ",transformedArray);
                                setResultByRoom(transformedArray);
    
                            }
                        }
                    })
            } else {
                setResultByRoom([]);
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'info', snackBarDuration: 3000, snackBarMessage: 'School Year, Semester, and Room cannot be blank.' });
            }
        }

        // Add flags that will determine the table's background color and borders
        function TableBorderFormatter(subject,prevSub,isVacant,TimeId){
            let borderChk;
            let sub;
            let sec;
            let ins;

            /***
             * 
             * No Overlaps/With Merge
             * 0 - blue fill, top and side
             * 1 - blue fill, side only
             * 2 - blue fill, bottom and side
             * 8 - blue fill, all sides
             * 
             * With Overlaps
             * 3 - red fill, top and side
             * 4 - red fill, side only
             * 5 - red fill, bottom and side
             * 9 - red fill, all sides
             * 
             * Vacant Slots
             * 6 - no fill, side only
             * 7 - no fill, bottom and side
             * 10 - no fill, all sides
             * default - no fill, top and side
             * 
             ***/
            
            if(subject !== null || typeof(subject) != "undefined") {
                if(prevSub == subject){
                    if(subject !== null && !subject.includes("%")){
                        sub = "";
                        sec = null;
                        ins = null;
                        if(isVacant){
                            borderChk = 6;
                        } else {
                            if(subject !== null && !subject.includes("/")){
                                borderChk = 1;
                            } else {
                                borderChk = 4;
                            }
                        }
                    } else {
                        sub = subject; 
                        sec = null; 
                        ins = null;
                    }
                } else {
                    if(subject !== null && subject.includes("/")){
                        sub = subject.replaceAll("_"," ").replaceAll("---, ---","No Instructor");
                            sec = null;
                            ins = null;
                        borderChk = 3;
                    }

                    else {
                        if(subject !== null && subject.includes("_")){
                            var [subj, section, instructor] = subject.split("_");
                            sub = subj;
                            sec = section;
                            ins = instructor;
                            if(ins != null && ins == "---, ---"){
                                ins = "No Instructor";
                            }
                            borderChk = 0;
                        } else if(subject !== null && subject.includes("%")){
                            sub = subject; sec = null; ins = null;
                        } else if (subject == null){                            
                            sub = null; sec = null; ins = null;
                        } else{                            
                            sub = "VACANT"; sec = null; ins = null;
                        }
                    }
                }
            }

            if(prevSub != "VACANT" && subject == "VACANT"){
                borderChk = -1;
                isVacant = true;
            } else if(subject != "VACANT"){
                isVacant = false;
            }

            if(TimeId == 27){
                if(isVacant){
                    if(prevSub != subject){
                        borderChk = 10;
                    } else {
                        borderChk = 7;
                    }
                } else {
                    if(subject !== null && !subject.includes("/")){
                        if(prevSub != subject){
                            borderChk = 8;
                        } else {
                            borderChk = 2;
                        }
                    } else {
                        if(prevSub != subject){
                            borderChk = 9;
                        } else {
                            borderChk = 5;
                        }
                    }
                }
            }

            return [borderChk, sub, sec, ins, isVacant];
        }

        setIsLoading(false);
    }

    useEffect(() => {
        if(filteredRoom !== null && roomSelect !== null){
            if(filteredRoom == ""){
                setSelectedRoom(null);
            } else {
                for(let i = 0; i < roomSelect.length; i++){
                    if(roomSelect[i].tableId == filteredRoom){
                        setSelectedRoom(roomSelect[i].code);
                        setSelectedRoomId(roomSelect[i].tableId);
                        break;
                    }
                }
            }
        } else {
            setSelectedRoom(null);
        }
        setResultByRoom([]);
        setOnView(false);

    }, [filteredRoom])

    useEffect(() => {
        if(filteredSy !== null && schoolYearSelect !== null){
            for(let i = 0; i < schoolYearSelect.length; i++){
                if(schoolYearSelect[i].tableId == filteredSy){
                    setAcademicYearSelect(schoolYearSelect[i].code.slice(3));
                    break;
                }
            }
        } else {
            let acadYear = filteredSy + "-" + parseInt(filteredSy + 1);
            setAcademicYearSelect(acadYear);
        }
        setResultByRoom([]);
        setOnView(false);
    }, [filteredSy])

    useEffect(() => {
        if(filteredSem !== null && semesterSelect !== null){
            for(let i = 0; i < semesterSelect.length; i++){
                if(semesterSelect[i].tableId == filteredSem){
                    setSemesterString(semesterSelect[i].code);
                    break;
                }
            }
        } else {
            switch(filteredSem){
                case '1S':
                    setSemesterString("First Semester");
                    break;
                case '2S':
                    setSemesterString("Second Semester");
                    break;
                case 'SM':
                    setSemesterString("Summer");
            }
        }
        setResultByRoom([]);
        
        setOnView(false);
    }, [filteredSem])

    // School Year Dropdown
    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect !== null &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? filteredSy === undefined : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    // Semesters Dropdown
    const imSelectDropdownSemesters = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? filteredSem === undefined : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    // Rooms Dropdown
    const imSelectRoomFacility = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={roomSelect}
                        onHandleChange={selectRoomChange()}
                        refClassContainer=""
                        name="room"
                        isRequired={false}
                        withLabel={false}
                        label="Room"
                        placeHolder="Select Room"
                        forwardRef={setValue}
                        selectedId={filteredRoom === undefined ? filteredRoom === undefined : filteredRoom}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    //console.log("auth.data.schoolyears.enrollment.SemesterId: ", auth.data.schoolyears.enrollment.SemesterId);
    return (
        <>
            {semesterSelect && schoolYearSelect && roomSelect &&

                <>
                    <main className="card">
                        <header className="card-header">
                            <h4 className="d-block">Room Facility Report</h4>
                            <div className="row">
                                <section className="col">{imSelectDropdownSchoolYear()}</section>
                                <section className="col">{imSelectDropdownSemesters()}</section>
                                <section className="col">{imSelectRoomFacility()}</section>
                                <button id="btnView" onClick={() => { onGetRoomFacilityReport() }} className="col btn btn-primary">View</button>
                            </div>
                                
                            {Object.keys(resultByRoom).length > 0 && isLoading == false ?
                               <PrintRoomFacilityReport results={[resultByRoom,academicYear,semesterString,selectedRoomId]}/>

                               :
                               <>
                                   {onView == true && isLoading == true &&
                                    <button style={{width:"190px", height:"38px"}} className="btn btn-border btn-secondary btn-sm mr-3">
                                        Please wait ...
                                        <div className="spinner-border mx-5" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </button>
                                   }
                               </>
                            }
                        </header>
                    </main>
                </>
            }
        </>
    )
}