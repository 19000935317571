import { number } from "prop-types";
import React, { useRef, forwardRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

const useStyles = makeStyles({
    table: {
        minWidth: 650
    },

});



export default function PrintSummarySubjectReport(props) {
    const { results } = props
    let componentRef = useRef();
    const classes = useStyles();


    const Component = forwardRef((props, ref) => {
        const pageStyle = " @page { size: 13in 8.5in; }"

        return (
            <>



                <div style={{ display: 'none' }}>
                    <style>{pageStyle}</style>
                    <div ref={ref}>
                        <div  >

                            <main className="card border-0 p-5">

                                <img style={{ width: "150px" }} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                <h4 className="text-center">Summary of Subjects Offered </h4>
                                <h4 className="text-center">A.Y. {results[0].SY}</h4>
                                <section className="card-body " >

                                    <div >

                                        <TableContainer component={Paper}>
                                            <Table className={classes.table} size="small" aria-label="a dense table">
                                                <TableHead>
                                                    <TableRow>
                                                        {/* <TableCell align="left">Class</TableCell> */}
                                                        <TableCell align="center">Subject Code</TableCell>
                                                        <TableCell align="left">Description</TableCell>
                                                        <TableCell align="center">No. of Units</TableCell>
                                                        <TableCell align="center">Lec Hrs</TableCell>
                                                        <TableCell align="center">Lec Units</TableCell>
                                                        <TableCell align="center">Lab Hrs</TableCell>
                                                        <TableCell align="center">Lab Units</TableCell>
                                                        <TableCell align="center">Subject Type</TableCell>
                                                        <TableCell align="center">Lab Cat</TableCell>
                                                        <TableCell align="center">No. of Classess</TableCell>
                                                    </TableRow>
                                                </TableHead>
                                                <TableBody>
                                                    {results.map((row) => {
                                                        return (
                                                            <>
                                                                <TableRow >
                                                                    {/* <TableCell align="left" >{row.Institute}</TableCell> */}
                                                                    <TableCell align="center">{row.SubjectCode}</TableCell>
                                                                    <TableCell align="left">{row.Description}</TableCell>
                                                                    <TableCell align="center">{row.Units}</TableCell>
                                                                    <TableCell align="center">{row.LecHours}</TableCell>
                                                                    <TableCell align="center">{row.LecUnits}</TableCell>
                                                                    <TableCell align="center">{row.LabHours}</TableCell>
                                                                    <TableCell align="center">{row.LabUnits}</TableCell>
                                                                    <TableCell align="center">{row.SubjectType}</TableCell>
                                                                    <TableCell align="center">{row.SubCode}</TableCell>
                                                                    <TableCell align="center">{row.NoOfClasses}</TableCell>
                                                                </TableRow>
                                                            </>
                                                        )
                                                    })}






                                                </TableBody>
                                            </Table>
                                        </TableContainer>



                                    </div>




                                </section>

                            </main>


                        </div>

                    </div>



                </div>


            </>
        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>
            <div>

                <Component ref={componentRef} />
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button className="btn ml-5 btn-success mr-3">Summary Report</button>}
                    content={() => componentRef.current}
                    onBeforePrint={handleBeforePrint}
                    pageStyle={`
                        @page {
                        size: 2.5in 4in;
                        margin: 20mm 0mm 25mm 0mm;
                        }
                  `}

                />
            </div>



        </>
    )


}