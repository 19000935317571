import React, { useState, useEffect } from "react";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";
import { useAppContext } from "../../../../contexts/useAppContext";
import { useForm } from 'react-hook-form';
import { getStudentTracer } from './__hooks__';

export default function IntentToEnrollVerification(props) {
    const { state: { auth }, dispatch } = useAppContext();
    const { setValue } = useForm()
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(0);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [keyword, setKeyword] = useState(null);
    const [onSearch, setOnSearch] = useState(false);
    const [isLastUpdate, setIsLastUpdate] = useState(true);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SY', label: 'School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'EnrollmentStatus', label: 'Enrollment Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'YearLevel', label: 'Year Level', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'EnrollmentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'intentToEnrollList',
        parentId: null,
        tableTitle: 'Student Masterlist',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: 1,
        onIsLastUpdate: isLastUpdate,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: { MORE_LINK: auth.data.UserType == 'RGR' || auth.data.UserType == 'RGD' ? '/admin/student/create-assessment/' : '/admin/accounting/student-account-assessment/' },
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch
    }



    useEffect(() => {
        const execute = async (val) => {
            await getStudentTracer(val)
                .then(response => {
                    // dispatch({ type: referenceTypes.GET_SCHOLARSHIPS, data: response.data, keyword: keyword, page: page, rowsPerPage: rowsPerPage });
                    setResults(response.data);
                    setTotalRecord(response.data.length > 0 ? response.data[0].TotalCount : 0);

                })
                .catch(error => {

                })
        }
        execute({
            enrollmentStatusId: auth.data.UserType == 'ACC' ? 'A' : auth.data.UserType == 'RGR' || auth.data.UserType == 'RGD' ? 'IE' : '',
            rowsPerPage: rowsPerPage,
            page: page,
            schoolYear: activeSy,
            semesterId: activeSem,
            search: keyword,
            isIntent: auth.data.UserType == 'ACC' ? 1 : null
        });


    }, [dispatch, page, rowsPerPage, keyword])

    return (
        <>
            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header ">
                    <div className="card-title ">
                        <h3 className="card-label">List of Students Intended to Enroll</h3>
                    </div>




                </div>

                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                    <IMTable tableProps={tableProps} />
                </div>
            </div>

        </>
    )
}