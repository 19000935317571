import instance from "../../../../../apis/local/systemAPI";

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentListOfSubjects = async (input) => {
    const result = await instance.post("api/enrollments/studentListOfSubjects", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentSpecialPermit = async (input) => {
    const result = await instance.post("api/enrollments/studentSpecialPermit", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentSpecialPermitaddUpdate = async (input) => {
    const result = await instance.post("api/enrollments/studentSpecialPermitAddUpdate", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const searchStudent = async (data) => {
    const result = await instance.post("api/enrollments/search", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradingPeriod = async () => {
    const result = await instance.get("api/references/grading-period/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

