import instance from "../../../../../apis/local/systemAPI";

export const searchStudentSubjects = async (data) => {
    const result = await instance.post("api/enrollments/searchStudentSubjects", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getStudentEnrollmentSubjects = async (condition) => {
    const result = await instance.post("api/student/student-enrollment-subjects/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectScheduleDetail = async (condition) => {
    const result = await instance.post(`api/schedule/details/q`, condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSubjectScheduleDetailSelect = async (condition) => {
    const result = await instance.post(`api/schedule/details/qy`, condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const createAddDropSubjects = async (formValues) => {
    const result = await instance.post("api/student/student-add-drop-subjects/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteAddDropSubjects = async (id) => {
    const result = await instance.delete(`api/student/student-add-drop-subjects/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getAddDropSubjects = async (condition) => {
    const result = await instance.post("api/student/student-add-drop-subjects/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const deleteStudentSubjects = async (val) => {
    const result = await instance.post("api/student/student-enrollment-subjects/delete", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const addUpdateStudentSubjects = async (val) => {
    const result = await instance.post("api/student/student-enrollment-subjects/addUpdate", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentSubjects = async (val) => {
    const result = await instance.post("api/student/student-enrollment-subjects/getStudentSubjectsWithSchedule", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createAssessmentAddOns = async (formValues) => {
    const result = await instance.post("api/assessment/add-ons", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getAssessmentAddOns = async (formValues) => {
    const result = await instance.post("api/assessment/student-other-fees/studentAddOns", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getCurriculumSubjectFilter = async (con) => {
    const result = await instance.post(`api/course-outlines/subjectFilter`, con);

    if (result.status === 200 || result.status === 201) {
        return result.data.data;
    }
}

export const addUpdateAddOns = async (con) => {
    const result = await instance.post(`api/assessment/student-other-fees/addUpdateAddOns`, con);
    if (result.status === 200 || result.status === 201) {
        return result.data.data;
    }
}

export const deleteAddOns = async (con) => {
    const result = await instance.post(`api/assessment/add-ons/deleteAddOns`, con);
    if (result.status === 200 || result.status === 201) {
        return result.data.data;
    }
}