import React, { useState, useEffect } from 'react';
import history from '../../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { useParams } from 'react-router-dom';
import Form from './Form';
import { createCourseOutlineDetail, getCourseOutline } from './__hooks__';
import Loading from '../../../../../../_metronic/layout/components/custom/forms/Loading';
import { NoAccess } from '../../../../../../_metronic/layout/components/custom/forms/NoAccess';



export default function Create(props) {
  const { state: { auth }, dispatch } = useAppContext();
  const { courseOutlineId } = props.match.params;
  const [selectedSem, setSelectedSem] = useState()
  const { id } = useParams()
  const [keepLoading, setKeepLoading] = useState(true)
  console.log(id)
  useEffect(() => {
    const execute = async () => {
      await getCourseOutline()
        .then(response => {

          if (response.success) {
            console.log(response.data)
            for (let i = 0; i < response.data.length; i++) {
              if (response.data[i].CourseOutlineId === parseInt(id)) {
                setSelectedSem(response.data[i])

              }
            }
          }
        })
        .catch(error => {

        })
    }

    execute()
  }, [])




  const onSubmit = formValues => {
    const execute = async () => {
      await createCourseOutlineDetail(formValues)
        .then(response => {
          if (response.success) {
            dispatch({ type: referenceTypes.CREATE, data: response.data });
            history.push(`${referenceTypes.LIST_LINK}/${id}`);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }
  console.log(selectedSem)

  setTimeout(function () {
    setKeepLoading(false)
  }, 5000);
  return (
    <>

      {selectedSem !== undefined &&
        (auth.data.UserType === 'DEN'
          || auth.data.UserType === 'RGR'
          || auth.data.UserType === 'ADM'

        )

        ?


        <div className="kt-container  kt-grid__item kt-grid__item--fluid">
          <div className="row ac-minimum-height-container-350">
            <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
              <Form onSubmit={onSubmit} doc={selectedSem} courseOutlineId={courseOutlineId} />
            </div>
          </div>
        </div>

        :

        <>
          <div className="card p-5">

            {keepLoading === true
              ?
              <Loading />
              :

              <>
                {auth.data.UserType === 'DEN'
                  || auth.data.UserType === 'RGR'
                  || auth.data.UserType === 'ADM'

                  ? ''

                  : <NoAccess />
                }
              </>
            }
          </div>

        </>
      }

    </>
  );
}