import React, { useState, useEffect } from "react";
import { Modal, Box } from '@material-ui/core'
import { useAppContext } from "../../../../contexts/useAppContext";
import {
    getUser, updateUser, getStudentAccount, getStudentClearance,
    intentToEnroll, isAllowedToIntent, studentLiabilities, getImage
} from "../__hooks__"
import { useForm } from "react-hook-form";
import CryptoJS from "crypto-js";
import './__hooks__/styles.css'
import ProfileCover from "../../../../../_metronic/_assets/logo/profileCover.jpg"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import FaithLogo from "../../../../../_metronic/_assets/logo/lb-logo.png"
import IMDialogNotification from "../../../../../_metronic/layout/components/custom/dialog/IMDialogNotification";
import moment from "moment";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import history from "../../../../history";
import { Link } from "react-router-dom";

export default function Student_Dashboard(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const [isOpen, setIsOpen] = useState(true)
    const [passwordChanged, setPasswordChanged] = useState(false)
    const [user, setUser] = useState()
    const [student, setStudent] = useState(null)
    const [isClearToIntent, setIsClearToIntent] = useState(false)
    const { register, handleSubmit, setValue } = useForm();
    const [clearanceToBeClear, setClearanceToBeClear] = useState([])
    const [isToIntent, setIsToIntent] = useState(0)
    const currentSy = auth.data.schoolyears.enrollment.SchoolYear
    const currentSem = auth.data.schoolyears.enrollment.SemesterId
    const [imagePreview, setImagePreview] = useState(null)


    // logics
    const [clearanceDialogue, setClearanceDialogue] = useState(false)
    const [alreadyEnrolled, setAlreadyEnrolled] = useState(false)
    const [isPendingIntent, setIsPendingIntent] = useState(false)
    const [studentStatus, setStudentStatus] = useState(null)
    const [flexWrap, setFlexWrap] = useState('nowrap');

    const updateFlexWrap = () => {
        if (window.innerWidth >= 1200) {
            setFlexWrap('nowrap');
        } else {
            setFlexWrap('wrap');
        }
        console.log(`Window resized to: ${window.innerWidth}px, flexWrap set to: ${flexWrap}`);
    };

    useEffect(() => {
        // Initial check
        updateFlexWrap();

        // Update on window resize
        window.addEventListener('resize', updateFlexWrap);

        // Cleanup event listener on unmount
        return () => {
            window.removeEventListener('resize', updateFlexWrap);
        };
    }, []); // Empty dependency array ensures this runs once on mount
    useEffect(() => {

    }, [passwordChanged])

    const handleClose = () => {
        setPasswordChanged(false)
    }

    const executeOperation = async (operation, condition, setter) => {
        let arr = []
        await operation()
            .then(response => {
                if (response.success) {

                    for (let i = 0; i < response.data.length; i++) {
                        if (eval(condition)) {
                            arr.push(response.data[i])

                        }
                    }
                    setter(arr)
                }
            })
            .catch(error => {
                alert(error.message)
            })
    }


    const executeGetStudentAccount = async (val) => {
        await getStudentAccount(val)
            .then(res => {
                if (res.data.length > 0) {
                    setStudent(res.data[0])
                    if (res.data[0].LatestSchoolYear == currentSy && res.data[0].LatestSemesterId == currentSem && (res.data[0].EnrollmentStatusId == 'E' || res.data[0].EnrollmentStatusId == 'IE' || res.data[0].EnrollmentStatusId == 'A' || res.data[0].EnrollmentStatusId == 'PV')) {
                        setStudentStatus(res.data[0].EnrollmentStatusId)
                        setAlreadyEnrolled(true)


                    }

                }

            })
    }


    const executeGetStudentClearance = async (val) => {
        await studentLiabilities(val)
            .then(res => {
                console.log(res)
                // setIsClearToIntent(res.isClear)
                setClearanceToBeClear(res.data)
            })
    }

    const onSubmitChangePassword = (formValues) => {
        const { password } = formValues
        const encyptionResult = ("*" + CryptoJS.SHA1(CryptoJS.SHA1(password))).toUpperCase();

        const userValues = {
            isDefaultPassword: 0,
            password: encyptionResult
        }

        const execute = async () => {
            await updateUser(auth.data.UserId, userValues)
                .then(response => {
                    if (response.success) {
                        setIsOpen(false)
                        setPasswordChanged(true)
                    }
                })
                .catch(error => {
                    alert("something went wrong")
                    console.log(error)
                })
        }
        execute();
    }


    const intentToEnrolled = () => {
        if (isClearToIntent == true) {
            enrollStudent({
                enrollmentStatusId: 'DR',
                schoolYear: currentSy,
                semesterId: currentSem,
                studentNo: student.StudentNo,
                studentId: student.StudentId,
                instituteId: student.InstituteId,
                courseId: student.CourseId,
                createdBy: auth.data.Username
            })
        } else {
            setClearanceDialogue(true)
        }
    }

    const enrollStudent = async (val) => {
        await intentToEnroll(val)
            .then(res => {

                setAlreadyEnrolled(true)
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'success',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Successfully intended to enroll'
                });
            })
            .catch(e => {

            })
    }

    const ChangePasswordModal = (props) => {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '55%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',

            boxShadow: 24,
            p: 4,
        };
        const check = () => {
            if (document.getElementById('password').value == document.getElementById('passwordRetype').value) {
                document.getElementById('changePasswordSubmit').removeAttribute("disabled");

            } else {
                document.getElementById('changePasswordSubmit').setAttribute("disabled", "disabled");
            }
        }
        return (
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="card" style={style}>
                    <div className="mt-5 text-center"> <img className="d-inline mx-auto" style={{ width: '50%' }} src={FaithLogo} /> </div>
                    <div className="card-body">
                        <h3 className="mb-4">Change you Password</h3>
                        <form onSubmit={handleSubmit(onSubmitChangePassword)} method="post">
                            <div className="mb-3">
                                <label className="col-form-label p-0" htmlFor="password">New Password</label>

                                <input
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    onKeyUp={check}
                                    ref={register({
                                        required: true
                                    })}
                                    required
                                />
                            </div>


                            <div>
                                <label className="col-form-label p-0" htmlFor="passwordRetype">Re-type Password</label>

                                <input
                                    name="passwordRetype"
                                    type="password"
                                    className="form-control"
                                    id="passwordRetype"
                                    onKeyUp={check}

                                    required
                                />
                            </div>

                            <button id="changePasswordSubmit" className="btn btn-primary btn-block mt-3">Save</button>

                        </form>

                    </div>
                </Box>


            </Modal>
        )
    }

    const PasswordDeletionConfirmation = (props) => {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '55%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',

            boxShadow: 24,
            p: 4,
        };

        return (
            <Modal
                open={props.isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="card p -5" style={style}>
                    <div className="mt-5 text-center">
                        <div className="check d-inline">
                            <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52">
                                <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" />
                                <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" />
                            </svg>
                        </div>

                    </div>
                    <div className="card-body text-center">
                        <h3 className="mb-5">Success!</h3>
                        <p className="card-text lead">Your Password has been changed. </p>
                        <button onClick={() => { handleClose() }} className="btn btn-primary">Confirm</button>


                    </div>
                </Box>


            </Modal>
        )
    }

    useEffect(() => {
        register({ name: 'password' },
            { required: true });
    }, [register])

    useEffect(() => {
        // executeOperation(getUser, 'response.data[i].UserId === auth.data.UserId', setUser)
        executeGetStudentAccount({ studentId: auth.data.StudentId })
        executeGetStudentClearance({ studentId: auth.data.StudentId })
    }, [])

    useEffect(() => {
        if (student != null) {
            isAllowedToIntent({ studentId: student.StudentId })
                .then(res => {
                    setIsToIntent(res.data.IsAllowed)
                })

            getImage(student.StudentNo)
                .then(resp => {
                    console.log(resp)
                    setImagePreview(resp)
                })
                .catch(e => {
                    return
                })
        }
    }, [student])

    console.log(isClearToIntent)
    console.log(student)

    console.log(currentSy)
    console.log(currentSem)
    console.log(clearanceToBeClear)

    console.log(studentStatus)
    console.log(isAllowedToIntent)
    console.log(alreadyEnrolled)
    console.log(isToIntent)

    return (
        <>
            {student !== null &&

                <>

                    {clearanceDialogue && <IMDialogNotification
                        title={`Liabilities Found`}
                        description=''
                        onShowDialog={setClearanceDialogue}
                        clearance={clearanceToBeClear}
                        isOpen={true}
                    />}

                    <main className="row" >
                        <section className="col-12 col-xl-3" >
                            <div className="card" style={{ height: '100%' }}>
                                <img className="card-img-top" src={ProfileCover} alt="Bologna" style={{ height: '200px', overflow: 'hidden' }} />
                                <div className="card-body bg-light text-center">
                                    <img className="avatar rounded-circle" src={imagePreview || ProfileLogo} alt="Bologna" />
                                    <h3 className="card-title mb-0">{student.StudentName}</h3>
                                    <p className="card-text mt-0">{student.CourseDesc}</p>
                                    <p className="card-text mt-0">{student.StudentNo}</p>
                                    {/* <hr />
                                    {isClearToIntent == false ?
                                        <>
                                            <h4 className="text-left">Notifications</h4>

                                            <div className="card shadow-lg p-3 mb-5 bg-white rounded">
                                                <p className=" mb-0 text-center">
                                                    You are on hold on the following department, please settle your accountability

                                                </p>

                                                <div className="card-body text-left">
                                                    <p>List of Clearances to Obtain</p>

                                                    <ol>
                                                        {clearanceToBeClear.map(d => {
                                                            return (
                                                                <>
                                                                    <li className="text-left">{d.DepartmentCode}</li>
                                                                    <ul>
                                                                        <li className="text-left">{d.Remarks}</li>

                                                                    </ul>
                                                                </>
                                                            )
                                                        })}
                                                    </ol>
                                                </div>

                                            </div>

                                        </>

                                        :

                                        ''

                                    } */}

                                </div>
                            </div>
                        </section>

                        <section className="col-12 col-xl-9" style={{ height: '100%' }}>

                            <section className="row mt-5 mx-auto" style={{ height: flexWrap == 'wrap' ? '30%' : '100%', display: 'flex', flexWrap: flexWrap, width: '100%' }}>
                                {alreadyEnrolled == false ?
                                    <>
                                        {isToIntent == 1 ?
                                            <Link className="card-customs col-12 col-xl-4 mr-0 mr-xl-5 bg-info p-0" to={{ pathname: `/admin/student/create-assessment/${student.StudentId}`, data: { studentId: student.StudentId, schoolYear: currentSy, semesterId: currentSem, isIntent: true, nextClassSectionId: student.NextClassSectionId, nextYearLevelId: student.NextYearLevelId, studentScholarship: student.StudentScholarship } }} >
                                                {/* <div onClick={() => { history.push(`/admin/student/create-assessment/${student.StudentId}`) }} className="card-customs col-4 mr-5 bg-info"> */}
                                                <div className="p-5">
                                                    <div className="media align-items-center">
                                                        <div className="media-body p-0">
                                                            <h4 className="text-white mb-0">Intent to Enroll </h4>
                                                            {studentStatus == null ? <span className="text-white">Enroll for S.Y {currentSy} / {currentSem}</span>
                                                                :
                                                                studentStatus == 'IE' ?
                                                                    <span className="text-info">Pending Assessment Verification For S.Y ({currentSy} / {currentSem})</span>
                                                                    :
                                                                    studentStatus == 'A' ?
                                                                        <span className="text-info">Pending Assessent of Fee Validation For S.Y ({currentSy} / {currentSem})</span>
                                                                        :
                                                                        studentStatus == 'PV' ?
                                                                            <span className="text-info">Pending Enrollment Payment For S.Y ({currentSy} / {currentSem})</span>
                                                                            :
                                                                            studentStatus == 'E' ?
                                                                                <span className="text-info">Already Enrolled For S.Y ({currentSy} / {currentSem})</span>
                                                                                :
                                                                                ''
                                                            }
                                                        </div>
                                                        <div className="align-self-center" >
                                                            <i className="fa fa-school text-white" style={{ fontSize: '40px' }} ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </Link>

                                            :
                                            <div className="card-customs col-12 col-xl-4 mr-0 mr-xl-5 bg-secondary p-0" onClick={() => { setClearanceDialogue(true) }} >

                                                {/* <div onClick={() => { history.push(`/admin/student/create-assessment/${student.StudentId}`) }} className="card-customs col-4 mr-5 bg-info"> */}
                                                <div className="p-5">
                                                    <div className="media align-items-center">
                                                        <div className="media-body p-0">
                                                            <h4 className="text-white mb-0">Intent to Enroll </h4>
                                                            {studentStatus == null ? <span className="text-white">Enroll for S.Y {currentSy} / {currentSem}</span>
                                                                :
                                                                studentStatus == 'IE' ?
                                                                    <span className="text-info">Pending Assessment Verification For S.Y ({currentSy} / {currentSem})</span>
                                                                    :
                                                                    studentStatus == 'A' ?
                                                                        <span className="text-info">Pending Assessent of Fee Validation For S.Y ({currentSy} / {currentSem})</span>
                                                                        :
                                                                        studentStatus == 'PV' ?
                                                                            <span className="text-info">Pending Enrollment Payment For S.Y ({currentSy} / {currentSem})</span>
                                                                            :
                                                                            studentStatus == 'E' ?
                                                                                <span className="text-info">Already Enrolled For S.Y ({currentSy} / {currentSem})</span>
                                                                                :
                                                                                ''
                                                            }
                                                        </div>
                                                        <div className="align-self-center" >
                                                            <i className="fa fa-school text-white" style={{ fontSize: '40px' }} ></i>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* </div> */}
                                            </div>

                                        }


                                    </>


                                    :

                                    <>
                                        {student.LatestSchoolYear == currentSy && student.LatestSemesterId == currentSem && student.EnrollmentStatusId == 'E' ?
                                            <>
                                                <Link className="card-customs col-12 col-xl-4 mr-0 mr-xl-5 bg-success p-0" to={{ pathname: `/admin/student/create-assessment/${student.EnrollmentId}`, data: { isIntent: true, nextClassSectionId: student.NextClassSectionId, nextYearLevelId: student.NextYearLevelId, isCom: true } }} >
                                                    {/* <div onClick={() => { history.push(`/admin/student/create-assessment/${student.StudentId}`) }} className="card-customs col-4 mr-5 bg-info"> */}
                                                    <div className="p-5">
                                                        <div className="media align-items-center">
                                                            <div className="media-body p-0">
                                                                <h4 className="text-white mb-0">Certificate of Matriculation</h4>

                                                                {/* <span className="text-white">Already Enrolled For S.Y ({currentSy} / {currentSem})</span> */}


                                                            </div>
                                                            <div className="align-self-center" >
                                                                <i className="fa fa-school text-white" style={{ fontSize: '40px' }} ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </Link>
                                            </>
                                            :

                                            <>
                                                <div className="col-12 col-xl-4 mr-0 mr-xl-5 bg-secondary p-0"  >
                                                    {/* <div onClick={() => { history.push(`/admin/student/create-assessment/${student.StudentId}`) }} className="card-customs col-4 mr-5 bg-info"> */}
                                                    <div className="p-5">
                                                        <div className="media align-items-center">
                                                            <div className="media-body p-0">
                                                                <h4 className="text-dark mb-0">Intent to Enroll</h4>
                                                                {studentStatus == null ? <span className="text-white">Enroll for S.Y {currentSy} / {currentSem}</span>
                                                                    :
                                                                    studentStatus == 'IE' ?
                                                                        <span className="text-info">Pending Assessment Verification For S.Y ({currentSy} / {currentSem})</span>
                                                                        :
                                                                        studentStatus == 'A' ?
                                                                            <span className="text-info">Pending Assessent of Fee Validation For S.Y ({currentSy} / {currentSem})</span>
                                                                            :
                                                                            studentStatus == 'PV' ?
                                                                                <span className="text-info">Pending Enrollment Payment For S.Y ({currentSy} / {currentSem})</span>
                                                                                :
                                                                                studentStatus == 'E' ?
                                                                                    <span className="text-info">Already Enrolled For S.Y ({currentSy} / {currentSem})</span>
                                                                                    :
                                                                                    ''
                                                                }
                                                            </div>
                                                            <div className="align-self-center" >
                                                                <i className="fa fa-school text-white" style={{ fontSize: '40px' }} ></i>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    {/* </div> */}
                                                </div>
                                            </>

                                        }


                                    </>

                                }


                                <div className="card col-12 col-xl-4 mr-0 mr-xl-5 mt-5 mt-xl-0 bg-secondary p-0" >
                                    <div className="card-content p-5">
                                        <div className="card-body cleartfix p-0">
                                            <div className="media align-items-stretch">
                                                {/* <div className="align-self-center">
                                                    <h1 className="mr-2">$36,000.00</h1>
                                                </div> */}
                                                <div className="media-body">
                                                    <h4>Subjects</h4>
                                                    <span>List</span>
                                                </div>
                                                <div className="align-self-center" >
                                                    <i className="fa fa-book text-success" style={{ fontSize: '40px' }} ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card col-12 col-xl-4 mr-0 mr-xl-5 mt-5 mt-xl-0 bg-secondary p-0" >
                                    <div className="card-content p-5">
                                        <div className="card-body cleartfix p-0">
                                            <div className="media align-items-stretch">
                                                {/* <div className="align-self-center">
                                                    <h1 className="mr-2">$36,000.00</h1>
                                                </div> */}
                                                <div className="media-body">
                                                    <h4>Curriculums</h4>
                                                    <span>List</span>
                                                </div>
                                                <div className="align-self-center" >
                                                    <i className="fa fa-clipboard text-success" style={{ fontSize: '40px' }} ></i>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </section>


                            <section className="row mt-5 mx-auto" style={{ height: flexWrap == 'wrap' ? '30%' : '100%', display: 'flex', flexWrap: flexWrap, width: '100%' }}>
                                {/* <Link to="" className="card-customs col-4 mr-5 bg-success" style={{padding:'0px'}}> */}
                                <div className="card-customs col-12 col-xl-4 mr-5 mt-5 mt-xl-0 bg-success p-0" onClick={() => { history.push("/admin/student/individual-report-card") }} >
                                    <div className="p-5">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <h4 className="text-white mb-0">Report Card</h4>
                                                <span className="text-white">View Grades</span>
                                            </div>
                                            <div className="align-self-center" >
                                                <i className="fa fa-book text-white" style={{ fontSize: '40px' }} ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-customs col-12 col-xl-4 mr-5 mt-5 mt-xl-0 bg-success p-0" onClick={() => { history.push("/admin/student/student-account-search") }} >
                                    <div className="p-5">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <h4 className="text-white mb-0">Statement Of Account</h4>
                                                <span className="text-white">View Account/Payments Summary</span>
                                            </div>
                                            <div className="align-self-center" >
                                                <i className="fa fa-book text-white" style={{ fontSize: '40px' }} ></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card-customs col-12 col-xl-4 mr-5 mt-5 mt-xl-0 bg-success p-0" onClick={() => { history.push("/admin/user/profile") }} >
                                    <div className="p-5">
                                        <div className="media align-items-center">
                                            <div className="media-body">
                                                <h4 className="text-white mb-0">ID Card Information</h4>
                                                <span className="text-white">Update your Picture/Signature</span>
                                            </div>
                                            <div className="align-self-center" >
                                                <i className="fa fa-id-card text-white" style={{ fontSize: '40px' }}></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </section>


                        </section>



                    </main>

                </>
            }


        </>
    )
}