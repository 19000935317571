import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { enrollmentTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getAssessmentTemplateFilter, deleteAssessmentTemplate, getInstitute,
  getSchoolYear, getSemester, getCourses, getYearLevel,
  addUpdateAssessmentTemplate, getPaymentModeSelect,
  cloneAssessmentTemplate
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel, TableBody } from '@material-ui/core';
import { useForm } from 'react-hook-form';
import CloneAssessmentTemplateModal from '../../../../../_metronic/layout/components/custom/modal/CloneAssessmentTemplateModal';


export default function List() {
  const { state: { fees, auth }, dispatch } = useAppContext();
  const activeSy = auth.data.schoolyears.enrollment.SchoolYear
  const activeSem = auth.data.schoolyears.enrollment.SemesterId
  const { setValue } = useForm()
  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
  const [page, setPage] = useState(fees.page);
  const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
  const [keyword, setKeyword] = useState(fees.keyword);
  const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
  const [onAlert, setOnAlert] = useState(false)
  const [isCloned, setIsCloned] = useState(null)

  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [isOpenClone, setIsOpenClone] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)

  const [filter, setFilter] = useState({ schoolYear: activeSy, semesterId: activeSem, instituteId: null, yearLevelId: 21, paymentModeId: 2 })
  const updateId = fees.id;


  const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }
  const [instituteSelect, setInstituteSelect] = useState(null)
  const [schoolYearSelect, setSchoolYearSelect] = useState(null)
  const [semesterSelect, setSemesterSelect] = useState(null)
  const [courseSelect, setCourseSelect] = useState(null)
  const [yearLevelSelect, setYearLevelSelect] = useState(null)
  const [paymentModeSelect, setPaymentModeSelect] = useState(null)
  const [isSearch, setIsSearch] = useState(false)

  const [switchState, setSwitchState] = useState({
    isRegular: false

  });


  const [formData, setFormData] = useState({
    schoolYear: null,
    semesterId: null,
    instituteId: null,
    referenceName: null,
    studentCategoryId: null,
    courseId: null,
    yearLevelId: null,
    paymentModeId: null,
    isActive: 1,
    isIrregular: null,
    createdBy: auth.data.UserName
  })

  const formSelectChange = name => event => { setFormData({ ...formData, [name]: event }) }
  const filterSelectChange = name => event => { setFilter({ ...filter, [name]: event }) }

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };
  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // on save grade element  -----------------------------------------------------------
  const onSaveGradeElement = async (val) => {
    const values = {
      assessmentTemplateId: 0,
      schoolYear: val.schoolYear,
      semesterId: val.semesterId,
      referenceName: val.referenceName,
      studentCategoryId: val.studentCategoryId,
      instituteId: val.instituteId,
      courseId: val.courseId,
      yearLevelId: val.yearLevelId,
      isActive: val.isActive,
      createdBy: auth.data.Username,
      paymentModeId: val.paymentModeId,
      isIrregular: val.isIrregular
    }
    await addUpdateAssessmentTemplate(values)
      .then(res => {
        console.log(res)
        console.log(res == '')

        if (res == '') {
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: 'Duplicate Found' });

        } else {
          setIsOpenAdd(false)
          onForceSearchFilter(val.schoolYear, val.semesterId, val.yearLevelId, val.paymentModeId)
          // setFilter({ semesterId: res.data.SemesterId, schoolYear: res.data.SchoolYear })
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Assessment Template has been saved.' });
          setFormData({
            schoolYear: null,
            semesterId: null,
            instituteId: null,
            referenceName: null,
            yearLevelId: null,
            studentCategoryId: null,
            isActive: 1,
            createdBy: auth.data.UserName
          })
        }



      })
  }

  // on update grade element
  const onUpdateElement = async (id, val) => {
    const values = {
      assessmentTemplateId: id,
      schoolYear: val.schoolYear,
      semesterId: val.semesterId,
      referenceName: val.referenceName,
      studentCategoryId: val.studentCategoryId,
      instituteId: val.instituteId,
      courseId: val.courseId,
      yearLevelId: val.yearLevelId,
      isActive: val.isActive,
      createdBy: auth.data.Username,
      isIrregular: val.isIrregular,
      paymentModeId: val.paymentModeId
    }
    await addUpdateAssessmentTemplate(values)
      .then(res => {
        setIsOpenUpdate(false)
        onSearchFilters()
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Assessment Template has been updated.' });
      })
  }

  // on delete grade element
  const onDeleteElement = async (id) => {
    await deleteAssessmentTemplate({ assessmentTemplateId: id })
      .then(res => {
        setIsOpenDelete(false)
        onSearchFilters()
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Assessment Template has been Deleted.' });
      })
      .catch(e => {

      })
  }



  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    setIsOpenAdd(true)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {
    setRowValue(data)
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
  }

  // Clone


  const onExecuteClone = async (data) => {
    await cloneAssessmentTemplate(data)
      .then(res => {
        console.log(res)
        setIsCloned(res.data.IsClone == 1 ? true : false)
      })
  }

  const onClone = () => {
    setIsOpenClone(true)
  }


  const onCloseClone = () => {
    setIsOpenClone(false)
    setIsCloned(null)
  }

  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  // const getSubjectScheduleReport = async (val) => {
  //   await getReport(val)
  //     .then(res => {
  //       let arr = []
  //       res.data.map(items => {
  //         arr.push({ ...items, Details: JSON.parse(items.Details) })
  //       })
  //       setReport(arr)
  //     })
  // }


  const onSearchFilters = async () => {
    await getAssessmentTemplateFilter(page, rowsPerPage, filter.schoolYear, filter.semesterId, filter.yearLevelId, filter.paymentModeId)
      .then(res => {
        setResults(res.data)
        // getSubjectScheduleReport({
        //     schoolYear: filter.schoolYear,
        //     semesterId: filter.semesterId,
        //     instituteId: filter.instituteId,
        //     withInstitute: filter.instituteId !== null && filter.instituteId !== '' ? true : false
        // })
        setOnAlert(true)
        setTotalRecord(res.data.length > 0 ? res.data.length : 0);
        setIsSearch(true)

      })
  }


  const onForceSearchFilter = async (sy, sem, yearLeveld, paymentModeId) => {
    await getAssessmentTemplateFilter(page, rowsPerPage, sy, sem, yearLeveld, paymentModeId)
      .then(res => {
        setResults(res.data)

        setOnAlert(true)
        setTotalRecord(res.data.length > 0 ? res.data.length : 0);
      })
  }


  const onSeachAgain = () => {
    setIsSearch(false)

    setResults([])
    setFilter((prevData) => {
      return { ...prevData, yearLevelId: 21, paymentModeId: 2 }
    })
    setOnAlert(false)

  }

  useEffect(() => {
    SelectCreator(getCourses, setCourseSelect, "CourseId", "Description")
    SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description")
    SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description")
    SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Description')
    SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')
    SelectCreator(getPaymentModeSelect, setPaymentModeSelect, 'PaymentModeId', 'PaymentMode')

  }, [])


  useEffect(() => {
    if (isSearch == true) {
      // alert('test1')
      getAssessmentTemplateFilter(page, rowsPerPage, filter.schoolYear, filter.semesterId, filter.yearLevelId, filter.paymentModeId)
        .then(res => {
          setResults(res.data)
          // getSubjectScheduleReport({
          //     schoolYear: filter.schoolYear,
          //     semesterId: filter.semesterId,
          //     instituteId: filter.instituteId,
          //     withInstitute: filter.instituteId !== null && filter.instituteId !== '' ? true : false
          // })
          setOnAlert(true)
          setTotalRecord(res.data.length > 0 ? res.data.length : 0);
        })
    }

  }, [filter.yearLevelId, filter.paymentModeId])

  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      schoolYear: props.value !== undefined && props.value !== null ? props.value.schoolYear : null,
      semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : null,
      instituteId: props.value !== undefined && props.value !== null ? props.value.instituteId : null,
      referenceName: props.value !== undefined && props.value !== null ? props.value.referenceName : null,
      studentCategoryId: props.value !== undefined && props.value !== null ? props.value.studentCategoryId : null,
      yearLevelId: props.value !== undefined && props.value !== null ? props.value.yearLevelId : null,
      paymentModeId: props.value !== undefined && props.value !== null ? props.value.paymentModeId : null,


    })


    const [switchState, setSwitchState] = useState({
      isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false,


    });

    const switchHandleChange = name => event => {
      console.log(event.target.checked)
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects
    const handleChange = name => event => { setData({ ...data, [name]: event }) }






    useEffect(() => {
      if (data && switchState) {

        props.onSave({
          ...data,
          isActive: switchState.isActive == true ? 1 : 0
        })
      }

    }, [data, switchState])



    console.log(props.value)
    return (
      <>

        <main className='container'>

          <div className="form-row">
            <div className="form-group col">
              <label htmlFor="inputEmail4">Reference Name</label>
              <input
                required type="text"
                className="form-control"
                id="inputEmail4"
                placeholder="Short Name"
                defaultValue={props.value !== undefined && props.value !== null ? props.value.referenceName : ''}
                onBlur={(e) => { setData({ ...data, referenceName: e.target.value }) }}
              />
            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.paymentModeSelect}
                onHandleChange={handleChange('paymentModeId')}
                refClassContainer=""
                name="paymentModeId"
                isRequired={false}
                withLabel={false}
                label="Payment Mode"
                placeHolder="Select Payment Mode"
                forwardRef={() => { }}
                selectedId={data.paymentModeId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.schoolYearSelect}
                onHandleChange={handleChange('schoolYear')}
                refClassContainer=""
                name="schoolYear"
                isRequired={false}
                withLabel={false}
                label="School Year"
                placeHolder="Select School Year"
                forwardRef={() => { }}
                selectedId={data.schoolYear}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>
          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.semesterSelect}
                onHandleChange={handleChange('semesterId')}
                refClassContainer=""
                name="semesterId"
                isRequired={false}
                withLabel={false}
                label="Semester"
                placeHolder="Select Semester"
                forwardRef={() => { }}
                selectedId={data.semesterId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.instituteSelect}
                onHandleChange={handleChange('instituteId')}
                refClassContainer=""
                name="instituteId"
                isRequired={false}
                withLabel={false}
                label="Institute"
                placeHolder="Select Institute"
                forwardRef={() => { }}
                selectedId={data.instituteId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>
          </div>





          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                onHandleChange={handleChange('studentCategoryId')}
                name="studentCategoryId"
                isRequired={true}
                withLabel={true}
                label="Student Category"
                placeHolder="Student Category"
                forwardRef={setValue}
                selectedId={data.studentCategoryId}
                refClassName={``}
                withDescription={false}
                // description={`Please select your gender.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: '', label: '' },
                  { id: '1', label: 'Tertiary' },
                  { id: '10', label: 'Graduate Studies' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
              />

            </div>

          </div>


          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.yearLevelSelect}
                onHandleChange={handleChange('yearLevelId')}
                refClassContainer=""
                name="yearLevelId"
                isRequired={false}
                withLabel={false}
                label="Year Level"
                placeHolder="Select Year Level"
                forwardRef={() => { }}
                selectedId={data.yearLevelId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>
          </div>


          <div className="form-row">

            <div className="form-group col">
              <FormControl component="fieldset">
                <FormControlLabel
                  name="isActive"
                  inputRef={() => { }}
                  control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                  label="Active"
                />

              </FormControl>
            </div>
          </div>
        </main>
      </>
    )
  }

  const DeleteContent = (props) => {


    console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.referenceName : ''} `}</span> </p>
        </main>



      </>
    )
  }

  //Modal Content
  const CloneContent = (props) => {
    const [data, setData] = useState({
      schoolYear: props.value !== undefined && props.value !== null ? props.value.schoolYear : null,
      semesterId: props.value !== undefined && props.value !== null ? props.value.semesterId : null,
      schoolYearToClone: null,
      semesterIdToClone: null


    })



    // Selects
    const handleChange = name => event => { setData({ ...data, [name]: event }) }



    useEffect(() => {
      if (data) {

        props.onSave({
          ...data,
          createdBy: auth.data.Username
        })
      }

    }, [data])



    console.log(props.value)
    return (
      <>

        <main className='container'>

          <p className='lead'>Assessment Template to Create</p>
          <hr />

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.schoolYearSelect}
                onHandleChange={handleChange('schoolYear')}
                refClassContainer=""
                name="schoolYear"
                isRequired={false}
                withLabel={true}
                label="School Year"
                placeHolder="Select School Year"
                forwardRef={() => { }}
                selectedId={data.schoolYear}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>


          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.semesterSelect}
                onHandleChange={handleChange('semesterId')}
                refClassContainer=""
                name="semesterId"
                isRequired={false}
                withLabel={true}
                label="Semester"
                placeHolder="Select Semester"
                forwardRef={() => { }}
                selectedId={data.semesterId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>
          <p className='lead'>Assessment Template to Clone</p>
          <hr />
          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.schoolYearSelect}
                onHandleChange={handleChange('schoolYearToClone')}
                refClassContainer=""
                name="schoolYearToClone"
                isRequired={false}
                withLabel={true}
                label="School Year to Clone"
                placeHolder="Select School Year"
                forwardRef={() => { }}
                selectedId={data.schoolYearToClone}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>




          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.semesterSelect}
                onHandleChange={handleChange('semesterIdToClone')}
                refClassContainer=""
                name="semesterIdToClone"
                isRequired={false}
                withLabel={true}
                label="Semester to Clone"
                placeHolder="Select Semester"
                forwardRef={() => { }}
                selectedId={data.semesterIdToClone}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />

            </div>

          </div>

        </main>
      </>
    )
  }



  // useEffect(() => {
  //     const execute = async () => {
  //         await getSubjectSchedule(page, rowsPerPage, keyword)
  //             .then(response => {
  //                 if (response.success) {
  //                     setResults(response.data);
  //                     setTotalRecord(response.data.length > 0 ? response.records : 0);
  //                 } else {
  //                     dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
  //                 }
  //             })
  //             .catch(error => {

  //                 dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

  //             })
  //     }
  //     execute();

  // }, [keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'ReferenceName', label: 'Reference Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'YearLevel', label: 'YearLevel', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'SchoolYear', label: 'SchoolYear', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Semester', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'PaymentMode', label: 'Payment Mode', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Institute', label: 'Department', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsIrregDesc', label: 'Status', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'AssessmentTemplateId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdateAssessmentTemplate',
    parentId: null,
    tableTitle: 'List of Assessment',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: enrollmentTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)

  console.log(isOpenDelete)
  console.log(results)
  console.log(paymentModeSelect)
  console.log(semesterSelect)
  console.log(schoolYearSelect)
  console.log(isCloned)
  return (
    <>
      {instituteSelect && schoolYearSelect && semesterSelect && courseSelect && yearLevelSelect && paymentModeSelect &&
        <>
          <AddUpdateModal
            title="Add Assessment Template"
            isOpen={isOpenAdd}
            Content={Content}
            handleClose={onAddGradeElementClose}
            isAdd={true}
            instituteSelect={instituteSelect}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            courseSelect={courseSelect}
            yearLevelSelect={yearLevelSelect}
            paymentModeSelect={paymentModeSelect}
            onSubmit={onSaveGradeElement}
            id="assessmentTemplateId"

          />

          <AddUpdateModal
            title="Update Assessment Template"
            isOpen={isOpenUpdate}
            Content={Content}
            value={keysToCamelCase(rowValue)}
            handleClose={onUpdateGradeElementClose}
            isUpdate={true}
            instituteSelect={instituteSelect}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            yearLevelSelect={yearLevelSelect}
            paymentModeSelect={paymentModeSelect}
            courseSelect={courseSelect}
            onSubmit={onUpdateElement}
            id="assessmentTemplateId"

          />

          <AddUpdateModal
            title="Delete Assessment Template"
            isOpen={isOpenDelete}
            Content={DeleteContent}
            handleClose={onDeleteGradeElementClose}
            isDelete={true}
            onSubmit={onDeleteElement}
            instituteSelect={instituteSelect}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            yearLevelSelect={yearLevelSelect}
            courseSelect={courseSelect}
            paymentModeSelect={paymentModeSelect}
            value={keysToCamelCase(rowDeleteValue)}
            id="assessmentTemplateId"

          />


          <CloneAssessmentTemplateModal
            title="Clone Assessment Template"
            isOpen={isOpenClone}
            Content={CloneContent}
            handleClose={onCloseClone}
            schoolYearSelect={schoolYearSelect}
            semesterSelect={semesterSelect}
            onSubmit={onExecuteClone}
            isCloned={isCloned}
          />

          {results.length > 0 ?
            <>

              <div className="card card-custom gutter-b example example-compact">
                <section className='my-5 p-5'>
                  <div className="row">
                    {/* <div className="card-title">
                    <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                  </div> */}
                    <section className='col'>
                      <h5 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h5>

                    </section>
                    <section className='col'>
                      <IMSelect
                        data={yearLevelSelect}
                        onHandleChange={filterSelectChange('yearLevelId')}
                        refClassContainer=""
                        name="yearLeveld"
                        isRequired={false}
                        withLabel={false}
                        label="Year Level"
                        placeHolder="Year Level"
                        forwardRef={() => { }}
                        selectedId={filter.yearLevelId}
                        refClassName={`text-center mr-3`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </section>
                    <section className='col'>
                      <IMSelect
                        data={paymentModeSelect}
                        onHandleChange={filterSelectChange('paymentModeId')}
                        refClassContainer=""
                        name="paymentModeId"
                        isRequired={false}
                        withLabel={false}
                        label="Payment Mode"
                        placeHolder="Payment Mode"
                        forwardRef={() => { }}
                        selectedId={filter.paymentModeId}
                        refClassName={`text-center mr-3`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </section>



                    <button onClick={() => { onSeachAgain() }} className="btn btn-sm btn-icon-sm btn-secondary mr-3 col">
                      Search Again
                    </button>
                    {/* <PrintedForm
                                                report={report}
                                            /> */}
                    {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                                                <i className="fa fa-plus"></i> Add New
                                            </button> */}

                  </div>
                  <hr />
                </section>

                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                  <IMTable tableProps={tableProps} />

                  <section className='d-flex align-items-center'>


                    <div className="form-group col mb-0">
                      <input
                        type="text"
                        className="form-control"
                        id="inputEmail4"
                        placeHolder=""
                        defaultValue={formData.referenceName}
                        onBlur={(e) => { setFormData({ ...formData, referenceName: e.target.value }) }}

                      />
                      <p className='text-muted mt-1 mb-0' style={{ fontSize: '11px' }}>Reference Name</p>
                    </div>


                    <div className="form-group mb-0 col">
                      <IMSelect
                        data={yearLevelSelect}
                        onHandleChange={formSelectChange('yearLevelId')}
                        refClassContainer=""
                        name="yearLeveld"
                        isRequired={false}
                        withLabel={false}
                        label="Year Level"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.yearLevelId}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`Year Level`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>

                    <div className="form-group mb-0 col">
                      <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={formSelectChange('schoolYear')}
                        refClassContainer=""
                        name="schoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="School Year"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.schoolYear}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`School Year`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>

                    <div className="form-group mb-0 col">
                      <IMSelect
                        data={semesterSelect}
                        onHandleChange={formSelectChange('semesterId')}
                        refClassContainer=""
                        name="semesterId"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.semesterId}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`Semester`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>

                    <div className="form-group mb-0 col">
                      <IMSelect
                        data={paymentModeSelect}
                        onHandleChange={formSelectChange('paymentModeId')}
                        refClassContainer=""
                        name="paymentModeId"
                        isRequired={false}
                        withLabel={false}
                        label="Payment Mode"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.paymentModeId}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`Payment Mode`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>


                    <div className="form-group mb-0 col">
                      <IMSelect
                        data={instituteSelect}
                        onHandleChange={formSelectChange('instituteId')}
                        refClassContainer=""
                        name="instituteId"
                        isRequired={false}
                        withLabel={false}
                        label="Institute"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.instituteId}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`Department`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>

                    <div className="form-group mb-0 col">
                      <IMSelect
                        data={courseSelect}
                        onHandleChange={formSelectChange('courseId')}
                        refClassContainer=""
                        name="courseId"
                        isRequired={false}
                        withLabel={false}
                        label="Course"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.courseId}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`Course`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>
                    <div className="form-group mb-0 col">
                      <IMSelect
                        onHandleChange={formSelectChange('isIrregular')}
                        refClassContainer=""
                        name="isIrregular"
                        isRequired={false}
                        withLabel={false}
                        label="Status"
                        placeHolder=""
                        forwardRef={() => { }}
                        selectedId={formData.isIrregular}
                        refClassName={`text-center mr-3`}
                        withDescription={true}
                        description={`Status`}
                        refDisabled={true}
                        refIsStatic={true}
                        refStaticData={[
                          { id: 0, label: 'Regular' },
                          { id: 1, label: 'Irregular' },
                          { id: 2, label: 'Cross Enrollee' }

                        ]}
                        field={{
                          tableId: 'tableId',
                          display: 'code',
                        }}
                      />
                    </div>
                    {/* <div className="form-group mb-0 col">

                      <FormControlLabel
                        name="isRegular"
                        // inputRef={register()}
                        control={<Switch checked={switchState.isRegular} onChange={switchHandleChange('isRegular')} value={switchState.isRegular} />}
                      // label="Is Regular"
                      />
                      <p className='text-muted mt-1 mb-0' style={{ fontSize: '11px' }}>Is Regular</p>

                    </div> */}

                    <div className="form-group col mb-0  mx-auto">
                      <>
                        {formData.yearLevelId !== null && formData.yearLevelId !== ''
                          && formData.semesterId !== null && formData.semesterId !== ''
                          && formData.subjectId !== null && formData.subjectId !== '' ?
                          <button
                            onClick={() => { onSaveGradeElement(formData) }}
                            className="btn btn-md btn-primary mx-auto">
                            Add
                          </button>
                          :
                          <button
                            disabled
                            className="btn btn-md btn-secondary mx-auto">
                            Add
                          </button>
                        }
                        <p className='text-muted mt-1 mb-0' style={{ fontSize: '11px' }}>Action</p>
                      </>


                    </div>






                  </section>


                </div>
              </div>
            </>
            :
            <>
              <div className='d-flex align-items-center'>
                <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                  <header className='card-header p-5 bg-secondary'>
                    <h6 className="float-left m-0 mt-4 ml-5">Assessment Template</h6>
                    <div className=' float-right'>
                      <button onClick={() => { onClone() }} className='btn btn-sm btn-warning mr-2'>Clone</button>
                      <button onClick={() => { onAddGradeElement() }} className='btn btn-sm btn-primary mr-5'>Add</button>
                    </div>

                  </header>
                  <section className="card-body">
                    {onAlert == true && results.length == 0 &&
                      <div className="alert alert-danger" role="alert">
                        No Record Found
                      </div>
                    }
                    <p className="card-text">Search for Assessment Template</p>



                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={schoolYearSelect}
                          onHandleChange={filterChange('schoolYear')}
                          refClassContainer=""
                          name="schoolYear"
                          isRequired={false}
                          withLabel={false}
                          label="School Year"
                          placeHolder="Select School Year"
                          forwardRef={() => { }}
                          selectedId={filter.schoolYear}
                          refClassName={`text-center`}
                          withDescription={true}
                          description={`School Year`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[
                          ]
                          }
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                    </div>


                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={semesterSelect}
                          onHandleChange={filterChange('semesterId')}
                          refClassContainer=""
                          name="semesterId"
                          isRequired={false}
                          withLabel={false}
                          label="Semester"
                          placeHolder="Select Semester"
                          forwardRef={() => { }}
                          selectedId={filter.semesterId}
                          refClassName={`text-center`}
                          withDescription={true}
                          description={`Semester`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[
                          ]
                          }
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                    </div>

                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={yearLevelSelect}
                          onHandleChange={filterSelectChange('yearLevelId')}
                          refClassContainer=""
                          name="yearLeveld"
                          isRequired={false}
                          withLabel={false}
                          label="Year Level"
                          placeHolder="Year Level"
                          forwardRef={() => { }}
                          selectedId={filter.yearLevelId}
                          refClassName={`text-center mr-3`}
                          withDescription={true}
                          description={`Year Level`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[]}
                          field={{
                            tableId: 'tableId',
                            display: 'code',
                          }}
                        />
                      </div>
                    </div>



                    <div className="form-row">
                      <div className="form-group col">
                        <IMSelect
                          data={paymentModeSelect}
                          onHandleChange={filterSelectChange('paymentModeId')}
                          refClassContainer=""
                          name="paymentModeId"
                          isRequired={false}
                          withLabel={false}
                          label="Payment Mode"
                          placeHolder="Payment Mode"
                          forwardRef={() => { }}
                          selectedId={filter.paymentModeId}
                          refClassName={`text-center mr-3`}
                          withDescription={true}
                          description={`Payment Mode`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[]}
                          field={{
                            tableId: 'tableId',
                            display: 'code',
                          }}
                        />
                      </div>
                    </div>



                    <div className="form-row bg-secondary">
                      <button onClick={() => { onSearchFilters() }} className='btn btn-success btn-block'>Search</button>

                    </div>

                  </section>
                </main>

              </div>

            </>

          }


        </>
      }

    </>
  );
}