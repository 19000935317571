import React, { useState, useEffect } from "react";
import { createPayment } from "./__hooks__/index.js";
import { getStudentAssessmentTemplateDetail } from "./__hooks__/proc.js"
import { useAppContext } from "../../../../contexts/useAppContext.js";
import { accountTypes } from "./__hooks__/types.js";
import { useParams } from "react-router-dom";
import { useForm } from 'react-hook-form';
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types.js";
import IMTableAssessmentFee from "../../../../../_metronic/layout/components/custom/table/IMTableAssessmentFee.js"
import history from "../../../../history.js";
import { NoAccess } from "../../../../../_metronic/layout/components/custom/forms/NoAccess.js";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading.js";
import NoDataFound from "../../../../../_metronic/layout/components/custom/forms/NoDataFound.js";
import IMTableStudentAssessment from "../../../../../_metronic/layout/components/custom/table/IMTableStudentAssessment.js";

export default function AccountSummaryPayments(props) {
    const { studentAccount, studentFees, studentDiscount, payments,
        backPayments, totalFees, isPending, totalDiscount, totalInterest,
        studentOtherFees, totalOtherFee, totalAssessmentFee, studentAddOns, totalAddOns,
        totalPayment, paymentSchedule, schoolYearSelected,
        semesterIdSelected, listOfBackAccounts } = props
    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
    const [result, setResult] = useState(null);
    const [totalBackAccount, setTotalBackAccount] = useState(0.00);

    const { state: { auth }, dispatch } = useAppContext();

    const [syDescription, setSYDescription] = useState(schoolYearSelected);
    const [semDescription, setSemDescription] = useState(semesterIdSelected);

    useEffect(() => {
        if (typeof (semesterIdSelected) !== "undefined" && typeof (schoolYearSelected) !== "undefined") {

        }
        let semesterId = semesterIdSelected;
        let schoolYear = schoolYearSelected;
        let schoolYearPlusOne = schoolYear + 1;
        switch (semesterId) {
            case '1S':
                setSemDescription('1st semester');
                break;
            case '2S':
                setSemDescription('2nd semester');
                break;
            case 'SM':
                setSemDescription('Summer');
                break;
            default:
                setSemDescription('1st semester');
                break;
        }

        setSYDescription("SY " + schoolYear + "-" + schoolYearPlusOne);

    }, []);


    const columnsPayment = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeCode', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FinalAmount', type: 'Amount', label: 'Amount', align: 'left', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }

    ]

    const columnsOtherFee = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeCode', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Fee', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    ]

    const columnsDiscount = [
        { id: 'RowCnt', label: '#', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Scholarship', label: 'Scholarship', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DiscountAmount', label: 'Discount Fee', align: 'right', withSorting: true, hidden: false, disablePadding: false, format: true },

    ]

    const columnsRecievedPayments = [
        { id: 'RowCnt', label: '#', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SYSem', label: 'S.Y / Semester', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'OrNo', label: 'OR Number', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DATE', label: 'Date', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Teller', label: 'Teller', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TYPE', label: 'Type', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TotalAmount', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },

    ]

    const columnsBackAccount = [
        { id: 'RowCnt', label: '#', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SYSem', label: 'S.Y / Semester', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'OrNo', label: 'OR Number', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DATE', label: 'Date', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Teller', label: 'Teller', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TYPE', label: 'Type', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TotalAmount', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },

    ]

    const columnsAddOns = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeDesc', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Amount', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    ]
    const tablePropsGroupSummary = {
        recordId: 'AssessmentTemplateDetailId',
        sortField: '',
        columns: columnsPayment,
        rows: studentFees,
        totalRecords: studentFees.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Fee',
    }

    const tablePropsOtherFeeGroup = {
        recordId: 'FeeId',
        sortField: '',
        columns: columnsOtherFee,
        rows: studentOtherFees !== null && studentOtherFees !== undefined ? studentOtherFees : [],
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Other Fee Details',

    }

    const tablePropsAddOnsGroup = {
        recordId: 'PaymentAddOnsId',
        sortField: '',
        columns: columnsAddOns,
        rows: studentAddOns,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Add Ons',

    }

    const tablePropsDiscount = {
        recordId: 'ScholarshipId',
        sortField: '',
        columns: columnsDiscount,
        rows: studentDiscount.length > 0 ? studentDiscount : [],
        totalRecords: studentDiscount.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Scholarship and Discounts',

    }

    const tablePropsPaymentRecieved = {
        recordId: 'PaymentId',
        sortField: '',
        columns: columnsRecievedPayments,
        rows: payments !== undefined && payments !== null && payments.length > 0 ? payments : [],
        totalRecords: payments !== undefined && payments !== null && payments.length > 0 ? payments.length : 0,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: '',
        withTableTitle: false,

    }

    const tablePropsPaymentBackAccount = {
        recordId: 'PaymentId',
        sortField: '',
        columns: columnsBackAccount,
        rows: backPayments !== undefined && backPayments !== null && backPayments.length > 0 ? backPayments : [],
        totalRecords: backPayments !== undefined && backPayments !== null && backPayments.length > 0 ? backPayments.length : 0,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: '',
        withTableTitle: false,
    }

    useEffect(() => {
        if (listOfBackAccounts !== undefined && listOfBackAccounts !== null && listOfBackAccounts.length > 0) {
            let totalRB = 0.00;
            let res = listOfBackAccounts.map(detail => {
                totalRB += detail[0];
                let nextSY = detail[1] + 1;
                let sy = detail[1] + " - " + nextSY + " / " + detail[2];
                return {
                    Amount: detail[0],
                    SYSem: sy,
                };
            });

            setTotalBackAccount(totalRB);
            setResult(res);
        }
    }, [listOfBackAccounts])

    const columnsBackAccount_RB = [
        { id: 'RowCnt', label: '#', align: 'center', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SYSem', label: 'S.Y / Semester', align: 'center', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'SemesterId', label: 'Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Amount', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    ]

    const tablePropsBackAccount_RB = {
        recordId: 'PaymentId',
        sortField: '',
        columns: columnsBackAccount_RB,
        rows: result !== undefined && result !== null && result.length > 0 ? result : [],
        totalRecords: result !== undefined && result !== null && result.length > 0 ? result.length : 0,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: '',
        withTableTitle: false,
    }

    return (
        <>

            <div className="row">
                <div className="col-12">
                    <div className="card">
                        <div className="card-body ">

                            <section className="">
                                <div className="" style={{ marginLeft: "15%", marginRight: "15%" }}>
                                    <div style={{ textAlign: "center" }}>
                                        <p><em>The <strong>Astra E-SOA</strong> currently reflects your outstanding balance for the {semDescription} of {syDescription}.</em></p>
                                        <p><em>Your SOA for the prior years up to the 1st semester of SY 2023-2024 will still be sent through your email accounts.</em></p>
                                        <p><em>For immediate concerns, kindly send your queries in this email (<strong>jllomonsod@firstasia.edu.ph</strong>). Thank you.</em></p>
                                    </div>
                                </div>
                            </section>

                            <br></br>

                            <div className=" mt-5 ml-5">
                                <h3 className="display-4">{studentAccount[0].StudentName}</h3>

                                <div className="row">
                                    <div className="col">
                                        <p className="my-0 lead">{studentAccount[0].CourseDesc}</p>
                                        <p className="my-0 lead">{studentAccount[0].SY}</p>
                                        <p className="my-0 lead">{studentAccount[0].Semester}</p>
                                        <p className="mt-0 lead mb-5">{studentAccount[0].StudentNo}</p>
                                    </div>

                                    <div className="col" style={{ textAlign: "end", paddingRight: "40px" }}>
                                        {paymentSchedule !== null &&
                                            <>
                                                {paymentSchedule.length > 0 &&
                                                    <div>
                                                        <p className="mt-0 lead"><span style={{ fontWeight: "600" }}>Payment Period:</span> {paymentSchedule[0].ExamPeriod}</p>
                                                        <p className="mt-0 lead"><span style={{ fontWeight: "600" }}>Payment Due*:</span> ₱{numberWithCommas(paymentSchedule[0].PaymentDue)}</p>
                                                        <p className="mt-0 lead"><span style={{ fontWeight: "600" }}>Due Date:</span> {paymentSchedule[0].DueDate}</p>
                                                    </div>
                                                }
                                            </>
                                        }
                                    </div>
                                </div>
                            </div>

                            <div className="">
                                <section className="card-header  ">
                                    <h2 className="text-dark d-inline">Account Summary</h2>
                                </section>

                                <div className="row">
                                    <div className="col-6">

                                        <div className="p-5">

                                            <IMTableAssessmentFee tableProps={tablePropsGroupSummary} />
                                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5">Total: ₱{numberWithCommas(parseFloat(totalAssessmentFee).toFixed(2))}</h6> </div>

                                        </div>
                                        <div className="card  border-0 container mb-5">
                                            <IMTableStudentAssessment
                                                tableProps={tablePropsOtherFeeGroup}
                                            />
                                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5">₱{numberWithCommas(parseFloat(totalOtherFee).toFixed(2))}</h6> </div>

                                            {/* <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right mr-3">Total: 1000</h6> </div> */}
                                        </div>






                                    </div>

                                    <div className="col-6">

                                        <div className=" p-5">

                                            <IMTableAssessmentFee tableProps={tablePropsAddOnsGroup} />
                                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-2 mr-5">Total Equivalent: ₱ {numberWithCommas(parseFloat(totalAddOns).toFixed(2))}</h6> </div>





                                        </div>
                                        <div className=" p-5">

                                            <IMTableAssessmentFee tableProps={tablePropsDiscount} />
                                            {isPending === false ?
                                                <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-2 mr-5">Total Equivalent: ₱ {numberWithCommas(parseFloat(totalDiscount).toFixed(2))}</h6> </div>

                                                :
                                                <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0  pr-2 mr-5 ">For Approval</h6> </div>

                                            }

                                        </div>

                                        <div className=" mb-3" >
                                            <div className="row no-gutters">

                                                <div className="col-md-12">
                                                    <div className="card-body">
                                                        <h5 className="card-title">Computations</h5>
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className="lead">Fees: </p>

                                                            </div>
                                                            <div className="col">

                                                                <h6 className="mb-0 text-right pr-5 mr-5"> ₱ {numberWithCommas(parseFloat(totalFees).toFixed(2))}</h6>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <p className="lead">Interest: </p>

                                                            </div>
                                                            <div className="col">

                                                                <h6 className="mb-0 text-right pr-5 mr-5">  ₱ {numberWithCommas(parseFloat(totalInterest).toFixed(2))}</h6>
                                                            </div>
                                                        </div>

                                                        <div className="row">
                                                            <div className="col">
                                                                <p className="lead">Discounts: </p>

                                                            </div>
                                                            <div className="col">

                                                                <h6 className="mb-0 text-right pr-5 mr-5"> - ₱ {numberWithCommas(parseFloat(totalDiscount).toFixed(2))}</h6>
                                                            </div>
                                                        </div>


                                                        <hr />
                                                        <div className="row">
                                                            <div className="col">
                                                                <p className="lead">Total: </p>

                                                            </div>
                                                            <div className="col">

                                                                <h6 className="mb-0 text-right pr-5 mr-5"> ₱ {totalFees + totalInterest - totalDiscount > 0 ?
                                                                    numberWithCommas(parseFloat(totalFees + totalInterest - totalDiscount).toFixed(2))
                                                                    :
                                                                    0
                                                                } </h6>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>

                            <div className=" my-5">
                                <h2 className="card-header  text-dark p-5 ">Back Accounts**</h2>
                                <IMTableAssessmentFee tableProps={tablePropsBackAccount_RB} />
                                <div className="mt-0 alert alert-secondary p-3 row justify-content-between">
                                    <h6 className="mb-0 text-left pr-2 mr-5 col">Total Back Account: </h6>

                                    <h6 className="mb-0 text-right pr-2 col">₱ {numberWithCommas(parseFloat(totalBackAccount).toFixed(2))}</h6>
                                </div>

                            </div>

                            <br></br>
                            <br></br>

                            <div className=" my-5">
                                <h2 className="card-header  text-dark p-5 ">Received Payments</h2>
                                <IMTableAssessmentFee tableProps={tablePropsPaymentRecieved} />
                                <div className="mt-0 alert alert-secondary p-3 row justify-content-between">
                                    <h6 className="mb-0 text-left pr-2 mr-5 col">Total Payment: </h6>

                                    <h6 className="mb-0 text-right pr-2 col">₱ {numberWithCommas(parseFloat(totalPayment).toFixed(2))}</h6>
                                </div>


                            </div>
                            {/* <br /> <br /> */}

                            <div className=" my-5">
                                <div className="mt-0 alert alert-secondary p-3 row justify-content-between">
                                    <h6 className="mb-0 text-left pr-2 mr-5 col">BALANCE***: </h6>

                                    <h6 className="mb-0 text-right pr-2 col">₱ {numberWithCommas(parseFloat((((totalFees + totalInterest - totalDiscount) - totalPayment)) + totalBackAccount).toFixed(2))}</h6>
                                </div>
                            </div>

                            <div className=" my-5">
                                <p style={{ fontStyle: "italic" }}>*<strong>PAYMENT DUE </strong>reflects the current semester's: (1) <strong>amount of current payment period</strong>, (2) <strong>unpaid amounts from previous payment periods</strong>, and (3) <strong>other fee details</strong>, with received payments already deducted.</p>
                            </div>

                            <div className=" my-5">
                                <p style={{ fontStyle: "italic" }}>**<strong>BACK ACCOUNTS</strong> in Astra E-SOA are only up to 2nd semester of SY 2023-2024. Your SOA for the prior years up to the 1st semester of SY 2023-2024 will still be sent through your email accounts.</p>
                            </div>

                            <div className=" my-5">
                                <p style={{ fontStyle: "italic" }}>***<strong>BALANCE</strong> shows the (1) <strong>current semester's balance</strong> and (2) <strong>remaining balance of previous semesters (Back Account) up to 2nd semester of SY 2023-2024</strong>, with received payments already deducted.</p>
                            </div>

                            {/* <div className=" my-5">
                                <h2 className="card-header  text-dark p-5 ">Back Account</h2>
                                <hr />
                                <IMTableAssessmentFee tableProps={tablePropsPaymentBackAccount} />


                            </div> */}


                        </div>
                    </div>
                </div>

            </div>






        </>
    )
}