import instance from "../../../../../apis/local/systemAPI";


export const getStudentMasterFile = async (val) => {
    const result = await instance.post(`api/students/studentMasterFile`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getStudentMasterIndividualData = async (val) => {
    const result = await instance.post(`api/students/studentMasterData`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCivilStatus = async () => {
    const result = await instance.get("api/references/civilstatus");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getReligion = async () => {
    const result = await instance.get("api/references/religions");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCountry = async () => {
    const result = await instance.get("api/references/countries");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getProvince = async () => {
    const result = await instance.get("api/references/provinces");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getMunicipality = async () => {
    const result = await instance.get(`api/references/municipalities`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourses = async () => {
    const result = await instance.get("api/references/courses");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchool = async () => {
    const result = await instance.get("api/references/schools");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevel = async (formValues) => {
    const result = await instance.get("api/references/yearlevels", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getFamilyPositions = async () => {
    const result = await instance.get("api/references/family-position");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getNationality = async (condition) => {
    const result = await instance.post("api/references/nationalities/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateApplicants = async (val) => {
    console.log(val)
    const result = await instance.post("api/students/register", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const addUpdateStudentSiblings = async (val) => {
    const result = await instance.post("api/students/addUpdateStudentSibling", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getImage = async (studentNo) => {
    const result = await instance.get(`/getImage/${studentNo}.jpg`);
    if (result.status === 200 || result.status === 201) {
        console.log(result)
        return `${result.config.baseURL}${result.config.url}`;
    } else {
        return false
    }
}

export const getSignature = async (studentNo) => {
    const result = await instance.get(`/getSignature/${studentNo}.jpg`);
    if (result.status === 200 || result.status === 201) {
        console.log(result)
        return `${result.config.baseURL}${result.config.url}`;
    } else {
        return false
    }
}

export const isImageSignatureApproved = async (val) => {
    const result = await instance.post("api/user/isApprovedImageSignature", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const sendMail = async (values) => {
    const result = await instance.post("api/nodemailer/send-mail", values);
    if (result.status === 200 || result.status === 201) {
        // alert('email send')
    }
}