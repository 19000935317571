import React, { useState, useEffect } from 'react';
import Form from './Form'
import { useParams } from 'react-router-dom';
import Loading from '../../../../../../_metronic/layout/components/custom/forms/Loading';
import {
    getCivilStatus, getReligion, getCourse, getYearLevel, getSchool,
    getNationality, getFamilyNetIncomes, getEducationalPlans, getScholarships, getFamilyPositions,
    getProvinces, getMunicipalities, getCountries, getStudentStatus
} from "./__hooks__"
import { useAppContext } from '../../../../../contexts/useAppContext';
import SelectCreator from '../../../../../../_metronic/functions/SelectCreator';
import { NationalitySelect } from '../../../References';


export default function Create(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const { id } = useParams()
    const [isLogin, setIsLogin] = useState(false)
    const [civilStatus, setCivilStatus] = useState()
    const [religion, setReligion] = useState()
    const [course, setCourse] = useState()
    const [yearLevel, setYearLevel] = useState()
    const [schools, setSchools] = useState()
    const [citizenship, setCitizenship] = useState()
    const [familyIncome, setFamilyIncome] = useState()
    const [educationalPlan, setEducationalPlan] = useState()
    const [scholarship, setScholarship] = useState()
    const [familyPosition, setFamilyPosition] = useState()
    const [provinces, setProvinces] = useState()
    const [municipalities, setMunicipalities] = useState()
    const [countries, setCountries] = useState()
    const [studentStatus, setStudentStatus] = useState()

    const [civilStatusSelect, setCivilStatusSelect] = useState()
    const [religionSelect, setReligionSelect] = useState()
    const [courseSelect, setCourseSelect] = useState()
    const [yearLevelSelect, setYearLevelSelect] = useState()
    const [schoolSelect, setSchoolSelect] = useState()
    const [citizenshipSelect, setCitizenshipSelect] = useState()
    const [familyIncomeSelect, setFamilyIncomeSelect] = useState()
    const [educationalPlanSelect, setEducationalPlanSelect] = useState()
    const [scholarshipSelect, setScholarshipSelect] = useState()
    const [familyPositionSelect, setFamilyPostitionSelect] = useState()
    const [provinceSelect, setProvinceSelect] = useState()
    const [municipalitySelect, setMunicipalitySelect] = useState()
    const [countrySelect, setCountrySelect] = useState()
    const [studentStatusSelect, setStudentStatusSelect] = useState()
    const [nationalitySelect, setNationalitySelect] = useState()
    const [nationalities, setNationalities] = useState()



    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }


    const onChangeCountry = (countryId) => {
        const getAll = "{where: 'CountryId = '}"

        // provinces
        const filterCountry = async (id) => {
            await getProvinces({ where: `WHERE CountryId = ${id}` })
                .then(res => {
                    setProvinces(res.data)
                })
                .catch(e => {

                })
        }

        filterCountry(countryId)


    }

    const onChangeProvince = (provinceId) => {

        const filterProvince = async (id) => {
            await getMunicipalities({ where: `WHERE municipalities.ProvinceId = ${id}` })
                .then(res => {
                    setMunicipalities(res.data)
                })
                .catch(e => {

                })
        }

        filterProvince(provinceId)


    }




    const getAll = "{where: ''}"
    useEffect(() => {

        // civil status
        Query(getCivilStatus, getAll, setCivilStatus)
        // religion
        Query(getReligion, getAll, setReligion)
        // course
        Query(getCourse, "{where: 'WHERE IsActive = 1'}", setCourse)
        // year level
        Query(getYearLevel, "{ where: 'WHERE IsActive = 1' }", setYearLevel)
        // schools
        Query(getSchool, getAll, setSchools)
        // citizenship
        Query(getNationality, getAll, setCitizenship)
        // family net income
        Query(getFamilyNetIncomes, getAll, setFamilyIncome)
        // educational plan
        Query(getEducationalPlans, getAll, setEducationalPlan)
        // scholarships
        Query(getScholarships, getAll, setScholarship)
        // family postions
        Query(getFamilyPositions, getAll, setFamilyPosition)
        // provinces
        Query(getProvinces, getAll, setProvinces)
        // municipalities
        Query(getMunicipalities, getAll, setMunicipalities)
        // countries
        Query(getCountries, getAll, setCountries)
        // student status
        Query(getStudentStatus, getAll, setStudentStatus)
        // nationality
        Query(getNationality, getAll, setNationalities)


    }, [])




    useEffect(() => {
        if (civilStatus !== undefined && religion !== undefined && course !== undefined && yearLevel !== undefined
            && schools !== undefined && citizenship !== undefined && familyIncome !== undefined && educationalPlan !== undefined &&
            scholarship !== undefined && familyPosition !== undefined && provinces !== undefined && municipalities !== undefined &&
            countries !== undefined && studentStatus !== undefined && nationalities !== undefined) {

            let civilStatusArr = []
            let religionArr = []
            let courseArr = []
            let yearLevelArr = []
            let schoolsArr = [{ tableId: -1, code: 'Not in the List' }]
            let citizenshipArr = []
            let familyIncomeArr = []
            let educationalPlanArr = []
            let scholarshipArr = []
            let familyPositionArr = []
            let provincesArr = []
            let municipalitiesArr = []
            let countriesArr = []
            let studentStatusArr = []
            let nationalityArr = []

            for (let c = 0; c < civilStatus.length; c++) {
                civilStatusArr.push({ tableId: civilStatus[c].CivilStatusId, code: civilStatus[c].CivilStatus })
            }

            for (let r = 0; r < religion.length; r++) {
                religionArr.push({ tableId: religion[r].ReligionId, code: religion[r].Religion })
            }

            for (let crs = 0; crs < course.length; crs++) {
                courseArr.push({ tableId: course[crs].CourseId, code: course[crs].Description })
            }

            for (let y = 0; y < yearLevel.length; y++) {
                yearLevelArr.push({ tableId: yearLevel[y].YearLevelId, code: yearLevel[y].Description })
            }

            for (let s = 0; s < schools.length; s++) {
                schoolsArr.push({ tableId: schools[s].SchoolId, code: schools[s].School })
            }

            for (let ctz = 0; ctz < citizenship.length; ctz++) {
                citizenshipArr.push({ tableId: citizenship[ctz].NationalityId, code: citizenship[ctz].Nationality })
            }


            for (let f = 0; f < familyIncome.length; f++) {
                familyIncomeArr.push({ tableId: familyIncome[f].MonthlyFamilyNetIncomeId, code: familyIncome[f].NetIncome })
            }


            for (let e = 0; e < educationalPlan.length; e++) {
                educationalPlanArr.push({ tableId: educationalPlan[e].EducationalPlanId, code: educationalPlan[e].Description })
            }

            for (let sc = 0; sc < scholarship.length; sc++) {
                scholarshipArr.push({ tableId: scholarship[sc].ScholarshipId, code: scholarship[sc].Scholarship })
            }

            for (let fp = 0; fp < familyPosition.length; fp++) {
                familyPositionArr.push({ tableId: familyPosition[fp].FamilyPositionId, code: familyPosition[fp].Description })
            }

            for (let pr = 0; pr < provinces.length; pr++) {
                provincesArr.push({ tableId: provinces[pr].ProvinceId, code: provinces[pr].Province })
            }

            for (let mu = 0; mu < municipalities.length; mu++) {
                municipalitiesArr.push({ tableId: municipalities[mu].MunicipalityId, code: municipalities[mu].Municipality })
            }

            for (let co = 0; co < countries.length; co++) {
                countriesArr.push({ tableId: countries[co].CountryId, code: countries[co].Country })
            }


            for (let co = 0; co < studentStatus.length; co++) {
                studentStatusArr.push({ tableId: studentStatus[co].StudentStatusId, code: studentStatus[co].Description })
            }

            for (let co = 0; co < nationalities.length; co++) {
                nationalityArr.push({ tableId: nationalities[co].NationalityId, code: nationalities[co].Nationality })
            }
            setCivilStatusSelect(civilStatusArr)
            setReligionSelect(religionArr)
            setCourseSelect(courseArr)
            setYearLevelSelect(yearLevelArr)
            setSchoolSelect(schoolsArr)
            setCitizenshipSelect(citizenshipArr)
            setFamilyIncomeSelect(familyIncomeArr)
            setEducationalPlanSelect(educationalPlanArr)
            setScholarshipSelect(scholarshipArr)
            setFamilyPostitionSelect(familyPositionArr)
            setProvinceSelect(provincesArr)
            setMunicipalitySelect(municipalitiesArr)
            setCountrySelect(countriesArr)
            setStudentStatusSelect(studentStatusArr)
            setNationalitySelect(nationalityArr)


        }

    }, [civilStatus, religion, course, yearLevel, schools, citizenship, familyIncome, educationalPlan, scholarship, familyPosition, provinces, municipalities, countries, studentStatus, nationalities])


    console.log(civilStatusSelect)
    console.log(religionSelect)
    console.log(courseSelect)
    console.log(yearLevelSelect)
    console.log(schoolSelect)
    console.log(citizenshipSelect)
    console.log(familyIncomeSelect)
    console.log(educationalPlanSelect)
    console.log(scholarshipSelect)
    console.log(familyPositionSelect)
    console.log(auth)
    console.log(provinceSelect)
    console.log(municipalitySelect)
    console.log(countrySelect)
    console.log(studentStatusSelect)

    return (
        <>

            {civilStatusSelect !== undefined && religionSelect !== undefined && courseSelect !== undefined && yearLevelSelect !== undefined &&
                schoolSelect !== undefined && citizenshipSelect !== undefined && familyIncomeSelect !== undefined && educationalPlanSelect !== undefined &&
                scholarshipSelect !== undefined && familyPositionSelect !== undefined && auth !== undefined
                && provinceSelect !== undefined && municipalitySelect !== undefined && countrySelect !== undefined && studentStatusSelect !== undefined
                && nationalitySelect !== undefined


                ?

                <div className="kt-container  kt-grid__item kt-grid__item--fluid">
                    <div className="row ac-minimum-height-container-350">
                        <div className="col-xl-12 col-lg-12 order-xl-1 order-lg-1 p-5">
                            <Form
                                civilStatusSelect={civilStatusSelect}
                                religionSelect={religionSelect}
                                courseSelect={courseSelect}
                                yearLevelSelect={yearLevelSelect}
                                schoolSelect={schoolSelect}
                                citizenshipSelect={citizenshipSelect}
                                familyIncomeSelect={familyIncomeSelect}
                                educationalPlanSelect={educationalPlanSelect}
                                scholarshipSelect={scholarshipSelect}
                                familyPositionSelect={familyPositionSelect}
                                provinceSelect={provinceSelect}
                                municipalitySelect={municipalitySelect}
                                countrySelect={countrySelect}
                                studentStatusSelect={studentStatusSelect}
                                onChangeCountry={onChangeCountry}
                                onChangeProvince={onChangeProvince}
                                nationalitySelect={nationalitySelect}


                            />
                        </div>
                    </div>
                </div>
                :
                <Loading />
            }





        </>
    );
}