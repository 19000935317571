import instance from "../../../../../apis/local/systemAPI";


export const getStudentTracer = async (formValues) => {
    const result = await instance.post("api/enrollments/studentAccountTracer", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

