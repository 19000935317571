import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';


export default function Form(props) {
    const { register, handleSubmit, errors, setValue } = useForm();
    // const [birthDate, setBirthDate] = useState(props.doc.BirthDate === undefined ? moment().format('YYYY-MM-DD') : moment(props.doc.BirthDate).format('YYYY-MM-DD'));
    const [data, setData] = useState({
        SchoolType: null
    })
    console.log(props);
    const [switchState, setSwitchState] = useState({
        IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
    });

    useEffect(() => {
        register({ name: 'schoolType' }, { required: true });
    }, [register])

    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    const selectHandleChange = name => event => {
        setData({ ...data, [name]: event });
    }

    const onSubmit = formValues => {
        const { schoolName, shortName, schoolType, schoolAddress, SchoolGroupId, IsActive } = formValues;

        const convertedFormValues = {
            schoolName: schoolName,
            shortName: shortName,
            schoolType: schoolType,
            schoolAddress: schoolAddress,
            SchoolGroupId: SchoolGroupId ? null : 0,
            IsActive: IsActive ? 1 : 0
        }

        console.log(convertedFormValues);
        console.log("this is converted values")
        props.onSubmit(convertedFormValues);
    }
    return (
        <>
            <div className="card card-custom gutter-b example example-compact">
                <div className="card-header">
                    <div className="card-title">
                        <h3 className="card-label">Form Details</h3>
                    </div>
                </div>
                <div className="card-body">
                    <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
                        <div className="kt-portlet__body">

                            <div className="form-group">
                                <label className="col-form-label">School <span className="text-danger">*</span> : </label>
                                <input
                                    type="text"
                                    name="schoolName"
                                    defaultValue={props.doc.School === undefined ? "" : props.doc.School}
                                    className={`form-control${errors.School === undefined ? "" : errors.School && " is-invalid"}`}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                    maxLength="100"
                                />
                                <span className="form-text text-muted">Please enter the School Name.</span>
                                {errors.school && errors.school.type === "required" && <div className="invalid-feedback">School Name is required</div>}
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">School Alias <span className="text-danger">*</span> : </label>
                                <input
                                    type="text"
                                    name="shortName"
                                    defaultValue={props.doc.ShortName === undefined ? "" : props.doc.ShortName}
                                    className={`form-control ${errors.ShortName === undefined ? "" : errors.ShortName && " is-invalid"}`}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                />
                                <span className="form-text text-muted">Please enter the School Short Name.</span>
                                {errors.ShortName && errors.ShortName.type === "required" && <div className="invalid-feedback">Short Name is required</div>}
                            </div>
                            <div className="form-group">
                                <label className="col-form-label">School Address <span className="text-danger">*</span> : </label>
                                <input
                                    type="text"
                                    name="schoolAddress"
                                    defaultValue={props.doc.SchoolAddress === undefined ? "" : props.doc.SchoolAddress}
                                    className={`form-control ${errors.SchoolAddress === undefined ? "" : errors.SchoolAddress && " is-invalid"}`}
                                    ref={register({
                                        required: true
                                    })}
                                    autoComplete="off"
                                />
                                <span className="form-text text-muted">Please enter the School Address.</span>
                                {errors.SchoolAddress && errors.SchoolAddress.type === "required" && <div className="invalid-feedback">School Address is required</div>}
                            </div>



                            <IMSelect
                                onHandleChange={selectHandleChange('SchoolType')}
                                refClassContainer="form-group"
                                name="schoolType"
                                isRequired={true}
                                withLabel={true}
                                label="School Type"
                                placeHolder="School Type"
                                forwardRef={setValue}
                                selectedId={data.SchoolType === null ? props.doc.SchoolType : data.SchoolType}
                                refClassName={``}
                                withDescription={true}
                                description={`Please indicate the School Type.`}
                                refDisabled={true}
                                refIsStatic={true}
                                refStaticData={[
                                    { id: '', label: '' },
                                    { id: 'PR', label: 'Private' },
                                    { id: 'PU', label: 'Public' }
                                ]}
                                field={{
                                    tableId: '',
                                    display: ''
                                }}
                                error={!!errors.gender}
                                errorMessage={errors.gender && errors.gender.type === "required" && 'Gender is required'}
                            />


                            <div className="form-group mt-5">
                                <FormControl component="fieldset">
                                    <FormControlLabel
                                        name="IsActive"
                                        inputRef={register()}
                                        control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                                        label="Active"
                                    />
                                </FormControl>
                            </div>
                            <div className="form-group mb-0" align="right">
                                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>

                                <button type="submit" className="btn btn-success">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </>
    );
}