import React from "react";
import { lighten, makeStyles, withStyles } from '@material-ui/core/styles';
import { Toolbar, Typography, Button, TextField } from '@material-ui/core/';
import { upperCase } from 'lodash';
import clsx from 'clsx';
import { useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { Search, HighlightOff } from '@material-ui/icons';
import { FormControlLabel, Switch } from "@material-ui/core";
import ExportExcel from "../../../../../app/modules/Admin/Enrollment/ApplicantReport/ExportExcel";
import ExportChedExcel from "../../../../../app/modules/Admin/Enrollment/ApplicantReport/ExportChedExcel";


const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === 'light'
            ? {
                color: theme.palette.secondary.main,
                backgroundColor: lighten(theme.palette.secondary.light, 0.85),
            }
            : {
                color: theme.palette.text.primary,
                backgroundColor: theme.palette.secondary.dark,
            },
    title: {
        flex: '1 1 100%',
    },
    input: {
        marginLeft: theme.spacing(1),
        flex: 1,
    },
    iconButton: {
        padding: 10
    },
    paper: {
        padding: '2px 4px',
        display: 'flex',
        alignItems: 'center',
        width: 300,
    }
}));


export default function ListFilters(props) {
    const { register, handleSubmit, errors } = useForm();

    const { selectDropdownStatus, selectDropdownSemester, selectDropdownSchoolYear, onSearch,
        keyword, onHandleChangeOnSearch, onHandleChangeKeyword, tableTitle, onDateFromHandler, onDateToHandler, selectDropdownInstitute, selectDropdownCourse,
        selectDropdownGender, selectDropdownYearLevel, switchHandleChange, switchState,
        selectDropdownCourses, selectDropdownCashier } = props


    const EnhancedTableToolbar = () => {

        const classes = useToolbarStyles();

        return (

            <>


                {props.type === 'paymentReport' &&
                    <>
                        <section className="row">
                            <section className="col-2 d-flex align-items-center">
                                Filters:
                            </section>

                            <section class="col-10 row d-flex justify-content-start">
                                {/* <div className="col bg-white my-0 p-0 ml-3">{selectDropdownSchoolYear()}</div>
                                <div className="col bg-white my-0 p-0 ml-3">{selectDropdownSemester()}</div> */}
                                <div className="col bg-white my-0 p-0 ml-3">{selectDropdownInstitute()}</div>
                                <div className="col bg-white my-0 p-0 ml-3">{selectDropdownCourses()}</div>
                                <div className="col bg-white my-0 p-0 ml-3">{selectDropdownYearLevel()}</div>
                                <div className="col bg-white my-0 p-0 ml-3">{selectDropdownCashier()}</div>
                            </section>
                        </section>

                        <br></br>
                    </>
                }

                <section className="row d-flex">
                    {/* {props.withTitle !== false &&
                        <Typography className="col-3 ml-5" variant="h6" id="tableTitle" component="div">
                            {onSearch && keyword !== '' ? <span>Searched keyword: <span className="col-3  text-primary">{`${upperCase(keyword)}`}</span></span> : 'Filter'}
                        </Typography>

                    } */}

                    <section className="col-2  align-items-center">
                        {props.type === 'paymentReport' ?
                            <>
                                Date Range:
                            </>

                            :

                            <>
                                Filter:
                            </>
                        }
                    </section>

                    <section className="col-10 row d-flex justify-content-start">
                        {props.type !== undefined && (props.type === 'applicantReport' || props.type === 'paymentReport')

                            &&
                            <> {props.type === 'applicantReport' &&
                                <>
                                    <div className="col bg-white my-0 p-0 mr-3">
                                        <FormControlLabel
                                            name="IsForReservation"
                                            inputRef={register()}
                                            control={<Switch checked={switchState.IsForReservation} onChange={switchHandleChange('IsForReservation')} value={switchState.IsForReservation} />}
                                            label="For Reservation"
                                        />

                                    </div>
                                    <div className="col-2 bg-white my-0 p-0 ">{selectDropdownCourses()}</div>
                                    <div className="col-2 bg-white my-0 p-0 ml-3">{selectDropdownYearLevel()}</div>

                                </>

                            }


                                <div className="col bg-white my-0 p-0 ml-3 ">
                                    <input
                                        type='date'
                                        onChange={(e) => { onDateFromHandler(e.target.value) }}
                                        className="form-control"
                                        defaultValue={props.dateFrom}
                                    />
                                </div>
                                <div className="col bg-white my-0 p-0 ml-3">
                                    <input
                                        type='date'
                                        onChange={(e) => { onDateToHandler(e.target.value) }}
                                        className="form-control"
                                        defaultValue={props.dateTo}

                                    />
                                </div>



                                {props.isGeneratingReport == true ?
                                    <button class="btn btn-secondary ml-5" type="button" disabled>
                                        <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                        &nbsp; Loading...
                                    </button>
                                    :
                                    <>
                                        <button onClick={() => { props.onSubmit() }} className="btn btn-sm btn-primary ml-5">
                                            {props.paymentReport !== undefined && props.paymentReport == 1 &&
                                                "Generate Payments"
                                            }
                                            {props.applicantReport !== undefined && props.applicantReport == 1 &&
                                                "Generate Report"
                                            }
                                            {props.studentBalanceReport !== undefined && props.studentBalanceReport == 1 &&
                                                "Generate Balance Report"
                                            }
                                            {props.enrollmentReport !== undefined && props.enrollmentReport == 1 &&
                                                "Generate Enrollment Report"
                                            }
                                        </button>
                                    </>

                                }

                                {props.results.length > 0 && props.paymentReport != 1 &&
                                    <>
                                        <br />
                                        <ExportExcel excelData={props.excelFormat} fileName={'Reports'} btnName="Export Report" />
                                        {props.type === 'applicantReport' &&
                                            <ExportExcel excelData={props.excelFormatReservation} fileName={'Reports'} btnName="Export Reservation Report" />
                                        }

                                    </>
                                }

                                {props.results.length > 0 && props.paymentReport == 1 && !props.isGeneratingReport ?
                                    <>
                                        <br />
                                        <ExportExcel excelData={props.excelFormat} fileName={'Reports'} btnName="Export Report" />
                                        {props.type === 'applicantReport' &&
                                            <ExportExcel excelData={props.excelFormatReservation} fileName={'Reports'} btnName="Export Reservation Report" />
                                        }

                                    </>

                                    :

                                    <></>
                                }

                            </>

                        }
                        {props.type !== undefined && props.type === 'applicantList'

                            ?
                            ''
                            :
                            <>
                                {props.type !== undefined && (props.type === 'receiveDocuments' || props.type === 'applicantReport' || props.type === 'classSection'
                                    || props.type === 'subjectSchedList' || props.type === 'studentBalanceReport'
                                    || props.type === 'studentEnrollmentListChedReport' || props.type === 'enrollmentStatisticReport' || props.type === 'paymentReport') ?
                                    ''
                                    :
                                    <div className="col-2 bg-white  my-0 p-0 mr-2">{selectDropdownStatus()}</div>

                                }
                                {props.type !== 'applicantReport' && props.type !== 'classSection' && props.type !== 'enrollmentStatisticReport' && props.type !== 'paymentReport' &&
                                    <>
                                        <div className="col-2 bg-white my-0 p-0 mr-2">{selectDropdownSchoolYear()}</div>
                                        <div className="col-2 bg-white my-0 p-0 mr-2">{selectDropdownSemester()}</div>
                                        {props.type === 'studentEnrollmentReport' && props.type !== 'studentEnrollmentListChedReport'
                                            ?
                                            <div className="col-2 bg-white my-0 p-0 mr-2">{selectDropdownInstitute()}</div>
                                            :
                                            ''

                                        }

                                        {props.type === 'studentBalanceReport' || props.type === 'studentEnrollmentReport' && props.type !== 'studentEnrollmentListChedReport' ?
                                            <>

                                                <div className="col-2 bg-white my-0 p-0 mr-2  d-block">{selectDropdownCourse()}</div>
                                            </>
                                            :

                                            ''

                                        }

                                        {props.type === 'studentEnrollmentReport' && props.type !== 'studentEnrollmentListChedReport' ?
                                            <div className="col-2 bg-white my-0 p-0 mr-2 mt-2">{selectDropdownGender()}</div>
                                            :
                                            ''
                                        }

                                        {props.type === 'studentEnrollmentReport' && props.type !== 'studentEnrollmentListChedReport' ?
                                            <div className="col-2 bg-white my-0 p-0 mr-2 mt-2">{selectDropdownYearLevel()}</div>
                                            :
                                            ''
                                        }





                                    </>

                                }

                            </>

                        }
                        {props.type !== undefined && (props.type === 'applicantList' || props.type === 'studentAccount')

                            ?
                            <> {props.type === 'applicantList' &&
                                <>
                                    <div className="col-3"></div>
                                    <div className="col-3"></div>
                                </>

                            }

                                {props.switchState !== undefined ?
                                    <FormControlLabel
                                        name="isPending"
                                        control={<Switch checked={props.switchState.IsVerified} onChange={props.switchHandleChange('IsVerified')} />}
                                        label="New Student"
                                        className="col-3 m-0 pr-0 lead justify-content-end"
                                    />

                                    :
                                    <>
                                        {props.type === 'applicantList' &&
                                            <div className="col-3"></div>

                                        }
                                    </>



                                }
                                <form className="col-3 " onSubmit={handleSubmit(onSubmit)}>
                                    {onSearch
                                        ?
                                        <>

                                            <Button fullWidth variant="contained" type="submit" color="error">Cancel Search</Button>

                                            {/* <IconButton type="submit" className={classes.iconButton} aria-label="clear" size="large">
                                  <HighlightOff />
                              </IconButton> */}

                                        </>

                                        :

                                        <>

                                            <TextField
                                                id="outlined-basic"
                                                label={props.searchName}
                                                variant="outlined"
                                                name="keyword"
                                                inputRef={register()}
                                                size="small"
                                                fullWidth
                                            />

                                        </>

                                    }

                                </form>
                            </>


                            :
                            <>

                                {props.type !== 'applicantReport' && props.type == 'studentBalanceReport' && props.type !== 'studentEnrollmentReport' &&
                                    props.type !== 'studentEnrollmentListChedReport' && props.type !== 'enrollmentStatisticReport' &&
                                    <form className="col-3" onSubmit={handleSubmit(onSubmit)}>
                                        {onSearch
                                            ?
                                            <>
                                                <Button fullWidth variant="contained" type="submit" color="error">Cancel Search</Button>

                                                {/* <IconButton type="submit" className={classes.iconButton} aria-label="clear" size="large">
                                  <HighlightOff />
                              </IconButton> */}

                                            </>

                                            :

                                            <TextField
                                                id="outlined-basic"
                                                label={props.searchName}
                                                variant="outlined"
                                                name="keyword"
                                                inputRef={register()}
                                                size="small"
                                                fullWidth
                                            />
                                        }

                                    </form>


                                }
                            </>



                        }


                    </section>



                </section>
                <main className="row ">
                    <section className="col-8">

                    </section>
                    <section className="mt-5 col-4 d-flex justify-content-end">
                        {props.isGeneratingReport == true && props.type != 'paymentReport' ?

                            <button class="btn btn-secondary ml-5" type="button" disabled>
                                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                &nbsp; Loading...
                            </button>

                            :

                            <>
                                {(props.type === 'studentBalanceReport' || props.type === 'studentEnrollmentReport' || props.type === 'studentEnrollmentListChedReport' ||
                                    props.type === 'enrollmentStatisticReport') &&
                                    <button onClick={() => { props.onSubmit() }} className="btn btn-sm btn-primary ml-3 p-3 justify-content-end">
                                        Generate Report
                                    </button>
                                }
                            </>
                        }



                        {(props.type === 'studentBalanceReport' || props.type === 'studentEnrollmentReport' || props.type === 'studentEnrollmentListChedReport'
                            || props.type === 'enrollmentStatisticReport') &&
                            <>
                                {props.results.length > 0 ?
                                    <>
                                        <ExportExcel excelData={props.excelFormat} fileName={'Report'} btnName="Export Report" />
                                        {/* <ExportChedExcel excelData={props.excelChedFormat} fileName={'Ched Reports'} btnName="Export Ched Report" /> */}


                                    </>

                                    :
                                    <>
                                        <button className="d-none"></button>

                                    </>

                                }
                            </>

                        }


                    </section>
                </main>


            </>
        );
    };

    EnhancedTableToolbar.propTypes = {
        numSelected: PropTypes.number.isRequired,
    };



    const onSubmit = (formValues) => {
        if (onSearch === true) {
            onHandleChangeOnSearch(false);
        } else {
            onHandleChangeOnSearch(true);
        }
        onHandleChangeKeyword(formValues.keyword);
    };


    return (
        <>
            <div className="p-5 float-right">  <EnhancedTableToolbar numSelected={0} /></div>





        </>
    )
}