import { number } from "prop-types";
import React, { useRef, forwardRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import CheckLogo from '../../../../../_metronic/_assets/logo/check.png'
import moment from "moment";


const useStyles = makeStyles({
    table: {
        minWidth: 650
    },

});


function calculateFontSize(stringLength, maxLength, minFontSize = 8, identifier) {
    // Handle edge cases where string_length is 0 or exceeds max_length
    if (identifier == 'yeah')
        console.log(stringLength)
    if (stringLength === 0) {
        return maxLength; // No text, so use full space
    } else if (stringLength > maxLength) {
        return minFontSize; // String too long, use minimum size
    }

    // Calculate ratio considering minimum font size
    const ratio = Math.min(1, stringLength / maxLength);
    const fontSize = 1 - ratio * (1 - minFontSize / 18);

    // Apply a minimum font size
    return Math.max(minFontSize, fontSize * 18);
}

function checkOverflow(element) {
    return element.scrollHeight > element.clientHeight || element.scrollWidth > element.clientWidth;
}


export default function GrantDisclosureAgreement(props) {
    const { student, totalScholarshipDiscount } = props
    let componentRef = useRef();
    const classes = useStyles();


    const Component = forwardRef((props, ref) => {
        const pageStyle = " @page { size:  8.5in 13in; margin: 10% }"
        return (
            <>




                <div style={{ display: 'none' }}>
                    <style>{pageStyle}</style>
                    <div ref={ref}>

                        <main className="container" style={{ fontSize: '21px' }}>
                            <main className="card p-3 border-0">
                                <section className="card-header text-center border-0">
                                    <div className='text-center'>
                                        <img style={{ width: '400px' }} src={FaithLogo} />
                                        <p className="font-weight-bold mt-5">GRANT DISCLOSURE</p>
                                    </div>
                                </section>
                                <section className='row'>
                                    < p className="col-12 mb-0 d-inline pr-0" style={{}}>
                                        Name of Student: <span className='text-dark   text-left'> &nbsp;&nbsp;&nbsp; {student.StudentName}</span>
                                    </ p>


                                </section>
                                <section className='row mt-5'>
                                    < p className="col-12 mb-0 d-inline pr-0" style={{}}>
                                        Type of Grant: <span className='text-dark   text-left'> &nbsp;&nbsp;&nbsp; {student.StudentScholarship}</span>
                                    </ p>
                                </section>
                                <section className='row mt-5'>
                                    < p className="col-12 mb-0 d-inline pr-0" style={{}}>
                                        Course/Track: <span className='text-dark   text-left'>&nbsp;&nbsp;&nbsp;  {student.CourseCode}</span>
                                    </ p>

                                </section>
                                <section className='row mt-5'>
                                    < p className="col-12 mb-0 d-inline pr-0" style={{}}>
                                        Grade/Year Level: <span className='text-dark   text-left'> &nbsp;&nbsp;&nbsp;  {student.YearLevelDesc}</span>
                                    </ p>
                                </section>
                                <br /> <br /> <br />
                                <section className='row mt-5 '>
                                    < p className="col-12 mb-0 d-inline pr-0" style={{}}>
                                        Dear &nbsp;<span className='text-dark'>{student.StudentName}</span>.
                                    </ p>
                                </section>
                                <section className='row mt-2 '>
                                    < p className="col-12 mb-0 d-inline pr-0 text-justify" style={{}}>
                                        Congratulation for being selected as a GRANTEE of <span className='text-dark'>{student.StudentScholarship}</span>! This grant entitles you to
                                        <span className='text-dark'> {student.ScholarshipPower}</span> equvalent to <span className='text-dark' style={{ whiteSpace: 'nowrap' }}>Php {totalScholarshipDiscount} </span>
                                        for <span className='text-dark'>{student.SemesterDesc},{student.SY}</span>
                                    </ p>



                                </section>
                                <br />
                                <section className='row mt-2 '>
                                    < p className="col-12 mb-0 d-inline pr-0 text-justify" style={{}}>
                                        You may use this as a form of payment which is deductable to the total amount due for the semester.
                                        This will serve as the cancellation of your promissory note. Please surrender the Accounting Copy of this grant
                                        to the Cashier's Office
                                    </ p>


                                </section>
                                <br />
                                <section className='row mt-2 '>

                                    < p className="col-12 mb-0 mt-5 d-inline pr-0 text-justify" style={{}}>
                                        For questions or concerns, you may contact the following personnel.
                                    </ p>


                                </section>
                                <br />
                                <section className='row mt-2 '>
                                    <div className="col-3">
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Name
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Ms. Janine Enriquez
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            (Accounting)
                                        </p>
                                    </div>


                                    <div className="col-3">
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Mobile
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            0961-549-9652
                                        </p>

                                    </div>
                                    <div className="col-6">
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Email
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            SHS - accounting@firstasia.edu.ph <br />
                                            TS - college-accounting@firstasia.edu.ph
                                        </p>

                                    </div>


                                </section>

                                <br />
                                <section className='row mt-2 '>
                                    <div className="col-3">

                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Ms. Arlene Kalaw
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            (Scholarship Concerns)
                                        </p>
                                    </div>


                                    <div className="col-3">
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Mobile
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            0949-674-5654
                                        </p>

                                    </div>
                                    <div className="col-6">

                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            atc@firstasia.edu.ph
                                        </p>

                                    </div>


                                </section>
                                <br />
                                <section className='row mt-2 '>

                                    < p className="col-12 mb-0 mt-5 d-inline pr-0 text-justify" style={{}}>
                                        Welcome to the Home of the Bravehearts!
                                    </ p>


                                </section>
                                <br /><br />
                                <section className='row mt-2 '>

                                    < p className="col-12 mb-0 mt-5 d-inline pr-0 text-justify" style={{}}>
                                        Sincerely yours,
                                    </ p>


                                </section>



                                <br />
                                <section className='row mt-2 '>
                                    <div className="col-6">

                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Maria Lourdes M. Platon
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            VP-Finance
                                        </p>
                                    </div>


                                    <div className="col-6">
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            Dr. Renelyn B. Salcedo
                                        </p>
                                        <br />
                                        <p className=" d-inline pr-0 text-justify" style={{}}>
                                            SVP-Student and Alumni Services
                                        </p>

                                    </div>



                                </section>
                            </main>

                        </main>

                    </div>
                </div >

            </>
        )

    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        const printButton = document.querySelector('.print-button');
        if (printButton) {
            printButton.style.display = 'none';
        }
    }


    return (
        <>


            <Component ref={componentRef} />
            {/* button to trigger printing of target component */}
            <ReactToPrint
                trigger={() => <button className="btn btn-sm btn-link mr-3 d-inline">Print Disclosure Agreement</button>}
                content={() => componentRef.current}
                onBeforePrint={handleBeforePrint}
                pageStyle={`
                        @page {
                        size:  8.5in 13in;
                        margin: 5%;
                        background-color: #ffffff;
                        }
                  `}

            />



        </>
    )


}