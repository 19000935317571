export const referenceTypes = {
    SELECTED_ROOM: 'SELECTED-ROOM',
    GET_ROOMS: 'GET-ROOMES',
    GET_ROOM: 'GET-ROOM-BY-ID',
    CREATE: 'CREATE-ROOM',
    UPDATE: 'UPDATE-ROOM',
    DELETE: 'DELETE-ROOM',
    LIST_LINK: '/admin/references/rooms',
    CREATE_LINK: '/admin/references/create-rooms/',
    UPDATE_LINK: '/admin/references/update-rooms/',
    DELETE_LINK: '/admin/references/delete-rooms/'
}