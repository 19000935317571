import React, { useState, useRef, useEffect } from "react";
import { useAppContext } from "../../../../contexts/useAppContext";
import FaithLogo from "../../../../../_metronic/_assets/logo/FaithCollegesPNG.png"
import { updatePassword, uploadImage, isUploadImageAndSignature, uploadSignature, getImage, isRequiredPWChange } from "../__hooks__";
import CryptoJS from "crypto-js";
import { useForm } from "react-hook-form";
import { Modal, Box } from '@material-ui/core'
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.jpg"
import ProfileCover from "../../../../../_metronic/_assets/logo/profileCover.jpg"
import UploadImageModal from "../../../../../_metronic/layout/components/custom/modal/UploadImageModal";
import history from "../../../../history";


export default function Profile() {
    const { state: { auth }, dispatch } = useAppContext()
    const [isOpen, setIsOpen] = useState(false)
    const [isOpenUploadImage, setIsOpenUploadImage] = useState(false)
    const { register, handleSubmit } = useForm();
    const fileInputRef = useRef();
    const [imagePreview, setImagePreview] = useState(null)

    const [passwordChanged, setPasswordChanged] = useState(false)


    const onChangePassword = () => {
        setIsOpen(true)
    }

    const onCloseChangPassword = () => {
        setIsOpen(false)

    }
    const handleRefresh = () => {
        dispatch({ type: 'LOGOUT', data: auth.data, loginType: 'L' });

    };


    const handleUploadImage = async (e, data, data2) => {
        e.preventDefault();
        const file = data.current.files[0];
        const file2 = data2.current.files[0];
        if (file && file2) {
            console.log(file)
            const formData = new FormData();
            const formData2 = new FormData();
            formData.append('image', file);
            formData.append('filename', auth.data.SerialNo);
            formData2.append('image', file2);
            formData2.append('filename', auth.data.SerialNo);
            try {
                const result = await uploadImage(formData);
                const resultImage = await uploadSignature(formData2)
                if ((result.status === 200 || result.status === 201) && (resultImage.status === 200 || resultImage.status === 201)) {
                    // Handle successful upload (e.g., show a success message, update the state, etc.)
                    console.log('Upload successful', result);
                    if (result.data.result == 1) {
                        isUploadImageAndSignature({ studentId: auth.data.StudentId })
                            .then(async resp => {
                                setIsOpenUploadImage(false)
                                handleRefresh()
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Image successfully uploaded and pending registrar approval.' });


                            })
                    }
                }
            } catch (error) {
                // Handle error
                console.error('Upload failed', error);
            }
        }
    };
    const onSubmitChangePassword = (formValues) => {
        const { password } = formValues
        const encyptionResult = ("*" + CryptoJS.SHA1(CryptoJS.SHA1(password))).toUpperCase();

        const userValues = {
            userId: auth.data.UserId,
            password: encyptionResult
        }

        const execute = async () => {
            await updatePassword(userValues)
                .then(response => {
                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Password Updated' });
                    setIsOpen(false)
                    setPasswordChanged(true)

                })
                .catch(error => {
                    // alert("something went wrong")
                    // console.log(error)
                })
        }
        execute();
    }

    const ChangePasswordModal = (props) => {
        const style = {
            position: 'absolute',
            top: '50%',
            left: '55%',
            transform: 'translate(-50%, -50%)',
            width: 400,
            bgcolor: 'background.paper',

            boxShadow: 24,
            p: 4,
        };
        const check = () => {
            if (document.getElementById('password').value == document.getElementById('passwordRetype').value) {
                document.getElementById('changePasswordSubmit').removeAttribute("disabled");

            } else {
                document.getElementById('changePasswordSubmit').setAttribute("disabled", "disabled");
            }
        }
        return (
            <Modal
                open={isOpen}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="card" style={style}>
                    <div className="mt-5 text-center"> <img className="d-inline mx-auto" style={{ width: '50%' }} src={FaithLogo} /> </div>
                    <div className="card-body">
                        <h3 className="mb-4">Change you Password</h3>
                        <form onSubmit={handleSubmit(onSubmitChangePassword)} method="post">
                            <div className="mb-3">
                                <label className="col-form-label p-0" htmlFor="password">New Password</label>

                                <input
                                    name="password"
                                    type="password"
                                    className="form-control"
                                    id="password"
                                    onKeyUp={check}
                                    ref={register({
                                        required: true
                                    })}
                                    required
                                />
                            </div>


                            <div>
                                <label className="col-form-label p-0" htmlFor="passwordRetype">Re-type Password</label>

                                <input
                                    name="passwordRetype"
                                    type="password"
                                    className="form-control"
                                    id="passwordRetype"
                                    onKeyUp={check}

                                    required
                                />
                            </div>

                            <button type="submit" id="changePasswordSubmit" className="btn btn-primary btn-block mt-3">Save</button>
                            <button type="button" onClick={() => { onCloseChangPassword() }} className="btn btn-secondary btn-block mt-3">Cancel</button>


                        </form>

                    </div>
                </Box>


            </Modal>
        )
    }

    useEffect(() => {
        if (auth.data.IsUploadImageSig == 1 && auth.data.IsUploadImageSigApp == 1) {
            getImage(auth.data.SerialNo)
                .then(resp => {
                    console.log(resp)
                    setImagePreview(resp)
                })
                .catch(e => {
                    return
                })
        }

        isRequiredPWChange({ userId: auth.data.UserId })
            .then(res => {
                if (res.data.IsDefaultPassword == 1) {
                    setIsOpen(true)
                }
            })

    }, [])

    console.log(auth)


    return (
        <>
            <ChangePasswordModal
                isOpen={isOpen}
            />
            <UploadImageModal
                isOpen={isOpenUploadImage}
                handleClose={() => setIsOpenUploadImage(false)}
                onSubmit={handleUploadImage}
            />
            <div className="card p-5">
                <ul className="nav nav-tabs" id="myTab" role="tablist">

                    <li className="nav-item">
                        <a className="nav-link active" id="profile-tab" data-toggle="tab" href="#profile" role="tab" aria-controls="profile" aria-selected="false">Profile</a>
                    </li>
                    {/* <li className="nav-item">
                        <a className="nav-link" id="contact-tab" data-toggle="tab" href="#contact" role="tab" aria-controls="contact" aria-selected="false">Contact</a>
                    </li> */}
                </ul>
                <div className="tab-content" id="myTabContent">
                    <div className="tab-pane fade show active" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <div className="row">
                            <div className="col-12 p-5">
                                {auth.data.IsUploadImageSig == 0 &&
                                    <button onClick={() => { setIsOpenUploadImage(true) }} className="text-right btn btn-link">Upload Image / Signature</button>
                                }
                                <button onClick={() => { onChangePassword() }} className="text-right btn btn-link">Change Password</button>
                                {auth.data.IsUploadImageSig == 1 && auth.data.IsUploadImageSigApp == 0 &&
                                    <p style={{ borderRadius: '5px' }} className="p-2 bg-secondary"> <i className="fa fa-info text-primary"></i> &nbsp;
                                        The image and signature are pending for approval. Once approved, they will be displayed on your profile and ID.</p>
                                }
                                <div className="card">

                                    <img
                                        className="card-img-top"
                                        src={ProfileCover}
                                        alt="Profile Cover"
                                        style={{ height: '200px', objectFit: 'cover' }}
                                    />
                                    <div className="card-body bg-light text-center">
                                        <img
                                            className="avatar rounded-circle"
                                            src={imagePreview || ProfileLogo}
                                            alt="Profile Logo"
                                            style={{ width: '150px', height: '100px', marginBottom: '15px' }}
                                        />
                                        {/* <p>Upload an Image</p>
                                <form
                                    onSubmit={handleUploadImage}
                                    method="post"
                                    encType="multipart/form-data"
                                    style={{ marginBottom: '15px' }}
                                >
                                    <input
                                        type="file"
                                        name="image"
                                        accept="image/*"
                                        ref={fileInputRef}
                                        required
                                        style={{ marginBottom: '10px' }}
                                    />
                                    <button
                                        type="submit"
                                        className="btn btn-primary"
                                        style={{ width: '100%' }}
                                    >
                                        Upload
                                    </button>
                                </form> */}
                                        <h3 className="card-title mb-0">
                                            {auth.data.FirstName} {auth.data.LastName}
                                        </h3>


                                    </div>
                                </div>



                            </div>


                        </div>
                    </div>
                    <div className="tab-pane fade" id="contact" role="tabpanel" aria-labelledby="contact-tab">3</div>
                </div>




            </div>
        </>
    )
}