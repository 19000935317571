import React, { useState, useEffect } from "react";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import {
    getStudentList, getSchoolYear, getSemester, studentAutoComplete, getORDetails, getFee,
    addUpdateReClass, deletePaymentDetails
} from "./__hooks__/index"
import { useAppContext } from "../../../../contexts/useAppContext";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { Table, TableBody, TableHead, TableRow, TextField, TableCell } from "@material-ui/core";
import { useForm } from "react-hook-form";
import { accountTypes } from "./__hooks__/types";
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from "lodash";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import DeleteModal from "../../../../../_metronic/layout/components/custom/modal/DeleteModal";
import { Delete } from "@material-ui/icons";



export default function ORReclassification(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const { setValue, handleSubmit, register } = useForm()
    const [student, setStudent] = useState(null)
    const [studentId, setStudentId] = useState(0)
    const [studentNo, setStudentNo] = useState(null)
    const [error, setError] = useState(false)
    const [results, setResults] = useState(null)
    const [selectedId, setSelectedId] = useState(null)
    const [addValues, setAddValues] = useState({ sy: 0, sem: 0, feeId: 0, amount: 0 })
    const [isToDelete, setIsToDelete] = useState(false)
    const [selectedToDelete, setSelectedToDelete] = useState(null)

    // filter
    const filteredSy = auth.data.schoolyears.enrollment.SchoolYear
    const filteredSem = auth.data.schoolyears.enrollment.SemesterId
    const [filter, setFilter] = useState({ sy: 0, sem: 0, feeId: 0, amount: 0 })
    //select
    const [sySelect, setSySelect] = useState(null)
    const [semSelect, setSemSelect] = useState(null)
    const [feeSelect, setFeeSelect] = useState(null)
    const [studentList, setStudentList] = useState([])
    let cnt = 0

    // functions


    const getStudentORDetails = async () => {
        await getORDetails({ studentId: studentId })
            .then(res => {
                if (res.data.length > 0) {
                    setResults(res.data)
                } else {
                    setError(true)
                }
            })
    }

    const onAddUpdateORDetails = async (val, isAdd) => {
        if (isAdd == false) {
            await addUpdateReClass({
                paymentDetailId: val.PaymentDetailId,
                paymentId: val.PaymentId,
                feeId: filter.feeId,
                schoolYear: filter.sy,
                semesterId: filter.sem,
                amount: filter.amount,
                studentId: studentId,
                createdBy: auth.data.Username
            }).then(res => {

                getStudentORDetails()
                    .then(res2 => {
                        setSelectedId(null)

                        if (res.data[0].Result == 1) {
                            dispatch({
                                type: componentTypes.SHOW_SNACKBAR,
                                snackBarStatus: 'success',
                                snackBarDuration: 3000,
                                snackBarMessage: 'Payment Details Updated'
                            });
                        } else {
                            dispatch({
                                type: componentTypes.SHOW_SNACKBAR,
                                snackBarStatus: 'error',
                                snackBarDuration: 3000,
                                snackBarMessage: 'Fee Already Exist'
                            });
                        }

                    })

            })
        } else {
            await addUpdateReClass({
                paymentDetailId: val.PaymentDetailId,
                paymentId: val.PaymentId,
                feeId: val.FeeId,
                schoolYear: val.Sy,
                semesterId: val.Sem,
                amount: val.Amount,
                studentId: studentId,
                createdBy: auth.data.Username
            }).then(res => {

                getStudentORDetails()
                    .then(res2 => {
                        setSelectedId(null)
                        setAddValues({})
                        if (res.data[0].Result == 1) {
                            dispatch({
                                type: componentTypes.SHOW_SNACKBAR,
                                snackBarStatus: 'success',
                                snackBarDuration: 3000,
                                snackBarMessage: 'Payment Details Updated'
                            });
                        } else {
                            dispatch({
                                type: componentTypes.SHOW_SNACKBAR,
                                snackBarStatus: 'error',
                                snackBarDuration: 3000,
                                snackBarMessage: 'Fee Already Exist'
                            });
                        }

                    })

            })
        }

    }

    const executeDelete = async () => {
        await deletePaymentDetails({ paymentDetailId: selectedToDelete })
            .then(res => {
                getStudentORDetails()
                    .then(res2 => {
                        setSelectedToDelete(null)
                        setIsToDelete(false)
                        dispatch({
                            type: componentTypes.SHOW_SNACKBAR,
                            snackBarStatus: 'success',
                            snackBarDuration: 3000,
                            snackBarMessage: 'Fee is Deleted'
                        });

                    })

            })

    }

    const getStudentListData = async (val) => {
        await getStudentList(val)
            .then(res => {
                if (res.data.length > 0)
                    setStudent(res.data[0])
                else {
                    // setError(true)
                }
            })
    }

    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);

    }

    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {
            })
    }, 300);

    const handleGetStudent = async (data) => {
        setStudentId(data.StudentId)
        setStudentNo(data.StudentNo)

        console.log(data)
    }

    const selectChange = name => event => {
        setFilter((prevData) => {
            return { ...prevData, [name]: event }
        })
    }

    const onCashieringHandle = () => {

    }

    const onCashieringAccount = () => {

    }

    const onCancel = () => {
        setStudent(null)
        setStudentNo('')
        setStudentId(0)
        setError(false)
        setResults(null)
        setAddValues({})
    }

    // effects

    useEffect(() => {
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'SchoolYear')
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'SemesterId')
        SelectCreator(getFee, setFeeSelect, 'FeeId', 'ShortName')
    }, [])


    useEffect(() => {
        if (studentId !== null && studentId !== 0) {
            getStudentListData({ schoolYear: filteredSy, semesterId: filteredSem, studentId: studentId })
        }
    }, [studentId])

    const imSelectDropdownFee = () => {
        return (
            <>
                {feeSelect &&
                    <IMSelect
                        data={feeSelect}
                        onHandleChange={selectChange('feeId')}
                        refClassContainer=""
                        name="feeId"
                        isRequired={false}
                        withLabel={false}
                        label="Fee"
                        placeHolder="Select Fee"
                        forwardRef={setValue}
                        selectedId={filter.feeId}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectChange('sy')}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filter.sy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectChange('sem')}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filter.sem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    console.log(student)
    console.log(studentList)
    console.log(results)
    console.log(sySelect)
    console.log(filter)
    return (
        <>

            <DeleteModal
                isOpen={isToDelete}
                handleDelete={() => executeDelete(selectedToDelete)}
                handleClose={() => setIsToDelete(false)}
                title={'Delete Payment Detail'}
                content={'Are you sure you want to delete this Fee?'}
                type={'delete'}
            />
            <div className="container">
                <div className="card">
                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                <h3 className="bg-secondary lead p-3 font-weight-bold mb-5">OR Reclassification</h3>

                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.StudentName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>

                                    </>

                                    :

                                    <>
                                        {/* <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3> */}
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }

                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.YearLevel} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                {student !== null &&
                                    <section className="mt-5">

                                        <button onClick={() => { onCancel() }} className="mt-5 btn btn-secondary">Search Again</button>

                                    </section>

                                }



                            </div>


                        </div>
                        <div className="col-12 col-xl-8">

                            <div className="p-5 mb-5 ">
                                {results == null ?
                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Search Student</h3>

                                        <section className="p-5">

                                            {error &&
                                                <div className="alert alert-warning mb-5" role="alert">
                                                    No OR Details Found
                                                </div>
                                            }

                                            <Autocomplete
                                                id="studentNo"
                                                options={studentList}
                                                getOptionLabel={(option) => option.StudentName}
                                                onChange={(event, value) => {
                                                    if (value) {
                                                        handleGetStudent(value)
                                                        // Handle the selected option
                                                    } else {
                                                        // Handle the case when no option is selected
                                                    }
                                                }}
                                                renderInput={(params) => (
                                                    <TextField
                                                        {...params}
                                                        label="Student Number"
                                                        variant="outlined"
                                                        inputRef={register}
                                                        name="studentNo"
                                                        value={studentNo}
                                                        onChange={
                                                            (e) => { handleStudentInputChange(e.target.value) }

                                                        }
                                                    />
                                                )}
                                            />

                                            {student !== null &&
                                                <section className="mt-5">

                                                    {error == false &&
                                                        <button onClick={() => { getStudentORDetails() }} className="btn btn-primary">View OR Details</button>
                                                    }

                                                    {/* <button onClick={() => { onCancel() }} className="btn btn-secondary float-right">Cancel</button> */}

                                                </section>
                                            }

                                            {error &&
                                                <section className="mt-5">

                                                    <button onClick={() => { onCancel() }} className="btn btn-secondary col-3 float-right">Search Again</button>

                                                </section>


                                            }





                                        </section>

                                    </>
                                    :
                                    <>
                                        {results.map((data, index) => {


                                            const selectAddChange = name => event => {
                                                let key = `${name}_${index}`
                                                setAddValues((prevData) => {
                                                    return { ...prevData, [key]: event }
                                                })
                                            }
                                            cnt = 0;
                                            return (
                                                <>
                                                    <section className="mb-5">
                                                        <i className="fas fa-money-bill-wave d-inline"></i> &nbsp;
                                                        <h4 className="text-primary d-inline">{data.ORNo} / <span className="text-dark">{parseFloat(data.TotalAmount).toFixed(2)}</span> </h4>
                                                        <hr />

                                                        <Table aria-label="a dense table" size="small">
                                                            <TableHead>
                                                                <TableRow>
                                                                    {selectedId == null ?
                                                                        <>
                                                                            <TableCell style={{ width: '10%' }} align="left">#</TableCell>
                                                                            <TableCell style={{ width: '10%' }} align="left">Description</TableCell>

                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TableCell colSpan={2} style={{ width: '10%' }} align="left">Description</TableCell>

                                                                        </>

                                                                    }
                                                                    <TableCell style={{ width: '20%' }} align="center">School Year</TableCell>
                                                                    <TableCell style={{ width: '20%' }} align="center">Semester</TableCell>
                                                                    <TableCell style={{ width: '20%' }} align="center">Amount</TableCell>
                                                                    {auth.data.UserType == 'CSM' &&
                                                                        <TableCell style={{ width: '20%' }} align="center">Action</TableCell>}


                                                                </TableRow>

                                                            </TableHead>
                                                            <TableBody>
                                                                {data.ORDetails.map(detl => {

                                                                    cnt += 1
                                                                    return (
                                                                        <>
                                                                            <TableRow>
                                                                                {selectedId != detl.PaymentDetailId ?
                                                                                    <>
                                                                                        <TableCell style={{ width: '10%' }} align="left">{cnt}</TableCell>
                                                                                        <TableCell style={{ width: '10%' }} align="left">{detl.FeeDesc}</TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">{detl.SchoolYear}</TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">{detl.Semester}</TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">{detl.Amount}</TableCell>
                                                                                        {auth.data.UserType == 'CSM' &&
                                                                                            <TableCell className="row" style={{ width: '20%' }} align="center">
                                                                                                <button onClick={(() => { setSelectedId(detl.PaymentDetailId); setFilter({ feeId: detl.FeeId, sy: detl.SchoolYear, sem: detl.Semester, amount: detl.Amount }) })} className="col btn btn-xs btn-icon btn-icon-xs btn-success mr-1" > <i className="fa fa-pencil-alt"></i></button>
                                                                                                <button onClick={() => { setIsToDelete(true); setSelectedToDelete(detl.PaymentDetailId) }} className="col btn btn-xs btn-icon btn-icon-xs btn-danger"> <i className=" fa fa-trash"></i></button>

                                                                                            </TableCell>
                                                                                        }

                                                                                    </>
                                                                                    :
                                                                                    <>
                                                                                        {/* <TableCell style={{ width: '0%' }} align="left"></TableCell> */}
                                                                                        <TableCell colSpan={2} style={{ width: '20%' }} align="left">

                                                                                            {imSelectDropdownFee()}

                                                                                        </TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">
                                                                                            {imSelectDropdownSchoolYear()}

                                                                                        </TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">
                                                                                            {imSelectDropdownSemester()}
                                                                                        </TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">
                                                                                            <input
                                                                                                className="form-control"
                                                                                                defaultValue={detl.Amount}
                                                                                                onChange={(e) => { setFilter((prevData) => { return { ...prevData, amount: e.target.value } }) }}
                                                                                            />
                                                                                        </TableCell>
                                                                                        <TableCell style={{ width: '20%' }} align="center">
                                                                                            <button onClick={(() => { setSelectedId(null) })} className="btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1" ><i className="fa fa-ban"></i></button>
                                                                                            <button
                                                                                                onClick={() => { onAddUpdateORDetails(detl, false) }}
                                                                                                className="btn btn-xs btn-icon btn-icon-xs btn-success"> <i class="fas fa-save"></i></button>

                                                                                        </TableCell>

                                                                                    </>
                                                                                }


                                                                            </TableRow>

                                                                        </>
                                                                    )
                                                                })}
                                                                {auth.data.UserType == 'CSM' &&
                                                                    <TableRow className="bg-secondary">

                                                                        <>
                                                                            {/* <TableCell style={{ width: '0%' }} align="left"></TableCell> */}
                                                                            <TableCell colSpan={2} style={{ width: '20%' }} align="left">

                                                                                <IMSelect
                                                                                    data={feeSelect}
                                                                                    onHandleChange={selectAddChange('feeId')}
                                                                                    refClassContainer=""
                                                                                    name="feeId"
                                                                                    isRequired={false}
                                                                                    withLabel={false}
                                                                                    label="Fee"
                                                                                    placeHolder="Select Fee"
                                                                                    forwardRef={setValue}
                                                                                    selectedId={addValues[`feeId_${index}`]}
                                                                                    refClassName={`text-center`}
                                                                                    withDescription={false}
                                                                                    //description={`Enrollment Status`}
                                                                                    refDisabled={true}
                                                                                    refIsStatic={false}
                                                                                    refStaticData={[

                                                                                    ]
                                                                                    }
                                                                                    field={{
                                                                                        tableId: 'tableId',
                                                                                        display: 'code'
                                                                                    }}
                                                                                />

                                                                            </TableCell>
                                                                            <TableCell style={{ width: '20%' }} align="center">
                                                                                <IMSelect
                                                                                    data={sySelect}
                                                                                    onHandleChange={selectAddChange('sy')}
                                                                                    refClassContainer=""
                                                                                    name="sy"
                                                                                    isRequired={false}
                                                                                    withLabel={false}
                                                                                    label="School Year"
                                                                                    placeHolder="School Year"
                                                                                    forwardRef={setValue}
                                                                                    selectedId={addValues[`sy_${index}`]}
                                                                                    refClassName={`text-center`}
                                                                                    withDescription={false}
                                                                                    //description={`Enrollment Status`}
                                                                                    refDisabled={true}
                                                                                    refIsStatic={false}
                                                                                    refStaticData={[

                                                                                    ]
                                                                                    }
                                                                                    field={{
                                                                                        tableId: 'tableId',
                                                                                        display: 'code'
                                                                                    }}
                                                                                />

                                                                            </TableCell>
                                                                            <TableCell style={{ width: '20%' }} align="center">
                                                                                <IMSelect
                                                                                    data={semSelect}
                                                                                    onHandleChange={selectAddChange('sem')}
                                                                                    refClassContainer=""
                                                                                    name="sem"
                                                                                    isRequired={false}
                                                                                    withLabel={false}
                                                                                    label="Semester"
                                                                                    placeHolder="Semester"
                                                                                    forwardRef={setValue}
                                                                                    selectedId={addValues[`sem_${index}`]}
                                                                                    refClassName={`text-center`}
                                                                                    withDescription={false}
                                                                                    //description={`Enrollment Status`}
                                                                                    refDisabled={true}
                                                                                    refIsStatic={false}
                                                                                    refStaticData={[

                                                                                    ]
                                                                                    }
                                                                                    field={{
                                                                                        tableId: 'tableId',
                                                                                        display: 'code'
                                                                                    }}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell style={{ width: '20%' }} align="center">
                                                                                {/* <div class="input-group mb-3">
                                                                            <div className="input-group-prepend">
                                                                                <span className="input-group-text" id="basic-addon1">₱</span>
                                                                            </div>
                                                                            <input
                                                                                type="number"
                                                                                className="form-control"
                                                                                placeholder=""
                                                                                aria-label=""
                                                                                aria-describedby="basic-addon1"
                                                                                onChange={(e) => { setAddValues((prevData) => { return { ...prevData, [`amount_${index}`]: e.target.value } }) }}
                                                                                value={addValues[`amount_${index}`] || ''}
                                                                            />
                                                                        </div> */}
                                                                                <input
                                                                                    className="form-control"
                                                                                    onChange={(e) => { setAddValues((prevData) => { return { ...prevData, [`amount_${index}`]: e.target.value } }) }}
                                                                                    value={addValues[`amount_${index}`] || ''}
                                                                                />
                                                                            </TableCell>
                                                                            <TableCell style={{ width: '20%' }} align="center">
                                                                                <button
                                                                                    disabled={!addValues[`feeId_${index}`] || !addValues[`sy_${index}`] || !addValues[`sem_${index}`] || !addValues[`amount_${index}`]}
                                                                                    onClick={() => {
                                                                                        onAddUpdateORDetails({
                                                                                            PaymentDetailId: 0,
                                                                                            PaymentId: data.PaymentId,
                                                                                            FeeId: addValues[`feeId_${index}`],
                                                                                            Sy: addValues[`sy_${index}`],
                                                                                            Sem: addValues[`sem_${index}`],
                                                                                            Amount: addValues[`amount_${index}`]

                                                                                        }, true)
                                                                                    }}
                                                                                    className="btn btn-xs btn-icon btn-icon-xs btn-primary"> <i class="fas fa-plus"></i></button>

                                                                            </TableCell>

                                                                        </>



                                                                    </TableRow>
                                                                }

                                                            </TableBody>
                                                        </Table>
                                                    </section>



                                                </>
                                            )

                                        })}
                                    </>
                                }





                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}