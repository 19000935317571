import React, { useState, forwardRef, useEffect } from 'react';
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    Slide,
    Button
} from '@material-ui/core';
import ProfileLogo from '../../../../../_metronic/_assets/logo/profileLogo.jpg';
import _ from 'lodash';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function CheckUploadedImageModal(props) {
    const { image, isOpen, handleClose, signature, handleApprove, handleDisapprove, studentNo, isEdit } = props;

    const handleApproveAndDownload = () => {
        downloadFile(image, `${studentNo}_image.jpg`);
        downloadFile(signature, `${studentNo}_signature.jpg`);

        // Handle other approve actions if needed
        handleApprove();
    };

    const handleDownload = () => {
        downloadFile(image, `${studentNo}_image.jpg`);
        downloadFile(signature, `${studentNo}_signature.jpg`);

        // Handle other approve actions if needed
        handleClose();
    };

    const downloadFile = (url, filename) => {
        if (!url) return;

        fetch(url)
            .then((response) => response.blob())
            .then((blob) => {
                const blobUrl = URL.createObjectURL(blob);
                const a = document.createElement('a');
                a.href = blobUrl;
                a.download = filename;
                document.body.appendChild(a);
                a.click();
                document.body.removeChild(a);
            })
            .catch((error) => console.error('Error downloading file:', error));
    };

    return (
        <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            maxWidth="sm"
            fullWidth
        >
            <DialogTitle>Image/Signature Uploaded</DialogTitle>
            <hr />
            <DialogContent>
                <section className='row'>
                    <div className='col-12 text-center'>
                        <p>Image</p>
                        <img
                            className=""
                            src={image || ProfileLogo}
                            alt="Profile Logo"
                            style={{ width: '150px', height: '100px', marginBottom: '15px' }}
                        />
                    </div>
                    <div className='col-12 text-center'>
                        <p>Signature</p>
                        <img
                            className=""
                            src={signature || ProfileLogo}
                            alt="Profile Logo"
                            style={{ width: '250px', height: '50px', marginBottom: '15px' }}
                        />
                    </div>
                </section>
            </DialogContent>
            <DialogActions>
                {isEdit == false ?
                    <button onClick={handleApproveAndDownload} className='btn btn-primary btn-sm'>
                        Approve and Download
                    </button>
                    :
                    <button onClick={handleDownload} className='btn btn-primary btn-sm'>
                        Download
                    </button>
                }
                {isEdit != true &&
                    <button onClick={handleDisapprove} className='btn btn-danger btn-sm'>
                        Decline
                    </button>
                }

                <Button onClick={handleClose} variant="contained" color="danger">
                    Exit
                </Button>
            </DialogActions>
        </Dialog>
    );
}
