import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import { makeStyles } from '@material-ui/core/styles';
import {
    getSchoolYear, getSemester, searchStudent, studentAutoComplete, getGradingPeriod,
    studentListOfSubjects, studentSpecialPermit,
    studentSpecialPermitaddUpdate
} from "./__hooks__"
import { TextField } from "@material-ui/core";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import PrintSpecialPermit from "./PrintSpecialPermit";
import { Link } from 'react-router-dom';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { debounce } from "lodash";
import { Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from "@material-ui/core"
const useStyles = makeStyles({
    table: {
        minWidth: 120,
    },
});

export default function SpecialExamPermit() {
    const { state: { auth }, dispatch } = useAppContext();
    const { setValue, handleSubmit, register } = useForm()
    const [withPermit, setWithPermit] = useState(false)
    const [permitNo, setPermitNo] = useState(null)
    const [selectedSubjectScheduleDetailId, setSelectedSubjectScheduleDetailId] = useState([])
    const [specialPermitResult, setSpecialPermitResult] = useState([])
    const [isLoadingPrintingResult, setIsLoadingPrintingResult] = useState(false)
    const classes = useStyles();

    const [student, setStudent] = useState(null)
    const [studentNo, setStudentNo] = useState()
    const [studentId, setStudentId] = useState(0)

    const [semSelect, setSemSelect] = useState()
    const [sySelect, setSySelect] = useState()
    const [gradingPeriodSelect, setGradingPeriodSelect] = useState()
    const activeSem = auth.data.schoolyears.enrollment.SemesterId
    const activeSy = auth.data.schoolyears.enrollment.SchoolYear
    const [isLoading, setIsLoading] = useState(false)


    const [filteredSy, setFilteredSy] = useState(activeSy)
    const [filteredSem, setFilteredSem] = useState(activeSem)
    const [filteredGradingPeriod, setFilteredGradingPeriod] = useState(null)

    const [studentList, setStudentList] = useState([])
    const [studentSubjectList, setStudentSubjectList] = useState([])


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectPeriodChange = name => event => { setFilteredGradingPeriod(event) }


    const executeSearchStudent = async (val) => {
        setIsLoading(true)
        await searchStudent(val)
            .then(res => {
                setIsLoading(false)

                console.log(res)
                if (res.data !== null) {
                    setStudent(res.data)
                } else {
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'error',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Student Not Found, Try Migrating Student In Campus'
                    });

                    setStudent(null)
                }
            })
            .catch(e => {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'error',
                    snackBarDuration: 5000,
                    snackBarMessage: 'Student Not Found'
                });
            })
    }

    const viewAssessment = async () => {
        await studentListOfSubjects({ enrollmentId: student.EnrollmentId })
            .then(res => {
                setStudentSubjectList(res.data)
            })
        setWithPermit(true)
    }

    const onCheckSubject = (e, id) => {
        let isChecked = e.target.checked

        if (isChecked) {
            setSelectedSubjectScheduleDetailId((prevData) => {
                return [...prevData, id]
            })
        } else {
            setSelectedSubjectScheduleDetailId((prevData) => {
                let arr = prevData.filter(e => e !== id);
                return arr
            })
        }
    }

    const onPrintSpecialPermit = async () => {
        setIsLoadingPrintingResult(true)
        await studentSpecialPermit({
            enrollmentId: student.EnrollmentId,
            subjectScheduleDetailId: selectedSubjectScheduleDetailId.toString(),
            gradingPeriodId: filteredGradingPeriod
        })
            .then(res => {
                // setIsLoadingPrintingResult(false)
                setSpecialPermitResult(res.data)
                if (res.data.length > 0) {
                    setPermitNo(res.data[0].PermitNo)
                }
            })
    }

    const handleStudentInputChange = async (input) => {
        debouncedSearch(input);
    }

    const debouncedSearch = debounce(async (inputValue) => {
        await studentAutoComplete({ input: inputValue })
            .then(res => {
                setStudentList(res.data)
                console.log(res.data)
            })
            .catch(e => {

            })
    }, 300);

    const handleGetStudent = async (data) => {
        setStudentNo(data.StudentNo)
        setStudentId(data.StudentId)
        console.log(data)
    }



    useEffect(() => {
        SelectCreator(getSemester, setSemSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSySelect, 'SchoolYear', 'Description')
        SelectCreator(getGradingPeriod, setGradingPeriodSelect, 'GradingPeriodId', 'Description')
    }, [])




    useEffect(() => {
        if (studentId !== undefined && studentId !== "" && studentId != 0) {
            setTimeout(() => {
                onSubmit({ studentId: studentId, semesterId: filteredSem, schoolYear: filteredSy })

            }, 500)

        }
    }, [studentId])

    useEffect(() => {
        if (student !== null) {
            let data = {
                studentId: studentId,
                semesterId: filteredSem,
                schoolYear: filteredSy
            }
            executeSearchStudent(data)
        }
    }, [filteredSem, filteredSy])




    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {sySelect &&
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? activeSy : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    const imSelectGradingPeriod = () => {
        return (
            <>
                {gradingPeriodSelect &&
                    <IMSelect
                        data={gradingPeriodSelect}
                        onHandleChange={selectPeriodChange()}
                        refClassContainer=""
                        name="GradingPeriodId"
                        isRequired={false}
                        withLabel={true}
                        label="Grading Period"
                        placeHolder="Select Grading Period"
                        forwardRef={setValue}
                        selectedId={filteredGradingPeriod === undefined ? null : filteredGradingPeriod}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    const onSubmit = (formValues) => {
        console.log(formValues)

        let data = {
            ...formValues,
            semesterId: filteredSem,
            schoolYear: filteredSy
        }
        executeSearchStudent(data)

    }



    const onCancelSearch = () => {
        setStudent(null)
        setStudentNo("")
        setStudentId(0)
    }

    const imSelectDropdownSemester = () => {
        return (
            <>
                {semSelect &&
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? activeSem : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }


    console.log(auth)
    console.log(student)


    console.log(sySelect)
    console.log(selectedSubjectScheduleDetailId)

    return (
        <>

            <div className="container">
                <div className="card">


                    <div className="row container my-5">
                        <div className="col-12 col-xl-4  border-right border-primary">
                            <div className=" p-5">
                                {student !== null ?
                                    <>
                                        <h3 className="">{`${student.LastName}, ${student.FirstName} ${student.MiddleName}`}</h3>
                                        <p className="my-0  ">{student.CourseDesc}</p>
                                    </>

                                    :

                                    <>
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Student Information</h3>
                                        {/* //<p className="my-0  ">Student Course</p> */}
                                    </>

                                }


                                <hr />

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Student Number" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>



                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={student.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Year Level" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.SchoolYear} - ${student.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="School Year & Semester" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>

                                <div className="input-group mb-3">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text" id="inputGroup-sizing-default">Student Type:</span>
                                    </div>
                                    {student !== null ?
                                        <>
                                            <input readOnly value={`${student.IsTrasferee === 1 ? "Transferee" : "Old Student"}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />

                                        </>

                                        :
                                        <input readOnly placeholder="Status" type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />


                                    }
                                </div>
                                <hr />


                            </div>


                        </div>
                        <div className="col-12 col-xl-8">

                            {withPermit == false
                                ?
                                <>
                                    <div className="p-5 mb-5 ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Search for a Student</h3>

                                        <section className="p-5">
                                            <div className="bg-white mb-3 p-0">{imSelectDropdownSchoolYear()}</div>
                                            <div className="bg-white mb-3 p-0">{imSelectDropdownSemester()}</div>
                                            <form onSubmit={handleSubmit(onSubmit)} method="post">

                                                {/* <TextField
                                            id="outlined-basic"
                                            label="Student No"
                                            variant="outlined"
                                            name="studentNo"
                                            value={studentNo}
                                            inputRef={register({
                                                required: true
                                            })}
                                            className="bg-white"
                                            size="small"
                                            fullWidth
                                            onChange={(e) => {
                                                if (e.target.value.length === 11) {
                                                    setStudentNo(e.target.value)

                                                }
                                            }}
                                        /> */}

                                                <Autocomplete
                                                    id="studentNo"
                                                    options={studentList}
                                                    getOptionLabel={(option) => option.StudentName}
                                                    onChange={(event, value) => {
                                                        if (value) {
                                                            handleGetStudent(value)
                                                            // Handle the selected option
                                                        } else {
                                                            // Handle the case when no option is selected
                                                        }
                                                    }}
                                                    renderInput={(params) => (
                                                        <TextField
                                                            {...params}
                                                            label="Student Number"
                                                            variant="outlined"
                                                            inputRef={register}
                                                            name="studentNo"
                                                            value={studentNo}
                                                            onChange={
                                                                (e) => { handleStudentInputChange(e.target.value) }

                                                            }
                                                        />
                                                    )}
                                                />


                                            </form>
                                            {isLoading == false ?
                                                <>
                                                    {student !== null ?
                                                        <>
                                                            <div className="mt-5">
                                                                {student.EnrollmentId !== null ?
                                                                    <button onClick={() => { viewAssessment() }} type="button" className="btn btn-primary mr-5 ">Special Exam Permit</button>
                                                                    :
                                                                    <>
                                                                        {student.PastEnrollmentRecord > 0 ?
                                                                            <Link to={{ pathname: `/admin/student/create-assessment/${student.StudentId}`, data: { isNewStudent: true, semesterId: filteredSem, schoolYear: filteredSy } }} className="btn btn-success mr-5" >Create Registration</Link>
                                                                            :
                                                                            <div className="alert alert-warning" role="alert">
                                                                                The student needs to receive documents first.
                                                                            </div>

                                                                        }


                                                                    </>
                                                                    //  <button onClick={() => { onStudentProgressHandler() }} type="button" className="mt-5 btn btn-success mr-5 ">Create Registration</button>

                                                                }
                                                                <button onClick={() => { onCancelSearch() }} type="button" className="btn btn-secondary ">Cancel</button>
                                                            </div>


                                                        </>
                                                        :
                                                        ''

                                                    }
                                                </>
                                                :
                                                <>
                                                    <div className="spinner-border" role="status">
                                                        <span className="sr-only">Loading...</span>
                                                    </div>
                                                </>
                                            }

                                        </section>



                                    </div>
                                </>
                                :
                                <>
                                    <h3 className="bg-secondary lead p-3 font-weight-bold">Student Special Exam Permit</h3>
                                    <main className="container">
                                        <section className="row">
                                            <div className="bg-white p-0 col">{imSelectGradingPeriod()}</div>


                                        </section>
                                        <section>
                                            <table className="table table-sm ">
                                                <thead>
                                                    <tr className="p-0">
                                                        <th scope="col pb-0"></th>
                                                        <th scope="col pb-0">Class Code</th>
                                                        <th scope="col pb-0">Subject Code</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <>
                                                        {studentSubjectList !== null && studentSubjectList.length > 0 &&
                                                            studentSubjectList.map(row => {
                                                                return (
                                                                    <>
                                                                        <tr>
                                                                            <th scope="row">
                                                                                <input
                                                                                    onChange={(e) => { onCheckSubject(e, row.SubjectScheduleDetailId) }}
                                                                                    type="checkbox"
                                                                                />

                                                                            </th>
                                                                            <td>{row.ClassCode}</td>
                                                                            <td>{row.SubjectCode}</td>

                                                                        </tr>

                                                                    </>
                                                                )
                                                            })}

                                                    </>



                                                </tbody>
                                            </table>


                                        </section>
                                        <section className="row">
                                            <div className="col">
                                                {isLoadingPrintingResult == false ?
                                                    <button
                                                        onClick={onPrintSpecialPermit}
                                                        disabled={filteredGradingPeriod == null}
                                                        className="btn btn-primary mt-5">Generate

                                                    </button>

                                                    :
                                                    <>
                                                        {specialPermitResult.length > 0 &&

                                                            <PrintSpecialPermit
                                                                results={specialPermitResult}
                                                                permitNo={permitNo}
                                                                gradingPeriod={filteredGradingPeriod}
                                                                studentSpecialPermitaddUpdate={studentSpecialPermitaddUpdate}
                                                                encoder={auth.data.Username}
                                                            />
                                                        }

                                                    </>

                                                }
                                            </div>
                                            <div className="col">
                                                <button
                                                    onClick={() => {
                                                        setStudentSubjectList([]); setWithPermit(false); setStudent(null);
                                                        setPermitNo(null); setFilteredGradingPeriod(null);
                                                        setSelectedSubjectScheduleDetailId([]);
                                                        setSpecialPermitResult([]); setIsLoadingPrintingResult(false)
                                                    }}
                                                    className="btn btn-secondary mt-5 float-right">Search Again

                                                </button>
                                            </div>



                                        </section>

                                    </main>



                                </>

                            }




                        </div>
                    </div>
                </div>
            </div>


        </>
    )

}