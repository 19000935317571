import instance from '../../../../../../../apis/local/systemAPI';

// START OF ASSESSMENT TEMPLATE SCHEDULE DETAIL
export const getActiveAssessmentTemplateScheduleDetails = async () => {
  const result = await instance.get("api/assessment/template-schedule-details/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getAssessmentTemplateScheduleDetailsByAssessmentTemplateScheduleId = async (id) => {
  const params = {
    "assessmentTemplateScheduleId": id === undefined ? null : id
  }
  var result = await instance.post('api/assessment/template-schedule-details/q', params);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createAssessmentTemplateScheduleDetail = async (formValues) => {
  const result = await instance.post("api/assessment/template-schedule-details", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateAssessmentTemplateScheduleDetail = async (id, formValues) => {
  const result = await instance.patch(`api/assessment/template-schedule-details/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteAssessmentTemplateScheduleDetail = async (id) => {
  const result = await instance.delete(`api/assessment/template-schedule-details/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF ASSESSMENT TEMPLATE SCHEDULE DETAIL