import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import {
    getCourseOutlineFilter, getCourseOutlineReport, getCourse,
    getSchoolYear, getSemester, getCourses,
    getCourseOutlineQuery, getInstituteQuery
} from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import PrintedForm from './PrintedForm';

export default function CurriculumReport() {
    const { state: { fees, auth }, dispatch } = useAppContext();
    const activeSy = auth.data.schoolyears.assessment.SchoolYear
    const activeSem = auth.data.schoolyears.assessment.SemesterId
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
    const [keyword, setKeyword] = useState(fees.keyword);
    const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
    const [onAlert, setOnAlert] = useState(false)


    const [filter, setFilter] = useState({ curriculumCode: null, instituteId: null, courseId: null })

    const updateId = fees.id;


    const filterChange = name => event => { setFilter({ ...filter, [name]: event }) }
    const [addCouseSelect, setAddCourseSelect] = useState(null)
    const [courseSelect, setCourseSelect] = useState([{ tableId: '', code: '' }])
    const [curriculumSelect, setCurriculumSelect] = useState([{ tableId: '', code: '' }])
    const [instituteSelect, setInstituteSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [semesterSelect, setSemesterSelect] = useState(null)


    const [report, setReport] = useState([])


    const [formData, setFormData] = useState({
        description: '',
        courseId: null,
        noOfYears: '',
        effectiveSchoolYear: null,
        effectiveSemesterId: null,
        effectiveDate: '',
        isActive: 1,
        createdBy: auth.data.UserName
    })

    const formSelectChange = name => event => { setFormData({ ...formData, [name]: event }) }
    const formInputChange = (name, data) => { setFormData({ ...formData, [name]: data }) }

    // table pagination and search -----------------------------------------------------

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };






    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
    }


    //Update
    const onUpdateGradeElement = (data) => {
    }



    //Delete
    const onDeleteGradeElement = (data) => {
    }




    // functions


    const onSearchFilters = async () => {
        await getCourseOutlineFilter(page, rowsPerPage, filter.curriculumCode, filter.instituteId, filter.courseId)
            .then(res => {
                if (res.data.length > 1) {
                    setResults(res.data)
                    setOnAlert(true)
                    setTotalRecord(res.data.length > 0 ? res.data.length : 0);
                } else if (res.data.length == 1) {
                    history.push(`/admin/references/course-outline-details/${res.data[0].CourseOutlineId}`)
                } else {
                    setOnAlert(true)
                    setTotalRecord(res.data.length > 0 ? res.data.length : 0);
                }

            })

        await getCourseOutlineReport({
            instituteId: filter.instituteId,
            curriculumCode: filter.curriculumCode,
            withInstituteId: filter.instituteId !== null ? true : false,
            withCurrCode: filter.curriculumCode !== null ? true : false
        })
            .then(res => {

                console.log(res)
                setReport(res)

            })
    }

    const onSeachAgain = () => {
        setResults([])
        setOnAlert(false)

    }

    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getInstituteQuery, setInstituteSelect, 'InstituteId', 'Description', { where: `WHERE I.IsActive = 1 ORDER BY I.Description` })
        SelectCreator(getCourse, setAddCourseSelect, 'CourseId', 'Description')

    }, [])


    useEffect(() => {
        const getCourseSelect = async () => {
            await getCourses({ where: `WHERE InstituteId = ${filter.instituteId}` })
                .then(resp => {
                    let arr = []
                    for (let i = 0; i < resp.data.length; i++) {
                        arr.push({ tableId: resp.data[i].CourseId, code: resp.data[i].Description })
                    }

                    setCourseSelect(arr)
                })
                .catch(e => {

                })
        }
        if (filter.instituteId !== null) {
            getCourseSelect()
        }


    }, [filter.instituteId])


    useEffect(() => {
        const getCurrSelect = async () => {
            await getCourseOutlineQuery({ where: `WHERE CO.CourseId = ${filter.courseId} AND I.InstituteId = ${filter.instituteId}` })
                .then(resp => {
                    let arr = []
                    for (let i = 0; i < resp.data.length; i++) {
                        arr.push({ tableId: resp.data[i].Description, code: resp.data[i].Description })
                    }

                    setCurriculumSelect(arr)
                })
                .catch(e => {

                })
        }
        if (filter.courseId !== null) {
            getCurrSelect()
        }
    }, [filter.courseId])







    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Course', label: 'Course', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'NoOfYears', label: 'No. of Years', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SchoolYearDescription', label: 'Effective School Year', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SemesterDescription', label: 'Effective Semester', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'CourseOutlineId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'courseOutlineReport',
        parentId: null,
        tableTitle: 'List of Course Outline',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: updateId,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch,
        onUpdate: onUpdateGradeElement,
        onDelete: onDeleteGradeElement
    }



    console.log(semesterSelect)
    console.log(schoolYearSelect)
    console.log(courseSelect)
    console.log(results)
    console.log(report)

    return (
        <>
            {semesterSelect && schoolYearSelect && instituteSelect && curriculumSelect && courseSelect && addCouseSelect &&
                <>

                    {results.length > 0 ?
                        <>
                            <div className="card card-custom gutter-b example example-compact">
                                <div className="card-header">
                                    {/* <div className="card-title">
                                        <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                                    </div> */}
                                    <div className="card-toolbar float-right">
                                        <div className="example-tools">

                                            <button onClick={() => { onSeachAgain() }} className="btn btn-lg btn-icon-sm btn-secondary mr-3">
                                                Search Again
                                            </button>
                                            {report.length > 0 ?
                                                <PrintedForm
                                                    report={report}
                                                />

                                                :
                                                <>
                                                    <section className='text-center'>
                                                        <div className="spinner-border" role="status">
                                                            <span className="sr-only">Loading...</span>
                                                        </div>

                                                    </section>

                                                </>

                                            }

                                            {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                        <i className="fa fa-plus"></i> Add New
                      </button> */}
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                                    <IMTable tableProps={tableProps} />

                                </div>
                            </div>
                        </>
                        :
                        <>
                            <div className='d-flex align-items-center'>
                                <main class="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                                    <header className='card-header p-5 bg-secondary'>
                                        <h6 class="float-left m-0 mt-4 ml-5">Course Outline</h6>
                                        <section className='float-right'>
                                            <button onClick={() => { onAddGradeElement() }} className='btn btn-sm btn-primary'>Add</button>

                                        </section>

                                    </header>
                                    <section class="card-body">
                                        {onAlert == true && results.length == 0 &&
                                            <div className="alert alert-danger" role="alert">
                                                No Record Found
                                            </div>
                                        }
                                        <p className="card-text">Search for Course Outline</p>

                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={instituteSelect}
                                                    onHandleChange={filterChange('instituteId')}
                                                    refClassContainer=""
                                                    name="instituteId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Department / Institute"
                                                    placeHolder="Department / Institute"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.instituteId}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Institute:`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={courseSelect}
                                                    onHandleChange={filterChange('courseId')}
                                                    refClassContainer=""
                                                    name="courseId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Course"
                                                    placeHolder="Program"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.courseId}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Program:`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>


                                        <div className="form-row">
                                            <div className="form-group col">
                                                <IMSelect
                                                    data={curriculumSelect}
                                                    onHandleChange={filterChange('curriculumCode')}
                                                    refClassContainer=""
                                                    name="curriculumCode"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label="Curriculum Code"
                                                    placeHolder="Curriculum Code"
                                                    forwardRef={() => { }}
                                                    selectedId={filter.curriculumCode}
                                                    refClassName={`text-center`}
                                                    withDescription={true}
                                                    description={`Curriculum Code:`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]
                                                    }
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}
                                                />
                                            </div>
                                        </div>

                                        <button onClick={() => { onSearchFilters() }} className='btn btn-success btn-block'>Search</button>


                                    </section>
                                </main>

                            </div>
                        </>

                    }


                </>
            }

        </>
    );
}