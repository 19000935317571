import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import {
    TableContainer, Paper, Table, TableBody, TableCell, TableHead, TableRow, TableFooter, TablePagination,
    Button, Modal, Box, Typography
} from '@material-ui/core';
import RowForm from "./RowForm";
import { createAssessmentAddOns, updateAssessmentAddOns, deleteAssessmentAddOns } from "./__hooks__"
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';

export default function AssessmentForm(props) {
    const { results, instituteSelect, courseSelect, yearLevelSelect, sySelect, semSelect,
        feeSelect, handleGetStudent, handleStudentInputChange, studentList, studentId, onFilterHandler } = props
    const { dispatch } = useAppContext()
    const [page, setPage] = useState(0)
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [dataPerPage, setDataPerPage] = useState([])
    // const [open, setOpen] = useState(false);
    const style = {
        position: 'absolute',
        top: '50%',
        left: '55%',
        transform: 'translate(-50%, -50%)',
        width: 400,
        bgcolor: 'background.paper',

        boxShadow: 24,
        p: 4,
    };
    const { register, handleSubmit, errors, setValue } = useForm();
    const [data, setData] = useState({ yearLevelId: null, courseId: null, withStudent: true })
    const [selectedId, setSelectedId] = useState()
    const [retrieve, setRetrieve] = useState()
    const RowCnt = page * rowsPerPage;
    const [switchState, setSwitchState] = useState({
        iSIndividual: true
    });

    let rowCnt = page * rowsPerPage
    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    const handleAdd = (data) => {
        console.log(data)
        const execute = async () => {
            await createAssessmentAddOns(data)
                .then(response => {
                    if (response.success) {
                        setRetrieve(true);
                        setSelectedId(0);
                        props.isResultUpdated(true)

                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // localStorage.clear();
                        // history.push('/auth/login');
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: "Please Fill up all the Fields" });
                    }
                })
        }
        execute();
    }


    const handleEdit = (id) => {
        console.log(id)
        setSelectedId(id);
    }

    const handleCancel = () => {
        console.log("handle cancel triggered")
        setSelectedId(0);
    }


    const handleSave = (id, data) => {
        console.log(id)
        console.log(data)
        const execute = async () => {
            await updateAssessmentAddOns(id, data)
                .then(response => {
                    if (response.success) {
                        setRetrieve(true);
                        setSelectedId(0);
                        props.isResultUpdated(true)


                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // localStorage.clear();
                        // history.push('/auth/login');
                    } else {
                        // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();
    }

    const handleDelete = (id) => {
        const execute = async () => {
            await deleteAssessmentAddOns(id)
                .then(response => {
                    if (response.success) {
                        setRetrieve(true);
                        props.isResultUpdated(true)
                        // setOpen(false)
                    }
                })
                .catch(error => {
                    if (error.response.status === 401) {
                        // localStorage.clear();
                        // history.push('/auth/login');
                    } else {
                        // dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    }
                })
        }
        execute();

    }

    useEffect(() => {
        if (results !== undefined) {
            if (results.length > 0) {
                let arr = []
                for (let i = RowCnt; i !== RowCnt + rowsPerPage; i++) {
                    if (results[i] === undefined) {

                    } else {
                        arr.push({ ...results[i], isOpenModal: false })

                    }
                }
                console.log(arr)
                setDataPerPage(arr)
            }

        }

    }, [results, page])

    useEffect(() => {
        onFilterHandler(data.courseId, data.yearLevelId, switchState.iSIndividual)
    }, [data, switchState])

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };
    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const DeleteModal = (props) => {


        return (
            <Modal
                open={props.isOpen}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="card p -5" style={style}>
                    <div className="card-header"> <h3>Delete Confirmation</h3> </div>
                    <div className="card-body">

                        <p className="card-text">Are you sure you want to delete this Add Ons?</p>
                        <button onClick={() => { handleDelete(props.id) }} className="btn btn-danger mr-3">Yes</button>
                        <button onClick={() => { handleClose(props.id) }} className="btn btn-secondary ">No</button>

                    </div>
                </Box>


            </Modal>
        )
    }

    console.log(results)
    console.log(rowsPerPage)

    const handleOpen = (id) => {
        let updatedItemList = dataPerPage.map((item) => {
            if (item.PaymentAddOnsId === id) {
                return { ...item, isOpenModal: true };
            }
            return item;
        });

        setDataPerPage(updatedItemList);

    };
    const handleClose = (id) => {

        let updatedItemList = dataPerPage.map((item) => {
            if (item.PaymentAddOnsId === id) {
                return { ...item, isOpenModal: false };
            }
            return item;
        });

        setDataPerPage(updatedItemList);

    };

    const selectHandleChange = name => event => {
        if (event == "") {
            setData({ ...data, [name]: null });

        } else {
            setData({ ...data, [name]: event });
        }
    }


    return (
        <> {dataPerPage !== undefined ?
            <>



                <div className="card p-5">
                    <div className="card-header p-5 row">
                        <h5 className="col">Assessment Add-ons List</h5>
                        <div className="col float-right">
                            <FormControlLabel
                                name="iSIndividual"
                                inputRef={register()}
                                control={<Switch checked={switchState.iSIndividual} onChange={switchHandleChange('iSIndividual')} value={switchState.iSIndividual} />}
                                label="Is Individual"
                            />
                        </div>
                        <div className="col">
                            <IMSelect
                                data={yearLevelSelect}
                                onHandleChange={selectHandleChange('yearLevelId')}
                                refClassContainer="form-control align-middle"
                                name="yearLevelId"
                                isRequired={true}
                                withLabel={false}
                                label=""
                                placeHolder="Year Level"
                                forwardRef={setValue}
                                selectedId={data.yearLevelId}
                                refClassName={``}
                                withDescription={true}
                                description={`Year Level`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]}
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                                error={!!errors.yearLevel}
                                errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                            />
                        </div>
                        <div className="col">
                            <IMSelect
                                data={courseSelect}
                                onHandleChange={selectHandleChange('courseId')}
                                refClassContainer="form-control align-middle"
                                name="courseId"
                                isRequired={true}
                                withLabel={false}
                                label=""
                                placeHolder="Course"
                                forwardRef={setValue}
                                selectedId={data.courseId}
                                refClassName={``}
                                withDescription={true}
                                description={`Course`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]}
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                                error={!!errors.yearLevel}
                                errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                            />
                        </div>

                    </div>
                    <div className="card-body">
                        <TableContainer>
                            <Table size="small" className="table">
                                <TableHead className="thead-light p-2">
                                    <TableRow className="p-5">
                                        <TableCell align="left">#</TableCell>
                                        <TableCell align="left">Student</TableCell>
                                        <TableCell align="left">Year Level</TableCell>
                                        <TableCell align="left">Course</TableCell>
                                        <TableCell align="center">Fee</TableCell>
                                        <TableCell align="center">Amount</TableCell>
                                        <TableCell align="center">School Year</TableCell>
                                        <TableCell align="center">Sem</TableCell>
                                        <TableCell align="center">Action</TableCell>


                                    </TableRow>
                                </TableHead>
                                <TableBody className="p-2">
                                    {results.length > 0 ? dataPerPage.map((result) => {
                                        console.log(result)
                                        console.log(result.PaymentAddOnsId)
                                        rowCnt++
                                        if (selectedId !== 0 && selectedId === result.PaymentAddOnsId) {
                                            return (
                                                <RowForm
                                                    key={result.PaymentAddOnsId}
                                                    result={result}
                                                    paymentAddOnsId={result.PaymentAddOnsId}
                                                    onHandleCancel={handleCancel}
                                                    onHandleSave={handleSave}
                                                    index={rowCnt}
                                                    instituteSelect={instituteSelect}
                                                    courseSelect={courseSelect}
                                                    yearLevelSelect={yearLevelSelect}
                                                    sySelect={sySelect}
                                                    semSelect={semSelect}
                                                    feeSelect={feeSelect}
                                                    handleGetStudent={handleGetStudent}
                                                    handleStudentInputChange={handleStudentInputChange}
                                                    studentList={studentList}
                                                    studentId={studentId}
                                                />
                                            );
                                        } else {
                                            return (
                                                <>

                                                    {/* {modal(result.PaymentAddOnsId,true)} */}
                                                    <DeleteModal
                                                        isOpen={result.isOpenModal}
                                                        id={result.PaymentAddOnsId}
                                                    />

                                                    <TableRow key={result.PaymentAddOnsId}>
                                                        <TableCell className="align-middle" align="left" >{rowCnt}</TableCell>
                                                        <TableCell className="align-middle" align="left">{result.StudentName}</TableCell>
                                                        <TableCell className="align-middle" align="left">{result.YearLevelDesc}</TableCell>
                                                        <TableCell className="align-middle" align="left">{result.CourseDesc}</TableCell>
                                                        <TableCell className="align-middle" align="center">{result.FeeDesc}</TableCell>
                                                        <TableCell className="align-middle" align="center">{result.Amount}</TableCell>
                                                        <TableCell className="align-middle" align="center">{result.SchoolYear}</TableCell>
                                                        <TableCell className="align-middle" align="center">{result.SemDesc}</TableCell>
                                                        <TableCell className="align-middle" align="center">
                                                            <button
                                                                className="btn btn-xs btn-icon btn-icon-xs btn-info"
                                                                onClick={() => handleEdit(result.PaymentAddOnsId)}
                                                            >
                                                                <i className="fa fa-pencil-alt text-white"></i>
                                                            </button>
                                                            <button
                                                                className="btn btn-xs btn-icon btn-icon-xs btn-danger m-1 "
                                                                onClick={() => { handleOpen(result.PaymentAddOnsId) }}
                                                            >
                                                                <i className="fa fa-trash-alt text-white"></i>
                                                            </button>


                                                        </TableCell>
                                                    </TableRow>
                                                </>

                                            )

                                        }
                                    }) : ""}

                                </TableBody>
                                <TableFooter>
                                    <RowForm
                                        result={[]}
                                        index={undefined}
                                        instituteSelect={instituteSelect}
                                        courseSelect={courseSelect}
                                        yearLevelSelect={yearLevelSelect}
                                        sySelect={sySelect}
                                        semSelect={semSelect}
                                        onHandleAdd={handleAdd}
                                        feeSelect={feeSelect}
                                        handleGetStudent={handleGetStudent}
                                        handleStudentInputChange={handleStudentInputChange}
                                        studentList={studentList}
                                        studentId={studentId}
                                    />
                                </TableFooter>
                            </Table>


                        </TableContainer>
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={results.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onChangePage={handleChangePage}
                            onChangeRowsPerPage={handleChangeRowsPerPage}
                        />
                    </div>

                </div>



            </>



            :

            <Loading />

        }


        </>
    )
}