import React, { useEffect } from "react";
import { useForm } from 'react-hook-form';
import { verifyUserByEmailAddress, loginWithGoogle, login } from '../__hooks__';
import { authTypes } from '../__hooks__/types';
import { componentTypes } from '../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { useAppContext } from '../../../contexts/useAppContext';
import firebase from '../../../apis/firebase/firebase.config';
import history from '../../../history';
import CryptoJS from "crypto-js";

export default function Login() {
  const { state: { auth }, dispatch } = useAppContext();
  const { register, handleSubmit, errors } = useForm();

  //LISTEN TO USER
  useEffect(() => {
    if (auth.loginType === 'L') {
      if (localStorage.getItem('Username') && localStorage.getItem('Password')) {
        history.push('../admin/dashboard');
      } else {
        // USER IS SIGNED OUT
        var element = document.getElementsByClassName('offcanvas-overlay');
        if (element.length > 0) {
          element[0].remove()
        }
        history.push('../auth/login');
      }
    } else if (auth.loginType === 'G') {
      firebase.auth().onAuthStateChanged(function (user) {
        if (user) {
          // USER IS SIGNED IN
          if (auth.isUserVerified) {
            history.push('../admin/dashboard');
          }
        } else {
          // USER IS SIGNED OUT
          var element = document.getElementsByClassName('offcanvas-overlay');
          if (element.length > 0) {
            element[0].remove()
          }
          history.push('../auth/login');
        }
      });
    } else if (auth.loginType === 'F') {

    }
  }, [auth, dispatch])


  useEffect(() => {
    dispatch({ type: 'HIDE-SNACKBAR' });

  }, [])

  // // VERIFY AND LOGIN
  const verifyUser = (data, accessToken) => {
    const { email } = data[0];
    // VERIFY USER
    const executeVerify = async () => {
      const verify = await verifyUserByEmailAddress(email);
      if (verify.success) {
        // LOGIN
        const executeLogin = async () => {
          const login = await loginWithGoogle(accessToken, email);
          if (login.success) {
            dispatch({ type: authTypes.LOGIN_WITH_GOOGLE, accessToken: accessToken, data: data[0], isAdmin: login.data.IsAdmin, roleId: login.data.RoleId, designation: login.data.Designation });
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: login.message });
            logout();
          }
        }
        executeLogin();
      } else {
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: verify.message });
        logout();
      }
    }
    executeVerify();
  }

  //LOGIN WITH GOOGLE
  const btnGoogleAuth = () => {
    // USING A POPUP
    var provider = new firebase.auth.GoogleAuthProvider();
    provider.addScope('profile');
    provider.addScope('email');

    firebase.auth().signInWithPopup(provider).then(function (result) {
      const token = result.credential.accessToken;
      const data = result.user.providerData;
      verifyUser(data, token);
    }).catch(function (error) {
      // HANDLE ERRORS HERE    
      console.log('Error Logs: ', error.message);
    });
  }

  //LOGOUT WITH GOOGLE
  const logout = () => {
    firebase.auth().signOut().then(function () {
      setTimeout(() => {
        dispatch({ type: authTypes.LOGOUT_WITH_GOOGLE });
      }, 1000)
      history.push('/auth/login');
    }).catch(function (error) {
      console.log("Error Logs: ", error.message);
    });
  }

  //User sign-up
  const userSignUp = (forgotPassword) => {
    //history.push('../auth/redirect');
    forgotPassword == true ? history.push('../auth/forgotpassword') : history.push('../auth/signup');
  }

  const onSubmit = formValues => {
    // LOGIN
    const execute = async () => {
      await login(formValues)
        .then(result => {
          if (result.success) {
            dispatch({ type: authTypes.LOGIN, data: result.data, loginType: 'L' });
            let encryptedPassword = CryptoJS.AES.encrypt(formValues.password, result.data.AccessToken).toString();
            localStorage.setItem('Username', formValues.emailAddress);
            localStorage.setItem('Password', encryptedPassword);
            localStorage.setItem('AccessToken', result.data.AccessToken);
            history.push('../admin/dashboard');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();
  }

  return (
    <div className="login-form login-signin" id="kt_login_signin_form">
      {/* begin::Head */}
      <div className="text-center mb-5 mb-lg-5">
        <h3 className="font-size-h1 mb-5">
          <b>LOGIN</b>
        </h3><br />
      </div>
      {/* end::Head */}

      {/*begin::Form*/}
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="form fv-plugins-bootstrap fv-plugins-framework mt-5"
      >
        <div className="form-group fv-plugins-icon-container">
          <label>Email Address <span className="text-danger">*</span></label>
          <input
            placeholder="Email Address"
            type="text"
            name="emailAddress"
            className={`form-control h-auto py-5 px-6${errors.emailAddress === undefined ? "" : " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoFocus
          />
          <span className="form-text text-muted">Please enter your email address.</span>
          {errors.emailAddress && errors.emailAddress.type === "required" && <div className="invalid-feedback">Email Address is required</div>}
        </div>
        <div className="form-group fv-plugins-icon-container">
          <label>Password <span className="text-danger">*</span></label>
          <input
            placeholder="Password"
            type="password"
            name="password"
            className={`form-control h-auto py-5 px-6${errors.password === undefined ? "" : " is-invalid"}`}
            ref={register({
              required: true
            })}
          />
          <span className="form-text text-muted">Please enter your password.</span>
          {errors.password && errors.password.type === "required" && <div className="invalid-feedback">Password is required</div>}
        </div>
        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          {/* <span className=""></span> */}
          {/* <button type="button" onClick={btnGoogleAuth} className="btn btn-google"><i className="socicon-google"></i> Login with Google</button> */}
          <button
            type="submit"
            className={`btn btn-primary btn-block`}
          >
            <i className="fa flaticon-paper-plane-1"></i>&nbsp;Login with ASTRA
          </button>
        </div>

        <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
          <a onClick={() => userSignUp(true)} style={{ color: '#0000EE', textDecoration: "underline" }}>Forgot password?</a>
          <a onClick={() => userSignUp(false)} style={{ color: '#0000EE', textDecoration: "underline" }}>Sign up</a>
        </div>

      </form>
      {/*end::Form*/}
    </div>
  );
}
