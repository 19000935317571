import React, { useState, useEffect } from "react";
import ListFilters from "../../../../../_metronic/layout/components/extras/filters/listFilters";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSchoolYear, getSemester, getStudentBalanceReport, getActiveSemester,
    getActiveSchoolYear, getInstitute, getCourse
} from "./__hooks__/index"
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import IMTableCustom from "../../../../../_metronic/layout/components/custom/table/IMTableCustom";
import moment from "moment";

export default function StudentBalanceReport(props) {
    const { setValue } = useForm();
    const [results, setResults] = useState([])
    const [filteredSem, setFilteredSem] = useState()
    const [filteredSy, setFilteredSy] = useState()
    const [filteredIns, setFilteredIns] = useState()
    const [filteredCourse, setFilteredCourse] = useState()
    const [filteredDateFrom, setFilteredDateFrom] = useState(moment().format("YYYY-MM-DD"))
    const [filteredDateTo, setFilteredDateTo] = useState(moment().add({ days: 1 }).format("YYYY-MM-DD"))
    const [semestersSelect, setSemestersSelect] = useState([])
    const [schoolYearSelect, setSchoolYearSelect] = useState([])
    const [instituteSelect, setInstituteSelect] = useState([])
    const [courseSelect, setCourseSelect] = useState([])
    const [activeSem, setActiveSem] = useState('1S')
    const [activeSy, setActiveSy] = useState('')
    const [excelFormat, setExcelFormat] = useState([])
    const [isGeneratingReport, setIsGeneratingReport] = useState(false)

    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectInsChange = name => event => { setFilteredIns(event) }
    const selectCourseChange = name => event => { setFilteredCourse(event) }


    const onGetStudentBalanceReport = async (v) => {
        await getStudentBalanceReport(v)
            .then(res => {
                setIsGeneratingReport(false)
                console.log(res)
                setResults(res.data)
            })
            .catch(e => {

            })
    }


    const onDateFromHandler = (date) => {
        setFilteredDateFrom(date)
    }

    const onDateToHandler = (date) => {
        setFilteredDateTo(date)

    }

    const onSubmit = () => {
        setIsGeneratingReport(true)
        let dataToBeSubmit = {
            semesterId: filteredSem,
            schoolYear: filteredSy,
            courseId: filteredCourse


        }
        console.log(dataToBeSubmit)
        onGetStudentBalanceReport(dataToBeSubmit)

    }



    useEffect(() => {
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getSemester, setSemestersSelect, 'SemesterId', 'Description')
        SelectCreator(getCourse, setCourseSelect, 'CourseId', 'Code')
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code')



        const exeGetActiveSy = async () => {
            await getActiveSchoolYear()
                .then(res => {
                    console.log(res)

                    setActiveSy(res.data[0].SchoolYear)
                    setFilteredSy(res.data[0].SchoolYear)
                })
        }

        const exeGetActiveSem = async () => {
            await getActiveSemester()
                .then(res => {
                    console.log(res)
                    setActiveSem(res.data[0].SemesterId)
                    setFilteredSem(res.data[0].SemesterId)
                })
        }

        exeGetActiveSy()
        exeGetActiveSem()

    }, [])

    useEffect(() => {
        if (results !== undefined) {
            let arr = []
            for (let i = 0; i < results.length; i++) {
                arr.push({
                    'StudentNo': `${results[i].StudentNo}`,
                    'Student Name': `${results[i].StudentName}`,
                    'Total Amount': `${results[i].TotalAmount}`,
                    'Total Balance': `${results[i].TotalBalance}`
                })


            }

            console.log(arr)
            setExcelFormat(arr)
        }
    }, [results])

    const columns = [
        { id: 'StudentNo', label: 'Student No:', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'StudentName', label: 'Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TotalAssessmentFee', label: 'Assessment Fee', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TotalPaid', label: 'Paid Amount', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'RemainingBalance', label: 'Total Balance', align: 'left', withSorting: true, hidden: false, disablePadding: false }

    ]

    const tableProps = {
        recordId: 'StudentId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: results.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'studentBalanceReport',
        parentId: null,
        tableTitle: '',

    }

    const imSelectDropdownSchoolYear = () => {
        return <IMSelect
            data={schoolYearSelect}
            onHandleChange={selectSyChange()}
            refClassContainer=""
            name="SchoolYear"
            isRequired={false}
            withLabel={false}
            label="SchoolYear"
            placeHolder="Select Status"
            forwardRef={setValue}
            selectedId={filteredSy === undefined ? activeSy : filteredSy}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }


    const imSelectDropdownSemesters = () => {
        return <IMSelect
            data={semestersSelect}
            onHandleChange={selectSemChange()}
            refClassContainer=""
            name="Semester"
            isRequired={false}
            withLabel={false}
            label="Semester"
            placeHolder="Select Semester"
            forwardRef={setValue}
            selectedId={filteredSem === undefined ? activeSem : filteredSem}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }

    const imSelectDropdownInstitute = () => {
        return <IMSelect
            data={instituteSelect}
            onHandleChange={selectInsChange()}
            refClassContainer=""
            name="Institute"
            isRequired={false}
            withLabel={false}
            label="Institute"
            placeHolder="Select Institute"
            forwardRef={setValue}
            selectedId={filteredIns === undefined ? "" : filteredIns}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }

    const imSelectDropdownCourse = () => {
        return <IMSelect
            data={courseSelect}
            onHandleChange={selectCourseChange()}
            refClassContainer=""
            name="Course"
            isRequired={false}
            withLabel={false}
            label="Course"
            placeHolder="Select Course"
            forwardRef={setValue}
            selectedId={filteredCourse === undefined ? "" : filteredCourse}
            refClassName={`text-center`}
            withDescription={false}
            //description={`Enrollment Status`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />
    }


    console.log(activeSem)
    console.log(activeSy)
    console.log(filteredSem)
    console.log(filteredSy)
    console.log(results)

    console.log(filteredDateFrom)
    console.log(moment().format("YYYY-MM-DD HH:mm:ss"))

    if (excelFormat.length > 0) {
        console.log(excelFormat[0])
        console.log(excelFormat[0][['Prototype']])

    }


    const sampleData = [
        {
            "firstname": "Venjo",
            "lastName": "Reyes"
        },
        {
            "firstname": "Jessca",
            "lastName": "Katigbak"
        }
    ]

    return (
        <>

            <div className="card">
                <div className="card-header font-weight-bold">
                    <h3 className="mb-0 d-block">  &nbsp;Student Balance Report</h3>
                    <div className="ml-2 mt-2" >
                        <p className=" d-block mb-0">{`SY ${activeSy} - ${parseInt(activeSy) + 1} / ${activeSem}`}</p>

                    </div>

                </div>
                <ListFilters
                    selectDropdownSemester={imSelectDropdownSemesters}
                    selectDropdownSchoolYear={imSelectDropdownSchoolYear}
                    selectDropdownInstitute={imSelectDropdownInstitute}
                    selectDropdownCourse={imSelectDropdownCourse}
                    onDateFromHandler={onDateFromHandler}
                    onDateToHandler={onDateToHandler}
                    dateFrom={filteredDateFrom}
                    dateTo={filteredDateTo}
                    tableTitle='Filter: '
                    type={'studentBalanceReport'}
                    searchName={'Search'}
                    onSubmit={onSubmit}
                    results={results}
                    excelFormat={excelFormat}
                    studentBalanceReport={1}
                    isGeneratingReport={isGeneratingReport}

                />

                <IMTableCustom tableProps={tableProps} />


            </div>


        </>
    )
}