import instance from '../../../../../apis/local/systemAPI';


export const getInstructionalGuide = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/grades/instructional-guide/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "S.SubjectCode": keyword,
                        "S.Description": keyword,
                        "EI.LastName": keyword,
                        "EI.FirstName": keyword,
                        "CS.Description": keyword,

                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/grades/instructional-guide/q`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstructionalGuideList = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide/getInstructionalGuideList", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createInstructionalGuide = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateInstructionalGuide = async (id, formValues) => {
    const result = await instance.patch(`api/grades/instructional-guide/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteInstructionalGuide = async (id) => {
    const result = await instance.post(`api/grades/instructional-guide/delete`, id);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSubjects = async () => {
    const result = await instance.get(`api/references/subjects/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getEmployees = async () => {
    const result = await instance.get(`api/employees/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const getSchoolYear = async () => {
    const result = await instance.get(`api/references/schoolyears/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getGradeTemplate = async () => {
    const result = await instance.get(`api/grades/grade-templates/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getInstructionalGuideQuery = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/getInstructionalGuideListById`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getInstructionalGuideSubjects = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/instructorSubjects`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const addUpdateInstructionalGuide = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/addUpdate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const isWithLab = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/isWithLab`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const checkIsEditable = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/isEditable`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getClassSectionSelect = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/getClassSectionSelect`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const instructionalGuideIsOverride = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/isOverride`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
