import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function GrantDisclosureAgreementDialog(props) {
    const { isOpen, onShowDialog,
        title, description, clearance, handleProceed, studentAccount, student, studentTotalDiscount } = props;
    const [open, setOpen] = useState(isOpen);
    const [isChecked, setIsChecked] = useState(false)
    const handleClose = () => {
        onShowDialog(false);
        setOpen(false);
    }


    const onHandleAgreement = event => {
        console.log(event)
        let isCheck = event.target.checked

        setIsChecked(isCheck)

    }



    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
                maxWidth="md" // You can set 'xs', 'sm', 'md', 'lg', 'xl' here
                fullWidth // This makes the dialog take the full width defined by maxWidth
                sx={{
                    '& .MuiDialog-container': {
                        '& .MuiPaper-root': {
                            maxWidth: '800px', // Custom width here
                        },
                    },
                }}
            >
                <DialogTitle id="alert-dialog-slide-title">Grant Disclosure</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">

                        <section className='container'>
                            <main>
                                <header className='d-lg-none'>
                                    <section className='row'>
                                        <p className="col-12 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Name of Student: <span className='text-dark float-right text-left'>{studentAccount.StudentName}</span>
                                        </p>


                                    </section>
                                    <section className='row mt-3'>
                                        <p className="col-12 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Type of Grant: <span className='text-dark float-right text-left'> {student.studentScholarship}</span>
                                        </p>
                                    </section>
                                    <section className='row mt-3'>
                                        <p className="col-12 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Course/Track: <span className='text-dark float-right text-left'>{studentAccount.CourseDesc}</span>
                                        </p>

                                    </section>
                                    <section className='row mt-3'>
                                        <p className="col-12 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Grade/Year Level: <span className='text-dark float-right text-left'>  {studentAccount.YearLevelDesc}</span>
                                        </p>
                                    </section>
                                </header>
                                <header className='d-none d-lg-block'>
                                    <section className='row'>
                                        <p className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Name of Student:
                                        </p>

                                        <div className="col-8 text-dark  text-left" style={{ fontSize: '10px' }}>
                                            {studentAccount.StudentName}
                                        </div>
                                    </section>
                                    <section className='row mt-3'>
                                        <p className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Type of Grant:
                                        </p>

                                        <div className="col-8  text-dark text-left" style={{ fontSize: '10px' }}>
                                            {student.studentScholarship}
                                        </div>
                                    </section>
                                    <section className='row mt-3'>
                                        <p className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Course/Track:
                                        </p>

                                        <div className="col-8 text-dark  text-left" style={{ fontSize: '10px' }}>
                                            {studentAccount.CourseDesc}
                                        </div>
                                    </section>
                                    <section className='row mt-3'>
                                        <p className="col-2 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                            Grade/Year Level:
                                        </p>

                                        <div className="col-8  text-dark text-left" style={{ fontSize: '10px' }}>
                                            {studentAccount.YearLevelDesc}
                                        </div>
                                    </section>


                                </header>

                                <br />
                                <br />

                                <section className='row mt-5 '>
                                    <p className="col-12 mb-0 d-inline pr-0" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        Dear &nbsp;<span className='text-dark'>{studentAccount.StudentName}</span>.
                                    </p>


                                </section>


                                <section className='row mt-2 '>
                                    <p className="col-12 mb-0 d-inline pr-0 text-justify" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        Congratulation for being selected as a GRANTEE of <span className='text-dark'>{student.studentScholarship}</span>! This grant entitles you to
                                        <span className='text-dark'> {studentAccount.ScholarshipPower}</span> equvalent to <span className='text-dark'>Php{studentTotalDiscount} </span>
                                        for <span className='text-dark'>{studentAccount.SemesterDesc},{studentAccount.SY}</span>
                                    </p>



                                </section>
                                <br />


                                <section className='row mt-2 '>


                                    <p className="col-12 mb-0 d-inline pr-0 text-justify" style={{ fontWeight: 'bold', fontSize: '10px' }}>
                                        You may use this as a form of payment which is deductible to the total amount due for the
                                        semester. This will serve as the cancellation of your promissory note.

                                    </p>


                                </section>

                                <br />

                                <p className="col-12 mb-0 d-inline pr-0 pl-0" style={{ fontWeight: 'bold', fontSize: '10px' }}> Welcome to the Home of the Bravehearts!</p>
                                <br />
                                <br />
                                <section className='d-flex align-items-center'>
                                    <input
                                        type='checkbox'
                                        onChange={onHandleAgreement}
                                    />
                                    <p className='d-inline text-primary mb-0' style={{ fontSize: '10px' }}> &nbsp; I have read and accept the grant disclosure agreement</p>
                                </section>

                            </main>
                        </section>


                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <button onClick={() => { handleClose(); }} className="btn btn-secondary">
                        Close
                    </button>
                    <button disabled={isChecked == false} onClick={() => { handleProceed(); handleClose(); }} className="btn btn-primary">
                        Continue
                    </button>


                </DialogActions>
            </Dialog>
        </>
    );
}