import instance from '../../../../../apis/local/systemAPI';

// START OF ROOM
export const getActiveRooms = async () => {
  const result = await instance.get("api/references/rooms");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getRooms = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/rooms/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "room": keyword,
            "description": keyword,
            "capacity": keyword,
            "location": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/rooms/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createRoom = async (formValues) => {
  const result = await instance.post("api/references/rooms", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateRoom = async (id, formValues) => {
  const result = await instance.patch(`api/references/rooms/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteRoom = async (id) => {
  const result = await instance.delete(`api/references/rooms/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF ROOM