import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import AddUpdateModal from '../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getGradeTemplate, createGradeTemplate, updateGradeTemplate, deleteGradeTemplate, getInstitute, getTest } from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { getIn, setIn } from 'formik';

export default function List() {
    const { state: { fees, auth }, dispatch } = useAppContext();
    const [results, setResults] = useState([]);
    const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
    const [page, setPage] = useState(fees.page);
    const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
    const [keyword, setKeyword] = useState(fees.keyword);
    const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
    const [isOpenAdd, setIsOpenAdd] = useState(false)
    const [isOpenUpdate, setIsOpenUpdate] = useState(false)
    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [rowValue, setRowValue] = useState(null)
    const [rowDeleteValue, setRowDeleteValue] = useState(null)
    const updateId = fees.id;



    const [instituteSelect, setInstituteSelect] = useState(null)


    // table pagination and search -----------------------------------------------------

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    const handleChangeKeyword = (event) => {
        setKeyword(event);
    };

    const handleChangeOnSearch = (event) => {
        setOnSearch(event);
    };


    // on save grade element  -----------------------------------------------------------
    const onSaveGradeElement = async (val) => {
        await createGradeTemplate(val)
            .then(res => {
                setIsOpenAdd(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been saved.' });

            })
    }

    // on update grade element
    const onUpdateElement = async (id, val) => {
        await updateGradeTemplate(id, val)
            .then(res => {
                setIsOpenUpdate(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been updated.' });
            })
    }

    // on delete grade element
    const onDeleteElement = async (id) => {
        await deleteGradeTemplate(id)
            .then(res => {
                setIsOpenDelete(false)
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Grade element has been Deleted.' });
            })
            .catch(e => {

            })
    }



    // modal content and logics -------------------------------------------------------

    //Add
    const onAddGradeElement = () => {
        setIsOpenAdd(true)
    }

    const onAddGradeElementClose = () => {
        setIsOpenAdd(false)
    }

    //Update
    const onUpdateGradeElement = (data) => {
        setRowValue(data)
    }

    const onUpdateGradeElementClose = () => {
        setIsOpenUpdate(false)
        setRowValue(null)
    }

    //Delete
    const onDeleteGradeElement = (data) => {
        setRowDeleteValue(data)
    }

    const onDeleteGradeElementClose = () => {
        setIsOpenDelete(false)
    }


    // functions
    const keysToCamelCase = (obj) => {
        if (obj !== null && obj !== undefined) {
            const newObject = {};
            for (const key in obj) {
                const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
                newObject[modifiedKey] = obj[key];
            }

            return newObject
        } else {
            return
        }

    }

    useEffect(() => {
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Description')
    }, [])

    //Modal Content
    const Content = (props) => {
        const [data, setData] = useState({
            gradeTemplateCode: props.value !== undefined && props.value !== null ? props.value.gradeTemplateCode : null,
            description: props.value !== undefined && props.value !== null ? props.value.gradeTemplateDesc : null,
            instituteId: props.value !== undefined && props.value !== null ? props.value.instituteId : null
        })


        const [switchState, setSwitchState] = useState({
            isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false,
            isPeriodicOverride: props.value !== undefined && props.value !== null ? props.value.isPeriodicOverride === 1 ? true : false : false,
            isFinalsOverride: props.value !== undefined && props.value !== null ? props.value.isFinalsOverride === 1 ? true : false : false

        });

        const switchHandleChange = name => event => {
            console.log(event.target.checked)
            setSwitchState({ ...switchState, [name]: event.target.checked });
        };

        // Selects

        const selectInstituteChange = name => event => { setData({ ...data, instituteId: event }) }





        useEffect(() => {
            if (data && switchState) {
                console.log({
                    ...data,
                    isActive: switchState.isActive == true ? 1 : 0,
                    isPeriodicOverride: switchState.isPeriodicOverride == true ? 1 : 0,
                    isFinalsOverride: switchState.isFinalsOverride == true ? 1 : 0,
                })
                props.onSave({
                    ...data,
                    isActive: switchState.isActive == true ? 1 : 0,
                    isPeriodicOverride: switchState.isPeriodicOverride == true ? 1 : 0,
                    isFinalsOverride: switchState.isFinalsOverride == true ? 1 : 0,
                })
            }

        }, [data, switchState])



        console.log(props.value)
        return (
            <>

                <main className='container'>
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="inputEmail4">Grade Template Code</label>
                            <input
                                required type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Short Name"
                                defaultValue={props.value !== undefined && props.value !== null ? props.value.gradeTemplateCode : ''}
                                onBlur={(e) => { setData({ ...data, gradeTemplateCode: e.target.value }) }}
                            />
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <label htmlFor="inputEmail4">Description</label>
                            <input
                                type="text"
                                className="form-control"
                                id="inputEmail4"
                                placeholder="Description"
                                defaultValue={props.value !== undefined && props.value !== null ? props.value.gradeTemplateDesc : ''}
                                onBlur={(e) => { setData({ ...data, description: e.target.value }) }}

                            />
                        </div>

                    </div>
                    <div className="form-row">
                        <div className="form-group col">
                            <IMSelect
                                data={props.instituteSelect}
                                onHandleChange={selectInstituteChange('instituteId')}
                                refClassContainer=""
                                name="instituteId"
                                isRequired={false}
                                withLabel={false}
                                label="Institute"
                                placeHolder="Select Institute"
                                forwardRef={() => { }}
                                selectedId={data.instituteId}
                                refClassName={`text-center`}
                                withDescription={false}
                                //description={`Enrollment Status`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]
                                }
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}
                            />

                        </div>

                    </div>
                    <div className="form-row">

                        <div className="form-group col">
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    name="isPeriodicOverride"
                                    inputRef={() => { }}
                                    control={<Switch checked={switchState.isPeriodicOverride} onChange={switchHandleChange('isPeriodicOverride')} value={switchState.isPeriodicOverride} />}
                                    label="Periodic Override"
                                />
                            </FormControl>
                        </div>
                        <div className="form-group col">
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    name="isFinalsOverride"
                                    inputRef={() => { }}
                                    control={<Switch checked={switchState.isFinalsOverride} onChange={switchHandleChange('isFinalsOverride')} value={switchState.isFinalsOverride} />}
                                    label="Finals Override"
                                />
                            </FormControl>
                        </div>
                        <div className="form-group col">
                            <FormControl component="fieldset">
                                <FormControlLabel
                                    name="isActive"
                                    inputRef={() => { }}
                                    control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                                    label="Active"
                                />
                            </FormControl>
                        </div>
                    </div>
                </main>
            </>
        )
    }

    const DeleteContent = (props) => {


        console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
        return (
            <>
                <main className='container'>
                    <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.gradeTemplateDesc : ''} `}</span> </p>
                </main>



            </>
        )
    }



    useEffect(() => {
        const execute = async () => {
            console.log(auth.data.InstituteId + '__' + auth.data.CourseId)
            if (auth.data.roles.includes(7)) {
                await getGradeTemplate(page, rowsPerPage, keyword, auth.data.InstituteId, null)
                    .then(response => {
                        setResults([])

                        if (response.success) {
                            setResults(response.data);
                            setTotalRecord(response.data.length > 0 ? response.records : 0);
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                        }
                    })
                    .catch(error => {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    })
            } else if (auth.data.roles.includes(6)) {
                await getGradeTemplate(page, rowsPerPage, keyword, null, auth.data.CourseId)
                    .then(response => {
                        if (response.success) {
                            setResults([])

                            setResults(response.data);
                            setTotalRecord(response.data.length > 0 ? response.records : 0);
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                        }
                    })
                    .catch(error => {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    })
            } else {
                await getGradeTemplate(page, rowsPerPage, keyword, null, null)
                    .then(response => {
                        if (response.success) {
                            setResults([])

                            setResults(response.data);
                            setTotalRecord(response.data.length > 0 ? response.records : 0);
                        } else {
                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                        }
                    })
                    .catch(error => {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                    })
            }

        }
        execute();

    }, [page, rowsPerPage, keyword, isOpenAdd, isOpenUpdate, isOpenDelete])

    useEffect(() => {
        if (rowValue !== null) {
            setIsOpenUpdate(true)
        }
    }, [rowValue])

    useEffect(() => {
        if (rowDeleteValue !== null) {
            setIsOpenDelete(true)
        }
    }, [rowDeleteValue])

    const columns = [
        { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'GradeTemplateDesc', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Institute', label: 'Institute', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'IsPeriodicOverride', label: 'Periodic Override', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        // { id: 'IsFinalsOverride', label: 'Finals Override', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
    ]

    const tableProps = {
        recordId: 'GradeTemplateId',
        sortField: '',
        columns: columns,
        rows: results,
        totalRecords: totalRecords,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'addUpdateGradeTemplate',
        parentId: null,
        tableTitle: 'List of Grade Template',
        onSearch: onSearch,
        keyword: keyword,
        lastUpdateId: updateId,
        onPage: page,
        onRowsPerPage: rowsPerPage,
        onRowsPerPageOptions: [10, 25, 50, 100],
        onTypes: referenceTypes,
        onHandleChangePage: handleChangePage,
        onHandleChangeRowsPerPage: handleChangeRowsPerPage,
        onHandleChangeKeyword: handleChangeKeyword,
        onHandleChangeOnSearch: handleChangeOnSearch,
        onUpdate: onUpdateGradeElement,
        onDelete: onDeleteGradeElement
    }
    console.log(rowValue)
    console.log(keysToCamelCase(rowValue))
    console.log(instituteSelect)
    console.log(auth)

    return (
        <>
            {instituteSelect &&
                <>
                    <AddUpdateModal
                        title="Add Grade Template"
                        isOpen={isOpenAdd}
                        Content={Content}
                        handleClose={onAddGradeElementClose}
                        isAdd={true}
                        instituteSelect={instituteSelect}
                        onSubmit={onSaveGradeElement}
                        id="gradeTemplateId"

                    />

                    <AddUpdateModal
                        title="Update Grade Template"
                        isOpen={isOpenUpdate}
                        Content={Content}
                        value={keysToCamelCase(rowValue)}
                        handleClose={onUpdateGradeElementClose}
                        isUpdate={true}
                        instituteSelect={instituteSelect}
                        onSubmit={onUpdateElement}
                        id="gradeTemplateId"

                    />

                    <AddUpdateModal
                        title="Delete Grade Template"
                        isOpen={isOpenDelete}
                        Content={DeleteContent}
                        handleClose={onDeleteGradeElementClose}
                        isDelete={true}
                        onSubmit={onDeleteElement}
                        instituteSelect={instituteSelect}
                        value={keysToCamelCase(rowDeleteValue)}
                        id="gradeTemplateId"

                    />
                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header">
                            <div className="card-title">
                                <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
                            </div>
                            <div className="card-toolbar">
                                <div className="example-tools">
                                    <button className="btn btn-sm btn-icon-sm btn-success mr-1"><i className="flaticon-download-1"></i> Export</button>
                                    <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                                        <i className="fa fa-plus"></i> Add New
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                            <IMTable tableProps={tableProps} />
                        </div>
                    </div>

                </>
            }

        </>
    );
}