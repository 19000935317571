import React, { useState, useEffect } from 'react';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getInstructionalGuide, getGradeBookSummaryList, getGradeBookSummary, getGradeBookSummaryFinals,
    addUpdateGradeFinalsDetails, getGBookFinalGradeDetailByStudentId, deanPosting, chairPosting, getGBookFinalGradeIds,
    unPostGrades, authenticateGrades, saveFinalGrade, getExistingFinalGrade, saveFinalGradeAll, postGrades
} from './__hooks__';
import { useParams } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';
import { TableContainer, Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core';
import GradeBookPostingModal from '../../../../../../_metronic/layout/components/custom/modal/GradeBookPostingModal';
import GradeBookPostingFinalModal from '../../../../../../_metronic/layout/components/custom/modal/GradeBookPostingFinalModal';
import { fi } from 'date-fns/locale';
const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },
});

export default function List(props) {
    const { data, classSectionId, subjectScheduleDetailId, scheduleType, scheduleTypeId, instructionalScheduleTypeId } = props
    const { state: { auth }, dispatch } = useAppContext();
    const [instructionalGuide, setInstructionalGuide] = useState(null)
    const [results, setResults] = useState([]);
    const classes = useStyles();
    const { id } = useParams()
    const [isOpenGbookDetails, setIsOpenGbookDetails] = useState(false)
    const [isOpenGbookDetailsFinals, setIsOpenGbookDetailsFinals] = useState(false)

    const [rawDetails, setRawDetails] = useState([])
    const [finalRawDetails, setFinalRawDetails] = useState([])
    const [toBePostedByDean, setToBePostedByDean] = useState([])
    const [isAuthenticated, setIsAuthenticated] = useState(false)
    const [gradingPeriods, setGradingPeriods] = useState([])

    const [isLoadingStudent, setIsLoadingStudent] = useState(false)
    const [isPosted, setIsPosted] = useState(false)
    const [currentData, setCurrentData] = useState({ ig: null, igDetailId: null })

    const [gBookFinalGradeIds, setGBookFinalGradeIds] = useState([])


    // final Grade
    const [existingFinalGradeId, setExistingFinalGradeId] = useState(null)

    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;


    let rowCnt = 0;

    // functions


    const saveStudentFinalGrade = async (val) => {
        console.log(val)
        for (let i = 0; i < val.length; i++) {
            // await saveFinalGradeAll(val[i])
            await postGrades({
                studentId: val[i].StudentId,
                schoolYear: data.SchoolYear,
                semesterId: data.SemesterId,
                subjectScheduleDetailId: val[i].SubjectScheduleDetailId,
                gradingPeriodId: val[i].GradingPeriodId
            })
                .then(async res => {
                    console.log(res)
                    console.log(val)
                    // if (res.data == null) {
                    //     await saveFinalGrade([{
                    //         finalGradeId: 0,
                    //         studentId: val[i].StudentId,
                    //         schoolYear: data.SchoolYear,
                    //         semesterId: data.SemesterId,
                    //         subjectScheduleDetailId: subjectScheduleDetailId,
                    //         gradingPeriodId: val[i].GradingPeriodId,
                    //         lecFinalGrade: val[i].LecFinalGrade,
                    //         labFinalGrade: val[i].LabFinalGrade,
                    //         createdBy: auth.data.Username,
                    //         dateUpdated: dateTime,
                    //         isLec: scheduleType == 'Lecture' ? true : false,
                    //         isLab: scheduleType == 'Laboratory' ? true : false,
                    //         instructionalGuideId: val[i].GivenInstructionalGuideId

                    //     }])
                    // } else {
                    //     console.log(res)
                    //     await saveFinalGrade([{
                    //         finalGradeId: res.data.FinalGradeId,
                    //         studentId: val[i].StudentId,
                    //         schoolYear: data.SchoolYear,
                    //         semesterId: data.SemesterId,
                    //         subjectScheduleDetailId: subjectScheduleDetailId,
                    //         gradingPeriodId: val[i].GradingPeriodId,
                    //         lecFinalGrade: val[i].LecFinalGrade,
                    //         labFinalGrade: val[i].LabFinalGrade,
                    //         createdBy: auth.data.Username,
                    //         dateUpdated: dateTime,
                    //         isLec: scheduleType == 'Lecture' ? true : false,
                    //         isLab: scheduleType == 'Laboratory' ? true : false,
                    //         instructionalGuideId: val[i].GivenInstructionalGuideId

                    //     }])
                    // }
                })
        }

    }


    const handleClose = () => {
        setIsOpenGbookDetails(false)
        setRawDetails([])
    }

    const handleCloseFinals = () => {
        setIsOpenGbookDetailsFinals(false)
        setFinalRawDetails([])
    }

    const getSummaryDetails = async (guideId, guideDetailId, gradingPeriodId) => {
        console.log('openedFunction')
        await getGradeBookSummary({
            instructionalGuideId: guideId,
            instructionalGuideDetailId: guideDetailId,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            gradingPeriodId: gradingPeriodId,
            subjectScheduleDetailId: subjectScheduleDetailId,
            classSectionId: classSectionId,
            isLec: data.IsLec,
            scheduleTypeId: scheduleTypeId
        })
            .then(res => {
                console.log(res)
                if (auth.data.UserType == 'DCI' || auth.data.UserType == 'DCG' || auth.data.UserType == 'DCH') {
                    setIsAuthenticated(res.isAuthenticatedByAll)
                    setToBePostedByDean(res.toBePostedByAll)
                } else if (auth.data.UserType == 'DEN') {
                    setIsAuthenticated(res.isAuthenticated)
                    setToBePostedByDean(res.toBePostedByDean)
                } else if (auth.data.UserType == 'CHR') {
                    setToBePostedByDean(res.toBePostedByChair)
                    setIsAuthenticated(res.isAuthenticatedChair)
                }
                setRawDetails(res.data)
            })
            .catch(e => {

            })
    }


    const getSummaryFinalDetails = async (detailId, guideId, gradingPeriodId) => {
        await getGradeBookSummaryFinals({
            instructionalGuideId: guideId,
            instructionalGuideDetailId: detailId,
            schoolYear: data.SchoolYear,
            semesterId: data.SemesterId,
            gradingPeriodId: gradingPeriodId,
            subjectScheduleDetailId: subjectScheduleDetailId,
            classSectionId: classSectionId,
            isLec: data.IsLec,
            scheduleTypeId: scheduleTypeId
        })
            .then(async res => {
                if (auth.data.roles.includes(7) && auth.data.roles.includes(6)) {
                    setIsAuthenticated(res.isAuthenticatedByAll)
                    setToBePostedByDean(res.isToPostedByAll)
                } else if (auth.data.roles.includes(7)) {
                    setIsAuthenticated(res.isAuthenticated)
                    setToBePostedByDean(res.toBePostedByDean)
                } else if (auth.data.roles.includes(6)) {
                    setToBePostedByDean(res.toBePostedByChair)
                    setIsAuthenticated(res.isAuthenticatedChair)
                }
                console.log(res)
                setFinalRawDetails(res.data)
                setIsPosted(res.isPosted)
                if (auth.data.roles.includes(7)) {
                    await getGBookFinalGradeIds({
                        finalGradeId: res.gBookFinalGradeIds.toString()
                    }).then(res => {
                        let ids = []
                        res.data.map(d => {
                            ids.push(d.GBookFinalGradeDetailsId)
                        })
                        setGBookFinalGradeIds(ids)
                    })
                } else if (auth.data.roles.includes(6)) {
                    await getGBookFinalGradeIds({
                        finalGradeId: res.gBookFinalGradeIds.toString()
                    }).then(res => {
                        let ids = []
                        res.data.map(d => {
                            ids.push(d.GBookFinalGradeDetailsId)
                        })
                        setGBookFinalGradeIds(ids)
                    })
                }


            })
            .catch(e => {

            })
    }

    const onLoad = async (periodId) => {
        // setFinalRawPeriodicDetails([])
        await getSummaryDetails(currentData.ig, currentData.igDetailId, periodId)

    }

    const handleOpen = (detailId, guideId, gradingPeriod) => {
        setIsLoadingStudent(true)
        setCurrentData({ ig: guideId, igDetailId: detailId })
        getSummaryDetails(guideId, detailId, gradingPeriod)

    }

    const handleOpenFinalGrades = async (detailId, guideId, gradingPeriod) => {
        setIsLoadingStudent(true)
        getSummaryFinalDetails(detailId, guideId, gradingPeriod)
    }

    const unPostingGrades = async (val, gradingPeriod) => {
        await unPostGrades(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grade have been Unpost' });
                // onLoad(gradingPeriod)
            })
            .catch(e => {

            })
    }

    const verifyingGrades = async (val, details) => {
        console.log(val)
        console.log(details)
        if (auth.data.UserType == 'DCI' || auth.data.UserType == 'DEN' || auth.data.UserType == 'DCG') {

            await saveStudentFinalGrade(details)
        }
        await authenticateGrades(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grade have been Authenticated' });
                // onLoad(gradingPeriod)
            })
            .catch(e => {

            })
    }


    const onSaveFinalGrade = async (val) => {

        console.log(val)
        if (auth.data.UserType == 'DEN' || auth.data.UserType == 'DCI') {

            await saveStudentFinalGrade(val)
        }
        await addUpdateGradeFinalsDetails(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grades have been Authenticated' });

            })
            .catch(e => {

            })
    }

    const onDeanPosting = async (val) => {
        await deanPosting(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grades have been Authenticated' });

            })
            .catch(e => {

            })
    }

    const onChairPosting = async (val) => {
        await chairPosting(val)
            .then(res => {
                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'The grades have been Authenticated' });

            })
            .catch(e => {

            })
    }


    useEffect(() => {
        const execute = async () => {
            await getInstructionalGuide({ instructionalGuideId: parseInt(id) })
                .then(response => {
                    if (response.success) {
                        setInstructionalGuide(response.data);
                    } else {
                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                    }
                })
                .catch(error => {

                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

                })
        }
        execute();

    }, [])

    useEffect(() => {
        console.log('teeest')
        console.log(rawDetails)
        if (rawDetails.length > 0) {
            setIsOpenGbookDetails(true)
            setIsLoadingStudent(false)
        } else {
            if (isLoadingStudent == true) {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'There is no student associated with this section.'
                });
                setIsLoadingStudent(false)
            }

        }
    }, [rawDetails])

    useEffect(() => {

        if (finalRawDetails.length > 0) {
            setIsOpenGbookDetailsFinals(true)
            setIsLoadingStudent(false)
        } else {
            if (isLoadingStudent == true) {
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 3000, snackBarMessage: 'There is no student associated with this section.'
                });
                setIsLoadingStudent(false)
            }

        }
    }, [finalRawDetails])


    useEffect(() => {
        if (instructionalGuide !== null) {
            const getSchedule = async (val) => {
                await getGradeBookSummaryList(val)
                    .then(res => {
                        console.log(res)
                        setGradingPeriods(res.gradingPeriods)
                        setResults(res.data)
                    })
            }

            getSchedule({
                instructionalGuideId: instructionalGuide[0].InstructionalGuideId

            })
        }
    }, [instructionalGuide])

    console.log(rawDetails)
    console.log(results)
    console.log(data)
    console.log(auth.data)
    console.log(gBookFinalGradeIds)


    console.log(finalRawDetails)
    console.log(toBePostedByDean)
    console.log(scheduleType)
    console.log(scheduleTypeId)
    return (
        <>
            {results &&
                <>

                    {rawDetails.length > 0 &&
                        <GradeBookPostingModal
                            isOpen={isOpenGbookDetails}
                            results={rawDetails}
                            handleClose={handleClose}
                            data={data}
                            isViewingOfSummary={true}
                            onAuthenticateGrades={onSaveFinalGrade}
                            listToBePosted={toBePostedByDean}
                            isAuthenticated={isAuthenticated}
                            date={dateTime}
                            role={auth.data.UserType}
                            createdBy={auth.data.Username}
                            unPostingGrades={unPostingGrades}
                            verifyingGrades={verifyingGrades}
                            onLoad={onLoad}
                            scheduleType={scheduleType}
                            dateTime={dateTime}

                        />

                    }


                    {finalRawDetails.length > 0 &&
                        <GradeBookPostingFinalModal
                            isOpen={isOpenGbookDetailsFinals}
                            results={finalRawDetails}
                            handleClose={handleCloseFinals}
                            createdBy={auth.data.Username}
                            date={dateTime}
                            data={data}
                            isDean={auth.data.RoleId == 9 ? true : false}
                            isChair={auth.data.RoleId == 6 ? true : false}
                            isPosted={isPosted}
                            onPostGrades={onDeanPosting}
                            listToBePosted={toBePostedByDean}
                            getGBookFinalGradeDetailByStudentId={getGBookFinalGradeDetailByStudentId}
                            gBookFinalGradeIds={gBookFinalGradeIds}
                            isAuthenticated={isAuthenticated}
                            role={auth.data.roles}
                            gradingPeriods={gradingPeriods}

                        />


                    }


                    <div className="card card-custom gutter-b example example-compact">
                        <div className="card-header d-block bg-white" style={{ minHeight: 0 }}>
                            <div className="card-title">
                                {/* <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3> */}
                            </div>
                            <div className="card-toolbar float-right">
                                <div className="example-tools">
                                    {/* <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary p-1">
                                        <i className="fa fa-plus p-0"></i>
                                    </button> */}
                                </div>
                            </div>
                        </div>
                        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
                            {/* <IMTable tableProps={tableProps} /> */}
                            <TableContainer >
                                <Table className={classes.table} size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="center">#</TableCell>
                                            <TableCell>Grading</TableCell>
                                            <TableCell>Percentage</TableCell>

                                            <TableCell>Action</TableCell>


                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {results.length > 0 ? results.map((result) => {
                                            const { InstructionalGuideId, InstructionalGuideDetailId, GradingPeriod, GradingPeriodId, SYDesc, SEMDesc,
                                                GradeTemplatePercentage } = result;
                                            rowCnt += 1;

                                            return (

                                                <TableRow key={InstructionalGuideId}>
                                                    <TableCell align="center">{rowCnt}</TableCell>

                                                    <TableCell component="th" scope="row">{GradingPeriod}</TableCell>
                                                    <TableCell component="th" scope="row">{GradeTemplatePercentage}</TableCell>

                                                    <TableCell align="left">
                                                        {isLoadingStudent == false ?

                                                            <button onClick={() => { handleOpen(InstructionalGuideDetailId, InstructionalGuideId, GradingPeriodId) }} className='btn btn-xs btn-icon btn-icon-xs btn-primary mr-1'>
                                                                <i className="fa fa-users"></i>
                                                            </button>
                                                            :
                                                            <button className="btn btn-sm btn-primary" type="button" disabled>
                                                                <span className="spinner-border spinner-border-sm p-0" role="status" aria-hidden="true"></span>
                                                                <span className="sr-only">Loading...</span>
                                                            </button>
                                                        }


                                                    </TableCell>

                                                </TableRow>
                                            );

                                        }) :
                                            //  <p className='p-1 text-center'>No data available in the table</p>
                                            ''
                                        }
                                        <TableRow className='bg-secondary'>
                                            <TableCell align="center"></TableCell>
                                            <TableCell component="th" scope="row">Grade Summary</TableCell>
                                            <TableCell component="th" scope="row">100.00</TableCell>


                                            <TableCell align="left">
                                                {isLoadingStudent == false ?

                                                    <button onClick={() => handleOpenFinalGrades(0, data.InstructionalGuideId, 0)} className='btn btn-xs btn-icon btn-icon-xs btn-info mr-1'>
                                                        <i className="fa fa-users"></i>
                                                    </button>

                                                    :
                                                    <button className="btn btn-sm btn-info" type="button" disabled>
                                                        <span className="spinner-border spinner-border-sm p-0" role="status" aria-hidden="true"></span>
                                                        <span className="sr-only">Loading...</span>
                                                    </button>
                                                }

                                            </TableCell>

                                        </TableRow>
                                    </TableBody>

                                </Table>
                            </TableContainer>
                        </div>
                    </div>

                </>
            }

        </>
    );
}