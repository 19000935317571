import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import { Modal, Box, TableSortLabel } from '@material-ui/core';
import SubjectScheduleDetailModal from '../modal/SubjectScheduleDetailModal';
import { Link } from 'react-router-dom';
import RowList from "../../../../../app/modules/Admin/Enrollment/SubjectSchedule/SubjectScheduleDetail/RowList"
import { SignalCellularConnectedNoInternet2BarRounded } from '@material-ui/icons';
import IMSelect from '../select/IMSelect';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { wrap } from 'lodash';
import { useAppContext } from '../../../../../app/contexts/useAppContext';


const useRowStyles = makeStyles({
    root: {
        '& > *': {
            borderBottom: 'unset',
        },
    },
    elevatedRow: {
        boxShadow: '0 4px 8px rgba(0,0,0,0.1)',
        borderTop: '2px solid rgba(0,0,0,0.1)',
    },
    container: {
        maxHeight: 'auto',
        maxWidth: '100%',
        overflow: 'auto',
    },
    visuallyHidden: {
        border: 0,
        clip: 'rect(0 0 0 0)',
        height: 1,
        margin: -1,
        overflow: 'hidden',
        padding: 0,
        position: 'absolute',
        top: 20,
        width: 1,
    }
});

const style = {
    position: 'absolute',
    top: '50%',
    left: '55%',
    transform: 'translate(-50%, -50%)',
    width: 400,
    bgcolor: 'background.transparent',

    boxShadow: 24,
    p: 4,
};


const stableSort = (array, comparator) => {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

const columns = [
    { id: 'ClassCode', label: 'Class Code', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Type', label: 'Type', align: 'center', withSorting: false, hidden: false, disablePadding: false },
    { id: 'SubjectCode', label: 'Subject', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    { id: 'ClassSection', label: 'Class Section', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'DayCode', label: 'Day', align: 'center', withSorting: false, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'Capacity', label: 'Capacity', align: 'center', withSorting: false, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'TimeStart', label: 'Time Start', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'TimeEnd', label: 'Time End', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'Room', label: 'Room', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'LecPercentageShare', label: 'Percentage Share', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'MergeClassCode', label: 'Merge', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },

    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '10%', hidden: false, disablePadding: false }
]

const StyledTableRow = withStyles(theme => ({
    root: {
        '&.Mui-selected, &.Mui-selected:hover': {
            backgroundColor: '#d1edff'
        }
    },
}))(TableRow)
const descendingComparator = (a, b, orderBy) => {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}


const getComparator = (order, orderBy) => {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function createData(name, calories, fat, carbs, protein, price) {
    return {
        name,
        calories,
        fat,
        carbs,
        protein,
        price,
        history: [
            { date: '2020-01-05', customerId: '11091700', amount: 3 },
            { date: '2020-01-02', customerId: 'Anonymous', amount: 1 },
        ],
    };
}

const EnhancedTableHead = (props) => {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{ maxWidth: '100%' }}>
                {/* <TableCell padding="checkbox">
                    <Checkbox
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={rowCount > 0 && numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ 'aria-label': 'Check All' }}
                    />
                </TableCell> */}
                {columns.map((column) => (
                    <TableCell
                        key={column.id}
                        align={column.align}
                        padding={column.disablePadding ? 'none' : 'default'}
                        sortDirection={orderBy === column.id ? order : false}
                        style={{ width: column.minWidth }}
                    >
                        {column.withSorting ? <TableSortLabel
                            active={orderBy === column.id}
                            direction={orderBy === column.id ? order : 'asc'}
                            onClick={createSortHandler(column.id)}
                        >
                            {column.label}
                            {orderBy === column.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === 'desc' ? '' : ''}
                                </span>
                            ) : null}
                        </TableSortLabel> : column.label}
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}



function Row(props) {
    const { row, mergeSubSchedFaculty, subSelect, handleClassSectionChange,
        classSectionSelect, roomSelect, onUpdateSubSchedDetail, classCodeSelect,
        onHandleDeleteSched, onDeleteLabHandler } = props;
    const classes = useRowStyles();
    const { state: { auth } } = useAppContext()

    const [isOpenDelete, setIsOpenDelete] = useState(false)
    const [selectedRowId, setSelectedRowId] = useState(null)
    const [selectedRowIdLab, setSelectedRowIdLab] = useState(null)
    const [isUpdating, setIsUpdatating] = useState(false)
    const [addTimeCount, setAddTimeCount] = useState(0)
    const [addTimeCountLab, setAddTimeCountLab] = useState(0)


    const initialState = {
        subjectScheduleDetailId: row.SubjectScheduleDetailId,
        subjectScheduleDetailListId: row.SubjectScheduleDetailListId,
        subjectScheduleId: row.SubjectScheduleId,
        classCode: row.ClassCode,
        subjectId: row.SubjectId,
        classSectionId: row.ClassSectionId,
        capacity: row.Capacity,
        schoolYear: row.SchoolYear,
        semesterId: row.SemesterId,
        timeStart: row.TimeStart,
        timeEnd: row.TimeEnd,
        labTimeStart: row.LabTimeStart,
        labTimeEnd: row.LabTimeEnd,
        dayCode: row.DayCode,
        roomId: row.RoomId,
        labRoomId: row.LabRoomId,
        labDayCode: row.LabDayCode,
        lecPercentageShare: row.LecPercentageShare,
        labPercentageShare: row.LabPercentageShare,
        mergeSubjectScheduleDetailId: row.MergeSubjectScheduleDetailId,
        mergeStatus: 'isAll'
    }


    const [dynamicData, setDynamicData] = useState(initialState)
    const [data, setData] = useState(initialState)
    const [switchState, setSwitchState] = useState({
        IsActive: row.schedules.length > 0 ? row.schedules[0].LabTimeStart == null ? false : true : false
    });


    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    const convertTime = (timeString) => {
        const timeString12hr = new Date('1970-01-01T' + timeString + 'Z')
            .toLocaleTimeString('en-US',
                { timeZone: 'UTC', hour12: true, hour: 'numeric', minute: 'numeric' }
            );

        return timeString12hr;
    };

    const onHandleCloseDeleteConfirmation = () => {
        setIsOpenDelete(false)
    }

    const onHandleOpenDeleteConfirmation = () => {
        setIsOpenDelete(true)
    }

    const onDeleteSubSchedDetl = (id1, id2) => {
        props.handleDelete(id1, id2)
        onHandleCloseDeleteConfirmation()


    }

    const handleOptionChange = (event) => {
        console.log(event.target.value)
        setData((prevData) => {
            return { ...prevData, mergeStatus: event.target.value }
        })
    };

    const onHandleEdit = (yearLevelId, sy, courseId) => {
        props.handleOpenSubSchedDetail(row.SubjectScheduleDetailId, yearLevelId, sy, courseId)

    }

    const handleEdit = (id, classSectionId) => {
        setSelectedRowId(id)
        handleClassSectionChange(classSectionId)
    }

    const handleEditLab = (id, classSectionId) => {
        // alert(id)
        setSelectedRowIdLab(id)
        handleClassSectionChange(classSectionId)
    }


    const selectHandleChange = name => event => {
        if (name == 'classSectionId') {
            handleClassSectionChange(event)
            setData({ ...data, [name]: event });

        } else if (name == 'mergeSubjectScheduleDetailId') {
            setData({ ...data, [name]: event, isCheckConflict: 0 });
        } else {
            console.log(event)
            setData({ ...data, [name]: event });
        }

    }

    const handleChange = name => event => {
        // alert(event)
        if (name == 'timeStart' || name == 'timeEnd' || name == 'roomId' || name == 'labTimeStart' || name == 'labTimeEnd' || name == 'labRoomId') {
            setData({ ...data, [name]: event.target.value, isCheckConflict: 1 });
        } else {
            setData({ ...data, [name]: event.target.value, isCheckConflict: 0 });
        }

    }

    const onSave = () => {
        console.log(data)
        console.log(dynamicData)
        setIsUpdatating(true)
        if (dynamicData.length > 0) {
            // alert('test1')
            onUpdateSubSchedDetail(dynamicData)
                .then(res => {
                    setIsUpdatating(false)
                    setSelectedRowId(null)
                    setAddTimeCount(0)
                    setData((prevData) => {
                        return { ...prevData, mergeSubjectScheduleDetailId: null }
                    })
                })
        } else {
            // alert('test2')
            onUpdateSubSchedDetail(data)
                .then(res => {
                    setIsUpdatating(false)
                    setSelectedRowId(null)
                    setAddTimeCount(0)
                    setData((prevData) => {
                        return { ...prevData, mergeSubjectScheduleDetailId: null }
                    })
                })
        }


    }

    const onSaveLab = () => {
        console.log(data)
        console.log(dynamicData)
        setIsUpdatating(true)
        if (dynamicData.length > 0) {

            onUpdateSubSchedDetail(dynamicData)
                .then(res => {
                    setIsUpdatating(false)
                    setSelectedRowIdLab(null)
                    setAddTimeCountLab(0)
                })
        } else {

            console.log({ ...data, isLab: 1 })
            onUpdateSubSchedDetail({ ...data, isLab: 1 })
                .then(res => {
                    setIsUpdatating(false)
                    setSelectedRowIdLab(null)
                    setAddTimeCountLab(0)
                })
        }


    }

    const onDeleteLab = () => {
        setIsUpdatating(true)
        onDeleteLabHandler({ ...data, labTimeStart: null, labTimeEnd: null, labDayCode: null, labRoomId: null })
            .then(res => {
                setIsUpdatating(false)
                setSelectedRowIdLab(null)
            })
    }


    const DeleteModal = (props) => {

        return (
            <Modal
                open={props.isOpen}
                onClose={props.handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <Box className="card p -5" style={style}>
                    <div className="card-header"> <h3>Delete Confirmation</h3> </div>
                    <div className="card-body">

                        <p className="card-text">Are you sure you want to delete this Subject Schedule?</p>
                        <button onClick={() => { props.handleDelete(props.id1, props.id2) }} className="btn btn-danger mr-3">Yes</button>
                        <button onClick={() => { props.handleClose(props.id) }} className="btn btn-secondary ">No</button>

                    </div>
                </Box>


            </Modal>
        )
    }


    const onAddTime = () => {
        setAddTimeCount((prevData) => {
            console.log(prevData)
            return prevData + 1
        })
    }

    const onAddTimeLab = () => {
        setAddTimeCountLab((prevData) => {
            console.log(prevData)
            return prevData + 1
        })
    }

    useEffect(() => {
        setSwitchState((prevData) => {
            return { IsActive: row.schedules.length > 0 ? row.schedules[0].LabTimeStart == null ? false : true : false }
        })



        if (row.schedules.length > 0) {
            let values = {}
            for (let i = 0; i < row.schedules.length; i++) {
                values[`timeStart_${i}`] = row.schedules[i].TimeStart
                values[`timeEnd_${i}`] = row.schedules[i].TimeEnd
                values[`subjectScheduleDetailListId_${i}`] = row.schedules[i].SubjectScheduleDetailListId
                values[`roomId_${i}`] = row.schedules[i].RoomId
                values[`dayCode_${i}`] = row.schedules[i].DayCode
            }
            setData((prevData) => {
                return {
                    subjectScheduleDetailId: row.SubjectScheduleDetailId,
                    subjectScheduleDetailListId: row.SubjectScheduleDetailListId,
                    subjectScheduleId: row.SubjectScheduleId,
                    classCode: row.ClassCode,
                    subjectId: row.SubjectId,
                    classSectionId: row.ClassSectionId,
                    capacity: row.Capacity,
                    schoolYear: row.SchoolYear,
                    semesterId: row.SemesterId,
                    timeStart: row.TimeStart,
                    timeEnd: row.TimeEnd,
                    labTimeStart: row.LabTimeStart,
                    labTimeEnd: row.LabTimeEnd,
                    dayCode: row.DayCode,
                    roomId: row.RoomId,
                    labRoomId: row.LabRoomId,
                    labDayCode: row.LabDayCode,
                    lecPercentageShare: row.LecPercentageShare,
                    labPercentageShare: row.LabPercentageShare,
                    mergeSubjectScheduleDetailId: row.MergeSubjectScheduleDetailId, ...values
                }
            })
        } else {

            setData({
                subjectScheduleDetailId: row.SubjectScheduleDetailId,
                subjectScheduleDetailListId: row.SubjectScheduleDetailListId,
                subjectScheduleId: row.SubjectScheduleId,
                classCode: row.ClassCode,
                subjectId: row.SubjectId,
                classSectionId: row.ClassSectionId,
                capacity: row.Capacity,
                schoolYear: row.SchoolYear,
                semesterId: row.SemesterId,
                timeStart: row.TimeStart,
                timeEnd: row.TimeEnd,
                labTimeStart: row.LabTimeStart,
                labTimeEnd: row.LabTimeEnd,
                dayCode: row.DayCode,
                roomId: row.RoomId,
                labRoomId: row.LabRoomId,
                labDayCode: row.LabDayCode,
                lecPercentageShare: row.LecPercentageShare,
                labPercentageShare: row.LabPercentageShare,
                mergeSubjectScheduleDetailId: row.MergeSubjectScheduleDetailId
            })
        }
    }, [row])


    useEffect(() => {
        console.log(data)
        console.log('run')
        console.log(data.hasOwnProperty(`timeStart_${1}`))
        const filteredObject = Object.keys(data)
            .filter(key => !/_\d+$/.test(key))
            .reduce((result, key) => {
                result[key] = data[key];
                return result;
            }, {});
        const dynamicScheduleArray = [filteredObject];

        // Check for properties changes on schedules
        for (let i = 1; data.hasOwnProperty(`timeStart_${i}`); i++) {
            console.log(data)

            dynamicScheduleArray.push({
                "subjectScheduleDetailId": data.subjectScheduleDetailId,
                "subjectScheduleDetailListId": data[`subjectScheduleDetailListId_${i}`],
                "subjectScheduleId": data.subjectScheduleId,
                "classSectionId": data.classSectionId,
                "timeStart": data[`timeStart_${i}`],
                "timeEnd": data[`timeEnd_${i}`],
                "roomId": data[`roomId_${i}`],
                "dayCode": data[`dayCode_${i}`],
            });
        }
        // Check for properties newly added on schedules LEC
        for (let i = 0; data.hasOwnProperty(`timeStartNew_${i}`); i++) {
            console.log(data)

            dynamicScheduleArray.push({
                "subjectScheduleDetailId": data.subjectScheduleDetailId,
                "subjectScheduleDetailListId": 0,
                "subjectScheduleId": data.subjectScheduleId,
                "classSectionId": data.classSectionId,
                "dayCode": data[`dayCodeNew_${i}`],
                "timeStart": data[`timeStartNew_${i}`],
                "timeEnd": data[`timeEndNew_${i}`],
                "roomId": data[`roomIdNew_${i}`],
            });
        }

        // Check for properties newly added on schedules LAB
        for (let i = 0; data.hasOwnProperty(`labTimeStartNew_${i}`); i++) {
            console.log(data)

            dynamicScheduleArray.push({
                "subjectScheduleDetailId": data.subjectScheduleDetailId,
                "subjectScheduleDetailListId": 0,
                "subjectScheduleId": data.subjectScheduleId,
                "classSectionId": data.classSectionId,
                "labDayCode": data[`labDayCodeNew_${i}`],
                "labTimeStart": data[`labTimeStartNew_${i}`],
                "labTimeEnd": data[`labTimeEndNew_${i}`],
                "labRoomId": data[`labRoomIdNew_${i}`],
                "isLab": 1,
            });
        }

        // If there are no dynamic properties, add a default object
        if (dynamicScheduleArray.length === 0) {
            dynamicScheduleArray.push(data);
        }
        console.log(dynamicScheduleArray)
        setDynamicData((prevData) => {
            return dynamicScheduleArray
        });
    }, [data]);


    // add row for scheduling


    const AddTimeRow = ({ addTimeCount }) => {
        // Create an array with the length of addTimeCount
        const timeArray = Array.from({ length: addTimeCount }, (_, index) => index);
        console.log(timeArray)
        console.log(addTimeCount)
        return (
            <>
                {addTimeCount > 0 && (
                    // Use map to loop through the array and render JSX
                    timeArray.map((index) => (
                        <TableRow className='bg-light'>
                            <TableCell align="center">{row.ClassCode}</TableCell>
                            <TableCell align="center">Lec</TableCell>
                            <TableCell align="center">{row.SubjectCode}</TableCell>
                            <TableCell align="center">{row.ClassSection}</TableCell>
                            <TableCell align="center">
                                <input
                                    style={{
                                        flex: 1,
                                        padding: '0.375rem 0.75rem',
                                        fontSize: '1rem',
                                        lineHeight: '1.5',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%'
                                    }}
                                    onChange={(event) => {
                                        const input = event.target.value;
                                        const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                        const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                        event.target.value = uniqueFilteredInput;
                                        setData({ ...data, [`dayCodeNew_${index}`]: uniqueFilteredInput, isCheckConflict: 1 });

                                    }}
                                    type="text"
                                    name="dayCode"
                                    placeholder="Day Code"
                                    autoComplete="off"
                                    defaultValue={data[`dayCodeNew_${index}`]}
                                />
                            </  TableCell>
                            <TableCell align="center">{row.Capacity}</TableCell>
                            <TableCell align="center" className='py-0'>
                                <input
                                    style={{
                                        padding: '2.1',
                                        lineHeight: '2.2',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%',

                                    }}
                                    onChange={handleChange(`timeStartNew_${index}`)}
                                    type="time"
                                    name={`timeStartNew_${index}`}
                                    placeholder="Time Start"
                                    defaultValue={data[`timeStartNew_${index}`]}

                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}


                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />
                                </datalist></TableCell>
                            <TableCell align="center" className='py-0' >
                                <input
                                    style={{
                                        padding: '2.1',

                                        lineHeight: '2.2',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%',

                                    }}
                                    className="text-center"
                                    onChange={handleChange(`timeEndNew_${index}`)}
                                    type="time"
                                    name={`timeEndNew_${index}`}
                                    placeholder="Time End"
                                    defaultValue={data[`timeEndNew_${index}`]}

                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}

                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />
                                </datalist>
                            </TableCell>
                            <TableCell align="center" > <IMSelect
                                data={roomSelect}
                                onHandleChange={selectHandleChange(`roomIdNew_${index}`)}
                                refClassContainer="form-control "
                                name={`roomIdNew_${index}`}
                                isRequired={false}
                                withLabel={false}
                                label=""
                                placeHolder="Select Room"
                                forwardRef={() => { }}
                                selectedId={data[`roomIdNew_${index}`]}
                                refClassName={``}
                                withDescription={false}
                                // description={`Please select your gender.`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]}
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}

                            /></TableCell>
                            <TableCell align="center">{row.LabPercentageShare == null ? '100' : row.LecPercentageShare}</TableCell>
                            {/* <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell> */}
                        </TableRow>
                    ))
                )}
            </>
        );
    };




    const AddTimeRowLab = ({ addTimeCount }) => {
        // Create an array with the length of addTimeCount
        const timeArray = Array.from({ length: addTimeCount }, (_, index) => index);
        console.log(timeArray)
        console.log(addTimeCount)
        return (
            <>
                {addTimeCountLab > 0 && (
                    // Use map to loop through the array and render JSX
                    timeArray.map((index) => (
                        <TableRow className='' style={{ backgroundColor: '#c0ddeb' }}>
                            <TableCell align="center">{row.ClassCode}</TableCell>
                            <TableCell align="center">Lab</TableCell>
                            <TableCell align="center">{row.SubjectCode}</TableCell>
                            <TableCell align="center">{row.ClassSection}</TableCell>
                            <TableCell align="center">{row.schedules.length > 0 &&
                                <input

                                    onChange={handleChange(`labDayCodeNew_${index}`)}
                                    type="text"
                                    name={`labDayCodeNew_${index}`}
                                    placeholder=""
                                    defaultValue={data[`labDayCodeNew_${index}`]}
                                    className='form-control'
                                    autoComplete="off"


                                />
                            }</  TableCell>
                            <TableCell align="center">{row.Capacity}</TableCell>
                            <TableCell align="center" className='py-0'>
                                <input
                                    style={{
                                        padding: '2.1',
                                        lineHeight: '2.2',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%',

                                    }}
                                    onChange={handleChange(`labTimeStartNew_${index}`)}
                                    type="time"
                                    name={`labTimeStartNew_${index}`}
                                    placeholder="Time Start"
                                    defaultValue={data[`labTimeStartNew_${index}`]}

                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}


                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />
                                </datalist></TableCell>
                            <TableCell align="center" className='py-0' >
                                <input
                                    style={{
                                        padding: '2.1',

                                        lineHeight: '2.2',
                                        color: '#495057',
                                        backgroundColor: '#fff',
                                        backgroundClip: 'padding-box',
                                        border: '1px solid #ced4da',
                                        borderRadius: '0.25rem',
                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                        width: '100%',

                                    }}
                                    className="text-center"
                                    onChange={handleChange(`labTimeEndNew_${index}`)}
                                    type="time"
                                    name={`labTimeEndNew_${index}`}
                                    placeholder="Time End"
                                    defaultValue={data[`labTimeEndNew_${index}`]}

                                    autoComplete="off"
                                    list="time-options"
                                    onKeyDown={e => e.preventDefault()}

                                />

                                <datalist id="time-options">
                                    <option value="07:00" />
                                    <option value="07:15" />
                                    <option value="07:30" />
                                    <option value="07:45" />
                                    <option value="08:00" />
                                    <option value="08:15" />
                                    <option value="08:30" />
                                    <option value="08:45" />
                                    <option value="09:00" />
                                    <option value="09:15" />
                                    <option value="09:30" />
                                    <option value="09:45" />
                                    <option value="10:00" />
                                    <option value="10:15" />
                                    <option value="10:30" />
                                    <option value="10:45" />
                                    <option value="11:00" />
                                    <option value="11:15" />
                                    <option value="11:30" />
                                    <option value="11:45" />
                                    <option value="12:00" />
                                    <option value="12:15" />
                                    <option value="12:30" />
                                    <option value="12:45" />
                                    <option value="13:00" />
                                    <option value="13:15" />
                                    <option value="13:30" />
                                    <option value="13:45" />
                                    <option value="14:00" />
                                    <option value="14:15" />
                                    <option value="14:30" />
                                    <option value="14:45" />
                                    <option value="15:00" />
                                    <option value="15:15" />
                                    <option value="15:30" />
                                    <option value="15:45" />
                                    <option value="16:00" />
                                    <option value="16:15" />
                                    <option value="16:30" />
                                    <option value="16:45" />
                                    <option value="17:00" />
                                    <option value="17:15" />
                                    <option value="17:30" />
                                    <option value="17:45" />
                                    <option value="18:00" />
                                    <option value="18:15" />
                                    <option value="18:30" />
                                    <option value="18:45" />
                                    <option value="19:00" />
                                    <option value="19:15" />
                                    <option value="19:30" />
                                    <option value="19:45" />
                                    <option value="20:00" />
                                    <option value="20:15" />
                                    <option value="20:30" />
                                    <option value="20:45" />
                                    <option value="21:00" />
                                    <option value="21:15" />
                                    <option value="21:30" />
                                    <option value="21:45" />
                                </datalist>
                            </TableCell>
                            <TableCell align="center" > <IMSelect
                                data={roomSelect}
                                onHandleChange={selectHandleChange(`labRoomIdNew_${index}`)}
                                refClassContainer="form-control "
                                name={`labRoomIdNew_${index}`}
                                isRequired={false}
                                withLabel={false}
                                label=""
                                placeHolder="Select Room"
                                forwardRef={() => { }}
                                selectedId={data[`labRoomIdNew_${index}`]}
                                refClassName={``}
                                withDescription={false}
                                // description={`Please select your gender.`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[
                                ]}
                                field={{
                                    tableId: 'tableId',
                                    display: 'code'
                                }}

                            /></TableCell>
                            <TableCell align="center">{row.LabPercentageShare == null ? '0' : row.LabPercentageShare}</TableCell>
                            <TableCell align="center"></TableCell>
                            <TableCell align="center"></TableCell>
                        </TableRow>
                    ))
                )}
            </>
        );
    };




    console.log(row)
    console.log(row.schedules)
    console.log(data)
    console.log(switchState)
    console.log(selectedRowIdLab)
    console.log(row.schedules.length)
    console.log(dynamicData)
    console.log(addTimeCount)
    console.log(addTimeCountLab)

    return (
        <>
            {isOpenDelete == true &&
                <DeleteModal
                    handleDelete={onDeleteSubSchedDetl}
                    handleClose={onHandleCloseDeleteConfirmation}
                    isOpen={isOpenDelete}
                    id1={row.SubjectScheduleDetailId}
                    id2={row.schedules.length > 0 ? row.schedules[0].SubjectScheduleDetailListId : 0}
                />
            }
            {/* <React.Fragment> */}



            <TableRow className={selectedRowId == null ? classes.root : classes.root} style={{ maxWidth: '100%' }}>
                {
                    selectedRowId == null ?
                        <>
                            <TableCell align="center">{row.ClassCode}</TableCell>
                            <TableCell align="center">Lec</TableCell>
                            <TableCell align="center">{row.SubjectCode}</TableCell>
                            <TableCell align="center">{row.ClassSection}</TableCell>
                            <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].DayCode}</  TableCell>
                            <TableCell align="center">{row.Capacity}</TableCell>
                            <TableCell align="center">{row.schedules.length > 0 && convertTime(row.schedules[0].TimeStart)}</TableCell>
                            <TableCell align="center">{row.schedules.length > 0 && convertTime(row.schedules[0].TimeEnd)}</TableCell>
                            <TableCell align="center">{row.schedules.length > 0 && row.schedules[0].Room}</TableCell>
                            <TableCell align="center">{row.LabPercentageShare == null ? '100' : row.LecPercentageShare}</TableCell>
                            <TableCell align="center">{row.MergeClassCode}</TableCell>


                            <TableCell className='row' align={selectedRowIdLab == null ? "center" : "left"} >
                                {selectedRowIdLab == null
                                    ?
                                    <>
                                        {(row.IsFinal == 0 || auth.data.UserType == 'RGD') ?
                                            <>
                                                <button
                                                    className="col btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                                                    onClick={() => handleEdit(row.SubjectScheduleDetailId, row.ClassSectionId)}
                                                >
                                                    <i className="fa fa-pencil-alt text-white"></i>
                                                </button>
                                                <button
                                                    className="col btn btn-xs btn-icon btn-icon-xs btn-warning mr-1"
                                                    // onClick={() => { onHandleEdit(row.YearLevelId, row.SchoolYear, row.CourseId) }}
                                                    onClick={() => handleEditLab(row.SubjectScheduleDetailId, row.ClassSectionId)}
                                                >
                                                    <i className="fa fa-flask text-white"></i>
                                                </button>


                                                {row.IsNotDeletable == 0 ?
                                                    <button className="col btn btn-xs btn-icon btn-icon-xs btn-danger mr-1"
                                                        onClick={() => { onHandleOpenDeleteConfirmation() }}
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                    :
                                                    <button
                                                        disabled
                                                        className="col btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1">
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                }


                                            </>
                                            :

                                            <>
                                                <p>Finalized</p>
                                            </>


                                        }

                                    </>
                                    :
                                    <>


                                        <div className="form-check">
                                            <input
                                                type="checkbox"
                                                className="form-check-input"
                                                id="exampleCheck1"
                                                onChange={switchHandleChange('IsActive')}
                                                defaultChecked={switchState.IsActive} />
                                            <label className="form-check-label" for="exampleCheck1">With Lab</label>
                                            {switchState.IsActive == true ?
                                                <>
                                                    <button onClick={() => { onAddTimeLab() }} className="btn btn-xs btn-icon btn-icon-xs ml-3  mr-1" style={{ backgroundColor: '#FF5F1F' }}>
                                                        <i className="fa fa-clock text-white"></i>
                                                    </button>
                                                </>
                                                :
                                                <>
                                                    <button disabled className="btn btn-xs btn-icon btn-icon-xs btn-secondary ml-3  mr-1" >
                                                        <i className="fa fa-clock text-white"></i>
                                                    </button>
                                                </>
                                            }

                                        </div>
                                        <section className="row ">
                                            <button
                                                className="col-12 btn btn-xs btn-icon btn-icon-xs btn-success mt-5"
                                                // onClick={() => { onHandleEdit(row.YearLevelId, row.SchoolYear, row.CourseId) }}
                                                onClick={() => onSaveLab()}
                                            >

                                                Save


                                            </button>


                                            {/* <button onClick={() => { setSelectedRowIdLab(null); setAddTimeCountLab(0); }} className="col-12btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1" >
                                                <i className="fa fa-window-close text-white"></i>
                                            </button> */}
                                            <button
                                                onClick={() => {
                                                    setSwitchState((prevData) => {
                                                        return { IsActive: row.schedules.length > 0 ? row.schedules[0].LabTimeStart == null ? false : true : false }
                                                    })
                                                    setSelectedRowIdLab(null);
                                                    setAddTimeCountLab(0);
                                                }}
                                                className="col-12 btn btn-xs btn-icon btn-icon-xs btn-secondary mt-1"
                                            >
                                                Cancel
                                            </button>

                                            {row.schedules.length > 0 && (row.schedules[0].LabTimeStart != null || row.schedules[0].LabTimeEnd != null) && !switchState.IsActive && (
                                                <button onClick={onDeleteLab} className="col-12 btn btn-xs btn-icon btn-icon-xs btn-danger mt-1">Delete Lab</button>
                                            )}
                                        </section>





                                    </>
                                }


                            </TableCell>
                        </>
                        :
                        <>
                            {isUpdating == false ?
                                <>
                                    <TableCell align="center" >{row.ClassCode}</TableCell>
                                    <TableCell align="center" >Lec</TableCell>

                                    <TableCell align="center" className='py-0 px-2'>

                                        <IMSelect
                                            data={subSelect}
                                            onHandleChange={selectHandleChange('subjectId')}
                                            refClassContainer=""
                                            name="subjectId"
                                            isRequired={true}
                                            withLabel={false}
                                            label=""
                                            placeHolder="Subject"
                                            forwardRef={() => { }}
                                            selectedId={data.subjectId === null ? row.SubjectId : data.subjectId}
                                            refClassName={` `}
                                            withDescription={false}
                                            // description={`Please select your gender.`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]}
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}

                                        />

                                    </TableCell>
                                    <TableCell align="center" className='py-0 px-2'>
                                        <IMSelect
                                            data={classSectionSelect}
                                            onHandleChange={selectHandleChange('classSectionId')}
                                            refClassContainer=""
                                            name="classSectionId"
                                            isRequired={true}
                                            withLabel={false}
                                            label=""
                                            placeHolder="Class Section"
                                            forwardRef={() => { }}
                                            selectedId={data.classSectionId === null ? row.ClassSectionId : data.classSectionId}
                                            refClassName={``}
                                            withDescription={false}
                                            // description={`Please select your gender.`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]}
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}

                                        />
                                    </TableCell>
                                    <TableCell align="center" className='py-0 px-2' >
                                        <input
                                            style={{
                                                flex: 1,
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem',
                                                lineHeight: '1.5',
                                                color: '#495057',
                                                backgroundColor: '#fff',
                                                backgroundClip: 'padding-box',
                                                border: '1px solid #ced4da',
                                                borderRadius: '0.25rem',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                width: '100%'
                                            }}
                                            onChange={(event) => {
                                                const input = event.target.value;
                                                const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                                const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                                event.target.value = uniqueFilteredInput;
                                                setData({ ...data, 'dayCode': uniqueFilteredInput, isCheckConflict: 1 });

                                            }}
                                            type="text"
                                            name="dayCode"
                                            placeholder="Day Code"
                                            autoComplete="off"
                                            defaultValue={row.DayCode}
                                        />
                                    </TableCell>
                                    <TableCell align="center" >
                                        <input
                                            style={{
                                                flex: 1,
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem',
                                                lineHeight: '1.5',
                                                color: '#495057',
                                                backgroundColor: '#fff',
                                                backgroundClip: 'padding-box',
                                                border: '1px solid #ced4da',
                                                borderRadius: '0.25rem',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                width: '100%'
                                            }}
                                            onChange={handleChange('capacity')}
                                            type="number"
                                            name="capacity"
                                            placeholder="Capacity"
                                            defaultValue={row.Capacity === undefined ? "" : row.Capacity}

                                            autoComplete="off"
                                        />
                                    </TableCell>
                                    <TableCell align="center" className='py-0'> <input
                                        style={{
                                            padding: '2.1',
                                            lineHeight: '2.2',
                                            color: '#495057',
                                            backgroundColor: '#fff',
                                            backgroundClip: 'padding-box',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem',
                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                            width: '100%',

                                        }}
                                        onChange={handleChange('timeStart')}
                                        type="time"
                                        name="timeStart"
                                        placeholder="Time Start"
                                        defaultValue={row.TimeStart === undefined ? "" : row.TimeStart}

                                        autoComplete="off"
                                        list="time-options"
                                        onKeyDown={e => e.preventDefault()}


                                    />

                                        <datalist id="time-options">
                                            <option value="07:00" />
                                            <option value="07:15" />
                                            <option value="07:30" />
                                            <option value="07:45" />
                                            <option value="08:00" />
                                            <option value="08:15" />
                                            <option value="08:30" />
                                            <option value="08:45" />
                                            <option value="09:00" />
                                            <option value="09:15" />
                                            <option value="09:30" />
                                            <option value="09:45" />
                                            <option value="10:00" />
                                            <option value="10:15" />
                                            <option value="10:30" />
                                            <option value="10:45" />
                                            <option value="11:00" />
                                            <option value="11:15" />
                                            <option value="11:30" />
                                            <option value="11:45" />
                                            <option value="12:00" />
                                            <option value="12:15" />
                                            <option value="12:30" />
                                            <option value="12:45" />
                                            <option value="13:00" />
                                            <option value="13:15" />
                                            <option value="13:30" />
                                            <option value="13:45" />
                                            <option value="14:00" />
                                            <option value="14:15" />
                                            <option value="14:30" />
                                            <option value="14:45" />
                                            <option value="15:00" />
                                            <option value="15:15" />
                                            <option value="15:30" />
                                            <option value="15:45" />
                                            <option value="16:00" />
                                            <option value="16:15" />
                                            <option value="16:30" />
                                            <option value="16:45" />
                                            <option value="17:00" />
                                            <option value="17:15" />
                                            <option value="17:30" />
                                            <option value="17:45" />
                                            <option value="18:00" />
                                            <option value="18:15" />
                                            <option value="18:30" />
                                            <option value="18:45" />
                                            <option value="19:00" />
                                            <option value="19:15" />
                                            <option value="19:30" />
                                            <option value="19:45" />
                                            <option value="20:00" />
                                            <option value="20:15" />
                                            <option value="20:30" />
                                            <option value="20:45" />
                                            <option value="21:00" />
                                            <option value="21:15" />
                                            <option value="21:30" />
                                            <option value="21:45" />
                                        </datalist></TableCell>
                                    <TableCell align="center" className='py-0' >
                                        <input
                                            style={{
                                                padding: '2.1',

                                                lineHeight: '2.2',
                                                color: '#495057',
                                                backgroundColor: '#fff',
                                                backgroundClip: 'padding-box',
                                                border: '1px solid #ced4da',
                                                borderRadius: '0.25rem',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                width: '100%',

                                            }}
                                            className="text-center"
                                            onChange={handleChange('timeEnd')}
                                            type="time"
                                            name="timeEnd"
                                            placeholder="Time End"
                                            defaultValue={row.TimeEnd === undefined ? "" : row.TimeEnd}

                                            autoComplete="off"
                                            list="time-options"
                                            onKeyDown={e => e.preventDefault()}

                                        />

                                        <datalist id="time-options">
                                            <option value="07:00" />
                                            <option value="07:15" />
                                            <option value="07:30" />
                                            <option value="07:45" />
                                            <option value="08:00" />
                                            <option value="08:15" />
                                            <option value="08:30" />
                                            <option value="08:45" />
                                            <option value="09:00" />
                                            <option value="09:15" />
                                            <option value="09:30" />
                                            <option value="09:45" />
                                            <option value="10:00" />
                                            <option value="10:15" />
                                            <option value="10:30" />
                                            <option value="10:45" />
                                            <option value="11:00" />
                                            <option value="11:15" />
                                            <option value="11:30" />
                                            <option value="11:45" />
                                            <option value="12:00" />
                                            <option value="12:15" />
                                            <option value="12:30" />
                                            <option value="12:45" />
                                            <option value="13:00" />
                                            <option value="13:15" />
                                            <option value="13:30" />
                                            <option value="13:45" />
                                            <option value="14:00" />
                                            <option value="14:15" />
                                            <option value="14:30" />
                                            <option value="14:45" />
                                            <option value="15:00" />
                                            <option value="15:15" />
                                            <option value="15:30" />
                                            <option value="15:45" />
                                            <option value="16:00" />
                                            <option value="16:15" />
                                            <option value="16:30" />
                                            <option value="16:45" />
                                            <option value="17:00" />
                                            <option value="17:15" />
                                            <option value="17:30" />
                                            <option value="17:45" />
                                            <option value="18:00" />
                                            <option value="18:15" />
                                            <option value="18:30" />
                                            <option value="18:45" />
                                            <option value="19:00" />
                                            <option value="19:15" />
                                            <option value="19:30" />
                                            <option value="19:45" />
                                            <option value="20:00" />
                                            <option value="20:15" />
                                            <option value="20:30" />
                                            <option value="20:45" />
                                            <option value="21:00" />
                                            <option value="21:15" />
                                            <option value="21:30" />
                                            <option value="21:45" />
                                        </datalist>
                                    </TableCell>
                                    <TableCell align="center" > <IMSelect
                                        data={roomSelect}
                                        onHandleChange={selectHandleChange('roomId')}
                                        refClassContainer="form-control "
                                        name="roomId"
                                        isRequired={false}
                                        withLabel={false}
                                        label=""
                                        placeHolder="Select Room"
                                        forwardRef={() => { }}
                                        selectedId={data.roomId === null ? row.RoomId : data.roomId}
                                        refClassName={``}
                                        withDescription={false}
                                        // description={`Please select your gender.`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[
                                        ]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}

                                    /></TableCell>
                                    <TableCell align="center" className='py-0 px-2'></TableCell>
                                    <TableCell align="center" className='py-0 px-2'>
                                        <IMSelect
                                            data={classCodeSelect}
                                            onHandleChange={selectHandleChange('mergeSubjectScheduleDetailId')}
                                            refClassContainer="form-control "
                                            name="mergeSubjectScheduleDetailId"
                                            isRequired={false}
                                            withLabel={false}
                                            label=""
                                            placeHolder="Select Class Code"
                                            forwardRef={() => { }}
                                            selectedId={data.mergeSubjectScheduleDetailId}
                                            refClassName={``}
                                            withDescription={false}
                                            // description={`Please select your gender.`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[
                                            ]}
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}

                                        />
                                        <section className='mt-1'>

                                            <label className='mr-5'>
                                                <input
                                                    type="radio"
                                                    value="isLec"
                                                    checked={data.mergeStatus === 'isLec'}
                                                    onChange={handleOptionChange}
                                                />
                                                &nbsp;Lec
                                            </label>

                                            <label className='mr-5'>
                                                <input
                                                    type="radio"
                                                    value="isLab"
                                                    checked={data.mergeStatus === 'isLab'}
                                                    onChange={handleOptionChange}
                                                />
                                                &nbsp;Lab
                                            </label>
                                            <label className='mr-5'>
                                                <input
                                                    type="radio"
                                                    value="isAll"
                                                    checked={data.mergeStatus === 'isAll'}
                                                    onChange={handleOptionChange}
                                                />
                                                &nbsp;Lec / Lab
                                            </label>

                                        </section>

                                    </TableCell>

                                </>

                                :
                                <>
                                    <TableCell align="center">
                                        <div className="spinner-border" role="status">
                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                    <TableCell align="center"></TableCell>
                                </>

                            }


                            <TableCell align="center" >
                                {isUpdating == false ?
                                    <>
                                        <button
                                            className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1"
                                            // onClick={() => { onHandleEdit(row.YearLevelId, row.SchoolYear, row.CourseId) }}
                                            onClick={() => onSave()}
                                        ><i className="fa fa-save text-white"></i>
                                        </button>

                                        <button onClick={() => { onAddTime() }} className="btn btn-xs btn-icon btn-icon-xs  mr-1" style={{ backgroundColor: '#FF5F1F' }}>
                                            <i className="fa fa-clock text-white"></i>
                                        </button>

                                        <button onClick={() => { setSelectedRowId(null); setAddTimeCount(0) }} className="btn btn-xs btn-icon btn-icon-xs btn-secondary mr-1" >
                                            <i className="fa fa-window-close text-white"></i>
                                        </button>
                                    </>
                                    :
                                    <>
                                        <button className="btn btn-primary" type="button" disabled>
                                            <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            <span className="sr-only">Loading...</span>
                                        </button>
                                    </>


                                }


                            </TableCell>


                        </>
                }




            </TableRow>
            {row.schedules.length > 1 && isUpdating == false &&
                <>
                    {row.schedules.slice(1).map((sched, index) => {
                        console.log(index)
                        return (
                            <>
                                {selectedRowId == null ?
                                    <>
                                        {sched.TimeStart != null &&
                                            <TableRow className='bg-light' key={index}>
                                                <TableCell align="center">{row.ClassCode}</TableCell>
                                                <TableCell align="center">Lec</TableCell>
                                                <TableCell align="center">{row.SubjectCode}</TableCell>
                                                <TableCell align="center">{row.ClassSection}</TableCell>
                                                <TableCell align="center">{sched.DayCode}</  TableCell>
                                                <TableCell align="center">{row.Capacity}</TableCell>
                                                <TableCell align="center">{convertTime(sched.TimeStart)}</TableCell>
                                                <TableCell align="center">{convertTime(sched.TimeEnd)}</TableCell>
                                                <TableCell align="center">{sched.Room}</TableCell>
                                                <TableCell align="center">{row.LabPercentageShare == null ? '100' : row.LecPercentageShare}</TableCell>
                                                {/* <TableCell align="center"></TableCell>
                                        <TableCell align="center"></TableCell> */}
                                            </TableRow>
                                        }

                                    </>
                                    :
                                    <>
                                        {sched.TimeStart != null &&
                                            <TableRow className='bg-light'>
                                                <TableCell align="center">{row.ClassCode}</TableCell>
                                                <TableCell align="center">Lec</TableCell>
                                                <TableCell align="center">{row.SubjectCode}</TableCell>
                                                <TableCell align="center">{row.ClassSection}</TableCell>
                                                <TableCell align="center">
                                                    <input
                                                        style={{
                                                            flex: 1,
                                                            padding: '0.375rem 0.75rem',
                                                            fontSize: '1rem',
                                                            lineHeight: '1.5',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                            width: '100%'
                                                        }}
                                                        onChange={(event) => {
                                                            const input = event.target.value;
                                                            const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                                            const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                                            event.target.value = uniqueFilteredInput;
                                                            setData({ ...data, [`dayCode_${index + 1}`]: uniqueFilteredInput, isCheckConflict: 1 });

                                                        }}
                                                        type="text"
                                                        name="dayCode"
                                                        placeholder="Day Code"
                                                        autoComplete="off"
                                                        defaultValue={data[`dayCode_${index + 1}`] == undefined || data[`dayCode_${index + 1}`] == null ? row.DayCode : data[`dayCode_${index + 1}`]}
                                                    />
                                                </  TableCell>
                                                <TableCell align="center">{row.Capacity}</TableCell>
                                                <TableCell align="center" className='py-0'>
                                                    <input
                                                        style={{
                                                            padding: '2.1',
                                                            lineHeight: '2.2',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                            width: '100%',

                                                        }}
                                                        onChange={handleChange(`timeStart_${index + 1}`)}
                                                        type="time"
                                                        name={`timeStart_${index + 1}`}
                                                        placeholder="Time Start"
                                                        defaultValue={sched.TimeStart}

                                                        autoComplete="off"
                                                        list="time-options"
                                                        onKeyDown={e => e.preventDefault()}


                                                    />

                                                    <datalist id="time-options">
                                                        <option value="07:00" />
                                                        <option value="07:15" />
                                                        <option value="07:30" />
                                                        <option value="07:45" />
                                                        <option value="08:00" />
                                                        <option value="08:15" />
                                                        <option value="08:30" />
                                                        <option value="08:45" />
                                                        <option value="09:00" />
                                                        <option value="09:15" />
                                                        <option value="09:30" />
                                                        <option value="09:45" />
                                                        <option value="10:00" />
                                                        <option value="10:15" />
                                                        <option value="10:30" />
                                                        <option value="10:45" />
                                                        <option value="11:00" />
                                                        <option value="11:15" />
                                                        <option value="11:30" />
                                                        <option value="11:45" />
                                                        <option value="12:00" />
                                                        <option value="12:15" />
                                                        <option value="12:30" />
                                                        <option value="12:45" />
                                                        <option value="13:00" />
                                                        <option value="13:15" />
                                                        <option value="13:30" />
                                                        <option value="13:45" />
                                                        <option value="14:00" />
                                                        <option value="14:15" />
                                                        <option value="14:30" />
                                                        <option value="14:45" />
                                                        <option value="15:00" />
                                                        <option value="15:15" />
                                                        <option value="15:30" />
                                                        <option value="15:45" />
                                                        <option value="16:00" />
                                                        <option value="16:15" />
                                                        <option value="16:30" />
                                                        <option value="16:45" />
                                                        <option value="17:00" />
                                                        <option value="17:15" />
                                                        <option value="17:30" />
                                                        <option value="17:45" />
                                                        <option value="18:00" />
                                                        <option value="18:15" />
                                                        <option value="18:30" />
                                                        <option value="18:45" />
                                                        <option value="19:00" />
                                                        <option value="19:15" />
                                                        <option value="19:30" />
                                                        <option value="19:45" />
                                                        <option value="20:00" />
                                                        <option value="20:15" />
                                                        <option value="20:30" />
                                                        <option value="20:45" />
                                                        <option value="21:00" />
                                                        <option value="21:15" />
                                                        <option value="21:30" />
                                                        <option value="21:45" />
                                                    </datalist></TableCell>
                                                <TableCell align="center" className='py-0' >
                                                    <input
                                                        style={{
                                                            padding: '2.1',

                                                            lineHeight: '2.2',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                            width: '100%',

                                                        }}
                                                        className="text-center"
                                                        onChange={handleChange(`timeEnd_${index + 1}`)}
                                                        type="time"
                                                        name={`timeEnd_${index + 1}`}
                                                        placeholder="Time End"
                                                        defaultValue={sched.TimeEnd}

                                                        autoComplete="off"
                                                        list="time-options"
                                                        onKeyDown={e => e.preventDefault()}

                                                    />

                                                    <datalist id="time-options">
                                                        <option value="07:00" />
                                                        <option value="07:15" />
                                                        <option value="07:30" />
                                                        <option value="07:45" />
                                                        <option value="08:00" />
                                                        <option value="08:15" />
                                                        <option value="08:30" />
                                                        <option value="08:45" />
                                                        <option value="09:00" />
                                                        <option value="09:15" />
                                                        <option value="09:30" />
                                                        <option value="09:45" />
                                                        <option value="10:00" />
                                                        <option value="10:15" />
                                                        <option value="10:30" />
                                                        <option value="10:45" />
                                                        <option value="11:00" />
                                                        <option value="11:15" />
                                                        <option value="11:30" />
                                                        <option value="11:45" />
                                                        <option value="12:00" />
                                                        <option value="12:15" />
                                                        <option value="12:30" />
                                                        <option value="12:45" />
                                                        <option value="13:00" />
                                                        <option value="13:15" />
                                                        <option value="13:30" />
                                                        <option value="13:45" />
                                                        <option value="14:00" />
                                                        <option value="14:15" />
                                                        <option value="14:30" />
                                                        <option value="14:45" />
                                                        <option value="15:00" />
                                                        <option value="15:15" />
                                                        <option value="15:30" />
                                                        <option value="15:45" />
                                                        <option value="16:00" />
                                                        <option value="16:15" />
                                                        <option value="16:30" />
                                                        <option value="16:45" />
                                                        <option value="17:00" />
                                                        <option value="17:15" />
                                                        <option value="17:30" />
                                                        <option value="17:45" />
                                                        <option value="18:00" />
                                                        <option value="18:15" />
                                                        <option value="18:30" />
                                                        <option value="18:45" />
                                                        <option value="19:00" />
                                                        <option value="19:15" />
                                                        <option value="19:30" />
                                                        <option value="19:45" />
                                                        <option value="20:00" />
                                                        <option value="20:15" />
                                                        <option value="20:30" />
                                                        <option value="20:45" />
                                                        <option value="21:00" />
                                                        <option value="21:15" />
                                                        <option value="21:30" />
                                                        <option value="21:45" />
                                                    </datalist>
                                                </TableCell>
                                                <TableCell align="center" > <IMSelect
                                                    data={roomSelect}
                                                    onHandleChange={selectHandleChange(`roomId_${index + 1}`)}
                                                    refClassContainer="form-control "
                                                    name={`roomId_${index + 1}`}
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label=""
                                                    placeHolder="Select Room"
                                                    forwardRef={() => { }}
                                                    selectedId={data[`roomId_${index + 1}`] == null || data[`roomId_${index + 1}`] == undefined ? sched.RoomId : data[`roomId_${index + 1}`]}
                                                    refClassName={``}
                                                    withDescription={false}
                                                    // description={`Please select your gender.`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]}
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}

                                                /></TableCell>
                                                <TableCell align="center">{row.LabPercentageShare == null ? '100' : row.LecPercentageShare}</TableCell>
                                                <TableCell align="center"></TableCell>
                                                <TableCell align="center">
                                                    <button onClick={() => { onHandleDeleteSched(sched.SubjectScheduleDetailListId) }} className='btn  btn-link  mr-1'>
                                                        Remove
                                                    </button>

                                                </TableCell>
                                            </TableRow>
                                        }

                                    </>

                                }

                            </>
                        )
                    })}
                </>
            }
            <AddTimeRow addTimeCount={addTimeCount} />

            {switchState.IsActive == true &&
                <>


                    {selectedRowIdLab == null &&
                        <>
                            {row.schedules.map(schedule => {
                                return (
                                    <>
                                        {schedule.LabTimeStart !== null &&
                                            <TableRow className="" style={selectedRowIdLab == null ? { backgroundColor: '#3699ff54' } : { backgroundColor: '#c0ddeb' }}>
                                                <TableCell align="center">{row.ClassCode}</TableCell>
                                                <TableCell align="center">Lab</TableCell>
                                                <TableCell align="center">{row.SubjectCode}</TableCell>
                                                <TableCell align="center">{row.ClassSection}</TableCell>
                                                <TableCell align="center">{schedule.LabDayCode != null ? schedule.LabDayCode : ''}</TableCell>
                                                <TableCell align="center">{row.Capacity}</TableCell>
                                                <TableCell align="center">{schedule.LabTimeStart != null ? convertTime(schedule.LabTimeStart) : ""}</TableCell>
                                                <TableCell align="center">{schedule.LabTimeEnd != null ? convertTime(schedule.LabTimeEnd) : ""}</TableCell>
                                                <TableCell align="center">{schedule.LabRoom != null ? schedule.LabRoom : ""}</TableCell>
                                                <TableCell align="center">{row.LabPercentageShare == null ? "" : row.LabPercentageShare}</TableCell>
                                            </TableRow>

                                        }
                                    </>
                                )
                            })}
                        </>
                    }
                    {selectedRowIdLab != null &&
                        <>
                            <>
                                <TableRow className="" style={selectedRowIdLab == null ? { backgroundColor: '#3699ff54' } : { backgroundColor: '#c0ddeb' }}>
                                    <TableCell align="center">{row.ClassCode}</TableCell>
                                    <TableCell align="center">Lab</TableCell>
                                    <TableCell align="center">{row.SubjectCode}</TableCell>
                                    <TableCell align="center">{row.ClassSection}</TableCell>
                                    <TableCell align="center" className='p-0'>
                                        <input
                                            style={{
                                                flex: 1,
                                                padding: '0.375rem 0.75rem',
                                                fontSize: '1rem',
                                                lineHeight: '1.5',
                                                color: '#495057',
                                                backgroundColor: '#fff',
                                                backgroundClip: 'padding-box',
                                                border: '1px solid #ced4da',
                                                borderRadius: '0.25rem',
                                                transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                width: '100%'
                                            }}
                                            onChange={(event) => {
                                                const input = event.target.value;
                                                const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                                const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                                event.target.value = uniqueFilteredInput;
                                                setData({ ...data, [`labDayCode`]: uniqueFilteredInput, isCheckConflict: 1 });

                                            }}
                                            type="text"
                                            name="labDayCode"
                                            placeholder="Lab Day Code"
                                            autoComplete="off"
                                            defaultValue={row.LabDayCode}

                                        />
                                    </TableCell>
                                    <TableCell align="center">{row.Capacity}</TableCell>
                                    <TableCell align="center"> <input
                                        style={{
                                            padding: '2.1',
                                            lineHeight: '2.2',
                                            color: '#495057',
                                            backgroundColor: '#fff',
                                            backgroundClip: 'padding-box',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem',
                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                            width: '100%',

                                        }}
                                        onChange={handleChange('labTimeStart')}
                                        type="time"
                                        name="labTimeStart"
                                        placeholder="Time Start"
                                        defaultValue={row.LabTimeStart}

                                        autoComplete="off"
                                        list="time-options"
                                        onKeyDown={e => e.preventDefault()}


                                    />

                                        <datalist id="time-options">
                                            <option value="07:00" />
                                            <option value="07:15" />
                                            <option value="07:30" />
                                            <option value="07:45" />
                                            <option value="08:00" />
                                            <option value="08:15" />
                                            <option value="08:30" />
                                            <option value="08:45" />
                                            <option value="09:00" />
                                            <option value="09:15" />
                                            <option value="09:30" />
                                            <option value="09:45" />
                                            <option value="10:00" />
                                            <option value="10:15" />
                                            <option value="10:30" />
                                            <option value="10:45" />
                                            <option value="11:00" />
                                            <option value="11:15" />
                                            <option value="11:30" />
                                            <option value="11:45" />
                                            <option value="12:00" />
                                            <option value="12:15" />
                                            <option value="12:30" />
                                            <option value="12:45" />
                                            <option value="13:00" />
                                            <option value="13:15" />
                                            <option value="13:30" />
                                            <option value="13:45" />
                                            <option value="14:00" />
                                            <option value="14:15" />
                                            <option value="14:30" />
                                            <option value="14:45" />
                                            <option value="15:00" />
                                            <option value="15:15" />
                                            <option value="15:30" />
                                            <option value="15:45" />
                                            <option value="16:00" />
                                            <option value="16:15" />
                                            <option value="16:30" />
                                            <option value="16:45" />
                                            <option value="17:00" />
                                            <option value="17:15" />
                                            <option value="17:30" />
                                            <option value="17:45" />
                                            <option value="18:00" />
                                            <option value="18:15" />
                                            <option value="18:30" />
                                            <option value="18:45" />
                                            <option value="19:00" />
                                            <option value="19:15" />
                                            <option value="19:30" />
                                            <option value="19:45" />
                                            <option value="20:00" />
                                            <option value="20:15" />
                                            <option value="20:30" />
                                            <option value="20:45" />
                                            <option value="21:00" />
                                            <option value="21:15" />
                                            <option value="21:30" />
                                            <option value="21:45" />
                                        </datalist></TableCell>
                                    <TableCell align="center"> <input
                                        style={{
                                            padding: '2.1',
                                            lineHeight: '2.2',
                                            color: '#495057',
                                            backgroundColor: '#fff',
                                            backgroundClip: 'padding-box',
                                            border: '1px solid #ced4da',
                                            borderRadius: '0.25rem',
                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                            width: '100%',

                                        }}
                                        onChange={handleChange('labTimeEnd')}
                                        type="time"
                                        name="labTimeEnd"
                                        placeholder="Time End"
                                        defaultValue={row.LabTimeEnd}


                                        autoComplete="off"
                                        list="time-options"
                                        onKeyDown={e => e.preventDefault()}


                                    />

                                        <datalist id="time-options">
                                            <option value="07:00" />
                                            <option value="07:15" />
                                            <option value="07:30" />
                                            <option value="07:45" />
                                            <option value="08:00" />
                                            <option value="08:15" />
                                            <option value="08:30" />
                                            <option value="08:45" />
                                            <option value="09:00" />
                                            <option value="09:15" />
                                            <option value="09:30" />
                                            <option value="09:45" />
                                            <option value="10:00" />
                                            <option value="10:15" />
                                            <option value="10:30" />
                                            <option value="10:45" />
                                            <option value="11:00" />
                                            <option value="11:15" />
                                            <option value="11:30" />
                                            <option value="11:45" />
                                            <option value="12:00" />
                                            <option value="12:15" />
                                            <option value="12:30" />
                                            <option value="12:45" />
                                            <option value="13:00" />
                                            <option value="13:15" />
                                            <option value="13:30" />
                                            <option value="13:45" />
                                            <option value="14:00" />
                                            <option value="14:15" />
                                            <option value="14:30" />
                                            <option value="14:45" />
                                            <option value="15:00" />
                                            <option value="15:15" />
                                            <option value="15:30" />
                                            <option value="15:45" />
                                            <option value="16:00" />
                                            <option value="16:15" />
                                            <option value="16:30" />
                                            <option value="16:45" />
                                            <option value="17:00" />
                                            <option value="17:15" />
                                            <option value="17:30" />
                                            <option value="17:45" />
                                            <option value="18:00" />
                                            <option value="18:15" />
                                            <option value="18:30" />
                                            <option value="18:45" />
                                            <option value="19:00" />
                                            <option value="19:15" />
                                            <option value="19:30" />
                                            <option value="19:45" />
                                            <option value="20:00" />
                                            <option value="20:15" />
                                            <option value="20:30" />
                                            <option value="20:45" />
                                            <option value="21:00" />
                                            <option value="21:15" />
                                            <option value="21:30" />
                                            <option value="21:45" />
                                        </datalist></TableCell>
                                    <TableCell align="center"> <IMSelect
                                        data={roomSelect}
                                        onHandleChange={selectHandleChange('labRoomId')}
                                        refClassContainer="form-control"
                                        name="labRoomId"
                                        isRequired={false}
                                        withLabel={false}
                                        label=""
                                        placeHolder="Select Room"
                                        forwardRef={() => { }}
                                        selectedId={data.labRoomId}
                                        refClassName={``}
                                        withDescription={false}
                                        // description={`Please select your gender.`}
                                        refDisabled={true}
                                        refIsStatic={false}
                                        refStaticData={[
                                        ]}
                                        field={{
                                            tableId: 'tableId',
                                            display: 'code'
                                        }}

                                    /></TableCell>
                                    <TableCell align="center"> <input
                                        className="form-control  "
                                        onChange={handleChange('labPercentageShare')}
                                        type="number"
                                        name="labPercentageShare"
                                        placeholder="labPercentageShare"
                                        defaultValue={row.LabPercentageShare}

                                    />
                                    </TableCell>
                                    <TableCell></TableCell>
                                    <TableCell>

                                    </TableCell>
                                </TableRow>
                            </>

                            <>

                            </>
                            {row.schedules.map((schedule, index) => {
                                return (
                                    <>
                                        {(schedule.TimeStart == null && schedule.LabTimeStart != null)
                                            &&
                                            <TableRow className="" style={selectedRowIdLab == null ? { backgroundColor: '#3699ff54' } : { backgroundColor: '#c0ddeb' }}>
                                                <TableCell align="center">{row.ClassCode}</TableCell>
                                                <TableCell align="center">Lab</TableCell>
                                                <TableCell align="center">{row.SubjectCode}</TableCell>
                                                <TableCell align="center">{row.ClassSection}</TableCell>
                                                <TableCell align="center" className='p-0'>
                                                    <input
                                                        style={{
                                                            flex: 1,
                                                            padding: '0.375rem 0.75rem',
                                                            fontSize: '1rem',
                                                            lineHeight: '1.5',
                                                            color: '#495057',
                                                            backgroundColor: '#fff',
                                                            backgroundClip: 'padding-box',
                                                            border: '1px solid #ced4da',
                                                            borderRadius: '0.25rem',
                                                            transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                            width: '100%'
                                                        }}
                                                        onChange={(event) => {
                                                            const input = event.target.value;
                                                            const filteredInput = input.toUpperCase().replace(/[^MTWHFSU]/g, ''); // Only allow specified letters
                                                            const uniqueFilteredInput = [...new Set(filteredInput)].join(''); // Remove duplicates
                                                            event.target.value = uniqueFilteredInput;
                                                            setData({ ...data, 'labDayCode': uniqueFilteredInput, isCheckConflict: 1 });

                                                        }}
                                                        type="text"
                                                        name="labDayCode"
                                                        placeholder="Lab Day Code"
                                                        autoComplete="off"
                                                        defaultValue={schedule.LabDayCode}

                                                    />
                                                </TableCell>
                                                <TableCell align="center">{row.Capacity}</TableCell>
                                                <TableCell align="center"> <input
                                                    style={{
                                                        padding: '2.1',
                                                        lineHeight: '2.2',
                                                        color: '#495057',
                                                        backgroundColor: '#fff',
                                                        backgroundClip: 'padding-box',
                                                        border: '1px solid #ced4da',
                                                        borderRadius: '0.25rem',
                                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                        width: '100%',

                                                    }}
                                                    onChange={handleChange('labTimeStart')}
                                                    type="time"
                                                    name="labTimeStart"
                                                    placeholder="Time Start"
                                                    defaultValue={schedule.LabTimeStart}

                                                    autoComplete="off"
                                                    list="time-options"
                                                    onKeyDown={e => e.preventDefault()}


                                                />

                                                    <datalist id="time-options">
                                                        <option value="07:00" />
                                                        <option value="07:15" />
                                                        <option value="07:30" />
                                                        <option value="07:45" />
                                                        <option value="08:00" />
                                                        <option value="08:15" />
                                                        <option value="08:30" />
                                                        <option value="08:45" />
                                                        <option value="09:00" />
                                                        <option value="09:15" />
                                                        <option value="09:30" />
                                                        <option value="09:45" />
                                                        <option value="10:00" />
                                                        <option value="10:15" />
                                                        <option value="10:30" />
                                                        <option value="10:45" />
                                                        <option value="11:00" />
                                                        <option value="11:15" />
                                                        <option value="11:30" />
                                                        <option value="11:45" />
                                                        <option value="12:00" />
                                                        <option value="12:15" />
                                                        <option value="12:30" />
                                                        <option value="12:45" />
                                                        <option value="13:00" />
                                                        <option value="13:15" />
                                                        <option value="13:30" />
                                                        <option value="13:45" />
                                                        <option value="14:00" />
                                                        <option value="14:15" />
                                                        <option value="14:30" />
                                                        <option value="14:45" />
                                                        <option value="15:00" />
                                                        <option value="15:15" />
                                                        <option value="15:30" />
                                                        <option value="15:45" />
                                                        <option value="16:00" />
                                                        <option value="16:15" />
                                                        <option value="16:30" />
                                                        <option value="16:45" />
                                                        <option value="17:00" />
                                                        <option value="17:15" />
                                                        <option value="17:30" />
                                                        <option value="17:45" />
                                                        <option value="18:00" />
                                                        <option value="18:15" />
                                                        <option value="18:30" />
                                                        <option value="18:45" />
                                                        <option value="19:00" />
                                                        <option value="19:15" />
                                                        <option value="19:30" />
                                                        <option value="19:45" />
                                                        <option value="20:00" />
                                                        <option value="20:15" />
                                                        <option value="20:30" />
                                                        <option value="20:45" />
                                                        <option value="21:00" />
                                                        <option value="21:15" />
                                                        <option value="21:30" />
                                                        <option value="21:45" />
                                                    </datalist></TableCell>
                                                <TableCell align="center"> <input
                                                    style={{
                                                        padding: '2.1',
                                                        lineHeight: '2.2',
                                                        color: '#495057',
                                                        backgroundColor: '#fff',
                                                        backgroundClip: 'padding-box',
                                                        border: '1px solid #ced4da',
                                                        borderRadius: '0.25rem',
                                                        transition: 'border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out',
                                                        width: '100%',

                                                    }}
                                                    onChange={handleChange('labTimeEnd')}
                                                    type="time"
                                                    name="labTimeEnd"
                                                    placeholder="Time End"
                                                    defaultValue={schedule.LabTimeEnd}


                                                    autoComplete="off"
                                                    list="time-options"
                                                    onKeyDown={e => e.preventDefault()}


                                                />

                                                    <datalist id="time-options">
                                                        <option value="07:00" />
                                                        <option value="07:15" />
                                                        <option value="07:30" />
                                                        <option value="07:45" />
                                                        <option value="08:00" />
                                                        <option value="08:15" />
                                                        <option value="08:30" />
                                                        <option value="08:45" />
                                                        <option value="09:00" />
                                                        <option value="09:15" />
                                                        <option value="09:30" />
                                                        <option value="09:45" />
                                                        <option value="10:00" />
                                                        <option value="10:15" />
                                                        <option value="10:30" />
                                                        <option value="10:45" />
                                                        <option value="11:00" />
                                                        <option value="11:15" />
                                                        <option value="11:30" />
                                                        <option value="11:45" />
                                                        <option value="12:00" />
                                                        <option value="12:15" />
                                                        <option value="12:30" />
                                                        <option value="12:45" />
                                                        <option value="13:00" />
                                                        <option value="13:15" />
                                                        <option value="13:30" />
                                                        <option value="13:45" />
                                                        <option value="14:00" />
                                                        <option value="14:15" />
                                                        <option value="14:30" />
                                                        <option value="14:45" />
                                                        <option value="15:00" />
                                                        <option value="15:15" />
                                                        <option value="15:30" />
                                                        <option value="15:45" />
                                                        <option value="16:00" />
                                                        <option value="16:15" />
                                                        <option value="16:30" />
                                                        <option value="16:45" />
                                                        <option value="17:00" />
                                                        <option value="17:15" />
                                                        <option value="17:30" />
                                                        <option value="17:45" />
                                                        <option value="18:00" />
                                                        <option value="18:15" />
                                                        <option value="18:30" />
                                                        <option value="18:45" />
                                                        <option value="19:00" />
                                                        <option value="19:15" />
                                                        <option value="19:30" />
                                                        <option value="19:45" />
                                                        <option value="20:00" />
                                                        <option value="20:15" />
                                                        <option value="20:30" />
                                                        <option value="20:45" />
                                                        <option value="21:00" />
                                                        <option value="21:15" />
                                                        <option value="21:30" />
                                                        <option value="21:45" />
                                                    </datalist></TableCell>
                                                <TableCell align="center"> <IMSelect
                                                    data={roomSelect}
                                                    onHandleChange={selectHandleChange('labRoomId')}
                                                    refClassContainer="form-control"
                                                    name="labRoomId"
                                                    isRequired={false}
                                                    withLabel={false}
                                                    label=""
                                                    placeHolder="Select Room"
                                                    forwardRef={() => { }}
                                                    selectedId={data.labRoomId === null ? schedule.LabRoomId : data.labRoomId}
                                                    refClassName={``}
                                                    withDescription={false}
                                                    // description={`Please select your gender.`}
                                                    refDisabled={true}
                                                    refIsStatic={false}
                                                    refStaticData={[
                                                    ]}
                                                    field={{
                                                        tableId: 'tableId',
                                                        display: 'code'
                                                    }}

                                                /></TableCell>
                                                <TableCell align="center"> <input
                                                    className="form-control  "
                                                    onChange={handleChange('labPercentageShare')}
                                                    type="number"
                                                    name="labPercentageShare"
                                                    placeholder="labPercentageShare"
                                                    defaultValue={row.LabPercentageShare}

                                                />
                                                </TableCell>
                                                <TableCell></TableCell>
                                                <TableCell>

                                                </TableCell>
                                            </TableRow>


                                        }



                                    </>
                                )
                            })}



                        </>
                    }


                    <AddTimeRowLab addTimeCount={addTimeCountLab} />





                </>

            }





            {/* </React.Fragment > */}
        </>


    );
}



export default function CollapsibleTable(props) {
    const { handleEdit, selectedId, classSectionSelect,
        subSelect, subSchedId, onOpenSubSchedDetail,
        handleCloseSubSchedDetail, onSaveSubSchedDetail,
        handleOpenSubSchedDetail, subSchedDetailData,
        onUpdateSubSchedDetail, semesterId, schoolYear, subjectTypeSelect,
        roomSelect, handleDelete, classCode, executeGetSubjectScheduleDetl,
        mergeSubSchedFaculty, handleClassSectionChange, classCodeSelect, results,
        onHandleDeleteSched, onDeleteLab } = props
    const { state: { auth } } = useAppContext()
    const [selected, setSelected] = useState([]);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('');
    const classes = useRowStyles();
    const isSelected = (name) => selected.indexOf(name) !== -1;


    const handleClick = (event, name) => {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(
                selected.slice(0, selectedIndex),
                selected.slice(selectedIndex + 1),
            );
        }

        setSelected(newSelected);
    };


    const handleRequestSort = (event, property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };




    const handleSelectAllClick = (event) => {
        if (event.target.checked) {
            const newSelecteds = results.map((n) => n['SubjectScheduleDetailId']);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    };


    console.log(classCode)
    console.log(results)
    console.log(subSchedDetailData)
    console.log(auth.data.UserType)
    return (

        <>
            {props.results !== undefined ?
                <>

                    <SubjectScheduleDetailModal

                        openMigrateModal={onOpenSubSchedDetail}
                        handleClose={handleCloseSubSchedDetail}
                        onSubmitStudentHandler={onSaveSubSchedDetail}
                        subSchedDetailData={subSchedDetailData}
                        subSelect={subSelect}
                        roomSelect={roomSelect}
                        classSectionSelect={classSectionSelect}
                        onUpdateSubSchedDetail={onUpdateSubSchedDetail}
                    />

                    <TableContainer component={Paper} style={{ overflowY: 'auto', maxWidth: '100%' }}>
                        <Table size="small" aria-label="a dense table" style={{ maxWidth: '100%', tableLayout: 'fixed' }}>
                            <EnhancedTableHead
                                classes={classes.container}
                                numSelected={selected.length}
                                order={order}
                                orderBy={orderBy}
                                onSelectAllClick={handleSelectAllClick}
                                onRequestSort={handleRequestSort}
                                rowCount={results.length}
                            />
                            {/* <TableHead className=''>
                                <TableRow>
                                    <TableCell align="center">Class Code</TableCell>
                                    <TableCell align="center">Type</TableCell>
                                    <TableCell align="center">Subject Code</TableCell>
                                    <TableCell align="center">Class Section</TableCell>
                                    <TableCell align="center">Day</TableCell>
                                    <TableCell align="center">Capacity</TableCell>
                                    <TableCell align="center">Time Start</TableCell>
                                    <TableCell align="center">Time End</TableCell>
                                    <TableCell align="center">Room</TableCell>
                                    <TableCell align="center">Merged Class</TableCell>

                                    <TableCell align="center">Action</TableCell>

                                </TableRow>
                            </TableHead> */}
                            <TableBody>
                                {stableSort(results, getComparator(order, orderBy))
                                    .map((row, index) => {
                                        const isItemSelected = isSelected(row['SubjectScheduleDetailId']);

                                        return (
                                            <>


                                                <Row
                                                    key={row.SubjectScheduleDetailId}
                                                    row={row}
                                                    handleEdit={handleEdit}
                                                    classSectionSelect={classSectionSelect}
                                                    subSelect={subSelect}
                                                    subSchedId={subSchedId}
                                                    selectedId={selectedId}
                                                    handleOpenSubSchedDetail={handleOpenSubSchedDetail}
                                                    handleDelete={handleDelete}
                                                    executeGetSubjectScheduleDetl={executeGetSubjectScheduleDetl}
                                                    mergeSubSchedFaculty={mergeSubSchedFaculty}
                                                    handleClassSectionChange={handleClassSectionChange}
                                                    roomSelect={roomSelect}
                                                    onUpdateSubSchedDetail={onUpdateSubSchedDetail}
                                                    classCodeSelect={classCodeSelect}
                                                    onHandleDeleteSched={onHandleDeleteSched}
                                                    onDeleteLabHandler={onDeleteLab}


                                                />



                                            </>
                                        )
                                    })}

                            </TableBody>



                        </Table>


                    </TableContainer>
                    {results.length > 0 ?
                        <>
                            {(results[0].IsFinal == 0 || auth.data.UserType == 'RGD') &&
                                <RowList
                                    result={[]}
                                    index={undefined}
                                    onHandleAdd={onSaveSubSchedDetail}
                                    classSectionSelect={classSectionSelect}
                                    subSelect={subSelect}
                                    roomSelect={roomSelect}
                                    semesterId={semesterId}
                                    schoolYear={schoolYear}
                                    subSchedId={subSchedId}
                                    subjectTypeSelect={subjectTypeSelect}
                                    subSchedDetailId={0}
                                    limitClassCode={classCode}
                                    handleClassSectionChange={handleClassSectionChange}


                                />
                            }
                        </>
                        :
                        <>
                            <RowList
                                result={[]}
                                index={undefined}
                                onHandleAdd={onSaveSubSchedDetail}
                                classSectionSelect={classSectionSelect}
                                subSelect={subSelect}
                                roomSelect={roomSelect}
                                semesterId={semesterId}
                                schoolYear={schoolYear}
                                subSchedId={subSchedId}
                                subjectTypeSelect={subjectTypeSelect}
                                subSchedDetailId={0}
                                limitClassCode={classCode}
                                handleClassSectionChange={handleClassSectionChange}

                            />
                        </>
                    }


                </>
                :
                <></>
            }

        </>

    );
}