export const referenceTypes = {
    SELECTED_SUBJECT_TYPE: 'SELECTED-SUBJECT-TYPE',
    GET_SUBJECT_TYPES: 'GET-SUBJECT-TYPES',
    GET_SUBJECT_TYPE: 'GET-SUBJECT-TYPE-BY-ID',
    CREATE: 'CREATE-SUBJECT-TYPE',
    UPDATE: 'UPDATE-SUBJECT-TYPE',
    DELETE: 'DELETE-SUBJECT-TYPE',
    LIST_LINK: '/admin/references/subject-types',
    CREATE_LINK: '/admin/references/create-subject-types/',
    UPDATE_LINK: '/admin/references/update-subject-types/',
    DELETE_LINK: '/admin/references/delete-subject-types/'
}