import instance from "../../../../../../apis/local/systemAPI";


export const getCivilStatus = async () => {
  const result = await instance.get("api/references/civilstatus");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getReligion = async () => {
  const result = await instance.get("api/references/religions");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCountry = async () => {
  const result = await instance.get("api/references/countries");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getProvince = async () => {
  const result = await instance.get("api/references/provinces");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getMunicipality = async () => {
  const result = await instance.get(`api/references/municipalities`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourses = async () => {
  const result = await instance.get("api/references/courses");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSchool = async () => {
  const result = await instance.get("api/references/schools");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getYearLevel = async (formValues) => {
  const result = await instance.get("api/references/yearlevels", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getApplicants = async (val) => {
  const result = await instance.post("api/admission/account/applicants", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteApplicants = async (val) => {
  const result = await instance.post("api/admission/account/deleteApplicants", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const updateApplicants = async (val) => {
  console.log(val)
  const result = await instance.post("api/students/register", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFamilyPositions = async () => {
  const result = await instance.get("api/references/family-position");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getNationality = async (condition) => {
  const result = await instance.post("api/references/nationalities/q", condition);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const addUpdateStudentSiblings = async (val) => {
  const result = await instance.post("api/students/addUpdateStudentSibling", val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

//