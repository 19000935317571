import React, { useState, useEffect } from "react";
import FormUpdate from "./FormUpdate";
import { useParams } from "react-router-dom";
import history from "../../../../history";
import {
    getCivilStatus, getReligion, getCourse, getYearLevel, getSchool,
    getStudentSchoolHistory, getProvinces, getMunicipalities, getCountries, getDiscount, getStudent, getScholarship
} from "../../Admissions/ApplicantDataEntry/__hooks__";
import { getStudentDiscount } from "./__hooks__";


export default function UpdateStudent(props) {


    const { lastName, firstName, middleName, religionId, civilStatusId, birthDate, birthPlace, celNo,
        telNo, lastSchool, email, address, courseId, yearLevelId, gender, enrollmentId,
        fatherName, fatherOccupation, fatherOccupationAddress, fatherContactNo, fatherAddress,
        fatherMaritalStatus, fatherCitizen, motherName, motherOccupation, motherOccupationAddress, motherContactNo,
        motherAddress, motherMaritalStatus, motherCitizen, motherEducation, fatherEducation, familyBackgroundId, studentId, studentInfoId
    } = props

    const { identifier } = props
    const { id } = useParams()
    const [studentDiscount, setStudentDiscount] = useState()
    const [student, setStudent] = useState()

    const [preSchoolData, setPreSchoolData] = useState()
    const [elemSchoolData, setElemSchoolData] = useState()
    const [jrShoolData, setJrSchoolData] = useState()
    const [srSchoolData, setSrSchoolData] = useState()

    const [civilStatus, setCivilStatus] = useState()
    const [religion, setReligion] = useState()
    const [course, setCourse] = useState()
    const [yearLevel, setYearLevel] = useState()
    const [schools, setSchool] = useState()
    const [schoolHistory, setSchoolHistory] = useState()
    const [provinces, setProvinces] = useState()
    const [municipalities, setMunicipalities] = useState()
    const [countries, setCountries] = useState()
    const [scholarship, setScholarship] = useState()


    const [civilStatusSelect, setCivilStatusSelect] = useState()
    const [religionSelect, setReligionSelect] = useState()
    const [courseSelect, setCourseSelect] = useState()
    const [yearLevelSelect, setYearLevelSelect] = useState()
    const [schoolSelect, setSchoolSelect] = useState()
    const [provinceSelect, setProvinceSelect] = useState()
    const [municipalitySelect, setMunicipalitySelect] = useState()
    const [countrySelect, setCountrySelect] = useState()
    const [scholarshipSelect, setScholarshipSelect] = useState()

    const studentDetails = {
        lastName: lastName,
        firstName: firstName,
        middleName: middleName,
        religion: religionId,
        civilStatus: civilStatusId,
        birthDate: birthDate,
        birthPlace: birthPlace,
        celNo: celNo,
        telNo: telNo,
        lastSchool: lastSchool,
        email: email,
        address: address,
        courseId: courseId,
        yearLevelId: yearLevelId,
        gender: gender,
        enrollmentId: enrollmentId,
        familyBackgroundId: familyBackgroundId,
        studentId: studentId,
        studentInfoId: studentInfoId

    }


    const studentFatherDetails = {
        parentName: fatherName,
        parentOccupation: fatherOccupation,
        parentOccupationAddress: fatherOccupationAddress,
        parentContactNo: fatherContactNo,
        parentAddress: fatherAddress,
        parentMaritalStatus: fatherMaritalStatus,
        parentCitizen: fatherCitizen,
        parentEducation: fatherEducation
    }

    const studentMotherDetails = {
        parentName: motherName,
        parentOccupation: motherOccupation,
        parentOccupationAddress: motherOccupationAddress,
        parentContactNo: motherContactNo,
        parentAddress: motherAddress,
        parentMaritalStatus: motherMaritalStatus,
        parentCitizen: motherCitizen,
        parentEducation: motherEducation
    }
    console.log(birthDate)



    const Query = (operation, condition, setData) => {
        let obj = eval("(" + condition + ")");
        console.log(obj)
        const execute = async () => {
            await operation(obj)
                .then(response => {
                    if (response.success) {
                        setData(response.data)
                    }
                    return
                })
                .catch(error => {
                    if (error) {
                        return error
                        // alert("something went wrong")
                    } else {
                        return
                    }
                })
        }

        execute()
    }

    const getAll = "{where: ''}"
    const getSchoolHistoryQuery = "{where: `WHERE student_school_history.StudentInfoId = ${ parseInt(id)}`}"
    const getStudentQuery = "{where: `WHERE s.StudentInfoId = ${ parseInt(id)}`}"
    const getStudentDiscountQuery = "{where: `WHERE sd.StudentId = ${student[0].StudentId}`}"



    useEffect(() => {

        //civil Status
        Query(getCivilStatus, getAll, setCivilStatus)
        //religion
        Query(getReligion, getAll, setReligion)
        //course
        Query(getCourse, getAll, setCourse)
        //year level
        Query(getYearLevel, getAll, setYearLevel)
        //school
        Query(getSchool, getAll, setSchool)
        // school history
        Query(getStudentSchoolHistory, getSchoolHistoryQuery, setSchoolHistory)
        // provinces
        Query(getProvinces, getAll, setProvinces)
        // municipalities
        Query(getMunicipalities, getAll, setMunicipalities)
        // countries
        Query(getCountries, getAll, setCountries)

        // countries
        Query(getScholarship, getAll, setScholarship)
        // discount
        Query(getStudent, getStudentQuery, setStudent)



    }, [])


    useEffect(() => {
        if (student !== undefined) {
            // discount
            Query(getDiscount, getStudentDiscountQuery, setStudentDiscount)
        }
    }, [student])


    useEffect(() => {
        if (schoolHistory !== undefined) {
            let preSchool = []
            let elem = []
            let jrHigh = []
            let srHigh = []
            for (let i = 0; i < schoolHistory.length; i++) {
                if (schoolHistory[i].SchoolLevelId === 1) {
                    preSchool.push(schoolHistory[i])
                } else if (schoolHistory[i].SchoolLevelId === 2) {
                    elem.push(schoolHistory[i])
                } else if (schoolHistory[i].SchoolLevelId === 3) {
                    jrHigh.push(schoolHistory[i])
                } else if (schoolHistory[i].SchoolLevelId === 4) {
                    srHigh.push(schoolHistory[i])
                }
            }

            setPreSchoolData(preSchool)
            setElemSchoolData(elem)
            setJrSchoolData(jrHigh)
            setSrSchoolData(srHigh)
        }
    }, [schoolHistory])


    useEffect(() => {
        if (civilStatus !== undefined && religion !== undefined && course !== undefined
            && yearLevel !== undefined && schools !== undefined && provinces !== undefined
            && countries !== undefined && municipalities !== undefined && scholarship !== undefined) {
            let schoolsArr = []
            let civilStatusArr = []
            let religionArr = []
            let courseArr = []
            let yearLevelArr = []
            let provincesArr = []
            let municipalitiesArr = []
            let countriesArr = []
            let scholarshipArr = []

            for (let s = 0; s < schools.length; s++) {
                schoolsArr.push({ tableId: schools[s].SchoolId, code: schools[s].School })
            }

            for (let s = 0; s < civilStatus.length; s++) {
                civilStatusArr.push({ tableId: civilStatus[s].CivilStatusId, code: civilStatus[s].CivilStatus })
            }

            for (let s = 0; s < religion.length; s++) {
                religionArr.push({ tableId: religion[s].ReligionId, code: religion[s].Religion })
            }

            for (let s = 0; s < course.length; s++) {
                courseArr.push({ tableId: course[s].CourseId, code: course[s].Description, instituteId: course[s].InstituteId })
            }

            for (let s = 0; s < yearLevel.length; s++) {
                yearLevelArr.push({ tableId: yearLevel[s].CourseId, code: yearLevel[s].Description })
            }
            for (let pr = 0; pr < provinces.length; pr++) {
                provincesArr.push({ tableId: provinces[pr].ProvinceId, code: provinces[pr].Province })
            }

            for (let mu = 0; mu < municipalities.length; mu++) {
                municipalitiesArr.push({ tableId: municipalities[mu].MunicipalityId, code: municipalities[mu].Municipality })
            }

            for (let co = 0; co < countries.length; co++) {
                countriesArr.push({ tableId: countries[co].CountryId, code: countries[co].Country })
            }

            for (let co = 0; co < scholarship.length; co++) {
                scholarshipArr.push({ tableId: scholarship[co].ScholarshipId, code: scholarship[co].Scholarship })
            }


            setSchoolSelect(schoolsArr)
            setCivilStatusSelect(civilStatusArr)
            setReligionSelect(religionArr)
            setCourseSelect(courseArr)
            setYearLevelSelect(yearLevelArr)
            setProvinceSelect(provincesArr)
            setMunicipalitySelect(municipalitiesArr)
            setCountrySelect(countriesArr)
            setScholarshipSelect(scholarshipArr)

        }

    }, [civilStatus, religion, course, yearLevel, schools, provinces, countries, municipalities, scholarship])

    console.log(schoolSelect)
    console.log(civilStatusSelect)
    console.log(religionSelect)
    console.log(courseSelect)
    console.log(yearLevelSelect)
    console.log(schoolHistory)
    console.log(preSchoolData)
    console.log(elemSchoolData)
    console.log(jrShoolData)
    console.log(srSchoolData)
    console.log(student)
    console.log(studentDiscount)
    console.log(scholarshipSelect)


    console.log(scholarship)
    console.log(identifier)

    return (
        <>
            {schoolSelect !== undefined && studentDiscount !== undefined && civilStatusSelect !== undefined && religionSelect !== undefined
                && courseSelect !== undefined && scholarshipSelect !== undefined && yearLevelSelect !== undefined &&

                <FormUpdate
                    schoolSelect={schoolSelect}
                    civilStatusSelect={civilStatusSelect}
                    religionSelect={religionSelect}
                    courseSelect={courseSelect}
                    yearLevelSelect={yearLevelSelect}
                    studentDetails={studentDetails}
                    fatherDetails={studentFatherDetails}
                    motherDetails={studentMotherDetails}
                    preSchool={preSchoolData}
                    elem={elemSchoolData}
                    jrHigh={jrShoolData}
                    srHigh={srSchoolData}
                    schoolHistory={schoolHistory}
                    municipalitySelect={municipalitySelect}
                    provinceSelect={provinceSelect}
                    countrySelect={countrySelect}
                    scholarshipSelect={scholarshipSelect}
                    studentDiscount={studentDiscount}
                    student={student}
                    data={props.location.data}
                    identifier={identifier}

                />


            }
        </>
    )

}