import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { FeeGroupSelect } from '../../';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    feeGroupId: null,
    applyType: null
  })
  const [switchState, setSwitchState] = useState({
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });
  useEffect(() => {
    register({ name: 'feeGroupId' }, { required: true });
    register({ name: 'applyType' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { feeGroupId, shortName, applyRate, applyType, isActive } = formValues;

    const convertedFormValues = {
      scholarshipId: props.scholarshipId,
      feeGroupId: feeGroupId,
      shortName: shortName,
      applyRate: applyRate,
      applyType: applyType,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <FeeGroupSelect
                refOnHandleChange={selectHandleChange('feeGroupId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.FeeGroupId === undefined ? "" : props.doc.FeeGroupId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.feeGroupId}
                refErrorMessage={errors.feeGroupId && errors.feeGroupId.type === "required" && 'Fee group is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Apply Rate <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="applyRate"
                  defaultValue={props.doc.ApplyRate === undefined ? "" : props.doc.ApplyRate}
                  className={`form-control${errors.applyRate === undefined ? "" : errors.applyRate && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your apply rate.</span>
                {errors.applyRate && errors.applyRate.type === "required" && <div className="invalid-feedback">Apply rate is required</div>}
              </div>
              <IMSelect
                onHandleChange={selectHandleChange('applyType')}
                refClassContainer="form-group"
                name="applyType"
                isRequired={true}
                withLabel={true}
                label="Apply Type"
                placeHolder="Apply Type"
                forwardRef={setValue}
                selectedId={data.applyType === null ? props.doc.ApplyType : data.applyType}
                refClassName={``}
                withDescription={true}
                description={`Please enter your apply type.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: '', label: '' },
                  { id: 'A', label: 'Amount' },
                  { id: 'P', label: 'Percentage' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
                error={!!errors.applyType}
                errorMessage={errors.applyType && errors.applyType.type === "required" && 'Apply type is required'}
              />
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={`${referenceTypes.LIST_LINK}/${props.scholarshipId}`} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}