import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { InstituteSelect } from '../';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    instituteId: null
  })
  const [switchState, setSwitchState] = useState({
    IsOffer: props.doc.IsOffer === undefined ? false : props.doc.IsOffer === 0 ? false : true,
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'instituteId' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { code, description, instituteId, maximumYear, seqNo, coursePrefix, classCode, isOffer, isActive } = formValues;

    const convertedFormValues = {
      code: code,
      description: description,
      instituteId: instituteId,
      maximumYear: maximumYear,
      seqNo: seqNo,
      coursePrefix: coursePrefix,
      classCode: classCode,
      isOffer: isOffer ? 1 : 0,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Code <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="code"
                  defaultValue={props.doc.Code === undefined ? "" : props.doc.Code}
                  className={`form-control${errors.code === undefined ? "" : errors.code && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                  maxLength="100"
                />
                <span className="form-text text-muted">Please enter your code.</span>
                {errors.code && errors.code.type === "required" && <div className="invalid-feedback">Code is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <InstituteSelect
                refOnHandleChange={selectHandleChange('instituteId')}
                refClassContainer="form-group"
                refWithLabel={true}
                refIsRequired={true}
                forwardRef={setValue}
                selectedId={props.doc.InstituteId === undefined ? "" : props.doc.InstituteId}
                refWithFilter={false}
                refClassName={``}
                refWithDescription={true}
                refDisabled={true}
                refError={!!errors.instituteId}
                refErrorMessage={errors.instituteId && errors.instituteId.type === "required" && 'Institute is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Maximum Year <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="maximumYear"
                  defaultValue={props.doc.MaximumYear === undefined ? "" : props.doc.MaximumYear}
                  className={`form-control${errors.maximumYear === undefined ? "" : errors.maximumYear && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your maximum year.</span>
                {errors.maximumYear && errors.maximumYear.type === "required" && <div className="invalid-feedback">Maximum year is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Sequence <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="seqNo"
                  defaultValue={props.doc.SeqNo === undefined ? "" : props.doc.SeqNo}
                  className={`form-control${errors.seqNo === undefined ? "" : errors.seqNo && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your sequence.</span>
                {errors.seqNo && errors.seqNo.type === "required" && <div className="invalid-feedback">Sequence is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Course Prefix <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="coursePrefix"
                  defaultValue={props.doc.CoursePrefix === undefined ? "" : props.doc.CoursePrefix}
                  className={`form-control${errors.coursePrefix === undefined ? "" : errors.coursePrefix && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your course prefix.</span>
                {errors.coursePrefix && errors.coursePrefix.type === "required" && <div className="invalid-feedback">Course prefix is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Class Code <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="classCode"
                  defaultValue={props.doc.ClassCode === undefined ? "" : props.doc.ClassCode}
                  className={`form-control${errors.classCode === undefined ? "" : errors.classCode && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your class code.</span>
                {errors.classCode && errors.classCode.type === "required" && <div className="invalid-feedback">Class code is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isOffer"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsOffer} onChange={switchHandleChange('IsOffer')} value={switchState.IsOffer} />}
                    label="Offer"
                  /><br />
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}