import React, { useState, useEffect } from "react";
import { useForm } from "react-hook-form";
import { TableRow, TableCell, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import Autocomplete from '@material-ui/lab/Autocomplete';
import { Table } from "react-bootstrap";
import { TextField } from "@material-ui/core";



export default function RowForm(props) {
    const { result, paymentAddOnId, onHandleCancel, onHandleSave, index,
        instituteSelect, courseSelect, yearLevelSelect, sySelect, semSelect,
        onHandleAdd, feeSelect, handleGetStudent, handleStudentInputChange, studentList,
        studentId } = props
    const { register, errors, setValue } = useForm();
    const recordCnt = props.result.length
    const today = new Date();
    const date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
    const time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
    const dateTime = date + ' ' + time;
    const initialState = recordCnt === 0 ? {
        paymentAddOnId: paymentAddOnId,
        paymentAddOns: null,
        courseId: null,
        yearLevelId: null,
        amount: null,
        instituteId: null,
        schoolYear: null,
        semesterId: null,
        feeId: null,
        studentId: null,
        dateUpdated: dateTime
    } : {
        paymentAddOnId: paymentAddOnId,
        paymentAddOns: result.PaymentAddOn,
        courseId: result.CourseId,
        yearLevelId: result.YearLevelId,
        amount: result.Amount,
        instituteId: result.InstituteId,
        schoolYear: result.SchoolYear,
        semesterId: result.SemesterId,
        feeId: result.FeeId,
        studentId: result.StudentId,
        dateUpdated: dateTime
    }

    console.log(result)

    const [data, setData] = useState(initialState);

    const selectHandleChange = name => event => {
        setData({ ...data, [name]: event });
    }

    const handleChange = name => event => {

        setData({ ...data, [name]: event.target.value });

    }
    console.log(result.PaymentAddOnsId)
    console.log(data)
    return (
        <>
            <TableRow key={paymentAddOnId} style={{ "verticalAlign": "center" }} >
                <TableCell align="center">{index === undefined ? '#' : index}</TableCell>
                <TableCell>
                    <Autocomplete
                        id="studentNo"
                        options={studentList}
                        getOptionLabel={(option) => option.StudentName}
                        onChange={(event, value) => {
                            if (value) {
                                console.log(value)
                                setData({ ...data, studentId: value.StudentId });

                                handleGetStudent(value)
                                // Handle the selected option
                            } else {
                                // Handle the case when no option is selected
                            }
                        }}
                        renderInput={(params) => (
                            <TextField
                                {...params}
                                label=""
                                variant="outlined"
                                inputRef={register}
                                name="studentNo"
                                // value={studentNo}
                                onChange={
                                    (e) => { handleStudentInputChange(e.target.value) }

                                }
                            />
                        )}
                    />
                    <p className="text-muted">Student #</p>
                </TableCell>
                <TableCell>
                    <IMSelect
                        data={yearLevelSelect}
                        onHandleChange={selectHandleChange('yearLevelId')}
                        refClassContainer="form-control align-middle"
                        name="yearLevelId"
                        isRequired={true}
                        withLabel={false}
                        label=""
                        placeHolder="Year Level"
                        forwardRef={setValue}
                        selectedId={data.yearLevelId === null ? result.YearLevelId : data.yearLevelId}
                        refClassName={``}
                        withDescription={true}
                        description={`Year Level`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]}
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                        error={!!errors.yearLevel}
                        errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                    />

                </TableCell>
                <TableCell>
                    <IMSelect
                        data={courseSelect}
                        onHandleChange={selectHandleChange('courseId')}
                        refClassContainer="form-control align-middle"
                        name="courseId"
                        isRequired={true}
                        withLabel={false}
                        label=""
                        placeHolder="Course"
                        forwardRef={setValue}
                        selectedId={data.courseId === null ? result.CourseId : data.courseId}
                        refClassName={``}
                        withDescription={true}
                        description={`Course`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]}
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                        error={!!errors.yearLevel}
                        errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                    />

                </TableCell>
                <TableCell>
                    <IMSelect
                        data={feeSelect}
                        onHandleChange={selectHandleChange('feeId')}
                        refClassContainer="form-control align-middle"
                        name="feeId"
                        isRequired={true}
                        withLabel={false}
                        label=""
                        placeHolder="Fee"
                        forwardRef={setValue}
                        selectedId={data.feeId === null ? result.FeeId : data.feeId}
                        refClassName={``}
                        withDescription={true}
                        description={`Fee`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]}
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                        error={!!errors.yearLevel}
                        errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                    />

                </TableCell>

                <TableCell>
                    <input
                        className="form-control align-middle"
                        onChange={handleChange('amount')}
                        type="number"
                        name="amount"
                        placeholder="Amount"
                        defaultValue={result.Amount === undefined ? "" : result.Amount}
                        ref={register({
                            required: true
                        })}
                        autoComplete="off"
                    />
                    <p className="text-muted">Amount</p>

                </TableCell>



                <TableCell>
                    <IMSelect
                        data={sySelect}
                        onHandleChange={selectHandleChange('schoolYear')}
                        refClassContainer="form-control align-middle"
                        name="schoolYear"
                        isRequired={true}
                        withLabel={false}
                        label=""
                        placeHolder="School Year"
                        forwardRef={setValue}
                        selectedId={data.schoolYear === null ? result.schoolYear : data.schoolYear}
                        refClassName={``}
                        withDescription={true}
                        description={`School Year`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]}
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                        error={!!errors.yearLevel}
                        errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                    />
                </TableCell>
                <TableCell>
                    <IMSelect
                        data={semSelect}
                        onHandleChange={selectHandleChange('semesterId')}
                        refClassContainer="form-control align-middle"
                        name="semesterId"
                        isRequired={true}
                        withLabel={false}
                        label=""
                        placeHolder="Semester"
                        forwardRef={setValue}
                        selectedId={data.semesterId === null ? result.semesterId : data.semesterId}
                        refClassName={``}
                        withDescription={true}
                        description={`Semester`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]}
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                        error={!!errors.yearLevel}
                        errorMessage={errors.yearLevel && errors.yearLevel.type === "required" && 'Year Level is required'}
                    />
                </TableCell>
                <TableCell align="center">
                    {result.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => onHandleAdd(data)}>
                        <i className="fa fa-plus"></i>
                    </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default" onClick={() => onHandleCancel()}>
                        <i className="fa fa-ban"></i>
                    </button>&nbsp;
                        <button className="btn btn-xs btn-icon btn-icon-xs btn-success mt-1" onClick={() => onHandleSave(result.PaymentAddOnsId, data)}>
                            <i className="fa fa-save text-white"></i>
                        </button></>}
                </TableCell>

            </TableRow>
        </>
    )

}