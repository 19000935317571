import React, { useState, useEffect } from "react";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import {
    getSemester, getSchoolYear, getUser, getFacultyLoadingReport, getFacultyLoadingTableReport, getFacultyLoadingReportBySection,
    getFacultyLoadingReportByRoom, subjectSummaryReport
} from "./__hooks__";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useForm } from "react-hook-form";
import PrintSummarySubjectReport from "./PrintSummarySubjectReport";
import IMTable from "../../../../../_metronic/layout/components/custom/table/IMTable";
import { useAppContext } from "../../../../contexts/useAppContext";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

export default function SummarySubjectReport(props) {
    const { setValue } = useForm()
    const { state: { auth } } = useAppContext()
    const [results, setResults] = useState([])
    const [resultBySection, setResultBySection] = useState([])
    const [resultByRoom, setResultByRoom] = useState([])
    const [tableResults, setTableResults] = useState([])
    const [semesterSelect, setSemesterSelect] = useState(null)
    const [schoolYearSelect, setSchoolYearSelect] = useState(null)
    const [userSelect, setUserSelect] = useState(null)


    const [filteredSem, setFilteredSem] = useState(auth.data.schoolyears.enrollment.SemesterId)
    const [filteredSy, setFilteredSy] = useState(auth.data.schoolyears.enrollment.SchoolYear)
    const [filteredUser, setFilteredUser] = useState(null)

    const [onView, setOnView] = useState(false)


    const selectSyChange = name => event => { setFilteredSy(event) }
    const selectSemChange = name => event => { setFilteredSem(event) }
    const selectUserChange = name => event => { setFilteredUser(event) }


    const [isLoading, setIsLoading] = useState(false)


    // functions

    const onGetSummaryReport = async () => {
        setIsLoading(true)
        setOnView(true)
        setTableResults([])
        await subjectSummaryReport({
            schoolYear: filteredSy,
            semesterId: filteredSem
        })
            .then(res => {
                setTableResults(res.data)
            })

        setIsLoading(false)

    }


    const onReset = () => {
        setResults([])
        setResultBySection([])
        setResultByRoom([])
        setTableResults([])
        setFilteredSem(auth.data.schoolyears.enrollment.SemesterId)
        setFilteredSy(auth.data.schoolyears.enrollment.SchoolYear)
        setFilteredUser(null)
        setOnView(false)
    }

    useEffect(() => {
        SelectCreator(getSemester, setSemesterSelect, 'SemesterId', 'Description')
        SelectCreator(getSchoolYear, setSchoolYearSelect, 'SchoolYear', 'Description')
        SelectCreator(getUser, setUserSelect, 'EmployeeId', 'FullName')




    }, [])




    const imSelectDropdownSchoolYear = () => {
        return (
            <>
                {schoolYearSelect !== null &&
                    <IMSelect
                        data={schoolYearSelect}
                        onHandleChange={selectSyChange()}
                        refClassContainer=""
                        name="SchoolYear"
                        isRequired={false}
                        withLabel={false}
                        label="SchoolYear"
                        placeHolder="Select School Year"
                        forwardRef={setValue}
                        selectedId={filteredSy === undefined ? filteredSy === undefined : filteredSy}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownSemesters = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={semesterSelect}
                        onHandleChange={selectSemChange()}
                        refClassContainer=""
                        name="Semester"
                        isRequired={false}
                        withLabel={false}
                        label="Semester"
                        placeHolder="Select Semester"
                        forwardRef={setValue}
                        selectedId={filteredSem === undefined ? filteredSem === undefined : filteredSem}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const imSelectDropdownFaculty = () => {
        return (
            <>
                {semesterSelect !== null &&
                    <IMSelect
                        data={userSelect}
                        onHandleChange={selectUserChange()}
                        refClassContainer=""
                        name="faculty"
                        isRequired={false}
                        withLabel={false}
                        label="Faculty"
                        placeHolder="Select Employee"
                        forwardRef={setValue}
                        selectedId={filteredUser === undefined ? filteredUser === undefined : filteredUser}
                        refClassName={`text-center`}
                        withDescription={false}
                        //description={`Enrollment Status`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[
                        ]
                        }
                        field={{
                            tableId: 'tableId',
                            display: 'code'
                        }}
                    />
                }
            </>
        )
    }

    const columns = [
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Units', label: 'No. of Units', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LecHours', label: 'Lec Hrs', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LecUnits', label: 'Lec Units', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LabHours', label: 'Lab Hrs', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'LabUnits', label: 'Lab Units', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectType', label: 'Subject Type', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubCode', label: 'Lab Cat', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'NoOfClasses', label: 'No. of Classess', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]

    const tableProps = {
        recordId: 'SubjectScheduleDetailId',
        sortField: '',
        columns: columns,
        rows: tableResults,
        totalRecords: tableResults.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: false,
        tableType: 'summarySubjectReport',
        parentId: null,
        tableTitle: '',

    }



    console.log(results)
    console.log(resultByRoom)
    console.log(auth.data)
    console.log(tableResults)

    return (
        <>
            {semesterSelect && schoolYearSelect && userSelect ?

                <>
                    <main className="card">
                        <header className="card-header">
                            <h4 className="d-block">Summary of Subjects Offered</h4>
                            <div className="row">
                                <section className="col">{imSelectDropdownSchoolYear()}</section>
                                <section className="col">{imSelectDropdownSemesters()}</section>
                                {/* <section className="col">{imSelectDropdownFaculty()}</section> */}
                                {isLoading == false ?
                                    <>
                                        {onView == false &&
                                            <button onClick={() => { onGetSummaryReport() }} className="col btn btn-primary">View</button>
                                        }
                                    </>
                                    :
                                    ''
                                }
                                {tableResults.length > 0 && isLoading == false ?
                                    <>
                                        <PrintSummarySubjectReport results={tableResults} />
                                    </>
                                    :
                                    <>
                                        {onView == true && isLoading == true &&
                                            <button className="btn btn-secondary mx-3 btn-sm">
                                                Please wait ...

                                                <div className="spinner-border mx-5" role="status">
                                                    <span className="sr-only">Loading...</span>
                                                </div>
                                            </button>

                                        }
                                    </>

                                }



                                {onView == true ?
                                    <button onClick={() => { onReset() }} className="ml-5 btn btn-secondary">Reset</button>
                                    :
                                    ''
                                }

                            </div>
                        </header>
                        <section className="card-body">
                            <IMTable tableProps={tableProps} />

                            {/* <IMTable tableProps={tableProps} /> */}

                        </section>

                    </main>

                </>

                :

                <Loading />
            }

        </>
    )
}