import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IMDialogEnrollOrValidate(props) {
    const { title, content, isOpen, onShowDialog, onHandleDelete,
        isAssessment, onDeletingClose, isRegistration, data, executeRejectOutsideCurriculum,
        isCurriculum, onHandleValidate, onHandleValidateEnroll } = props;
    const [open, setOpen] = useState(isOpen);

    const handleClose = () => {
        onShowDialog({ status: false });
        setOpen(false);
        {
            isCurriculum === true &&
                executeRejectOutsideCurriculum()
        }
    }

    const handleCloseAssessment = () => {
        // onShowDialog({ status: false });
        onDeletingClose()
        setOpen(false);
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={isAssessment !== true ? handleClose : handleCloseAssessment}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">Student has zero balance</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        The student has a zero balance. Would you prefer to validate and enroll the student or only validate their Information?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>


                    <button onClick={onHandleValidate} className="btn btn-success">
                        Validate
                    </button>
                    <button onClick={onHandleValidateEnroll} className="btn btn-success">
                        Enroll & Validate
                    </button>
                    <button onClick={handleClose} className="btn btn-secondary">
                        Cancel
                    </button>


                </DialogActions>
            </Dialog>
        </>
    );
}