import React, { useState, useEffect } from "react";
import { studentForDroppingSubjects, unDropSubjects, dropSubjects, getDroppingReason, saveReason } from "./__hooks__"
import history from "../../../../history";
import { Table, TableBody, TableHead, TableRow, TextField, TableCell } from "@material-ui/core";
import ConfirmationModal from "../../../../../_metronic/layout/components/custom/modal/ConfrimationModal";
import { useAppContext } from "../../../../contexts/useAppContext";
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";

export default function EnrollmentRecord(props) {
    const { state: { auth }, dispatch } = useAppContext();
    const [studentSubjects, setStudentSubjects] = useState(null)
    const { data } = props.location
    const [isOpenConfirmation, setIsOpenConfirmation] = useState(false)
    const [isOpenConfirmationUndrop, setIsOpenConfirmationUnDrop] = useState(false)
    const [dropReasonSelect, setDropReasonSelect] = useState([])
    const [selectedStudentSubjectId, setSelectedStudentSubjectId] = useState([])
    const [selectedReason, setSelectedReason] = useState(null)
    const [reason, setReason] = useState(null)
    const [switchState, setSwitchState] = useState({
        IsDrop: true
    });
    if (data == undefined) {
        history.push(`/admin/enrollment/enrollment-dropping`)
    }
    let cnt = 0


    const selectDropChange = name => event => { setSelectedReason(event) }

    const onDropSubjects = async () => {
        await dropSubjects({ subjectId: selectedStudentSubjectId.toString(), dropBy: auth.data.Username, dropReasonId: selectedReason })
            .then(async resp => {
                await studentForDroppingSubjects({
                    enrollmentId: data.EnrollmentId,
                    schoolYear: data.SchoolYear,
                    semesterId: data.SemesterId
                }).then(resp => {
                    setSelectedStudentSubjectId([])
                    setStudentSubjects(resp.data)
                    setIsOpenConfirmation(false)
                    setSelectedReason(null)
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'success',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Subject has been dropped'
                    });
                })
            })
    }


    const onUndropSubject = async () => {
        await unDropSubjects({ subjectId: selectedStudentSubjectId.toString(), unDroppedBy: auth.data.Username })
            .then(async resp => {
                await studentForDroppingSubjects({
                    enrollmentId: data.EnrollmentId,
                    schoolYear: data.SchoolYear,
                    semesterId: data.SemesterId
                }).then(resp => {
                    setSelectedStudentSubjectId([])
                    setStudentSubjects(resp.data)
                    setIsOpenConfirmationUnDrop(false)
                    setSelectedReason(null)
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'success',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Subject has been undropped'
                    });
                })
            })
    }

    const handleCheckboxChange = (event, id) => {
        let isChecked = event.target.checked;
        setSelectedStudentSubjectId((prevData) => {
            if (isChecked) {
                return [...prevData, id];
            } else {
                return prevData.filter(item => item !== id);
            }
        });

    }

    const switchHandleChange = name => event => {
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };


    const onSaveReason = async () => {
        saveReason({ description: reason })
            .then(resp => {
                SelectCreator(getDroppingReason, setDropReasonSelect, 'DroppingReasonId', 'Description')
                setSelectedReason(null)
                setReason(null)
                dispatch({
                    type: componentTypes.SHOW_SNACKBAR,
                    snackBarStatus: 'success',
                    snackBarDuration: 5000,
                    snackBarMessage: 'New reason has been added'
                });
            })
    }

    useEffect(() => {
        if (data != undefined) {
            studentForDroppingSubjects({
                enrollmentId: data.EnrollmentId,
                schoolYear: data.SchoolYear,
                semesterId: data.SemesterId
            }).then(resp => {
                setStudentSubjects(resp.data)
            })
        }

        SelectCreator(getDroppingReason, setDropReasonSelect, 'DroppingReasonId', 'Description')
    }, [])

    useEffect(() => {
        setSelectedStudentSubjectId([])
        setSelectedReason(null)
    }, [switchState.IsDrop])


    console.log(data)
    console.log(studentSubjects)
    console.log(selectedStudentSubjectId)
    console.log(switchState.IsDrop)
    console.log(dropReasonSelect)
    return (
        <>
            <ConfirmationModal
                isOpen={isOpenConfirmation}
                title={'Drop Subjects'}
                content={'Are you sure you want to drop the Selected Subjects'}
                onSubmit={onDropSubjects}
                handleClose={() => { setIsOpenConfirmation(false) }}
            />

            <ConfirmationModal
                isOpen={isOpenConfirmationUndrop}
                title={'Undrop Subjects'}
                content={'Are you sure you want to undrop the Selected Subjects'}
                onSubmit={onUndropSubject}
                handleClose={() => { setIsOpenConfirmationUnDrop(false) }}
            />
            {studentSubjects != null && dropReasonSelect &&
                <div className="container">
                    <div className="card">
                        <div className="row container my-5">
                            <div className="col-12 col-xl-4  border-right border-primary">
                                <div className=" p-5">
                                    <h3 className="">{`${data.LastName}, ${data.FirstName} ${data.MiddleName}`}</h3>
                                    <p className="my-0  ">{data.CourseDesc}</p>
                                    <hr />
                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Student No: &nbsp;&nbsp;</span>
                                        </div>
                                        <input readOnly value={data.StudentNo} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>

                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">Year Level: &nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        <input readOnly value={data.YearLevelDesc} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>


                                    <div className="input-group mb-3">
                                        <div className="input-group-prepend">
                                            <span className="input-group-text" id="inputGroup-sizing-default">SY / Sem&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</span>
                                        </div>
                                        <input readOnly value={`${data.SchoolYear} - ${data.SemesterId}`} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                    </div>
                                    <hr />

                                    {switchState.IsDrop &&
                                        <IMSelect
                                            data={dropReasonSelect}
                                            onHandleChange={selectDropChange()}
                                            refClassContainer=""
                                            name="droppingReasonId"
                                            isRequired={false}
                                            withLabel={true}
                                            label="Dropping Reason"
                                            placeHolder="Select Reason"
                                            forwardRef={() => { }}
                                            selectedId={selectedReason}
                                            refClassName={`text-center`}
                                            withDescription={false}
                                            //description={`Enrollment Status`}
                                            refDisabled={true}
                                            refIsStatic={false}
                                            refStaticData={[

                                            ]
                                            }
                                            field={{
                                                tableId: 'tableId',
                                                display: 'code'
                                            }}
                                        />

                                    }

                                    {selectedReason == -1 &&
                                        <div className="input-group mt-5">
                                            <input type="text" className="form-control" aria-label="Text input with segmented dropdown button" onChange={(e) => { setReason(e.target.value) }} />
                                            <div className="input-group-append">
                                                <button disabled={reason == null || reason.trim() == ''} type="button" className="btn btn-outline-secondary btn-primary" onClick={onSaveReason}>Save Reason</button>

                                            </div>
                                        </div>
                                    }

                                </div>


                            </div>
                            <div className="col-12 col-xl-8">
                                <FormControl component="fieldset" className="mr-auto">
                                    <FormControlLabel
                                        name="isActive"
                                        inputRef={() => { }}
                                        control={<Switch checked={switchState.IsDrop}
                                            onChange={switchHandleChange('IsDrop')}
                                            value={switchState.IsDrop} />}
                                        label={switchState.IsDrop ? 'Drop' : 'Undrop'}
                                    />

                                    <p>Note: Switch On for Dropping, switch off for Undropping</p>
                                </FormControl>
                                <Table aria-label="a dense table" size="small" className="card p-5">
                                    <TableHead className="bg-secondary">
                                        <TableRow>
                                            <TableCell align="center">Class Code</TableCell>
                                            <TableCell align="center">Subject</TableCell>
                                            <TableCell align="center">Status</TableCell>
                                            <TableCell align="center">Action</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {studentSubjects.map(detl => {

                                            cnt += 1
                                            return (
                                                <>
                                                    <TableRow>

                                                        <TableCell align="center">{detl.ClassCode}</TableCell>
                                                        <TableCell align="center">{detl.SubjectCode}</TableCell>
                                                        <TableCell align="center">{detl.IsDrop == 1 ? 'Drop' : 'Enrolled'}</TableCell>
                                                        <TableCell align="center">
                                                            <input
                                                                // defaultChecked={switchState.IsDrop == true ? detl.IsDrop == 1 : detl.IsDrop == 0}
                                                                checked={selectedStudentSubjectId.includes(detl.StudentEnrollmentSubjectsId)}
                                                                disabled={switchState.IsDrop == true ? detl.IsDrop == 1 : detl.IsDrop == 0}
                                                                type="checkbox"
                                                                onChange={(e) => { handleCheckboxChange(e, detl.StudentEnrollmentSubjectsId) }}
                                                            />

                                                        </TableCell>


                                                    </TableRow>

                                                </>
                                            )
                                        })}


                                    </TableBody>
                                </Table>
                                {selectedStudentSubjectId.length > 0 && (switchState.IsDrop == true ? selectedReason != null : selectedReason == null)
                                    ?
                                    <>
                                        {switchState.IsDrop ?
                                            <button onClick={() => { setIsOpenConfirmation(true) }} className="btn btn-danger btn-sm mt-5">Drop Selected Subjects</button>
                                            :
                                            <button onClick={() => { setIsOpenConfirmationUnDrop(true) }} className="btn btn-primary btn-sm mt-5">Undrop Selected Subjects</button>
                                        }
                                    </>
                                    :
                                    <>
                                        {switchState.IsDrop ?
                                            <button disabled className="btn btn-secondary btn-sm mt-5">Drop Selected Subjects</button>

                                            :
                                            <button disabled className="btn btn-secondary btn-sm mt-5">Undrop Selected Subjects</button>

                                        }
                                    </>
                                }

                            </div>
                        </div>
                    </div>
                </div>

            }



        </>
    )
}