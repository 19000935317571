export const applicantTypes = {

    SELECTED_STUDENT_INFO: 'SELECTED-STUDENT-INFO',
    GET_STUDENT_INFO: 'GET-STUDENT-INFO',
    GET_STUDENT_INFO_BY_ID: 'GET-STUDENT-INFO-BY-ID',
    GET_SCHOOLS: 'GET-SCHOOLS',
    GET_RELIGIONS: 'GET-RELIGIONS',
    GET_CIVILSTATUS: 'GET-CIVILSTATUS',
    GET_YEARLEVELS: 'GET-YEARLEVELS',
    GET_NATIONALITY: 'GET-NATIONALITY',
    GET_NET_INCOME: 'GET-NET-INCOME',
    GET_EDUCATIONAL_PLAN: 'GET-EDUCATIONAL-PLAN',
    GET_SCHOLARSHIP: 'GET-EDUCATIONAL-PLAN',
    GET_FAMILY_POSITION: 'GET-FAMILY-POSITION',
    CREATE_STUDENT: 'CREATE-STUDENT',
    CREATE_STUDENT_FAMILY_BACKGROUND: '/admin/admissions/create-applicant-data-entry/',
    CREATE_STUDENT_SCHOOL_HISTORY: '/admin/admissions/create-applicant-data-entry/',
    CREATE: 'CREATE-APPLICANT',
    UPDATE: 'UPDATE-APPLICANT',
    DELETE: 'DELETE-APPLICANT',
    ADMISSION: '/admin/admissions/applicant-data-entry',

    CREATE_STUDENT_INFO: '/admin/admissions/create-applicant-data-entry/',
    LIST_LINK: '/admin/admissions/create-applicant-data-entry/',
    SUBMITTED: '/admin/admissions/form-submitted/',
    CREATE_LINK: '/admin/admissions/create-applicant-data-entry/',
    UPDATE_LINK: '/admin/admissions/update-applicant-data-entry/',
    DELETE_LINK: '/admin/admissions/delete-applicant-data-entry/',
    MORE_LINK: '/admin/admissions/detailed-applicant-data-entry/',
    CREATE_LINK_DETAIL: '/admin/admissions/create-applicant-data-entry-detail'
}