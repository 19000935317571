import instance from "../../../../../apis/local/systemAPI";

export const searchStudent = async (data) => {
    const result = await instance.post("api/enrollments/search", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const shiftCourse = async (data) => {
    const result = await instance.post("api/enrollments/shiftCourse", data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async (condition) => {
    const result = await instance.post("api/references/semesters/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async (condition) => {
    const result = await instance.post("api/references/schoolyears/q", condition);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getCourse = async () => {
    const result = await instance.get("api/references/courses/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateStudent = async (id, formValues) => {
    const result = await instance.patch(`api/students/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateEnrollment = async (id, formValues) => {
    const result = await instance.patch(`api/enrollments/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const createStudentShiftHistory = async (formValues) => {
    const result = await instance.post("api/student/student-shifting-history", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const studentAutoComplete = async (input) => {
    const result = await instance.post("api/students/autoComplete", input);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
