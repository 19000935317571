import React, { useState, useEffect } from 'react';
import history from '../../../../../history';
import AddUpdateModal from '../../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { admissionsTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
  getApplicants, deleteApplicants, getCivilStatus, getReligion, getCountry, getProvince,
  getMunicipality, getCourses, getYearLevel, getSchool, updateApplicants,
  getFamilyPositions, getNationality, addUpdateStudentSiblings
} from './__hooks__';
import IMTable from '../../../../../../_metronic/layout/components/custom/table/IMTable';
// import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../../_metronic/layout/components/custom/select/IMSelect';
import FaithLogo from '../../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import SelectCreator from '../../../../../../_metronic/functions/SelectCreator';
import { useForm } from 'react-hook-form';
import moment from 'moment';




export default function List() {
  const { state: { fees, auth }, dispatch } = useAppContext();
  const { setValue } = useForm()
  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(0);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [keyword, setKeyword] = useState(null);
  const [onSearch, setOnSearch] = useState(false);
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)
  const updateId = fees.id;
  const [isVerified, setIsVerified] = useState(false)

  // Select

  const [civilStatusSelect, setCivilStatusSelect] = useState([])
  const [religionSelect, setReligionSelect] = useState([])
  const [countrySelect, setCountrySelect] = useState([])
  const [provinceSelect, setProvinceSelect] = useState([])
  const [municipalitySelect, setMunicipalitySelect] = useState([])
  const [courseSelect, setCourseSelect] = useState([])
  const [yearLevelSelect, setYearLevelSelect] = useState([])
  const [schoolSelect, setSchoolSelect] = useState([])
  const [familyPositionSelect, setFamilyPositionSelect] = useState([])
  const [nationalitySelect, setNationalitySelect] = useState([])

  let today = new Date();
  let date = today.getFullYear() + '-' + (today.getMonth() + 1) + '-' + today.getDate();
  let time = today.getHours() + ":" + today.getMinutes() + ":" + today.getSeconds();
  let dateTime = date + ' ' + time;

  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    history.push(`${admissionsTypes.CREATE_LINK}/new-applicant`)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {

    console.log(data)
    setRowValue({ ...data, DateCreated: moment(dateTime).format('YYYY-MM-DD') })
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
  }


  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  const executeGetListOfApplicant = async () => {
    setResults([])
    await getApplicants({
      schoolYear: auth.data.schoolyears.admissions.SchoolYear,
      semesterId: auth.data.schoolyears.admissions.SemesterId,
      isVerified: isVerified,
      page: page,
      rowsperpage: rowsPerPage,
      keyword: keyword
    })
      .then(response => {
        console.log(response)
        setResults(response.data);
        setTotalRecord(response.totalRecord);

      })
      .catch(error => {

        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

      })
  }

  // [AMCP - 11/23/2023] - Added Username to values being passed to API
  const onDeleteElement = async (id, val) => {
    await deleteApplicants({ studentInfoId: val.studentInfoId, studentId: val.studentId, username: auth.data.Username })
      .then(res => {
        setIsOpenDelete(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Students has been Deleted.' });
      })
      .catch(e => {

      })
  }


  const onUpdateApplicant = async (id, data) => {
    console.log(data)
    let studentSiblings = data.studentSiblings;
    await updateApplicants({ ...data, createdBy: auth.data.Username, dateUpdated: moment(dateTime).format('YYYY-MM-DD'), updatedBy: auth.data.Username, studentTypeId: null })
      .then(async res => {
        const updatedStudentSiblings = studentSiblings.map(sibling => {
          return { ...sibling, studentInfoId: res.data.StudentInfoId };
        });
        console.log(updatedStudentSiblings)
        await addUpdateStudentSiblings(updatedStudentSiblings)
          .then(resp => {
            onUpdateGradeElementClose()
            executeGetListOfApplicant()
          })

      })
  }

  const onChangeCheckbox = (checkbox) => {
    console.log(checkbox)
    setIsVerified(checkbox)
  }
  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      studentId: props.value !== undefined && props.value !== null ? props.value.studentId : null,
      studentInfoId: props.value !== undefined && props.value !== null ? props.value.studentInfoId : null,
      lastName: props.value !== undefined && props.value !== null ? props.value.lastName : null,
      firstName: props.value !== undefined && props.value !== null ? props.value.firstName : null,
      middleName: props.value !== undefined && props.value !== null ? props.value.middleName : null,
      schoolId: props.value !== undefined && props.value !== null ? props.value.lastSchoolId : null,
      gender: props.value !== undefined && props.value !== null ? props.value.gender : null,
      civilStatusId: props.value !== undefined && props.value !== null ? props.value.civilStatusId : null,
      religionId: props.value !== undefined && props.value !== null ? props.value.religionId : null,
      birthDate: props.value !== undefined && props.value !== null ? moment(props.value.birthDate).format('YYYY-MM-DD') : null,
      birthPlace: props.value !== undefined && props.value !== null ? props.value.birthPlace : null,
      telNo: props.value !== undefined && props.value !== null ? props.value.telNo : null,
      cellNo: props.value !== undefined && props.value !== null ? props.value.celNo : null,
      emailAddress: props.value !== undefined && props.value !== null ? props.value.emailAddress : null,
      countryId: props.value !== undefined && props.value !== null ? props.value.countryId : null,
      provinceId: props.value !== undefined && props.value !== null ? props.value.provinceId : null,
      municipalityId: props.value !== undefined && props.value !== null ? props.value.municipalityId : null,
      barangay: props.value !== undefined && props.value !== null ? props.value.barangay : null,
      courseId: props.value !== undefined && props.value !== null ? props.value.courseId : null,
      yearLevelId: props.value !== undefined && props.value !== null ? props.value.yearLevelId : null,
      schoolId: props.value !== undefined && props.value !== null ? props.value.schoolId : null,
      fatherName: props.value !== undefined && props.value !== null ? props.value.fatherName : null,
      fatherBusinessName: props.value !== undefined && props.value !== null ? props.value.fatherBusinessName : null,
      fatherBusinessAddress: props.value !== undefined && props.value !== null ? props.value.fatherBusinessAddress : null,
      fatherBusinessContactNo: props.value !== undefined && props.value !== null ? props.value.fatherBusinessContactNo : null,
      fatherHomeAddress: props.value !== undefined && props.value !== null ? props.value.fatherHomeAddress : null,
      fatherMaritalStatus: props.value !== undefined && props.value !== null ? props.value.fatherMaritalStatus : null,
      fatherCitizen: props.value !== undefined && props.value !== null ? props.value.fatherCitizen : null,
      fatherHighestEducationalAttainment: props.value !== undefined && props.value !== null ? props.value.fatherHighestEducationalAttainment : null,
      motherName: props.value !== undefined && props.value !== null ? props.value.motherName : null,
      motherBusinessName: props.value !== undefined && props.value !== null ? props.value.motherBusinessName : null,
      motherBusinessAddress: props.value !== undefined && props.value !== null ? props.value.motherBusinessAddress : null,
      motherBusinessContactNo: props.value !== undefined && props.value !== null ? props.value.motherBusinessContactNo : null,
      motherHomeAddress: props.value !== undefined && props.value !== null ? props.value.motherHomeAddress : null,
      motherMaritalStatus: props.value !== undefined && props.value !== null ? props.value.motherMaritalStatus : null,
      motherCitizen: props.value !== undefined && props.value !== null ? props.value.motherCitizen : null,
      motherHighestEducationalAttainment: props.value !== undefined && props.value !== null ? props.value.motherHighestEducationalAttainment : null,
      contactPerson: props.value !== undefined && props.value !== null ? props.value.contactPerson : null,
      contactPersonAddress: props.value !== undefined && props.value !== null ? props.value.contactPersonAddress : null,
      contactPersonCelNo: props.value !== undefined && props.value !== null ? props.value.contactPersonCelNo : null,
      preferredCourseId1: props.value !== undefined && props.value !== null ? props.value.preferredCourseId1 : null,
      preferredCourseId2: props.value !== undefined && props.value !== null ? props.value.preferredCourseId2 : null,
      preferredSchoolId1: props.value !== undefined && props.value !== null ? props.value.preferredSchoolId1 : null,
      preferredSchoolId2: props.value !== undefined && props.value !== null ? props.value.preferredSchoolId2 : null,
      facebook: props.value !== undefined && props.value !== null ? props.value.facebook : null,
      twitter: props.value !== undefined && props.value !== null ? props.value.twitterX : null,
      instagram: props.value !== undefined && props.value !== null ? props.value.instagram : null,
      guardianOccupation: props.value !== undefined && props.value !== null ? props.value.contactPersonOccupation : null,
      familyMonthlyIncome: props.value !== undefined && props.value !== null ? props.value.familyMonthlyIncome : null,
      preElementary: props.value !== undefined && props.value !== null ? props.value.preElementary : null,
      elementary: props.value !== undefined && props.value !== null ? props.value.elementary : null,
      jrHigh: props.value !== undefined && props.value !== null ? props.value.jrHigh : null,
      srHigh: props.value !== undefined && props.value !== null ? props.value.srHigh : null,
      seniorHighStrand: props.value !== undefined && props.value !== null ? props.value.strand : null,
      familyPositionId: props.value !== undefined && props.value !== null ? props.value.familyPositionId : null,
      preElemNotListed: props.value !== undefined && props.value !== null ? props.value.preElemNotListedSchool : null,
      elemNotListed: props.value !== undefined && props.value !== null ? props.value.elemNotListedSchool : null,
      jrHighNotListed: props.value !== undefined && props.value !== null ? props.value.jrHighNotListedSchool : null,
      srHighNotListed: props.value !== undefined && props.value !== null ? props.value.srHighNotListedSchool : null,
      preElemTo: props.value !== undefined && props.value !== null ? props.value.preElemTo : null,
      preElemFrom: props.value !== undefined && props.value !== null ? props.value.preElemFrom : null,
      elemFrom: props.value !== undefined && props.value !== null ? props.value.elemFrom : null,
      elemTo: props.value !== undefined && props.value !== null ? props.value.elemTo : null,
      jrFrom: props.value !== undefined && props.value !== null ? props.value.jrFrom : null,
      jrTo: props.value !== undefined && props.value !== null ? props.value.jrTo : null,
      srFrom: props.value !== undefined && props.value !== null ? props.value.srFrom : null,
      srTo: props.value !== undefined && props.value !== null ? props.value.srTo : null,
      lastSchoolAttendedNotListed: props.value !== undefined && props.value !== null ? props.value.schoolNotListed : null,
      studentSiblings: props.value !== undefined && props.value !== null ? props.value.studentSiblings : null



    })


    const [switchState, setSwitchState] = useState({
      isTransferee: props.value !== undefined && props.value !== null ? props.value.isTransferee === 1 ? true : false : false,
      IsForReservation: false

    });

    const switchHandleChange = name => event => {
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects

    const selectHandleChange = name => event => {
      console.log(event)
      let parts = name.split("_");
      console.log(parts)
      if (parts[0] === 'StudentSiblings') {
        const updatedSiblings = data.studentSiblings.map(sibling => {
          if (sibling.studentSiblingId == parts[1]) {
            return { ...sibling, [parts[2]]: event };
          }
          return sibling;
        });
        props.onSave({
          ...data,
          [name]: event,
          studentSiblings: updatedSiblings,
          isTransferee: switchState.isActive == true ? 1 : 0
        })
        setData(prevData => {
          if (parts[0] === 'StudentSiblings') {
            const updatedSiblings = prevData.studentSiblings.map(sibling => {
              if (sibling.studentSiblingId == parts[1]) {
                return { ...sibling, [parts[2]]: event };
              }
              return sibling;
            });
            return { ...prevData, studentSiblings: updatedSiblings };
          } else {
            return { ...prevData, [name]: event };
          }
        });
      } else {
        props.onSave({
          ...data,
          [name]: event,
          isTransferee: switchState.isActive == true ? 1 : 0
        })
        console.log(name + '_' + event);
        setData({ ...data, [name]: event })
      }


    }
    const selectHandleChangeInput = name => event => {
      let parts = name.split("_");
      console.log(parts)
      setData(prevData => {
        if (parts[0] == 'StudentSiblings') {
          const updatedSiblings = prevData.studentSiblings.map(sibling => {
            console.log(sibling.studentSiblingId + '_' + parts[1])
            if (sibling.studentSiblingId == parts[1]) {
              return { ...sibling, [parts[2]]: event.target.value };
            }
            return sibling;
          });
          return { ...prevData, studentSiblings: updatedSiblings };
        } else {
          return { ...prevData, [name]: event.target.value };
        }
      });
    };






    useEffect(() => {
      const delayDebounceFn = setTimeout(() => {
        props.onSave({
          ...data,
          isTransferee: switchState.isActive == true ? 1 : 0
        })
      }, 500)

      return () => clearTimeout(delayDebounceFn)
    }, [data, switchState])



    console.log(props.value)
    console.log(data)
    console.log(data.municipalityId)
    console.log(props.civilStatusSelect)
    console.log(keyword)
    return (
      <>

        <div className="card-body">
          <div className='text-center'>
            <img style={{ width: '250px' }} src={FaithLogo} />
            <p className='my-5'><strong>NOTE: </strong> The personal information submitted herein shall be used for the initial phase of the application for Admission Examination.</p>

          </div>

          <>
            <section className="kt-form">

              <div className="kt-portlet__body ">
                <h4><i className="fa fa-child icon-xl ml-auto"></i> &nbsp; Applicant Information</h4>

                <hr />

                <div className="row">

                  <div className="form-group col-12 col-md-4 mb-3">
                    <label className="col-form-label p-0">Last Name <span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('lastName')}
                      type="text"
                      name="lastName"
                      defaultValue={data.lastName}
                      className={`form-control`}

                      autoComplete="off"
                      maxLength="100"

                    />



                  </div>
                  <div className="form-group col-12 col-md-4 mb-3">
                    <label className="col-form-label p-0">First Name <span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('firstName')}
                      type="text"
                      name="firstName"
                      defaultValue={data.firstName}
                      className={`form-control `}

                      autoComplete="off"
                      maxLength="100"

                    />


                  </div>
                  <div className="form-group col-12 col-md-4 mb-3">
                    <label className="col-form-label p-0">Middle Name <span className="text-danger">*</span> </label>
                    <input
                      onChange={selectHandleChangeInput('middleName')}
                      type="text"
                      name="middleName"
                      defaultValue={data.middleName}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>


                </div>

                <div className="row">
                  <div className="form-group col mb-3">
                    <IMSelect
                      data={schoolSelect}
                      onHandleChange={selectHandleChange('schoolId')}
                      refClassContainer="form-group mb-3"
                      name="lastSchoolName"
                      isRequired={true}
                      withLabel={true}
                      label="Last School Attended"
                      placeHolder="Last School Attended"
                      forwardRef={setValue}
                      selectedId={data.schoolId == -1 ? null : data.schoolId}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}

                    />

                    {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                  </div>

                  {(data.schoolId == null || data.schoolId == -1) &&
                    <div className="form-group col">
                      <label className="col-form-label p-0">Last School (If not Listed)<span className="text-danger">*</span>  </label>
                      <input
                        onChange={selectHandleChangeInput('lastSchoolAttendedNotListed')}
                        type="text"
                        name="lastSchoolAttendedNotListed"
                        defaultValue={data.lastSchoolAttendedNotListed}
                        className={`form-control mt-2`}
                        ref={() => { }}
                        autoComplete="off"
                        maxLength="100"

                      />
                    </div>
                  }

                  <div className='col mb-0'>
                    <IMSelect
                      data={civilStatusSelect}
                      onHandleChange={selectHandleChange('civilStatusId')}
                      refClassContainer="form-group mb-3"
                      name="civilStatusId"
                      isRequired={true}
                      withLabel={true}
                      label="Civil Status"
                      placeHolder="Civil Status"
                      forwardRef={setValue}
                      selectedId={data.civilStatusId}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}

                    />
                  </div>
                  <div className='col mb=0'>
                    <IMSelect
                      data={religionSelect}
                      onHandleChange={selectHandleChange('religionId')}
                      refClassContainer="form-group mb-3"
                      name="religion"
                      isRequired={true}
                      withLabel={true}
                      label="Religion"
                      placeHolder="Religion"
                      forwardRef={setValue}
                      selectedId={data.religionId}
                      refClassName={``}

                      withDescription={false}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}

                    />
                  </div>
                  <div className="form-group col mb-3">
                    <label className="col-form-label p-0">Date of Birth <span className="text-danger">*</span> </label>
                    <input
                      onChange={selectHandleChangeInput('birthDate')}
                      type="date"
                      name="birthDate"
                      defaultValue={data.birthDate}
                      className={`form-control `}


                    />

                  </div>

                </div>
                <div className='row'>

                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Place of Birth <span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('birthPlace')}
                      type="text"
                      name="birthPlace"
                      defaultValue={data.birthPlace}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />
                  </div>

                  <div className='col-12 col-md-3 mb-0'>
                    <IMSelect
                      data={props.familyPositionSelect}
                      onHandleChange={selectHandleChange('familyPositionId')}
                      refClassContainer="form-group mb-3"
                      name="familyPositionId"
                      isRequired={true}
                      withLabel={true}
                      label="Position in the Family"
                      placeHolder="Position in the Family"
                      forwardRef={setValue}
                      selectedId={data.familyPositionId}
                      refClassName={``}

                      withDescription={false}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>

                  <div className='col-12 col-md-3 mb=0'>
                    <IMSelect

                      data={yearLevelSelect}
                      onHandleChange={selectHandleChange('yearLevelId')}
                      refClassContainer="form-group mb-3"
                      name="yearLevel"
                      isRequired={true}
                      withLabel={true}
                      label="Year Level"
                      placeHolder=""
                      forwardRef={setValue}
                      selectedId={data.yearLevelId}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[


                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>
                  <div className='col-12 col-md-3 mb-0'>
                    <IMSelect
                      onHandleChange={selectHandleChange('gender')}
                      refClassContainer="form-group mb-3"
                      name="gender"
                      isRequired={true}
                      withLabel={true}
                      label="Gender"
                      placeHolder="Gender"
                      forwardRef={setValue}
                      selectedId={data.gender}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={true}
                      refStaticData={[
                        { id: '', label: '' },
                        { id: 'M', label: 'Male' },
                        { id: 'F', label: 'Female' }
                      ]}
                      field={{
                        tableId: '',
                        display: ''
                      }}

                    />
                  </div>

                </div>
                <div className='row'>

                  {switchState.IsForReservation == false
                    &&
                    <div className='col-12 col-md-3 mb=0'>
                      <IMSelect
                        data={courseSelect}
                        onHandleChange={selectHandleChange('courseId')}
                        refClassContainer="form-group mb-3"
                        name="coursese"
                        isRequired={true}
                        withLabel={true}
                        label="Course"
                        placeHolder=""
                        forwardRef={setValue}
                        selectedId={data.courseId}
                        refClassName={``}
                        withDescription={false}
                        // description={`Please select your gender.`}
                        refDisabled={true}
                        refIsStatic={false}
                        refStaticData={[

                        ]}
                        field={{
                          tableId: 'tableId',
                          display: 'code'
                        }}
                      />
                    </div>

                  }
                  {switchState.IsForReservation == false && <div className='col-12 col-md-3 mb=0'></div>}


                  {/* <div className='col-12 col-md-3 mb=0'></div> */}






                </div>

                {switchState.IsForReservation == true &&
                  <>
                    <div className='row mt-5'>
                      <div className='col-12 col-md-4 mb=0'>
                        <IMSelect
                          data={courseSelect}
                          onHandleChange={selectHandleChange('courseId')}
                          refClassContainer="form-group mb-3"
                          name="coursese"
                          isRequired={true}
                          withLabel={true}
                          label="Reserved Course"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.courseId}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4 mb=0'>
                        <IMSelect
                          data={courseSelect}
                          onHandleChange={selectHandleChange('preferredCourseId1')}
                          refClassContainer="form-group mb-3"
                          name="preferredCourseId1"
                          isRequired={true}
                          withLabel={true}
                          label="Preferred Course 1"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.preferredCourseId1}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4 mb=0'>
                        <IMSelect
                          data={courseSelect}
                          onHandleChange={selectHandleChange('preferredCourseId2')}
                          refClassContainer="form-group mb-3"
                          name="preferredCourseId2"
                          isRequired={true}
                          withLabel={true}
                          label="Preferred Course 2"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.preferredCourseId2}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                    </div>
                    <div className='row mt-5'>
                      <div className="form-group col-12 col-md-6 mb-3">
                        <IMSelect
                          data={schoolSelect}
                          onHandleChange={selectHandleChange('preferredSchoolId1')}
                          refClassContainer="form-group mb-3"
                          name="preferredSchoolId1"
                          isRequired={true}
                          withLabel={true}
                          label="Preferred School 1"
                          placeHolder="Preferred School"
                          forwardRef={setValue}
                          selectedId={data.preferredSchoolId1}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />

                        {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                      </div>
                      <div className="form-group col-12 col-md-6 mb-3">
                        <IMSelect
                          data={schoolSelect}
                          onHandleChange={selectHandleChange('preferredSchoolId2')}
                          refClassContainer="form-group mb-3"
                          name="preferredSchoolId2"
                          isRequired={true}
                          withLabel={true}
                          label="Preferred School 2"
                          placeHolder="Preferred School"
                          forwardRef={setValue}
                          selectedId={data.preferredSchoolId2}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />

                        {/* <span className="form-text text-muted">High School / If Transferee - last school attended</span> */}

                      </div>
                    </div>

                  </>
                }
                <br /> <br />
                <h4><i className="fa fa-id-card icon-xl ml-auto"></i> &nbsp; Applicant Contact Information</h4>

                <hr />
                <div className='row'>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Landline #<span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('telNo')}
                      type="text"
                      name="landline"
                      defaultValue={data.telNo}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Mobile # <span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('cellNo')}
                      type="text"
                      name="mobile"
                      defaultValue={data.cellNo}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Email <span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('emailAddress')}
                      type="email"
                      name="emailAddress"
                      defaultValue={data.emailAddress}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Facebook <span className="text-info">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('facebook')}
                      type="text"
                      name="facebook"
                      defaultValue={data.facebook}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>
                </div>

                <div className='row'>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Twitter / X <span className="text-info">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('twitter')}
                      type="text"
                      name="twitter"
                      defaultValue={data.twitter}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Instagram <span className="text-info">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('instagram')}
                      type="text"
                      name="instagram"
                      defaultValue={data.instagram}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <IMSelect
                      data={countrySelect}
                      onHandleChange={selectHandleChange('countryId')}
                      refClassContainer="form-group mb-3"
                      name="countryId"
                      isRequired={true}
                      withLabel={true}
                      label="Country"
                      placeHolder="Country"
                      forwardRef={setValue}
                      selectedId={data.countryId}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <IMSelect
                      data={provinceSelect}
                      onHandleChange={selectHandleChange('provinceId')}
                      refClassContainer="form-group mb-3"
                      name="provinceId"
                      isRequired={true}
                      withLabel={true}
                      label="Province"
                      placeHolder="Province"
                      forwardRef={setValue}
                      selectedId={data.provinceId}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>
                </div>

                <div className='row'>

                  <div className="form-group col-12 col-md-3 mb-3">
                    <IMSelect
                      data={props.municipalitySelect}
                      onHandleChange={selectHandleChange('municipalityId')}
                      refClassContainer="form-group mb-3"
                      name="municipalityId"
                      isRequired={true}
                      withLabel={true}
                      label="Municipality"
                      placeHolder="Municipality"
                      forwardRef={setValue}
                      selectedId={data.municipalityId}
                      refClassName={``}
                      withDescription={false}
                      // description={`Please select your gender.`}
                      refDisabled={true}
                      refIsStatic={false}
                      refStaticData={[

                      ]}
                      field={{
                        tableId: 'tableId',
                        display: 'code'
                      }}
                    />
                  </div>
                  <div className="form-group col-12 col-md-3 mb-3">
                    <label className="col-form-label p-0">Barangay <span className="text-danger">*</span>  </label>
                    <input
                      onChange={selectHandleChangeInput('barangay')}
                      type="text"
                      name="barangay"
                      defaultValue={data.barangay}
                      className={`form-control `}
                      autoComplete="off"
                      maxLength="100"

                    />

                  </div>




                </div>




              </div>

              <div className="kt-portlet__body mt-5">
                <h4><i className="fas fa-users icon-xl"></i> Family Background</h4> <hr />

                <div className={'card mr-2'}  >

                  <h5 className="card-header bg-secondary p-5 is-invalid">{'Father\'s Information'}</h5>
                  <div className='card-body p-3'>
                    <div className='row'>




                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Full Name <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('fatherName')}
                          type="text"
                          name="fatherName"
                          defaultValue={data.fatherName}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('fatherBusinessName')}
                          type="text"
                          name="fatherOccupation"
                          defaultValue={data.fatherBusinessName}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>


                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Occupation Address <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('fatherBusinessAddress')}
                          type="text"
                          name="fatherOccupationAddress"
                          defaultValue={data.fatherBusinessAddress}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-12 col-md-6 mb-3">
                        <label className="col-form-label p-0">Contact# <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('fatherBusinessContactNo')}
                          type="text"
                          name="fatherContactNumber"
                          defaultValue={data.fatherBusinessContactNo}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>
                      <div className="form-group col-12 col-md-6 mb-3">
                        <label className="col-form-label p-0">Home Address <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('fatherHomeAddress')}
                          type="text"
                          name="fatherHomeAddress"
                          defaultValue={data.fatherHomeAddress}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                    </div>
                    <div className='row'>

                      <div className='col-12 col-md-4 mb-0'>
                        <IMSelect
                          data={civilStatusSelect}
                          onHandleChange={selectHandleChange('fatherMaritalStatus')}
                          refClassContainer="form-group mb-3"
                          name="fatherMaritalStatus"
                          isRequired={false}
                          withLabel={true}
                          label="Marital Status"
                          placeHolder="Marital Status"
                          forwardRef={setValue}
                          selectedId={data.fatherMaritalStatus}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4 mb-0'>
                        <IMSelect
                          data={props.nationalitySelect}
                          onHandleChange={selectHandleChange('fatherCitizen')}
                          refClassContainer="form-group mb-3"
                          name="fatherCitizenship"
                          isRequired={false}
                          withLabel={true}
                          label="Citizenship"
                          placeHolder="Citizenship"
                          forwardRef={setValue}
                          selectedId={data.fatherCitizen}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4 mb-0'>
                        <IMSelect
                          onHandleChange={selectHandleChange(`fatherHighestEducationalAttainment`)}
                          refClassContainer="form-group mb-3"
                          name={`fatherHighestEducationalAttainment`}
                          isRequired={false}
                          withLabel={true}
                          label="Highest Educational Attainment"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.fatherHighestEducationalAttainment}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 1, label: 'Preschool' },
                            { id: 2, label: 'Elementary' },
                            { id: 3, label: 'Highschool' },
                            { id: 4, label: 'Bachelor Degree' },
                            { id: 5, label: 'Masteral Degree' },
                            { id: 6, label: 'Doctoral Degree' }


                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />

                      </div>

                    </div>
                  </div>

                </div>



                <div className={'card mt-5 mr-2'}  >

                  <h5 className="card-header bg-secondary p-5 is-invalid">{'Mother\'s Information'}</h5>
                  <div className='card-body p-3'>
                    <div className='row'>




                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Full Name <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('motherName')}
                          type="text"
                          name="motherName"
                          defaultValue={data.motherName}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('motherBusinessName')}
                          type="text"
                          name="motherOccupation"
                          defaultValue={data.motherBusinessName}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>


                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Occupation Address <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('motherBusinessAddress')}
                          type="text"
                          name="motherOccupationAddress"
                          defaultValue={data.motherBusinessAddress}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>
                    </div>
                    <div className='row'>
                      <div className="form-group col-12 col-md-6 mb-3">
                        <label className="col-form-label p-0">Contact# <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('motherBusinessContactNo')}
                          type="text"
                          name="motherContactNumber"
                          defaultValue={data.motherBusinessContactNo}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>
                      <div className="form-group col-12 col-md-6 mb-3">
                        <label className="col-form-label p-0">Home Address <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('motherHomeAddress')}
                          type="text"
                          name="motherHomeAddress"
                          defaultValue={data.fatherHomeAddress}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                    </div>
                    <div className='row'>

                      <div className='col-12 col-md-4 mb-0'>
                        <IMSelect
                          data={civilStatusSelect}
                          onHandleChange={selectHandleChange('motherMaritalStatus')}
                          refClassContainer="form-group mb-3"
                          name="motherMaritalStatus"
                          isRequired={false}
                          withLabel={true}
                          label="Marital Status"
                          placeHolder="Marital Status"
                          forwardRef={setValue}
                          selectedId={data.motherMaritalStatus}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4 mb-0'>
                        <IMSelect
                          data={props.nationalitySelect}
                          onHandleChange={selectHandleChange('motherCitizen')}
                          refClassContainer="form-group mb-3"
                          name="motherCitizen"
                          isRequired={false}
                          withLabel={true}
                          label="Citizenship"
                          placeHolder="Citizenship"
                          forwardRef={setValue}
                          selectedId={data.motherCitizen}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />
                      </div>
                      <div className='col-12 col-md-4 mb-0'>
                        <IMSelect
                          onHandleChange={selectHandleChange(`motherHighestEducationalAttainment`)}
                          refClassContainer="form-group mb-3"
                          name={`motherHighestEducationalAttainment`}
                          isRequired={false}
                          withLabel={true}
                          label="Highest Educational Attainment"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.motherHighestEducationalAttainment}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 1, label: 'Preschool' },
                            { id: 2, label: 'Elementary' },
                            { id: 3, label: 'Highschool' },
                            { id: 4, label: 'Bachelor Degree' },
                            { id: 5, label: 'Masteral Degree' },
                            { id: 6, label: 'Doctoral Degree' }


                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />

                      </div>

                    </div>
                  </div>

                </div>


                {/* {siblingsComponent} */}


                {/* <FamilyIncomeForm
infoHandler={monthlyIncomeInfoHandler}
types={applicantTypes}
getFamilyNetIncome={getFamilyNetIncome}
getEducationalPlan={getEducationalPlan}
getScholarship={getScholarship}
getFamilyPosition={getFamilyPosition}
netIncomeSelect={familyIncomeSelect}
educationalPlanSelect={educationalPlanSelect}
scholarshipSelect={scholarshipSelect}
familyPositionSelect={familyPositionSelect}
/> */}

                {data.studentSiblings != null && data.studentSiblings != undefined && data.studentSiblings.map((d, index) => {
                  console.log(d)
                  console.log(data.studentSiblings.length)
                  return (
                    <>
                      <div className={`card mr-2 mb-5  ${index == data.studentSiblings.length - 1 ? 'slide-inStudentForm' : ''}`}  >
                        <section className='card-header bg-secondary p-5 is-invalid'>

                          <h5 className="float-left">{`Sibling #${index + 1} Information`}</h5>
                          {/* {d == studentSiblingIndex.length - 1 &&
                                                        <button type='button' onClick={() => { setStudentSiblingIndex((prevData) => { return [...prevData, d + 1] }) }} className='btn btn-primary btn-sm float-right'>Add more Sibling</button>

                                                    } */}
                        </section>
                        <div className='card-body p-3'>
                          <div className='row'>




                            <div className="form-group col-12 col-md-4 mb-3">
                              <label className="col-form-label p-0">Full Name   </label>
                              <input
                                onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_name`)}
                                type="text"
                                name={`name`}
                                defaultValue={data.studentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).name}
                                className={`form-control mt-2 `}

                                autoComplete="off"
                                maxLength="100"

                              />

                            </div>



                            <div className="form-group col-12 col-md-4 mb-3">
                              <label className="col-form-label p-0">School / Company  </label>
                              <input
                                onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_affiliation`)}
                                type="text"
                                name={`affiliation`}
                                defaultValue={data.studentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).affiliation}
                                className={`form-control mt-2 `}

                                autoComplete="off"
                                maxLength="100"

                              />

                            </div>

                            <div className="form-group col-12 col-md-4 mb-3">
                              <label className="col-form-label p-0">Contact#  </label>
                              <input
                                onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_contactNo`)}
                                type="number"
                                name={`contactNo`}
                                defaultValue={data.studentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).contactNo}
                                className={`form-control mt-2`}

                                autoComplete="off"
                                maxLength="100"

                              />

                            </div>



                          </div>
                          <div className='row'>
                            <div className="form-group col-12 col-md-4 mb-3">
                              <label className="col-form-label p-0">Age  </label>
                              <input
                                onChange={selectHandleChangeInput(`StudentSiblings_${d.studentSiblingId}_age`)}
                                type="text"
                                name={`age`}
                                defaultValue={data.studentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).age}
                                className={`form-control mt-2 `}

                                autoComplete="off"
                                maxLength="100"

                              />

                            </div>
                            <div className='col-12 col-md-4 mb-0'>
                              <IMSelect
                                data={civilStatusSelect}
                                onHandleChange={selectHandleChange(`StudentSiblings_${d.studentSiblingId}_civilStatusId`)}
                                refClassContainer="form-group mb-3"
                                name={`civilStatusId`}
                                isRequired={false}
                                withLabel={true}
                                label="Marital Status"
                                placeHolder="Marital Status"
                                forwardRef={setValue}
                                selectedId={data.studentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).civilStatusId}
                                refClassName={``}
                                withDescription={false}
                                // description={`Please select your gender.`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[

                                ]}
                                field={{
                                  tableId: 'tableId',
                                  display: 'code'
                                }}

                              />
                            </div>
                            <div className='col-12 col-md-4 mb-0'>
                              <IMSelect

                                data={yearLevelSelect}
                                onHandleChange={selectHandleChange(`StudentSiblings_${d.studentSiblingId}_yearLevelId`)}
                                refClassContainer="form-group mb-3"
                                name={`yearLevelId`}
                                isRequired={false}
                                withLabel={true}
                                label="Year Level"
                                placeHolder=""
                                forwardRef={setValue}
                                selectedId={data.studentSiblings.find(siblings => siblings.studentSiblingId == d.studentSiblingId).yearLevelId}
                                refClassName={``}
                                withDescription={false}
                                // description={`Please select your gender.`}
                                refDisabled={true}
                                refIsStatic={false}
                                refStaticData={[


                                ]}
                                field={{
                                  tableId: 'tableId',
                                  display: 'code'
                                }}
                              />
                            </div>

                          </div>

                        </div>

                      </div>
                    </>
                  )
                })}

              </div>
              <div className="kt-portlet__body my-5">
                <div className={'card mr-2'}  >
                  <div className='card-header bg-secondary pt-4 pl-5 pr-5 pb-0 is-invalid '>
                    <h5 className="mr-5 mb-0">Guardian</h5>

                  </div>

                  <div className='card-body p-3'>

                    <div className='row'>
                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Guardian <span className="text-danger">*</span>  </label>
                        <input
                          type="text"
                          name="contactPerson"
                          defaultValue={data.contactPerson}
                          className={`form-control `}
                          onChange={selectHandleChangeInput('contactPerson')}
                          autoComplete="off"
                          maxLength="100"
                        />


                      </div>
                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Address<span className="text-danger">*</span>  </label>
                        <input
                          type="text"
                          name="contactPersonAddress"
                          defaultValue={data.contactPersonAddress}
                          className={`form-control`}
                          onChange={selectHandleChangeInput('contactPersonAddress')}
                          autoComplete="off"
                          maxLength="100"
                        />

                      </div>
                      <div className="form-group col-12 col-md-4 mb-3">
                        <label className="col-form-label p-0">Contact No/s<span className="text-danger">*</span>  </label>
                        <input
                          type="number"
                          name="contactPersonCelNo"
                          defaultValue={data.contactPersonCelNo}
                          className={`form-control`}
                          onChange={selectHandleChangeInput('contactPersonCelNo')}
                          autoComplete="off"
                          maxLength="100"
                        />

                      </div>
                      <div className="form-group col-12 col-md-4 mb-5">
                        <label className="col-form-label p-0">Relationship <span className="text-danger">*</span>  </label>
                        <input
                          type="text"
                          name="relationshipGuardian"
                          defaultValue={data.relationshipGuardian}
                          className={`form-control `}
                          onChange={selectHandleChangeInput('relationshipGuardian')}
                          autoComplete="off"
                          maxLength="100"
                        />

                      </div>
                      <div className="form-group col-12 col-md-4 mb-5">
                        <label className="col-form-label p-0">Occupation <span className="text-danger">*</span>  </label>
                        <input
                          type="text"
                          name="guardianOccupation"
                          defaultValue={data.guardianOccupation}
                          className={`form-control `}
                          onChange={selectHandleChangeInput('guardianOccupation')}
                          autoComplete="off"
                          maxLength="100"
                        />

                      </div>

                      <div className="form-group col-12 col-md-4 mb-5">
                        <label className="col-form-label p-0">Family Monthly Income <span className="text-danger">*</span>  </label>
                        <div class="input-group mb-3">
                          <div class="input-group-prepend">
                            <span class="input-group-text" id="basic-addon1">₱</span>
                          </div>
                          <input
                            type="number"
                            name="familyMonthlyIncome"
                            defaultValue={data.familyMonthlyIncome}
                            className={`form-control `}
                            onChange={selectHandleChangeInput('familyMonthlyIncome')}
                            autoComplete="off"
                            maxLength="100"
                          />
                        </div>

                      </div>
                    </div>
                  </div>
                </div>



              </div>

              <div className="kt-portlet__body mt-5">
                <h4><i className="fas fa-user-graduate icon-xl"></i> Educational Background</h4> <hr />
                {/* PRE ELEM */}
                <div className='card mt-5'>

                  <h5 className="card-header bg-secondary p-5">Pre - Elementary</h5>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          data={schoolSelect}
                          onHandleChange={selectHandleChange(`preElementary`)}
                          refClassContainer="form-group mb-3"
                          name={`preElementary`}
                          isRequired={true}
                          withLabel={true}
                          label="School Name"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.preElementary}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />



                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                        <input
                          disabled={data.preElementary == null ? false : true}
                          onChange={selectHandleChangeInput('preElemNotListed')}
                          type="text"
                          name="preElementaryNotListedSchool"
                          defaultValue={data.preElemNotListed}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('preElemTo')}
                          refClassContainer="form-group mb-3"
                          name="preElemTo"
                          isRequired={true}
                          withLabel={true}
                          label="From"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.preElemTo}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('preElemFrom')}
                          refClassContainer="form-group mb-3"
                          name="preElemFrom"
                          isRequired={true}
                          withLabel={true}
                          label="To"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.preElemFrom}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                {/* ELEM */}
                <div className='card mt-5'>

                  <h5 className="card-header bg-secondary p-5">Elementary</h5>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          data={schoolSelect}
                          onHandleChange={selectHandleChange(`elementary`)}
                          refClassContainer="form-group mb-3"
                          name={`elementary`}
                          isRequired={true}
                          withLabel={true}
                          label="School Name"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.elementary}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />



                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                        <input
                          disabled={data.elementary == null ? false : true}
                          onChange={selectHandleChangeInput('elemNotListed')}
                          type="text"
                          name="elementaryNotListedSchool"
                          defaultValue={data.elemNotListed}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('elemFrom')}
                          refClassContainer="form-group mb-3"
                          name="elemFrom"
                          isRequired={true}
                          withLabel={true}
                          label="From"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.elemFrom}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('elemTo')}
                          refClassContainer="form-group mb-3"
                          name="elemTo"
                          isRequired={true}
                          withLabel={true}
                          label="To"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.elemTo}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                {/* JR HIGH */}
                <div className='card mt-5'>

                  <h5 className="card-header bg-secondary p-5">Junior High</h5>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          data={schoolSelect}
                          onHandleChange={selectHandleChange(`jrHigh`)}
                          refClassContainer="form-group mb-3"
                          name={`jrHigh`}
                          isRequired={true}
                          withLabel={true}
                          label="School Name"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.jrHigh}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />



                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                        <input
                          disabled={data.jrHigh == null ? false : true}
                          onChange={selectHandleChangeInput('jrHighNotListed')}
                          type="text"
                          name="juniorHighNotListedSchool"
                          defaultValue={data.jrHighNotListed}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('jrFrom')}
                          refClassContainer="form-group mb-3"
                          name="jrFrom"
                          isRequired={true}
                          withLabel={true}
                          label="From"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.jrFrom}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('jrTo')}
                          refClassContainer="form-group mb-3"
                          name="jrTo"
                          isRequired={true}
                          withLabel={true}
                          label="To"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.jrTo}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                    </div>

                  </div>
                </div>

                {/* SR HIGH */}
                <div className='card mt-5'>

                  <h5 className="card-header bg-secondary p-5">Senior High</h5>
                  <div className='card-body p-3'>
                    <div className='row'>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          data={schoolSelect}
                          onHandleChange={selectHandleChange(`srHigh`)}
                          refClassContainer="form-group mb-3"
                          name={`srHigh`}
                          isRequired={true}
                          withLabel={true}
                          label="School Name"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.srHigh}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={false}
                          refStaticData={[

                          ]}
                          field={{
                            tableId: 'tableId',
                            display: 'code'
                          }}
                        />



                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <label className="col-form-label p-0">If not Listed <span className="text-info">*</span>  </label>
                        <input
                          disabled={data.srHigh == null ? false : true}
                          onChange={selectHandleChangeInput('srHighNotListed')}
                          type="text"
                          name="seniorHighNotListedSchool"
                          defaultValue={data.srHighNotListed}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>

                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('srFrom')}
                          refClassContainer="form-group mb-3"
                          name="srFrom"
                          isRequired={true}
                          withLabel={true}
                          label="From"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.srFrom}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-md-3 mb-3">
                        <IMSelect
                          onHandleChange={selectHandleChange('srTo')}
                          refClassContainer="form-group mb-3"
                          name="srTo"
                          isRequired={true}
                          withLabel={true}
                          label="To"
                          placeHolder=""
                          forwardRef={setValue}
                          selectedId={data.srTo}
                          refClassName={``}
                          withDescription={false}
                          // description={`Please select your gender.`}
                          refDisabled={true}
                          refIsStatic={true}
                          refStaticData={[
                            { id: '', label: '' },
                            { id: 2023, label: '2023' },
                            { id: 2022, label: '2022' },
                            { id: 2021, label: '2021' },
                            { id: 2020, label: '2020' },
                            { id: 2019, label: '2019' },
                            { id: 2018, label: '2018' },
                            { id: 2017, label: '2017' },
                            { id: 2016, label: '2016' },
                            { id: 2015, label: '2015' },
                            { id: 2014, label: '2014' },
                            { id: 2013, label: '2013' },
                            { id: 2012, label: '2012' },
                            { id: 2011, label: '2011' },
                            { id: 2010, label: '2010' },
                            { id: 2009, label: '2009' },
                            { id: 2008, label: '2008' },
                          ]}
                          field={{
                            tableId: '',
                            display: ''
                          }}
                        />
                      </div>
                      <div className="form-group col-12 col-md-12 mb-3">
                        <label className="col-form-label p-0">Track / Strand <span className="text-danger">*</span>  </label>
                        <input
                          onChange={selectHandleChangeInput('seniorHighStrand')}
                          type="text"
                          name="seniorHighStrand"
                          defaultValue={data.seniorHighStrand}
                          className={`form-control `}
                          autoComplete="off"
                          maxLength="100"

                        />

                      </div>
                    </div>

                  </div>
                </div>

                {/* <EducationBackground
                                            label='Pre-Elementary'
                                            className='card mt-5'
                                            infoHandler={preElementaryInfoHandler}
                                            name='preElementary'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}
                                        />
                                        <EducationBackground
                                            label='Elementary'
                                            className='card mt-5'
                                            infoHandler={elementaryInfoHandler}
                                            name='elementary'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}


                                        />
                                        <EducationBackground
                                            label='Junior High School'
                                            className='card mt-5'
                                            infoHandler={jrHighSchoolInfoHandler}
                                            name='jrHighSchool'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}


                                        />
                                        <EducationBackground
                                            label='Senior High School'
                                            className='card mt-5'
                                            infoHandler={srHighSchoolInfoHandler}
                                            name='srHighSchool'
                                            getSchoolInfo={getSchoolInfo}
                                            types={applicantTypes}
                                            sySelect={sySelect}


                                        /> */}






              </div>


              <hr />


            </section>
          </>


        </div>
      </>
    )
  }

  const DeleteContent = (props) => {


    console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.studentName : ''} `}</span> </p>
        </main>



      </>
    )
  }



  useEffect(() => {
    console.log(auth)
    console.log(isVerified)
    console.log(page)
    console.log(rowsPerPage)
    console.log(auth)
    console.log(isOpenDelete)
    executeGetListOfApplicant();

  }, [auth, isOpenDelete, isVerified, page, rowsPerPage, keyword])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    console.log(rowDeleteValue)
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])


  useEffect(() => {
    SelectCreator(getCivilStatus, setCivilStatusSelect, 'CivilStatusId', 'CivilStatus')
    SelectCreator(getReligion, setReligionSelect, 'ReligionId', 'Religion')
    SelectCreator(getCountry, setCountrySelect, 'CountryId', 'Country')
    SelectCreator(getProvince, setProvinceSelect, 'ProvinceId', 'Province')
    SelectCreator(getMunicipality, setMunicipalitySelect, 'MunicipalityId', 'Municipality')
    SelectCreator(getFamilyPositions, setFamilyPositionSelect, 'FamilyPositionId', 'Description')
    SelectCreator(getNationality, setNationalitySelect, 'NationalityId', 'Nationality')
    SelectCreator(getCourses, setCourseSelect, 'CourseId', 'Description')
    SelectCreator(getYearLevel, setYearLevelSelect, 'YearLevelId', 'Description')
    SelectCreator(getSchool, setSchoolSelect, 'SchoolId', 'School')

  }, [])




  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'StudentNo', label: 'Student No', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'StudentName', label: 'Student Name', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'EnrollmentStatus', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'DateCreated', label: 'Date of Application', align: 'center', withSorting: true, hidden: false, disablePadding: false, withDateFormat: true },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'StudentId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdateApplicants',
    parentId: null,
    tableTitle: 'List of Applicants',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: admissionsTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)
  console.log(keysToCamelCase(rowValue))
  console.log(page)
  console.log(rowsPerPage)
  console.log(results)
  console.log(totalRecords)
  console.log(isOpenDelete)
  console.log(civilStatusSelect)
  console.log(familyPositionSelect)
  console.log(municipalitySelect)
  console.log(schoolSelect)

  return (
    <>

      <>


        <AddUpdateModal
          title="Update Applicant Details"
          isOpen={isOpenUpdate}
          Content={Content}
          value={keysToCamelCase(rowValue)}
          handleClose={onUpdateGradeElementClose}
          onSubmit={onUpdateApplicant}
          isUpdate={true}
          civilStatusSelect={civilStatusSelect}
          religionSelect={religionSelect}
          countrySelect={countrySelect}
          provinceSelect={provinceSelect}
          courseSelect={courseSelect}
          yearLevelSelect={yearLevelSelect}
          schoolSelect={schoolSelect}
          familyPositionSelect={familyPositionSelect}
          nationalitySelect={nationalitySelect}
          municipalitySelect={municipalitySelect}
          type="studentAccount"
          id="gradeTemplateId"

        />

        <AddUpdateModal
          title="Delete Applicant Details"
          isOpen={isOpenDelete}
          Content={DeleteContent}
          handleClose={onDeleteGradeElementClose}
          isDelete={true}
          onSubmit={onDeleteElement}

          value={keysToCamelCase(rowDeleteValue)}
          id="gradeTemplateId"

        />
        <div className="card card-custom gutter-b example example-compact">
          <div className="card-header">
            <div className="card-title">
              <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary"></span> List of Applicants</h3>
            </div>
            <div className="card-toolbar">
              <div className="example-tools">
                <div className="input-group">
                  <div className="input-group-prepend">
                    <div className="input-group-text">
                      <input onChange={(e) => { onChangeCheckbox(e.target.checked) }} type="checkbox" aria-label="Checkbox for verification" />
                    </div>
                  </div>
                  <div className="input-group-append">
                    <span className="input-group-text">Is Verified</span>
                  </div>
                </div>
                <div className='ml-5'>
                  <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                    Add Applicant
                  </button>
                </div>

              </div>
            </div>
          </div>
          <div className="card-body" style={{ padding: '0px', margin: '0px' }}>

            <IMTable tableProps={tableProps} />
          </div>
        </div>

      </>


    </>
  );
}