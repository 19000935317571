import instance from '../../../../../apis/local/systemAPI';

// START OF COURSE OUTLINE

export const getCourseOutline = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/course-outlines/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "CO.Description": keyword,
            "C.Description": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: 50000
    }
    result = await instance.post(`api/course-outlines/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineFilter = async (page, rowsPerPage, curriculumCode, instituteId, courseId) => {
  var result;
  const params = {
    where: [
      {
        "o": "", "p": ["LIKE", "&&", {
          "CO.Description": curriculumCode !== null ? curriculumCode : '',
          "C.CourseId": courseId !== null ? courseId : '',
          "I.InstituteId": instituteId !== null ? instituteId : ''
        }
        ]
      }
    ]
    , start: page + 1
    , limit: 50000
    , orderBy: 'ORDER BY CO.EffectiveSchoolYear DESC, CO.EffectiveSemesterId ASC'
  }
  result = await instance.post(`api/course-outlines/q`, params);

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getActiveCourseOutlines = async () => {
  const result = await instance.get("api/course-outlines/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineReport = async (values) => {
  const result = await instance.post("api/course-outlines/report", values);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const createCourseOutline = async (formValues) => {
  const result = await instance.post("api/course-outlines/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateCourseOutline = async (id, formValues) => {
  const result = await instance.patch(`api/course-outlines/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getSchoolYear = async () => {
  const result = await instance.get(`api/references/schoolyears/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSemester = async () => {
  const result = await instance.get(`api/references/semesters/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getCourses = async (val) => {
  const result = await instance.post(`api/references/courses/q`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getCourse = async () => {
  const result = await instance.get(`api/references/courses`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseQuery = async (con) => {
  const result = await instance.post(`api/references/courses`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineAll = async () => {
  const result = await instance.get(`api/course-outlines/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getCourseOutlineQuery = async (q) => {
  const result = await instance.post(`api/course-outlines/s`, q);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitute = async () => {
  const result = await instance.get(`api/references/institutes/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getInstituteQuery = async (con) => {
  const result = await instance.post(`api/references/institutes/q`, con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const addUpdateCourseOutline = async (formValues) => {
  const result = await instance.post("api/course-outlines/addUpdate", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const deleteCourseOutline = async (formValues) => {
  const result = await instance.post("api/course-outlines/delete", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF COURSE OUTLINE



