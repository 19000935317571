import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { referenceTypes } from './__hooks__/types';
import { FormControl, FormControlLabel, Switch } from '@material-ui/core';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export default function Form(props) {
  const { register, handleSubmit, errors, setValue } = useForm();
  const [data, setData] = useState({
    feeType: null
  })
  const [switchState, setSwitchState] = useState({
    IsVisible: props.doc.IsVisible === undefined ? false : props.doc.IsVisible === 0 ? false : true,
    IsOneRow: props.doc.IsOneRow === undefined ? false : props.doc.IsOneRow === 0 ? false : true,
    AllowInAll: props.doc.AllowInAll === undefined ? false : props.doc.AllowInAll === 0 ? false : true,
    IsActive: props.doc.IsActive === undefined ? true : props.doc.IsActive === 0 ? false : true
  });

  useEffect(() => {
    register({ name: 'feeType' }, { required: true });
  }, [register])

  const switchHandleChange = name => event => {
    setSwitchState({ ...switchState, [name]: event.target.checked });
  };

  const selectHandleChange = name => event => {
    setData({ ...data, [name]: event });
  }

  const onSubmit = formValues => {
    const { feeCode, description, feeType, seq, isVisible, isOneRow, allowInAll, isActive } = formValues;

    const convertedFormValues = {
      feeCode: feeCode,
      description: description,
      feeType: feeType,
      seq: seq,
      isVisible: isVisible ? 1 : 0,
      isOneRow: isOneRow ? 1 : 0,
      allowInAll: allowInAll ? 1 : 0,
      isActive: isActive ? 1 : 0
    }

    // console.log(convertedFormValues);
    props.onSubmit(convertedFormValues);
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label">Form Details</h3>
          </div>
        </div>
        <div className="card-body">
          <form onSubmit={handleSubmit(onSubmit)} method="post" className="kt-form">
            <div className="kt-portlet__body">
              <div className="form-group">
                <label className="col-form-label">Fee Code <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="feeCode"
                  defaultValue={props.doc.FeeCode === undefined ? "" : props.doc.FeeCode}
                  className={`form-control${errors.feeCode === undefined ? "" : errors.feeCode && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                  maxLength="3"
                />
                <span className="form-text text-muted">Please enter your short name.</span>
                {errors.feeCode && errors.feeCode.type === "required" && <div className="invalid-feedback">Fee code is required</div>}
              </div>
              <div className="form-group">
                <label className="col-form-label">Description <span className="text-danger">*</span> : </label>
                <input
                  type="text"
                  name="description"
                  defaultValue={props.doc.Description === undefined ? "" : props.doc.Description}
                  className={`form-control${errors.description === undefined ? "" : errors.description && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your description.</span>
                {errors.description && errors.description.type === "required" && <div className="invalid-feedback">Description is required</div>}
              </div>
              <IMSelect
                onHandleChange={selectHandleChange('feeType')}
                refClassContainer="form-group"
                name="feeType"
                isRequired={true}
                withLabel={true}
                label="Fee Type"
                placeHolder="Fee Type"
                forwardRef={setValue}
                selectedId={data.feeType === null ? props.doc.FeeType : data.feeType}
                refClassName={``}
                withDescription={true}
                description={`Please enter your fee type.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: '', label: '' },
                  { id: 'EF', label: 'Enrollment Fees' },
                  { id: 'NF', label: 'Non-School Fees' },
                  { id: 'OF', label: 'Other Fees' },
                  { id: 'LF', label: 'Laboratory Fees' },
                  { id: 'AP', label: 'Advance Payment' },
                  { id: 'TF', label: 'Tuition Fees' },
                  { id: 'IC', label: 'Installment Charges' },
                  { id: 'SF', label: 'School Development Fees' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
                error={!!errors.feeType}
                errorMessage={errors.feeType && errors.feeType.type === "required" && 'Fee type is required'}
              />
              <div className="form-group">
                <label className="col-form-label">Sequence <span className="text-danger">*</span> : </label>
                <input
                  type="number"
                  name="seq"
                  defaultValue={props.doc.Seq === undefined ? "" : props.doc.Seq}
                  className={`form-control${errors.seq === undefined ? "" : errors.seq && " is-invalid"}`}
                  ref={register({
                    required: true
                  })}
                  autoComplete="off"
                />
                <span className="form-text text-muted">Please enter your sequence.</span>
                {errors.seq && errors.seq.type === "required" && <div className="invalid-feedback">Sequence is required</div>}
              </div>
              <div className="form-group mt-5">
                <FormControl component="fieldset">
                  <FormControlLabel
                    name="isVisible"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsVisible} onChange={switchHandleChange('IsVisible')} value={switchState.IsVisible} />}
                    label="Visible"
                  /><br />
                  <FormControlLabel
                    name="isOneRow"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsOneRow} onChange={switchHandleChange('IsOneRow')} value={switchState.IsOneRow} />}
                    label="One Row"
                  /><br />
                  <FormControlLabel
                    name="allowInAll"
                    inputRef={register()}
                    control={<Switch checked={switchState.AllowInAll} onChange={switchHandleChange('AllowInAll')} value={switchState.AllowInAll} />}
                    label="Allow in All"
                  /><br />
                  <FormControlLabel
                    name="isActive"
                    inputRef={register()}
                    control={<Switch checked={switchState.IsActive} onChange={switchHandleChange('IsActive')} value={switchState.IsActive} />}
                    label="Active"
                  />
                </FormControl>
              </div>
              <div className="form-group mb-0" align="right">
                <Link to={referenceTypes.LIST_LINK} className="btn btn-secondary mr-1">Cancel</Link>
                <button type="submit" className="btn btn-success">Submit</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </>
  );
}