import React, { useState, useEffect, useRef } from "react";
import { useAppContext } from "../../../../contexts/useAppContext";
import { useForm } from "react-hook-form";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import IMCollapsibleTableStudent from "../../../../../_metronic/layout/components/custom/table/IMCollapsibleTableStudent";
import IMTableStudentAssessment from "../../../../../_metronic/layout/components/custom/table/IMTableStudentAssessment";
import { FormControlLabel, Switch, Checkbox } from "@material-ui/core";
import IMDialog from "../../../../../_metronic/layout/components/custom/dialog/IMDialog";
import CertificateOfMatriculation from "./CertificateOfMatriculation";
import AssessmentForm from "../../Accounting/StudentAccount/AssessmentForm";
import AssessmentFormWithDiscount from "../../Accounting/StudentAccount/AssessmentFormWithDiscount";
import IMCollapsibleTableStudentAAddDropSubjects from "../../../../../_metronic/layout/components/custom/table/IMCollapsibleTalbeStudentAddDropSubjects";
import IMDialogSubmition from "../../../../../_metronic/layout/components/custom/dialog/IMDialogSubmition";
import GrantDisclosureAgreementDialog from "../../../../../_metronic/layout/components/custom/dialog/GrantDisclosueAgreementDialog";

export default function ReAssessment(props) {
    const {
        studentAccount, studentSubjectScheduleDetail, studentAssessmentFees, studentOtherFees,
        studentDiscount, studentClassSectionDropdown, studentSubjectDropdown, paymentModeSelect,
        totalAssessmentFee, totalOtherFee, totalInterest, onIrregularHandler, onClassSectionHandler,
        onPaymentModeHandler, onSelectedSubjectDetail, onSubmitAssessment, onCreateAssessment,
        onWithraw, onDeleteStudentAssessment, onWidrawnStudentAssessment, isOnCurriculum, rejectOutsideCurriculum, showDialog,
        setShowDialog, continueInCurriculum, existingSelectedSub, onAddIrregularSubject, existingSubSchedDetailId,
        totalDiscount, dateTime, totalLabFee, studentTotalLabModes, totalTuitionFee, totalRegFee, totalMiscFee,
        studentTotalUnits, isSyncing, onSyncToCampus, studentTotalDiscount, studentAddOns, totalAddOns,
        paymentSchedule, studentPayment, isSubjectLoading, isAssessmentLoading, onLoadingSubmition,
        studentSubjectScheduleDetailPending, onChangeStudentYearLevel, studentYearLevel, yearLevelSelect,
        studentTotalCreditUnits, paymentScheduleNoDiscount, status, isWithDiscount
    } = props

    // student chosen categories
    const [isIntentToEnroll, setIsIntentToEnroll] = useState(false)
    const [data, setData] = useState({
        ClassSectionId: studentAccount.ClassSectionId || null,
        PaymentModeId: studentAccount.PaymentModeId || null,
        AssessmentTemplateId: 0,
        CourseId: null,
        SubSchedDetailId: null,
        YearLevelId: studentAccount.YearLevelId || null
    })


    const [showDeleteDialog, setShowDeleteDialog] = useState({ id: null, record: '', status: false });
    const [showWithdrawnDialog, setShowWithdrawnDialog] = useState({ id: null, record: '', status: false });


    // student IsIrregular
    const [switchState, setSwitchState] = useState({
        isIrregular: studentAccount.IsIrregular == 1 ? true : false
    });

    const onDeleteAssessmentDialog = () => {
        setShowDeleteDialog({ id: null, record: studentAccount.StudentName, status: true })

    }
    const onWithdrawnEnrollmentDialog = () => {
        setShowWithdrawnDialog({ id: null, record: studentAccount.StudentName, status: true })

    }


    const switchHandleChange = name => event => {
        onIrregularHandler(event.target.checked)
        setSwitchState({ ...switchState, [name]: event.target.checked });
    };


    // utilities
    const { setValue } = useForm();
    const [selectedSubjectCode, setSelectedSubjectCode] = useState([])
    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }
    const { state: { auth } } = useAppContext()
    const fees = studentAssessmentFees.concat(studentOtherFees)


    const selectHandleChange = name => event => {
        if (name == 'ClassSectionId') {
            onClassSectionHandler(event)
            setData({ ...data, [name]: event });
        } else if (name == 'SubSchedDetailId') {
            setData({ ...data, [name]: '' });
            onAddIrregularSubject(event)

        } else if (name == 'PaymentModeId') {
            onPaymentModeHandler(event)
            setData({ ...data, [name]: event });

        } else if (name == 'YearLevelId') {
            onChangeStudentYearLevel(event)
            setData({ ...data, [name]: event });

        }


    }

    useEffect(() => {
        if (data.PaymentModeId == null && studentAssessmentFees.length > 0) {
            setData({ ...data, PaymentModeId: studentAssessmentFees[0].PaymentModeId });

        }
    }, [studentAssessmentFees])


    useEffect(() => {
        if (status != undefined && status.nextClassSectionId != undefined) {
            console.log(status)
            onClassSectionHandler(status.nextClassSectionId)
        }
    }, [])


    const imSelectDropdownClassSection = () => {

        return <IMSelect
            data={studentClassSectionDropdown}
            onHandleChange={selectHandleChange('ClassSectionId')}
            refClassContainer=""
            name="ClassSectionId"
            isRequired={true}
            withLabel={true}
            label={status != undefined && status.nextClassSectionId != undefined ? "Class Section" : "Choose Class Section"}
            placeHolder="References"
            forwardRef={setValue}
            selectedId={status != undefined && status.nextClassSectionId != undefined ? status.nextClassSectionId : data.ClassSectionId === null ? '' : data.ClassSectionId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            isDisabled={status != null && status != undefined && status.nextClassSectionId != undefined ? true : false}
            refIsStatic={false}
            refStaticData={[
            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />


    }

    const imSelectDropdownSubject = () => {

        return <IMSelect
            data={studentSubjectDropdown}
            onHandleChange={selectHandleChange('SubSchedDetailId')}
            refClassContainer=""
            name="SubSchedDetailId"
            isRequired={true}
            withLabel={true}
            label="Choose Subject"
            placeHolder="Subject"
            forwardRef={setValue}
            selectedId={data.SubSchedDetailId === null ? '' : data.SubSchedDetailId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />


    }

    const imSelectDropdownPaymentMode = () => {
        return <IMSelect
            data={paymentModeSelect}
            onHandleChange={selectHandleChange('PaymentModeId')}
            refClassContainer=""
            name="PaymentModeId"
            isRequired={true}
            withLabel={true}
            label="Choose Payment Mode"
            placeHolder="Payment Mode"
            forwardRef={setValue}
            selectedId={data.PaymentModeId === null ? '' : data.PaymentModeId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}

        />

    }

    const imSelectDropdownYearLevel = () => {
        return <IMSelect
            data={yearLevelSelect}
            onHandleChange={selectHandleChange('YearLevelId')}
            refClassContainer=""
            name="YearLevelId"
            isRequired={true}
            withLabel={true}
            label="Choose Year Level"
            placeHolder="Year Level"
            forwardRef={setValue}
            selectedId={data.YearLevelId === null ? studentYearLevel !== null ? studentYearLevel : '' : data.YearLevelId}
            refClassName={``}
            withDescription={false}
            // description={`Please select your gender.`}
            refDisabled={true}
            refIsStatic={false}
            refStaticData={[

            ]
            }
            field={{
                tableId: 'tableId',
                display: 'code'
            }}
        />

    }


    const childColumns = [
        { id: 'RowCnt', type: 'index', label: '', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
        { id: 'TimeStartDesc', type: 'time', label: 'Time Start', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'TimeEndDesc', type: 'time', label: 'Time End', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'DayCode', type: 'string', label: 'Day Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Room', type: 'string', label: 'Room', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    ]

    const columns = [

        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Units', label: 'Units', align: 'center', withSorting: false, hidden: false, disablePadding: false },

        // { id: 'Action', type: 'action', label: 'Select Subject', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }

    ]

    const pendingColumns = [

        { id: 'ClassCode', label: 'Class Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'UnitHour', label: 'Units', align: 'center', withSorting: false, hidden: false, disablePadding: false },
        { id: 'SubjectStatusId', label: 'Status', align: 'left', withSorting: true, hidden: false, disablePadding: false },

    ]

    const columnsPayment = [

        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeCode', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'FinalAmount', type: 'Amount', label: 'Amount', align: 'left', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }


    ]


    const columnsOtherFee = [

        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeCode', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Fee', label: 'Amount', align: 'left', withSorting: true, hidden: false, disablePadding: false },


    ]

    const columnsDiscount = [

        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'Scholarship', label: 'Scholarship', align: 'right', withSorting: true, hidden: false, disablePadding: false },


    ]

    const columnsAddOns = [
        { id: 'RowCnt', label: '#', align: 'left', withSorting: false, hidden: false, disablePadding: false },
        { id: 'FeeDesc', label: 'Fee Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
        { id: 'Amount', label: 'Amount', align: 'center', withSorting: true, hidden: false, disablePadding: false },
    ]


    const tableProps = {
        recordId: 'SubjectId',
        recordIdMore: 'SubjectScheduleDetailListId',
        sortField: '',
        columns: columns,
        childColumns: childColumns,
        rows: studentSubjectScheduleDetail,
        totalRecords: studentSubjectScheduleDetail.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: true,
        tableType: 'child',
        // parentId: id,
        childArray: 'schedules',
        tableTitle: 'List of Subjects',

    }

    const tablePropsIreg = {
        recordId: 'SubjectId',
        recordIdMore: 'SubjectScheduleDetailListId',
        sortField: '',
        columns: pendingColumns,
        childColumns: childColumns,
        rows: studentSubjectScheduleDetailPending,
        totalRecords: studentSubjectScheduleDetailPending.length,
        withMoreButton: false,
        childWithMoreButton: false,
        withFooter: true,
        tableType: 'child',
        // parentId: id,
        childArray: 'schedules',
        tableTitle: 'List of Subjects',

    }

    const tablePropsFeeGroup = {
        recordId: 'feeCode',
        sortField: '',
        columns: columnsPayment,
        rows: studentAssessmentFees,
        totalRecords: studentAssessmentFees.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Fees',

    }
    let tablePropsOtherFeeGroup = {
        recordId: 'FeeId',
        sortField: '',
        columns: columnsOtherFee,
        rows: studentOtherFees,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Other Fee Details',

    }

    const tablePropsAddOnsGroup = {
        recordId: 'PaymentAddOnsId',
        sortField: '',
        columns: columnsAddOns,
        rows: studentAddOns,
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Add Ons',

    }


    const tablePropsScholarships = {
        recordId: 'StudentDiscountId',
        sortField: '',
        columns: columnsDiscount,
        rows: status == undefined || status.isIntent != true ? studentDiscount : [],
        // totalRecords: paymentRef.length,
        withMoreButton: true,
        childWithMoreButton: true,
        withFooter: true,
        tableType: 'parent',
        parentId: null,
        tableTitle: 'Scholarships',
    }
    console.log(data)
    console.log(studentAccount)
    console.log(studentAssessmentFees)
    console.log(studentOtherFees)
    console.log(fees)
    console.log(studentDiscount)
    console.log(auth)
    console.log(totalOtherFee)
    console.log(status)


    console.log(studentSubjectScheduleDetail)
    console.log(existingSubSchedDetailId)
    console.log(selectedSubjectCode)
    console.log(existingSelectedSub)
    return (
        <>

            {isOnCurriculum == false && <IMDialog
                title={`Outside Student Curriculum`}
                content={<span>Are you sure you want to proceed adding the subject? </span>}
                onHandleDelete={continueInCurriculum}
                onShowDialog={setShowDialog}
                data={showDialog.data}
                isRegistration={false}
                isCurriculum={true}
                executeRejectOutsideCurriculum={rejectOutsideCurriculum}
                isOpen={isOnCurriculum == false}
            />
            }

            {showDeleteDialog.status && <IMDialogSubmition
                title={`Assessment Deletion`}
                content={<span>Do you want to Delete this Student Assessment?  ?</span>}
                handleSubmit={onDeleteStudentAssessment}
                onShowDialog={setShowDeleteDialog}
                isOpen={true}
            />}

            {showWithdrawnDialog.status && <IMDialogSubmition
                title={`Withdrawn Enrollment`}
                content={<span>Do you want to Withrawn this Student Enrollment? ?</span>}
                handleSubmit={onWidrawnStudentAssessment}
                onShowDialog={setShowWithdrawnDialog}
                isOpen={true}
            />}

            {isIntentToEnroll && <GrantDisclosureAgreementDialog
                title={`Liabilities Found`}
                description=''
                onShowDialog={setIsIntentToEnroll}
                isOpen={true}
                studentAccount={studentAccount}
                student={status}
                studentTotalDiscount={numberWithCommas(studentTotalDiscount)}
                handleProceed={onCreateAssessment}
            />}


            < div className="row" >

                <div className="col-12 col-xl-6 mt-5">

                    <div className="card p-5">
                        <div className="card-header">
                            <h3 className="display-4 ">{studentAccount.StudentName}</h3>
                            <p className="my-0 lead ">{studentAccount.CourseDesc}</p>
                            <p className="my-0 lead ">{studentAccount.SY}</p>
                            <p className="my-0 lead">{studentAccount.SemesterDesc}</p>
                            <p className="my-0 lead ">{studentAccount.StudentNo}</p>




                            {studentAccount.EnrollmentStatusId === 'E' &&
                                <>
                                    <div
                                        style={{ 'backgroundColor': 'rgba(34, 255, 145, 0.2)' }}
                                        className="alert mt-5" role="alert">
                                        Enrolled
                                    </div>
                                </>
                            }

                            {studentAccount.EnrollmentStatusId === 'A' &&
                                <>
                                    <div
                                        style={{ 'backgroundColor': 'rgba(34, 255, 145, 0.2)' }}
                                        className="alert mt-5"
                                        role="alert">
                                        For Assessment of Fee Validation
                                    </div>
                                </>
                            }

                            {studentAccount.EnrollmentStatusId === 'PV' &&
                                <>
                                    <div
                                        style={{ 'backgroundColor': 'rgba(34, 255, 145, 0.2)' }}
                                        className="alert mt-5"
                                        role="alert">
                                        Payment Validated
                                    </div>
                                </>
                            }

                            {(studentAccount.EnrollmentStatusId === 'DR' || studentAccount.EnrollmentStatusId === 'W' || studentAccount.EnrollmentStatusId === 'IE' || studentAccount.EnrollmentStatusId === undefined) && (status == null || status == undefined || status.isIntent != true) ?
                                <>
                                    <FormControlLabel
                                        name="isIrregular"
                                        control={<Switch checked={switchState.isIrregular}
                                            onChange={switchHandleChange('isIrregular')}
                                            value={switchState.isIrregular} />}
                                        label="Irregular"
                                    />

                                </>
                                :
                                ''
                            }

                            {studentAccount.EnrollmentStatusId === null &&
                                <>
                                    <div

                                        className="alert mt-5 bg-warning text-light"
                                        role="alert">
                                        Student Documents is Required
                                    </div>
                                </>
                            }



                            {auth.data.UserType == 'S' &&
                                <span>{studentAccount.EnrollmentStatusId !== 'DR' && studentAccount.EnrollmentStatusId !== undefined && studentSubjectScheduleDetail.length == 0 && studentAssessmentFees.length == 0 && studentOtherFees.length == 0 && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </span>
                            }


                            {studentAccount.EnrollmentStatusId !== 'DR' && studentAccount.EnrollmentStatusId !== 'W' && studentAccount.EnrollmentStatusId !== 'IE' && studentAccount.EnrollmentStatusId !== undefined ?
                                <div className="input-group mt-5">
                                    <div className="input-group-prepend ">
                                        <span className="input-group-text" id="basic-addon1">Class Section</span>
                                    </div>

                                    {studentAccount.IsIrregular == 1 ?
                                        <input readOnly type="text" value="Irregular Student" className="form-control" placeholder="Irregular Student" aria-label="Username" aria-describedby="basic-addon1" />

                                        :
                                        <>

                                            <input readOnly value={studentSubjectScheduleDetail.length > 0 ? studentSubjectScheduleDetail[0].ClassSection : ''} type="text" className="form-control" placeholder="Username" aria-label="Username" aria-describedby="basic-addon1" />

                                        </>
                                    }

                                </div>
                                :
                                <>
                                    {switchState.isIrregular === false ?
                                        <>
                                            {isSubjectLoading == false || data.ClassSectionId == null ?
                                                <>
                                                    <div className='my-5 '>{imSelectDropdownClassSection()}</div>

                                                </>
                                                :
                                                <>
                                                    <br className="mt-5" />
                                                    {/* <button class="btn btn-secondary" type="button" disabled> */}
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;&nbsp;Loading Please wait...
                                                    {/* </button> */}
                                                </>


                                            }
                                        </>

                                        :
                                        <>
                                            <div className="mt-5">{imSelectDropdownYearLevel()}</div>
                                            <div className='my-5 '>{imSelectDropdownSubject()}</div>
                                        </>
                                    }
                                </>
                            }
                        </div>

                        <div className="card  border-0 container mb-5">
                            <h4 className="container mt-5">Subjects <span>{studentAccount.EnrollmentStatusId !== 'DR' && studentAccount.EnrollmentStatusId !== undefined && studentSubjectScheduleDetail.length == 0 && <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>} </span> </h4>


                            <IMCollapsibleTableStudent
                                tableProps={tableProps}
                                subjectCodeHandler={onSelectedSubjectDetail}
                                selectedSub={existingSelectedSub}
                                selectedSubCode={selectedSubjectCode}
                                selectedSubSchedId={existingSubSchedDetailId}
                                isExistedAcc={studentAccount.EnrollmentStatusId !== 'A' && studentAccount.EnrollmentStatusId !== 'PV' && studentAccount.EnrollmentStatusId !== 'E' ? false : true}
                                isProcessed={studentAccount.EnrollmentStatusId !== 'A' && studentAccount.EnrollmentStatusId !== 'PV' && studentAccount.EnrollmentStatusId !== 'E' ? false : true}

                            />
                            {studentSubjectScheduleDetail.length > 0 &&
                                <p className="mt-5">Total Credit Unit: <span className="fw-bold">{studentTotalCreditUnits}</span> </p>
                            }





                            <br />
                            {studentSubjectScheduleDetailPending.length > 0 &&
                                <>
                                    <h4 className="container mt-5">Add/Drop Subjects</h4>

                                    <IMCollapsibleTableStudentAAddDropSubjects
                                        tableProps={tablePropsIreg}
                                        subjectCodeHandler={() => { }}
                                        subjectIdHandler={() => { }}
                                        selectedSub={[]}
                                        selectedSubCode={[]}
                                        isExistedAcc={false}
                                        isProcessed={true}
                                        type='addDrop'

                                    />
                                </>


                            }



                        </div>
                    </div>

                </div>


                <div className="col-12 col-xl-6 mt-5">
                    <div className="card p-5  ">
                        <h4 className="card-header  text-dark p-5 ">Payment Selection</h4>

                        {studentAccount.EnrollmentStatusId !== 'DR' && studentAccount.EnrollmentStatusId !== 'W' && studentAccount.EnrollmentStatusId !== 'IE' && studentAccount.EnrollmentStatusId !== undefined ?
                            <div className="input-group container mt-5 ">
                                <div className="input-group-prepend ">
                                    <span className="input-group-text" id="basic-addon1">Payment Mode</span>
                                </div>

                                <input readOnly value={studentAccount.PaymentModeDesc} type="text" className="form-control" placeholder="No Payment Mode Available" aria-label="Username" aria-describedby="basic-addon1" />


                            </div>
                            :
                            <>

                                {isAssessmentLoading == false && data.AssessmentTemplateId == 0 ?
                                    <div className='my-5 container'>{imSelectDropdownPaymentMode()}</div>
                                    :

                                    <>
                                        <div className="">
                                            <br className="mt-5" />
                                            {/* <button class="btn btn-secondary" type="button" disabled> */}
                                            &nbsp;&nbsp;Loading Please wait...
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>

                                            {/* </button> */}
                                        </div>

                                    </>

                                }
                            </>
                        }


                        <div className="input-group container mt-5 ">
                            <div className="input-group-prepend ">
                                <span className="input-group-text" id="basic-addon1">Assessment Template</span>
                            </div>
                            {studentAssessmentFees.length > 0 ?
                                <input readOnly value={studentAssessmentFees[0].ReferenceName} type="text" className="form-control" placeholder="No Payment Mode Available" aria-label="Username" aria-describedby="basic-addon1" />
                                :
                                <input readOnly value={""} type="text" className="form-control" placeholder="No Payment Mode Available" aria-label="Username" aria-describedby="basic-addon1" />

                            }
                        </div>



                        <div className="card  border-0 container mb-5">

                            <IMTableStudentAssessment
                                tableProps={tablePropsFeeGroup}
                            />


                        </div>
                        <div className="card  border-0 container mb-5">
                            <IMTableStudentAssessment tableProps={tablePropsOtherFeeGroup} />
                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5"><span className="float-left">Total Other Fee:</span> ₱{numberWithCommas(totalOtherFee)}</h6> </div>
                        </div>
                        <div className="card  border-0 container mb-5">
                            <IMTableStudentAssessment tableProps={tablePropsAddOnsGroup} />
                            <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5"><span className="float-left">Total Add Ons:</span> ₱{numberWithCommas(totalAddOns)}</h6> </div>
                        </div>
                        {status == undefined || status.isIntent != true &&
                            <div className="card  border-0 container mb-5">
                                <IMTableStudentAssessment tableProps={tablePropsScholarships} />
                                <div className="mt-0 alert alert-secondary p-3 "><h6 className="mb-0 text-right pr-5 mr-5"><span className="float-left">Total Discount Fee:</span> ₱{numberWithCommas(studentTotalDiscount)}</h6> </div>
                            </div>
                        }




                        <h4 className="card-header  text-dark p-5 ">Payment Summary</h4>

                        <div className="container mt-5">

                            <div className="row">
                                <div className="col">
                                    <p className="lead">Total Fee: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5"> ₱{numberWithCommas(parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalAddOns))}</h6>
                                </div>
                            </div>

                            {totalInterest > 0.00 && totalInterest !== 0 ?
                                <div className="row">
                                    <div className="col">
                                        <p className="lead">Interest: </p>

                                    </div>
                                    <div className="col">

                                        <h6 className="mb-0 text-right pr-5 mr-5">₱ {numberWithCommas(totalInterest)}</h6>
                                    </div>
                                </div>
                                : ''

                            }

                            <div className="row">
                                <div className="col">
                                    <p className="lead">Tuition Fee Discounts: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5"> - ₱{numberWithCommas(status == undefined || status.isIntent != true ? studentTotalDiscount : 0)}</h6>
                                </div>
                            </div>
                            <hr />
                            <div className="row">
                                <div className="col">
                                    <p className="lead">Total Amount: </p>

                                </div>
                                <div className="col">

                                    <h6 className="mb-0 text-right pr-5 mr-5">
                                        ₱{parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalInterest) + parseFloat(totalAddOns) - parseFloat(status == undefined || status.isIntent != true ? studentTotalDiscount : 0) < 0 ? 0.00 :
                                            numberWithCommas(((parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalInterest) + parseFloat(totalAddOns)) - parseFloat(status == undefined || status.isIntent != true ? studentTotalDiscount : 0)).toFixed(2))
                                        }
                                    </h6>
                                </div>
                            </div>


                            {(studentAccount.EnrollmentStatusId == 'DR' || studentAccount.EnrollmentStatusId == 'W') &&
                                <>
                                    {data.PaymentModeId !== null ?
                                        <>
                                            {onLoadingSubmition != true ?
                                                <div className="mt-5 float-right">
                                                    <button onClick={() => { onSubmitAssessment() }} className="btn btn-sm btn-primary">Submit Assessment</button>
                                                </div>
                                                :
                                                <button class="btn btn-secondary mt-5 float-right" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp;&nbsp; Processing Assessment...
                                                </button>
                                            }

                                        </>


                                        :
                                        <div className="mt-5 float-right">
                                            <button disabled className="btn btn-sm btn-secondary">Submit Assessment</button>
                                        </div>

                                    }

                                    {/* {isSyncing === false ?
                                        <button
                                            onClick={() => { onSyncToCampus() }}
                                            className="btn btn-sm btn-info float-left">Sync to Campus</button>
                                        :
                                        <button class="btn btn-secondary float-left" type="button" disabled>
                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                            &nbsp; Syncing...
                                        </button>
                                    } */}
                                </>


                            }

                            {(studentAccount.EnrollmentStatusId !== 'DR' || studentAccount.EnrollmentStatusId !== 'W') &&
                                <>
                                    {studentAccount.EnrollmentStatusId == undefined &&
                                        <>
                                            {/* {isSyncing === false ?
                                                <button
                                                    onClick={() => { onSyncToCampus() }}
                                                    className="btn btn-sm btn-info float-left">Sync to Campus</button>
                                                :
                                                <button class="btn btn-secondary float-left" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp; Syncing...
                                                </button>
                                            } */}

                                            {data.PaymentModeId !== null ?
                                                <>
                                                    {onLoadingSubmition != true ?

                                                        <div className="mt-5 float-right">
                                                            <button onClick={() => { status.isIntent == true && isWithDiscount == true ? setIsIntentToEnroll(true) : onCreateAssessment() }} className="btn btn-sm btn-success">{status != undefined && status.isIntent == true ? 'Save Assessment' : 'Create Assessment'}</button>
                                                        </div>

                                                        :

                                                        <button class="btn btn-secondary mt-5 float-right" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp; Processing Assessment...
                                                        </button>

                                                    }

                                                </>
                                                :
                                                <div className="mt-5 float-right">
                                                    <button disabled className="btn btn-sm btn-secondary">{status != undefined && status.isIntent == true ? 'Save Assessment' : 'Create Assessment'}</button>
                                                </div>
                                            }
                                        </>
                                    }
                                    {studentAccount.EnrollmentStatusId == 'IE' &&
                                        <>
                                            {/* {isSyncing === false ?
                                                <button
                                                    onClick={() => { onSyncToCampus() }}
                                                    className="btn btn-sm btn-info float-left">Sync to Campus</button>
                                                :
                                                <button class="btn btn-secondary float-left" type="button" disabled>
                                                    <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                    &nbsp; Syncing...
                                                </button>
                                            } */}

                                            {data.PaymentModeId !== null ?
                                                <>
                                                    {onLoadingSubmition != true ?

                                                        <div className="mt-5 float-right">
                                                            <button onClick={() => { status != null && status != undefined && status.isIntent == true && isWithDiscount == true ? setIsIntentToEnroll(true) : onSubmitAssessment() }} className="btn btn-sm btn-success">{status != undefined && status.isIntent == true ? 'Save Assessment' : 'Validate Assessment'}</button>
                                                        </div>

                                                        :

                                                        <button class="btn btn-secondary mt-5 float-right" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp; Processing Assessment...
                                                        </button>

                                                    }

                                                </>
                                                :
                                                <div className="mt-5 float-right">
                                                    <button disabled className="btn btn-sm btn-secondary">{status != undefined && status.isIntent == true ? 'Save Assessment' : 'Validate Assessment'}</button>
                                                </div>
                                            }
                                        </>
                                    }
                                    <section className="row justify-content-between">


                                        <div className="col-8 row">
                                            {studentAccount.EnrollmentStatusId == 'E' && isSubjectLoading == false && isAssessmentLoading == false &&
                                                <CertificateOfMatriculation
                                                    studentAccount={studentAccount}
                                                    studentSubjectScheduleDetail={studentSubjectScheduleDetail}
                                                    studentAssessmentFees={studentAssessmentFees}
                                                    studentOtherFees={studentOtherFees}
                                                    studentAddOns={studentAddOns}
                                                    studentDiscount={studentTotalDiscount}
                                                    encoder={auth.data.Username.slice(0, -17)}
                                                    studentTotalUnits={studentTotalUnits}
                                                    studentTotalCreditUnits={studentTotalCreditUnits}
                                                    date={dateTime}
                                                    paymentSchedule={paymentSchedule}
                                                    totalOther={totalOtherFee}
                                                    totalAssessmentFee={totalAssessmentFee}
                                                    totalAddOns={totalAddOns}
                                                    studentPayment={studentPayment}
                                                    interest={totalInterest}
                                                    status={status}

                                                />

                                            }
                                            {studentAssessmentFees.length > 0 && isSubjectLoading == false && isAssessmentLoading == false && (studentAccount.EnrollmentStatusId == 'A' || studentAccount.EnrollmentStatusId == 'PV' || studentAccount.EnrollmentStatusId == 'E') &&
                                                ((status == undefined && status == null) || status.isIntent == false) &&
                                                <>

                                                    <AssessmentForm
                                                        subjectScheduleDetail={studentSubjectScheduleDetail.concat(studentSubjectScheduleDetailPending.filter(d => d.SubjectStatusId == 3))}
                                                        student={studentAccount}
                                                        assessmentTemplate={studentAssessmentFees}
                                                        currentDate={dateTime}
                                                        encoder={auth.data.LastName + ', ' + auth.data.FirstName}
                                                        finalOtherFees={totalOtherFee}
                                                        finalOtherFeesSpecs={studentOtherFees}
                                                        finalLabFees={totalLabFee}
                                                        labCount={studentTotalLabModes}
                                                        labCode={'Lab'}
                                                        tuitionFee={totalTuitionFee}
                                                        regFee={totalRegFee}
                                                        miscFee={totalMiscFee}
                                                        interest={totalInterest}
                                                        totalUnit={studentTotalUnits}
                                                        fees={fees}
                                                        totalFee={parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalAddOns)}
                                                        total={((parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalInterest) + parseFloat(totalAddOns))).toFixed(2) - parseFloat(totalDiscount)}
                                                        totalWithoutOther={(parseFloat(totalAssessmentFee) - parseFloat(studentTotalDiscount)).toFixed(2)}
                                                        totalOtherFee={totalOtherFee}
                                                        paymentSchedule={paymentSchedule}
                                                        studentDiscount={studentDiscount}
                                                        studentTotalDiscount={studentTotalDiscount}
                                                        studentPayment={studentPayment}
                                                        studentTotalCreditUnits={studentTotalCreditUnits}
                                                        totalAddOns={totalAddOns}
                                                    />

                                                    <AssessmentFormWithDiscount
                                                        subjectScheduleDetail={studentSubjectScheduleDetail.concat(studentSubjectScheduleDetailPending.filter(d => d.SubjectStatusId == 3))}
                                                        student={studentAccount}
                                                        assessmentTemplate={studentAssessmentFees}
                                                        currentDate={dateTime}
                                                        encoder={auth.data.LastName + ', ' + auth.data.FirstName}
                                                        finalOtherFees={totalOtherFee}
                                                        finalOtherFeesSpecs={studentOtherFees}
                                                        finalLabFees={totalLabFee}
                                                        labCount={studentTotalLabModes}
                                                        labCode={'Lab'}
                                                        tuitionFee={totalTuitionFee}
                                                        regFee={totalRegFee}
                                                        miscFee={totalMiscFee}
                                                        interest={totalInterest}
                                                        totalUnit={studentTotalUnits}
                                                        fees={fees}
                                                        totalFee={parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalAddOns)}
                                                        total={((parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalInterest) + parseFloat(totalAddOns))).toFixed(2)}
                                                        totalWithoutOther={(parseFloat(totalAssessmentFee) - parseFloat(studentTotalDiscount)).toFixed(2)}
                                                        totalOtherFee={totalOtherFee}
                                                        paymentSchedule={paymentScheduleNoDiscount}
                                                        studentDiscount={studentDiscount}
                                                        studentTotalDiscount={studentTotalDiscount}
                                                        studentPayment={studentPayment}
                                                        studentTotalCreditUnits={studentTotalCreditUnits}
                                                        totalAddOns={totalAddOns}

                                                    />

                                                </>


                                            }

                                        </div>

                                        {/* <div className="col-4">
                                            {studentAssessmentFees.length > 0 &&
                                                <AssessmentFormWithDiscount
                                                    subjectScheduleDetail={studentSubjectScheduleDetail}
                                                    student={studentAccount}
                                                    assessmentTemplate={studentAssessmentFees}
                                                    currentDate={dateTime}
                                                    encoder={auth.data.Username}
                                                    finalOtherFees={totalOtherFee}
                                                    finalOtherFeesSpecs={studentOtherFees}
                                                    finalLabFees={totalLabFee}
                                                    labCount={studentTotalLabModes}
                                                    labCode={'Lab'}
                                                    tuitionFee={totalTuitionFee}
                                                    regFee={totalRegFee}
                                                    miscFee={totalMiscFee}
                                                    interest={totalInterest}
                                                    totalUnit={studentTotalUnits}
                                                    scholarship={studentTotalDiscount}

                                                />}
                                        </div> */}
                                        <div className="col-4 float-right">

                                            {studentAccount.EnrollmentStatusId !== undefined && (studentAccount.EnrollmentStatusId == 'A' || studentAccount.EnrollmentStatusId == 'PV') &&
                                                <>
                                                    {onLoadingSubmition != true ?
                                                        <div className="float-right">
                                                            <button onClick={() => { onDeleteAssessmentDialog() }} className="btn btn-sm btn-danger">Delete Assessment</button>
                                                        </div>
                                                        :
                                                        <button class="btn btn-secondary  float-right" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp; Processing ...
                                                        </button>}
                                                </>

                                            }


                                            {studentAccount.EnrollmentStatusId !== undefined && studentAccount.EnrollmentStatusId == 'E' &&
                                                ((status == undefined && status == null) || status.isIntent == false) &&
                                                <>
                                                    {onLoadingSubmition != true ?
                                                        <div className="float-right">
                                                            <button onClick={() => { onWithdrawnEnrollmentDialog() }} className="btn btn-sm btn-sm btn-danger">Withdrawn Enrollment</button>
                                                        </div>
                                                        :
                                                        <button class="btn btn-secondary  float-right" type="button" disabled>
                                                            <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                                                            &nbsp;&nbsp; Processing ...
                                                        </button>
                                                    }

                                                </>

                                            }
                                        </div>


                                    </section>







                                </>

                            }


                            {/* <div className="row mt-5 float-right">
                                                    {studentAccount.EnrollmentStatusId !== 'DR' ?
                                                        <>

                                                            <AssessmentForm
                                                                subjectScheduleDetail={studentSubjectScheduleDetail}
                                                                student={studentAccount}
                                                                assessmentTemplate={studentAssessmentFees}
                                                                currentDate={dateTime}
                                                                encoder={auth.data.Username}
                                                                finalOtherFees={totalOtherFee}
                                                                finalOtherFeesSpecs={studentOtherFees}
                                                                finalLabFees={finalLabFees}
                                                                labCount={labMode}
                                                                labCode={finalLabCode}
                                                                tuitionFee={finalTuitionFee}
                                                                regFee={finalRegFee}
                                                                miscFee={finalMiscFee}
                                                                interest={interestValues}
                                                                enrollment={enrollment[0]}
                                                                totalUnit={totalUnit}
                                                            />

                                                            <AssessmentFormWithDiscount
                                                                subjectScheduleDetail={subjectScheduleDetail}
                                                                student={student}
                                                                assessmentTemplate={assessmentTemplate}
                                                                currentDate={dateTime}
                                                                encoder={auth.data.Username}
                                                                finalOtherFees={finalOtherFees}
                                                                finalOtherFeesSpecs={finalOtherFeesSpecs}
                                                                finalLabFees={finalLabFees}
                                                                labCount={labMode}
                                                                labCode={finalLabCode}
                                                                tuitionFee={finalTuitionFee}
                                                                regFee={finalRegFee}
                                                                miscFee={finalMiscFee}
                                                                interest={interestValues}
                                                                scholarship={finalDiscount}
                                                                enrollment={enrollment[0]}
                                                                totalUnit={totalUnit}

                                                            />

                                                        </>

                                                        : ''
                                                    }

                            </div> */}


                        </div>


                    </div>

                </div>



                {/* {isDeleting && <IMDialog
                    title={`Question`}
                    content={<span>Are you sure you want to delete this Assessment?</span>}
                    onHandleDelete={onDeleteAssessment}
                    onDeletingClose={onDeletingClose}
                    isOpen={isDeleting}
                    isAssessment={true}
                />} */}


            </div >

            {/* <div className="col-12">
                {studentAssessmentFees.length > 0 &&

                    <AssessmentForm
                        subjectScheduleDetail={studentSubjectScheduleDetail}
                        student={studentAccount}
                        assessmentTemplate={studentAssessmentFees}
                        currentDate={dateTime}
                        encoder={auth.data.LastName + ', ' + auth.data.FirstName}
                        finalOtherFees={totalOtherFee}
                        finalOtherFeesSpecs={studentOtherFees}
                        finalLabFees={totalLabFee}
                        labCount={studentTotalLabModes}
                        labCode={'Lab'}
                        tuitionFee={totalTuitionFee}
                        regFee={totalRegFee}
                        miscFee={totalMiscFee}
                        interest={totalInterest}
                        totalUnit={studentTotalUnits}
                        fees={fees}
                        totalFee={parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalAddOns)}
                        total={((parseFloat(totalAssessmentFee) + parseFloat(totalOtherFee) + parseFloat(totalInterest)) - parseFloat(totalDiscount)).toFixed(2)}
                        totalWithoutOther={(parseFloat(totalAssessmentFee) - parseFloat(totalDiscount)).toFixed(2)}
                        totalOtherFee={totalOtherFee}
                        paymentSchedule={paymentSchedule}
                        studentDiscount={studentDiscount}
                        studentTotalDiscount={studentTotalDiscount}
                        studentPayment={studentPayment}
                    />

                }

            </div> */}
        </>




    )



}