import instance from "../../../../../apis/local/systemAPI";


export const getORDetails = async (formValues) => {
    const result = await instance.post("api/cashiering/student/getORDetails", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getFee = async () => {
    const result = await instance.get("api/references/fees");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const updateORDetails = async (val) => {
    const result = await instance.post("api/cashiering/student/addUpdateReClass", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const cancelOR = async (val) => {
    const result = await instance.post("api/cashiering/student/cancelOR", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}
export const deleteFee = async (val) => {
    const result = await instance.post("api/cashiering/student/deletePaymentDetail", val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

