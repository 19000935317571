import React, { useState, useRef, forwardRef, useEffect } from "react";
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide, Button } from '@material-ui/core';
import './__hooks__/modal-styles.css';
import { useForm } from 'react-hook-form';
// import {  verifyCredentials, getLastestPrintingDetails } from './__hooks__';
import { useAppContext } from '../../../../../app/contexts/useAppContext';
// import { componentTypes } from '../../snackbar/__hooks__/types';

const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function SummaryOfGradesConfirmation(props) {
    const { title, content, isOpen, handleConfirm, handleClose } = props;
    const { register, handleSubmit, errors } = useForm();
    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const [onAlert, setOnAlert] = useState(false);
    const [onEmptyFields, setOnEmptyFields] = useState(false);

    const onModalClose = () => {
      handleClose();
    }

    const onSubmit = formValues => {
      handleConfirm();
    };

    useEffect(() => {
    }, []);

    return (
        <>
          <Dialog
            open={isOpen}
            TransitionComponent={Transition}
            keepMounted
            onConfirm={handleConfirm}
            onClose={handleClose}
            maxWidth="sm"
            disableBackdropClick="true"
            fullWidth>

            <DialogTitle>{title}</DialogTitle>

            <hr />
            <DialogContent>
                <DialogContentText>{content}</DialogContentText>
            </DialogContent>

            {/* <div className="form-row reprint-card-modal">
                <div className="form-group col">
                    <label >Last Printed By: <span style={{fontWeight:"600"}}>{lastCreatedBy}</span></label>
                </div>
            </div> */}

              <div className="form-group d-flex flex-wrap justify-content-between align-items-center" style={{margin:"0% 5%"}}>
                <button onClick={handleSubmit(onSubmit)} className={`btn btn-success`}>Confirm</button>
                <button onClick={onModalClose} className={`btn btn-secondary`}>Cancel</button>
              </div> 

            <DialogActions>
                {/* <Button onClick={handleConfirm} variant="contained" color="danger">
                    Confirm
                </Button>

                <Button onClick={handleClose} variant="contained" color="danger">
                    Cancel
                </Button> */}
            </DialogActions>
          </Dialog>
        </>
    );
}