import instance from '../../../../../apis/local/systemAPI';

// START OF FEE GROUP
export const getActiveFeeGroups = async () => {
  const result = await instance.get("api/references/feegroups/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFeeGroups = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/feegroups/page/${page + 1}/${rowsPerPage}`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "feeCode": keyword,
            "description": keyword,
            "shortName": keyword,
            "feeType": keyword
          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/feegroups/q`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createFeeGroup = async (formValues) => {
  const result = await instance.post("api/references/feegroups", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateFeeGroup = async (id, formValues) => {
  const result = await instance.patch(`api/references/feegroups/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteFeeGroup = async (id) => {
  const result = await instance.delete(`api/references/feegroups/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF FEE GROUP