import React, { useState, useEffect } from 'react';
import {
  generateHonorList
} from './__hooks__';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import './__hooks__/styles.css'
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import moment from "moment";

export default function PrintHonorList(props){
  const { state: { fees, auth, semesters }, dispatch } = useAppContext();
  const [results, setResults] = useState([]);
  const [onAlert, setOnAlert] = useState(false);
  const [data, setData] = useState({ 
          schoolYear: localStorage.getItem("ghl-school-year"),
          semesterId: localStorage.getItem("ghl-semester-id"),
          instituteId: localStorage.getItem("ghl-institute-id"),
          courseId: localStorage.getItem("ghl-course-id"),
          //studentId: localStorage.getItem("ghl-student-id"),
          instituteArray: localStorage.getItem("ghl-institute-select"),
          schoolYearDisplay: localStorage.getItem("ghl-schoolYearDisplay"),
          semesterDisplay: localStorage.getItem("ghl-semesterDisplay")
  });

  //console.log("PROPS: ", props.location.state.data);
  const currDate = moment().format('YYYY-MM-DD');

  let tempInstituteList = localStorage.getItem("ghl-institute-select");
  let newInstituteList = tempInstituteList ? tempInstituteList.split(',') : [];
  let newData = [];
  let currUser = auth.data.Username.split('@')[0];
  let ctr = 0;

  useEffect(() => {
    if(props.location.state.data !== undefined) {
      newData = props.location.state.data;
      setData((prevData) => {
        return {...prevData, 
          schoolYear: newData.schoolYear,
          semesterId: newData.semesterId,
          instituteId: newData.instituteId,
          courseId: newData.courseId,
          //studentId: newData.studentId,
          schoolYearDisplay: newData.schoolYearDisplay,
          semesterDisplay: newData.semesterDisplay
        }
      });

    } else {
      
      setData((prevData) => {
        return {...prevData,
          schoolYear: localStorage.getItem("ghl-school-year"),
          semesterId: localStorage.getItem("ghl-semester-id"),
          instituteId: localStorage.getItem("ghl-institute-id"),
          courseId: localStorage.getItem("ghl-course-id"),
          //studentId: localStorage.getItem("ghl-student-id"),
          schoolYearDisplay: localStorage.getItem("ghl-schoolYearDisplay"),
          semesterDisplay: localStorage.getItem("ghl-semesterDisplay")
        }
      });
    }

    if((data.schoolYear === null || data.schoolYear == '') || (data.semesterId === null || data.semesterId == '')){
      dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "No data available." });
    } else {
      searchForGrades();
    }
  }, []);

  const searchForGrades = async () => {

    const newArray = [];
    const consolidated = [];
    let noDataCtr = 0;

    if(data.instituteId != 90 || (data.instituteId == 90 && data.courseId != 0)){
      newInstituteList = [];
      newInstituteList.push(data.instituteId);
    } 

    for(let i = 0; i < newInstituteList.length; i++){
      let values = {
        //studentId: data.studentId === null ? 0 : data.studentId,
        schoolYear: data.schoolYear,
        semesterId: data.semesterId,
        instituteId: newInstituteList[i], //data.instituteId === null ? 90 : data.instituteId,
        courseId: data.courseId === null ? 0 : data.courseId
        //instituteArray: newInstituteList
      };

      await generateHonorList(values)
        .then(result => {
            if (result.success) {
                if(result.success){

                    if(result.data.length > 0){
                      newArray.push(result.data);
                    } else {
                      noDataCtr++;
                    }
                    
                    //setIsLoading(false);
                } else {
                    setOnAlert(true);  
                    //setIsLoading(false);
                }          
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: result.message });
            }
        })
        .catch(error => {
          if (error.response.status === 401) {
            //history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        });
    }

    if(noDataCtr == newInstituteList.length){
      setOnAlert(true); 
    } else {
      if(newInstituteList.length > 1){
        consolidated.push(newArray);
        setResults(consolidated[0]);
      } else {
        setResults(newArray[0]);
      }
    }
  }

  return (
    <>
      {
        // This will be the table view when search is for a specific Institute or Student
        results.length > 0 && (data.instituteId != 90 || data.courseId != 0) &&
          <>
            <br></br>
            <div id="" className="faith-header-print-only">
                <h2 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h2>
                {/* <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p> */}
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>HONOR LIST REPORT</p>
                <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.schoolYearDisplay} - {data.semesterDisplay}</p>
                {/* <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p> */}
            </div>
            
            <br></br>
            <br></br>

            <div id="" className="sog-print-detail">
                <p className="" style={{lineHeight:"1px"}}>{`Printed By: ${currUser}`}</p>
                <p className="" style={{lineHeight:"1px"}}>Print Date: {currDate}</p>
            </div>
            
            <br></br>

            <table className="class-record-style class-record-table-centered">
                <thead>  
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header">#</th>
                        <th className="class-record-style-header" width="7%">STUDENT NO</th>
                        <th className="class-record-style-header" width="17%">NAME OF STUDENT</th>
                        <th className="class-record-style-header" width="5%">GPA</th>
                        <th className="class-record-style-header" width="5%">Exact GPA</th>
                        <th className="class-record-style-header" width="5%">GPA W/O NSTP AND ROTC</th>
                        <th className="class-record-style-header" width="5%">UNITS</th>
                        <th className="class-record-style-header" width="5%">YEAR</th>
                        <th className="class-record-style-header" width="9%">FINAL HONOR</th>
                        <th className="class-record-style-header" width="9%">TYPE OF HONOR</th>
                        <th className="class-record-style-header" width="5%">COURSE</th>
                        <th className="class-record-style-header" width="5%">COLLEGE</th>
                        <th className="class-record-style-header" width="5%">SCHOOL</th>
                        <th className="class-record-style-header" width="17%">NAME OF STUDENT</th>
                    </tr>
                </thead>
                  {results.map((row, i) => (
                    <tbody>
                           {row.Details.map((res, index) => {
                              return(
                                <>
                                  <tr key={index} >
                                    <td className="class-record-style">
                                      {`${index == 0 ? i + 1 : ''}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.StudentNo}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${index == 0 ? row.StudentName : ''}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.GPA}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.ExactGPA}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.GPAWithout}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Units}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Year}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.FinalHonorType}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.HonorType}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Course}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Institute}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.School}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.FirstName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}` + ' ' + 
                                      `${res.MiddleName.length > 0 ? (res.MiddleName.charAt(0).toUpperCase() + '. ') : ''}`  +
                                      `${res.LastName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}`}
                                    </td>
                                  </tr> 
                                </>
                           )})}         
                    </tbody>
                   ))}   
            </table>
            
            <br></br>
            <br></br>
          </>  
      }

      {
        // This will be the table view when search is for All Institute or Student
        results.length > 0 && (data.instituteId == 90 && data.courseId == 0) &&
        <>
          <div id="" className="faith-header-print-only">
              <h2 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h2>
              {/* <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p> */}
              <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>HONOR LIST REPORT</p>
              <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.schoolYearDisplay} - {data.semesterDisplay}</p>
              {/* <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p> */}
          </div>

          <br></br>
          <br></br>

          <div id="" className="sog-print-detail">
              <p className="" style={{lineHeight:"1px"}}>{`Printed By: ${currUser}`}</p>
              <p className="" style={{lineHeight:"1px"}}>Print Date: {currDate}</p>
          </div>

          {results.map((details, i) => (
            <>
              <table key={i} className="class-record-style class-record-table-centered">
                <thead>  
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header">#</th>
                        <th className="class-record-style-header" width="7%">STUDENT NO</th>
                        <th className="class-record-style-header" width="17%">NAME OF STUDENT</th>
                        <th className="class-record-style-header" width="5%">GPA</th>
                        <th className="class-record-style-header" width="5%">Exact GPA</th>
                        <th className="class-record-style-header" width="5%">GPA W/O NSTP AND ROTC</th>
                        <th className="class-record-style-header" width="5%">UNITS</th>
                        <th className="class-record-style-header" width="5%">YEAR</th>
                        <th className="class-record-style-header" width="9%">FINAL HONOR</th>
                        <th className="class-record-style-header" width="9%">TYPE OF HONOR</th>
                        <th className="class-record-style-header" width="5%">COURSE</th>
                        <th className="class-record-style-header" width="5%">COLLEGE</th>
                        <th className="class-record-style-header" width="5%">SCHOOL</th>
                        <th className="class-record-style-header" width="17%">NAME OF STUDENT</th>
                    </tr>
                </thead>

                {details.map((row, i) => (
                    <tbody>
                      {row.Details.map((res, index) => {
                              if(index == 0) {
                                ctr++;
                              }

                              return(
                                <>
                                  <tr key={index} >
                                    <td className="class-record-style" style={{paddingLeft:"5px"}}>
                                      {`${index == 0 ? ctr : ''}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.StudentNo}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${index == 0 ? row.StudentName : ''}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.GPA}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.ExactGPA}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.GPAWithout}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Units}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Year}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.FinalHonorType}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.HonorType}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Course}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.Institute}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.School}`}
                                    </td>

                                    <td className="class-record-style sog-td-padding-left">
                                      {`${res.FirstName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}` + ' ' + 
                                      `${res.MiddleName.length > 0 ? (res.MiddleName.charAt(0).toUpperCase() + '. ') : ''}`  +
                                      `${res.LastName.toLowerCase().split(' ').map(word => {return word.charAt(0).toUpperCase() + word.slice(1);}).join(' ')}`}
                                    </td>
                                  </tr> 
                                </>
                           )})}    
                    </tbody>
                   ))}   
              </table>

              <br></br>
              <br></br>
            </>
          ))} 
        </>
      }

      {results.length == 0 && !onAlert &&
        <Loading /> // "No Data Available"
      }

      { onAlert && 
        <>
          <div id="" className="faith-header-print-only">
              <h2 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h2>
              {/* <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p> */}
              <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>HONOR LIST REPORT</p>
              <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>{data.schoolYearDisplay} - {data.semesterDisplay}</p>
              {/* <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p> */}
          </div>

          <br></br>
          <br></br>

          <div id="" className="sog-print-detail">
              <p className="" style={{lineHeight:"1px"}}>{`Printed By: ${currUser}`}</p>
              <p className="" style={{lineHeight:"1px"}}>Print Date: {currDate}</p>
          </div>

          <p className="text-center" style={{lineHeight:"10px",fontSize:"1.4em"}}>No Data Available</p>
        </>
      }
    </>
  )
}