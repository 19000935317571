import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { Link } from 'react-router-dom';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getComputationTypes } from './__hooks__';
import IMTable from '../../../../../_metronic/layout/components/custom/table/IMTable';

export default function List() {
  const { state: { computationTypes }, dispatch } = useAppContext();
  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(computationTypes.totalRecords);
  const [page, setPage] = useState(computationTypes.page);
  const [rowsPerPage, setRowsPerPage] = useState(computationTypes.rowsPerPage);
  const [keyword, setKeyword] = useState(computationTypes.keyword);
  const [onSearch, setOnSearch] = useState(computationTypes.keyword === '' ? false : true);
  const updateId = computationTypes.id;
  const [isLastUpdate, setIsLastUpdate] = useState(true);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };

  useEffect(() => {
    const execute = async () => {
      await getComputationTypes(page, rowsPerPage, keyword)
        .then(response => {
          if (response.success) {
            dispatch({ type: referenceTypes.GET_COMPUTATION_TYPES, data: response.data, keyword: keyword, page: page, rowsPerPage: rowsPerPage });
            setResults(response.data);
            setTotalRecord(response.data.length > 0 ? response.records : 0);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {
          if (error.response.status === 401) {
            localStorage.clear();
            history.push('/auth/login');
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
          }
        })
    }
    execute();

    const timer = setTimeout(() => {
      setIsLastUpdate(false);
      dispatch({ type: referenceTypes.CLEAR_LAST_UPDATE_ID });
    }, 2500);
    return () => {
      clearTimeout(timer);
    }
  }, [dispatch, page, rowsPerPage, keyword])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'ComputationCode', label: 'Computation Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Description', label: 'Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsActive', label: 'Active', align: 'center', withSorting: false, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'AssessmentComputationTypeId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'parent',
    parentId: null,
    tableTitle: 'List of Computation Types',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onIsLastUpdate: isLastUpdate,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: referenceTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch
  }

  return (
    <>
      <div className="card card-custom gutter-b example example-compact">
        <div className="card-header">
          <div className="card-title">
            <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
          </div>
          <div className="card-toolbar">
            <div className="example-tools">
              <button className="btn btn-sm btn-icon-sm btn-success mr-1"><i className="flaticon-download-1"></i> Export</button>
              <Link to={referenceTypes.CREATE_LINK} className="btn btn-sm btn-icon-sm btn-primary">
                <i className="fa fa-plus"></i> Add New
              </Link>
            </div>
          </div>
        </div>
        <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
          <IMTable tableProps={tableProps} />
        </div>
      </div>
    </>
  );
}