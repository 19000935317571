import instance from '../../../../../apis/local/systemAPI';

// START OF SUBJECT
export const getActiveSubjects = async () => {
  const result = await instance.get("api/references/subjects/active");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjects = async () => {
  const result = await instance.get("api/references/subjects");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getFilteredSubjects = async (con) => {
  const result = await instance.post("api/references/subjects/q", con);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectsTypes = async () => {
  const result = await instance.get("api/references/subject-types");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}



export const getSubjectsCategories = async () => {
  const result = await instance.get("api/references/subject-categories");
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}




export const createSubjectRequisite = async (formValues) => {
  const result = await instance.post("api/references/subject-prerequisite", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}





export const updatePreRequisite = async (id, formValues) => {
  const result = await instance.patch(`api/references/subject-prerequisite/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}





export const getSubject = async (page, rowsPerPage, keyword) => {
  var result;
  if (keyword === '') {
    result = await instance.get(`api/references/subjects/page/${page + 1}/${rowsPerPage}/Description`);
  } else {
    const params = {
      where: [
        {
          "o": "", "p": ["LIKE", "||", {
            "Description": keyword,
            "SubjectCode": keyword

          }
          ]
        }
      ]
      , start: page + 1
      , limit: rowsPerPage
    }
    result = await instance.post(`api/references/subjects/f`, params);
  }

  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
export const getSubjectRelation = async (formValues) => {
  const result = await instance.post("api/references/subjects/getSubjectRelation", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const createSubject = async (formValues) => {
  const result = await instance.post("api/references/subjects/", formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const updateSubject = async (id, formValues) => {
  const result = await instance.patch(`api/references/subjects/${id}`, formValues);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteSubject = async (id) => {
  const result = await instance.delete(`api/references/subjects/${id}`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getInstitute = async () => {
  const result = await instance.get(`api/references/institutes/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const getSubjectCategory = async () => {
  const result = await instance.get(`api/references/subject-categories/`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const getTest = async () => {
  const result = await instance.get(`api/registrar/facultyLoading/facultyReport`);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}


export const addUpdateSubjectRequisite = async (val) => {
  const result = await instance.post(`api/references/subjects/addUpdateRequisite`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}

export const deleteSubjectRequisite = async (val) => {
  const result = await instance.post(`api/references/subjects/deleteRequisite`, val);
  if (result.status === 200 || result.status === 201) {
    return result.data;
  }
}
// END OF SUBJECT