import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import {
    getSchoolYear, getSemester, getInstitute, getCustomCourseList, studentAutoComplete
} from './__hooks__';
//import PrintAttendanceSheet from "./PrintAttendanceSheet";
import SelectCreator from '../../../../../_metronic/functions/SelectCreator';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';
import { makeStyles } from '@material-ui/core/styles';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { TextField } from "@material-ui/core";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";
import moment from "moment";
import { Link } from 'react-router-dom';
import HonorListExcelFormat from "./HonorListExcelFormat";
import history from '../../../../history';
import SummaryOfGradesConfirmation from "../../../../../_metronic/layout/components/custom/modal/SummaryOfGradesConfirmation";

export default function HonorList() {

    const { state: { fees, auth, semesters }, dispatch } = useAppContext();
    const { register, handleSubmit, errors } = useForm();
    const [onAlert, setOnAlert] = useState(false);  
    const [instituteSelect, setInstituteSelect] = useState([]);
    const [instituteArray, setInstituteArray] = useState([])
    const [courseSelect, setCourseSelect] = useState([]);
    const [schoolYearSelect, setSchoolYearSelect] = useState(null);
    const [semesterSelect, setSemesterSelect] = useState(null);
    const [schoolYearDisplay, setSchoolYearDisplay] = useState(null);
    const [semesterDisplay, setSemesterDisplay] = useState(null);
    const [isLoading, setIsLoading] = useState(false);
    const [studentList, setStudentList] = useState([]);
    const [results, setResults] = useState([]);
    //const [studentId, setStudentId] = useState(0);
    const [isForConfirmation, setIsForConfirmation] = useState(false);
    const [timeGenerated, setTimeGenerated] = useState(null);
    const [defaultValues, setDefaultValues] = useState({
        //studentId: 0,
        studentName: '',
    });
    //const history = useHistory();
    const currDate = moment().format('YYYY-MM-DD');

    const onReset = async () => {
        setResults([]);
        setIsLoading(false);
        setOnAlert(false);

        // setDefaultValues((prevData) => {
        //     return {...prevData, 
        //         studentId: studentSelected.studentId, 
        //         studentName: studentSelected.studentName
        //     }
        // });
    }

    // const [studentSelected, setStudentSelected] = useState({
    //     studentId: 0,
    //     studentName: '',
    // });

    const [filter, setFilter] = useState({ 
        instituteId: null,
        courseId: null,
        schoolYear: auth.data.schoolyears.grading.SchoolYear, 
        semesterId: auth.data.schoolyears.grading.SemesterId
    });

    const filterChange = name => event => { 
        setFilter({ ...filter, [name]: event });
        setOnAlert(false);
    }

    useEffect(() => {
        // SelectCreator(instituteDropdown, setInstitutes, "InstituteId", "Description");
        SelectCreator(getSchoolYear, setSchoolYearSelect, "SchoolYear", "Description");
        SelectCreator(getSemester, setSemesterSelect, "SemesterId", "Description");
        SelectCreator(getInstitute, setInstituteSelect, 'InstituteId', 'Code');
        SelectCreator(getCustomCourseList, setCourseSelect, 'CourseIdx', 'Code');
    }, []);

    useEffect(() => {
        if(instituteSelect.length > 0){
            let arrIG = [];
            for(let i = 1; i < instituteSelect.length; i++){
                arrIG.push(instituteSelect[i].tableId);
            }
            setInstituteArray(arrIG);
        }
    }, [instituteSelect]);

    useEffect(() => {
        setArgs((prevData) => {
            return {...prevData, 
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: (filter.instituteId === null || filter.instituteId == '') ? 90 : filter.instituteId,
                courseId: (filter.courseId === null || filter.courseId == '') ? 0 : filter.courseId,
                //studentId: studentId,
                instituteArray: instituteArray,
                schoolYearDisplay: schoolYearDisplay,
                semesterDisplay: semesterDisplay
            }
        });
    }, [filter.schoolYear, filter.semesterId, filter.instituteId, filter.courseId, 
        instituteArray, semesterDisplay, schoolYearDisplay]);

    useEffect(() => {
        localStorage.setItem("ghl-school-year",filter.schoolYear);
        localStorage.setItem("ghl-semester-id",filter.semesterId);
        localStorage.setItem("ghl-institute-id",(filter.instituteId === null || filter.instituteId == "") ? 90 : filter.instituteId);
        localStorage.setItem("ghl-course-id",(filter.courseId === null || filter.courseId == "") ? 0 : filter.courseId);
        //localStorage.setItem("ghl-student-id",studentId);
        localStorage.setItem("ghl-institute-select",instituteArray);
        localStorage.setItem("ghl-schoolYearDisplay",schoolYearDisplay);
        localStorage.setItem("ghl-semesterDisplay",semesterDisplay);
    }, [filter.schoolYear, filter.semesterId, filter.instituteId, filter.courseId, 
        instituteArray, semesterDisplay, schoolYearDisplay]);

    useEffect(() => {
        if(schoolYearSelect !== null && typeof(schoolYearSelect) !== "undefined" ) {
            for(let i = 0; i < schoolYearSelect.length; i++){
                if(filter.schoolYear == schoolYearSelect[i].tableId){
                    setSchoolYearDisplay(schoolYearSelect[i].code);
                    break;
                }
            }
        }

        if(semesterSelect !== null && typeof(semesterSelect) !== "undefined" ) {
            for(let i = 0; i < semesterSelect.length; i++){
                if(filter.semesterId == semesterSelect[i].tableId){
                    setSemesterDisplay(semesterSelect[i].code);
                    break;
                }
            }
        }
       
    }, [filter.schoolYear, filter.semesterId, schoolYearSelect, semesterSelect]);

    const onSearch = () => {
        searchForGrades();
    }

    const handleStudentInputChange = async (input) => {
        await studentAutoComplete({ input: input })
            .then(res => {
                setStudentList(res.data);
            })
            .catch(e => {

            })
    }

    // const handleGetStudent = async (data) => {
        
    //     //setStudent(null);
    //     setResults([]);
    //     setOnAlert(false);
    //     setStudentId(data.StudentId);

    //     if(studentList !== null){
    //         for(let i = 0; i < studentList.length; i++){
    //             if(studentList[i].StudentId == data.StudentId){
    //                 setStudentSelected((prevData) => {
    //                     return {...prevData, 
    //                         studentId: studentList[i].StudentId, 
    //                         studentName: studentList[i].StudentName
    //                     }
    //                 });
    //                 break;
    //             }
    //         }
    //     }
    // }

    const [args, setArgs] = useState({ 
        schoolYear: filter.schoolYear,
        semester: filter.semesterId,
        instituteId: null,
        courseId: null,
        //studentId: 0,
        instituteArray: instituteArray,
        schoolYearDisplay: schoolYearDisplay,
        semesterDisplay: semesterDisplay
    });

    const searchForGrades = async () => {

        if(filter.schoolYear == '' || filter.semesterId == ''){
            setOnAlert(true);
        } else {
            setIsLoading(true);
            let date = new Date();
            setTimeGenerated(date.toLocaleTimeString('en-US', { hour12: true }));
            setResults([]);
            setOnAlert(false);
    
            let values = {
                //studentId: studentId === null ? 0 : studentId,
                schoolYear: filter.schoolYear,
                semesterId: filter.semesterId,
                instituteId: filter.instituteId === null ? 90 : filter.instituteId,
                courseId: filter.courseId === null ? 0 : filter.courseId,
                instituteSelect: instituteSelect
            };

            let displayNoRecordFound = true;
        }
    }

    const handleSearchClick = () => {
        if((filter.instituteId === null || filter.instituteId == "" || filter.instituteId == 90)){
            if((filter.courseId === null || filter.courseId == "" || filter.courseId == 0)){
                setIsForConfirmation(true);
            } else {
                history.push('/report/honor-list', { data: args });
            }
        } else {
            history.push('/report/honor-list', { data: args });
        }
    };

    const afterConfirmation = () => {
        history.push('/report/honor-list', { data: args });
    };

    return (
        <>
            {
                results.length == 0 && !isLoading && schoolYearSelect && semesterSelect && instituteSelect && courseSelect &&
                <>
                    <SummaryOfGradesConfirmation
                        isOpen={isForConfirmation}
                        handleConfirm={() => afterConfirmation()}
                        handleClose={() => setIsForConfirmation(false)}
                        title={'Generate Honor List Report'}
                        content={'The selected filters will list all available data. Would you like to proceed?'}
                    />

                    <div className='d-flex align-items-center'>
                        <main className="card mx-auto my-auto shadow-lg" style={{ width: "500px" }}>
                            <header className='card-header p-5 bg-secondary'>
                                <h6 class="float-left m-0 mt-4 ml-5">Honor List</h6>
                            </header>

                            <section className="card-body">
                                   {onAlert == true && results.length == 0 &&
                                       <div className="alert alert-danger" role="alert">
                                           No Record Found
                                       </div>
                                   }

                                   {/* School Year Dropdown */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={schoolYearSelect}
                                               onHandleChange={filterChange('schoolYear')}
                                               refClassContainer=""
                                               name="schoolYear"
                                               isRequired={false}
                                               withLabel={true}
                                               label="School Year"
                                               placeHolder="Select School Year"
                                               forwardRef={() => { }}
                                               selectedId={filter.schoolYear}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`School Year`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                           />
                                       </div>
                                   </div>

                                   {/* Semester Dropdown */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={semesterSelect}
                                               onHandleChange={filterChange('semesterId')}
                                               refClassContainer=""
                                               name="semesterId"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Semester"
                                               placeHolder="Select Semester"
                                               forwardRef={() => { }}
                                               selectedId={filter.semesterId}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Semester`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                           />
                                       </div>
                                   </div>

                                   {/* Institutes */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={instituteSelect}
                                               onHandleChange={filterChange('instituteId')}
                                               refClassContainer=""
                                               name="instituteId"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Search by Institute"
                                               placeHolder="Select Institute"
                                               forwardRef={() => { }}
                                               selectedId={filter.instituteId}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Institutes`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                               isWithToolTip={true}
                                               toolTipText={'Optional; Leave blank to search "All" by default'}
                                           />
                                       </div>
                                   </div>

                                   {/* Courses */}
                                   <div className="form-row">
                                       <div className="form-group col">
                                           <IMSelect
                                               data={courseSelect}
                                               onHandleChange={filterChange('courseId')}
                                               refClassContainer=""
                                               name="courseId"
                                               isRequired={false}
                                               withLabel={true}
                                               label="Search by Course"
                                               placeHolder="Select Course"
                                               forwardRef={() => { }}
                                               selectedId={filter.courseId}
                                               refClassName={`text-center`}
                                               withDescription={false}
                                               description={`Courses`}
                                               refDisabled={true}
                                               refIsStatic={false}
                                               refStaticData={[
                                               ]
                                               }
                                               field={{
                                                   tableId: 'tableId',
                                                   display: 'code'
                                               }}
                                               isWithToolTip={true}
                                               toolTipText={'Optional; Leave blank to search "All" by default'}
                                           />
                                       </div>
                                   </div>

                                        <div className='row'>
                                            <div className="form-group col">
                                                {
                                                    (filter.semesterId != '' && filter.schoolYear != '') ?

                                                    <>
                                                        <button className='btn btn-success btn-block' onClick={() => { handleSearchClick() }} > 
                                                            Search
                                                        </button> 
                                                    </>

                                                    :
                                                
                                                    <>
                                                        <button className='btn btn-success btn-block' onClick={() => { setOnAlert(true) }} > 
                                                            Search
                                                        </button> 
                                                    </>
                                                }
                                            </div>

                                            <div className="form-group col">
                                                {
                                                    // totalRecords > 0 && 
                                                    <HonorListExcelFormat 
                                                        fileName={'HonorListReport'} 
                                                        btnName={'Extract Report'} 
                                                        schoolYear={filter.schoolYear}
                                                        semesterId={filter.semesterId}
                                                        instituteId={(filter.instituteId === null || filter.instituteId == '') ? 90 : filter.instituteId}
                                                        courseId={(filter.courseId === null || filter.courseId == '') ? 0 : filter.courseId}
                                                        //studentId={studentId}
                                                        instituteArray={instituteArray}
                                                        schoolYearDisplay={schoolYearDisplay}
                                                        semesterDisplay={semesterDisplay}
                                                    />
                                                }
                                            </div>
                                        </div>
                            </section>
                        </main>
                    </div>
                </>
            }
        </>
    )
}