import instance from '../../../../../apis/local/systemAPI';


export const getGradeElement = async (page, rowsPerPage, keyword) => {
    var result;
    if (keyword === '') {
        result = await instance.get(`api/references/grade-element/page/${page + 1}/${rowsPerPage}`);
    } else {
        const params = {
            where: [
                {
                    "o": "", "p": ["LIKE", "||", {
                        "shortName": keyword,
                        "description": keyword
                    }
                    ]
                }
            ]
            , start: page + 1
            , limit: rowsPerPage
        }
        result = await instance.post(`api/references/grade-element/q`, params);
    }

    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createGradeElement = async (formValues) => {
    const result = await instance.post("api/references/grade-element/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateGradeElement = async (id, formValues) => {
    const result = await instance.patch(`api/references/grade-element/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteGradeElement = async (id) => {
    const result = await instance.delete(`api/references/grade-element/${id}`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}