import React, { useState, useEffect } from 'react';
import {
  getClassRecordRawGrades, getClassRecordHeaders, 
  getFinalRatingHeaders, getClassRecordFinalRating,
  getClassRecordFinalRatingByGradingPeriod, getClassRecordRoomDetails,
  getclassRecordFinalRatingAll
} from './__hooks__';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import './__hooks__/styles.css'
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

export default function PrintClassRecord(props){

  const { state: { fees, auth, semesters }, dispatch } = useAppContext();
  const [data, setData] = useState({ 
    employeeId: 767, //auth.data.EmployeeId,
    schoolYear: localStorage.getItem("class-record-school-year"),
    semester: localStorage.getItem("class-record-semester"),
    instructionalGuide: localStorage.getItem("class-record-instructional-guide"),
    gradingPeriodId: localStorage.getItem("class-record-grading-period"),
    scheduleType: localStorage.getItem("class-record-schedule-type"),
    reportTypeId: localStorage.getItem("class-record-report-type-id"),
    ssdId: localStorage.getItem("class-record-subj-sched-ids"),
    syDisplay: localStorage.getItem("class-record-sy-display"),
    semDisplay: localStorage.getItem("class-record-sem-display"),
    rtDisplay: localStorage.getItem("class-record-rt-display")
  });

  const [rawGradeDetails, setRawGradeDetails] = useState([]);
  const [finalRatingDetails, setFinalRatingDetails] = useState([]);
  const [rawGradeHeaders, setRawGradeHeaders] = useState([]);
  const [finalRatingHeaders, setFinalRatingHeaders] = useState([]);
  const [finalGradesHeaders, setFinalGradesHeaders] = useState([]);


  const [finalGradeByGradingPeriod, setFinalGradeByGradingPeriod] = useState([]);
  const [finalGradesAll, setFinalGradesAll] = useState([]);
  const [roomDetails, setRoomDetails] = useState([]);

  //console.log("props.location.data: ",props.location.data);
  let intructionalGuides = localStorage.getItem("class-record-instructional-guides");
  let scheduleTypeList = localStorage.getItem("class-record-schedule-types");
  let subjSchedIds = localStorage.getItem("class-record-subj-sched-ids");
  let intructionalGuidesArr = intructionalGuides ? intructionalGuides.split(',') : [];
  let scheduleTypeArr = scheduleTypeList ? scheduleTypeList.split(',') : [];
  let subjSchedIdsArr = subjSchedIds ? subjSchedIds.split(',') : [];

  useEffect(() => {
    if(props.location.data !== undefined) {
      const newData = props.location.data;
      setData((prevData) => {
        return {...prevData, 
            schoolYear: newData.schoolYear,
            semester: newData.semester,
            instructionalGuide: newData.instructionalGuide,
            gradingPeriodId: newData.gradingPeriodId,
            scheduleType: newData.scheduleType,
            ssdId: newData.ssdId,
            reportTypeId: newData.reportTypeId,
            syDisplay: newData.schoolYearDisplay,
            semDisplay: newData.semesterDisplay,
            rtDisplay: newData.reportTypeDisplay
        }
      });

    } else {
      
      setData((prevData) => {
        return {...prevData, 
            schoolYear: localStorage.getItem("class-record-school-year"),
            semester: localStorage.getItem("class-record-semester"),
            instructionalGuide: localStorage.getItem("class-record-instructional-guide"),
            gradingPeriodId: localStorage.getItem("class-record-grading-period"),
            scheduleType: localStorage.getItem("class-record-schedule-type"),
            ssdId: localStorage.getItem("class-record-subj-sched-ids"),
            reportTypeId: localStorage.getItem("class-record-report-type-id"),
            syDisplay: localStorage.getItem("class-record-sy-display"),
            semDisplay: localStorage.getItem("class-record-sem-display"),
            rtDisplay: localStorage.getItem("class-record-rt-display")
        }
      });
    }

    if(data.schoolYear === null || data.semester === null || data.instructionalGuide === null || data.scheduleType === null || data.gradingPeriodId === null){
          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'warning', snackBarDuration: 5000, snackBarMessage: "No data available." });
    } else {

      if(data.reportTypeId == 1) {
        getClassRecord();
        getClassRecordHeader();
      } else {
        //getClassRecord_FinalGrade();
        getClassRecord_RoomDetails();
        getFinalRating_Headers();

        if(data.gradingPeriodId >= 1){
          getClassRecord_FinalGradeByGradingPeriod();
        }  else {
          getClassRecord_FinalGradeAll();
        }
      }
    }
  }, []);

  const getClassRecord = async() => {
    let arr = [];
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      await getClassRecordRawGrades(data.schoolYear,data.semester,intructionalGuidesArr[i],subjSchedIdsArr[i],data.gradingPeriodId,scheduleTypeArr[i])//data.scheduleType)
          .then(res => {
            
              if(res.data.length > 0){
                arr.push(res.data[0]);
                
              }
          })
          .catch(error => {
          
            return []
          })
    }
    setRawGradeDetails(arr);
  }    

  // To Be Replaced - For Removal
  const getClassRecord_FinalGrade = async() => {
    let arr = [];
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      await getClassRecordFinalRating(data.schoolYear,data.semester,subjSchedIdsArr[i],data.gradingPeriodId,scheduleTypeArr[i])
          .then(res => {
            
              if(res.data.length > 0){
                arr.push(res.data[0]);
                
              }
          })
          .catch(error => {
          
            return []
          })
    }
    setFinalRatingDetails(arr);
  }   

  const getClassRecord_FinalGradeAll = async() => {
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      await getclassRecordFinalRatingAll(data.schoolYear,data.semester,subjSchedIdsArr[i],1,scheduleTypeArr[i])
          .then(res => {
            
              if(res.data.length > 0){
                setFinalGradesAll(res.data);
              }
          })
          .catch(error => {
            return []
          })
    }
  }
  
  const getClassRecord_FinalGradeByGradingPeriod = async() => {
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      await getClassRecordFinalRatingByGradingPeriod(data.schoolYear,data.semester,subjSchedIdsArr[i],data.gradingPeriodId,scheduleTypeArr[i])
          .then(res => {
            
              if(res.data.length > 0){
                setFinalGradeByGradingPeriod(res.data);
              }
          })
          .catch(error => {
            return []
          })
    }
  }

  const getClassRecord_RoomDetails= async() => {
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      await getClassRecordRoomDetails(data.schoolYear,data.semester,subjSchedIdsArr[i],scheduleTypeArr[i])
          .then(res => {
              if(res.data.length > 0){
                setRoomDetails(res.data);
              }
          })
          .catch(error => {
            return []
          })
    }
  }   

  const getClassRecordHeader = async() => {
    let arr = [];
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      
      let arr2 = [];
      let arr_prelim = [];
      let arr_midterm = [];
      let arr_finals= [];
      await getClassRecordHeaders(data.schoolYear,data.semester,intructionalGuidesArr[i],data.gradingPeriodId)
          .then(res => {
            

              for(let ii = 0; ii < res.data.length; ii++){
                switch(res.data[ii].GradingPeriodId){
                  case 1:
                    arr_prelim.push(res.data[ii]);
                    break;
                  case 2:
                    arr_midterm.push(res.data[ii]);
                    break;
                  case 4:
                    arr_finals.push(res.data[ii]);
                    break;
                  default:
                    break;
                }
              }

              arr2.push(arr_prelim);
              arr2.push(arr_midterm);
              arr2.push(arr_finals);

              arr.push(arr2);
              
          })
          .catch(error => {
          
            return []
          })
    }
    setRawGradeHeaders(arr);
  }

  const getFinalRating_Headers = async() => {
    let arr = [];
    let arr_finalGrade = [];
    for(let i = 0; i < intructionalGuidesArr.length; i++) {
      
      let arr2 = [];
      let arr_prelim = [];
      let arr_midterm = [];
      let arr_finals = [];
      await getFinalRatingHeaders(data.schoolYear,data.semester,subjSchedIdsArr[i],data.gradingPeriodId)
          .then(res => {
            
              for(let ii = 0; ii < res.data.length; ii++){
                switch(res.data[ii].GradingPeriodId){
                  case 1:
                    arr_prelim.push(res.data[ii]);
                    break;
                  case 2:
                    arr_midterm.push(res.data[ii]);
                    break;
                  case 4:
                    arr_finals.push(res.data[ii]);
                    break;
                  default:
                    arr_finalGrade.push(res.data[ii]);
                    break;
                }
              }

              arr2.push(arr_prelim);
              arr2.push(arr_midterm);
              arr2.push(arr_finals);

              arr.push(arr2);
              
          })
          .catch(error => {
          
            return []
          })
    }
    setFinalRatingHeaders(arr);
    setFinalGradesHeaders(arr_finalGrade);
  } 

  //console.log("finalGradesAll:", finalGradesAll);

  return (
    <>
      {rawGradeDetails.length > 0 && rawGradeDetails.map((res, index) => {
        return (
          <>
            <br></br>
            <div id="" className="faith-header-print-only">
                <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p>
                <p className="text-center" style={{lineHeight:"1px"}}>Class Record</p>
                <p className="text-center" style={{lineHeight:"1px"}}>{data.semDisplay} - {data.syDisplay}</p>
                <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p>
            </div>

            <div key={index}>
              <table id="raw-grade-headers" className="class-record-style-table class-record-table-centered">
                  <thead >
                      <tr>
                          <th className="class-record-header-odd" width="10%" >Subject:</th>
                          <th className="class-record-header-even" width="10%">{res.Subject}</th>
                          <th className="class-record-header-odd" width="10%">Days:</th>
                          <th className="class-record-header-even" width="10%">{res.Days}</th>
                          <th className="class-record-header-odd" width="10%">Time:</th>
                          <th className="class-record-header-even" width="10%">{res.Time}</th>
                          <th className="class-record-header-odd" width="10%">Room:</th>
                          <th className="class-record-header-even" width="10%">{res.Room}</th>

                      </tr>
                      <tr>
                          <th className="class-record-header-odd">Instructor:</th>
                          <th className="class-record-header-even">{res.Instructor}</th>
                          <th className="class-record-header-odd">Class Code:</th>
                          <th className="class-record-header-even">{res.ClassCode}</th>
                          <th className="class-record-header-odd">Code:</th>
                          <th className="class-record-header-even">{res.Code}</th>
                          <th className="class-record-header-odd">Section:</th>
                          <th className="class-record-header-even">{res.ClassSection}</th>
                      </tr>
                  </thead>
              </table>
            </div>
            
            <br></br>

            <table className="class-record-style class-record-table-centered">
                <thead>  
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header" rowSpan="2" width="10">#</th>
                        <th className="class-record-style-header" rowSpan="2">Students</th>

                        {rawGradeHeaders[index].map((details,i) => {
                          
                            return ( details.length > 0 &&
                              <>
                                <th className="class-record-style-header" colSpan={details.length}>{
                                  i == 0 ? "Prelim Grade" : i == 1 ? "Midterm Grade" : "Final Grade"
                                }</th> 
                                <th className="class-record-style-header" rowSpan="2">Computed Grade</th>
                                <th className="class-record-style-header" rowSpan="2">{
                                  i == 0 ? "Prelim Grade" : i == 1 ? "Midterm Grade" : "Final Grade"
                                }</th> 

                                {
                                  data.gradingPeriodId != 0 &&
                                  <>
                                    <th className="class-record-style-header" rowSpan="2">Posted</th>
                                    <th className="class-record-style-header" rowSpan="2">Subject Head Status</th>
                                    <th className="class-record-style-header" rowSpan="2">Dean Status</th>
                                  </>
                                }
                                
                              </>
                            )
                          }
                        )}
                    </tr>

                    {/* Should be dynamic - third colspan should also be dynamic*/}
                    
                    <tr>
                      {rawGradeHeaders[index].map((details,i) => {
                        return (
                          <>
                          {details.length > 0 && details.map((res,i) => {
                            
                              return ( 
                                <th className="class-record-style-header">{res.ReportCode}</th>
                              )
                            }
                          )}
                          </>
                        )
                      }
                      )}
                    </tr>
                    
                </thead>
                <tbody className="class-record-style">
                    {res.Details.map((row, index) => (
                        <tr key={index} className="class-record-style">
                            <td className="class-record-style" >{index + 1}</td>
                            <td className="class-record-style" >{`${row.Student}`}</td>

                            {row.Details.map((res, index) => {
                              
                                return(
                                  <>
                                    {
                                      res.DisplayReportCode !== null &&
                                      <td className={(res.SortOrder == 9 && (res.Grade < 75.00 && res.Grade !== null) || res.SortOrder == 3 && (res.Grade < 74.50 && res.Grade !== null)) ? "class-record-style-grades-failed"
                                        : "class-record-style-grades"
                                        }
                                        >{res.Grade}</td>
                                    }

                                    {
                                      data.gradingPeriodId != 0 && res.SortOrder == 9 && res.DisplayReportCode !== null &&
                                      <>
                                        <td className="class-record-style-grades" >{res.PostedBy}</td>
                                        <td className="class-record-style-grades" >{res.ValidatedBy}</td>
                                        <td className="class-record-style-grades" >{res.AuthenticatedBy}</td>
                                      </>
                                    }
                                  </>
                                )
                            })}
                        </tr> 
                    ))}
                </tbody>
            </table>
            
            <br></br>
            <br></br>
          </>                              
        )}
      )}

      {/* To Be Removed */}
      {finalRatingDetails.length > 0 && finalRatingHeaders.length > 0 && finalRatingDetails.map((res, index) => {
        return (
          <>
            <br></br>
            <div id="" className="faith-header-print-only">
                <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p>
                <p className="text-center" style={{lineHeight:"1px"}}>Class Record</p>
                <p className="text-center" style={{lineHeight:"1px"}}>{data.semDisplay} - {data.syDisplay}</p>
                <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p>
            </div>

            <div key={index}>
              <table id="final-rating-headers" className="class-record-style-table class-record-table-centered">
                  <thead >
                      <tr>
                          <th className="class-record-header-odd" width="10%" >Subject:</th>
                          <th className="class-record-header-even" width="10%">{res.Subject}</th>
                          <th className="class-record-header-odd" width="10%">Days:</th>
                          <th className="class-record-header-even" width="10%">{res.Days}</th>
                          <th className="class-record-header-odd" width="10%">Time:</th>
                          <th className="class-record-header-even" width="10%">{res.Time}</th>
                          <th className="class-record-header-odd" width="10%">Room:</th>
                          <th className="class-record-header-even" width="10%">{res.Room}</th>

                      </tr>
                      <tr>
                          <th className="class-record-header-odd">Instructor:</th>
                          <th className="class-record-header-even">{res.Instructor}</th>
                          <th className="class-record-header-odd">Class Code:</th>
                          <th className="class-record-header-even">{res.ClassCode}</th>
                          <th className="class-record-header-odd">Code:</th>
                          <th className="class-record-header-even">{res.Code}</th>
                          <th className="class-record-header-odd">Section:</th>
                          <th className="class-record-header-even">{res.ClassSection}</th>
                      </tr>
                  </thead>
              </table>
            </div>
            
            <br></br>

            <table className="class-record-style class-record-table-centered">
                <thead>  
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header" rowSpan="2" width="10">#</th>
                        <th className="class-record-style-header" rowSpan="2">Students</th>

                        {finalRatingHeaders[index].map((details,i) => {
                            return ( details.length > 0 &&
                              <>
                                <th className="class-record-style-header" colSpan={details.length}>{
                                  i == 0 ? "Prelim" : i == 1 ? "Midterm" : "Finals"
                                }</th> 
                                <th className="class-record-style-header" rowSpan="2">Computed Grade</th>
                                <th className="class-record-style-header" rowSpan="2">{
                                  i == 0 ? "Prelim Grade" : i == 1 ? "Midterm Grade" : "Finals Grade"
                                }</th> 

                                {
                                  data.gradingPeriodId != 0 &&
                                  <>
                                    <th className="class-record-style-header" rowSpan="2">Posted</th>
                                    <th className="class-record-style-header" rowSpan="2">Subject Head Status</th>
                                    <th className="class-record-style-header" rowSpan="2">Dean Status</th>
                                  </>
                                }
                              </>
                            )
                          }
                        )}

                        {data.gradingPeriodId == 0 && finalGradesHeaders.map((details,i) => {
                          return (
                            <>
                              <th className="class-record-style-header" rowSpan="2">{details.ReportCode}</th>
                            </>
                          )
                        }
                        )}

                        {/* {
                          data.gradingPeriodId == 0 &&
                          <>
                            <th className="class-record-style-header" rowSpan="2">LEC Grade</th>
                            <th className="class-record-style-header" rowSpan="2">LAB Grade</th>
                            <th className="class-record-style-header" rowSpan="2">FINAL GRADE</th>
                          </>

                        {/* <th className="class-record-style-header" colSpan={rawGradeHeaders[index].length}>Final Grade</th> 
                        <th className="class-record-style-header" rowSpan="2">Computed Grade</th>
                        <th className="class-record-style-header" rowSpan="2">Prelim Grade</th> */}
                    </tr>

                    {/* Should be dynamic - third colspan should also be dynamic*/}
                    
                    <tr>
                      {finalRatingHeaders[index].map((details,i) => {
                        return (
                          <>
                          {details.length > 0 && details.map((res,i) => {
                            
                              return ( 
                                <>
                                  <th className="class-record-style-header">{res.ReportCode}</th>
                                </>
                              )
                            }
                          )}
                          </>
                        )
                      }
                      )}
                    </tr>
                    
                </thead>
                <tbody className="class-record-style">
                    {res.Details.map((row, index) => (
                        <tr key={index} className="class-record-style">
                            <td className="class-record-style" >{index + 1}</td>
                            <td className="class-record-style" >{`${row.Student}`}</td>

                            {row.Details.map((res, index) => {

                                console.log("row.Details", row.Details);
                                console.log("data.gradingPeriodId", data.gradingPeriodId);
                              
                                return(
                                  <>
                                  
                                    {/* Display grades when DisplayReportCode is not null and SortOrder is less than 10 */}
                                    {
                                      (res.DisplayReportCode !== null && res.SortOrder < 10) &&
                                      <td className={(res.SortOrder == 9 && (res.Grade < 75.00 && res.Grade !== null) || res.SortOrder == 3 && (res.Grade < 74.50 && res.Grade !== null)) ? "class-record-style-grades-failed"
                                      : "class-record-style-grades"
                                      } >{res.Grade}</td>
                                    }

                                    {/* Display Posted By, Validated By, and Authenticated By when a specific Grading Period is selected */}
                                    {
                                      data.gradingPeriodId != 0 && res.SortOrder == 9 && res.DisplayReportCode !== null &&
                                      <>
                                        <td className="class-record-style-grades" >{res.PostedBy}</td>
                                        <td className="class-record-style-grades" >{res.ValidatedBy}</td>
                                        <td className="class-record-style-grades" >{res.AuthenticatedBy}</td>
                                      </>
                                    }

                                    {/* Display LecFinalGrade, LabFinalGrade, and Final Grade when no Grading Period is selected */}
                                    {
                                      (data.gradingPeriodId == 0 && res.SortOrder >= 10) &&
                                      <td className="class-record-style-grades">{res.Grade}</td>
                                    }
                                  </>
                                )
                            })}
                        </tr> 
                    ))}
                </tbody>
            </table>
            
            <br></br>
            <br></br>
          </>                              
        )}
      )}

      {/* Replacement for Final Grade Report Type */}
      {roomDetails.length > 0 && (finalGradeByGradingPeriod.length > 0 || finalGradesAll.length > 0) &&
          <>
            <br></br>

            {roomDetails.map((res,i) => {
              return (
                <>
                  <div id="" className="faith-header-print-only">
                      <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                      <p className="text-center" style={{lineHeight:"1px"}}>City of Tanauan, Batangas</p>
                      <p className="text-center" style={{lineHeight:"1px"}}>Class Record</p>
                      <p className="text-center" style={{lineHeight:"1px"}}>{data.semDisplay} - {data.syDisplay}</p>
                      <p className="text-center" style={{lineHeight:"1px"}}>{data.rtDisplay}</p>
                  </div>
                    
                  <div key={i}>
                    <table id="final-rating-headers" className="class-record-style-table class-record-table-centered">
                        <thead >
                            <tr>
                                <th className="class-record-header-odd" width="10%" >Subject:</th>
                                <th className="class-record-header-even" width="10%">{res.Subject}</th>
                                <th className="class-record-header-odd" width="10%">Days:</th>
                                <th className="class-record-header-even" width="10%">{res.Days}</th>
                                <th className="class-record-header-odd" width="10%">Time:</th>
                                <th className="class-record-header-even" width="10%">{res.Time}</th>
                                <th className="class-record-header-odd" width="10%">Room:</th>
                                <th className="class-record-header-even" width="10%">{res.Room}</th>
                    
                            </tr>
                            <tr>
                                <th className="class-record-header-odd">Instructor:</th>
                                <th className="class-record-header-even">{res.Instructor}</th>
                                <th className="class-record-header-odd">Class Code:</th>
                                <th className="class-record-header-even">{res.ClassCode}</th>
                                <th className="class-record-header-odd">Code:</th>
                                <th className="class-record-header-even">{res.Code}</th>
                                <th className="class-record-header-odd">Section:</th>
                                <th className="class-record-header-even">{res.ClassSection}</th>
                            </tr>
                        </thead>
                    </table>
                  </div>
                </>
              )
            })}
          
            <br></br>

            <table className="class-record-style class-record-table-centered">
                <thead>
                    <tr className="class-record-style-header">
                        <th className="class-record-style-header" rowSpan="2" width="10">#</th>
                        <th className="class-record-style-header" rowSpan="2">Students</th>

                        {finalRatingHeaders[0].map((details,i) => {
                            return ( details.length > 0 &&
                              <>
                                <th className="class-record-style-header" colSpan={details.length}>{
                                  i == 0 ? "Prelim" : i == 1 ? "Midterm" : "Finals"
                                }</th> 
                                <th className="class-record-style-header" rowSpan="2">Computed Grade</th>
                                <th className="class-record-style-header" rowSpan="2">{
                                  i == 0 ? "Prelim Grade" : i == 1 ? "Midterm Grade" : "Finals Grade"
                                }</th> 

                                {
                                  data.gradingPeriodId != 0 &&
                                  <>
                                    <th className="class-record-style-header" rowSpan="2">Posted</th>
                                    <th className="class-record-style-header" rowSpan="2">Subject Head Status</th>
                                    <th className="class-record-style-header" rowSpan="2">Dean Status</th>
                                  </>
                                }
                              </>
                            )
                          }
                        )}
                                
                        {data.gradingPeriodId == 0 && finalGradesHeaders.map((details,i) => {
                            return (
                              <>
                                <th className="class-record-style-header" rowSpan="2">{details.ReportCode}</th>
                              </>
                            )
                          })
                        }
                    </tr>

                    <tr>
                      {finalRatingHeaders[0].map((details,i) => {
                        return (
                          <>
                          {details.length > 0 && details.map((res,i) => {
                            
                              return ( 
                                <>
                                  <th className="class-record-style-header">{res.ReportCode}</th>
                                </>
                              )
                            }
                          )}
                          </>
                        )
                      }
                      )}
                    </tr>
                </thead>
                
                <tbody className="class-record-style">
                    {finalGradeByGradingPeriod.map((res, index) => (
                        <tr key={index} className="class-record-style">

                            <td className="class-record-style" style={{paddingLeft:"4px",paddingRight:"4px"}}>{index + 1}</td>
                            <td className="class-record-style" style={{paddingLeft:"4px",paddingRight:"4px"}}>{`${res.StudentName}`}</td>

                            {
                              <>
                                {
                                  typeof(res.LecGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.LecGrade}</td>
                                }
                                {
                                  typeof(res.LabGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.LabGrade}</td>
                                }
                                <td className={(res.ComputedGrade < 74.50 && res.ComputedGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.ComputedGrade}</td>
                                <td className={(res.FinalGrade < 75.00 && res.FinalGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.FinalGrade}</td>
                              </>
                            }

                            {
                              data.gradingPeriodId != 0 &&
                              <>
                                <td className="class-record-style-grades" >{res.PostedBy}</td>
                                <td className="class-record-style-grades" >{res.ValidatedBy}</td>
                                <td className="class-record-style-grades" >{res.AuthenticatedBy}</td>
                              </>
                            }
                        </tr> 
                    ))}

                    {finalGradesAll.map((res, index) => (
                        <tr key={index} className="class-record-style">

                            <td className="class-record-style" >{index + 1}</td>
                            <td className="class-record-style" >{`${res.StudentName}`}</td>

                            {
                              <>
                                {
                                  typeof(res.PrelimLecGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.PrelimLecGrade}</td>
                                }
                                {
                                  typeof(res.PrelimLabGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.PrelimLabGrade}</td>
                                }
                                <td className={(res.PrelimComputedGrade < 74.50 && res.PrelimComputedGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.PrelimComputedGrade}</td>
                                <td className={(res.PrelimGrade < 75.00 && res.PrelimGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.PrelimGrade}</td>

                                
                                {
                                  typeof(res.MidtermLecGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.MidtermLecGrade}</td>
                                }
                                {
                                  typeof(res.MidtermLabGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.MidtermLabGrade}</td>
                                }
                                <td className={(res.MidtermComputedGrade < 74.50 && res.MidtermComputedGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.MidtermComputedGrade}</td>
                                <td className={(res.MidtermGrade < 75.00 && res.MidtermGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.MidtermGrade}</td>

                                
                                {
                                  typeof(res.FinalsLecGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.FinalsLecGrade}</td>
                                }
                                {
                                  typeof(res.FinalsLabGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.FinalsLabGrade}</td>
                                }
                                <td className={(res.FinalsComputedGrade < 74.50 && res.FinalsComputedGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.FinalsComputedGrade}</td>
                                <td className={(res.FinalsGrade < 75.00 && res.FinalsGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.FinalsGrade}</td>

                                {
                                  typeof(res.LecGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.LecGrade}</td>
                                }

                                {
                                  typeof(res.LabGrade) !== "undefined" && 
                                  <td className="class-record-style-grades">{res.LabGrade}</td>
                                }

                                <td className={(res.FinalGrade < 74.50 && res.FinalGrade !== null) ? "class-record-style-grades-failed"
                                : "class-record-style-grades"
                                } >{res.FinalGrade}</td>
                              </>
                            }

                            {
                              data.gradingPeriodId != 0 &&
                              <>
                                <td className="class-record-style-grades" >{res.PostedBy}</td>
                                <td className="class-record-style-grades" >{res.ValidatedBy}</td>
                                <td className="class-record-style-grades" >{res.AuthenticatedBy}</td>
                              </>
                            }
                        </tr> 
                    ))}
                </tbody>
            </table>
            
            <br></br>
            <br></br>
          </>                     
      }

      {rawGradeDetails.length == 0 && finalGradeByGradingPeriod.length == 0 && finalGradesAll.length == 0 && 
        <Loading /> // "No Data Available"
      }
    </>
  )
}