import instance from "../../../../../apis/local/systemAPI";



export const getSchoolYear = async () => {
    const result = await instance.get("api/references/schoolyears/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentStatus = async () => {
    const result = await instance.get("api/enrollment/status/enrollmentStatusDropdown");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getSemester = async () => {
    const result = await instance.get("api/references/semesters/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstitute = async () => {
    const result = await instance.get("api/references/institutes/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getCourse = async () => {
    const result = await instance.get("api/references/courses/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getYearLevel = async () => {
    const result = await instance.get("api/references/yearlevels/");
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSemester = async () => {
    const result = await instance.post("api/references/semesters/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getActiveSchoolYear = async () => {
    const result = await instance.post("api/references/schoolyears/q", { where: `WHERE IsActive = 1` });
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentReport = async (formValues) => {
    const result = await instance.post("api/enrollment/enrollment-report/search", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getEnrollmentChedReport = async (formValues) => {
    const result = await instance.post("api/enrollment/enrollment-report/searchChedReport", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}