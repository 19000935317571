import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { getActiveEducations } from './__hooks__';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export function EducationSelect(props) {
  const { refDisabled, refIsStatic, refStaticData, refIsRequired, refWithLabel, forwardRef, selectedId
    , refOnHandleChange, refClassName, refWithDescription, refClassContainer, refError, refErrorMessage } = props;
  const { dispatch } = useAppContext();
  const [disabled, setDisabled] = useState(refDisabled);
  const [results, setResults] = useState(refIsStatic ? Object.values(refStaticData) : []);

  useEffect(() => {
    if (!refIsStatic) {
      const execute = async () => {
        await getActiveEducations()
          .then(response => {
            if (response.success) {
              dispatch({ type: referenceTypes.GET_EDUCATIONS, data: response.data });
              setResults(response.data);
              setDisabled(false);
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
            }
          })
          .catch(error => {
            if (error.response.status === 401) {
              localStorage.clear();
              history.push('/auth/login');
            } else {
              dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
            }
          })
      }
      execute();
    }
  }, [dispatch, refIsStatic])

  // const handleChange = val => {
  //   dispatch({ type: referenceTypes.SELECTED_EDUCATION, data: results.filter(row => row.EducationId === Number(val)) });
  // }

  return (
    <IMSelect
      name="educationId"
      isRequired={refIsRequired}
      withLabel={refWithLabel}
      label="Education"
      placeHolder="Education"
      forwardRef={forwardRef}
      selectedId={selectedId}
      data={results}
      field={{
        tableId: 'EducationId',
        code: 'Code',
        display: 'Education'
      }}
      onHandleChange={refOnHandleChange}
      withClassName={refClassName}
      withDescription={refWithDescription}
      withClassContainer={refClassContainer}
      description="Please enter your education."
      disabled={disabled}
      error={refError}
      errorMessage={refErrorMessage}
    />
  );
}