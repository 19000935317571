import { number } from "prop-types";
import React, { useRef, forwardRef } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from '../../../../../_metronic/_assets/logo/FaithCollegesPNG.png'
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import './__hooks__/styles.css'
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

const useStyles = makeStyles({
    table: {
        minWidth: 650,
    },

    blueBGColor: {
        //backgroundColor:"#b3ebfb !important",
        padding: "3px 0px 3px 0px !important"
    },

    redBGColor: {
        // backgroundColor:"#ff6c6c !important"
        color:"red !important"
        ,padding: "3px 0px 3px 0px !important"
    },

    topAndSideBorders: {
        borderBottomStyle:"none",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
        ,padding: "3px 0px 3px 0px !important"
    },

    sideBordersOnly: {
        borderBottomStyle:"none",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
        ,padding: "3px 0px 3px 0px !important"
    },

    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
        ,padding: "3px 0px 3px 0px !important"
    },
    
    bottomAndSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"none",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
        ,padding: "3px 0px 3px 0px !important"
    },

    allSideBorders: {
        borderBottomStyle:"solid",
        borderTopStyle:"solid",
        borderLeftStyle:"solid",
        borderRightStyle:"solid",
        borderWidth:"medium",
        borderColor:"#ebedf6"
        ,padding: "3px 0px 3px 0px !important"
    }

});

export default function PrintRoomFacilityReport(props) {
    const results = props["results"]["0"];
    const academicYear = props["results"]["1"];
    const semester = props["results"]["2"];
    const room = props["results"]["3"];
    let componentRef = useRef();
    const classes = useStyles();

    //console.log("room: ", room);

    const Component = forwardRef((props, ref) => {
        return (
            <>
                <style type="text/css" media="print">{"\
                  @page {\ size: portrait;\ margin: 20mm 20mm 20mm 20mm;}\
                "}</style>

                <div id="main-div"  >
                    <div ref={ref}>
                        <div className="container" >
                            <main id="main-semantic" className="card border-0 p-5">
                                <section id="section-semantic" className="card-body my-5" >
                                    <div id="faith-header" >
                                        <img style={{ width: "200px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                        <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                        <h4 className="text-center">Room Facility Report</h4>
                                        <h4 className="text-center">A.Y. {academicYear}, {semester}</h4>
                                    </div>

                                    <br></br>
                                    <br></br>

                                    {results.map((res, index) => {
                                        return (

                                            <div key={index}>
                                                <div className="break-div break-line"></div>
                                                
                                                <div id="" className="faith-header-print-only-rfr" style={{display:"none"}}>
                                                    <img style={{ width: "200px"}} src={FaithLogo} className="mx-auto d-flex justify-content-center" />
                                                    <h3 className="text-center">FIRST ASIA INSTITUTE OF TECHNOLOGY AND HUMANITIES</h3>
                                                    <h4 className="text-center">Room Facility Report</h4>
                                                    <h4 className="text-center">A.Y. {academicYear}, {semester}</h4>
                                                </div>

                                                <div 
                                                    className={
                                                        res.VacantRoom ? "page-break-default" :
                                                            room == -2 ? 
                                                                index == 0 ? 
                                                                    "page-break-non-vacant-first" : 
                                                                    "page-break-non-vacant-succeeding"
                                                            : room == -1 ?
                                                                (index - 1) > 0 ?
                                                                    (results[index - 1].VacantRoom && results[index + 1].VacantRoom) ? "page-break-after-vacant" :
                                                                        (!results[index - 1].VacantRoom && results[index + 1].VacantRoom) ? "page-break-non-vacant-succeeding" :
                                                                            (results[index - 1].VacantRoom && !results[index + 1].VacantRoom) ? "page-break-after-vacant" :
                                                                                (!results[index - 1].VacantRoom && !results[index + 1].VacantRoom) ? "page-break-non-vacant-succeeding" 
                                                                                : "page-break-default"
                                                                : "page-break-default"
                                                            : ""
                                                    }
                                                >
                                                    <div className="break-div break-line"></div>
                                                    <h6  style={{backgroundColor:"white"}}><span >Room: </span>{res.Room}</h6>
                                                    <TableContainer key={res.Room} component={Paper} >
                                                        <Table className={classes.table} size="small" aria-label="a dense table" style={{pageBreakInside:"auto", maxHeight:"1056px", tableLayout:"fixed"}}>
                                                            <TableHead >
                                                                <TableRow>
                                                                    <TableCell className="td-padding" width="20%" align="center">Time</TableCell>
                                                                    <TableCell className="td-padding" width="13%" align="center">Monday</TableCell>
                                                                    <TableCell className="td-padding" width="13%" align="center">Tuesday</TableCell>
                                                                    <TableCell className="td-padding" width="13%" align="center">Wednesday</TableCell>
                                                                    <TableCell className="td-padding" width="13%" align="center">Thursday</TableCell>
                                                                    <TableCell className="td-padding" width="13%" align="center">Friday</TableCell>
                                                                    <TableCell className="td-padding" width="13%" align="center">Saturday</TableCell>
                                                                </TableRow>
                                                            </TableHead>
                                                            <TableBody>
                                                                {res.Details.map((row, index) => (
                                                                    <TableRow key={index}>
                                                                        
                                                                        {/* Table Column for Start Time/End Time */}
                                                                        <TableCell className="td-padding subject" width="20%" align="center"> 
                                                                            {row.TimeEnd != null ? row.TimeStart + " - " + row.TimeEnd : row.TimeStart}
                                                                        </TableCell>

                                                                        {/* Table Column for Monday Schedule */}
                                                                        {
                                                                            row.MonSpan > 0 || row.TimeId == 0 ? 
                                                                            <TableCell 
                                                                                rowSpan={row.MonSpan}
                                                                                width="13%" 
                                                                                align="center" 
                                                                                className={row.MonSub != null ? 
                                                                                    row.TimeId != 0 ?
                                                                                        row.MonSub != "VACANT" && !row.MonSub.includes("%") ?
                                                                                            row.MonBorderChk == 0 ? [classes.blueBGColor, classes.topAndSideBorders]
                                                                                                : row.MonBorderChk == 1 ? [classes.blueBGColor, classes.sideBordersOnly]
                                                                                                : row.MonBorderChk == 2 ? [classes.blueBGColor, classes.bottomAndSideBorders]
                                                                                                : row.MonBorderChk == 3 ? [classes.redBGColor, classes.topAndSideBorders]
                                                                                                : row.MonBorderChk == 4 ? [classes.redBGColor, classes.sideBordersOnly]
                                                                                                : row.MonBorderChk == 5 ? [classes.redBGColor, classes.bottomAndSideBorders]
                                                                                                : row.MonBorderChk == 6 ? [classes.sideBordersOnly]
                                                                                                : row.MonBorderChk == 7 ? [classes.bottomAndSideBorders]
                                                                                                : row.MonBorderChk == 8 ? [classes.blueBGColor, classes.allSideBorders]
                                                                                                : row.MonBorderChk == 9 ? [classes.redBGColor, classes.allSideBorders]
                                                                                                : row.MonBorderChk == 10 ? [classes.allSideBorders]
                                                                                            : [classes.blueBGColor, classes.sideBordersOnly]
                                                                                        : row.MonSub == "VACANT" ? 
                                                                                            row.MonBorderChk == 10 ? 
                                                                                                [classes.allSideBorders] : 
                                                                                                [classes.topAndSideBorders] 
                                                                                        : [classes.allSideBorders] //Default for Room Percentage per Day
                                                                                    : [classes.allSideBorders]
                                                                                :{} }
                                                                                >
                                                                                <p className="subject">{row.MonSub}</p>
                                                                                <p className="subject-details">{row.MonSec}</p>
                                                                                <p className="subject-details">{row.MonIns}</p>
                                                                            </TableCell>
                                                                            :
                                                                            <></>
                                                                        }

                                                                        {/* Table Column for Tuesday Schedule */}
                                                                        {
                                                                            row.TueSpan > 0 || row.TimeId == 0 ? 
                                                                                <TableCell 
                                                                                    rowSpan={row.TueSpan}
                                                                                    width="13%" 
                                                                                    align="center" 
                                                                                    className={row.TueSub != null ?
                                                                                        row.TimeId != 0 ?
                                                                                            row.TueSub != "VACANT" && !row.TueSub.includes("%") ?
                                                                                                row.TueBorderChk == 0 ? [classes.blueBGColor, classes.topAndSideBorders]
                                                                                                    : row.TueBorderChk == 1 ? [classes.blueBGColor, classes.sideBordersOnly]
                                                                                                    : row.TueBorderChk == 2 ? [classes.blueBGColor, classes.bottomAndSideBorders]
                                                                                                    : row.TueBorderChk == 3 ? [classes.redBGColor, classes.topAndSideBorders]
                                                                                                    : row.TueBorderChk == 4 ? [classes.redBGColor, classes.sideBordersOnly]
                                                                                                    : row.TueBorderChk == 5 ? [classes.redBGColor, classes.bottomAndSideBorders]
                                                                                                    : row.TueBorderChk == 6 ? [classes.sideBordersOnly]
                                                                                                    : row.TueBorderChk == 7 ? [classes.bottomAndSideBorders]
                                                                                                    : row.TueBorderChk == 8 ? [classes.blueBGColor, classes.allSideBorders]
                                                                                                    : row.TueBorderChk == 9 ? [classes.redBGColor, classes.allSideBorders]
                                                                                                    : row.TueBorderChk == 10 ? [classes.allSideBorders]
                                                                                                : [classes.blueBGColor, classes.sideBordersOnly]
                                                                                            : row.TueSub == "VACANT" ? 
                                                                                                row.TueBorderChk == 10 ? 
                                                                                                    [classes.allSideBorders] : 
                                                                                                    [classes.topAndSideBorders] 
                                                                                            : [classes.allSideBorders] //Default for Room Percentage per Day
                                                                                        : [classes.allSideBorders]
                                                                                    :{} }
                                                                                    >
                                                                                    <p className="subject">{row.TueSub}</p>
                                                                                    <p className="subject-details">{row.TueSec}</p>
                                                                                    <p className="subject-details">{row.TueIns}</p>
                                                                                </TableCell>
                                                                            :
                                                                            <></>
                                                                        }

                                                                        {/* Table Column for Wednesday Schedule */}
                                                                        {
                                                                            row.WedSpan > 0 || row.TimeId == 0 
                                                                            ? 
                                                                            <TableCell 
                                                                                rowSpan={row.WedSpan}
                                                                                width="13%" 
                                                                                align="center" 
                                                                                className={row.WedSub != null ? 
                                                                                    row.TimeId != 0 ?
                                                                                        row.WedSub != "VACANT" && !row.WedSub.includes("%") ?
                                                                                            row.WedBorderChk == 0 ? [classes.blueBGColor, classes.topAndSideBorders]
                                                                                                : row.WedBorderChk == 1 ? [classes.blueBGColor, classes.sideBordersOnly]
                                                                                                : row.WedBorderChk == 2 ? [classes.blueBGColor, classes.bottomAndSideBorders]
                                                                                                : row.WedBorderChk == 3 ? [classes.redBGColor, classes.topAndSideBorders]
                                                                                                : row.WedBorderChk == 4 ? [classes.redBGColor, classes.sideBordersOnly]
                                                                                                : row.WedBorderChk == 5 ? [classes.redBGColor, classes.bottomAndSideBorders]
                                                                                                : row.WedBorderChk == 6 ? [classes.sideBordersOnly]
                                                                                                : row.WedBorderChk == 7 ? [classes.bottomAndSideBorders]
                                                                                                : row.WedBorderChk == 8 ? [classes.blueBGColor, classes.allSideBorders]
                                                                                                : row.WedBorderChk == 9 ? [classes.redBGColor, classes.allSideBorders]
                                                                                                : row.WedBorderChk == 10 ? [classes.allSideBorders]
                                                                                            : [classes.blueBGColor, classes.sideBordersOnly]
                                                                                        : row.WedSub == "VACANT" ? 
                                                                                            row.WedBorderChk == 10 ? 
                                                                                                [classes.allSideBorders] : 
                                                                                                [classes.topAndSideBorders] 
                                                                                        : [classes.allSideBorders] //Default for Room Percentage per Day
                                                                                    : [classes.allSideBorders]
                                                                                :{} }
                                                                                >
                                                                                <p className="subject">{row.WedSub}</p>
                                                                                <p className="subject-details">{row.WedSec}</p>
                                                                                <p className="subject-details">{row.WedIns}</p>
                                                                            </TableCell>
                                                                            :
                                                                            <></>
                                                                        }

                                                                        {/* Table Column for Thursday Schedule */}
                                                                        {
                                                                            row.ThuSpan > 0 || row.TimeId == 0 ?
                                                                            <TableCell 
                                                                                rowSpan={row.ThuSpan}
                                                                                width="13%" 
                                                                                align="center" 
                                                                                className={row.ThuSub != null ?
                                                                                    row.TimeId != 0 ?
                                                                                        row.ThuSub != "VACANT" && !row.ThuSub.includes("%") ?
                                                                                            row.ThuBorderChk == 0 ? [classes.blueBGColor, classes.topAndSideBorders]
                                                                                                : row.ThuBorderChk == 1 ? [classes.blueBGColor, classes.sideBordersOnly]
                                                                                                : row.ThuBorderChk == 2 ? [classes.blueBGColor, classes.bottomAndSideBorders]
                                                                                                : row.ThuBorderChk == 3 ? [classes.redBGColor, classes.topAndSideBorders]
                                                                                                : row.ThuBorderChk == 4 ? [classes.redBGColor, classes.sideBordersOnly]
                                                                                                : row.ThuBorderChk == 5 ? [classes.redBGColor, classes.bottomAndSideBorders]
                                                                                                : row.ThuBorderChk == 6 ? [classes.sideBordersOnly]
                                                                                                : row.ThuBorderChk == 7 ? [classes.bottomAndSideBorders]
                                                                                                : row.ThuBorderChk == 8 ? [classes.blueBGColor, classes.allSideBorders]
                                                                                                : row.ThuBorderChk == 9 ? [classes.redBGColor, classes.allSideBorders]
                                                                                                : row.ThuBorderChk == 10 ? [classes.allSideBorders]
                                                                                            : [classes.blueBGColor, classes.sideBordersOnly]
                                                                                        : row.ThuSub == "VACANT" ? 
                                                                                            row.ThuBorderChk == 10 ? 
                                                                                                [classes.allSideBorders] : 
                                                                                                [classes.topAndSideBorders] 
                                                                                        : [classes.allSideBorders] //Default for Room Percentage per Day
                                                                                    : [classes.allSideBorders]
                                                                                :{} }
                                                                                >
                                                                                <p className="subject">{row.ThuSub}</p>
                                                                                <p className="subject-details">{row.ThuSec}</p>
                                                                                <p className="subject-details">{row.ThuIns}</p>
                                                                            </TableCell>
                                                                            :
                                                                            <></>
                                                                        }

                                                                        {/* Table Column for Friday Schedule */}
                                                                        {
                                                                            row.FriSpan > 0 || row.TimeId == 0 ?
                                                                            <TableCell 
                                                                                rowSpan={row.FriSpan}
                                                                                width="13%" 
                                                                                align="center" 
                                                                                className={row.FriSub != null ? 
                                                                                    row.TimeId != 0 ?
                                                                                        row.FriSub != "VACANT" && !row.FriSub.includes("%") ?
                                                                                            row.FriBorderChk == 0 ? [classes.blueBGColor, classes.topAndSideBorders]
                                                                                                : row.FriBorderChk == 1 ? [classes.blueBGColor, classes.sideBordersOnly]
                                                                                                : row.FriBorderChk == 2 ? [classes.blueBGColor, classes.bottomAndSideBorders]
                                                                                                : row.FriBorderChk == 3 ? [classes.redBGColor, classes.topAndSideBorders]
                                                                                                : row.FriBorderChk == 4 ? [classes.redBGColor, classes.sideBordersOnly]
                                                                                                : row.FriBorderChk == 5 ? [classes.redBGColor, classes.bottomAndSideBorders]
                                                                                                : row.FriBorderChk == 6 ? [classes.sideBordersOnly]
                                                                                                : row.FriBorderChk == 7 ? [classes.bottomAndSideBorders]
                                                                                                : row.FriBorderChk == 8 ? [classes.blueBGColor, classes.allSideBorders]
                                                                                                : row.FriBorderChk == 9 ? [classes.redBGColor, classes.allSideBorders]
                                                                                                : row.FriBorderChk == 10 ? [classes.allSideBorders]
                                                                                            : [classes.blueBGColor, classes.sideBordersOnly]
                                                                                        : row.FriSub == "VACANT" ? 
                                                                                            row.FriBorderChk == 10 ? 
                                                                                                [classes.allSideBorders] : 
                                                                                                [classes.topAndSideBorders] 
                                                                                        : [classes.allSideBorders] //Default for Room Percentage per Day
                                                                                    : [classes.allSideBorders]
                                                                                :{} }
                                                                                >
                                                                                <p className="subject">{row.FriSub}</p>
                                                                                <p className="subject-details">{row.FriSec}</p>
                                                                                <p className="subject-details">{row.FriIns}</p>
                                                                            </TableCell>
                                                                            :
                                                                            <></>
                                                                        }

                                                                        {/* Table Column for Saturday Schedule */}
                                                                        {
                                                                            row.SatSpan > 0 || row.TimeId == 0 ?
                                                                            <TableCell 
                                                                                rowSpan={row.SatSpan}
                                                                                width="13%" 
                                                                                align="center" 
                                                                                className={row.SatSub != null ?
                                                                                    row.TimeId != 0 ?
                                                                                        row.SatSub != "VACANT" && !row.SatSub.includes("%") ?
                                                                                            row.SatBorderChk == 0 ? [classes.blueBGColor, classes.topAndSideBorders]
                                                                                                : row.SatBorderChk == 1 ? [classes.blueBGColor, classes.sideBordersOnly]
                                                                                                : row.SatBorderChk == 2 ? [classes.blueBGColor, classes.bottomAndSideBorders]
                                                                                                : row.SatBorderChk == 3 ? [classes.redBGColor, classes.topAndSideBorders]
                                                                                                : row.SatBorderChk == 4 ? [classes.redBGColor, classes.sideBordersOnly]
                                                                                                : row.SatBorderChk == 5 ? [classes.redBGColor, classes.bottomAndSideBorders]
                                                                                                : row.SatBorderChk == 6 ? [classes.sideBordersOnly]
                                                                                                : row.SatBorderChk == 7 ? [classes.bottomAndSideBorders]
                                                                                                : row.SatBorderChk == 8 ? [classes.blueBGColor, classes.allSideBorders]
                                                                                                : row.SatBorderChk == 9 ? [classes.redBGColor, classes.allSideBorders]
                                                                                                : row.SatBorderChk == 10 ? [classes.allSideBorders]
                                                                                            : [classes.blueBGColor, classes.sideBordersOnly]
                                                                                        : row.SatSub == "VACANT" ? 
                                                                                            row.SatBorderChk == 10 ? 
                                                                                                [classes.allSideBorders] : 
                                                                                                [classes.topAndSideBorders] 
                                                                                        : [classes.allSideBorders] //Default for Room Percentage per Day
                                                                                    : [classes.allSideBorders]
                                                                                :{} }
                                                                                >
                                                                                <p className="subject">{row.SatSub}</p>
                                                                                <p className="subject-details">{row.SatSec}</p>
                                                                                <p className="subject-details">{row.SatIns}</p>
                                                                            </TableCell>
                                                                            :
                                                                            <></>
                                                                        }
                                                                    </TableRow> 
                                                                ))}
                                                            </TableBody>
                                                        </Table>
                                                    </TableContainer>
                                                    <br></br>
                                                </div>
                                            </div>
                                        )
                                    })}
                                </section>
                            </main>
                        </div>
                    </div>
                </div>

                <div id="loading-div" className="d-none" >
                    <div className="container" style={{width:"1224px", maxWidth:"1224px"}}>
                        <main className="card border-0 p-5">
                            <section className="card-body my-5" >
                                <Loading />
                            </section>
                        </main>
                    </div>
                </div>
            </>
        )
    })

    const handleBeforePrint = () => {
        // Modify the contents of the page before printing
        // For example, you could hide certain elements or apply custom styling
        // Here's an example of how to hide the printing button when the page is printed
        // const printButton = document.querySelector('.print-button');
        // if (printButton) {
        //     printButton.style.display = 'none';
        // }
        
        const faithHeader = document.getElementById('faith-header');
        const breakline = document.querySelectorAll('.break-div');
        const main = document.getElementById('main-semantic');
        const section = document.getElementById('section-semantic');
        const mainDiv = document.getElementById('main-div');
        const loadingDiv = document.getElementById('loading-div');
        const faithHeaderPrintRFR = document.querySelectorAll(".faith-header-print-only-rfr");

        if (faithHeaderPrintRFR) {
            for (let i = 0; i < faithHeaderPrintRFR.length; i++) {
                faithHeaderPrintRFR[i].style.display = 'block';
            }
        }

        if (faithHeader) {
            faithHeader.style.display = 'none';
        }

        if (breakline) {
            for (let i = 0; i < breakline.length; i++) {
                breakline[i].classList.remove('break-line');
              }
        }

        if (main) {
            main.classList.remove('card', 'border-0', 'p-5');
        }

        if (section) {
            section.classList.remove('card-body', 'my-5');
        }

        if (mainDiv) {
            mainDiv.style.display = 'none';
        }

        if (loadingDiv) {
            loadingDiv.classList.remove('d-none');
        }
    }

    const handleAfterPrint = () => {
        const faithHeader = document.getElementById('faith-header');
        const breakline = document.querySelectorAll('.break-div');
        const main = document.getElementById('main-semantic');
        const section = document.getElementById('section-semantic');
        const mainDiv = document.getElementById('main-div');
        const loadingDiv = document.getElementById('loading-div');
        
        const faithHeaderPrintRFR = document.querySelectorAll(".faith-header-print-only-rfr");

        if (faithHeaderPrintRFR) {
            for (let i = 0; i < faithHeaderPrintRFR.length; i++) {
                faithHeaderPrintRFR[i].style.display = 'none';
            }
        }

        if (faithHeader) {
            faithHeader.style.display = 'block';
        }

        if (breakline) {
            for (let i = 0; i < breakline.length; i++) {
                breakline[i].classList.add('break-line');
              }
        }

        if (main) {
            let listMain = main.classList;
            listMain.add('card', 'border-0', 'p-5');
        }

        if (section) {
            let listSection = section.classList;
            listSection.add('card-body', 'my-5');
        }

        if (mainDiv) {
            mainDiv.style.display = 'block';
        }

        if (loadingDiv) {
            loadingDiv.classList.add('d-none');
        }
    }

    return (
        <>
            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    trigger={() => <button style={{marginTop:"1%", width:"190px", height:"38px"}} className="btn btn-sm btn-success mr-3">Generate Report</button>}
                    content={() => componentRef.current}
                    onBeforeGetContent={handleBeforePrint}
                    onAfterPrint={handleAfterPrint}
                />
                
                <Component ref={componentRef} />
            </div>
        </>
    )
}