import React, { useState, useEffect } from 'react';
import history from '../../../../../../history';
import AddUpdateModal from '../../../../../../../_metronic/layout/components/custom/modal/AddUpdateModal';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import { getCourseOutlineReq, createCourseOutlineReq, updateCourseOutlineReq, deleteCourseOutlineReq, getSubject } from './__hooks__';
import IMSelect from '../../../../../../../_metronic/layout/components/custom/select/IMSelect';
import SelectCreator from '../../../../../../../_metronic/functions/SelectCreator';
import IMTable from '../../../../../../../_metronic/layout/components/custom/table/IMTable';
import { Switch, FormControl, FormControlLabel } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import { useForm } from 'react-hook-form';
export default function List() {
  const { state: { fees }, dispatch } = useAppContext();
  const { setValue } = useForm()
  const [results, setResults] = useState([]);
  const [totalRecords, setTotalRecord] = useState(fees.totalRecords);
  const [page, setPage] = useState(fees.page);
  const [rowsPerPage, setRowsPerPage] = useState(fees.rowsPerPage);
  const [keyword, setKeyword] = useState(fees.keyword);
  const [onSearch, setOnSearch] = useState(fees.keyword === '' ? false : true);
  const [isOpenAdd, setIsOpenAdd] = useState(false)
  const [isOpenUpdate, setIsOpenUpdate] = useState(false)
  const [isOpenDelete, setIsOpenDelete] = useState(false)
  const [rowValue, setRowValue] = useState(null)
  const [rowDeleteValue, setRowDeleteValue] = useState(null)
  const updateId = fees.id;
  const { id } = useParams()


  const [instituteSelect, setInstituteSelect] = useState(null)
  const [subjectSelect, setSubjectSelect] = useState(null)


  // table pagination and search -----------------------------------------------------

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value);
    setPage(0);
  };

  const handleChangeKeyword = (event) => {
    setKeyword(event);
  };

  const handleChangeOnSearch = (event) => {
    setOnSearch(event);
  };


  // on save grade element  -----------------------------------------------------------
  const onSaveGradeElement = async (val) => {
    await createCourseOutlineReq(val)
      .then(res => {
        setIsOpenAdd(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Requisite has been saved.' });

      })
  }

  // on update grade element
  const onUpdateElement = async (id, val) => {
    await updateCourseOutlineReq(id, val)
      .then(res => {
        setIsOpenUpdate(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Requisite has been updated.' });
      })
  }

  // on delete grade element
  const onDeleteElement = async (id) => {
    await deleteCourseOutlineReq(id)
      .then(res => {
        setIsOpenDelete(false)
        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'success', snackBarDuration: 3000, snackBarMessage: 'Requisite has been Deleted.' });
      })
      .catch(e => {

      })
  }



  // modal content and logics -------------------------------------------------------

  //Add
  const onAddGradeElement = () => {
    setIsOpenAdd(true)
  }

  const onAddGradeElementClose = () => {
    setIsOpenAdd(false)
  }

  //Update
  const onUpdateGradeElement = (data) => {
    setRowValue(data)
  }

  const onUpdateGradeElementClose = () => {
    setIsOpenUpdate(false)
    setRowValue(null)
  }

  //Delete
  const onDeleteGradeElement = (data) => {
    setRowDeleteValue(data)
  }

  const onDeleteGradeElementClose = () => {
    setIsOpenDelete(false)
  }


  // functions
  const keysToCamelCase = (obj) => {
    if (obj !== null && obj !== undefined) {
      const newObject = {};
      for (const key in obj) {
        const modifiedKey = key.charAt(0).toLowerCase() + key.slice(1);
        newObject[modifiedKey] = obj[key];
      }

      return newObject
    } else {
      return
    }

  }

  useEffect(() => {
    SelectCreator(getSubject, setSubjectSelect, 'SubjectId', 'Description')
  }, [])

  //Modal Content
  const Content = (props) => {
    const [data, setData] = useState({
      courseOutlineDetailId: parseInt(id),
      subjectId: props.value !== undefined && props.value !== null ? props.value.subjectId : null,
      courseOutlineType: props.value !== undefined && props.value !== null ? props.value.courseOutlineType : null
    })


    const [switchState, setSwitchState] = useState({
      isActive: props.value !== undefined && props.value !== null ? props.value.isActive === 1 ? true : false : false,


    });

    const switchHandleChange = name => event => {
      console.log(event.target.checked)
      setSwitchState({ ...switchState, [name]: event.target.checked });
    };

    // Selects

    const selectSubChange = name => event => { setData({ ...data, subjectId: event }) }
    const selectReqTypeChange = name => event => { setData({ ...data, courseOutlineType: event }) }




    useEffect(() => {
      if (data && switchState) {

        props.onSave({
          ...data,
          isActive: switchState.isActive == true ? 1 : 0,
        })
      }

    }, [data, switchState])



    console.log(props.value)
    return (
      <>

        <main className='container'>


          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                data={props.subjectSelect}
                onHandleChange={selectSubChange('subjectId')}
                refClassContainer=""
                name="subjectId"
                isRequired={false}
                withLabel={false}
                label="Subject"
                placeHolder="Select Subject"
                forwardRef={() => { }}
                selectedId={data.subjectId}
                refClassName={`text-center`}
                withDescription={false}
                //description={`Enrollment Status`}
                refDisabled={true}
                refIsStatic={false}
                refStaticData={[
                ]
                }
                field={{
                  tableId: 'tableId',
                  display: 'code'
                }}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <IMSelect
                onHandleChange={selectReqTypeChange(`courseOutlineType`)}
                refClassContainer="form-group mb-3"
                name={`courseOutlineType`}
                isRequired={true}
                withLabel={false}
                label="Requisite Type"
                placeHolder="Requisite Type"
                selectedId={data.courseOutlineType}
                refClassName={``}
                withDescription={false}
                forwardRef={setValue}
                // description={`Please select your gender.`}
                refDisabled={true}
                refIsStatic={true}
                refStaticData={[
                  { id: 'C', label: 'CO-Requisite' },
                  { id: 'P', label: 'PRE-Requisite' },
                  { id: 'A', label: 'STRICT CO-Requisite' }
                ]}
                field={{
                  tableId: '',
                  display: ''
                }}
              // error={!!errors.gender}
              // errorMessage={errors.gender && errors.gender.type === "required" && 'Gender is required'}
              />
            </div>
          </div>

          <div className="form-row">
            <div className="form-group col">
              <FormControl component="fieldset">
                <FormControlLabel
                  name="isActive"
                  inputRef={() => { }}
                  control={<Switch checked={switchState.isActive} onChange={switchHandleChange('isActive')} value={switchState.isActive} />}
                  label="Active"
                />
              </FormControl>
            </div>
          </div>
        </main>
      </>
    )
  }

  const DeleteContent = (props) => {


    console.log(props.value !== undefined && props.value !== null ? props.value.shortName : '')
    return (
      <>
        <main className='container'>
          <p className=''>Are you sure you want to remove <span className='text=primary'>{`${props.value !== undefined && props.value !== null ? props.value.subject : ''} `}</span> </p>
        </main>



      </>
    )
  }



  useEffect(() => {
    const execute = async () => {
      await getCourseOutlineReq({ where: `WHERE COR.CourseOutlineDetailId = ${parseInt(id)}` })
        .then(response => {
          if (response.success) {
            setResults(response.data);
            setTotalRecord(response.data.length > 0 ? response.data.length : 0);
          } else {
            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
          }
        })
        .catch(error => {

          dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });

        })
    }
    execute();

  }, [isOpenAdd, isOpenDelete, isOpenUpdate])

  useEffect(() => {
    if (rowValue !== null) {
      setIsOpenUpdate(true)
    }
  }, [rowValue])

  useEffect(() => {
    if (rowDeleteValue !== null) {
      setIsOpenDelete(true)
    }
  }, [rowDeleteValue])

  const columns = [
    { id: 'RowCnt', label: '#', align: 'center', minWidth: '15px', withSorting: false, hidden: false, disablePadding: false },
    { id: 'SubjectCode', label: 'Subject Code', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Subject', label: 'Subject Description', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'OutlineTypeDescription', label: 'Type', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'IsActive', label: 'Active', align: 'left', withSorting: true, hidden: false, disablePadding: false },
    { id: 'Action', label: 'Action', align: 'center', withSorting: false, minWidth: '120px', hidden: false, disablePadding: false }
  ]

  const tableProps = {
    recordId: 'CourseOutlineReqId',
    sortField: '',
    columns: columns,
    rows: results,
    totalRecords: totalRecords,
    withMoreButton: false,
    childWithMoreButton: false,
    withFooter: false,
    tableType: 'addUpdateGradeTemplate',
    parentId: null,
    tableTitle: 'List of Course Outline Requisite',
    onSearch: onSearch,
    keyword: keyword,
    lastUpdateId: updateId,
    onPage: page,
    onRowsPerPage: rowsPerPage,
    onRowsPerPageOptions: [10, 25, 50, 100],
    onTypes: referenceTypes,
    onHandleChangePage: handleChangePage,
    onHandleChangeRowsPerPage: handleChangeRowsPerPage,
    onHandleChangeKeyword: handleChangeKeyword,
    onHandleChangeOnSearch: handleChangeOnSearch,
    onUpdate: onUpdateGradeElement,
    onDelete: onDeleteGradeElement
  }
  console.log(rowValue)
  console.log(keysToCamelCase(rowValue))
  console.log(instituteSelect)

  return (
    <>
      {subjectSelect &&
        <>
          <AddUpdateModal
            title="Add Course Outline Requisite"
            isOpen={isOpenAdd}
            Content={Content}
            handleClose={onAddGradeElementClose}
            isAdd={true}
            subjectSelect={subjectSelect}
            onSubmit={onSaveGradeElement}
            id="courseOutlineReqId"

          />

          <AddUpdateModal
            title="Update Course Outline Requisite"
            isOpen={isOpenUpdate}
            Content={Content}
            value={keysToCamelCase(rowValue)}
            handleClose={onUpdateGradeElementClose}
            isUpdate={true}
            subjectSelect={subjectSelect}
            onSubmit={onUpdateElement}
            id="courseOutlineReqId"

          />

          <AddUpdateModal
            title="Delete Course Outline Requisite"
            isOpen={isOpenDelete}
            Content={DeleteContent}
            handleClose={onDeleteGradeElementClose}
            isDelete={true}
            onSubmit={onDeleteElement}
            subjectSelect={subjectSelect}
            value={keysToCamelCase(rowDeleteValue)}
            id="courseOutlineReqId"

          />
          <div className="card card-custom gutter-b example example-compact">
            <div className="card-header">
              <div className="card-title">
                <h3 className="card-label"><span className="kt-badge kt-badge--brand kt-badge--md text-primary">{totalRecords}</span> Record/s Found</h3>
              </div>
              <div className="card-toolbar">
                <div className="example-tools">
                  <button onClick={() => { onAddGradeElement() }} className="btn btn-sm btn-icon-sm btn-primary">
                    <i className="fa fa-plus"></i> Add New
                  </button>
                </div>
              </div>
            </div>
            <div className="card-body" style={{ padding: '0px', margin: '0px' }}>
              <IMTable tableProps={tableProps} />
            </div>
          </div>

        </>
      }

    </>
  );
}