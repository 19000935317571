import React, { useState, forwardRef } from 'react';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Slide } from '@material-ui/core';
import IMSelect from '../select/IMSelect';


const Transition = forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function IMDialogClearanceNotification(props) {
    const { isOpen, onShowDialog,
        title, description, clearance, onHandleRemarks, accountabilitySelect } = props;
    const [open, setOpen] = useState(isOpen);
    const [remarksValue, setRemarksValue] = useState('')
    const [accountabilityId, setAccountabilityId] = useState(0)
    const handleClose = (event, reason) => {

        if (reason && reason === "backdropClick")
            return;
        onShowDialog(false);
        setOpen(false);;


    }

    const handleChange = (event) => {
        setRemarksValue(event.target.value);
    };

    const selectHandleChange = name => event => {
        setAccountabilityId(event);
    }


    const onSubmitRemarks = () => {
        onHandleRemarks(remarksValue, accountabilityId)
        handleClose()
    }

    return (
        <>
            <Dialog
                open={open}
                TransitionComponent={Transition}
                keepMounted
                onClose={handleClose}
                aria-labelledby="alert-dialog-slide-title"
                aria-describedby="alert-dialog-slide-description"
            >
                <DialogTitle id="alert-dialog-slide-title">{title}</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-slide-description">
                        {description}
                        <br />
                        <br />
                        <IMSelect
                            data={accountabilitySelect}
                            onHandleChange={selectHandleChange(`accountabilityId`)}
                            refClassContainer="form-group mb-3"
                            name={`accountabilityId`}
                            isRequired={true}
                            withLabel={true}
                            label="Accountability"
                            placeHolder=""
                            forwardRef={() => { }}
                            selectedId={accountabilityId}
                            refClassName={``}
                            withDescription={false}
                            // description={`Please select your gender.`}
                            refDisabled={true}
                            refIsStatic={false}
                            refStaticData={[


                            ]}
                            field={{
                                tableId: 'tableId',
                                display: 'code'
                            }}
                        // error={!!errors.gender}
                        // errorMessage={errors.gender && errors.gender.type === "required" && 'Gender is required'}
                        />
                        <br />
                        <p>Remarks</p>
                        <textarea className='form-control' onChange={handleChange} style={{ width: '100%', padding: '10px' }} />



                    </DialogContentText>
                </DialogContent>
                <DialogActions>


                    <button disabled={accountabilityId == 0 || remarksValue == ''} onClick={onSubmitRemarks} className="btn btn-success">
                        Submit
                    </button>

                    <button onClick={handleClose} className="btn btn-secondary">
                        Close
                    </button>


                </DialogActions>
            </Dialog>
        </>
    );
}