import React, { useState, useEffect } from 'react';
import history from '../../../../history';
import { getActiveCourses, getActiveCoursesByInstituteId } from './__hooks__';
import { referenceTypes } from './__hooks__/types';
import { useAppContext } from '../../../../contexts/useAppContext';
import { componentTypes } from '../../../../../_metronic/layout/components/snackbar/__hooks__/types';
import IMSelect from '../../../../../_metronic/layout/components/custom/select/IMSelect';

export function CourseSelect(props) {
    const { refDisabled, refIsStatic, refStaticData, refIsRequired, refWithLabel, forwardRef, selectedId
        , refOnHandleChange, refClassName, refWithDescription, refClassContainer, refError, refErrorMessage } = props;
    const { state: { institutes }, dispatch } = useAppContext();
    const [disabled, setDisabled] = useState(refDisabled);
    const [results, setResults] = useState(refIsStatic ? Object.values(refStaticData) : []);

    useEffect(() => {
        if (!refIsStatic) {
            localStorage.removeItem('withSelectedCourse');
            const execute = async () => {
                if (props.refWithFilter) {
                    if (institutes.length !== 0) {

                        if (Object.values(institutes.data).length > 0) {

                            setResults([]);
                            if (props.institute === '') {
                                console.log(localStorage.getItem('withSelectedInstitute'))


                                await getActiveCoursesByInstituteId(Object.values(institutes.data))
                                    .then(response => {
                                        if (response.success) {
                                            dispatch({ type: referenceTypes.GET_COURSES, data: response.data });
                                            setResults(response.data);
                                            setDisabled(false);
                                        } else {
                                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                                        }
                                    })
                                    .catch(error => {
                                        if (error.response.status === 401) {
                                            localStorage.clear();
                                            history.push('/auth/login');
                                        } else {
                                            dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                        }
                                    })

                            } else {
                                if (Object.values(institutes.data).length > 0) {
                                    if (localStorage.getItem('withSelectedInstitute')) {
                                        await getActiveCoursesByInstituteId(Object.values(institutes.data)[0])
                                            .then(response => {
                                                if (response.success) {
                                                    dispatch({ type: referenceTypes.GET_COURSES, data: response.data });
                                                    setResults(response.data);
                                                    setDisabled(false);
                                                } else {
                                                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                                                }
                                            })
                                            .catch(error => {
                                                if (error.response.status === 401) {
                                                    localStorage.clear();
                                                    history.push('/auth/login');
                                                } else {
                                                    dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                                }
                                            })
                                    } else {
                                        if (Object.values(institutes.data).filter(row => row.InstituteId === props.institute)[0] !== undefined) {
                                            await getActiveCoursesByInstituteId(Object.values(institutes.data).filter(row => row.InstituteId === props.institute)[0])
                                                .then(response => {
                                                    if (response.success) {
                                                        dispatch({ type: referenceTypes.GET_COURSES, data: response.data });
                                                        setResults(response.data);
                                                        setDisabled(false);
                                                    } else {
                                                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                                                    }
                                                })
                                                .catch(error => {
                                                    if (error.response.status === 401) {
                                                        localStorage.clear();
                                                        history.push('/auth/login');
                                                    } else {
                                                        dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                                                    }
                                                })
                                        }
                                    }
                                }
                            }
                        }
                    }
                } else {
                    await getActiveCourses()
                        .then(response => {
                            if (response.success) {
                                dispatch({ type: referenceTypes.GET_COURSES, data: response.data });
                                setResults(response.data);
                                setDisabled(false);
                            } else {
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: response.message });
                            }
                        })
                        .catch(error => {
                            if (error.response.status === 401) {
                                localStorage.clear();
                                history.push('/auth/login');
                            } else {
                                dispatch({ type: componentTypes.SHOW_SNACKBAR, snackBarStatus: 'error', snackBarDuration: 3000, snackBarMessage: error.response.data.message });
                            }
                        })
                }
            }
            execute();

            return () => {
                dispatch({ type: referenceTypes.SELECTED_COURSE, data: [] });
            }
        }
    }, [dispatch, props.refWithFilter, institutes, props.institute, refIsStatic])

    // const handleChange = val => {
    //     dispatch({ type: referenceTypes.SELECTED_COURSE, data: results.filter(row => row.CourseId === Number(val)) });
    //     localStorage.setItem('withSelectedCourse', true);
    // }

    return (
        <IMSelect
            name="courseId"
            isRequired={refIsRequired}
            withLabel={refWithLabel}
            label="Course"
            placeHolder="Course"
            forwardRef={forwardRef}
            selectedId={selectedId}
            data={results}
            field={{
                tableId: 'CourseId',
                code: 'Code',
                display: 'Description'
            }}
            onHandleChange={refOnHandleChange}
            withClassName={refClassName}
            withDescription={refWithDescription}
            withClassContainer={refClassContainer}
            description="Please enter your course."
            disabled={disabled}
            error={refError}
            errorMessage={refErrorMessage}
        />
    );
}