
import React, { useState, useEffect } from "react";
import { useAppContext } from "../../../../contexts/useAppContext";


export default function Assessment(props) {
    const { state: { auth } } = useAppContext()

    return (
        <>

            <h1>Test</h1>

        </>

    )
}
