import React, { useState, useEffect } from "react";
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';
import {
    studentClearanceReport
} from "./__hooks__/index"
import moment from "moment";

export default function ClearanceReportExcelFormat({ fileName, btnName, page, keyword, rowsPerPage, 
    schoolYear, semesterId, instituteId, courseId, clearanceId, schoolYearDisplay, semesterDisplay }) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';
    const [extractReport, setExtractReport] = useState([]);
    const [excelFormat, setExcelFormat] = useState([]);
    const [collegeName, setCollegeName] = useState('');
    const [clearanceStatusName, setClearanceStatusName] = useState('');
    
    const currDate = moment().format('YYYYMMDD');

    let schoolYearTo = schoolYear + 1;

    //Initial API call
    useEffect(() => {
        console.log("excel API call triggered")
        studentClearanceReport({
            rowsPerPage: rowsPerPage,
            page: page,
            keyword: keyword != null ? keyword.trim() : null,
            schoolYear: schoolYear,
            semesterId: semesterId,
            instituteId: instituteId,
            courseId: courseId,
            clearanceId: clearanceId,
            extractAll: 1
        }).then(res => {
            setExtractReport(res.data);
        })
    }, [keyword, schoolYear, semesterId, instituteId, courseId, clearanceId]);

    //useEffect when extracReport is updated
    useEffect(() => {
        console.log("extractReport: ",extractReport);
        if (extractReport.length > 0) {
            let arr = [];

            if(instituteId == '' || instituteId === null || instituteId == 90){
                setCollegeName("All-Colleges");
            } else {
                setCollegeName(extractReport[0].Institute);
            }

            console.log("clearanceId: ", clearanceId);

            if(clearanceId == '' || clearanceId === null || clearanceId == 0){
                setClearanceStatusName("All-ClearanceStatus");
            } else {
                setClearanceStatusName(extractReport[0].ClearanceStatus + "-Only");
            }

            for (let i = 0; i < extractReport.length; i++) {
                arr.push({
                    '#': i+1,
                    'Student No': `${extractReport[i].StudentNo}`,
                    'Student Name': `${extractReport[i].StudentName}`,
                    'College': `${extractReport[i].Institute}`,
                    'Program': `${extractReport[i].Course}`,
                    'Year Level': `${extractReport[i].YearLevel}`,
                    'Accountability': `${extractReport[i].Accountability}`,
                    'Remarks': `${extractReport[i].Remarks}`,
                    'Department': `${extractReport[i].Department}`,
                    'School Year': `${extractReport[i].SchoolYear}`,
                    'Semester': `${extractReport[i].Semester}`,
                    'ClearanceStatus': `${extractReport[i].ClearanceStatus}`,
                    'Hold By': `${extractReport[i].HoldBy}`,
                    'Hold Date': `${extractReport[i].HoldDate}`,
                    'Cleared By': `${extractReport[i].ClearBy}`,
                    'Cleared Date': `${extractReport[i].ClearDate}`
                })
            }
            console.log(arr)
            setExcelFormat(arr)
        }
    }, [extractReport])

    const exportToExcel = () => {

        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');
        const month = ["January","February","March","April","May","June","July","August","September","October","November","December"];
        const COLUMNS = "ABCDEFGHIJKLMNOP";

        var currentdate = new Date(); 
        var datetime = "as of " 
            + month[currentdate.getMonth()] + ' ' 
            + currentdate.getDate() + ', ' 
            + currentdate.getFullYear() + ' ' 
            + currentdate.getHours() + ":" 
            + currentdate.getMinutes() + ":" 
            + currentdate.getSeconds();

        let cellHeaderNameA1 = 'Clearance Report';
        let cellHeaderNameA2 = schoolYearDisplay + " - " + semesterDisplay; //'SY ' + schoolYear + '-' + schoolYearTo + ' - ' + semesterId;
        let cellHeaderNameA4 = '#';
        let cellHeaderNameB4 = 'STUDENT NO';
        let cellHeaderNameC4 = 'STUDENT NAME';
        let cellHeaderNameD4 = 'COLLEGE';
        let cellHeaderNameE4 = 'PROGRAM';
        let cellHeaderNameF4 = 'YEAR LEVEL';
        let cellHeaderNameG4 = 'ACCOUNTABILITY';
        let cellHeaderNameH4 = 'REMARKS';
        let cellHeaderNameI4 = 'DEPARTMENT';
        let cellHeaderNameJ4 = 'SCHOOL YEAR';
        let cellHeaderNameK4 = 'SEMESTER';
        let cellHeaderNameL4 = 'CLEARANCE STATUS';
        let cellHeaderNameM4 = 'HOLD BY';
        let cellHeaderNameN4 = 'HOLD DATE';
        let cellHeaderNameO4 = 'CLEARED BY';
        let cellHeaderNameP4 = 'CLEARED DATE';

        worksheet.getCell('A4').value = cellHeaderNameA4;
        worksheet.getCell('B4').value = cellHeaderNameB4;
        worksheet.getCell('C4').value = cellHeaderNameC4;
        worksheet.getCell('D4').value = cellHeaderNameD4;
        worksheet.getCell('E4').value = cellHeaderNameE4;
        worksheet.getCell('F4').value = cellHeaderNameF4;
        worksheet.getCell('G4').value = cellHeaderNameG4;
        worksheet.getCell('H4').value = cellHeaderNameH4;
        worksheet.getCell('I4').value = cellHeaderNameI4;
        worksheet.getCell('J4').value = cellHeaderNameJ4;
        worksheet.getCell('K4').value = cellHeaderNameK4;
        worksheet.getCell('L4').value = cellHeaderNameL4;
        worksheet.getCell('M4').value = cellHeaderNameM4;
        worksheet.getCell('N4').value = cellHeaderNameN4;
        worksheet.getCell('O4').value = cellHeaderNameO4;
        worksheet.getCell('P4').value = cellHeaderNameP4;

        for(let i = 0; i < COLUMNS.length; i++){
            worksheet.getCell(COLUMNS[i] + '4').fill = { type: 'pattern', pattern: 'solid', fgColor:{argb:'228b22'} };
            worksheet.getCell(COLUMNS[i] + '4').font = { bold: true, color: {argb:'ffffffff'} };
        }

        // Add headers
        const headers = Object.keys(excelFormat[0]);
        // worksheet.addRow(headers);

        // Add data rows
        excelFormat.forEach((data) => {
            const row = [];
            headers.forEach((header) => {
                row.push(data[header]);
            });
            worksheet.addRow(row);
        });


        // Adjust column widths based on content
        worksheet.columns.forEach((column, index) => {
            let maxLength = 0;
            column.eachCell({ includeEmpty: true }, (cell) => {
                const columnLength = cell.value ? (cell.value.toString().length + 3) : 10;
                if (columnLength > maxLength) {
                    maxLength = columnLength;
                }
            });
            column.width = maxLength < 10 ? 10 : maxLength;
        });

        worksheet.getCell('A1').value = cellHeaderNameA1.toUpperCase();
        worksheet.getCell('A1').font = { bold: true, color: {argb:'ff000000'}, size: 20 };
        
        worksheet.getCell('A2').value = cellHeaderNameA2.toUpperCase();
        worksheet.getCell('A2').font = { bold: true, color: {argb:'ff000000'}, size: 15 };
        
        worksheet.getCell('D2').value = datetime;

        // Generate a blob from the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + "_" + currDate + "_" + collegeName + "_" + clearanceStatusName + "_" + fileExtension);
        });
    };

    return (
        <>
            <button onClick={exportToExcel} className="btn btn-success btn-block">{btnName}</button>
        </>
    );
}
