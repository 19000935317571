import { number } from "prop-types";
import React, { useRef, useState, useEffect } from "react";
import ReactToPrint from "react-to-print";
import FaithLogo from "../../../../../_metronic/_assets/logo/FaithCollegesPNG.png"
import ProfileLogo from "../../../../../_metronic/_assets/logo/profileLogo.png"
import moment from "moment";

export default function PrintSOA(props) {
    let componentRef = useRef();
    const { 
        // fees, 
        studentAssessmentFees, 
        studentOtherFees, 
        totalAddOns,
        studentAddOns,
        totalInterest,
        studentDiscount,
        totalDiscount,
        totalUnits,
        paymentDetails,
        studentAccount,
        paymentSchedule,
        totalBackAccount,
        result
    } = props

    const [totalEnrollmentRelatedFees, setTotalEnrollmentRelatedFees] = useState(0);
    const [totalNonEnrollmentRelatedFees, setTotalNonEnrollmentRelatedFees] = useState(0);
    const [totalAddOnFees, settotalAddOnFees] = useState(0);
    const [totalDue, setTotalDue] = useState(0);
    const [totalDueFinal, setTotalDueFinal] = useState(0);
    const [totalPaymentMade, setTotalPaymentMade] = useState(0);
   
    const numberWithCommas = (x) => { return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ","); }

    const style = {
        border: 'none !important'
    }

    useEffect(() => {
        if(studentAssessmentFees !== null) {
            let amount = 0;
            for(let i = 0; i < studentAssessmentFees.length; i++){
                amount += parseFloat(studentAssessmentFees[i].FinalAmount);
            }
            setTotalEnrollmentRelatedFees(amount);
        }
    }, [studentAssessmentFees]);


    useEffect(() => {
        if(studentOtherFees !== null) {
            let amount = 0;
            for(let i = 0; i < studentOtherFees.length; i++){
                amount += parseFloat(studentOtherFees[i].FinalAmount);
            }
            setTotalNonEnrollmentRelatedFees(amount);
        }
    }, [studentOtherFees]);


    useEffect(() => {
        settotalAddOnFees(totalAddOns);
    }, [totalAddOns]);


    useEffect(() => {
        setTotalDue(totalEnrollmentRelatedFees + totalNonEnrollmentRelatedFees + totalAddOnFees + totalInterest);
    }, [totalEnrollmentRelatedFees, totalNonEnrollmentRelatedFees, totalAddOnFees, totalInterest]);


    useEffect(() => {
        setTotalDueFinal(totalDue - totalDiscount);
    }, [totalDue, totalDiscount]);


    useEffect(() => {
        if(paymentDetails !== null) {
            setTotalPaymentMade(paymentDetails[paymentDetails.length - 1].Amount)
        }
    }, [paymentDetails]);
    
    return (
        <>

            <div>
                {/* button to trigger printing of target component */}
                <ReactToPrint
                    //trigger={() => <button className="btn btn-link pb-0 d-inline col">Print SOA</button>}
                    trigger={() => <button className="btn btn-success d-inline ml-5">Print SOA</button>}
                    content={() => componentRef}
                />


            </div>

            <div className="d-none">
                <div className="container" ref={(el) => (componentRef = el)}>

                    <main className="card border-0 container">
                        <section className="card-header text-center border-0">
                            <div className='text-center'>
                                <img style={{ width: '250px' }} src={FaithLogo} />
                                <h4 className="font-weight-bold mt-5">STATEMENT OF ACCOUNT</h4>
                                <h4 className="font-weight-bold mt-5">{`${studentAccount[0].SchoolYear} ${studentAccount[0].Semester}`}</h4>
                            </div>
                        </section>
                        <section className="card-body">
                            <div className="row">
                                <div className="col-8 float-left">
                                    {/* <div> <p className="font-weight-bold d-inline">School Year: &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp; </p> <p className=" d-inline">{`${studentAccount[0].SchoolYear}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Semester: &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${studentAccount[0].Semester}`}</p> </div> */}


                                </div>
                                {/* <div className="col-4 float-right">
                                    <div> <p className="font-weight-bold d-inline">Assessment Date: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${studentAccount[0].SY}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Payment Mode: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className=" d-inline">{`${studentAccount[0].PaymentMode}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Reference: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${studentAccount[0].SY}`}</p> </div>
                                    <div> <p className="font-weight-bold d-inline">Encoder: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</p> <p className="d-inline">{`${studentAccount[0].SY}`}</p> </div>
                                </div> */}
                            </div>
                            <br />
                            <hr style={{ borderTop: '3px solid #36454F', fontWeight: 'bold', }} />

                            <div className="row">
                                <div className="col-7 float-left">
                                    <div className="row">
                                        <div className="col-1 p-0">
                                            <img className="float-right" style={{ width: '50px' }} src={ProfileLogo} />

                                        </div>

                                        <div className="col-11">
                                            <table style={{width:"100%"}}>
                                                <tr>
                                                    <td style={{width:"30%"}}>
                                                        <h6 className="font-weight-bold d-inline">Student #:</h6>
                                                    </td>
                                                    <td>
                                                        <p className="lead d-inline">{`${studentAccount[0].StudentNo}`}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6 className="font-weight-bold d-inline">Name:</h6>
                                                    </td>
                                                    <td>
                                                        <p className="lead d-inline">{`${studentAccount[0].StudentName}`}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6 className="font-weight-bold d-inline">Program/Year Level:</h6>
                                                    </td>
                                                    <td>
                                                        <p className="lead d-inline">{`${studentAccount[0].CourseCode}/${studentAccount[0].YearLevel}`}</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-5 float-right">
                                    <div className="row">
                                        <div className="col-11">
                                            <table style={{width:"100%"}}>
                                                <tr>
                                                    <td>
                                                        <h6 className="font-weight-bold d-inline">Payment Period:</h6>
                                                    </td>
                                                    <td>
                                                        <p className="lead d-inline">{`${paymentSchedule[0].ExamPeriod}`}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6 className="font-weight-bold d-inline">Payment Due:</h6>
                                                    </td>
                                                    <td>
                                                        <p className="lead d-inline">{numberWithCommas(parseFloat(paymentSchedule[0].PaymentDue).toFixed(2))}</p>
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        <h6 className="font-weight-bold d-inline">Due Date:</h6>
                                                    </td>
                                                    <td>
                                                        <p className="lead d-inline">{`${paymentSchedule[0].DueDate}`}</p>
                                                    </td>
                                                </tr>
                                            </table>
                                        </div>
                                    </div>
                                    {/* <div> <h6 className="font-weight-bold d-inline">Program: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="lead d-inline">{`${studentAccount[0].CourseCode}`}</p> </div>
                                    <div> <h6 className="font-weight-bold d-inline">Year Level: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="lead d-inline">{`${studentAccount[0].YearLevel}`}</p> </div>
                                    <div> <h6 className="font-weight-bold d-inline">Year Level: &nbsp; &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</h6> <p className="lead d-inline">{`${studentAccount[0].YearLevel}`}</p> </div> */}
                                </div>
                            </div>

                            <hr style={{ borderTop: '3px solid #36454F', fontWeight: 'bold', }} />

                            <br />


                            <section className="">
                                <div className="" style={{marginLeft:"15%",marginRight:"15%"}}>
                                    {/* <p className="font-weight-bold text-center" style={{ fontWeight: 'bold' }}>ASSESSMENT OF FEES</p> */}

                                    <table className="table table-hover mt-5 table-sm  table-borderless" style={{width:"100%"}}>
                                        <tbody className="text-center pt-5">
                                            {studentAssessmentFees.map(data => (
                                                <tr className="mt-5 border-0">
                                                    {/* {data.CodeDesc == 'LB' ?
                                                        <>
                                                            {data.FinalAmount !== null &&
                                                                <th className="p-0 text-center" align="left" scope="row">{data.CodeDesc}</th>

                                                            }

                                                        </>
                                                        :
                                                        <th className="p-0 text-center" align="left" scope="row">{data.CodeDesc}</th>
                                                    } */}
                                                    {data.CodeDesc == 'TF' ?
                                                        <td className="p-0 text-left" align="left">{`${data.Description} (${totalUnits} @ ${data.Amount})`}</td>
                                                        :
                                                        <>
                                                            {data.CodeDesc == 'LB' ?
                                                                <>
                                                                    {data.FinalAmount !== null &&
                                                                        <td className="p-0 text-left" align="left">{data.Description}</td>

                                                                    }

                                                                </>
                                                                :
                                                                <td className="p-0 text-left" align="left">{data.Description}</td>
                                                            }
                                                        </>
                                                    }

                                                    <td className="text-right pl-0" scope="col"></td>
                                                    <td className="text-right pl-0" scope="col"></td>

                                                    {data.FinalAmount !== null &&
                                                        <td className="p-0 text-right" align="right">{numberWithCommas(parseFloat(data.FinalAmount).toFixed(2))}</td>

                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot className="text-center">
                                            <tr>
                                                {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                <th className="text-left pl-0" scope="col">Total Fee and Other Fees</th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalEnrollmentRelatedFees).toFixed(2))}</th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <tbody className="text-center pt-5">
                                            {studentOtherFees.map(data => (
                                                <tr className="mt-5 border-0">
                                                    {/* {data.CodeDesc == 'LB' ?
                                                        <>
                                                            {data.FinalAmount !== null &&
                                                                <th className="p-0 text-center" align="left" scope="row">{data.CodeDesc}</th>

                                                            }

                                                        </>
                                                        :
                                                        <th className="p-0 text-center" align="left" scope="row">{data.CodeDesc}</th>
                                                    } */}
                                                    {data.CodeDesc == 'TF' ?
                                                        <td className="p-0 text-left" align="left">{`${data.Description} (${totalUnits} @ ${data.Amount})`}</td>
                                                        :
                                                        <>
                                                            {data.CodeDesc == 'LB' ?
                                                                <>
                                                                    {data.FinalAmount !== null &&
                                                                        <td className="p-0 text-left" align="left">{data.Description}</td>

                                                                    }

                                                                </>
                                                                :
                                                                <td className="p-0 text-left" align="left">{data.Description}</td>
                                                            }
                                                        </>
                                                    }

                                                    <td className="text-right pl-0" scope="col"></td>
                                                    <td className="text-right pl-0" scope="col"></td>

                                                    {data.FinalAmount !== null &&
                                                        <td className="p-0 text-right" align="right">{numberWithCommas(parseFloat(data.FinalAmount).toFixed(2))}</td>

                                                    }
                                                </tr>
                                            ))}
                                        </tbody>
                                        <tfoot className="text-center">
                                            <tr>
                                                {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                <th className="text-left pl-0" scope="col">Non-School Fees</th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalNonEnrollmentRelatedFees).toFixed(2))}</th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    {
                                        totalAddOnFees != 0 &&
                                        <table className="table table-hover mt-5 table-sm  table-borderless">
                                            <tbody className="text-center pt-5">
                                                {studentAddOns.map(data => (
                                                    <tr className="mt-5 border-0">
                                                        <td className="p-0 text-left" align="left">{data.FeeDesc}</td>
                                                        <td className="text-right pl-0" scope="col"></td>
                                                        <td className="text-right pl-0" scope="col"></td>
                                                        <td className="p-0 text-right" align="right">{numberWithCommas(parseFloat(data.Amount).toFixed(2))}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                            <tfoot className="text-center">
                                                <tr>
                                                    {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                    <th className="text-left pl-0" scope="col">TOTAL ADD-ON FEES</th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalAddOnFees).toFixed(2))}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    }

                                    {
                                        totalInterest != 0 &&
                                        <table className="table table-hover mt-5 table-sm  table-borderless">
                                            <tbody className="text-center pt-5">
                                            </tbody>
                                            <tfoot className="text-center">
                                                <tr>
                                                    {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                    <th className="text-left pl-0" scope="col">INTEREST </th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalInterest).toFixed(2))}</th>
                                                </tr>
                                            </tfoot>
                                        </table>
                                    }

                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <tbody className="text-center pt-5">
                                        </tbody>
                                        <tfoot className="text-center">
                                            <tr>
                                                {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                <th className="text-left pl-0" scope="col">Total Fees </th>
                                                <th className="text-left pl-0" scope="col">{"===========>"}</th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalDue).toFixed(2))}</th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        {
                                            totalDiscount != 0 &&
                                            <>
                                                <tbody className="text-center pt-5">
                                                    {studentDiscount.map(data => (
                                                        <tr className="mt-5 border-0">
                                                            <td className="p-0 text-left" align="left">{data.Scholarship}</td>
                                                            <td className="text-right pl-0" scope="col"></td>
                                                            <td className="text-right pl-0" scope="col"></td>
                                                            <td className="p-0 text-right" align="right">{numberWithCommas(parseFloat(data.DiscountAmount).toFixed(2))}</td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </>
                                        }
                                        
                                        <tfoot className="text-center">
                                            <tr>
                                                <th className="text-left pl-0" scope="col">SCHOLARSHIP/DISCOUNTS </th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalDiscount).toFixed(2))}</th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    
                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <tbody className="text-center pt-5">
                                        </tbody>
                                        <tfoot className="text-center">
                                            <tr>
                                                {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                <th className="text-left pl-0" scope="col">TOTAL DUE </th>
                                                <th className="text-left pl-0" scope="col">{"===========>"}</th>
                                                <th className="text-right pl-0" scope="col"></th>
                                                <th className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(totalDueFinal).toFixed(2))}</th>
                                            </tr>
                                        </tfoot>
                                    </table>

                                    <p className="font-weight-bold text-center" style={{ fontWeight: 'bold' }}>BACK ACCOUNTS</p>

                                    {result !== null ?
                                        <table className="table table-hover mt-5 table-sm  table-borderless">
                                            <thead className="text-center">
                                                <tr>
                                                    <th className="text-left pl-0" scope="col">School Year / Semester</th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col"></th>
                                                    <th className="text-right pl-0" scope="col">Amount</th>
                                                </tr>
                                            </thead>
                                            <tbody className="text-center pt-5">
                                                {result.map((data,i) => {
                                                    return (
                                                        <>
                                                            {
                                                                i + 1 == (result.length) ?
                                                                <>
                                                                    <tr className="mt-5 border-0">
                                                                        <td className="text-left pl-0" scope="col">{data.SYSem}</td>
                                                                        <td className="text-left pl-0" scope="col"></td>
                                                                        <td className="text-right pl-0" scope="col"></td>
                                                                        <td className="text-right pl-0" scope="col"></td>
                                                                        <td className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(data.Amount).toFixed(2))}</td>
                                                                    </tr>

                                                                    <tr className="mt-5 border-0">
                                                                        <td className="text-left pl-0" scope="col" style={{fontWeight:"bold"}}>Total Back Account</td>
                                                                        <td className="text-left pl-0" scope="col"></td>
                                                                        <td className="text-right pl-0" scope="col"></td>
                                                                        <td className="text-right pl-0" scope="col"></td>
                                                                        <td className="text-right pl-0" scope="col" style={{fontWeight:"bold"}}>{numberWithCommas(parseFloat(totalBackAccount).toFixed(2))}</td>
                                                                    </tr>
                                                                </>
                                                                :
                                                                <tr className="mt-5 border-0">
                                                                    <td className="text-left pl-0" scope="col">{data.SYSem}</td>
                                                                    <td className="text-left pl-0" scope="col"></td>
                                                                    <td className="text-right pl-0" scope="col"></td>
                                                                    <td className="text-right pl-0" scope="col"></td>
                                                                    <td className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(data.Amount).toFixed(2))}</td>
                                                                </tr>
                                                            }
                                                        </>

                                                    )
                                                })}
                                            </tbody>
                                        </table>

                                        :

                                        <table className="table table-hover mt-5 table-sm  table-borderless">
                                            <tbody className="text-center pt-5">
                                                <tr className="mt-5 border-0">
                                                    <td className="text-left pl-0" scope="col" style={{fontWeight:"bold"}}>Total Back Account</td>
                                                    <td className="text-left pl-0" scope="col"></td>
                                                    <td className="text-right pl-0" scope="col"></td>
                                                    <td className="text-right pl-0" scope="col"></td>
                                                    <td className="text-right pl-0" scope="col" style={{fontWeight:"bold"}}>{numberWithCommas(parseFloat(0.00).toFixed(2))}</td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    }

                                    <p className="font-weight-bold text-center" style={{ fontWeight: 'bold' }}>PAYMENT MADE</p>

                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <thead className="text-center">
                                            <tr>
                                                <th className="text-left pl-0" scope="col">Date</th>
                                                <th className="text-left pl-0" scope="col">Particulars</th>
                                                <th className="text-right pl-0" scope="col">Ref. No.</th>
                                                <th className="text-right pl-0" scope="col">Amount</th>
                                                <th className="text-right pl-0" scope="col"></th>
                                            </tr>
                                        </thead>
                                        <tbody className="text-center pt-5">
                                            {paymentDetails.map((data,i) => {
                                                return (
                                                    <>
                                                        {
                                                            i != (paymentDetails.length - 1) ?
                                                            <tr className="mt-5 border-0">
                                                                <td className="text-left pl-0" scope="col">{data.Date}</td>
                                                                <td className="text-left pl-0" scope="col">{data.Particulars}</td>
                                                                <td className="text-right pl-0" scope="col">{data.RefNo}</td>
                                                                <td className="text-right pl-0" scope="col">{numberWithCommas(parseFloat(data.Amount).toFixed(2))}</td>
                                                                <td className="text-right pl-0" scope="col"></td>
                                                            </tr>
                                                            :
                                                                <tr className="mt-5 border-0">
                                                                    <td className="text-left pl-0" scope="col" style={{fontWeight:"bold"}}>Total Assessment Payments</td>
                                                                    <td className="text-left pl-0" scope="col"></td>
                                                                    <td className="text-right pl-0" scope="col"></td>
                                                                    <td className="text-right pl-0" scope="col"></td>
                                                                    <td className="text-right pl-0" scope="col" style={{fontWeight:"bold"}}>{numberWithCommas(parseFloat(totalPaymentMade).toFixed(2))}</td>
                                                                </tr>
                                                        }
                                                    </>
                                                    
                                                )
                                            })}
                                        </tbody>
                                    </table>

                                    <table className="table table-hover mt-5 table-sm  table-borderless">
                                        <tbody className="text-center pt-5">
                                        </tbody>
                                        <tfoot className="text-center">
                                            <tr>
                                                {/* <th className="text-center pl-0" scope="col">CODE</th> */}
                                                <th className="text-left pl-0" scope="col-3">BALANCE </th>
                                                <th className="text-left pl-0" scope="col-3">{"===========>"}</th>
                                                <th className="text-right pl-0" scope="col-3"></th>
                                                <th className="text-right pl-0" scope="col-3">{numberWithCommas(parseFloat((totalDueFinal - totalPaymentMade) + totalBackAccount).toFixed(2))}</th>
                                            </tr>
                                        </tfoot>
                                    </table>
                                    
                                </div>

                            </section>

                            <hr style={{ borderTop: '3px solid #36454F', fontWeight: 'bold', }} />

                            <section className="">
                                <div className="" style={{marginLeft:"15%",marginRight:"15%"}}>
                                    <div style={{textAlign:"center"}}>
                                        <p><em>The <strong>Astra E-SOA</strong> currently reflects your outstanding balance for the {studentAccount[0].Semester} of {studentAccount[0].SchoolYear}.</em></p>   
                                        <p><em>Your SOA for the prior years up to the 1st semester of SY 2023-2024 will still be sent through your email accounts.</em></p>
                                        <p><em>For immediate concerns, kindly send your queries in this email (jllomonsod@firstasia.edu.ph). Thank you.</em></p>
                                    </div>
                                </div>
                            </section>

                        </section>
                    </main>
                </div>
            </div>
        </>
    )
}