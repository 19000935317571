import React from "react";
import NoDocument from "../../../../_assets/logo/NoDocument.png"
import Loading from "./Loading";

const NoDocuments = (props) => {


    return (
        <>
            {props.enrollment !== undefined && props.enrollment.length > 0 ?
                <div className="mx-auto" style={{ 'width': '40%' }}>
                    <div className="jumbotron bg-white text-center">

                        <img style={{ width: '40%' }} src={NoDocument} />
                        <h1 className="display-4">No Credentials</h1>
                        <p className="lead">Credentials need to be submitted first in Registrar Office.</p>


                    </div>

                </div>

                :

                <Loading />


            }

        </>
    )

}

export default NoDocuments