import instance from "../../../../../../apis/local/systemAPI";

// export const getInstructionalGuideSchedule = async (con) => {
//     const result = await instance.post("api/grades/instructional-guide-schedule/q", con);
//     if (result.status === 200 || result.status === 201) {
//         return result.data;
//     }
// }


export const getInstructionalGuideSchedule = async (con) => {
    const result = await instance.post("api/grades/instructional-guide-schedule/scheduleList", con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstructionalGuideOverride = async (con) => {
    const result = await instance.post("api/grades/instructional-guide-override/overrideList", con);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const createInstructionalGuideDetail = async (formValues) => {
    const result = await instance.post("api/grades/instructional-guide-detail/", formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const updateInstructionalGuideDetail = async (id, formValues) => {
    const result = await instance.patch(`api/grades/instructional-guide-detail/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const deleteInstructionalGuideSchedule = async (id) => {
    const result = await instance.post(`api/grades/instructional-guide-schedule/delete/`, id);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getInstructionalGuide = async (data) => {
    const result = await instance.post(`api/grades/instructional-guide/f`, data);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getGradingPeriod = async () => {
    const result = await instance.get(`api/references/grading-period/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getExamType = async () => {
    const result = await instance.get(`api/student/exam-types/`);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const getCurrentInstructionalGuide = async (query) => {
    const result = await instance.post("api/grades/instructional-guide/getInstructionalGuideListById", query);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}



export const addUpdateInstructionalGuideOverride = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide-override/addUpdate`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const getScheduleClassSection = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide-schedule/classSection`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const isWithLab = async (val) => {
    const result = await instance.post(`api/grades/instructional-guide/isWithLab`, val);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


