import React, { useState, useEffect } from "react";
import history from "../../../../history";
import { getSubjectRelation, addUpdateSubjectRequisite, getSubjects, deleteSubjectRequisite } from "./__hooks__"
import { useParams } from 'react-router-dom';
import { TableContainer, Table, TableRow, TableCell, TableHead, Paper, TableBody } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import SelectCreator from "../../../../../_metronic/functions/SelectCreator";
import IMSelect from "../../../../../_metronic/layout/components/custom/select/IMSelect";
import { useAppContext } from "../../../../contexts/useAppContext";
import { componentTypes } from "../../../../../_metronic/layout/components/snackbar/__hooks__/types";
import NotificationModal from "../../../../../_metronic/layout/components/custom/modal/NotificationModal";
import Loading from "../../../../../_metronic/layout/components/custom/forms/Loading";

const useStyles = makeStyles({
    root: {
        width: '100%',
    },
    container: {
        maxHeight: 440,
    },
});

export default function SubjectDetails(props) {
    const { state: { auth }, dispatch } = useAppContext()
    const { subject } = props.location
    const { id } = useParams()
    const [subjectRelations, setSubjectRelations] = useState([])
    const [selectedId, setSelectedId] = useState(null)
    const [selectedToDelete, setSelectedToDelete] = useState(null)
    const classes = useStyles();
    const [data, setData] = useState({ subjectId: null, type: null })
    const [isToDelete, setIsToDelete] = useState(false)

    // selects
    const [subjectSelects, setSubjectSelect] = useState([])
    const aliasSelect = [
        { tableId: 'A', code: 'Alias' },
        { tableId: 'P', code: 'PreRequisite' },
        { tableId: 'C', code: 'CoRequisite' }
    ]
    if (subject == undefined || subject == null) {
        history.push('/admin/references/subjects')
    }

    const executeAddUpdatePreRequisiteId = async (requisiteId, row) => {
        await addUpdateSubjectRequisite({
            subjectPreRequisiteId: requisiteId,
            subjectId: id,
            subjectRequisiteId: data.subjectId == null ? row.SubjectId : data.subjectId,
            createdBy: auth.data.Username,
            type: data.type == null ? row.Type : data.type
        }).then(res => {
            setSelectedId(null)
            setData({ subjectId: null, type: null })
            getSubjectRelation({ subjectId: id })
                .then(resp => {
                    setSubjectRelations(resp.data)
                    dispatch({
                        type: componentTypes.SHOW_SNACKBAR,
                        snackBarStatus: 'success',
                        snackBarDuration: 5000,
                        snackBarMessage: 'Subject Relations Updated'
                    });
                })
        })
    }

    const executeDeleteSubjectRequisite = async (requisiteId) => {
        await deleteSubjectRequisite({ subjectPreRequisiteId: requisiteId })
            .then(res => {
                setSelectedId(null)
                setData({ subjectId: null, type: null })
                getSubjectRelation({ subjectId: id })
                    .then(resp => {
                        setSubjectRelations(resp.data)
                        setSelectedToDelete(null)
                        setIsToDelete(false)
                        dispatch({
                            type: componentTypes.SHOW_SNACKBAR,
                            snackBarStatus: 'success',
                            snackBarDuration: 5000,
                            snackBarMessage: 'Subject Relation Deleted'
                        });
                    })
            })
    }

    const selectHandleChange = name => event => { setData((prevData) => { return { ...prevData, [name]: event } }); }

    useEffect(() => {
        if (subject !== undefined || subject !== null) {
            getSubjectRelation({ subjectId: id })
                .then(res => {
                    setSubjectRelations(res.data)
                })
        }
        SelectCreator(getSubjects, setSubjectSelect, 'SubjectId', 'SubjectCode')
    }, [])

    console.log(subject)
    console.log(subjectRelations)
    console.log(subjectSelects)
    return (
        <>
            {subject !== undefined && subjectSelects.length > 0 ?
                <>

                    <NotificationModal
                        isOpen={isToDelete}
                        handleClose={() => executeDeleteSubjectRequisite(selectedToDelete)}
                        title={'Delete Subject Relation'}
                        content={'Are you sure you want to delete?'}
                    />
                    <div className="container">
                        <div className="card">


                            <div className="row container my-5">
                                <div className="col-12 col-xl-4  border-right border-primary">
                                    <div className=" p-5">
                                        <h5 className="text-primary">
                                            <i className="fa fa-book"></i> &nbsp;&nbsp;
                                            {subject.Description}</h5>


                                        <hr />

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Lec Hours</span>
                                            </div>
                                            <input readOnly value={subject.LecHours} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>

                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Lab Hours</span>
                                            </div>
                                            <input readOnly value={subject.LabHours} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Credit Unit</span>
                                            </div>
                                            <input readOnly value={subject.Units} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>
                                        <div className="input-group mb-3">
                                            <div className="input-group-prepend">
                                                <span className="input-group-text" id="inputGroup-sizing-default">Lab Category</span>
                                            </div>
                                            <input readOnly value={subject.SubjectCategory} type="text" className="form-control" aria-label="Default" aria-describedby="inputGroup-sizing-default" />
                                        </div>
                                        <button className="btn btn-secondary" onClick={() => { history.push('/admin/references/subjects') }}>
                                            Back
                                        </button>


                                    </div>


                                </div>
                                <div className="col-12 col-xl-8">
                                    <div className="p-5  ">
                                        <h3 className="bg-secondary lead p-3 font-weight-bold">Subject Relationship</h3>

                                        <Paper className={classes.root}>
                                            <TableContainer className={classes.container}>
                                                <Table stickyHeader size="small" aria-label="a dense table">
                                                    <TableHead>
                                                        <TableRow >
                                                            <TableCell style={{ width: '20%' }}>Subject Code</TableCell>
                                                            <TableCell style={{ width: '35%' }}>Subject Description</TableCell>
                                                            <TableCell style={{ width: '15%' }}>Relation</TableCell>
                                                            <TableCell align="center" style={{ width: '30%' }}>Action</TableCell>
                                                        </TableRow>
                                                    </TableHead>
                                                    <TableBody>
                                                        {subjectRelations.length > 0 && subjectRelations.map((row, index) => {
                                                            console.log(row)
                                                            return (
                                                                <>
                                                                    {selectedId !== row.SubjectPreRequisiteId ?
                                                                        <>
                                                                            <TableRow hover tabIndex={-1} key={index}>
                                                                                <TableCell >{row.SubjectRequisiteCode}</TableCell>
                                                                                <TableCell >{row.SubjectRequisite}</TableCell>
                                                                                <TableCell >{row.Relationship}</TableCell>
                                                                                <TableCell align="center" >
                                                                                    <button className="btn btn-xs btn-icon btn-icon-xs btn-info mr-1"
                                                                                        onClick={() => { setSelectedId(row.SubjectPreRequisiteId) }}>
                                                                                        <i className="fa fa-pencil-alt text-white"></i>
                                                                                    </button>
                                                                                    <button
                                                                                        className="btn btn-xs btn-icon btn-icon-xs btn-danger"
                                                                                        onClick={() => { setSelectedToDelete(row.SubjectPreRequisiteId); setIsToDelete(true); }}
                                                                                    >
                                                                                        <i className="fa fa-trash"></i>
                                                                                    </button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                        :
                                                                        <>
                                                                            <TableRow hover tabIndex={-1} >
                                                                                <TableCell >
                                                                                    <IMSelect
                                                                                        data={subjectSelects}
                                                                                        onHandleChange={selectHandleChange('subjectId')}
                                                                                        refClassContainer=""
                                                                                        name="Subject"
                                                                                        isRequired={true}
                                                                                        withLabel={false}
                                                                                        placeHolder="Subject"
                                                                                        forwardRef={() => { }}
                                                                                        selectedId={data.subjectId == null ? row.SubjectId : data.subjectId}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[

                                                                                        ]
                                                                                        }
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}

                                                                                    />
                                                                                </TableCell>
                                                                                <TableCell ></TableCell>
                                                                                <TableCell >
                                                                                    <IMSelect
                                                                                        data={aliasSelect}
                                                                                        onHandleChange={selectHandleChange('type')}
                                                                                        refClassContainer=""
                                                                                        name="Relation"
                                                                                        isRequired={true}
                                                                                        withLabel={false}
                                                                                        placeHolder="Type"
                                                                                        forwardRef={() => { }}
                                                                                        selectedId={data.type == null ? row.Type : data.type}
                                                                                        refClassName={``}
                                                                                        withDescription={false}
                                                                                        // description={`Please select your gender.`}
                                                                                        refDisabled={true}
                                                                                        refIsStatic={false}
                                                                                        refStaticData={[

                                                                                        ]
                                                                                        }
                                                                                        field={{
                                                                                            tableId: 'tableId',
                                                                                            display: 'code'
                                                                                        }}

                                                                                    />
                                                                                </TableCell >


                                                                                <TableCell align="center">
                                                                                    <button
                                                                                        className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1"
                                                                                        onClick={() => { executeAddUpdatePreRequisiteId(row.SubjectPreRequisiteId, row) }}
                                                                                    >
                                                                                        <i className="fa fa-save"></i>
                                                                                    </button>
                                                                                    <button className="btn btn-xs btn-icon btn-icon-xs btn-secondary"
                                                                                        onClick={() => { setSelectedId(null) }}>
                                                                                        <i className="fa fa-window-close"></i>
                                                                                    </button>
                                                                                </TableCell>
                                                                            </TableRow>
                                                                        </>
                                                                    }


                                                                </>
                                                            )

                                                        })}
                                                        <TableRow hover tabIndex={-1} size="small" >
                                                            <TableCell >
                                                                <IMSelect
                                                                    data={subjectSelects}
                                                                    onHandleChange={selectHandleChange('subjectId')}
                                                                    refClassContainer=""
                                                                    name="Subject"
                                                                    isRequired={true}
                                                                    withLabel={false}
                                                                    placeHolder="Subject"
                                                                    forwardRef={() => { }}
                                                                    selectedId={data.subjectId}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]
                                                                    }
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />
                                                            </TableCell>
                                                            <TableCell ></TableCell>
                                                            <TableCell >
                                                                <IMSelect
                                                                    data={aliasSelect}
                                                                    onHandleChange={selectHandleChange('type')}
                                                                    refClassContainer=""
                                                                    name="Relation"
                                                                    isRequired={true}
                                                                    withLabel={false}
                                                                    placeHolder="Type"
                                                                    forwardRef={() => { }}
                                                                    selectedId={data.type}
                                                                    refClassName={``}
                                                                    withDescription={false}
                                                                    // description={`Please select your gender.`}
                                                                    refDisabled={true}
                                                                    refIsStatic={false}
                                                                    refStaticData={[

                                                                    ]
                                                                    }
                                                                    field={{
                                                                        tableId: 'tableId',
                                                                        display: 'code'
                                                                    }}

                                                                />
                                                            </TableCell >


                                                            <TableCell align="center">
                                                                <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1"
                                                                    onClick={() => { executeAddUpdatePreRequisiteId(0, { SubjectId: null, Type: null }) }}
                                                                >
                                                                    <i className="fa fa-save"></i>
                                                                </button>
                                                            </TableCell>
                                                        </TableRow>
                                                    </TableBody>
                                                </Table>
                                            </TableContainer>

                                        </Paper>

                                    </div>


                                </div>
                            </div>
                        </div>
                    </div>

                </>

                :

                <Loading />



            }

        </>
    )
}