import React from "react";
import * as FileSaver from 'file-saver';
import ExcelJS from 'exceljs';

export default function ExcelJsReport({ excelData, fileName, btnName }) {
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
    const fileExtension = '.xlsx';

    const exportToExcel = () => {
        // Create a new Excel workbook
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('Data');

        // Add headers
        const headers = Object.keys(excelData[0]);
        worksheet.addRow(headers);

        // Add data rows
        excelData.forEach((data) => {
            const row = [];
            headers.forEach((header) => {
                row.push(data[header]);
            });
            worksheet.addRow(row);
        });

        // Generate a blob from the workbook
        workbook.xlsx.writeBuffer().then((buffer) => {
            // Create a Blob from the buffer
            const data = new Blob([buffer], { type: fileType });
            FileSaver.saveAs(data, fileName + fileExtension);
        });
    };

    return (
        <>
            <button onClick={exportToExcel} className="mx-3 btn btn-success">{btnName}</button>
        </>
    );
}
