export const referenceTypes = {
    SELECTED_FAMILY_FAMILY_POSITION: 'SELECTED-FAMILY_POSITION',
    GET_FAMILY_POSITION: 'GET-FAMILY-POSITION',
    GET_FAMILY_POSITION_BY_ID: 'GET-FAMILY-POSITION-BY-ID',
    CREATE: 'CREATE-FAMILY-POSITION',
    UPDATE: 'UPDATE-FAMILY-POSITION',
    DELETE: 'DELETE-FAMILY-POSITION',
    LIST_LINK: '/admin/references/family-position',
    CREATE_LINK: '/admin/references/create-family-position/',
    UPDATE_LINK: '/admin/references/update-family-position/',
    DELETE_LINK: '/admin/references/delete-family-position/'
}