import React, { useState, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { useAppContext } from '../../../../../../contexts/useAppContext';
import { TableRow, TableCell, FormControl, FormControlLabel, Switch } from '@material-ui/core';
import { StudentCategorySelect, InstituteSelect, CourseSelect, YearLevelSelect, ScholarshipSelect, StudyTypeSelect } from '../../../../References/';

export default function Form(props) {
  const { state: { studentCategories, institutes, courses, yearLevels, scholarships, studyTypes } } = useAppContext();
  const { AssessmentLabCategoryId, AssessmentTemplateRateId, LabMode, Amount, IsActive } = props.doc;
  const { assessmentTemplateRateId } = props
  const { register, errors, setValue } = useForm();
  const recordCnt = props.doc.length;
  const initialState = recordCnt === 0 ? {
    assessmentTemplateDetailId: props.assessmentTemplateDetailId,
    assessmentTemplateRateId: assessmentTemplateRateId,
    labMode: null,
    amount: null,
    isActive: true
  } : {
    assessmentTemplateRateId: assessmentTemplateRateId,
    labMode: LabMode,
    amount: Amount,
    isActive: IsActive === 1 ? true : false
  }
  const [data, setData] = useState(initialState);

  useEffect(() => {
    register({ name: 'labMode' }, { required: true });
    register({ name: 'amount' }, { required: true });
  }, [register])

  const handleChange = name => event => {
    if (name === 'isActive') {
      setData({ ...data, [name]: event.target.checked });
    } else {
      setData({ ...data, [name]: event.target.value });
    }
  }

  return (
    <>
      <TableRow key={AssessmentLabCategoryId}>
        <TableCell align="center">{props.index === undefined ? '#' : props.index}</TableCell>
        <TableCell>
          <input
            onChange={handleChange('labMode')}
            type="number"
            name="labMode"
            placeholder="LabMode"
            defaultValue={LabMode === undefined ? "" : LabMode}
            className={`form-control${errors.labMode === undefined ? "" : errors.labMode && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            style={{ width: '150px' }}
          />
        </TableCell>


        <TableCell>
          <input
            onChange={handleChange('amount')}
            type="number"
            name="amount"
            placeholder="Amount"
            defaultValue={Amount === undefined ? "" : Amount}
            className={`form-control${errors.amount === undefined ? "" : errors.amount && " is-invalid"}`}
            ref={register({
              required: true
            })}
            autoComplete="off"
            style={{ width: '150px' }}
          />
        </TableCell>

        <TableCell align="center">
          <FormControl>
            <FormControlLabel
              name="isActive"
              inputRef={register()}
              control={<Switch checked={data.isActive} onChange={handleChange('isActive')} value={data.isActive} />}
              label="Active"
            />
          </FormControl>
        </TableCell>
        <TableCell align="center">
          {props.doc.length === 0 ? <button className="btn btn-xs btn-icon btn-icon-xs btn-success" onClick={() => props.onHandleAdd(data)}>
            <i className="fa fa-plus"></i>
          </button> : <><button className="btn btn-xs btn-icon btn-icon-xs btn-default mr-1" onClick={() => props.onHandleCancel()}>
            <i className="fa fa-ban"></i>
          </button>&nbsp;
            <button className="btn btn-xs btn-icon btn-icon-xs btn-success mr-1" onClick={() => props.onHandleSave(AssessmentLabCategoryId, data)}>
              <i className="fa fa-save text-white"></i>
            </button></>}
        </TableCell>
      </TableRow>
    </>
  );
}