import instance from "../../../../apis/local/systemAPI";


export const updateUser = async (id, formValues) => {
    const result = await instance.patch(`api/user/${id}`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}


export const updatePassword = async (formValues) => {
    const result = await instance.post(`api/user/changePassword`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const isUploadImageAndSignature = async (formValues) => {
    const result = await instance.post(`api/user/uploadedImage`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}

export const uploadImage = async (formValues) => {
    const result = await instance.post(`/uploadImage`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}

export const uploadSignature = async (formValues) => {
    const result = await instance.post(`/uploadSignature`, formValues);
    if (result.status === 200 || result.status === 201) {
        return result;
    }
}


export const getImage = async (studentNo) => {
    const result = await instance.get(`/getImage/${studentNo}.jpg`);
    if (result.status === 200 || result.status === 201) {
        console.log(result)
        return `${result.config.baseURL}${result.config.url}`;
    } else {
        return false
    }
}


export const isRequiredPWChange = async (json) => {
    const result = await instance.post("api/user/isRequiredPWChange", json);
    if (result.status === 200 || result.status === 201) {
        return result.data;
    }
}